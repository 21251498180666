<template>
  <div>
    <v-dialog :value="formDialog" persistent width="1000">
      <v-card>
        <v-card-title>
          <span class="headline">Novo Registro de Cobrança</span>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    readonly
                    filled
                    label="Data da Cobrança *"
                    v-model="date_created"
                    :error-messages="errors.collect('date_created')"
                    data-vv-name="date_created"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm4 md4>
                  <v-autocomplete
                    required
                    label="Agente *"
                    v-model="id_agent"
                    :items="agents"
                    :clearable="['ADD', 'EDIT'].includes(this.mode)"
                    :error-messages="errors.collect('id_agent')"
                    :no-filter="true"
                    :search-input.sync="searchAgent"
                    item-text="nick"
                    item-value="id"
                    no-data-text="Nenhum agente encontrado"
                    placeholder="Digite aqui o nick do agente"
                    deletable-chips
                    small-chips
                    chips
                    outlined
                  ></v-autocomplete>
                </v-flex>

                <v-flex xs12 sm4 md4>
                  <v-select
                    required
                    label="Classificação / Prazo *"
                    clear-icon="mdi-close"
                    v-model="rating"
                    :items="possible_ratings"
                    :clearable="['ADD', 'EDIT'].includes(this.mode)"
                    :error-messages="errors.collect('rating')"
                    v-validate="'required:rating'"
                    item-text="description"
                    item-value="id"
                    data-vv-name="rating"
                    outlined
                  ></v-select>
                </v-flex>

                <v-flex xs12 sm4 md4>
                  <v-select
                    required
                    label="Risco *"
                    clear-icon="mdi-close"
                    v-model="risk"
                    :items="possible_risks"
                    :clearable="['ADD', 'EDIT'].includes(this.mode)"
                    :error-messages="errors.collect('risk')"
                    v-validate="'required:risk'"
                    item-text="description"
                    item-value="id"
                    data-vv-name="risk"
                    outlined
                  ></v-select>
                </v-flex>

                <v-flex xs12 sm12 md12>
                  <v-text-field
                    required
                    label="Observação / Descrição *"
                    clearable
                    v-model="observation"
                    :error-messages="errors.collect('observation')"
                    v-validate="'required:Observação,observation'"
                    data-vv-name="observation"
                    counter
                    maxlength="240"
                    hint="Insira no máximo 240 caracteres"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm4 md4>
                  <v-text-field
                    required
                    readonly
                    filled
                    label="ID do Anotado Atual *"
                    v-model="id_current_annotated"
                    :error-messages="errors.collect('id_current_annotated')"
                    v-validate="
                      'required:ID do Último Anotado,id_current_annotated'
                    "
                    data-vv-name="id_current_annotated"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm4 md4>
                  <v-text-field
                    required
                    readonly
                    filled
                    label="ID do Último Anotado *"
                    v-model="id_last_annotated"
                    :error-messages="errors.collect('id_last_annotated')"
                    v-validate="
                      'required:ID do Último Anotado,id_last_annotated'
                    "
                    data-vv-name="id_last_annotated"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm4 md4>
                  <v-text-field
                    readonly
                    filled
                    label="Pagamento Pendente"
                    hint="Pagamento Pendente + Semana Atual"
                    persistent-hint
                    v-model="debt"
                    data-vv-name="debt"
                    :error-messages="errors.collect('debt')"
                    outlined
                    >R$</v-text-field
                  >
                </v-flex>

                <v-flex xs12 sm4 md4>
                  <v-text-field
                    required
                    readonly
                    filled
                    label="Contato / Grupo *"
                    v-model="ag_group_id"
                    :error-messages="errors.collect('ag_group_id')"
                    v-validate="'required:Contato / Grupo,ag_group_id'"
                    data-vv-name="ag_group_id"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm4 md4>
                  <v-text-field
                    readonly
                    filled
                    label="Responsável *"
                    v-model="responsible"
                    :error-messages="errors.collect('responsible')"
                    data-vv-name="responsible"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm4 md4 v-if="this.mode != 'FINISH'">
                  <v-text-field
                    filled
                    readonly
                    label="Contato do Responsável *"
                    v-model="responsible_contact"
                    :error-messages="errors.collect('responsible_contact')"
                    data-vv-name="responsible_contact"
                    outlined
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-col class="text-center">
            <v-btn
              raised
              color="error"
              @click="setDialog({ dialog: 'formDialog', show: false })"
              >{{ mode == "VIEW" ? "Voltar" : "Cancelar" }}</v-btn
            >
          </v-col>

          <v-col class="text-center">
            <v-btn
              raised
              color="primary"
              v-if="mode == 'ADD'"
              @click="getAgentData()"
              >Pesquisar dados do Agente</v-btn
            >
          </v-col>

          <v-col class="text-center">
            <v-btn
              raised
              color="success"
              type="submit"
              v-if="mode == 'EDIT' || mode == 'ADD'"
              @click="saveBilling()"
              >Salvar</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

import debounce from "../../../util/debounce.js";

export default {
  name: "BillingsForm",
  computed: {
    ...mapState("billings", [
      "mode",
      "selectedItem",
      "formDialog",
      "agents",
      "possible_ratings",
      "possible_risks",
    ]),
    ...mapFields("billings", ["filter.id_agent,"]),
    ...mapFields("billings", [
      "selectedItem.id",
      "selectedItem.id_agent",
      "selectedItem.id_current_annotated",
      "selectedItem.id_last_annotated",
      "selectedItem.ag_group_id",
      "selectedItem.debt",
      "selectedItem.date_created",
      "selectedItem.observation",
      "selectedItem.pending_payment",
      "selectedItem.rating",
      "selectedItem.responsible",
      "selectedItem.responsible_contact",
      "selectedItem.risk",
    ]),
  },
  mixins: [debounce],
  mounted() {
    this.getAgents = this.debounce(this.getAgents, 500);
  },
  methods: {
    ...mapMutations("billings", ["setDialog", "setSelectedItem"]),
    ...mapActions("billings", ["save", "getAgents", "getAgentData"]),

    saveBilling() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.save();
        }
      });
    }
  },
  data: () => ({
    valid: false,
    searchAgent: "",
  }),
  watch: {
    searchAgent(val) {
      var payload = {
        search: val,
      };
      this.getAgents(payload);
    },
  },
};
</script>
