<template>
  <div>
    <div @drop.prevent="addDropFile" @dragover.prevent>
      <v-card>
        <v-card-title>
          <span class="headline">Agente</span>

          <v-spacer></v-spacer>
          <v-progress-circular
            v-if="loading"
            indeterminate
            class="text-right"
            color="primary"
            size="24"
          ></v-progress-circular>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm6 md6>
                  <v-autocomplete
                    label="Clube *"
                    :items="slots"
                    v-model="id_instance"
                    :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                    :error-messages="errors.collect('id_instance')"
                    v-validate="'required:Clube,id_instance'"
                    item-text="name"
                    item-value="id"
                    data-vv-name="id_instance"
                    outlined
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="ID Suprema"
                    v-model="id_pppoker"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('id_pppoker')"
                    data-vv-name="id_pppoker"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Nick *"
                    v-model="nick"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('nick')"
                    v-validate="'required:Nick,nick'"
                    data-vv-name="nick"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Nome"
                    v-model="name"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('name')"
                    data-vv-name="name"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-select
                    label="Tipo de Acerto *"
                    :items="types_deal"
                    v-model="type_deal"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('type_deal')"
                    v-validate="'required:Tipo de Acerto,type_deal'"
                    item-text="description"
                    item-value="type"
                    data-vv-name="type_deal"
                    outlined
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-select
                    label="Tipo de Fechamento *"
                    :items="types_closure"
                    v-model="type_closure"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('type_closure')"
                    v-validate="'required:Tipo de Fechamento,type_closure'"
                    item-text="description"
                    item-value="type"
                    data-vv-name="type_closure"
                    outlined
                  ></v-select>
                </v-flex>
                <v-flex xs6 sm6 md6>
                  <v-autocomplete
                      clearable
                      deletable-chips
                      v-model="selectedContact"
                      :readonly="this.mode != 'EDIT' && this.mode != 'ADD'"
                      :no-filter="true"
                      :items="contacts"
                      outlined
                      chips
                      small-chips
                      no-data-text="Nenhum contato encontrado"
                      :item-text="getTextContact"
                      :return-object="true"
                      placeholder="Digite aqui o nome do contato ou grupo"
                      label="Grupo do Agente"
                      :search-input.sync="searchContact"
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Limite *"
                    v-model="limit_annotated"
                    :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                    :error-messages="errors.collect('limit_annotated')"
                    v-validate="'required:Limite,limit_annotated|decimal:2'"
                    data-vv-name="limit_annotated"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-select
                    label="Tipo de Limite *"
                    :items="limits_type"
                    v-model="limit_type"
                    :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                    :error-messages="errors.collect('limit_type')"
                    v-validate="'required:Tipo de Limite,limit_type'"
                    item-text="description"
                    item-value="id"
                    data-vv-name="limit_type"
                    outlined
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-select
                    label="Tipo de Fichas *"
                    :items="types_chips"
                    v-model="type_chips"
                    :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                    :error-messages="errors.collect('type_chips')"
                    v-validate="'required:Tipo de Fichas,type_chips'"
                    item-text="description"
                    item-value="id"
                    data-vv-name="type_chips"
                    outlined
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-select
                    label="Envio de Fichas Para Jogadores *"
                    :items="types_send"
                    v-model="type_send"
                    :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                    :error-messages="errors.collect('type_send')"
                    v-validate="'required:Envio de Fichas Para Jogadores,type_send'"
                    item-text="description"
                    item-value="id"
                    data-vv-name="type_send"
                    outlined
                  ></v-select>
                </v-flex>
                <v-flex xs6 sm6 md6>
                    <v-autocomplete
                      label="Contato do Responsável"
                      placeholder="(Digite o contato do responsável)"
                      outlined
                      deletable-chips
                      chips
                      small-chips
                      :no-filter="true"
                      :items="groups_contacts"
                      :search-input.sync="searchInput"
                      :item-text="getTextGroup"
                      v-model="selectedResponsibleContact"
                      data-vv-name="ag_group_id"
                      no-data-text="Nenhum contato encontrado"
                    ></v-autocomplete>
                  </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-select
                    label="Situação "
                    :items="situations"
                    v-model="situation"
                    :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                    :error-messages="errors.collect('situation')"
                    v-validate="'required:Situação,situation'"
                    item-text="description"
                    item-value="id"
                    data-vv-name="situation"
                    outlined
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-checkbox :readonly="this.mode == 'VIEW'" :disabled="this.mode == 'VIEW'" v-model="is_club" class="mx-2" label="SubClube"></v-checkbox>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-col>
            <v-btn text @click="closeDialog">{{ mode == 'VIEW' ? 'Voltar' : 'Cancelar'}}</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn text type="submit" right @click="saveAgent">Salvar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import debounce from '../../../util/debounce.js' 

export default {
  name: "AgentsForm",
  computed: {
    ...mapState("slots", ["slots"]),
    ...mapState("agents", ["contacts", "item", "instances", "loading", "master_agents", "mode", "groups_contacts"]),
    ...mapFields("agents", [
      "editedItem.ag_group_id",
      "editedItem.ag_group_name",
      "editedItem.id_instance",
      "editedItem.email",
      "editedItem.id_pppoker",
      "editedItem.id_supreme",
      "editedItem.is_club",
      "editedItem.is_master_agent",
      "editedItem.id_master_agent",
      "editedItem.limit_annotated",
      "editedItem.nick",
      "editedItem.name",
      "editedItem.rake_deal",
      "editedItem.type_deal",
      "editedItem.type_closure",
      "editedItem.type_chips",
      "editedItem.type_send",
      "editedItem.phone",
      "editedItem.limit_type",
      "editedItem.responsible",
      "editedItem.responsible_contact",
      "editedItem.situation",
      "selectedContact",
      "selectedContactWithdrawal",
      "selectedResponsibleContact",
      "editedItem.withdrawal_contact"
    ])
  },
  created() {
    this.$validator.extend("required", {
      getMessage: (field, [label]) => label + " deve ser informado",
      validate: (_, [, field]) => {
        if (this[field] || this[field] === 0) {
          return true;
        } else {
          return false;
        }
      },
    });
  },
  mixins: [debounce],
  mounted() {
    this.getContacts = this.debounce(this.getContacts, 500)
    this.getGroupsList = this.debounce(this.getGroupsList, 500);
  },
  methods: {
    ...mapActions("agents", ["closeDialog", "getContacts", "getGroupsList", "getInstances", "save"]),
    getRakesDeal(type) {
      let deals_type = ["Simples", "Variável"];
      if (deals_type[type]) {
        return deals_type[type];
      }
      return "";
    },
    getTextGroup(item) {
      if (item) {
        if (item.ag_group_id) {
          return item.ag_group_id + " | " + item.nick;
        }
      }
      return "";
    },
    getTextContact(item) {
      if(item.name) {
        if(item.name.length == 1) {
          return item.name
        }
        
        var text = ""
        item.name.forEach((name) => {
          text += name + " | "
        })
        return text
      }
      else {
        return text
      }
    },
    getTextAgent(item) {
      if(item) {
        if(item.id_pppoker) {
          return item.id_pppoker + " | " + item.nick + " | " + item.club
        } else {
          return item.nick
        }
      }
      return ''
    },
    saveAgent() {
      this.$validator.validateAll().then(valid => {
        if (valid) {
          if(this.is_master_agent === true) {
            this.is_master_agent = 1
          } else if(this.is_master_agent === false) {
            this.is_master_agent = 0
          }
          if(!this.id_master_agent) {
            this.id_master_agent = null
          }
          if(this.selectedContact) {
            if(this.selectedContact._id != "" && this.selectedContact._id != undefined) {
              this.ag_group_id = this.selectedContact["_id"]
              this.ag_group_name = this.selectedContact["name"][0]
            }
          }
          console.log(this.selectedResponsibleContact.length)
          if(this.selectedContactWithdrawal) {
            if(this.selectedContactWithdrawal._id != "" && this.selectedContactWithdrawal._id != undefined) {
              this.withdrawal_contact = this.selectedContactWithdrawal["_id"]
            }
          }
          // if(this.selectedResponsibleContact && this.selectedResponsibleContact._id) {
          //   this.selectedResponsibleContact = this.selectedResponsibleContact.split(" | ")
          //   this.selectedResponsibleContact = Object.assign({}, this.selectedResponsibleContact)
          // }
          this.limit_annotated = parseFloat(this.limit_annotated)
          this.save();
        }
      });
    }
  },
  data: () => ({
    types_deal: [
      { type: 0, description: "Ganho/Perda" },
      { type: 1, description: "Giro" },
      { type: 2, description: "Normal" },
      { type: 3, description: "Fichas Enviadas/Retiradas" },
      { type: 4, description: "Sem Acerto" },
      { type: 5, description: "Sem Acerto/GP" },
      
    ],
    types_closure: [
      { type: 0, description: "Automático" },
      { type: 1, description: "Manual" },
    ],
    rakes_deal: [
      { type: 0, description: "Simples" },
      { type: 1, description: "Variável" }
    ],
    types_chips: [
      { id: 0, description: "Paga"},
      { id: 1, description: "Não Paga"},
      { id: 2, description: "Diferença dos Anotados"}
    ],
    limits_type: [
      { id: 0, description: "Anotados"},
      { id: 1, description: "Anotados e Adt. Rake"},
    ],
    types_send: [
      { id: 0, description: "Automática"},
      { id: 1, description: "Manual"},
    ],
    situations: [
      {
        id: 0,
        description: "Inadimplente - 2022"
      }, 
      {
        id: 1,
        description: "Em Acordo"
      },
      {
        id: 2,
        description: "Rotativo Semanal"
      },
      {
        id: 3,
        description: "Ativo"
      },
      { 
        id: 4, 
        description: "Não Definido"
      },
      { 
        id: 5,
        description: "Inadimplente - 2021"
      }
    ],
    rules: {
      required: value => !!value || "Required.",
      counter: value => value.length <= 20 || "Max 20 characters"
    },
    searchAgent: '',
    searchContact: '',
    searchContactWithdrawal: '',
    searchInput: '',
    valid: false
  }),
  watch: {
    searchInput(val) {
      var payload = {
        search: val,
        groups_contacts: this.ag_group_id
      };
      this.getGroupsList(payload);
    },
    searchContact: {
      handler () {
        this.getContacts({search: this.searchContact})
      },
    },
    searchContactWithdrawal: {
      handler () {
        this.getContacts({search: this.searchContactWithdrawal})
      },
    }
  }
};
</script>
