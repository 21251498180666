import axios from "axios";
import { getField, updateField } from "vuex-map-fields";
import endPoints from "../../../config/endPoints";
import formatDate from '../../../util/formatDate.js'
import {
  defaultErrorNotification,
  defaultSuccessNotification,
} from "../../../constants";

const defaultFilter = {
  id: "",
  contact_id: "",
  contact_name: "",
  status: "",
  id_transaction_bank: "",
  bank: "",
  bank_name: "",
  date_payment: "",
  date_created: "",
  value: "",

};

const defaultPaymentAnnotated = {
  id: null,
  contact_id: null,
  contact_name: "",
  status: null,
  id_transaction_bank: null,
  bank: null,
  bank_name: "",
  date_payment: "",
  date_created: "",
  value: 0,

};

export default {
  namespaced: true,
  state: {
    items: [],
    selectedItem: {},
    slots: [],
    loading: false,
    totalItems: 0,
    filterDialog: false,
    showDialog: false,
    filter: {
      id: "",
      bank: "",
      contact_id: "",
      contact_name: "",
      status: "",
      id_transaction_bank: "",
      bank_name: "",
      date_payment: "",
      date_created: "",
      value: "",

    },
    notification: {
      show: false,
      message: "",
      type: "",
    },
    pagination: {
      page: 1,
      itemsPerPage: 10,
    },
    possible_status: [
      { id: 0, description: 'Pendente' },
      { id: 1, description: 'Pago' },
      { id: 2, description: 'Expirado' },
    ],
  },
  mutations: {
    view: (state, payload) => {
      state.selectedItem = payload;
      state.selectedItem.date_created = new Date(payload.date_created);
      if (payload.date_payment) {
        state.selectedItem.date_payment = new Date(payload.date_payment);
      }
      state.showDialog = true;
    },
    clearFilter: (state) => {
      state.filter = Object.assign({}, defaultFilter);
    },

    setItems: (state, payload) => (state.items = payload),
    setTotalItems: (state, payload) => (state.totalItems = payload),
    setLoading: (state, payload) => (state.loading = payload),
    showNotification: (state, payload) => (state.notification = payload),
    setSelectedItem: (state, payload) => (state.selectedItem = payload),
    setShowDialog: (state, payload) => {
      state.showDialog = payload;
      state.selectedItem = Object.assign({}, defaultPaymentAnnotated);
    },
    setShowFilter: (state, payload) => (state.filterDialog = payload),
    updateField,
  },
  actions: {
    getItems: async ({ commit, state }) => {
      let filter = state.filter;
      let url = `${endPoints.payments_annotateds}?page=${state.pagination.page}&itemsPerPage=${state.pagination.itemsPerPage}`;
      Object.keys(filter).forEach(function (field) {
        if (
          filter[field] !== "" &&
          filter[field] !== undefined &&
          filter[field] !== null
        ) {
          if (field == "end" || field == "start") {
            var e = new Date(filter[field])
            url += "&" + field + "=" + formatDate(e) + " " + e.toLocaleTimeString()
          } else {
            url += "&" + field + "=" + filter[field]
          }
        }
      });

      if (
        state.pagination &&
        state.pagination.sortBy &&
        state.pagination.sortBy.length > 0
      ) {
        url +=
          "&sort_by=" +
          (state.pagination.sortBy[0] === "name"
            ? "id"
            : state.pagination.sortBy[0]);
        url += "&sort_desc=" + state.pagination.sortDesc[0];
      }

      commit("setLoading", true);

      try {
        let items = await axios.get(url);
        commit("setItems", items.data.data);
        commit("setTotalItems", items.data.total);
      } catch (error) {
        commit(
          "showNotification",
          defaultErrorNotification(error?.response?.data?.message)
        );
      }
      commit("setLoading", false);
    },
    checkStatus: async ({ commit, dispatch }, payload) => {
      let url = endPoints.payments_annotateds + "/check_status/" + payload.id_transaction_bank;
      try {
        await axios.post(url, payload).then((response) => {
          console.log(response.data)
          commit(
            "showNotification",
            defaultSuccessNotification(response.data)
          );
          dispatch("getItems");
        });
        dispatch("getItems");
      } catch (error) {
        commit(
          "showNotification",
          defaultErrorNotification(error?.response?.data?.message)
        );
      }
    },
    closeDialog: (({ commit, state }) => {
      state.selectedItem = Object.assign({}, defaultPaymentAnnotated);
      commit('setShowDialog', false)
    }),

  },
  getters: {
    getField,
  },
};
