<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    
    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2000"
      v-model="showNotification"
    >
        {{ notificationMessage }}
    </v-snackbar>
    <v-toolbar flat class="elevation-5">
      <div>
        <v-toolbar-title>Rotas</v-toolbar-title>
      </div>
      <v-spacer></v-spacer>
      <div class="button-toolbar">
        <v-btn color="black" dark class="ma-2" @click="showFilter({show: true})" small><v-icon>mdi-filter</v-icon></v-btn>
        <v-btn color="black" dark class="ma-2" @click="getRoutes" small><v-icon>mdi-refresh</v-icon></v-btn>
        <v-btn color="black" dark class="ma-2" @click="add" small><v-icon>mdi-plus</v-icon></v-btn>
      </div> 
    </v-toolbar>


    <v-data-table
      :headers="headers"
      :loading="loading"
      :items="routes"
      :options.sync="pagination"
      :server-items-length="totalRoutes"
      no-data-text="Nenhuma rota encontrada"
      class="elevation-10 table-margin"
    >
      <template v-slot:[`item.actions`]="{ item }">

        <v-tooltip bottom>
          <template v-slot:activator="{on, attrs}">
            <v-icon small v-on="on" v-bind="attrs" class="mr-2" @click="viewRoute(item)">mdi-information</v-icon>
          </template>
          <span>Informações</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{on, attrs}">        
            <v-icon small v-on="on" v-bind="attrs" class="mr-2" @click="edit({item: item})">mdi-pencil</v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>

        <v-tooltip bottom> 
          <template v-slot:activator="{on, attrs}">    
            <v-icon small v-on="on" v-bind="attrs" class="mr-2" @click="showDeleteDialog(item)">mdi-delete</v-icon>
          </template>
          <span>Apagar</span>
        </v-tooltip>

      </template>

      <template v-slot:[`item.type`]="{ item }">
        <v-chip
          :color="getColor(item)"
          dark
        >
          {{ getType( item.type ) }}
        </v-chip>
      </template>
      
    </v-data-table>
    
    <v-dialog :value="dialog" width="800" @click:outside="closeDialog">
      <route-form></route-form>
    </v-dialog>

    <v-dialog :value="dialogFilter" width="600" @click:outside="showFilter({show: false})">
      <route-filter></route-filter>
    </v-dialog>

    <v-dialog :value="dialogDelete" persistent width="500">
      <v-card>
        <v-card-title class="headline">Ao apagar esta rota, irá excluir suas relações com os perfis de acesso. Confirmar a exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn
              text
              @click="closeDeleteDialog()"
            >Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn
              text
              type="submit"
              right
              @click="deleteRoute"
            >Confirmar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogReceipt"
      @click:outside="setDialogReceipt({show: false})"
    >
      <v-card height="90vh">
        <v-img max-height="100%" :src="receipt_show" contain></v-img>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import { mapFields } from 'vuex-map-fields'
  import RoutesForm from './RoutesForm'
  import RoutesFilter from './RoutesFilter'

  export default {
    name: 'Routes',
    route_code: 'Routes',
    computed: {
      ...mapState('login', ['userData']),
      ...mapState('routes', ['contact_show', 'dialog', 'dialogDelete', 'dialogReceipt', 
            'route','instances', 'errors', 'users', 'loading', 'mode', 'notificationType', 'notificationMessage', 
            'rowsPerPage', 'receipt_show', 'totalRoutes', 'routes', 'agents']),
      ...mapFields('routes', [
          "dialogReceipt",
          'id_agent',
          'pagination',
          'dialogFilter',
          'overlay',
          'showNotification'
          // ...
        ])
    },
    created() {
      this.getRoutes({resetPage: false})
      this.setScreen({screen: 'routes'})
    },
    components: {
      "route-form": RoutesForm,
      "route-filter": RoutesFilter
    },
    methods: {
      ...mapMutations('routes', ['add', 'edit', 'viewRoute', 'showFilter', 'setDialogReceipt', "setScreen"]),
      ...mapActions('routes', ['closeDialog', 'closeDeleteDialog', 'deleteRoute', 'getUsers', 'getRoutes', 'getRoutesNoPage', 
                    'showDeleteDialog','showReceipt', 'startWithdrawal', 'stopWithdrawal', 'getAgents' ]),
      isMobile() {
        if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
          return true;
        }
        return false
      },
      getType(type) {
        return this.types[type]
      },
      getColor(item) {
        if(item.type == 0) {
          return 'blue'
        } else if(item.type == 1) {
          return 'orange'
        } else if(item.type == 2) {
          return 'green'
        } else if(item.type == 3) {
          return 'gray'
        } else if(item.type == 4) {
          return 'red'
        }
      }
    },
    data: () => ({
      headers: [
        { text: "Nome",  value: "name", sortable: false, align: "left"},
        { text: "Tipo",  value: "type", sortable: false, align: "center" },
        { text: "Tela",  value: "menu_string", sortable: false, align: "center"},
        { text: "Ações",  value: "actions", sortable: false, align: "right" }
      ],
      types: [
        'GET',
        'PUT',
        'POST',
        'PATCH',
        'DELETE'
      ],
      sheet_fields: {
        Nome: {
          field: 'name',
        },
        Tipo: {
          field: 'type',
          callback: (value) => {
            if(value) {
              let type = [
                'GET',
                'PUT',
                'POST',
                'PATCH',
                'DELETE',
              ]
              return type[value]
            }
            return ''
          }
        },
      },
    }),
    watch: {
      pagination: {
        handler () {
          this.getRoutes({resetPage: false})
        },
      },
    },
  }
</script>

<style>
</style>