<template>
  <div>
    <div>
      <v-card>
        <v-card-title>
          <span class="headline text-center justify-center">Faixa de Rakeback</span>

          <v-spacer></v-spacer>
          <v-progress-circular
            v-if="loading_rake_deals"
            indeterminate
            class="text-right"
            color="primary"
            size="24"
          ></v-progress-circular>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Valor Inicial *"
                    v-model="start_value"
                    :readonly="true"
                    :error-messages="errors.collect('start_value')"
                    v-validate="'required:Valor Inicial,start_value|decimal:2'"
                    data-vv-name="start_value"
                    outlined
                    :disabled="this.mode == 'VIEW' || this.mode == 'FINISH'"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Valor Final *"
                    v-model="finish_value"
                    :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                    :error-messages="errors.collect('finish_value')"
                    v-validate="'required:Valor,finish_value|decimal:2'"
                    data-vv-name="finish_value"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Porcentagem *"
                    v-model="percentage"
                    :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                    :error-messages="errors.collect('percentage')"
                    v-validate="'required:Porcentagem,percentage|min_value:0|max_value:100'"
                    data-vv-name="percentage"
                    append-icon="mdi-percent-outline"
                    outlined
                    type="number"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                    <v-select
                      label="Tipo de Faixa"
                      :items="possible_rake_deal_types"
                      v-model="type_rake_deal"
                      :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                      item-value="id"
                      item-text="description"
                      outlined
                    ></v-select>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-col>
            <v-btn text @click="closeDialog">{{ mode == 'VIEW' ? 'Voltar' : 'Cancelar'}}</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn text type="submit" right @click="saveRakeDeal">Salvar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
    name: "RakeDealForm",
    computed: {
        ...mapState("rake_deals", ["loading_rake_deals", "mode"]),
        ...mapFields("rake_deals", [
            "editedItem.start_value",
            "editedItem.finish_value",
            "editedItem.percentage",
            "editedItem.id_agent",
            "editedItem.type_rake_deal",
        ])
    },
    created() {
        this.$validator.extend("required", {
        getMessage: (field, [label]) => label + " deve ser informado",
        validate: (_, [, field]) => {
            if (this[field] || this[field] === 0) {
            return true;
            } else {
            return false;
            }
        },
        });
    },
    methods: {
        ...mapActions("rake_deals", ["closeDialog", "add", "save"]),
        saveRakeDeal() {
            this.$validator.validateAll().then(valid => {
                if (valid) {
                  this.start_value = parseFloat(this.start_value)
                  this.finish_value = parseFloat(this.finish_value)
                  this.percentage = parseFloat(this.percentage)
                  this.save();
                }
            });
        }
    },
    data: () => ({
        valid: false,
        possible_rake_deal_types: [
          { id: 0, description: "Geral" },
          { id: 1, description: "Ganhos" },
          { id: 2, description: "Perdas" },
        ]
    })
};
</script>
