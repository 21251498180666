import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../../config/endPoints';
import { defaultErrorNotification, defaultSuccessNotification } from "@/constants";
import { AGENT_FOLDER_CLIENT, AGENT_FOLDER_USER, AGENT_FOLDER_AGENT } from '@/constants/agentFolder'

const defaultUserOrAgent = {
    folder_id: '',
    user_id: '',
    client_id: '',
    agent_id: '',
    percentage: '',
}

// const defaultFilterGeneric = {
//     name_filter: '',
// }

export default {
    namespaced: true,
    state: {
        folder: {
            id: ''
        },
        showDialog: false,
        showDeleteDialog: false,
        selectedItem: {},
        loadingUsers: false,
        loadingAgents: false,
        loadingClients: false,
        mode: '',
        agents: [],
        users: [],
        clients: [],
        findAgents: [],
        findUsers: [],
        findClients: [],
        filter_agents: {
            nick: '',
        },
        filter_users: {
            name: '',
        },
        filter_clients: {
            name: '',
        },
        filterGeneric: {
            name_filter: ''
        },
        notification: {
            show: false,
            message: "",
            type: "",
        },
        filterDialog: false,
        filterType: '',
        itemToDelete: null,
    },
    mutations: {
        addAgent: (state) => {
            state.selectedItem = Object.assign({}, defaultUserOrAgent);
            state.showDialog = true;
            state.mode = "ADD_AGENT";
        },
        addUser: (state) => {
            state.selectedItem = Object.assign({}, defaultUserOrAgent);
            state.showDialog = true;
            state.mode = "ADD_USER";
        },
        addClient: (state) => {
            state.selectedItem = Object.assign({}, defaultUserOrAgent);
            state.showDialog = true;
            state.mode = "ADD_CLIENT";
        },
        prepareItemToDelete: (state, payload) => {
            if (payload.agent_id){
                state.itemToDelete = AGENT_FOLDER_AGENT
            } else if(payload.user_id){
                state.itemToDelete = AGENT_FOLDER_USER
            } else {
                state.itemToDelete = AGENT_FOLDER_CLIENT
            }
            state.selectedItem = payload;
            state.showDeleteDialog = true;
        },
        closeDeleteDialog: (state) => {
            state.selectedItem = Object.assign({}, defaultUserOrAgent);
            state.showDeleteDialog = false;
        },
        setFolder: (state, payload) => state.folder = payload,
        setAgents: (state, payload) => state.agents = payload,
        setUsers: (state, payload) => state.users = payload,
        setClients: (state, payload) => state.clients = payload,
        setFindAgents: (state, payload) => state.findAgents = payload,
        setFindUsers: (state, payload) => state.findUsers = payload,
        setFindClients: (state, payload) => state.findClients = payload,
        setLoadingUsers: (state, payload) => state.loadingUsers = payload,
        setLoadingClients: (state, payload) => state.loadingClients = payload,
        setLoadingAgents: (state, payload) => state.loadingAgents = payload,
        setSelectedItem: (state, payload) => state.selectedItem = payload,
        setShowDeleteDialog: (state, payload) => state.showDeleteDialog = payload,
        setShowDialog: (state, payload) => state.showDialog = payload,
        showFilterDialog: (state, payload) => {
            if (payload.show != false) {
                state.filterDialog = true
                state.filterType = payload.filterType
            } else {
                state.filterDialog = false
                state.filterType = ''
                state.filterGeneric = {
                    name_filter: ''
                }           
            }
        }, 
        showNotification: (state, payload) => state.notification = payload,
        updateField
    },
    getters: {
        getField,
    },
    actions: {
        goBack: ({ dispatch }) => {
            dispatch('menu/openLink', '/agents_folders', { root: true })
        },
        getFolder: async ({ commit }, payload) => {
            let url = `${endPoints.agents_folders}/${payload}`;
            try {
                let items = await axios.get(url);
                commit("setFolder", items.data);
            } catch (error) {
                commit("showNotification", defaultErrorNotification(error?.response?.data?.message));
            }
        },
        getUsers: async ({ commit }, payload) => {
            let url = `${endPoints.agents_folders}/${payload}/users`;
        
            commit("setLoadingUsers", true);
        
            try {
                let items = await axios.get(url);
                commit("setUsers", items.data);
            } catch (error) {
                commit("showNotification", defaultErrorNotification(error?.response?.data?.message));
            }
            commit("setLoadingUsers", false);
        },

        getClients: async ({ commit }, payload) => {
            let url = `${endPoints.agents_folders}/${payload}/clients`;

            commit("setLoadingClients", true);
            try {
                let items = await axios.get(url);
                commit("setClients", items.data);
            } catch (error) {
                commit("showNotification", defaultErrorNotification(error?.response?.data?.message));
            }
            commit("setLoadingClients", false);
        },

        getAgents: async ({ commit }, payload) => {
            let url = `${endPoints.agents_folders}/${payload}/agents`;
        
            commit("setLoadingAgents", true);
            try {
                let items = await axios.get(url);
                commit("setAgents", items.data);
            } catch (error) {
                commit("showNotification", defaultErrorNotification(error?.response?.data?.message));
            }
            commit("setLoadingAgents", false);
        },
        deleteItem: async ({ commit, dispatch, state }) => {
            let url = `${endPoints.agents_folders}/${state.folder.id}/`
            if (state.itemToDelete == AGENT_FOLDER_USER){
                url += `users?user_id=${state.selectedItem.user_id}`
            } else if (state.itemToDelete == AGENT_FOLDER_AGENT) {
                url += `agents?agent_id=${state.selectedItem.agent_id}`
            } else {
                url += `clients?client_id=${state.selectedItem.client_id}`
            }
            try {
              await axios.delete(url);
      
              commit("setShowDeleteDialog", false);
              commit("setSelectedItem", {});
              commit("showNotification", defaultSuccessNotification());
                
              if (state.itemToDelete == AGENT_FOLDER_USER){
                dispatch("getUsers", state.folder.id)
              } else if (state.itemToDelete == AGENT_FOLDER_AGENT) {
                dispatch("getAgents", state.folder.id)
              } else {
                dispatch("getClients", state.folder.id)
              }
            } catch (error) {
              commit("showNotification", defaultErrorNotification(error?.response?.data?.message));
            }
        },
        save: async ({ commit, state, dispatch }) => {
            let user_id = state.selectedItem.user_id
            let url = `${endPoints.agents_folders}/${state.folder.id}/`;
            if (state.mode == "ADD_USER"){
                url += `users?user_id=${user_id}&percentage=${state.selectedItem.percentage}`
            } else if (state.mode == "ADD_AGENT") {
                url += `agents?agent_id=${state.selectedItem.agent_id}`
            } else if(state.mode == "ADD_CLIENT"){
                url += `clients?client_id=${state.selectedItem.client_id}`
            }
            console.log(url)
            let func = axios.post
            try {
      
              await func(url, state.selectedItem);
              commit("showNotification", defaultSuccessNotification());
              commit("setShowDialog", false);
              if (state.mode == "ADD_USER"){
                dispatch("getUsers", state.folder.id)
              } else if (state.mode == "ADD_AGENT") {
                dispatch("getAgents", state.folder.id)
              } else if (state.mode == "ADD_CLIENT"){
                dispatch("getClients", state.folder.id)
              }
            } catch (error) {
              commit("showNotification", defaultErrorNotification(error?.response?.data?.message));
            }
            state.selectedItem = Object.assign({}, defaultUserOrAgent);
        },
        getFindAgents({ commit, state }) {
            commit('setLoadingAgents', true)
            let url = endPoints.agents;
            let filter = state.filter_agents;
            url += '?page=' + 1 + '&' + 'itemsPerPage=' + 20 + '&find=1';

            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined) {
                    url += "&" + field + "=" + filter[field]
                }
            });
            console.log(url)
            axios.get(url).then(items => {
                commit('setLoadingAgents', false)
                commit('setFindAgents', items.data.data)
            });
        },
        getFindUsers({ commit, state }) {
            commit('setLoadingUsers', true)
            let url = endPoints.users;
            let filter = state.filter_users;
            url += '?page=' + 1 + '&' + 'itemsPerPage=' + 20 + '&find=1';

            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined) {
                    url += "&" + field + "=" + filter[field]
                }
            });

            axios.get(url).then(items => {
                commit('setLoadingUsers', false)
                commit('setFindUsers', items.data.data)
            });
        },
        getFindClients({ commit, state }) {
            commit('setLoadingClients', true)
            let url = endPoints.clients;
            let filter = state.filter_clients;
            url += '?page=' + 1 + '&' + 'itemsPerPage=' + 20 + '&find=1';
            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined) {
                    url += "&" + field + "=" + filter[field]
                }
            });

            axios.get(url).then(items => {
                commit('setLoadingClients', false)
                if (items.data.data.length!=0){
                    commit('setFindClients', items.data.data)
                }
            });
        },
        getFilterGeneric({ state, commit }, payload) {
            if (state.filterGeneric.name_filter.length < 1) {
                let notification = {
                    show: true,
                    message: "Filtro inválido",
                    type: "error"
                }
                commit('showNotification', notification)
            } else {
                let url = '' 
                if (state.filterType == 'FILTER_AGENT') {
                    url = `${endPoints.agents_folders}/${payload.folder_id}/agents?&agent=${state.filterGeneric.name_filter}`;
                } else {
                    url = `${endPoints.agents_folders}/${payload.folder_id}/users?&user=${state.filterGeneric.name_filter}`;
                }
                try {
                    axios.get(url).then(
                        (items) => {
                            commit("setAgents", items.data);
                            commit("showFilterDialog", {show: false})
                        },
                        error => {
                            let notification = {
                                show: true,
                                message: error.response.data.message,
                                type: "error"
                            }
                            commit('showNotification', notification)
                        }
                    )
                } catch (error) {
                    let notification = {
                        show: true,
                        message: "Erro ao tentar filtrar",
                        type: "error"
                    }
                    commit('showNotification', notification)
                }
            }
        }
    }
}