<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2000"
      v-model="showNotification"
    >
      {{ notificationMessage }}
    </v-snackbar>

    <v-toolbar flat class="elevation-5">
      <v-toolbar-title>Gestores</v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- <download-excel
        :before-generate="generateSheet"
        :before-finish="finishSheet"
        :name="getSheetName()"
        :fetch="getItemsNoPage"
        :fields="sheet_fields"
        class="excel"
        worksheet = "Gestores"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn  v-bind="attrs" v-on="on"  color="black" dark class="ma-2" small>
              <v-icon>mdi-file-excel</v-icon>
            </v-btn> 
          </template>
          <span>Gerar Planilha</span>
        </v-tooltip>
      </download-excel>   -->
      <v-tooltip bottom>
        <template v-slot:activator="{on, attrs}">
          <v-btn color="black" dark class="ma-2" @click="downloadXlsx({ show: true })" v-bind="attrs" v-on="on" small>
            <v-icon>mdi-file-excel</v-icon>
          </v-btn>
        </template>
        <span>Gerar Planilha</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="black" dark class="ma-2" @click="showFilter({show: true})" small>
              <v-icon>mdi-filter</v-icon>
            </v-btn>
        </template>
        <span>Filtrar</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="black" dark class="ma-2" @click="getItems({resetPage: false})" small>
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
        </template>
        <span>Recarregar</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
           <v-btn v-bind="attrs" v-on="on" color="black" dark class="ma-2" @click="add" small>
             <v-icon>mdi-plus</v-icon
            ></v-btn>
        </template>
        <span>Adicionar</span>
      </v-tooltip>
      
     
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :loading="loading"
      :items="items"
      :options.sync="pagination"
      :server-items-length="totalItems"
      :expanded.sync="expanded"
      no-data-text="Nenhum gestor encontrado"
      class="elevation-10 table-margin"
    >
      <template v-slot:[`item.type_manager`]="{ item }">
        <span>{{ getTypeManager(item.type_manager)}}</span>
      </template>
      <template v-slot:[`item.transactions_charged`]="{ item }">
        <span>{{ item.transactions_charged ? "Cobrar" : "Não cobrar"}}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon  v-bind="attrs" v-on="on" small class="mr-2" @click="viewItem({item: item})">mdi-information</v-icon>
          </template>
          <span>Mais Informações</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon  v-bind="attrs" v-on="on" small class="mr-2" @click="edit({item: item})">mdi-pencil</v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
           <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="showDeleteDialog(item)">mdi-delete</v-icon>
          </template>
          <span>Excluir</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog :value="dialog" @click:outside="closeDialog" width="600">
      <managers-form></managers-form>
    </v-dialog>

    <v-dialog :value="dialogFilter" @click:outside="showFilter({show: false})" width="600" @keydown.enter="filter">
      <managers-filter></managers-filter>
    </v-dialog>

    <v-dialog :value="dialogDelete" @click:outside="closeDeleteDialog" width="300">
      <v-card>
        <v-card-title class="headline">Confirmar exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn
              text
              @click="closeDeleteDialog()"
            >Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn
              text
              type="submit"
              right
              @click="deleteItem"
            >Confirmar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
  import { mapActions, mapMutations, mapState } from 'vuex'
  import { mapFields } from 'vuex-map-fields'
  import ManagersForm from './ManagersForm'
  import ManagersFilter from './ManagersFilter'
  // import JsonExcel from 'vue-json-excel'
  import { utils, writeFile } from "xlsx";

  export default {
    name: 'Managers',
    components: {
      "managers-form": ManagersForm,
      "managers-filter": ManagersFilter,
      // "download-excel": JsonExcel,
    },
    created() {
      this.getItems({resetPage: false})
      this.getSlots({ resetPage: false, noPages: true, onlyIfNotSearched: true });
    },
    computed: {
      ...mapState('managers', ['dialog', 'dialogAnnotated', 'dialogDelete', 'item', 'loading', 'mode', 'notificationType', 'notificationMessage', 'rowsPerPage',  'totalItems', 'items']),
      ...mapFields('managers', [
        'dialogFilter',
        'pagination',
        'showNotification'
      ]),
      ...mapFields('chat', ['showContactDescription'])
    },
    data: () => ({
      expanded: [],
      headers: [
        { text: "Clube", value: "club_name" },
        { text: "Id Suprema", value: "id_pppoker" },
        {
          text: "Nick",
          align: "left",
          value: "nick"
        },
        { text: "Tipo", value: "type_manager", align: "center"}, 
        { text: "Cobrar Transações", value: "transactions_charged", align: "center"}, 
        { text: '', value: 'data-table-expand' },
        { text: "Ações",  value: "actions", sortable: false, align: "center" },
      ],
      overlay: false,
      /* sheet_fields: {
        Clube: {
          field: 'club_name',
        },
        'Id PPPoker': {
          field: 'id_pppoker',
        },
        Nick: {
          field: 'nick',
        },
        'Tipo': {
          field: 'type_manager',
          callback: (type) => {
            let types = ["Interno", "Externo"];
            if (types[type]) {
              return types[type];
            }
            return "";
          }
        }
      } */
    }),
    methods: {
      ...mapActions("slots", [
        "getSlots",
      ]),
      ...mapActions('managers', ['closeDialog', 'closeDeleteDialog', 'deleteItem', 'getItems', 'getItemsNoPage', 'showDeleteDialog', 'viewItem']),
      ...mapActions('instances', ['getInstances']),
      ...mapMutations('managers', ['add', 'edit', 'showFilter']),
      getTypeManager(type) {
        let types = ["Interno", "Externo"];
        if (types[type]) {
          return types[type];
        }
        return "";
      },
      filter() {
        this.getItems({resetPage: true});
        this.showFilter({ show: false });
      },
      finishSheet() {
        this.overlay = false
      },
      generateSheet() {
        this.overlay = true
      },
      getSheetName() {
        var date = new Date()
        var name = 'managers_' + date.toLocaleDateString() + " " + date.toLocaleTimeString()
        return name+'.xls'
      },
      async downloadXlsx() {
        this.generateSheet()
        try {
          let itemsNoPage = await this.getItemsNoPage();
          if(itemsNoPage) {
            const wb = utils.book_new();
            let possible_types = ['Interno', 'Externo']
            let items = itemsNoPage.map(row => ({
              "Clube":  row.club_name,
              "Id PPPoker":  row.id_pppoker,
              "Nick": row.nick,
              "Tipo": possible_types[row.type_manager],
            }))
            const ws = utils.json_to_sheet(items);
            utils.book_append_sheet(wb, ws, "Gestores");
            await writeFile(wb, this.getSheetName());
          }
        } catch (error) {
          this.finishSheet()
        } finally {
          this.finishSheet()
        }
      },
    },
    watch: {
      pagination: {
        handler () {
          this.getItems({resetPage: false})
        },
      },
    }
  }
</script>
