<template>
  <div>
    <v-overlay :value="ol">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2000"
      v-model="showNotification"
    >
        {{ notificationMessage }}
    </v-snackbar>
    <div @drop.prevent="addDropFile" @dragover.prevent>
      <v-card>
        <v-card-title>
          <span class="headline text-center justify-center">Publicar Status</span>
          <v-spacer></v-spacer>
          <v-progress-circular
            v-if="loading"
            indeterminate
            class="text-right"
            color="primary"
            size="24"
          ></v-progress-circular>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm12 md12>
                  <v-select
                    label="Tipo *"
                    :items="types"
                    v-model="type"
                    :error-messages="errors.collect('type')"
                    v-validate="'required:Tipo,type'"
                    item-text="description"
                    item-value="id"
                    data-vv-name="type"
                    outlined
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-textarea
                    auto-grow
                    outlined
                    v-if="type == 0"
                    label="Texto *"
                    id="textareamessage"
                    hide-details="true"
                    rows="1"
                    :error-messages="errors.collect('text')"
                    data-vv-name="text"
                    v-model="text"
                    :value="text"
                  ></v-textarea>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-card height="200px" v-if="image && type == 1">
                    <v-img max-height="100%" :src="typeof image == 'string' ? image : image.fileBase64" contain></v-img>
                  </v-card>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-file-input
                    v-model="image"
                    v-if="type == 1"
                    label="Imagem"
                    :error-messages="errors.collect('image')"
                    data-vv-name="image"
                    outlined
                  ></v-file-input>
                </v-flex>
                <v-flex xs12 sm12 md12>
                    <div v-for="instance in whatsappInstances" :key="instance.id_instance">
                        <v-checkbox hide-details class="shrink mr-2 mt-0" v-model="clubs" :label="instance.name" :value="instance.id_instance"></v-checkbox>
                    </div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-col class="text-right">
            <v-btn text type="submit" right @click="publishStatus">Publicar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import b64 from '../../../util/Base64'


export default {
    name: 'PublishStatus',
    computed: {
        ...mapState('instances', ['instances']),
        ...mapState('publish_status', ['loading', 'notificationMessage', 'notificationType']),
        ...mapFields('publish_status', [
            'status_publish.text',
            'status_publish.image',
            'status_publish.clubs',
            'status_publish.type',
            'showNotification',
            'ol'
        ]),
        whatsappInstances: function() {
            return this.instances.filter((i) => i.id != '')
        },
    },
    created() {
        this.getInstances()
    },
    methods: {
        ...mapActions("instances", ["getInstances"]),
        ...mapActions("publish_status", ["publishStatus"]),
        addDropFile(e) {
            if (e.dataTransfer) {
                if (e.dataTransfer.files[0]) {
                    let image = e.dataTransfer.files[0];
                    this.receipt_file = image
                    b64(image).then((fileBase64) => {
                        image.fileBase64 = fileBase64
                        this.image = image
                    })
                }
            }
        },
        isMobile() {
            if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
            return true;
            }
            return false
        },
    },
    data: () => ({
        types: [
            { id: 0, description: 'Texto' },
            { id: 1, description: 'Imagem' }
        ],
        valid: false
    })
}
</script>

<style>
    .inline-club {
        display: inline;
    }
</style>