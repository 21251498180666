<template>
    <div @drop.prevent="addDropFile" @dragover.prevent>
      <v-card>
        <v-card-title>
          <span class="headline">Venda VIP</span>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="ID do Jogador *"
                    v-model="player_id"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('player_id')"
                    v-validate="'required'"
                    data-vv-name="player_id"
                    outlined
                  ></v-text-field>
                </v-flex>

                <!-- <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    v-validate="'required'"

                    label="Clube *"
                    v-model="club_id"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('club_id')"
                    data-vv-name="club_id"
                    :items="club_id"
                    item-text="name"
                    outlined
                  ></v-text-field>
                </v-flex> -->

                <v-flex xs12 sm6 md6>
                  <v-autocomplete
                    label="Clube *"
                    :items="slots"
                    v-model="club_id"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('slots')"
                    v-validate="'required'"
                    :item-text="getTextSlot"
                    item-value="id"
                    data-vv-name="slots"
                    outlined
                  ></v-autocomplete>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-select
                    label="Tipo *"
                    required
                    :items="possible_types"
                    v-model="type"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('type')"
                    v-validate="'required'"
                    :item-text="getTextType"
                    item-value="id"
                    data-vv-name="type"
                    @change="setValue"

                    outlined
                  >
                  </v-select>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-text-field 
                  required 
                  label="Valor" 
                  v-model="value"
                  outlined
                  ></v-text-field>
                </v-flex>
                

                <v-flex xs12 sm6 md6>
                  <v-select
                    label="Tipo de Pagamento *"
                    required
                    :items="possible_payment_types"
                    v-model="payment_type"
                    :readonly="this.mode == 'VIEW' || this.payment_type == 2"
                    :disabled="this.payment_type == 2"
                    :error-messages="errors.collect('payment_type')"
                    v-validate="'required'"
                    item-text="name"
                    item-value="id"
                    data-vv-name="payment_type"
                    outlined
                  >
                  </v-select>
                </v-flex>

                <v-flex xs12 sm6 md6 v-if="payment_type == 1">
                  <v-select
                    label="Banco"
                    :items="banks"
                    v-model="bank_id"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('bank')"
                    v-validate="'required'"
                    :item-text="getTextBank"
                    item-value="id"
                    data-vv-name="bank"
                    outlined
                  >
                  </v-select>
                </v-flex>
                <v-flex xs12 sm6 md6 v-else>
                  <v-select
                    label="Banco"
                    :items="banks"
                    v-model="bank_id"
                    :readonly="this.mode == 'VIEW'"
                    :item-text="getTextBank"
                    item-value="id"
                    data-vv-name="bank"
                    outlined
                  >
                  </v-select>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-datetime-picker
                    v-model="date_picker"
                    date-format="dd/MM/yyyy"
                    clearText="Limpar"
                    okText="Confirmar"
                    :time-picker-props="timeProps"
                    :readonly="this.mode == 'VIEW'"
                    label="Data *"
                    :textFieldProps="{ outlined: true }"
                  >
                    <template slot="dateIcon">
                      <v-icon>mdi-calendar</v-icon>
                    </template>
                    <template slot="timeIcon">
                      <v-icon>mdi-clock</v-icon>
                    </template>
                  </v-datetime-picker>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-select
                    label="Status *"
                    required
                    disabled
                    :items="possible_status"
                    v-model="status"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('status')"
                    v-validate="'required'"
                    :item-text="getTextStatus"
                    item-value="id"
                    data-vv-name="name"
                    outlined
                  >
                  </v-select>
                  <span v-if="observation != null"> {{observation}} </span>
                </v-flex>

                <v-flex xs12 sm12 md12>
                  <v-file-input
                  v-validate="'required: ' + payment_type == 1"
                    :disabled="payment_type !== 1"
                    class="mt-2"
                    type="file"
                    v-model="receipt_file"
                    v-if="this.mode == 'ADD'"
                    label="Comprovante"
                    :error-messages="errors.collect('receipt')"
                    outlined
                  >
                  </v-file-input>
                </v-flex>

                

              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-col>
            <v-btn
              text
              @click="closeDialog"
              >{{ mode == "VIEW" ? "Voltar" : "Cancelar" }}</v-btn
            >
          </v-col>
          <v-col class="text-right">
            <v-btn
              text
              type="submit"
              right
              v-if="mode == 'EDIT' || mode == 'ADD'"
              @click="saveRelease"
              >Salvar</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import toBase64 from '@util/Base64'

export default {
    name: 'VipSalesForm',
    computed: {
        ...mapState('vip_sales', [
            'mode',
            'selectedItem',
            'screen',
            'showDialog',
        ]),
        ...mapState('slots', ['slots']),
        ...mapState('bank', { banks: 'items' }),
        ...mapFields('vip_sales', [
            'selectedItem.id',
            'selectedItem.player_id',
            'selectedItem.club_id',
            'selectedItem.date_picker',
            'selectedItem.type',
            'selectedItem.value',
            'selectedItem.bank_id',
            'selectedItem.payment_type',
            'selectedItem.status',
            'selectedItem.observation',
            'selectedItem.receipt',
            'selectedItem.receipt_file',
        ]),
        fileBase64: function () {
            if (this.fileString) {
                return this.fileString
            }
            return ''
        },
        possible_payment_types: function () {
            if (this.selectedItem.payment_type == 2) {
                return [
                    {
                        id: 2,
                        name: 'Anotado',
                    },
                ]
            }
            return this.payment_types
        },
    },
    created() {
        this.getSlots({ resetPage: false, noPages: true })
        this.setValue(this.selectedItem.type)
    },

    beforeUpdate() {
        this.setValue(this.selectedItem.type)
    },
    methods: {
        ...mapActions('slots', ['getSlots']),

        ...mapActions('vip_sales', ['updateSelectedItem', 'closeDialog']),
        ...mapMutations('vip_sales', ['setShowDialog', 'setSelectedItem']),
        ...mapActions('vip_sales', ['save']),

        addDropFile(e) {
            if (this.mode == 'ADD' || this.mode == 'EDIT') {
                if (e.dataTransfer) {
                    if (e.dataTransfer.files[0]) {
                        this.receipt_file = e.dataTransfer.files[0]
                    }
                }
            }
        },
        saveRelease() {
            this.$validator.validateAll().then((valid) => {
                if (valid) {
                    var d = new Date(this.date_picker)
                    this.date =
                        d.toISOString().substr(0, 10) +
                        ' ' +
                        d.toLocaleTimeString()
                    this.save()
                }
            })
        },

        setValue(itemId) {
            const a = this.possible_types.find((type) => type.id == itemId)
            this.selectedItem.value = a.price.toString().replace('.', ',')
        },
        getTextBank(item) {
            if (item.account) {
                return item.name + ' | ' + item.account
            } else {
                return item.name
            }
        },
        getTextSlot(item) {
            return item.name
        },
        getTextType(item) {
            // this.selectedItem.value = item.price.replace(".", ",");
            // this.onInputChange(item.price.toString().replace(".", ","));
            item.value = item.price
            return item.name
        },
        getTextPaymentType(item) {
            return item.name
        },
        getTextStatus(item) {
            if (item.account) {
                return item.name + ' | ' + item.account
            } else {
                return item.name
            }
        },
        setFileBase64() {
            if (this.receipt_file && this.receipt_file.name) {
                toBase64(this.receipt_file).then((fileBase64) => {
                    this.fileString = fileBase64
                })
            } else {
                this.fileString = ''
            }
        },
    },

    data: () => ({
        possible_types: [
            {
                id: 0,
                name: 'Coelho',
                price: 6.9,
            },
            {
                id: 1,
                name: 'Time Bank',
                price: 19.4,
            },
            {
                id: 2,
                name: 'Emoji',
                price: 6.9,
            },
            {
                id: 3,
                name: 'Bronze 1 dia',
                price: 3.5,
            },
            {
                id: 4,
                name: 'Bronze 7 dias',
                price: 9.5,
            },
            {
                id: 5,
                name: 'Bronze 30 dias',
                price: 19,
            },
            {
                id: 6,
                name: 'Bronze 90 dias',
                price: 49,
            },
            {
                id: 7,
                name: 'Bronze 365 dias',
                price: 159,
            },
            {
                id: 8,
                name: 'Prata 1 dia',
                price: 5,
            },
            {
                id: 9,
                name: 'Prata 7 dias',
                price: 20,
            },
            {
                id: 10,
                name: 'Prata 30 dias',
                price: 79,
            },
            {
                id: 11,
                name: 'Prata 90 dias',
                price: 194,
            },
            {
                id: 12,
                name: 'Prata 365 dias',
                price: 569,
            },
            {
                id: 13,
                name: 'Ouro 1 dia',
                price: 9.7,
            },
            {
                id: 14,
                name: 'Ouro 7 dias',
                price: 48.5,
            },
            {
                id: 15,
                name: 'Ouro 30 dias',
                price: 194,
            },
            {
                id: 16,
                name: 'Ouro 90 dias',
                price: 474,
            },
            {
                id: 17,
                name: 'Ouro 365 dias',
                price: 1394,
            },
        ],
        payment_types: [
            {
                id: 0,
                name: 'Fichas',
            },
            {
                id: 1,
                name: 'Pix',
            },
        ],
        possible_status: [
            {
                id: 0,
                name: 'Pendente',
            },
            {
                id: 1,
                name: 'Aguardando',
            },
            {
                id: 2,
                name: 'Pago',
            },
            {
                id: 3,
                name: 'Concluído',
            },
            {
                id: 4,
                name: 'Erro bot',
            },
        ],
        timeProps: {
            format: '24hr',
        },
        rulesFields: [(value) => !!value || 'Campo obrigatório'],
    }),
    watch: {
        receipt_file: {
            handler() {
                this.setFileBase64()
            },
        },
    },
}
</script>


<style>
:disabled {
    opacity: 55%;
}
</style>