import axios from "axios";
import { getField, updateField } from "vuex-map-fields";
import endPoints from "@config/endPoints";
import {
  defaultErrorNotification,
  defaultSuccessNotification,
} from "@constants";
import formatDate from '@util/formatDate.js'

const defaultFilter = {
  id: "",
  start: "",
  end: ""
};

const defaultItem = {
  id: null,
  start_date: "",
  finish_date: "",
  start_date_picker: null,
  finish_date_picker: null
};

export default {
  namespaced: true,
  state: {
    items: [],
    selectedItem: {},
    mode: "",
    loading: false,
    totalItems: 0,
    deleteDialog: false,
    filterDialog: false,
    errDialog : false,
    messageErr : "",
    filtered: false,
    formDialog: false,
    notificationMessage: '',
    notificationType: '',
    filter: {
      id: "",
      start: "",
      end: ""
    },
    notification: {
      show: false,
      message: "",
      type: "",
    },
    overlay: false,
    period_conciliation: {},
    pagination: {
      page: 1,
      itemsPerPage: 10,
    },
  },
  mutations: {
    add: (state) => {
      state.selectedItem = Object.assign({}, defaultItem);
      // set default date yesterday 00:00:00
      state.selectedItem.start_date_picker = new Date(formatDate(new Date(new Date().setDate(new Date().getDate() - 1)), 'yyyy-MM-dd') + ' 00:00:00')
      // set default date yesterday 23:59:59
      state.selectedItem.finish_date_picker = new Date(formatDate(new Date(new Date().setDate(new Date().getDate() - 1)), 'yyyy-MM-dd') + ' 23:59:59')
      
      state.formDialog = true;
      state.mode = "ADD";
    },
    edit: (state, payload) => {
      
      state.selectedItem = Object.assign({}, payload.item);
      state.selectedItem.start_date_picker = new Date(payload.item.start_date)
      state.selectedItem.finish_date_picker = new Date(payload.item.finish_date)
      state.formDialog = true;
      state.mode = "EDIT";
    },
    view: (state, payload) => {
      state.selectedItem = payload;
      state.formDialog = true;
      state.mode = "VIEW";
    },
    clearFilter: (state) => {
      state.filter = Object.assign({}, defaultFilter);
    },
    setFiltered: (state, payload) => state.filtered = payload.filtered,
    setItems: (state, payload) => (state.items = payload),
    setTotalItems: (state, payload) => (state.totalItems = payload),
    setLoading: (state, payload) => (state.loading = payload),
    showNotification: (state, payload) => (state.notification = payload),
    setSelectedItem: (state, payload) => (state.selectedItem = payload),
    showDeleteDialog: (state, payload) => {
      state.deleteDialog = payload.show
      state.selectedItem = payload.item
    
    },
    showFormDialog: (state, payload) => {
      state.formDialog = payload.show;
      state.selectedItem = Object.assign({}, defaultItem);
    },
    showErrDialog: (state, payload) => {
      state.errDialog = payload.show;
      state.messageErr = payload.message;
    },
    setPeriodConciliation: (state, payload) => {
      state.period_conciliation = payload.period
    },
    showFilterDialog: (state, payload) => (state.filterDialog = payload.show),
    showOverlay: (state, payload) => (state.overlay = payload.show),
    updateField,
  },
  actions: {
    getItems: async ({ commit, state }, payload) => {
      let filter = state.filter;
      let page = state.pagination.page;
      let itemsPerPage = state.pagination.itemsPerPage;
      if (payload.pagination) {
        page = payload.pagination.page;
        itemsPerPage = payload.pagination.itemsPerPage;
      }
      let url = `${endPoints.conciliation}/period_conciliation?page=${page}&itemsPerPage=${itemsPerPage}`;

      Object.keys(filter).forEach(function(field) {
        if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
          commit('setFiltered', {filtered: true})

          if (field == "end" || field == "start") {
              var e = new Date(filter[field])
              url += "&" + field + "=" + formatDate(e) + " " + e.toLocaleTimeString()
          } else {
              url += "&" + field + "=" + filter[field]
          }
        }
      });

      if (
        state.pagination &&
        state.pagination.sortBy &&
        state.pagination.sortBy.length > 0
      ) {
        url +=
          "&sort_by=" +
          (state.pagination.sortBy[0] === "name"
            ? "id"
            : state.pagination.sortBy[0]);
        url += "&sort_desc=" + state.pagination.sortDesc[0];
      }

      commit("setLoading", true);

      try {
        let items = await axios.get(url);
        commit("setItems", items.data.data);
        commit("setTotalItems", items.data.total);
      } catch (error) {
        commit(
          "showNotification",
          defaultErrorNotification(error?.response?.data?.message)
        );
      }
      commit("setLoading", false);
    },
    deleteItem: async ({ commit, dispatch, state }) => {
      commit("setLoading", true);
      try {
        await axios.delete(`${endPoints.conciliation}/period_conciliation/${state.selectedItem.id}`);

        commit("setLoading", false);
        commit("showDeleteDialog", {show: false});
        commit("setSelectedItem", {});
        commit(
          "showNotification",
          defaultSuccessNotification("Periodo de conciliação removido com sucesso")
        );

        return dispatch("getItems");
      } catch (error) {
        commit(
          "showNotification",
          defaultErrorNotification(error?.response?.data?.message)
        );
        commit("setLoading", false);
      }
    },
    fillTables: async ({ commit }, payload) => {
      let url = endPoints.conciliation + "/fill_tables/" + payload.id;
      try {
        commit("showOverlay", {show: true});
  
        await axios.post(url);
        commit("showOverlay", {show: false});
        commit("showNotification", defaultSuccessNotification("Tabelas preenchidas com sucesso"));
      } catch (error) {
        commit("showOverlay", {show: false});
        console.log(error?.response?.data?.message)
        commit("showErrDialog", {show: true, message: error?.response?.data?.message});
        commit(
          "showNotification",
          defaultErrorNotification(error?.response?.data?.message)
        );
      }
    },
    openPeriodDetail: ({ commit, dispatch }, payload) => {
      commit('setPeriodConciliation', { period: payload })
      dispatch('menu/openLink', '/period_conciliation/' + payload.id, { root: true })
    },
    save: async ({ commit, state, dispatch, rootState }) => {
      let url = endPoints.conciliation + "/period_conciliation";
      let func = "";
      state.mode === "ADD"
        ? (func = axios.post)
        : ((func = axios.put), (url += `/${state.selectedItem.id}`));
      commit("setLoading", true);
      try {
        state.selectedItem.user_id = rootState.login.userData.id;
        state.selectedItem.user_name = rootState.login.userData.name;
        // state.selectedItem.percentage = parseFloat(state.selectedItem.percentage);

        await func(url, state.selectedItem);

        commit(
          "showNotification",
          defaultSuccessNotification("Período cadastrado com sucesso")
        );
        commit("showFormDialog", false);
        commit("setLoading", false);
        dispatch("getItems");
      } catch (error) {
        commit(
          "showNotification",
          defaultErrorNotification(error?.response?.data?.message)
        );
        commit("setLoading", false);
      }
    },
    linkOperations: async ({commit}, payload) => {
      commit("showOverlay", {show: true});
      let url = endPoints.conciliation + "/link_operations/" + payload.item.id;
      // if (payload.onlyOutsidePeriod){
      //   console.log("onlyOutsidePeriod")
      //   url += "?only_outside_period=true"
      // }
      try {
        await axios.post(url)

        commit(
          "showNotification",
          defaultSuccessNotification("Operações vinculadas com sucesso")
        );
        
        commit("showOverlay", {show: false});
      } catch (error) {
        commit(
          "showNotification",
          defaultErrorNotification(error?.response?.data?.message)
        );
        commit("showOverlay", {show: false});
      }
    },    
    removeVinculations: async ({commit}, payload) => {
      commit("showOverlay", {show: true});
      let url = endPoints.conciliation + "/remove_vinculations/" + payload.item.id;
    
      try {
        await axios.post(url)

        commit(
          "showNotification",
          defaultSuccessNotification("Operações desvinculadas com sucesso")
        );
        
        commit("showOverlay", {show: false});
      } catch (error) {
        commit(
          "showNotification",
          defaultErrorNotification(error?.response?.data?.message)
        );
        commit("showOverlay", {show: false});
      }
    },    
  },
  getters: {
    getPeriodSelected: state => {
      return state.period_conciliation.id
  },
    getField,
  },
};
