<template>
    <div>
      <v-container grid-list-md>
          <v-card>
              <v-card-title class="justify-center">Agente - {{ agent_closure.nick_agent }}</v-card-title>
              <v-card-text>
                  <v-container>
                      <v-layout wrap>
                          <v-flex xs4 sm4 md4>
                          <span>Tipo de Acerto: {{getTypeDeal(agent_closure.type_deal)}}</span>
                          </v-flex>
                          <v-flex xs4 sm4 md4 v-if="agent_closure.type_deal == 0">
                          <span>Porcentagem Aplicada: {{agent_closure.percentage_applied}}%</span>
                          </v-flex>
                          <v-flex xs4 sm4 md4 v-if="agent_closure.type_deal == 0">
                          <span>Total Ganhos: {{getTotalAgSubAg(agent_closure, "total_gains", "total_gains_sub_agents")}}</span>
                          </v-flex>
                          <v-flex xs6 sm6 md6 v-if="agent_closure.type_deal != 0">
                          <span>Porcentagem Aplicada: {{agent_closure.percentage_applied}}%</span>
                          </v-flex>
                          <v-flex xs4 sm4 md4>
                          <span>Rake Gerado: {{getTotalRake(agent_closure)}}</span>
                          </v-flex>
                          <v-flex xs4 sm4 md4>
                          <span>Rakeback: {{getTotalRakeback(agent_closure)}}</span>
                          </v-flex>
                          <v-flex xs4 sm4 md4>
                          <span>Valor a Pagar: {{(agent_closure.total_value_to_pay) ? agent_closure.total_value_to_pay.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "R$ 0,00"  }}</span>
                          </v-flex>
          
                      </v-layout>
       
                  </v-container>
                <div class="justify-center text-center mb-2">
                  <v-btn @click="openDialogTransactionsCentral">
                      Transações Central
                  </v-btn>
                </div>
  
              </v-card-text>
          </v-card>
          
            <v-card v-if="isAdjustsDetails">
                <v-card-title class="justify-center mt-2">Ajustes </v-card-title>
                <v-card-text>
                    <v-container>
                        <template v-for="(adjust, i) in adjustsDetails">
                          <v-flex xs4 sm4 md4 :key="i" class="mt-1">
                            <span>{{ adjust.description }}: R$ {{ getAdjust(adjust.value) }}</span>
                          </v-flex>
                        </template>
                        <v-flex xs4 sm4 md4 :key="i" class="mt-1">
                          <span> Total: R$ {{ getAdjust(totalAdjust) }}</span>
                        </v-flex>
                    </v-container>
                </v-card-text>
            </v-card>
            <v-container grid-list-md>
                <v-layout wrap>
                    <v-flex xs12 sm12 md6 xl6 class="elevation-10">
                        <v-card-title class="justify-center">Rake Gerado</v-card-title>
                        <GChart
                            :settings="{ packages: ['corechart'] }"
                            type="PieChart"
                            :data="chartRake"
                            :options="optionRake"
                        />
                    </v-flex>
                    <v-flex xs12 sm12 md6 xl6 class="elevation-10">
                        <v-card-title class="justify-center">Rakeback</v-card-title>
                        <GChart
                            :settings="{ packages: ['corechart'] }"
                            type="PieChart"
                            :data="chartRakeback"
                            :options="optionRake"
                        />
                    </v-flex>
                    <v-flex xs12 sm12 md6 xl6>
                        <v-card-title class="justify-center">Jogadores</v-card-title>
                        <v-data-table
                            :headers="headers_players"
                            :loading="loading_players"
                            :items="playersClosure"   
                            disable-pagination   
                            hide-default-footer
                            no-data-text="Nenhum registro encontrado"
                            class="elevation-10 table-margin"
                        >
                            <template v-slot:item.player_gains="{ item }">
                              <span>{{ (item.player_gains) ? item.player_gains.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "R$ 0,00" }}</span>
                            </template>
                            <template v-slot:item.player_tax="{ item }">
                              <span>{{ (item.player_tax) ? item.player_tax.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "R$ 0,00" }}</span>
                            </template>
                            <template v-slot:item.player_spin="{ item }">
                              <span>{{ (item.player_spin) ? item.player_spin.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "R$ 0,00" }}</span>
                            </template>
                        </v-data-table>
                    </v-flex> 
                    <v-flex xs12 sm12 md6 xl6>
                        <v-card-title class="justify-center">Sub-Agentes</v-card-title>
                        <v-data-table
                            :headers="headers_agents"
                            :loading="loading_agents"
                            :items="subAgentsClosure"    
                            disable-pagination 
                            hide-default-footer
                            no-data-text="Nenhum registro encontrado"
                            class="elevation-10 table-margin"
                        >
                            <template v-slot:item.total_gains="{ item }">
                                <span>{{ (item.total_gains) ? item.total_gains.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "R$ 0,00" }}</span>
                            </template>
                            <template v-slot:item.total_taxes="{ item }">
                                <span>{{ getTotalTwoFields(item, "total_taxes", "total_spin" ) }}</span>
                            </template>
                            <template v-slot:item.total_rakeback="{ item }">
                                <span>{{ getTotalTwoFields(item, "total_rakeback", "total_spin_rakeback" ) }}</span>
                            </template>
                            <template v-slot:item.percentage_applied="{ item }">
                                <span>{{ (item.fixed_percentage == 0) ? item.difference_percentage : item.percentage_applied }}%</span>
                            </template>
                        </v-data-table>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-container>
  
        <v-dialog :value="dialogTransactionsCentral" @click:outside="showDialogTransactionsCentral({show: false})" @keydown.esc="showDialogTransactionsCentral({show: false})">
          <v-card class="elevation-10 table-margin">
            <v-card-title class="justify-center mt-2">Transações Central</v-card-title>
            <v-data-table
                :headers="headersTransactions"
                :items="transactinsCentral"
                :server-items-length="totalItemsTransaction"    
                no-data-text="Nenhuma registro encontrado"
                
                :item-class= "getClassColorTransaction" 
              >
                <template v-slot:[`item.date`]="{ item }">
                  <span>{{ getDate(item.date)}}</span>
                </template>
                <template v-slot:[`item.value`]="{ item }">
                  <span>{{ item.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
                </template>
                <template v-slot:[`item.type_transaction`]="{ item }">
                  <span>{{ item.type_transaction == 0 ? "Saída" : "Entrada" }}</span>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-on="on" v-bind="attrs" small class="mr-2" @click="viewItem({item: item})">mdi-information</v-icon>
                    </template>
                    <span>Informações</span>
                  </v-tooltip>
                </template>
            </v-data-table>
          </v-card>
        </v-dialog>
  
    </div>
  </template>
  
  <script>
  import { mapActions, mapMutations, mapState } from "vuex";
  import { mapFields } from "vuex-map-fields";
  
  export default {
    name: "AgentsClosureDatesDetail",
    created() {
      var period = this.$route.params.period_id;
      var closure = this.$route.params.closure_id;
  
      this.setAgentClosureParam({period: period, closure_id: closure})
      this.getDataAgentClosure()
      this.getAdjusts({ closure_id: closure })
    },
    computed: {
      ...mapFields("agents_closure_detail", ["dialogTransactionsCentral", "transactinsCentral", "totalItemsTransaction"]),
      ...mapState("agents_closure_detail", [
          "agent_closure",
          "adjustsDetails",
          "isAdjustsDetails",
          "totalAdjust",
          "chartRake",
          "chartRakeback",
          "loading_players",
          "loading_agents",
          "subAgentsClosure",
          "playersClosure",
          "mode",
      ]),
    },
    methods: {
      ...mapActions('agents_closure_detail', ['getDataAgentClosure', 'getAdjusts', 'openDialogTransactionsCentral']),
      ...mapMutations('agents_closure_detail', ['setAgentClosureParam', 'showDialogTransactionsCentral']),
      getDate(date) {
        if (date) {
          var d = new Date(date);
          return d.toLocaleDateString() + " " + d.toLocaleTimeString();
        }
        return "";
      },
      getTypeDeal(type) {
          let types = ["Ganho/Perda", "Giro", "Normal", "Fichas Enviadas/Retiradas", "Sem Acerto", "Sem Acerto/GP"]
          return types[type]
      },
      getTotalRake(item) {
        let rake = item.total_taxes + item.total_rake_sub_agents + item.total_spin_sub_agents + item.total_spin
        return rake.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      },
      getAdjust(value) {
        return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      },
      getTotalRakeback(item) {
        let rakeback = item.rakeback + item.rakeback_spin + item.total_spin_rakeback_sub_agents + item.total_rakeback_sub_agents
        return rakeback.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      },
      getTotalAgSubAg(item, field1, field2) {
        if(item[field1] || item[field2] === 0) {
          if(item[field2] || item[field2] === 0) {
            let total = (item[field2] + item[field1])
            return total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
          }
          return item[field1].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        } else if(item[field2] || item[field2] === 0) {
          return item[field2].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        }
        return "0".toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      },
      getTotalTwoFields(item, field1, field2) {
        if(item[field1] || item[field2] === 0) {
          if(item.id_agent == 1466) console.log('testee')
          if(item[field2] || item[field2] === 0) {
            let total = (item[field2] + item[field1])
            if(item.id_agent == 1466) console.log('testee2', total)
            return total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
          }
          return item[field1].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        } else if(item[field2] || item[field2] === 0) {
          return item[field2].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        }
        return "0".toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      },
      getClassColorTransaction(item) {
        if(item.type_transaction == 0) {
          return "row-red"
        } else {
          return "row-green"
        }
      },
    },
    data: () => ({
        headers_players: [
          { text: "Id Suprema", value: "id_pppoker", sortable: false },
          { text: "Nick", value: "nick", sortable: false },
          { text: "Ganhos", value: "player_gains", sortable: true },
          { text: "Spin", value: "player_spin", sortable: true },
          { text: "Rake Gerado", value: "player_tax", sortable: true },
          { text: "Mãos Jogadas", value: "hands_played", sortable: true },
        ],
        headers_agents: [
          { text: "Clube", value: "club_name", sortable: false },
          { text: "Id Suprema", value: "id_pppoker", sortable: false },
          { text: "Nick", value: "nick_agent", sortable: false },
          { text: "Ganhos", value: "total_gains", sortable: false },
          { text: "Rake Gerado", value: "total_taxes", sortable: false },
          { text: "P. Aplicada", value: "percentage_applied", sortable: false, align: 'right'},
          { text: "Rakeback", value: "total_rakeback", sortable: false },
        ],
        headersTransactions: [
          
          { text: "Clube", value: "club_name", sortable: false },
          { text: "Data", value: "date", sortable: false },
          { text: "ID Gestor", value: "id_manager_app", sortable: false },
          { text: "Nick Gestor", value: "manager_nick", sortable: false },
          { text: "Agente", value: "id_agent_app", sortable: false },
          { text: "Player", value: "id_player_app", sortable: false },
          { text: "Tipo", value: "type_transaction", sortable: false },
          { text: "Valor", value: "value", sortable: false }
        ],
        items: [],
        optionRake: {
          chart: {
            title: 'Rake'
          },
          legend: {
              position: 'bottom'
          },
          pieHole: 0.8,
          slices: {
              0: { color: 'green' },
              1: { color: 'blue' }
          }
        }
    })
  };
  </script>
  