import axios from "axios";
import { getField, updateField } from "vuex-map-fields";
import endPoints from "../../../config/endPoints";
import {
  defaultErrorNotification,
  // defaultSuccessNotification,
} from "../../../constants";

const defaultFilter = {
  id: "",
  ispb: "",
  name: "",
  code: "",
  compe: "",
  access: "",
  full_name: "",
  date_op: "",
};

const defaultBankItem = {
  ispb: null,
  name: "",
  code: null,
  compe: "",
  access: "",
  full_name: "",
  date_op: "",
};

export default {
  namespaced: true,
  state: {
    items: [],
    selectedItem: {},
    mode: "",
    loading: false,
    totalItems: 0,
    deleteDialog: false,
    filterDialog: false,
    showDialog: false,
    filter: {
      ispb: "",
      name: "",
      code: "",
      compe: "",
      access: "",
      full_name: "",
      date_op: "",
    },
    notification: {
      show: false,
      message: "",
      type: "",
    },
    pagination: {
      page: 1,
      itemsPerPage: 10,
    },
  },
  mutations: {
    view: (state, payload) => {
      state.selectedItem = payload;
      state.showDialog = true;
      state.mode = "VIEW";
    },
    clearFilter: (state) => {
      state.filter = Object.assign({}, defaultFilter);
    },
    setItems: (state, payload) => (state.items = payload),
    setTotalItems: (state, payload) => (state.totalItems = payload),
    setLoading: (state, payload) => (state.loading = payload),
    showNotification: (state, payload) => (state.notification = payload),
    setSelectedItem: (state, payload) => (state.selectedItem = payload),
    setDeleteDialog: (state, payload) => (state.deleteDialog = payload),
    setShowDialog: (state, payload) => {
      state.showDialog = payload;
      state.selectedItem = Object.assign({}, defaultBankItem);
    },
    setShowFilter: (state, payload) => (state.filterDialog = payload),
    updateField,
  },
  actions: {
    getItems: async ({ commit, state }) => {
      let filter = state.filter;
      let url = `${endPoints.banks_bacen}?page=${state.pagination.page}&itemsPerPage=${state.pagination.itemsPerPage}`;

      console.log("URL: ", url)

      Object.keys(filter).forEach(function(field) {
        if (
          filter[field] !== "" &&
          filter[field] !== undefined &&
          filter[field] !== null
        ) {
          url += "&" + field + "=" + filter[field];
        }
      });

      if (
        state.pagination &&
        state.pagination.sortBy &&
        state.pagination.sortBy.length > 0
      ) {
        url +=
          "&sort_by=" +
          (state.pagination.sortBy[0] === "id"
            ? "id"
            : state.pagination.sortBy[0]);
        url += "&sort_desc=" + state.pagination.sortDesc[0];
      }

      commit("setLoading", true);

      try {
        let items = await axios.get(url);
        commit("setItems", items.data.data);
        commit("setTotalItems", items.data.total);
      } catch (error) {
        commit(
          "showNotification",
          defaultErrorNotification(error?.response?.data?.message)
        );
      }
      commit("setLoading", false);
    },
  },
  getters: {
    getField,
  },
};
