<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar
      :color="notification.type"
      right
      top
      :timeout="2000"
      v-model="notification.show"
    >
      {{ notification.message }}
    </v-snackbar>

    <v-tabs v-model="tab" class="meetings-data elevation-10">
      <v-tab>Lista</v-tab>
      <v-tab>Calendário</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item class="elevation-10">
        <v-card>
          <v-toolbar flat class="elevation-5">
            <v-toolbar-title>Reuniões Marcadas</v-toolbar-title>
            <v-spacer></v-spacer>
            <div class="button-toolbar">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="black"
                    dark
                    class="ma-2"
                    @click="setShowFilter(true)"
                    v-bind="attrs"
                    v-on="on"
                    small
                  >
                    <v-icon color="white">mdi-filter</v-icon>
                  </v-btn>
                </template>
                <span>Filtro</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="black"
                    dark
                    class="ma-2"
                    @click="getItems()"
                    v-bind="attrs"
                    v-on="on"
                    small
                    ><v-icon>mdi-refresh</v-icon></v-btn
                  >
                </template>
                <span>Recarregar</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="black"
                    dark
                    class="ma-2"
                    v-if="profileAccess()"
                    v-bind="attrs"
                    v-on="on"
                    @click="add()"
                    small
                    ><v-icon>mdi-plus</v-icon></v-btn
                  >
                </template>
                <span>Criar nova reunião</span>
              </v-tooltip>
            </div>
          </v-toolbar>

          <v-data-table
            :headers="headers"
            :loading="loading"
            :items="items"
            :options.sync="pagination"
            :server-items-length="totalItems"
            class="elevation-10 table-margin"
            no-data-text="Nenhuma reunião encontrada"
          >
            <template v-slot:[`item.room`]="{ item }">
              <span class="ma-2">{{ getMeetingRoom(item.room) }}</span>
            </template>

            <template v-slot:[`item.date`]="{ item }">
              <span>{{ getDate(item.date) }}</span>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip bottom v-if="profileAccess()">
                <template v-slot:activator="{ attrs, on }">
                  <v-icon
                    color="purple"
                    v-bind="attrs"
                    v-on="on"
                    small
                    class="mr-2"
                    @click="viewLink(item)"
                    >mdi-google-classroom</v-icon
                  >
                </template>
                <span>Entrar na reunião</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ attrs, on }">
                  <v-icon
                    color="grey darken-1"
                    small
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="view(item)"
                    >mdi-information</v-icon
                  >
                </template>
                <span>Informações</span>
              </v-tooltip>

              <v-tooltip bottom v-if="profileAccess()">
                <template v-slot:activator="{ attrs, on }">
                  <v-icon
                    color="blue"
                    small
                    v-bind="attrs"
                    v-on="on"
                    class="mr-2"
                    @click="edit(item)"
                    >mdi-pencil</v-icon
                  >
                </template>
                <span>Editar</span>
              </v-tooltip>

              <v-tooltip bottom v-if="profileAccess()">
                <template v-slot:activator="{ attrs, on }">
                  <v-icon
                    color="yellow darken-2"
                    small
                    v-bind="attrs"
                    v-on="on"
                    class="mr-2"
                    @click="edit(item)"
                    >mdi-form-select</v-icon
                  >
                </template>
                <span>Editar</span>
              </v-tooltip>

              <v-tooltip bottom v-if="profileAccess()">
                <template v-slot:activator="{ attrs, on }">
                  <v-icon
                    color="red darken-1"
                    small
                    v-bind="attrs"
                    v-on="on"
                    class="mr-2"
                    @click="deleteItem(item)"
                    >mdi-delete</v-icon
                  >
                </template>
                <span>Excluir</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card height="500">
          <v-card-title class="text-center justify-center">
            Calendário
          </v-card-title>
          <v-row justify="space-around">
            <div class="subheading">
              Reuniões do dia: {{ date1 }}
              <v-card-text>Esta parte está em desenvolvimento! 👿</v-card-text>
            </div>
            <v-date-picker
              v-model="date1"
              width="800"
              :events="arrayEvents"
              event-color="orange darken-2"
              elevation="15"
            ></v-date-picker>
          </v-row>
        </v-card>
      </v-tab-item>
    </v-tabs-items>

    <v-dialog
      :value="linkDialog"
      @click:outside="setLinkDialog(false)"
      width="600"
    >
      <v-card>
        <v-card-title
          >Link: <br />
          {{ linkTeste }} <br /><br />
          Participantes: {{ participantes }}</v-card-title
        >
      </v-card>
    </v-dialog>

    <v-dialog
      :value="showDialog"
      @click:outside="setShowDialog(false)"
      width="1000"
    >
      <meetings-form></meetings-form>
    </v-dialog>

    <v-dialog
      :value="filterDialog"
      @click:outside="setShowFilter(false)"
      width="600"
    >
      <meetings-filter></meetings-filter>
    </v-dialog>

    <v-dialog
      :value="deleteDialog"
      @click:outside="setDeleteDialog(true)"
      @keydown.enter="deleteAction"
      width="300"
    >
      <v-card>
        <v-card-title class="headline">Confirmar a exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn text @click="setDeleteDialog(false)">Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn text type="submit" right @click="deleteAction"
              >Confirmar</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import MeetingsForm from "./MeetingsForm";
import MeetingsFilter from "./MeetingsFilter";

export default {
  name: "Meetings",

  created() {
    this.getItems({ resetPage: false });
  },

  mounted() {
    // date-picker
    this.arrayEvents = [...Array(20)].map(() => {
      const day = Math.floor(Math.random() * 30);
      const d = new Date();
      d.setDate(day);
      return d.toISOString().substr(0, 10);
    });

    console.log(this.arrayEvents)
  },
  components: {
    MeetingsForm,
    MeetingsFilter,
  },

  methods: {
    ...mapActions("meetings", ["getItems", "getItem", "deleteAction"]),
    ...mapMutations("meetings", [
      "setShowDialog",
      "add",
      "edit",
      "view",
      "viewLink",
      "setLinkDialog",
      "setShowFilter",
      "setDeleteDialog",
      "deleteItem",
    ]),
    profileAccess() {
      if (
        this.userData.role == 0 ||
        this.userData.role == 1 ||
        this.userData.role == 70
      ) {
        return false;
      } else {
        return true;
      }
    },
    getMeetingRoom(room) {
      return this.meeting_rooms[room];
    },
    getDate(date) {
      if (date != null) {
        var d = new Date(date);
        return d.toLocaleDateString() + " " + d.toLocaleTimeString();
      } else {
        return "--------";
      }
    },
    functionEvents(date) {
      const [, , day] = date.split("-");
      if ([12, 17, 28].includes(parseInt(day, 10))) return true;
      if ([1, 19, 22, 23, 24].includes(parseInt(day, 10)))
        return ["red", "#00f"];
      return false;
    },
  },
  computed: {
    ...mapState("login", ["userData"]),
    ...mapState("meetings", [
      "items",
      "notification",
      "mode",
      "loading",
      "totalItems",
      "linkDialog",
      "showDialog",
      "filterDialog",
      "deleteDialog",
      "linkTeste",
      "participantes",
    ]),
    ...mapFields("meetings", ["pagination", "link"]),
  },
  data: () => ({
    meeting_rooms: ["Recepção", "Baias"],
    headers: [
      { text: "Data", value: "date", sortable: false },
      { text: "Título", value: "title", sortable: false },
      { text: "Pauta", value: "pauta", sortable: false },
      { text: "Sala de Reunião", value: "room", sortable: false },
      { text: "Criada por", value: "created_by", sortable: false },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        align: "center",
        width: "10%",
      },
    ],
    tab: "",
    arrayEvents: null,
    date1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
  }),

  watch: {
    pagination: {
      handler() {
        this.getItems({ resetPage: false });
      },
    },
  },
};
</script>
