<template>
  <div>
    <div @drop.prevent="addDropFile" @dragover.prevent>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card>
        <v-card-title>
          <span class="headline">Novo Período</span>
          <v-spacer></v-spacer>
          <v-progress-circular
            v-if="loading"
            indeterminate
            class="text-right"
            color="primary"
            size="24"
          ></v-progress-circular>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    required
                    label="Descrição *"
                    v-model="description"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('description')"
                    v-validate="'required:Descrição,description|max_length:254'"
                    data-vv-name="description"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-menu
                    v-model="showStart"
                    :close-on-content-click="true"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="start_formatted"
                        label="Inicio *"
                        outlined=""
                        readonly
                        append-icon="mdi-close"
                        @click:append="clearStart()"
                        persistent-hint
                        :error-messages="errors.collect('date_start')"
                        v-validate="'required:Inicio,date_start'"
                        data-vv-name="date_start"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      locale="pt-br"
                      v-model="date_start"
                      no-title
                      @input="(e) => setStart(e)"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-menu
                    v-model="showFinish"
                    :close-on-content-click="true"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="finish_formatted"
                        label="Fim *"
                        outlined=""
                        readonly
                        append-icon="mdi-close"
                        @click:append="clearFinish()"
                        persistent-hint
                        :error-messages="errors.collect('date_end')"
                        v-validate="'required:Fim,date_end'"
                        data-vv-name="date_end"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      locale="pt-br"
                      v-model="date_end"
                      no-title
                      @input="(e) => setFinish(e)"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-col>
            <v-btn text @click="closeDialog">{{
              mode == "VIEW" ? "Voltar" : "Cancelar"
            }}</v-btn>
          </v-col>
          <v-col class="text-right" v-if="mode != 'VIEW'">
            <v-btn text type="submit" right @click="savePeriod">Salvar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import debounce from "../../../../util/debounce.js";

export default {
  name: "PeriodsPlayersBalanceForm",
  computed: {
    ...mapState("periods_players_balance", [
      "item",
      "loading",
      "mode",
      "periods_closure",
    ]),
    ...mapFields("periods_players_balance", [
      "finish_formatted",
      "selectedItem.description",
      "selectedItem.date_start",
      "selectedItem.date_end",
      "overlay",
      "start_formatted",
    ]),
  },
  created() {
    this.getPeriodClosureByName = this.debounce(
      this.getPeriodClosureByName,
      500
    );
    this.$validator.extend("required", {
      getMessage: (field, [label]) => label + " deve ser informado",
      validate: (_, [, field]) => {
        if (this[field] || this[field] === 0) {
          return true;
        } else {
          return false;
        }
      },
    });
    this.$validator.extend("max_length", {
      getMessage: (_, [length]) => "No máximo " + length + " caracteres.",
      validate: (val, [length]) => {
        if (val.length > length) {
          return false;
        } else {
          return true;
        }
      },
    });
  },
  mixins: [debounce],
  methods: {
    ...mapMutations("periods_players_balance", ["closeDialog"]),
    ...mapActions("periods_players_balance", ["getPeriodClosureByName", "save"]),
    clearStart() {
      this.date_start = "";
      this.start_formatted = "";
    },
    clearFinish() {
      this.date_end = "";
      this.finish_formatted = "";
    },
    getDate(date) {
      if (date) {
        var d = new Date(date);
        return d.toLocaleDateString() + " " + d.toLocaleTimeString();
      }
      return "";
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    },
    savePeriod() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.save();
        }
      });
    },
    setStart(date) {
      this.date_start = date;
      this.showStart = false;
      this.start_formatted = this.formatDate(date);
    },
    setFinish(date) {
      this.date_end = date;
      this.showFinish = false;
      this.finish_formatted = this.formatDate(date);
    },
  },
  data: () => ({
    fileString: "",
    timeProps: {
      format: "24hr",
    },
    valid: false,
    showStart: false,
    showFinish: false,
    possible_types: [
      {
        id: 0,
        description: "Fechamento",
      },
      {
        id: 1,
        description: "Teste",
      },
    ],
    searchPeriod: "",
  }),
  watch: {
    searchPeriod(val) {
      var payload = {
        search: val,
      };
      if (val != "") {
        this.getPeriodClosureByName(payload);
      }
    },
  },
};
</script>
