import axios from "axios";
import endPoints from "../../config/endPoints";
import { getField, updateField } from "vuex-map-fields";
import router from "../../config/router";

export default {
  namespaced: true,
  state: {
    changePassword: {
      confirmation_password: "",
      current_password: "",
      new_password: "",
    },
    showMenu: true,
    dialogChangePassword: false,
    minim: false,
    path: "",
    menus: [],
    showNotification: false,
    notificationMessage: "",
    notificationType: "",
  },
  mutations: {
    menuDrawer: (state, payload) => (state.drawer = payload.mini),
    menuMini: (state, payload) => (state.minim = payload.mini),
    setPath: (state, path) => (state.path = path),
    showDialogChangePassword: (state, payload) => {
      state.dialogChangePassword = payload.show;
      state.changePassword = {
        confirmation_password: "",
        current_password: "",
        new_password: "",
      };
    },
    setShowMenu: (state, payload) => (state.showMenu = payload),
    setMenus: (state, payload) => {
      state.menus = payload.menus;
    },
    showNotification: (state, payload) => {
      (state.showNotification = payload.show),
        (state.notificationMessage = payload.message),
        (state.notificationType = payload.type);
    },
    updateField,
  },
  getters: {
    getField,
  },
  actions: {
    changePass({ commit, rootGetters, state }) {
      let url =
        endPoints.users + "/change_password/" + rootGetters["login/getUserId"];
      axios
        .post(url, state.changePassword)
        .then(() => {
          commit("showDialogChangePassword", { show: false });
          let notification = {
            show: true,
            message: "Senha Alterada com sucesso",
            type: "success",
          };
          commit("showNotification", notification);
        })
        .catch((error) => {
          var message = "Ocorreu um erro ao alterar a senha";
          if (error.response) {
            if (error.response.data) {
              if (error.response.data.message) {
                message = error.response.data.message;
              }
            }
          }
          let notification = {
            show: true,
            message: message,
            type: "error",
          };
          commit("showNotification", notification);
        });
    },
    buildMenu({ commit, rootGetters }) {
      axios.get(endPoints.menus + "/build_menu").then(
        (response) => {
          let menus = response.data;
          let flag =
            rootGetters["login/getDefaultPassword"] == 1 ? false : true;
          commit("setMenus", { menus: menus });
          commit("setShowMenu", flag);
          if (!flag) {
            let notification = {
              show: true,
              message:
                "Não é permitido utilizar a senha padrão. Troque sua senha!",
              type: "error",
            };
            commit("showNotification", notification);
          }
        },
        () => {
          let notification = {
            show: true,
            message: "Usuário ou senha inválidos",
            type: "error",
          };
          commit("showNotification", notification);
        }
      );
    },
    buildMenuSearch({ commit, rootGetters }, payload) {
      axios.get(endPoints.menus + "/build_menu_search/" + payload.menu_name).then(
        (response) => {
          let menus = response.data;
          let flag =
            rootGetters["login/getDefaultPassword"] == 1 ? false : true;
          commit("setMenus", { menus: menus });
          commit("setShowMenu", flag);
          if (!flag) {
            let notification = {
              show: true,
              message:
                "Não é permitido utilizar a senha padrão. Troque sua senha!",
              type: "error",
            };
            commit("showNotification", notification);
          }
        },
        () => {
          let notification = {
            show: true,
            message: "Usuário ou senha inválidos",
            type: "error",
          };
          commit("showNotification", notification);
        }
      );
    },
    openMenu({ commit, state }, menu) {
      if (menu.link) {
        if (menu.link != state.path) {
          commit("setPath", menu.link);
          commit("menuMini", { mini: true });
          router.push(menu.link);
        }
      }
    },
    openLink({ commit, state }, url) {
      if (state.path != url) {
        commit("setPath", url);
        commit("menuMini", { mini: true });
        router.push(url);
      }
    },
  },
};
