<template>
  <div>
         <v-dialog :value="showDialog" persistent width="600">
      <v-card>
        <v-card-title>
          <span class="headline">Pasta de Agente</span>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Título*"
                    v-model="title"
                    :error-messages="errors.collect('title')"
                    v-validate="'required'"
                    data-vv-name="title"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-datetime-picker
                    v-model="date_picker"
                    date-format="dd/MM/yyyy"
                    clearText="Limpar"
                    okText="Confirmar"
                    :time-picker-props="timeProps"
                    :readonly="this.mode == 'VIEW'"
                    label="Data *"
                    :textFieldProps="{ outlined: true }"
                  >
                    <template slot="dateIcon">
                      <v-icon>mdi-calendar</v-icon>
                    </template>
                    <template slot="timeIcon">
                      <v-icon>mdi-clock</v-icon>
                    </template>
                  </v-datetime-picker>
                </v-flex>

                <v-flex xs12 sm6 md12>
                  <v-text-field
                    required
                    label="Descrição *"
                    v-model="description"
                    :error-messages="errors.collect('description')"
                    v-validate="'required'"
                    data-vv-name="description"
                    outlined
                  ></v-text-field>
                </v-flex>
                
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-col>
            <v-btn
              text
              @click="setShowDialog(false)"
              >{{ mode == "VIEW" ? "Voltar" : "Cancelar" }}</v-btn
            >
          </v-col>
          <v-col class="text-right">
            <v-btn
              text
              type="submit"
              right
              v-if="mode == 'EDIT' || mode == 'ADD'"
              @click="saveFolder"
              >Salvar</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
    name: 'AgentsFoldersForm',
    methods: {
    ...mapMutations("agents_folders", ["setShowDialog", "setSelectedItem", "setShowDialog"]),
    ...mapActions("agents_folders", ["save"]),
    saveFolder() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          var d = new Date(this.date_picker);
          this.date = d.toISOString().substr(0, 10) + " " + d.toLocaleTimeString();
          this.save();
        }
      });
    },
  },
  computed: {
    ...mapState("agents_folders", [
      "mode",
      "selectedItem",
      "showDialog",
    ]),
    ...mapFields("agents_folders", [
      "selectedItem.id",
      "selectedItem.description",
      "selectedItem.date",
      "selectedItem.title",
      "selectedItem.date_picker",
    ]),
  },
  data: () => ({
    timeProps: {
      format: "24hr",
    },
  }),
}
</script>

<style>

</style>