<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline text-center justify-center">Filtro</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form">
          <v-container grid-list-md>
            <v-layout wrap>
                <v-flex xs12 sm6 md6>
                    <v-select
                    label="Apenas Agentes"
                    :items="possible_targets"
                    v-model="only_agents"
                    item-text="description"
                    item-value="id"
                    outlined
                    ></v-select>
                </v-flex>
                <v-flex xs12 sm6 md6>
                    <v-select
                    label="Tipo de Visualização"
                    :items="possible_views"
                    v-model="view_type"
                    item-text="description"
                    item-value="id"
                    outlined
                    ></v-select>
                </v-flex>
                <v-flex xs12 sm6 md6>
                    <v-select
                    :clearable="true"
                    clear-icon="mdi-close"
                    label="Clube"
                    :items="instances"
                    v-model="club"
                    item-value="id_instance"
                    item-text="name"
                    data-vv-name="name"
                    outlined
                    ></v-select>
                </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field
                  label="ID Agente"
                  v-model="id_agent"
                  data-vv-name="id_agent"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field
                  label="Nick Agente"
                  v-model="nick_agent"
                  data-vv-name="inscription"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field
                  label="ID Sub Agente"
                  v-model="sub_agent_id"
                  data-vv-name="inscription"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field
                  label="Nick Sub Agente"
                  v-model="nick_subagent"
                  data-vv-name="inscription"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-select
                  clear-icon="mdi-close"
                  :clearable="true"
                  label="Tipo de Acerto"
                  :items="possible_types_deal"
                  v-model="type_deal_agent"
                  item-text="description"
                  item-value="id"
                  outlined
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field
                  label="ID Planilha"
                  v-model="id_spreadsheet"
                  data-vv-name="inscription"
                  outlined
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn text @click="showFilter({show: false})">Cancelar</v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn text type="submit" right @click="filter">Filtrar</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "PlayersBalanceFilter",
  computed: {
    ...mapState("players_balance", ["instances"]),
    ...mapFields("players_balance", [
      "filter.club",
      "filter.only_agents",
      "filter.view_type",
      "filter.id_pppoker",
      "filter.id_pppoker_agent",
      "filter.nick",
      "filter.nick_agent",
      "filter.nick_subagent",
      "filter.sub_agent_id",
      "filter.id_agent",
      "filter.id_spreadsheet",
      "filter.type_deal_agent"
      // ...
    ]),
  },
  data: () => ({
    possible_views: [
      { id: 0, description: "Análitico" },
      { id: 1, description: "Consolidado" },
    ],
    possible_targets: [
      { id: 0, description: "Não" },
      { id: 1, description: "Sim" },
    ],
    possible_types_deal: [
      {id: 0, description: "Ganho/Perda"},
      {id: 1, description: "Giro"}, 
      {id: 2, description: "Normal"},
      {id: 3, description: "Fichas Enviadas/Retiradas"},
      {id: 4, description: "Sem Acerto"},
      {id: 5, description: "Sem Acerto/GP"},
    ],
  }),
  methods: {
    ...mapMutations("players_balance", ["showFilter"]),
    ...mapActions("players_balance", [
      "getItems",
    ]),
    filter() {
      this.getItems({ resetPage: true });
      this.showFilter({ show: false });
    },
  },
};
</script>

<style>
</style>