<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2000"
      v-model="showNotification"
    >
      {{ notificationMessage }}
    </v-snackbar>
    <v-toolbar flat class="elevation-5">
      <div>
        <v-toolbar-title>Despesas Recorrentes</v-toolbar-title>
      </div>
      <v-spacer></v-spacer>
      <div class="button-toolbar">
        <!-- <download-excel
          :before-generate="generateSheet"
          :before-finish="finishSheet"
          :name="getSheetName()"
          :fetch="getItemsNoPage"
          :fields="sheet_fields"
          class="excel"
          worksheet="Despesas"
        >
          <v-btn color="black" dark class="ma-2" small
            ><v-icon>mdi-download</v-icon></v-btn
          >
        </download-excel> -->
        <v-tooltip bottom>
          <template v-slot:activator="{on, attrs}">
            <v-btn color="black" dark class="ma-2" @click="downloadXlsx({ show: true })" v-bind="attrs" v-on="on" small>
              <v-icon>mdi-file-excel</v-icon>
            </v-btn>
          </template>
          <span>Gerar Planilha</span>
        </v-tooltip>
        <v-btn
          color="black"
          dark
          class="ma-2"
          @click="showFilter({ show: true })"
          small
          ><v-icon>mdi-filter</v-icon></v-btn
        >
        <v-btn
          color="black"
          dark
          class="ma-2"
          @click="showDialogImportSpreadsheet({ show: true })"
          small
          ><v-icon>mdi-file-excel</v-icon></v-btn
        >
        <v-btn color="black" dark class="ma-2" @click="getItems" small
          ><v-icon>mdi-refresh</v-icon></v-btn
        >
        <v-btn color="black" dark class="ma-2" @click="addRecurrent" small
          ><v-icon>mdi-plus</v-icon></v-btn
        >
      </div>
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :loading="loading"
      :items="items"
      :options.sync="pagination"
      :server-items-length="totalItems"
      no-data-text="Nenhuma despesa encontrada"
      class="elevation-10 table-margin"
      show-expand
      :expanded.sync="expanded"
    >
      <template v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }">
        <v-icon @click="expand(true)" v-if="item.pix_key && !isExpanded"
          >mdi-chevron-down</v-icon
        >
        <v-icon @click="expand(false)" v-if="item.pix_key && isExpanded"
          >mdi-chevron-up</v-icon
        >
      </template>
      <template v-slot:expanded-item="{ item }">
        <td>Chave Pix: {{ item.pix_key }}</td>
      </template>
      <template v-slot:[`item.date`]="{ item }">
        <span>{{ getDate(item.date) }}</span>
      </template>
      <template v-slot:[`item.value`]="{ item }">
        <span>{{
          item.value.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })
        }}</span>
      </template>
      <template v-slot:[`item.variable`]="{ item }">
        <span>{{ getVariable(item.variable) }}</span>
      </template>
      <template v-slot:[`item.recurrence`]="{ item }">
        <span>{{ getRecurrence(item.recurrence) }}</span>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <span>{{ getStatus(item.status) }}</span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ attrs, on }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              small
              class="mr-2"
              @click="viewItem(item)"
              >mdi-information</v-icon
            >
          </template>
          <span>Informações</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ attrs, on }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              small
              class="mr-2"
              @click="editRecurrent({ item: item })"
              >mdi-pencil</v-icon
            >
          </template>
          <span>Editar</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ attrs, on }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              small
              class="mr-2"
              @click="showDeleteDialog(item)"
              >mdi-delete</v-icon
            >
          </template>
          <span>Apagar</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ attrs, on }">
            <v-icon
              v-bind="attrs"
              color='blue'
              v-on="on"
              small
              class="mr-2"
              @click="addExpense({item: item})"
              >mdi-plus</v-icon
            >
          </template>
          <span>Adicionar Despesa</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog :value="dialogRecurrent" width="800" @click:outside="closeDialog">
      <expense-form></expense-form>
    </v-dialog>

    <v-dialog :value="dialog" width="800" @click:outside="closeExpense">
      <expense-real-form></expense-real-form>
    </v-dialog>

    <v-dialog
      :value="dialogFilter"
      width="600"
      @click:outside="showFilter({ show: false })"
    >
      <expense-filter></expense-filter>
    </v-dialog>

    <v-dialog
      :value="dialogImportSpreadsheet"
      @click:outsude="showDialogImportSpreadsheet({ show: false })"
      width="450"
    >
      <v-card>
        <v-card-title class="headline text-center justify-center">
          Importar Planilha
          <v-progress-circular
            v-if="loading"
            indeterminate
            class="text-right"
            color="primary"
            size="24"
          ></v-progress-circular>
        </v-card-title>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12 sm12 md12>
              <v-file-input
                v-model="spreadsheet_import"
                label="Planilha"
                outlined
                dense
              ></v-file-input>
            </v-flex>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-col>
            <v-btn text @click="showDialogImportSpreadsheet({ show: false })"
              >Cancelar</v-btn
            >
          </v-col>
          <v-col class="text-right">
            <v-btn
              text
              type="submit"
              right
              @click="importSpreadsheet({ expense: expenseAutomatic })"
              >Importar</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      :value="dialogAutomaticExpense"
      @click:outsude="showDialogAutomaticExpense({ show: false })"
      width="450"
    >
      <v-card>
        <v-card-title class="headline text-center justify-center">
          Pagar Despesa
          <v-progress-circular
            v-if="loading"
            indeterminate
            class="text-right"
            color="primary"
            size="24"
          ></v-progress-circular>
        </v-card-title>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12 sm12 md12 v-if="this.mode != 'FINISH'">
              <v-select
                label="Banco Pagamento*"
                :items="banksPix"
                v-model="bank"
                :error-messages="errors.collect('bank')"
                :item-text="getTextBank"
                item-value="id"
                data-vv-name="bank"
                outlined
              >
              </v-select>
            </v-flex>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-col>
            <v-btn text @click="showDialogAutomaticExpense({ show: false })"
              >Cancelar</v-btn
            >
          </v-col>
          <v-col>
            <v-btn
              text
              @click="
                finishExpense({ expense: expenseAutomatic, screen: 'expense' })
              "
              >Manual</v-btn
            >
          </v-col>
          <v-col
            class="text-right"
            v-if="expenseAutomatic.value < 10000 || this.userData.role == 99"
          >
            <v-btn
              text
              type="submit"
              right
              @click="finishAutomaticExpense({ expense: expenseAutomatic })"
              >Automático</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog :value="dialogDelete" persistent width="300">
      <v-card>
        <v-card-title class="headline">Confirmar a exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn text @click="closeDeleteDialog()">Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn text type="submit" right @click="deleteItem"
              >Confirmar</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogReceipt"
      @click:outside="setDialogReceipt({ show: false })"
    >
      <v-card height="90vh">
        <v-img max-height="100%" :src="receipt_show" contain></v-img>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import ExpenseRealForm from "../expense/ExpenseForm.vue";
import ExpenseForm from "./ExpenseRecurrentForm";
import ExpenseFilter from "./ExpenseRecurrentFilter";
// import JsonExcel from "vue-json-excel";
import { utils, writeFile } from "xlsx";

export default {
  name: "ExpensesRecurrent",
  computed: {
    ...mapState("bank", { banks: "items" }),
    ...mapState("login", ["userData"]),
    ...mapState("expense_recurrent", [
      "dialogImportSpreadsheet",
      "expenseAutomatic",
      "users",
      "contact_show",
      "dialogRecurrent",
      "dialogAutomaticExpense",
      "dialogImportSpreadsheet",
      "dialogDelete",
      "dialogReceipt",
      "item",
      "instances",
      "companies",
      "errors",
      "loading",
      "mode",
      "notificationType",
      "notificationMessage",
      "rowsPerPage",
      "receipt_show",
      "totalItems",
      "items",
    ]),
    ...mapState("expense", [
      "dialog",
    ]),
    ...mapFields("expense_recurrent", [
      "id_company",
      "expenseAutomatic.bank",
      "spreadsheet_import",
      "pagination",
      "dialogFilter",
      "overlay",
      "showNotification",
      // ...
    ]),
    banksPix() {
      return this.banks.filter((b) => b.pix_key);
    },
  },
  created() {
    this.getCompanies();
    this.getBanks();
    this.getExpenseTypes();
    this.getItems({ resetPage: false });
  },
  components: {
    // "download-excel": JsonExcel,
    "expense-form": ExpenseForm,
    "expense-filter": ExpenseFilter,
    "expense-real-form": ExpenseRealForm
  },
  methods: {
    ...mapActions("bank", { getBanks: "getItems" }),
    ...mapMutations("expense_recurrent", [
      "addRecurrent",
      "editRecurrent",
      "viewItem",
      "showFilter",
      "setDialogReceipt",
      "showDialogAutomaticExpense",
      "showDialogImportSpreadsheet",
    ]),
    ...mapMutations("expense", [
      "addExpense",
      "closeExpense"
    ]),
    ...mapActions("expense_recurrent", [
      "checkFinishExpense",
      "closeDialog",
      "closeDeleteDialog",
      "deleteItem",
      "finishExpense",
      "finishAutomaticExpense",
      "getItems",
      "getItemsNoPage",
      "getUsers",
      "getCompanies",
      "getExpenseTypes",
      "importSpreadsheet",
      "showDeleteDialog",
      "showReceipt",
      "startWithdrawal",
      "stopWithdrawal",
      "scheduleExpense",
    ]),
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      }
      return false;
    },
    getTextBank(item) {
      if (item.account) {
        return item.name + " | " + item.pix_key;
      } else {
        return item.name;
      }
    },
    getStatus(status) {
      return this.possible_status[status];
    },
    getVariable(variable) {
      return this.variables[variable];
    },
    getRecurrence(recurrence) {
      return this.recurrences[recurrence];
    },
    getDate(date) {
      var d = new Date(date);
      return d.toLocaleDateString() + " " + d.toLocaleTimeString();
    },
    finishSheet() {
      this.overlay = false;
    },
    generateSheet() {
      this.overlay = true;
    },
    getSheetName() {
      var date = new Date();
      var name =
        "despesas_" +
        date.toLocaleDateString() +
        " " +
        date.toLocaleTimeString();
      return name + ".xls";
    },
    async downloadXlsx() {
      this.generateSheet()
      try {
        let itemsNoPage = await this.getItemsNoPage()
        if(itemsNoPage) {
          const wb = utils.book_new()
          let possible_variables = ["Custo Fixo", "Custo Variável"];
          let items = itemsNoPage.map(row => ({
            "Data":  this.dateXLSX(row.date),
            "Hora": this.timeXLSX(row.date),
            "Descrição":  row.description,
            "Valor":  this.valueXLSX(row.value),
            "Banco":  row.bank_name,
            "Pago Por":  row.user_name,
            "Plano de Contas":  row.type_description,
            "Tipo":  possible_variables[row.variable],
            "Recorrência":  this.recurrenceXLSX(row.row.recurrence),
            "Observação":  row.observation,
          }))
          const ws = utils.json_to_sheet(items)
          utils.book_append_sheet(wb, ws, "Despesas")
          await writeFile(wb, this.getSheetName())
        }
      } catch (error) {
        this.finishSheet()
      } finally {
        this.finishSheet()
      }
    },
    dateXLSX: (value) => {
      var d = new Date(value);
      return d.toLocaleDateString();
    },
    timeXLSX: (value) => {
      var d = new Date(value);
      return d.toLocaleTimeString();
    },
    valueXLSX: (value) => {
      if (value) {
        let real = value.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });
        return real.substr(3);
      }
      return 0;
    },
    recurrenceXLSX: (value) => {
      if (value) {
        let recurrences = ["Mensal", "Trimestral", "Semestral", "Anual"];
        return recurrences[value];
      }
      return "";
    },
    saveWithdrawal() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.value = parseFloat(this.value);
          this.value = this.value.toFixed(2);
          this.save();
        }
      });
    },
  },
  data: () => ({
    expanded: [],
    headers: [
      {
        text: "Empresa",
        align: "left",
        value: "company_name",
        sortable: false,
      },
      {
        text: "Descrição",
        value: "description",
        sortable: false,
        width: "20%",
      },
      { text: "Valor", value: "value", sortable: false },
      { text: "Banco", value: "bank_name", sortable: false },
      { text: "Plano de Contas", value: "type_description", sortable: false },
      { text: "Tipo", value: "variable", sortable: false },
      { text: "Recorrência", value: "recurrence", sortable: false },
      { text: "Ações", value: "actions", sortable: false, align: "center" },
    ],
    recurrences: ["Mensal", "Trimestral", "Semestral", "Anual"],
    variables: ["Custo Fixo", "Custo Variável"],
    possible_status: ["Pendente", "Agendado", "Pago", "Em Processamento"],
    sheet_fields: {
      Data: {
        field: "date",
        callback: (value) => {
          var d = new Date(value);
          return d.toLocaleDateString();
        },
      },
      Hora: {
        field: "date",
        callback: (value) => {
          var d = new Date(value);
          return d.toLocaleTimeString();
        },
      },
      Descrição: {
        field: "description",
      },
      Valor: {
        field: "value",
        callback: (value) => {
          if (value) {
            let real = value.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
            return real.substr(3);
          }
          return 0;
        },
      },
      Banco: {
        field: "bank_name",
      },
      "Pago Por": {
        field: "user_name",
      },
      "Plano de Contas": {
        field: "type_description",
      },
      Tipo: {
        field: "variable",
        callback: (value) => {
          let variables = ["Custo Fixo", "Custo Variável"];
          return variables[value];
        },
      },
      Recorrência: {
        field: "recurrence",
        callback: (value) => {
          if (value) {
            let recurrences = ["Mensal", "Trimestral", "Semestral", "Anual"];
            return recurrences[value];
          }
          return "";
        },
      },
      Observação: {
        field: "observation",
      },
    },
    valid: false,
  }),
  watch: {
    pagination: {
      handler() {
        this.getItems({ resetPage: false });
      },
    },
  },
};
</script>

<style>
@import "./css/expense.css";
</style>
