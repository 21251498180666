<template>
    <div>
        <v-overlay :value="loading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <v-snackbar
            :color="notification.type"
            right
            top
            :timeout="2000"
            v-model="notification.show"
        >
            {{ notification.message }}
        </v-snackbar>

        <v-toolbar flat class="elevation-5">
            <v-icon color="black" left>mdi-cog</v-icon>
            <v-toolbar-title>Configurações dos Clubes</v-toolbar-title>
            <v-spacer></v-spacer>
            <div class="button-toolbar">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="black"
                            dark
                            class="ma-2"
                            @click="setShowFilter(true)"
                            v-bind="attrs"
                            v-on="on"
                            small
                        >
                            <v-icon color="white">mdi-filter</v-icon>
                        </v-btn>
                    </template>
                    <span>Filtro</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="black"
                            dark
                            class="ma-2"
                            @click="getItems"
                            v-bind="attrs"
                            v-on="on"
                            small
                            ><v-icon>mdi-refresh</v-icon></v-btn
                        >
                    </template>
                    <span>Recarregar</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="black"
                            dark
                            class="ma-2"
                            @click="addConfig"
                            v-bind="attrs"
                            v-on="on"
                            small
                        >
                            <v-icon color="white">mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    <span>Adicionar</span>
                </v-tooltip>
            </div>
        </v-toolbar>

        <v-data-table
            :headers="headers"
            :loading="loading"
            :items="items"
            :options.sync="pagination"
            :server-items-length="totalItems"
            class="elevation-10 table-margin"
            no-data-text="Nenhuma falta encontrada"
        >
            <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ attrs, on }">
                        <v-icon
                            small
                            class="mr-2"
                            v-bind="attrs"
                            v-on="on"
                            @click="view(item)"
                            >mdi-information</v-icon
                        >
                    </template>

                    <span>Informações</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ attrs, on }">
                        <v-icon
                            v-bind="attrs"
                            v-on="on"
                            small
                            class="mr-2"
                            @click="edit({ item: item })"
                            >mdi-pencil</v-icon
                        >
                    </template>
                    <span>Editar</span>
                </v-tooltip>
            </template>

            <template v-slot:[`item.status`]="{ item }">
                <v-chip
                    :color="getColorStatus(item.status)"
                    class="ma-2"
                    text-color="white"
                    label
                    >{{ getStatusText(item.status) }}</v-chip
                >
            </template>
            <template v-slot:[`item.createFichasAccount`]="{ item }">
                <v-chip
                    :color="getColor(item.createFichasAccount)"
                    class="ma-2"
                    text-color="white"
                    label
                    >{{ formatBoolean(item.createFichasAccount) }}</v-chip
                >
            </template>
            <template v-slot:[`item.emailConfirmationApp`]="{ item }">
                <v-chip
                    :color="getColor(item.emailConfirmationApp)"
                    class="ma-2"
                    text-color="white"
                    label
                    >{{ formatBoolean(item.emailConfirmationApp) }}</v-chip
                >
            </template>
            <template v-slot:[`item.emailConfirmationFichas`]="{ item }">
                <v-chip
                    :color="getColor(item.emailConfirmationFichas)"
                    class="ma-2"
                    text-color="white"
                    label
                    >{{ formatBoolean(item.emailConfirmationFichas) }}</v-chip
                >
            </template>
            <template v-slot:[`item.approve`]="{ item }">
                <v-chip
                    :color="getColor(item.approve)"
                    class="ma-2"
                    text-color="white"
                    label
                    >{{ formatBoolean(item.approve) }}</v-chip
                >
            </template>

            <template v-slot:[`item.app`]="{ item }">
                <span class="ma-2">{{ formatString(item.app) }}</span>
            </template>
        </v-data-table>

        <v-dialog :value="dialog" width="600" @click:outside="closeDialog">
            <config-clubs-form></config-clubs-form>
        </v-dialog>

        <v-dialog
            :value="filterDialog"
            @click:outside="setShowFilter(false)"
            width="500"
        >
            <config-clubs-filter></config-clubs-filter>
        </v-dialog>

        <!-- <v-dialog :value="dialogDelete" persistent width="300">
            <v-card>
                <v-card-title class="headline"
                    >Confirmar a exclusão?</v-card-title
                >

                <v-card-actions>
                    <v-col>
                        <v-btn text @click="closeDeleteDialog()"
                            >Cancelar</v-btn
                        >
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text type="submit" right @click="deleteAbsence"
                            >Confirmar</v-btn
                        >
                    </v-col>
                </v-card-actions>
            </v-card>
        </v-dialog> -->
    </div>
</template>
  
  <script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import ConfigClubsFilter from './ConfigClubsFilter.vue'
import ConfigClubsForm from './ConfigClubsForm.vue'

export default {
    name: 'ConfigClubs',

    created() {
        this.getItems({ resetPage: false })
    },

    components: {
        ConfigClubsForm,
        ConfigClubsFilter,
    },

    methods: {
        ...mapActions('custom_clubs', ['getItems', 'closeDialog']),
        ...mapMutations('custom_clubs', [
            'edit',
            'view',
            'addConfig',
            'setShowFilter',
        ]),

        formatString(value) {
            return value[0].toUpperCase() + value.slice(1).toLowerCase()
        },
        formatBoolean(value) {
            return value ? 'Sim' : 'Não'
        },
        getColor(value) {
            if (value == true) {
                return 'green'
            } else {
                return 'red'
            }
        },
        getColorStatus(status) {
            if (status == 20) {
                return 'orange'
            } else if (status == 21) {
                return 'blue'
            } else if (status == 22) {
                return 'green'
            } else if (status == 90) {
                return 'red'
            } else if (status == 91) {
                return 'red'
            } else {
                return 'red'
            }
        },
        getStatusText(status) {
            if (status == 20) {
                return 'Configuração Criada'
            } else if (status == 21) {
                return 'Criado no Cloudflare'
            } else if (status == 22) {
                return 'Configurado'
            } else if (status == 90) {
                return 'Erro no Cloudflare'
            } else if (status == 91) {
                return 'Erro no Jenkins'
            } else {
                return 'Erro Desconhecido'
            }
        },
    },
    computed: {
        ...mapState('login', ['userData']),
        ...mapState('custom_clubs', [
            'dialog',
            'items',
            'notification',
            'mode',
            'loading',
            'totalItems',
            'filterDialog',
            'possible_types',
            'dialogDelete',
            'filterDialog',
        ]),
        ...mapFields('custom_clubs', ['pagination']),
    },
    data: () => ({
        headers: [
            {
                text: 'Domínio',
                value: 'nameDomain',
                sortable: true,
                align: 'left',
            },
            {
                text: 'Agente',
                value: 'agent',
                sortable: true,
                align: 'left',
            },
            {
                text: 'Aplicativo',
                value: 'app',
                sortable: true,
                align: 'left',
            },
            { text: 'Clube', value: 'club', sortable: true, align: 'left' },
            {
                text: ' Aprova Automaticamente',
                value: 'approve',
                sortable: true,
                align: 'center',
            },
            {
                text: 'Cria Conta no Fichas',
                value: 'createFichasAccount',
                sortable: true,
                align: 'center',
            },
            {
                text: 'Confirma E-mail no App',
                value: 'emailConfirmationApp',
                sortable: true,
                align: 'center',
            },
            {
                text: 'Confirma E-mail no Fichas',
                value: 'emailConfirmationFichas',
                sortable: true,
                align: 'center',
            },
            {
                text: 'Status',
                value: 'status',
                sortable: true,
                align: 'center',
            },
            {
                text: 'Ações',
                value: 'actions',
                sortable: false,
                align: 'center',
            },
        ],
    }),

    watch: {
        pagination: {
            handler() {
                this.getItems({ resetPage: false })
            },
        },
    },
}
</script>
  