<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline">Filtro</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm6 md6>
                <v-text-field
                  required
                  label="ID Usuário"
                  v-model="id"
                  data-vv-name="id"
                  clear-icon="mdi-close"
                  :clearable="true"
                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-text-field
                  required
                  label="Nome"
                  v-model="name"
                  data-vv-name="name"
                  clear-icon="mdi-close"
                  :clearable="true"
                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-text-field
                  required
                  label="Email"
                  v-model="email"
                  data-vv-name="email"
                  clear-icon="mdi-close"
                  :clearable="true"
                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-select
                  label="Status"
                  :items="possible_status"
                  v-model="status"
                  item-text="status"
                  item-value="id"
                  clear-icon="mdi-close"
                  :clearable="true"
                  outlined
                ></v-select>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-select
                  label="Tipo"
                  :items="possible_types"
                  v-model="type"
                  item-text="type"
                  item-value="id"
                  clear-icon="mdi-close"
                  :clearable="true"
                  outlined
                ></v-select>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-select
                  label="Senha Padrão"
                  :items="is_default_password"
                  v-model="default_password"
                  item-text="text"
                  item-value="id"
                  clear-icon="mdi-close"
                  :clearable="true"
                  outlined
                ></v-select>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn text @click="showFilter({ show: false }), clearFilter()">Cancelar </v-btn>
        </v-col>
        <v-col class="text-center">
          <v-btn
            color="white"
            class="ma-2"
            @click="clearFilter()"
            right
            small
            depressed
          >
            <v-icon color="black">mdi-filter-remove</v-icon>
          </v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn text type="submit" right @click="filter">Filtrar</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "UsersFilter",
  computed: {
    ...mapState("agent_users", [
      "possible_status",
      "possible_types",
      "is_default_password",
    ]),
    ...mapFields("agent_users", [
      "filter.id",
      "filter.name",
      "filter.status",
      "filter.type",
      "filter.default_password",
      "filter.email",
      // ...
    ]),
  },
  methods: {
    ...mapMutations("agent_users", ["showFilter", "clearFilter"]),
    ...mapActions("agent_users", ["getItems"]),
    filter() {
      this.getItems({ resetPage: false });
      this.showFilter({ show: false });
    },
  },
};
</script>
