<template>
  <div>
    <div @drop.prevent="addDropFile" @dragover.prevent>
      <v-card>
        <v-card-title>
          <span class="headline text-center">Ajustar Conta</span>
          <v-spacer></v-spacer>
          <v-progress-circular
            v-if="loading"
            indeterminate
            class="text-right"
            color="primary"
            size="24"
          ></v-progress-circular>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm6 md6>
                  <v-select
                    label="Tipo"
                    :items="types"
                    v-model="type"
                    :readonly="true"
                    :error-messages="errors.collect('type')"
                    item-text="description"
                    item-value="id"
                    data-vv-name="type"
                    outlined
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-select
                    label="Banco *"
                    v-model="bank"
                    :items="banks"
                    :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                    v-validate="'required:Banco,bank'"
                    :error-messages="errors.collect('bank')"
                    :item-text="getTextBank"
                    item-value="id"
                    data-vv-name="bank"
                    outlined
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Valor"
                    v-model="value"
                    :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                    :error-messages="errors.collect('value')"
                    v-validate="'required:Valor,value|decimal:2'"
                    data-vv-name="value"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-select
                    label="Clube"
                    clear-icon="mdi-close"
                    :clearable="true"
                    :items="instances"
                    v-model="id_instance"
                    :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                    :error-messages="errors.collect('id_instance')"
                    item-text="name"
                    item-value="id"
                    data-vv-name="id_instance"
                    outlined
                  ></v-select>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-col>
            <v-btn text @click="closeDialog">{{ mode == 'VIEW' ? 'Voltar' : 'Cancelar'}}</v-btn>
          </v-col>
          <v-col class="text-right" v-if="mode != 'VIEW'">
            <v-btn text type="submit" right @click="saveFinancialMoviment">Salvar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "FinancialMovimentsForm",
  computed: {
    ...mapState("bank", { banks: "items" }),
    ...mapState("instances", ["instances"]),
    ...mapState("financial_moviments", [
      "item",
      "loading",
      "mode",
    ]),
    ...mapFields("financial_moviments", [
      "editedItem.id_instance",
      "editedItem.value",
      "editedItem.bank",
      "editedItem.type"
      // ...
    ]),
  },
  created() {
    this.$validator.extend("required", {
      getMessage: (field, [label]) => label + " deve ser informado",
      validate: (_, [, field]) => {
        if (this[field]) {
          return true;
        } else {
          return false;
        }
      },
    });
  },
  methods: {
    ...mapMutations("financial_moviments", ["closeDialog"]),
    ...mapActions("financial_moviments", [
      "save",
    ]),
    getTextBank(item) {
      if(item.account) {
        return item.name + ' | ' + item.account
      } else {
        return item.name
      }
    },
    saveFinancialMoviment() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.value = parseFloat(this.value);
          this.save();
        }
      });
    },
  },
  data: () => ({
    valid: false,
    types: [
      { id: 0, description: 'Saque' },
      { id: 1, description: 'Despesa' },
      { id: 2, description: 'Depósito' },
      { id: 3, description: 'Ajuste' },
      { id: 4, description: 'Receita' }
    ]
  }),
};
</script>
