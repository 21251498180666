<template>
  <div>
    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2000"
      v-model="showNotification"
    >
      {{ notificationMessage }}
    </v-snackbar>
    <v-list-item class="text-center justify-center elevation-10 agent-data">
      <v-list-item-content>
        <v-list-item-title class="headline">Emails</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-tabs v-model="tab" class="agent-data elevation-10">
      <v-tab>
        <v-badge
          color="deep-purple accent-4"
          icon="mdi-at"
        >
          Emails Registrados
        </v-badge>
      </v-tab>

      <v-tab>
        <v-badge
          color="green"
          icon="mdi-email-send"
        >
          Emails Enviados
        </v-badge>
      </v-tab>
    </v-tabs> 
    <v-tabs-items v-model="tab">

      <v-tab-item class="elevation-10">
        <v-card> 
          <v-card-title class="text-center justify-center">
            <v-icon
              color="deep-purple accent-4"
            >
              mdi-at
            </v-icon>
            Emails Registrados
            <v-spacer></v-spacer>
            <div class="button-toolbar">
              <v-btn color="black" dark class="ma-2" @click="showFilterEmailRegister({show: true})" small><v-icon>mdi-filter</v-icon></v-btn>
              <v-btn color="black" dark class="ma-2" @click="getEmailsRegister" small><v-icon>mdi-refresh</v-icon></v-btn>
              <v-btn color="black" dark class="ma-2" @click="addEmailRegister" small><v-icon>mdi-plus</v-icon></v-btn>
            </div> 
          </v-card-title>
            
          <v-data-table
            :headers="headersEmailRegister"
            :loading="loading"
            :items="emailsRegister"
            :options.sync="pagination"
            :server-items-length="totalEmailsRegister"
            no-data-text="Nenhum email registrado"
            class="elevation-10 table-margin"
          >
            <template v-slot:[`item.actions`]="{ item }">

              <v-tooltip bottom>
                <template v-slot:activator="{on, attrs}">
                  <v-icon small v-on="on" v-bind="attrs" class="mr-2" @click="viewEmailRegister(item)">mdi-information</v-icon>
                </template>
                <span>Informações</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{on, attrs}">        
                  <v-icon small v-on="on" v-bind="attrs" class="mr-2" @click="editEmailRegister({item: item})">mdi-pencil</v-icon>
                </template>
                <span>Editar</span>
              </v-tooltip>

              <v-tooltip bottom> 
                <template v-slot:activator="{on, attrs}">    
                  <v-icon small v-on="on" v-bind="attrs" class="mr-2" @click="showDeleteEmailRegister(item)">mdi-delete</v-icon>
                </template>
                <span>Apagar</span>
              </v-tooltip>

            </template>

            <template v-slot:[`item.type`]="{ item }">
              <v-chip
                :color="getColor(item)"
                dark
              >
                {{ getType( item.type ) }}
              </v-chip>
            </template>
            
          </v-data-table>

        </v-card>
      </v-tab-item>

      <v-tab-item color="deep-purple accent-4" class="elevation-10">
        <v-card> 
          <v-card-title class="text-center justify-center verde">
            <v-icon
              color="green"
            >
              mdi-email-send
            </v-icon>
            Emails Enviados
          </v-card-title>

        </v-card>
      </v-tab-item>

    </v-tabs-items>
  </div>
</template>

<script>
// import debounce from '../../../util/debounce.js' 
// import { mapActions, mapMutations, mapState } from "vuex";
// import { mapFields } from "vuex-map-fields"; 
export default {
  data: () => ({
    tab: '',
    headers: [
      { text: "Destinatário",  value: "name", sortable: false, align: "center"},
      { text: "Email",  value: "email", sortable: false, align: "center" },
      { text: "Mensagem",  value: "message", sortable: false, align: "center"},
      { text: "Ações",  value: "actions", sortable: false, align: "center" }
    ],
  }),
};
</script>

<style>
</style>