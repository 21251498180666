<template>
  <v-card>
    <v-card-title>
      <span class="headline">Adicionar Usuário</span>
    </v-card-title>

    <v-card-text>
      <v-form ref="form" lazy-validation>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12 sm12 md12>
              <v-text-field
                required
                label="Nome *"
                v-model="name"
                :error-messages="errors.collect('name')"
                data-vv-name="name"
                v-validate="'required:Nome,name'"
                outlined
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm12 md12>
              <v-text-field
                autocomplete="chrome-off"
                required
                label="Email *"
                v-model="email"
                :error-messages="errors.collect('email')"
                data-vv-name="email"
                v-validate="'email:Email,email'"
                outlined
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 md6>
              <v-select
                label="Status *"
                clear-icon="mdi-close"
                v-model="status"
                :items="possible_status"
                :error-messages="errors.collect('status')"
                data-vv-name="status"
                v-validate="'required:Status,status'"
                item-text="status"
                item-value="id"
                outlined
              ></v-select>
            </v-flex>

            <v-flex xs12 sm6 md6>
              <v-select
                required
                label="Tipo *"
                clear-icon="mdi-close"
                v-model="type"
                :items="possible_types"
                :error-messages="errors.collect('type')"
                v-validate="'required:Tipo,type'"
                item-text="type"
                item-value="id"
                data-vv-name="type"
                outlined
              ></v-select>
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-col>
        <v-btn text @click="closeDialog">Cancelar</v-btn>
      </v-col>
      <v-col class="text-right">
        <v-btn text type="submit" right @click="saveUser">Salvar</v-btn>
      </v-col>
    </v-card-actions>
  </v-card>
  
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
export default {
  name: "UsersForm",
  created() {
    this.$validator.extend("required", {
      getMessage: (field, [label]) => label + " deve ser informado",
      validate: (_, [, field]) => {
        if (
          (this[field] || this[field] === 0) &&
          this[field] !== "" &&
          this[field] !== null
        ) {
          return true;
        } else {
          return false;
        }
      },
    });
  },
  computed: {
    ...mapState("agent_users", [
      "loading",
      "items",
      "selectedItem",
      "totalItems",
      "dialog",
      "dialogFilter",
      "possible_types",
      "possible_status",
      "mode",
    ]),
    ...mapFields("agent_users", [
      "pagination",
      "selectedItem.name",
      "selectedItem.email",
      "selectedItem.type",
      "selectedItem.status",
      "selectedItem.id_agent",
    ]),
  },
  methods: {
    ...mapMutations("agent_users", [
      "add",
      "edit"
    ]),
    ...mapActions("agent_users", [
      "getItems",
      "save",
      "closeDialog",
    ]),
    saveUser() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.save();
        }
      });
    },
  },
  data: () => ({
    valid: false,
  }),
};
</script>
