<template>
    <div>
        <v-card>
            <v-card-title color="red">
                <span class="headline text-center justify-center">Filtro</span>

                <v-col class="text-right">
                    <v-btn
                        v-if="filtered == true"
                        color="white"
                        class="ma-2"
                        @click="clearFilter"
                        right
                        small
                        depressed
                    >
                        <v-icon color="black">mdi-filter-remove</v-icon>
                    </v-btn>
                </v-col>
            </v-card-title>

            <v-card-text>
                <v-form ref="form">
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12 sm6 md6>
                                <v-text-field
                                    required
                                    label="ID"
                                    v-model="id"
                                    data-vv-name="id"
                                    outlined
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-text-field
                                    required
                                    label="CPF/CNPJ"
                                    v-model="pix_identifier"
                                    outlined
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-text-field
                                    required
                                    label="Identificador"
                                    v-model="endToEndId"
                                    outlined
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-autocomplete
                                    label="Banco"
                                    :items="banks"
                                    v-model="bank"
                                    :item-text="getTextBank"
                                    item-value="id"
                                    clear-icon="mdi-close"
                                    :clearable="true"
                                    outlined
                                >
                                </v-autocomplete>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-autocomplete
                                    label="Plataforma"
                                    :items="plataforms"
                                    v-model="plataform"
                                    item-text="description"
                                    item-value="id"
                                    outlined
                                    clearable
                                    @change="id_instance = null"
                                ></v-autocomplete>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-autocomplete
                                    :clearable="true"
                                    clear-icon="mdi-close"
                                    label="Clube"
                                    :disabled="!plataform && plataform != 0"
                                    :items="clubsByPlatform"
                                    v-model="id_instance"
                                    item-text="name"
                                    item-value="id"
                                    multiple
                                    outlined
                                ></v-autocomplete>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-datetime-picker
                                    v-model="start"
                                    date-format="dd/MM/yyyy"
                                    clearText="Limpar"
                                    okText="Confirmar"
                                    :time-picker-props="timeProps"
                                    label="De *"
                                    :textFieldProps="{ outlined: true }"
                                >
                                    <template slot="dateIcon">
                                        <v-icon>mdi-calendar</v-icon>
                                    </template>
                                    <template slot="timeIcon">
                                        <v-icon>mdi-clock</v-icon>
                                    </template>
                                </v-datetime-picker>
                            </v-flex>

                            <v-flex xs12 sm6 md6>
                                <v-datetime-picker
                                    v-model="end"
                                    date-format="dd/MM/yyyy"
                                    clearText="Limpar"
                                    okText="Confirmar"
                                    label="Até *"
                                    :time-picker-props="timeProps"
                                    :textFieldProps="{ outlined: true }"
                                >
                                    <template slot="dateIcon">
                                        <v-icon>mdi-calendar</v-icon>
                                    </template>
                                    <template slot="timeIcon">
                                        <v-icon>mdi-clock</v-icon>
                                    </template>
                                </v-datetime-picker>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-text-field
                                    required
                                    label="Valor"
                                    v-model="value"
                                    outlined
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-select
                                    clear-icon="mdi-close"
                                    :clearable="true"
                                    label="Status"
                                    :items="possible_status"
                                    v-model="status"
                                    item-text="description"
                                    item-value="id"
                                    outlined
                                ></v-select>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-text-field
                                    required
                                    label="Id Suprema"
                                    v-model="id_pppoker"
                                    outlined
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-text-field
                                    required
                                    label="Nick"
                                    v-model="nick"
                                    outlined
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-select
                                    clear-icon="mdi-close"
                                    :clearable="true"
                                    label="Tipo de Pagamento"
                                    :items="payment_types"
                                    v-model="payment_type"
                                    item-text="description"
                                    item-value="id"
                                    outlined
                                ></v-select>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-select
                                    clear-icon="mdi-close"
                                    :clearable="true"
                                    label="Fichas"
                                    :items="possible_types"
                                    v-model="type"
                                    item-text="description"
                                    item-value="id"
                                    outlined
                                ></v-select>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-select
                                    clear-icon="mdi-close"
                                    :clearable="true"
                                    label="Validação"
                                    :items="validations"
                                    v-model="validation"
                                    item-text="description"
                                    item-value="id"
                                    outlined
                                ></v-select>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-select
                                    clear-icon="mdi-close"
                                    :clearable="true"
                                    label="Status do Envio"
                                    :items="possible_type_sends"
                                    v-model="type_send"
                                    item-text="description"
                                    item-value="id"
                                    outlined
                                ></v-select>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-col>
                    <v-btn text @click="showFilter({ show: false })"
                        >Cancelar</v-btn
                    >
                </v-col>
                <v-col class="text-right">
                    <v-btn text type="submit" right @click="filter"
                        >Filtrar</v-btn
                    >
                </v-col>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import debounce from '../../../util/debounce.js'

export default {
    name: 'DepositsFilter',
    computed: {
        ...mapState('bank', { banks: 'items' }),
        ...mapState('deposit', ['filtered']),
        ...mapState('slots', ['slots']),
        ...mapFields('deposit', [
            'filter.id',
            'filter.code',
            'filter.bank',
            'filter.id_instance',
            'filter.plataform',
            'filter.start',
            'filter.end',
            'filter.value',
            'filter.status',
            'filter.id_pppoker',
            'filter.nick',
            'filter.type',
            'filter.type_send',
            'filter.payment_type',
            'filter.validation',
            'filter.pix_identifier',
            'filter.endToEndId'
            // ...
        ]),
        clubsByPlatform() {
            console.log(this.plataform)
            return this.slots.filter((slot) => slot.plataform_id == this.plataform);
        },
    },
    data: () => ({
        showStart: false,
        showEnd: false,
        start_formatted: '',
        end_formatted: '',
        plataforms: [
            { id: 0, description: "Suprema Poker" },
            { id: 1, description: "Pppoker" },
            { id: 2, description: "Cacheta"},
        ],
        payment_types: [
            { id: 0, description: 'Transferência' },
            { id: 3, description: 'Pix' },
            { id: 1, description: 'Anotado' },
            { id: 2, description: 'Troca de Ticket' },
            { id: 4, description: 'Bônus' },
            { id: 5, description: 'Ganhos/Perdas' },
            { id: 7, description: 'Reembolso' },
        ],
        possible_status: [
            { id: 0, description: 'Pendente' },
            { id: 10, description: 'Pendente de Aprovação Interna' },
            { id: 1, description: 'Em Andamento' },
            { id: 2, description: 'Concluido' },
            { id: 3, description: 'Estornado' },
            { id: 5, description: 'Agendado/Erro' },
            { id: 6, description: 'Validado' },
            { id: 7, description: 'Recusado' },
            { id: 8, description: 'Concluido com Erro' },
            { id: 9, description: 'Recusado SX' },
        ],
        possible_types: [
            { id: 0, description: 'Envio de Fichas Jogador' },
            { id: 1, description: 'Envio de Fichas Agência' },
            { id: 2, description: 'Abater Anotados' },
            { id: 3, description: 'Adicionar ao Saldo Positivo' },
            { id: 4, description: 'Transferência de Fichas' },
            { id: 5, description: 'Bônus' },
        ],
        possible_type_sends: [
            { id: 0, description: 'Pendente' },
            { id: 1, description: 'Solicitado' },
            { id: 2, description: 'Realizado' },
            { id: 3, description: 'Erro Bot' },
            { id: 4, description: 'Manual - PB' },
            { id: 5, description: 'Realizado - PB' },
            { id: 6, description: 'Duplicado' },
        ],
        validations: [
            { id: 0, description: 'Pendente' },
            { id: 1, description: 'Automática' },
            { id: 2, description: 'Erro' },
            { id: 3, description: 'Não encontrado' },
            { id: 4, description: 'Duplicado' },
            { id: 5, description: 'Valor Diferente' },
            { id: 6, description: 'Manual - Sistema' },
        ],
        searchInput: '',
        timeProps: {
            format: '24hr',
        },
    }),
    mixins: [debounce],
    mounted() {
        this.getUsers = this.debounce(this.getUsers, 500)
    },
    methods: {
        ...mapMutations('deposit', ['showFilter']),
        ...mapActions('deposit', ['getItems', 'getUsers', 'clearFilter']),
        clearStart() {
            this.start = ''
            this.start_formatted = ''
        },
        clearEnd() {
            this.end = ''
            this.end_formatted = ''
        },
        getTextBank(item) {
            if (item.account) {
                return item.name + ' | ' + item.account
            } else {
                return item.name
            }
        },
        filter() {
            this.getItems({ resetPage: true })
            this.showFilter({ show: false })
        },
        formatDate(date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`
        },
        setStart(date) {
            this.start = date
            this.showStart = false
            this.start_formatted = this.formatDate(date)
        },
        setEnd(date) {
            this.end = date
            this.showEnd = false
            this.end_formatted = this.formatDate(date)
        },
    },
    watch: {
        searchInput: {
            handler() {
                if (this.searchInput) {
                    var payload = {
                        search: this.searchInput,
                    }
                    this.getUsers(payload)
                }
            },
        },
    },
}
</script>

<style>
</style>