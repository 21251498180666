<template>
  <div>
    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2000"
      v-model="showNotification"
    >
      {{ notificationMessage }}
    </v-snackbar>
    <v-list-item class="card-manager text-center justify-center elevation-10 manager-data">
      <v-list-item-content>
        <v-list-item-title class="headline">Manager {{ manager.nick }}</v-list-item-title>
        <v-list-item-subtitle>
          <div>ID PPPoker: {{ manager.id_pppoker }}</div>
          <div>Nick: {{ manager.nick }}</div>
          <div>Tipo de Manager: {{ getTypeManager(manager.type_manager) }}</div>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-tabs v-model="tab" class="manager-data elevation-10">
      <v-tab>Agentes</v-tab>
      <v-tab>Saldo</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item class="elevation-10">
        <v-card> 
          <v-card-title class="text-center justify-center">
              Agentes
          </v-card-title>
          <v-btn color="black"  @click="addAgentManager" dark class="add-align-right" small>
              <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-data-table
            :headers="headersAgentManager"
            :loading="loadingAgentManager"
            :items="agents_manager"
            :options.sync="paginationAgentManager"
            :server-items-length="totalAgentManagers"
            no-data-text="Nenhum agente cadastrado para o gestor"
            class="elevation-1 table-submanager"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="showDeleteDialogAgentManager(item)">mdi-delete</v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
      <v-tab-item class="elevation-10">
        <balance-manager :manager_id="manager_id"></balance-manager>
      </v-tab-item>
    </v-tabs-items>

    <v-dialog :value="dialogSubManagers" @click:outside="closeDialogAgentManager" width="1280">
      <agent-manager-form></agent-manager-form>
    </v-dialog>
    

    <v-dialog :value="dialogDeleteAgentManager" @click:outside="closeDeleteDialogAgentManager" width="450">
      <v-card>
        <v-card-title class="headline">Confirmar exclusão?</v-card-title>
         <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm12 md12>
                <v-select
                  v-model="typeDeleteAgentManager"
                  :items="types_delete"
                  outlined
                  item-value="id"
                  item-text="description"
                  label="Sub-Agentes"
                ></v-select>
              </v-flex>
            </v-layout>
         </v-container>
        <v-card-actions>
          <v-col>
            <v-btn
              text
              @click="closeDeleteDialogAgentManager()"
            >Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn
              text
              type="submit"
              right
              @click="deleteAgentManager"
            >Confirmar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from 'vuex-map-fields'
import BalanceManager from './balance_manager/BalanceManager'
import AgentManagerForm from './agents_manager/AgentManagerForm'

export default {
  name: "Manager",
  computed: {
    ...mapState("manager", [
      "manager",
      "documents",
      "loading_documents",
      "notificationMessage",
      "notificationType",
      "showNotification"
    ]),
    ...mapState("agent_manager", {
      agents_manager: "items",
      loadingAgentManager: "loading",
      dialogSubManagers: "dialog",
      dialogDeleteAgentManager: "dialogDelete",
      totalAgentManagers: "totalItems"
    }),
    ...mapFields("agent_manager", {
      paginationAgentManager: "pagination",
      typeDeleteAgentManager: "type_delete" 
    })
  },
  
  components: {
    "balance-manager": BalanceManager,
    "agent-manager-form": AgentManagerForm
  },
  created() {
    let manager_id = this.$route.params.manager_id;
    this.manager_id = manager_id;
    this.getManager({ manager_id: manager_id });
    this.getAgentsManager({manager_id: manager_id});
  },
  data: () => ({
    headersDocuments: [
      {
        text: "Nome",
        value: "name",
      },
    ],
    headersAgentManager: [
      { text: "Nick", value: "nick", sortable: false },
      { text: "Ações", value: "actions", sortable: false, align: 'right'}
    ],
    types_delete: [
      { id: 0, description: "Remover"},
      { id: 1, description: "Manter"}
    ],
    tab: ''
  }),
  methods: {
    ...mapMutations("agent_manager", {
      addAgentManager: "add",
      closeDialogAgentManager: "closeDialog",
      editAgentManager: "edit",
    }),
    ...mapActions("agent_manager", {
      closeDeleteDialogAgentManager: "closeDeleteDialog",
      deleteAgentManager: "deleteItem",
      getAgentsManager: "getItems",
      showDeleteDialogAgentManager: "showDeleteDialog"
    }),
    ...mapActions("manager", ["getManager", "goBack"]),
    getTypeManager(type) {
      let types = ["Interno", "Externo"];
      if (types[type]) {
        return types[type];
      }
      return "";
    }
  },
  watch: {
    paginationAgentManager: {
      handler () {
        this.getAgentsManager({resetPage: false, manager_id: this.manager_id})
      },
    }
  }
};
</script>
