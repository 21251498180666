<template>
    <div>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <v-snackbar
            :color="notificationType"
            top
            center
            :timeout="3000"
            v-model="showNotification"
            >{{ notificationMessage }}</v-snackbar
        >
        <v-toolbar flat class="elevation-5">
            <div>
                <v-toolbar-title>Auto Atendimentos</v-toolbar-title>
            </div>
            <v-spacer></v-spacer>
            <div class="button-toolbar">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="black"
                            dark
                            class="ma-2"
                            @click="downloadXlsx({ show: true })"
                            v-bind="attrs"
                            v-on="on"
                            small
                        >
                            <v-icon>mdi-file-excel</v-icon>
                        </v-btn>
                    </template>
                    <span>Gerar Planilha</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="black"
                            dark
                            class="ma-2"
                            @click="showFilter({ show: true })"
                            small
                            v-on="on"
                            v-bind="attrs"
                        >
                            <v-icon :color="filtered ? 'grey' : 'white'"
                                >mdi-filter</v-icon
                            >
                        </v-btn>
                    </template>
                    <span>Filtro</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="black"
                            dark
                            class="ma-2"
                            @click="getItems"
                            small
                            v-on="on"
                            v-bind="attrs"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Recarregar</span>
                </v-tooltip>
            </div>
        </v-toolbar>

        <v-data-table
            :headers="headers"
            :loading="loading"
            :items="items"
            :options.sync="pagination"
            :server-items-length="totalItems"
            :item-class="getClassColor"
            no-data-text="Nenhuma auto atendimento encontrado"
            class="elevation-10 table-margin"
            :footer-props="{
                'items-per-page-options': [10, 20, 50, 100],
                'items-per-page-text': 'Itens por página',
                'show-first-last-page': true,
                'page-text': `Página ${pagination.page} de ${
                    parseInt(totalItems / pagination.itemsPerPage) + 1
                }`,
            }"
            :expanded.sync="expanded"
        >
            <template v-slot:[`item.value`]="{ item }">
                <span>{{
                    item.value ? parseFloat(item.value).toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                    }) : 'R$ 0,00'
                }}</span>
            </template>
            <template v-slot:[`item.createdAt`]="{ item }">
                <span>{{ getDate(item.createdAt) }}</span>
            </template>
            <template v-slot:[`item.status`]="{ item }">
                <v-chip
                    :color="getColorStatus(item.status)"
                    class="ma-2"
                    :text-color="item.validation_state != 0 ? 'white' : 'black'"
                    label
                    >{{ item.status }}</v-chip
                >
            </template>
            <template v-slot:[`item.idClientUser`]="{ item }">
                <v-chip
                    :color="item.idClientUser ? 'green' : 'red'"
                    class="ma-2"
                    :text-color="'white'"
                    label
                    >{{ item.idClientUser ? 'Sim' : 'Não' }}
                </v-chip>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            v-on="on"
                            v-bind="attrs"
                            class="mr-2"
                            @click="showDialogInfo({ item, show: true, mode: 'VIEW' })"
                            >mdi-information</v-icon
                        >
                    </template>
                    <span>Infoelse rmações</span>
                </v-tooltip>
                <v-tooltip v-if="item.status == 'Solicitado' || item.status == 'Cancelado'" bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            v-on="on"
                            v-bind="attrs"
                            class="mr-2"
                            @click="checkPayment({ item })"
                            >mdi-bank-transfer</v-icon
                        >
                    </template>
                    <span>Verificar Pagamento</span>
                </v-tooltip>
                <v-tooltip v-if="item.status == 'Aguardando liberação'" bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            v-on="on"
                            v-bind="attrs"
                            class="mr-2"
                            @click="showDialogReleaseAutoAttendance({ item, show: true })"
                            >mdi-shield-search</v-icon
                        >
                    </template>
                    <span>Analisar Envio</span>
                </v-tooltip>
                <v-tooltip v-if="item.status == 'Erro'" bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            v-on="on"
                            v-bind="attrs"
                            class="mr-2"
                            @click="showDialogEditAndResend({ item, show: true })"
                            >mdi-robot</v-icon
                        >
                    </template>
                    <span>Editar e Reenviar</span>
                </v-tooltip>
                <v-tooltip bottom v-if="item.phone">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            v-on="on"
                            v-bind="attrs"
                            class="mr-2"
                            @click="openWhatsapp({ phone: item.phone })"
                            >mdi-whatsapp</v-icon
                        >
                    </template>
                    <span>Whatsapp</span>
                </v-tooltip>
            </template>
        </v-data-table>

        <v-dialog
            :value="dialogFilter"
            width="600"
            @click:outside="showFilter({ show: false })"
            @keydown.esc="showFilter({ show: false })"
            @keydown.enter="filter"
        >
            <auto-attendance-filter></auto-attendance-filter>
        </v-dialog>

        <v-dialog
            :value="dialogReleaseAutoAttendance"
            width="1000"
            @click:outside="showDialogReleaseAutoAttendance({ show: false, item: {}, mode: 'LIST' })"
            @keydown.esc="showDialogReleaseAutoAttendance({ show: false, item: {}, mode: 'LIST' })"
            @keydown.enter="filter"
        >
            <auto-attendance-release></auto-attendance-release>
        </v-dialog>

        <v-dialog
            :value="dialogEditAndResend"
            width="1000"
            @click:outside="showDialogEditAndResend({ show: false, item: {}, mode: 'LIST' })"
            @keydown.esc="showDialogEditAndResend({ show: false, item: {}, mode: 'LIST' })"
            @keydown.enter="filter"
        >
            <auto-attendance-resend></auto-attendance-resend>
        </v-dialog>

        <v-dialog
            :value="dialogInfo"
            width="1000"
            @click:outside="showDialogInfo({ show: false, item: {}, mode: 'LIST' })"
            @keydown.esc="showDialogInfo({ show: false, item: {}, mode: 'LIST' })"
        >
            <auto-attendance-detail></auto-attendance-detail>
        </v-dialog>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import AutoAttendanceFilter from './AutoAttendanceFilter'
import AutoAttendanceRelease from './AutoAttendanceRelease.vue';
import AutoAttendanceResend from './AutoAttendanceResend.vue';
import AutoAttendanceDetail from './AutoAttendanceDetail.vue';

export default {
    name: 'AutoAttendance',
    computed: {
        ...mapState('auto_attendance', [
            'items',
            'totalItems',
            'expanded',
            'notificationMessage',
            'notificationType',
            'filtered',
            'total_value',
            'avg_time',
        ]),
        ...mapFields('auto_attendance', ['dialogInfo', 'dialogFilter', 'dialogEditAndResend', 'dialogReleaseAutoAttendance', 'loading', 'mode', 'overlay', 'pagination', 'showNotification']),
    },
    created() {
        this.wathAutoAttendance()
        this.getClubs({ resetPage: false, noPages: true, onlyActive: true});
    },
    components: {
        'auto-attendance-filter': AutoAttendanceFilter,
        'auto-attendance-release': AutoAttendanceRelease,
        'auto-attendance-resend': AutoAttendanceResend,
        'auto-attendance-detail': AutoAttendanceDetail
    },
    methods: {
        ...mapMutations('auto_attendance', [
            'showDialogInfo',
            'showDialogReleaseAutoAttendance',
            'showDialogEditAndResend',
            'showFilter'
        ]),
        ...mapActions('auto_attendance', [
            'checkPayment',
            'getItems'
        ]),
        ...mapActions("clubs", ["getClubs"]),
        getClassColor(item) {
           console.log('to aqui')
            if (item.status == 'Aguardando liberação') {
                return 'row-orange'
            } else if(item.status == 'Aguardando envio') {
                return 'row-purple'
            } else if (item.status == 'Solicitado') {
                return 'row-cyan'
            } else if (item.status == 'Enviado') {
                return 'row-green'
            } else if (item.status == 'Erro') {
                return 'row-red'
            }
        },
        getDate(date) {
            if (date) {
                var d = new Date(date)
                return d.toLocaleDateString() + ' ' + d.toLocaleTimeString()
            }
            return ''
        },
        getColorStatus(status) {
            if (status == 'Aguardando liberação' || status == 'Pago') {
                return 'orange'
            } else if(status == 'Aguardando envio') {
                return 'purple'
            } else if (status == 'Solicitado') {
                return 'cyan'
            } else if (status == 'Enviado') {
                return 'green'
            } else if (status == 'Erro') {
                return 'red'
            }
        },
        wathAutoAttendance() {
            if (this.mode == 'LIST') {
                this.getItems({ resetPage: false })
            }
            if (this.$router) {
                if (this.$router.history.current.name == 'auto_attendance') {
                    setTimeout(() => {
                        this.wathAutoAttendance()
                    }, 25000)
                }
            }
        },
        openWhatsapp({ phone }) {
            window.open(`https://api.whatsapp.com/send/?phone=55${phone}`, '_blank')
        }
    },
    data: () => ({
        headers: [
            { text: 'Id', value: 'id', sortable: true },
            { text: 'Nome', value: 'name', sortable: false },
            { text: 'Doc.', value: 'doc', sortable: false },
            { text: 'Telefone', value: 'phone', sortable: false },
            { text: 'Plataforma', value: 'platform', sortable: false },
            { text: 'Clube', value: 'slotLabel', sortable: false },
            { text: 'Id App', value: 'playerid', sortable: false },
            { text: 'Valor', value: 'value', sortable: true },
            { text: 'Conta', value: 'accountNickname', sortable: false },
            { text: 'Criado em', value: 'createdAt', sortable: true },
            { text: 'Status', value: 'status', align: 'center', sortable: true },
            { text: 'App', value: 'idClientUser', align: 'center', sortable: false },
            { text: '', value: 'data-table-expand' },
            {
                text: 'Ações',
                value: 'actions',
                sortable: false,
                align: 'center',
            },
        ]
    }),
    watch: {
        pagination: {
            handler() {
                this.getItems({ resetPage: false })
            },
        }
    },
}
</script>

<style lang="sass">
.row-cyan 
    color: teal !important
.row-green
    color: green !important
.row-red 
    color: red !important    

</style>