import axios from 'axios'
import { getField, updateField } from 'vuex-map-fields'
import endPoints from '../../../config/endPoints'
import formatDate from '../../../util/formatDate.js'

import {
  defaultErrorNotification,
  defaultSuccessNotification,
} from '../../../constants'

const defaultFilter = {
  id_blacklist: '',
  blocked_type: '',
  observation: '',
  pix_type: '',
  start: '',
  end: '',
}

const defaultWithdrawalBlacklistItem = {
  id_blacklist: '',
  blocked_type: null,
  observation: '',
  pix_key: '',
  pix_type: null,
}

export default {
  namespaced: true,
  state: {
    items: [],
    selectedItem: {},
    totalItems: 0,
    loading: false,
    filterDialog: false,
    formDialog: false,
    deleteDialog: false,
    filtered: false,
    filter: {
      id_blacklist: '',
      blocked_type: '',
      observation: '',
      pix_type: '',
      start: '',
      end: '',
    },
    notification: {
      show: false,
      message: '',
      type: '',
    },
    pagination: {
      page: 1,
      itemsPerPage: 10,
    },
    possible_blocked_types: [
      { blocked_type: 0, name_blocked_type: "ID" },
      { blocked_type: 1, name_blocked_type: "CPF" },
      { blocked_type: 2, name_blocked_type: "CNPJ" },
      { blocked_type: 3, name_blocked_type: "Chave PIX" },
    ],
    possible_pix_types: [
      { pix_type: 0, name_pix_type: "CPF / CNPJ" },
      { pix_type: 1, name_pix_type: "Celular" },
      { pix_type: 2, name_pix_type: "E-mail" },
      { pix_type: 3, name_pix_type: "Aleatória" },
    ],
  },
  mutations: {
    setDialog: (state, payload) => {
      if (payload.show == false) {
        state.selectedItem = Object.assign({}, defaultWithdrawalBlacklistItem)
        state.filter = Object.assign({}, defaultFilter)
      }
      if (payload.item) {
        state.selectedItem = payload.item
      }
      if (payload.mode) {
        state.mode = payload.mode
      }
      state[payload.dialog] = payload.show
    },
    setDefaultItem: (state) => (state.selectedItem = Object.assign({}, defaultWithdrawalBlacklistItem)),
    setFilter: (state, payload) => (state.filter = payload),
    setFiltered: (state, payload) => (state.filtered = payload),
    setItems: (state, payload) => (state.items = payload),
    setLoading: (state, payload) => (state.loading = payload),
    setSelectedItem: (state, payload) => (state.selectedItem = payload),
    setTotalItems: (state, payload) => (state.totalItems = payload),
    showNotification: (state, payload) => (state.notification = payload),
    updateField,
  },
  actions: {
    clearFilter: ({ commit }) => {
      commit('setFilter', Object.assign({}, defaultFilter))
      commit('setDialog', { dialog: 'filterDialog', show: false , mode: ''})
    },
    getItems: async ({ commit, state }) => {

      let filter = state.filter
      let url = endPoints.withdrawals_blacklist
      
      if (state.pix_type != '' || state.pix_type != 0) {
        let pix_type_str = filter.pix_type.toString()
        filter.pix_type = pix_type_str
      }

      url += '?page=' + state.pagination.page + "&itemsPerPage=" + state.pagination.itemsPerPage;

      commit('setFiltered', false)
      Object.keys(filter).forEach(function (field) {
        if (
          filter[field] != '' &&
          filter[field] != undefined &&
          filter[field] != null
        ) {
          commit('setFiltered', true)

          if (field == 'end' || field == 'start') {
            var e = new Date(filter[field])
            url +=
            '&' + field + '=' + formatDate(e) + ' ' + e.toLocaleTimeString()
          } else {
            url += '&' + field + '=' + filter[field]
          }
        }
      })

      if (
        state.pagination &&
        state.pagination.sortBy &&
        state.pagination.sortBy.length > 0
      ) {
        url +=
          '&sort_by=' +
          (state.pagination.sortBy[0] === 'date'
            ? 'id'
            : state.pagination.sortBy[0])
        url += '&sort_asc=' + state.pagination.sortAsc[0]
      }
      commit('setLoading', true)
      try {
        await axios.get(url).then(items => {
          commit('setItems', items.data.data)
          commit('setTotalItems', items.data.total_itens)
          if (state.mode == "ADD" || state.mode == "EDIT")
            commit('setDialog', { dialog: 'formDialog', show: false, mode: '' })
          if (state.mode == "" || state.mode == undefined || state.mode == null)
            commit('setDialog', { dialog: 'filterDialog', show: false, mode: '' })
          
          commit('setLoading', false)
          defaultSuccessNotification()
        });
      } catch (error) {
        commit('setLoading', false)
        commit(
          'showNotification',
          defaultErrorNotification(error?.response?.data?.message)
        )
      }
    },
    deleteItem: async ({ commit, dispatch, state }) => {
      commit('setLoading', true)
      try {

        await axios.delete(`${endPoints.withdrawals_blacklist}/${state.selectedItem.id}`)

        commit('setDialog', { dialog: 'deleteDialog', show: false, mode: '' })
        commit(
          'showNotification',
          defaultSuccessNotification('Registro removido com sucesso')
        )
        commit('setLoading', false)
        dispatch('getItems')
      } catch (error) {
        commit(
          'showNotification',
          defaultErrorNotification(error?.response?.message)
        )
        commit('setLoading', false)
      }
    },
    save: async ({ commit, state, dispatch, rootState }) => {

      let url = endPoints.withdrawals_blacklist
      let func = ''
      state.mode === 'ADD'
        ? (func = axios.post)
        : ((func = axios.put), (url += `/${state.selectedItem.id}`))

      commit('setLoading', true)
      try {

        state.selectedItem.user_id = rootState.login.userData.id
        state.selectedItem.user_name = rootState.login.userData.name

        if (state.selectedItem.cpf) {
          state.selectedItem.id_blacklist = state.selectedItem.cpf
        }
        if (state.selectedItem.cnpj) {
          state.selectedItem.id_blacklist = state.selectedItem.cnpj
        }
        if (state.selectedItem.pix_key) {
          state.selectedItem.id_blacklist = state.selectedItem.pix_key
        }

        await func(url, state.selectedItem)

        commit(
          'showNotification',
          defaultSuccessNotification('Operação feita com sucesso')
        )
        dispatch('getItems')
        commit('setLoading', false)
      } catch (error) {
        commit(
          'showNotification',
          defaultErrorNotification(error?.response?.message)
        )
        commit('setLoading', false)
      }
    },
  },
  getters: {
    getField,
  },
}
