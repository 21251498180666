<template>
  <div>
    <div @drop.prevent="addDropFile" @dragover.prevent>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card>
        <v-card-title>
          <span class="headline">Período de Concliação</span>
          <v-spacer></v-spacer>
          <v-progress-circular
            v-if="loading"
            indeterminate
            class="text-right"
            color="primary"
            size="24"
          ></v-progress-circular>

        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm6 md6>
                  <v-datetime-picker
                    v-model="start_date_picker"
                    date-format="dd/MM/yyyy"
                    :time-picker-props="timeProps"
                    label="Data de Inicio*"
                    :textFieldProps="{ outlined: true }"
                  >
                    <template slot="dateIcon">
                      <v-icon>mdi-calendar</v-icon>
                    </template>
                    <template slot="timeIcon">
                      <v-icon>mdi-clock</v-icon>
                    </template>
                  </v-datetime-picker>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-datetime-picker
                    v-model="finish_date_picker"
                    date-format="dd/MM/yyyy"
                    :time-picker-props="timeProps"
                    label="Data de Fim*"
                    :textFieldProps="{ outlined: true }"
                  >
                    <template slot="dateIcon">
                      <v-icon>mdi-calendar</v-icon>
                    </template>
                    <template slot="timeIcon">
                      <v-icon>mdi-clock</v-icon>
                    </template>
                  </v-datetime-picker>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-col>
            <v-btn text @click="showFormDialog({show: false})">{{ mode == 'VIEW' ? 'Voltar' : 'Cancelar'}}</v-btn>
          </v-col>
          <v-col class="text-right" v-if="mode != 'VIEW'">
            <v-btn text type="submit" right @click="savePeriod">Salvar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "PeriodsClosureForm",
  computed: {
    ...mapState("period_conciliation", [
      "loading",
      "mode",
    ]),
    ...mapFields("period_conciliation", [
      "finish_formatted",
      "selectedItem.start_date",
      "selectedItem.finish_date",
      "selectedItem.start_date_picker",
      "selectedItem.finish_date_picker",
      "overlay",
      "start_formatted"
    ]),
  },
  created() {},
  methods: {
    ...mapMutations("period_conciliation", ["showFormDialog"]),
    ...mapActions("period_conciliation", ["save"]),
    getDate(date) {
      if (date) {
        var d = new Date(date);
        return d.toLocaleDateString() + " " + d.toLocaleTimeString();
      }
      return "";
    },
    formatDate(date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`
    },
    savePeriod() {
      let valid = this.$refs.form.validate();
      if (valid) {
        var d1 = new Date(this.start_date_picker);
        this.start_date = d1.getFullYear() + "-" + (d1.getMonth() + 1) + "-" + d1.getDate() + " " + d1.toLocaleTimeString();
        var d2 = new Date(this.finish_date_picker);
        this.finish_date = d2.getFullYear() + "-" + (d2.getMonth() + 1) + "-" + d2.getDate() + " " + d2.toLocaleTimeString();
        this.save();
      }
    }
  },
  data: () => ({
    fileString: '',
    timeProps: {
      format: "24hr",
    },
    valid: false,
    showStart: false,
    showFinish: false,
    rulesFields: [(value) => !!value || "Campo obrigatório"],
  })
};
</script>
