<template>
    <div>
         <v-dialog :value="showDialog" persistent width="600">
      <v-card>
        <v-card-title>
          <span class="headline">Receita</span>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Descrição *"
                    v-model="description"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('description')"
                    v-validate="'required'"
                    data-vv-name="description"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-select
                    label="Empresa *"
                    required
                    :items="possible_companies"
                    v-model="company"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('company')"
                    v-validate="'required'"
                    item-text="name"
                    item-value="id"
                    data-vv-name="company"
                    outlined
                  >
                  </v-select>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-select
                    label="Banco *"
                    required
                    :items="banks"
                    v-model="bank"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('bank')"
                    v-validate="'required'"
                    :item-text="getTextBank"
                    item-value="id"
                    data-vv-name="bank"
                    outlined
                  >
                  </v-select>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Valor *"
                    v-model="value"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('value')"
                    data-vv-name="value"
                    v-validate="'required'"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Observação *"
                    v-model="observation"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('observation')"
                    v-validate="'required'"
                    data-vv-name="observation"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-datetime-picker
                    v-model="date_picker"
                    date-format="dd/MM/yyyy"
                    clearText="Limpar"
                    okText="Confirmar"
                    :time-picker-props="timeProps"
                    :readonly="this.mode == 'VIEW'"
                    label="Data *"
                    :textFieldProps="{ outlined: true }"
                  >
                    <template slot="dateIcon">
                      <v-icon>mdi-calendar</v-icon>
                    </template>
                    <template slot="timeIcon">
                      <v-icon>mdi-clock</v-icon>
                    </template>
                  </v-datetime-picker>
                </v-flex>

              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-col>
            <v-btn
              text
              @click="setShowDialog(false)"
              >{{ mode == "VIEW" ? "Voltar" : "Cancelar" }}</v-btn
            >
          </v-col>
          <v-col class="text-right">
            <v-btn
              text
              type="submit"
              right
              v-if="mode == 'EDIT' || mode == 'ADD'"
              @click="saveRelease"
              >Salvar</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </div>
</template>

<script>

import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
    name: 'ReceiptsForm',
    methods: {
    ...mapMutations("receipts", ["setShowDialog", "setSelectedItem", "setShowDialog"]),
    ...mapActions("receipts", ["save"]),
    saveRelease() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          var d = new Date(this.date_picker);
          this.date = d.toISOString().substr(0, 10) + " " + d.toLocaleTimeString();
          this.save();
        }
      });
    },
    getTextBank(item) {
      if(item.account) {
        return item.name + ' | ' + item.account
      } else {
        return item.name
      }
    }
  },
  computed: {
    ...mapState("receipts", [
      "mode",
      "selectedItem",
      "showDialog",
      "banks"
    ]),
    ...mapFields("receipts", [
      "selectedItem.id",
      "selectedItem.description",
      "selectedItem.date",
      "selectedItem.date_picker",
      "selectedItem.bank",
      "selectedItem.observation",
      "selectedItem.company",
      "selectedItem.value",
      "selectedItem.user_id",
    ]),
  },
  data: () => ({
    possible_companies: [
      {
        id: 0,
        name: "Stars",
      },
      {
        id: 1,
        name: "Aquarios",
      },
      {
        id: 2,
        name: "Jacks",
      },
      {
        id: 3,
        name: "Canubet",
      },
      {
        id: 4,
        name: "Grupo Stars",
      },
      {
        id: 5,
        name: "Stars Horizon",
      },
      {
        id: 6,
        name: "Outros",
      },
      {
        id: 7,
        name: "CGP"
      },
      {
        id: 8, 
        name: "PHD Digital"
      },
      {
        id: 9, 
        name: "Se precisar"
      }
    ],
    timeProps: {
      format: "24hr",
    },
  }),
}
</script>