<template>
  <div>
    <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar
        :color="notificationType"
        top
        center
        :timeout="3000"
        v-model="showNotification"
        >{{ notificationMessage }}</v-snackbar
    >

    <v-toolbar flat class="elevation-5">
      <div>
          <v-toolbar-title>Aprovação de Jogadores</v-toolbar-title>
      </div>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="black"
                  dark
                  class="ma-2"
                  @click="showFilter({ show: true })"
                  small
                  v-on="on"
                  v-bind="attrs"
              >
                  <v-icon :color="filtered ? 'grey' : 'white'"
                      >mdi-filter</v-icon
                  >
              </v-btn>
          </template>
          <span>Filtro</span>
      </v-tooltip>
      <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="black"
                  dark
                  class="ma-2"
                  @click="getItems"
                  small
                  v-on="on"
                  v-bind="attrs"
              >
                  <v-icon>mdi-refresh</v-icon>
              </v-btn>
          </template>
          <span>Recarregar</span>
      </v-tooltip>
    </v-toolbar>

    <div>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [10, 20, 50, 100],
          'items-per-page-text': 'Itens por página',
          'show-first-last-page': true,
        }"
        class="elevation-5"
      > 
        <template v-slot:[`item.createtime`]="{ item }">
          <span>{{ getDate(item.createtime) }}</span>
        </template>
        <template v-slot:[`item.clubName`]="{ item }">
          <span>{{ getSlot(item.clubID, item) }}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  v-on="on"
                  v-bind="attrs"
                  class="mr-2"
                  color="red"
                  @click="approve({ item: {...item, approve: 0}})"
                  >mdi-close</v-icon
                >
            </template>
            <span>Recusar</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  v-on="on"
                  v-bind="attrs"
                  color="green"
                  class="mr-2"
                  @click="approve({ item: {...item, approve: 1}})"
                  >mdi-check</v-icon
                >
            </template>
            <span>Aprovar</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>

    <v-dialog
        :value="dialogFilter"
        width="600"
        @click:outside="showFilter({ show: false })"
        @keydown.esc="showFilter({ show: false })"
        @keydown.enter="filter"
    >
      <player-approval-filter></player-approval-filter>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import PlayerApprovalFilter from "./PlayerApprovalFilter.vue";

export default {
  name: 'PlayerApproval',
  components: {
    "player-approval-filter": PlayerApprovalFilter
  },
  computed: {
    ...mapState('player_approval', ['items', 'loading', "notificationMessage", "notificationType", "overlay", "filtered"]),
    ...mapFields('player_approval', ['dialogFilter', 'filter.clubID', 'showNotification']),
    ...mapState("slots", ["slots"]),
  },
  created() {
    this.getSlots({
      resetPage: false,
      noPages: true,
      onlyIfNotSearched: true,
    });
  },
  data () {
    return {
      headers: [
        { text: 'Id Clube', value: 'clubID' },
        { text: 'Clube', value: 'clubName' },
        { text: 'Id Jogador', value: 'playerID' },
        { text: 'Solicitado em', value: 'createtime' },
        { text: 'Ações', value: 'actions', sortable: false },
      ]
    }
  },
  methods: {
    ...mapActions('player_approval', ['approve', 'getItems']),
    ...mapActions('slots', ['getSlots']),
    ...mapMutations('player_approval', ['showFilter']),
    getDate(date) {
      if (date) {
          var d = new Date(date)
          return d.toLocaleDateString() + ' ' + d.toLocaleTimeString()
      }
      return ''
    },
    getSlot(id_app) {
      let slot = this.slots.find(slot => slot.id_app === id_app)
      return slot ? slot.name : ''
    },
  }
}
</script>