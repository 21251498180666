<template>
   <v-list-item>
    <v-list-item-icon>
      <v-icon>mdi-account</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>{{ "Nome: " + contact_name ? contact_name : '-' }}</v-list-item-title>
      <v-list-item-subtitle>{{ "Telefone: " + contact_id }}</v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
  export default {
    props: [
      'contact_id',
      'contact_name'
    ],
    data: () => ({
      
    }),
    methods: {
      
    }
  }
</script>

<style>
  .document{
    min-width: 300px;
    justify-content: center;
    align-items: center;
    color: rgba(0, 0, 0, 0.87) !important;
    text-decoration: none;
    margin: 5px 10px 0 10px;
    margin-right: auto;
  }

</style>
