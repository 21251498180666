<template>
  <div>
    <div @drop.prevent="addDropFile" @dragover.prevent>
      <v-dialog
        :value="showDialog"
        persistent
        width="1100"
        @outside="setShowDialog(false)"
      >
        <v-stepper non-linear :value="valueStep" @change="setStep">
          <v-stepper-header>
            <v-stepper-step editable step="1"> Dados Pessoais </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step editable step="2">
              Dados Familiares
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step editable step="3">
              Dados Profissionais
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="4" editable> Endereço </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 sm6 md6>
                      <v-text-field
                        autocomplete="chrome-off"
                        dense
                        required
                        label="Nome *"
                        v-model="name"
                        :clearable="this.mode == 'ADD' || this.mode == 'EDIT'"
                        :readonly="this.mode == 'VIEW'"
                        :error-messages="errors.collect('name')"
                        v-validate="'required:Nome,name'"
                        data-vv-name="name"
                        outlined
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm3 md3>
                      <v-text-field
                        dense
                        required
                        label="CPF *"
                        v-model="cpf"
                        v-mask="'###.###.###-##'"
                        :clearable="this.mode == 'ADD' || this.mode == 'EDIT'"
                        :readonly="this.mode == 'VIEW'"
                        :error-messages="errors.collect('cpf')"
                        v-validate="'required:CPF,cpf'"
                        data-vv-name="cpf"
                        outlined
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm3 md3>
                      <v-text-field
                        autocomplete="chrome-off"
                        dense
                        required
                        label="RG *"
                        v-model="rg"
                        :clearable="this.mode == 'ADD' || this.mode == 'EDIT'"
                        :readonly="this.mode == 'VIEW'"
                        :error-messages="errors.collect('rg')"
                        v-validate="'required:RG,rg'"
                        data-vv-name="rg"
                        outlined
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm3 md3>
                      <v-text-field
                        autocomplete="chrome-off"
                        dense
                        required
                        label="CTPS *"
                        v-model="ctps"
                        :clearable="this.mode == 'ADD' || this.mode == 'EDIT'"
                        :readonly="this.mode == 'VIEW'"
                        :error-messages="errors.collect('ctps')"
                        v-validate="'required:CTPS,ctps'"
                        data-vv-name="ctps"
                        outlined
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm4 md4>
                      <v-text-field
                        autocomplete="chrome-off"
                        dense
                        required
                        label="PIS *"
                        v-model="pis"
                        :clearable="this.mode == 'ADD' || this.mode == 'EDIT'"
                        :readonly="this.mode == 'VIEW'"
                        :error-messages="errors.collect('pis')"
                        v-validate="'required:PIS,pis'"
                        data-vv-name="pis"
                        outlined
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm3 md3>
                      <v-text-field
                        autocomplete="chrome-off"
                        dense
                        required
                        label="Série *"
                        v-model="serie"
                        :clearable="this.mode == 'ADD' || this.mode == 'EDIT'"
                        :readonly="this.mode == 'VIEW'"
                        :error-messages="errors.collect('serie')"
                        v-validate="'required:Série,serie'"
                        data-vv-name="serie"
                        outlined
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm2 md2>
                      <v-text-field
                        autocomplete="chrome-off"
                        dense
                        required
                        label="UF *"
                        v-model="uf"
                        :clearable="this.mode == 'ADD' || this.mode == 'EDIT'"
                        :readonly="this.mode == 'VIEW'"
                        :error-messages="errors.collect('uf')"
                        v-validate="'required:UF,uf'"
                        data-vv-name="uf"
                        outlined
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm3 md3>
                      <v-text-field
                        dense
                        label="Certificado de Dispensa"
                        v-model="dispensation"
                        :readonly="this.mode == 'VIEW'"
                        :error-messages="errors.collect('dispensation')"
                        data-vv-name="dispensation"
                        outlined
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm3 md3>
                      <v-text-field
                        autocomplete="chrome-off"
                        dense
                        label="Naturalidade"
                        v-model="nationality"
                        :clearable="this.mode == 'ADD' || this.mode == 'EDIT'"
                        :readonly="this.mode == 'VIEW'"
                        outlined
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm4 md4>
                      <v-select
                        dense
                        label="Sexo"
                        clear-icon="mdi-close"
                        v-model="gender"
                        :items="possible_genders"
                        :clearable="this.mode == 'ADD' || this.mode == 'EDIT'"
                        :readonly="this.mode == 'VIEW'"
                        item-text="gender"
                        item-value="id"
                        outlined
                      ></v-select>
                    </v-flex>

                    <v-flex xs12 sm2 md2>
                      <v-select
                        dense
                        label="PCD *"
                        clear-icon="mdi-close"
                        v-model="pcd"
                        :items="possible_pcd"
                        :clearable="this.mode == 'ADD' || this.mode == 'EDIT'"
                        :readonly="this.mode == 'VIEW'"
                        :error-messages="errors.collect('pcd')"
                        v-validate="'required:PCD,pcd'"
                        required
                        item-text="description"
                        item-value="id"
                        data-vv-name="pcd"
                        outlined
                      ></v-select>
                    </v-flex>

                    <v-flex xs12 sm3 md3>
                      <v-text-field
                        autocomplete="chrome-off"
                        dense
                        required
                        label="Data de Nascimento *"
                        v-model="birth_date"
                        v-mask="'##/##/####'"
                        :clearable="this.mode == 'ADD' || this.mode == 'EDIT'"
                        :readonly="this.mode == 'VIEW'"
                        v-validate="'required:Data de Nascimento,birth_date'"
                        :error-messages="errors.collect('birth_date')"
                        data-vv-name="birth_date"
                        outlined
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm4 md4>
                      <v-text-field
                        dense
                        label="CNH "
                        v-model="cnh"
                        :clearable="this.mode == 'ADD' || this.mode == 'EDIT'"
                        :readonly="this.mode == 'VIEW'"
                        outlined
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm2 md2>
                      <v-text-field
                        dense
                        label="Categoria "
                        v-model="category"
                        :clearable="this.mode == 'ADD' || this.mode == 'EDIT'"
                        :readonly="this.mode == 'VIEW'"
                        outlined
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm3 md3>
                      <v-text-field
                        dense
                        label="Título de eleitor *"
                        required
                        v-model="voter_registration"
                        :clearable="this.mode == 'ADD' || this.mode == 'EDIT'"
                        :readonly="this.mode == 'VIEW'"
                        :error-messages="errors.collect('voter_registration')"
                        v-validate="
                          'required:Título de eleitor,voter_registration'
                        "
                        data-vv-name="voter_registration"
                        outlined
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm2 md2>
                      <v-text-field
                        dense
                        label="Zona *"
                        v-model="zone"
                        required
                        :clearable="this.mode == 'ADD' || this.mode == 'EDIT'"
                        :readonly="this.mode == 'VIEW'"
                        :error-messages="errors.collect('zone')"
                        v-validate="'required:Zona,zone'"
                        data-vv-name="zone"
                        outlined
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm2 md2>
                      <v-text-field
                        dense
                        label="Seção *"
                        required
                        v-model="section"
                        :clearable="this.mode == 'ADD' || this.mode == 'EDIT'"
                        :readonly="this.mode == 'VIEW'"
                        :error-messages="errors.collect('section')"
                        v-validate="'required:Seção,section'"
                        data-vv-name="section"
                        outlined
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm5 md5>
                      <v-select
                        dense
                        label="Formação"
                        clear-icon="mdi-close"
                        v-model="formation"
                        :items="possible_formations"
                        :clearable="this.mode == 'ADD' || this.mode == 'EDIT'"
                        :readonly="this.mode == 'VIEW'"
                        item-text="formation"
                        item-value="id"
                        data-vv-name="formation"
                        outlined
                      ></v-select>
                    </v-flex>

                    <v-flex xs12 sm3 md3>
                      <v-select
                        dense
                        label="Estado Civil"
                        clear-icon="mdi-close"
                        v-model="civil_status"
                        :items="possible_civil_status"
                        :clearable="this.mode == 'ADD' || this.mode == 'EDIT'"
                        :readonly="this.mode == 'VIEW'"
                        item-text="civil_status"
                        item-value="id"
                        outlined
                      ></v-select>
                    </v-flex>

                    <v-flex xs12 sm3 md3>
                      <v-text-field
                        autocomplete="chrome-off"
                        dense
                        required
                        label="Telefone *"
                        v-model="phone"
                        v-mask="'(##)#####-####'"
                        :clearable="this.mode == 'ADD' || this.mode == 'EDIT'"
                        :readonly="this.mode == 'VIEW'"
                        :error-messages="errors.collect('phone')"
                        v-validate="'required:Telefone,phone'"
                        data-vv-name="phone"
                        outlined
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm5 md5>
                      <v-text-field
                        autocomplete="chrome-off"
                        dense
                        required
                        label="E-mail *"
                        v-model="email"
                        :clearable="this.mode == 'ADD' || this.mode == 'EDIT'"
                        :readonly="this.mode == 'VIEW'"
                        :error-messages="errors.collect('email')"
                        v-validate="'email:E-mail,email'"
                        data-vv-name="email"
                        outlined
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
              <v-card-actions>
                <v-col>
                  <v-btn color="primary" dark @click="setShowDialog(false)">{{
                    mode == "VIEW" ? "Voltar" : "Cancelar"
                  }}</v-btn>
                </v-col>
              </v-card-actions>
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 sm6 md6>
                      <v-text-field
                        dense
                        label="Nome da Mãe "
                        v-model="mother"
                        :clearable="this.mode == 'ADD' || this.mode == 'EDIT'"
                        :readonly="this.mode == 'VIEW'"
                        outlined
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm6 md6>
                      <v-text-field
                        dense
                        label="Nome do Pai "
                        v-model="father"
                        :clearable="this.mode == 'ADD' || this.mode == 'EDIT'"
                        :readonly="this.mode == 'VIEW'"
                        outlined
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm4 md4>
                      <v-select
                        dense
                        label="Filho(s) *"
                        :items="possible_childrens"
                        v-model="children"
                        :readonly="this.mode == 'VIEW'"
                        :error-messages="errors.collect('children')"
                        v-validate="'required:Filhos,children'"
                        data-vv-name="children"
                        item-text="description"
                        item-value="id"
                        outlined
                      ></v-select>
                    </v-flex>

                    <v-flex v-if="children != 0" xs12 sm4 md4>
                      <v-btn class="ma-2" @click="addNewChildren" text>
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                      <v-btn class="ma-2" @click="removeNewChildren" text>
                        <v-icon>mdi-minus</v-icon>
                      </v-btn>
                    </v-flex>

                    <v-layout v-if="children != 0" wrap>
                      <v-layout wrap v-for="c in childrensData" :key="c.id">
                        <v-flex xs12 sm6 md6>
                          <v-text-field
                            dense
                            label="Nome Completo"
                            v-model="c.name_children"
                            :error-messages="errors.collect('street')"
                            data-vv-name="street"
                            outlined
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm3 md3>
                          <v-text-field
                            dense
                            label="CPF "
                            v-mask="'###.###.###-##'"
                            v-model="c.cpf_children"
                            :error-messages="errors.collect('cpf')"
                            v-validate="'required:CPF,cpf'"
                            data-vv-name="CPF"
                            outlined
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm3 md3>
                          <v-text-field
                            autocomplete="chrome-off"
                            dense
                            required
                            label="Data de Nascimento"
                            v-model="c.birth_date_children"
                            v-mask="'##/##/####'"
                            :error-messages="errors.collect('birth_date')"
                            data-vv-name="birth_date"
                            outlined
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-layout>
                  </v-layout>
                </v-container>
              </v-form>
              <v-card-actions>
                <v-col>
                  <v-btn
                    color="primary"
                    dark
                    @click="setStep(1), setShowDialog(false)"
                    >{{ mode == "VIEW" ? "Voltar" : "Cancelar" }}</v-btn
                  >
                </v-col>
              </v-card-actions>
            </v-stepper-content>
            <v-stepper-content step="3">
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 sm4 md4>
                      <v-text-field
                        autocomplete="chrome-off"
                        dense
                        label="Matrícula "
                        v-model="registration"
                        :clearable="this.mode == 'ADD' || this.mode == 'EDIT'"
                        :readonly="this.mode == 'VIEW'"
                        outlined
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm4 md4>
                      <v-select
                        dense
                        label="Empresa *"
                        clear-icon="mdi-close"
                        v-model="id_company"
                        :items="companies"
                        :clearable="this.mode == 'ADD' || this.mode == 'EDIT'"
                        :readonly="this.mode == 'VIEW'"
                        :error-messages="errors.collect('id_company')"
                        v-validate="'required:Empresa,id_company'"
                        item-text="name"
                        item-value="id"
                        data-vv-name="id_company"
                        outlined
                      ></v-select>
                    </v-flex>

                    <v-flex xs12 sm4 md4>
                      <v-select
                        dense
                        label="Setor *"
                        clear-icon="mdi-close"
                        v-model="department"
                        :items="departments"
                        :clearable="this.mode == 'ADD' || this.mode == 'EDIT'"
                        :readonly="this.mode == 'VIEW'"
                        :error-messages="errors.collect('department')"
                        v-validate="'required:Setor,department'"
                        item-text="name"
                        item-value="id"
                        data-vv-name="department"
                        outlined
                      ></v-select>
                    </v-flex>

                    <v-flex xs12 sm4 md4>
                      <v-select
                        dense
                        label="Registro *"
                        clear-icon="mdi-close"
                        v-model="registry"
                        required
                        :items="possible_registry"
                        :clearable="this.mode == 'ADD' || this.mode == 'EDIT'"
                        :readonly="this.mode == 'VIEW'"
                        :error-messages="errors.collect('registry')"
                        v-validate="'required:Registro,registry'"
                        item-text="registry"
                        item-value="id"
                        data-vv-name="registry"
                        outlined
                      ></v-select>
                    </v-flex>

                    <v-flex xs12 sm4 md4>
                      <v-select
                        dense
                        label="Cargo *"
                        clear-icon="mdi-close"
                        v-model="role"
                        required
                        :items="roles"
                        :clearable="this.mode == 'ADD' || this.mode == 'EDIT'"
                        :readonly="this.mode == 'VIEW'"
                        :error-messages="errors.collect('role')"
                        v-validate="'required:Cargo,role'"
                        item-text="name"
                        item-value="id"
                        data-vv-name="role"
                        outlined
                      ></v-select>
                    </v-flex>

                    <v-flex xs12 sm4 md4>
                      <v-text-field
                        dense
                        label="Horário Expediente"
                        v-model="expedient"
                        :clearable="this.mode == 'ADD' || this.mode == 'EDIT'"
                        :readonly="this.mode == 'VIEW'"
                        outlined
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm4 md4>
                      <v-text-field
                        dense
                        label="Admissão *"
                        v-mask="'##/##/####'"
                        v-model="admission"
                        @input="parseDate(admission)"
                        :clearable="this.mode == 'ADD' || this.mode == 'EDIT'"
                        :readonly="this.mode == 'VIEW'"
                        :error-messages="errors.collect('voucher')"
                        data-vv-name="voucher"
                        outlined
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm4 md4>
                      <v-text-field
                        dense
                        label="1º Experiência *"
                        v-model="experience"
                        v-mask="'##/##/####'"
                        :clearable="this.mode == 'ADD' || this.mode == 'EDIT'"
                        readonly
                        filled
                        outlined
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm4 md4>
                      <v-text-field
                        dense
                        label="2º Experiência *"
                        v-mask="'##/##/####'"
                        v-model="second_experience"
                        :clearable="this.mode == 'ADD' || this.mode == 'EDIT'"
                        readonly
                        filled
                        outlined
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm3 md3>
                      <v-text-field
                        dense
                        label="Salário *"
                        prefix="R$"
                        v-model="salary"
                        :clearable="this.mode == 'ADD' || this.mode == 'EDIT'"
                        :readonly="this.mode == 'VIEW'"
                        :error-messages="errors.collect('voucher')"
                        data-vv-name="voucher"
                        outlined
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm3 md3>
                      <v-text-field
                        dense
                        prefix="R$"
                        label="Vale Transporte"
                        v-model="voucher"
                        :clearable="this.mode == 'ADD' || this.mode == 'EDIT'"
                        :readonly="this.mode == 'VIEW'"
                        outlined
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm3 md3>
                      <v-text-field
                        dense
                        prefix="R$"
                        label="Vale Refeição"
                        v-model="ticket"
                        :clearable="this.mode == 'ADD' || this.mode == 'EDIT'"
                        :readonly="this.mode == 'VIEW'"
                        outlined
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm3 md3>
                      <v-text-field
                        dense
                        prefix="R$"
                        label="Comissão"
                        v-model="commission"
                        :clearable="this.mode == 'ADD' || this.mode == 'EDIT'"
                        :readonly="this.mode == 'VIEW'"
                        outlined
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm5 md5>
                      <v-text-field
                        autocomplete="chrome-off"
                        dense
                        label="E-mail Corporativo"
                        v-model="corporate_email"
                        :clearable="this.mode == 'ADD' || this.mode == 'EDIT'"
                        :readonly="this.mode == 'VIEW'"
                        outlined
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
              <v-card-actions>
                <v-col>
                  <v-btn
                    color="primary"
                    dark
                    @click="setStep(1), setShowDialog(false)"
                    >{{ mode == "VIEW" ? "Voltar" : "Cancelar" }}</v-btn
                  >
                </v-col>
              </v-card-actions>
            </v-stepper-content>
            <v-stepper-content step="4">
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 sm4 md4>
                      <v-text-field
                        autocomplete="chrome-off"
                        dense
                        outlined
                        clearable
                        required
                        v-model="cep"
                        v-mask="'#####-###'"
                        :no-filter="true"
                        v-validate="'required:CEP,cep'"
                        no-data-text="CEP não encontrado"
                        :error-messages="errors.collect('cep')"
                        label="CEP *"
                        data-vv-name="cep"
                      ></v-text-field>
                    </v-flex>

                    <v-card-actions>
                      <v-col>
                        <v-btn text @click="getApiCep(cep), showAddress(true)"
                          >PESQUISAR</v-btn
                        >
                      </v-col>
                    </v-card-actions>

                    <v-layout wrap v-if="addressForm || mode == 'EDIT'">
                      <v-flex xs12 sm8 md8>
                        <v-text-field
                          dense
                          filled
                          required
                          label="Logradouro"
                          v-model="street"
                          :error-messages="errors.collect('street')"
                          data-vv-name="street"
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm6 md6>
                        <v-text-field
                          dense
                          filled
                          required
                          label="Cidade"
                          v-model="city"
                          :error-messages="errors.collect('city')"
                          data-vv-name="city"
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm6 md6>
                        <v-text-field
                          dense
                          filled
                          required
                          label="Bairro"
                          v-model="district"
                          :error-messages="errors.collect('district')"
                          data-vv-name="district"
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm4 md4>
                        <v-text-field
                          dense
                          filled
                          required
                          label="Estado"
                          v-model="state"
                          :error-messages="errors.collect('state')"
                          data-vv-name="state"
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm4 md4>
                        <v-text-field
                          dense
                          label="Número"
                          v-model="number"
                          :readonly="this.mode == 'VIEW'"
                          :error-messages="errors.collect('number')"
                          data-vv-name="number"
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm4 md4>
                        <v-text-field
                          dense
                          label="Complemento"
                          v-model="complement"
                          :readonly="this.mode == 'VIEW'"
                          :error-messages="errors.collect('complement')"
                          data-vv-name="complement"
                          outlined
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-layout>
                </v-container>
              </v-form>
              <v-card-actions>
                <v-col>
                  <v-btn
                    color="primary"
                    dark
                    @click="setShowDialog(false), setStep(1)"
                    >{{ mode == "VIEW" ? "Voltar" : "Cancelar" }}</v-btn
                  >
                </v-col>
                <v-col class="text-right">
                  <v-btn
                    color="success"
                    type="submit"
                    right
                    v-if="mode == 'EDIT' || mode == 'ADD'"
                    @click="saveCollaborator"
                    >Salvar</v-btn
                  >
                </v-col>
              </v-card-actions>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import debounce from "../../../util/debounce.js";
// import toBase64 from "@util/Base64";

export default {
  name: "CollaboratorsForm",
  computed: {
    ...mapState("login", ["userData"]),
    ...mapState("collaborators", [
      "addressForm",
      "id_collaborator",
      "apiAddress",
      "arrayCEP",
      "companies",
      "roles",
      "possible_genders",
      "possible_formations",
      "departments",
      "possible_pcd",
      "possible_registry",
      "possible_civil_status",
      "possible_childrens",
      "mode",
      "selectedItem",
      "showDialog",
      "childrensData",
    ]),
    ...mapFields("collaborators", [
      "selectedItem.id",
      "selectedItem.registration",
      "selectedItem.role",
      "selectedItem.department",
      "selectedItem.ctps",
      "selectedItem.serie",
      "selectedItem.uf",
      "selectedItem.pis",
      "selectedItem.rg",
      "selectedItem.birth_date",
      "selectedItem.nationality",
      "selectedItem.gender",
      "selectedItem.civil_status",
      "selectedItem.children",
      "selectedItem.cnh",
      "selectedItem.category",
      "selectedItem.voter_registration",
      "selectedItem.zone",
      "selectedItem.section",
      "selectedItem.mother",
      "selectedItem.father",
      "selectedItem.formation",
      "selectedItem.name",
      "selectedItem.cep",
      "selectedItem.cpf",
      "selectedItem.email",
      "selectedItem.id_company",
      "selectedItem.state",
      "selectedItem.district",
      "selectedItem.city",
      "selectedItem.street",
      "selectedItem.phone",
      "selectedItem.voucher",
      "selectedItem.number",
      "selectedItem.complement",
      "selectedItem.dispensation",
      "selectedItem.commission",
      "selectedItem.registry",
      "selectedItem.pcd",
      "selectedItem.salary",
      "selectedItem.ticket",
      "selectedItem.admission",
      "selectedItem.experience",
      "selectedItem.second_experience",
      "selectedItem.corporate_email",
      "selectedItem.expedient",
    ]),
  },
  mixins: [debounce],
  created() {
    this.getCompanies();
    this.getRoles();
    this.$validator.extend("required", {
      getMessage: (field, [label]) => label + " deve ser informado",
      validate: (_, [, field]) => {
        if (this[field] || this[field] === 0) {
          return true;
        } else {
          return false;
        }
      },
    });
  },
  data: () => ({
    valueStep: null,
    rules: {
      required: (value) => !!value || "Required.",
      counter: (value) => value.length <= 20 || "Max 20 characters",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    },
    timeProps: {
      format: "24hr",
    },
    valid: false,
    searchInput: "",
    fileString: "",
  }),
  methods: {
    setStep(step) {
      setTimeout(() => {
        this.valueStep = step;
      }, 500);
    },
    ...mapMutations("collaborators", [
      "setShowDialog",
      "setSelectedItem",
      "setShowDialog",
      "showAddress",
      "setDateExperiences",
      "addNewChildren",
      "removeNewChildren",
    ]),
    ...mapActions("collaborators", [
      "save",
      "getCompanies",
      "getApiCep",
      "getRoles",
      "getChildrens",
      "saveChildren",
    ]),
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      var stringFormatada = year + "/" + month + "/" + day;
      var dataFormatada = new Date(stringFormatada);

      var experienceDate = new Date(stringFormatada);
      experienceDate.setDate(dataFormatada.getDate() + 44);
      var experienceDateFormatada = experienceDate
        .toISOString()
        .substring(0, 10);

      var secondExperienceDate = new Date(stringFormatada);
      secondExperienceDate.setDate(dataFormatada.getDate() + 45);
      var secondExperienceDateFormatada = secondExperienceDate
        .toISOString()
        .substring(0, 10);

      this.setDateExperiences({
        experienceDate: experienceDateFormatada,
        secondExperienceDate: secondExperienceDateFormatada,
      });
    },
    clearCompany() {
      this.id_company = "";
    },
    getStatusByCode(role) {
      switch (role) {
        case 0:
          return "Inativo";
        case 1:
          return "Ativo";
      }
    },
    saveCollaborator() {
      // this.saveChildren()
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.save({ childrensData: this.childrensData });
        }
      });
    },
  },
};
</script>
