import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../../config/endPoints';

export default {
    namespaced: true,
    state: {
        agent_closure: {},
        adjustsDetails: [],
        chartRake: [],
        chartRakeback: [],
        dialog: false,
        dialogTransactionsCentral: false,
        loadingTransactionsCentral: false,
        loading_agents: false,
        loading_data: false,
        loading_players: false,
        editedItem: {},
        isAdjustsDetails: false,
        filter: {
            period: '',
            closure_id: ''
        },
        overlay: false,
        period: 0,
        id: 0,
        playersClosure: [],
        subAgentsClosure: [],
        showNotification: false,
        transactinsCentral: [],
        totalAdjust: 0
    },
    mutations: {
        setAgentClosure(state, payload) {
            var item = payload.item
            state.agent_closure = payload.item
            state.chartRake = [
                ['Tipo', 'Valor'],
                ['Jogadores', parseFloat((item.total_taxes + item.total_spin).toFixed(2))],
                ['Agentes', parseFloat((item.total_rake_sub_agents + item.total_spin_rakeback_sub_agents).toFixed(2))],
            ];
            state.chartRakeback = [
                ['Tipo', 'Valor'],
                ['Jogadores', parseFloat((item.rakeback + item.rakeback_spin).toFixed(2))],
                ['Agentes', parseFloat((item.total_rakeback_sub_agents + item.total_spin_rakeback_sub_agents).toFixed(2))],
            ]
        },
        setSubAgentsClosure(state, payload) {
            state.subAgentsClosure = payload.items
        },
        setPlayersClosure(state, payload) {
            state.playersClosure = payload.items
        },
        setAgentClosureParam(state, payload) {
            state.period = payload.period
            state.closure_id = payload.closure_id
        },
        loadingPlayers(state, payload) {
            state.loading_players = payload.show
        },
        loadingTransactionsCentral(state, payload) {
            state.loadingTransactionsCentral = payload.show
        },
        setAdjustDetails: (state, payload) => {
            if (payload.adjustsDetails.length > 0) {
                state.isAdjustsDetails = true
            } else {
                state.isAdjustsDetails = false
            }
            state.adjustsDetails = payload.adjustsDetails
            let total = 0
            payload.adjustsDetails.forEach(adjust => {
                total += adjust.value
            })
            state.totalAdjust = total
        },
        showDialogTransactionsCentral: (state, payload) => {
            state.dialogTransactionsCentral = payload.show
        },
        setTransactionCentralItems: (state, payload) => {
            state.transactinsCentral = payload.items
        },
        setTotalItemsTransactions: (state, payload) => {
            state.totalItemsTransactions = payload.total
        },
        updateField
    },
    getters: {
        getField,
    },
    actions: {
        getDataAgentClosure: ({ commit, dispatch, state }) => {
            var filter = state.filter;
            let url = endPoints.agents_closure + "/closure?";

            filter.period = state.period
            filter.closure_id = state.closure_id

            Object.keys(filter).forEach((field) => {
                if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
                    url += "&" + field + "=" + filter[field]
                }
            });
            axios.get(url).then(item => {
                commit('setAgentClosure', { item: item.data })
                dispatch('getSubAgentsClosureRake', { id_master_agent: item.data.id_agent })
                dispatch('getPlayersRake', { id_agent: item.data.id_agent })
            });
        },
        getSubAgentsClosureRake: ({ commit, state }, payload) => {
            var filter = state.filter;
            let url = endPoints.agents_closure + "/sub_agents_closure?page=1&itemsPerPage=5000";
            url += "&id_master_agent=" + payload.id_master_agent

            Object.keys(filter).forEach((field) => {
                if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
                    url += "&" + field + "=" + filter[field]
                }
            });
            axios.get(url).then(item => {
                let items = []
                if (item.data != null) {
                    items = item.data.data
                }
                commit('setSubAgentsClosure', { items: items })
            });
        },
        getAdjusts: ({ commit }, payload) => {
            let url = endPoints.agents_closure + '/adjusts_by_id_closure/' + payload.closure_id;

            console.log("data")
            axios.get(url).then(result => {
                commit('setAdjustDetails', { adjustsDetails : result.data})
                console.log(result.data)
            })
        },
        getPlayersRake: ({ commit, state }, payload) => {
            var filter = state.filter;
            let url = endPoints.agents_closure + "/players_rake?page=1&itemsPerPage=5000";
            url += "&id_agent=" + payload.id_agent

            Object.keys(filter).forEach((field) => {
                if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
                    url += "&" + field + "=" + filter[field]
                }
            });
            commit('loadingPlayers', { show: true })
            axios.get(url).then(item => {
                let items = []
                if (item.data != null) {
                    items = item.data
                }
                commit('loadingPlayers', { show: false })
                commit('setPlayersClosure', { items: items })
            });
        },
        openDialogTransactionsCentral: ({commit, state}) => {

            let url = endPoints.agents_closure + "/central_transactions?";
            url += `id_agent=${state.agent_closure.id_agent}&period=${state.agent_closure.period}&id_agent_closure=${state.agent_closure.id}`
            commit('loadingTransactionsCentral', { show: true })
            axios.get(url).then(results => {
                console.log(results.data)
                let items = []
                if (results.data != null) {
                    items = results.data
                }
                commit('loadingTransactionsCentral', { show: false })
                commit('showDialogTransactionsCentral', { show: true })
                commit('setTransactionCentralItems', { items: items })
                commit('setTotalItemsTransactions', { total: items.length })
            }).catch(() => {
                commit('loadingTransactionsCentral', { show: false })
            });
        }
    }
}