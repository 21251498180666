<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    
    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2000"
      v-model="showNotification"
    >
        {{ notificationMessage }}
    </v-snackbar>
    <v-toolbar flat class="elevation-5">
      <div>
        <v-toolbar-title>Notificações</v-toolbar-title>
      </div>
      <v-spacer></v-spacer>
      <div class="button-toolbar">
        <v-btn color="black" dark class="ma-2" @click="showFilter({show: true})" small><v-icon>mdi-filter</v-icon></v-btn>
        <v-btn color="black" dark class="ma-2" @click="getItems" small><v-icon>mdi-refresh</v-icon></v-btn>
        <v-btn color="black" dark class="ma-2" @click="add" small><v-icon>mdi-plus</v-icon></v-btn>
      </div> 
    </v-toolbar>

     <v-data-table
      :headers="headers"
      :loading="loading"
      :item-class= "getClassColor"
      :items="items"
      :options.sync="pagination"
      :server-items-length="totalItems"
      no-data-text="Nenhum erro encontrado"
      class="elevation-10 table-margin"
    >
      <template v-slot:[`item.value`]="{ item }">
        <span>{{ item.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">

        <v-tooltip bottom>
          <template v-slot:activator="{on, attrs}">
            <v-icon small v-on="on" v-bind="attrs" class="mr-2" @click="viewItem(item)">mdi-information</v-icon>
          </template>
          <span>Informações</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{on, attrs}">        
            <v-icon small v-on="on" v-bind="attrs" class="mr-2" @click="edit({item: item})">mdi-pencil</v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{on, attrs}">    
            <v-icon small v-on="on" v-bind="attrs" class="mr-2" @click="showDeleteDialog(item)">mdi-delete</v-icon>
          </template>
          <span>Apagar</span>
        </v-tooltip>

        <v-tooltip bottom v-if="item.status == 1 ">
          <template v-slot:activator="{on, attrs}">
            <v-icon small v-on="on" v-bind="attrs" class="mr-2" @click="finishFail({fail: item, screen: 'fail'})">mdi-check</v-icon>
          </template>
          <span>Concluir correção</span>
        </v-tooltip>        

      </template>
    </v-data-table>
    
    <v-dialog :value="dialog" width="800" @click:outside="closeDialog">
      <app-notifications-form></app-notifications-form>
    </v-dialog>

    <v-dialog :value="dialogFilter" width="600" @click:outside="showFilter({show: false})">
      <app-notifications-filter></app-notifications-filter>
    </v-dialog>

    <v-dialog :value="dialogDelete" persistent width="300">
      <v-card>
        <v-card-title class="headline">Confirmar a exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn
              text
              @click="closeDeleteDialog()"
            >Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn
              text
              type="submit"
              right
              @click="deleteItem"
            >Confirmar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogReceipt"
      @click:outside="setDialogReceipt({show: false})"
    >
      <v-card height="90vh">
        <v-img max-height="100%" :src="receipt_show" contain></v-img>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import { mapFields } from 'vuex-map-fields'
  import AppNotificationsForm from './AppNotificationsForm'
  import AppNotificationsFilter from './AppNotificationsFilter'
  import { utils, writeFile } from "xlsx";

  export default {
    name: 'AppNotifications',
    computed: {
      ...mapState('login', ['userData']),
      ...mapState('fail', ['contact_show', 'dialog', 'dialogDelete', 'dialogReceipt', 'dialogAudits',
            'item','instances', 'errors', 'users', 'loading', 'mode', 'notificationType', 'notificationMessage', 
            'rowsPerPage', 'receipt_show', 'totalItems', 'items', 'agents']),
      ...mapFields('fail', [
          'id_agent',
          'pagination',
          'dialogFilter',
          'overlay',
          'showNotification'
          // ...
        ])
    },
    created() {
      this.getItems({resetPage: false})
      this.setScreen({screen: 'fail'})
    },
    components: {
      // "download-excel": JsonExcel,
      "app-notifications-form": AppNotificationsForm,
      "app-notifications-filter": AppNotificationsFilter,
    },
    methods: {
      ...mapMutations('fail', ['add', 'Audits','edit', 'viewItem', 'showFilter', 'setDialogReceipt', "setScreen"]),
      ...mapActions('fail', ['closeDialog', 'closeDialogAudits', 'closeDeleteDialog', 'deleteItem', 'getUsers', 'getItems', 'getItemsNoPage', 
                    'showDeleteDialog','showReceipt', 'startWithdrawal', 'stopWithdrawal', 'getAgents', 'startFail', 'stopFail',
                    'finishFail' ]),
      isMobile() {
        if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
          return true;
        }
        return false
      },
      getStatus(status) {
        return this.statuss[status]
      },
      getBalance(balance) {
        return this.balances[balance]
      },
      getType(type) {
        return this.types[type]
      },
      getDate(date) {
        let datenova = date.split('-').reverse().join('/');
        return datenova
      },
      finishSheet() {
        this.overlay = false
      },
      generateSheet() {
        this.overlay = true
      },
      getSheetName() {
        var date = new Date()
        var name = 'erros_' + date.toLocaleDateString() + " " + date.toLocaleTimeString()
        return name+'.xls'
      },
      async downloadXlsx() {
        this.generateSheet()
        try {
          let itemsNoPage = await this.getItemsNoPage()
          if(itemsNoPage) {
            const wb = utils.book_new()
            let possible_status = ['Não Prejuízo', 'Pendente', 'Recuperado']
            let possible_balances = ['Não Prejuízo', 'Pendente', 'Recuperado']
            let possible_types = ['Adiantamento Rakeback', 'Anotados', 'Atenção', 'Comunicação', 'Demora', 'Fechamento de Caixa',    'Interpretação', 'Sistema']
            let items = itemsNoPage.map(row => ({
              "Data":  this.dateXLSX(row.date),
              "Hora":  this.dateXLSX(row.date),
              "Descrição":  row.description,
              "Valor":  this.valueXLSX(row.value),
              "Status":  possible_status[row.status],
              "Balance":  possible_balances[row.balance],
              "Type":  possible_types[row.type],
              "Usuário":  row.user_name,
              "Observação":  row.observation,
            }))
            const ws = utils.json_to_sheet(items)
            utils.book_append_sheet(wb, ws, "Erros")
            await writeFile(wb, this.getSheetName())
          }
        } catch (error) {
          this.finishSheet()
        } finally {
          this.finishSheet()
        }
      },
      dateXLSX: (value) => {
        var d = new Date(value);
        return d.toLocaleDateString();
      },
      timeXLSX: (value) => {
        var d = new Date(value);
        return d.toLocaleTimeString();
      },
      valueXLSX: (value) => {
        if (value) {
          let real = value.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          });
          return real.substr(3);
        }
        return 0;
      },
      getClassColor(item) {
        if(item.status == 2) {
          return 'row-green'
        } else if(item.status == 1) {
          return 'row-red'
        } else if(item.status == 0) {
          return 'row-teal'
        }
      },
      saveWithdrawal() {
        this.$validator.validateAll().then(valid => {
          if(valid) {
            this.value = parseFloat(this.value)
            this.value = this.value.toFixed(2)
            this.save()
          }
        });
      }
    },
    data: () => ({
      headers: [
        {
          text: "Data",
          align: "left",
          value: "date",
          sortable: false
        },
        { text: "Tipo",  value: "type", sortable: false},
        { text: "Descrição",  value: "description", sortable: false},
        { text: "Agente",  value: "agent_nick", sortable: false},
        { text: "Status",  value: "status", sortable: false},
        { text: "Usuário", value: "user_name", sortable: false},
        { text: "Valor", value: "value", sortable: false},
        { text: "Saldo Positivo",  value: "balance", sortable: false},
        { text: "Observação",  value: "observation", sortable: false},
        { text: "Ações",  value: "actions", sortable: false, align: "center" }
      ],
      statuss: ['Não Prejuízo', 'Pendente', 'Recuperado'],
      balances: ['Sim', 'Não'],
      types: [
        'Adiantamento Rakeback',
        'Anotados',
        'Atenção',
        'Comunicação',
        'Demora',
        'Fechamento de Caixa',   
        'Interpretação',
        'Sistema',
        'Depósito',
        'Saque'
      ],
      searchAgent: '',
      sheet_fields: {
        Data: {
          field: 'date',
          callback: (value) => {
            var d = new Date(value)
            return d.toLocaleDateString()
          }
        },
        Hora: {
          field: 'date',
          callback: (value) => {
            var d = new Date(value)
            return d.toLocaleTimeString()
          }
        },
        Descrição: {
          field: 'description',
        },
        Valor: {
          field: 'value',
          callback: (value) => {
            if(value) {
              let real = value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) 
              return real.substr(3)
            }
            return 0
          }
        },
        Status: {
          field: 'status',
          callback: (value) => {
            if(value) {
              let status = ['Não Prejuízo', 'Pendente', 'Recuperado']
              return status[value]
            }
            return ''
          }
        },
        Balance: {
          field: 'balance',
          callback: (value) => {
            if(value) {
              let balance = ['Não Prejuízo', 'Pendente', 'Recuperado']
              return balance[value]
            }
            return ''
          }
        },
        Type: {
          field: 'type',
          callback: (value) => {
            if(value) {
              let type = [
                'Adiantamento Rakeback',
                'Anotados',
                'Atenção',
                'Comunicação',
                'Demora',
                'Fechamento de Caixa',   
                'Interpretação',
                'Sistema',  
              ]
              return type[value]
            }
            return ''
          }
        },
        Usuário: {
          field: 'user_name'
        },
        Observação: {
          field: 'observation'
        }
      },
      valid: false
    }),
    watch: {
      pagination: {
        handler () {
          this.getItems({resetPage: false})
        },
      },
      agents_annotated() {
        console.log('Trocou mas n trocou')
      },
      searchContact (val) {
        var payload = {
          search: val,
          contacts: this.selected_contact
        }
        this.getContacts(payload)
      },
      searchAgent (val) {
        var payload = {
          search: val
        }
        this.getAgents(payload)
      }
    },
  }
</script>

<style>

</style>