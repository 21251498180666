import axios from 'axios';
// import endPoints from '../../config/endPoints';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../../config/endPoints';

export default {
    namespaced: true,
    state: {
        contacts: [],
        contacts_mode: '',
        ws: {},
        type_search: 0
    },
    mutations: {
        setContacts: (state, payload) => {
            state.contacts = payload.contacts
        },
        updateField
    },
    getters: {
        getField
    },
    actions: {
        getContacts({ commit, state }, payload) {
            console.log("getContacts", state.type_search)
            if (payload.search) {
                if(state.type_search == 1){
                    axios.get(endPoints.contact_list + "/search_contacts/" + payload.search).then(response => {
                        let contacts = []
                        if (response.data != null) {
                            contacts = response.data
                        }
                        var payloadContacts = {
                            contacts: contacts
                        }
                        commit('setContacts', payloadContacts)
                    });
                }  else if (state.type_search == 0) {
                    axios.get(endPoints.httpchats + "/search_chat/" + payload.search).then(response => {
                        let contacts = []
                        if (response.data != null) {
                            contacts = response.data
                        }
                        var payloadContacts = {
                            contacts: contacts
                        }
                        commit('setContacts', payloadContacts)
                    });
                }
            }
        }
    }
}