<template>
  <div>
    <v-dialog :value="dialog" persistent width="900">
      <v-card>
        <v-card-title>
          <span class="headline">Candidato </span>
        </v-card-title>
        <v-stepper v-model="e6" vertical>
          <v-stepper-step :complete="e6 > 1" step="1">
            1ª Etapa
          </v-stepper-step>
          <v-stepper-content step="1">
            <v-card class="mb-12" height="200px">
              <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-container grid-list-md>
                    <v-layout wrap>
                      <v-flex xs12 sm3 md3>
                        <v-datetime-picker
                          :key="id"
                          v-model="date"
                          date-format="dd/MM/yyyy"
                          label="Data da Entrevista *"
                          :disabled="this.mode == 'VIEW'"
                          :time-picker-props="timeProps"
                          :textFieldProps="{ outlined: true }"
                        >
                          <template slot="dateIcon">
                            <v-icon>mdi-calendar</v-icon>
                          </template>
                          <template slot="timeIcon">
                            <v-icon>mdi-clock</v-icon>
                          </template>
                        </v-datetime-picker>
                      </v-flex>
                      <v-flex xs12 sm6 md6>
                        <v-text-field
                          required
                          label="Nome*"
                          v-model="name"
                          :readonly="this.mode == 'VIEW'"
                          data-vv-name="name"
                          :error-messages="errors.collect('name')"
                          v-validate="'required:Nome,name'"
                          outlined
                        ></v-text-field>
                      </v-flex>

                      <v-flex xs12 sm3 md3>
                        <v-text-field
                          autocomplete="chrome-off"
                          required
                          label="Telefone *"
                          v-model="phone"
                          v-mask="'(##)#####-####'"
                          :clearable="this.mode == 'ADD' || this.mode == 'EDIT'"
                          :readonly="this.mode == 'VIEW'"
                          :error-messages="errors.collect('phone')"
                          v-validate="'required:Telefone,phone'"
                          data-vv-name="phone"
                          outlined
                        ></v-text-field>
                      </v-flex>

                      <v-flex xs12 sm5 md5>
                        <v-text-field
                          autocomplete="chrome-off"
                          required
                          :readonly="this.mode == 'VIEW'"
                          placeholder="example@email.com"
                          label="Email *"
                          v-model="email"
                          data-vv-name="email"
                          :error-messages="errors.collect('email')"
                          v-validate="'required:Email,email'"
                          outlined
                        ></v-text-field>
                      </v-flex>

                      <v-flex xs12 sm4 md4>
                        <v-select
                          label="Cargo de Interesse *"
                          :items="roles"
                          v-model="role"
                          readonly
                          :error-messages="errors.collect('role')"
                          v-validate="'required:Cargo,role'"
                          data-vv-name="role"
                          item-text="name"
                          item-value="id"
                          outlined
                        ></v-select>
                      </v-flex>

                      <v-flex xs12 sm3 md3>
                        <v-select
                          label="Setor *"
                          v-model="department"
                          :items="departments"
                          readonly
                          :error-messages="errors.collect('department')"
                          v-validate="'required:Setor,department'"
                          item-text="name"
                          item-value="id"
                          data-vv-name="department"
                          outlined
                        ></v-select>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-form>
              </v-card-text>
            </v-card>
            <v-card-actions>
              <v-col>
                <v-btn color="error" right @click="setShowDialog(false)">
                  Cancelar
                </v-btn>
              </v-col>
              <v-col class="text-right">
                <v-btn type="submit" color="primary" @click="e6 = 2">
                  Próximo
                </v-btn>
              </v-col>
            </v-card-actions>
          </v-stepper-content>

          <v-stepper-step :complete="e6 > 2" step="2">
            2ª Etapa
          </v-stepper-step>

          <v-stepper-content step="2">
            <v-card class="mb-12">
              <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-container grid-list-md>
                    <v-layout wrap>
                      <v-flex xs12 sm5 md5>
                        <v-text-field
                          label="Solicitante"
                          v-model="requester_name"
                          outlined
                          data-vv-name="Solicitante"
                          readonly
                        >
                        </v-text-field>
                      </v-flex>

                      <v-flex xs12 sm4 md4>
                        <v-select
                          label="Formato da Entrevista"
                          clear-icon="mdi-close"
                          v-model="interview_format"
                          :items="possible_interview"
                          :clearable="this.mode == 'ADD' || this.mode == 'EDIT'"
                          :readonly="this.mode == 'VIEW'"
                          item-text="description"
                          item-value="id"
                          outlined
                        ></v-select>
                      </v-flex>

                      <v-flex xs12 sm3 md3>
                        <v-select
                          label="Candidato indicado"
                          clear-icon="mdi-close"
                          v-model="nominated_candidate"
                          :items="possibles"
                          :clearable="this.mode == 'ADD' || this.mode == 'EDIT'"
                          :readonly="this.mode == 'VIEW'"
                          item-text="description"
                          item-value="id"
                          outlined
                        ></v-select>
                      </v-flex>

                      <v-flex v-if="nominated_candidate == 0" xs12 sm4 md4>
                        <v-autocomplete
                          clearable
                          v-model="who_nominated"
                          :no-filter="true"
                          :items="collaboratorsNominated"
                          :disabled="this.mode == 'VIEW'"
                          chips
                          small-chips
                          no-data-text="Nenhum colaborador encontrado"
                          item-text="name"
                          item-value="id"
                          label="Quem indicou"
                          outlined
                          :search-input.sync="searchInputWhoNominated"
                        ></v-autocomplete>
                      </v-flex>

                      <v-flex xs12 sm4 md4>
                        <v-select
                          label="Entrevista Comportamental"
                          clear-icon="mdi-close"
                          v-model="behavioral_interview"
                          :items="possible_results"
                          :clearable="this.mode == 'ADD' || this.mode == 'EDIT'"
                          :readonly="this.mode == 'VIEW'"
                          item-text="description"
                          item-value="id"
                          data-vv-name="behavioral_interview"
                          outlined
                        ></v-select>
                      </v-flex>

                      <v-flex xs12 sm4 md4>
                        <v-select
                          label="Responsável"
                          clear-icon="mdi-close"
                          v-model="responsible_test"
                          :items="collaboratorsResponsibles"
                          :clearable="this.mode == 'ADD' || this.mode == 'EDIT'"
                          :readonly="this.mode == 'VIEW'"
                          item-text="name"
                          item-value="id"
                          outlined
                        ></v-select>
                      </v-flex>

                      <v-layout v-if="behavioral_interview == 0">
                        <v-flex xs12 sm4 md4>
                          <v-datetime-picker
                            :key="id"
                            v-model="test"
                            date-format="dd/MM/yyyy"
                            label="Prova"
                            :disabled="this.mode == 'VIEW'"
                            :time-picker-props="timeProps"
                            :textFieldProps="{ outlined: true }"
                          >
                            <template slot="dateIcon">
                              <v-icon>mdi-calendar</v-icon>
                            </template>
                            <template slot="timeIcon">
                              <v-icon>mdi-clock</v-icon>
                            </template>
                          </v-datetime-picker>
                        </v-flex>

                        <v-flex xs12 sm4 md4>
                          <v-select
                            label="Resultado"
                            clear-icon="mdi-close"
                            v-model="result_test"
                            :items="possible_results"
                            :clearable="
                              this.mode == 'ADD' || this.mode == 'EDIT'
                            "
                            :readonly="this.mode == 'VIEW'"
                            item-text="description"
                            item-value="id"
                            outlined
                          ></v-select>
                        </v-flex>
                      </v-layout>
                    </v-layout>
                  </v-container>
                </v-form>
              </v-card-text>
            </v-card>
            <v-card-actions>
              <v-col>
                <v-btn
                  color="error"
                  right
                  @click="setShowDialog(false), (e6 = 1)"
                >
                  Cancelar
                </v-btn>
              </v-col>
              <v-col>
                <v-btn @click="e6 = 1" text> Voltar </v-btn>
              </v-col>
              <v-col class="text-right">
                <v-btn color="primary" @click="e6 = 3"> Próximo </v-btn>
              </v-col>
            </v-card-actions>
          </v-stepper-content>

          <v-stepper-step :complete="e6 > 3" step="3">
            3ª Etapa
          </v-stepper-step>

          <v-stepper-content step="3">
            <v-card class="mb-12">
              <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-container grid-list-md>
                    <v-layout wrap>
                      <v-layout
                        wrap
                        v-if="result_test == 0 && behavioral_interview == 0"
                      >
                        <v-flex xs12 sm6 md6>
                          <v-datetime-picker
                            :key="id"
                            v-model="date_interview"
                            date-format="dd/MM/yyyy"
                            label="Data da Entrevista Técnica"
                            :disabled="this.mode == 'VIEW'"
                            :time-picker-props="timeProps"
                            :textFieldProps="{ outlined: true }"
                          >
                            <template slot="dateIcon">
                              <v-icon>mdi-calendar</v-icon>
                            </template>
                            <template slot="timeIcon">
                              <v-icon>mdi-clock</v-icon>
                            </template>
                          </v-datetime-picker>
                        </v-flex>
                        <v-flex xs12 sm6 md6>
                          <v-select
                            label="Resultado"
                            clear-icon="mdi-close"
                            v-model="interview_result"
                            :items="possible_results"
                            :clearable="
                              this.mode == 'ADD' || this.mode == 'EDIT'
                            "
                            :readonly="this.mode == 'VIEW'"
                            item-text="description"
                            item-value="id"
                            outlined
                          ></v-select>
                        </v-flex>
                        <v-layout wrap v-if="interview_result == 0">
                          <v-flex xs12 sm3 md3>
                            <v-datetime-picker
                              :key="id"
                              v-model="practice_test"
                              date-format="dd/MM/yyyy"
                              label="Teste Prático"
                              :disabled="this.mode == 'VIEW'"
                              :time-picker-props="timeProps"
                              :textFieldProps="{ outlined: true }"
                            >
                              <template slot="dateIcon">
                                <v-icon>mdi-calendar</v-icon>
                              </template>
                              <template slot="timeIcon">
                                <v-icon>mdi-clock</v-icon>
                              </template>
                            </v-datetime-picker>
                          </v-flex>

                          <v-flex xs12 sm5 md5>
                            <v-autocomplete
                              clearable
                              v-model="responsible_practice_test"
                              :no-filter="true"
                              :items="collaborators"
                              :disabled="this.mode == 'VIEW'"
                              chips
                              small-chips
                              no-data-text="Nenhum colaborador encontrado"
                              item-text="name"
                              item-value="id"
                              label="Responsável"
                              outlined
                              :search-input.sync="searchInput"
                            ></v-autocomplete>
                          </v-flex>

                          <v-flex xs12 sm4 md4>
                            <v-select
                              label="Avaliação Psicológica"
                              clear-icon="mdi-close"
                              v-model="psychological_assessment"
                              :items="possible_psychological_assessment"
                              :clearable="
                                this.mode == 'ADD' || this.mode == 'EDIT'
                              "
                              :readonly="this.mode == 'VIEW'"
                              item-text="description"
                              item-value="id"
                              outlined
                            ></v-select>
                          </v-flex>
                        </v-layout>
                      </v-layout>

                      <v-flex xs12 sm6 md6>
                        <v-datetime-picker
                          :key="id"
                          v-model="return_process"
                          date-format="dd/MM/yyyy"
                          label="Retorno do Processo"
                          :disabled="this.mode == 'VIEW'"
                          :time-picker-props="timeProps"
                          :textFieldProps="{ outlined: true }"
                        >
                          <template slot="dateIcon">
                            <v-icon>mdi-calendar</v-icon>
                          </template>
                          <template slot="timeIcon">
                            <v-icon>mdi-clock</v-icon>
                          </template>
                        </v-datetime-picker>
                      </v-flex>

                      <v-flex xs12 sm6 md6>
                        <v-select
                          label="Pode ser chamado novamente"
                          clear-icon="mdi-close"
                          v-model="called_again"
                          :items="possibles"
                          :clearable="this.mode == 'ADD' || this.mode == 'EDIT'"
                          :readonly="this.mode == 'VIEW'"
                          item-text="description"
                          item-value="id"
                          outlined
                        ></v-select>
                      </v-flex>

                      <v-flex xs12 sm6 md6>
                        <v-select
                          label="Resultado Final"
                          v-model="final_result"
                          :items="possible_final_result"
                          readonly
                          item-text="description"
                          item-value="id"
                          outlined
                          :error-messages="errors.collect('final_result')"
                          v-validate="'required:Reultado Final,final_result'"
                          data-vv-name="final_result"
                        ></v-select>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-form> </v-card-text
            ></v-card>
            <v-card-actions>
              <v-col>
                <v-btn
                  color="error"
                  right
                  @click="setShowDialog(false), (e6 = 1)"
                >
                  Cancelar
                </v-btn>
              </v-col>
              <v-col>
                <v-btn @click="e6 = 2" text> Voltar </v-btn>
              </v-col>
              <v-col class="text-right">
                <v-btn type="submit" color="success" @click="saveCandidate">
                  Salvar
                </v-btn>
              </v-col>
            </v-card-actions>
          </v-stepper-content>
        </v-stepper>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "CandidatesForm",
  methods: {
    ...mapActions("candidates", [
      "save",
      "getSearchCollaborators",
      "getCollaborators",
      "getDepartments",
      "getRoles",
    ]),
    ...mapMutations("candidates", ["setShowDialog", "showNotification"]),
    saveCandidate() {
      if (this.behavioral_interview == null) {
        this.selectedItem.next_step = 0;
      }
      if (this.behavioral_interview == 1) {
        this.setSelectedItem(0);
      } else if (this.behavioral_interview == 0) {
        this.selectedItem.next_step = 1;
      }
      if (this.result_test == 1) {
        this.setSelectedItem(1);
      } else if (this.result_test == 0) {
        this.selectedItem.next_step = 2;
      }
      if (this.interview_result == 1) {
        this.setSelectedItem(2);
      } else if (this.interview_result == 0) {
        this.selectedItem.next_step = 3;
      }
      if (this.psychological_assessment == 1) {
        this.setSelectedItem(3);
      } else if (
        this.interview_result == 0 &&
        this.psychological_assessment == 0
      ) {
        this.selectedItem.final_result = 1;
        this.selectedItem.next_step = 4;
      }
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.date = this.setData(this.date);
          if (
            this.test != "" &&
            this.test != null &&
            this.test != "Invalid Date"
          ) {
            this.test = this.setData(this.test);
          }
          if (
            this.date_interview != "" &&
            this.date_interview != null &&
            this.date_interview != "Invalid Date"
          ) {
            this.date_interview = this.setData(this.date_interview);
          }
          if (
            this.practice_test != "" &&
            this.practice_test != null &&
            this.practice_test != "Invalid Date"
          ) {
            this.practice_test = this.setData(this.practice_test);
          }
          if (
            this.return_process != "" &&
            this.return_process != null &&
            this.return_process != "Invalid Date"
          ) {
            this.return_process = this.setData(this.return_process);
          }
          this.save();
          this.e6 = 1;
        } else {
          let notification = {
            show: true,
            message: "Preencha todos os campos corretamente",
            type: "error",
          };
          this.showNotification(notification);
        }
      });
    },
    setData(data) {
      var d = new Date(data);
      return d.toISOString().substr(0, 10) + " " + d.toLocaleTimeString();
    },
    setSelectedItem(value) {
      this.selectedItem.failed_in = value;
      this.selectedItem.final_result = 2;
      this.selectedItem.next_step = 4;
    },
  },
  computed: {
    ...mapState("candidates", [
      "mode",
      "selectedItem",
      "dialog",
      " errors",
      "collaborators",
      "collaboratorsNominated",
      "collaboratorsResponsibles",
      "roles",
      "departments",
      "possible_interview",
      "possible_results",
      "possible_final_result",
      "possible_psychological_assessment",
      "possibles",
    ]),
    ...mapFields("candidates", [
      "selectedItem.id",
      "selectedItem.behavioral_interview",
      "selectedItem.called_again",
      "selectedItem.department",
      "selectedItem.final_result",
      "selectedItem.result_test",
      "selectedItem.role",
      "selectedItem.date",
      "selectedItem.name",
      "selectedItem.interview_format",
      "selectedItem.nominated_candidate",
      "selectedItem.practice_test",
      "selectedItem.psychological_assessment",
      "selectedItem.requester",
      "selectedItem.requester_name",
      "selectedItem.responsible_practice_test",
      "selectedItem.responsible_test",
      "selectedItem.test",
      "selectedItem.who_nominated",
      "selectedItem.email",
      "selectedItem.return_process",
      "selectedItem.phone",
      "selectedItem.date_interview",
      "selectedItem.interview_result",
    ]),
  },
  created() {
    this.getRoles();
    this.getDepartments();
    this.$validator.extend("required", {
      getMessage: (field, [label]) => label + " inválido",
      validate: (_, [, field]) => {
        if (this[field] || this[field] === 0) {
          if (field == "email") {
            const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            var validEmail = pattern.test(this[field]) || false;
            return validEmail;
          } else {
            return true;
          }
        } else {
          return false;
        }
      },
    });
  },
  data: () => ({
    e6: 1,
    valid: false,
    timeProps: {
      format: "24hr",
    },
    searchInput: "",
    searchInputWhoNominated: "",
  }),
  watch: {
    searchInput: {
      handler() {
        if (this.searchInput) {
          var payload = {
            search: this.searchInput,
            type: 0,
          };
          this.getSearchCollaborators(payload);
        }
      },
    },
    searchInputWhoNominated: {
      handler() {
        if (this.searchInputWhoNominated) {
          var payload = {
            search: this.searchInputWhoNominated,
            type: 1,
          };
          this.getSearchCollaborators(payload);
        }
      },
    },
  },
};
</script>