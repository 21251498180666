<template>
  <div>
    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2000"
      v-model="showNotification"
    >
      {{ notificationMessage }}
    </v-snackbar>
    <v-toolbar flat color="green lighten-4">
      <v-icon color="green darken-4">mdi-account-group</v-icon>
      <div style="margin-left:10px;color:rgb(27, 54, 0)">Usuários</div>
      <v-spacer></v-spacer>
      <!-- <download-excel
        :before-generate="generateSheet"
        :before-finish="finishSheet"
        :name="getSheetName()"
        :fetch="getItemsNoPage"
        :export-fields="sheet_fields"
        class="excel"
        worksheet="Periodos"
        type="xls"
      > 
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="black" dark class="ma-2" small v-bind="attrs" v-on="on">
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </template>
        <span>Baixar xls</span>
      </v-tooltip>
      </download-excel> -->
      <v-tooltip bottom>
        <template v-slot:activator="{on, attrs}">
          <v-btn color="black" dark class="ma-2" @click="downloadXlsx({ show: true })" v-bind="attrs" v-on="on" small>
            <v-icon>mdi-file-excel</v-icon>
          </v-btn>
        </template>
        <span>Baixar XLSX</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            color="black"
            dark
            class="ma-2"
            @click="showFilter({ show: true })"
            small
          >
            <v-icon :color="filtered ? 'grey' : 'white'">mdi-filter</v-icon>
          </v-btn>
        </template>
        <span>Filtrar</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            color="black"
            dark
            class="ma-2"
            @click="getItems"
            small
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>
        <span>Recarregar</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="black"
            v-bind="attrs"
            v-on="on"
            dark
            class="ma-2"
            @click="addUser"
            small
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Adicionar usuário</span>
      </v-tooltip>
    </v-toolbar>
    <v-dialog
      :value="dialogFilter"
      width="600"
      v-model="dialogFilter"
      @click:outside="showFilter({ show: false })"
    >
      <user-filter></user-filter>
    </v-dialog>
    
    <v-dialog :value="dialog" persistent width="600">
      <users-form></users-form>
    </v-dialog>

    <v-data-table
      :headers="headers"
      :loading="loading"
      :items="users"
      :options.sync="pagination"
      :server-items-length="totalUsers"
      no-data-text="Nenhum usuário encontrado"
      class="elevation-1"
    >
      <template v-slot:[`item.role`]="{ item }">
        <span>{{ getRoleByCode(item.role) }}</span>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-chip :color="item.status ? 'green' : 'red'" class="ma-2" text-color='white' label>{{ getStatusByCode(item.status) }}</v-chip>
      </template>
      <template v-slot:[`item.authenticator_configured`]="{ item }">
        <v-chip :color="item.authenticator_configured ? 'green' : 'red'" class="ma-2" text-color='white' label>{{ item.authenticator_configured ? "Sim" : "Não" }}</v-chip>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              small
              class="mr-2"
              v-if="item.authenticator_configured"
              @click="resetQrCode({user: item})"
            >
              mdi-qrcode
            </v-icon>
          </template>
          <span>Resetar QR Code</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              small
              class="mr-2"
              @click="viewUser(item)"
            >
              mdi-information
            </v-icon>
          </template>
          <span>Informações</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              small
              class="mr-2"
              @click="edit({ item: item })"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              small
              class="mr-2"
              @click="showDeleteDialog(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>Excluir</span>
        </v-tooltip>
  
      </template>
    </v-data-table>

    <v-dialog :value="dialogDelete" persistent width="300">
      <v-card>
        <v-card-title class="headline text-center"
          >Confirmar a exclusão?</v-card-title
        >

        <v-card-actions>
          <v-col>
            <v-btn text @click="closeDeleteDialog()">Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn text type="submit" right @click="deleteUser"
              >Confirmar</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import userFilter from "./UsersFilter.vue";
// import JsonExcel from "vue-json-excel";
import possible_roles from "./possible_roles"
import { utils, writeFile } from "xlsx";
import valida_cpf_cnpj from '@util/CpfCnpj'
import UsersForm from "./UsersForm";

export default {
  name: "Usuarios",
  created() {
    this.getInstances();
    this.getItems();
  },
  components: {
    "user-filter": userFilter,
    "users-form": UsersForm,
    // "download-excel": JsonExcel,
  },
  computed: {
    ...mapFields("users", ["overlay"]),
    ...mapState("instances", ["instances"]),
    ...mapState("users", [
      "dialog",
      "dialogDelete",
      "errors",
      "filtered",
      "loading",
      "mode",
      "notificationType",
      "notificationMessage",
      "rowsPerPage",
      "pagination",
      "totalUsers",
      "users",
      "items"
    ]),
    ...mapState("login", ["userData"]),
    ...mapFields("users", [
      "dialogFilter",
      "pagination",
      "showNotification",
      // ...
    ]),
    rolesUser() {
      var role = this.userData.role;
      if (role == 99 || role == 93) {
        return this.possible_roles;
      } else if (role == 98) {
        var role_chat = [0, 1, 6, 80];
        return this.possible_roles.filter(
          (pr) => role_chat.indexOf(pr.role) != -1
        );
      } else if (role == 97 || role == 81) {
        var role_financial = [2, 5];
        return this.possible_roles.filter(
          (pr) => role_financial.indexOf(pr.role) != -1
        );
      } else if (role == 96) {
        var role_commercial = [3];
        return this.possible_roles.filter(
          (pr) => role_commercial.indexOf(pr.role) != -1
        );
      } else if (role == 94) {
        var role_marketing = [4, 7];
        return this.possible_roles.filter(
          (pr) => role_marketing.indexOf(pr.role) != -1
        );
      }

      return [];
    },
  },
  data: () => ({
    headers: [
      {
        text: "CPF/CNPJ",
        align: "left",
        sortable: true,
        value: "inscription",
      },
      {
        text: "Nome",
        align: "left",
        sortable: true,
        value: "name",
      },
      { text: "Email", value: "email", sortable: true },
      { text: "Clube", value: "instance_name", sortable: true },
      { text: "Contato", value: "contact", sortable: false },
      { text: "Data de Criação", value: "date_created", sortable: true },
      { text: "Status", value: "status", align: 'center', sortable: true },
      { text: "Authenticator Configurado", align: 'center', value: "authenticator_configured", sortable: false},
      { text: "Ações", value: "actions", sortable: false, align: "center" },
    ],
    possible_roles: possible_roles,
    rulesFields: [(value) => !!value || "Campo obrigatório"],
    rulesInscription: [(value) => value ? valida_cpf_cnpj(value) || "CPF/CNPJ inválido" : "Campo obrigatório"],
    rules: {
      required: (value) => !!value || "Required.",
      counter: (value) => value.length <= 20 || "Max 20 characters",
      email: (value) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    },
    possible_status: [
      { status: 0, description: "Inativo" },
      { status: 1, description: "Ativo" },
    ],
    sheet_fields: {
      Nome: {
        field: "name",
        callback: (value) => {
          if (value) {
            return value;
          }
        },
      },
      Email: {
        field: "email",
        callback: (value) => {
          if (value) {
            return value;
          }
          return 0;
        },
      },
      "Data de criação": {
        field: "date_created",
        callback: (value) => {
          if (value) {
            let aux = "";
            aux += value[3];
            aux += value[4];
            aux += value[2];
            aux += value[0];
            aux += value[1];
            for (let i = 5; i < value.length; i++) {
              aux += value[i];
            }
            var d = new Date(aux);
            return d.toLocaleDateString();
          }
        },
      },

      Funcao: {
        field: "role",
        callback: (value) => {
          let role = possible_roles.find((r) => r.role == value)
          if(role) {
            return role.description
          }
          return '';    
        },
      },
      status: {
        field: "status",
        callback: (value) => {
          return value ? "Ativo" : "Inativo";
        },
      },
    },
    valid: false,
  }),
  methods: {
    ...mapActions("instances", ["getInstances"]),
    ...mapMutations("users", ["addUser", "viewUser", "showFilter"]),
    ...mapActions("users", [
      "closeDialog",
      "closeDeleteDialog",
      "deleteUser",
      "edit",
      "getItems",
      "getItemsNoPage",
      "getUsers",
      "saveUser",
      "showDeleteDialog",
      "resetQrCode"
    ]),
    filter() {
      this.showFilter({ show: false });
    },
    finishSheet() {
      this.overlay = false;
    },
    formTitle() {
      switch (this.mode) {
        case "ADD":
          return "Novo Usuário";
        case "EDIT":
          return "Editar Usuário";
        default:
          return "Usuário";
      }
    },
    generateSheet() {
      this.overlay = true;
    },
    getRoleByCode(role) {
      let rl = this.possible_roles.filter((r) => r.role == role);
      if (rl.length > 0) {
        return rl[0].description;
      } else {
        return "";
      }
    },
    getSheetName() {
      var date = new Date();
      var name =
        "usuarios" +
        date.toLocaleDateString() +
        " " +
        date.toLocaleTimeString();
      return name + ".xls";
    },
    async downloadXlsx() {
      this.generateSheet()
      try {
        let itemsNoPage = await this.getItemsNoPage()
        if(itemsNoPage) {
          const wb = utils.book_new()
            let items = itemsNoPage.map(row => ({
            "Nome":  row.name,
            "Email":  row.email,
            "Data de criação":  this.dateCreatedXLSX(row.date_created),
            "Função":  this.roleXLSX(row.role),
            "Status":  this.statusXLSX(row.status),
          }))
          const ws = utils.json_to_sheet(items)
          utils.book_append_sheet(wb, ws, "Despesas")
          await writeFile(wb, this.getSheetName())
        }
      } catch (error) {
        this.finishSheet()
      } finally {
        this.finishSheet()
      }
    },
    dateCreatedXLSX: (value) => {
      if (value) {
        let aux = "";
        aux += value[3];
        aux += value[4];
        aux += value[2];
        aux += value[0];
        aux += value[1];
        for (let i = 5; i < value.length; i++) {
          aux += value[i];
        }
        var d = new Date(aux);
        return d.toLocaleDateString();
      }
    },
    roleXLSX: (value) => {
      let role = possible_roles.find((r) => r.role == value)
      if(role) {
        return role.description
      }
      return '';
    },
    statusXLSX: (value) => {
      return value ? "Ativo" : "Inativo";
    },
    getStatusByCode(role) {
      switch (role) {
        case 0:
          return "Inativo";
        case 1:
          return "Ativo";
      }
    },
    save() {
      let valid = this.$refs.form.validate();
      console.log(valid)
      if (valid) {
        this.saveUser();
      }
    },
  },
  watch: {
    pagination: {
      handler() {
        this.getItems();
      },
    },
  },
};
</script>

<style>
.alert {
  background-color: white;
  z-index: 100;
  top: 50%;
}
.error--text {
  color: red !important;
}
.right {
  float: right;
}
.v-card__text {
  padding: 0px !important;
}
/* removing spin-button from contact field */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
</style>