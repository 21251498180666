<template>
  <div>
    <div @drop.prevent="addDropFile" @dragover.prevent>

      <v-card>
        <v-card-title>
          <span class="headline">Perdas e Ganhos</span>
          <v-spacer></v-spacer>
          <v-progress-circular
            v-if="loading"
            indeterminate
            class="text-right"
            color="primary"
            size="24"
          ></v-progress-circular>

        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm12 md12>
                  <v-select
                    label="Clube *"
                    :items="instances"
                    v-model="club"
                    :readonly="this.mode == 'VIEW' || this.mode == 'EDIT'"
                    :error-messages="errors.collect('club')"
                    v-validate="'required:Clube,club'"
                    item-text="name"
                    item-value="id_instance"
                    data-vv-name="club"
                    outlined
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Ganhos Jogador *"
                    v-model="player_gains"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('player_gains')"
                    v-validate="'required:Ganhos do Jogador,player_gains|decimal:2'"
                    data-vv-name="player_gains"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Taxa *"
                    v-model="tax"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('tax')"
                    v-validate="'required:Ganhos do Jogador,tax|decimal:2'"
                    data-vv-name="tax"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-datetime-picker
                    v-model="date_picker"
                    date-format="dd/MM/yyyy"
                    :time-picker-props="timeProps"
                    label="Data *"
                    :disabled="this.mode == 'VIEW' || this.mode == 'EDIT'"
                    v-validate="'required:Data,date_picker'"
                    :error-messages="errors.collect('date_picker')"
                    data-vv-name="date_picker"
                    :textFieldProps="{outlined: true}"
                  >
                    <template slot="dateIcon">
                      <v-icon>mdi-calendar</v-icon>
                    </template>
                    <template slot="timeIcon">
                      <v-icon>mdi-clock</v-icon>
                    </template>
                  </v-datetime-picker>
                </v-flex>
                <v-expansion-panels v-if="this.mode == 'VIEW'">
                  <v-expansion-panel>
                    <v-expansion-panel-header>Auditoria</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-layout wrap>
                        <v-flex xs12 sm6 md6>
                          <v-text-field
                            required
                            label="Criado em"
                            :readonly="true"
                            outlined
                            :value="getDate(this.date_created)"
                          ></v-text-field>
                        </v-flex>
                       <v-flex xs12 sm6 md6>
                          <v-text-field
                            required
                            label="Criado Por"
                            :readonly="true"
                            outlined
                            :value="this.created_user_name"
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-col>
            <v-btn text @click="closeDialog">{{ mode == 'VIEW' ? 'Voltar' : 'Cancelar'}}</v-btn>
          </v-col>
          <v-col class="text-right" v-if="mode != 'VIEW'">
            <v-btn text type="submit" right @click="saveGainsLoses">Salvar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "GainsLosesForm",
  computed: {
    ...mapState("instances", ["instances"]),
    ...mapState("gains_loses", [
      "item",
      "instance_name",
      "loading",
      "mode",
      "message",
    ]),
    ...mapFields("gains_loses", [
        "editedItem.club",
        "editedItem.player_gains",
        "editedItem.tax",
        "editedItem.date",
        "editedItem.date_picker"
      // ...
    ]),
  },
  created() {
    this.$validator.extend("required", {
      getMessage: (field, [label]) => label + " deve ser informado",
      validate: (_, [, field]) => {
        if (this[field] || this[field] === 0) {
          return true;
        } else {
          return false;
        }
      },
    });
    this.$validator.extend("max_length", {
      getMessage: (_, [length]) => "No máximo " + length + " caracteres.",
      validate: (val, [length]) => {
        if (val.length > length) {
          return false;
        } else {
          return true;
        }
      },
    });
  },
  methods: {
    ...mapMutations("gains_loses", ["closeDialog"]),
    ...mapActions("gains_loses", ["save"]),
    getDate(date) {
      if (date) {
        var d = new Date(date);
        return d.toLocaleDateString() + " " + d.toLocaleTimeString();
      }
      return "";
    },
    saveGainsLoses() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          if (this.date_picker) {
            let d = new Date(this.date_picker);
            let localeDateSplitted = d.toLocaleDateString().split("/")
            this.date = `${localeDateSplitted[2]}-${localeDateSplitted[1]}-${localeDateSplitted[0]} ${d.toLocaleTimeString()}`
          }
          this.player_gains = parseFloat(this.player_gains);
          this.tax = parseFloat(this.tax);
          this.save();
        }
      });
    },
  },
  data: () => ({
    fileString: '',
    timeProps: {
      format: "24hr",
    },
    valid: false,
  })
};
</script>
