<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2000"
      v-model="showNotification"
    >
      {{ notificationMessage }}
    </v-snackbar>
    
    <v-toolbar class="mt-2 mb-5 elevation-0 px-3git">
      <v-toolbar-title class="title">Agentes Vinculados ao Cliente</v-toolbar-title>
      <v-spacer></v-spacer>
       
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn 
          v-bind="attrs" 
          v-on="on" 
          color="#373B40" 
          dark 
          @click="setShowDialog(true)" 
          small
          >Vincular Agente</v-btn>
        </template>
        <span>Vincular um novo cadastro de agente a esse cliente</span>
      </v-tooltip>
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :loading="loading"
      :items="itemsFilteredByClient"
      :options.sync="paginationByClient"
      :server-items-length="totalItemsByClient"
      :expanded.sync="expanded"
      :item-class="getClassColor" 
      show-expand
      no-data-text="Nenhum agente vinculado a esse cliente"
      class="elevation-10 table-margin pt-6"
    >

      <template v-slot:[`item.id_pppoker`]="{ item }">
        <span>
          {{( item.id_pppoker )}}
        </span>
      </template>

      <template v-slot:[`item.id_pppoker_old`]="{ item }">
        <span>
          {{( item.id_pppoker_old )}}
        </span>
      </template>

      <template v-slot:[`item.type_deal`]="{ item }">
        <span>{{ getTypeDeal(item.type_deal)}}</span>
      </template>

      <template v-slot:[`item.type_closure`]="{ item }">
        <span>{{ getTypeClosure(item.type_closure)}}</span>
      </template>

      <template v-slot:[`item.limit_type`]="{ item }">
        <span>{{ getLimitType(item.limit_type) }}</span>
      </template>

      <template v-slot:[`item.limit_annotated`]="{ item }">
        <span>{{ item.limit_annotated.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
      </template>

      <template v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }">
        <v-icon @click="expand(true)" v-if="!isExpanded">mdi-chevron-down</v-icon>
        <v-icon @click="expand(false)" v-if="isExpanded">mdi-chevron-up</v-icon>
      </template>

      <template v-slot:[`item.actions`]="{ item }" v-align="right">
        <v-tooltip bottom >
          <template v-slot:activator="{ attrs, on }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              small
              class="mr-2"
              @click="setDelete(item)"
              >mdi-delete</v-icon
            >
          </template>
          <span>Excluir Vinculo</span>
        </v-tooltip>

      </template>

      <template v-slot:expanded-item="{ item }">
        <td :colspan="headers.length">
          <v-container grid-list-xs>
            <v-row dense>
              <v-col cols="2">
                <div>
                  <strong>Nome:</strong> 
                  {{ item.name }}
                </div>
                <div>
                  <strong>Nick:</strong> 
                  {{ item.nick }}
                </div>
              </v-col>

              <v-col cols="2">
                <div>
                  <strong>Id Suprema:</strong> 
                  {{ item.id_pppoker }}
                </div>
                <div>
                  <strong>Id PPPoker:</strong> 
                  {{ item.id_pppoker_old }}
                </div>
              </v-col>

              <v-col cols="2">
                <div v-if="item.is_master_agent == 0">
                  <strong>Ag. Master:</strong> 
                  {{ item.nick_master_agent }}
                </div>
                <div v-else>
                  <strong>Master: </strong>
                  {{ (item.is_master_agent == 1) ? 'Sim' : 'Não' }}
                </div>
                <div>
                  <strong>Clube:</strong> 
                  {{ item.club_name }}
                </div>
              </v-col>

              <v-col cols="3">
                <div>
                  <strong>Tipo de Acerto:</strong> 
                  {{ getTypeDeal(item.type_deal) }}
                </div>
                <div>
                  <strong>Tipo de Fechamento:</strong> 
                  {{ getTypeClosure(item.type_closure) }}
                </div>
              </v-col>

              <v-col cols="3">
                <div>
                  <strong>Telefone:</strong> 
                  {{ item.phone }}
                </div>
                <div>
                  <strong>Grupo:</strong> 
                  {{ item.ag_group_id }}
                </div>
                <div>
                  <strong>Email:</strong> 
                  {{ item.email }}
                </div>
              </v-col>
            </v-row>
          </v-container>
        </td>
      </template>
      
    </v-data-table>

    <v-dialog
      :value="showDialog"
      @keydown.esc="setShowDialog(false)"
      @click:outside="setShowDialog(false)"
      width="700"
    >
      <link-client-form></link-client-form>
    </v-dialog>

    <v-dialog
      :value="dialogDelete"
      @click:outside="setDeleteDialog(false)"
      @keydown.enter="deleteLink({'delete': true})"
      width="300"
    >
      <v-card>
        <v-card-title class="headline">Remover vinculo?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn text @click="setDeleteDialog(false)">Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn text type="submit" right @click="deleteLink({'delete': true})"
              >Confirmar</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
  </div>
</template>

<script>
  import { mapActions, mapMutations, mapState } from 'vuex'
  import { mapFields } from 'vuex-map-fields'
  import LinkClientForm from "../LinkClientForm.vue";


  export default {
    name: 'AgentsData',

    components: {
      "link-client-form": LinkClientForm
    },

    created() {
      let client_id = this.$route.params.client_id;

      this.filter.client_id = client_id
      this.getItemsByClient({resetPage: true, filteredByClient: true})
    },

    computed: {
      ...mapState('agents', [
        'loading', 
        'mode', 
        'notificationType', 
        'notificationMessage', 
        'rowsPerPageByClient',  
        'totalItemsByClient', 
        'itemsFilteredByClient',
      ]),
      ...mapFields('agents', [
        'paginationByClient',
        'showNotification',
        "filter",
      ]),
      ...mapState("client", [
        "clientId",
        "loading",
        "showDialog",
        "notificationMessage",
        "notificationType",
        "notification",
        'dialogDelete',
      ]),
    },
    methods: {
      ...mapActions('agents', [
        'getItemsByClient', 
        'closeDeleteDialog',
      ]),
      ...mapActions('client', [
        "deleteLink",
      ]),
      ...mapMutations("client", [
        "setShowDialog",
        "link",
        "setDelete",
        "setDeleteDialog",
      ]),

      getTypeDeal(type) {
        let types = ["Ganho/Perda", "Giro", "Normal", "Fichas Enviadas/Retiradas", "Sem Acerto", "Sem Acerto/GP"];
        if (types[type]) {
          return types[type];
        }
        return "";
      },
      getTypeClosure(type) {
        let types = ["Automático", "Manual"];
        if (types[type]) {
          return types[type];
        }
        return "";
      },
      getLimitType(type) {
        let types = ["Anotados", "Anotados e Adt. de Rake"];
        if (types[type]) {
          return types[type];
        }
        return "";
      },
      getClassColor(item){
        return item.blocked_annotated == 1 ? "row-yellow-schedule" : ""
      },
      
    },


    data: () => ({
      expanded: [],
      headers: [
        { text: "Id", value: "id" },
        { text: "Nick", align: "left", value: "nick" },
        { text: "ID PPPoker", value: "id_pppoker_old", align: "left", },
        { text: "ID Suprema", value: "id_pppoker", align: "left",},
        { text: "Grupo", value: "ag_group_name" },
        { text: "Limite", value: "limit_annotated"},
        { text: "Tipo de Limite", value: "limit_type", align: "left", width: "16%"  },
        { text: "",  value: "actions", sortable: false, align: "center" },
        { text: '', value: 'data-table-expand' },
      ],
      overlay: false,
    }),


    watch: {
      paginationByClient: {
        handler () {
          this.getItemsByClient({resetPage: false, filteredByClient: true})
        },
      },
    }
  }
</script>

