import axios from 'axios';
import endPoints from '../../../config/endPoints';
import { getField, updateField } from 'vuex-map-fields';

export default {
    namespaced: true,
    state: {
        avg_time_first_contact: 0,
        avg_time: '',
        big_time: 0,
        total: 0,
        filter: {},
        attendance_transfer: {},
        attendances: [],
        dialog_message_at: false,
        overlay: false,
        dialogTransferAttendance: false,
        messages_at: [],
        notification_type: '',
        notification_message: '',
        showNotification: '',
        users_name: [],
        users: []
    },
    mutations: {
        setAvgTime: (state, payload) => {
            let registers = 0
            let registers_first_contact = 0
            let time = 0
            let big_time = 0
            let time_first_contact = 0

            const buildTimeString = (delta) => {

                // calculate (and subtract) whole hours
                var hours = Math.floor(delta / 3600) % 24;
                delta -= hours * 3600;

                // calculate (and subtract) whole minutes
                var minutes = Math.floor(delta / 60) % 60;
                delta -= minutes * 60;
                var seconds = parseInt(delta % 60);

                if (hours.toString().length == 1) {
                    hours = "0".concat(hours.toString());
                }
                if (minutes.toString().length == 1) {
                    minutes = "0".concat(minutes.toString());
                }
                if (seconds.toString().length == 1) {
                    seconds = "0".concat(seconds.toString());
                }
                // what's left is seconds
                return hours + ":" + minutes + ":" + seconds;
            }

            payload.attendances.map((f) => {
                if (f.ended_time && f.receive_time) {
                    let diff = f.ended_time - f.receive_time
                    if (diff < 6000) {
                        time += Math.abs(f.ended_time - f.receive_time)
                        registers++;
                    } else {
                        big_time++;
                    }
                }
                if (f.start_time && f.receive_time) {
                    let diff_first = f.start_time - f.receive_time
                    if (diff_first < 6000) {
                        time_first_contact += diff_first
                        registers_first_contact++;
                    }
                }
            })
            if (payload.attendances.length != 0) {
                var avg = time / registers
                var avg_time_first_contact = time_first_contact / registers_first_contact
                let avg_string = buildTimeString(avg)
                let avg_first_string = buildTimeString(avg_time_first_contact)
                state.avg_time = avg_string
                state.avg_time_first_contact = avg_first_string
            } else {
                state.avg_time = "-"
            }
            state.big_time = big_time

        },
        overlay: (state, payload) => {
            state.overlay = payload.show
        },
        showFilter: (state, payload) => state.dialogFilter = payload.show,
        showDialogMessagesAttendance: (state, payload) => {
            state.dialog_message_at = payload.show
        },
        setUsersName: (state, payload) => {
            let users_name = []
            payload.users.forEach(user => {
                users_name[user.id] = user.name
            });
        },
        setMessagesAt: (state, payload) => {
            state.messages_at = payload.messages
        },
        setUsers: (state, payload) => {
            state.users = payload.users
        },
        setAttendances: (state, payload) => {
            state.attendances = payload.attendances
        },
        setTotal: (state, payload) => {
            state.total = payload.total
        },
        showNotification: (state, payload) => {
            state.showNotification = payload.show,
                state.notificationMessage = payload.message,
                state.notificationType = payload.type
        },
        updateField
    },
    getters: {
        getField
    },
    actions: {
        getAttendants: ({ commit }, payload) => {
            let url = endPoints.users;
            url += '/attendants/' + payload.search

            axios.get(url).then(users => {
                commit('setUsers', { users: users.data })
            });
        },
        getAttendances: ({ commit }, payload) => {
            let url = endPoints.httpchats + '/attendances';
            var data = {
                start_time: payload.start_date.getTime() / 1000,
                ended_time: payload.end_date.getTime() / 1000,
                user_id: payload.user_id
            }
            commit('overlay', { show: true })
            axios.post(url, data).then(attendances => {
                var data = attendances.data
                if (data == null) {
                    data = []
                }
                commit('setAttendances', { attendances: data })
                commit('setAvgTime', { attendances: data })
                commit('setTotal', { total: data.length })
                commit('overlay', { show: false })
            });
        },
        dialogMessagesAttendance: ({ commit }, payload) => {
            let url = endPoints.httpchats + '/attendances/messages?contact_id=' + payload.item.contact_id + '&attendance_id=' + payload.item.attendance_id;

            commit('overlay', { show: true })
            axios.get(url).then(attendances => {
                var data = attendances.data
                if (data == null) {
                    data = []
                }
                commit('overlay', { show: false })
                commit('showDialogMessagesAttendance', { show: true })
                commit('setMessagesAt', { messages: data })
            }, () => {
                let notification = {
                    show: true,
                    message: "Ocorreu um erro ao buscar as mensagens do attendance!",
                    type: "error"
                }
                commit('overlay', { show: false })
                commit('showNotification', notification)
            });
        }
    }
}