<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar
      :color="notification.type"
      right
      top
      :timeout="2000"
      v-model="notification.show"
    >
      {{ notification.message }}
    </v-snackbar>

    <v-toolbar flat class="elevation-5">
      <div>
        <v-toolbar-title>Pastas de Agentes</v-toolbar-title>
      </div>
      <v-spacer></v-spacer>
      <div class="button-toolbar">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="black"
              dark
              class="ma-2"
              @click="setShowFilter(true)"
              v-bind="attrs"
              v-on="on"
              small
            >
              <v-icon color="white">mdi-filter</v-icon>
            </v-btn>
          </template>
          <span>Filtro</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="black"
              dark
              class="ma-2"
              @click="getItems"
              v-bind="attrs"
              v-on="on"
              small
              ><v-icon>mdi-refresh</v-icon></v-btn
            >
          </template>
          <span>Recarregar</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="black"
              dark
              class="ma-2"
              v-if="profileAccess()"
              v-bind="attrs"
              v-on="on"
              @click="add()"
              small
              ><v-icon>mdi-plus</v-icon></v-btn
            >
          </template>
          <span>Adicionar Pasta</span>
        </v-tooltip>
      </div>
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :loading="loading"
      :items="items"
      :options.sync="pagination"
      :server-items-length="totalItems"
      class="elevation-10 table-margin"
      no-data-text="Nenhuma despesa encontrada"
    >
      
      <template v-slot:[`item.date`]="{ item }">
        <span>{{ getDate(item.date) }}</span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ attrs, on }">
            <v-icon
              small
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="viewItem(item)"
              >mdi-information</v-icon
            >
          </template>
          <span>Informações da pasta</span>
        </v-tooltip>

        <v-tooltip bottom v-if="profileAccess()">
          <template v-slot:activator="{ attrs, on }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              small
              class="mr-2"
              @click="edit(item)"
              >mdi-pencil</v-icon
            >
          </template>
          <span>Editar pasta</span>
        </v-tooltip>

        <v-tooltip bottom v-if="profileAccess()">
          <template v-slot:activator="{ attrs, on }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              small
              class="mr-2"
              @click="deleteFolder(item)"
              >mdi-delete</v-icon
            >
          </template>
          <span>Excluir pasta</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog
      :value="showDialog"
      @click:outside="setShowDialog(false)"
      width="1000"
    >
      <agents-folders-form></agents-folders-form>
    </v-dialog>

    <v-dialog
      :value="filterDialog"
      @click:outside="setShowFilter(false)"
      width="600"
    >
      <agents-folders-filter></agents-folders-filter>
    </v-dialog>

    <v-dialog
      :value="deleteDialog"
      @click:outside="setDeleteDialog(true)"
      @keydown.enter="deleteItem"
      width="300"
    >
      <v-card>
        <v-card-title class="headline">Confirmar a exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn text @click="setDeleteDialog(false)">Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn text type="submit" right @click="deleteItem"
              >Confirmar</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import AgentsFoldersForm from "./AgentsFoldersForm";
import AgentsFoldersFilter from "./AgentsFoldersFilter";
export default {
name: "AgentsFolders",

  created() {
    this.getItems();
  },

  components: {
    AgentsFoldersForm,
    AgentsFoldersFilter,
  },

  methods: {
    ...mapActions("agents_folders", ["getItems", "deleteItem", "viewItem"]),
    ...mapMutations("agents_folders", [
      "setShowDialog",
      "add",
      "edit",
      "setShowFilter",
      "setDeleteDialog",
      "deleteFolder",
    ]),
    ...mapMutations("agents_folder", ["setFolder"]),
    profileAccess() {
      if (
        this.userData.role == 0 ||
        this.userData.role == 1 ||
        this.userData.role == 70
      ) {
        return false;
      } else {
        return true;
      }
    },
    getDate(date) {
      var d = new Date(date);
      return d.toLocaleDateString()
    },
  },
  computed: {
    ...mapState("login", ["userData"]),
    ...mapState("agents_folders", [
      "items",
      "notification",
      "mode",
      "loading",
      "totalItems",
      "showDialog",
      "filterDialog",
      "deleteDialog",
    ]),
    ...mapFields("agents_folders", ["pagination"]),
  },
  data: () => ({
    headers: [
      { text: "ID", value: "id", sortable: true, width: "10%"},
      { text: "Título", value: "title", sortable: false },
      { text: "Descrição", value: "description", sortable: false },
      { text: "Data", value: "date", sortable: true, width: "15%" },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        align: "center",
        width: "10%",
      },
    ],
  }),
}
</script>
