<template>
    <div class="reference" :class="{own: type == 1, other: type == 0,  reply: type == 2}" role="button">
        <v-dialog
            content-class="reference-dialog"
            width="400"
            height="300"
            v-model="dialogReferenceMessage"
        >
            <message class="message" :index="0" :isGroup="isGroup" :message="message_reference" :openedChat="openedChat" :lastMessage="{}" :class="{own: message_reference.type == 0}" :reference="true"></message>
        </v-dialog>
        <span class="l" :class="{own: message_reference.type == 0, other: message_reference.type == 1}"></span>
        <div class="reference-message" @click="onClickReference(reference_id)"> 
            <div class="reference-username" :class="{own: message_reference.type == 0, other: message_reference.type == 1}"> 
                {{ contact_name }}
            </div> 
            <div class="reference-data">
                <div class="reference-content" v-if="message_reference.content" v-html="getMessageContent(message_reference)"></div>
                <div class="reference-content" v-else-if="message_reference.image_base64">
                    <div> 
                        <v-icon small v-if="!message_reference.content">mdi-camera</v-icon>
                        Foto
                    </div>
                </div>
                <div class="reference-content" v-else-if="message_reference.audio_base64">
                    <div> 
                        <v-icon small v-if="!message_reference.content">mdi-microphone</v-icon>
                        Audio
                    </div>
                </div>
            </div>
        </div>    
        <div class="reference-image" v-if="message_reference.image_base64">
            <v-img max-width="50" max-height="50" :src="message_reference.image_base64"></v-img>
        </div>
        <div class="reference-image" v-if="message_reference.document_thumb">
            <v-img max-width="50" max-height="50" :src="message_reference.document_thumb"></v-img>
        </div>
        <v-icon v-if="close" @click="setReplyMessage({message: {}})">mdi-close</v-icon>
    </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
    name: 'reference-message',
    props: [
        'close',
        'contact_name',
        'isGroup',
        'openedChat',
        'message_reference',
        'reference_id',
        'type'
    ],
    components: {
        message: () => import('./Message.vue')
    },
    data: () => ({
        dialogReferenceMessage: false,
    }),
    methods: {
        ...mapMutations("chat", ["setReplyMessage"]),
        getMessageContent(message) {
            var messageTreated = 
            message.content
            .replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/"/g, '&quot;')
            .replace(/'/g, '&#039;')
            .replace(/\n/g, '<br>')
            .replace(/\*([^]+?)\*/g, "<b>$1</b>")
            return messageTreated
        },
        onClickReference(referenceId) {
            var e = document.getElementById(referenceId)
            if(e) {
                e.style.backgroundColor = "#ccc"

                e.scrollIntoView({
                    behavior: 'auto',
                    block: 'center',
                    callback:  setTimeout((e) => {
                        e.style.backgroundColor = ""
                    }, 1000, e),
                    inline: 'center'
                }); 
            }
            else {
                this.dialogReferenceMessage = true
            }

        },
    }
}
</script>

<style>
    @import './css/reference.css';
</style>
