<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar
      :color="notification.type"
      right
      top
      :timeout="2000"
      v-model="notification.show"
    >
      {{ notification.message }}
    </v-snackbar>

    <v-dialog
      :value="filterDialog"
      width="600"
      @click:outside="setDialog({ dialog: 'filterDialog', show: false })"
    >
      <player-history-filter></player-history-filter>
    </v-dialog>

    <v-toolbar>
      <v-icon color="black">mdi-bookmark-outline</v-icon>
      <v-toolbar-title class="font-weight-medium">TomTicket</v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="button-toolbar text-right">
        <!-- <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="black"
              dark
              class="ma-2"
              @click="setDialog({ dialog: 'filterDialog', show: true })"
              v-bind="attrs"
              v-on="on"
              small
            >
              <v-icon color="white">mdi-filter</v-icon>
            </v-btn>
          </template>
          <span>Filtro</span>
        </v-tooltip> -->

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="black"
              dark
              class="ma-2"
              @click="getItems({ resetPage: false })"
              v-bind="attrs"
              v-on="on"
              small
              ><v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Recarregar</span>
        </v-tooltip>
      </div>
    </v-toolbar>

    <v-card>
      <v-data-table
        :headers="headers"
        :loading="loading"
        :items="items"
        :options.sync="pagination"
        :server-items-length="totalItems"
        no-data-text="Nenhum registro encontrado"
        class="elevation-10 table-margin"
      >
        <template v-slot:[`item.descsituacao`]="{ item }">
          <v-chip
            :color="getColorStatus(item.descsituacao)"
            class="ma-2"
            text-color="white"
            label
            >{{ item.descsituacao }}</v-chip
          >
        </template>
        <template v-slot:[`item.prioridade`]="{ item }">
          <v-chip
            :color="getColorPrioridade(item.prioridade)"
            class="ma-2"
            text-color="white"
            label
            >{{ getPrioridade(item.prioridade) }}</v-chip
          >
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
// import PlayerHistoryFilter from "./PlayerHistoryFilter";

export default {
  name: "TomTicket",
  // components: {
  //   "player-history-filter": PlayerHistoryFilter,
  // },
  created() {
    this.getItems({ resetPage: false });
  },
  methods: {
    ...mapActions("tomticket", ["getItems"]),
    ...mapMutations("tomticket", ["view", "setDialog"]),
    // 0 - Aguardando interação do atendente
    // 1 - Não iniciada
    // 2 - Respondido, aguardando resposta do cliente
    // 3 - Respondido pelo cliente, aguardando resposta
    // 4 - Cancelada
    // 5 - Finalizada
    // 6 - Atendente modificado
    // 7 - Enviada para equipe de apoio
    // 8 - Aguardando avaliação do gerente
    // 9 - Aguardando avaliação do gerente
    getColorStatus(payload) {
      switch (payload) {
        case "Aguardando interação do atendente":
          return "blue darken-1";
        case "Não iniciada":
          return "purple darken-1";
        case "Respondido, aguardando resposta do cliente":
          return "light-blue darken-1";
        case "Respondido pelo cliente, aguardando resposta":
          return "teal darken-1";
        case "Cancelada":
          return "brown darken-1";
        case "Finalizado":
          return "green darken-1";
        default:
          return "grey darken-1";
      }
    },
    getColorPrioridade(payload) {
      switch (payload) {
        case 1:
          return "blue darken-4";
        case 2:
          return "yellow darken-4";
        case 3:
          return "orange darken-4";
        case 4:
          return "red darken-4";
        default:
          return "grey darken-1";
      }
    },
    getPrioridade(payload) {
      switch (payload) {
        case 1:
          return "Baixa";
        case 2:
          return "Normal";
        case 3:
          return "Alta";
        case 4:
          return "Urgente";
        default:
          return "Sem Prioridade";
      }
    },
  },
  computed: {
    ...mapState("login", ["userData"]),
    ...mapState("tomticket", [
      "items",
      "notification",
      "loading",
      "totalItems",
      "filterDialog",
    ]),
    ...mapFields("tomticket", [
      "pagination",
      "filter.data_criacao",
      "filter.atendente",
      "filter.categoria",
      "filter.departamento",
      "filter.descsituacao",
      "filter.nomecliente",
      "filter.prioridade",
      "filter.titulo",
      "filter.start",
      "filter.end",
    ]),
  },
  data: () => ({
    headers: [
      {
        text: "Criado em",
        value: "data_criacao",
        sortable: false,
        align: "center",
      },
      {
        text: "Prioridade",
        value: "prioridade",
        sortable: false,
        align: "center",
      },
      { text: "Título", value: "titulo", sortable: false, align: "left" },
      { text: "Atendente", value: "atendente", sortable: false, align: "left" },
      { text: "Categoria", value: "categoria", sortable: false, align: "left" },
      {
        text: "Departamento",
        value: "departamento",
        sortable: false,
        align: "left",
      },
      {
        text: "Status",
        value: "descsituacao",
        sortable: false,
        align: "center",
      },
      { text: "Cliente", value: "nomecliente", sortable: false, align: "left" },
    ],
  }),
};
</script>
