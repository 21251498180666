<template>
  <div>
    <v-dialog :value="showDialog" persistent width="600">
      <v-card>
        <v-card-title>
          <span class="headline">Centro de Custo</span>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    required
                    label="Nome do Centro de Custo *"
                    v-model="name"
                    :clearable="this.mode == 'ADD' || this.mode == 'EDIT'"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('name')"
                    v-validate="'required:Nome,name'"
                    data-vv-name="name"
                    outlined
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-col>
            <v-btn text @click="setShowDialog(false)">{{
              mode == "VIEW" ? "Voltar" : "Cancelar"
            }}</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn
              text
              type="submit"
              right
              v-if="mode == 'EDIT' || mode == 'ADD'"
              @click="save"
              >Salvar</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "CostCenterForm",
  computed: {
    ...mapState("cost_center", [
      "mode",
      "selectedItem",
      "showDialog"
      ]),
    ...mapFields("cost_center", [
      "selectedItem.id",
      "selectedItem.name",
    ]),
  },
  methods: {
    ...mapMutations("cost_center", [
      "setShowDialog",
      "setSelectedItem",
      "setShowDialog",
    ]),
    ...mapActions("cost_center", [
      "save",
      ]),
  },
  data: () => ({}),
};
</script>
