import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../../config/endPoints';
import b64 from '../../../util/Base64'

const defaultItem = {
    description: '',
    file_name: '',
    last_update: '',
    type_document: '',
    document_file: ''
};

export default {
    namespaced: true,
    state: {
        agent_id: 0,
        dialog: false,
        dialogDelete: false,
        dialogShowDocument: false,
        documentToShow: '',
        editedItem: {},
        find_agents: [],
        filter: {
            id_master_agent: ''
        },
        filter_agent: {
            nick: '',
        },
        items: [],
        itemToDelete: {},
        loading: false,
        mode: 'LIST',
        overlay: false,
        pagination_subagent: {
            page: 1,
            itemsPerPage: 10
        },
        subAgentsAgent: [],
        totalItems: 0
    },
    mutations: {
        add: (state) => {
            state.mode = "ADD"
            state.selectedContact = {}
            state.editedItem = Object.assign({}, defaultItem)
            state.dialog = true
        },
        edit: (state, payload) => {
            state.selectedContact = {}
            state.editedItem = Object.assign({}, payload.item)
            state.editedIndex = state.subAgentsAgent.indexOf(payload.item)
            state.mode = "EDIT"
            state.dialog = true
        },
        closeDialog: (state) => {
            state.editedItem = {}
            state.editedIndex = -1
            state.errors = [];
            state.dialog = false
            state.mode = "LIST"
        },
        loading: (state, payload) => state.loading = payload.loading,
        setMode: (state, mode) => state.mode = mode,
        setSubAgents: (state, payload) => {
            state.subAgentsAgent = payload.subAgents
        },
        setItems: (state, payload) => {
            state.items = payload.items
        },
        overlay: (state, payload) => {
            state.overlay = payload.show
        },
        setDialogShowDocument: (state, payload) => state.dialogShowDocument = payload.show,
        setDocumentToShow: (state, payload) => state.documentToShow = payload.document,
        setItemToDelete: (state, item) => state.itemToDelete = item,
        setTotalItems: (state, payload) => state.totalItems = payload.total,
        setMasterAgents: (state, payload) => state.master_agents = payload.master_agents,
        showDialog: (state, show) => state.dialog = show,
        setFindAgents: (state, payload) => state.find_agents = payload.find_agents,
        setDialogDelete: (state, payload) => state.dialogDelete = payload.show,
        showFilter: (state, payload) => state.dialogFilter = payload.show,
        showLoading: (state, show) => state.loading = show,
        showNotification: (state, payload) => {
            state.showNotification = payload.show,
                state.notificationMessage = payload.message,
                state.notificationType = payload.type
        },
        updateField
    },
    getters: {
        getField,
    },
    actions: {
        closeDialog: (({ commit }) => {
            commit('closeDialog', false)
        }),
        closeDeleteDialog: ({ commit }) => {
            commit('setDialogDelete', { show: false })
            commit('setItemToDelete', {})
        },
        deleteItem: ({ dispatch, commit, state }) => {
            commit('loading', true)
            let url = endPoints.documents + '/';
            url += state.itemToDelete.id
            axios.delete(url).then(() => {
                let notification = {
                    show: true,
                    message: "Documento excluido com sucesso",
                    type: "success"
                }
                commit('showNotification', notification)
                commit('setDialogDelete', { show: false, item: {} })
                commit('loading', { loading: false })
                dispatch('getItems')
            });
        },
        showDeleteDialog: ({ commit }, item) => {
            commit('setDialogDelete', { show: true })
            commit('setItemToDelete', item)
        },
        getItems: ({ commit, rootGetters }) => {
            commit('loading', { loading: true })
            let url = endPoints.documents
            url += `/${rootGetters["agent/getIdAgent"]}`
            axios.get(url).then(response => {
                let data = response.data != null ? response.data : []

                commit('setTotalItems', { total: data.length })
                commit('loading', { loading: false })
                commit('setItems', { items: data })
            });
        },
        showDocument: ({ commit }, payload) => {

            let file = payload.item.document_file
            let mimetype = file.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
            if (mimetype.indexOf("pdf") != -1 || payload.download == true) {
                var a = document.createElement("a"); //Create <a>
                a.href = file; //Image Base64 Goes here
                a.download = payload.item.file_name; //File name Here
                a.click();
            } else {
                commit('setDocumentToShow', { document: payload.item.document_file })
                commit('setDialogShowDocument', { show: true })
            }
            commit('overlay', { show: false })

        },
        save: ({ commit, dispatch, state, rootGetters }) => {
            var url = endPoints.documents;
            let func = '';
            if (state.mode == 'ADD') {
                func = axios.post;
                state.editedItem.id_agent = rootGetters["agent/getIdAgent"];
                state.editedItem.file_name = state.editedItem.document_file.name;
                url = endPoints.documents;
            } else {
                func = axios.put;
                url += endPoints.documents;
            }

            b64(state.editedItem.document_file).then((fileBase64) => {
                state.editedItem.document_file = fileBase64
                func(url, state.editedItem).then(
                    () => {
                        dispatch('getItems', { resetPage: true, agent_id: rootGetters["agent/getIdAgent"] })
                        let notification = {
                            show: true,
                            message: "Documento salvo com sucesso",
                            type: "success"
                        }
                        commit('showNotification', notification)
                        commit('closeDialog')
                    },
                    error => {
                        let notification = {
                            show: true,
                            message: error.response.data.message,
                            type: "error"
                        }
                        commit('showNotification', notification)
                    }
                );
            });

        }
    }
}