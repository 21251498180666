<template>
  <div>
    <div @drop.prevent="addDropFile" @dragover.prevent>
      <v-card>
        <v-card-title>
          <span class="headline text-center">Ocorrência</span>
          <v-spacer></v-spacer>
          <v-progress-circular
            v-if="loading"
            indeterminate
            class="text-right"
            color="primary"
            size="24"
          ></v-progress-circular>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>

                <v-flex center>
                  <v-text-field
                    required
                    :rules="[rules.required]"
                    label="Nova Ocorrência"
                    v-model="occurrence"
                    data-vv-name="occurrence"
                    outlined
                  ></v-text-field>
                </v-flex>

              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-col>
            <v-btn text @click="closeDialog"> Fechar </v-btn>
          </v-col>
          <v-col class="text-right" v-if="mode != 'VIEW'">
            <v-btn text type="submit" right @click="saveOccurrences">Salvar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  data: () => ({
    valid: true,
    rules: {
      required(occurrence) { 
        return !!occurrence || 'Campo Obrigatório'
      },
    }
  }),
  name: "OccurrencesForm",
  props: ["screen", "instances"],
  computed: {
    ...mapGetters("login", ["getInstanceId"]),
    ...mapState("occurrences", [
      "menus",
      "loading",
      "mode",
    ]),
    // ...mapState("instances", ["instances"]),
    ...mapFields("occurrences", ["editedItem.id", "editedItem.occurrence",]),
  },
  created() {
    this.setScreen({ screen: this.$props.screen })
    this.getInstances()
    this.$validator.extend("required", {
      getMessage: (field, [label]) => label + " deve ser informado",
      validate: (_, [, field]) => {
        if (this[field] || this[field] === 0) {
          return true;
        } else {
          return false;
        }
      },
    });
  },
  methods: {
    ...mapActions("occurrences", ["closeDialog", "save"]),
    ...mapActions("instances", ["getInstances"]),
    ...mapMutations("occurrences", ["setScreen"]),
    saveOccurrences() {
      let valid = this.$refs.form.validate()
      if (valid) {
        this.save();
      }
    },
  },
};
</script>