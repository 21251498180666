import axios from "axios";
import { getField, updateField } from "vuex-map-fields";
import endPoints from "../../../config/endPoints";

const defaultItem = {
  description: "",
  date: "",
  date_picker: "",
  type: "",
  id_company: null,
  bank: null,
  id_cost_center: null,
  cost_center_name: "",
  recurrence: null,
  variable: 0,
  observation: "",
  user_name: "",
  receipt: "",
  receipt_file: "",
  status: 0,
  value: 0,
  pix_key: "",
  id_financial_solicitation: null,
  name_destiny: "",
  inscription: "",
};

export default {
  namespaced: true,
  state: {
    banks: [],
    costCenters: [],
    options: [],
    companies: [],
    contacts: [],
    dialog: false,
    dialogDelete: false,
    dialogFilter: false,
    dialogReceipt: false,
    dialogAutomaticExpense: false,
    dialogImportSpreadsheet: false,
    expenseAutomatic: {},
    expense_types: [],
    editedItem: {},
    filter: {
      id_company: "",
      start: "",
      end: "",
      description: "",
      observation: "",
      value: "",
      bank: "",
      user_id: "",
      recurrence: "",
      variable: "",
      type: "",
      status: "",
      id_cost_center: "",
      name_destiny: "",
      inscription: "",
    },
    filterTypes: {
      has_child: ""
    },
    instances: [],
    items: [],
    itemToDelete: {},
    loading: false,
    mode: "LIST",
    modePay: "single",
    overlay: false,
    page: 1,
    pagination: {
      page: 1,
      itemsPerPage: 10,
    },
    rowsSelected: [],
    successNotification: false,
    notificationMessage: "",
    notificationType: "success",
    receipt_show: "",
    selectedContact: {},
    showNotification: false,
    totalItems: 0,
    rowsPerPage: 10,
    spreadsheet_import: {},
    users: [],
  },
  mutations: {
    addFinancialSolicitation: (state, payload) => {
      state.editedItem = Object.assign({}, defaultItem);
      state.editedItem.date_picker = new Date();
      state.editedItem.id_company = payload.item.id_company
      state.editedItem.description = payload.item.description
      state.editedItem.value = payload.item.value
      state.editedItem.pix_key = payload.item.pix_key
      state.editedItem.id_financial_solicitation = payload.item.id
      state.dialog = true;
      state.mode = "ADD"
    },
    add: (state) => {
      state.dialog = true;
      state.mode = "ADD";
      defaultItem.date_picker = new Date();
      state.editedItem = Object.assign({}, defaultItem);
    },
    setRowsSelected: (state) => {
      state.rowsSelected = []
    },
    edit: (state, payload) => {
      payload.item.date_picker = new Date(payload.item.date);
      state.editedItem = Object.assign({}, payload.item);
      state.editedIndex = state.instances.indexOf(payload.item);
      state.dialog = true;
      state.mode = "EDIT";
    },
    addExpense: (state, payload) => {
      state.dialog = true;
      state.mode = "ADD";
      payload.item.date_picker = new Date();
      payload.item.receipt_file = ""
      state.editedItem = Object.assign({}, payload.item);
    },
    closeDialog: (state) => {
      state.editedItem = {};
      state.editedIndex = -1;
      state.errors = [];
      state.dialog = false;
      state.mode = "LIST";
    },
    closeExpense: (state) => {
      state.editedItem = {};
      state.editedIndex = -1;
      state.errors = [];
      state.dialog = false;
      state.mode = "LIST";
    },
    loading: (state, payload) => (state.loading = payload.show),
    setMode: (state, mode) => (state.mode = mode),
    setItems: (state, items) => (state.items = items),
    setItemToDelete: (state, item) => (state.itemToDelete = item),
    setTotalItems: (state, total) => (state.totalItems = total),
    setRowsPerPage: (state, rowsPerPage) => (state.rowsPerPage = rowsPerPage),
    showDialog: (state, show) => (state.dialog = show),
    setPage: (state, payload) => {
      state.pagination.page = payload.page;
      state.pagination.itemsPerPage = payload.itemsPerPage;
    },
    setExpenseTypes: (state, payload) => {
      state.options = payload.expense_types
    },
    setBanks: (state, payload) => {
      state.banks = payload.banks;
    },
    setCostCenters: (state, payload) => {
      state.costCenters = payload.costCenters;
    },
    setCompanies: (state, payload) => {
      state.companies = payload.companies;
    },
    setContacts: (state, payload) => {
      state.contacts = payload.contacts;
    },
    setUsers: (state, payload) => {
      state.users = payload.users;
    },
    setDialogDelete: (state, show) => (state.dialogDelete = show),
    setDialogReceipt: (state, payload) => (state.dialogReceipt = payload.show),
    showDialogImportSpreadsheet: (state, payload) =>
      (state.dialogImportSpreadsheet = payload.show),
    showDialogAutomaticExpense: (state, payload) => {
      state.dialogAutomaticExpense = payload.show;
    },
    setInstances: (state, payload) => (state.instances = payload.instances),
    setMasterAgents: (state, payload) =>
      (state.master_agents = payload.master_agents),
    setModePay: (state, payload) => (state.modePay = payload.mode),
    showFilter: (state, payload) => (state.dialogFilter = payload.show),
    showErrorNotification: (state, show) => (state.errorNotification = show),
    showSuccessNotification: (state, show) =>
      (state.successNotification = show),
    showLoading: (state, show) => (state.loading = show),
    showNotification: (state, payload) => {
      (state.showNotification = payload.show),
        (state.notificationMessage = payload.message),
        (state.notificationType = payload.type);
    },
    showOverlay: (state, payload) => {
      state.overlay = payload.show;
    },
    setExpenseAutomatic: (state, payload) => {
      state.expenseAutomatic = payload.expense;
    },
    setSpreadsheetImport: (state, payload) => {
      state.spreadsheet_import = payload.spreadsheet;
    },
    setReceipt: (state, payload) => (state.receipt_show = payload.receipt),

    viewItem(state, item) {
      console.log(state.costCenters)
      console.log(item)
      state.editedItem = item;
      state.editedItem.date_picker = new Date(item.date);
      state.editedIndex = state.items.indexOf(item);
      state.dialog = true;
      state.mode = "VIEW";

    },
    updateField,
  },
  getters: {
    getField,
  },
  actions: {
    closeDialog: ({ commit }) => {
      commit("closeDialog", false);
    },
    closeExpense: ({ commit }) => {
      commit("closeDialog", false);
    },
    closeDeleteDialog: ({ commit }) => {
      commit("setDialogDelete", false);
      commit("setItemToDelete", {});
    },
    checkFinishExpense: ({ commit }, payload) => {
      commit('setModePay', { mode: payload.mode })
      commit("setExpenseAutomatic", {
        expense: Object.assign({}, payload.expense),
      });
      commit("showDialogAutomaticExpense", { show: true });
    },
    duplicateExpenses: ({ commit, state, dispatch }) => {
      console.log(state.rowsSelected)
      let url = endPoints.expenses + "/duplicate_expenses";

      axios.post(url, state.rowsSelected).then((response) => {
        console.log(response)

        let notification = {
          show: true,
          message: "Despesas duplicadas com sucesso",
          type: "success"
        };
        commit("showNotification", notification);
        commit("setRowsSelected");
        dispatch("getItems", { resetPage: true });

      })
    },
    deleteItem: ({ dispatch, commit, state }) => {
      commit("loading", { show: true });
      axios
        .delete(
          endPoints.expenses + "/" + state.itemToDelete.id,
          state.itemToDelete
        )
        .then(() => {
          dispatch("closeDeleteDialog");
          commit("loading", { show: false });
          let notification = {
            show: true,
            message: "Despesa excluida com sucesso",
            type: "success",
          };
          commit("showNotification", notification);
          return dispatch("getItems", { resetPage: false });
        });
    },
    scheduleExpense: ({ dispatch, state }, payload) => {
      state.editedItem = Object.assign({}, payload.expense);
      state.editedIndex = state.items.indexOf(payload.expense);
      state.editedItem.status = 1;
      state.mode = "EDIT";
      dispatch("save", { close: false });
    },
    finishExpense: ({ commit, dispatch, state }, payload) => {
      if (state.dialogAutomaticExpense == true) {
        commit("showDialogAutomaticExpense", { show: false });
      }
      state.editedItem = Object.assign({}, payload.expense);
      state.editedIndex = state.items.indexOf(payload.expense);
      state.editedItem.status = 2;
      state.mode = "EDIT";
      dispatch("save", { close: false });
    },
    finishAutomaticExpense: ({ commit, dispatch }, payload) => {
      commit("loading", { show: true });
      if (payload.expense.bank) {
        axios
          .put(endPoints.pix + "/expense", payload.expense)
          .then(() => {
            commit("showDialogAutomaticExpense", { show: false });
            let notification = {
              show: true,
              message: "Pagamento da despesa em processamento!",
              type: "success",
            };
            commit("setExpenseAutomatic", { expense: {} });
            commit("loading", { show: false });
            commit("showNotification", notification);
            dispatch("getItems", { resetPage: false });
          })
          .catch((error) => {
            let notification = {
              show: true,
              message: error?.response?.data?.message ||
                "Ocorreu um erro ao realizar o envio do Pix. Realize o envio manual!",
              type: "error",
            };
            commit("loading", { show: false });
            commit("showNotification", notification);
          });
      } else {
        let notification = {
          show: true,
          message: "Banco para pagamento deve ser informado",
          type: "error",
        };
        commit("showNotification", notification);
      }
    },
    getCompanies: ({ commit }) => {
      let url = endPoints.companies;
      url += "?page=" + 1 + "&" + "itemsPerPage=" + 999;
      axios.get(url).then((items) => {
        var companies = items.data.data;
        commit("setCompanies", { companies: companies });
      });
    },
    getBanks: ({ commit }) => {
      let url = endPoints.banks;
      url += "?page=" + 1 + "&" + "itemsPerPage=" + 999;
      axios.get(url).then((items) => {
        var banks = items.data.data;
        commit("setBanks", { banks: banks });
      });
    },
    getCostCenters: ({ commit }) => {
      let url = endPoints.cost_center;
      url += "?page=" + 1 + "&" + "itemsPerPage=" + 999;
      axios.get(url).then((items) => {
        var costCenters = items.data.data;
        commit("setCostCenters", { costCenters: costCenters });
      });
    },
    getExpenseTypes: ({ commit, state }) => {
      let url = endPoints.expense_types;
      url += "?page=" + 1 + "&" + "itemsPerPage=" + 999;

      let filter = state.filterTypes;
      if (filter.has_child != '') {
        url += "&has_child=0"
      }

      axios.get(url).then((items) => {
        var types = items.data.data;
        commit("setExpenseTypes", { expense_types: types });
      });
    },
    getSearchExpenseTypes: ({ commit, state }, payload) => {
      let url = endPoints.expense_types;
      url += "?page=" + 1 + "&" + "itemsPerPage=" + 999;

      let filter = state.filterTypes;
      if (filter.has_child != '') {
        url += "&has_child=0" + '&expense=' + payload.search
      }

      axios.get(url).then((items) => {
        var types = items.data.data;
        commit("setExpenseTypes", { expense_types: types });
      });
    },
    getItems: ({ commit, state }, payload) => {
      commit("loading", { show: true });
      let url = endPoints.expenses;
      let filter = state.filter;
      if (payload.resetPage === true) {
        url += "?page=" + 1 + "&" + "itemsPerPage=" + 10;
        commit("setPage", { page: 1, itemsPerPage: 10 });
      } else {
        url +=
          "?page=" +
          state.pagination.page +
          "&" +
          "itemsPerPage=" +
          state.pagination.itemsPerPage;
      }

      Object.keys(filter).forEach(function (field) {
        if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
          if (field == "end") {
            var e = new Date(filter.end);
            e.setDate(e.getDate() + 1);
            url += "&" + field + "=" + e.toISOString().substr(0, 10);
          } else {
            url += "&" + field + "=" + filter[field];
          }
        }
      });

      axios.get(url).then((items) => {
        commit("setTotalItems", items.data.total);
        commit("loading", { show: false });
        commit("setItems", items.data.data);
        // console.log(items.data.data)
      });
    },
    getItemsNoPage: ({ state }) => {
      let url = endPoints.expenses;
      let filter = state.filter;
      url += "?page=" + "1" + "&" + "itemsPerPage=" + "99999";

      Object.keys(filter).forEach(function (field) {
        if (filter[field] !== "" && filter[field] !== undefined) {
          if (field == "end") {
            var e = new Date(filter.end);
            e.setDate(e.getDate() + 1);
            url += "&" + field + "=" + e.toISOString().substr(0, 10);
          } else {
            url += "&" + field + "=" + filter[field];
          }
        }
      });
      return axios.get(url).then((items) => {
        return items.data.data;
      });
    },
    getUsers: ({ commit }, payload) => {
      let url = endPoints.users;
      url += "/attendants/" + payload.search;

      axios.get(url).then((users) => {
        commit("setUsers", { users: users.data });
      });
    },
    showDeleteDialog: ({ commit }, item) => {
      commit("setDialogDelete", true);
      commit("setItemToDelete", item);
    },
    payExpenses: ({ commit, state }, payload) => {
      axios.post(endPoints.expenses + "/pay_multiple_expenses", { expenses: state.rowsSelected, mode: payload.mode }).then((response) => {
        let count = response.data.payed;
        let notification = {
          show: true,
          message: `${count} de ${state.rowsSelected.length} despesas pagas com sucesso.`,
          type: count == 0 ? "error" : (count == state.rowsSelected.length ? "success" : "warning"),
        };
        if (count == 0) {
          notification.message = "Não foi possivel efetuar o pagamento de nenhuma despesa."
        }
        commit("showNotification", notification);
        commit("showDialogAutomaticExpense", { show: false });
        commit("setRowsSelected");
      })
    },
    save: async ({ dispatch, commit, state }) => {
      var url = endPoints.expenses;
      let func = "";
      let data = state.editedItem;
      data.inscription = data.inscription.replace(/\D/g, "");

      commit("showOverlay", { show: true });
      if (state.mode == "ADD") {
        func = axios.post;
        url = endPoints.expenses;
        if (data.receipt_file != "") {
          var file = data.receipt_file;

          const toBase64 = (file) =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => resolve(reader.result);
              reader.onerror = (error) => reject(error);
            });

          let base64_file = await toBase64(file);
          data.receipt = base64_file;
        }
      } else {
        func = axios.put;
        url += "/" + data.id;
      }
      console.log(url)

      func(url, data).then(
        () => {
          dispatch("getItems", { resetPage: true });
          let notification = {
            show: true,
            message: "Despesa salva com sucesso",
            type: "success",
          };
          commit("showNotification", notification);
          commit("closeDialog");
          commit("showOverlay", { show: false });
          commit("financial_solicitations/showNotification", notification, { root: true });
          commit("financial_solicitations/setShowDialogExpense", false, { root: true });
          dispatch("financial_solicitations/getItems", { resetPage: false }, { root: true });
          console.log(data)
        },
        (error) => {
          let notification = {
            show: true,
            message: error.response.data.message,
            type: "error",
          };
          commit("showNotification", notification);
          commit("showOverlay", { show: false });
        }
      );
    },
    importSpreadsheet: ({ commit, dispatch, state }) => {
      let url = endPoints.expenses + "/import_spreadsheet";
      let formData = new FormData();
      formData.append("file", state.spreadsheet_import);
      commit("showOverlay", { show: true });
      axios
        .post(url, formData)
        .then((response) => {
          let notification = {
            show: true,
            message: `${response.data.total_import} despesas importadas com sucesso.`,
            type: "success",
          };
          commit("setSpreadsheetImport", { spreadsheet: null });
          commit("showNotification", notification);
          commit("showDialogImportSpreadsheet", { show: false });
          commit("loading", { show: false });
          commit("showOverlay", { show: false });
          dispatch("getItems", { resetPage: true });
        })
        .catch(() => {
          let notification = {
            show: true,
            message:
              "Ocorreu um erro ao importar a planilha. Contate o administrador do sistema.",
            type: "error",
          };
          commit("loading", { show: false });
          commit("showOverlay", { show: false });
          commit("setSpreadsheetImport", { spreadsheet: null });
          commit("showNotification", notification);
        });
    },
    showReceipt: ({ commit }, payload) => {
      let url = endPoints.receipt;

      url += "/" + payload.item.id + "?type=1";
      commit("showOverlay", { show: true });
      axios
        .get(url)
        .then((r) => {
          let file = r.data.file;
          let mimetype = file.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
          if (mimetype.indexOf("pdf") != -1) {
            let name = "Comprovante-" + payload.item.id + ".pdf";
            var a = document.createElement("a"); //Create <a>
            a.href = file; //Image Base64 Goes here
            a.download = name; //File name Here
            a.click();
          } else {
            commit("setReceipt", { receipt: r.data.file });
            commit("setDialogReceipt", { show: true });
          }
          commit("showOverlay", { show: false });
        })
        .catch(() => {
          let notification = {
            show: true,
            message: "Comprovante não encontrado",
            type: "error",
          };
          commit("showNotification", notification);
          commit("showOverlay", { show: false });
        });
    },
  },
};
