import axios from 'axios';
import { updateField } from 'vuex-map-fields';
import endPoints from '../../../config/endPoints';
import { defaultErrorNotification, defaultSuccessNotification } from "../../../constants"



export default {
    namespaced: true,
    state: {
        clientId: null,
        selectedItem:{},
        loading: false,
        // deleteDialog: false,
        // concludeDialog: false,
        showDialog: false,
        overlay: false,
        screen: "",
        notification: {
            show: false,
            message: "",
            type: "",
        },
    },
    mutations: {
        link: (state, payload) => {
            state.selectedItem = Object.assign({}, payload);
            state.showDialog = true;
        },
        setClientId: (state, payload) => (state.clientId = payload),
        setLoading: (state, payload) => (state.loading = payload),
        showNotification: (state, payload) => (state.notification = payload),
        setShowDialog: (state, payload) => {
            state.showDialog = payload;
        },

        updateField
    },


    actions: {
        link: async ({commit}, payload) => {
            commit('setLoading', true )

            let func = axios.put
            let url = endPoints.agents + "/agent/link/"+ payload.agent.id;
                
            try{
                if(payload.delete == true){
                    payload.agent.client_id = null
                } else {
                    payload.agent.client_id = payload.client.id
                }
                let response = await func(url, payload.agent);

                payload.delete == true ? commit("setDeleteDialog", false) : commit("setShowDialog", false)

                commit("showNotification", defaultSuccessNotification("Vinculação realizada com sucesso"));
                commit("setLoading", false);
                commit("setClientId", response.data.client_id)
            }
            catch (error) {
                commit("showNotification", defaultErrorNotification(error?.response?.data?.message));commit("setLoading", false);
            }
        },
        
    }
}