<template>
    <div>
        <v-card>
            <v-card-title>
                <span class="headline text-center justify-center">Filtro</span>
            </v-card-title>

            <v-card-text>
                <v-form ref="form" lazy-validation>
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12 sm6 md6>
                                <v-datetime-picker
                                    v-model="start"
                                    date-format="dd/MM/yyyy"
                                    clearText="Limpar"
                                    okText="Confirmar"
                                    :time-picker-props="timeProps"
                                    label="De *"
                                    :textFieldProps="{ outlined: true }"
                                >
                                    <template slot="dateIcon">
                                        <v-icon>mdi-calendar</v-icon>
                                    </template>
                                    <template slot="timeIcon">
                                        <v-icon>mdi-clock</v-icon>
                                    </template>
                                </v-datetime-picker>
                            </v-flex>

                            <v-flex xs12 sm6 md6>
                                <v-datetime-picker
                                    v-model="end"
                                    date-format="dd/MM/yyyy"
                                    clearText="Limpar"
                                    okText="Confirmar"
                                    label="Até *"
                                    :time-picker-props="timeProps"
                                    :textFieldProps="{ outlined: true }"
                                >
                                    <template slot="dateIcon">
                                        <v-icon>mdi-calendar</v-icon>
                                    </template>
                                    <template slot="timeIcon">
                                        <v-icon>mdi-clock</v-icon>
                                    </template>
                                </v-datetime-picker>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-autocomplete
                                    label="Cliente"
                                    :clearable="true"
                                    outlined
                                    :items="clients"
                                    v-model="id_client"
                                    item-text="name"
                                    item-value="id"
                                    clear-icon="mdi-close"
                                >
                                </v-autocomplete>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-text-field
                                    required
                                    label="Valor"
                                    prefix="R$"
                                    v-model="value"
                                    :clearable="
                                        this.mode == 'ADD' ||
                                        this.mode == 'EDIT'
                                    "
                                    :readonly="this.mode == 'VIEW'"
                                    :error-messages="errors.collect('value')"
                                    outlined
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-autocomplete
                                    label="Clube"
                                    :clearable="true"
                                    outlined
                                    :items="slots"
                                    v-model="id_slot"
                                    item-text="name"
                                    item-value="id"
                                    clear-icon="mdi-close"
                                >
                                </v-autocomplete>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-text-field
                                    required
                                    label="ID Aplicativo"
                                    v-model="id_app"
                                    :clearable="
                                        this.mode == 'ADD' ||
                                        this.mode == 'EDIT'
                                    "
                                    :error-messages="errors.collect('id_app')"
                                    outlined
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-select
                                    label="Tipo"
                                    :items="possible_types"
                                    v-model="type"
                                    :error-messages="errors.collect('type')"
                                    item-text="description"
                                    item-value="id"
                                    outlined
                                ></v-select>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-select
                                    label="Tipo de Pagamento"
                                    :items="possible_payment_types"
                                    v-model="payment_type"
                                    item-text="description"
                                    item-value="id"
                                    outlined
                                ></v-select>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-col>
                    <v-btn
                        text
                        @click="
                            setShowFilter(false)
                            clearFilter()
                        "
                        >Cancelar</v-btn
                    >
                </v-col>
                <v-col>
                    <v-btn
                        text
                        
                        @click="
                            clearFilter()
                        "
                        >Limpar</v-btn
                    >
                </v-col>
                <v-col class="text-right">
                    <v-btn text type="submit" right @click="filter"
                        >Filtrar</v-btn
                    >
                </v-col>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
    name: 'AnnotatedsFilter',
    created() {
        this.getClients({ resetPage: false })
    },
    computed: {
        ...mapState('annotateds', [
            'mode',
            'selectedItem',
            'showDialog',
            'slots',
            'possible_types',
            'possible_payment_types',
        ]),
        ...mapState('clients', { clients: 'items' }),
        ...mapFields('annotateds', [
            'filter.id',
            'filter.id_client',
            'filter.id_app',
            'filter.id_slot',
            'filter.type',
            'filter.payment_type',
            'filter.value',
            'filter.date_created',
            'filter.start',
            'filter.end',
        ]),
    },
    methods: {
        clearStart() {
            this.start = ''
            this.start_formatted = ''
        },
        clearEnd() {
            this.end = ''
            this.end_formatted = ''
        },
        formatDate(date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`
        },
        setStart(date) {
            this.start = date
            this.showStart = false
            this.start_formatted = this.formatDate(date)
        },
        setEnd(date) {
            this.end = date
            this.showEnd = false
            this.end_formatted = this.formatDate(date)
        },
        ...mapMutations('annotateds', ['setShowFilter', 'clearFilter']),
        ...mapActions('annotateds', ['getItems']),
        ...mapActions('clients', { getClients: 'getItems' }),
        filter() {
            this.getItems()
            this.setShowFilter(false)
        },
    },
    data: () => ({
        showStart: false,
        showEnd: false,
        start_formatted: '',
        end_formatted: '',
        timeProps: {
            format: '24hr',
        },
    }),
}
</script>
