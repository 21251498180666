<template>
  <div>
    <div @drop.prevent="addDropFile" @dragover.prevent>

      <v-card>
        <v-card-title>
          <span class="headline">Bônus 5 reais</span>
          <v-spacer></v-spacer>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm4 md4>
                  <v-text-field
                    required
                    :label="getPlayerLabel('ID Suprema')"
                    v-model="id_pppoker"
                    :rules="[rules.required, rules.max_length(id_pppoker, 10), rules.onlyNumbers]"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md4>
                  <v-text-field
                    required
                    :label="getPlayerLabel('Nick')"
                    v-model="nick"
                    :error-messages="errors.collect('nick')"
                    :rules="[rules.required]"
                    data-vv-name="nick"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md4>
                  <v-text-field
                    required
                    label="CPF/CNPJ"
                    v-model="pix_identifier"
                    :rules="[rules.max_length(pix_identifier, 30), rules.cpf_cnpj]"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md4>
                  <v-text-field
                    required
                    label="Identificador"
                    v-model="endToEndId"
                    :readonly="true"
                    :error-messages="errors.collect('endToEndId')"
                    :rules="[rules.max_length(endToEndId, 100)]"
                    data-vv-name="endToEndId"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm4 md4>
                  <v-text-field
                    :rules="[rules.required]"
                    required
                    label="Contato *"
                    v-model="contact_id"
                    data-vv-name="contact_id"
                    outlined
                  >
                  </v-text-field>
                </v-flex>

                <v-flex xs12 sm12 md12>
                  <v-textarea
                    auto-grow
                    outlined
                    label="Observação"
                    id="textareamessage"
                    hide-details="true"
                    rows="1"
                    :rules="[rules.max_length(observation, 254)]"
                    v-model="observation"
                    :value="observation"
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-col>
            <v-btn text @click="showDepositDialog({show: false})">
              Cancelar
            </v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn text type="submit" right @click="saveDeposit" :disabled="block_click">Salvar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import toBase64 from '@util/Base64'
import valida_cpf_cnpj from "@util/CpfCnpj"

export default {
  name: "DepositForm",
  computed: {
    ...mapGetters("login", ["getInstanceId"]),
    ...mapState("bonus", [
      "depositDialog",
    ]),
    ...mapFields("bonus", [
      "editedItem.id",
      "editedItem.code",
      "editedItem.bank",
      "editedItem.date",
      "editedItem.created_user_name",
      "editedItem.finish_user_name",
      "editedItem.date_created",
      "editedItem.finish_at",
      "editedItem.date_picker",
      "editedItem.value",
      // "editedItem.club",
      "editedItem.id_pppoker",
      "editedItem.nick",
      "editedItem.status",
      "editedItem.payment_type",
      "editedItem.group_message",
      "editedItem.receipt",
      "editedItem.receipt_file",
      "editedItem.instance_id",
      "editedItem.type",
      "editedItem.name",
      "editedItem.observation",
      "editedItem.id_instance",
      "editedItem.sent_id_pppoker",
      "editedItem.sent_nick",
      "editedItem.sent_id_instance",
      "editedItem.pix_identifier",
      "editedItem.validation_state",
      "editedItem.pix_id",
      "editedItem.endToEndId",
      "editedItem.bonus",
      "editedItem.bonus_id",
      "editedItem.contact_id",
      "message_deposit",
      // ...
    ]),
    fileBase64: function() {
      if(this.fileString) {
        return this.fileString
      }
      return ''
    }
  },
  created() {
    this.$validator.extend("required", {
      getMessage: (field, [label]) => label + " deve ser informado",
      validate: (_, [, field]) => {
        if (this[field] || this[field] === 0) {
          return true;
        } else {
          return false;
        }
      },
    });
    this.$validator.extend("max_length", {
      getMessage: (_, [length]) => "No máximo " + length + " caracteres.",
      validate: (val, [length]) => {
        if (val.length > length) {
          return false;
        } else {
          return true;
        }
      },
    });
  },
  methods: {
    ...mapActions("bonus", ["saveDepositSt"]),
    ...mapMutations("bonus", ["showDepositDialog"]),
    getAlertMessage() {
      if (typeof this.bank == "object") {
        return this.bank.observation;
      }
      return "";
    },
    getAccountType(type) {
      switch (type) {
        case 0:
          return "Corrente";
        case 1:
          return "Poupança";
      }
    },
    getDate(date) {
      if (date) {
        var d = new Date(date);
        return d.toLocaleDateString() + " " + d.toLocaleTimeString();
      }
      return "";
    },
    getPlayerLabel(label) {
      let newLabel = label
      if (this.type === 4)
        newLabel += ' (Enviada)'
      
      newLabel += ' *'
      return newLabel
    },
    getTextBank(item) {
      if(item.account) {
        return item.name + ' | ' + item.account
      } else {
        return item.name
      }
    },
    setFileBase64() {
      if(this.receipt_file && this.receipt_file.name) {
        toBase64(this.receipt_file).then((fileBase64) => {
          this.fileString = fileBase64
        })
      }
      else {
        this.fileString = ''
      }
    },
    saveDeposit() {
      let valid = this.$refs.form.validate()
      
      if (valid) {
        this.block_click = true
        if (this.date_picker) {
          var d = new Date(this.date_picker);
          this.date =
            d.toISOString().substr(0, 10) + " " + d.toLocaleTimeString();
        }
        this.bonus = this.bonus == true ? 1 : 0
        this.value = parseFloat(this.value);
        this.saveDepositSt().then(() => { this.block_click = false }).catch(() => { this.block_click = false });
      }
    },
    typeSelectOnChange() {
      let idTransfer = this.banks.filter((bank) => bank.code === '1000')[0].id
      if(this.type == 4) {
        this.setBank({bank: idTransfer})
        this.setPaymentType({paymentType: 0})
      } else if (this.bank === idTransfer){
        this.setBank({bank: ''})
      }
    }
  },
  props: ["screen"],
  data: () => ({
    rules: {
      bonus(bonus, pix_identifier) {
        if(bonus) {
          if(!pix_identifier) {
            return "CPF/CNPJ deve ser informado"
          } else {
            return valida_cpf_cnpj(pix_identifier.replaceAll(".", "").replaceAll("-", "").replaceAll("/", "")) || 'CPF/CNPJ inválidos'
          }
        } else {
          return true
        }
      },
      cpf_cnpj(value) {
        if(!value) return true
        else {
          return valida_cpf_cnpj(value.replaceAll(".", "").replaceAll("-", "").replaceAll("/", "")) || 'CPF/CNPJ inválidos'
        }
      },
      onlyNumbers(value) {
        return !! parseInt(value) || "Valor deve ser númerico"
      },
      decimal(value) {
        let regex = /^[-]?\d*(\.\d+)?$/
        return regex.test(value) || 'Valor deve ser númerico'
      },
      required(value) { 
        return !!value || 'Campo Obrigatório'
      },
      requiredAcceptZero(value) { 
        return !!value || value === 0 || 'Campo Obrigatório'
      },
      max_length(value, max_length) {
        if(value && value.length > max_length) return 'Máximo de ' + max_length + ' caracteres.'
        return true
      },
    },
    block_click: false,
    payment_types: [
      { id: 0, description: "Transferência" },
      { id: 3, description: "Pix" },
      { id: 1, description: "Anotado" },
      { id: 2, description: "Troca de Ticket" },
    ],
    possible_status: [
      { id: 0, description: "Pendente" },
      { id: 1, description: "Em Andamento" },
      { id: 2, description: "Concluido" },
      { id: 3, description: "Estornado" },
    ],
    possible_types: [
      { id: 0, description: "Envio de Fichas Jogador"},
      { id: 1, description: "Envio de Fichas Agência"},
      { id: 2, description: "Abater Anotados"},
      { id: 3, description: "Adicionar ao Saldo Positivo"},
      { id: 4, description: "Transferência de Fichas"},
      { id: 5, description: "Bônus"},
      { id: 6, description: "Reembolso"}
    ],
    fileString: '',
    timeProps: {
      format: "24hr",
    },
    valid: false,
  }),
  watch: {
    receipt_file: {
      handler() {
        this.setFileBase64();
      },
    },
  },
};
</script>


<style>
</style>