import axios from "axios";
import { getField, updateField } from "vuex-map-fields";
import endPoints from "../../../config/endPoints";
import {
  defaultErrorNotification,
  defaultSuccessNotification,
} from "../../../constants";

const defaultItem = {
  id_company: null,
  cep: "",
  registration: null,
  role: null,
  department: null,
  ctps: "",
  id_collaborator: null,
  name_children: "",
  cpf_children: "",
  birth_date_children: "",
  serie: "",
  uf: "",
  pis: "",
  rg: "",
  birth_date: "",
  nationality: "",
  gender: null,
  civil_status: null,
  children: 0,
  CNH: "",
  category: "",
  voter_registration: "",
  zone: null,
  section: null,
  mother: "",
  father: "",
  formation: null,
  cpf: "",
  email: "",
  name: "",
  phone: "",
  voucher: null,
  number: "",
  complement: "",
  dispensation: "",
  registry: null,
  pcd: null,
  salary: null,
  commission: null,
  ticket: null,
  admission: "",
  experience: "",
  second_experience: "",
  corporate_email: "",
  expedient: "",
  state: "",
  district: "",
  city: "",
  street: "",

};

const defaultFilter = {
  id_company: "",
  cpf: "",
  email: "",
  id: "",
  name: "",
  phone: "",
  cep: "",
  role: "",
  department: "",
};

const defaultChildrensData = {
  id: null,
  id_collaborator: null,
  name_children: "",
  cpf_children: "",
  birth_date_children: "",
};

export default {
  namespaced: true,
  state: {
    apiAddress: {},
    addressForm: false,
    items: [],
    departments: [],
    companies: [],
    selectedItem: {},
    dialogAddress: false,
    mode: "",
    loading: false,
    totalItems: 0,
    totalChildrens: 0,
    deleteDialog: false,
    filterDialog: false,
    childrenDialog: false,
    roles: [],
    showDialog: false,
    filter: {
      id_company: "",
      cpf: "",
      email: "",
      id: "",
      name: "",
      phone: "",
      cep: "",
      role: "",
      department: "",
    },
    notification: {
      show: false,
      message: "",
      type: "",
    },
    pagination: {
      page: 1,
      itemsPerPage: 10,
    },
    childrensData: [
      {
        id_collaborator: null,
        name_children: "",
        cpf_children: "",
        birth_date_children: "",
      },
    ],
    possible_genders: [
      { id: 0, gender: "Não especificado" },
      { id: 1, gender: "Masculino" },
      { id: 2, gender: "Feminino" }
    ],
    possible_formations: [
      { id: 0, formation: "Ensino Médio Incompleto" },
      { id: 1, formation: "Ensino Médio Completo" },
      { id: 2, formation: "Ensino Superior Incompleto" },
      { id: 3, formation: "Ensino Superior Completo" },
      { id: 4, formation: "Pós Graduação" },
    ],
    possible_civil_status: [
      { id: 0, civil_status: "Solteiro" },
      { id: 1, civil_status: "Casado" },
      { id: 2, civil_status: "Divorciado" },
      { id: 3, civil_status: "Separado" },
      { id: 4, civil_status: "Viúvo" },
    ],
    possible_pcd: [
      { id: 0, description: "Não" },
      { id: 1, description: "Sim" },

    ],
    possible_childrens: [
      { id: 0, description: "Não" },
      { id: 1, description: "Sim" },
    ],
    possible_registry: [
      { id: 0, registry: "CLT" },
      { id: 1, registry: "Estágio" },
      { id: 2, registry: "PJ" },
    ],
  },
  mutations: {
    showAddress(state, payload) {
      state.addressForm = payload
    },
    addNewChildren(state) {
      let default_children = {
        id: null,
        id_collaborator: null,
        name_children: "",
        cpf_children: "",
        birth_date_children: "",
      };
      state.childrensData.push(default_children);
    },
    removeNewChildren(state) {
      state.childrensData.pop();
    },
    add: (state) => {
      state.selectedItem = Object.assign({}, defaultItem);
      state.childrensData = [Object.assign({}, defaultChildrensData)]
      state.showDialog = true;
      state.mode = "ADD";
    },
    deleteCollaborators: (state, payload) => {
      state.selectedItem = payload;
      state.deleteDialog = true;
    },
    view: (state, payload) => {
      state.selectedItem = payload;
      state.showDialog = true;
      state.mode = "VIEW";
    },
    clearFilter: (state) => {
      state.filter = Object.assign({}, defaultFilter);
    },
    setCompanies: (state, payload) => {
      state.companies = payload.companies;
    },
    setDepartments: (state, payload) => {
      state.departments = payload.departments;
    },
    setChildrenDialog: (state, payload) => {
      state.childrenDialog = payload.search;
    },
    setDateExperiences: (state, payload) => {
      const [y, m, d] = payload.experienceDate.split("-");
      state.selectedItem.experience = d + "/" + m + "/" + y
      const [year, month, day] = payload.secondExperienceDate.split("-");
      state.selectedItem.second_experience = day + "/" + month + "/" + year

    },
    setRoles: (state, payload) => {
      state.roles = payload.roles;
    },

    setApiAddress: (state, payload) => {
      state.apiAddress = payload.apiAddress;
      state.selectedItem.street = state.apiAddress.address;
      state.selectedItem.city = state.apiAddress.city;
      state.selectedItem.district = state.apiAddress.district;
      state.selectedItem.state = state.apiAddress.state;
      state.selectedItem.cep = state.apiAddress.code;

    },
    setItems: (state, payload) => (state.items = payload),
    setChildrens: (state, payload) => (state.childrensData = payload),
    setTotalItems: (state, payload) => (state.totalItems = payload),
    setTotalChildrens: (state, payload) => (state.totalChildrens = payload),
    setLoading: (state, payload) => (state.loading = payload),
    showNotification: (state, payload) => (state.notification = payload),
    setSelectedItem: (state, payload) => (state.selectedItem = payload),
    setDeleteDialog: (state, payload) => (state.deleteDialog = payload),
    setShowDialog: (state, payload) => {
      state.showDialog = payload;
      state.selectedItem = Object.assign({}, defaultItem);
      state.street = ""
    },
    setShowFilter: (state, payload) => (state.filterDialog = payload),
    updateField,
  },
  actions: {
    saveChildren: (payload) => {
      const pattern = new RegExp(/[^0-9]/g);
      var childrensToSave = payload.state.childrensData
      let url = endPoints.childrens;
      let childrensForSave = []
      let childrensForUpdate = []
      childrensToSave.forEach(children => {
        if (children.id == null) {

          let childrenForSave = {}

          childrenForSave.name_children = children.name_children
          childrenForSave.birth_date_children = children.birth_date_children
          childrenForSave.cpf_children = children.cpf_children
          childrenForSave.cpf_children = childrenForSave.cpf_children.replace(pattern, "");
          childrenForSave.id_collaborator = children.id_collaborator
          childrensForSave.push(childrenForSave)
        } else {
          childrensForUpdate.push(children)
        }
      })
      if (childrensForSave.length > 0) {
        axios.post(url, childrensForSave)
      }
      if (childrensForUpdate.length > 0) {
        axios.put(url, childrensForUpdate)
      }
    },


    edit: ({ state, dispatch }, payload) => {

      state.selectedItem = Object.assign({}, payload);
      state.showDialog = true;
      state.mode = "EDIT";
      dispatch("getChildrens", state.selectedItem.id);
    },
    getApiCep: async ({ commit }, payload) => {

      let url = endPoints.cep + "?code=" + payload;
      axios.get(url).then((apiAddress) => {

        if (apiAddress.data.ok != true) {
          commit(
            "showNotification",
            defaultErrorNotification("Erro ao localizar CEP. Por favor, digite manualmente")
          );
          console.log("erro");
        } else {
          commit("setApiAddress", { apiAddress: apiAddress.data });
        }
      });
    },
    getDepartments: ({ commit }) => {
      let url = endPoints.departments;
      url += "?page=" + 1 + "&" + "itemsPerPage=" + 999;

      axios.get(url).then((departments) => {
        commit("setDepartments", { departments: departments.data.data });

      });
    },
    getItems: async ({ commit, state }) => {

      let filter = state.filter;
      let url = `${endPoints.collaborators}?page=${state.pagination.page}&itemsPerPage=${state.pagination.itemsPerPage}`;
      Object.keys(filter).forEach(function (field) {
        if (
          filter[field] !== "" &&
          filter[field] !== undefined &&
          filter[field] !== null
        ) {
          url += "&" + field + "=" + filter[field];
        }
      });
      if (
        state.pagination &&
        state.pagination.sortBy &&
        state.pagination.sortBy.length > 0
      ) {
        url +=
          "&sort_by=" +
          (state.pagination.sortBy[0] === "name"
            ? "id"
            : state.pagination.sortBy[0]);
        url += "&sort_desc=" + state.pagination.sortDesc[0];
      }
      commit("setLoading", true);
      try {
        let items = await axios.get(url);
        commit("setItems", items.data.data);
        commit("setTotalItems", items.data.total);


      } catch (error) {
        commit(
          "showNotification",
          defaultErrorNotification(error?.response?.data?.message)
        );
      }
      commit("setLoading", false);
    },
    getChildrens: async ({ commit }, payload) => {
      // let filter = state.filter;
      let url = endPoints.childrens + "?id_collaborator=" + payload;

      commit("setLoading", true);
      try {
        let childrens = await axios.get(url);
        commit("setChildrens", childrens.data.data);
        commit("setTotalChildrens", childrens.data.total);

      } catch (error) {
        commit(
          "showNotification",
          defaultErrorNotification(error?.response?.data?.message)
        );
      }
      commit("setLoading", false);
    },
    deleteItem: async ({ commit, dispatch, state }) => {
      commit("setLoading", true);

      try {
        await axios.delete(
          `${endPoints.collaborators}/${state.selectedItem.id}`
        );

        commit("setLoading", false);
        commit("setDeleteDialog", false);
        commit("setSelectedItem", {});
        commit(
          "showNotification",
          defaultSuccessNotification("Colaborador removido com sucesso")
        );

        return dispatch("getItems");
      } catch (error) {
        commit(
          "showNotification",
          defaultErrorNotification(error?.response?.message)
        );
        commit("setLoading", false);
      }
    },
    getCompanies: ({ commit }) => {
      let url = endPoints.companies;
      url += "?page=" + 1 + "&" + "itemsPerPage=" + 999;
      axios.get(url).then((items) => {
        var companies = items.data.data;
        commit("setCompanies", { companies: companies });
      });
    },
    getRoles: ({ commit }) => {
      let url = endPoints.roles;
      url += "?page=" + 1 + "&" + "itemsPerPage=" + 999;
      axios.get(url).then((items) => {
        var roles = items.data.data;
        commit("setRoles", { roles: roles });

      });
    },
    save: async ({ commit, state, dispatch, rootState }, payload) => {
      const pattern = new RegExp(/[^0-9]/g);
      let url = endPoints.collaborators;
      let func = "";
      state.mode === "ADD"
        ? (func = axios.post)
        : ((func = axios.put), (url += `/${state.selectedItem.id}`));
      commit("setLoading", true);
      try {
        state.selectedItem.user_id = rootState.login.userData.id;
        state.selectedItem.voucher = parseFloat(state.selectedItem.voucher);
        state.selectedItem.ticket = parseFloat(state.selectedItem.ticket);
        state.selectedItem.salary = parseFloat(state.selectedItem.salary);
        state.selectedItem.commission = parseFloat(state.selectedItem.commission);
        state.selectedItem.children = parseInt(state.selectedItem.children);
        state.selectedItem.zone = parseInt(state.selectedItem.zone);
        state.selectedItem.section = parseInt(state.selectedItem.section);
        state.selectedItem.children = parseInt(state.selectedItem.children);
        state.selectedItem.registry = parseInt(state.selectedItem.registry);
        state.selectedItem.registration = parseInt(state.selectedItem.registration);

        //v-mask removal
        state.selectedItem.cpf = state.selectedItem.cpf.replace(pattern, "");
        state.selectedItem.phone = state.selectedItem.phone.replace(
          pattern,
          ""
        );
        if (state.selectedItem.complement == undefined) {
          state.selectedItem.complement = " "
        }
        state.selectedItem.address =
          state.apiAddress.address +
          ", " +
          state.selectedItem.number +
          " - " +
          state.selectedItem.complement +
          " - " +
          state.apiAddress.district +
          ", " +
          state.apiAddress.city +
          " - " +
          state.apiAddress.state +
          ", " +
          state.apiAddress.code;

        if (state.apiAddress.code != undefined && state.apiAddress.code != null) {
          state.selectedItem.cep = state.apiAddress.code

        }

        if (state.mode === "ADD") {
          await func(url, state.selectedItem).then((collaboratorRespost) => {
            payload.childrensData.forEach(collaboratorId => {
              collaboratorId.id_collaborator = collaboratorRespost.data.id
            })
            if (state.selectedItem.children == 1) {
              dispatch("saveChildren", payload.childrensData)
            }
          });
        } else {
          payload.childrensData.forEach(collaboratorId => {
            collaboratorId.id_collaborator = state.selectedItem.id
          })
          await func(url, state.selectedItem)
          if (state.selectedItem.children == 1) {
            dispatch("saveChildren", payload.childrensData)
          }
        }

        commit(
          "showNotification",
          defaultSuccessNotification("Colaborador salvo com sucesso")
        );
        commit("setShowDialog", false);
        commit("setLoading", false);
        dispatch("getItems");
      } catch (error) {
        commit(
          "showNotification",
          defaultErrorNotification(error?.response?.message)
        );
        commit("setLoading", false);
      }
    },
    viewItem({ dispatch }, payload) {
      dispatch("menu/openLink", "/collaborators/" + payload.item.id, {
        root: true,
      });
    },
  },
  getters: {
    getField,
  },
};
