<template>
  <div>
    <div>
      <v-card>
        <v-card-title>
          <span class="headline text-center">Jogador</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs9 sm9 md9>
                  <v-text-field
                    required
                    label="Nome"
                    v-model="name"
                    :readonly="mode != 'EDIT'"
                    :disabled="mode != 'EDIT'"
                    :error-messages="errors.collect('name')"
                    v-validate="'max_length:100'"
                    data-vv-name="name"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    label="CPF"
                    v-model="cpf"
                    :readonly="mode != 'EDIT'"
                    :disabled="mode != 'EDIT'"
                    v-validate="'max_length:14'"
                    data-vv-name="cpf"
                    v-mask="'###.###.###-##'"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    label="Data de Nascimento"
                    v-model="birth_date"
                    :readonly="mode != 'EDIT'"
                    :disabled="mode != 'EDIT'"
                    v-validate="'max_length:10'"
                    data-vv-name="birth_date"
                    v-mask="'##/##/####'"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm9 md9>
                  <v-text-field
                    required
                    label="Email"
                    v-model="email"
                    :readonly="mode != 'EDIT'"
                    :disabled="mode != 'EDIT'"
                    :error-messages="errors.collect('email')"
                    v-validate="'email|max_length:100'"
                    data-vv-name="email"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md4>
                  <v-text-field
                    required
                    label="Contato"
                    v-model="contact_id"
                    :readonly="true"
                    :disabled="true"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md4>
                  <v-select
                      label="Clube"
                      :items="instances"
                      v-model="club"
                      :readonly="mode != 'EDIT'"
                      :disabled="mode != 'EDIT'"
                      :error-messages="errors.collect('club')"
                      item-text="name"
                      item-value="id_instance"
                      data-vv-name="club"
                      outlined
                    >
                  </v-select>
                </v-flex>
                <v-flex xs12 sm4 md4>
                  <v-select
                    label="Como conheceu o ST⭐RS?"
                    :items="knowByTypes"
                    v-model="know_by"
                    :readonly="mode != 'EDIT'"
                    :disabled="mode != 'EDIT'"
                    :error-messages="errors.collect('know_by')"
                    item-text="description"
                    item-value="id"
                    data-vv-name="know_by"
                    outlined
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm12 md6 xl6>
                  <v-checkbox :readonly="mode != 'EDIT'" :disabled="mode != 'EDIT'" v-model="bonus" class="mx-2" label="Bônus"></v-checkbox>
                </v-flex>
                <v-flex xs12 sm12 md6 xl6>
                  <v-checkbox :readonly="mode != 'EDIT'" :disabled="mode != 'EDIT'" v-model="pppoker_accepted" class="mx-2" label="Aceito PPPoker"></v-checkbox>
                </v-flex>
                <v-tabs v-model="tab" class="elevation-10">
                    <v-tab>ID's</v-tab>
                    <v-tab>Contas</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab" class="tab-100">
                    <v-tab-item>      
                        <v-btn v-if="mode == 'EDIT'" color="black" dark class="add-align-right" @click="addPlayerId" small>
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                        <v-data-table
                            :headers="headersPlayerIds"
                            :loading="loading_player_chat"
                            :items="player_ids_chat"
                            :options.sync="pagination_player_ids_chat"
                            :server-items-length="totalPlayerIdsChat"
                            no-data-text="Nenhum id relacionado"
                        >
                          <template v-slot:[`item.id_pppoker`]="props">
                            <v-flex xs12 sm12 md12>
                              <v-text-field
                                required
                                reverse
                                v-if="mode == 'EDIT' && props.item.edited == 1"
                                v-model="props.item.id_pppoker"
                                :readonly="mode == 'VIEW'"
                              ></v-text-field>
                              <span v-else>{{ props.item.id_pppoker }}</span>
                            </v-flex>
                          </template>
                        </v-data-table>
                    </v-tab-item>
                    <v-tab-item>
                        <v-btn v-if="mode == 'EDIT'" color="black" dark class="add-align-right" @click="addPlayerAccount" small>
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                        <v-data-table
                            :headers="headersAccounts"
                            :loading="loading_player_chat"
                            :items="player_accounts_chat"
                            :server-items-length="totalAccountsChat"
                            :options.sync="pagination_player_accounts_chat"
                            no-data-text="Nenhuma conta encontrada"
                        >
                          <template v-slot:[`item.bank_name`]="props">
                            <v-flex xs12 sm12 md12>
                              <v-select
                                required
                                reverse
                                :items="banks"
                                :readonly="mode != 'EDIT'"
                                :disabled="mode != 'EDIT'"
                                item-value="id"
                                item-text="name"
                                v-if="mode == 'EDIT' && props.item.edited == 1"
                                v-model="props.item.bank"
                              ></v-select>
                              <span v-else>{{ props.item.bank_name }}</span>
                            </v-flex>
                          </template>
                          <template v-slot:[`item.agency`]="props">
                            <v-flex xs12 sm12 md12>
                              <v-text-field
                                required
                                reverse
                                v-if="mode == 'EDIT' && props.item.edited == 1"
                                v-model="props.item.agency"
                                :readonly="mode == 'VIEW'"
                              ></v-text-field>
                              <span v-else>{{ props.item.agency }}</span>
                            </v-flex>
                          </template>
                          <template v-slot:[`item.account`]="props">
                            <v-flex xs12 sm12 md12>
                              <v-text-field
                                required
                                reverse
                                v-if="mode == 'EDIT' && props.item.edited == 1"
                                v-model="props.item.account"
                                :readonly="mode == 'VIEW'"
                              ></v-text-field>
                              <span v-else>{{ props.item.account }}</span>
                            </v-flex>
                          </template>
                          <template v-slot:[`item.pix_key`]="props">
                            <v-flex xs12 sm12 md12>
                              <v-text-field
                                required
                                reverse
                                v-if="mode == 'EDIT' && props.item.edited == 1"
                                v-model="props.item.pix_key"
                                :readonly="mode == 'VIEW'"
                              ></v-text-field>
                              <span v-else>{{ props.item.pix_key }}</span>
                            </v-flex>
                          </template>
                          <template v-slot:[`item.type`]="props">
                            <v-flex xs12 sm12 md12>
                              <v-select
                                required
                                reverse
                                :items="possible_types"
                                :readonly="mode != 'EDIT'"
                                :disabled="mode != 'EDIT'"
                                item-value="id"
                                item-text="description"
                                v-if="mode == 'EDIT' && props.item.edited == 1"
                                v-model="props.item.type"
                              ></v-select>
                              <span v-else>{{ getType(props.item.type) }}</span>
                            </v-flex>
                          </template>
                          <template v-slot:[`item.actions`]="{ item }">
                              <v-icon small class="mr-2" @click="edit({item: item})">mdi-pencil</v-icon>
                              <v-icon small class="mr-2" @click="showDeleteDialog(item)">mdi-delete</v-icon>
                          </template>

                        </v-data-table>
                    </v-tab-item>
                </v-tabs-items>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-col>
            <v-btn text @click="closePlayerData({ show: false })" :disabled="loading_player_chat">{{ mode == 'VIEW' ? 'Voltar' : 'Cancelar'}}</v-btn>
          </v-col>
          <v-col class="text-center" v-if="mode == 'VIEW'">
            <v-btn v-if="mode == 'VIEW'" dark @click="editPlayerChat">Editar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn text type="submit" @click="savePlayerChat()" right :disabled="mode == 'VIEW'">Salvar</v-btn>
          </v-col>
        </v-card-actions>

      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "PlayerForm",
  computed: {
    ...mapState("bank", { banks: "items" }),
    ...mapState("instances", ["instances"]),
    ...mapState("players", [
      "knowByTypes",
    ]),
    ...mapState("player", [
      "loading_player_chat",
      "player_chat",
      "player_ids_chat",
      "player_accounts_chat",
      "totalPlayerIds",
      "totalPlayerIdsChat",
      "totalAccountsChat"
    ]),
    ...mapFields("player", {mode: "mode_chat"}),
    ...mapFields("player", [
      "player_chat.id",
      "player_chat.name",
      "player_chat.cpf",
      "player_chat.birth_date",
      "player_chat.email",
      "player_chat.player_id",
      "player_chat.club",
      "player_chat.know_by",
      "player_chat.contact_id",
      "player_chat.contact_name",
      "player_chat.pppoker_accepted",
      "player_chat.bonus",
      "pagination_player_ids_chat",
      "pagination_player_accounts_chat",

      // ...
    ]),
  },
  created() {
    this.$validator.extend("required", {
      getMessage: (field, [label]) => label + " deve ser informado",
      validate: (_, [, field]) => {
        if (this[field] || this[field] === 0) {
          return true;
        } else {
          return false;
        }
      },
    });

    this.$validator.extend("max_length", {
      getMessage: (_, [length]) => "No máximo " + length + " caracteres.",
      validate: (val, [length]) => {
        if (val.length > length) {
          return false;
        } else {
          return true;
        }
      },
    });
  },
  methods: {
    ...mapMutations("player", ["addPlayerId", "addPlayerAccount", "editPlayerChat"]),
    ...mapMutations("chat", ["showPlayerData"]),
    ...mapActions("players", [
      "validate",
    ]),
    ...mapActions("player", ["closePlayerData", "savePlayerChat"]),
    getType(type) {
        let types = ["Principal", "Outros"]
        return types[type]
    },
  },
  data: () => ({
    valid: false,
    searchInput: '',
    headersAccounts: [
        { text: "Banco", value: "bank_name", sortable: false, align: 'center' },
        { text: "Agência", value: "agency", sortable: false, align: 'center' },
        { text: "Conta", value: "account", sortable: false, align: 'center' },
        { text: "Chave Pix", value: "pix_key", sortable: false, align: 'center' },
        { text: "Tipo", value: "type", sortable: false, align: 'center' },
    ],
    headersPlayerIds: [
      { text: "Id", value: "id_pppoker", sortable: false, align: 'right' }
    ],
    possible_types: [
      {
        id: 0, 
        description: "Principal"
      },
      {
        id: 1, 
        description: "Outros"
      },
    ],
    tab: ''
  })
};
</script>

<style>
@import "./css/player.css";
</style>
