<template>
  <v-card @drop.prevent="addDropFile" @dragover.prevent>
    <v-card-title>
      <span class="headline text-center justify-center">Conta</span>

      <v-spacer></v-spacer>
      <v-progress-circular
        v-if="loading"
        indeterminate
        class="text-right"
        color="primary"
        size="24"
      ></v-progress-circular>
    </v-card-title>

    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12 sm6 md6>
              <v-select
                :clearable="true"
                clear-icon="mdi-close"
                label="Banco *"
                :items="banks"
                v-model="bank"
                :readonly="this.mode == 'VIEW'"
                :disabled="this.mode == 'VIEW'"
                :error-messages="errors.collect('bank')"
                v-validate="'required:Banco,bank'"
                item-value="id"
                item-text="name"
                data-vv-name="bank"
                outlined
              ></v-select>
            </v-flex>
            <v-flex xs12 sm6 md6>
              <v-text-field
                required
                label="Agência"
                v-model="agency"
                :disabled="country == '076'"
                :readonly="this.mode == 'VIEW'"
                :error-messages="errors.collect('agency')"
                data-vv-name="agency"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md6>
              <v-text-field
                required
                label="Conta"
                v-model="account"
                :disabled="country == '076'"
                :readonly="this.mode == 'VIEW'"
                :error-messages="errors.collect('account')"
                data-vv-name="account"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md6>
              <v-text-field
                required
                label="Chave Pix"
                v-model="pix_key"
                :readonly="this.mode == 'VIEW'"
                :error-messages="errors.collect('pix_key')"
                data-vv-name="pix_key"
                outlined
              ></v-text-field>
            </v-flex>
             <v-flex xs12 sm6 md6>
              <v-select
                  clear-icon
                  label="Tipo *"
                  :items="types"
                  v-model="type"
                  :readonly="this.mode == 'VIEW'"
                  :error-messages="errors.collect('type')"
                  :v-validate="'required:Tipo,type'"
                  item-text="description"
                  item-value="id"
                  data-vv-name="type"
                  outlined
              >
              </v-select>
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-col>
        <v-btn text @click="closeDialog">{{ mode == 'VIEW' ? 'Voltar' : 'Cancelar'}}</v-btn>
      </v-col>
      <v-col class="text-right">
        <v-btn text type="submit" right @click="save">Salvar</v-btn>
      </v-col>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
    name: "AccountsPlayerForm",
    created() {
      this.$validator.extend("required", {
        getMessage: (field, [label]) => label + " deve ser informado",
        validate: (_, [, field]) => {
          if (this[field] || this[field] === 0) {
            return true;
          } else {
            return false;
          }
        },
      });
    },
    computed: {
        ...mapState("bank", { banks: "items" }),
        ...mapState("accounts_player", ["loading", "mode"]),
        ...mapFields("accounts_player", [
          "editedItem.bank",
          "editedItem.agency",
          "editedItem.account",
          "editedItem.pix_key",
          "editedItem.type"
        ])
    },
    methods: {
      ...mapActions("accounts_player", ["closeDialog", "save"]),
      addDropFile(e) {
        if (this.mode == "ADD" || this.mode == "EDIT") {
          if (e.dataTransfer) {
            if (e.dataTransfer.files[0]) {
              let file = e.dataTransfer.files[0];
              this.document_file = file
            }
          }
        }
      }
    },
    data: () => ({
      types: [
        {
          id: 0, 
          description: "Principal"
        },
        {
          id: 1, 
          description: "Outros"
        },
      ],
      valid: false
    }),
}
</script>