<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    
    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2000"
      v-model="showNotification"
    >
        {{ notificationMessage }}
    </v-snackbar>
    <v-toolbar flat class="elevation-5">
      <div>
        <v-toolbar-title>Ocorrências</v-toolbar-title>
      </div>
      <v-spacer></v-spacer>
      <div class="button-toolbar">
        <v-btn color="black" dark class="ma-2" @click="add" small><v-icon>mdi-plus</v-icon></v-btn>
        <v-btn color="black" dark class="ma-2" @click="getOccurrences" small><v-icon>mdi-refresh</v-icon></v-btn>
      </div> 
    </v-toolbar>


    <v-data-table
      :headers="headers"
      :loading="loading"
      :items="occurrences"
      :options.sync="pagination"
      :server-items-length="totalOccurrences"
      no-data-text="Nenhuma ocorrência encontrada"
      class="elevation-10 table-margin"
    >
      <template v-slot:[`item.actions`]="{ item }">

        <v-tooltip bottom>
          <template v-slot:activator="{on, attrs}">
            <v-icon small v-on="on" v-bind="attrs" class="mr-2" @click="editedItem(item)">mdi-pencil</v-icon>
            <v-icon small class="mr-2" @click="showDeleteDialog(item)" >mdi-delete</v-icon>

          </template>
          <span>Editar</span>
        </v-tooltip>

      </template>
    </v-data-table>>

    <v-dialog :value="dialogDelete"  @click:outside="closeDeleteDialog" @keydown.enter="deleteItem" width="300">
      <v-card>
        <v-card-title class="headline">Confirmar a exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn text @click="closeDeleteDialog()">Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn text type="submit" right @click="deleteItem">Confirmar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog :value="dialog" width="800" @click:outside="closeDialog">
      <occurrences-form></occurrences-form>
    </v-dialog>

  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import { mapFields } from 'vuex-map-fields'
  import OccurrencesForm from './OccurrencesForm'

  export default {
    name: 'Occurrences',
    occurrences_code: 'Occurrences',
    computed: {
      ...mapState('login', ['userData']),
      ...mapState('occurrences', ['dialog','dialogDelete' , 'loading', 'notificationType', 'notificationMessage',
           'rowsPerPage', 'receipt_show', 'totalOccurrences', 'occurrences', 'dialogPrint']),
      ...mapFields('occurrences', [
          'pagination',
          'dialogFilter',
          'overlay',
          'showNotification'
          // ...
        ])
    },
    created() {
      this.getOccurrences({resetPage: false})
    },
    components: {
      "occurrences-form": OccurrencesForm,
    },
    methods: {
      ...mapMutations('occurrences', ['add', 'edit', 'editedItem']),
      ...mapActions('occurrences', ['closeDialog','deleteItem', 'getOccurrences','showDeleteDialog', 'closeDeleteDialog' ]),
    },
    data: () => ({
      headers: [
        { text: "ID",  value: "id", sortable: false, align: "center"},
        { text: "Ocorrências",  value: "occurrence", sortable: false, align: "center"},
        { text: "Ações",  value: "actions", sortable: false, align: "center"},
      ],
    }),
    watch: {
      pagination: {
        handler () {
          this.getOccurrences({resetPage: false})
        },
      },
    },
  }
</script>

<style>
</style>