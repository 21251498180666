<template>
    <div>
         <v-dialog :value="showDialog" persistent width="600">
      <v-card>
        <v-card-title>
          <span class="headline">Avaliações
          </span>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="ID"
                    v-model="id"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('id')"
                    v-validate="'required:Percentage,percentage'"
                    data-vv-name="Porcentagem"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Atendimento Iniciado"
                    v-model="created_at"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('id')"
                    v-validate="'required:Percentage,percentage'"
                    data-vv-name="Porcentagem"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Atendimento Finalizado"
                    v-model="finish_at"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('id')"
                    v-validate="'required:Percentage,percentage'"
                    data-vv-name="Porcentagem"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Atendente"
                    v-model="user_name"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('user_name')"
                    v-validate="'required:Percentage,percentage'"
                    data-vv-name="Porcentagem"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                 <v-select
                    label="Avaliação "
                    :items="possible_avaliations"
                    v-model="score"
                    readonly
                    item-text="avaliation"
                    item-value="score"
                    outlined
                  ></v-select>
                </v-flex>

                <v-flex xs12 sm6 md6>
                 <v-select
                    label="Status "
                    :items="possible_status"
                    v-model="status"
                    readonly
                    item-text="description"
                    item-value="status"
                    outlined
                  ></v-select>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="ID Pppoker"
                    v-model="id_pppoker"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('id_pppoker')"
                    v-validate="'required:Percentage,percentage'"
                    data-vv-name="Porcentagem"
                    outlined
                  ></v-text-field>
                </v-flex>

                 <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="ID Depósito"
                    v-model="deposit_id"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('percentage')"
                    v-validate="'required:Percentage,percentage'"
                    data-vv-name="Porcentagem"
                    outlined
                  ></v-text-field>
                </v-flex>
                
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Contato"
                    v-model="contact_id"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('contact_id')"
                    v-validate="'required:Name,name'"
                    data-vv-name="Nome"
                    outlined
                  ></v-text-field>
                </v-flex>

              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-col>
            <v-btn
              text
              @click="setShowDialog(false)"
              >{{ mode == "VIEW" ? "Voltar" : "Cancelar" }}</v-btn
            >
          </v-col>
        </v-card-actions>

      </v-card>
    </v-dialog>
    </div>
</template>

<script>

import { mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
    name: 'AvaliationsForm',
    methods: {
    ...mapMutations("avaliations", ["setShowDialog", "setSelectedItem", "setShowDialog"]),
  },
  computed: {
    ...mapState("avaliations", [
      "mode",
      "selectedItem",
      "showDialog",
      "possible_avaliations",
      "possible_status"
    ]),
    ...mapFields("avaliations", [
      "selectedItem.id",
      "selectedItem.finish_at",
      "selectedItem.created_at",
      "selectedItem.user_name",
      "selectedItem.score",
      "selectedItem.status",
      "selectedItem.id_pppoker",
      "selectedItem.contact_id",
      "selectedItem.deposit_id",
    ]),
  },
  data: () => ({
    valid: false,
  }),
}
</script>