<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline text-center justify-center">Filtro</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md>
            <v-layout wrap>

              <v-flex xs12 sm6 md12>
                <v-text-field
                  label="ID"
                  v-model="id"
                  :clearable="true"
                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-datetime-picker
                  v-model="start"
                  date-format="dd/MM/yyyy"
                  clearText="Limpar"
                  okText="Confirmar"
                  :time-picker-props="timeProps"
                  label="De *"
                  :textFieldProps="{outlined: true}"
                >
                  <template slot="dateIcon">
                    <v-icon>mdi-calendar</v-icon>
                  </template>
                  <template slot="timeIcon">
                    <v-icon>mdi-clock</v-icon>
                  </template>
                </v-datetime-picker>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-datetime-picker
                  v-model="end"
                  date-format="dd/MM/yyyy"
                  clearText="Limpar"
                  okText="Confirmar"
                  label="Até *"
                  :time-picker-props="timeProps"
                  :textFieldProps="{outlined: true}"
                >
                  <template slot="dateIcon">
                    <v-icon>mdi-calendar</v-icon>
                  </template>
                  <template slot="timeIcon">
                    <v-icon>mdi-clock</v-icon>
                  </template>
                </v-datetime-picker>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn
            text
            @click="
              setShowFilter(false);
              clearFilter();
            "
            >Cancelar</v-btn
          >
        </v-col>
        <v-col class="text-right">
          <v-btn text type="submit" right @click="filter">Filtrar</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "PeriodConciliationFilter",
  computed: {
    ...mapState("period_conciliation", [
      "mode",
      "selectedItem",
      "showDialog"
      ]),
    ...mapFields("period_conciliation", [
      "filter.id",
      "filter.start",
      "filter.end",
    ]),
  },   
  methods: {
    ...mapMutations("period_conciliation", [
      "showFilterDialog",
      "clearFilter"
    ]),
    ...mapActions("period_conciliation", [
      "getItems"
    ]),
    clearStart() {
      this.start = ''
      this.start_formatted = ''
    },
    clearEnd() {
      this.end = ''
      this.end_formatted = ''
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    },
    filter() {
      this.getItems({ resetPage: true });
      this.showFilterDialog({show: false});
    },
  },
  data: () => ({
    timeProps: {
      format: "24hr",
    },
  }),
};
</script>
