<template>
  <div>
    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2000"
      v-model="showNotification"
    >{{ notificationMessage }}</v-snackbar>
    <v-toolbar flat class="elevation-5">
      <div>
        <v-toolbar-title>Faturamento {{ weekly_revenue.id || weekly_revenue_id }} - {{ getDate(weekly_revenue.start_date) }} até {{ getDate(weekly_revenue.finish_date) }}</v-toolbar-title>
      </div>
      <v-spacer></v-spacer>
      <div class="button-toolbar">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="black" dark class="ma-2" small @click="returns">
              <v-icon>mdi-keyboard-return</v-icon>
            </v-btn>
          </template>
          <span>Voltar</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="black" dark class="ma-2" @click="showFilter({show: true})" small>
              <v-icon>mdi-filter</v-icon>
            </v-btn> 
          </template>
          <span>Filtrar</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="black" dark class="ma-2" @click="getItems" small>
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Recarregar</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="black" dark class="ma-2" @click="add" small>
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Adicionar</span>
        </v-tooltip>
      </div>
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :loading="loading"
      :items="items"
      :options.sync="pagination"
      :server-items-length="totalItems"    
      no-data-text="Nenhuma registro encontrado"
      class="elevation-10 table-margin"
    >
      <template v-slot:[`item.rake`]="{ item }">
        <span>{{ (item.rake) ? item.rake.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "R$ 0,00" }}</span>
      </template>
      <template v-slot:[`item.rakeback`]="{ item }">
        <span>{{ (item.rakeback) ? item.rakeback.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "R$ 0,00" }}</span>
      </template>
      <template v-slot:[`item.app_tax`]="{ item }">
        <span>{{ getAppTax(item.app_tax) }}</span>
      </template>
      <template v-slot:[`item.value`]="{ item }">
        <span>{{ getAppTax(item.value) }}</span>
      </template>
      <template v-slot:[`item.league_tax`]="{ item }">
        <span>{{ getAppTax(item.league_tax) }}</span>
      </template>
      <template v-slot:[`item.percentage`]="{ item }">
        <span>{{ getPercentage(item.percentage) }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" small class="mr-2" color="blue" @click="calcApp(item)">mdi-calculator</v-icon>
          </template>
          <span>Calcular Taxa APP</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" small class="mr-2" color="red" @click="showDeleteDialog(item)">mdi-delete</v-icon>
          </template>
          <span>Excluir</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog :value="dialog" @click:outside="closeDialog()">
      <slots-revenue-form></slots-revenue-form>
    </v-dialog>

    <v-dialog :value="dialogFilter" width="600" @click:outside="showFilter({show: false})">
      <slots-revenue-filter></slots-revenue-filter>
    </v-dialog>

    <v-dialog :value="dialogDelete"  @click:outside="closeDeleteDialog" @keydown.enter="deleteItem" width="300">
      <v-card>
        <v-card-title class="headline">Confirmar a exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn text @click="closeDeleteDialog()">Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn text type="submit" right @click="deleteItem">Confirmar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import SlotsRevenueFilter from "./SlotsRevenueFilter";
import SlotsRevenueForm from "./SlotsRevenueForm";

export default {
  name: "SlotsRevenue",
  computed: {
    ...mapState("login", ["userData"]),
    ...mapState("slots_revenue", [
      "dialog",
      "dialogDelete",
      "item",
      "instances",
      "filtered",
      "filter",
      "loading",
      "mode",
      "weekly_revenue",
      "weekly_revenue_id",
      "notificationType",
      "notificationMessage",
      "rowsPerPage",
      "totalItems",
      "items",
    ]),
    ...mapState('instances', ['instances']),
    ...mapFields("slots_revenue", [
      "pagination",
      "dialogFilter",
      "overlay",
      "showNotification",
      // ...
    ]),
  },
  created() {
    this.getInstances()
    this.setWeeklyRevenueId({weekly_revenue_id: this.$route.params.weekly_revenue_id})
  },
  components: {
    "slots-revenue-filter": SlotsRevenueFilter,
    "slots-revenue-form": SlotsRevenueForm,
  },
  methods: {
    ...mapMutations("slots_revenue", [
      "closeDialog",
      "setWeeklyRevenueId",
      "showFilter",
      "add",
    ]),
    ...mapActions('instances', ['getInstances']),
    ...mapActions("slots_revenue", [
      "return",
      "closeDeleteDialog",
      "deleteItem",
      "getItems",
      "showDeleteDialog",
      "calcApp"
    ]),
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      }
      return false;
    },
    returns() {
      this.return()
    },
    getAppTax(appTax) {
      if (appTax == null) {
        return "N/A"
      } else {
        return appTax.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      }
    },
    getPercentage(percentage) {
      return percentage + ' %'
    },
    getDate(date) {
      if (date) {
        var d = new Date(date + " 12:00:00");
        return d.toLocaleDateString();
      }
      return "";
    },
  },
  data: () => ({
    additional_text: "",
    build_fields: {},
    expanded: [],
    headers: [
      { text: "Slot", value: "slot_name", sortable: false },
      { text: "Rake", value: "rake", sortable: true },
      { text: "Rakeback", value: "rakeback", sortable: true },
      { text: "Taxa da Liga", value: "league_tax", sortable: true },
      { text: "Taxa do App", value: "app_tax", sortable: true },
      { text: "Faturamento", value: "value", sortable: true },
      { text: "Ações", value: "actions", sortable: false }
    ],
    valid: false,
  }),
  watch: {
    pagination: {
      handler() {
        this.getItems({ resetPage: false });
      },
    },
  },
};
</script>
