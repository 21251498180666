<template>
  <div>
    <v-dialog :value="showDialog" persistent width="600">
      <v-card>
        <v-card-title>
          <span class="headline">Base de dados: Banco Central do Brasil (BACEN)</span>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm2 md2>
                  <v-text-field
                    required
                    dense
                    label="ISPB"
                    v-model="ispb"
                    :readonly="this.mode == 'VIEW'"
                    data-vv-name="ispb"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    dense
                    label="Nome"
                    v-model="name"
                    :readonly="this.mode == 'VIEW'"
                    data-vv-name="name"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm4 md4>
                  <v-text-field
                    required
                    dense
                    label="Código"
                    v-model="code"
                    :readonly="this.mode == 'VIEW'"
                    data-vv-name="code"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    dense
                    label="Participante da COMPE"
                    v-model="compe"
                    :readonly="this.mode == 'VIEW'"
                    data-vv-name="compe"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    dense
                    label="Acesso Principal"
                    v-model="access"
                    :readonly="this.mode == 'VIEW'"
                    data-vv-name="access"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    dense
                    label="Nome Extenso"
                    v-model="full_name"
                    :readonly="this.mode == 'VIEW'"
                    data-vv-name="full_name"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    dense
                    label="Início da Operação"
                    v-model="date_op"
                    :readonly="this.mode == 'VIEW'"
                    data-vv-name="date_op"
                    outlined
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-col>
            <v-btn text @click="setShowDialog(false)">{{
              mode == "VIEW" ? "Voltar" : "Cancelar"
            }}</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn
              text
              type="submit"
              right
              v-if="mode == 'EDIT' || mode == 'ADD'"
              @click="save"
              >Salvar</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {/* mapActions, */mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "BanksBacenForm",
  computed: {
    ...mapState("banks_bacen", [
      "mode",
      "selectedItem",
      "showDialog"
      ]),
    ...mapFields("banks_bacen", [
      "selectedItem.id",
      "selectedItem.ispb",
      "selectedItem.name",
      "selectedItem.code",
      "selectedItem.compe",
      "selectedItem.access",
      "selectedItem.full_name",
      "selectedItem.date_op",
    ]),
  },
  methods: {
    ...mapMutations("banks_bacen", [
      "setShowDialog",
      "setSelectedItem",
      "setShowDialog",
    ]),
    // ...mapActions("banks_bacen", [
    //   "save",
    //   ]),
  },
  data: () => ({}),
};
</script>
