<template>
  <div>
    <div @drop.prevent="addDropFile" @dragover.prevent>
      <v-card>
        <v-card-title>
          <span class="headline text-center">Novo Perfil de Acesso</span>
          <v-spacer></v-spacer>
          <v-progress-circular
            v-if="loading"
            indeterminate
            class="text-right"
            color="primary"
            size="24"
          ></v-progress-circular>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Nome *"
                    v-model="name"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('name')"
                    v-validate="'required:Nome,name'"
                    data-vv-name="name"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    type="number"
                    required
                    label="Código *"
                    v-model="profile_code"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('profile_code')"
                    v-validate="'required:Nome,profile_code'"
                    data-vv-profile_code="profile_code"
                    outlined
                  ></v-text-field>
                </v-flex>

              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-col>
            <v-btn text @click="closeDialog">{{ mode == 'VIEW' ? 'Voltar' : 'Cancelar'}}</v-btn>
          </v-col>
          <v-col class="text-right" v-if="mode != 'VIEW'">
            <v-btn text type="submit" right @click="saveProfile">Salvar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import debounce from '../../util/debounce.js' 
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "ProfilesForm",
  props: ["screen", "instances"],
  computed: {
    ...mapGetters("login", ["getInstanceId"]),
    ...mapState("profiles", [
      "item",
      "loading",
      "mode",
    ]),
    ...mapState("instances", ["instances"]),
    ...mapFields("profiles", [
      "dialogReceipt",
      "editedItem.profile_code",
      "editedItem.name",
      // ...
    ]),
  },
  mixins: [debounce],
  mounted() {
    this.getAgents = this.debounce(this.getAgents, 500)
    this.getAttendants = this.debounce(this.getAttendants, 500); 
  },
  created() {
    this.setScreen({ screen: this.$props.screen })
    this.getInstances()
    this.$validator.extend("required", {
      getMessage: (field, [label]) => label + " deve ser informado",
      validate: (_, [, field]) => {
        if (this[field] || this[field] === 0) {
          return true;
        } else {
          return false;
        }
      },
    });
  },
  methods: {
    ...mapActions("profiles", [
      "getAttendants",
      "getUsers",
      "getAgents",
      "closeDialog",
      "save",
    ]),
    ...mapActions("instances", ["getInstances"]),
    ...mapMutations("profiles", ["setScreen"]),
    addDropFile(e) {
      if (this.mode == "ADD" || this.mode == "EDIT") {
        if (e.dataTransfer) {
          if (e.dataTransfer.files[0]) {
            this.receipt_file = e.dataTransfer.files[0];
          }
        }
      }
    },
    getTextAgent(item) {
      if(item) {
          if(item.id_pppoker) {
              return item.id_pppoker + " | " + item.nick + " | " + item.club_name
          } else {
              return item.nick
          }
      }
      return ''
    },
    saveProfile() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.save();
        }
      });
    },
  },
};
</script>