<template>
  <div>
    <v-snackbar
      :color="notification.type"
      right
      top
      :timeout="2000"
      v-model="notification.show"
    >
      {{ notification.message }}
    </v-snackbar>

    <v-toolbar flat class="elevation-5">
      <v-toolbar-title>Atualizações</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="black"
            dark
            class="ma-2"
            @click="refreshItems"
            small
            v-on="on"
            v-bind="attrs"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>
        <span> Recarregar </span>
      </v-tooltip>

      <template v-if="role_allowed">
        <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="black"
            dark
            class="ma-2"
            @click="add(item)"
            small
            v-on="on"
            v-bind="attrs"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span> Adicionar </span>
        </v-tooltip>
      </template>
      
    </v-toolbar>

    <v-dialog :value="showDialog" persistent width="600">
      <releases-form></releases-form>
    </v-dialog>

    <v-data-table
      :headers="headers"
      :loading="loading"
      :items="items"
      :options.sync="pagination"
      :server-items-length="totalItems"
      no-data-text="Nenhuma atualização encontrada"
      class="elevation-10 table-margin"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              @click="view(item)"
              v-on="on"
              v-bind="attrs"
              >mdi-information</v-icon
            >
          </template>
          <span> Informações </span>
        </v-tooltip>

        <template v-if="role_allowed">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                class="mr-2"
                @click="edit(item)"
                v-on="on"
                v-bind="attrs"
                >mdi-pencil</v-icon
              >
            </template>
            <span> Editar </span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                class="mr-2"
                @click="deleteRelease(item)"
                v-on="on"
                v-bind="attrs"
                >mdi-delete</v-icon
              >
            </template>
            <span> Excluir </span>
          </v-tooltip>
        </template>

      </template>

      <template v-slot:[`item.date`]="{ item }">
        <span>{{ new Date(item.date).toLocaleString() }}</span>
      </template>
    </v-data-table>

    <v-dialog :value="deleteDialog" persistent width="300">
      <v-card>
        <v-card-title class="headline">Confirmar a exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn text @click="setDeleteDialog(false)">Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn text type="submit" right @click="deleteItem"
              >Confirmar</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { mapFields } from 'vuex-map-fields'
import ReleasesForm from "./ReleasesForm";

export default {
  name: "Releases",

  created() {
    this.getItems()
    this.getRequestsByTitle()
  },

  components: {
    ReleasesForm,
  },

  computed: {
    ...mapState("login", ["userData"]),
    ...mapState("releases", [
      "showDialog",
      "deleteDialog",
      "items",
      "loading",
      "mode",
      "selectedItem",
      "notification",
      "totalItems"
    ]),
    ...mapFields('releases', [
      'pagination'
    ]),
    role_allowed() {
      let roles_allowed = [99, 98, 97, 96, 95, 94]
      return roles_allowed.includes(this.userData.role)
    }
  },

  methods: {
    ...mapMutations("releases", [
      "setShowDialog",
      "setDeleteDialog",
      "setLoading",
      "deleteRelease",
      "view",
      "edit",
      "add",
    ]),
    ...mapActions("releases", ["getItems", "deleteItem", "refreshItems", "getRequestsByTitle"]),
  },

  data: () => ({
    headers: [
      { text: "ID", value: "id", sortable: true },
      { text: "Título", value: "title", sortable: false, width: "40%" },
      { text: "Data", value: "date", sortable: true },
      { text: "Módulo", value: "module", sortable: true },
      { text: "Versão", value: "version", sortable: false },
      { text: "Ações", value: "actions", sortable: false, align: "center" },
    ],
  }),
  watch: {
      pagination: {
        handler () {
          this.getItems()
        },
      },
    },
};
</script>