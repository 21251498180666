import axios from "axios"
import { getField, updateField } from "vuex-map-fields"
import endPoints from "../../../../config/endPoints"
import { defaultErrorNotification, defaultSuccessNotification } from "../../../../constants"

const formatDate = (d) => {
    var day, month, year
    month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear()

    if (month.length < 2)
        month = '0' + month
    if (day.length < 2)
        day = '0' + day

    return [year, month, day].join('-')
}

const defaultFilter = {
    id: '',
    email: '',
    created_at:'',
}
  
const defaultClientItem = {
    id: null,
    created_at: '',
    email: '',
    password: '',
    phone_number: '',
}

export default {
    namespaced: true,
    state: {
        client_id: null,
        dialogDelete: false,
        dialogForm: false,
        dialogMail: false,
        itemsClientsRegistrations: [],
        loading: false,
        mode: '',
        notification: {
            show: false,
            message: '',
            type: '',
        },
        pagination: {
            page: 1,
            itemsPerPage: 10,
        },
        rowsPerPage: null, 
        selectedItem: {},
        showDialog: false,
        totalItems: null,
    },
    mutations: {
        add: (state) => {
            state.selectedItem = Object.assign({}, defaultClientItem)
            state.showDialog = true
            state.mode = "ADD"
        },
        view: (state, payload) => {
            state.selectedItem = payload
            state.mode = "VIEW"
        },
        clearFilter: (state) => {
            state.filter = Object.assign({}, defaultFilter)
        },
        setClientId: (state, payload) => {
            state.client_id = payload.client_id
        },
        setDeleteDialog: (state, payload) => {
            state.selectedItem = Object.assign({}, payload.item)
            state.dialogDelete = payload.show
            state.mode = payload.mode
        },
        setFormDialog: (state, payload) => {
            state.selectedItem = Object.assign({}, payload.item)
            state.dialogForm = payload.show
            state.mode = payload.mode
        },
        setMailDialog: (state, payload) => {
            state.selectedItem = Object.assign({}, payload.item)
            state.dialogMail = payload.show
            state.mode = payload.mode
        },
        setItemsClientsRegistrations: (state, payload) => (state.itemsClientsRegistrations = payload),
        setDefaultItem: (state) => (state.selectedItem = Object.assign({}, defaultClientItem)),
        setLoading: (state, payload) => (state.loading = payload),
        setPage: (state, payload) => {
            state.pagination.page = payload.page
            state.pagination.itemsPerPage = payload.itemsPerPage
        },
        setSelectedItem: (state, payload) => state.selectedItem = payload,
        setTotalItems: (state, payload) => (state.totalItems = payload),
        showNotification: (state, payload) => (state.notification = payload),
        updateField,
    },
    actions: {
        mailFirstAccess: async ({ state, commit, dispatch }) => {

            let url = `${ endPoints.clients_registrations }` + '/mail_first_access/' + state.selectedItem.id
            commit("setLoading", true)
            try {
                await axios.put(url, state.selectedItem)
                commit("setLoading", false)
                commit("clients/showNotification", defaultSuccessNotification("E-mail enviado com sucesso!"), { root: true })
                commit('setDefaultItem')
                commit("setMailDialog", { item: {}, show: false, mode: '' })
                dispatch('getItems')
            } catch (error) {
                commit("setLoading", false)
                commit('setDefaultItem')
                commit("setMailDialog", { item: {}, show: false, mode: '' })
                commit("clients/showNotification", defaultErrorNotification(error?.response?.data?.message), { root: true })
            }
        },
        manageClientId: ({ commit, dispatch }, payload) => {
            commit('setClientId', payload)
            dispatch('getItems')
        },
        getItems: async ({ commit, state }) => {
            let url = `${ endPoints.clients_registrations }` + '?client_id=' + state.client_id
            let filter = {}
            
            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
                    if (field == "end_date" || field == "start_date") {
                        var e = new Date(filter[field])
                        url += "&" + field + "=" + formatDate(e) + " " + e.toLocaleTimeString()
                    } else {
                        url += "&" + field + "=" + filter[field]
                    }
                }
            })
            
            if (state.pagination && state.pagination.sortBy && state.pagination.sortBy.length > 0) {
                url += "&sort_by=" + state.pagination.sortBy[0]
                url += "&sort_desc=" + state.pagination.sortDesc[0]
            }

            commit("setLoading", true)
            try {
                await axios.get(url).then(items => {
                    commit("setItemsClientsRegistrations", items.data.data)
                    commit("setTotalItems", items.data.total)
                    commit("setLoading", false)
                    commit("setDefaultItem")
                })
            } catch (error) {
                commit("setDefaultItem")
                commit("setLoading", false)
                commit("clients/showNotification", defaultErrorNotification(error?.response?.data?.message), { root: true })
            }
        },
        deleteItem: async ({ commit, dispatch, state }) => {
            
            commit("setLoading", true)
            try {
                await axios.delete(`${endPoints.clients_registrations}/${state.selectedItem.id}`)
                
                commit("setLoading", false)
                commit("setDeleteDialog", { item: {}, show: false, mode: '' })
                commit("setDefaultItem")
                commit("clients/showNotification", defaultSuccessNotification("Cadastro deletado com sucesso"), { root: true })
                return dispatch('getItems')
            } catch (error) {
                commit("clients/showNotification", defaultErrorNotification(error?.response?.data?.message), { root: true })
                commit("setLoading", false)
            }
        },
        save: async ({ commit, state, dispatch }) => {

            let url = endPoints.clients_registrations
            let func = ""

            state.mode === "ADD"
            ? (func = axios.post)
            : ((func = axios.put), (url += `/${state.selectedItem.id}`))

            commit("setLoading", true)
            try {
                if (state.mode === 'ADD') {
                    state.selectedItem.client_id = parseInt(state.client_id)
                }
                
                await func(url, state.selectedItem)
                commit("setLoading", false)
                commit("clients/showNotification", defaultSuccessNotification("Cadastro do cliente salvo com sucesso!"), { root: true })
                commit('setDefaultItem')
                commit("setFormDialog", { item: {}, show: false, mode: '' })
                dispatch('getItems')
                
            } catch (error) {
                commit("setLoading", false)
                commit('setDefaultItem')
                commit("clients/showNotification", defaultErrorNotification(error?.response?.data?.message), { root: true })
                commit("setFormDialog", { item: {}, show: false, mode: '' })
            }
        },
    },
    getters: {
        getField,
    },
}