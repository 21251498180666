import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../../config/endPoints';

const defaultItem = {
    description: '',
    date: '',
    date_picker: new Date(),
    type: 0,
    status: 0,
    balance: 0,
    observation: '',
    value: 0,
    user_id: null,
    id_agent: '',
};

const defaultAudit = {
    date: '',
    value: 0,
};

export default {
    namespaced: true,
    state: {
        agents: [],
        contacts: [],
        dialog: false,
        dialogDelete: false,
        dialogDeleteAudit: false,
        dialogFilter: false,
        dialogReceipt: false,
        dialogAudits: false,
        dialogAuditForm: false,
        expense_types: [],
        editedItem: {},
        filter: {
            start: '',
            end: '',
            description: '',
            value: '',
        },
        instances: [],
        items: [],
        audits: [],
        itemToDelete: {},
        loading: false,
        mode: 'LIST',
        overlay: false,
        page: 1,
        pagination: {
            page: 1,
            itemsPerPage: 10
        },
        successNotification: false,
        notificationMessage: '',
        notificationType: "success",
        receipt_show: '',
        selectedContact: {},
        showNotification: false,
        totalItems: 0,
        totalAudits: 0,
        rowsPerPage: 10,
        users_name: [],
        users: []
    },
    mutations: {
        add: (state, payload) => {
            state.mode = "ADD"
            defaultItem.date_picker = new Date()
            state.editedItem = Object.assign({}, defaultItem)
            state.screen = payload.screen
            if (state.screen != "messages") {
                state.dialog = true
            }
        },
        addAudit: (state, payload) => {
            state.mode = "ADD"
            defaultAudit.date_picker = new Date()
            state.editedAudit = Object.assign({}, defaultAudit)
            state.screen = payload.screen
            if (state.screen != "messages") {
                state.dialogAuditForm = true
            }
        },
        Audits: (state, payload) => {
            state.mode = "ADD"
            defaultItem.date_picker = new Date()
            state.editedItem = Object.assign({}, defaultItem)
            state.screen = payload.screen
            if (state.screen != "messages") {
                state.dialogAudits = true
            }
        },
        edit: (state, payload) => {
            payload.item.date_picker = new Date(payload.item.date)
            state.editedItem = Object.assign({}, payload.item)
            state.editedIndex = state.instances.indexOf(payload.item)
            state.dialog = true
            state.mode = "EDIT"
        },
        editAuditForm: (state, payload) => {
            state.editedAudit = Object.assign({}, payload.audit)
            state.editedIndex = state.instances.indexOf(payload.audit)
            state.dialogAuditForm = true
            state.mode = "EDIT"
        },
        closeDialog: (state) => {
            state.editedItem = {}
            state.editedIndex = -1
            state.errors = [];
            state.dialog = false
            state.mode = "LIST"
        },
        closeDialogAudits: (state) => {
            state.dialogAudits = false
        },
        closeDialogAuditForm: (state) => {
            state.editedAudit = {}
            state.editedIndex = -1
            state.audits = [];
            state.dialogAudits = false
            state.mode = "LIST"
        },
        loading: (state, payload) => state.loading = payload.show,
        setMode: (state, mode) => state.mode = mode,
        setItems: (state, items) => state.items = items,
        setAudits: (state, audits) => state.audits = audits,
        setItemToDelete: (state, item) => state.itemToDelete = item,
        setTotalItems: (state, total) => state.totalItems = total,
        setTotalAudits: (state, totalAudits) => state.totalAudits = totalAudits,
        setRowsPerPage: (state, rowsPerPage) => state.rowsPerPage = rowsPerPage,
        showDialog: (state, show) => state.dialog = show,
        showDialogAudits: (state, show) => state.dialogAudits = show,
        showDialogAuditForm: (state, show) => state.dialogAuditForm = show,
        setPage: (state, payload) => {
            state.pagination.page = payload.page;
            state.pagination.itemsPerPage = payload.itemsPerPage
        },
        setAgents: (state, payload) => {
            state.agents = payload.agents
        },
        setContacts: (state, payload) => {
            state.contacts = payload.contacts
        },
        setScreen: (state, payload) => state.screen = payload.screen,
        setUsersName: (state, payload) => {
            let users_name = []
            payload.users.forEach(user => {
                users_name[user.id] = user.name
            });
        },
        setUsers: (state, payload) => {
            state.users = payload.users
        },
        setDialogDelete: (state, show) => state.dialogDelete = show,
        setDialogDeleteAudit: (state, show) => state.dialogDeleteAudit = show,
        setDialogReceipt: (state, payload) => state.dialogReceipt = payload.show,
        setInstances: (state, payload) => state.instances = payload.instances,
        showFilter: (state, payload) => state.dialogFilter = payload.show,
        showErrorNotification: (state, show) => state.errorNotification = show,
        showSuccessNotification: (state, show) => state.successNotification = show,
        showLoading: (state, show) => state.loading = show,
        showNotification: (state, payload) => {
            state.showNotification = payload.show,
                state.notificationMessage = payload.message,
                state.notificationType = payload.type
        },
        showOverlay: (state, payload) => {
            state.overlay = payload.show
        },
        setReceipt: (state, payload) => state.receipt_show = payload.receipt,
        viewItem(state, item) {
            state.editedItem = item
            state.editedIndex = state.items.indexOf(item)
            state.dialog = true
            state.mode = "VIEW"
        },
        updateField
    },
    getters: {
        getField,
    },
    actions: {
        getAttendants: ({ commit }, payload) => {
            let url = endPoints.users;
            url += '/attendants/' + payload.search

            axios.get(url).then(users => {
                commit('setUsers', { users: users.data })
            });
        },
        getAttendances: ({ commit }, payload) => {
            let url = endPoints.httpchats + '/attendances';
            var data = {
                start_time: payload.start_date.getTime() / 1000,
                ended_time: payload.end_date.getTime() / 1000,
                user_id: payload.user_id
            }
            commit('overlay', { show: true })
            axios.post(url, data).then(attendances => {
                var data = attendances.data
                if (data == null) {
                    data = []
                }
                commit('setAttendances', { attendances: data })
                commit('setAvgTime', { attendances: data })
                commit('setTotal', { total: data.length })
                commit('overlay', { show: false })
            });
        },
        closeDialog: (({ commit, state }) => {
            state.editedItem = {}
            if (state.screen != "messages") {
                commit('message/setSelectedMessages', { messages: [] }, { root: true })
                commit('showDialog', false)
            } else {
                commit('message/showDialogFail', { show: false }, { root: true })
            }
            setTimeout(() => {
                commit('closeDialog')
                commit('showErrorNotification', false)
                commit('showSuccessNotification', false)
            }, 300);
        }),
        closeDialogAudits: (({ commit }) => {
            setTimeout(() => {
                commit('closeDialogAudits')
                commit('showErrorNotification', false)
                commit('showSuccessNotification', false)
            });
        }),
        closeDialogAuditForm: (({ commit }) => {
            setTimeout(() => {
                commit('closeDialogAuditForm')
                commit('showErrorNotification', false)
                commit('showSuccessNotification', false)
            });
        }),
        closeDeleteDialog: ({ commit }) => {
            commit('setDialogDelete', false)
            commit('setItemToDelete', {})
        },
        closeDeleteDialogAudit: ({ commit }) => {
            commit('setDialogDeleteAudit', false)
            commit('setAuditToDelete', {})
        },
        deleteItem: ({ dispatch, commit, state }) => {
            commit('loading', true)
            axios.delete(endPoints.fails + "/" + state.itemToDelete.id, state.itemToDelete).then(() => {
                dispatch('closeDeleteDialog')
                commit('loading', false)
                let notification = {
                    show: true,
                    message: "Erro excluido com sucesso",
                    type: "success"
                }
                commit('showNotification', notification)
                return dispatch('getItems', { resetPage: false });
            });
        },
        deleteAudit: ({ dispatch, commit, state }) => {
            commit('loading', true)
            axios.delete(endPoints.fails + "/audit/" + state.itemToDelete.id, state.itemToDelete).then(() => {
                dispatch('closeDeleteDialogAudit')
                commit('loading', false)
                let notification = {
                    show: true,
                    message: "Erro excluido com sucesso",
                    type: "success"
                }
                commit('showNotification', notification)
                return dispatch('getItems', { resetPage: false });
            });
        },
        getItems: ({ commit, state }, payload) => {
            commit('loading', true)
            let url = endPoints.fails;
            // let filter = state.filter;
            if (payload.resetPage === true) {
                url += '?page=' + 1 + '&' + 'itemsPerPage=' + 10;
                commit('setPage', { page: 1, itemsPerPage: 10 })
            } else {
                url += '?page=' + state.pagination.page + '&' + 'itemsPerPage=' + state.pagination.itemsPerPage;
            }


            // Object.keys(filter).forEach(function(field) {
            //     if (filter[field] !== "" && filter[field] !== undefined) {
            //         if (field == "end") {
            //             var e = new Date(filter.end)
            //             e.setDate(e.getDate() + 1)
            //             url += "&" + field + "=" + e.toISOString().substr(0, 10)
            //         } else {
            //             url += "&" + field + "=" + filter[field]
            //         }
            //     }
            // });

            axios.get(url).then(items => {
                commit('setTotalItems', items.data.total)
                commit('loading', false)
                commit('setItems', items.data.data)
            });
        },
        getAudits: ({ commit, state }) => {
            commit('loading', true)
            let url = endPoints.fails + '/audits';
            let filter = state.filter;

            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined) {
                    if (field == "end") {
                        var e = new Date(filter.end)
                        e.setDate(e.getDate() + 1)
                        url += "&" + field + "=" + e.toISOString().substr(0, 10)
                    } else {
                        url += "&" + field + "=" + filter[field]
                    }
                }
            });

            axios.get(url).then(audits => {
                commit('setTotalAudits', audits.data.total)
                commit('loading', false)
                commit('setAudits', audits.data.data)
            });
        },
        getAgents({ commit }, payload) {
            if (payload.search) {
                axios.get(endPoints.agents + "/agent_by_name/" + payload.search).then(response => {
                    var payloadAgents = {
                        agents: response.data
                    }
                    if (response.data == null) {
                        payloadAgents.agents = []
                    }
                    commit('setAgents', payloadAgents)
                });
            }
        },
        getItemsNoPage: ({ state }) => {
            let url = endPoints.fails;
            let filter = state.filter;
            url += '?page=' + '1' + '&' + 'itemsPerPage=' + '99999';

            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined) {
                    if (field == "end") {
                        var e = new Date(filter.end)
                        e.setDate(e.getDate() + 1)
                        url += "&" + field + "=" + e.toISOString().substr(0, 10)
                    } else {
                        url += "&" + field + "=" + filter[field]
                    }
                }
            });
            return axios.get(url).then(items => {
                return items.data.data
            });
        },
        getUsers: ({ commit }, payload) => {
            let url = endPoints.users;
            url += '/attendants/' + payload.search
            axios.get(url).then(users => {
                commit('setUsers', { users: users.data })
            });
        },
        showDeleteDialog: ({ commit }, item) => {
            commit('setDialogDelete', true)
            commit('setItemToDelete', item)
        },
        showDeleteDialogAudit: ({ commit }, item) => {
            commit('setDialogDeleteAudit', true)
            commit('setAuditToDelete', item)
        },
        startFail: ({ dispatch, state }, payload) => {
            state.editedItem = Object.assign({}, payload.fail)
            state.editedIndex = state.items.indexOf(payload.fail)
            state.editedItem.status = 1
            state.screen = payload.screen
            state.mode = "EDIT"
            dispatch('save', { close: false })
        },
        finishFail: ({ dispatch, state }, payload) => {
            state.editedItem = Object.assign({}, payload.fail)
            state.editedIndex = state.items.indexOf(payload.fail)
            state.editedItem.status = 2
            state.mode = "EDIT"
            state.screen = payload.screen
            dispatch('save', { close: false })
        },
        save: async({ dispatch, commit, state }) => {
            var url = endPoints.fails;
            let func = '';
            let data = state.editedItem
            commit('showOverlay', { show: true })
            if (state.mode == 'ADD') {
                func = axios.post;
            } else {
                func = axios.put;
                url += '/' + data.id;
            }

            console.log(data)
            func(url, data).then(
                () => {
                    let notification = {
                        show: true,
                        message: "Erro salvo com sucesso",
                        type: "success"
                    }
                    commit('loading', { show: false })
                    commit('showOverlay', { show: false })
                    if (state.screen != 'messages') {
                        commit('showNotification', notification)
                        commit('closeDialog')
                        commit('showOverlay', { show: false })
                    } else {
                        commit('closeDialog')
                        commit('message/showDialogFail', { show: false }, { root: true })
                        commit('message/showNotification', notification, { root: true })                        
                    }
                    dispatch('getItems', { resetPage: true })
                },
                error => {
                    console.log(error)
                    let notification = {
                        show: true,
                        message: error.response.data.message,
                        type: "error"
                    }
                    commit('showNotification', notification)
                    commit('showOverlay', { show: false })
                }
            );
        },
        showReceipt: ({ commit }, payload) => {
            let url = endPoints.receipt;

            url += '/' + payload.item.id + '?type=1'
            commit('showOverlay', { show: true })
            axios.get(url).then(r => {
                commit('showOverlay', { show: false })
                commit('setReceipt', { receipt: r.data.file })
                commit('setDialogReceipt', { show: true })
            }).catch(() => {
                let notification = {
                    show: true,
                    message: "Comprovante não encontrado",
                    type: "error"
                }
                commit('showNotification', notification)
                commit('showOverlay', { show: false })
            });
        }
    }
}