import axios from "axios";
import { getField, updateField } from "vuex-map-fields";
import endPoints from "../../../config/endPoints";
import { defaultErrorNotification, defaultSuccessNotification } from "../../../constants"


const formatDate = (d) => {
    var day, month, year;
    month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

const defaultFilter = {
    company: "",
    description: "",
    value: "",
    start_date: "",
    end_date: "",
    observation: "",
    bank: "",
}

const defaultReceiptItem = {
  id: 0,
  company: 0,
  description: "",
  value: 0,
  date: "",
  date_picker: new Date(),
  date_created: "",
  observation: "",
  bank: 0,
  user_id: 0,
};

export default {
  namespaced: true,
  state: {
    items: [],
    banks: [],
    selectedItem: {},
    mode: "",
    loading: false,
    totalItems: 0,
    deleteDialog: false,
    filterDialog: false,
    showDialog: false,
    filter: {
      company: "",
      description: "",
      value: "",
      start_date: "",
      end_date: "",
      observation: "",
      bank: "",
    },
    notification: {
      show: false,
      message: "",
      type: "",
    },
    pagination: {
      page: 1,
      itemsPerPage: 10,
    },
  },
  mutations: {
    add: (state) => {
      defaultReceiptItem.date_picker = new Date();
      state.selectedItem = Object.assign({}, defaultReceiptItem);
      state.showDialog = true;
      state.mode = "ADD";
    },
    deleteReceipt: (state, payload) => {
      state.selectedItem = payload;
      state.deleteDialog = true;
    },
    edit: (state, payload) => {
      payload.date_picker = new Date(payload.date);
      state.selectedItem = Object.assign({}, payload);
      state.showDialog = true;
      state.mode = "EDIT";
    },
    view: (state, payload) => {
      state.selectedItem = payload;
      state.selectedItem.date_picker = new Date(payload.date);
      state.showDialog = true;
      state.mode = "VIEW";
    },
    setBanks: (state, payload) => {
      state.banks = payload.banks;
    },
    clearFilter: (state) => {
        state.filter = Object.assign({}, defaultFilter)
    },
    setItems: (state, payload) => (state.items = payload),
    setTotalItems: (state, payload) => (state.totalItems = payload),
    setLoading: (state, payload) => (state.loading = payload),
    showNotification: (state, payload) => (state.notification = payload),
    setSelectedItem: (state, payload) => state.selectedItem = payload,
    setDeleteDialog: (state, payload) => (state.deleteDialog = payload),
    setShowDialog: (state, payload) => {
      state.showDialog = payload;
      state.selectedItem = Object.assign({}, defaultReceiptItem);
    },
    setShowFilter: (state, payload) => (state.filterDialog = payload),
    updateField,
  },
  actions: {
    getItems: async ({ commit, state }) => {
     
      let filter = state.filter
      let url = `${endPoints.receipts}?page=${state.pagination.page}&itemsPerPage=${state.pagination.itemsPerPage}`;

      Object.keys(filter).forEach(function(field) {
        if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {                    
            if (field == "end_date" || field == "start_date") {
                var e = new Date(filter[field])
                url += "&" + field + "=" + formatDate(e) + " " + e.toLocaleTimeString()
            } else {
                url += "&" + field + "=" + filter[field]
            }
        }
      })

      commit("setLoading", true);

      try {
        let items = await axios.get(url);
        commit("setItems", items.data.data);
        commit("setTotalItems", items.data.total);
      } catch (error) {
        commit(
          "showNotification",
          defaultErrorNotification(error?.response?.data?.message)
        );
      }
      commit("setLoading", false);
    },
    deleteItem: async ({ commit, dispatch, state }) => {
      commit("setLoading", true);
      try {
        await axios.delete(`${endPoints.receipts}/${state.selectedItem.id}`);

        commit("setLoading", false);
        commit("setDeleteDialog", false);
        commit("setSelectedItem", {});
        commit("showNotification", defaultSuccessNotification("Receita removida com sucesso"));

        return dispatch("getItems");
      } catch (error) {
        commit(
          "showNotification",
          defaultErrorNotification(error?.response?.data?.message)
        );
        commit("setLoading", false);
      }
    },
    getBanks: ({ commit }) => {
      let url = endPoints.banks;
      url += "?page=" + 1 + "&" + "itemsPerPage=" + 999;
      axios.get(url).then((items) => {
        let banks = items.data.data;
        commit("setBanks", { banks: banks });
      });
    },
    save: async ({ commit, state, dispatch, rootState }) => {
      let url = endPoints.receipts;
      let func = "";
      state.mode === "ADD"
        ? (func = axios.post)
        : ((func = axios.put), (url += `/${state.selectedItem.id}`));
      commit("setLoading", true);
      try {
        let date_created = new Date();
        state.selectedItem.user_id = rootState.login.userData.id;
        state.selectedItem.date_created =
          date_created.toISOString().substr(0, 10) +
          " " +
          date_created.toLocaleTimeString();
        state.selectedItem.date =
          state.selectedItem.date_picker.toISOString().substr(0, 10) +
          " " +
          state.selectedItem.date_picker.toLocaleTimeString();

        if (typeof state.selectedItem.value === "string") {
          state.selectedItem.value = parseFloat(
            state.selectedItem.value.replace(/,/, ".")
          );
        }

        await func(url, state.selectedItem);

        commit("showNotification", defaultSuccessNotification("Receita salva com sucesso"));
        commit("setShowDialog", false);
        commit("setLoading", false);
        dispatch("getItems");
      } catch (error) {
        commit("showNotification", defaultErrorNotification(error?.response?.data?.message));
        commit("setLoading", false);
      }
    },
  },
  getters: {
    getField,
  },
};
