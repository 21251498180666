import axios from "axios";
import { getField, updateField } from "vuex-map-fields";
import endPoints from "../../../config/endPoints";
import { defaultErrorNotification, defaultSuccessNotification } from "../../../constants"


const formatDate = (d) => {
  var day, month, year;
  month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}

const defaultFilter = {
  id: 0,
  person_type: 0,
  inscription: 0,
  name: '',
  country: '',
  city: '',
  state: '',
}

const defaultClientItem = {
  id: null,
  type: 0,
  inscription: '',
  name: '',
  birth_date: '',
  date_picker: '',
  corporate_name: '',
  country: '',
  cep: '',
  district: '',
  address: '',
  number: '',
  city: '',
  payment_for: 0,
  limit_annotated: 0,
  state: '',
  complement: '',
  contact: null,
  whatsapp_group: '',
  created_at: new Date(),
  situation: 4,
};

export default {
  namespaced: true,
  state: {
    client: {},
    items: [],
    states: [],
    countries: [],
    countriesSuperData: [],
    selectedItem: {},
    mode: "",
    loading: false,
    totalItems: 0,
    deleteDialog: false,
    concludeDialog: false,
    filterDialog: false,
    showDialog: false,
    overlay: false,
    screen: "",
    filter: {
      id: '',
      type: '',
      inscription: '',
      name: '',
      birth_date: '',
      corporate_name: '',
      country: '',
      city: '',
      state: '',
      contact: '',
      whatsapp_group: '',
      payment_for: '',
      search: '',
    },
    possible_types: [
      {
        id: 0,
        name: 'Pessoa Física',
      },
      {
        id: 1,
        name: 'Pessoa Juridica',
      },
    ],
    type_of_payment: [
      {id: 0, name: 'Data'},
      {id: 1, name: 'Rateio'},
    ],
    notification: {
      show: false,
      message: "",
      type: "",
    },
    pagination: {
      page: 1,
      itemsPerPage: 10,
    },
  },


  mutations: {
    add: (state) => {
      defaultClientItem.date_picker = new Date();
      state.selectedItem = Object.assign({}, defaultClientItem);
      state.showDialog = true;
      state.mode = "ADD";
    },
    deleteClient: (state, payload) => {
      state.selectedItem = payload;
      state.deleteDialog = true;
    },
    edit: (state, payload) => {
      state.selectedItem = Object.assign({}, payload);
      state.mode = "EDIT";
    },
    view: (state, payload) => {
      state.selectedItem = payload;
      state.mode = "VIEW";
    },
    clearFilter: (state) => {
      state.filter = Object.assign({}, defaultFilter)
    },

    overlay: (state, payload) => {
      state.overlay = payload.show
    },
    setClient: (state, payload) => (state.client = payload),
    setMode: (state, payload) => (state.mode = payload),
    setItems: (state, payload) => (state.items = payload),
    setStates: (state, payload) => (state.states = payload),
    setCountries: (state, payload) => (state.countries = payload),
    setCountriesSuperData: (state, payload) => (state.countriesSuperData = payload),
    setTotalItems: (state, payload) => (state.totalItems = payload),
    setLoading: (state, payload) => (state.loading = payload),
    showNotification: (state, payload) => (state.notification = payload),
    setSelectedItem: (state, payload) => state.selectedItem = payload,
    setDeleteDialog: (state, payload) => (state.deleteDialog = payload),
    setConcludeDialog: (state, payload) => (state.concludeDialog = payload),
    setShowDialog: (state, payload) => {
      state.showDialog = payload;
      state.selectedItem = Object.assign({}, defaultClientItem);
    },
    setShowFilter: (state, payload) => (state.filterDialog = payload),
    setPage: (state, payload) => {
      state.pagination.page = payload.page;
      state.pagination.itemsPerPage = payload.itemsPerPage;
    },

    updateField,
  },


  actions: {

    getItems: async ({ commit, state }, payload) => {
      let filter = state.filter
      let url = `${endPoints.clients}`;


      if (payload.resetPage === true) {
        url += "?page=" + 1 + "&" + "itemsPerPage=" + 10;
        commit("setPage", { page: 1, itemsPerPage: 10 });
      } else {
        url +=
          "?page=" +
          state.pagination.page +
          "&" +
          "itemsPerPage=" +
          state.pagination.itemsPerPage;
      }

      Object.keys(filter).forEach(function (field) {
        if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
          if (field == "end_date" || field == "start_date") {
            var e = new Date(filter[field])
            url += "&" + field + "=" + formatDate(e) + " " + e.toLocaleTimeString()
          } else {
            url += "&" + field + "=" + filter[field]
          }
        }
      })

      if (state.pagination && state.pagination.sortBy && state.pagination.sortBy.length > 0) {
        url += "&sort_by=" + state.pagination.sortBy[0]
        url += "&sort_desc=" + state.pagination.sortDesc[0]
      }

      commit("setLoading", true);

      try {
        let items = await axios.get(url);
        

        for (let [index, item] of items.data.data.entries()) {
          items.data.data[index].contact = '+' + item.contact.toString()
        }

        commit("setItems", items.data.data);
        commit("setTotalItems", items.data.total);
      } catch (error) {
        commit("showNotification", defaultErrorNotification(error?.response?.data?.message));
      }

      commit("setLoading", false);
    },

    getClient: async ({ commit }, payload) => {
      let url = `${endPoints.clients}/${payload.id}`;

      commit("setLoading", true);

      try {
        let response = await axios.get(url);
        response.data.contact = response.data.contact.toString()
        
        commit("setClient", response.data);

      } catch (error) {
        commit("showNotification", defaultErrorNotification(error?.response?.data?.message));
      }

      commit("setLoading", false);
    },


    deleteItem: async ({ commit, dispatch, state }) => {
      commit("setLoading", true);

      try {
        await axios.delete(`${endPoints.clients}/${state.selectedItem.id}`);

        commit("setLoading", false);
        commit("setDeleteDialog", false);
        commit("setSelectedItem", {});
        commit("showNotification", defaultSuccessNotification("Cliente deletado com sucesso"));

        return dispatch('getItems', { resetPage: true })

      } catch (error) {
        commit("showNotification", defaultErrorNotification(error?.response?.data?.message));
        commit("setLoading", false);

      }
    },


    save: async ({ commit, state, dispatch, rootState }) => {
      let url = endPoints.clients;
      let func = "";
      state.selectedItem.limit_annotated = parseFloat(state.selectedItem.limit_annotated)
      state.mode === "ADD"
        ? (func = axios.post)
        : ((func = axios.put), (url += `/update/${state.selectedItem.id}`));

      commit("setLoading", true);
      try {
        state.selectedItem.created_user = rootState.login.userData.id;

        if (state.mode === "ADD") {
          state.selectedItem.created_at =
            state.selectedItem.date_picker.toISOString().substr(0, 10) + " " +
            state.selectedItem.date_picker.toLocaleTimeString();
        }

        state.selectedItem.inscription = state.selectedItem.inscription.replace(/[,.-]/g, "")

        if (state.selectedItem.cep) {
          state.selectedItem.cep = state.selectedItem.cep.replace(/[,.-]/g, "")
        }

        if (state.selectedItem.birth_date == "") {
          state.selectedItem.birth_date = null;
        }
        
        if (state.selectedItem.number == "") {
          state.selectedItem.number = null;
        }
        if (typeof state.selectedItem.contact != "string") {
          state.selectedItem.contact = state.selectedItem.contact.toString()
        } else {
          state.selectedItem.contact = state.selectedItem.contact.replace(/[+()-]/g, "")
          state.selectedItem.contact = state.selectedItem.contact.replace(/ /g, "")
        }
        console.log(state.selectedItem)
        await func(url, state.selectedItem);

        commit("showNotification", defaultSuccessNotification("Cliente salvo com sucesso"));
        commit("setShowDialog", false);
        commit("setLoading", false);
        dispatch('getItems', { resetPage: true })

      } catch (error) {
        console.log(error)
        commit("showNotification", defaultErrorNotification(error?.response?.data?.message));
        commit("setLoading", false);
      }
    },


    updateSelectedItem: ({ commit, state }) => {
      commit("setSelectedItem", state.selectedItem)
    },

    viewItem({ dispatch, commit }, payload) {
      commit("view", payload);
      dispatch('menu/openLink', '/clients/' + payload.id, { root: true })
    },


    getCep: ({ commit, state }) => {
      var r_cep = state.selectedItem.cep.replace(/-/, "")
      if (r_cep.length == 8) {
        let url = "https://viacep.com.br/ws/" + r_cep + "/json/"

        axios.get(url).then(response => {
          if (response.data && response.data.length != 0) {

            state.selectedItem.district = response.data.bairro
            state.selectedItem.address = response.data.logradouro
            state.selectedItem.city = response.data.localidade
            state.selectedItem.country = "Brasil"
            state.selectedItem.state = response.data.uf

          } else {
            commit('showNotification', defaultErrorNotification("Ocorreu um erro ao buscar o CEP"))

          }
        }).catch((err) => {
          commit('showNotification', defaultErrorNotification(err))
        })
      } else {
        commit('showNotification', defaultErrorNotification("CEP inválido"))
      }
    },


    getStates: ({ commit }) => {
      let url = "https://servicodados.ibge.gov.br/api/v1/localidades/estados"

      axios.get(url).then(response => {
        const ufs = []

        for (let state of response.data) {
          ufs.push(state.sigla)
        }
        commit('setStates', ufs)

      }).catch((err) => {
        commit('showNotification', defaultErrorNotification(err))
      })
    },

    async getCountries({ commit }) {
      try {
        const response = await axios.get('https://restcountries.com/v3/all')

        let countries = response.data.map(country => country.translations.por)

        commit('setCountriesSuperData', response.data)
        commit('setCountries', countries)

      } catch (error) {
        commit('showNotification', defaultErrorNotification(error))
      }
    },

  },
  getters: {
    getField,
  },
};
