export const buildDataTopRakes = (data) => {
    let uniqueClubs = [...new Set(data.map(obj => obj.club_name))];
    let buildedData = {
        rake: [],
        rakeback: [],
        players_active: [],
        agents_active: [],
    };
    let headers = [
        { type: 'string', label: 'Data' },
    ];
    let headersClubs = [];
    let lastDate = '';

    let defaultRow = ['']
    let defaultRowRakeback = ['']
    let defaultRowPlayers = ['']
    let defaultRowAgents = ['']

    uniqueClubs.forEach((c) => {
        headersClubs.push({ type: 'number', label: c })
    })
    buildedData.rake.push([...headers, ...headersClubs]);
    buildedData.rakeback.push([...headers, ...headersClubs]);
    buildedData.players_active.push([...headers, ...headersClubs]);
    buildedData.agents_active.push([...headers, ...headersClubs]);

    for (let i = 1; i <= uniqueClubs.length; i++) {
        defaultRow[i] = 0;
        defaultRowRakeback[i] = 0;
        defaultRowPlayers[i] = 0;
        defaultRowAgents[i] = 0;
    }
    let rowRake = Object.assign(defaultRow);
    let rowRakeback = Object.assign(defaultRowRakeback);
    let rowPlayers = Object.assign(defaultRowPlayers);
    let rowAgents = Object.assign(defaultRowAgents);

    data.forEach((d) => {
        if (d.start_date != lastDate) {
            if (lastDate != '') {
                let pushRow = []
                let pushRowRakeback = []
                let pushRowPlayers = []
                let pushRowAgents = []
                Object.assign(pushRow, rowRake)
                Object.assign(pushRowRakeback, rowRakeback)
                Object.assign(pushRowPlayers, rowPlayers)
                Object.assign(pushRowAgents, rowAgents)
                buildedData.rake.push(pushRow)
                buildedData.rakeback.push(pushRowRakeback)
                buildedData.players_active.push(pushRowPlayers)
                buildedData.agents_active.push(pushRowAgents)
            }
            Object.assign(rowRake, defaultRow);
            Object.assign(rowRakeback, defaultRowRakeback);
            Object.assign(rowPlayers, defaultRowPlayers);
            Object.assign(rowAgents, defaultRowAgents);
            lastDate = d.start_date;
            let date = new Date(d.start_date + " 12:00:00");
            rowRake[0] = date.toLocaleDateString();
            rowRakeback[0] = date.toLocaleDateString();
            rowPlayers[0] = date.toLocaleDateString();
            rowAgents[0] = date.toLocaleDateString();
        }

        let index = (uniqueClubs.indexOf(d.club_name) + 1);
        rowRake[index] = d.total_rake
        rowRakeback[index] = d.rakeback + d.total_rakeback_sub_agents
        rowPlayers[index] = d.active_players
        rowAgents[index] = d.active_agents
    })
    buildedData.rake.push(rowRake)
    buildedData.rakeback.push(rowRakeback)
    buildedData.players_active.push(rowPlayers)
    buildedData.agents_active.push(rowAgents)
    return buildedData;
}