<template>
    <v-container grid-list-md>
        <v-layout wrap>
            <v-flex sm12 md6 xs6 xl6>
                <v-card class="elevation-10" >
                    <v-card-title class="text-center justify-center">Rake/Rakeback Gerado</v-card-title>
                    <GChart
                        type="LineChart"
                        :data="chartRake"
                        :options="options_rake"
                        :resizeDebounce="500"
                    />
                </v-card>
            </v-flex>
            <v-flex sm12 md6 xs6 xl6>
                <v-card class="elevation-10" >
                    <v-card-title class="text-center justify-center">Jogadores/Agentes Ativos</v-card-title>
                    <GChart
                        type="LineChart"
                        :data="chartActiveAgentsPlayers"
                        :options="options_active_players"
                        :resizeDebounce="500"
                    />
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "Rake",
  computed: {
    ...mapState("rake", [
      "rakes_agent",
      "qt_active_agents_players"
    ]),
    chartRake() {
      return [this.headers_rake, ...this.rakes_agent]
    },
    chartActiveAgentsPlayers() {
      return [this.header_active_agents_players, ...this.qt_active_agents_players]
    }
  },
  created() {
      this.getRakesAgent()
  },
  data: () => ({
    headers_rake: [
      {type: 'string', label: 'Data'},
      {type: 'number', label: 'Total Rake'},
      {type: 'number', label: 'Total Rake SB'},
      {type: 'number', label: 'Rakeback'},
      {type: 'number', label: 'Rakeback SB'},
    ],
    header_active_agents_players: [
      {type: 'string', label: 'Data'},
      {type: 'number', label: 'Jogadores Ativos'},
      {type: 'number', label: 'Agentes Ativos'},
    ],
    options_rake:  {
        chart: {
            title: 'Rake/Rakeback Gerado',
        },
        legend: { position: 'bottom'},
        vAxis: {
            title: 'Valor',
        },  
    },
    options_active_players:  {
        chart: {
            title: 'Jogadores/Agentes Ativos',
        },
        legend: { position: 'bottom'},
        vAxis: {
            title: 'Valor',
        },  
    },
  }),
  methods: {
    ...mapActions("rake", ["getRakesAgent"]),
  }
}
</script>