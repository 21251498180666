<template>
  <v-card>
    <v-card-title class="justify-center">{{ this.contacts_mode == 'new-chat' ? 'Iniciar Conversa' : 'Enviar Contato' }}</v-card-title>
    <v-card-text >
      <v-col class="d-flex" cols="12" sm="12">
        <v-select
          :clearable="true"
          clear-icon="mdi-close"
          label="Banco"
          :items="types_searches"
          v-model="type_search"
          item-text="text"
          item-value="value"
          outlined
        ></v-select>
      </v-col>
      <v-col class="d-flex" cols="12" sm="12">
        <v-autocomplete
          clearable
          deletable-chips
          v-model="selected_contact"
          :no-filter="true"
          :items="contacts"
          outlined
          chips
          small-chips
          no-data-text="Nenhum contato encontrado"
          :item-text="getText"
          :return-object="true"
          placeholder="Digite aqui o nome do contato ou grupo"
          label="Contatos"
          :search-input.sync="searchInput"
        ></v-autocomplete>
      </v-col>
      <v-col v-if="this.contacts_mode == 'new-chat'" class="d-flex" cols="12" sm="12">
        <v-autocomplete
          :items="instancesUser"
          label="Whatsapp"
          outlined
          v-model="instance_id"
          item-text="name"
          item-value="id"
        ></v-autocomplete>
      </v-col>
    </v-card-text>
    <v-card-actions>
      <v-col>
        <v-btn text @click="showDialogContacts({show: false})">Cancelar</v-btn>
      </v-col>
      <v-col class="text-right">
        <v-btn text type="submit" right @click="confirm()">Confirmar</v-btn>
      </v-col>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
  import { mapFields } from 'vuex-map-fields'
  import debounce from '../../../../util/debounce.js' 
  export default {
    props: [
      'contacts_mode',
      'showDialogContacts'
    ],
    computed: {
      ...mapGetters("login", ["getInstanceId"]),
      ...mapFields("contact_chat", ["type_search"]),
      ...mapGetters("instances", {instances: "instancesChat"}),
      ...mapState('contact_chat', ['contacts']),
      instancesUser: {
        get: function() {
          let instanceId = this.getInstanceId
          if(instanceId != "" && instanceId != null && instanceId != undefined) {
            return this.instances.filter((i) => {
              return i.id == this.getInstanceId
            }) 
          }
          return this.instances
        }
      },
    },
    created() {
      let instanceId = this.getInstanceId
      if(instanceId != "" && instanceId != null && instanceId != undefined) {
        this.instance_id = this.getInstanceId
      }
    },
    data: () => ({
      instance_id: '',
      selected_contact: {},
      searchInput: '',
      types_searches: [
        {text: 'Chat', value: 0},
        {text: 'Contato', value: 1}
      ],
    }),
    mixins: [debounce],
    methods: {
      ...mapActions('contact_chat', ['getContacts']),
      ...mapActions('chat', ['onSendContact', 'startChat']),
      ...mapMutations('chat', ['showNotification']),
      confirm() {
        let notification = {
          show: true,
          message: "Preencha todos os campos!",
          type: "error"
        }
        if(this.contacts_mode == 'new-chat') {
          if(this.selected_contact && this.instance_id) {
            if(this.selected_contact._id != "" && this.selected_contact._id != null && this.selected_contact._id != undefined) {
              this.startChat({contact: this.selected_contact, instance_id: this.instance_id})
            }
            else{
              this.showNotification(notification)
            }
          }
          else {
            this.showNotification(notification)
          }
        } else if(this.contacts_mode == 'send-contact') {
          if(this.selected_contact) {
            if(this.selected_contact._id != "" && this.selected_contact._id != null && this.selected_contact._id != undefined) {
              this.onSendContact({contact: this.selected_contact})
            }
            else {
              this.showNotification(notification)
            }
          } 
          else {
            this.showNotification(notification)
          }
        }
      },
      customFilter(item){
        return item
      },
      getText(item) {
        if(item.name) {
          if(item.name.length == 1) {
            return item.name
          }
          
          var text = ""
          item.name.forEach((name) => {
            text += name + " | "
          })
          return text
        }
        else {
          return text
        }
      },
    },
    mounted() {
      this.getContacts = this.debounce(this.getContacts, 500)
    },
    watch: {
      searchInput (val) {
        var payload = {
          search: val,
          contacts: this.selected_contact
        }
        this.getContacts(payload)
      },
    },
  }
</script>

<style>
  .contato-autocomplete{
    margin: 10px !important;
  }
</style>
