import axios from "axios";
import { getField, updateField } from "vuex-map-fields";
import endPoints from "../../../config/endPoints";
import formatDate from '../../../util/formatDate.js'
import {
  defaultErrorNotification,
  defaultSuccessNotification,
} from "../../../constants";

const defaultFilter = {
  id: "",
  id_client: "",
  start: "",
  end: "",
  value: "",
  id_slot: "",
  id_app: "",
  type: "",
  payment_type: "",
};

const defaultAnnotated = {
  id: null,
  id_client: null,
  date_created: "",
  value: 0,
  id_slot: null,
  id_app: "",
  type: null,
  payment_type: null,
  pix_id: null,
};

export default {
  namespaced: true,
  state: {
    items: [],
    client: {},
    screen: '',
    dialogOrder: false,
    dialogReorder: false,
    pixRelated: {},
    selectedItem: {},
    mode: "",
    slots: [],
    loading: false,
    totalItems: 0,
    deleteDialog: false,
    filterDialog: false,
    showDialog: false,
    filter: {
      id: "",
      id_client: "",
      date_created: "",
      value: "",
      id_slot: "",
      id_app: "",
      type: "",
      payment_type: "",
    },
    notification: {
      show: false,
      message: "",
      type: "",
    },
    pagination: {
      page: 1,
      itemsPerPage: 10,
    },
    possible_status: [
      { id: 0, description: 'Criado' },
      { id: 1, description: 'Ordem Criada' },
      { id: 2, description: 'Erro ao criar ordem' },
      { id: 3, description: 'Concluído' },
      { id: 4, description: 'Erro Bot' },
      { id: 5, description: 'Erro Pix' },
    ],

    possible_types: [
      { id: 0, description: 'Crédito Concedido' },
      { id: 1, description: 'Crédito Pago' },
    ],
    possible_payment_types: [
      { id: 0, description: 'Fichas' },
      { id: 1, description: 'Pix' },
    ],
  },
  mutations: {
    add: (state, payload) => {
      state.mode = "ADD"
      state.screen = payload.screen
      defaultAnnotated.date_created = new Date();
      state.selectedItem = Object.assign({}, defaultAnnotated);
      state.selectedItem.id_client = state.client.id
      state.showDialog = true;
    },
    deleteAnnotated: (state, payload) => {
      state.selectedItem = payload;
      state.deleteDialog = true;
    },
    edit: (state, payload) => {
      state.selectedItem = Object.assign({}, payload);
      state.selectedItem.date_created = new Date(payload.date_created);
      state.showDialog = true;
      state.mode = "EDIT";
    },
    createOrderAnnotated(state, payload) {
      state.dialogOrder = true;
      state.selectedItem = payload;
    },
    reorderAnnotated(state, payload) {
      state.dialogReorder = true;
      state.selectedItem = payload;
    },
    setDialogOrder(state, payload) {
      state.dialogOrder = payload;
    },
    setDialogReorder(state, payload) {
      state.dialogReorder = payload;
    },

    view: (state, payload) => {
      state.selectedItem = payload;
      state.selectedItem.date_created = new Date(payload.date_created);
      state.showDialog = true;
      state.mode = "VIEW";
    },
    clearFilter: (state) => {
      state.filter = Object.assign({}, defaultFilter);
    },
    setSlots: (state, slots) => {
      state.slots = slots
    },
    setItems: (state, payload) => (state.items = payload),
    setClient: (state, payload) => (state.client = payload),
    setTotalItems: (state, payload) => (state.totalItems = payload),
    setLoading: (state, payload) => (state.loading = payload),
    setPixRelated: (state, payload) => (state.pixRelated = payload),
    showNotification: (state, payload) => (state.notification = payload),
    setSelectedItem: (state, payload) => (state.selectedItem = payload),
    setDeleteDialog: (state, payload) => (state.deleteDialog = payload),
    setShowDialog: (state, payload) => {
      state.showDialog = payload;
      state.selectedItem = Object.assign({}, defaultAnnotated);
    },
    setShowDialogPixRelated: (state, payload) => {
      state.dialogPixRelated = payload;
    },
    setShowFilter: (state, payload) => (state.filterDialog = payload),
    updateField,
  },
  actions: {
    getItems: async ({ commit, state }) => {
      let filter = state.filter;
      let url = `${endPoints.annotateds}?page=${state.pagination.page}&itemsPerPage=${state.pagination.itemsPerPage}`;
      Object.keys(filter).forEach(function (field) {
        if (
          filter[field] !== "" &&
          filter[field] !== undefined &&
          filter[field] !== null
        ) {
          if (field == "end" || field == "start") {
            var e = new Date(filter[field])
            url += "&" + field + "=" + formatDate(e) + " " + e.toLocaleTimeString()
          } else {
            url += "&" + field + "=" + filter[field]
          }
        }
      });

      if (
        state.pagination &&
        state.pagination.sortBy &&
        state.pagination.sortBy.length > 0
      ) {
        url +=
          "&sort_by=" +
          (state.pagination.sortBy[0] === "name"
            ? "id"
            : state.pagination.sortBy[0]);
        url += "&sort_desc=" + state.pagination.sortDesc[0];
      }

      commit("setLoading", true);

      try {
        let items = await axios.get(url);
        commit("setItems", items.data.data);
        commit("setTotalItems", items.data.total);
      } catch (error) {
        commit(
          "showNotification",
          defaultErrorNotification(error?.response?.data?.message)
        );
      }
      commit("setLoading", false);
    },
    deleteItem: async ({ commit, dispatch, state }) => {
      commit("setLoading", true);
      try {
        await axios.delete(`${endPoints.annotateds}/${state.selectedItem.id}`);

        commit("setLoading", false);
        commit("setDeleteDialog", false);
        commit("setSelectedItem", {});
        commit(
          "showNotification",
          defaultSuccessNotification("Anotado removido com sucesso")
        );

        return dispatch("getItems");
      } catch (error) {
        commit(
          "showNotification",
          defaultErrorNotification(error?.response?.message)
        );
        commit("setLoading", false);
      }
    },
    save: async ({ commit, state, dispatch }) => {
      let url = endPoints.annotateds;
      state.selectedItem.id_client = parseInt(state.selectedItem.id_client)
      state.selectedItem.value = parseFloat(state.selectedItem.value)
      let func = "";
      state.mode === "ADD"
        ? (func = axios.post)
        : ((func = axios.put), (url += `/${state.selectedItem.id}`));
      commit("setLoading", true);
      if (state.selectedItem.type != 1) {
        state.selectedItem.payment_type = null
      }
      try {
        await func(url, state.selectedItem);
        commit(
          "showNotification",
          defaultSuccessNotification("Anotado salvo com sucesso")
        );
        commit("setShowDialog", false);
        commit("setLoading", false);
        dispatch("getItems");
      } catch (error) {
        
        console.log(error)
        commit(
          "showNotification",
          defaultErrorNotification(error?.response?.message)
        );
        commit("setLoading", false);
      }
    },
    viewItem: async ({ commit }, payload) => {
      let url = endPoints.pix + "/" + payload.pix_id;
      if (payload.pix_id) {
      try {
        let pix = await axios.get(url);
        console.log(pix.data)
        commit("setPixRelated", pix.data);
        commit("view", payload);
      } catch (error) {
        commit(
          "showNotification",
          defaultErrorNotification(error?.response?.message)
        );
      }}
      else{
        commit("view", payload);
      }
    },
    getClient: async ({ commit }, payload) => {
      let url = endPoints.clients + "/contact/" + payload.contact ;
      try {
        let client = await axios.get(url);
        commit("setClient", client.data)
        commit('add', { screen: payload.screen })
        commit('message/showDialogAnnotated', { show: true }, { root: true })


      } catch (error) {
        commit('message/showDialogAnnotated', { show: false }, { root: true })
        let message = {
          show: true,
          message: "Não é possivel criar anotado pois esse contato não está vinculado a nenhum grupo!",
          notificationTime: 3200,
          type: "error"
        }
        commit('message/showNotification', message, { root: true })
      }
      

    },
    getSlots: async ({ commit }) => {
      let url = endPoints.slots;
      url += "?page=" + 1 + "&" + "itemsPerPage=" + 999;
      try {
        let slots = await axios.get(url);
        commit("setSlots", slots.data.data)


      } catch (error) {
        commit(
          "showNotification",
          defaultErrorNotification(error?.response?.data?.message)
        );
      }

    },
    createOrder: async ({ commit, state, dispatch }) => {
      let url = endPoints.annotateds + "/create_order";
      try {
        await axios.post(url, state.selectedItem);
        commit(
          "showNotification",
          defaultSuccessNotification("Ordem criada com sucesso")
        );
        commit("setDialogOrder", false);
        commit("setLoading", false);
        dispatch("getItems");
      } catch (error) {
        commit(
          "showNotification",
          defaultErrorNotification(error?.response?.message)
        );
        commit("setDialogOrder", false);
        commit("setLoading", false);
      }

    },
    closeReorderDialog: ({ commit }) => {
      commit('setDialogReorder', false)
      commit('setSelectedItem', {})
    },
    closeDialog: (({ commit, state }) => {
      state.selectedItem = {}
      if (state.screen != "messages") {
        commit('setShowDialog', false)
      } else {
        commit('message/showDialogAnnotated', { show: false }, { root: true })
      }

      // commit('closeDialog')
    }),
    reorder: ({ state, commit, dispatch }) => {
      const url = endPoints.annotateds + "/reorder";
      axios.post(url, state.selectedItem).then(() => {
        let notification = {
          show: true,
          message: "Ordem reenviada com sucesso",
          type: "success"
        }
        dispatch('getItems', { resetPage: true })
        dispatch('closeReorderDialog')
        commit('showNotification', notification)
      }).catch((error) => {
        let notification = {
          show: true,
          message: error.response.data.message,
          type: "error"
        }
        commit('showNotification', notification)
      })
    },
  },
  getters: {
    getField,
  },
};
