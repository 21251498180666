<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline text-center justify-center">Filtro</span>

        <v-col class="text-right">
          <v-btn v-if="filteredTransactions" color="white"  @click="clearFilterTransactions" right small depressed>
            <v-icon color="black">mdi-filter-remove</v-icon>
          </v-btn>
        </v-col>
      </v-card-title>

      <v-card-text>
        <v-form ref="form">
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm6 md6 v-if="showPeriod">
                <v-text-field
                    label="Periodo"
                    v-model="period"
                    outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-select
                  :clearable="true"
                  clear-icon="mdi-close"
                  label="Clube"
                  :items="instances"
                  v-model="id_instance"
                  item-text="name"
                  item-value="id_instance"
                  outlined
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field
                    label="Id do Gestor"
                    v-model="id_manager_app"
                    outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field
                    label="Id do Agente"
                    v-model="id_agent_app"
                    outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field
                    label="Id do Player"
                    v-model="id_player_app"
                    outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field required label="Valor" v-model="value" outlined></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-select
                  clear-icon="mdi-close"
                  :clearable="true"
                  label="Suspeita"
                  :items="possible_suspect"
                  v-model="suspect"
                  item-text="description"
                  item-value="id"
                  outlined
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-select
                  clear-icon="mdi-close"
                  :clearable="true"
                  label="Tipo Suspeita ( G - J )"
                  :items="possible_type_suspect"
                  v-model="type_suspect"
                  item-text="description"
                  item-value="id"
                  outlined
                ></v-select>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn text @click="showFilterTransactions({show: false})">Cancelar</v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn text type="submit" right @click="filter">Filtrar</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "PeriodsClosureTransactionsFilter",
  created() {
    this.getInstances()
  },
  computed: {
    ...mapState("instances", ["instances"]),
    ...mapFields("periods_closure_transactions", [
      "filterTransactions.period",
      "filterTransactions.suspect",
      "filterTransactions.type_suspect",
      "filterTransactions.id_manager_app",
      "filterTransactions.id_agent_app",
      "filterTransactions.id_player_app",
      "filterTransactions.value",
      "filterTransactions.id_instance",
      "filteredTransactions"
      // ...
    ]),
  },
  props: [
    'showPeriod'
  ],
  data: () => ({
    possible_suspect: [
      {id: 0, description: "Não"},
      {id: 1, description: "Sim"},
      {id: 2, description: "Sim/Validada"}
    ],
    possible_type_suspect: [
      {id: 0, description: "Interno - Externo"},
      {id: 1, description: "Externo - Interno"}
    ],
    searchPeriod: '',
    timeProps: {
      format: "24hr",
    },
  }),
  methods: {
    ...mapActions('instances', ['getInstances']),
    ...mapMutations("periods_closure_transactions", ["showFilterTransactions"]),
    ...mapActions("periods_closure_transactions", ["clearFilterTransactions", "getPeriodClosureTransactions"]),
    filter() {
      this.getPeriodClosureTransactions({ resetPage: true });
      this.showFilterTransactions({ show: false });
    },
  }
};
</script>

<style>
</style>