<template>
  <div>
    <v-card>
      <p class="text-center font-weight-medium text-h6">
        VALOR TOTAL: R${{ this.transactions_total_value }}
      </p>

      <v-data-table
        :headers="headers"
        :loading="loading"
        :items="items"
        :options.sync="pagination"
        :server-items-length="totalItems"
        :item-class="getClassColor"
        :footer-props="{
          'items-per-page-options': [10, 20, 50, 100],
          'items-per-page-text': 'Itens por página',
          'show-first-last-page': true,
          'page-text': `Página ${pagination.page} de ${parseInt(totalItems/pagination.itemsPerPage) + 1}`
        }"
        no-data-text="Nenhuma registro encontrado"
        class="elevation-10 table-margin"
      >
        <template v-slot:[`item.date`]="{ item }">
          <span>{{ getDateTime(item.date) }}</span>
        </template>
        <template v-slot:[`item.value`]="{ item }">
          <span>{{
            item.value.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })
          }}</span>
        </template>
        <template v-slot:[`item.type_transaction`]="{ item }">
          <span>{{ item.type_transaction == 0 ? "Saída" : "Entrada" }}</span>
        </template>
        <template v-slot:[`item.suspect`]="{ item }">
          <span>{{
            item.suspect == 0
              ? "Não"
              : item.suspect == 1
              ? "Sim"
              : "Sim/Validada"
          }}</span>
        </template>
        <template v-slot:[`item.type_suspect`]="{ item }">
          <span>{{
            item.type_suspect == 0
              ? "Interno - Externo"
              : item.type_suspect == 1
              ? "Externo - Interno"
              : "-"
          }}</span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "PlayerTransactions",

  methods: {
    ...mapActions("player_history", ["getItems"]),

    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      }
      return false;
    },
    getClassColor(item) {
      if (item.type_transaction == 0) {
        return "row-red";
      } else {
        return "row-green";
      }
    },
    getDateTime(date) {
      if (date) {
        var d = new Date(date);
        return d.toLocaleDateString() + " " + d.toLocaleTimeString();
      }
      return "";
    },
  },
  computed: {
    ...mapState("player_history", [
      "filtered",
      "items",
      "loading",
      "totalItems",
      "transactions_total_value",
    ]),
    ...mapFields("player_history", [
      "current_tab",
      "pagination",
      "filter.id_pppoker",
      "filter.value",
      "filter.status",
      "filter.start",
      "filter.end",
    ]),
  },
  data: () => ({
    headers: [
      { text: "Id", value: "id", sortable: false },
      { text: "Period", value: "id_period_closure", sortable: false },
      { text: "Clube", value: "club_name", sortable: false },
      { text: "Data", value: "date", sortable: false },
      { text: "Gestor", value: "id_manager_app", sortable: false },
      { text: "Agente", value: "id_agent_app", sortable: false },
      { text: "Player", value: "id_player_app", sortable: false },
      { text: "Tipo", value: "type_transaction", sortable: false },
      { text: "Valor", value: "value", sortable: false }, // soma valor
      { text: "Suspeita", value: "suspect", sortable: false },
      {
        text: "Tipo Suspeita ( G - J )",
        value: "type_suspect",
        sortable: false,
      },
    ],
  }),
  watch: {
    pagination: {
      handler() {
        if (this.filtered) {
          if(this.current_tab == 'periods_closure_transactions') {
            this.getItems({ 
              end_point: 'periods_closure_transactions',
              extend_url: '',
              resetPage: false
            })
          }
        }
      },
    },
  },
};
</script>
