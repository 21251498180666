import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../../config/endPoints';

const defaultChipsTransfer = {
    name_pppoker: '',
    id_pppoker: '',
    id_agent: '',
    balance: 0,
    name_supreme: '',
    id_supreme: '',
    chips: 0,
    status: 0,
};

const spreadsheet_headers_config_import = [
    { database_field: "Club", description: "Clube", column_sheet: 0, type: "int" },
    { database_field: "IdPppoker", description: "Id do Jogador", column_sheet: 1, type: "string" },
    { database_field: "Nick", description: "Nick", column_sheet: 4, type: "string" },
    { database_field: "IdPppokerAgent", description: "Id do Agente", column_sheet: 6, type: "string" },
    { database_field: "PlayerGains", description: "Total de Ganhos do Jogador", column_sheet: 26, type: "float" },
    { database_field: "PlayerTax", description: "Total de Rake do Jogador", column_sheet: 45, type: "float" },
    { database_field: "PlayerSpin", description: "Total de Spin Up", column_sheet: 46, type: "float" },
    { database_field: "HandsPlayed", description: "Mãos Jogadas", column_sheet: 66, type: "int" }
]

export default {
    namespaced: true,
    state: {
        type_import: 1,
        dialog: false,
        dialogSpreadsheetTransfer: false,
        dialogSupreme: false,
        dialogDelete: false,
        dialogFilter: false,
        dialogReceipt: false,
        editedChipsTransfer: {},
        filter: {
            name_pppoker: '',
            id_pppoker: '',
            id_agent: '',
            balance: '',
            name_supreme: '',
            id_supreme: '',
            chips: '',
            status: '',
            club_origin: '',
            newagent_password: ''
        },
        instances: [],
        chipsTransfers: [],
        item_spreadsheet: {},
        spreadsheet_headers_config_import: spreadsheet_headers_config_import,
        transferToDelete: {},
        loading: false,
        mode: 'LIST',
        overlay: false,
        page: 1,
        pagination: {
            page: 1,
            itemsPerPage: 10
        },
        successNotification: false,
        notificationMessage: '',
        notificationType: "success",
        receipt_show: '',

        selectedContact: {},
        selected_spreadsheet: null,
        showNotification: false,
        totalChipsTransfers: 0,
        rowsPerPage: 10,
        menus: []
    },
    mutations: {
        add: (state, payload) => {
            state.mode = "ADD"
            state.editedChipsTransfer = Object.assign({}, defaultChipsTransfer)
            state.screen = payload.screen
            if (state.screen != "messages") {
                state.dialog = true
            }
        },
        edit: (state, payload) => {
            state.editedChipsTransfer = Object.assign({}, payload.item)
            state.mode = "EDIT"
            state.dialog = true
        },
        registerSupreme: (state, payload) => {
            state.editedChipsTransfer = Object.assign({}, payload.item)
            state.dialogSupreme = true
            state.mode = "EDIT"
        },
        closeDialog: (state) => {
            state.editedChipsTransfer = {}
            state.editedIndex = -1
            state.errors = [];
            state.dialog = false
            state.mode = "LIST"
        },
        closeDialogSupreme: (state) => {
            state.editedChipsTransfer = {}
            state.editedIndex = -1
            state.errors = [];
            state.dialogSupreme = false
            state.mode = "LIST"
        },
        loading: (state, payload) => state.loading = payload.show,
        setMode: (state, mode) => state.mode = mode,
        setChipsTransfers: (state, payload) =>  {
            state.chipsTransfers = payload
            console.log(state.chipsTransfers)
        },
        setClearFilter: (state) => 
            state.filter = {
                name_pppoker: '',
                id_pppoker: '',
                id_agent: '',
                balance: '',
                name_supreme: '',
                id_supreme: '',
                chips: '',
                status: '',
                club_origin: '',
                newagent_password: '',
            },
        setChipsTransferToDelete: (state, transfer) => state.transferToDelete = transfer,
        setTotalChipsTransfers: (state, total) =>  state.totalChipsTransfers = total,
        setRowsPerPage: (state, rowsPerPage) => state.rowsPerPage = rowsPerPage,
        showDialog: (state, show) => state.dialog = show,
        setPage: (state, payload) => {
            state.pagination.page = payload.page;
            state.pagination.itemsPerPage = payload.itemsPerPage
        },
        setScreen: (state, payload) => state.screen = payload.screen,
        setUsersName: (state, payload) => {
            let users_name = []
            payload.users.forEach(user => {
                users_name[user.id] = user.name
            });
        },
        setMenus: (state, payload) => {
            state.menus = payload.menus
        },
        setHeadersSpreadsheet: (state, payload) => {
            state.spreadsheet_headers_config_import = payload.headers
        },
        setDialogDelete: (state, show) => state.dialogDelete = show,
        setDialogReceipt: (state, payload) => state.dialogReceipt = payload.show,
        setDialogSpreadsheetTransfer: (state, payload) => state.dialogSpreadsheetTransfer = payload.show,
        setItemSpreadsheet: (state, payload) => state.item_spreadsheet = payload.item,
        setSelectedSpreadsheet: (state, payload) => state.selected_spreadsheet = payload.spreadsheet,
        setItemsSpreadsheet: (state, payload) => state.spreadsheet_period = payload.items,
        setInstances: (state, payload) => state.instances = payload.instances,
        showFilter: (state, payload) => state.dialogFilter = payload.show,
        showErrorNotification: (state, show) => state.errorNotification = show,
        showSuccessNotification: (state, show) => state.successNotification = show,
        showLoading: (state, show) => state.loading = show,
        showNotification: (state, payload) => {
            state.showNotification = payload.show,
                state.notificationMessage = payload.message,
                state.notificationType = payload.type
        },
        showOverlay: (state, payload) => {
            state.overlay = payload.show
        },
        setReceipt: (state, payload) => state.receipt_show = payload.receipt,
        viewChipsTransfer(state, transfer) {
            state.editedChipsTransfer = transfer
            state.editedIndex = state.chipsTransfers.indexOf(transfer)
            state.dialog = true
            state.mode = "VIEW"
        },
        updateField
    },
    getters: {
        getField,
    },
    actions: {
        closeDialog: (({ commit, state }) => {
            state.editedChipsTransfer = {}
            if (state.screen != "messages") {
                commit('message/setSelectedMessages', { messages: [] }, { root: true })
                commit('showDialog', false)
            } else {
                commit('message/showDialogFail', { show: false }, { root: true })
            }
            setTimeout(() => {
                commit('closeDialog')
                commit('showErrorNotification', false)
                commit('showSuccessNotification', false)
            }, 300);
        }),
        closeDialogSupreme: (({ commit, state }) => {
            state.editedChipsTransfer = {}
            if (state.screen != "messages") {
                commit('message/setSelectedMessages', { messages: [] }, { root: true })
                commit('showDialog', false)
            } else {
                commit('message/showDialogFail', { show: false }, { root: true })
            }
            setTimeout(() => {
                commit('closeDialogSupreme')
                commit('showErrorNotification', false)
                commit('showSuccessNotification', false)
            }, 300);
        }),
        closeDeleteDialog: ({ commit }) => {
            commit('setDialogDelete', false)
            commit('setChipsTransferToDelete', {})
        },
        deleteChipsTransfer: ({ dispatch, commit, state }) => {
            commit('loading', true)
            axios.delete(endPoints.chips_transfer + "/deleteChipsTransfer/" + state.transferToDelete.Id, state.transferToDelete).then(() => {
                dispatch('closeDeleteDialog')
                commit('loading', false)
                let notification = {
                    show: true,
                    message: "Transferência excluida com sucesso",
                    type: "success"
                }
                commit('showNotification', notification)
                return dispatch('getChipsTransfers', { resetPage: false });
            });
        },
        getChipsTransfers: ({ commit, state }, payload) => {
            commit('loading', true)
            let url = endPoints.chips_transfer;
            let filter = Object.assign({}, state.filter)
            if (payload.resetPage === true) {
                url += '?page=' + 1 + '&' + 'itemsPerPage=' + 10;
                commit('setPage', { page: 1, itemsPerPage: 10 })
            } else {
                url += '?page=' + state.pagination.page + '&' + 'itemsPerPage=' + state.pagination.itemsPerPage;
            }

            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
                    url += "&" + field + "=" + filter[field]
                }
            });

            axios.get(url).then(transfers => {
                console.log(transfers.data.data)
                commit('setTotalChipsTransfers', transfers.data.total)
                commit('loading', false)
                commit('setChipsTransfers', transfers.data.data)
            });
        },
        importSpreadsheet: ({ commit, dispatch, state }) => {
            let url = endPoints.chips_transfer + "/transfer_spreadsheet"
            let formData = new FormData();
            let headers = [];
            state.spreadsheet_headers_config_import.forEach((h) => {
                h.column_spreadsheet = parseInt(h.column_spreadsheet)
                headers.push(h)
            })
            formData.append('file', state.selected_spreadsheet);
            formData.append('headers', JSON.stringify(headers))
            formData.append('start_line', state.start_line)
            formData.append('type_import', state.type_import)
            commit('showOverlay', { show: true })
            axios.post(url, formData).then((response) => {
                let errors = response.data.errors
                if (errors) {
                    if (errors.length != 0) {
                        let errors_period = []
                        errors.forEach(e => { errors_period.push({ error: e }) })
                        commit('setErrorsPeriod', { errors: errors_period, show: true })
                    }
                }
                commit('showOverlay', { show: false })
                let notification = {
                    show: true,
                    message: "Planilha importada com sucesso.",
                    type: "success"
                }
                commit('setSelectedSpreadsheet', { spreadsheet: null })
                commit('showNotification', notification)
                dispatch('getSpreadsheetClosure')
                dispatch('getItems')
            }).catch(() => {
                let notification = {
                    show: true,
                    message: "Ocorreu um erro ao importar a planilha. Contate o administrador do sistema.",
                    type: "error"
                }
                commit('overlay', { show: false })
                commit('showNotification', notification)
            });
        },
        finishTransfer: ({ dispatch, state }, payload) => {
            state.editedChipsTransfer = Object.assign({}, payload.transfer)
            state.editedIndex = state.chipsTransfers.indexOf(payload.transfer)
            state.editedChipsTransfer.status = 2
            state.mode = "EDIT"
            state.screen = payload.screen
            dispatch('save', { close: false })
        },
        finishTransferBot: ({ dispatch, state }, payload) => {
            state.editedChipsTransfer = Object.assign({}, payload.transfer)
            state.editedIndex = state.chipsTransfers.indexOf(payload.transfer)
            state.editedChipsTransfer.status = 2
            state.mode = "EDIT"
            state.screen = payload.screen
            dispatch('orderBot', { close: false })
        },
        backTransfer: ({ dispatch, state }, payload) => {
            state.editedChipsTransfer = Object.assign({}, payload.transfer)
            state.editedIndex = state.chipsTransfers.indexOf(payload.transfer)
            state.editedChipsTransfer.status = 1
            state.mode = "EDIT"
            state.screen = payload.screen
            dispatch('save', { close: false })
        },
        getChildrenSearch: ({ commit }, payload) => {
            let url = endPoints.menus + '/children_search';
            url += '/' + payload.search

            axios.get(url).then(menus => {
                commit('setMenus', { menus: menus.data })
            });
        },
        getChipsTransfersNoPage: ({ state }) => {
            let url = endPoints.chips_transfer;
            let filter = Object.assign({}, state.filter);
            url += '?page=' + '1' + '&' + 'chipsTransfersPerPage=' + '99999';

            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined) {
                    url += "&" + field + "=" + filter[field]
                }
            });
            return axios.get(url).then(tranfers => {
                return tranfers.data.data
            });
        },
        showDeleteDialog: ({ commit }, transfer) => {
            commit('setDialogDelete', true)
            commit('setChipsTransferToDelete', transfer)
        },
        showDialogSpreadsheetTransfer: ({ commit }) => {
            let url = endPoints.chips_transfer + "/spreadsheet_transfer";
            axios.get(url).then(items => {
                commit('setHeadersSpreadsheet', { headers: items.data.headers })
                commit('setItemsSpreadsheet', { items: items.data.data })
                commit('setDialogSpreadsheetTransfer', { show: true })
            });
        },
        save: async({ dispatch, commit, state }) => {
            var url = endPoints.chips_transfer;
            let func = '';
            let data =  Object.assign({}, state.editedChipsTransfer)
            data.chips = parseFloat(data.chips)
            data.balance = parseFloat(data.balance)

            commit('showOverlay', { show: true })
            if (state.mode == 'ADD') {
                func = axios.post;
                url = endPoints.chips_transfer;
                data.club_origin = data.club_origin.toString()
                data.club_destiny = data.club_origin
            } else {
                func = axios.put;
                url += '/updateChipsTransfer/' + data.Id;
            }

            func(url, data).then(
                () => {
                    dispatch('getChipsTransfers', { resetPage: true })
                    let notification = {
                        show: true,
                        message: "Transferência salva com sucesso",
                        type: "success"
                    }
                    commit('loading', { show: false })
                    commit('showOverlay', { show: false })
                    if (state.screen != 'messages') {
                        commit('showNotification', notification)
                        commit('closeDialog')
                        commit('closeDialogSupreme')
                        commit('showOverlay', { show: false })
                    } else {
                        commit('closeDialog')
                        commit('closeDialogSupreme')
                        commit('message/showDialogFail', { show: false }, { root: true })
                        commit('message/showNotification', notification, { root: true })                        
                    }
                },
                error => {
                    let notification = {
                        show: true,
                        message: error.response.data.message,
                        type: "error"
                    }
                    commit('showNotification', notification)
                    commit('showOverlay', { show: false })
                }
            );
        },
        orderBot: async({ dispatch, commit, state }) => {
            let data =  Object.assign({}, state.editedChipsTransfer)
            data.chips = parseFloat(data.chips)
            data.balance = parseFloat(data.balance)

            commit('showOverlay', { show: true })

            let url = endPoints.chips_transfer + '/updateChipsTransferBot/' + data.Id;
            axios.put(url, data).then(
                () => {
                    dispatch('getChipsTransfers', { resetPage: true })
                    let notification = {
                        show: true,
                        message: "Transferência com o BOT",
                        type: "success"
                    }
                    commit('loading', { show: false })
                    commit('showOverlay', { show: false })
                    commit('showNotification', notification)
                    commit('closeDialog')
                    commit('closeDialogSupreme')
                    commit('showOverlay', { show: false })
                },
                error => {
                    let notification = {
                        show: true,
                        message: error.response.data.message,
                        type: "error"
                    }
                    commit('showNotification', notification)
                    commit('showOverlay', { show: false })
                }
            );
        },
        getItemsNoPage: ({ commit, state }) => {
            commit('showOverlay', { show: true })
            let url = endPoints.chips_transfer;
            let filter = state.filter;
            url += '?page=' + '1' + '&' + 'itemsPerPage=' + '99999';

            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined) {
                    if (field == "end" || field == "start") {
                        var e = new Date(filter[field])

                        url += "&" + field + "=" + e.toISOString().substr(0, 10) + " " + e.toLocaleTimeString()

                    } else {
                        url += "&" + field + "=" + filter[field]
                    }
                }
            });
            return axios.get(url).then(items => {
                commit('showOverlay', { show: false })
                return items.data.data
            }).catch(() => {
                commit('showOverlay', { show: false })
            });
        },
    }
}