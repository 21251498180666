<template>
  <div>
    <v-toolbar flat class="elevation-5">
        <v-toolbar-title>Relatório de Agentes</v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- <download-excel
            :before-generate="generateSheet"
            :before-finish="finishSheet"
            :name="getSheetName()"
            :fetch="getItemsNoPage"
            :fields="sheet_fields"
            class="excel"
            worksheet = "Agentes"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn  v-bind="attrs" v-on="on"  color="black" dark class="ma-2" small>
                <v-icon>mdi-file-excel</v-icon>
                </v-btn> 
            </template>
            <span>Gerar Planilha</span>
          </v-tooltip>
        </download-excel> -->
        <v-tooltip bottom>
          <template v-slot:activator="{on, attrs}">
            <v-btn color="black" dark class="ma-2" @click="downloadXlsx({ show: true })" v-bind="attrs" v-on="on" small>
              <v-icon>mdi-file-excel</v-icon>
            </v-btn>
          </template>
          <span>Gerar Planilha</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" color="black" dark class="ma-2" @click="showFilter({show: true})" small>
              <v-icon>mdi-filter</v-icon>
              </v-btn>
          </template>
          <span>Filtrar</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" color="black" dark class="ma-2" @click="getItems({resetPage: false})" small>
              <v-icon>mdi-refresh</v-icon>
              </v-btn>
          </template>
          <span>Recarregar</span>
        </v-tooltip>
    </v-toolbar>

    <v-dialog :value="dialogFilter" @click:outside="showFilter({show: false})" width="600" @keydown.enter="filter">
      <report-agents-filter></report-agents-filter>
    </v-dialog>

    <v-data-table
        :headers="headers"
        :loading="loading"
        :items="items"
        :options.sync="pagination"
        :server-items-length="totalItems"    
        no-data-text="Nenhuma registro encontrado"
        class="elevation-10 table-margin"
    >
      <template v-slot:[`item.total_gains`]="{ item }">
        <span>{{ (item.total_gains) ? item.total_gains.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "R$ 0,00" }}</span>
      </template>
      <template v-slot:[`item.total_rake`]="{ item }">
        <span>{{ (item.total_rake) ? item.total_rake.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "R$ 0,00" }}</span>
      </template>
      <template v-slot:[`item.total_rakeback`]="{ item }">
        <span>{{ (item.total_rakeback) ? item.total_rakeback.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "R$ 0,00" }}</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'
//import JsonExcel from 'vue-json-excel'
import ReportAgentsFilter from './ReportAgentsFilter'
import { utils, writeFile } from "xlsx";

export default {
  created() {
    this.getItems({resetPage: false})
    this.getInstances()
  },
  computed: {
    ...mapFields('report_agents_rake', [
      'pagination'
    ]),
    ...mapState('report_agents_rake', ['dialogFilter', 'items', 'loading', 'totalItems'])
  },
  components: {
    // "download-excel": JsonExcel,
    "report-agents-filter": ReportAgentsFilter
  },
  data: () => ({
    headers: [
      { text: "Clube", value: "club_name", sortable: true },
      { text: "Id Suprema", value: "id_pppoker", sortable: false },
      { text: "Nick do Agente", value: "nick", sortable: false },
      { text: "A. Ativos", value: "active_agents", sortable: true },
      { text: "J. Ativos", value: "active_players", sortable: true },
      { text: "Ganhos", value: "total_gains", sortable: true },
      { text: "Rake Gerado", value: "total_rake", sortable: true },
      { text: "Rakeback", value: "total_rakeback", sortable: true },
      { text: "Ações", value: "actions", sortable: false }
    ],
    sheet_fields: {
      Clube: {
        field: 'club_name',
      },
      'Id PPPoker': {
        field: 'id_pppoker',
      },
      Nick: {
        field: 'nick',
      },
      'Agentes Ativos (Média)': {
        field: 'active_agents',
      },
      'Jogadores Ativos (Média)': {
        field: 'active_players',
      },
      'Ganhos/Perdas': {
        field: "total_gains",
        callback: (value) => {
          if (value) {
            let real = value.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
            if(value < 0 ) {
                return real.substr(4);
              }
            return real.substr(3);
          }
          return 0;
        },
      },
      'Rake Gerado': {
        field: "total_rake",
        callback: (value) => {
          if (value) {
            let real = value.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
            if(value < 0 ) {
                return real.substr(4);
              }
            return real.substr(3);
          }
          return 0;
        },
      },
      'Rakeback': {
        field: "total_rakeback",
        callback: (value) => {
          if (value) {
            let real = value.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
            if(value < 0 ) {
                return real.substr(4);
              }
            return real.substr(3);
          }
          return 0;
        },
      },
    }
  }),
  methods: {
    ...mapMutations('report_agents_rake', ['showFilter']),
    ...mapActions('agents', ["getInstances"]),
    ...mapActions('report_agents_rake', ['getItems', 'getItemsNoPage']),
    finishSheet() {
      this.overlay = false
    },
    generateSheet() {
      this.overlay = true
    },
    getSheetName() {
      var date = new Date()
      var name = 'relatorio_rake_agentes_' + date.toLocaleDateString() + " " + date.toLocaleTimeString()
      return name+'.xls'
    },
    async downloadXlsx() {
        this.generateSheet()
        try {
          let itemsNoPage = await this.getItemsNoPage();
          if(itemsNoPage) {
            const wb = utils.book_new();
            let items = itemsNoPage.map(row => ({
              "Clube":  row.club_name,
              "Id PPPoker":  row.id_pppoker,
              "Nick": row.nick,
              "Agente Ativos (Média)": row.active_agents,
              "Jogadores Ativos (Média)": row.active_players,
              "Ganhos/Perdas": this.totalGainsXLSX(row.total_gains),
              "Rake Gerado": this.totalGainsXLSX(row.total_rake),
              "Rakeback": this.totalGainsXLSX(row.total_rakeback),
            }))
            const ws = utils.json_to_sheet(items);
            utils.book_append_sheet(wb, ws, "Agentes");
            await writeFile(wb, this.getSheetName());
          }
        } catch (error) {
          this.finishSheet()
        } finally {
          this.finishSheet()
        }
      },
      totalGainsXLSX: (value) => {
        if (value) {
          let real = value.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          });
          if(value < 0 ) {
              return real.substr(4);
            }
          return real.substr(3);
        }
        return 0;
      },
  },
  watch: {
    pagination: {
      handler() {
        this.getItems({ resetPage: false });
      },
    }
  }
}
</script>