const defaultFail = {
    description: '',
    date: '',
    date_picker: new Date(),
    type: 0,
    status: 0,
    balance: 0,
    observation: '',
    value: 0,
    user_id: null,
    id_agent: '',
};

export default defaultFail