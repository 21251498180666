const defaultItem = {
    "id": null,
    "name": "",
    "birth_date": "",
    "email": "",
    "player_id": "",
    "know_by": null,
    "contact_id": "",
    "contact_name": "",
    "date_created": "",
    "club": null,
    "club_name": "",
    "know_by_description": "",
    "pppoker_accepted": false,
};

export default defaultItem