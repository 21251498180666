import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../config/endPoints';

const defaultInstance = {
    automatic_pix: null,
    cg_id: "",
    cg_name: "",
    id: "",
    id_pppoker: "",
    id_league: "",
    name: "",
    nick: "",
    token: "",
    phone: "",
    is_club: false,
    base_url: "",
    preferred_bank: null
};

export default {
    namespaced: true,
    state: {
        dialog: false,
        dialogDelete: false,
        editedInstance: {},
        errors: [],
        instances: [],
        leagues: [],
        instanceToDelete: {},
        loading: false,
        mode: 'LIST',
        notificationMessage: '',
        notificationType: '',
        page: 1,
        pagination: {
            page: 1,
            itemsPerPage: 100
        },
        showNotification: false,
        totalInstances: 0,
        rowsPerPage: 100
    },
    mutations: {
        addInstance: (state) => {
            state.dialog = true
            state.mode = "ADD"
            state.editedInstance = Object.assign({}, defaultInstance)
        },
        editInstance: (state, item) => {
            state.editedInstance = Object.assign({}, item)
            state.editedIndex = state.instances.indexOf(item)
            state.dialog = true
            state.mode = "EDIT"
        },
        closeDialog: (state) => {
            state.editedInstance = Object.assign({}, defaultInstance)
            state.editedIndex = -1
            state.errors = [];
        },
        loading: (state, loading) => state.loading = loading,
        setMode: (state, mode) => state.mode = mode,
        setInstances: (state, instances) => state.instances = instances,
        setLeagues: (state, leagues) => state.leagues = leagues,
        setInstanceToDelete: (state, instance) => state.instanceToDelete = instance,
        setTotalInstances: (state, total) => state.totalInstances = total,
        setRowsPerPage: (state, rowsPerPage) => state.rowsPerPage = rowsPerPage,
        showDialog: (state, show) => state.dialog = show,
        setDialogDelete: (state, show) => state.dialogDelete = show,
        showLoading: (state, show) => state.loading = show,
        updateField,
        showNotification: (state, payload) => {
            state.showNotification = payload.show,
                state.notificationMessage = payload.message,
                state.notificationType = payload.type
        },
        viewInstance(state, item) {
            state.editedInstance = item
            state.editedIndex = state.instances.indexOf(item)
            state.dialog = true
            state.mode = "VIEW"
        }
    },
    getters: {
        getField,
        getClubs: (state, _, __, rootGetters) => {
            let instanceId = rootGetters["login/getInstanceId"]
            let instances = []

            if (instanceId != "" && instanceId != null && instanceId != undefined) {
                instances = state.instances.filter((i) => i.id == instanceId)
                return instances
            } else {
                return state.instances
            }
        },
        instancesChat: (state) => {
            return state.instances.filter((i) => i.id != "" && i.token != "")
        },
        getInstances: (state) => {
            return state.instances
        }
    },
    actions: {
        closeDialog: (({ commit }) => {
            commit('showDialog', false)
            commit('closeDialog')
        }),
        closeDeleteDialog: ({ commit }) => {
            commit('setDialogDelete', false)
            commit('setInstanceToDelete', {})
        },
        deleteInstance: ({ dispatch, commit, state }) => {
            commit('loading', true)
            axios.delete(endPoints.instances + "/" + state.instanceToDelete.id_instance, state.instanceToDelete).then(() => {
                dispatch('closeDeleteDialog')
                let notification = {
                    show: true,
                    message: "Instância excluída com sucesso.",
                    type: "success"
                }
                commit('showNotification', notification)
                return dispatch('getInstances');
            });
        },
        getInstances: ({ commit, state }) => {

            commit('loading', true)
            let url = endPoints.instances;
            url += '?page=' + state.pagination.page + '&' + 'itemsPerPage=' + 150;
            axios.get(url).then(instances => {
                commit('setTotalInstances', instances.data.total)
                commit('loading', false)
                commit('setInstances', instances.data.data)
            });
        },
        getLeagues: ({ commit }) => {
            commit('loading', true)
            let url = endPoints.league;
            url += '?page=1' + '&' + 'itemsPerPage=999';
            axios.get(url).then(leagues => {
                commit('loading', false)
                commit('setLeagues', leagues.data.data)
            });
        },
        showDeleteDialog: ({ commit }, item) => {
            commit('setDialogDelete', true)
            commit('setInstanceToDelete', item)
        },
        saveInstance: ({ dispatch, commit, state }) => {
            var url = endPoints.instances;
            let func = "";
            if (state.mode === "ADD") {
                func = axios.post;
                url = endPoints.instances;
            } else {
                func = axios.put;
                url += '/' + state.editedInstance.id_instance;
            }

            func(url, state.editedInstance).then(
                () => {
                    dispatch('getInstances')
                    let notification = {
                        show: true,
                        message: "Instância salva com sucesso.",
                        type: "success"
                    }
                    dispatch('closeDialog')
                    commit('showNotification', notification)
                },
                error => {
                    let notification = {
                        show: true,
                        message: error.response.data.message,
                        type: "error"
                    }
                    commit('showNotification', notification)
                }
            );
        },
        updateContactList({ commit }, item) {
            axios.put(endPoints.contact_list + "/old_update/" + item.id, item).then(() => {
                let notification = {
                    show: true,
                    message: "Lista de contatos atualizada com sucesso.",
                    type: "success"
                }
                commit('showNotification', notification)
            })
        },
        newUpdateContactList({ commit }, item) {
            try {
                axios.put(endPoints.contact_list + "/new_update/" + item.id, item).then(() => {
                    let notification = {
                        show: true,
                        message: "Lista de contatos atualizada com sucesso.",
                        type: "success"
                    }
                    commit('showNotification', notification)
                })
            } catch (error) {
              let notification = {
                  show: true,
                  message: "Erro ao atualizar lista de contatos.",
                  type: "error"
              }
              commit('showNotification', notification)
            }
        }
    }
}