<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2000"
      v-model="showNotification"
    >{{ notificationMessage }}</v-snackbar>
    <v-toolbar flat class="elevation-5">
      <div>
        <v-toolbar-title>Controle Perdas e Ganhos</v-toolbar-title>
      </div>
      <v-spacer></v-spacer>
      <div class="button-toolbar">
        <!-- <download-excel
          :before-generate="generateSheet"
          :before-finish="finishSheet"
          :name="getSheetName()"
          :fetch="getItemsNoPage"
          :export-fields="sheet_fields"
          class="excel"
          worksheet="Perdas e Ganhos"
          type="xls"
        >
          <v-btn color="black" dark class="ma-2" small>
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </download-excel> -->
        <v-tooltip bottom>
          <template v-slot:activator="{on, attrs}">
            <v-btn color="black" dark class="ma-2" @click="downloadXlsx({ show: true })" v-bind="attrs" v-on="on" small>
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </template>
          <span>Gerar Planilha</span>
        </v-tooltip>
        <v-btn color="black" dark class="ma-2" @click="showFilter({show: true})" small>
          <v-icon>mdi-filter</v-icon>
        </v-btn>
        <v-btn color="black" dark class="ma-2" @click="getItems" small>
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-btn color="black" dark class="ma-2" @click="add" small>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :loading="loading"
      :items="items"
      :options.sync="pagination"
      :server-items-length="totalItems"
      :item-class= "getClassColor"                  
      no-data-text="Nenhuma registro encontrado"
      class="elevation-10 table-margin"
    >

      <template v-slot:[`item.player_gains`]="{ item }">
        <span>{{ item.player_gains.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
      </template>
      <template v-slot:[`item.tax`]="{ item }">
        <span>{{ item.tax.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
      </template>
      <template v-slot:[`item.player_gains_evolution`]="{ item }">
        <span>{{ item.player_gains_evolution.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
      </template>
      <template v-slot:[`item.tax_evolution`]="{ item }">
        <span>{{ item.tax_evolution.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
      </template>
      <template v-slot:[`item.date`]="{ item }">
        <span>{{ getDate(item.date)}}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="viewItem(item)">mdi-information</v-icon>
        <v-icon small class="mr-2" @click="edit({item: item})">mdi-pencil</v-icon>
        <v-icon
          small
          class="mr-2"
          @click="showDeleteDialog(item)"
        >mdi-delete</v-icon>
        <v-icon small class="mr-2" v-if="item.verified == 0 && profileAccess()" @click="verifyItem({item: item})">mdi-check</v-icon>
      </template>
    </v-data-table>

    <v-dialog :value="dialog" @click:outside="closeDialog()" width="800">
      <gain-loses-form></gain-loses-form>
    </v-dialog>

    <v-dialog :value="dialogFilter" width="600" @click:outside="showFilter({show: false})" @keydown.enter="filter">
      <gain-loses-filter></gain-loses-filter>
    </v-dialog>

    <v-dialog :value="dialogDelete"  @click:outside="closeDeleteDialog" @keydown.enter="deleteItem" width="300">
      <v-card>
        <v-card-title class="headline">Confirmar a exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn text @click="closeDeleteDialog()">Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn text type="submit" right @click="deleteItem">Confirmar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import GainsLosesForm from "./GainsLosesForm";
import GainsLosesFilter from "./GainsLosesFilter";
// import JsonExcel from "vue-json-excel";
import { utils, writeFile } from "xlsx";

export default {
  name: "GainsLoses",
  computed: {
    ...mapState("login", ["userData"]),
    ...mapState("gains_loses", [
      "dialog",
      "dialogDelete",
      "item",
      "instances",
      "errors",
      "loading",
      "mode",
      "notificationType",
      "notificationMessage",
      "rowsPerPage",
      "totalItems",
      "items",
    ]),
    ...mapFields("gains_loses", [
      "pagination",
      "dialogFilter",
      "overlay",
      "showNotification",
      // ...
    ]),
  },
  created() {
    this.getItems()
    this.getInstances();
  },
  components: {
    // "download-excel": JsonExcel,
    "gain-loses-form": GainsLosesForm,
    "gain-loses-filter": GainsLosesFilter,
  },
  methods: {
    ...mapActions("instances", ["getInstances"]),
    ...mapMutations("gains_loses", [
      "add",
      "closeDialog",
      "edit",
      "viewItem",
      "showFilter"
    ]),
    ...mapActions("gains_loses", [
      "closeDeleteDialog",
      "deleteItem",
      "getItems",
      "getItemsNoPage",
      "showDeleteDialog",
      "verifyItem"
    ]),
    filter() {
      this.getItems({ resetPage: true });
      this.showFilter({ show: false });
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      }
      return false;
    },
    getDate(date) {
      if (date) {
        var d = new Date(date);
        return d.toLocaleDateString() + " " + d.toLocaleTimeString();
      }
      return "";
    },
    finishSheet() {
      this.overlay = false;
    },
    generateSheet() {
      this.overlay = true;
    },
    getSheetName() {
      var date = new Date();
      var name =
        "ganhos_perdas_" +
        date.toLocaleDateString() +
        " " +
        date.toLocaleTimeString();
      return name + ".xls";
    },
    async downloadXlsx() {
      this.generateSheet()
      try {
        let itemsNoPage = await this.getItemsNoPage()
        if(itemsNoPage) {
          const wb = utils.book_new()
            let items = itemsNoPage.map(row => ({
              "Club":  row.club_name,
              "Ganhos do Jogador":  this.dotToCommaXLSX(row.player_gains),
              "Taxa":  this.dotToCommaXLSX(row.tax),
              "Data":  this.dateXLSX(row.date),
              "Criado Por":  row.created_user_name,
              "Criado Em":  this.dateXLSX(row.date_created),
              "Dif. Ganhos":  this.dotToCommaXLSX(row.player_gains_evolution),
              "Dif. Taxas":  this.dotToCommaXLSX(row.tax_evolution),
            }))
          const ws = utils.json_to_sheet(items)
          utils.book_append_sheet(wb, ws, "Ganhos")
          await writeFile(wb, this.getSheetName())
        }
      } catch (error) {
        this.finishSheet()
      } finally {
        this.finishSheet()
      }
    },
    dotToCommaXLSX: (value) => {
      if (value) {
        return value.toString().replace(".", ",")
      }
      return 0;
    },
    dateXLSX: (value) => {
      if(value) {
        var d = new Date(value);
        return d.toLocaleDateString();
      }
    },
    valueXLSX: (value) => {
      if (value) {
        let real = value.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });
        return real.substr(3);
      }
      return 0;
    },
    getClassColor(item) {
      if(item.verified == 0) {
        return 'row-red'
      } else {
        return 'row-green'
      }
    },
    profileAccess() {
      if(this.userData.role > 90 || this.userData.role == 2 ) {
        return true
      } else {
        return false
      }
    },
  },
  data: () => ({
    build_fields: {},
    expanded: [],
    headers: [
      { text: "Clube", value: "club_name", sortable: false },
      { text: "Ganhos do Jogador", value: "player_gains", sortable: false },
      { text: "Taxa", value: "tax", sortable: false },
      { text: "Data", value: "date", sortable: false},
      { text: "Criado Por", value: "created_user_name", sortable: false },
      { text: "Dif. Ganhos", value: "player_gains_evolution", sortable: false },
      { text: "Dif. Taxa", value: "tax_evolution", sortable: false },
      { text: "Ações", value: "actions", sortable: false, align: "center" },
    ],
    sheet_fields: {
      Club: {
        field: "club_name"
      },
      "Ganhos do Jogador": {
        field: "player_gains",
        callback: (value) => {
          if (value) {
            return value.toString().replace(".", ",")
          }
          return 0;
        },
      },
      "Taxa": {
        field: "tax",
        callback: (value) => {
          if (value) {
            return value.toString().replace(".", ",")
          }
          return 0;
        },
      },
      Data: {
        field: "date",
        callback: (value) => {
          if(value) {
            var d = new Date(value);
            return d.toLocaleDateString();
          }
        },
      },
      "Criado Por": {
        field: "created_user_name",
      },
      "Criado Em": {
        field: "date_created",
        callback: (value) => {
          if(value) {
            var d = new Date(value);
            return d.toLocaleDateString();
          }
        },
      },
      "Dif. Ganhos": {
        field: "player_gains_evolution",
        callback: (value) => {
          if (value) {
            return value.toString().replace(".", ",")
          }
          return 0;
        },
      },
      "Dif. Taxas": {
        field: "tax_evolution",
        callback: (value) => {
          if (value) {
            return value.toString().replace(".", ",")
          }
          return 0;
        },
      },
    },
    valid: false,
  }),
  watch: {
    pagination: {
      handler() {
        this.getItems({ resetPage: false });
      },
    },
  },
};
</script>
