<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar
      :color="notification.type"
      right
      top
      :timeout="2000"
      v-model="notification.show"
    >
      {{ notification.message }}
    </v-snackbar>

    <v-toolbar flat class="ma-2 mb-5 elevation-6 px-3">
      <div>
        <v-toolbar-title>Clientes</v-toolbar-title>
      </div>
      <v-spacer></v-spacer>
      <div class="button-toolbar">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="#333"
              dark
              class="ma-2"
              @click="setShowFilter(true)"
              v-bind="attrs"
              v-on="on"
              small
            >
              <v-icon color="white">mdi-filter</v-icon>
            </v-btn>
          </template>
          <span>Filtro</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="#333"
              dark
              class="ma-2"
              @click="getItems"
              v-bind="attrs"
              v-on="on"
              small
              ><v-icon>mdi-refresh</v-icon></v-btn
            >
          </template>
          <span>Recarregar</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="#333"
              dark
              class="ma-2"
              v-bind="attrs"
              v-on="on"
              @click="add()"
              small
              ><v-icon>mdi-plus</v-icon></v-btn
            >
          </template>
          <span>Adicionar Venda</span>
        </v-tooltip>
      </div>
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :loading="loading"
      :items="items"
      :options.sync="pagination"
      :server-items-length="totalItems"
      class="elevation-6 ma-2 table-margin px-6 pt-3 row-pointer"
      :footer-props="{
        'items-per-page-options': [10, 20, 50, 100],
        'items-per-page-text': 'Itens por página',
        'show-first-last-page': true,
        'page-text': `Página ${pagination.page} de ${parseInt(totalItems/pagination.itemsPerPage) + 1}`
      }" 
      no-data-text="Nenhum cliente encontrado"
    >
    
      <template v-slot:[`item.type`]="{ item }">
        <span>{{ item.type == 0 ? "Pessoa Física" : "Pessoa Juridica" }} </span>
      </template>
      
      <template v-slot:[`item.inscription`]="{ item }">
        <span>{{ getCpf(item.inscription) }}</span>
      </template>

      <template v-slot:[`item.contact`]="{ item }">
        <span>{{ getContact(item.contact) }}</span>
      </template>

      <template v-slot:[`item.situation`]="{ item }">
        <span>{{ getSituation(item.situation) }}</span>
      </template>


      <template v-slot:[`item.actions`]="{ item }" v-align="right">
        <v-tooltip bottom>
          <template v-slot:activator="{ attrs, on }">
            <v-icon
              small
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="viewItem(item)"
              >mdi-information</v-icon
            >
          </template>
          <span>Informações do Cliente</span>
        </v-tooltip>

        <v-tooltip bottom >
          <template v-slot:activator="{ attrs, on }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              small
              class="mr-2"
              @click="deleteClient(item)"
              >mdi-delete</v-icon
            >
          </template>
          <span>Excluir Cliente</span>
        </v-tooltip>

      </template>
    </v-data-table>

    <v-dialog
      :value="deleteDialog"
      @click:outside="setDeleteDialog(false)"
      @keydown.enter="deleteItem()"
      width="300"
    >
      <v-card>
        <v-card-title class="headline">Confirmar a exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn text @click="setDeleteDialog(false)">Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn text type="submit" right @click="deleteItem"
              >Confirmar</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    

    <v-dialog
      :value="showDialog"
      @keydown.esc="setShowDialog(false)"
      @click:outside="setShowDialog(false)"
      width="1000"
    >
      <client-form></client-form>
    </v-dialog>

    <v-dialog
      :value="filterDialog"
      @keydown.esc="setShowFilter(false)"
      @click:outside="setShowFilter(false)"
      width="600"
    >
      <client-filter></client-filter>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import ClientsForm from "./ClientsForm";
import ClientsFilter from "./ClientsFilter";


export default {
  name: "Clients",

  components: {
    "client-form": ClientsForm,
    "client-filter": ClientsFilter,
  },

  computed: {
    ...mapState("clients", [
      'items',
      "notification",
      "mode",
      "loading",
      "totalItems",
      "showDialog",
      "filterDialog",
      "deleteDialog",
      "concludeDialog",
      "overlay",
      "pagination",
      "selectedItem",
    ]),
    ...mapFields("clients", [
      "pagination",
      "dialogPixRelated",
      "paginationPix",
      "showNotification",
    ]),
  },


  methods: {
    ...mapActions("clients", [
      "getItems", 
      "deleteItem",
      "concludeItem",
      "showReceipt",
      "getRelatedPixes",
      "viewItem",
    ]),
    ...mapMutations("clients", [
      "setShowDialog",
      "add",
      "edit",
      "view",
      "deleteClient",
      "setShowFilter",
      "setDeleteDialog",
      "deleteClient",
    ]),

    

    getCpf(item) {
      if (item.length == 11 ){
        return item.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
      } else {
        return item.replace(/(\d{2})?(\d{3})?(\d{3})?(\d{4})?(\d{2})/, "$1.$2.$3/$4-$5");
      }
    },

    getContact (item) {
      return item.toString().replace(/(\d{2})?(\d{2})?(\d{1})?(\d{4})?(\d{4})/, "$1 ($2) $3 $4-$5")
    },

    getSituation(situation) {
      let situations = ["Inadimplente", "Em Acordo", "Rotativo Semanal", "Ativo", "Não Definido"] 
      if (situations[situation]) {
      return situations[situation];
      }
      return "";
    }

  },


  data: () => ({
    headers: [
        { text: "Id", value: "id" },
        { text: "Name/Nome Fantasia", value: "name", sortable: true },
        { text: "Apelido", value: "nickname", sortable: true },
        { text: "Tipo", value: "type" },
        { text: "CPF/CNPJ",value: "inscription", sortable: true },
        { text: "Contato", value: "contact", sortable: true },
        { text: "Situação", value: "situation", sortable: true },
        { text: "Ações",  value: "actions", sortable: false, align: "center" },
      ],
  }),
  watch: {
    pagination: {
      handler() {
        this.getItems({ resetPage: false });
      },
    },
  },
};
</script>

<style lang="sass">

</style>