<template>
  <v-card>
    <v-card-title class="justify-center">Ordem Auxiliar - {{ id }}</v-card-title>
    <v-card-text>
      <v-col class="d-flex" cols="12" sm="12">
        <v-select
          :items="possible_types"
          label="Tipo"
          outlined
          readonly
          v-model="type"
          item-text="description"
          item-value="id"
        ></v-select>
        <v-select
          :items="instances"
          label="Clube"
          outlined
          readonly
          v-model="club"
          item-text="name"
          item-value="id_instance"
        ></v-select>
        <v-text-field
          label="ID do Jogador"
          outlined
          readonly
          v-model="player_id"
        ></v-text-field>
        <v-text-field
          label="Status"
          outlined
          readonly
          :value="getStatus(status)"
        ></v-text-field>
      </v-col>
      <v-col class="d-flex" cols="12" sm="12">
        <v-text-field
          label="Criado em"
          outlined
          readonly
          :value="getDate(created_at)"
        ></v-text-field>
        <v-text-field
          label="Finalizado em"
          outlined
          readonly
          :value="getDate(finish_at)"
        ></v-text-field>
        <v-text-field
          label="Item"
          outlined
          v-if="item"
          readonly
          v-model="item_name"
        ></v-text-field>
      </v-col>
    </v-card-text>
    <v-card-actions>
      <v-col>
        <v-btn text @click="showDialogViewUtilityOrder({show: false})">Cancelar</v-btn>
      </v-col>
      <v-col>
        <v-btn v-if="status == 2 && [2, 3].includes(type)" text right @click="showDialogPrint({show: true})">Visualizar Print</v-btn>
      </v-col>
    </v-card-actions>

    <v-dialog :value="dialogPrint" width="600" @click:outside="showDialogPrint({show: false})" @keydown.esc="showDialogPrint({ show:false })">
      <v-card height="90vh">
        <v-img :src="getPrint(profile_print)" contain></v-img>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
computed: {
    ...mapState('instances', ['instances']),
    ...mapState('message', ['dialogPrint']),
    ...mapFields("message", [
        "utility_order.id",
        "utility_order.type",
        "utility_order.status",
        "utility_order.player_id",
        "utility_order.club",
        "utility_order.item",
        "utility_order.item_name",
        "utility_order.created_at",
        "utility_order.finish_at",
        "utility_order.profile_print",
    // ...
    ]),
},
data: () => ({
    possible_types: [
        { id: 0, description: "Exclusão" },
        { id: 1, description: "Envio de Items" },
        { id: 2, description: "Perfil do Membro" },
        { id: 3, description: "Perfil do Agente" },
    ],
}),
created() {
    this.getInstances()
},
methods: {
    ...mapActions('instances', ['getInstances']),
    ...mapMutations('message', ['showDialogViewUtilityOrder', 'showDialogPrint']),
    getDate(date) {
      if (date) {
        var d = new Date(date);
        return d.toLocaleDateString() + " " + d.toLocaleTimeString();
      }
      return "";
    },
    getPrint(print) {
      return "data:image/jpeg;base64," + print;
    },
    getStatus(status) {
      switch(status) {
        case 0:
          return 'Aguardando'
        case 1:
          return 'Em andamento'
        case 2: 
          return 'Concluído'
        case 3: 
          return 'Falha na Verificação'
        case 4: 
          return 'Diamantes Insuficientes'
        case 5: 
          return 'Id Inválido'
        case 6:
          return 'Bot Inativo'
        case 7:
          return 'Motivo não Identificado'
        case 8:
          return 'Erro de Confirmação'
        case 9:
          return 'Item Não Encontrado'
      }
    },
},
}
</script>
