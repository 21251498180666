<template>
  <div>
    <v-card>
        <v-card-title>
          <span class="headline text-center">Filtro</span>
          <v-spacer></v-spacer>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>

                <v-flex xs12 sm6 md6>
                  <v-autocomplete
                    clearable
                    deletable-chips
                    v-model="id_agent"
                    :no-filter="true"
                    :items="agents"
                    outlined
                    chips
                    small-chips
                    no-data-text="Nenhum agente encontrado"
                    item-text="nick"
                    item-value="id"
                    placeholder="Digite aqui o nick do agente"
                    label="Agente"
                    :search-input.sync="searchAgent"
                  ></v-autocomplete>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Valor *"
                    v-model="value"
                    :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                    :error-messages="errors.collect('value')"
                    v-validate="'required:Valor,value|decimal:2'"
                    data-vv-name="value"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-datetime-picker
                    v-model="start"
                    date-format="dd/MM/yyyy"
                    clearText="Limpar"
                    okText="Confirmar"
                    :time-picker-props="timeProps"
                    label="De *"
                    :textFieldProps="{ outlined: true }"
                  >
                    <template slot="dateIcon">
                      <v-icon>mdi-calendar</v-icon>
                    </template>
                    <template slot="timeIcon">
                      <v-icon>mdi-clock</v-icon>
                    </template>
                  </v-datetime-picker>
                  
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-datetime-picker
                    v-model="end"
                    date-format="dd/MM/yyyy"
                    clearText="Limpar"
                    okText="Confirmar"
                    label="Até *"
                    :time-picker-props="timeProps"
                    :textFieldProps="{outlined: true}"
                  >
                    <template slot="dateIcon">
                      <v-icon>mdi-calendar</v-icon>
                    </template>
                    <template slot="timeIcon">
                      <v-icon>mdi-clock</v-icon>
                    </template>
                  </v-datetime-picker>
                  
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-select
                    label="Status"
                    :items="possible_status"
                    v-model="status_filter"
                    data-vv-name="status_filter"
                    outlined
                    item-text="text"
                    item-value="value"
                  >
                  </v-select>
                </v-flex>

              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn text @click="showFilter({show: false})">Cancelar</v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn text type="submit" right @click="filter">Filtrar</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import debounce from "../../../util/debounce.js";

export default {
  name: "RakebackFilter",
  computed: {
    ...mapState("rakeback", ["menus"]),
    ...mapFields("rakeback", [
      "mode",
      "agents",
      "filter.start",
      "filter.end",
      "filter.id_agent",
      "filter.value",
      "filter.name",
      "filter.status_filter",
      // ...
    ]),
  },
  mixins: [debounce],
  mounted() {
    this.getChildrenSearch = this.debounce(this.getChildrenSearch, 500);
  },
  methods: {
    ...mapMutations("rakeback", ["showFilter"]),
    ...mapActions("rakeback", [
      "getRakes",
      "getAgents",
      "closeDialog",
      "save",
    ]),
    clearStart() {
        this.start = ''
        this.start_formatted = ''
    },
    clearEnd() {
        this.end = ''
        this.end_formatted = ''
    },
    filter() {
      this.getRakes({ resetPage: true });
      this.showFilter({ show: false });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    },
    getTextUser(item) {
      if(item.account) {
        return item.name + ' | ' + item.account
      } else {
        return item.name
      }
    },
    setStart(date) {
      this.start = date;
      this.showStart = false;
      this.start_formatted = this.formatDate(date);
    },
    setEnd(date) {
      this.end = date;
      this.showEnd = false;
      this.end_formatted = this.formatDate(date);
    },
  },
  data: () => ({
    possible_status: [
      {
        text: "Pendente",
        value: 0,
      },
      {
        text: "Devendo",
        value: 1,
      },
      {
        text: "Concluído",
        value: 2, 
      },
    ],
    timeProps: {
      format: "24hr",
    },
    searchAgent: ''
  }),
  watch: {
    searchAgent (val) {
      var payload = {
        search: val
      }
      this.getAgents(payload)
    }
  },
};
</script>

<style>
</style>