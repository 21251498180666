<template>
  <div>
    <v-card>
      <v-card-title color="red">
          <span class="headline text-center justify-center">Filtro</span>

          <v-col class="text-right">
            <v-btn v-if="filtered == true" color="white" class="ma-2"  @click="clearFilter" right small depressed>
              <v-icon color="black">mdi-filter-remove</v-icon>
            </v-btn>
          </v-col>
      </v-card-title>

      <v-card-text>
        <v-form ref="form">
          <v-container grid-list-md>
            <v-layout wrap>
              
              <v-flex xs6 sm6 md6 v-if="bank != 85">
                <v-select
                  clear-icon="mdi-close"
                  :clearable="true"
                  label="Banco *"
                  :items="banksApi"
                  v-model="bank"
                  item-text="name"
                  item-value="id"
                  outlined
                  :rules="rulesFields"
                ></v-select>
              </v-flex>
              <v-flex xs12 sm12 md12 v-else>
                <v-select
                  clear-icon="mdi-close"
                  :clearable="true"
                  label="Banco *"
                  :items="banksApi"
                  v-model="bank"
                  item-text="name"
                  item-value="id"
                  outlined
                  :rules="rulesFields"
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6 md6 v-if="bank == 77 || bank == 81">
                <v-select
                  clear-icon="mdi-close"
                  :clearable="true"
                  label="Tipo"
                  :items="types"
                  v-model="type"
                  item-text="description"
                  item-value="id"
                  outlined
                ></v-select>
              </v-flex>
              
              <v-flex xs12 sm6 md6  v-if="bank == 77 || bank == 81">
                <v-select
                  clear-icon="mdi-close"
                  :clearable="true"
                  label="Tipo Operação"
                  :items="possible_types_operations"
                  v-model="type_operation"
                  item-text="description"
                  item-value="id"
                  outlined
                ></v-select>
              </v-flex>
             <v-flex xs12 sm6 md6  v-if="bank == 77 || bank == 81">
                <v-select
                  clear-icon="mdi-close"
                  :clearable="true"
                  label="Status"
                  :items="possible_status"
                  v-model="status"
                  item-text="description"
                  item-value="id"
                  outlined
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-datetime-picker
                  v-model="start"
                  date-format="dd/MM/yyyy"
                  clearText="Limpar"
                  okText="Confirmar"
                  :rules="rulesFields"
                  :time-picker-props="timeProps"
                  label="De *"
                  :textFieldProps="{outlined: true}"
                >
                  <template slot="dateIcon">
                    <v-icon>mdi-calendar</v-icon>
                  </template>
                  <template slot="timeIcon">
                    <v-icon>mdi-clock</v-icon>
                  </template>
                </v-datetime-picker>
              </v-flex>

              <v-flex xs12 sm6 md6 v-if="bank != 82 && bank != 92">
                <v-datetime-picker
                  v-model="end"
                  date-format="dd/MM/yyyy"
                  clearText="Limpar"
                  okText="Confirmar"
                  :rules="rulesFields"
                  label="Até *"
                  :time-picker-props="timeProps"
                  :textFieldProps="{outlined: true}"
                >
                  <template slot="dateIcon">
                    <v-icon>mdi-calendar</v-icon>
                  </template>
                  <template slot="timeIcon">
                    <v-icon>mdi-clock</v-icon>
                  </template>
                </v-datetime-picker>
              </v-flex>
                            
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn text @click="showFilter({show: false})">Cancelar</v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn text type="submit" right @click="filter">Filtrar</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import debounce from "../../../util/debounce.js";

export default {
  name: "ExtractFilter",
  computed: {
    ...mapState("extract", ["filtered"]),
    ...mapState("bank", { banks: "items" }),
    ...mapFields("extract", [
        "filter.id",
        "filter.bank",
        "filter.type",
        "filter.type_operation",
        "filter.cpf_origin",
        "filter.name_origin",
        "filter.cpf_destiny",
        "filter.name_destiny",
        "filter.value",
        "filter.start", 
        "filter.status",
        "filter.end"
      // ...
    ]),
    banksApi() {
      return this.banks.filter(bank =>  bank.id == 85 || bank.id == 82 || bank.id == 91 || bank.id == 92 || bank.id == 93 || bank.id == 94 || bank.id == 97 || bank.id == 98);
    },
  },
  data: () => ({
    rulesFields: [(value) => !!value || "Campo obrigatório"],
    showStart: false,
    showEnd: false,
    start_formatted: "",
    end_formatted: "",
    types: [
      { id: 0, description: 'Saída' },
      { id: 1, description: 'Entrada' },
    ],
    possible_types_operations: [
      { id: 0, description: "Manual" },
      { id: 1, description: "Pix" },
      { id: 2, description: "Tarifa" }
    ],
    possible_status: [
      { id: 1, description: "Em Processamento" },
      { id: 2, description: "Concluido" },
      { id: 3, description: "Recusado" },
    ],
    possible_types: [
      { id: 0, description: "Envio de Fichas Jogador"},
      { id: 1, description: "Envio de Fichas Agência"},
      { id: 2, description: "Abater Anotados"},
      { id: 3, description: "Adicionar ao Saldo Positivo"},
      { id: 4, description: "Transferência de Fichas"},
      { id: 5, description: "Bônus"},
      { id: 6, description: "Reembolso"}
    ],
    validations: [
      { id: 0, description: "Pendente"},
      { id: 1, description: "Automática"},
      { id: 2, description: "Manual"},
      { id: 3, description: "Não encontrado"},
      { id: 4, description: "Duplicado"},
      { id: 5, description: "Valor Diferente"},
      { id: 6, description: "Manual - Sistema"}
    ],
    searchInput: "",
    timeProps: {
      format: "24hr",
    },
  }),
  mixins: [debounce],
  mounted() {
    this.getUsers = this.debounce(this.getUsers, 500);
    this.getBanks();
  },
  methods: {
    ...mapMutations("extract", ["showFilter"]),
    ...mapActions("extract", ["getItems", "getUsers", "clearFilter"]),
    ...mapActions("bank", { getBanks: "getItems" }),
    clearStart() {
      this.start = ''
      this.start_formatted = ''
    },
    clearEnd() {
      this.end = ''
      this.end_formatted = ''
    },
    filter() {
      let valid = this.$refs.form.validate();
      if (valid) {
        this.getItems({ resetPage: true });
        this.showFilter({ show: false });
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    },
    setStart(date) {
      this.start = date;
      this.showStart = false;
      this.start_formatted = this.formatDate(date);
    },
    setEnd(date) {
      this.end = date;
      this.showEnd = false;
      this.end_formatted = this.formatDate(date);
    },
  },
  watch: {
    searchInput: {
      handler() {
        if (this.searchInput) {
          var payload = {
            search: this.searchInput,
          };
          this.getUsers(payload);
        }
      },
    },
  },
};
</script>

<style>
</style>