<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline text-center justify-center">Filtro</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md>
            <v-layout wrap>

              <v-flex xs12 sm6 md12>
                <v-text-field
                  label="Título"
                  v-model="title"
                  data-vv-name="title"
                  :clearable="true"
                  outlined
                ></v-text-field>
              </v-flex>  

              <v-flex xs12 sm6 md12>
                <v-text-field
                  label="Descrição"
                  v-model="description"
                  data-vv-name="description"
                  :clearable="true"
                  outlined
                ></v-text-field>
              </v-flex>
              
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn
            text
            @click="
              setShowFilter(false);
              clearFilter();
            "
            >Cancelar</v-btn
          >
        </v-col>
        <v-col class="text-right">
          <v-btn text type="submit" right @click="filter">Filtrar</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "AgentsFoldersFilter",
  computed: {
    ...mapState("agents_folders", ["mode", "selectedItem", "showDialog"]),
    ...mapFields("agents_folders", [
      "filter.description",
      "filter.title",
    ]),
  },   
  methods: {
    ...mapMutations("agents_folders", ["setShowFilter", "clearFilter"]),
    ...mapActions("agents_folders", ["getItems"]),
    filter() {
      this.getItems();
      this.setShowFilter(false);
    },
  },
  data: () => ({
    timeProps: {
      format: "24hr",
    },
  }),
};
</script>