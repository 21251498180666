<template>
  <div>
    <div>
      <v-card>
        <v-card-title>
          <span class="headline text-center justify-center"
            >Inserir Equipamento</span
          >
          <v-spacer></v-spacer>
          <v-progress-circular
            v-if="loading_equipments"
            indeterminate
            class="text-right"
            color="primary"
            size="24"
          ></v-progress-circular>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm12 md12 v-if="mode == 'ADD'">
                  <v-autocomplete
                    clearable
                    deletable-chips
                    v-model="equipment_id"
                    :no-filter="true"
                    :items="equipments"
                    outlined
                    chips
                    small-chips
                    no-data-text="Nenhum equipamento encontrado"
                    :item-text="getTextEquipment"
                    item-value="id"
                    placeholder="Digite aqui o nome do equipamento"
                    label="Equipamento"
                    :search-input.sync="searchEquipment"
                  ></v-autocomplete>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-col>
            <v-btn text @click="closeDialog">{{
              mode == "VIEW" ? "Voltar" : "Cancelar"
            }}</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn text type="submit" right @click="saveEquipment">Salvar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

import debounce from '../../../../util/debounce.js'

export default {
  name: "CollaboratorsEquipmentsForm",
  computed: {
    ...mapState("collaborators_equipments", [
      "mode",
      "equipments",
      "collab_equip",
      "loading_equipments"
      ]),
    ...mapFields("collaborators_equipments", [
      "editedItem.collaborator_id",
      "editedItem.equipment_id",
      "editedItem.equipment_name",
      "editedItem.equipment_model",
      "editedItem.equipment_color"
    ]),
  },
  created() {
    this.getEquipmentsForm();
    this.$validator.extend("required", {
      getMessage: (field, [label]) => label + " deve ser informado",
      validate: (_, [, field]) => {
        if (this[field] || this[field] === 0) {
          return true;
        } else {
          return false;
        }
      },
    });
  },
  mixins: [debounce],
  mounted() {
    this.getFindEquipments = this.debounce(this.getFindEquipments, 500)
  },
  methods: {
    ...mapActions("collaborators_equipments", [
    "add",
    "closeDialog",
    "getFindEquipments",
    "getEquipments",
    "getEquipmentsForm",
    "setCollaborator",
    "save"
    ]),
    ...mapActions("collaborator", [
    "setCollaborator",
    ]),
    getTextEquipment(item) {
      if (item) {
        if (item.id) {
          return item.name + " | " + item.model + " | " + item.color;
        } else {
          return item.name;
        }
      }
      return "";
    },
    saveEquipment() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.save();
        }
      });
    },
  },
  data: () => ({
    valid: false,
    searchEquipment: "",
  }),
  watch: {
    searchEquipment: {
        handler () {
          if(this.searchEquipment != null ) {
            this.getFindEquipments({ search: this.searchEquipment })
        }
      },
    },
  }
};
</script>
