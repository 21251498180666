import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../config/endPoints';
import { defaultErrorNotification } from "../../constants";

const defaultFilter = {
   active: ""
};

export default {
    namespaced: true,
    state: {
        clubs: [],
        dialog: false,
        dialogDelete: false,
        editedSlot: {},
        errors: [],
        filter: {
            active: ""
        },
        filterDialog: false,
        instanceToDelete: {},
        loading: false,
        mode: 'LIST',
        notificationMessage: '',
        notificationType: '',
        page: 1,
        pagination: {
            page: 1,
            limit: 10
        },
        showNotification: false,
        slots: [],
        slotsActives: [],
        searched: false,
        totalClubs: 0,
        rowsPerPage: 50,
        platforms: [
            {
                id: 10,
                description: "Suprema Poker"
            },
            {
                id: 13,
                description: "PPPoker"
            },
            {
                id: 12,
                description: "Cacheta League Brazil"
            }
        ],

        possible_status: [
            { id: 0, description: "Inativo" },
            { id: 1, description: "Ativo" }
        ],
        possible_web_deposits_withdrawals: [
            { id: 0, description: "Não" },
            { id: 1, description: "Sim" }
        ],
    },
    mutations: {
        clearFilter: (state) => {
            state.filter = Object.assign({}, defaultFilter)
        },
        loading: (state, loading) => state.loading = loading,
        setMode: (state, mode) => state.mode = mode,
        setClubs: (state, payload) => {
            state.clubs = payload.clubs
        },
        setTotalClubs: (state, payload) => state.totalClubs = payload.total,
        setRowsPerPage: (state, rowsPerPage) => state.rowsPerPage = rowsPerPage,
        showDialog: (state, show) => state.dialog = show,
        showLoading: (state, show) => state.loading = show,
        updateField,
        setPage: (state, payload) => {
            state.pagination.page = payload.page;
            state.pagination.limit = payload.limit;
        },
        setShowFilter(state, payload) {
            state.filterDialog = payload
            state.mode = "VIEW"
        },
        showNotification: (state, payload) => {
            state.showNotification = payload.show,
                state.notificationMessage = payload.message,
                state.notificationType = payload.type
        },
        setSearched: (state, payload) => state.searched = payload.searched
    },
    getters: {
        getField,
        getSlots: (state) => {
            return state.slots
        },
        getSlotsActives: (state) => {
            return state.slotsActives
        },

    },
    actions: {
        getClubs: async ({ commit, state }, payload) => {
            let filter = state.filter
            let url = endPoints.clubs;

            if(payload && payload.onlyIfNotSearched) {
                if(state.searched) {
                    return
                }
            }
    
            if (payload.resetPage) {
                url += "?page=" + 1 + "&" + "limit=" + 10;
                commit("setPage", { page: 1, limit: 10 });
            } else {
                if (payload.noPages) {
                    url += "?page=" + 1 + "&" + "limit=" + 999;
                } else {
                    url +=
                    "?page=" +
                    state.pagination.page +
                    "&" +
                    "limit=" +
                    state.pagination.itemsPerPage;
                }
            }
            if (state.pagination && state.pagination.sortBy && state.pagination.sortBy.length > 0) {
                url += "&sort_by=" + state.pagination.sortBy[0]
                url += "&sort_desc=" + !state.pagination.sortDesc[0]
            } 
            if(payload.onlyActive !== undefined && payload.onlyActive !== null && payload.onlyActive === true) {
                url += "&status=0"
            }

            Object.keys(filter).forEach(function (field) {
                if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
                    url += "&" + field + "=" + filter[field]
                }
            })

            commit("loading", true);

            try {
                let result = await axios.get(url);
                commit("setClubs", { clubs: result.data.data })
                commit("setTotalClubs", { total: result.data.total });
                commit("setSearched", { searched: true })                

            } catch (error) {
                commit(
                    "showNotification",
                    defaultErrorNotification(error?.response?.data?.message)
                );
            }
            commit("loading", false);
           
        }
    }
}