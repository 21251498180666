<template>
    <div>
        <v-dialog :value="dialog" persistent width="600">
            <v-card>
                <v-card-title>
                    <span class="headline">{{ this.formTitle() }}</span>
                </v-card-title>

                <v-card-text>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-container grid-list-md>
                            <v-layout wrap>
                                <v-flex xs12 sm6 md6>
                                    <v-text-field
                                        required
                                        label="Inscrição *"
                                        v-model="inscription"
                                        :readonly="this.mode == 'VIEW'"
                                        :rules="rulesInscription"
                                        outlined
                                    ></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm6 md6>
                                    <v-text-field
                                        required
                                        label="Nome *"
                                        v-model="name"
                                        :readonly="this.mode == 'VIEW'"
                                        :rules="rulesFields"
                                        outlined
                                    ></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm6 md6>
                                    <v-text-field
                                        required
                                        label="Contato *"
                                        v-model="contact"
                                        :readonly="this.mode == 'VIEW'"
                                        :rules="rulesFields"
                                        hide-details
                                        type="number"
                                        placeholder="55 (99) 9 9999-9999"
                                        outlined
                                    ></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm6 md6>
                                    <v-text-field
                                        required
                                        label="Email *"
                                        v-model="email"
                                        :readonly="this.mode == 'VIEW'"
                                        :rules="rulesFields"
                                        outlined
                                    ></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm6 md6>
                                    <v-select
                                        label="Ativo *"
                                        :items="possible_status"
                                        v-model="status"
                                        :readonly="this.mode == 'VIEW'"
                                        item-text="description"
                                        item-value="status"
                                        outlined
                                    >
                                    </v-select>
                                </v-flex>
                                <v-flex xs12 sm6 md6>
                                    <v-select
                                        :items="instances"
                                        label="Clube / Instância"
                                        outlined
                                        v-model="instance_id"
                                        item-text="name"
                                        item-value="id"
                                    ></v-select>
                                </v-flex>
                                <v-flex xs12 sm6 md6>
                                    <v-text-field
                                        label="Data de Criação *"
                                        v-model="date_created"
                                        :disabled="this.mode != 'VIEW'"
                                        :readonly="this.mode == 'VIEW'"
                                        outlined
                                    ></v-text-field>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-col>
                        <v-btn text @click="closeDialog">{{
                            mode == 'VIEW' ? 'Voltar' : 'Cancelar'
                        }}</v-btn>
                    </v-col>
                    <v-col class="text-right">
                        <v-btn
                            text
                            type="submit"
                            right
                            v-if="mode == 'EDIT' || mode == 'ADD'"
                            @click="save"
                            >Salvar</v-btn
                        >
                    </v-col>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import valida_cpf_cnpj from '@util/CpfCnpj'

export default {
    name: 'UsersForm',
    computed: {
        ...mapState('instances', ['instances']),
        ...mapState('users', [
            'dialog',
            'dialogDelete',
            'editedUser',
            'errors',
            'filtered',
            'loading',
            'mode',
            'notificationType',
            'notificationMessage',
            'rowsPerPage',
            'pagination',
            'totalUsers',
        ]),
        ...mapFields('users', [
            'editedUser.name',
            'editedUser.contact',
            'editedUser.email',
            'editedUser.role',
            'editedUser.status',
            'editedUser.date_created',
            'editedUser.instance_id',
            'editedUser.default_password',
            'editedUser.inscription',
            'pagination',
            'showNotification',
            // ...
        ]),
    },
    methods: {
        ...mapMutations('cost_center', [
            'setShowDialog',
            'setSelectedItem',
            'setShowDialog',
        ]),
        ...mapActions('users', ['closeDialog', 'saveUser']),

        save() {
            let valid = this.$refs.form.validate()
            console.log(valid)
            if (valid) {
                this.saveUser()
            }
        },

        formTitle() {
            switch (this.mode) {
                case 'ADD':
                    return 'Novo Usuário'
                case 'EDIT':
                    return 'Editar Usuário'
                default:
                    return 'Usuário'
            }
        },
    },
    data: () => ({
        rulesFields: [(value) => !!value || 'Campo obrigatório'],
        rulesInscription: [
            (value) =>
                value
                    ? valida_cpf_cnpj(value) || 'CPF/CNPJ inválido'
                    : 'Campo obrigatório',
        ],
        rules: {
            required: (value) => !!value || 'Required.',
            counter: (value) => value.length <= 20 || 'Max 20 characters',
            email: (value) => {
                const pattern =
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'Invalid e-mail.'
            },
        },
        possible_status: [
            { status: 0, description: 'Inativo' },
            { status: 1, description: 'Ativo' },
        ],
    }),
}
</script>