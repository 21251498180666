import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../../config/endPoints';
import defaultItem from './default_sale'

const defaultFilter = {
    code: "",
    tracking_code: "",
    status: "",
    state: ""
}

export default {
    namespaced: true,
    state: {
        backup_items: [],
        contact: '',
        contact_show: {},
        dialog: false,
        dialogInTransit: false,
        dialogContact: false,
        dialogFilter: false,
        dialogDelete: false,
        dialogReceipt: false,
        editedItem: {},
        errors: [],
        items: [],
        itemToDelete: {},
        deleteType: '',
        loading: false,
        instance_name: '',
        mode: 'LIST',
        message: '',
        notificationType: '',
        notificationMessage: '',
        page: 1,
        filter: {
            tracking_code: "",
            status: "",
            state: ""
        },
        filtered: false,
        overlay: false,
        pagination: {
            page: 1,
            itemsPerPage: 10
        },
        products: [],
        stock_products: [],
        showNotification: false,
        totalItems: 0,
        transit_item: {},
        tracking_code_transit: '',
        rowsPerPage: 10,
        receipt_show: {},
        screen: "messages"
    },
    mutations: {
        add: (state, payload) => {
            state.mode = "ADD"
            state.products = []
            state.screen = payload.screen
            if (payload.sale) {
                state.editedItem = payload.sale
                state.message = payload.message
                state.contact = payload.contact
                state.instance_name = payload.instance_name
            } else {
                state.editedItem = Object.assign({}, defaultItem)
                state.dialog = true
            }
        },
        closeDialog: (state) => {
            state.dialog = false
            state.editedItem = Object.assign({}, defaultItem)
            state.editedIndex = -1
            state.errors = [];
        },
        edit: (state, payload) => {
            state.screen = payload.screen
            if (payload.screen == "messages") {
                state.message = payload.message
                state.contact = payload.contact
                state.instance_name = payload.instance_name
            }
            state.editedItem = Object.assign({}, payload.item)
            state.editedIndex = state.items.indexOf(payload.item)
            state.dialog = true
            state.mode = "EDIT"
        },
        loading: (state, loading) => state.loading = loading,
        inTransitSale: (state, payload) => {
            state.dialogInTransit = true
            state.transit_item = payload.sale
        },
        closeTransitSale: (state) => {
            state.dialogInTransit = false
        },
        setErrorMessage: (state, message) => state.errorMessage = message,
        setMode: (state, mode) => state.mode = mode,
        setItems: (state, items) => state.items = items,
        setItemToDelete: (state, payload) => state.itemToDelete = payload.item,
        setTotalItems: (state, total) => state.totalItems = total,
        setRowsPerPage: (state, rowsPerPage) => state.rowsPerPage = rowsPerPage,
        showFilter: (state, payload) => state.dialogFilter = payload.show,
        showDialog: (state, show) => state.dialog = show,
        setDialogDelete: (state, show) => state.dialogDelete = show,
        setDialogReceipt: (state, payload) => state.dialogReceipt = payload.show,
        setReceipt: (state, payload) => state.receipt_show = payload.receipt,
        showErrorNotification: (state, show) => state.errorNotification = show,
        showSuccessNotification: (state, show) => state.successNotification = show,
        showLoading: (state, payload) => state.loading = payload.show,
        setStockProducts: (state, payload) => state.stock_products = payload.items,
        setDeleteType: (state, payload) => state.deleteType = payload.type,
        setProducts: (state, payload) => {
            state.products = payload.products
        },
        setScreen: (state, payload) => state.screen = payload.screen,
        setTrackingCode: (state, payload) => state.tracking_code = payload.tracking_code,
        showNotification: (state, payload) => {
            state.showNotification = payload.show,
                state.notificationMessage = payload.message,
                state.notificationType = payload.type
        },
        setFilter: (state, filter) => {
            state.filter = filter
        },
        setFiltered: (state, payload) => {
            state.filtered = payload.filtered
        },
        updateField,
        viewItem(state, payload) {
            state.editedItem = payload.item
            state.editedIndex = state.items.indexOf(payload.item)
            state.dialog = true
            state.mode = "VIEW"
            state.products
        }
    },
    getters: {
        getField,
    },
    actions: {
        closeDialog: ({ commit, state }) => {
            if (state.screen != "messages") {
                state.editedItem = Object.assign({}, defaultItem)
                state.editedIndex = -1
                commit('showDialog', false)
            } else {
                commit('message/showDialogSale', { show: false }, { root: true })
            }
            state.errors = [];
        },
        calcShipments: ({ commit, state }) => {
            if (state.editedItem.address_cep && state.editedItem.address_cep.length >= 8 && state.products.length != 0) {
                commit('showLoading', { show: true })
                state.products.forEach((product) => {
                    if (product.edited == 1) {
                        var r_cep = state.editedItem.address_cep.replace("-")
                        if (r_cep.length == 8) {
                            let url = endPoints.stock + "/shipment/" + product.stock_id
                            var data = []
                            axios.post(url, { cep: r_cep, type: "04510" }).then(response => {
                                commit('showLoading', { show: false })
                                if (response.data && response.data.length != 0) {
                                    data = response.data[0]
                                    product.shipment = parseFloat(data.value)
                                } else {
                                    let notification = {
                                        show: true,
                                        message: "Ocorreu um erro ao calcular o frete. Tente mais tarde",
                                        type: "error"
                                    }
                                    commit('showNotification', notification)
                                }
                            }).catch(() => {
                                commit('showLoading', { show: false })
                                let notification = {
                                    show: true,
                                    message: "Ocorreu um erro ao calcular o frete. Tente mais tarde",
                                    type: "error"
                                }
                                commit('showNotification', notification)
                            })
                        } else {
                            let notification = {
                                show: true,
                                message: "Cep invalido",
                                type: "error"
                            }
                            commit('showNotification', notification)
                        }
                    }
                })
                commit('showLoading', { show: false })
            } else {
                let notification = {
                    show: true,
                    message: "CEP/Produtos devem ser informados",
                    type: "error"
                }
                commit('showNotification', notification)
            }
        },
        clearFilter: ({ commit, dispatch }) => {
            commit('setFilter', Object.assign({}, defaultFilter))
            commit('setFiltered', { filtered: false })
            commit('showFilter', { show: false })
            dispatch('getItems', { resetPage: true })
        },
        closeDeleteDialog: ({ commit }) => {
            commit('setDialogDelete', false)
            commit('setItemToDelete', {})
        },
        deleteItem: ({ dispatch, commit, state }) => {
            commit('loading', true)
            var url = state.deleteType == 0 ? endPoints.sales : endPoints.sales_items
            axios.delete(url + "/" + state.itemToDelete.id, state.itemToDelete).then(() => {
                dispatch('closeDeleteDialog')
                if (state.deleteType == 0) {
                    return dispatch('getItems');
                } else {
                    return dispatch('getItemsSales');
                }
            });
        },
        editItem: ({ commit }, payload) => {
            commit('loading', true)
            let url = endPoints.sales_items;
            url += '?page=' + '1' + '&' + 'itemsPerPage=' + '99999';
            url += "&sales_id=" + payload.item.id
            axios.get(url).then(items => {
                commit('loading', false)
                commit('setProducts', { products: items.data.data })
                commit('edit', payload)
            })
        },
        getItemsSales: ({ commit, state }) => {
            let url = endPoints.sales_items;
            url += '?page=' + '1' + '&' + 'itemsPerPage=' + '99999';
            url += "&sales_id=" + state.editedItem.id
            commit('loading', true)
            axios.get(url).then(items => {
                commit('loading', false)
                commit('setProducts', { products: items.data.data })
            })
        },
        startSale: ({ dispatch, state }, payload) => {
            state.editedItem = Object.assign({}, payload.sale)
            state.editedIndex = state.items.indexOf(payload.sale)
            state.editedItem.status = 1
            state.mode = "EDIT"
            dispatch('save', { close: false })
        },
        transitSale: ({ commit, dispatch, state }) => {
            state.editedItem = state.transit_item
            state.editedIndex = state.items.indexOf(state.transit_item)
            state.editedItem.status = 2
            state.editedItem.tracking_code = state.tracking_code_transit
            state.mode = "EDIT"
            commit('closeTransitSale')
            dispatch('save', { close: false })
        },
        finishSale: ({ dispatch, state }, payload) => {
            state.editedItem = Object.assign({}, payload.sale)
            state.editedIndex = state.items.indexOf(payload.sale)
            state.editedItem.status = 3
            state.mode = "EDIT"
            dispatch('save', { close: false })
        },
        backStepSale: ({ dispatch, state }, payload) => {
            state.editedItem = Object.assign({}, payload.sale)
            state.editedIndex = state.items.indexOf(payload.sale)
            state.editedItem.status = state.editedItem.status - 1
            state.mode = "EDIT"
            dispatch('save', { close: false })
        },
        saveItemSale: ({ commit, dispatch }, payload) => {
            var url = endPoints.sales_items;
            let func = '';
            if (payload.mode == 'ADD') {
                func = axios.post;
                url = endPoints.sales_items;
            } else {
                func = axios.put;
                url += '/' + payload.item.id;
            }

            func(url, payload.item).then(
                () => {
                    dispatch('getItems')
                    dispatch('getItemsSales')
                    let notification = {
                        show: true,
                        message: "Item da venda salvo com sucesso.",
                        type: "success"
                    }
                    commit('showNotification', notification)
                    commit('setTrackingCode', { tracking_code: '' })
                },
                error => {
                    let notification = {
                        show: true,
                        message: error.response.data.message,
                        type: "error"
                    }
                    commit('showNotification', notification)
                }
            );
        },
        getItems: ({ commit, state }) => {
            commit('loading', true)
            let url = endPoints.sales;
            let filter = state.filter;
            let filtered = false;
            url += '?page=' + state.pagination.page + '&' + 'itemsPerPage=' + state.pagination.itemsPerPage;

            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
                    filtered = true
                    url += "&" + field + "=" + filter[field]
                }
            });
            axios.get(url).then(items => {
                commit('setFiltered', { filtered: filtered })
                commit('setTotalItems', items.data.total)
                commit('loading', false)
                commit('setItems', items.data.data)
            });
        },
        getItemsNoPage: ({ state }) => {
            let url = endPoints.sales;
            let filter = state.filter;
            url += '?page=' + '1' + '&' + 'itemsPerPage=' + '99999';

            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
                    if (field == "end" || field == "start") {
                        var e = new Date(filter[field])

                        url += "&" + field + "=" + e.toISOString().substr(0, 10) + " " + e.toLocaleTimeString()
                    } else {
                        url += "&" + field + "=" + filter[field]
                    }
                }
            });
            return axios.get(url).then(items => {
                return items.data.data
            });
        },
        getStockProducts: ({ commit }, payload) => {

            let url = endPoints.products + "/stock";
            url += "?name=" + payload.search

            axios.get(url).then(items => {
                commit('setStockProducts', { items: items.data.data })
            });
        },
        showDeleteDialog: ({ commit }, payload) => {
            commit('setDialogDelete', true)
            commit('setItemToDelete', payload)
            commit('setDeleteType', payload)
        },
        showReceipt: ({ commit }, payload) => {
            let url = endPoints.receipt;

            url += '/' + payload.item.id + '?type=3'
            commit('overlay', { show: true })
            axios.get(url).then(r => {
                let file = r.data.file
                let mimetype = file.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
                if (mimetype.indexOf("pdf") != -1) {
                    var a = document.createElement("a"); //Create <a>
                    a.href = file; //Image Base64 Goes here
                    a.download = "Comprovante.pdf"; //File name Here
                    a.click();
                } else {
                    commit('setReceipt', { receipt: r.data.file })
                    commit('setDialogReceipt', { show: true })
                }
                commit('overlay', { show: false })
            }).catch(() => {
                let notification = {
                    show: true,
                    message: "Comprovante não encontrado",
                    type: "error"
                }
                commit('showNotification', notification)
                commit('overlay', { show: false })
            });
        },
        showContactDialog: ({ commit }, payload) => {
            var url = endPoints.httpchats + '/contact/' + payload.withdrawal.contact_id
            axios.get(url).then((response) => {

                if (response && response.data) {
                    commit('setContactShow', { contact: response.data })
                    commit('setDialogContact', { show: true })
                } else {
                    let notification = {
                        show: true,
                        message: "Ocorreu um erro ao buscar o contato!",
                        type: "error"
                    }
                    commit('showNotification', notification)
                }
            }).catch(() => {
                let notification = {
                    show: true,
                    message: "Ocorreu um erro ao buscar o contato!",
                    type: "error"
                }
                commit('showNotification', notification)
            })
        },
        save: async({ dispatch, commit, state }) => {
            var url = endPoints.sales;
            let func = '';
            if (state.mode == 'ADD') {
                func = axios.post;
                url = endPoints.sales;
                if (state.editedItem.receipt_file.name) {
                    var file = state.editedItem.receipt_file

                    const toBase64 = file => new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => resolve(reader.result);
                        reader.onerror = error => reject(error);
                    });

                    let base64_file = await toBase64(file)
                    state.editedItem.receipt = base64_file
                }
            } else {
                func = axios.put;
                url += '/' + state.editedItem.id;
                state.products.forEach((p) => {
                    if (p.edited == 1) {
                        var mode = p.id ? 'EDIT' : 'ADD'
                        var payload = { item: p, mode: mode }
                        if (mode == 'ADD') {
                            p.sales_id = state.editedItem.id
                        }
                        dispatch('saveItemSale', payload)
                    }
                })
            }
            let register = state.editedItem
            register.items = state.products
            func(url, register).then(
                () => {
                    let notification = {
                        show: true,
                        message: "Venda salva com sucesso.",
                        type: "success"
                    }
                    if (state.screen != 'messages') {
                        dispatch('getItems', { resetPage: true })
                        commit('showNotification', notification)
                        commit('closeDialog')
                    } else {
                        commit('closeDialog')
                        commit('message/showDialogSale', { show: false }, { root: true })
                        commit('message/showNotification', notification, { root: true })
                    }
                },
                error => {
                    let notification = {
                        show: true,
                        message: error.response.data.message,
                        type: "error"
                    }
                    commit('showNotification', notification)
                }
            );
        },
        viewItem: ({ commit }, payload) => {
            commit('loading', true)
            let url = endPoints.sales_items;
            url += '?page=' + '1' + '&' + 'itemsPerPage=' + '99999';
            url += "&sales_id=" + payload.item.id

            axios.get(url).then(items => {
                commit('loading', false)
                commit('setProducts', { products: items.data.data })
                commit('viewItem', payload)
            })
        }
    }
}