<template>
  <div>
    
      <v-card>
          <v-card-title>
            <span class="headline">Filtro</span>
          </v-card-title>

          <v-card-text>

          <v-form ref="form"  lazy-validation>
              <v-container grid-list-md>
              <v-layout wrap>
                  <v-flex xs12 sm6 md6>
                    <v-select
                        label="Tipo"
                        :items="types"
                        v-model="type"
                        item-text="description"
                        item-value="id"
                        clear-icon="mdi-close"
                        :clearable="true"
                        outlined
                    >
                    </v-select>
                  </v-flex>
                  <v-flex xs12 sm6 md6 >
                    <v-select
                        label="Banco"
                        :items="banks"
                        v-model="bank"
                        :item-text="getTextBank"
                        item-value="id"
                        clear-icon="mdi-close"
                        :clearable="true"
                        outlined
                    >
                    </v-select>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field
                        label="Valor"
                        v-model="value"
                        outlined
                    ></v-text-field>
                  </v-flex>
                   <v-flex xs12 sm6 md6>
                    <v-text-field
                        label="Descrição"
                        v-model="description"
                        outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-menu
                        v-model="showStart"
                        :close-on-content-click="true"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="start_formatted"
                                label="De"
                                outlined=""
                                readonly
                                append-icon="mdi-close"
                                @click:append="clearStart()"
                                persistent-hint
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker locale="pt-br" v-model="start" no-title @input="(e) => setStart(e)"></v-date-picker>
                    </v-menu>
                  </v-flex>
                  
                  <v-flex xs12 sm6 md6>
                    <v-menu
                        v-model="showEnd"
                        :close-on-content-click="true"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="end_formatted"
                                label="Até"
                                outlined=""
                                readonly
                                append-icon="mdi-close"
                                @click:append="clearEnd()"
                                persistent-hint
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker locale="pt-br" v-model="end" no-title @input="(e) => setEnd(e)"></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-select
                        label="Clube *"
                        :items="instances"
                        v-model="id_instance"
                        item-text="name"
                        item-value="id_instance"
                        clear-icon="mdi-close"
                        :clearable="true"
                        outlined
                    ></v-select>
                  </v-flex>
              </v-layout>
              </v-container>
          </v-form>
          </v-card-text>

          <v-card-actions>
          <v-col>
              <v-btn
              text
              @click="showFilter({show: false})"
              >Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
              <v-btn
              text
              type="submit"
              right
              @click="filter"
              >Filtrar</v-btn>
          </v-col>
          </v-card-actions>
      </v-card>
    </div>
</template>

<script>
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
  import { mapFields } from 'vuex-map-fields'

  export default {
    name: 'WithdrawlsFilter',
    computed: {
        ...mapGetters('login', ['getInstanceId']),
        ...mapFields('financial_moviments', [
            'filter.type',
            'filter.bank',
            'filter.value',
            'filter.description',
            'filter.start',
            'filter.end',
            'filter.id_instance'
          // ...
        ]),
        ...mapState('instances', ['instances']),
        ...mapState("bank", { banks: "items" }),
        computedStart () {
            return this.start ? this.start : ''
        },
        computedEnd () {
            return this.date ? this.date : ''
        },
    },
    data: () => ({
      possible_status: [
        {status: 0, description: 'Pendente'},
        {status: 1, description: 'Cancelado'},
        {status: 2, description: 'Concluido'},
      ],
      showStart: false,
      showEnd: false,
      start_formatted: '',
      end_formatted: '',
      types: [
        { id: 0, description: 'Saque' },
        { id: 1, description: 'Despesa' },
        { id: 2, description: 'Depósito' },
        { id: 3, description: 'Ajuste' }
      ]
    }),
    methods: {
        ...mapMutations('financial_moviments', ['showFilter']),
        ...mapActions('financial_moviments', ['getItems']),
        clearStart() {
            this.start = ''
            this.start_formatted = ''
        },
        clearEnd() {
            this.end = ''
            this.end_formatted = ''
        },
        filter() {
            this.getItems({resetPage: true})
            this.showFilter({show: false})
        },
        formatDate(date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`
        },
        getTextBank(item) {
          if(item.account) {
            return item.name + ' | ' + item.account
          } else {
            return item.name
          }
        },
        setStart(date) {
            this.start = date
            this.showStart = false
            this.start_formatted = this.formatDate(date)
        },
        setEnd(date) {
            this.end = date
            this.showEnd = false
            this.end_formatted = this.formatDate(date)
        }
    }
  }
</script>

<style>

</style>