import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../../config/endPoints';

export default {
    namespaced: true,
    state: {
        contacts: [],
        dialogFilter: false,
        editedItem: {},
        filter: {
            id_slot: '',
            id: '',
            name: '',
            nick: '',
            master_agent: '',
            phone: '',
            rake_deal: '',
        },
        items: [],
        itemToDelete: {},
        loading: false,
        mode: 'LIST',
        page: 1,
        pagination: {
            page: 1,
            itemsPerPage: 10
        },
        successNotification: false,
        notificationMessage: '',
        notificationType: "success",
        selectedContact: {},
        showNotification: false,
        totalItems: 0,
        rowsPerPage: 10
    },
    mutations: {
        closeDialog: (state) => {
            state.editedItem = {}
            state.editedIndex = -1
            state.errors = [];
            state.dialog = false
            state.mode = "LIST"
        },
        loading: (state, loading) => state.loading = loading,
        setMode: (state, mode) => state.mode = mode,
        setItems: (state, items) => state.items = items,
        setTotalItems: (state, total) => state.totalItems = total,
        setPage: (state, payload) => {
            state.pagination.page = payload.page;
            state.pagination.itemsPerPage = payload.itemsPerPage
        },
        showFilter: (state, payload) => state.dialogFilter = payload.show,
        showErrorNotification: (state, show) => state.errorNotification = show,
        showSuccessNotification: (state, show) => state.successNotification = show,
        showLoading: (state, show) => state.loading = show,
        showNotification: (state, payload) => {
            state.showNotification = payload.show,
                state.notificationMessage = payload.message,
                state.notificationType = payload.type
        },
        updateField
    },
    getters: {
        getField,
    },
    actions: {
        getItems: ({ commit, state }, payload) => {
            console.log('to aqui')
            commit('loading', true)
            let url = endPoints.agents_tree;
            let filter = state.filter;
            if (payload.resetPage === true) {
                url += '?page=' + 1 + '&' + 'itemsPerPage=' + 10;
                commit('setPage', { page: 1, itemsPerPage: 10 })
            } else {
                url += '?page=' + state.pagination.page + '&' + 'itemsPerPage=' + state.pagination.itemsPerPage;
            }


            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined) {
                    url += "&" + field + "=" + filter[field]
                }
            });

            axios.get(url).then(items => {
                commit('showFilter', { show: false })
                commit('setTotalItems', items.data.length)
                commit('loading', false)
                let data = items.data.data
                data.forEach((i) => {
                    if(i.has_childrens) {
                        i.children = []
                    }
                })
                commit('setItems', data)
            });
        },
        fetchTreeChildrens: async (_, payload) => {
            let url = endPoints.agents_tree; 
            let level = parseInt(payload.level) + 1
            url += "?tree_code=" + payload.tree_code + "&level=" + level
            return axios.get(url)
                    .then(res => {
                        if(res.data && res.data.data && res.data.data.length > 0) {
                            let data = res.data.data
                            data.forEach((d) => {
                                if(d.has_childrens) {
                                    d.children = []
                                }
                            })
                            payload.children = data
                        } else {
                            delete payload.children
                        }
                    })
                    .catch(() => {
                        delete payload.children
                    })
        },
        getItemsNoPage: ({ state }) => {
            let url = endPoints.agents_tree;
            let filter = state.filter;
            url += '?page=' + '1' + '&' + 'itemsPerPage=' + '99999';

            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined) {
                    url += "&" + field + "=" + filter[field]
                }
            });
            return axios.get(url).then(items => {
                return items.data.data
            });
        }
    }
}