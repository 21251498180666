<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline">Filtro</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm12 md12>
                <v-autocomplete
                  clearable
                  clear-icon="mdi-close"
                  label="Clube *"
                  :items="slots"
                  v-model="id_slot"
                  item-text="name"
                  item-value="id"
                  data-vv-name="id_slot"
                  outlined
                ></v-autocomplete>
              </v-flex>
              <!--v-flex xs12 sm6 md6>
                <v-text-field
                  required
                  label="ID"
                  v-model="id_pppoker"
                  data-vv-name="id_pppoker"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field required label="Nick" v-model="nick" data-vv-name="nick" outlined></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field required label="Nome" v-model="name" data-vv-name="name" outlined></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                  <v-select
                    label="Tipo de Acerto *"
                    :items="types_deal"
                    v-model="type_deal"
                    :error-messages="errors.collect('type_deal')"
                    v-validate="'required'"
                    item-text="description"
                    item-value="type"
                    data-vv-name="type_deal"
                    clear-icon="mdi-close"
                    :clearable="true"
                    outlined
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field required label="Telefone" v-model="phone" data-vv-name="phone" outlined></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-autocomplete
                    clearable
                    deletable-chips
                    v-model="id_master_agent"
                    :no-filter="true"
                    :items="master_agents"
                    outlined
                    chips
                    small-chips
                    no-data-text="Nenhum agente encontrado"
                    :item-text="getTextAgent"
                    item-value="id"
                    placeholder="Digite aqui o id/nome do agente master"
                    label="Agente Master"
                    :search-input.sync="searchAgent"
                  ></v-autocomplete>
                </v-flex-->
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn text @click="showFilter({show: false})">Cancelar</v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn text type="submit" right @click="filter">Filtrar</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import debounce from '../../../util/debounce.js' 

export default {
  name: "WithdrawlsFilter",
  computed: {
    ...mapState("slots", [
      "slots"
    ]),
    ...mapState("agents", [ "master_agents"]),
    ...mapFields("agents_tree", [
        "filter.ag_group_id",
        "filter.ag_group_name",
        "filter.id_slot",
        "filter.id_pppoker",
        "filter.id_master_agent",
        "filter.name",
        "filter.nick",
        "filter.phone",
        "filter.rake_deal",
        "filter.type_deal",
      // ...
    ])
  },
  data: () => ({
    possible_status: [
      { status: 0, description: "Pendente" },
      { status: 1, description: "Cancelado" },
      { status: 2, description: "Concluido" }
    ],
    types_deal: [
      { type: 0, description: "Ganho/Perda" },
      { type: 1, description: "Giro" },
      { type: 2, description: "Normal"}
    ],
    searchAgent: ''
  }),
  mixins: [debounce],
  mounted() {
    this.getMasterAgents = this.debounce(this.getMasterAgents, 500)
  },
  methods: {
    ...mapMutations("agents_tree", ["showFilter"]),
    ...mapActions("agents_tree", ["getItems", "getMasterAgents"]),
    getTextAgent(item) {
      if(item) {
        if(item.id_pppoker) {
          return item.id_pppoker + " | " + item.nick + " | " + item.club
        } else {
          return item.nick
        }
      }
      return ''
    },
    filter() {
      this.getItems({resetPage: true});
      this.showFilter({ show: false });
    },
  },
  watch: {
    searchAgent: {
      handler () {
        this.getMasterAgents({search: this.searchAgent})
      },
    },
  }
};
</script>

<style>
</style>