<template>
  <div>
    <v-snackbar
      :color="notification.type"
      right
      top
      :timeout="2000"
      v-model="notification.show"
    >
      {{ notification.message }}
    </v-snackbar>
    
    <v-toolbar class="mt-2 mb-5 elevation-0 px-3git">
      <v-toolbar-title class="title">ID de Plataformas</v-toolbar-title>
      <v-spacer></v-spacer>
       
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn 
          v-bind="attrs" 
          v-on="on" 
          color="#373B40" 
          dark 
          @click="add = true"
          small
          >Adicionar ID</v-btn>
        </template>
        <span>Acionar novo ID de Plataforma</span>
      </v-tooltip>
    </v-toolbar>

    <v-container fluid grid-list-lg pa-0 px-4>
      <v-layout wrap >
        
        <v-flex xs12 sm12 md6 v-for="app of clientApps" v-bind:key="app.id" >
          <v-card class="d-flex justify-space-between align-center pa-3 pt-4"
          elevation="0"
          outlined
          >
              <span class="pl-3"> {{appNames[app.platform].text}}: <strong> {{app.player_id}}</strong></span>

              <v-card elevation="0" class="pa-0 ma-0">
                <!-- <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn 
                    v-bind="attrs" 
                    v-on="on"
                    small 
                    fab
                    elevation="0"
                    class="ml-3 pa-2"
                    color="#ebebeb"
                    @click="copyToClipborad(app)"
                  >
                  <v-icon dark small color="#757575">
                    {{ app.iconCopy }}
                  </v-icon>
                  </v-btn>
                </template>
                  <span>Copiar ID</span>
              </v-tooltip> -->
                
                <v-btn
                  v-if="app.checked"
                  small 
                  elevation="0"
                  class="ml-2 pa-2 disable-events disabled-green"
                  color="#d0f3d0"
                  
                >
                  <v-icon dark small color="green">
                    mdi-check-bold
                  </v-icon>
                  Verificado
                </v-btn>
                <v-btn
                v-else
                  small 
                  elevation="0"
                  class="ml-2  pa-2"
                  color="#e8ecee"
                  @click="checkIdApp(app)"
                >  Verificar
                </v-btn>


                <v-btn
                  small
                  elevation="0"
                  class="ml-2 pa-2"
                  color="#ffe4e4"
                  @click="setIdToDelete(app)"
                >
                <v-icon dark size="18" color="red">
                  mdi-delete
                </v-icon> Excluir
                </v-btn>

              </v-card>
            </v-card>
        </v-flex>

        <template v-if="add">
          <template >
            <v-flex xs12 sm3 md3>
              <v-autocomplete
                class="atention_border"
                :items="appNames"
                v-model="platform"
                label="Nome da Plataforma *"
                :rules="ruleRequired"
                required
                outlined
              ></v-autocomplete>
            </v-flex>
          </template>
          <template >
            <v-flex xs12 sm2 md2>
              <v-text-field
                class="atention_border"
                v-model="player_id"
                label="ID *"
                :rules="ruleRequired"
                color="blue"
                required
                outlined
              ></v-text-field>
            </v-flex>
          </template>

          <template v-if="player_id == '' ||  platform == null ">
            <v-flex xs12 sm1 md1>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn 
                    v-bind="attrs" 
                    v-on="on" 
                    class="mt-2"
                    fab
                    dark
                    small 
                    elevation="0"
                    color="red"
                    @click="setAdd(false)"
                  >
                  <v-icon dark >
                      mdi-close
                    </v-icon>
                  </v-btn>
                </template>
                <span>Cancelar</span>
              </v-tooltip>
            </v-flex>
          </template>

          <template v-else>
            <v-flex xs12 sm1 md1>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn 
                    v-bind="attrs" 
                    v-on="on" 
                    class="mt-2"
                    fab
                    dark
                    small 
                    elevation="0"
                    color="blue"
                    @click="addApp"
                  >
                    <v-icon dark >
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </template>
                <span>Salvar</span>
              </v-tooltip>
            </v-flex>
          </template>

        </template>
        <template v-else>
          <template >
            <v-flex xs12 sm6 md6 class="mt-4">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn 
                  v-bind="attrs" 
                  v-on="on" 
                  class="mx-2"
                  fab
                  dark
                  small 
                  elevation="0"
                  color="blue"
                  @click="add = true"
                >
                <v-icon dark>
                  mdi-plus
                </v-icon>
                </v-btn>
              </template>
              <span>Adicionar novo ID</span>
            </v-tooltip>
            </v-flex>
          </template>
        </template>
      </v-layout>
    </v-container>



      <v-dialog :value="dialogDelete"
      @click:outside="setDialogDelete(false)"
      @keydown.enter="setDialogDelete(false)"
      width="320"
      >
        <v-card>
          <v-card-title class="headline justify-center">Excluir ID APP?</v-card-title>

          <v-card-actions>
            <v-col>
              <v-btn text @click="setDialogDelete(false)">Cancelar</v-btn>
            </v-col>
            <v-col class="text-right">
              <v-btn text type="submit" right @click="removeClientApp()"
                >Confirmar</v-btn
              >
            </v-col>
          </v-card-actions>
        </v-card>
      </v-dialog>

  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
  import { mapFields } from 'vuex-map-fields'
  import debounce from "../../../../../util/debounce.js";
  // import LinkGroupForm from "./LinkGroupForm.vue";

  export default {
    name: 'ClientApps',


  // components: {
  //   'client-group-form': LinkGroupForm,
  // },
    
  async created() {

    console.log("this.clientApps")
    console.log(this.clientApps)
  },

  computed: {
    ...mapState("client", [
      "notification",
      "clientApps",
      "dialogDelete",
    ]),
    ...mapFields("client", [
      "clientIdApp.player_id",
      "clientIdApp.platform",
      "showNotification",
    ]),
  },
  methods: {
    ...mapMutations("client", [
      "setClientIdDelete",
    ]),

    ...mapActions("client", [
      "addClientApp",
      "removeClientApp",
      "checkIdApp",
    ]),


    getTextGroup(item) {
      if (item) {
        if (item.ag_group_id) {
          return item.ag_group_id + " | " + item.ag_group_name + " | " + item.nick;
        }
      }
      return "";
    },

    linkAgentToGroup() {
      let valid = this.$refs.form.validate();
      if (valid) {
        this.save();
      }
    },

    setAdd(value){
      this.add = value
      this.player_id = ''
      this.platform = ''
    },

    addApp(){
      console.log(this.platform)
      console.log(this.player_id)
      this.addClientApp()
      this.setAdd(false)
    },

    setIdToDelete(item) {
      console.log(item)
      this.setClientIdDelete(item)
    },

    copyToClipborad (value) {
      let textToCopy = value.platform + ": "+ value.player_id
      navigator.clipboard.writeText(textToCopy)
      value.iconCopy = "mdi-check"
      setTimeout(() => {
        value.iconCopy = "mdi-content-copy"
      }, 2000);
    },
    
  },

  data: () => ({
    headers: [
      { text: "Nome do Grupo", value: "name", sortable: false },
      { text: "Telefone", value: "phone", sortable: false },
      { text: "Clube", value: "instance_name", sortable: false },
      { text: "Ações", value: "actions", sortable: false, align: "right" }
    ],
    ruleRequired: [(value) => value != null || "Campo obrigatório"],
    searchInput: "",
    valid: false,
    items: 5,
    add: false,
    iconCopy: "mdi-content-copy",
    appNames: [
      {value: 0, text: "Suprema Poker"},
      {value: 1, text: "Pppoker"},
      {value: 2, text: "Cacheta"},
    ],
  }),

  mixins: [debounce],

  watch: {
    searchInput(val) {
      var payload = {
        search: val,
        groups_contacts: this.ag_group_id
      };
      this.getGroupsList(payload);
    },
    pagination: {
      handler() {
        this.getContacts({ resetPage: false, filteredByClient: true });
      },
    },
  },
}
</script>

<style>
.atention_border div div :first-child {
  border-color: rgb(145, 144, 156) !important;
}

button.disabled-green.v-btn[disabled] {
  background-color: #4caf50 !important;
  color: #fff !important;
}

.disable-events {
  pointer-events: none
}
</style>