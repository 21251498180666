import axios from "axios";
import { getField, updateField } from "vuex-map-fields";
import endPoints from "../../../config/endPoints";
import { defaultErrorNotification } from "../../../constants";

const defaultFilter = {
  start_date: "",
  finish_date: "",
  requester: "",
  cust_min: "",
  cust_max: "",
  department: "",
  candidates_min: "",
  candidates_max: "",
  status: "",
  role: "",
  responsible: "",

}

const defaultJobItem = {
  id: null,
  candidates: null,
  cust: null,
  start_date: "",
  finish_date: "",
  responsible: null,
  requester: null,
  role: null,
  department: null,
  status: null,
};

export default {
  namespaced: true,
  state: {
    job: {},
    roles: [],
    departments: [],
    dialogFinish: false,
    jobToDelete: {},
    collaborators: [],
    collaboratorsByDepartments: [],
    items: [],
    selectedItem: {},
    dialogDelete: false,
    mode: "",
    loading: false,
    errors: [],
    totalItems: 10,
    dialog: false,
    deleteDialog: false,
    filterDialog: false,
    overlay: false,
    filter: {
      start_date: "",
      finish_date: "",
      requester: "",
      cust_min: "",
      cust_max: "",
      department: "",
      candidates_min: "",
      candidates_max: "",
      status: "",
      role: "",
      responsible: "",
    },
    notification: {
      show: false,
      message: "",
      type: "",
    },
    pagination: {
      page: 1,
      itemsPerPage: 10,
    },
    possible_status: [
      { id: 0, description: "Em Aberto" },
      { id: 1, description: "Finalizada" }
    ],
  },
  mutations: {
    finishDialog(state, payload) {
      state.mode = 'FINISH';
      state.dialogFinish = true;
      state.selectedItem = payload;
      state.job.finish_date = new Date();
    },
    closeFinishDialog(state) {
      state.dialogFinish = false;
      state.job = Object.assign({}, {});
      state.job.finish_date = new Date();
    },
    addJob: (state) => {
      state.dialog = true;
      state.mode = "ADD";
      defaultJobItem.status = 0;
      defaultJobItem.start_date = new Date();
      state.selectedItem = Object.assign({}, defaultJobItem);
    },
    closeDialog: (state) => {
      state.selectedItem = {};
      state.editedIndex = -1;
      state.errors = [];
      state.dialog = false;
      state.mode = "LIST";
    },
    setRoles: (state, payload) => {
      state.roles = payload.roles;
    },
    editJob: (state, payload) => {
      state.collaboratorsByDepartments = state.collaborators
      payload.item.start_date = new Date(payload.item.start_date);
      if (payload.item.finish_date !== null && payload.item.finish_date !== undefined && payload.item.finish_date !== "") {
        payload.item.finish_date = new Date(payload.finish_date);
      }
      state.selectedItem = Object.assign({}, payload.item);
      state.dialog = true;
      state.mode = "EDIT";
    },
    view: (state, payload) => {
      state.collaboratorsByDepartments = state.collaborators
      state.collaborator = payload.collaborator
      state.selectedItem = payload;
      state.selectedItem.start_date = new Date(payload.start_date);
      if (state.selectedItem.finish_date !== null && state.selectedItem.finish_date !== undefined && state.selectedItem.finish_date !== "") {
        state.selectedItem.finish_date = new Date(payload.finish_date);
      }
      state.dialog = true;
      state.mode = "VIEW";
    },
    clearFilter: (state) => {
      state.filter = Object.assign({}, defaultFilter)
    },
    setCollaborators: (state, payload) => {
      state.collaborators = payload.collaborators;
    },
    setCollaboratorsByDepartments: (state, payload) => {
      state.collaboratorsByDepartments = payload.collaborators;
    },
    setDepartments: (state, payload) => {
      state.departments = payload.departments;
    },
    setItems: (state, payload) => {
      state.items = payload
    },
    setJob: (state, payload) => {
      state.job = payload
    },
    setJobToDelete: (state, job) => state.jobToDelete = job,
    setTotalItems: (state, payload) => (state.totalItems = payload),
    setLoading: (state, payload) => (state.loading = payload),
    showNotification: (state, payload) => (state.notification = payload),
    setDialogDelete: (state, show) => state.dialogDelete = show,
    setShowDialog: (state, payload) => {
      state.dialog = payload;
      state.collaboratorsByDepartments = [];
      state.selectedItem = Object.assign({}, defaultJobItem);
    },
    setPage: (state, payload) => {
      state.pagination.page = payload.page;
      state.pagination.itemsPerPage = payload.itemsPerPage;
    },
    showOverlay: (state, payload) => {
      state.overlay = payload.show;
    },
    setShowFilter: (state, payload) => (state.filterDialog = payload),
    updateField,
  },
  actions: {
    getRoles: ({ commit }) => {
      let url = endPoints.roles;
      url += "?page=" + 1 + "&" + "itemsPerPage=" + 999;

      axios.get(url).then((roles) => {
        commit("setRoles", { roles: roles.data.data });

      });
    },
    openCandidates: async ({ dispatch, commit }, payload) => {
      commit("setJob", payload)
      dispatch('menu/openLink', '/candidates/' + payload.id, { root: true })
    },
    closeDialog: ({ commit }) => {
      commit("closeDialog", false);
    },
    closeDeleteDialog: ({ commit }) => {
      commit('setDialogDelete', false)
      commit('setJobToDelete', {})
    },
    showDeleteDialog: ({ commit }, item) => {
      commit('setDialogDelete', true)
      commit('setJobToDelete', item)
    },
    deleteJob: ({ dispatch, commit, state }) => {
      commit('setLoading', true)
      axios.delete(endPoints.jobs + "/" + state.jobToDelete.id, state.jobToDelete).then(() => {
        dispatch('closeDeleteDialog')
        let notification = {
          show: true,
          message: "Vaga excluída com sucesso.",
          type: "success"
        }
        commit('showNotification', notification)
        return dispatch('getItems', { resetPage: false });
      });
    },
    getSearchCollaborators: ({ commit }, payload) => {
      let url = endPoints.collaborators;
      url += "/collaborator/" + payload.search;

      axios.get(url).then((collaborators) => {
        commit("setCollaborators", { collaborators: collaborators.data });
      });

    },
    getSearchCollaboratorsByDepartment: ({ commit }, payload) => {
      let url = endPoints.collaborators;
      url += "/collaborator/" + payload.search + "/" + payload.department;
      axios.get(url).then((collaborators) => {
        commit("setCollaboratorsByDepartments", { collaborators: collaborators.data });
      });

    },
    getCollaborators: ({ commit }) => {
      let url = endPoints.collaborators;
      url += "?page=" + 1 + "&" + "itemsPerPage=" + 999;

      axios.get(url).then((collaborators) => {

        commit("setCollaborators", { collaborators: collaborators.data.data });

      });
    },
    getDepartments: ({ commit }) => {
      let url = endPoints.departments;
      url += "?page=" + 1 + "&" + "itemsPerPage=" + 999;

      axios.get(url).then((departments) => {

        commit("setDepartments", { departments: departments.data.data });

      });
    },
    getItems: async ({ commit, state }, payload) => {
      let filter = state.filter
      let url = endPoints.jobs;
      if (payload.resetPage) {
        url += "?page=" + 1 + "&" + "itemsPerPage=" + 10;
        commit("setPage", { page: 1, itemsPerPage: 10 });
      } else {
        url +=
          "?page=" +
          state.pagination.page +
          "&" +
          "itemsPerPage=" +
          state.pagination.itemsPerPage;
      }

      if (filter.cust_min != '' && filter.cust_min != undefined && filter.cust_min != null) {
        filter.cust_min = filter.cust_min.replace(",", ".")
      }
      if (filter.cust_max != '' && filter.cust_max != undefined && filter.cust_max != null) {
        filter.cust_max = filter.cust_max.replace(",", ".")
      }
      Object.keys(filter).forEach(function (field) {
        if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
          url += "&" + field + "=" + filter[field]
        }
      })

      if (state.pagination && state.pagination.sortBy && state.pagination.sortBy.length > 0) {
        url += "&sort_by=" + (state.pagination.sortBy[0] === 'score' ? "id" : state.pagination.sortBy[0])
        url += "&sort_desc=" + state.pagination.sortDesc[0]
      }

      commit("setLoading", true);
      try {
        let items = await axios.get(url);
        commit("setItems", items.data.data)
        commit("setTotalItems", items.data.total);


      } catch (error) {
        commit(
          "showNotification",
          defaultErrorNotification(error?.response?.data?.message)
        );


      }
      commit("setLoading", false);
    },
    save: async ({ dispatch, commit, state }) => {
      var url = endPoints.jobs;
      let func = "";
      let data = state.selectedItem;

      state.selectedItem.id_collaborator = parseFloat(state.selectedItem.id_collaborator);
      commit("showOverlay", { show: true });
      if (state.mode == "ADD") {
        func = axios.post;
        url = endPoints.jobs;

      } else if (state.mode == "EDIT") {
        func = axios.put;
        url += "/" + data.id;
      } else {

        data.cust = parseFloat(state.job.cust)
        data.finish_date = state.job.finish_date
        data.status = 1;

        func = axios.put;
        url += "/" + data.id;
      }

      func(url, data).then(
        () => {
          dispatch("getItems", { resetPage: true });
          let notification = {
            show: true,
            message: "Vaga salva com sucesso",
            type: "success",
          };
          commit("showNotification", notification);
          commit("closeDialog");
          commit("closeFinishDialog");
          commit("showOverlay", { show: false });
          dispatch("getCollaborators")
        },
        (error) => {
          let notification = {
            show: true,
            message: error.response.data.message,
            type: "error",
          };
          commit("showNotification", notification);
          commit("showOverlay", { show: false });
        }
      );
    },
  },
  getters: {
    getJob: (state) => {
      state.collaborators.find(collaborator => {
        if (collaborator.id == state.job.requester) {
          state.job.requester_name = collaborator.name
        }
      })
      return state.job
    },
    getField,
  }
};
