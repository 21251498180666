import axios from "axios";
import { getField, updateField } from "vuex-map-fields";
import endPoints from "@config/endPoints";
import formatDate from '../../../util/formatDate.js'

const defaultItem = {
    id: 0,
    description: "",
    value: 0,
    start_date: "",
    finish_date: "",
    start_formatted: "",
    finish_formatted: "",
    id_league: 0,
    total_clubs: 0,
}

const defaultFilter = {
  id: "",
  description: "",
  value: "",
  start_date: "",
  finish_date: "",
};

export default {
  namespaced: true,
  state: {
    dialogFilter: false,
    dialogForm: false,
    dialogDelete: false,
    selectedItem: {},
    itemToDelete: {},
    filter: {
      id: "",
      description: "",
      value: "",
      start_date: "",
      finish_date: "",
    },
    items: [],
    filtered: false,
    loading: false,
    mode: "LIST",
    overlay: false,
    page: 1,
    pagination: {
      page: 1,
      itemsPerPage: 10,
    },
    successNotification: false,
    notificationMessage: "",
    notificationType: "success",
    showNotification: false,
    totalItems: 0,
    rowsPerPage: 10,
    start_formatted: '',
    finish_formatted: '',
  },
  mutations: {
    add: (state) => {
      state.selectedItem = Object.assign({}, defaultItem);
      state.dialogForm = true;
      state.mode = "ADD";
    },
    edit: (state, payload) => {
      state.selectedItem = Object.assign({}, payload.item);
      const start = new Date(payload.item.start_date + " 12:00:00")
      const finish = new Date(payload.item.finish_date + " 12:00:00")
      state.start_formatted = start.toLocaleDateString()
      state.finish_formatted = finish.toLocaleDateString()
      state.dialogForm = true;
      state.mode = "EDIT";
    },
    loading: (state, payload) => (state.loading = payload.show),
    setMode: (state, mode) => (state.mode = mode),
    setItems: (state, items) => (state.items = items),
    setTotalItems: (state, total) => (state.totalItems = total),
    setRowsPerPage: (state, rowsPerPage) => (state.rowsPerPage = rowsPerPage),
    setFilter: (state, filter) => {
      state.filter = filter
    },
    setSelectedItem: (state, payload) => (state.selectedItem = payload),
    setDialogDelete: (state, show) => (state.dialogDelete = show),
    setDialogForm: (state, show) => (state.dialogForm = show),
    setItemToDelete: (state, item) => (state.itemToDelete = item),
    setFiltered: (state, filtered) => state.filtered = filtered,
    setPage: (state, payload) => {
      state.pagination.page = payload.page;
      state.pagination.itemsPerPage = payload.itemsPerPage;
    },
    showFilter: (state, payload) => (state.dialogFilter = payload.show),
    showErrorNotification: (state, show) => (state.errorNotification = show),
    showSuccessNotification: (state, show) =>
      (state.successNotification = show),
    showLoading: (state, show) => (state.loading = show),
    showNotification: (state, payload) => {
      (state.showNotification = payload.show),
      (state.notificationMessage = payload.message),
      (state.notificationType = payload.type);
    },
    showOverlay: (state, payload) => {
      state.overlay = payload.show;
    },
    updateField
  },
  actions: {
    closeDialogForm: ({ commit }) => {
        commit("setDialogForm", false);
        commit("setSelectedItem", {});
    },
    showDialogForm: ({ commit }, item) => {
        commit("setDialogForm", true);
        commit("setSelectedItem", item);
    },
    closeDialogDelete: ({ commit }) => {
        commit("setDialogDelete", false);
        commit("setItemToDelete", {});
    },
    showDialogDelete: ({ commit }, item) => {
        commit("setDialogDelete", true);
        commit("setItemToDelete", item);
    },
    clearFilter: ({ commit, dispatch }) => {
      commit('setFilter', Object.assign({}, defaultFilter))
      commit('showFilter', { show: false })
      commit('showOverlay', { show: true })

      setTimeout(function() {
          commit('showOverlay', { show: false })
          dispatch('getItems', { resetPage: true })
      }, 100);
    },
    viewItem({ commit, dispatch }, payload) {
      commit('slots_revenue/setWeeklyRevenue', { weekly_revenue: payload }, { root: true })
      dispatch('menu/openLink', '/slots_revenue/' + payload.id, { root: true })
    },
    getItems: ({ commit, state }, payload) => {
      commit("loading", { show: true });
      let url = endPoints.weekly_revenue;

      let filter = state.filter;
      if (payload.resetPage === true) {
        url += "?page=" + 1 + "&" + "itemsPerPage=" + 10;
        commit("setPage", { page: 1, itemsPerPage: 10 });
      } else {
        url +=
          "?page=" +
          state.pagination.page +
          "&" +
          "itemsPerPage=" +
          state.pagination.itemsPerPage;
      }
      commit('setFiltered', false)
      Object.keys(filter).forEach(function(field) {
        if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
          commit('setFiltered', true)
         
          if (field == "finish_date" || field == "start_date") {
            var e = new Date(filter[field] + " 12:00:00")
            url += "&" + field + "=" + formatDate(e)
          } else {
              url += "&" + field + "=" + filter[field]
          }
        }
      });
      
      if (state.pagination && state.pagination.sortBy && state.pagination.sortBy.length > 0) {
        url += "&sort_by=" + state.pagination.sortBy[0]
        url += "&sort_desc=" + state.pagination.sortDesc[0]
      }
    
      axios.get(url).then((items) => {
        commit("setTotalItems", items.data.total);
        commit("loading", { show: false });
        commit("setItems", items.data.data);
      });
    },
    deleteItem: ({ dispatch, commit, state }) => {
        commit("loading", { show: true });
        axios.delete(endPoints.weekly_revenue + "/" + state.itemToDelete.id, state.itemToDelete).then(() => {
            dispatch("closeDialogDelete");
            commit("loading", { show: false });
            let notification = {
              show: true,
              message: "Faturamento excluido com sucesso",
              type: "success",
            };
            commit("showNotification", notification);
            return dispatch("getItems", { resetPage: false });
          });
      },
      save: ({ dispatch, commit, state }) => {
        let url = endPoints.weekly_revenue;
        let func = '';
        if (state.mode == 'ADD') {
            func = axios.post;
            url = endPoints.weekly_revenue;
        } else {
            func = axios.put;
            url += '/' + state.selectedItem.id;
        }
        state.selectedItem.value = parseFloat(state.selectedItem.value)
        state.selectedItem.total_clubs = parseInt(state.selectedItem.total_clubs)
        func(url, state.selectedItem).then(
            () => {
                dispatch('getItems', { resetPage: false })
                let notification = {
                    show: true,
                    message: "Faturamento salvo com sucesso.",
                    type: "success"
                }
                dispatch('closeDialogForm')
                commit('showNotification', notification)
            },
            error => {
                let notification = {
                    show: true,
                    message: error.response.data.message,
                    type: "error"
                }
                commit('showNotification', notification)
            }
        );
    },
  },
  getters: {
    getField,
  },
};
