import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../../config/endPoints';

const defaultFilterTransactions = {
    period: "",
    suspect: "",
    type_suspect: "",
    id_manager_app: "",
    id_agent_app: "",
    id_player_app: "",
    value: "",
    id_instance: ""
}

export default {
    namespaced: true,
    state: {
        dialogFilterTransactions: false,
        itemsTransactions: [],
        filterTransactions: {
            period: "",
            suspect: "",
            type_suspect: "",
            id_manager_app: "",
            id_agent_app: "",
            id_player_app: "",
            value: "",
            id_instance: ""
        },
        loadingTransactions: false,
        filteredTransactions: false,
        overlay: false,
        paginationTransactions: {
            page: 1,
            itemsPerPage: 15
        },
        totalItemsTransaction: 0,
    },
    getters: {
        getField,
    },
    mutations: {
        loadingTransactions: (state, loading) => {
            state.loadingTransactions = loading
        },
        setFilterTransactions: (state, filter) => {
            state.filterTransactions = filter
        },
        setFilteredTransactions: (state, payload) => {
            state.filteredTransactions = payload.filtered
        },
        setItemsTransactions: (state, items) => state.itemsTransactions = items, 
        setTotalItemsTransaction: (state, total) => state.totalItemsTransaction = total,
        showErrorNotification: (state, show) => state.errorNotification = show,
        showFilterTransactions: (state, payload) => state.dialogFilterTransactions = payload.show,
        showSuccessNotification: (state, show) => state.successNotification = show,
        showNotification: (state, payload) => {
            state.showNotification = payload.show,
            state.notificationMessage = payload.message,
            state.notificationType = payload.type
        },
        updateField
    },
    actions: {
        clearFilterTransactions: ({ commit, dispatch }) => {
            commit('setFilterTransactions', Object.assign({}, defaultFilterTransactions))
            commit('showFilterTransactions', { show: false })
            dispatch('getPeriodClosureTransactions', { resetPage: true })
        },
        getPeriodClosureTransactions: ({ commit, state }) => {
            commit('loadingTransactions', true)
            var filter = state.filterTransactions;
            let url = endPoints.periods_closure_transactions;
            url += '?page=' + state.paginationTransactions.page + '&' + 'itemsPerPage=' + state.paginationTransactions.itemsPerPage;

            let filtered = false
            Object.keys(filter).forEach((field) => {
                if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
                    filtered = true
    
                    if (field == "start") {
                        var e = new Date(filter[field])
    
                        url += "&" + field + "=" + e.toISOString().substr(0, 10) + " " + e.toLocaleTimeString()
                    } else {
                        url += "&" + field + "=" + filter[field]
                    }
                }
            });
            if (state.pagination && state.pagination.sortBy && state.pagination.sortBy.length > 0) {
                url += "&sort_by=" + state.pagination.sortBy[0]
                url += "&sort_desc=" + state.pagination.sortDesc[0]
            }
            commit('setFilteredTransactions', { filtered: filtered })
            axios.get(url).then(items => {
                console.log('to aquii')
                commit('loadingTransactions', false)
                commit('setTotalItemsTransaction', items.data.total)
                commit('setItemsTransactions', items.data.data)
            });
        },
        getPeriodClosureTransactionNoPage: ({ commit, state }) => {
            commit('loadingTransactions', true)
            var filter = state.filter;
            let url = endPoints.periods_closure_transactions;
            url += '?page=' + 1 + '&' + 'itemsPerPage=' + "99999";
       
            Object.keys(filter).forEach((field) => {
                if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
        
    
                    if (field == "start") {
                        var e = new Date(filter[field])
    
                        url += "&" + field + "=" + e.toISOString().substr(0, 10) + " " + e.toLocaleTimeString()
                    } else {
                        url += "&" + field + "=" + filter[field]
                    }
                }
            });
            return axios.get(url).then(items => {
                commit('loadingTransactions', false)
                return items.data.data
            });
        },
        getPeriodClosureTransactionsByPeriod: ({ commit, state }, payload) => {
   
            let period = '';
        
            if(state.period_closure_id) {
                period = state.period_closure_id
            } else if(payload && payload.period_id) {
                period = payload.period_id
            }
            console.log(period)
            if (period) {
                commit('loadingTransactions', true)
                var filter = state.filterTransactions;
                let url = endPoints.periods_closure_transactions;
                url += '?page=' + state.paginationTransactions.page + '&' + 'itemsPerPage=' + state.paginationTransactions.itemsPerPage;
                filter.period = period
                let filtered = false
                Object.keys(filter).forEach((field) => {
                    if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
                        filtered = true
        
                        if (field == "start") {
                            var e = new Date(filter[field])
        
                            url += "&" + field + "=" + e.toISOString().substr(0, 10) + " " + e.toLocaleTimeString()
                        } else {
                            url += "&" + field + "=" + filter[field]
                        }
                    }
                });
                if (state.pagination && state.pagination.sortBy && state.pagination.sortBy.length > 0) {
                    url += "&sort_by=" + state.pagination.sortBy[0]
                    url += "&sort_desc=" + state.pagination.sortDesc[0]
                }
                commit('setFilteredTransactions', { filtered: filtered })
                axios.get(url).then(items => {
                    commit('loadingTransactions', false)
                    commit('setTotalItemsTransaction', items.data.total)
                    commit('setItemsTransactions', items.data.data)
                });
            }
        },
        getPeriodClosureTransactionsByPeriodNoPage: ({ commit, state }, payload) => {
            let period = '';
        
            if(state.period_closure_id) {
                period = state.period_closure_id
            } else if(payload && payload.period_id) {
                period = payload.period_id
            }
            if (period) {
                commit('loadingTransactions', true)
                var filter = state.filter;
                let url = endPoints.periods_closure_transactions;
                url += '?page=' + 1 + '&' + 'itemsPerPage=' + "99999";
                filter.period = period
                Object.keys(filter).forEach((field) => {
                    if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
           
        
                        if (field == "start") {
                            var e = new Date(filter[field])
        
                            url += "&" + field + "=" + e.toISOString().substr(0, 10) + " " + e.toLocaleTimeString()
                        } else {
                            url += "&" + field + "=" + filter[field]
                        }
                    }
                });
                return axios.get(url).then(items => {
                    commit('loadingTransactions', false)
                    return items.data.data
                });
            }
        },
    }
}