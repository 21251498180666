<template>
  <div>
    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2000"
      v-model="showNotification"
    >
      {{ notificationMessage }}
    </v-snackbar>
    <v-toolbar flat class="elevation-10">
      <v-toolbar-title>Plano de Contas</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="black" dark class="ma-2" @click="getExpenseTypes" small><v-icon>mdi-refresh</v-icon></v-btn> 
      <!-- Mudar -->
      <v-btn color="black" dark class="ma-2" @click="add" small><v-icon>mdi-plus</v-icon></v-btn>
      
    </v-toolbar>

    <v-dialog :value="dialog" persistent width="600">

      <v-card>
        <v-card-title>
          <span class="headline">{{ this.formTitle() }}</span>
        </v-card-title>

        <v-card-text>
          <v-alert
            v-if="successNotification"
            :value="successNotification"
            dense
            type="success"
            icon="mdi-check"
            outlined
          >Plano de contas salvo com sucesso.</v-alert>
          <v-alert
            v-if="errorNotification"
            :value="errorNotification"
            color="error"
            icon="mdi-alert-circle"
            outlined
          >{{ this.errorMessage }}</v-alert>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    required
                    label="Descrição *"
                    v-model="label"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('label')"
                    v-validate="'required'"
                    data-vv-name="label"
                    outlined
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    required
                    label="ID"
                    v-model="id_dre"
                    :readonly="this.mode == 'VIEW'"
                    data-vv-name="id_dre"
                    outlined
                  ></v-text-field>
                </v-flex>
              </v-layout>

            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-col>
            <v-btn
              text
              @click="closeDialog"
            >{{ mode == 'VIEW' ? 'Voltar' : 'Cancelar'}}</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn
              text
              type="submit"
              right
              v-if="mode == 'EDIT'  || mode == 'ADD'"
              @click="save"
            >Salvar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <v-data-table
      :headers="headers"
      :loading="loading"
      :items="expenseTypes"
      :options.sync="pagination"
      :server-items-length="totalExpenseTypes"
      disable-pagination
      hide-default-footer
      item-key="id"
      no-data-text="Nenhum plano de contas encontrado"
      class="elevation-5 table-margin"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="edit(item)">mdi-pencil</v-icon>
        <v-icon small class="mr-2" @click="showDeleteDialog(item)">mdi-delete</v-icon>
      </template>
    </v-data-table> -->
    <v-treeview
    activatable
    :items="showedExpenseTypes"
    open-on-click
    :load-children="getNodeChildren"
    :key="treeViewKey"
    class="elevation-5 table-margin"
    >
      <template v-slot:append="{ item }">
        <span>{{  "(Id:  " + item.id_dre  + ")  "}}</span>
        <v-icon small class="mr-2" @click="add(item)">mdi-plus</v-icon>
        <v-icon small class="mr-2" @click="edit(item)">mdi-pencil</v-icon>
        <v-icon small class="mr-2" @click="showDeleteDialog(item)">mdi-delete</v-icon>
      </template>
    </v-treeview>

    <v-dialog :value="dialogDelete" persistent width="300">
      <v-card>
        <v-card-title class="headline">Confirmar a exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn
              text
              @click="closeDeleteDialog()"
            >Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn
              text
              type="submit"
              right
              @click="deleteExpenseType"
            >Confirmar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
  
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import debounce from '../../util/debounce.js' 
  import { mapFields } from 'vuex-map-fields'

  export default {
    name: 'Plano de Contas',
    created() {
      console.log(this.showedExpenseTypes)
      this.getExpenseTypes()
    },
    computed: {
      ...mapState('expense_types', ['dialog', 'dialogDelete', 'errorMessage', 'errorNotification', 'errors', 'loading', 'mode','notificationType', 'notificationMessage', 'successNotification', 'totalExpenseTypes', 'expenseTypes', 'showedExpenseTypes', 'treeViewKey']),
      ...mapFields('expense_types', [
          'editedExpenseTypes.label',
          'editedExpenseTypes.id_dre',
          'showNotification'
        ]
      )
    },
    data: () => ({
      rules: {
        required: value => !!value || "Required.",
        counter: value => value.length <= 20 || "Max 20 characters"
      },
      valid: false,
    }),
    mixins: [debounce],
    methods: {
      add(item = {}) {
        this.addExpenseType(item)
      },
      edit(item) {
        this.editExpenseType(item)
      },
      ...mapMutations('expense_types', ['addExpenseType', 'editExpenseType']),
      ...mapActions('expense_types', ['closeDialog', 'closeDeleteDialog', 'deleteExpenseType', 'getExpenseTypes', 'saveExpenseTypes', 'showDeleteDialog', 'updateContactList', 'getNodeChildren']),
      getText(item) {
        if(item.name) {
          if(item.name.length == 1) {
            return item.name
          }
          
          var text = ""
          item.name.forEach((name) => {
            text += name + " | "
          })
          return text
        }
        else {
          return text
        }
      },
      formTitle() {
        switch (this.mode) {
          case "ADD":
            return "Novo Plano de Contas";
          case "EDIT":
            return "Editar Plano de Contas";
          default:
            return "Plano de Contas";
        }
      },
      save() {
        this.$validator.validateAll().then(valid => {
          if(valid) {
            this.saveExpenseTypes()
          }
        })
      }
    },
     watch: {
      pagination: {
        handler () {
          this.getExpenseTypes()
        },
      },
      searchInput (val) {
        var payload = {
          search: val
        }
        this.getContacts(payload)
      },
    },
  }
</script>

<style>
  .alert {
    background-color: white;
    z-index: 100;
    top: 50%;
  }
  .error--text {
    color: red !important;
  }
  .right {
    float: right;
  }
  .v-card__text {
    padding: 0px !important;
  }
</style>