<template>
  <div>
    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2000"
      v-model="showNotification"
    >
      {{ notificationMessage }}
    </v-snackbar>
    <v-list-item class="card-agent text-center justify-center elevation-10 agent-data">
      <v-list-item-content>
        <v-list-item-title class="headline">Jogador {{ player.name }}</v-list-item-title>
        <v-list-item-subtitle>
          <div>CPF: {{ player.cpf }}</div>
          <div>Nome: {{ player.name }}</div>
          <div>Telefone: {{ player.contact_id }}</div>
          <div>Data de Nascimento: {{ player.birth_date }}</div>
          <div>Email: {{ player.email }}</div>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-tabs v-model="tab" class="agent-data elevation-10">
      <v-tab>IDs relacionados</v-tab>
      <v-tab>Balanço</v-tab>
      <v-tab>Endereços</v-tab>
      <v-tab>Contas</v-tab>
      <v-tab>Documentos</v-tab>
    </v-tabs> 
    <v-tabs-items v-model="tab">
      <v-tab-item class="elevation-10">
        <v-card> 
          <v-card-title class="text-center justify-center">
              IDs Relacionados
          </v-card-title>
          <v-btn color="black"  @click="addPlayerId" dark class="add-align-right" small>
              <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-data-table
            :headers="headersPlayerIds"
            :loading="loadingPlayerId"
            :items="playerIds"
            :options.sync="paginationPlayerId"
            :server-items-length="totalPlayerIds"
            no-data-text="Nenhum id relacionado"
            class="elevation-1 table-subagent"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="showDialogDeletePlayerId(item)">mdi-delete</v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
      <v-tab-item >
        <balance-player :player_id="player_id"></balance-player>
      </v-tab-item>
      <v-tab-item>
        <address-player :player_id="this.$route.params.player_id"></address-player>
      </v-tab-item>
      <v-tab-item>
        <accounts-player :player_id="this.$route.params.player_id"></accounts-player>
      </v-tab-item>
      <v-tab-item class="elevation-10">
        <documents-player></documents-player>
      </v-tab-item>
    </v-tabs-items>

    <v-dialog :value="dialogPlayerId" @click:outside="closeDialogPlayerId" width="300">
      <v-card>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    required
                    label="Id Suprema"
                    v-model="id_pppoker"
                    v-validate="'required:IdPppoker,id_pppoker'"
                    data-vv-name="id_pppoker"
                    outlined
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-col>
            <v-btn text @click="closeDialogPlayerId" :disabled="loadingPlayerId">Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn text type="submit" right @click="savePlayerId">Salvar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog :value="dialogDeletePlayerId" @click:outside="closeDialogDeletePlayerId" width="300">
      <v-card>
        <v-card-title class="headline">Confirmar exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn
              text
              @click="closeDialogDeletePlayerId()"
            >Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn
              text
              type="submit"
              right
              @click="deletePlayerId"
            >Confirmar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import AddressPlayer from './address/AddressPlayer'
import AccountsPlayer from './accounts/AccountsPlayer'
import BalancePlayer from './balance_player/BalancePlayer'
import DocumentsPlayer from "./documents/DocumentsPlayer"

export default {
  name: "Player",
  computed: {
    ...mapState("player", [
      "documents",
      "loading_documents",
      "loading_id_related",
      "player",
    ]),
    ...mapFields("player", [
      "showNotification",
      "notificationMessage",
      "notificationType",
    ]),
    ...mapFields("player_ids", [
      "editedItem.id_pppoker",
    ]),
    ...mapFields("player_ids", {
      paginationPlayerId: "pagination"
    }),
    ...mapState("player_ids", {
        dialogPlayerId: "dialog",
        dialogDeletePlayerId: "dialogDelete",
        playerIds: "playerIds",
        loadingPlayerId: "loading",
        totalPlayerIds: "totalPlayerIds"
      }  
    ),
  },
  
  components: {
    "accounts-player": AccountsPlayer,
    "address-player": AddressPlayer, 
    "balance-player": BalancePlayer,
    "documents-player": DocumentsPlayer, 
  },
  created() {
    let player_id = this.$route.params.player_id;
    this.player_id = player_id;
    this.getPlayer({ player_id: player_id });
    this.getPlayerIds({ player_id: player_id })
  },
  data: () => ({
    headersDocuments: [
      {
        text: "Nome",
        value: "name",
      },
    ],
    headersPlayerIds: [
      { text: "Id", value: "id_pppoker", sortable: false, align: 'right' },
      { text: "Ações",  value: "actions", sortable: false, align: "center", width: '10%' }
    ],
    tab: '',
    valid: false
  }),
  methods: {
    ...mapMutations("rake_deals", {
      addRakeDeal: "add",
      editRakeDeal: "edit"
    }),
    ...mapMutations("player_ids", {
      addPlayerId: "add",
      closeDialogPlayerId: "closeDialog",
      editPlayerId: "edit"
    }),
    ...mapActions("player_ids", {
      closeDialogDeletePlayerId: "closeDeleteDialog",
      deletePlayerId: "deleteItem",
      getPlayerIds: "getPlayerIds",
      savePlayerId: "save",
      showDialogDeletePlayerId: "showDeleteDialog",
    }),
    ...mapActions("player", ["getPlayer", "goBack"]),
  },
  watch: {
    paginationPlayerId: {
      handler() {
        this.getPlayerIds({player_id: this.player_id});
      }
    }
  }
};
</script>

<style>
@import "./css/player.css";
</style>
