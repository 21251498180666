<template>
  <v-card @drop.prevent="addDropFile" @dragover.prevent>
    <v-card-title>
      <span class="headline text-center justify-center">Documento</span>

      <v-spacer></v-spacer>
      <v-progress-circular
        v-if="loading"
        indeterminate
        class="text-right"
        color="primary"
        size="24"
      ></v-progress-circular>
    </v-card-title>

    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12 sm6 md6>
              <v-text-field
                required
                label="Descrição*"
                v-model="description"
                :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                :error-messages="errors.collect('description')"
                v-validate="'required:Descrição,description'"
                data-vv-name="description"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md6  v-if="this.mode == 'VIEW'">
              <v-text-field
                required
                label="Nome do Arquivo*"
                v-model="file_name"
                :readonly="this.mode == 'VIEW' "
                :error-messages="errors.collect('file_name')"
                v-validate="'required:Nome do Arquivo,file_name'"
                data-vv-name="file_name"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md6>
              <v-select
                  label="Tipo de Documento *"
                  :items="types_document"
                  v-model="type_document"
                  :error-messages="errors.collect('type_document')"
                  v-validate="'required:Tipo de Documento,type_document'"
                  data-vv-name="type_document"
                  item-text="description"
                  item-value="id"
                  outlined
              ></v-select>
            </v-flex>
            <v-flex xs12 sm12 md12>
              <v-file-input
                class="mt-2"
                v-model="document_file"
                v-if="this.mode == 'ADD'"
                label="Arquivo"
                outlined
              ></v-file-input>
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-col>
        <v-btn text @click="closeDialog">{{ mode == 'VIEW' ? 'Voltar' : 'Cancelar'}}</v-btn>
      </v-col>
      <v-col class="text-right">
        <v-btn text type="submit" right @click="save">Salvar</v-btn>
      </v-col>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
    name: "DocumentsAgentForm",
    created() {
      this.$validator.extend("required", {
        getMessage: (field, [label]) => label + " deve ser informado",
        validate: (_, [, field]) => {
          if (this[field] || this[field] === 0) {
            return true;
          } else {
            return false;
          }
        },
      });
    },
    computed: {
        ...mapState("documents_player", ["loading", "find_agents", "mode"]),
        ...mapFields("documents_player", [
          "editedItem.description",
          "editedItem.file_name",
          "editedItem.type_document",
          "editedItem.last_update",
          "editedItem.document_file"
        ])
    },
    methods: {
      ...mapActions("documents_player", ["closeDialog", "save"]),
      addDropFile(e) {
        if (this.mode == "ADD" || this.mode == "EDIT") {
          if (e.dataTransfer) {
            if (e.dataTransfer.files[0]) {
              let file = e.dataTransfer.files[0];
              this.document_file = file
            }
          }
        }
      }
    },
    data: () => ({
      types_document: [
        {
          id: 0, 
          description: "Identidade/CPF"
        },
        {
          id: 1, 
          description: "CNPJ"
        },
        {
          id: 2, 
          description: "Comprovante de Endereço"
        },
        {
          id: 3, 
          description: "Contrato"
        },
        { 
          id: 4,
          description: "Outros"
        }
      ],
      valid: false
    })
}
</script>