<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline text-center justify-center">Filtro</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm12 md12>
                <v-text-field
                  dense
                  label="Nome"
                  v-model="name"
                  :clearable="true"
                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-select
                  dense
                  label="Cargo"
                  :items="roles"
                  v-model="role"
                  :error-messages="errors.collect('role')"
                  item-text="name"
                  item-value="id"
                  :clearable="true"
                  data-vv-name="role"
                  outlined
                >
                </v-select>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-select
                  dense
                  label="Setor"
                  :items="departments"
                  v-model="department"
                  :error-messages="errors.collect('department')"
                  item-text="name"
                  item-value="id"
                  :clearable="true"
                  data-vv-name="department"
                  outlined
                >
                </v-select>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-select
                  dense
                  append-icon="mdi-close"
                  @click:append="clearCompany()"
                  label="Empresa *"
                  :items="companies"
                  v-model="id_company"
                  item-text="name"
                  item-value="id"
                  outlined
                ></v-select>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-text-field
                  dense
                  label="CPF"
                  v-model="cpf"
                  :clearable="true"
                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm12 md12>
                <v-text-field
                  dense
                  label="E-mail"
                  v-model="email"
                  :clearable="true"
                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-text-field
                  dense
                  label="Telefone"
                  v-model="phone"
                  :clearable="true"
                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-text-field
                  dense
                  label="CEP"
                  v-mask="'#####-###'"
                  v-model="cep"
                  :clearable="true"
                  outlined
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn
            text
            @click="
              setShowFilter(false);
              clearFilter();
            "
            >Cancelar</v-btn
          >
        </v-col>
        <v-col class="text-right">
          <v-btn text type="submit" right @click="filter">Filtrar</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "CollaboratorsFilter",
  computed: {
    ...mapState("login", ["userData"]),
    ...mapState("collaborators", [
      "companies",
      "mode",
      "selectedItem",
      "roles",
      "departments",
      "showDialog",
    ]),
    ...mapFields("collaborators", [
      "filter.id",
      "filter.name",
      "filter.cpf",
      "filter.email",
      "filter.id_company",
      "filter.address",
      "filter.phone",
      "filter.role",
      "filter.department",
      "filter.cep",
      "filter.voucher",
    ]),
  },
  methods: {
    ...mapMutations("collaborators", ["setShowFilter", "clearFilter"]),
    ...mapActions("collaborators", ["getItems"]),

    clearCompany() {
      this.id_company = "";
    },

    filter() {
      this.getItems();
      this.setShowFilter(false);
    },
  },
  data: () => ({
    possible_status: [
      { status: 0, description: "Inativo" },
      { status: 1, description: "Ativo" },
    ],
  }),
};
</script>
