<template>
    <div>
        <v-overlay :value="loading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <v-snackbar
            :color="notification.type"
            right
            top
            :timeout="2000"
            v-model="notification.show"
        >
            {{ notification.message }}
        </v-snackbar>

        <v-toolbar flat class="elevation-5">
            <v-icon color="black" left >mdi-cash-plus</v-icon>
            <v-toolbar-title ><b> Contas a Receber</b></v-toolbar-title>
            <v-spacer></v-spacer>
            <div class="button-toolbar">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="black"
                            dark
                            class="ma-2"
                            @click="setShowFilter(true)"
                            v-bind="attrs"
                            v-on="on"
                            small
                        >
                            <v-icon color="white">mdi-filter</v-icon>
                        </v-btn>
                    </template>
                    <span>Filtro</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="black"
                            dark
                            class="ma-2"
                            @click="getItems"
                            v-bind="attrs"
                            v-on="on"
                            small
                            ><v-icon>mdi-refresh</v-icon></v-btn
                        >
                    </template>
                    <span>Recarregar</span>
                </v-tooltip>
            </div>
        </v-toolbar>

        <v-data-table
            :headers="headers"
            :loading="loading"
            :items="items"
            :options.sync="pagination"
            :server-items-length="totalItems"
            class="elevation-10 table-margin"
            no-data-text="Nenhuma conta encontrada"
        >
            <template v-slot:[`item.value`]="{ item }">
                <span>{{ formatMoney(item.value) }}</span>
            </template>
            <template v-slot:[`item.status`]="{ item }">
                <v-chip
                    :color="getClassStatus(item.status)"
                    class="ma-2"
                    text-color="white"
                    label
                    >{{ getStatusDescription(item.status) }}</v-chip
                >
            </template>
            <template v-slot:[`item.id_slot`]="{ item }">
                <span class="ma-2">{{ getSlotName(item.id_slot) }}</span>
            </template>
            <template v-slot:[`item.date_created`]="{ item }">
                <span>{{ formatDate(item.date_created) }}</span>
            </template>
            <template v-slot:[`item.due_date`]="{ item }">
                <span>{{ formatDateDue(item.due_date) }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ attrs, on }">
                        <v-icon
                            small
                            color="black"
                            class="mr-2"
                            v-bind="attrs"
                            v-on="on"
                            @click="viewItems(item)"
                            >mdi-information</v-icon
                        >
                    </template>
                    <span>Informações</span>
                </v-tooltip>
            </template>
        </v-data-table>

        <v-dialog
            :value="showDialog"
            @click:outside="setShowDialog(false)"
            width="1000"
        >
            <bills-items></bills-items>
        </v-dialog>

        <v-dialog
            :value="filterDialog"
            @click:outside="setShowFilter(false), clearFilter()"
            width="600"
        >
            <bills-to-receive-filter></bills-to-receive-filter>
        </v-dialog>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import { formatDate, formatMoney } from '@util/format_util'

import BillsToReceiveFilter from './BillsToReceiveFilter'
import BillsItems from '../bills_items/BillsItems.vue'

export default {
    name: 'BillsToReceive',

    created() {
        this.getItems()
        this.getSlots({ noPages: true })
    },

    components: {
        BillsToReceiveFilter,
        BillsItems,
    },

    methods: {
        ...mapActions('bills_to_receive', ['getItems', 'deleteItem']),
        ...mapActions('slots', { getSlots: 'getSlots' }),
        ...mapActions('bills_items', { getBillsItems: 'getItems' }),
        ...mapMutations('bills_items', ['setTypeAndIdBill']),
        ...mapMutations('bills_to_receive', [
            'setShowDialog',
            'view',
            'setShowFilter',
            'clearFilter',
        ]),
        getClassStatus(status) {
            if (status == 0) {
                return 'red'
            } else if (status == 1) {
                return 'green'
            }
        },
        viewItems(item) {
            this.setShowDialog(true)
            this.setTypeAndIdBill({ id: item.id, bill_type: 1 })
            this.getBillsItems()
        },
        getSlotName(id) {
            const slot = this.slots.find((element) => element.id == id)
            return slot.name
        },
        formatMoney(value) {
            return formatMoney(value)
        },
        formatDate(date) {
            return formatDate(date)
        },
        formatDateDue(date) {
            let day = date.split('-')[2]
            let month = date.split('-')[1]
            let year = date.split('-')[0]
            return `${day}/${month}/${year}`
        },
        getStatusDescription(status) {
            switch (status) {
                case 0:
                    return 'Pendente'
                case 1:
                    return 'Concluído'
            }
        },
    },
    computed: {
        ...mapState('slots', ['slots']),
        ...mapState('bills_to_receive', [
            'items',
            'notification',
            'mode',
            'loading',
            'totalItems',
            'showDialog',
            'filterDialog',
        ]),
        ...mapFields('bills_to_receive', ['pagination']),
    },
    data: () => ({
        headers: [
            { text: 'ID', value: 'id', sortable: true, align: 'center' },
            {
                text: 'ID Cliente',
                value: 'id_client',
                sortable: false,
                align: 'center',
            },
            {
                text: 'Status',
                value: 'status',
                sortable: false,
                align: 'center',
            },
            { text: 'Value', value: 'value', sortable: false, align: 'center' },
            {
                text: 'Data de Criação',
                value: 'date_created',
                sortable: false,
                align: 'center',
            },
            {
                text: 'Data de Vencimento',
                value: 'due_date',
                sortable: false,
                align: 'center',
            },
            {
                text: 'Slot',
                value: 'id_slot',
                sortable: false,
                align: 'center',
            },
            {
                text: 'ID Período',
                value: 'id_period',
                sortable: false,
                align: 'center',
            },
            {
                text: 'ID Fechamento',
                value: 'id_closure',
                sortable: false,
                align: 'center',
            },
            {
                text: 'ID App',
                value: 'id_app',
                sortable: false,
                align: 'center',
            },
            {
                text: 'Ações',
                value: 'actions',
                sortable: false,
                align: 'center',
                width: '10%',
            },
        ],
    }),
    watch: {
        pagination: {
            handler() {
                this.getItems({ resetPage: false })
            },
        },
    },
}
</script>
