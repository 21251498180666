<template>
<div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    
    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2000"
      v-model="showNotification"
    >
        {{ notificationMessage }}
    </v-snackbar>
    <v-toolbar flat class="elevation-5">
      <div>
        <v-toolbar-title>Transações</v-toolbar-title>
      </div>
      <v-spacer></v-spacer>
      <div class="button-toolbar">
        <download-excel
          :before-generate="generateSheetTransactions"
          :before-finish="finishSheetTransactions"
          :name="getSheetNameTransactions()"
          :fetch="getPeriodClosureTransactionsNoPage"
          :export-fields="sheet_fields_transactions"
          class="excel"
          worksheet="Fechamento Agentes"
          type="xls"
        >
          <v-btn color="black" dark class="ma-2" small>
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </download-excel>
        <v-tooltip bottom>
          <template v-slot:activator="{on, attrs}">
            <v-btn color="black" dark class="ma-2" @click="downloadXlsx({ show: true })" v-bind="attrs" v-on="on" small>
              <v-icon>mdi-file-excel</v-icon>
            </v-btn>
          </template>
          <span>Gerar Planilha</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="black" dark class="ma-2" @click="showFilterTransactions({show: true})" small>
              <v-icon :color="filteredTransactions ? 'grey' : 'white'">mdi-filter</v-icon>
            </v-btn> 
          </template>
          <span>Filtrar</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="black" dark class="ma-2" @click="getPeriodClosureTransactions" small>
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Recarregar</span>
        </v-tooltip>
      </div>
    </v-toolbar>


    <v-data-table
      :headers="headersTransactions"
      :loading="loadingTransactions"
      :items="itemsTransactions"
      :options.sync="paginationTransactions"
      :server-items-length="totalItemsTransaction"    
      no-data-text="Nenhuma registro encontrado"
      class="elevation-10 table-margin"
      :item-class= "getClassColorTransaction" 
    >
      <template v-slot:[`item.date`]="{ item }">
        <span>{{ getDateTime(item.date)}}</span>
      </template>
      <template v-slot:[`item.value`]="{ item }">
        <span>{{ item.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
      </template>
      <template v-slot:[`item.type_transaction`]="{ item }">
        <span>{{ item.type_transaction == 0 ? "Saída" : "Entrada" }}</span>
      </template>
      <template v-slot:[`item.suspect`]="{ item }">
        <span>{{ item.suspect == 0 ? "Não" : item.suspect == 1 ? "Sim" : "Sim/Validada" }}</span>
      </template>
      <template v-slot:[`item.type_suspect`]="{ item }">
        <span>{{ item.type_suspect == 0 ? "Interno - Externo" : item.type_suspect == 1 ? "Externo - Interno" : "-"}}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }"> 
        <v-tooltip bottom v-if="item.status != 2 && item.status != 3 && item.type_deal != 4">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" small class="mr-2" @click="checkSuspectTransaction({item: item})">mdi-check</v-icon>
          </template>
          <span>Validar Transação</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog :value="dialogFilterTransactions" width="600" @click:outside="showFilterTransactions({show: false})">
      <periods-closure-transactions-filter :showPeriod="true"></periods-closure-transactions-filter>
    </v-dialog>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import { mapFields } from 'vuex-map-fields'
  import PeriodsClosureTransactionsFilter from './PeriodsClosureTransactionsFilter'
  // import JsonExcel from 'vue-json-excel'
  import { utils, writeFile } from "xlsx";

  export default {
    name: 'PeriodsClosureTransactions',
    computed: {
      ...mapState('login', ['userData']),
      ...mapState('periods_closure_transactions', ['dialog', 'item', 'errors', 'filteredTransactions', 'loadingTransactions', 'mode', 'notificationType', 'notificationMessage', 'totalItemsTransaction', 'itemsTransactions']),
      ...mapFields('periods_closure_transactions', [
          'paginationTransactions',
          'dialogFilterTransactions',
          'dialogDelete',
          'overlay',
          'showNotification'
        ])
    },
    created() {
      this.getPeriodClosureTransactions({resetPage: false})
    },
    components: {
      // "download-excel": JsonExcel,
      "periods-closure-transactions-filter": PeriodsClosureTransactionsFilter
    },
    methods: {
      ...mapMutations('periods_closure_transactions', ['showFilterTransactions']),
      ...mapActions('periods_closure_transactions', ['closeDialog', 'closeDeleteDialog', 'deleteItem', 'getPeriodClosureTransactions', 'getPeriodClosureTransactionsNoPage', 'verifyItem', 'showDeleteDialog']),
      isMobile() {
        if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
          return true;
        }
        return false
      },
      getClassColorTransaction(item) {
        if(item.type_transaction == 0) {
          return "row-red"
        } else {
          return "row-green"
        }
      },
      finishSheetTransactions() {
        this.overlay = false
      },
      generateSheetTransactions() {
        this.overlay = true
      },
      getDateTime(date) {
        if (date) {
          var d = new Date(date);
          return d.toLocaleDateString() + " " + d.toLocaleTimeString();
        }
        return "";
      },
      getSheetName() {
        var date = new Date()
        var name = 'periods_closure_transactions_' + date.toLocaleDateString() + " " + date.toLocaleTimeString()
        return name+'.xls'
      },
      getSheetNameTransactions() {
        var date = new Date();
        var name =
          "transacoes_fechamento_" + 
          date.toLocaleDateString() +
          " " +
          date.toLocaleTimeString();
        return name + ".xls";
      },
      async downloadXlsx() {
        console.log('1 opa')
        this.generateSheetTransactions()
        console.log('2 opa')
        try {
        console.log('3 opa')
          let itemsNoPage = await this.getPeriodClosureTransactionsNoPage()
        console.log('4 opa')
          if(itemsNoPage) {
        console.log('5 opa')
            const wb = utils.book_new()
              let items = itemsNoPage.map(row => ({
              "Clube":  row.club_name,
              "Periodo":  row.id_period_closure,
              "Data":  this.dateXLSX(row.date),
              "Agente":  row.id_agent_app,
              "Jogador":  row.id_player_app,
              "Tipo":  this.typeTransactionXLSX(row.type_transaction),
              "Valor":  this.valueXLSX(row.club_name),
              "Suspeita":  this.suspectXLSX(row.suspect),
              "Tipo Suspeita":  this.typeSuspect(row.type_suspect),
            }))
            const ws = utils.json_to_sheet(items)
            utils.book_append_sheet(wb, ws, "Despesas")
            await writeFile(wb, this.getSheetNameTransactions())
          }
        } catch (error) {
          this.finishSheetTransactions()
        } finally {
          this.finishSheetTransactions()
        }
      },
      dateXLSX: (value) => {
        if (value) {
          var d = new Date(value)
          return d.toLocaleDateString() + " " + d.toLocaleTimeString()
        }
        return ""
      },
      typeTransactionXLSX: (value) => {
        return value == 0 ? "Saída" : "Entrada"
      },
      valueXLSX: (value) => {
        if (value) {
          let real = value.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })
          if(value < 0) {
            real = real.substr(4)
            return "-" + real
          } else {
            return real.substr(3)
          }
        }
        return 0
      },
      suspectXLSX: (value) => {
        return value == 0 ? "Não" : "Sim"
      },
      typeSuspectXLSX: (value) => {
        return value == 0 ? "Interno - Externo" : value == 1 ? "Externo - Interno" : "-"
      },
    },
    data: () => ({
    headersTransactions: [
        { text: "Id", value: "id", sortable: false },
        { text: "Period", value: "id_period_closure", sortable: false },
        { text: "Clube", value: "club_name", sortable: false },
        { text: "Data", value: "date", sortable: false },
        { text: "Gestor", value: "id_manager_app", sortable: false },
        { text: "Agente", value: "id_agent_app", sortable: false },
        { text: "Player", value: "id_player_app", sortable: false },
        { text: "Tipo", value: "type_transaction", sortable: false },
        { text: "Valor", value: "value", sortable: false },
        { text: "Suspeita", value: "suspect", sortable: false },
        { text: "Tipo Suspeita ( G - J )", value: "type_suspect", sortable: false },
        { text: "Ações", value: "actions", sortable: false }
      ],
      sheet_fields_transactions: {
        "Clube": {
          field: "club_name",
        },
        "Periodo": {
          field: "id_period_closure"
        },
        "Data": {
          field: "date",
          callback: (value) => {
            if (value) {
              var d = new Date(value);
              return d.toLocaleDateString() + " " + d.toLocaleTimeString();
            }
            return "";
          }
        },
        "Gestor": {
          field: "id_manager_app"
        },
        "Agente": {
          field: "id_agent_app"
        },
        "Jogador": {
          field: "id_player_app"
        },
        "Tipo": {
          field: "type_transaction",
          callback: (value) => value == 0 ? "Saída" : "Entrada"
        },
        "Valor": {
          field: "value",
          callback: (value) => {
            if (value) {
              let real = value.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              });
              if(value < 0) {
                real = real.substr(4);
                return "-" + real
              } else {
                return real.substr(3);
              }
            }
            return 0;
          }
        },
        "Suspeita": {
          field: "suspect",
          callback: (value) => value == 0 ? "Não" : "Sim"
        },
        "Tipo Suspeita": {
          field: "type_suspect",
          callback: (value) => value == 0 ? "Interno - Externo" : value == 1 ? "Externo - Interno" : "-"
        },
      },
      valid: false
    }),
    watch: {
      paginationTransactions: {
        handler () {
          this.getPeriodClosureTransactions({resetPage: false})
        },
      },
    },
  }
</script>

<style>

</style>