<template>
  <div>
    <v-dialog :value="showDialog" persistent width="600">
      <v-card>
        <v-card-title>
          <span class="headline">Solicitações</span>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm6 md12>
                  <v-text-field
                    required
                    label="Título *"
                    v-model="title"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('title')"
                    v-validate="'required'"
                    data-vv-name="title"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-select
                    label="Setor de origem *"
                    required
                    :items="possible_modules"
                    v-model="department_origin"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('department_origin')"
                    v-validate="'required'"
                    data-vv-name="department_origin"
                    outlined
                  >
                  </v-select>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-select
                    label="Setor de destino *"
                    required
                    :items="possible_modules"
                    v-model="department_destination"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('department_destination')"
                    v-validate="'required'"
                    data-vv-name="department_destination"
                    outlined
                  >
                  </v-select>
                </v-flex>


                <v-flex xs12 sm6 md12>
                  <v-textarea
                    required
                    label="Descrição *"
                    v-model="description"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('description')"
                    v-validate="'required'"
                    data-vv-name="description"
                    outlined
                    :rows="3"
                  ></v-textarea>
                </v-flex>

                <template v-if="userIsAdmin() && this.mode == 'EDIT'">

                  <v-flex xs12 sm6 md12>
                    <v-textarea
                      required
                      label="Observação *"
                      v-model="observation"
                      :readonly="this.mode == 'VIEW'"
                      :error-messages="errors.collect('observation')"
                      data-vv-name="observation"
                      outlined
                      :rows="3"
                    ></v-textarea>
                  </v-flex>

                  <v-flex xs12 sm6 md6>
                    <v-select
                      label="Status *"
                      required
                      :items="possible_status"
                      v-model="status"
                      :readonly="this.mode == 'VIEW'"
                      :error-messages="errors.collect('status')"
                      v-validate="'required'"
                      data-vv-name="status"
                      outlined
                      item-text="text"
                      item-value="value"
                    >
                    </v-select>
                  </v-flex>
                  
                </template>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-col>
            <v-btn
              text
              @click="
                setShowDialog(false);
                setSelectedItem({});
              "
              >{{ mode == "VIEW" ? "Voltar" : "Cancelar" }}</v-btn
            >
          </v-col>
          <v-col class="text-right">
            <v-btn
              text
              type="submit"
              right
              v-if="mode == 'EDIT' || mode == 'ADD'"
              @click="saveRequest"
              >Salvar</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  created() {
    this.$validator.extend("max_length", {
      getMessage: (_, [length]) => "No máximo " + length + " caracteres.",
      validate: (val, [length]) => {
        if (val.length > length) {
          return false;
        } else {
          return true;
        }
      },
    });
    this.$validator.extend("min_length", {
      getMessage: (_, [length]) => "No mínimo " + length + " caracteres.",
      validate: (val, [length]) => {
        if (val.length < length) {
          return false;
        } else {
          return true;
        }
      },
    });
  },
  computed: {
    ...mapState("requests", [
      "mode",
      "showDialog",
      "creation_date",
     
    ]),
    ...mapState("login", ["userData"]),
    ...mapFields("requests", [
      "selectedItem.id",
      "selectedItem.title",
      "selectedItem.description",
      "selectedItem.department_origin",
      "selectedItem.department_destination",
      "selectedItem.status",
      "selectedItem.observation",
    ]),
  },
  methods: {
    ...mapMutations("requests", ["setShowDialog", "setSelectedItem"]),
    ...mapActions("requests", ["save"]),
    saveRequest() {
      this.$validator.validateAll().then((valid) => {
        if (valid) this.save();
      });
    },
    userIsAdmin() {
      return [99, 98, 97, 96, 95, 94].includes(this.userData.role);
    },
  },
  data: () => ({
    possible_modules: [
      "Atendimento",
      "Comercial",
      "Diretoria",
      "Financeiro",
      "Marketing",
      "RH",
      "TI",
    ],
    possible_status: [
      {
        text: "Pendente",
        value: 0,
      },
      {
        text: "Concluido",
        value: 1,
      },
      {
        text: "Recusado",
        value: 2,
      },
    ],
    timeProps: {
      format: "24hr",
    },
  }),
  
};
</script>