import axios from "axios";
import { getField, updateField } from "vuex-map-fields";
import endPoints from "../../../config/endPoints";
import { defaultErrorNotification } from "../../../constants";


const defaultFilter = {
    id: "",
    score: "",
    user_name: "",
    status: "",
    created_at: "",
    finish_at: ""
}

const defaultAvaliationItem = {
  id: "",
  score: "",
  user_name: "",
  status: "",
  created_at: "",
  finish_at: ""
};

export default {
  namespaced: true,
  state: {
    items: [],
    selectedItem: {},
    mode: "",
    loading: false,
    totalItems: 10,
    deleteDialog: false,
    filterDialog: false,
    showDialog:false,
    filter: {
      created_at: "",
      finish_at: "",
      id: "",
      user_name: "",
      score: "",
      status: ""
    },
    notification: {
      show: false,
      message: "",
      type: "",
    },
    pagination: {
      page: 1,
      itemsPerPage: 10,
    },
    possible_avaliations: [
      {score: 0, avaliation: "Não Avaliado"},
      {score: 1, avaliation: "Insatisfeito"},
      {score: 2, avaliation: "Satisfeito"},
      {score: 3, avaliation: "Muito Satisfeito"}
      ],
      possible_status: [
        {status: 1, description: "Em aberto"},
        {status: 2, description: "Finalizado"}
      ]
      
  },
  mutations: {
    view: (state, payload) => {
      state.selectedItem = payload;
      state.showDialog = true;
      state.mode = "VIEW";
    },
    clearFilter: (state) => {
        state.filter = Object.assign({}, defaultFilter)
    },
    setItems: (state, payload) => {
        state.items = payload
    },
    setTotalItems: (state, payload) => (state.totalItems = payload),
    setLoading: (state, payload) => (state.loading = payload),
    showNotification: (state, payload) => (state.notification = payload),
    setSelectedItem: (state, payload) => state.selectedItem = payload,
    setShowDialog: (state, payload) => {
      state.showDialog = payload;
      state.selectedItem = Object.assign({}, defaultAvaliationItem);
    },
    setPage: (state, payload) => {
      state.pagination.page = payload.page;
      state.pagination.itemsPerPage = payload.itemsPerPage;
    },
    setShowFilter: (state, payload) => (state.filterDialog = payload),
    updateField,
  },
  actions: {
    getItems: async ({ commit, state }, payload) => {
      let filter = state.filter
      let url = endPoints.avaliations;
      if (payload.resetPage) {
        url += "?page=" + 1 + "&" + "itemsPerPage=" + 10;
        commit("setPage", { page: 1, itemsPerPage: 10 });
      } else {
        url +=
          "?page=" +
          state.pagination.page +
          "&" +
          "itemsPerPage=" +
          state.pagination.itemsPerPage;
      }

      Object.keys(filter).forEach(function(field) {
        if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
          url += "&" + field + "=" + filter[field]
        }
      })

      if (state.pagination && state.pagination.sortBy && state.pagination.sortBy.length > 0) {
        url += "&sort_by=" + (state.pagination.sortBy[0] === 'score' ? "id" : state.pagination.sortBy[0])
        url += "&sort_desc=" + state.pagination.sortDesc[0]
      }

      commit("setLoading", true);

      try {
        let items = await axios.get(url);
        commit("setItems",items.data.data)
        commit("setTotalItems", items.data.total);
      } catch (error) {
        commit(
          "showNotification",
          defaultErrorNotification(error?.response?.data?.message)
        );


      }
      commit("setLoading", false);
    }
  },
  getters: {
    getField,
  },
};
