<template>
    <div>
        <v-dialog :value="dialogForm" persistent width="600">
            <v-card>
                <v-card-title>
                    <span class="headline">Adicionar Fechamento</span>
                </v-card-title>

                <v-card-text>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-container grid-list-md>
                            <v-layout wrap>
                                <v-flex xs12 sm12 md12>
                                    <v-text-field
                                        required
                                        label="Descrição *"
                                        v-model="description"
                                        :readonly="this.mode == 'VIEW'"
                                        :error-messages="
                                            errors.collect('description')
                                        "
                                        v-validate="
                                            'required:Description,description'
                                        "
                                        data-vv-name="Descrição"
                                        outlined
                                    ></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm12 md12>
                                    <v-select
                                        required
                                        :clearable="true"
                                        clear-icon="mdi-close"
                                        label="Liga"
                                        :items="items"
                                        :readonly="this.mode == 'VIEW'"
                                        v-model="id_league"
                                        item-text="name"
                                        item-value="id"
                                        :error-messages="
                                            errors.collect('id_league')
                                        "
                                        v-validate="
                                            'required:Liga,id_league'
                                        "
                                        data-vv-name="Liga"
                                        outlined
                                    >
                                    </v-select>
                                </v-flex>

                                <v-flex xs12 sm6 md6>
                                    <v-menu
                                        v-model="showStart"
                                        :close-on-content-click="true"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px"
                                    >
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-text-field
                                                v-model="start_formatted"
                                                label="Inicio *"
                                                outlined=""
                                                readonly
                                                append-icon="mdi-close"
                                                @click:append="clearStart()"
                                                persistent-hint
                                                :error-messages="
                                                    errors.collect('start_date')
                                                "
                                                v-validate="
                                                    'required:Inicio,start_date'
                                                "
                                                data-vv-name="start_date"
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            locale="pt-br"
                                            v-model="start_date"
                                            no-title
                                            @input="(e) => setStart(e)"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-flex>

                                <v-flex xs12 sm6 md6>
                                    <v-menu
                                        v-model="showFinish"
                                        :close-on-content-click="true"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px"
                                    >
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-text-field
                                                v-model="finish_formatted"
                                                label="Fim *"
                                                outlined=""
                                                readonly
                                                append-icon="mdi-close"
                                                @click:append="clearFinish()"
                                                persistent-hint
                                                :error-messages="
                                                    errors.collect(
                                                        'finish_date',
                                                    )
                                                "
                                                v-validate="
                                                    'required:Fim,finish_date'
                                                "
                                                data-vv-name="finish_date"
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            locale="pt-br"
                                            v-model="finish_date"
                                            no-title
                                            @input="(e) => setFinish(e)"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-col>
                        <v-btn text @click="closeDialogForm">{{
                            mode == 'VIEW' ? 'Voltar' : 'Cancelar'
                        }}</v-btn>
                    </v-col>
                    <v-col class="text-right">
                        <v-btn
                            text
                            type="submit"
                            right
                            v-if="mode == 'EDIT' || mode == 'ADD'"
                            @click="save"
                            >Salvar</v-btn
                        >
                    </v-col>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
    name: 'LeagueClosureForm',
    created() {
        this.getItems()
    },
    methods: {
        ...mapMutations('league_closure', ['setSelectedItem']),
        ...mapActions('league', ['getItems']),
        ...mapActions('league_closure', [
            'save',
            'showDialogForm',
            'closeDialogForm',
        ]),
        formatDate(date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`
        },
        clearStart() {
            this.start_date = ''
            this.start_formatted = ''
        },
        clearFinish() {
            this.finish_date = ''
            this.finish_formatted = ''
        },
        setStart(date) {
            this.start_date = date
            this.showStart = false
            this.start_formatted = this.formatDate(date)
        },
        setFinish(date) {
            this.finish_date = date
            this.showFinish = false
            this.finish_formatted = this.formatDate(date)
        },
    },
    computed: {
        ...mapState('league', ['items']),
        ...mapState('league_closure', ['mode', 'selectedItem', 'dialogForm']),
        ...mapFields('league_closure', [
            'selectedItem.id',
            'selectedItem.description',
            'selectedItem.start_date',
            'selectedItem.finish_date',
            'selectedItem.value',
            'selectedItem.id_league',
            'selectedItem.total_clubs',
            'start_formatted',
            'finish_formatted',
        ]),
    },
    data: () => ({
        showStart: false,
        showFinish: false,
        valid: false,
    }),
}
</script>