import axios from "axios";
import { getField, updateField } from "vuex-map-fields";
import endPoints from "@config/endPoints";
// import {
//   defaultErrorNotification,
//   defaultSuccessNotification,
// } from "@constants";
// import formatDate from '@util/formatDate.js'
// const defaultPagination = {
//   page: 1,
//   itemsPerPage: 10,
// };
const paramsQuery = [
  {
    id: 0,
    name: "period_conciliation_orders",
    initials: "pco",
    column: "order_id"
  },
  {
    id: 1,
    name: "period_conciliation_transactions",
    initials: "pct",
    column: "transaction_id"
  },
  {
    id: 2,
    name: "period_conciliation_moviments",
    initials: "pcm",
    column: "moviment_id"
  },
  {
    id: 3,
    name: "period_conciliation_bank_moviments",
    initials: "pcbm",
    column: "bank_moviment_id"
  },
  {
    id: 4,
    name: "period_conciliation_annotated",
    initials: "pca",
    column: "annotated_id"
  },
  {
    id: 5,
    name: "period_conciliation_closures",
    initials: "pcc",
    column: "closure_id"
  },
]

export default {
  namespaced: true,
  state: {
    lowTypes: [
      { id: 1, description: 'Erro' },
      { id: 2, description: 'Fora do Período' },
      { id: 3, description: 'Número de Vinculações Incorreto' },
      { id: 4, description: 'Jogador Saiu do Clube' },
      { id: 5, description: 'Transferencia de Fichas entre Clubes' },
      { id: 6, description: 'Reembolso - Saque Cancelado' },
      { id: 99, description: 'Outros' },
    ],
    tab_origin: null,
    type_transaction: null,
    low:{
      low_type: null,
      observation_low: "",
    },
    multiples: false,
    // dialogManualLow: false,
    itemsToLow: [],
    closeAllDialogsManualLow: false,
  },
  mutations: {
    
    showDialogManualLow: (state, payload) => {
      state.tab_origin = payload.tab_origin;
      state.multiples = payload.multiples;
      if (payload.type_transaction != null){
        state.type_transaction = payload.type_transaction;
      }
      state.itemsToLow = payload.items;
    },
    setCloseAllDialogs: (state) => {      
      state.closeAllDialogsManualLow = !state.closeAllDialogsManualLow;
    },
    setTypeTransaction: (state, type) => {
      state.type_transaction = type;
    },

    updateField,
  },
  actions: {
    setCloseDialogManualLow: ({state, commit}) => {
      state.low = Object.assign({}, {});
      state.tab_origin = null;
      state.multiples = false;
      state.itemsToLow = [];
      commit('setCloseAllDialogs')
    },
    updateTotalVinculations: async function ({ state, commit, dispatch }) {
      let url = endPoints.conciliation;
      let data = paramsQuery[state.tab_origin]
      let origin = state.tab_origin
      data.low_type = state.low.low_type
      data.observation_low = state.low.observation_low
      if (state.multiples == true){
        data.items = state.itemsToLow
        url += '/update_total_vinculations_multiples';
      }else{
        url += '/update_total_vinculations';
        data.id = state.itemsToLow[0].id
        data.new_total_vinculations = state.itemsToLow[0].linked_to
      }
      axios.post(url, data).then(
        () => {
          let notification = {
            show: true,
            message: "Registro atualizado com sucesso.",
            notificationTime: 3000,
            type: "success"
          }
          commit('period_conciliation/showNotification', notification, { root: true })
          let path = `${data.name}/getItems`
          if (origin == 1) {
            dispatch('period_conciliation_transactions/getItems', { type: state.type_transaction }, { root: true })
          } else {
            dispatch(path, '', { root: true })
          }
          commit('period_conciliation_dialog_vinculation/setCloseDialog', '', { root: true })
        },
        error => {
          console.log(error)
          let notification = {
            show: true,
            message: "Erro ao atualizar registro.",
            notificationTime: 3000,
            type: "error"
          }
          // commit('showOverlay', { show: false })
          commit('period_conciliation/showNotification', notification, { root: true })
          return
        }
      )
      dispatch('setCloseDialogManualLow')
    },
    setPeriod: function ({ commit }, payload) {
      commit("setPeriod", { id: payload.period })
    },
  },
  getters: {
    getField,
  },
};
