<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2000"
      v-model="showNotification"
    >
      {{ notificationMessage }}
    </v-snackbar>
    <v-toolbar flat class="elevation-5">
      <div>
        <v-toolbar-title>Faturamento</v-toolbar-title>
      </div>
      <v-spacer></v-spacer>
      <div class="button-toolbar">
        <v-btn
          color="black"
          dark
          class="ma-2"
          @click="showFilter({ show: true })"
          small
          ><v-icon>mdi-filter</v-icon></v-btn
        >
        <v-btn color="black" dark class="ma-2" @click="reload" small
          ><v-icon>mdi-refresh</v-icon></v-btn
        >
        <v-btn color="black" dark class="ma-2" @click="add" small
          ><v-icon>mdi-plus</v-icon></v-btn
        >
      </div>
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :loading="loading"
      :items="items"
      :options.sync="pagination"
      :server-items-length="totalItems"
      no-data-text="Nenhum item encontrado"
      class="elevation-10 table-margin"
    >
      <template v-slot:[`item.value`]="{ item }">
        <span>{{
          item.value.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })
        }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <!-- <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" small color="blue" class="mr-2" @click="edit({item: item})">mdi-pencil</v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip> -->
        <v-tooltip bottom>
          <template v-slot:activator="{on, attrs}">
            <v-icon small v-on="on" v-bind="attrs" color="red" class="mr-2" @click="showDialogDelete(item)">
                mdi-delete
            </v-icon>
          </template>
          <span>Excluir</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{on, attrs}">
            <v-icon small v-on="on" v-bind="attrs" color="yellow darken-3" class="mr-2" @click="viewItem(item)">
                mdi-information
            </v-icon>
          </template>
          <span>Informações</span>
        </v-tooltip>
        <!-- <v-tooltip bottom>
          <template v-slot:activator="{on, attrs}">
            <v-icon small v-on="on" v-bind="attrs" color="green darken-2" class="mr-2" @click="showDialogDelete(item)">
                mdi-calculator
            </v-icon>
          </template>
          <span>Informações</span>
        </v-tooltip> -->
      </template>
      <template v-slot:[`item.start_date`]="{ item }">
        <span>{{ getDate(item.start_date)}}</span>
      </template>
      <template v-slot:[`item.finish_date`]="{ item }"> 
        <span>{{ getDate(item.finish_date)}}</span>
      </template>
      <template v-slot:[`item.rake`]="{ item }"> 
        <span>{{ getMoney(item.rake)}}</span>
      </template>
      <template v-slot:[`item.rakeback`]="{ item }"> 
        <span>{{ getMoney(item.rakeback)}}</span>
      </template>
      <template v-slot:[`item.app_tax`]="{ item }"> 
        <span>{{ getMoney(item.app_tax)}}</span>
      </template>
      <template v-slot:[`item.revenues`]="{ item }"> 
        <span>{{ getMoney(item.revenues)}}</span>
      </template>
    </v-data-table>

    <v-dialog :value="dialogDelete" persistent width="300">
      <v-card>
        <v-card-title class="headline">Confirmar exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn
              text
              @click="closeDialogDelete()"
            >Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn
              text
              type="submit"
              right
              @click="deleteItem"
            >Confirmar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      :value="dialogFilter"
      width="600"
      @click:outside="showFilter({ show: false })"
    >
      <weekly-revenue-filter></weekly-revenue-filter>
    </v-dialog>
    <v-dialog
      :value="dialogForm"
      width="600"
      @click:outside="showDialogForm({ show: false })"
    >
      <weekly-revenue-form></weekly-revenue-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import WeeklyRevenueFilter from './WeeklyRevenueFilter'
import WeeklyRevenueForm from './WeeklyRevenueForm'

export default {
  name: "WeeklyRevenue",
  components: {
      WeeklyRevenueFilter,
      WeeklyRevenueForm
  },
  computed: {
    ...mapState("weekly_revenue", [
      "dialogFilter",
      "dialogForm",
      "dialogDelete",
      "item",
      "loading",
      "mode",
      "notificationType",
      "notificationMessage",
      "rowsPerPage",
      "totalItems",
      "items",
      "overlay",
      "showNotification",
    ]),
    ...mapFields("weekly_revenue", ['pagination'])
  },
  created() {
    this.getItems({ resetPage: false });
  },
  methods: {
    ...mapMutations("weekly_revenue", [
        "showFilter",
        "showDialogForm",
        "add",
        "edit",
    ]),
    ...mapActions("weekly_revenue", [
      "getItems",
      "deleteItem",
      "showDialogDelete",
      "closeDialogDelete",
      "viewItem",
    ]),
    reload(){
      this.getItems({ resetPage: false })
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      }
      return false;
    },
    getClassColor(item) {
      if (item.status == 1) {
        return "row-green";
      } else if (item.status == 0) {
        return "row-yellow-schedule";
      } else if (item.status == 2) {
        return "row-red";
      }
    },
    getMoney(money) {
      if (money == null) {
        return "-----"
      } else {
        return money.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      }
    },
    getValue(value) {
      return value != null ? value : "-----";
    },
    getDate(date) {
      if (date != null && date != "") {
        var d = new Date(date + " 12:00:00");
        return d.toLocaleDateString();
      } else {
        return "--------"
      }     
    },
  },
  data: () => ({
    headers: [
      { text: "ID", value: "id", sortable: true },
      { text: "Descrição", value: "description", sortable: false },
      { text: "Início", value: "start_date", sortable: false },
      { text: "Fim", value: "finish_date", sortable: false },
      { text: "Rake da Liga", value: "value", sortable: true },
      { text: "Total de Clubes", value: "total_clubs", sortable: true },
      { text: "Liga", value: "name_league", sortable: true },
      { text: "Rake", value: "rake", sortable: true },
      { text: "Rakeback", value: "rakeback", sortable: true },
      { text: "Taxa do App", value: "app_tax", sortable: true },
      { text: "Faturamento", value: "revenues", sortable: true },
      { text: "Ações", value: "actions", sortable: false },
    ],
    valid: false,
    timeProps: {
      format: "24hr",
    },
  }),
  watch: {
    pagination: {
      handler() {
        this.getItems({ resetPage: false });
      },
    },
  },
};
</script>

<style>

</style>
