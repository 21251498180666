<template>
  <div>
    <v-dialog :value="showDialog" persistent width="600">
      <v-card>
        <v-card-title>
          <span class="headline">Atualizações</span>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Título *"
                    v-model="title"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('title')"
                    v-validate="'required'"
                    data-vv-name="title"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-select
                    label="Módulo *"
                    required
                    :items="possible_modules"
                    v-model="module"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('module')"
                    v-validate="'required'"
                    item-text="module"
                    item-value="module"
                    data-vv-name="module"
                    outlined
                  >
                  </v-select>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Versão *"
                    v-model="version"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('version')"
                    data-vv-name="version"
                    v-validate="'required'"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-datetime-picker
                    v-model="date"
                    date-format="dd/MM/yyyy"
                    clearText="Limpar"
                    okText="Confirmar"
                    :time-picker-props="timeProps"
                    :readonly="this.mode == 'VIEW'"
                    label="Data *"
                    v-validate="'required'"
                    :error-messages="errors.collect('date')"
                    data-vv-name="date"
                    :textFieldProps="{ outlined: true }"
                  >
                    <template slot="dateIcon">
                      <v-icon>mdi-calendar</v-icon>
                    </template>
                    <template slot="timeIcon">
                      <v-icon>mdi-clock</v-icon>
                    </template>
                  </v-datetime-picker>
                </v-flex>

                <v-flex xs12 sm6 md12>
                  <v-autocomplete
                    :search-input.sync="requestTitleSearch"
                    v-model="selectedRequests"
                    :items="requests"
                    item-text="title"
                    outlined
                    dense
                    chips
                    small-chips
                    deletable-chips
                    label="Solicitações"
                    multiple
                  ></v-autocomplete>
                </v-flex>

                <v-flex xs12 sm6 md12>
                  <v-textarea
                    required
                    label="Descrição *"
                    v-model="description"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('description')"
                    data-vv-name="description"
                    v-validate="'required'"
                    outlined
                    :rows="3"
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-col>
            <v-btn
              text
              @click="
                setShowDialog(false);
                setSelectedItem({});
              "
              >{{ mode == "VIEW" ? "Voltar" : "Cancelar" }}</v-btn
            >
          </v-col>
          <v-col class="text-right">
            <v-btn
              text
              type="submit"
              right
              v-if="mode == 'EDIT' || mode == 'ADD'"
              @click="saveRelease"
              >Salvar</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  methods: {
    ...mapMutations("releases", ["setShowDialog", "setSelectedItem"]),
    ...mapActions("releases", ["save", "getRequestsByTitle"]),
    saveRelease() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.save(this.selectedRequests);
          this.selectedRequests = []
        }
      });
    },
  },
  computed: {
    ...mapState("releases", [
      "mode",
      "selectedItem",
      "showDialog",
      "requests"
    ]),
    ...mapFields("releases", [
      "selectedItem.id",
      "selectedItem.title",
      "selectedItem.description",
      "selectedItem.date",
      "selectedItem.module",
      "selectedItem.version",
    ]),
  },
  data: () => ({
    possible_modules: [
      "Atendimento",
      "Comercial",
      "Diretoria",
      "Financeiro",
      "Marketing",
      "RH",
      "TI",
    ],
    timeProps: {
      format: "24hr",
    },
    requestTitleSearch: '',
    selectedRequests: []
  }),
  watch: {
      requestTitleSearch(val) {
          this.getRequestsByTitle(val || '')
      },
    },
};
</script>