<template>
  <div>
    <v-card-title>Fechamento Clube</v-card-title>
    <v-expansion-panels :value="1">
      <v-expansion-panel>
        <v-expansion-panel-header>Colunas Planilha</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card>
            <v-data-table
              :headers="headers_config_spreadsheet"
              :items="spreadsheet_headers_config_import"
              disable-pagination
              hide-default-footer
            >     
              <template v-slot:[`item.column_spreadsheet`]="props">
                <v-text-field
                  required
                  reverse
                  v-model="props.item.column_spreadsheet"
                ></v-text-field>
              </template> 
              <template v-slot:[`item.type_field`]="{ item }">
                <span>{{ getTypeHeaderSpreadSheet(item.type_field)}}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-expansion-panel-content> 
      </v-expansion-panel>
      <v-expansion-panel>
      <v-expansion-panel-header>Gerar Fechamento</v-expansion-panel-header>
        <v-expansion-panel-content :value="true">
            <v-card>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12 sm12 md6 xl6>
                    <v-autocomplete
                      clearable
                      v-model="period"
                      :items="periods_closure"
                      no-data-text="Nenhum periodo encontrado"
                      item-text="description"
                      item-value="id"
                      placeholder="Digite aqui o nome do periodo"
                      label="Periodo"
                      :error-messages="errors.collect('period')"
                      v-validate="'required:Periodo,period'"
                      data-vv-name="period"
                      :search-input.sync="searchPeriod"
                    ></v-autocomplete>
                  </v-flex>
                  <v-flex xs12 sm12 md6 xl6>
                    <v-autocomplete
                        label="Clube *"
                        :items="slots"
                        v-model="id_instance"
                        :error-messages="errors.collect('id_instance')"
                        v-validate="'required:Clube,id_instance'"
                        :item-text="getTextSlot"
                        item-value="id"
                        data-vv-name="id_instance"
                      >
                    </v-autocomplete>
                  </v-flex>
                  <v-flex xs12 sm12 md6 xl6>
                    <v-file-input
                      accept="*"
                      label="Adicionar Planilha"
                      v-model="selected_spreadsheet"
                    ></v-file-input>
                  </v-flex>
                  <v-flex xs12 sm12 md6 xl6>
                      <v-text-field
                        required
                        class="text-right"
                        label="Linha Inicial *"
                        v-model="start_line"
                        text-align="right"
                        type="number"
                        v-validate="'required:Linha Inicial,start_line'"
                        :error-messages="errors.collect('start_line')"
                        data-vv-name="start_line"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
              <v-card-actions>
                <v-col class="text-right">
                  <v-btn @click="clubClosure" text type="submit" right >Gerar Relatório</v-btn>
                </v-col>
              </v-card-actions>
            </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { mapFields } from "vuex-map-fields";
import debounce from '../../../../util/debounce.js' 

export default {
  
  name: "ClubClosure",
  computed: {
    ...mapState('slots', ['slots']),
    ...mapState('club_closure', ['periods_closure']),
    ...mapFields('club_closure', [
      'editedItem.id_instance',
      'editedItem.period',
      'editedItem.start_line',
      'selected_spreadsheet',
      'spreadsheet_headers_config_import',
    ])
  },
  created() {
    this.getPeriodClosureByName = this.debounce(this.getPeriodClosureByName, 500)
    this.getSlots({ resetPage: false, noPages: true, onlyIfNotSearched: true });
  },
  components: {
  },
  mixins: [debounce],
  methods: {
    
    ...mapActions('slots', ['getSlots']),
    ...mapActions('club_closure', ['getPeriodClosureByName', 'generateReportClubClosure']),
    getTypeHeaderSpreadSheet(type) {
      if(type == 'int') {
        return 'Inteiro'
      } else if(type == 'float') {
        return 'Decimal'
      } else {
        return 'Texto'
      }
    },
    clubClosure() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.generateReportClubClosure();
        }
      });
    },
    getTextSlot(item) {
      let platform = ""
      if(item.plataform_id == 0) {
        platform = "Suprema"
      } else if(item.plataform_id == 1) {
        platform = "Pppoker"
      } else if(item.plataform_id == 2) {
        platform = "Cacheta"
      }
      return platform + " | " +item.name
    },
  },
  data: () => ({
    nameSpreadsheet: '',
    headers_config_spreadsheet: [
      { text: "Descrição", value: "description_field", sortable: false },
      { text: "Coluna", value: "column_spreadsheet", align: 'right', sortable: true },
      { text: "Tipo", value: "type_field", align: 'right', sortable: false }
    ],
    searchPeriod: ''
  }),
  watch: {
    searchPeriod (val) {
      var payload = {
        search: val
      }
      this.getPeriodClosureByName(payload)
    }
  },
};
</script>
