<template>
    <div>
        <v-card>
            <v-card-title>
                <span class="headline text-center justify-center">Filtro</span>

                <v-col class="text-right">
                    <v-btn
                        v-if="filtered == true"
                        color="white"
                        class="ma-2"
                        @click="clearFilter"
                        right
                        small
                        depressed
                    >
                        <v-icon color="black">mdi-filter-remove</v-icon>
                    </v-btn>
                </v-col>
            </v-card-title>

            <v-card-text>
                <v-form ref="form">
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12 sm6 md6>
                                <v-text-field
                                    required
                                    label="ID Ordem"
                                    v-model="id"
                                    outlined
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-text-field
                                    required
                                    label="ID Externo"
                                    v-model="external_id"
                                    outlined
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-autocomplete
                                    label="Origem"
                                    :clearable="true"
                                    clear-icon="mdi-close"
                                    :items="origins"
                                    v-model="origin"
                                    item-text="description"
                                    item-value="id"
                                    outlined
                                ></v-autocomplete>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-select
                                    :clearable="true"
                                    clear-icon="mdi-close"
                                    label="BOT"
                                    :items="bots"
                                    v-model="id_bot"
                                    item-text="name"
                                    item-value="id"
                                    outlined
                                ></v-select>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-select
                                    :clearable="true"
                                    clear-icon="mdi-close"
                                    label="Tipo"
                                    :items="possible_types"
                                    v-model="type"
                                    item-text="description"
                                    item-value="id"
                                    outlined
                                ></v-select>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-datetime-picker
                                    v-model="start"
                                    date-format="dd/MM/yyyy"
                                    clearText="Limpar"
                                    okText="Confirmar"
                                    :time-picker-props="timeProps"
                                    label="De *"
                                    :textFieldProps="{ outlined: true }"
                                >
                                    <template slot="dateIcon">
                                        <v-icon>mdi-calendar</v-icon>
                                    </template>
                                    <template slot="timeIcon">
                                        <v-icon>mdi-clock</v-icon>
                                    </template>
                                </v-datetime-picker>
                            </v-flex>

                            <v-flex xs12 sm6 md6>
                                <v-datetime-picker
                                    v-model="end"
                                    date-format="dd/MM/yyyy"
                                    clearText="Limpar"
                                    okText="Confirmar"
                                    label="Até *"
                                    :time-picker-props="timeProps"
                                    :textFieldProps="{ outlined: true }"
                                >
                                    <template slot="dateIcon">
                                        <v-icon>mdi-calendar</v-icon>
                                    </template>
                                    <template slot="timeIcon">
                                        <v-icon>mdi-clock</v-icon>
                                    </template>
                                </v-datetime-picker>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-autocomplete
                                    :clearable="true"
                                    clear-icon="mdi-close"
                                    label="Clube"
                                    :items="botClubs"
                                    v-model="club"
                                    item-value="id"
                                    item-text="name"
                                    data-vv-name="name"
                                    multiple
                                    outlined
                                ></v-autocomplete>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-text-field
                                    required
                                    label="Valor"
                                    v-model="value"
                                    outlined
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-text-field
                                    required
                                    label="Slot"
                                    v-model="slot"
                                    outlined
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-text-field
                                    required
                                    label="ID Jogador"
                                    v-model="player_id"
                                    outlined
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-autocomplete
                                    label="Status"
                                    :clearable="true"
                                    clear-icon="mdi-close"
                                    :items="possible_status"
                                    v-model="status"
                                    item-text="description"
                                    item-value="id"
                                    outlined

                                ></v-autocomplete>
                            </v-flex>
                            <v-flex xs12 sm6 md6 v-if="origin == 16">
                                <v-checkbox
                                    v-model="awaiting_validation"
                                    value="1"
                                    label="Aguardando Validação"
                                ></v-checkbox>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-col>
                    <v-btn text @click="showFilter({ show: false })"
                        >Cancelar</v-btn
                    >
                </v-col>
                <v-col class="text-right">
                    <v-btn text type="submit" right @click="filter"
                        >Filtrar</v-btn
                    >
                </v-col>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
    name: 'BotsFilter',
    computed: {
        ...mapState('slots', ['slotsActives']),
        ...mapState('bots', ['bots', 'filtered', 'origins']),
        ...mapFields('bots', [
            'filter.id',
            'filter.type',
            'filter.club',
            'filter.slot',
            'filter.value',
            'filter.status',
            'filter.external_id',
            'filter.id_bot',
            'filter.origin',
            'filter.player_id',
            'filter.id_instance',
            'filter.awaiting_validation',
            'filter.start',
            'filter.end',
        ]),
        botClubs() {
            return this.slotsActives.filter((i) => i.id_bot)
        },
    },
    created() {
        this.getBots()
        this.getPossiblesOrigins()
    },
    data: () => ({
        possible_status: [
            { id: 0, description: 'Aguardando' },
            { id: 1, description: 'Em Andamento' },
            { id: 2, description: 'Concluída' },
            { id: 3, description: 'Jogador sem Saldo' },
            { id: 4, description: 'Clube sem Saldo' },
            { id: 5, description: 'Id Inválido' },
            { id: 6, description: 'Bot inativo' },
            { id: 7, description: 'Erro no print' },
            { id: 8, description: 'Jogador não é agente' },
            { id: 9, description: 'Recusado - Outros' },
            { id: 10, description: 'Erro Confirmação' },
            { id: 11, description: 'Valor Inválido' },
            { id: 12, description: 'Ip inválido' },
            { id: 13, description: 'Ip não informado' },
            { id: 15, description: 'Ordem duplicada' },
            { id: 16, description: 'Timeout' },
        ],
        possible_origins: [
            { id: 1, description: 'Depósito Stars' },
            { id: 2, description: 'Saque Stars' },
            { id: 3, description: 'Fechamento Stars' },
            { id: 5, description: 'Depósito DRX' },
            { id: 6, description: 'Saque DRX' },
            { id: 7, description: 'Fechamento DRX' },
            { id: 10, description: 'DRX 24H' },
            { id: 14, description: 'Stars 24H' },
        ],
        possible_types: [
            { id: 0, description: 'Saque' },
            { id: 1, description: 'Depósito' },
            { id: 2, description: 'Fechamento' },
        ],
        timeProps: {
            format: '24hr',
        },
    }),
    methods: {
        ...mapMutations('bots', ['showFilter']),
        ...mapActions('bots', [
            'getItems',
            'getBots',
            'clearFilter',
            'getInstances',
            'getPossiblesOrigins',
        ]),
        filter() {
            this.getItems({ resetPage: true })
            this.showFilter({ show: false })
        },
    },
}
</script>

<style></style>
