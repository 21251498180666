<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline text-center justify-center">Filtro</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md>
            <v-layout wrap>

              <v-flex xs12 sm2 md2>
                <v-text-field
                  label="ID"
                  v-model="id"
                  :clearable="true"
                  outlined
                ></v-text-field>
              </v-flex>

               <v-flex xs12 sm5 md5>
                <v-menu
                  v-model="showStart"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="start_formatted"
                      label="De"
                      outlined
                      readonly
                      append-icon="mdi-close"
                      @click:append="clearStart()"
                      persistent-hint
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="pt-br"
                    v-model="created_at"
                    no-title
                    @input="(date) => setStart(date)"
                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex xs12 sm5 md5>
                <v-menu
                  v-model="showEnd"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="end_formatted"
                      label="Até"
                      outlined
                      readonly
                      append-icon="mdi-close"
                      @click:append="clearEnd()"
                      persistent-hint
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="pt-br"
                    v-model="finish_at"
                    no-title
                    @input="(e) => setEnd(e)"
                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-text-field
                  label="Atendente"
                  v-model="user_name"
                  :clearable="true"
                  outlined
                ></v-text-field>
              </v-flex>

               <v-flex xs12 sm6 md6>
                <v-text-field
                  label="ID Pppoker"
                  v-model="id_pppoker"
                  :clearable="true"
                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 md6>
                
               <v-select
                  :clearable="true"
                  clear-icon="mdi-close"
                  label="Avaliação"
                  :items="possible_avaliations"
                  v-model="score"
                  item-text="avaliation"
                  item-value="score"
                  outlined
                ></v-select>
                
              </v-flex>

              <v-flex xs12 sm6 md6>
                
               <v-select
                  :clearable="true"
                  clear-icon="mdi-close"
                  label="Status"
                  :items="possible_status"
                  v-model="status"
                  item-text="description"
                  item-value="status"
                  outlined
                ></v-select>
                
              </v-flex>

            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn
            text
            @click="
              setShowFilter(false);
              clearFilter();
            "
            >Cancelar</v-btn
          >
        </v-col>
        <v-col class="text-right">
          <v-btn text type="submit" right @click="filter()">Filtrar</v-btn>
        </v-col>
        
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "AvaliationFilter",
  computed: {
    ...mapState("avaliations", ["mode", "selectedItem", "showDialog"]),
    ...mapFields("avaliations", [
      "filter.id",
      "filter.data",
      "filter.score",
      "filter.user_name",
      "filter.status",
      "filter.id_pppoker",
      "filter.created_at",
      "filter.finish_at",
      "possible_avaliations",
      "possible_status"
    ]),
  },   
  methods: {
    ...mapMutations("avaliations", ["setShowFilter", "clearFilter"]),
    ...mapActions("avaliations", ["getItems"]),
    filter() {
      this.getItems({ resetPage: true });
      this.setShowFilter(false);
    },
     formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    },
     setStart(date) {
      this.created_at = date;
      this.showStart = false;
      this.start_formatted = this.formatDate(date);
    },
    setEnd(date) {
      this.end = date;
      this.showEnd = false;
      this.end_formatted = this.formatDate(date);
    },
    clearStart() {
      this.created_at = "";
      this.start_formatted = "";
    },
    clearEnd() {
      this.finish_at = "";
      this.end_formatted = "";
    }
  },
  data: () => ({
     start_formatted: "",
     showStart: false,
     showEnd: false,
     end_formatted: ""
  }),
};
</script>
