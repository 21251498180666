<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar
      :color="notification.type"
      right
      top
      :timeout="2000"
      v-model="notification.show"
    >
      {{ notification.message }}
    </v-snackbar>

    <v-toolbar flat class="elevation-5">
      <div>
        <v-toolbar-title>Solicitações Financeiras</v-toolbar-title>
      </div>
      <v-spacer></v-spacer>
      <div class="button-toolbar">
        <!-- <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <download-excel
              :before-generate="generateSheet"
              :before-finish="finishSheet"
              :name="getSheetName()"
              :fetch="getItemsNoPage"
              :fields="sheet_fields"
              class="excel"
              worksheet="Solicitações Financeiras"
              v-bind="attrs"
              v-on="on"
            >
              <v-btn color="black" dark class="ma-2" small
                ><v-icon>mdi-download</v-icon></v-btn
              >
            </download-excel>
          </template>
          <span>Baixar planilha</span>
        </v-tooltip> -->

        <v-tooltip bottom>
          <template v-slot:activator="{on, attrs}">
            <v-btn color="black" dark class="ma-2" @click="downloadXlsx({ show: true })" v-bind="attrs" v-on="on" small>
              <v-icon>mdi-file-excel</v-icon>
            </v-btn>
          </template>
          <span>Gerar Planilha</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="black"
              dark
              class="ma-2"
              @click="setShowFilter(true)"
              v-bind="attrs"
              v-on="on"
              small
            >
              <v-icon color="white">mdi-filter</v-icon>
            </v-btn>
          </template>
          <span>Filtro</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="black"
              dark
              class="ma-2"
              @click="getItems"
              v-bind="attrs"
              v-on="on"
              small
              ><v-icon>mdi-refresh</v-icon></v-btn
            >
          </template>
          <span>Recarregar</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="black"
              dark
              class="ma-2"
              v-bind="attrs"
              v-on="on"
              @click="addSolicitation()"
              small
              ><v-icon>mdi-plus</v-icon></v-btn
            >
          </template>
          <span>Adicionar Solicitação</span>
        </v-tooltip>
      </div>
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :loading="loading"
      :items="items"
      :options.sync="pagination"
      :server-items-length="totalItems"
      no-data-text="Nenhum pedido encontrado"
      class="elevation-1 table"
    >
      <template v-slot:[`item.date_created`]="{ item }">
        <span>{{ getDateCreated(item.date_created) }}</span>
      </template>

      <template v-slot:[`item.value`]="{ item }">
        <span>{{
          item.value.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })
        }}</span>
      </template>

      <template v-slot:[`item.type_solicitation`]="{ item }">
        <span class="ma-2">{{
          getSolicitationType(item.type_solicitation)
        }}</span>
      </template>

      <template v-slot:[`item.pix_type`]="{ item }">
        <span class="ma-2">{{ getPixType(item.pix_type) }}</span>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          :color="getColorStatus(item.status)"
          class="ma-2"
          text-color="white"
          label
          >{{ getStatus(item.status) }}</v-chip
        >
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom v-if="item.status == 2 && profileAccess()">
          <template v-slot:activator="{ attrs, on }">
            <v-icon
              color="green darken-4"
              small
              v-bind="attrs"
              v-on="on"
              class="mr-2"
              @click="add({ financial_solicitations: item })"
              >mdi-currency-brl</v-icon
            >
          </template>
          <span>Gerar despesa</span>
        </v-tooltip>

        <v-tooltip bottom v-if="item.status == 0 && profileAccess()">
          <template v-slot:activator="{ attrs, on }">
            <v-icon
              color="blue"
              v-bind="attrs"
              v-on="on"
              small
              class="mr-2"
              @click="edit(item)"
              >mdi-pencil</v-icon
            >
          </template>
          <span>Editar</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ attrs, on }">
            <v-icon
              color="black"
              dark
              small
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="view(item)"
              >mdi-information</v-icon
            >
          </template>
          <span>Informações</span>
        </v-tooltip>

        <v-tooltip bottom v-if="item.status == 2 && profileAccess()">
          <template v-slot:activator="{ attrs, on }">
            <v-icon
              color="green darken-2"
              small
              v-bind="attrs"
              v-on="on"
              class="mr-2"
              @click="showFinishDialog(item)"
              >mdi-marker-check</v-icon
            >
          </template>
          <span>Concluir</span>
        </v-tooltip>

        <v-tooltip bottom v-if="item.status == 0">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="red"
              v-bind="attrs"
              v-on="on"
              small
              class="mr-2"
              @click="showDeleteDialog(item)"
              >mdi-delete</v-icon
            >
          </template>
          <span>Excluir</span>
        </v-tooltip>

        <v-tooltip bottom v-if="item.status == 0 && profileAccess()">
          <template v-slot:activator="{ attrs, on }">
            <v-icon
              color="green"
              small
              v-bind="attrs"
              v-on="on"
              class="mr-2"
              @click="showApproveDialog(item)"
              >mdi-check</v-icon
            >
          </template>
          <span>Aprovar</span>
        </v-tooltip>

        <v-tooltip bottom v-if="item.status == 0 && profileAccess()">
          <template v-slot:activator="{ attrs, on }">
            <v-icon
              small
              v-bind="attrs"
              v-on="on"
              class="mr-2"
              @click="showRefuseDialog(item)"
              >mdi-close-octagon</v-icon
            >
          </template>
          <span>Recusar</span>
        </v-tooltip>

        <v-tooltip bottom v-if="item.status == 3">
          <template v-slot:activator="{ attrs, on }">
            <v-icon
              small
              v-bind="attrs"
              v-on="on"
              class="mr-2"
              @click="
                showReceiptFinancialSolicitation({
                  financial_solicitations: item,
                })
              "
              >mdi-receipt</v-icon
            >
          </template>
          <span>Comprovante</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog
      :value="deleteDialog"
      @click:outside="setDeleteDialog(true)"
      @keydown.enter="deleteItem"
      width="300"
    >
      <v-card>
        <v-card-title class="headline">Confirmar a exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn text @click="setDeleteDialog(false)">Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn text type="submit" right @click="deleteItem"
              >Confirmar</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      class="texte-center"
      :value="finishDialog"
      @click:outside="setFinishDialog(false)"
      @keydown.enter="updateStatus({ status: 3 })"
      width="500"
    >
      <v-card>
        <v-card-title class="headline">Confirmar a conclusão?</v-card-title>
        <v-card-subtitle class="headline"
          >Este processo não gera despesa.</v-card-subtitle
        >

        <v-card-actions>
          <v-col>
            <v-btn text @click="setFinishDialog(false)">Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn text type="submit" right @click="updateStatus({ status: 3 })"
              >Confirmar</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      :value="approveDialog"
      @click:outside="setApproveDialog(false)"
      @keydown.enter="updateStatus({ status: 2 })"
      width="400"
    >
      <v-card>
        <v-card-title class="headline">Confirmar a aprovação?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn text @click="setApproveDialog(false)">Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn text type="submit" right @click="updateStatus({ status: 2 })"
              >Confirmar</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      :value="refuseDialog"
      @click:outside="setRefuseDialog(false)"
      @keydown.enter="updateStatus({ status: 1 })"
      width="400"
    >
      <v-card>
        <v-card-title class="headline">Confirmar a reprovação?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn text @click="setRefuseDialog(false)">Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn text type="submit" right @click="updateStatus({ status: 1 })"
              >Confirmar</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogReceiptFinancialSolicitation"
      @click:outside="setDialogReceipt({ show: false })"
    >
      <v-card height="90vh">
        <v-img max-height="100%" :src="receipt_show" contain></v-img>
      </v-card>
    </v-dialog>

    <v-dialog
      :value="showDialogExpense"
      @click:outside="setShowDialogExpense(false)"
      width="1000"
    >
      <expense-form></expense-form>
    </v-dialog>

    <v-dialog
      :value="showDialog"
      @click:outside="setShowDialog(false)"
      width="1000"
    >
      <financial-solicitations-form></financial-solicitations-form>
    </v-dialog>

    <v-dialog
      :value="filterDialog"
      @click:outside="setShowFilter(false)"
      width="1000"
    >
      <financial-solicitations-filter></financial-solicitations-filter>
    </v-dialog>
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mapState, mapActions, mapMutations } from "vuex";
import ExpenseForm from "../expense/ExpenseForm";
import FinancialSolicitationsForm from "./FinancialSolicitationsForm";
import FinancialSolicitationsFilter from "./FinancialSolicitationsFilter";
// import JsonExcel from "vue-json-excel";
import { utils, writeFile } from "xlsx";

export default {
  name: "FinancialSolicitations",

  components: {
    // "download-excel": JsonExcel,
    ExpenseForm,
    FinancialSolicitationsForm,
    FinancialSolicitationsFilter,
  },
  created() {
    this.getItems();
    this.getCompanies();
  },
  methods: {
    ...mapActions("expense", ["closeDialog", "saveExpense"]),
    ...mapActions("financial_solicitations", [
      "showReceiptFinancialSolicitation",
      "getItemsNoPage",
      "importSpreadsheet",
      "add",
      "getUsers",
      "getItems",
      "getCompanies",
      "deleteItem",
      "updateStatus",
    ]),
    ...mapMutations("financial_solicitations", [
      "showOverlay",
      "showDialogImportSpreadsheet",
      "addSolicitation",
      "refuseItem",
      "edit",
      "view",
      "setShowFilter",
      "setShowDialog",
      "setShowDialogExpense",
      "setDeleteDialog",
      "showDeleteDialog",
      "setFinishDialog",
      "setApproveDialog",
      "showFinishDialog",
      "showApproveDialog",
      "setRefuseDialog",
      "showRefuseDialog",
      "setDialogReceipt",
    ]),
    profileAccess() {
      return (
        this.userData.role >= 93 ||
        this.userData.id == 253 ||
        this.userData.id == 132 ||
        this.userData.id == 60 ||
        this.userData.id == 21
      );
    },
    getColorStatus(status) {
      if (status == 0) {
        return "orange darken-1";
      } else if (status == 1) {
        return "red darken-3";
      } else if (status == 2) {
        return "cyan";
      } else if (status == 3) {
        return "green darken-2";
      }
    },
    getDateCreated(date_created) {
      if (date_created != null) {
        var d = new Date(date_created);
        return d.toLocaleDateString() + " " + d.toLocaleTimeString();
      } else {
        return "--------";
      }
    },
    // getDate(date) {
    //   var d = new Date(date);
    //   return d.toLocaleDateString() + " " + d.toLocaleTimeString();
    // },
    getSolicitationType(type_solicitation) {
      return this.possible_solicitation_types[type_solicitation];
    },
    getPixType(pix_type) {
      return this.possible_pix_types[pix_type];
    },
    getStatus(status) {
      return this.possible_status[status];
    },
    finishSheet() {
      this.loading = false;
    },
    generateSheet() {
      this.loading = true;
    },
    getSheetName() {
      var date = new Date();
      var name =
        "solicitações_financeiras_" +
        date.toLocaleDateString() +
        " " +
        date.toLocaleTimeString();
      return name + ".xls";
    },
    async downloadXlsx() {
      this.generateSheet()
      try {
        let itemsNoPage = await this.getItemsNoPage()
        if(itemsNoPage) {
          const wb = utils.book_new()
            let items = itemsNoPage.map(row => ({
            "Data":  this.dateTimeXLSX(row.date_created),
            "Tipo": this.getSolicitationType(row.type_solicitation),
            "Solicitante":  row.user_name,
            "Descrição":  row.description,
            "Quantidade":  row.quantity,
            "Valor":  this.valueXLSX(row.value),
            "Link":  row.link,
            "Nome do PIX":  row.pix_name,
            "Tipo PIX":  this.getPixType(row.pix_type),
            "Chave PIX":  row.pix_key,
            "Empresa":  row.company_name,
            "Julgado por":  row.judge,
            "Status":  this.getStatus(row.status),
          }))
          const ws = utils.json_to_sheet(items)
          utils.book_append_sheet(wb, ws, "Despesas")
          await writeFile(wb, this.getSheetName())
        }
      } catch (error) {
        this.finishSheet()
      } finally {
        this.finishSheet()
      }
    },
    dateTimeXLSX: (value) => {
      if (value) {
        var d = new Date(value);
        return d.toLocaleDateString() + " " + d.toLocaleTimeString();
      }
    },
    valueXLSX: (value) => {
      if (value) {
        let real = value.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });
        return real.substr(3);
      }
      return 0;
    },
  },
  computed: {
    ...mapState("login", ["userData"]),
    ...mapState("financial_solicitations", [
      "dialogImportSpreadsheet",
      "companies",
      "item",
      "items",
      "mode",
      "maskMode",
      "totalItems",
      "showDialog",
      "showDialogExpense",
      "dialogReceiptFinancialSolicitation",
      "notification",
      "filterDialog",
      "deleteDialog",
      "finishDialog",
      "approveDialog",
      "refuseDialog",
      "receipt_show",
    ]),
    ...mapFields("financial_solicitations", [
      "id_company",
      "loading",
      "pagination",
      "spreadsheet_import",
    ]),
  },
  data: () => ({
    tab: "",
    headers: [
      { text: "Data do pedido", value: "date_created", sortable: false },
      { text: "Solicitado por", value: "user_name", sortable: false },
      {
        text: "Tipo de solicitação",
        value: "type_solicitation",
        sortable: false,
      },
      {
        text: "Descrição",
        value: "description",
        sortable: false,
        width: "30%",
      },
      { text: "Valor", value: "value", sortable: false },
      { text: "Nome do PIX", value: "pix_name", sortable: false },
      { text: "Tipo PIX", value: "pix_type", sortable: false },
      { text: "Chave PIX", value: "pix_key", sortable: false },
      { text: "Empresa", value: "company_name", sortable: false },
      { text: "Julgado por", value: "judge", sortable: false },
      { text: "Status", value: "status", sortable: false, align: "center" },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        align: "center",
        width: "10%",
      },
    ],
    possible_solicitation_types: ["Compra", "Reembolso", "Outros"],
    possible_pix_types: ["CPF / CNPJ", "Celular", "E-mail", "Aleatória"],
    possible_status: ["Pendente", "Recusado", "Aprovado", "Concluído"],
    sheet_fields: {
      Data: {
        field: "date_created",
      },
      Solicitante: {
        field: "user_name",
      },
      Descrição: {
        field: "description",
      },
      Quantidade: {
        field: "quantity",
      },
      Valor: {
        field: "value",
        callback: (value) => {
          if (value) {
            let real = value.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
            return real.substr(3);
          }
          return 0;
        },
      },
      Link: {
        field: "link",
      },
      "Nome do PIX": {
        field: "pix_name",
      },
      "Tipo PIX": {
        field: "pix_type",
      },
      "Chave PIX": {
        field: "pix_key",
      },
      Empresa: {
        field: "company_name",
      },
      "Julgado por": {
        field: "judge",
      },
      Status: {
        field: "status",
      },
    },
  }),
  watch: {
    pagination: {
      handler() {
        this.getItems({ resetPage: false });
      },
    },
  },
};
</script>
