import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../config/endPoints';
import { defaultErrorNotification } from "../../constants";

const defaultSlot = {
    plataform_id: 0,
    web_withdrawals: 1,
    web_deposits: 1,
    id_bot: 14,
    status: 1,
    id: null,
    id_app: "",
    name: "",
    nick: "",
    min_deposit_value: 10,
    allow_none: 0,
    closing_grouping: null,
    automatic_approval: 0
};

const defaultFilter = {
    plataform_id: "",
    status: "",
    id: "",
    id_app: "",
    id_bot: "",
    name: "",
    nick: "",
    allow_none: "",
    automatic_approval: ""
};

export default {
    namespaced: true,
    state: {
        dialog: false,
        dialogDelete: false,
        editedSlot: {},
        errors: [],
        filter: {
            plataform_id: "",
            status: "",
            id: "",
            id_app: "",
            name: "",
            nick: "",
            web_withdrawals: "",
            web_deposits: "",
            id_bot: ""
        },
        filterDialog: false,
        instanceToDelete: {},
        loading: false,
        mode: 'LIST',
        notificationMessage: '',
        notificationType: '',
        page: 1,
        pagination: {
            page: 1,
            itemsPerPage: 10
        },
        showNotification: false,
        slots: [],
        slotsActives: [],
        searched: false,
        bots: [],
        totalSlots: 0,
        totalBots: 0,
        rowsPerPage: 50,
        plataform_types: [
            {
                id: 0,
                description: "Suprema Poker"
            },
            {
                id: 1,
                description: "PPPoker"
            },
            {
                id: 2,
                description: "Cacheta"
            }
        ],

        possible_status: [
            { id: 0, description: "Inativo" },
            { id: 1, description: "Ativo" }
        ],
        possible_web_deposits_withdrawals: [
            { id: 0, description: "Não" },
            { id: 1, description: "Sim" }
        ],
    },
    mutations: {
        addSlot: (state) => {
            state.dialog = true
            state.mode = "ADD"
            state.editedSlot = Object.assign({}, defaultSlot)
        },
        editSlot: (state, item) => {
            state.editedSlot = Object.assign({}, item)
            // state.editedIndex = state.instances.indexOf(item)
            state.dialog = true
            state.mode = "EDIT"
        },
        closeDialog: (state) => {
            state.editedInstance = Object.assign({}, defaultSlot)
            // state.editedIndex = -1
            state.errors = [];
        },
        clearFilter: (state) => {
            state.filter = Object.assign({}, defaultFilter)
        },
        loading: (state, loading) => state.loading = loading,
        setMode: (state, mode) => state.mode = mode,
        setSlots: (state, slots) => state.slots = slots,
        setSlotsActives: (state, slots) => state.slotsActives = slots,
        setBots: (state, bots) => state.bots = bots,
        setInstanceToDelete: (state, instance) => state.instanceToDelete = instance,
        setTotalSlots: (state, total) => state.totalSlots = total,
        setTotalBots: (state, totalBots) => state.totalBots = totalBots,
        setRowsPerPage: (state, rowsPerPage) => state.rowsPerPage = rowsPerPage,
        showDialog: (state, show) => state.dialog = show,
        setDialogDelete: (state, show) => state.dialogDelete = show,
        showLoading: (state, show) => state.loading = show,
        updateField,
        setPage: (state, payload) => {
            state.pagination.page = payload.page;
            state.pagination.itemsPerPage = payload.itemsPerPage;
        },
        setShowFilter(state, payload) {
            state.filterDialog = payload
            state.mode = "VIEW"
        },
        showNotification: (state, payload) => {
            state.showNotification = payload.show,
                state.notificationMessage = payload.message,
                state.notificationType = payload.type
        },
        setSearched: (state, payload) => state.searched = payload.searched,
        viewSlot(state, item) {
            state.editedSlot = item
            // state.editedIndex = state.slots.indexOf(item)
            state.dialog = true
            state.mode = "VIEW"
        },
    },
    getters: {
        getField,
        getSlots: (state) => {
            return state.slots
        },
        getSlotsActives: (state) => {
            return state.slotsActives
        },

    },
    actions: {
        closeDialog: (({ commit }) => {
            commit('showDialog', false)
            commit('closeDialog')
        }),
        closeDeleteDialog: ({ commit }) => {
            commit('setDialogDelete', false)
            commit('setInstanceToDelete', {})
        },
        deleteInstance: ({ dispatch, commit, state }) => {
            commit('loading', true)
            axios.delete(endPoints.instances + "/" + state.instanceToDelete.id, state.instanceToDelete).then(() => {
                dispatch('closeDeleteDialog')
                let notification = {
                    show: true,
                    message: "Instância excluída com sucesso.",
                    type: "success"
                }
                commit('showNotification', notification)
                return dispatch('getInstances');
            });
        },
        deleteSlot: ({ dispatch, commit, state }) => {
            commit('loading', true)
            axios.delete(endPoints.slots + "/" + state.instanceToDelete.id, state.instanceToDelete).then(() => {
                dispatch('closeDeleteDialog')
                let notification = {
                    show: true,
                    message: "Slot excluído com sucesso.",
                    type: "success"
                }
                commit('showNotification', notification)
                return dispatch('getSlots', { resetPage: false });
            });
        },
        getSlots: async ({ commit, state }, payload) => {
            let filter = state.filter
            let url = endPoints.slots;

            if(payload && payload.onlyIfNotSearched) {
                if(state.searched) {
                    return
                }
            }
    
            if (payload.resetPage) {
                url += "?page=" + 1 + "&" + "itemsPerPage=" + 10;
                commit("setPage", { page: 1, itemsPerPage: 10 });
            } else {
                if (payload.noPages) {
                    url += "?page=" + 1 + "&" + "itemsPerPage=" + 999;
                } else {
                    url +=
                    "?page=" +
                    state.pagination.page +
                    "&" +
                    "itemsPerPage=" +
                    state.pagination.itemsPerPage;
                }
            }
            if (state.pagination && state.pagination.sortBy && state.pagination.sortBy.length > 0) {
                url += "&sort_by=" + state.pagination.sortBy[0]
                url += "&sort_desc=" + !state.pagination.sortDesc[0]
            }
            if(payload.inactive !== undefined && payload.inactive !== null && payload.inactive === false) {
                
                url += "&status=1"
            }

            Object.keys(filter).forEach(function (field) {
                if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
                    url += "&" + field + "=" + filter[field]
                }
            })

            commit("loading", true);

            try {
                let slots = await axios.get(url);
                commit("setSlots", slots.data.data)
                commit("setSlotsActives", slots.data.data.filter(slot => slot.status === 1))
                commit("setTotalSlots", slots.data.total);
                commit("setSearched", {searched: true})                

            } catch (error) {
                commit(
                    "showNotification",
                    defaultErrorNotification(error?.response?.data?.message)
                );
            }
            commit("loading", false);
           
        },
        getBots: async ({ commit }) => {
            
            let url = endPoints.bots;
            try {
                let bots = await axios.get(url);
                commit("setBots", bots.data.data)
                commit("setTotalBots", bots.data.total);
                

            } catch (error) {
                commit(
                    "showNotification",
                    defaultErrorNotification(error?.response?.data?.message)
                );
            }
            commit("loading", false);
       
        },
        showDeleteDialog: ({ commit }, item) => {
            commit('setDialogDelete', true)
            commit('setInstanceToDelete', item)
        },
        saveSlot: ({ dispatch, commit, state }) => {
            var url = endPoints.slots;
            let func = "";
            if (state.mode === "ADD") {
                func = axios.post;
                url = endPoints.slots;
                
            } else {
                func = axios.put;
                url += '/' + state.editedSlot.id;
            }

            state.editedSlot.id = parseInt(state.editedSlot.id);
            state.editedSlot.id_bot = parseInt(state.editedSlot.id_bot);
            state.editedSlot.id_app = parseInt(state.editedSlot.id_app);
            
            func(url, state.editedSlot).then(
                () => {
                    dispatch('getSlots', { resetPage: false })
                    let notification = {
                        show: true,
                        message: "Slot salvo com sucesso.",
                        type: "success"
                    }
                    dispatch('closeDialog')
                    commit('showNotification', notification)
                },
                error => {
                    let notification = {
                        show: true,
                        message: error.response.data.message,
                        type: "error"
                    }
                    commit('showNotification', notification)
                }
            );
        }
    }
}