<template>
    <div @drop.prevent="addDropFile" @dragover.prevent>
        <v-dialog :value="showDialog" persistent width="600">
            <v-card>
                <v-card-title>
                    <span class="headline">Cadastrar Cliente</span>
                </v-card-title>

                <v-card-text>
                    <v-form ref="form" lazy-validation>
                        <v-container grid-list-md>
                            <v-layout wrap>
                                <v-flex xs12 sm6 md6>
                                    <v-select
                                        label="Tipo *"
                                        v-model="type"
                                        :items="possible_types"
                                        item-text="name"
                                        item-value="id"
                                        :readonly="this.mode == 'VIEW'"
                                        @chage="setTypeVerification"
                                        required
                                        outlined
                                    ></v-select>
                                </v-flex>

                                <template v-if="isPeoplePerson()">
                                    <v-flex xs12 sm6 md6>
                                        <v-text-field
                                            v-model="name"
                                            label="Nome *"
                                            :readonly="this.mode == 'VIEW'"
                                            :rules="rulesFields"
                                            required
                                            outlined
                                        ></v-text-field>
                                    </v-flex>
                                </template>

                                <template v-else>
                                    <v-flex xs12 sm12 md12>
                                        <v-text-field
                                            v-model="name"
                                            label="Nome Fantasia *"
                                            :readonly="this.mode == 'VIEW'"
                                            :rules="rulesFields"
                                            required
                                            outlined
                                        ></v-text-field>
                                    </v-flex>
                                    <v-flex xs12 sm12 md12>
                                        <v-text-field
                                            v-model="corporate_name"
                                            label="Razão Social *"
                                            :readonly="this.mode == 'VIEW'"
                                            :rules="rulesFields"
                                            required
                                            outlined
                                        ></v-text-field>
                                    </v-flex>
                                </template>

                                <template>
                                    <v-flex xs12 sm6 md6>
                                        <v-text-field
                                            v-model="nickname"
                                            label="Apelido"
                                            :readonly="this.mode == 'VIEW'"
                                            outlined
                                        ></v-text-field>
                                    </v-flex>
                                </template>

                                <v-flex xs12 sm6 md6>
                                    <v-text-field
                                        v-if="isPeoplePerson()"
                                        v-model="inscription"
                                        label="CPF *"
                                        :readonly="this.mode == 'VIEW'"
                                        :rules="rulesFields"
                                        v-mask="'###.###.###-##'"
                                        required
                                        outlined
                                    ></v-text-field>
                                    <v-text-field
                                        v-else
                                        label="CNPJ *"
                                        v-model="inscription"
                                        :rules="rulesFields"
                                        :readonly="this.mode == 'VIEW'"
                                        required
                                        outlined
                                    ></v-text-field>
                                </v-flex>

                                <v-flex xs12 sm6 md6>
                                    <v-text-field
                                        v-if="isPeoplePerson()"
                                        v-model="birth_date"
                                        type="date"
                                        label="Data de Nascimento"
                                        :readonly="this.mode == 'VIEW'"
                                        required
                                        outlined
                                    ></v-text-field>

                                    <v-text-field
                                        v-else
                                        v-model="birth_date"
                                        type="date"
                                        label="Data de Abertura"
                                        :readonly="this.mode == 'VIEW'"
                                        outlined
                                    ></v-text-field>
                                </v-flex>

                                <v-flex xs12 sm6 md6>
                                    <v-text-field
                                        v-model="cep"
                                        label="CEP"
                                        :readonly="this.mode == 'VIEW'"
                                        v-mask="'#####-###'"
                                        outlined
                                        @blur="searchAddress"
                                    ></v-text-field>
                                </v-flex>

                                <v-flex xs12 sm6 md6>
                                    <v-text-field
                                        v-model="address"
                                        label="Endereço"
                                        :readonly="this.mode == 'VIEW'"
                                        outlined
                                    ></v-text-field>
                                </v-flex>

                                <v-flex xs12 sm6 md6>
                                    <v-text-field
                                        v-model="number"
                                        label="Número"
                                        :readonly="this.mode == 'VIEW'"
                                        outlined
                                    ></v-text-field>
                                </v-flex>

                                <v-flex xs12 sm6 md6>
                                    <v-text-field
                                        v-model="complement"
                                        label="Complemento"
                                        :readonly="this.mode == 'VIEW'"
                                        outlined
                                    ></v-text-field>
                                </v-flex>

                                <v-flex xs12 sm6 md6>
                                    <v-text-field
                                        v-model="district"
                                        label="Bairro"
                                        :readonly="this.mode == 'VIEW'"
                                        outlined
                                    ></v-text-field>
                                </v-flex>

                                <v-flex xs12 sm6 md6>
                                    <v-text-field
                                        v-model="city"
                                        label="Cidade"
                                        :readonly="this.mode == 'VIEW'"
                                        outlined
                                    ></v-text-field>
                                </v-flex>

                                <v-flex xs12 sm6 md6>
                                    <v-autocomplete
                                        v-model="state"
                                        :items="states"
                                        label="Estado"
                                        :readonly="this.mode == 'VIEW'"
                                        outlined
                                    ></v-autocomplete>
                                </v-flex>

                                <v-flex xs12 sm6 md6>
                                    <v-autocomplete
                                        v-model="country"
                                        :items="countries"
                                        item-text="common"
                                        item-value="common"
                                        label="País"
                                        :readonly="this.mode == 'VIEW'"
                                        outlined
                                    ></v-autocomplete>
                                </v-flex>

                                <v-flex xs12 sm6 md6>
                                    <vue-tel-input-vuetify
                                        outlined
                                        label="Telefone *"
                                        :rules="rulesFields"
                                        v-model="contact"
                                       
                                    ></vue-tel-input-vuetify>
                                </v-flex>

                                <v-flex xs12 sm6 md6>
                                    <v-select
                                        label="Tipo de Pagamento *"
                                        v-model="payment_for"
                                        :items="type_of_payment"
                                        item-text="name"
                                        item-value="id"
                                        :readonly="this.mode == 'VIEW'"                                      
                                        @chage="setTypeVerification"
                                        required
                                        outlined
                                    ></v-select>
                                </v-flex>

                                <v-flex xs12 sm6 md6>
                                    <v-text-field
                                        v-model="limit_annotated"
                                        prefix="R$"
                                        label="Limite de Anotados *"
                                        :readonly="this.mode == 'VIEW'"
                                        :rules="rulesAnnotated"
                                        required
                                        outlined
                                    ></v-text-field>
                                </v-flex>


                                <v-flex xs12 sm6 md6>
                                    <v-select
                                        label="Situação "
                                        :items="situations"
                                        v-model="situation"
                                        :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                                        :error-messages="errors.collect('situation')"
                                        v-validate="'required:Situação,situation'"
                                        item-text="description"
                                        item-value="id"
                                        data-vv-name="situation"
                                        outlined
                                    ></v-select>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-col>
                        <v-btn text @click="setShowDialog(false)">{{
                            mode == 'VIEW' ? 'Voltar' : 'Cancelar'
                        }}</v-btn>
                    </v-col>
                    <v-col class="text-right">
                        <v-btn
                            text
                            type="submit"
                            right
                            v-if="mode == 'EDIT' || mode == 'ADD'"
                            @click="saveRelease"
                            >Salvar</v-btn
                        >
                    </v-col>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
    name: 'ClientsForm',

    created() {
        this.getStates()
        this.getCountries()
    },

    computed: {
        ...mapState('clients', [
            'mode',
            'selectedItem',
            'showDialog',
            'states',
            'countries',
            'countriesSuperData',
            'possible_types',
            'type_of_payment',
            'phonePrefixes',
        ]),
        ...mapFields('clients', [
            'selectedItem.id',
            'selectedItem.type',
            'selectedItem.inscription',
            'selectedItem.name',
            'selectedItem.nickname',
            'selectedItem.birth_date',
            'selectedItem.date_picker',
            'selectedItem.corporate_name',
            'selectedItem.country',
            'selectedItem.cep',
            'selectedItem.district',
            'selectedItem.address',
            'selectedItem.number',
            'selectedItem.city',
            'selectedItem.payment_for',
            'selectedItem.limit_annotated',
            'selectedItem.state',
            'selectedItem.complement',
            'selectedItem.contact',
            'selectedItem.whatsapp_group',
            'selectedItem.country_code',
            'selectedItem.situation'
        ]),
    },

    methods: {
        ...mapActions('slots', ['getSlots']),

        ...mapActions('clients', [
            'updateSelectedItem',
            'save',
            'getCep',
            'getStates',
            'getCountries',
        ]),
        ...mapMutations('clients', [
            'setShowDialog',
            'setSelectedItem',
            'setShowDialog',
            'setCountryCode',
        ]),

        saveRelease() {
            let valid = this.$refs.form.validate()
            if (valid) {
                console.log('to aqui')
                this.save()
            }
        },

        onInput(formattedNumber, item) {
            if(item.number.e164 == undefined || item.number.e164 == null)  {
              this.selectedItem.contact = ''   
            } else {
                this.selectedItem.contact = parseFloat(
                    item.number.e164.replace(/\+/g, '')
                )
            }
        },

        searchAddress() {
            this.getCep()
        },

        isPeoplePerson() {
            return (
                this.selectedItem.type == 0 ||
                this.selectedItem.type == undefined
            )
        },

        setTypeVerification() {
            this.typeVerification = this.selectedItem.type
        },
    },

    data: () => ({
        typeVerification: '',
        timeProps: {
            format: '24hr',
        },
        situations: [
            {
                id: 0,
                description: "Inadimplente"
            }, 
            {
                id: 1,
                description: "Em Acordo"
            },
            {
                id: 2,
                description: "Rotativo Semanal"
            },
            {
                id: 3,
                description: "Ativo"
            },
            { 
                id: 4, 
                description: "Não Definido"
            }
        ],
        rulesAnnotated: [(value) => (!!value || value == 0) || 'Campo obrigatório'],
        rulesFields: [(value) => !!value || 'Campo obrigatório'],
    }),

    watch: {},
}
</script>


<style>
:disabled {
    opacity: 55%;
}
</style>