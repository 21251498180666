<template>
  <div>
    
      <v-card>
          <v-card-title color="red">
            <span class="headline text-center justify-center">Filtro</span>

            <v-col class="text-right">
              <v-btn v-show="filtered" color="white" class="ma-2"  @click="clearFilter" right small depressed>
                <v-icon color="black">mdi-filter-remove</v-icon>
              </v-btn>
            </v-col>
          </v-card-title>

          <v-card-text>

          <v-form ref="form"  lazy-validation>
              <v-container grid-list-md>
              <v-layout wrap>
                  <v-flex xs12 sm4 md4>
                    <v-select
                        label="Tipo"
                        :items="types"
                        v-model="type"
                        item-text="description"
                        item-value="id"
                        clear-icon="mdi-close"
                        :clearable="true"
                        outlined
                    >
                    </v-select>
                  </v-flex>
                  <v-flex xs12 sm4 md4>
                    <v-text-field
                        label="Valor"
                        v-model="value"
                        outlined
                    ></v-text-field>
                  </v-flex>
                  
                  <v-flex xs12 sm4 md4>
                    <v-text-field
                        label="ID PPPoker"
                        v-model="id_pppoker"
                        outlined
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm3 md3>
                    <v-menu
                        v-model="showStart"
                        :close-on-content-click="true"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="start_formatted"
                                label="De"
                                outlined=""
                                readonly
                                append-icon="mdi-close"
                                @click:append="clearStart()"
                                persistent-hint
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker locale="pt-br" v-model="start_date" no-title @input="(e) => setStart(e)"></v-date-picker>
                    </v-menu>
                  </v-flex>

                  
                  <v-flex xs12 sm3 md3>
                    <v-menu
                        v-model="showStartTime"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="start_time"
                                label="Horário"
                                outlined=""
                                readonly
                                append-icon="mdi-close"
                                @click:append="clearStartTime()"
                                persistent-hint
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        
                        <v-time-picker 
                          v-model="start_time"
                          format="24hr"
                          @click:minute="setStartTime() && $refs.endTimeField.save(start_time)"
                          color="black"
                          header-color="black">
                        </v-time-picker>
                    </v-menu>
                  </v-flex>
                  
                  <v-flex xs12 sm3 md3>
                    <v-menu
                        v-model="showEnd"
                        :close-on-content-click="true"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="end_formatted"
                                label="Até"
                                outlined=""
                                readonly
                                append-icon="mdi-close"
                                @click:append="clearEnd()"
                                persistent-hint
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker locale="pt-br" v-model="end_date" no-title @input="(e) => setEnd(e)"></v-date-picker>
                    </v-menu>
                  </v-flex>

                  <v-flex xs12 sm3 md3>
                    <v-menu
                        v-model="showEndTime"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                ref="endTimeField"
                                v-model="end_time"
                                label="Horário"
                                outlined=""
                                readonly
                                append-icon="mdi-close"
                                @click:append="clearEndTime()"
                                persistent-hint
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-time-picker 
                          v-model="end_time"
                          format="24hr"
                          @click:minute="setEndTime() && $refs.endTimeField.save(end_time)"
                          color="black"
                          header-color="black">
                        </v-time-picker>

                    </v-menu>
                  </v-flex>
              </v-layout>
              </v-container>
          </v-form>
          </v-card-text>

          <v-card-actions>
          <v-col>
              <v-btn
              text
              @click="showFilter({show: false, actionFilter: false})"
              >Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
              <v-btn
              text
              type="submit"
              right
              @click="filterChecks"
              >Filtrar</v-btn>
          </v-col>
          </v-card-actions>
      </v-card>
    </div>
</template>

<script>
  import { mapActions, mapMutations, mapState } from 'vuex'
  import { mapFields } from 'vuex-map-fields'
  import _ from 'lodash'

  export default {
    name: 'ChecksFilter',
    beforeUpdate() {
      this.setFilterDatesOnUpdate()
    },
    computed: {
        ...mapFields('financial_checks', [
            'filter.type',
            'filter.value',
            'filter.id_pppoker',
            'filter.start_date',
            'filter.start_time',
            'filter.end_date',
            'filter.end_time',
          // ...
        ]),
        ...mapState('financial_checks', ['items', 'filtered']),
        computedStart () {
            return this.start ? this.start : ''
        },
        computedEnd () {
            return this.date ? this.date : ''
        }
    },
    data: () => ({
      possible_status: [
        {status: 0, description: 'Pendente'},
        {status: 1, description: 'Cancelado'},
        {status: 2, description: 'Concluido'},
      ],
      showStart: false,
      showStartTime: false,
      showEnd: false,
      showEndTime: false,
      start_formatted: '',
      end_formatted: '',
      types: [
        { id: 0, description: 'Saque' },
        { id: 1, description: 'Despesa' },
        { id: 2, description: 'Depósito' },
        { id: 3, description: 'Ajuste' }
      ]
    }),
    methods: {
        ...mapMutations('financial_checks', ['showFilter', 'setFiltered', 'setFilterWhenOpened']),
        ...mapActions('financial_checks', ['clearFilter']),
        clearStart() {
            this.start_date = ''
            this.start_formatted = ''
            this.start_time = ''
        },
        clearStartTime() {
            this.start_time = ''
        },
        clearEnd() {
            this.end_date = ''
            this.end_formatted = ''
            this.end_time = ''
        },
        clearEndTime() {
            this.end_time = ''
        },
        filterChecks() {
            if ((this.type !== '' && !_.isUndefined(this.type)) || this.value !== '' || this.id_pppoker !== '' || this.validateDateFields().isValid) {
              this.setFiltered({ filtered: true })
              this.setFilterWhenOpened()
            } else {
              this.setFiltered({ filtered: false })
            }

            this.showFilter({show: false, actionFilter: true})
        },
        formatDate(date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`
        },
        setFilterDatesOnUpdate() {
          if(this.start_date !== "") {
            let splittedStartDate = this.start_date.split('-')
            this.start_formatted = splittedStartDate[2] + "/" + splittedStartDate[1] + "/" + splittedStartDate[0]
          }

          if(this.end_date !== "") {
            let splittedEndDate = this.end_date.split('-')
            this.end_formatted = splittedEndDate[2] + "/" + splittedEndDate[1] + "/" + splittedEndDate[0]
          }
        },
        setStart(date) {
          this.start = date
          this.showStart = false
          this.start_formatted = this.formatDate(date)
        },
        setStartTime() {
          this.showStartTime = false
        },
        setEnd(date) {
          this.end = date
          this.showEnd = false
          this.end_formatted = this.formatDate(date)
        },
        setEndTime() {
          this.showEndTime = false
        },
        validateDateFields() {
          if(this.start_formatted != "" || this.end_formatted != "") {
            let auxSplitDate = []
            let start_date = ''
            let end_date = ''

            if(this.start_formatted === "") {
              start_date = _.minBy(this.items(), function(item) { return item.sort_date; });
              start_date = start_date.sort_date
            } else {
              auxSplitDate = this.start_formatted.split("/")
              let startTime = this.start_time === "" || _.isUndefined(this.start_time) ? "00:00:00" : this.start_time
              start_date = '' + auxSplitDate[2] + '-' + auxSplitDate[1] + '-' + auxSplitDate[0] + ' ' +  startTime
            }

            if(this.end_formatted === "") {
              end_date = _.maxBy(this.items(), function(item) { return item.sort_date; });
              end_date = end_date.sort_date
              this.end_date = end_date
            } else {
              auxSplitDate = this.end_formatted.split("/")
              let endTime = this.end_time === "" || _.isUndefined(this.end_time) ? "23:59:59" : this.end_time
              end_date = '' + auxSplitDate[2] + '-' + auxSplitDate[1] + '-' + auxSplitDate[0] + ' ' +  endTime
            } 

            return { 
              isValid: end_date > start_date,
              startDate: start_date,
              endDate: end_date
            }
          } 

          return {
            isValid: false,
            startDate: '',
            endDate: ''
          }
        }
    }
  }
</script>

<style>

</style>