<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2000"
      :value="showNotification"
    >
      {{ notificationMessage }}
    </v-snackbar>
    <v-toolbar flat class="elevation-5">
      <div>
        <v-toolbar-title>Fechamento Liga</v-toolbar-title>
      </div>
      <v-spacer></v-spacer>
      <div class="button-toolbar">
        
        <v-btn color="black" dark class="ma-2" @click="reload" small
          ><v-icon>mdi-refresh</v-icon></v-btn
        >
        <v-btn color="black" dark class="ma-2" @click="add" small
          ><v-icon>mdi-plus</v-icon></v-btn
        >
      </div>
    </v-toolbar>
    
    <v-data-table
      :headers="headers"
      :loading="loading"
      :items="items"
      :options.sync="pagination"
      :server-items-length="totalItems"
      no-data-text="Nenhum item encontrado"
      class="elevation-10 table-margin"
    >
      <template v-slot:[`item.value`]="{ item }">
        <span>{{
          item.value.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })
        }}</span>
      </template>
      <template v-slot:[`item.start_date`]="{ item }">
        <span>{{ getDate(item.start_date) }}</span>
      </template>
      <template v-slot:[`item.end_date`]="{ item }">
        <span>{{ getDate(item.end_date) }}</span>
      </template>
      <template v-slot:[`item.total_gains`]="{ item }">
        <span>{{
          item.total_gains.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })
        }}</span>
      </template>
      <template v-slot:[`item.total_rake`]="{ item }">
        <span>{{
          item.total_rake.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })
        }}</span>
      </template>
      <template v-slot:[`item.total_value`]="{ item }">
        <span>{{
          item.total_value.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })
        }}</span>
      </template>
      <template v-slot:[`item.payments`]="{ item }">
        <span>{{
          (item.total_value - item.payments).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })
        }}</span>
      </template>
      <template v-slot:[`item.id_league`]="{ item }">
        <span>{{
          leagues.find((league) => league.id === item.id_league).name
        }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{on, attrs}">
            <v-icon small v-on="on" v-bind="attrs" color="yellow darken-3" class="mr-2" @click="viewItem(item)">
                mdi-information
            </v-icon>
          </template>
          <span>Informações</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{on, attrs}">
            <v-icon small v-on="on" v-bind="attrs" color="green darken-2" class="mr-2" @click="addClub(item)">
                mdi-calculator
            </v-icon>
          </template>
          <span>Buscar dados</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{on, attrs}">
            <v-icon small v-on="on" v-bind="attrs" color="red" class="mr-2" @click="showDialogDelete(item)">
                mdi-delete
            </v-icon>
          </template>
          <span>Excluir</span>
        </v-tooltip>
      </template>
      
    </v-data-table>

    <v-dialog :value="dialogDelete" persistent width="300">
      <v-card>
        <v-card-title class="headline">Confirmar exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn
              text
              @click="closeDialogDelete"
            >Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn
              text
              type="submit"
              right
              @click="deleteItem"
            >Confirmar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      :value="dialogFilter"
      width="600"
      @click:outside="showFilter({ show: false })"
    >
      <weekly-revenue-filter></weekly-revenue-filter>
    </v-dialog>
    <v-dialog
      :value="dialogForm"
      width="600"
      @click:outside="closeDialogForm"
    >
      <league-closure-form></league-closure-form>
    </v-dialog>
    
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import LeagueClosureForm from './league_closure_forms/LeagueClosureForm'

export default {
  name: "LeagueClosure",
  components: {
      LeagueClosureForm,
  },
  computed: {
    ...mapState("league_closure", [
      "dialogFilter",
      "dialogForm",
      "dialogDelete",
      "item",
      "loading",
      "mode",
      "notificationType",
      "notificationMessage",
      "rowsPerPage",
      "totalItems",
      "items",
      "overlay",
      "showNotification",
    ]),
    ...mapState('league', {leagues: 'items'}),
    ...mapFields("league_closure", ['pagination'])
  },
  created() {
    this.getItems({ resetPage: false });
    this.getLeagues();
  },
  methods: {
    ...mapMutations("league_closure", [
        "showFilter",
        "add",
        "edit",
    ]),
    ...mapActions("league_closure", [
      "closeDialogForm",
      "getItems",
      "deleteItem",
      "addClub",
      "showDialogDelete",
      "closeDialogDelete",
      "viewItem",
    ]),
    ...mapActions("league", { getLeagues: "getItems" }),
    reload(){
      this.getItems({ resetPage: false })
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      }
      return false;
    },
    getDate(date) {
      if (date) {
        var d = new Date(date + " 03:00:00");
        return d.toLocaleDateString()
      }
      return "";
    },
  },
  data: () => ({
    headers: [
      { text: "ID", value: "id", sortable: false },
      { text: "Descrição", value: "description", sortable: false },
      { text: "Início", value: "start_date", sortable: false },
      { text: "Fim", value: "end_date", sortable: false },
      { text: "Ganho/Perda", value: "total_gains", sortable: false },
      { text: "Rake", value: "total_rake", sortable: false },
      { text: "Resultado", value: "total_value", sortable: false },
      { text: "Valor em aberto", value: "payments", sortable: false },
      { text: "Liga", value: "id_league", sortable: false },
      // { text: "Id Fechamento Liga", value: "league_closure_id", sortable: false },
      { text: "Ações", value: "actions", sortable: false },
    ],
    valid: false,
    timeProps: {
      format: "24hr",
    },
  }),
  watch: {
    pagination: {
      handler() {
        this.getItems({ resetPage: false });
      },
    },
  },
};
</script>

<style>

</style>
