import axios from "axios";
import { getField, updateField } from "vuex-map-fields";
import endPoints from "../../../config/endPoints";

const defaultItem = {
  description: "",
  date: "",
  date_picker: new Date(),
  type: "",
  id_company: null,
  bank: null,
  recurrence: null,
  variable: 0,
  observation: "",
  user_name: "",
  status: 0,
  value: 0,
  pix_key: "",
};

export default {
  namespaced: true,
  state: {
    banks: [],
    companies: [],
    contacts: [],
    dialogRecurrent: false,
    dialogDelete: false,
    dialogFilter: false,
    dialogReceipt: false,
    dialogAutomaticExpense: false,
    dialogImportSpreadsheet: false,
    expenseAutomatic: {},
    expense_types: [],
    editedItem: {},
    filter: {
      id_company: "",
      start: "",
      end: "",
      description: "",
      value: "",
      bank: "",
      user_id: "",
      recurrence: "",
      variable: "",
      type: "",
      status: "",
    },
    instances: [],
    items: [],
    itemToDelete: {},
    loading: false,
    mode: "LIST",
    overlay: false,
    page: 1,
    pagination: {
      page: 1,
      itemsPerPage: 10,
    },
    successNotification: false,
    notificationMessage: "",
    notificationType: "success",
    receipt_show: "",
    selectedContact: {},
    showNotification: false,
    totalItems: 0,
    rowsPerPage: 10,
    spreadsheet_import: {},
    users: [],
  },
  mutations: {
    addRecurrent: (state) => {
      state.dialogRecurrent = true;
      state.mode = "ADD";
      defaultItem.date_picker = new Date();
      console.log(defaultItem)
      state.editedItem = Object.assign({}, defaultItem);
    },
    editRecurrent: (state, payload) => {
      payload.item.date_picker = new Date(payload.item.date);
      state.editedItem = Object.assign({}, payload.item);
      state.editedIndex = state.instances.indexOf(payload.item);
      state.dialogRecurrent = true;
      state.mode = "EDIT";
    },
    closeDialog: (state) => {
      state.editedItem = {};
      state.editedIndex = -1;
      state.errors = [];
      state.dialogRecurrent = false;
      state.mode = "LIST";
    },
    loading: (state, payload) => (state.loading = payload.show),
    setMode: (state, mode) => (state.mode = mode),
    setItems: (state, items) => (state.items = items),
    setItemToDelete: (state, item) => (state.itemToDelete = item),
    setTotalItems: (state, total) => (state.totalItems = total),
    setRowsPerPage: (state, rowsPerPage) => (state.rowsPerPage = rowsPerPage),
    showDialog: (state, show) => (state.dialogRecurrent = show),
    setPage: (state, payload) => {
      state.pagination.page = payload.page;
      state.pagination.itemsPerPage = payload.itemsPerPage;
    },
    setExpenseTypes: (state, payload) => {
      state.expense_types = payload.expense_types;
    },
    setBanks: (state, payload) => {
      state.banks = payload.banks;
    },
    setCompanies: (state, payload) => {
      state.companies = payload.companies;
    },
    setContacts: (state, payload) => {
      state.contacts = payload.contacts;
    },
    setUsers: (state, payload) => {
      state.users = payload.users;
    },
    setDialogDelete: (state, show) => (state.dialogDelete = show),
    setDialogReceipt: (state, payload) => (state.dialogReceipt = payload.show),
    showDialogImportSpreadsheet: (state, payload) =>
      (state.dialogImportSpreadsheet = payload.show),
    showDialogAutomaticExpense: (state, payload) => {
      state.dialogAutomaticExpense = payload.show;
    },
    setInstances: (state, payload) => (state.instances = payload.instances),
    setMasterAgents: (state, payload) =>
      (state.master_agents = payload.master_agents),
    showFilter: (state, payload) => (state.dialogFilter = payload.show),
    showErrorNotification: (state, show) => (state.errorNotification = show),
    showSuccessNotification: (state, show) =>
      (state.successNotification = show),
    showLoading: (state, show) => (state.loading = show),
    showNotification: (state, payload) => {
      (state.showNotification = payload.show),
        (state.notificationMessage = payload.message),
        (state.notificationType = payload.type);
    },
    showOverlay: (state, payload) => {
      state.overlay = payload.show;
    },
    setExpenseAutomatic: (state, payload) => {
      state.expenseAutomatic = payload.expense;
    },
    setSpreadsheetImport: (state, payload) => {
      state.spreadsheet_import = payload.spreadsheet;
    },
    setReceipt: (state, payload) => (state.receipt_show = payload.receipt),
    viewItem(state, item) {
      state.editedItem = item;
      state.editedItem.date_picker = new Date(item.date);
      state.editedIndex = state.items.indexOf(item);
      state.dialogRecurrent = true;
      state.mode = "VIEW";
    },
    updateField,
  },
  getters: {
    getField,
  },
  actions: {
    closeDialog: ({ commit }) => {
      commit("closeDialog", false);
    },
    closeDeleteDialog: ({ commit }) => {
      commit("setDialogDelete", false);
      commit("setItemToDelete", {});
    },
    checkFinishExpense: ({ commit }, payload) => {
      commit("setExpenseAutomatic", {
        expense: Object.assign({}, payload.expense),
      });
      commit("showDialogAutomaticExpense", { show: true });
    },
    deleteItem: ({ dispatch, commit, state }) => {
      console.log("cheguei aqui 2");
      commit("loading", { show: true });
      axios
        .delete(
          endPoints.expenses_recurrent + "/" + state.itemToDelete.id,
          state.itemToDelete
        )
        .then(() => {
          dispatch("closeDeleteDialog");
          commit("loading", { show: false });
          let notification = {
            show: true,
            message: "Despesa excluida com sucesso",
            type: "success",
          };
          commit("showNotification", notification);
          return dispatch("getItems", { resetPage: false });
        });
    },
    scheduleExpense: ({ dispatch, state }, payload) => {
      state.editedItem = Object.assign({}, payload.expense);
      state.editedIndex = state.items.indexOf(payload.expense);
      state.editedItem.status = 1;
      state.mode = "EDIT";
      dispatch("save", { close: false });
    },
    finishExpense: ({ commit, dispatch, state }, payload) => {
      if (state.dialogAutomaticExpense == true) {
        commit("showDialogAutomaticExpense", { show: false });
      }
      state.editedItem = Object.assign({}, payload.expense);
      state.editedIndex = state.items.indexOf(payload.expense);
      state.editedItem.status = 2;
      state.mode = "EDIT";
      dispatch("save", { close: false });
    },
    finishAutomaticExpense: ({ commit, dispatch }, payload) => {
      commit("loading", { show: true });
      if (payload.expense.bank) {
        axios
          .put(endPoints.pix + "/expense", payload.expense)
          .then(() => {
            commit("showDialogAutomaticExpense", { show: false });
            let notification = {
              show: true,
              message: "Pagamento da despesa em processamento!",
              type: "success",
            };
            commit("setExpenseAutomatic", { expense: {} });
            commit("loading", { show: false });
            commit("showNotification", notification);
            dispatch("getItems", { resetPage: false });
          })
          .catch((error) => {
            console.log("Dei erro aqui: ", error);
            let notification = {
              show: true,
              message:
                "Ocorreu um erro ao realizar o envio do Pix. Realize o envio manual!",
              type: "error",
            };
            commit("loading", { show: false });
            commit("showNotification", notification);
          });
      } else {
        let notification = {
          show: true,
          message: "Banco para pagamento deve ser informado",
          type: "error",
        };
        commit("showNotification", notification);
      }
    },
    getCompanies: ({ commit }) => {
      let url = endPoints.companies;
      url += "?page=" + 1 + "&" + "itemsPerPage=" + 999;
      axios.get(url).then((items) => {
        var companies = items.data.data;
        commit("setCompanies", { companies: companies });
      });
    },
    getBanks: ({ commit }) => {
      let url = endPoints.banks;
      url += "?page=" + 1 + "&" + "itemsPerPage=" + 999;
      axios.get(url).then((items) => {
        var banks = items.data.data;
        commit("setBanks", { banks: banks });
      });
    },
    getExpenseTypes: ({ commit }) => {
      let url = endPoints.expense_types;
      url += "?page=" + 1 + "&" + "itemsPerPage=" + 999;
      axios.get(url).then((items) => {
        var types = items.data.data;
        commit("setExpenseTypes", { expense_types: types });
      });
    },
    getItems: ({ commit, state }, payload) => {
      commit("loading", { show: true });
      let url = endPoints.expenses_recurrent;
      let filter = state.filter;
      if (payload.resetPage === true) {
        url += "?page=" + 1 + "&" + "itemsPerPage=" + 10;
        commit("setPage", { page: 1, itemsPerPage: 10 });
      } else {
        url +=
          "?page=" +
          state.pagination.page +
          "&" +
          "itemsPerPage=" +
          state.pagination.itemsPerPage;
      }

      Object.keys(filter).forEach(function(field) {
        if (filter[field] !== "" && filter[field] !== undefined) {
          if (field == "end") {
            var e = new Date(filter.end);
            e.setDate(e.getDate() + 1);
            url += "&" + field + "=" + e.toISOString().substr(0, 10);
          } else {
            url += "&" + field + "=" + filter[field];
          }
        }
      });

      axios.get(url).then((items) => {
        commit("setTotalItems", items.data.total);
        commit("loading", { show: false });
        commit("setItems", items.data.data);
      });
    },
    getItemsNoPage: ({ state }) => {
      let url = endPoints.expenses_recurrent;
      let filter = state.filter;
      url += "?page=" + "1" + "&" + "itemsPerPage=" + "99999";

      Object.keys(filter).forEach(function(field) {
        if (filter[field] !== "" && filter[field] !== undefined) {
          if (field == "end") {
            var e = new Date(filter.end);
            e.setDate(e.getDate() + 1);
            url += "&" + field + "=" + e.toISOString().substr(0, 10);
          } else {
            url += "&" + field + "=" + filter[field];
          }
        }
      });
      return axios.get(url).then((items) => {
        return items.data.data;
      });
    },
    getUsers: ({ commit }, payload) => {
      let url = endPoints.users;
      url += "/attendants/" + payload.search;

      axios.get(url).then((users) => {
        commit("setUsers", { users: users.data });
      });
    },
    showDeleteDialog: ({ commit }, item) => {
      console.log("cheguei no showDeleteDialog");
      commit("setDialogDelete", true);
      commit("setItemToDelete", item);
    },
    save: async ({ dispatch, commit, state }) => {
      var url = endPoints.expenses_recurrent;
      let func = "";
      let data = state.editedItem;
      commit("showOverlay", { show: true });
      if (state.mode == "ADD") {
        func = axios.post;
        url = endPoints.expenses_recurrent;
      } else {
        func = axios.put;
        url += "/" + data.id;
      }

      func(url, data).then(
        () => {
          dispatch("getItems", { resetPage: true });
          let notification = {
            show: true,
            message: "Despesa salva com sucesso",
            type: "success",
          };
          commit("showNotification", notification);
          commit("closeDialog");
          commit("showOverlay", { show: false });
        },
        (error) => {
          let notification = {
            show: true,
            message: error.response.data.message,
            type: "error",
          };
          commit("showNotification", notification);
          commit("showOverlay", { show: false });
        }
      );
    },
    importSpreadsheet: ({ commit, dispatch, state }) => {
      let url = endPoints.expenses_recurrent + "/import_spreadsheet";
      let formData = new FormData();
      formData.append("file", state.spreadsheet_import);
      commit("showOverlay", { show: true });
      axios
        .post(url, formData)
        .then(() => {
          let notification = {
            show: true,
            message: "Planilha importada com sucesso.",
            type: "success",
          };
          commit("setSpreadsheetImport", { spreadsheet: null });
          commit("showNotification", notification);
          commit("showDialogImportSpreadsheet", { show: false });
          commit("loading", { show: false });
          commit("showOverlay", { show: false });
          dispatch("getItems", { resetPage: true });
        })
        .catch(() => {
          let notification = {
            show: true,
            message:
              "Ocorreu um erro ao importar a planilha. Contate o administrador do sistema.",
            type: "error",
          };
          commit("loading", { show: false });
          commit("showOverlay", { show: false });
          commit("setSpreadsheetImport", { spreadsheet: null });
          commit("showNotification", notification);
        });
    },
    showReceipt: ({ commit }, payload) => {
      let url = endPoints.receipt;

      url += "/" + payload.item.id + "?type=1";
      commit("showOverlay", { show: true });
      axios
        .get(url)
        .then((r) => {
          let file = r.data.file;
          let mimetype = file.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
          if (mimetype.indexOf("pdf") != -1) {
            let name = "Comprovante-" + payload.item.id + ".pdf";
            var a = document.createElement("a"); //Create <a>
            a.href = file; //Image Base64 Goes here
            a.download = name; //File name Here
            a.click();
          } else {
            commit("setReceipt", { receipt: r.data.file });
            commit("setDialogReceipt", { show: true });
          }
          commit("showOverlay", { show: false });
        })
        .catch(() => {
          let notification = {
            show: true,
            message: "Comprovante não encontrado",
            type: "error",
          };
          commit("showNotification", notification);
          commit("showOverlay", { show: false });
        });
    },
  },
};
