<template>
  <v-card>
    <v-card-title class="headline justify-center">{{message}}</v-card-title>

    <v-card-actions>
      <v-col>
        <v-btn
          text
          @click="cancelFunc"
        >Cancelar</v-btn>
      </v-col>
      <v-col class="text-right">
        <v-btn
          text
          type="submit"
          right
          @click="confirmFunc"
        >Confirmar</v-btn>
      </v-col>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
    name: 'CardConfirm',
    props: [
        'cancelFunc',
        'confirmFunc',
        'message',
    ],
}
</script>