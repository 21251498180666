<template>
  <div>
    <div>

      <v-card>
        <v-card-title>
          <span class="headline">Produtos</span>
          <v-spacer></v-spacer>
          <v-progress-circular
            v-if="loading"
            indeterminate
            class="text-right"
            color="primary"
            size="24"
          ></v-progress-circular>

        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Nome *"
                    v-model="name"
                    :disabled="this.mode == 'EDIT'"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('name')"
                    v-validate="'required:Nome,name|max_length:100'"
                    data-vv-name="name"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                      required
                      label="Valor *"
                      v-model="value"
                      :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                      :error-messages="errors.collect('value')"
                      v-validate="'required:Valor,value|decimal:2'"
                      data-vv-name="value"
                      outlined
                  ></v-text-field>
                  </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Comprimento (cm)"
                    v-model="length"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('length')"
                    v-validate="'decimal'"
                    data-vv-name="length"
                    outlined
                    type="number"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Altura (cm)"
                    v-model="height"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('height')"
                    v-validate="'decimal'"
                    data-vv-name="height"
                    outlined
                    type="number"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Largura (cm)"
                    v-model="width"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('width')"
                    v-validate="'decimal'"
                    data-vv-name="width"
                    outlined
                    type="number"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Peso (kg)"
                    v-model="weight"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('weight')"
                    v-validate="'decimal'"
                    data-vv-name="weight"
                    outlined
                    type="number"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-select
                      label="Tipo *"
                      :items="possible_types"
                      v-model="type"
                      :readonly="this.mode == 'VIEW'"
                      :error-messages="errors.collect('type')"
                      v-validate="'required:Tipo,type'"
                      item-text="description"
                      item-value="id"
                      data-vv-name="type"
                      outlined
                  >
                  </v-select>
                  </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-col>
            <v-btn text @click="closeDialog">{{ mode == 'VIEW' ? 'Voltar' : 'Cancelar'}}</v-btn>
          </v-col>
          <v-col class="text-right" v-if="mode != 'VIEW'">
            <v-btn text type="submit" right @click="saveProduct">Salvar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "ProductsForm",
  computed: {
    ...mapState("products", [
      "loading",
      "mode",
    ]),
    ...mapFields("products", [
      "editedItem.name",
      "editedItem.length",
      "editedItem.width",
      "editedItem.height",
      "editedItem.weight",
      "editedItem.type",
      "editedItem.value"
      // ...
    ]),
  },
  created() {
    this.$validator.extend("required", {
      getMessage: (field, [label]) => label + " deve ser informado",
      validate: (_, [, field]) => {
        if (this[field] || this[field] === 0) {
          return true;
        } else {
          return false;
        }
      },
    });
    this.$validator.extend("max_length", {
      getMessage: (_, [length]) => "No máximo " + length + " caracteres.",
      validate: (val, [length]) => {
        if (val.length > length) {
          return false;
        } else {
          return true;
        }
      },
    });
  },
  methods: {
    ...mapMutations("products", ["closeDialog"]),
    ...mapActions("products", ["save"]),
    saveProduct() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.value = parseFloat(this.value)
          if(this.length) this.length = parseInt(this.length)
          if(this.width) this.width = parseInt(this.width)
          if(this.height) this.height = parseInt(this.height)
          if(this.weight) this.weight = parseInt(this.weight)
          this.save();
        }
      });
    },
  },
  data: () => ({
    valid: false,
    possible_types: [
      {id: 0, description: 'Moletom'},
      {id: 1, description: 'Blusa'},
      {id: 2, description: 'Boné'},
      {id: 3, description: 'Calça'},
      {id: 4, description: 'Bermuda'},
      {id: 5, description: 'Outros'},
    ],
  })
};
</script>
