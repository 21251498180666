<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-toolbar flat class="elevation-5">
        <div>
          <v-toolbar-title>Anotados</v-toolbar-title>
        </div>

        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="black" dark class="ma-2" @click="showSendMessageModal({show: true})" small>
              <v-icon>mdi-message</v-icon>
            </v-btn>
          </template>
          <span>Enviar Mensagem</span>
        </v-tooltip>

        <v-spacer></v-spacer>
        <div class="download_annotated">
          <!-- <download-excel
            :before-generate="generateSheet"
            :before-finish="finishSheet"
            :name="getSheetName()"
            :fetch="getItemsNoPage"
            :fields="sheet_fields"
            worksheet = "Anotados"
          >
           <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" color="black" dark small class="ma-2">
                  <v-icon>mdi-file-excel</v-icon>
                </v-btn>
              </template>
              <span>Gerar planilha</span>
            </v-tooltip>
          </download-excel>   -->

          <v-tooltip bottom>
            <template v-slot:activator="{on, attrs}">
              <v-btn color="black" dark class="ma-2" @click="downloadXlsx({ show: true })" v-bind="attrs" v-on="on" small>
                <v-icon>mdi-file-excel</v-icon>
              </v-btn>
            </template>
            <span>Gerar Planilha</span>
          </v-tooltip>
        </div>    
                  
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="black" dark class="ma-2" @click="getItems" small>
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Recarregar</span>
        </v-tooltip>
                  
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="black" dark class="ma-2"  @click="showFilter({show: true})" small>
              <v-icon :color="filtered == true ? 'grey' : 'white'">mdi-filter</v-icon>
            </v-btn>
          </template>
          <span>Filtrar</span>
        </v-tooltip>
       
    </v-toolbar>
    <v-dialog :value="dialogFilter" @click:outside="showFilter({show: false})" width="600" @keydown.enter="filter">
      <manage-annotated-filter></manage-annotated-filter>
    </v-dialog>
    <v-dialog :value="dialogSendMessageModal" @click:outside="showSendMessageModal({show: false})" width="600">
      <manage-annotated-send-message></manage-annotated-send-message>
    </v-dialog>
    <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="pagination"
        :item-class="getClassColor"
        :server-items-length="totalItems"
        class="elevation-10 table-margin table-at"
        show-expand
        :expanded.sync="expanded"
    >
      <template v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }">
        <v-icon @click="expand(true)" v-if="item.description && !isExpanded">mdi-chevron-down</v-icon>
        <v-icon @click="expand(false)" v-if="item.description && isExpanded">mdi-chevron-up</v-icon>
      </template>
      <template v-slot:expanded-item="{ item }">
        <td :colspan="headers.length">Motivo para alteração manual: {{ item.description }}</td>
      </template>
      <template v-slot:[`item.type_deal`]="{ item }">
        <span>{{ getTypeDeal(item.type_deal) }}</span>
      </template>
      <template v-slot:[`item.date_created`]="{ item }">
        <span>{{ getDate(item.date_created)}}</span>
      </template>
      <template v-slot:[`item.origin`]="{ item }">
        <span>{{ getOrigin(item.origin)}}</span>
      </template>
      <template v-slot:[`item.pending_payment`]="{ item }">
        <span>{{ (item.pending_payment) ? item.pending_payment.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0.00' }}</span>
      </template>
      <template v-slot:[`item.current_week`]="{ item }">
        <span>{{ (item.current_week) ? item.current_week.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0.00' }}</span>
      </template>
      <template v-slot:[`item.changed_value`]="{ item }">
        <span>{{ (item.changed_value) ? item.changed_value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0.00' }}</span>
      </template>
      
      <template v-slot:[`item.actions`]="{ item }">
        
        <v-tooltip bottom v-if="item.validate==0">
          <template v-slot:activator="{ attrs, on }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              small
              class="mr-2"
              @click="validateAnnotated(item)"
              >mdi-account-check</v-icon
            >
          </template>
          <span>Validar Anotado</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
  import { mapActions, mapMutations, mapState } from 'vuex'
  import { mapFields } from 'vuex-map-fields'
  import ManageAnnotatedFilter from './ManageAnnotatedFilter'
  import ManageAnnotatedSendMessage from './ManageAnnotatedSendMessage'
  // import JsonExcel from 'vue-json-excel'
  import { utils, writeFile } from "xlsx";

  export default {
    
    components: {
      "manage-annotated-filter": ManageAnnotatedFilter,
      "manage-annotated-send-message": ManageAnnotatedSendMessage,
      // "download-excel": JsonExcel,
    },
    computed: {
      ...mapState('manage_annotated', ['annotated', 'filtered', 'items', 'totalItems']),
      ...mapFields('manage_annotated', [
          'dialogFilter',
          'dialogSendMessageModal',
          'filter.contact_id',
          'filter.type_report',
          'filter.order_by',
          'pagination'
      ]),
    },
    created () {
      this.getSlots({ resetPage: false, noPages: true, onlyIfNotSearched: true })
      this.getItems()
    },
    data: () => ({
      date: '',
      expanded: [],
      headers: [
        { text: '', value: 'data-table-expand', sortable: false },
        { text: "Clube", value: "instance_name", sortable: false },
        { text: "Cliente",  value: "client_name", sortable: false },
        { text: "Contato",  value: "contact_name", sortable: false },
        { text: "Nick", value: "nick_agent", sortable: false },
        { text: "P. Pendente", value: "pending_payment", sortable: false },
        { text: "Tipo de Acordo", value: "type_deal", sortable: false },
        { text: "Semana Atual", value: "current_week", sortable: false },
        { text: "Valor Alterado", value: "changed_value", sortable: false },
        { text: "Data", value: "date_created", sortable: false },
        { text: "Observação", value: "observation", sortable: false },
        { text: "Usuario", value: "user_name", sortable: false },
        { text: "Origem", value: "origin", sortable: false },
        { text: "Id Transação", value: "id_transaction", sortable: false },
        
        { text: "Ações", value: "actions", sortable: false, align: "center", width: "10%"},
      ],
      json_meta: [
        [
          {
              'key': 'charset',
              'value': 'utf-8'
          }
        ]
      ],
      sheet_fields: {
        Clube: {
          field: 'instance_name'
        },
        IdAgent: {
          field: 'id_agent'
        },
        Cliente: {
          field: 'client_name'
        },
        Contato: {
          field: 'contact_name',
        },
        Nick: {
          field: 'nick_agent'
        },
        "P. Pendente": {
          field: "pending_payment",
          callback: (value) => {
            if (value) {
              let real = value.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              });
              if(value < 0) {
                real = real.substr(4);
                return "-" + real
              } else {
                return real.substr(3);
              }
            }
            return '0,00';
          },
        },
        "Tipo de Acordo": {
          field: "type_deal",
          callback: (value) => {
            let types = ["Ganho/Perda", "Giro", "Normal", "Fichas Enviadas/Retiradas", "Sem Acerto", "Sem Acerto/GP"];
            if (types[value]) {
              return types[value];
            }
            return "---";
          },
        },
        "Semana Atual": {
          field: "current_week",
          callback: (value) => {
            if (value) {
              let real = value.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              });
              if(value < 0) {
                real = real.substr(4);
                return "-" + real
              } else {
                return real.substr(3);
              }
            }
            return '0,00';
          },
        },
        "Valor Alterado": {
          field: "changed_value",
          callback: (value) => {
            if (value) {
              let real = value.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              });
              if(value < 0) {
                real = real.substr(4);
                return "-" + real
              } else {
                return real.substr(3);
              }
            }
            return '0,00';
          },
        },
        Data : {
          field: 'date_created',
            callback: (value) => {
              if(value) {
                var d = new Date(value)
                return d.toLocaleDateString() + " " + d.toLocaleTimeString()
              }
              else {
                return ''
              }
          }
        },
        "Usuário": {
          field: 'user_name'
        },
        "Observação": {
          field: 'observation'
        },
        "Nº Contato": {
          field: 'contact_id',
          callback: (value) => {
            return "'" + value
          }
        },
        "Origem": {
          field: 'origin',
          callback: (value) => {
            if(value == 1) {
              return 'Fechamento'
            } else if(value == 2) {
              return 'Deposito'
            } else if(value == 3) {
              return 'Saque'
            } else {
              return 'Manual'
            }
          }
        },
        "Id Transação": {
          field: 'id_transaction'
        },
      },
      overlay: false,
    }),
    methods: {
      ...mapActions("slots", [
        "getSlots",
      ]),
      ...mapActions('manage_annotated', ['getItems', 'getItemsNoPage', 'validateAnnotated']),
      ...mapMutations('manage_annotated', ['showFilter', 'showSendMessageModal']),  
      finishSheet() {
        this.overlay = false
      },
      generateSheet() {
        this.overlay = true
      },
      getOrigin(origin){
        let origins = ["Manual", "Fechamento", "Deposito", "Saque", "Correção Fechamento", "Correção de Saldo Incorreto", "Correção de Valor Lançado Incorretamente", "Multa Suprema"]
        return origins[origin]
      },
      getClassColor(item) {
        if (item.validate == 1){
          return "row-green";
        } else {
          return "row-red";
        }
      },
      getSheetName() {
        var date = new Date()
        var name = 'anotados_' + date.toLocaleDateString() + " " + date.toLocaleTimeString()
        return name + '.xlsx'
      },
      getDate(date) {
        if(date) {
          var d = new Date(date)
          return d.toLocaleDateString() + " " + d.toLocaleTimeString()
        }
        else {
          return ''
        }
      },
      getTypeDeal(type) {
        let types = ["Ganho/Perda", "Giro", "Normal", "Fichas Enviadas/Retiradas", "Sem Acerto", "Sem Acerto/GP"];
        if (types[type]) {
          return types[type];
        }
        return "---";
      },
      async downloadXlsx() {
        this.generateSheet()
        try {
          let itemsNoPage = await this.getItemsNoPage()
          if(itemsNoPage) {
            const wb = utils.book_new()
            let items = itemsNoPage.map(row => ({
              "Clube":row.instance_name,
              "IdAgent": row.id_agent,
              "Cliente": row.client_name,
              "Contato": row.contact_name,
              "Nick": row.nick_agent,
              "P. Pendente": row.pending_payment,
              "Tipo de Acordo": this.typeDealXLSX(row.type_deal),
              "Semana Atual": row.current_week,
              "Valor Alterado": row.changed_value,
              "Data": this.dateCreatedXLSX(row.date_created),
              "Usuário": row.user_name,
              "Observação": row.observation,
              "Nº Contato": this.contactIdXLSX(row.contact_id),
              "Origem": this.originXLSX(row.origin),
              "Id Transação": row.id_transaction,
            }))
            const ws = utils.json_to_sheet(items);
            utils.book_append_sheet(wb, ws, "Anotados");
            await writeFile(wb, this.getSheetName());
          }
        } catch (error) {
          this.finishSheet()
        } finally {
          this.finishSheet()
        }
      },
      typeDealXLSX: (value) => {
        let types = ["Ganho/Perda", "Giro", "Normal", "Fichas Enviadas/Retiradas", "Sem Acerto", "Sem Acerto/GP"];
        if (types[value]) {
          return types[value];
        }
        return "---";
      },
      /* changedValueXLSX: (value) => {
        if (value) {
          let real = value.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          });
          if(value < 0) {
            real = real.substr(4);
            return "-" + real
          } else {
            return real.substr(3);
          }
        }
        return '0,00';
      }, */
      dateCreatedXLSX: (value) => {
        if(value) {
          var d = new Date(value)
          return d.toLocaleDateString() + " " + d.toLocaleTimeString()
        }
        else {
          return ''
        }
      },
      contactIdXLSX: (value) => {
        return "'" + value
      },
      originXLSX: (value) => {
        if(value == 1) {
          return 'Fechamento'
        } else if(value == 2) {
          return 'Deposito'
        } else if(value == 3) {
          return 'Saque'
        } else {
          return 'Manual'
        }
      }
    },
    watch: {    
      pagination: {
        handler() {
          this.getItems({ resetPage: false });
        },
      },
    },
  }
</script>

<style>
  .ag {
    margin-bottom: -10px;
  }

</style>

