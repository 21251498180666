<template>
  <div>
    <v-tabs v-model="tab" class="elevation-10">
      
      <v-tab>Por Hora</v-tab>
      <v-tab>Por Dia</v-tab>
      <v-tab>Monitoramento</v-tab>
      
      
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-tab-item class="dashboard-tab">
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex sm12 md12 xs12 xl12>
              <v-card class="elevation-10">
                <v-card-title class="text-center justify-center"
                  >Atendimentos Últimas 24 Horas</v-card-title
                >
                <GChart
                  type="ColumnChart"
                  :data="chartAttendancesDayQuantity"
                  :options="options_quantity_day"
                  :resizeDebounce="500"
                />
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-tab-item>
      <v-tab-item class="dashboard-tab">
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex sm12 md12 xs12 xl12>
              <v-card class="elevation-10">
                <v-card-title class="text-center justify-center"
                  >Atendimentos Últimos 30 dias</v-card-title
                >
                <GChart
                  :settings="{ packages: ['bar'] }"
                  type="ColumnChart"
                  :data="chartAttendancesMonthQuantity"
                  :options="options_quantity_month"
                  :resizeDebounce="500"
                  height="800"
                />
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-tab-item>
      <v-tab-item class="dashboard-tab">
        <v-card class="elevation-10 d-flex flex-column">
          <v-flex class="d-flex flex-row justify-space-between">
            <h1 class="heading-1 pl-5 pt-6 pb-3">Depósitos</h1>
            <v-flex xs12 md2>
              <template v-if="instances">
                <v-select
                  clear-icon="mdi-close"
                  :clearable="true"
                  :items="instancesName"
                  @change="changeSelectedInstance"
                  item-value="instances"
                  item-text="name"
                  label="Clube"
                  outlined
                  class="pr-5 pt-6 pb-3"
                ></v-select>
              </template>
            </v-flex>
          </v-flex>
          <v-container class="card-columns" grid-list-md fluid>
            <v-layout wrap>
              <v-flex sm12 xs12 md3 x12>
                <v-card class="elevation-10 d-flex flex-column text-center">
                  <h2 class="grey--text text--darken-4">Pendentes</h2>
                  <hr />
                  <h2 :class="pendingDepositStatus(pendingDeposits)">
                    {{ pendingDeposits }}
                  </h2>
                </v-card>
              </v-flex>

              <v-flex sm12 xs12 md3 x12>
                <v-card class="elevation-10 d-flex flex-column text-center">
                  <h2 class="grey--text text--darken-4">Agendados/Erro</h2>
                  <hr />
                  <h2>
                    {{ errorDeposits }}
                  </h2>
                </v-card>
              </v-flex>

              <v-flex sm12 xs12 md3 xl3>
                <v-card
                  class="elevation-10 d-flex flex-column text-center grey--text text--darken-4"
                >
                  <h2>Feitos hoje</h2>
                  <hr />
                  <h2>
                    {{ todayDeposits }}
                  </h2>
                </v-card>
              </v-flex>

              <v-flex sm12 xs12 md3 xl3>
                <v-card
                  class="elevation-10 d-flex flex-column text-center grey--text text--darken-4"
                >
                  <h2>Tempo médio</h2>
                  <hr />
                  <h2>
                    {{ buildTimeString(average_time_deposits) }}
                  </h2>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
          <h1 class="heading-1 pl-5 pt-6 pb-3">Saques</h1>
          <v-container class="card-columns" grid-list-md fluid>
            <v-layout wrap>
              <v-flex sm12 xs12 md3 x12>
                <v-card class="elevation-10 d-flex flex-column text-center">
                  <h2 class="grey--text text--darken-4">Pendentes</h2>
                  <hr />
                  <h2 :class="pendingWithdrawalStatus(pendingWithdrawals)">
                    {{ pendingWithdrawals }}
                  </h2>
                </v-card>
              </v-flex>

              <v-flex sm12 xs12 md3 x12>
                <v-card class="elevation-10 d-flex flex-column text-center">
                  <h2 class="grey--text text--darken-4">Agendados/Erro</h2>
                  <hr />
                  <h2>
                    {{ errorWithdrawals }}
                  </h2>
                </v-card>
              </v-flex>

              <v-flex sm12 xs12 md3 x12>
                <v-card class="elevation-10 d-flex flex-column text-center">
                  <h2 class="grey--text text--darken-4">Feitos hoje</h2>
                  <hr />
                  <h2>
                    {{ todayWithdrawals }}
                  </h2>
                </v-card>
              </v-flex>

              <v-flex sm12 xs12 md3 xl3>
                <v-card
                  class="elevation-10 d-flex flex-column text-center grey--text text--darken-4"
                >
                  <h2>Tempo médio</h2>
                  <hr />
                  <h2>
                    {{ buildTimeString(average_time_withdrawals) }}
                  </h2>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>

          <h1 class="heading-1 pl-5 pt-6 pb-3">Chats</h1>
          <v-container class="card-columns" grid-list-md fluid>
            <v-layout wrap>
              <v-flex sm12 xs12 md3 x12>
                <v-card class="elevation-10 d-flex flex-column text-center">
                  <h2 class="grey--text text--darken-4">Em aberto - Agentes</h2>
                  <hr />
                  <h2 :class="openChatStatus(openChatsAgents)">
                    {{ openChatsAgents }}
                  </h2>
                </v-card>
              </v-flex>

              <v-flex sm12 xs12 md3 x12>
                <v-card class="elevation-10 d-flex flex-column text-center">
                  <h2 class="grey--text text--darken-4">
                    Em aberto - Jogadores
                  </h2>
                  <hr />
                  <h2 :class="openChatStatus(openChatsPlayers)">
                    {{ openChatsPlayers }}
                  </h2>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  created() {
    this.getItemsDay();
    this.getItemsMonth();
    this.getInstances();
    this.loadData();
  },
  mounted() {},
  computed: {
    ...mapState("login", ["userData"]),
    ...mapState("attendance_dashboard", [
      "average_time_deposits",
      "average_time_withdrawals",
      "errorDeposits",
      "errorWithdrawals",
      "items_day_quantity",
      "items_month_quantity",
      "instances",
      "pendingDeposits",
      "pendingWithdrawals",
      "todayWithdrawals",
      "todayDeposits",
      "openChats",
      "overlay"
    ]),
    chartAttendancesDayQuantity() {
      return [this.headers_quantity_day, ...this.items_day_quantity];
    },
    chartAttendancesMonthQuantity() {
      return [this.headers_quantity_month, ...this.items_month_quantity];
    },
    instancesName() {
      return this.instances.map((instance) => instance.name);
    },
    openChatsAgents() {
      let openChats = 0;
      if (this.openChats) {
        if (this.openChats.length > 0) {
          this.openChats.forEach((o) => {
            if (o.is_group == true) {
              openChats = o.open_chats;
            }
            if(openChats == "")
              openChats = 0
          });
        }
      }

      return openChats;
    },
    openChatsPlayers() {
      let openChats = 0;
      if (this.openChats) {
        if (this.openChats.length > 0) {
          this.openChats.forEach((o) => {
            if (o.is_group == false) {
              openChats = o.open_chats;
            }
          });
        }
      }

      return openChats;
    },
  },
  data() {
    return {
      headers_quantity_day: [
        { type: "string", label: "Hora" },
        { type: "number", label: "Quantidade" },
      ],
      headers_quantity_month: [
        { type: "string", label: "Dia" },
        { type: "number", label: "00:00 as 06:00" },
        { type: "number", label: "06:00 as 12:00" },
        { type: "number", label: "12:00 as 18:00" },
        { type: "number", label: "18:00 as 00:00" },
        { type: "number", label: "Total" },
      ],
      options_quantity_day: {
        chart: {
          title: "Atendimentos",
          subtitle: "(Últimas 24 Horas)",
        },
        legend: { position: "right" },
        vAxis: {
          title: "Quantidade",
        },
      },
      options_quantity_month: {
        chart: {
          title: "Atendimentos",
          subtitle: "(Últimos 30 dias)",
        },
        legend: { position: "right" },
        vAxis: {
          title: "Quantidade",
          height: 400,
        },
        series: { 4: { type: "line" } },
      },
      tab: "",
    };
  },
  methods: {
    ...mapMutations("attendance_dashboard", ["setSelectedInstance"]),
    ...mapActions("attendance_dashboard", [
      "getItemsDay",
      "getItemsMonth",
      "getDeposits",
      "getInstances",
      "getTodayDeposits",
      "getWithdrawals",
      "getTodayWithdrawals",
      "getChats",
    ]),
    changeSelectedInstance(e) {
      this.setSelectedInstance(e);
      this.loader();
    },
    showAttendanceAdmin() {
      let roles = [0, 1, 2, 93, 95, 96, 97, 98, 99];
      return roles.indexOf(this.userData.role) != -1;
    },
    pendingDepositStatus(data) {
      if (data.length < 12) {
        return { "green--text": true };
      }
      return data.length < 20
        ? { "yellow--text": true }
        : { "red--text": true };
    },
    pendingWithdrawalStatus(data) {
      if (data.length < 5) {
        return { "green--text": true };
      }
      return data.length < 10
        ? { "yellow--text": true }
        : { "red--text": true };
    },
    openChatStatus(data) {
      return data >= 10 ? { "red--text": true } : {};
    },
    buildTimeString(data) {
      return `${Math.trunc(data / 3600)}:${
        Math.trunc(data / 60) % 60
      }:${Math.trunc(data % 60)}`;
    },
    loader() {
      this.getDeposits();
      this.getWithdrawals();
      this.getChats();
    },
    loadData() {
      this.loader();
      setTimeout(this.loadData, 30000);
    },
  },
};
</script>

<style scoped>
.dashboard-tab {
  background-color: #f2f2f2;
}
</style>
