<template>
  <div>
    <v-card class="elevation-10"> 
      <v-card>
        <v-card-title>
          <div class="text-left">
            Saldo Jogador: {{ balance_player.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}
          </div>
          <v-divider/>
          <div class="text-right">
            Saldo Agência: {{ balance_agency.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}
          </div>
        </v-card-title>
        <v-card-title class="text-center justify-center">
            Saldo Por Clube
        </v-card-title>
        <v-data-table
          :headers="headersClub"
          :loading="loading_club"
          :items="items_club"
          :server-items-length="totalItemsClub"
          hide-default-footer
          no-data-text="Saldo por clube não encontrado"
          class="elevation-10 table-margin"
        >
          <template v-slot:[`item.balance`]="{ item }">
            <span>{{ item.balance.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
          </template>
          <template v-slot:[`item.balance_agency`]="{ item }">
            <span>{{ item.balance_agency.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
          </template>

        </v-data-table>
      </v-card>
      <v-card-title class="text-center justify-center">
        Histórico Periodos
      </v-card-title>
      <v-data-table
        :headers="headers"
        :loading="loading"
        :items="items"
        :options.sync="pagination"
        :server-items-length="totalItems"
        no-data-text="Nenhum historico encontrado"
        class="elevation-10 table-margin"
      >
        <template v-slot:[`item.player_gains`]="{ item }">
          <span>{{ item.player_gains.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
        </template>
        <template v-slot:[`item.finish_date`]="{ item }">
          {{getDate(item.finish_date)}}
        </template>
        <template v-slot:[`item.sended`]="{ item }">
          <span>{{ item.sended.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
        </template>
        <template v-slot:[`item.received`]="{ item }">
          <span>{{ item.received.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
        </template>
        <template v-slot:[`item.balance`]="{ item }">
          <span>{{ item.balance.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
        </template>
        <template v-slot:[`item.sended_agency`]="{ item }">
          <span>{{ item.sended_agency.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
        </template>
        <template v-slot:[`item.received_agency`]="{ item }">
          <span>{{ item.received_agency.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
        </template>
        <template v-slot:[`item.balance_agency`]="{ item }">
          <span>{{ item.balance_agency.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
    name: "BalanceAgent",
    computed: {
      ...mapState("balance_player", ["balance_agency", "balance_player", "loading", "loading_club", "items", "items_club", "totalItems", "totalItemsClub"]),
      ...mapFields("balance_player", ["pagination"])
    },
    created() {
      this.getItems()
      this.getItemsByClub()
      this.$validator.extend("required", {
        getMessage: (field, [label]) => label + " deve ser informado",
        validate: (_, [, field]) => {
            if (this[field] || this[field] === 0) {
            return true;
            } else {
                return false;
            }
        },
      });
    },
    methods: {
      ...mapActions("balance_player", ["getItems", "getItemsByClub"]),
      getDate(date) {
        if(date) {
          var d = new Date(date)
          return d.toLocaleDateString()
        }
        else {
          return ''
        }
      },
    },
    data: () => ({
      headers: [
        { text: "Periodo", value: "period_description", sortable: false, align: 'left' },
        { text: "Data", value: "finish_date", sortable: false },
        { text: "Ganhos/Perdas", value: "player_gains", sortable: false },
        { text: "Recebido", value: "received", sortable: false },
        { text: "Enviado", value: "sended", sortable: false, align: 'right' },
        { text: "Saldo", value: "balance", sortable: false, align: 'right' },
        { text: "Recebido Agência", value: "received_agency", sortable: false, align: 'right' },
        { text: "Enviado Agência", value: "sended_agency", sortable: false, align: 'center' },
        { text: "Saldo Agência", value: "balance_agency", sortable: false, align: 'right' },
      ],
      headersClub: [
        { text: "Clube", value: "club_name" },
        { text: "Saldo Jogador", value: "balance" },
        { text: "Saldo Agência", value: "balance_agency" }
      ]
    }),
    props: [
      "agent_id"
    ],
    watch: {
      pagination: {
        handler () {
          this.getItems({resetPage: false, agent_id: this.agent_id})
        },
      }
    }
};
</script>

