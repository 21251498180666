<template>
    <div>
        <v-toolbar flat class="mb-0">
            <div class="button-toolbar mt-4 mr-4">
                <v-tooltip bottom dense size="30">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="#E4E4E4"
                            elevation="0"
                            class="py-4"
                            @click="setSelectedRegistrys()"
                            v-bind="attrs"
                            v-on="on"
                            small
                        >
                            <v-icon small color="dark" class="mr-2"
                                >mdi-card-multiple</v-icon
                            >
                            Selecionar Registros
                        </v-btn>
                    </template>
                    <span>Múltiplos Registros</span>
                </v-tooltip>
            </div>
            <div class="button-toolbar mt-4 mr-5">
                <v-tooltip bottom dense size="30" v-if="this.selectedRegistrys">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="#E4E4E4"
                            elevation="0"
                            class="py-4"
                            @click="
                                setDialogManualLow({
                                    multiples: true,
                                    show: true,
                                    items: selected.map((item) => {
                                        return {
                                            id: item.id,
                                            new_total_vinculations:
                                                item.linked_to,
                                        }
                                    }),
                                })
                            "
                            v-bind="attrs"
                            v-on="on"
                            small
                        >
                            <v-icon small color="dark" class="mr-2"
                                >mdi-arrow-down-bold</v-icon
                            >
                            Baixa Manual
                        </v-btn>
                    </template>
                    <span>Baixa Manual</span>
                </v-tooltip>
            </div>
            <v-spacer></v-spacer>
            <div class="button-toolbar mr-1">
                <v-tooltip bottom dense size="30">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="#E4E4E4"
                            elevation="0"
                            class="py-4"
                            @click="
                                setShowFilter({ show: true, type: 'credit' })
                            "
                            v-bind="attrs"
                            v-on="on"
                            small
                        >
                            Filtrar
                            <v-icon small color="dark">mdi-filter</v-icon>
                        </v-btn>
                    </template>
                    <span>Filtro</span>
                </v-tooltip>
            </div>
        </v-toolbar>
        <v-data-table
            v-model="selected"
            item-key="id"
            :show-select="selectedRegistrys"
            :headers="headersCredit"
            :options.sync="paginationCredit"
            :server-items-length="totalItemsCredit"
            :items="itemsCredit"
            :item-class="getClassColor"
            :footer-props="{
                'items-per-page-options': [10, 20, 50, 100],
                'items-per-page-text': 'Itens por página',
                'show-first-last-page': true,
                'page-text': `Página ${paginationCredit.page} de ${
                    parseInt(totalItemsCredit / paginationCredit.itemsPerPage) + 1
                }`,
            }"
        >
            <template v-slot:[`item.date`]="{ item }">
                <span>{{ formatDate(item.date) }}</span>
            </template>
            <template v-slot:[`item.value`]="{ item }">
                <span>{{ formatMoney(item.value) }}</span>
            </template>
            <template v-slot:[`item.type_manager`]="{ item }">
                <span>{{ formatManagerType(item.type_manager) }}</span>
            </template>
            <template v-slot:[`item.type_transaction`]="{ item }">
                <span>{{ formatTypeTransaction(item.type_transaction) }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ attrs, on }">
                        <v-icon
                            small
                            color="black"
                            class="mr-2"
                            v-bind="attrs"
                            v-on="on"
                            @click="openDialog(item)"
                            >mdi-information</v-icon
                        >
                    </template>
                    <span>Informações</span>
                </v-tooltip>
            </template>
            <template v-slot:[`item.ligations`]="{ item }">
                <span>{{
                    formatLigations(item.linked_to, item.total_vinculations)
                }}</span>
            </template>
        </v-data-table>

        <v-dialog
            :value="dialogManualLow"
            @click:outside="setCloseDialogManualLow()"
            width="1280"
        >
            <PeriodsConciliationLowDialog />
        </v-dialog>
        <v-dialog
            :value="dialog"
            @click:outside="closeDialogVinculation"
            width="1280"
        >
            <PeriodConciliationVinculationsDialog />
        </v-dialog>
        <v-dialog
            :value="filterDialog"
            @click:outside="setShowFilter({ show: false, type: 'credit' })"
            width="600"
        >
            <PeriodConciliationTransactionsCreditFilter />
        </v-dialog>
    </div>
</template>


<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { formatDate, formatMoney } from '@util/format_util'
import { mapFields } from 'vuex-map-fields'

export default {
    name: 'PeriodConciliationTransactionsCreditGrid',
    computed: {
        ...mapState('period_conciliation_transactions', [
            'itemsCredit',
            'loading',
            'totalItemsCredit',
            'headersCredit',
            'getClassColor',
            'filterDialog',
        ]),
        ...mapState('periods_conciliation_low_dialog', [
            'closeAllDialogsManualLow',
        ]),
        ...mapState('period_conciliation_dialog_vinculation', [
            'closeAllDialogs',
        ]),
        ...mapFields('period_conciliation_transactions', [
            'paginationCredit',
            'selectedItem',
        ]),
    },
    components: {
        PeriodsConciliationLowDialog: () =>
            import(
                '../periods_conciliation_low_dialog/PeriodsConciliationLowDialog.vue'
            ),
        PeriodConciliationVinculationsDialog: () =>
            import(
                '../period_conciliation_dialog_vinculation/PeriodConciliationVinculationsDialog.vue'
            ),
        PeriodConciliationTransactionsCreditFilter: () =>
            import('./PeriodConciliationTransactionsCreditFilter.vue'),
    },
    created() {
        this.getItems({ type: 0 })
    },
    methods: {
        ...mapActions('period_conciliation_transactions', ['getItems']),
        closeDialogVinculation() {
            this.dialog = false
            this.closeDialog()
        },
        ...mapMutations('period_conciliation_transactions', [
            'view',
            'setShowFilter',
        ]),
        ...mapActions('period_conciliation_dialog_vinculation', [
            'getVinculations',
        ]),
        ...mapMutations('period_conciliation_dialog_vinculation', [
            'closeDialog',
            'openVinculations',
            'buildItems',
        ]),
        ...mapMutations('period_conciliation', ['showNotification']),
        ...mapActions('periods_conciliation_low_dialog', [
            'setCloseDialogManualLow',
        ]),
        ...mapMutations('periods_conciliation_low_dialog', [
            'showDialogManualLow', 'setTypeTransaction',
        ]),

        openDialog(item) {
            this.dialog = true
            item.tab_origin = 1
            this.setTypeTransaction(item.type)
            this.openVinculations(item)
            this.getVinculations()
            this.buildItems()
        },

        formatDate(date) {
            return formatDate(date)
        },

        formatMoney(value) {
            return formatMoney(value)
        },
        formatManagerType(type) {
            let types = ['Interno', 'Externo']
            if (types[type]) {
                return types[type]
            }
            return 'Sem Gestor'
        },

             formatTypeTransaction(type) {
            if (type == 0) {
                return 'Retirada'
            } else if (type == 1){
                return 'Envio'
            }
        },
        setSelectedRegistrys() {
            this.selectedRegistrys = !this.selectedRegistrys
            if (!this.selectedRegistrys) {
                this.selected = []
            }
        },
        setDialogManualLow(payload) {
            payload.tab_origin = 1
            this.dialogManualLow = true
            payload.type_transaction = 0
            this.showDialogManualLow(payload)
        },
        closeDialogManualLow() {
            this.dialogManualLow = false
            this.setCloseDialogManualLow()
        },

        formatLigations(linked_to, total_vinculations) {
            return `${linked_to} de ${total_vinculations}`
        },
    },
    data: () => ({
        selectedRegistrys: false,
        selected: [],
        dialogManualLow: false,
        dialog: false,
    }),
    watch: {
        paginationCredit: {
            handler() {
                this.getItems({ resetPage: false, type: 0 })
            },
        },
        closeAllDialogs: {
            handler() {
                this.dialog = false
                this.dialog
            },
        },
        closeAllDialogsManualLow: {
            handler() {
                this.dialogManualLow = false
            },
        },
    },
}
</script>



