import axios from "axios";
import { getField, updateField } from "vuex-map-fields";
import endPoints from "@config/endPoints";
import {
  defaultErrorNotification,
  defaultSuccessNotification,
} from "@constants";
import formatDate from '@util/formatDate.js'



const defaultFilter = {
  id: "",
  start: "",
  end: "",
  date: "",
  player_id: "",
  value: "",
  slot: "",
  status: "",
  origin: "",
  type: "",
  vinculations: "",
  selected_types_low: "",
}


export default {
  namespaced: true,
  state: {
    headers: [
      { text: 'Id da Ordem', value: 'id_order', sortable: false },
      { text: 'Origem', value: 'origin_description', sortable: false },
      { text: 'Data', value: 'created_at', sortable: false },
      { text: 'Id do Jogador', value: 'player_id', sortable: false },
      { text: 'Valor', value: 'value', sortable: true },
      { text: 'Slot', value: 'slot_name', sortable: false },
      { text: 'Tipo', value: 'type', sortable: false },
      { text: 'Status', value: 'status', sortable: false },
      { text: 'Ligações', value: 'ligations', sortable: false, align: 'center' },
      { text: 'Ações', value: 'actions', sortable: false },
    ],
    possible_status: [
      { id: 0, description: 'Aguardando' },
      { id: 1, description: 'Em Andamento' },
      { id: 2, description: 'Concluída' },
      { id: 3, description: 'Jogador sem Saldo' },
      { id: 4, description: 'Clube sem Saldo' },
      { id: 5, description: 'Id Inválido' },
      { id: 6, description: 'Bot inativo' },
      { id: 7, description: 'Erro no print' },
      { id: 8, description: 'Jogador não é agente' },
      { id: 9, description: 'Recusado - Outros' },
      { id: 10, description: 'Erro Confirmação' },
      { id: 11, description: 'Valor Inválido' },
      { id: 12, description: 'Ip inválido' },
      { id: 13, description: 'Ip não informado' },
      { id: 15, description: 'Ordem duplicada' },
      { id: 16, description: 'Timeout' },
  ],
    getClassColor(item) {
      let ligationsTotals = item.total_vinculations - item.linked_to
      if (item.type_low == 1) {
        return 'brown--text text--darken-1'
      } else {
        if (ligationsTotals == 0) {
          return 'light-green--text text--darken-3'
        } else if (ligationsTotals == 1) {
          return 'blue--text text--darken-4'
        } else if (ligationsTotals == 2 && item.total_vinculations == 3) {
          return 'orange--text text--darken-2'
        }
        else if (ligationsTotals == item.total_vinculations) {
          return 'red--text text--accent-4'
        }
      }

    },

    items: [],
    itemsRelated: [],
    selectedItem: {},
    mode: "",
    dialog: false,
    loading: false,
    totalItems: 0,
    totalItemsRelated: 0,
    filterDialog: false,
    filtered: false,
    filter: {
      id: "",
      start: "",
      end: "",
      date: "",
      player_id: "",
      value: "",
      status: "",
      slot: "",
      origin: "",
      type: "",
      vinculation: "",
      selected_types_low: "",
    },
    notification: {
      show: false,
      message: "",
      type: "",
    },
    overlay: false,
    pagination: {
      page: 1,
      itemsPerPage: 10,
    },
  },
  mutations: {
    showNotification: (state, payload) => (state.notification = payload),
    showLoading: (state, payload) => {
      state.loading = payload.show;
    },
    view: (state, payload) => {
      state.selectedItem = payload;
      state.dialog = true
      state.mode = "VIEW";
    },
    closeDialog: (state) => {
      state.dialog = false
    },
    setTotalItemsRelated: (state, payload) => (state.totalItemsRelated = payload.total),
    setItemsRelated: (state, payload) => {
      state.itemsRelated = payload.items
    },
    setTotalItems: (state, payload) => (state.totalItems = payload.total),
    setItems: (state, payload) => {
      state.items = payload.items
    },
    clearFilter: (state) => {
      state.filter = Object.assign({}, defaultFilter)
    },
    setShowFilter: (state, payload) => {
      state.filterDialog = payload;
    },
    setFiltered: (state, payload) => state.filtered = payload.filtered,


    updateField,
  },


  actions: {
    getItems: async ({ commit, state, rootGetters }) => {
      console.log("chama getItems")
      let period = rootGetters['period_conciliation/getPeriodSelected']
      if (period == 0 || period == null || period == undefined || period == "") {
        period = location.href.split('period_conciliation/')[1]
      }
      let filter = state.filter;
      let itemsPerPage = state.pagination.itemsPerPage
      if (itemsPerPage == -1) {
        itemsPerPage = 9999
      }
      let url = `${endPoints.conciliation}/period_conciliation_orders/${period}?page=${state.pagination.page}&itemsPerPage=${itemsPerPage}`;

      Object.keys(filter).forEach(function (field) {
        if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
          commit('setFiltered', { filtered: true })

          if (field == "end" || field == "start" || field == "date") {
            var e = new Date(filter[field])
            url += "&" + field + "=" + formatDate(e) + " " + e.toLocaleTimeString()
          }
          else if (field == "value") {
            var v = filter[field].replace(",", ".")
            url += "&" + field + "=" + v
          }

          else {
            url += "&" + field + "=" + filter[field]
          }
        }
      });

      if (state.pagination && state.pagination.sortBy && state.pagination.sortBy.length > 0) {

        url += "&sort_by=" + state.pagination.sortBy[0]
        url += "&sort_desc=" + state.pagination.sortDesc[0]
      }

      commit("showLoading", { show: true });

      try {
        let items = await axios.get(url);
        commit("setItems", { items: items.data.data });
        commit("setTotalItems", { total: items.data.total });
      } catch (error) {
        commit(
          "showNotification",
          defaultErrorNotification(error?.response?.data?.message)
        );
      }
      commit("showLoading", { show: false });
    },
    deleteItem: async ({ commit, dispatch, state }) => {
      commit("showLoading", { show: true });
      try {
        await axios.delete(`${endPoints.conciliation}/period_conciliation/${state.selectedItem.id}`);

        commit("showLoading", { show: false });
        commit("showDeleteDialog", { show: false });
        commit("setSelectedItem", {});
        commit(
          "showNotification",
          defaultSuccessNotification("Periodo de conciliação removido com sucesso")
        );

        return dispatch("getItems");
      } catch (error) {
        commit(
          "showNotification",
          defaultErrorNotification(error?.response?.data?.message)
        );
        commit("showLoading", { show: false });
      }
    },
  },
  getters: {
    getField,
  },
};
