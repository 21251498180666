<template>
    <div @drop.prevent="addDropFile" @dragover.prevent>
        <v-card>
            <v-card-title>
                <span class="headline"> {{ getTitle() }} </span>
            </v-card-title>

            <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12 sm6 md6>
                                <v-text-field
                                    required
                                    :rules="[rules.required, rules.validDomain]"
                                    label="Domínio"
                                    v-model="nameDomain"
                                    :readonly="this.mode !== 'ADD'"
                                    data-vv-name="Domínio"
                                    :disabled="this.mode === 'VIEW'"
                                    outlined
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-select
                                    required
                                    :rules="[rules.required]"
                                    :clearable="true"
                                    label="Aplicativo"
                                    :items="possible_apps"
                                    v-model="app"
                                    item-text="app"
                                    item-value="app"
                                    :disabled="this.mode === 'VIEW'"
                                    outlined
                                ></v-select>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-text-field
                                    required
                                    :rules="[rules.required]"
                                    label="Agente"
                                    v-model="agent"
                                    data-vv-name="Agente"
                                    :disabled="this.mode === 'VIEW'"
                                    outlined
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-text-field
                                    required
                                    :rules="[rules.required]"
                                    label="Clube"
                                    v-model="club"
                                    data-vv-name="Clube"
                                    :disabled="this.mode === 'VIEW'"
                                    outlined
                                ></v-text-field>
                            </v-flex>

                            <!-- <v-flex xs7 sm7 md7>
                                <v-color-picker
                                    hide-inputs
                                    v-model="colorPrimary"
                                    label="Cor Primária"
                                    :disabled="this.mode === 'VIEW'"
                                    required
                                    :rules="[rules.required]"
                                ></v-color-picker>
                            </v-flex>
                            <v-flex xs5 sm5 md5>
                                <v-text-field
                                    v-model="colorPrimary"
                                    label="Código da Cor Primária"
                                    :disabled="mode === 'VIEW'"
                                    outlined
                                    required
                                    :rules="[rules.required]"
                                ></v-text-field>
                            </v-flex> -->
                            <v-flex xs6 sm6 md6>
                                <v-checkbox
                                    v-model="approve"
                                    label="Aprova Automaticamente"
                                    :disabled="mode === 'VIEW'"
                                ></v-checkbox>
                            </v-flex>
                            <v-flex xs6 sm6 md6>
                                <v-checkbox
                                    v-model="createFichasAccount"
                                    label="Cria Conta no Fichas"
                                    :disabled="mode === 'VIEW'"
                                ></v-checkbox>
                            </v-flex>
                            <v-flex xs6 sm6 md6>
                                <v-checkbox
                                    v-model="emailConfirmationApp"
                                    label="Confirma E-mail no App"
                                    :disabled="mode === 'VIEW'"
                                ></v-checkbox>
                            </v-flex>
                            <v-flex xs6 sm6 md6>
                                <v-checkbox
                                    v-model="emailConfirmationFichas"
                                    label="Confirma E-mail no Fichas"
                                    :disabled="mode === 'VIEW'"
                                ></v-checkbox>
                            </v-flex>

                            <v-flex
                                xs12
                                sm12
                                md12
                                class="d-flex justify-center align-center"
                            >
                                <v-file-input
                                    v-model="selectedBackgroundImg"
                                    label="Imagem de Fundo "
                                    accept="image/png"
                                    v-on:change="
                                        addFileBackground(selectedBackgroundImg)
                                    "
                                    :disabled="this.mode === 'VIEW'"
                                    outlined
                                    :required="this.mode === 'ADD'"
                                    :rules="
                                        this.mode === 'ADD'
                                            ? [rules.required]
                                            : []
                                    "
                                ></v-file-input>
                            </v-flex>
                            <v-flex
                                xs12
                                sm12
                                md12
                                class="d-flex justify-center align-center"
                            >
                                <img
                                    v-if="imageBackgroundUrl"
                                    :src="imageBackgroundUrl"
                                    style="max-width: 50%; width: 40%"
                                />
                                <img
                                    v-else
                                    :src="backgroundImgUrl"
                                    style="max-width: 50%; width: 40%"
                                />
                            </v-flex>
                            <v-flex
                                xs12
                                sm12
                                md12
                                class="d-flex justify-center align-center"
                            >
                                <v-file-input
                                    v-model="selectedLogo"
                                    label="Logo"
                                    accept="image/png"
                                    v-on:change="addFile(selectedLogo)"
                                    :disabled="this.mode === 'VIEW'"
                                    outlined
                                    :required="this.mode === 'ADD'"
                                    :rules="
                                        this.mode === 'ADD'
                                            ? [rules.required]
                                            : []
                                    "
                                ></v-file-input>
                            </v-flex>
                            <v-flex
                                xs12
                                sm12
                                md12
                                class="d-flex justify-center align-center"
                            >
                                <img
                                    v-if="imageUrl"
                                    :src="imageUrl"
                                    style="max-width: 50%; width: 40%"
                                />
                                <img
                                    v-else
                                    :src="logoUrl"
                                    style="max-width: 50%; width: 40%"
                                />
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-col>
                    <v-btn text @click="setShowDialog(false)">{{
                        mode == 'VIEW' ? 'Voltar' : 'Cancelar'
                    }}</v-btn>
                </v-col>
                <v-col class="text-right" v-if="mode != 'VIEW'">
                    <v-btn text type="submit" right @click="saveConfig"
                        >Salvar</v-btn
                    >
                </v-col>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
// import b64 from '../../util/Base64'

export default {
    name: 'ConfigClubsForm',
    created() {
        this.$validator.extend('required', {
            getMessage: (field, [label]) => label + ' deve ser informado',
            validate: (_, [, field]) => {
                if (this[field] || this[field] === 0) {
                    return true
                } else {
                    return false
                }
            },
        })
        this.$validator.extend('max_length', {
            getMessage: (_, [length]) => 'No máximo ' + length + ' caracteres.',
            validate: (val, [length]) => {
                if (val.length > length) {
                    return false
                } else {
                    return true
                }
            },
        })
    },
    methods: {
        ...mapActions('custom_clubs', ['save']),
        ...mapMutations('custom_clubs', ['setShowDialog']),
        getTitle() {
            return this.mode == 'ADD'
                ? 'Adicionar Configuração'
                : this.mode == 'EDIT'
                ? 'Editar Configuração'
                : this.mode == 'VIEW'
                ? 'Visualizar Configuração'
                : ''
        },
        addDropFile(e) {
            if (this.mode == 'ADD' || this.mode == 'EDIT') {
                if (e.dataTransfer) {
                    if (e.dataTransfer.files[0]) {
                        this.attest_file = e.dataTransfer.files[0]
                    }
                }
            }
        },
        addFile(image) {
            if (this.logoUrl) {
                URL.revokeObjectURL(this.logoUrl)
            }
            if (image) {
                this.imageUrl = URL.createObjectURL(image)
            }
        },
        addFileBackground(image) {
            if (this.backgroundImgUrl) {
                URL.revokeObjectURL(this.backgroundImgUrl)
            }
            if (image) {
                this.imageBackgroundUrl = URL.createObjectURL(image)
            }
        },
        saveConfig() {
            let valid = this.$refs.form.validate()
            if (valid) {
                this.save({
                    imageLogo: this.selectedLogo,
                    imageBackground: this.selectedBackgroundImg,
                })
            }
        },
    },
    computed: {
        ...mapState('custom_clubs', ['mode', 'selectedItem', 'dialog']),
        ...mapFields('custom_clubs', [
            'selectedItem.nameDomain',
            'selectedItem.app',
            'selectedItem.agent',
            'selectedItem.approve',
            'selectedItem.createFichasAccount',
            'selectedItem.emailConfirmationApp',
            'selectedItem.emailConfirmationFichas',
            'selectedItem.club',
            'selectedItem.logoUrl',
            'selectedItem.style.colorPrimary',
            'selectedItem.backgroundImgUrl',
        ]),
    },
    data: () => ({
        valid: false,
        timeProps: {
            format: '24hr',
        },
        searchInput: '',
        selectedLogo: null,
        selectedBackgroundImg: null,

        imageUrl: null,
        imageBackgroundUrl: null,
        possible_apps: [{ app: 'suprema' }, { app: 'cacheta' }],
        rules: {
            required: (value) => !!value || 'Campo Obrigatório',
            validDomain: (value) => {
                const subdomainPattern = /^[a-zA-Z0-9.-]+$/
                return subdomainPattern.test(value) || 'Domínio inválido.'
            },
        },
    }),
    watch: {
        searchInput: {
            handler() {
                if (this.searchInput) {
                    var payload = {
                        search: this.searchInput,
                    }
                    this.getSearchCollaborators(payload)
                }
            },
        },
    },
}
</script>