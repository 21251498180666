<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2000"
      v-model="showNotification"
      >{{ notificationMessage }}</v-snackbar
    >
    <v-toolbar flat class="elevation-5">
      <v-icon color="black" style="padding-right:6px">mdi-account-cash </v-icon>
      <v-toolbar-title><strong> Saldo de Jogadores</strong></v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="button-toolbar">
        <!-- <download-excel
          :before-generate="generateSheet"
          :before-finish="finishSheet"
          :name="getSheetName()"
          :fetch="getItemsNoPage"
          :export-fields="sheet_fields"
          class="excel"
          worksheet="Saldo"
          type="xls"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="black"
                dark
                small
                class="ma-2"
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </template>
            <span>Gerar planilha</span>
          </v-tooltip>
        </download-excel> -->

        <v-tooltip bottom>
          <template v-slot:activator="{on, attrs}">
            <v-btn color="black" dark class="ma-2" @click="downloadXlsx({ show: true })" v-bind="attrs" v-on="on" small>
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </template>
          <span>Gerar Planilha</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="black"
              dark
              class="ma-2"
              @click="showDialogTotals({ item })"
              small
            >
              <v-icon>mdi-poker-chip</v-icon>
            </v-btn>
          </template>
          <span>Totais</span>
        </v-tooltip>

        <v-btn
          color="black"
          dark
          class="ma-2"
          @click="showFilter({ show: true })"
          small
        >
          <v-icon>mdi-filter</v-icon>
        </v-btn>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="black"
              dark
              class="ma-2"
              @click="getItems"
              small
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Recarregar</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="black"
              dark
              class="ma-2"
              @click="showDialogSpreadsheet({ item })"
              small
            >
              <v-icon>mdi-file-excel</v-icon>
            </v-btn>
          </template>
          <span>Planilhas</span>
        </v-tooltip>
      </div>
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :loading="loading"
      :items="items"
      item-key="id"
      :options.sync="pagination"
      :server-items-length="totalItems"
      group-by="id_agent"
      no-data-text="Nenhum registro encontrado"
      class="elevation-10 table-margin"
    >
      <template v-slot:[`group.header`]="{ items, isOpen, toggle }">
        <th colspan="1">
          <v-icon @click="toggle"
            >{{ isOpen ? "mdi-chevron-down" : "mdi-chevron-up" }}
          </v-icon>
          Id Agente:
          {{ items[0].id_agent }}
          ({{ items[0].nick_master_agent != null ? items[0].nick_master_agent : items[0].nick_agent }})
        </th>
        <th colspan="9">
          Nick Agente:
          {{ items[0].nick_agent }}
        </th>
      </template>
      <template v-slot:item="{ item }">
        <tr :class="getClassColor(item)">
          <td>
            {{ item.club_name }}
          </td>
          <td>{{ item.nick_agent }}</td>
          <td>{{ item.sub_agent_id }}</td>
          <td>{{ item.nick_subagent }}</td>
          <td>
            <span>{{ getTypeDealAgent(item.type_deal_agent) }}</span>
          </td>
          <td>
            <span>{{ formatToMoney(item.player_chips) }}</span>
          </td>
          <td>
            <span>{{ formatToMoney(item.agent_cashier) }}</span>
          </td>
          <td>{{ item.id_spreadsheet }}</td>
        </tr>
      </template>

      <template v-slot:[`item.date`]="{ item }">
        <span>{{ getDate(item.date) }}</span>
      </template>
      <template v-slot:[`item.type_deal_agent`]="{ item }">
        <span>{{ getTypeDealAgent(item.type_deal_agent) }}</span>
      </template>
    </v-data-table>
    <v-dialog :value="dialogPeriod" @click:outside="closeDialog()" width="800">
      <!-- <players-balance-form></players-balance-form> -->
    </v-dialog>

    <v-dialog
      :value="dialogTotals"
      @click:outside="setDialogTotals({ show: false })"
      width="1280"
    >
      <v-card>
        <v-card-title>Valores Totais de Fichas No Período</v-card-title>
        <v-spacer></v-spacer>

        <v-data-table
          :headers="headersValues"
          :items="chip_totals"
          :item-class="getClassColor"
          hide-default-footer
          no-data-text="Nenhum registro encontrado"
        >
          <template v-slot:[`item.type_deal`]="{ item }">
            <span>{{ getTypeDealAgent(item.type_deal) }}</span>
          </template>
          <template v-slot:[`item.player_chips`]="{ item }">
            <span>{{ formatToMoney(item.player_chips) }}</span>
          </template>
          <template v-slot:[`item.subagent_cashier`]="{ item }">
            <span>{{ formatToMoney(item.subagent_cashier) }}</span>
          </template>
          <template v-slot:[`item.agent_cashier`]="{ item }">
            <span>{{ formatToMoney(item.agent_cashier) }}</span>
          </template>
        </v-data-table>

        <!-- <v-card-subtitle><b>Total Fichas (Normal)</b></v-card-subtitle>
        <v-card-subtitle>Valor: {{ this.totalNormalChips ? this.totalNormalChips.Float64.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 0 }}</v-card-subtitle>

        <v-card-subtitle><b>Total Fichas (GP)</b></v-card-subtitle>
        <v-card-subtitle>Valor: {{ this.totalGPChips ? this.totalGPChips.Float64.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 0 }}</v-card-subtitle> -->

        <v-card-actions>
          <v-col>
            <v-btn text small @click="setDialogTotals({ show: false })"
              >Voltar</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      :value="dialogSpreadsheet"
      @click:outside="setDialogSpreadsheet({ show: false })"
      @keydown.enter="deleteItem"
      width="1280"
    >
      <v-card>
        <v-expansion-panels :value="0">
          <v-expansion-panel :value="true">
            <v-expansion-panel-header
              >Planilhas Utilizadas</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <v-card>
                <v-data-table
                  :headers="headers_spreadsheet"
                  :items="spreadsheet_balance"
                  :options.sync="paginationDialog"
                  :server-items-length="totalItemsDialog"
                >
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-on="on"
                          v-bind="attrs"
                          small
                          class="mr-2"
                          @click="showDeleteDialog(item)"
                          >mdi-delete</v-icon
                        >
                      </template>
                      <span>Deletar</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>Nova Planilha</v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-card
                @drop.prevent="addDropFile"
                @dragover.prevent
                @paste="onPaste"
              >
                <v-data-table
                  :headers="headers_config_spreadsheet"
                  :items="spreadsheet_headers_config_import"
                  disable-pagination
                  hide-default-footer
                >
                  <template v-slot:[`item.column_spreadsheet`]="props">
                    <v-text-field
                      required
                      reverse
                      v-model="props.item.column_spreadsheet"
                    ></v-text-field>
                  </template>
                  <template v-slot:[`item.type_field`]="{ item }">
                    <span>{{ getTypeHeaderSpreadSheet(item.type_field) }}</span>
                  </template>
                </v-data-table>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 sm12 md6 xl4>
                      <v-file-input
                        accept="*"
                        label="Adicionar Planilha"
                        v-model="selected_spreadsheet"
                      ></v-file-input>
                    </v-flex>
                    <v-flex xs12 sm12 md4 xl4>
                      <v-select
                        label="Clube"
                        :clearable="true"
                        :items="instances"
                        v-model="club_import"
                        :readonly="this.mode == 'VIEW'"
                        item-text="name"
                        item-value="id_instance"
                        clear-icon="mdi-close"
                      >
                      </v-select>
                    </v-flex>
                    <v-flex xs12 sm12 md6 xl4>
                      <v-text-field
                        required
                        class="text-right"
                        label="Linha Inicial *"
                        v-model="start_line"
                        text-align="right"
                        type="number"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
                <v-card-actions>
                  <v-col>
                    <v-btn text @click="setDialogSpreadsheet({ show: false })"
                      >Voltar</v-btn
                    >
                  </v-col>
                  <v-col class="text-center">
                    <v-btn readonly v-if="overlay" color="error"
                      >Carregando ...</v-btn
                    >
                  </v-col>
                  <v-col class="text-right">
                    <v-btn
                      v-if="selected_spreadsheet && nameSpreadsheet"
                      @click="importSpreadsheet"
                      text
                      type="submit"
                      right
                      >Confirmar</v-btn
                    >
                  </v-col>
                </v-card-actions>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-dialog>

    <v-dialog
      :value="dialogFilter"
      width="600"
      @click:outside="showFilter({ show: false })"
      @keydown.enter="filter"
    >
      <players-balance-filter></players-balance-filter>
    </v-dialog>

    <v-dialog
      :value="dialogDelete"
      @click:outside="closeDeleteDialog"
      @keydown.enter="deleteItem"
      width="300"
    >
      <v-card>
        <v-card-title class="headline">Confirmar a exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn text @click="closeDeleteDialog()">Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn text type="submit" right @click="deleteItem"
              >Confirmar</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
// import JsonExcel from "vue-json-excel";
import PlayersBalanceFilter from "./PlayersBalanceFilter.vue";
import { utils, writeFile } from "xlsx";

export default {
  name: "PlayersBalance",
  computed: {
    ...mapState("instances", ["instances"]),
    ...mapState("players_balance", [
      "dialogDelete",
      "item",
      "instances",
      "loading",
      "mode",
      "notificationType",
      "notificationMessage",
      "rowsPerPage",
      "totalItems",
      "totalItemsDialog",
      "totalValue",
      "totalPayedChips",
      "totalNormalChips",
      "totalGPChips",
      "items",
      "chip_totals",
      "spreadsheet_headers_config_import",
      "spreadsheet_balance",
    ]),
    ...mapFields("players_balance", [
      "pagination",
      "paginationDialog",
      "dialogFilter",
      "dialogSpreadsheet",
      "club_import",
      "dialogTotals",
      "dialogPeriod",
      "overlay",
      "start_line",
      "selected_spreadsheet",
      "showNotification",
      // ...
    ]),
    nameSpreadsheet() {
      return this.selected_spreadsheet.name;
    },
  },
  created() {
    this.getItems();
    this.getInstances();
  },
  components: {
    // "download-excel": JsonExcel,
    "players-balance-filter": PlayersBalanceFilter,
  },
  methods: {
    addDropFile(e) {
      if (e.dataTransfer) {
        if (e.dataTransfer.files[0]) {
          this.selected_spreadsheet = e.dataTransfer.files[0];
        }
      }
    },
    ...mapActions("instances", ["getInstances"]),
    ...mapMutations("players_balance", [
      "edit",
      "add",
      "setDialogSpreadsheet",
      "setDialogTotals",
      "showFilter",
    ]),
    ...mapActions("players_balance", [
      "closeDeleteDialog",
      "deleteItem",
      "getItems",
      "getSpreadsheet",
      "getInstances",
      "getItemsNoPage",
      "importSpreadsheet",
      "showDeleteDialog",
      "showDialogSpreadsheet",
      "showDialogTotals",
    ]),
    filter() {
      this.getItems({ resetPage: true });
      this.showFilter({ show: false });
    },
    getClassColor(item) {
      if (item.agent_id == item.id_player) {
        return "row-blue";
      } else if (item.sub_agent_id == item.id_player) {
        return "row-green";
      }
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      }
      return false;
    },
    getDate(date) {
      var d = new Date(date);
      return d.toLocaleDateString() + " " + d.toLocaleTimeString();
    },
    formatToMoney(value) {
      return value.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
    },
    getTypeHeaderSpreadSheet(type) {
      if (type == "int") {
        return "Inteiro";
      } else if (type == "float") {
        return "Decimal";
      } else {
        return "Texto";
      }
    },
    finishSheet() {
      this.overlay = false;
    },
    generateSheet() {
      this.overlay = true;
    },
    getSheetName() {
      var date = new Date();
      var name =
        "saldo_jogadores" +
        date.toLocaleDateString() +
        " " +
        date.toLocaleTimeString();
      return name + ".xls";
    },
    async downloadXlsx() {
      this.generateSheet()
      try {
        let itemsNoPage = await this.getItemsNoPage()
        let possible_types = ["Ganho/Perda", "Giro", "Normal","Fichas Enviadas/Retiradas", "Sem Acerto", "Sem Acerto/GP"];
        if(itemsNoPage) {
          const wb = utils.book_new()
            let items = itemsNoPage.map(row => ({
              "Clube":  row.club_name,
              "ID Agente":  row.agent_id,
              "Nick Agente":  row.nick_agent,
              "ID Subagente":  row.sub_agent_id,
              "Nick Subagente":  row.nick_subagent,
              "Tipo de Acerto":  possible_types[row.type_deal_agent],
              "Saldo":  row.player_chips,
              "Saldo Maleta":  row.agent_cashier,
              "Saldo Downline":  row.downline,
              "ID Planilha":  row.id_spreadsheet,
            }))
          const ws = utils.json_to_sheet(items)
          utils.book_append_sheet(wb, ws, "Despesas")
          await writeFile(wb, this.getSheetName())
        }
      } catch (error) {
        this.finishSheet()
      } finally {
        this.finishSheet()
      }
    },
    onPaste(e) {
      if (e.clipboardData && e.clipboardData.items.length != 0) {
        var file = e.clipboardData.items[0];
        var file_data = file.getAsFile();
        this.selected_spreadsheet = file_data;
      }
    },
  },
  data: () => ({
    headers: [
      { text: "Clube", value: "club_name", sortable: false },
      { text: "ID Agente", value: "id_agent", sortable: false },
      { text: "Nick Agente", value: "nick_agent", sortable: false },
      { text: "ID Subagente", value: "sub_agent_id", sortable: false },
      { text: "Nick Subagente", value: "nick_subagent", sortable: false },
      { text: "Tipo de Acerto", value: "type_deal_agent", sortable: false },
      { text: "Saldo", value: "player_chips", sortable: true },
      { text: "Saldo Maleta", value: "agent_cashier", sortable: true },
      // { text: "Saldo Downline", value: "downline", sortable: false },
      // { text: "Pagamento Pendente", value: "pending_payment", sortable: false },
      // { text: "Semana Atual", value: "current_week", sortable: false },
      // { text: "Fichas Pagas", value: "payed_chips", sortable: false },
      { text: "ID Planilha", value: "id_spreadsheet", sortable: false },
    ],
    headersValues: [
      { text: "Tipo de Fechamento", value: "type_deal", sortable: false },
      { text: "Player Chips", value: "player_chips", sortable: false },
      { text: "Agent Cashier", value: "agent_cashier", sortable: false },
      { text: "Sub Agent Cashier", value: "subagent_cashier", sortable: false },
    ],
    headers_config_spreadsheet: [
      { text: "Descrição", value: "description_field", sortable: false },
      {
        text: "Coluna",
        value: "column_spreadsheet",
        align: "right",
        sortable: true,
      },
      { text: "Tipo", value: "type_field", align: "right", sortable: false },
    ],
    headers_spreadsheet: [
      { text: "Id", value: "id", sortable: false },
      { text: "Nome", value: "name", sortable: false },
      { text: "Data de Importação", value: "date", sortable: false },
      { text: "Ações", value: "actions", sortable: false },
    ],
    getTypeDealAgent(type_deal) {
      switch (type_deal) {
        case null:
          return "------";
        case 0:
          return "Ganho/Perda";
        case 1:
          return "Giro";
        case 2:
          return "Normal";
        case 3:
          return "Fichas Enviadas/Retiradas";
        case 4:
          return "Sem Acerto";
        case 5:
          return "Sem Acerto/GP";
      }
    },
    sheet_fields: {
      Clube: {
        field: "club_name",
      },
      "ID Agente": {
        field: "agent_id",
      },
      "Nick Agente": {
        field: "nick_agent",
      },
      "ID Subagente": {
        field: "sub_agent_id",
      },
      "Nick Subagente": {
        field: "nick_subagent",
      },
      "Tipo de Acerto": {
        field: "type_deal_agent",
        callback: (value) => {
          let types = [
            "Ganho/Perda",
            "Giro",
            "Normal",
            "Fichas Enviadas/Retiradas",
            "Sem Acerto",
            "Sem Acerto/GP",
          ];
          if (types[value]) {
            return types[value];
          }
          return "---";
        },
      },
      Saldo: {
        field: "player_chips",
      },
      "Saldo Maleta": {
        field: "agent_cashier",
      },
      "Saldo Downline": {
        field: "downline",
      },
      "ID Planilha": {
        field: "id_spreadsheet",
      },
    },
    valid: false,
  }),
  watch: {
    pagination: {
      handler() {
        this.getItems({ resetPage: false });
      },
    },
    paginationDialog: {
      handler() {
        this.getSpreadsheet({ resetPage: false });
      },
    },
  },
};
</script>