import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../../config/endPoints';

const formatDate = (d) => {
    var day, month, year;
    month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

const defaultRakeback = {
    date_picker: '',
    value: 0,
    id_agent: 0,
    id_instance: 0
};

export default {
    namespaced: true,
    state: {
        agentByAgGroupId: 0,
        dialog: false,
        dialogDelete: false,
        dialogFilter: false,
        dialogReceipt: false,
        editedRake: {},
        filter: {
            start: '',
            end: '',
            value: '',
            id_agent: ''
        },
        instances: [],
        rakes: [],
        rakeToDelete: {},
        loading: false,
        mode: 'LIST',
        overlay: false,
        page: 1,
        pagination: {
            page: 1,
            rakesPerPage: 10
        },
        successNotification: false,
        notificationMessage: '',
        notificationType: "success",
        receipt_show: '',
        selectedContact: {},
        showNotification: false,
        totalRakes: 0,
        rowsPerPage: 10,
        menus: [],
        agents: [],
        camp: 0
    },
    mutations: {
        add: (state, payload) => {
            state.mode = "ADD"
            state.editedRake = Object.assign({}, defaultRakeback)
            state.screen = payload.screen
            if (state.screen != "messages") {
                state.dialog = true
            }
        },
        edit: (state, payload) => {
            state.editedRake = Object.assign({}, payload.item)
            state.dialog = true
            state.mode = "EDIT"
        },
        closeDialog: (state) => {
            state.editedRake = {}
            state.editedIndex = -1
            state.errors = [];
            state.dialog = false
            state.mode = "LIST"
        },
        loading: (state, payload) => state.loading = payload.show,
        setMode: (state, mode) => state.mode = mode,
        setRakes: (state, rakes) => {
            state.rakes = rakes
        },
        setAgents: (state, payload) => {
            state.agents = payload.agents
        },
        setRakeToDelete: (state, rake) => state.rakeToDelete = rake,
        setTotalRakes: (state, total) => state.totalRakes = total,
        setRowsPerPage: (state, rowsPerPage) => state.rowsPerPage = rowsPerPage,
        showDialog: (state, show) => state.dialog = show,
        setPage: (state, payload) => {
            state.pagination.page = payload.page;
            state.pagination.rakesPerPage = payload.rakesPerPage
        },
        setScreen: (state, payload) => state.screen = payload.screen,
        setUsersName: (state, payload) => {
            let users_name = []
            payload.users.forEach(user => {
                users_name[user.id] = user.name
            });
        },
        setMenus: (state, payload) => {
            state.menus = payload.menus
        },
        setDialogDelete: (state, show) => state.dialogDelete = show,
        setDialogReceipt: (state, payload) => state.dialogReceipt = payload.show,
        setInstances: (state, payload) => state.instances = payload.instances,
        showFilter: (state, payload) => state.dialogFilter = payload.show,
        showErrorNotification: (state, show) => state.errorNotification = show,
        showSuccessNotification: (state, show) => state.successNotification = show,
        showLoading: (state, show) => state.loading = show,
        showNotification: (state, payload) => {
            state.showNotification = payload.show,
                state.notificationMessage = payload.message,
                state.notificationType = payload.type
        },
        showOverlay: (state, payload) => {
            state.overlay = payload.show
        },
        setReceipt: (state, payload) => state.receipt_show = payload.receipt,
        viewRake(state, rake) {
            state.editedRake = rake
            state.editedIndex = state.rakes.indexOf(rake)
            state.dialog = true
            state.mode = "VIEW"
        },
        updateField
    },
    getters: {
        getField,
    },
    actions: {
        closeDialog: (({ commit, state }) => {
            state.editedRake = {}
            if (state.screen != "messages") {
                commit('message/setSelectedMessages', { messages: [] }, { root: true })
                commit('showDialog', false)
            } else {
                commit('message/showDialogFail', { show: false }, { root: true })
            }
            setTimeout(() => {
                commit('closeDialog')
                commit('showErrorNotification', false)
                commit('showSuccessNotification', false)
            }, 300);
        }),
        closeDeleteDialog: ({ commit }) => {
            commit('setDialogDelete', false)
            commit('setRakeToDelete', {})
        },
        deleteRake: ({ dispatch, commit, state }) => {
            commit('loading', true)
            axios.delete(endPoints.rakeback + "/deleteRake/" + state.rakeToDelete.id, state.rakeToDelete).then(() => {
                dispatch('closeDeleteDialog')
                commit('loading', false)
                let notification = {
                    show: true,
                    message: "Adiantamento excluido com sucesso",
                    type: "success"
                }
                commit('showNotification', notification)
                return dispatch('getRakes', { resetPage: false });
            });
        },
        getAgents({ commit }, payload) {
            if (payload.search) {
                axios.get(endPoints.rakeback + "/agent_by_name/" + payload.search).then(response => {
                    var payloadAgents = {
                        agents: response.data
                    }
                    if (response.data == null) {
                        payloadAgents.agents = []
                    }
                    commit('setAgents', payloadAgents)
                });
            }
        },
        getRakes: ({ commit, state }, payload) => {
            commit('loading', true)
            let url = endPoints.rakeback;
            let filter = state.filter;

            if (payload.resetPage === true) {
                url += '?page=' + 1 + '&' + 'rakesPerPage=' + 10;
                commit('setPage', { page: 1, rakesPerPage: 10 })
            } else {
                url += '?page=' + state.pagination.page + '&' + 'rakesPerPage=' + state.pagination.rakesPerPage;
            }


            if (payload.noFilter != 'yes') {
                Object.keys(filter).forEach(function(field) {
                    if (filter[field] !== "" && filter[field] !== undefined) {
                        commit('setFiltered', true)
                        if (field == "end" || field == "start") {
                            var e = new Date(filter[field])
                            url += "&" + field + "=" + formatDate(e) + " " + e.toLocaleTimeString()
                        } else {
                            url += "&" + field + "=" + filter[field]
                        }
                    }
                });
            }

            axios.get(url).then(rakes => {
                commit('setTotalRakes', rakes.data.total)
                commit('loading', false)
                commit('setRakes', rakes.data.data)
            });
        },
        getRakesWeek: ({ commit, state }, payload) => {
            commit('loading', true)
            let url = endPoints.rakeback;
            
            //
            let d = new Date();
            let weekDay = d.getDay();
            let weekInitial = d.setDate(d.getDate()-(weekDay));
            let filter = state.filter;
            filter.start = weekInitial;

            // if (payload.chat == 1) {
            //     getAgentByAgGroupId({ agGroupId: payload.agGroupId })
            //     filter.id_agent = state.agentByAgGroupId
            //     payload.chat = 0
            // }

            if (payload.resetPage === true) {
                url += '?page=' + 1 + '&' + 'rakesPerPage=' + 10;
                commit('setPage', { page: 1, rakesPerPage: 10 })
            } else {
                url += '?page=' + state.pagination.page + '&' + 'rakesPerPage=' + state.pagination.rakesPerPage;
            }


            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined) {
                    commit('setFiltered', true)
                    if (field == "end" || field == "start") {
                        var e = new Date(filter[field])
                        url += "&" + field + "=" + formatDate(e) + " " + e.toLocaleTimeString()
                    } else {
                        url += "&" + field + "=" + filter[field]
                    }
                }
            });

            axios.get(url).then(rakes => {
                commit('setTotalRakes', rakes.data.total)
                commit('loading', false)
                commit('setRakes', rakes.data.data)
            });
        },
        getRakesPass: ({ commit, state }) => {
            commit('loading', true)
            let url = endPoints.rakeback;
            
            let d = new Date();
            let weekDay = d.getDay();
            let weekInitial = d.setDate(d.getDate()-(weekDay - 1));
            let filter = state.filter;
            filter.start = weekInitial;
            filter.id_agent = state.editedRake.id_agent;

            url += '?page=' + 1 + '&' + 'rakesPerPage=' + 20;
            commit('setPage', { page: 1, rakesPerPage: 20 })

            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined) {
                    commit('setFiltered', true)
                    if (field == "end" || field == "start") {
                        var e = new Date(filter[field])
                        url += "&" + field + "=" + formatDate(e) + " " + e.toLocaleTimeString()
                    } else {
                        url += "&" + field + "=" + filter[field]
                    }
                }
            });

            axios.get(url).then(rakes => {
                commit('setTotalRakes', rakes.data.total)
                commit('loading', false)
                commit('setRakes', rakes.data.data)
            });
        },
        getRakesNoPage: ({ state }) => {
            let url = endPoints.rakeback;
            let filter = state.filter;
            url += '?page=' + '1' + '&' + 'rakesPerPage=' + '99999';

            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined) {
                    url += "&" + field + "=" + filter[field]
                }
            });
            return axios.get(url).then(rakes => {
                return rakes.data.data
            });
        },
        showDeleteDialog: ({ commit }, rake) => {
            commit('setDialogDelete', true)
            commit('setRakeToDelete', rake)
        },
        save: async({ dispatch, commit, state }) => {
            var url = endPoints.rakeback;
            let func = '';
            let data = state.editedRake
            data.value = parseFloat(data.value)

            if (state.mode == 'ADD') {
                func = axios.post;
                url = endPoints.rakeback;
            } else {
                func = axios.put;
                url += '/updateRake/' + data.id;
            }

            func(url, data).then(
                () => {
                    dispatch('getRakes', { resetPage: true })
                    let notification = {
                        show: true,
                        message: "Rakeback salvo com sucesso",
                        type: "success"
                    }
                    commit('loading', { show: false })
                    commit('showOverlay', { show: false })
                    if (state.screen != 'messages') {
                        commit('showNotification', notification)
                        commit('closeDialog')
                        commit('showOverlay', { show: false })
                    } else {
                        commit('closeDialog')
                        commit('message/showDialogFail', { show: false }, { root: true })
                        commit('message/showNotification', notification, { root: true })                        
                    }
                },
                error => {
                    let notification = {
                        show: true,
                        message: error.response.data.message,
                        type: "error"
                    }
                    commit('showNotification', notification)
                    commit('showOverlay', { show: false })
                }
            );
        }
    }
}