import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../../config/endPoints';

const defaultItem = {
    name: "",
    length: null,
    width: null,
    height: null,
    weight: null,
    type: 0,
    value: 0,
};

const defaultFilter = {
    name: "",
    type: ""
}

export default {
    namespaced: true,
    state: {
        dialog: false,
        dialogFilter: false,
        dialogDelete: false,
        editedItem: {},
        errors: [],
        items: [],
        itemToDelete: {},
        loading: false,
        mode: 'LIST',
        notificationType: '',
        notificationMessage: '',
        page: 1,
        filter: {
            name: "",
            type: ""
        },
        filtered: false,
        overlay: false,
        pagination: {
            page: 1,
            itemsPerPage: 10
        },
        showNotification: false,
        totalItems: 0,
        rowsPerPage: 10
    },
    mutations: {
        add: (state) => {
            state.mode = "ADD"
            state.editedItem = Object.assign({}, defaultItem)
            state.dialog = true
        },
        edit: (state, payload) => {
            state.editedItem = Object.assign({}, payload.item)
            state.editedIndex = state.items.indexOf(payload.item)
            state.dialog = true
            state.mode = "EDIT"
        },
        closeDialog: (state) => {
            state.dialog = false
            state.editedItem = Object.assign({}, defaultItem)
            state.editedIndex = -1
            state.errors = [];
        },
        loading: (state, loading) => state.loading = loading,
        setErrorMessage: (state, message) => state.errorMessage = message,
        setMode: (state, mode) => state.mode = mode,
        setItems: (state, items) => state.items = items,
        setItemToDelete: (state, item) => state.itemToDelete = item,
        setTotalItems: (state, total) => state.totalItems = total,
        setRowsPerPage: (state, rowsPerPage) => state.rowsPerPage = rowsPerPage,
        showFilter: (state, payload) => state.dialogFilter = payload.show,
        showDialog: (state, show) => state.dialog = show,
        setDialogDelete: (state, show) => state.dialogDelete = show,
        showErrorNotification: (state, show) => state.errorNotification = show,
        showSuccessNotification: (state, show) => state.successNotification = show,
        showLoading: (state, show) => state.loading = show,
        showNotification: (state, payload) => {
            state.showNotification = payload.show,
                state.notificationMessage = payload.message,
                state.notificationType = payload.type
        },
        setFilter: (state, filter) => {
            state.filter = filter
        },
        setFiltered: (state, payload) => {
            state.filtered = payload.filtered
        },
        updateField,
        viewItem(state, item) {
            state.editedItem = item
            state.editedIndex = state.items.indexOf(item)
            state.dialog = true
            state.mode = "VIEW"
        }
    },
    getters: {
        getField,
    },
    actions: {
        clearFilter: ({ commit, dispatch }) => {
            commit('setFilter', Object.assign({}, defaultFilter))
            commit('setFiltered', { filtered: false })
            commit('showFilter', { show: false })
            dispatch('getItems', { resetPage: true })
        },
        closeDeleteDialog: ({ commit }) => {
            commit('setDialogDelete', false)
            commit('setItemToDelete', {})
        },
        deleteItem: ({ dispatch, commit, state }) => {
            commit('loading', true)
            axios.delete(endPoints.products + "/" + state.itemToDelete.id, state.itemToDelete).then(() => {
                dispatch('closeDeleteDialog')
                return dispatch('getItems');
            });
        },
        getItems: ({ commit, state }) => {
            commit('loading', true)
            let url = endPoints.products;
            let filter = state.filter;
            let filtered = false;
            url += '?page=' + state.pagination.page + '&' + 'itemsPerPage=' + state.pagination.itemsPerPage;

            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
                    filtered = true
                    url += "&" + field + "=" + filter[field]
                }
            });
            axios.get(url).then(items => {
                commit('setFiltered', { filtered: filtered })
                commit('setTotalItems', items.data.total)
                commit('loading', false)
                commit('setItems', items.data.data)
            });
        },
        getItemsNoPage: ({ state }) => {
            let url = endPoints.products;
            let filter = state.filter;
            url += '?page=' + '1' + '&' + 'itemsPerPage=' + '99999';

            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
                    if (field == "end" || field == "start") {
                        var e = new Date(filter[field])

                        url += "&" + field + "=" + e.toISOString().substr(0, 10) + " " + e.toLocaleTimeString()
                    } else {
                        url += "&" + field + "=" + filter[field]
                    }
                }
            });
            return axios.get(url).then(items => {
                return items.data.data
            });
        },
        showDeleteDialog: ({ commit }, item) => {
            commit('setDialogDelete', true)
            commit('setItemToDelete', item)
        },
        save: ({ dispatch, commit, state }) => {
            var url = endPoints.products;
            let func = '';
            if (state.mode == 'ADD') {
                func = axios.post;
                url = endPoints.products;
            } else {
                func = axios.put;
                url += '/' + state.editedItem.id;
            }
            func(url, state.editedItem).then(
                () => {
                    dispatch('getItems')
                    let notification = {
                        show: true,
                        message: "Produto salvo com sucesso.",
                        type: "success"
                    }
                    commit('closeDialog')
                    commit('showNotification', notification)
                },
                error => {
                    let notification = {
                        show: true,
                        message: error.response.data.message,
                        type: "error"
                    }
                    commit('showNotification', notification)
                }
            );
        }
    }
}