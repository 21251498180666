<template>
  <div>
    <v-card class="elevation-10"> 
      <v-card-title class="text-center justify-center">
        Jogadores Relacionados ao Agente
      </v-card-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
           <v-btn v-bind="attrs" v-on="on" color="black" dark class="add-align-right" @click="add" small>
             <v-icon>mdi-plus</v-icon
            ></v-btn>
        </template>
        <span>Adicionar</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="black" dark class="add-align-right" @click="getItems" small>
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
        </template>
        <span>Recarregar</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="black" dark class="add-align-right" @click="showFilter({show: true})" small>
              <v-icon>mdi-filter</v-icon>
            </v-btn>
        </template>
        <span>Filtrar</span>
      </v-tooltip>

      <v-data-table
        :headers="headers"
        :loading="loading"
        :items="items"
        :options.sync="pagination"
        :server-items-length="totalItems"
        no-data-text="Nenhum jogador encontrado"
        class="elevation-10 table-margin"
        :footer-props="{
            'items-per-page-options': [10, 20, 50, 100],
            'items-per-page-text': 'Itens por página',
            'show-first-last-page': true,
            'page-text': `Página ${pagination.page} de ${parseInt(totalItems/pagination.itemsPerPage) + 1}`
          }" 
      >
        <template v-slot:[`item.start_date`]="{ item }">
          <span>{{ getDate(item.start_date) }}</span>
        </template>
        <template v-slot:[`item.id_player`]="{ item }">
          <v-icon v-if="item.id_player" color="blue">mdi-check-decagram</v-icon>
        </template>
        <template v-slot:[`item.balance`]="{ item }">
          <span>{{ getValue(item.balance) }}</span>
        </template>
        <template v-slot:[`item.balance_agency`]="{ item }">
          <span>{{ getValue(item.balance_agency) }}</span>
        </template>
        <template v-slot:[`item.player_deal`]="{ item }">
          <span>{{ item.player_deal ? item.player_deal + "%" : "" }}</span>
        </template>

        <template v-slot:[`item.actions`]="{ item }">

          <v-tooltip bottom >
            <template v-slot:activator="{on, attrs}">        
              <v-icon small v-on="on" v-bind="attrs" class="mr-2" @click="edit({ percentage: item.player_deal, player_agent_id: item.id })">mdi-pencil</v-icon>
            </template>
            <span>Editar Deal</span>
          </v-tooltip>

        </template>
      </v-data-table>

      <v-dialog :value="dialogFilter" @click:outside="showFilter({show: false})" width="600" @keydown.enter="filter">
        <players-agents-filter></players-agents-filter>
      </v-dialog>

      <v-dialog :value="formDialog" persistent width="600" @click:outside="closeFormDialog">
        <v-card>
          <v-card-title>
            <span class="headline">Editar Deal do Jogador</span>
          </v-card-title>

          <v-card-text>
                <v-form ref="form">
                  <v-container grid-list-md>
                    <v-layout wrap>
                      <v-flex xs12 sm12 md12>
                        <v-text-field
                          required
                          label="Porcentagem *"
                          v-model="percentage"
                          :error-messages="errors.collect('percentage')"
                          v-validate="'required:Porcentagem,percentage|min_value:0|max_value:100'"
                          data-vv-name="percentage"
                          append-icon="mdi-percent-outline"
                          outlined
                          type="number"
                        ></v-text-field>
                      </v-flex> 
                    </v-layout>
                  </v-container>
                </v-form>
              </v-card-text>

          <v-card-actions>
            <v-col>
              <v-btn text @click="closeFormDialog">Cancelar</v-btn>
            </v-col>
            <v-col class="text-right">
              <v-btn
                text
                type="submit"
                right
                @click="saveDeal"
                >Salvar</v-btn
              >
            </v-col>
          </v-card-actions>

        </v-card>
      </v-dialog>

      <v-dialog :value="dialog" persistent width="600" @click:outside="closeDialog()">
            <v-card>
              <v-card-title>
                <span class="headline">Adicionar Jogador</span>
              </v-card-title>

              <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-container grid-list-md>
                    <v-layout wrap>
                      <v-flex xs12 sm12 md12>
                        <v-autocomplete
                          clearable
                          deletable-chips
                          v-model="player_id"
                          :no-filter="true"
                          :items="players"
                          outlined
                          chips
                          small-chips
                          no-data-text="Nenhum jogador encontrado"
                          :item-text="getTextPlayer"
                          item-value="id_player"
                          placeholder="Digite aqui ID do jogador"
                          label="Jogador"
                          :search-input.sync="searchInput"
                        ></v-autocomplete>
                      </v-flex> 
                    </v-layout>
                  </v-container>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-col>
                  <v-btn text @click="closeDialog">Cancelar</v-btn>
                </v-col>
                <v-col class="text-right">
                  <v-btn
                    text
                    type="submit"
                    right
                    @click="showConfirmDialog(true)"
                    >Salvar</v-btn
                  >
                </v-col>
              </v-card-actions>

            </v-card>
          </v-dialog>

          <v-dialog :value="confirmDialog" persistent width="600" @click:outside="showConfirmDialog(false)">
            <v-card>
              <v-card-title>
                <span class="headline">Caso esse jogador esteja vinculado a um outro agente, esse vínculo será desfeito! Deseja continuar?</span>
              </v-card-title>

              <v-card-actions>
                <v-col>
                  <v-btn text @click="showConfirmDialog(false)">Cancelar</v-btn>
                </v-col>
                <v-col class="text-right">
                  <v-btn
                    text
                    type="submit"
                    right
                    @click="save"
                    >Salvar</v-btn
                  >
                </v-col>
              </v-card-actions>

            </v-card>
          </v-dialog>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import PlayersAgentFilter from "./PlayersAgentFilter.vue"

export default {
    name: "PlayersAgent",
    components: {
      "players-agents-filter": PlayersAgentFilter,
    },
    computed: {
      ...mapState("players_agent", ["loading","items", "totalItems", "dialog", "confirmDialog", "formDialog", "players", "dialogFilter"]),
      ...mapFields("players_agent", ["pagination", "editedItem.player_id", "percentage"])
    },
    created() {
      this.getItems()
      this.$validator.extend("required", {
        getMessage: (field, [label]) => label + " deve ser informado",
        validate: (_, [, field]) => {
            if (this[field] || this[field] === 0) {
            return true;
            } else {
                return false;
            }
        },
      });
    },
    methods: {
      ...mapActions("players_agent", ["getItems", "save", "saveDeal", "closeDialog", "closeFormDialog", "getPlayers"]),
      ...mapMutations('players_agent', ['add', 'edit', 'showConfirmDialog', 'showFilter']),
      getDate(date) {
        if(date) {
          var d = new Date(date)
          return d.toLocaleDateString()
        }
        else {
          return ''
        }
      },
      filter() {
        this.getItems();
        this.showFilter({ show: false });
      },
      getTextPlayer(item) {
      if(item.name) {
        return item.id_pppoker + ' | ' + item.name
      } else {
        return item.id_pppoker
      }
    },
    getValue(value) {
      return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }
    },
    data: () => ({
      headers: [
        { text: "Clube", value: "club_name", sortable: false },
        { text: "Id Suprema", value: "id_pppoker", sortable: false },
        { text: "Ult. Atividade", value: "start_date", sortable: false, align: 'right' },
        { text: "Deal", value: "player_deal", sortable: false },
        { text: "Ações", value: "actions", sortable: false }
      ],
      searchInput: '',
      valid: false
    }),
    props: [
      "agent_id"
    ],
    watch: {
      pagination: {
        handler () {
          this.getItems()
        },
      },
      searchInput(val) {
        if (val) {
          this.getPlayers({ agent_id: this.agent_id, search: val })
        }
    },
    }
};
</script>

<style>
@import "../css/agent.css";
</style>
