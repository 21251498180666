<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar
      :color="notification.type"
      right
      top
      :timeout="2000"
      v-model="notification.show"
    >
      {{ notification.message }}
    </v-snackbar>

    <v-toolbar flat class="elevation-5">
      <div>
        <v-toolbar-title>Vagas</v-toolbar-title>
      </div>
      <v-spacer></v-spacer>
      <div class="button-toolbar">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="black"
              dark
              class="ma-2"
              @click="setShowFilter(true)"
              v-bind="attrs"
              v-on="on"
              small
            >
              <v-icon color="white">mdi-filter</v-icon>
            </v-btn>
          </template>
          <span>Filtro</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="black"
              dark
              class="ma-2"
              @click="getItems"
              v-bind="attrs"
              v-on="on"
              small
              ><v-icon>mdi-refresh</v-icon></v-btn
            >
          </template>
          <span>Recarregar</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="black"
              dark
              class="ma-2"
              @click="addJob"
              v-bind="attrs"
              v-on="on"
              small
            >
              <v-icon color="white">mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Adicionar</span>
        </v-tooltip>
      </div>
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :loading="loading"
      :items="items"
      :options.sync="pagination"
      :server-items-length="totalItems"
      class="elevation-10 table-margin"
      no-data-text="Nenhuma vaga encontrada"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-if="item.status == 0" v-slot:activator="{ attrs, on }">
            <v-icon
              small
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="finishDialog(item)"
              >mdi-check</v-icon
            >
          </template>

          <span>Finalizar</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ attrs, on }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              small
              class="mr-2"
              @click="view(item)"
              >mdi-information</v-icon
            >
          </template>
          <span>Informações</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ attrs, on }">
            <v-icon
              small
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="openCandidates(item)"
              >mdi-account-group</v-icon
            >
          </template>

          <span>Candidatos</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-if="item.status == 0" v-slot:activator="{ attrs, on }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              small
              class="mr-2"
              @click="editJob({ item: item })"
              >mdi-pencil</v-icon
            >
          </template>
          <span>Editar</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ attrs, on }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              small
              class="mr-2"
              @click="showDeleteDialog(item)"
              >mdi-delete</v-icon
            >
          </template>
          <span>Excluir</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          :color="getColor(item.status)"
          class="ma-2"
          text-color="white"
          label
          >{{ getStatus(item.status) }}</v-chip
        >
      </template>
      <template v-slot:[`item.requester`]="{ item }">
        <span v-if="item.requester !== null" class="ma-2">{{
          getRequester(item.requester)
        }}</span>
      </template>

      <template v-slot:[`item.responsible`]="{ item }">
        <span class="ma-2">{{ getRequester(item.responsible) }}</span>
      </template>
      <template v-slot:[`item.role`]="{ item }">
        <span class="ma-2">{{ getRole(item.role) }}</span>
      </template>
      <template v-slot:[`item.start_date`]="{ item }">
        <span class="ma-2">{{ getDate(item.start_date) }}</span>
      </template>
      <template v-slot:[`item.finish_date`]="{ item }">
        <span class="ma-2">{{ getDate(item.finish_date) }}</span>
      </template>
      <template v-slot:[`item.department`]="{ item }">
        <span class="ma-2">{{ getDepartment(item.department) }}</span>
      </template>
      <template v-slot:[`item.cust`]="{ item }">
        <span v-if="item.cust !== null">{{
          item.cust.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })
        }}</span>
      </template>
    </v-data-table>

    <v-dialog :value="dialog" width="800" @click:outside="closeDialog">
      <jobs-form></jobs-form>
    </v-dialog>
    <v-dialog
      :value="dialogFinish"
      width="800"
      @click:outside="closeFinishDialog"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Finalizar Vaga </span>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Custo da Vaga"
                    v-model="job.cust"
                    prefix="R$"
                    data-vv-name="Custo"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-datetime-picker
                    v-model="job.finish_date"
                    required
                    date-format="dd/MM/yyyy"
                    label="Data de Finalização *"
                    :disabled="this.mode == 'VIEW'"
                    :time-picker-props="timeProps"
                    :textFieldProps="{ outlined: true }"
                  >
                    <template slot="dateIcon">
                      <v-icon>mdi-calendar</v-icon>
                    </template>
                    <template slot="timeIcon">
                      <v-icon>mdi-clock</v-icon>
                    </template>
                  </v-datetime-picker>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
          <v-card-actions>
            <v-col>
              <v-btn color="error" @click="closeFinishDialog">Cancelar</v-btn>
            </v-col>
            <v-col class="text-right" v-if="mode != 'VIEW'">
              <v-btn type="submit" color="success" right @click="finishJob"
                >Finalizar</v-btn
              >
            </v-col>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      :value="filterDialog"
      @click:outside="setShowFilter(false)"
      width="600"
    >
      <jobs-filter></jobs-filter>
    </v-dialog>

    <v-dialog :value="dialogDelete" persistent width="300">
      <v-card>
        <v-card-title class="headline">Confirmar a exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn text @click="closeDeleteDialog()">Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn text type="submit" right @click="deleteJob">Confirmar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import JobsForm from "./JobsForm.vue";
import JobsFilter from "./JobsFilter.vue";

export default {
  name: "Jobs",

  created() {
    this.getItems({ resetPage: false });
    this.getRoles();
    this.getCollaborators();
    this.getDepartments();
  },

  components: {
    JobsForm,
    JobsFilter,
  },

  methods: {
    ...mapActions("jobs", [
      "getItems",
      "getRoles",
      "getCollaborators",
      "getDepartments",
      "closeDialog",
      "closeDeleteDialog",
      "deleteJob",
      "showDeleteDialog",
      "openCandidates",
      "save",
    ]),
    ...mapMutations("jobs", [
      "addJob",
      "view",
      "closeFinishDialog",
      "setShowFilter",
      "editJob",
      "finishDialog",
    ]),

    getRole(role_id) {
      let role = this.roles.find((role) => role.id == role_id);
      return role.name;
    },
    getColor(type) {
      if (type == 0) {
        return "light-blue darken-3";
      } else {
        return "green darken-3";
      }
    },

    getDate(date) {
      if (date) {
        var d = new Date(date);
        return d.toLocaleDateString() + " " + d.toLocaleTimeString();
      }
      return "";
    },
    getDepartment(department_id) {
      return this.departments.find(
        (department) => department.id == department_id
      ).name;
    },
    getRequester(requester) {
      return this.collaborators.find(
        (collaborator) => collaborator.id == requester
      ).name;
    },
    getStatus(status) {
      return this.possible_status[status].description;
    },
    finishJob() {
      this.job.cust = this.job.cust.toString().replace(",", ".");
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          var d = new Date(this.job.finish_date);
          this.job.finish_date =
            d.toISOString().substr(0, 10) + " " + d.toLocaleTimeString();
          this.value = parseFloat(this.value);
          this.save();
        }
      });
    },
  },
  computed: {
    ...mapState("login", ["userData"]),
    ...mapState("jobs", [
      "dialog",
      "items",
      "job",
      "notification",
      "dialogFinish",
      "mode",
      "loading",
      "totalItems",
      "collaborators",
      "filterDialog",
      "departments",
      "roles",
      "possible_status",
      "dialogDelete",
    ]),
    ...mapFields("jobs", ["pagination"]),
  },
  data: () => ({
    valid: false,
    timeProps: {
      format: "24hr",
    },

    headers: [
      {
        text: "Candidatos",
        value: "candidates",
        sortable: true,
        align: "center",
      },
      {
        text: "Data Inicial",
        value: "start_date",
        sortable: true,
        align: "center",
      },
      {
        text: "Solicitante",
        value: "requester",
        sortable: true,
        align: "center",
      },
      {
        text: "Responsável",
        value: "responsible",
        sortable: true,
        align: "center",
      },
      { text: "Cargo", value: "role", sortable: true, align: "center" },
      { text: "Setor", value: "department", sortable: true, align: "center" },
      {
        text: "Data Final",
        value: "finish_date",
        sortable: true,
        align: "center",
      },
      {
        text: "Custo",
        value: "cust",
        sortable: true,
        align: "center",
      },
      { text: "Status", value: "status", sortable: true, align: "center" },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        align: "center",
        width: "10%",
      },
    ],
  }),

  watch: {
    pagination: {
      handler() {
        this.getItems({ resetPage: false });
      },
    },
  },
};
</script>
