const defaultChipsTransfer = {
    name_pppoker: '',
    id_pppoker: '',
    id_agent: '',
    balance: 0,
    name_supreme: '',
    club_origin: 0,
    club_destiny: 0,
    id_supreme: '',
    contact: '',
    chips: 0
};

export default defaultChipsTransfer