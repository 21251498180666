<template>
  <div>
    <v-card>
      <v-card-title class="headline">Buscar Pix</v-card-title>
      <v-container grid-list-md>
        <v-layout wrap>
          <v-flex xs12 sm12 md12>
            <v-datetime-picker
              v-model="date_start"
              required
              date-format="dd/MM/yyyy"
              :time-picker-props="timeProps"
              label="Data"
              :error-messages="errors.collect('date_start')"
              v-validate="'required:Data Inicial,date_start'"
              :textFieldProps="{ outlined: true }"
            >
              <template slot="dateIcon">
                <v-icon>mdi-calendar</v-icon>
              </template>
              <template slot="timeIcon">
                <v-icon>mdi-clock</v-icon>
              </template>
            </v-datetime-picker>
          </v-flex>
          <!--v-flex xs12 sm6 md6>
            <v-datetime-picker
              v-model="date_end"
              required
              date-format="dd/MM/yyyy"
              :time-picker-props="timeProps"
              label="Até"
              :error-messages="errors.collect('date_end')"
              v-validate="'required:Data Final,date_end'"
              :textFieldProps="{ outlined: true }"
            >
              <template slot="dateIcon">
                <v-icon>mdi-calendar</v-icon>
              </template>
              <template slot="timeIcon">
                <v-icon>mdi-clock</v-icon>
              </template>
            </v-datetime-picker>
          </v-flex!-->
          <v-flex xs12 sm12 md12>
            <v-select
              label="Banco"
              :items="bankBPay"
              v-model="bank_pix"
              :item-text="getTextBank"
              item-value="id"
              outlined
            ></v-select>
          </v-flex>
        </v-layout>
      </v-container>
      <v-card-actions>
        <v-col>
          <v-btn text @click="showDialogSearchPix({ show: false })"
            >Cancelar</v-btn
          >
        </v-col>
        <v-col class="text-right">
          <v-btn text @click="search">Buscar</v-btn>
        </v-col>
      </v-card-actions>

      <!-- <v-card color="white" dark height="190px">
          <div class="text-center">
          <v-card-text> <span class="headline">Buscando Pix...</span> </v-card-text>
           <v-spacer></v-spacer>
            <v-progress-circular
              indeterminate
              color="primary"
              size="90"
            ></v-progress-circular>
          </div>
        </v-card>
      </v-dialog> -->
    </v-card>
    <v-dialog v-model="loadingSearchPix" hide-overlay persistent width="470">
      <v-card color="white">
        <v-card-title>
          <span class="headline">Buscando Pix...</span>
          <v-spacer></v-spacer>
          <v-progress-circular
            indeterminate
            class="text-right"
            color="primary"
            size="30"
          ></v-progress-circular>
        </v-card-title>
        <v-card-actions>
          <v-col class="text-center">
            <v-btn color="info" @click="setLoadingSearchPix({ show: false }); showDialogSearchPix({ show: false })"
              >Continuar em Segundo Plano </v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
export default {
  created() {
    this.getBanks();
  },
  computed: {
    ...mapState("bank", { banks: "items" }),
    ...mapState("deposit", ["loadingSearchPix"]),
    ...mapFields("deposit", [
      "dialogSearchPix",
      "pix_search.date_start",
      "pix_search.date_end",
      "pix_search.bank_pix",
    ]),
    bankBPay() {
      return this.banks.filter((item) => item.id == 98);
    },
  },
  methods: {
    ...mapActions("bank", { getBanks: "getItems" }),
    ...mapActions("deposit", ["searchPix"]),
    ...mapMutations("deposit", ["showDialogSearchPix", "setLoadingSearchPix"]),
    getTextBank(item) {
      if (item.account) {
        return item.name + " | " + item.account;
      } else {
        return item.name;
      }
    },
    search() {
      this.$validator.validateAll().then((valid) => {
        console.log(valid, this.date_end)
        if (valid) {
          if (this.date_start != null && this.date_start != "") {
            this.date_start =
              this.date_start.toISOString().substr(0, 10) +
              " " +
              this.date_start.toLocaleTimeString();
            this.value = parseFloat(this.value);
          }

          this.searchPix();
        }
      });
    },
  },
  data: () => ({
    timeProps: {
      format: "24hr",
    },
  }),
};
</script>