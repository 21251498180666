import axios from "axios";
import { getField, updateField } from "vuex-map-fields";
import endPoints from "../../../config/endPoints";

const defaultItem = {
  id: null,
  id_agent: null,
  value_overlay: 0,
  value_diamonds: 0,
  value_transactions: 0,
  fix_value_transactions: 0,
  charge_overlay: 0,
  charge_diamonds: 0,
  charge_transactions: 0,
  auto_exclusion: 1,
  max_value_exclusion: 1,
  weeks_for_exclusion: 2,
  moves_in_central: 1,
  send_report_closure: 0,
  send_report_central_transactions: 0
};

export default {
  namespaced: true,
  state: {
    mode: "EDIT",
    agent_id: 0,
    selectedItem: {},
    item: [],
    loading: false,
    loading_overlay: false,
    notificationMessage: '',
    notificationType: "success",
    showNotification: false,
    pagination_subagent: {
      page: 1,
      itemsPerPage: 10,
    },
    possible_overlays: [
      { id: 0, description: "Não Cobrar" },
      { id: 1, description: "Dividir Igualmente" },
      { id: 2, description: "Fixo" },
    ],
    possible_diamonds: [
      { id: 0, description: "Não Cobrar" },
      { id: 1, description: "Fixo" },
    ],
    possible_transactions: [
      { id: 0, description: "Não Cobrar" },
      { id: 1, description: "Cobrar" },
    ],
    possible_exclusions: [
      { id: 0, description: "Não" },
      { id: 1, description: "Sim" },
    ],
  },
  mutations: {
    add: (state) => {
      state.selectedItem = Object.assign({}, defaultItem);
      state.mode = "ADD";
    },
    edit: (state, payload) => {
      state.selectedItem = Object.assign({}, payload.item);
      state.mode = "EDIT";
    },
    loading: (state, payload) => (state.loading = payload.loading),
    setItem: (state, payload) => {
      state.selectedItem = Object.assign({}, payload.item);
      state.item = payload.item;
      state.mode = "EDIT";
    },
    loading_overlay: (state, payload) => {
      state.loading_overlay = payload.show;
    },
    showLoading: (state, show) => (state.loading = show),
    showNotification: (state, payload) => {
      (state.showNotification = payload.show),
        (state.notificationMessage = payload.message),
        (state.notificationType = payload.type);
    },
    updateField,
  },
  getters: {
    getField,
  },
  actions: {
    getItem: ({ commit, rootGetters }) => {
      commit("loading", { loading: true });
      let url = endPoints.parameters_agent_closure;
      url += `/${rootGetters["agent/getIdAgent"]}`;
      axios.get(url).then((response) => {
        let data = response.data != null ? response.data : [];

        commit("loading", { loading: false });
        commit("setItem", { item: data });
      });
    },
    save: ({ dispatch, commit, state, rootGetters }) => {
      let url = endPoints.parameters_agent_closure;
      let func = "";
      func = axios.put;
      url += "/" + state.selectedItem.id;

      state.selectedItem.id_agent = rootGetters["agent/getIdAgent"];
      state.selectedItem.value_overlay = parseFloat(state.selectedItem.value_overlay)
      state.selectedItem.value_diamonds = parseFloat(state.selectedItem.value_diamonds)
      state.selectedItem.value_transactions = parseFloat(state.selectedItem.value_transactions)
      state.selectedItem.fix_value_transactions = parseFloat(state.selectedItem.fix_value_transactions)

      func(url, state.selectedItem).then(
        () => {
          dispatch("getItem");
          let notification = {
            show: true,
            message: "Parâmetros salvos com sucesso!",
            type: "success",
          };
          commit("showNotification", notification);
        },
        (error) => {
          let notification = {
            show: true,
            message: error.response.data.message,
            type: "error",
          };
          commit("showNotification", notification);
        }
      );
    },
  },
};
