import axios from 'axios'
import { getField, updateField } from 'vuex-map-fields'
import endPoints from '../../../config/endPoints'
import formatDate from '../../../util/formatDate.js'

import {
  defaultErrorNotification,
  defaultSuccessNotification,
} from '../../../constants'

const defaultFilter = {
  start: '',
  end: '',
  ag_group_id: '',
  date_created: '',
  debt: '',
  id_agent: '',
  observation: '',
  rating: '',
  responsible: '',
  responsible_contact: '',
  risk: '',
  status: '',
}

const defaultBillingItem = {
  id: null,
  id_agent: null,
  id_current_annotated: '',
  id_last_annotated: '',
  ag_group_id: '',
  date_created: '',
  debt: null,
  observation: '',
  pending_payment: null,
  rating: null,
  risk: null,
  responsible: '',
  responsible_contact: ''
}

export default {
  namespaced: true,
  state: {
    ratings: [],
    agents: [],
    items: [],
    agent_info: {},
    selectedItem: {},
    totalItems: 0,
    loading: false,
    filterDialog: false,
    formDialog: false,
    deleteDialog: false,
    filtered: false,
    filter: {
      start: '',
      end: '',
      ag_group_id: '',
      date_created: '',
      debt: '',
      id_agent: '',
      observation: '',
      rating: '',
      responsible: '',
      responsible_contact: '',
      risk: '',
      status: '',
    },
    notification: {
      show: false,
      message: '',
      type: '',
    },
    pagination: {
      page: 1,
      itemsPerPage: 10,
    },
    possible_ratings: [
      { id: 0, description: "Curto" },
      { id: 1, description: "Médio" },
      { id: 2, description: "Longo" },
    ],
    possible_risks: [
      { id: 0, description: "Muito Baixo" },
      { id: 1, description: "Baixo" },
      { id: 2, description: "Médio" },
      { id: 3, description: "Alto" },
      { id: 4, description: "Muito Alto" },
    ],
    possible_status: [
      { id: 0, description: "Pendente" },
      { id: 1, description: "Atrasado" },
      { id: 2, description: "Concluído" },
      { id: 3, description: "Cancelado" },
    ]
  },
  mutations: {
    setDialog: (state, payload) => {
      if (payload.show == false) {
        state.selectedItem = Object.assign({}, defaultBillingItem)
        state.filter = Object.assign({}, defaultFilter)
      }
      if (payload.item) {
        state.selectedItem = payload.item
      }
      if (payload.mode) {
        state.mode = payload.mode
      }
      state[payload.dialog] = payload.show
    },
    setAgentsAnnotated: (state, payload) => { state.agents = payload.agents },
    setAgentInfo: (state, payload) => { state.agent_info = payload },
    setDefaultItem: (state) => (state.selectedItem = Object.assign({}, defaultBillingItem)),
    setFilter: (state, payload) => (state.filter = payload),
    setFiltered: (state, payload) => (state.filtered = payload),
    setItems: (state, payload) => (state.items = payload),
    setLoading: (state, payload) => (state.loading = payload),
    setRatings: (state, payload) => { state.ratings = payload.ratings },
    setSelectedItem: (state, payload) => (state.selectedItem = payload),
    setTotalItems: (state, payload) => (state.totalItems = payload),
    showNotification: (state, payload) => (state.notification = payload),
    updateField,
  },
  actions: {
    clearFilter: ({ commit }) => {
      commit('setFilter', Object.assign({}, defaultFilter))
      commit('setDialog', { dialog: 'filterDialog', show: false })
    },
    getAgentData: async ({ state, commit }) => {
      let url = endPoints.billings + "/agent_annotated_info/" + state.selectedItem.id_agent

      commit('setLoading', true)
      try {
        let items = await axios.get(url)
        commit('setAgentInfo', items.data)
        defaultSuccessNotification()
      } catch (error) {
        commit(
          'showNotification',
          defaultErrorNotification(error?.response?.data?.message)
        )
      }

      state.selectedItem.id_current_annotated = state.agent_info.id_current_annotated
      state.selectedItem.id_last_annotated = state.agent_info.id_last_annotated
      state.selectedItem.ag_group_id = state.agent_info.ag_group_id
      state.selectedItem.pending_payment = state.agent_info.pending_payment
      state.selectedItem.current_week = state.agent_info.current_week
      state.selectedItem.debt = state.agent_info.current_week + state.agent_info.pending_payment

      commit('setLoading', false)

    },
    getAgents({ commit }, payload) {
      if (payload.search) {
        axios.get(endPoints.agents + "/agent_by_name/" + payload.search).then(response => {
          var payloadAgents = {
            agents: response.data
          }
          if (response.data == null) {
            payloadAgents.agents = []
          }
          commit('setAgentsAnnotated', payloadAgents)
        });
      }
    },
    getItems: async ({ commit, state }) => {
      let filter = state.filter
      let url = endPoints.billings
      
      url += '?page=' + state.pagination.page + "&itemsPerPage=" + state.pagination.itemsPerPage;

      commit('setFiltered', false)
      Object.keys(filter).forEach(function (field) {
        if (
          filter[field] !== '' &&
          filter[field] !== undefined &&
          filter[field] !== null
        ) {
          commit('setFiltered', true)

          if (field == 'end' || field == 'start') {
            var e = new Date(filter[field])
            url +=
              '&' + field + '=' + formatDate(e) + ' ' + e.toLocaleTimeString()
          } else {
            url += '&' + field + '=' + filter[field]
          }
        }
      })

      if (
        state.pagination &&
        state.pagination.sortBy &&
        state.pagination.sortBy.length > 0
      ) {
        url +=
          '&sort_by=' +
          (state.pagination.sortBy[0] === 'date'
            ? 'id'
            : state.pagination.sortBy[0])
        url += '&sort_asc=' + state.pagination.sortAsc[0]
      }
      commit('setLoading', true)
      try {
        commit('setDefaultItem')
        let items = await axios.get(url)
        commit('setItems', items.data.data)
        commit('setTotalItems', items.data.total_itens)
        if (state.mode == "ADD")
          commit('setDialog', { dialog: 'formDialog', show: false })
        if (state.mode == "EDIT")
          commit('setDialog', { dialog: 'filterDialog', show: false })
        defaultSuccessNotification()
      } catch (error) {
        commit(
          'showNotification',
          defaultErrorNotification(error?.response?.data?.message)
        )
      }
      commit('setLoading', false)
    },
    deleteItem: async ({ commit, dispatch, state }) => {
      commit('setLoading', true)
      try {
        await axios.delete(`${endPoints.billings}/${state.selectedItem.id}`)

        commit('setDialog', { dialog: 'deleteDialog', show: false })
        commit('setLoading', false)
        commit(
          'showNotification',
          defaultSuccessNotification('Cobrança removida com sucesso')
        )

        return dispatch('getItems')
      } catch (error) {
        commit(
          'showNotification',
          defaultErrorNotification(error?.response?.message)
        )
        commit('setLoading', false)
      }
    },
    save: async ({ commit, state, dispatch, rootState }) => {

      let url = endPoints.billings
      let func = ''
      state.mode === 'ADD'
        ? (func = axios.post)
        : ((func = axios.put), (url += `/${state.selectedItem.id}`))

      commit('setLoading', true)
      try {
        let date_created = new Date();
        state.selectedItem.date_created = date_created.toISOString().substr(0, 10) + " " + date_created.toLocaleTimeString();
        state.selectedItem.user_id = rootState.login.userData.id
        state.selectedItem.user_name = rootState.login.userData.name

        await func(url, state.selectedItem)

        commit(
          'showNotification',
          defaultSuccessNotification('Operação feita com sucesso')
        )
        commit('setDialog', { dialog: 'formDialog', show: false })
        commit('setLoading', false)
        dispatch('getItems', { resetPage: false })
      } catch (error) {
        commit(
          'showNotification',
          defaultErrorNotification(error?.response?.message)
        )
        commit('setLoading', false)
      }
    },
  },
  getters: {
    getField,
  },
}
