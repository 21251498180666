<template>
  <div class="elevation-10">
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="50"></v-progress-circular>
    </v-overlay>
    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2000"
      v-model="showNotification"
    >
        {{ notificationMessage }}
    </v-snackbar>
    <v-dialog v-model="dialog" max-width="600">
      <div @drop.prevent="addDropFile" @dragover.prevent>
        <v-card>
          <v-card-title>
            <span class="headline">Menu</span>
          </v-card-title>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    label="Nome *"
                    v-model="name"
                    :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                    :error-messages="errors.collect('name')"
                    v-validate="'required'"
                    data-vv-name="name"
                    type="text"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12 v-if="level == 1">
                  <v-textarea
                    auto-grow
                    label="Ícone *"
                    v-model="icon"
                    :error-messages="errors.collect('icon')"
                    v-validate="'required'"
                    data-vv-name="icon"
                    background-color="white"
                    type="text"
                    outlined
                    id="textareamessage"
                    hide-details="true"
                    rows="1"
                  ></v-textarea>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-textarea
                    auto-grow
                    label="Link *"
                    v-model="link"
                    :error-messages="errors.collect('link')"
                    data-vv-name="link"
                    background-color="white"
                    type="text"
                    outlined
                    id="textareamessagetwo"
                    hide-details="true"
                    rows="1"
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
          <v-card-actions>
            <v-col>
              <v-btn text @click="closeDialog">Cancelar</v-btn>
            </v-col>
            <v-col class="text-right">
              <v-btn text type="submit" right @click="save">Salvar</v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </div>
    </v-dialog>
    <v-dialog :value="dialogDelete" persistent width="300">
      <v-card>
        <v-card-title class="headline">Confirmar exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn
              text
              @click="deleteDialog({show: false, item: {}})"
            >Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn
              text
              type="submit"
              right
              @click="deleteItem"
            >Confirmar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog :value="dialogRelation" width="700"  @click:outside="setDialogRelation({ show: false })">
      <v-card> 
        <v-card-title> {{ menuSelected.name }} : </v-card-title>
        <v-data-table
          :headers="headersMenus"
          :items="menusRelation"
          hide-default-header
          hide-default-footer
          class="elevation-1"
          :items-per-page="99"
        >
          <template v-slot:[`item.has_relation`]="{ item }">
            <v-icon @click="removeRelation(item)" color="blue" v-if="item.has_relation == 1">mdi-eye</v-icon>
            <v-icon @click="addRelation(item)" v-if="item.has_relation == 0">mdi-eye-off</v-icon>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
    <v-toolbar flat color="blue lighten-4">
      <v-icon color="indigo darken-4">mdi-menu</v-icon>
      <div style="margin-left:10px;color:rgb(0, 0, 82)">Menus</div>
      <v-spacer></v-spacer>
      <v-btn color="black" dark class="ma-2" small @click="add({})"><v-icon>mdi-plus</v-icon></v-btn>
    </v-toolbar>
    <v-divider></v-divider>
    <v-treeview
      :items="items" 
      :load-children="getChildrens"
      transition
      item-key="item"
    >
      <template v-slot:prepend="{ item }">
        <v-icon>
          {{ item.icon }}
        </v-icon>
      </template>
      <template slot="append" slot-scope="{ item }">
        <v-icon 
          :class="{mdichevron: item.order == 0}" 
          @click="changeOrderDown({item: item, order})" 
          v-if="item.level == 1 && parentLenght(item, items)"
        >
          mdi-chevron-down
        </v-icon>
        <v-icon class="mdichevron" @click="changeOrderUp({item: item, order})" v-if="item.level == 1 && item.order != 0">mdi-chevron-up</v-icon>
        <v-icon small class="margin-c" color="blue" v-if="item.level == 1" @click="add({item: item})">mdi-plus</v-icon>
        <v-icon small class="mdichevron yellow-d" v-if="item.level == 2" @click="viewRelation({item: item})">mdi-account-eye</v-icon>
        <v-icon small class="margin-c" color="green" v-if="item.name != 'Administração'" @click="edit({item: item})" >mdi-pencil</v-icon>
        <v-icon small class="margin-c" color="red" v-if="item.name != 'Administração'" @click="deleteDialog({show: true, item: item})">mdi-delete</v-icon>
      </template>
    </v-treeview>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import b64 from '../../util/Base64'

export default {
  created() {
    this.getMenus({ id_profile: 0 })
  },
  computed: {
    items () {
      let dads = []
      this.menus.forEach( dad => {
        if (dad.level == 1) {
          dads.push(dad)
        }
      });
      return dads
    },
    ...mapState("menus", ["menus", "loading", "mode", "notificationMessage", "notificationType", "menusRelation", "dialogRelation", "menuSelected"]),
    ...mapFields("menus", [
      "active",
      "open",
      "dialog",
      "dialogDelete",
      "editedItem.id",
      "editedItem.name",
      "editedItem.icon",
      "editedItem.link",
      "editedItem.level",
      "showNotification"
    ])
  },
  data: () => ({
    headersMenus: [
      { text: "Perfil",  value: "profile_name", sortable: false, align: "left"},
      { text: "Permissão",  value: "has_relation", sortable: false, align: "center" },
    ],
    valid: false,
    types: [
      {type: 1, description: 'Normal'},
      {type: 99, description: 'Padrão'}
    ]
  }),
  methods: {
    addRelation(item) {
      this.saveMenu({ id_menu: this.menuSelected.id, id_profile: item.profile_id })
    },
    removeRelation(item) {
      this.removeMenu({ id_relation: item.id_relation })
    },
    getHasRelation(item) {
      if (item == 1) {
        return "mdi-eye"
      } else {
        return "mdi-eye-off"
      }
    },
    parentLenght(item, items) {
      let count = -1
      items.forEach(unity => {
        if (unity.parent == null) {
          count += 1 
        }
      });
      if (item.order != count) {
        return true 
      } else return false
    },
    addDropFile(e) {
      if (e.dataTransfer) {
        if (e.dataTransfer.files[0]) {
          let image = e.dataTransfer.files[0];
          b64(image).then((fileBase64) => {
            image.fileBase64 = fileBase64
            this.image = image
          })
        }
      }
    },
    ...mapMutations("menus", ["add", "closeDialog", "edit", "setDialogRelation"]),
    ...mapActions("menus", ["getChildrens", "changeOrderDown", "changeOrderUp", "deleteDialog", "deleteItem", "getMenus", "saveMenus", "viewRelation", "saveMenu", "removeMenu"]),
    save() {
      this.$validator.validateAll().then(valid => {
        if(valid) {
          this.saveMenus()
        }
      })
    },
  }
};
</script>

<style>
.mdichevron {
  margin-right: 20px !important;
}
.yellow-d {
  color:rgb(179, 179, 0) !important;
}
.margin-c {
  margin-left: 4px !important;
  margin-right: 4px !important;
}
</style>