<template>
  <div>
    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2000"
      v-model="showNotification"
    >
      {{ notificationMessage }}
    </v-snackbar>
    
    <v-toolbar class="mt-2 mb-5 elevation-0 px-3git">
      <v-toolbar-title class="title">Grupos Vinculados ao Cliente</v-toolbar-title>
      <v-spacer></v-spacer>
       
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn 
          v-bind="attrs" 
          v-on="on" 
          color="#373B40" 
          dark 
          @click="setShowDialog(true)" 
          small
          >Vincular Grupo</v-btn>
        </template>
        <span>Vincular novo grupo a esse cliente</span>
      </v-tooltip>
    </v-toolbar>

    <v-data-table
        :headers="headers"
        :loading="loading"
        :items="contactsFilteredByClient"
        :options.sync="pagination"
        :server-items-length="totalContacts"
        no-data-text="Nenhum grupo vinculado"
        class="elevation-10 table-margin"
        :footer-props="{
          'items-per-page-options': [10, 20, 50, 100],
          'items-per-page-text': 'Itens por página',
          'show-first-last-page': true,
          'page-text': `Página ${pagination.page} de ${parseInt(totalContacts/pagination.itemsPerPage) + 1}`
        }"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="deleteLinkClient(item)"
            >mdi-delete</v-icon
          >
        </template>
      </v-data-table>


      <v-dialog
      :value="dialogDelete"
      @click:outside="setDialogDelete(false)"
      @keydown.enter="setDialogDelete(false)"
      width="320"
      >
        <v-card>
          <v-card-title class="headline justify-center">Remover vinculação?</v-card-title>

          <v-card-actions>
            <v-col>
              <v-btn text @click="setDialogDelete(false)">Cancelar</v-btn>
            </v-col>
            <v-col class="text-right">
              <v-btn text type="submit" right @click="removeClientLink()"
                >Confirmar</v-btn
              >
            </v-col>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        :value="showDialog"
        @keydown.esc="setShowDialog(false)"
        @click:outside="setShowDialog(false)"
        width="1000"
      >
        <client-group-form></client-group-form>
      </v-dialog>

  </div>
</template>

<script>
  import { mapActions, mapMutations, mapState } from 'vuex'
  import { mapFields } from 'vuex-map-fields'
  import debounce from "../../../../../util/debounce.js";
  import LinkGroupForm from "./LinkGroupForm.vue";

  export default {
    name: 'ClientGroups',


  components: {
    'client-group-form': LinkGroupForm,
  },
    
  created() {
    let client_id = this.$route.params.client_id;

    this.filter.is_Group = true
    this.filter.client_id = client_id

    this.getContacts({resetPage: false, filteredByClient: true});
  },

  computed: {
    ...mapState("contacts", [
      "notificationMessage",
      "notificationType",
      "loading",
      "contactsFilteredByClient",
      "filter",
      "pagination",
      "totalContacts",
      'showDialog',
    ]),
    ...mapFields("contacts", [
      "showNotification",
      "dialogDelete",
      "pagination",
    ]),
  },
  methods: {
    ...mapMutations("contacts", [
      "setDialogDelete",
      "deleteLinkClient",
      "setShowDialog",
    ]),
    ...mapActions("contacts", [
      "getContacts",
      "removeClientLink",
    ]),


    getTextGroup(item) {
      if (item) {
        if (item.ag_group_id) {
          return item.ag_group_id + " | " + item.ag_group_name + " | " + item.nick;
        }
      }
      return "";
    },

    linkAgentToGroup() {
      let valid = this.$refs.form.validate();
      if (valid) {
        this.save();
      }
    }
  },
  data: () => ({
    headers: [
      { text: "Nome do Grupo", value: "name", sortable: false },
      { text: "Telefone", value: "phone", sortable: false },
      { text: "Clube", value: "instance_name", sortable: false },
      { text: "Ações", value: "actions", sortable: false, align: "right" }
    ],
    rules: {
      required(value) {
        return !!value || "Campo Obrigatório";
      }
    },
    searchInput: "",
    valid: false
  }),

  mixins: [debounce],

  watch: {
    searchInput(val) {
      var payload = {
        search: val,
        groups_contacts: this.ag_group_id
      };
      this.getGroupsList(payload);
    },
    pagination: {
      handler() {
        this.getContacts({ resetPage: false, filteredByClient: true });
      },
    },
  },
}
</script>

