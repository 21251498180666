import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../../config/endPoints';


const defaultResend = {
    playerid: '',
    slot: '',
    platform: ''
}

const formatDate = (d) => {
    var day, month, year;
    month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

/* const defaultFilter = {
    id: '',
    start_date: '',
    end_date: '',
    status: '',
    id_club: '',
    player_id: '',
    doc: '',
    value: ''
} */

export default {
    namespaced: true,
    state: {
        dialog: false,
        dialogInfo: false,
        dialogDelete: false,
        dialogFilter: false,
        dialogEditAndResend: false,
        dialogReleaseAutoAttendance: false,
        editedItem: {},
        filter: {
            id: '',
            start_date: '',
            end_date: '',
            status: '',
            id_club: '',
            player_id: '',
            doc: '',
            value: '',
        },
        items: [],
        itemToDelete: {},
        loading: false,
        loadingSearchPix: false,
        mode: 'LIST',
        overlay: false,
        page: 1,
        pagination: {
            page: 1,
            itemsPerPage: 10
        },
        resend: {
            playerid: '',
            slot: '',
            platform: ''
        },
        successNotification: false,
        notificationMessage: '',
        notificationType: "success",
        showNotification: false,
        totalItems: 0,
        rowsPerPage: 10,
    },
    mutations: {
        closeDialog: (state) => {
            state.editedItem = {}
            state.editedIndex = -1
            state.errors = [];
            state.dialog = false
            state.mode = "LIST"
        },
        loading: (state, loading) => state.loading = loading,
        setMode: (state, mode) => state.mode = mode,
        setFilter: (state, payload) => {
            state.filter = Object.assign({}, payload.filter)
        },
        setItems: (state, items) => state.items = items,
        setTotalItems: (state, total) => state.totalItems = total,
        showDialog: (state, show) => state.dialog = show,
        showFilter: (state, payload) => state.dialogFilter = payload.show,
        setPage: (state, payload) => {
            state.pagination.page = payload.page;
            state.pagination.itemsPerPage = payload.itemsPerPage
        },
        showDialogEditAndResend: (state, payload) => {
            state.dialogEditAndResend = payload.show,
            state.editedItem = payload.item,
            state.mode = payload.mode
            state.resend = Object.assign({}, defaultResend)
            console.log('limpou o resend')
        },
        showDialogReleaseAutoAttendance: (state, payload) => {
            state.dialogReleaseAutoAttendance = payload.show,
            state.editedItem = payload.item
            state.mode = payload.mode
        },
        showDialogInfo: (state, payload) => {
            state.dialogInfo = payload.show,
            state.editedItem = payload.item
            state.mode = payload.mode
        },
        showLoading: (state, show) => state.loading = show,
        showNotification: (state, payload) => {
            state.showNotification = payload.show,
                state.notificationMessage = payload.message,
                state.notificationType = payload.type
        },
        updateField
    },
    getters: {
        getField,
    },
    actions: {
        blockAutoAttendance: ({ commit, dispatch, state }) => {
            commit('showLoading', true)
            axios.put(`${endPoints.auto_attendance}/block/${state.editedItem.id}`).then(() => {
                commit('showLoading', false)
                commit('showDialogReleaseAutoAttendance', { show: false, item: {}, mode: 'LIST'})
                commit('showNotification', { show: true, message: 'Auto atendimento liberado com sucesso!', type: 'success' })
                dispatch('getItems', { resetPage: false })
            }).catch(() => {
                commit('showLoading', false)
                commit('showNotification', { show: true, message: 'Erro ao liberar auto atendimento!', type: 'error' })
            })
        },
        checkPayment: ({ commit, dispatch }, payload) => {
            commit('showLoading', true)
            axios.put(`${endPoints.auto_attendance}/check_payment/${payload.item.id}`).then(() => {
                commit('showLoading', false)
                commit('showNotification', { show: true, message: 'Auto atendimento atualizado com sucesso!', type: 'success' })
                dispatch('getItems', { resetPage: false })
            }).catch((error) => {
                let message = error?.response?.data?.message || 'Pagamento ainda não foi confirmado!'
                commit('showLoading', false)
                commit('showNotification', { show: true, message: message, type: 'error' })
            })
        },
        getItems: ({ commit, state }, payload) => {
            commit('loading', true)
            let url = endPoints.auto_attendance;
            let filter = state.filter;
            if (payload.resetPage === true) {
                url += '?page=' + 1 + '&' + 'limit=' + 10;
                commit('setPage', { page: 1, itemsPerPage: 10 })
            } else {
                url += '?page=' + state.pagination.page + '&' + 'limit=' + state.pagination.itemsPerPage;
            }

            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
                    if (field == "start_date" || field == "end_date") {
                        var e = new Date(filter[field])
                        url += "&" + field + "=" + formatDate(e) + " " + e.toLocaleTimeString()
                    } else {
                        url += "&" + field + "=" + filter[field]
                    }
                }
            });

            if (state.pagination && state.pagination.sortBy && state.pagination.sortBy.length > 0) {
                url += "&sort_by=" + state.pagination.sortBy[0]
                url += "&sort_desc=" + state.pagination.sortDesc[0]
            }

            axios.get(url).then(items => {
                commit('showFilter', { show: false })

                commit('setItems', items.data.data)
                commit('setTotalItems', items.data.total)
                commit('loading', false)
            });
        },
        getItemsNoPage: ({ state }) => {
            let url = endPoints.agents;
            let filter = state.filter;
            url += '?page=' + '1' + '&' + 'itemsPerPage=' + '99999';

            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined) {
                    url += "&" + field + "=" + filter[field]
                }
            });
            return axios.get(url).then(items => {
                return items.data.data
            });
        },
        resendAutoAttendance: ({ commit, dispatch, state }) => {
            commit('showLoading', true)
            axios.put(`${endPoints.auto_attendance}/resend/${state.editedItem.id}`, state.resend).then(() => {
                commit('showLoading', false)
                commit('showDialogEditAndResend', { show: false, item: {}, mode: 'LIST'})
                commit('showNotification', { show: true, message: 'Auto atendimento reenviado com sucesso!', type: 'success' })
                dispatch('getItems', { resetPage: false })
            }).catch((error) => {
                console.log('vim aqui')
                let message = error?.response?.data?.message || 'Erro ao reenviar o auto atendimento!'
                commit('showLoading', false)
                commit('showNotification', { show: true, message: message, type: 'error' })
            })
        },
        releaseAutoAttendance: ({ commit, dispatch, state }) => {
            commit('showLoading', true)
            axios.put(`${endPoints.auto_attendance}/release/${state.editedItem.id}`).then(() => {
                commit('showLoading', false)
                commit('showDialogReleaseAutoAttendance', { show: false, item: {}, mode: 'LIST'})
                commit('showNotification', { show: true, message: 'Auto atendimento liberado com sucesso!', type: 'success' })
                dispatch('getItems', { resetPage: false })
            }).catch((error) => {
                let message = error?.response?.data?.message || 'Erro ao liberar o auto atendimento!'
                commit('showLoading', false)
                commit('showNotification', { show: true, message: message, type: 'error' })
            })
        }
    }
}
