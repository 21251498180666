import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../../config/endPoints';

export default {
    namespaced: true,
    state: {
        dialogFilter: false,
        items: [],
        filter: {
            club: '',
            id_pppoker: '',
            nick: '',
            start: '',
            end: '',
            master: ''
        },
        loading: false,
        pagination: {
            page: 1,
            itemsPerPage: 10
        },
        totalItems: 0
    },
    mutations: {
        loading: (state, payload) => state.loading = payload.loading,
        setItems: (state, payload) => state.items = payload.items,
        setTotalItems: (state, total) => state.totalItems = total,
        showFilter: (state, payload) => state.dialogFilter = payload.show,
        updateField,
    },
    getters: {
        getField,
    },
    actions: {
        getItems: ({ commit, state }, payload) => {
            commit('loading', { loading: true })
            let url = endPoints.agents_closure + "/report_closure"
            let filter = state.filter

            if (payload.resetPage === true) {
                url += '?page=' + 1 + '&' + 'itemsPerPage=' + 10;
                commit('setPage', { page: 1, itemsPerPage: 10 })
            } else {
                url += '?page=' + state.pagination.page + '&' + 'itemsPerPage=' + state.pagination.itemsPerPage;
            }

            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined) {
                    url += "&" + field + "=" + filter[field]
                }
            });
            if (state.pagination && state.pagination.sortBy && state.pagination.sortBy.length > 0) {
                url += "&sort_by=" + state.pagination.sortBy[0]
                url += "&sort_desc=" + state.pagination.sortDesc[0]
            }
            axios.get(url).then(response => {
                commit('showFilter', { show: false })
                commit('setItems', { items: response.data.data })
                commit('loading', { loading: false })
                commit('setTotalItems', response.data.total)
            }).catch(() => {
                commit('showFilter', { show: false })
                commit('setItems', { items: [] })
                commit('loading', { loading: false })
                commit('setTotalItems', 0)
            });
        },
        getItemsNoPage: ({ commit, state }) => {
            commit('loading', { loading: true })
            let url = endPoints.agents_closure + "/report_closure"
            let filter = state.filter
            url += '?page=' + '1' + '&' + 'itemsPerPage=' + '99999';

            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined) {
                    url += "&" + field + "=" + filter[field]
                }
            });
            if (state.pagination && state.pagination.sortBy && state.pagination.sortBy.length > 0) {
                url += "&sort_by=" + state.pagination.sortBy[0]
                url += "&sort_desc=" + state.pagination.sortDesc[0]
            }

            return axios.get(url).then(items => {
                commit('loading', { loading: false })
                return items.data.data
            }).catch(() => {
                commit('loading', { loading: false })
            });
        },
    }
}