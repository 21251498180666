<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline text-center justify-center">Filtro</span>

        <v-col class="text-right">
          <v-btn
            v-show="filtered"
            color="white"
            class="ma-2"
            @click="clearFilter"
            right
            small
            depressed
          >
            <v-icon color="black">mdi-filter-remove</v-icon>
          </v-btn>
        </v-col>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm6 md6>
                <v-text-field
                  required
                  outlined
                  label="ID Suprema *"
                  v-model="id_pppoker"
                  v-validate="'required:IDSuprema,id_pppoker'"
                  data-vv-name="id_pppoker"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-text-field
                  required
                  label="Valor"
                  v-model="value"
                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-datetime-picker
                  v-model="start"
                  date-format="dd/MM/yyyy"
                  clearText="Limpar"
                  okText="Confirmar"
                  :time-picker-props="timeProps"
                  label="De"
                  :textFieldProps="{ outlined: true }"
                >
                  <template slot="dateIcon">
                    <v-icon>mdi-calendar</v-icon>
                  </template>
                  <template slot="timeIcon">
                    <v-icon>mdi-clock</v-icon>
                  </template>
                </v-datetime-picker>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-datetime-picker
                  v-model="end"
                  date-format="dd/MM/yyyy"
                  clearText="Limpar"
                  okText="Confirmar"
                  label="Até"
                  :time-picker-props="timeProps"
                  :textFieldProps="{ outlined: true }"
                >
                  <template slot="dateIcon">
                    <v-icon>mdi-calendar</v-icon>
                  </template>
                  <template slot="timeIcon">
                    <v-icon>mdi-clock</v-icon>
                  </template>
                </v-datetime-picker>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-col class="text-center">
          <v-btn color="success" outlined tile type="submit" @click="filter()"
            >Filtrar</v-btn
          >
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "PlayerHistoryFilter",
  computed: {
    ...mapState("player_history", ["filtered"]),
    ...mapFields("player_history", [
      "extend_url",
      "current_tab",
      "filter.id_pppoker",
      "filter.value",
      "filter.start",
      "filter.end",
    ]),
  },
  data: () => ({
    valid: false,
    showStart: false,
    showEnd: false,
    start_formatted: "",
    end_formatted: "",
    timeProps: {
      format: "24hr",
    },
  }),
  methods: {
    ...mapMutations("player_history", ["showFilter"]),
    ...mapActions("player_history", ["getItems", "clearFilter"]),
    filter() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.getItems({
            end_point: this.current_tab,
            extend_url: this.extend_url,
            resetPage: true,
          });
        }
      });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    },
    clearStart() {
      this.start = "";
      this.start_formatted = "";
    },
    clearEnd() {
      this.end = "";
      this.end_formatted = "";
    },
    setStart(date) {
      this.start = date;
      this.showStart = false;
      this.start_formatted = this.formatDate(date);
    },
    setEnd(date) {
      this.end = date;
      this.showEnd = false;
      this.end_formatted = this.formatDate(date);
    },
  },
};
</script>
