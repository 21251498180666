<template>
  <div>
    <div @drop.prevent="addDropFile" @dragover.prevent>
      <v-card>
        <v-card-title>
          <span class="headline">Gestor</span>

          <v-spacer></v-spacer>
          <v-progress-circular
            v-if="loading"
            indeterminate
            class="text-right"
            color="primary"
            size="24"
          ></v-progress-circular>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm6 md6>
                  <v-autocomplete
                    label="Clube *"
                    :items="slots"
                    v-model="club"
                    :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                    :error-messages="errors.collect('club')"
                    v-validate="'required:Clube,club'"
                    item-text="name"
                    item-value="id"
                    data-vv-name="club"
                    outlined
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-select
                    label="Tipo de Gestor *"
                    :items="types_manager"
                    v-model="type_manager"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('type_manager')"
                    v-validate="'required:Tipo de Gestor,type_manager'"
                    item-text="description"
                    item-value="type"
                    data-vv-name="type_manager"
                    outlined
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Id Suprema"
                    v-model="id_pppoker"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('id_pppoker')"
                    data-vv-name="id_pppoker"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Nick *"
                    v-model="nick"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('nick')"
                    v-validate="'required:Nick,nick'"
                    data-vv-name="nick"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-select
                    label="Cobrar Transações *"
                    :items="types_transactions_charged"
                    v-model="transactions_charged"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('transactions_charged')"
                    v-validate="'required:Tipo de Gestor,transactions_charged'"
                    item-text="description"
                    item-value="type"
                    data-vv-name="transactions_charged"
                    outlined
                  ></v-select>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-col>
            <v-btn text @click="closeDialog">{{ mode == 'VIEW' ? 'Voltar' : 'Cancelar'}}</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn text type="submit" right @click="saveManager">Salvar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import debounce from '../../../util/debounce.js' 

export default {
  name: "ManagerForm",
  computed: {
    ...mapState("slots", [
      "slots"
    ]),
    ...mapState("managers", ["contacts", "item", "loading", "mode"]),
    ...mapFields("managers", [
      "editedItem.club",
      "editedItem.id_pppoker",
      "editedItem.nick",
      "editedItem.type_manager",
      "editedItem.transactions_charged"
    ])
  },
  created() {
    this.$validator.extend("required", {
      getMessage: (field, [label]) => label + " deve ser informado",
      validate: (_, [, field]) => {
        if (this[field] || this[field] === 0) {
          return true;
        } else {
          return false;
        }
      },
    });
  },
  mixins: [debounce],
  mounted() {
    this.getContacts = this.debounce(this.getContacts, 500)
  },
  methods: {
    ...mapActions("managers", ["closeDialog", "getContacts", "save"]),
    getRakesDeal(type) {
      let deals_type = ["Simples", "Variável"];
      if (deals_type[type]) {
        return deals_type[type];
      }
      return "";
    },
    getTextContact(item) {
      if(item.name) {
        if(item.name.length == 1) {
          return item.name
        }
        
        var text = ""
        item.name.forEach((name) => {
          text += name + " | "
        })
        return text
      }
      else {
        return text
      }
    },
    getTextAgent(item) {
      if(item) {
        if(item.id_pppoker) {
          return item.id_pppoker + " | " + item.nick + " | " + item.club
        } else {
          return item.nick
        }
      }
      return ''
    },
    saveManager() {
      this.$validator.validateAll().then(valid => {
        if (valid) {
          this.save();
        }
      });
    }
  },
  data: () => ({
    types_manager: [
      { type: 0, description: "Interno" },
      { type: 1, description: "Externo" },
    ],
    types_transactions_charged: [
      { type: 0, description: "Não Cobrar" },
      { type: 1, description: "Cobrar" },
    ],
    rules: {
      required: value => !!value || "Required.",
      counter: value => value.length <= 20 || "Max 20 characters"
    },
    searchAgent: '',
    searchContact: '',
    valid: false
  }),
  watch: {},
};
</script>
