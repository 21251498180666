import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../config/endPoints';

const defaultItem = {
    name: '',
    profile_code: '',
};

export default {
    namespaced: true,
    state: {
        agents: [],
        contacts: [],
        dialog: false,
        dialogDelete: false,
        dialogFilter: false,
        dialogReceipt: false,
        expense_types: [],
        editedItem: {},
        filter: {
            name: '',
            profile_code: '',
        },
        instances: [],
        items: [],
        itemToDelete: {},
        loading: false,
        mode: 'LIST',
        overlay: false,
        page: 1,
        pagination: {
            page: 1,
            itemsPerPage: 10
        },
        successNotification: false,
        notificationMessage: '',
        notificationType: "success",
        receipt_show: '',
        selectedContact: {},
        showNotification: false,
        totalItems: 0,
        rowsPerPage: 10,
        users_name: [],
        users: []
    },
    mutations: {
        add: (state, payload) => {
            state.mode = "ADD"
            defaultItem.date_picker = new Date()
            state.editedItem = Object.assign({}, defaultItem)
            state.screen = payload.screen
            if (state.screen != "messages") {
                state.dialog = true
            }
        },
        edit: (state, payload) => {
            payload.item.date_picker = new Date(payload.item.date)
            state.editedItem = Object.assign({}, payload.item)
            state.editedIndex = state.instances.indexOf(payload.item)
            state.dialog = true
            state.mode = "EDIT"
        },
        closeDialog: (state) => {
            state.editedItem = {}
            state.editedIndex = -1
            state.errors = [];
            state.dialog = false
            state.mode = "LIST"
        },
        loading: (state, payload) => state.loading = payload.show,
        setMode: (state, mode) => state.mode = mode,
        setItems: (state, items) => state.items = items,
        setItemToDelete: (state, item) => state.itemToDelete = item,
        setTotalItems: (state, total) => state.totalItems = total,
        setRowsPerPage: (state, rowsPerPage) => state.rowsPerPage = rowsPerPage,
        showDialog: (state, show) => state.dialog = show,
        setPage: (state, payload) => {
            state.pagination.page = payload.page;
            state.pagination.itemsPerPage = payload.itemsPerPage
        },
        setAgents: (state, payload) => {
            state.agents = payload.agents
        },
        setContacts: (state, payload) => {
            state.contacts = payload.contacts
        },
        setScreen: (state, payload) => state.screen = payload.screen,
        setUsersName: (state, payload) => {
            let users_name = []
            payload.users.forEach(user => {
                users_name[user.id] = user.name
            });
        },
        setUsers: (state, payload) => {
            state.users = payload.users
        },
        setDialogDelete: (state, show) => state.dialogDelete = show,
        setDialogReceipt: (state, payload) => state.dialogReceipt = payload.show,
        setInstances: (state, payload) => state.instances = payload.instances,
        showFilter: (state, payload) => state.dialogFilter = payload.show,
        showErrorNotification: (state, show) => state.errorNotification = show,
        showSuccessNotification: (state, show) => state.successNotification = show,
        showLoading: (state, show) => state.loading = show,
        showNotification: (state, payload) => {
            state.showNotification = payload.show,
                state.notificationMessage = payload.message,
                state.notificationType = payload.type
        },
        showOverlay: (state, payload) => {
            state.overlay = payload.show
        },
        setReceipt: (state, payload) => state.receipt_show = payload.receipt,
        viewItem(state, item) {
            if (item.contact_id) {
                var contact = { _id: item.contact_id, name: [item.contact_name] }
                state.selectedContact = contact
                state.contacts = [contact]
            } else {
                state.contacts = []
                state.selectedContact = {}
            }

            state.editedItem = item
            state.editedItem.date_picker = new Date(item.date)
            state.editedIndex = state.items.indexOf(item)
            state.dialog = true
            state.mode = "VIEW"
        },
        updateField
    },
    getters: {
        getField,
    },
    actions: {
        closeDialog: (({ commit, state }) => {
            state.editedItem = {}
            if (state.screen != "messages") {
                commit('message/setSelectedMessages', { messages: [] }, { root: true })
                commit('showDialog', false)
            } else {
                commit('message/showDialogFail', { show: false }, { root: true })
            }
            setTimeout(() => {
                commit('closeDialog')
                commit('showErrorNotification', false)
                commit('showSuccessNotification', false)
            }, 300);
        }),
        closeDeleteDialog: ({ commit }) => {
            commit('setDialogDelete', false)
            commit('setItemToDelete', {})
        },
        deleteItem: ({ dispatch, commit, state }) => {
            commit('loading', true)
            axios.delete(endPoints.profiles + "/deleteProfile/" + state.itemToDelete.id, state.itemToDelete).then(() => {
                dispatch('closeDeleteDialog')
                commit('loading', false)
                let notification = {
                    show: true,
                    message: "Perfil de Acesso excluido com sucesso",
                    type: "success"
                }
                commit('showNotification', notification)
                return dispatch('getItems', { resetPage: false });
            });
        },
        getItems: ({ commit, state }, payload) => {
            commit('loading', true)
            let url = endPoints.profiles;
            let filter = state.filter;
            if (payload.resetPage === true) {
                url += '?page=' + 1 + '&' + 'itemsPerPage=' + 10;
                commit('setPage', { page: 1, itemsPerPage: 10 })
            } else {
                url += '?page=' + state.pagination.page + '&' + 'itemsPerPage=' + state.pagination.itemsPerPage;
            }


            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined) {
                    url += "&" + field + "=" + filter[field]
                }
            });

            axios.get(url).then(items => {
                commit('setTotalItems', items.data.total)
                commit('loading', false)
                commit('setItems', items.data.data)
            });
        },
        getItemsNoPage: ({ state }) => {
            let url = endPoints.profiles;
            let filter = state.filter;
            url += '?page=' + '1' + '&' + 'itemsPerPage=' + '99999';

            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined) {
                    url += "&" + field + "=" + filter[field]
                }
            });
            return axios.get(url).then(items => {
                return items.data.data
            });
        },
        showDeleteDialog: ({ commit }, item) => {
            commit('setDialogDelete', true)
            commit('setItemToDelete', item)
        },
        save: async({ dispatch, commit, state }) => {
            var url = endPoints.profiles;
            let func = '';
            let data = state.editedItem

            commit('showOverlay', { show: true })
            if (state.mode == 'ADD') {
                func = axios.post;
                url = endPoints.profiles;
            } else {
                func = axios.put;
                url += '/updateProfile/' + data.id;
            }

            func(url, data).then(
                () => {
                    dispatch('getItems', { resetPage: true })
                    let notification = {
                        show: true,
                        message: "Perfil de Acesso salvo com sucesso",
                        type: "success"
                    }
                    commit('loading', { show: false })
                    commit('showOverlay', { show: false })
                    if (state.screen != 'messages') {
                        commit('showNotification', notification)
                        commit('closeDialog')
                        commit('showOverlay', { show: false })
                    } else {
                        commit('closeDialog')
                        commit('message/showDialogFail', { show: false }, { root: true })
                        commit('message/showNotification', notification, { root: true })                        
                    }
                },
                error => {
                    let notification = {
                        show: true,
                        message: error.response.data.message,
                        type: "error"
                    }
                    commit('showNotification', notification)
                    commit('showOverlay', { show: false })
                }
            );
        },
        showReceipt: ({ commit }, payload) => {
            let url = endPoints.receipt;

            url += '/' + payload.item.id + '?type=1'
            commit('showOverlay', { show: true })
            axios.get(url).then(r => {
                commit('showOverlay', { show: false })
                commit('setReceipt', { receipt: r.data.file })
                commit('setDialogReceipt', { show: true })
            }).catch(() => {
                let notification = {
                    show: true,
                    message: "Comprovante não encontrado",
                    type: "error"
                }
                commit('showNotification', notification)
                commit('showOverlay', { show: false })
            });
        },
        viewItem({ dispatch }, payload) {
            dispatch('profile/getPermissionsProfileSelected', { id: payload.item.id }, { root: true })
            dispatch('menu/openLink', '/profiles/' + payload.item.id, { root: true })
        }
    }
}