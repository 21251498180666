<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline text-center justify-center">Filtro</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form">
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm6 md6>
                <v-menu
                  v-model="showStart"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="start_formatted"
                      label="De"
                      outlined
                      readonly
                      append-icon="mdi-close"
                      @click:append="clearStart()"
                      persistent-hint
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="pt-br"
                    v-model="start"
                    no-title
                    @input="(e) => setStart(e)"
                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-menu
                  v-model="showEnd"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="end_formatted"
                      label="Até"
                      outlined
                      readonly
                      append-icon="mdi-close"
                      @click:append="clearEnd()"
                      persistent-hint
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker locale="pt-br" v-model="end" no-title @input="(e) => setEnd(e)"></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field required label="Valor" v-model="value" outlined></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field required label="tx_id" v-model="tx_id" outlined></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field required label="endToEndId" v-model="endToEndId" outlined></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-select
                  :clearable="true"
                  clear-icon="mdi-close"
                  label="Utilizado"
                  :items="useds"
                  v-model="used"
                  item-text="description"
                  item-value="id"
                  outlined
                ></v-select>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-select
                  :clearable="true"
                  clear-icon="mdi-close"
                  label="Tipo"
                  :items="types"
                  v-model="type"
                  item-text="description"
                  item-value="id"
                  outlined
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-select
                  :clearable="true"
                  clear-icon="mdi-close"
                  label="Status"
                  :items="possible_status"
                  v-model="status"
                  item-text="description"
                  item-value="id"
                  outlined
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-select
                  :clearable="true"
                  clear-icon="mdi-close"
                  label="Tipo Transação"
                  :items="types_transaction"
                  v-model="type_transaction"
                  item-text="description"
                  item-value="id"
                  outlined
                  @change="(value) => {if(!value) id_transaction = ''}"
                ></v-select>
              </v-flex>
              <v-flex  xs12 sm6 md6>
                <v-text-field :readonly="!(type_transaction || type_transaction === 0)" required label="Id Transação" v-model="id_transaction" outlined></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field required label="CPF/₢NPJ" v-model="client_key" outlined></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn text @click="showFilter({show: false})">Cancelar</v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn text type="submit" right @click="filter">Filtrar</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
export default {
  name: "ExpensesFilter",
  computed: {
    ...mapFields("pix", [
      "filter.used",
      "filter.start",
      "filter.end",
      "filter.value",
      "filter.endToEndId",
      "filter.tx_id",
      "filter.type",
      "filter.type_transaction",
      "filter.id_transaction",
      "filter.status",
      "filter.client_key"
    ]),
  },
  data: () => ({
    showStart: false,
    showEnd: false,
    start_formatted: "",
    end_formatted: "",
    useds: [
      { id: 0, description: "Não" },
      { id: 1, description: "Sim" }
    ],
    types: [
      { id: 1, description: "Entrada" },
      { id: 2, description: "Saída" }
    ],
    possible_status: [
      { id: 1, description: "Pendente" },
      { id: 2, description: "Concluido" },
      { id: 3, description: "Erro" },
    ],
    types_transaction: [
      { id: 1, description: "Saque" },
      { id: 2, description: "Despesa" },
      { id: 3, description: "Auto Atendimento" },
      { id: 4, description: "Deposito Cliente" },
      { id: 5, description: "Saque Cliente" },
      { id: 6, description: "Depósito" },
    ],
    searchInput: "",
  }),
  methods: {
    ...mapMutations("pix", ["showFilter"]),
    ...mapActions("pix", ["getItems"]),
    clearStart() {
        this.start = ''
        this.start_formatted = ''
    },
    clearEnd() {
        this.end = ''
        this.end_formatted = ''
    },
    filter() {
      this.getItems({ resetPage: true });
      this.showFilter({ show: false });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    },
    setStart(date) {
      this.start = date;
      this.showStart = false;
      this.start_formatted = this.formatDate(date);
    },
    setEnd(date) {
      this.end = date;
      this.showEnd = false;
      this.end_formatted = this.formatDate(date);
    },
  },
  watch: {},
};
</script>

<style>
</style>