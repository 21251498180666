<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline text-center justify-center">Bots</span>
      </v-card-title>

      <v-data-table
      :headers="headers"
      :items="bots"
      :item-class= "getClassColor"   
      no-data-text="Nenhum bot encontrado"
      class="table-margin"
    >
      <template v-slot:[`item.status`]="{ item }">
        <span>{{ getStatus(item.is_active) }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{on, attrs}">
            <v-icon small v-on="on" v-bind="attrs" class="mr-2" v-if="item.is_active != 1" @click="activateBot({ item, is_active: 1 })">
                mdi-robot
            </v-icon>
            <v-icon small v-on="on" v-bind="attrs" class="mr-2" v-else @click="activateBot({ item, is_active: 0 })">
                mdi-robot-off
            </v-icon>
          </template>
          <span v-if="item.is_active != 1">Ativar BOT</span>
          <span v-else>Desativar BOT</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{on, attrs}">
            <v-icon small v-on="on" v-bind="attrs" class="mr-2" v-if="item.is_active != 0" @click="activateBot({ item, is_active: 2 })">
                mdi-robot-confused
            </v-icon>
          </template>
          <span>Instabilidade</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{on, attrs}">
            <v-icon small v-on="on" v-bind="attrs" class="mr-2" v-if="item.action_bot != 3" @click="updateActionBot({ item, action_bot: 3 })">
                mdi-hammer-wrench
            </v-icon>
            <v-icon small v-on="on" v-bind="attrs" class="mr-2" v-else @click="updateActionBot({ item, action_bot: item.id == 4 || item.id == 9 ? 5 : 0})">
                mdi-wrench-clock
            </v-icon>
          </template>
          <span v-if="item.action_bot != 3">Ativar Manutenção</span>
          <span v-else>Desativar Manutenção</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{on, attrs}">
            <v-icon small v-on="on" v-bind="attrs" class="mr-2" v-if="item.action_bot == 0 && item.is_active == 1" @click="updateActionBot({ item, action_bot: 1 })">
                mdi-map-search
            </v-icon>
          </template>
          <span>Atualizar Coordenadas</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{on, attrs}">
            <v-icon small v-on="on" v-bind="attrs" class="mr-2" v-if="item.action_bot == 0 && item.is_active == 1" @click="updateActionBot({ item, action_bot: 2 })">
                mdi-restart
            </v-icon>
          </template>
          <span>Reiniciar Aplicativo</span>
        </v-tooltip>
      </template>
    </v-data-table>

      <v-card-actions>
        <v-col>
          <v-btn text @click="showBotDialog({ show: false })">Voltar</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "BotsGrid",
  created() {
    this.getBots();
  },
  computed: {
      ...mapState("bots", ["bots"])
  },
  data: () => ({
    headers: [
        { text: "BOT", value: "name", sortable: false },
        { text: "Status", value: "status", sortable: false },
        { text: "Ações", value: "actions", sortable: false, align: "center" },
    ],
  }),
  methods: {
    getStatus(status) {
      if(status == 0) {
        return 'Desativado'
      } else if(status == 1) {
        return 'Ativo'
      } else if(status == 2){
        return 'Instável'
      }
    },
    getClassColor(item) {
      if(item.is_active == 0) {
          return 'row-red'
      } else if(item.is_active == 1) {
        return 'row-blue'
      } else if(item.is_active == 2) {
        return 'row-yellow-schedule'
      }
    },
    ...mapMutations("bots", ["showBotDialog"]),
    ...mapActions("bots", ["getBots", "activateBot", "updateActionBot"])
  }
};
</script>

<style></style>
