import axios from "axios";
import { getField, updateField } from "vuex-map-fields";
import endPoints from "../../../config/endPoints";

export default {
  namespaced: true,
  state: {
    itemToDelete: {},
    collaborator: {},
    editedItem: {},
    dialog: false,
    loading_collaborator: false,
    equipments: [],
    mode: "LIST",
    page: 1,
    totalEquipments: 0,
  },
  mutations: {
    loading_collaborator: (state, payload) => (state.loading_collaborator = payload.loading),
    setCollaborator: (state, payload) => (state.collaborator = payload.collaborator),
    setEquipments: (state, payload) => (state.equipments = payload.equipments),
    setTotalEquipments: (state, payload) =>
      (state.totalEquipments = payload.total),
    updateField,
  },
  getters: {
    getIdCollaborator: (state) => {
      return state.collaborator.id;
    },
    getField,
  },
  actions: {
    getCollaborator: ({ commit }, payload) => {
      commit("loading_collaborator", { loading: true });
      let url = endPoints.collaborators + "/" + payload.collaborator_id;
      axios.get(url).then((response) => {
        commit("setCollaborator", { collaborator: response.data });
        commit("loading_collaborator", { loading: false });
      });
    },
    goBack: ({ dispatch }) => {
      dispatch("menu/openLink", "/collaborators", { root: true });
    },
  },
};
