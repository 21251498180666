<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline text-center justify-center">Filtro</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form">
          <v-container grid-list-md>
            <v-layout wrap>

              <v-flex xs12 sm6 md6>
                <v-menu
                  v-model="showStart"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="start_formatted"
                      label="De"
                      outlined
                      readonly
                      append-icon="mdi-close"
                      @click:append="clearStart()"
                      persistent-hint
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="pt-br"
                    v-model="start"
                    no-title
                    @input="(e) => setStart(e)"
                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-menu
                  v-model="showEnd"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="end_formatted"
                      label="Até"
                      outlined
                      readonly
                      append-icon="mdi-close"
                      @click:append="clearEnd()"
                      persistent-hint
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker locale="pt-br" v-model="end" no-title @input="(e) => setEnd(e)"></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-autocomplete
                  clearable
                  deletable-chips
                  v-model="user_id"
                  :no-filter="true"
                  :items="users"
                  outlined
                  chips
                  small-chips
                  no-data-text="Nenhum usuario encontrado"
                  item-text="name"
                  item-value="id"
                  placeholder="Digite aqui o nome do usuario"
                  label="Usuario"
                  :search-input.sync="searchInput"
                ></v-autocomplete>
              </v-flex>                

              <v-flex xs12 sm6 md6>
                <v-autocomplete
                  clearable
                  deletable-chips
                  v-model="id_agent"
                  :no-filter="true"
                  :items="agents"
                  outlined
                  chips
                  small-chips
                  no-data-text="Nenhum agente encontrado"
                  item-text="nick"
                  item-value="id"
                  placeholder="Digite aqui o nick do agente"
                  label="Agente"
                  :search-input.sync="searchAgent"
                ></v-autocomplete>
              </v-flex>

              <v-flex xs12 sm6 md12>
                <v-textarea
                  required
                  label="Descrição *"
                  v-model="description"
                  :error-messages="errors.collect('description')"
                  v-validate="'required'"
                  data-vv-name="description"
                  outlined
                  :rows="3"
                ></v-textarea>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-text-field
                  required
                  label="Valor *"
                  v-model="value"
                  :error-messages="errors.collect('value')"
                  v-validate="'required:Valor,value|decimal:2'"
                  data-vv-name="value"
                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-select
                  label="Status *"
                  required
                  :items="possible_status"
                  v-model="status"
                  :error-messages="errors.collect('status')"
                  v-validate="'required'"
                  data-vv-name="status"
                  outlined
                  item-text="text"
                  item-value="value"
                >
                </v-select>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-select
                  label="Tipo *"
                  required
                  :items="possible_type"
                  v-model="type"
                  :readonly="this.mode == 'VIEW'"
                  :error-messages="errors.collect('type')"
                  v-validate="'required:Type,type'"
                  data-vv-name="type"
                  outlined
                  item-text="text"
                  item-value="value"
                >
                </v-select>
              </v-flex>       

              <v-flex xs12 sm6 md6>
                <v-select
                  label="Saldo Positivo *"
                  required
                  :items="possible_balance"
                  v-model="balance"
                  :error-messages="errors.collect('balance')"
                  v-validate="'required'"
                  data-vv-name="balance"
                  outlined
                  item-text="text"
                  item-value="value"
                >
                </v-select>
              </v-flex>       

            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn text @click="showFilter({show: false})">Cancelar</v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn text type="submit" right @click="filter">Filtrar</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import debounce from "../../../util/debounce.js";

export default {
  name: "FailsFilter",
  computed: {
    ...mapState("fail", ["agents", "find_agents", "users"]),
    ...mapFields("fail", [
      "filter.status",
      "filter.balance",
      "filter.type",
      "filter.id",
      "filter.id_agent",
      "filter.description",
      "filter.start",
      "filter.end",
      "filter.user_id",
      "filter.value",
      // ...
    ]),
  },
  data: () => ({
    showStart: false,
    showEnd: false,
    start_formatted: "",
    end_formatted: "",
    searchAgent: '',
    searchInput: '',
    possible_type: [
      {
        text: "Adiantamento rakeback",
        value: 0,
      },
      {
        text: "Anotados",
        value: 1,
      },
      {
        text: "Atenção",
        value: 2,
      },
      {
        text: "Comunicação",
        value: 3,
      },
      {
        text: "Demora",
        value: 4,
      },
      {
        text: "Fechamento de Caixa",
        value: 5,
      },
      {
        text: "Interpretação",
        value: 6,
      },
      {
        text: "Sistema",
        value: 7,
      },
    ],
    possible_status: [
      {
        text: "Não Prejuízo",
        value: 0,
      },
      {
        text: "Pendente",
        value: 1,
      },
      {
        text: "Recuperado",
        value: 2,
      },
    ],
    possible_balance: [
      {
        text: "Sim",
        value: 0,
      },
      {
        text: "Não",
        value: 1,
      }
    ],
  }),
  mixins: [debounce],
  mounted() {
    this.getAgents = this.debounce(this.getAgents, 500)
    this.getAttendants = this.debounce(this.getAttendants, 500); 
  },
  methods: {
    ...mapMutations("fail", ["showFilter"]),
    ...mapActions("fail", [
      "getItems",
      "getAttendants",
      "getUsers",
      "getAgents",
      "closeDialog",
      "save",
    ]),
    clearStart() {
        this.start = ''
        this.start_formatted = ''
    },
    clearEnd() {
        this.end = ''
        this.end_formatted = ''
    },
    filter() {
      this.getItems({ resetPage: true });
      this.showFilter({ show: false });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    },
    getTextUser(item) {
      if(item.account) {
        return item.name + ' | ' + item.account
      } else {
        return item.name
      }
    },
    setStart(date) {
      this.start = date;
      this.showStart = false;
      this.start_formatted = this.formatDate(date);
    },
    setEnd(date) {
      this.end = date;
      this.showEnd = false;
      this.end_formatted = this.formatDate(date);
    },
  },
  watch: {
    searchInput(val) {
      if (val) {
        var payload = {
          search: val,
        };
        this.getAttendants(payload);
      }
    },
    searchAgent (val) {
      var payload = {
        search: val
      }
      this.getAgents(payload)
    }
  }
};
</script>

<style>
</style>