export const defaultErrorNotification = (message = "Houve um erro...") => {
    return {
      show: true,
      message: message,
      type: "error",
    };
};

export const defaultSuccessNotification = (message = "Sucesso na operação!") => {
    return {
        show: true,
        message: message,
        type: "success",
    };
};