<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline text-center justify-center">Filtro</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm6 md6>
                <v-text-field
                  dense
                  label="Nome"
                  v-model="name"
                  :clearable="true"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm3 md3>
                <v-menu
                  v-model="showStart"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="start_formatted"
                      label="De"
                      outlined
                      dense
                      readonly
                      append-icon="mdi-close"
                      @click:append="clearStart()"
                      persistent-hint
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="pt-br"
                    v-model="start_date"
                    no-title
                    @input="(date) => setStart(date)"
                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex xs12 sm3 md3>
                <v-menu
                  v-model="showEnd"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="end_formatted"
                      label="Até"
                      outlined
                      dense
                      readonly
                      append-icon="mdi-close"
                      @click:append="clearEnd()"
                      persistent-hint
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="pt-br"
                    v-model="finish_date"
                    no-title
                    @input="(e) => setEnd(e)"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field
                  dense
                  label="Email"
                  v-model="email"
                  :clearable="true"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-select
                  :clearable="true"
                  clear-icon="mdi-close"
                  label="Pode ser chamado novamente"
                  :items="possibles"
                  dense
                  v-model="called_again"
                  item-text="description"
                  item-value="id"
                  outlined
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-select
                  :clearable="true"
                  clear-icon="mdi-close"
                  label="Próxima Etapa"
                  dense
                  :items="steps.slice(0, 4)"
                  v-model="next_step"
                  item-text="description"
                  item-value="id"
                  outlined
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-select
                  :clearable="true"
                  clear-icon="mdi-close"
                  label="Reprovado na"
                  dense
                  :items="steps.slice(0, 4)"
                  v-model="failed_in"
                  item-text="description"
                  item-value="id"
                  outlined
                ></v-select>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-select
                  :clearable="true"
                  clear-icon="mdi-close"
                  label="Candidato Indicado"
                  dense
                  :items="possibles"
                  v-model="nominated_candidate"
                  item-text="description"
                  item-value="id"
                  outlined
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-select
                  :clearable="true"
                  clear-icon="mdi-close"
                  label="Resultado"
                  dense
                  :items="possible_final_result"
                  v-model="final_result"
                  item-text="description"
                  item-value="id"
                  outlined
                ></v-select>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn
            text
            @click="
              setShowFilter(false);
              clearFilter();
            "
            >Cancelar</v-btn
          >
        </v-col>
        <v-col class="text-right">
          <v-btn text type="submit" right @click="filter()">Filtrar</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "CandidatesFilter",
  computed: {
    ...mapState("candidates", [
      "mode",
      "collaborators",
      "possible_status",
      "possible_final_result",
      "possibles",
      "steps",
      "roles",
      "departments",
    ]),
    ...mapFields("candidates", [
      "filter.name",
      "filter.nominated_candidate",
      "filter.final_result",
      "filter.called_again",
      "filter.start_date",
      "filter.email",
      "filter.finish_date",
      "filter.failed_in",
      "filter.next_step",
    ]),
  },
  methods: {
    ...mapMutations("candidates", ["setShowFilter", "clearFilter"]),
    ...mapActions("candidates", ["getItems", "getSearchCollaborators"]),
    filter() {
      this.getItems({ resetPage: true });
      this.setShowFilter(false);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    },
    setStart(date) {
      this.start_date = date;
      this.showStart = false;
      this.start_formatted = this.formatDate(date);
    },
    setEnd(date) {
      this.finish_date = date;
      this.showEnd = false;
      this.end_formatted = this.formatDate(date);
    },
    clearStart() {
      this.start_date = "";
      this.start_formatted = "";
    },
    clearEnd() {
      this.finish_date = "";
      this.end_formatted = "";
    },
  },
  data: () => ({
    start_formatted: "",
    showStart: false,
    showEnd: false,
    end_formatted: "",
    searchInput: "",
  }),
  watch: {
    searchInput: {
      handler() {
        if (this.searchInput) {
          var payload = {
            search: this.searchInput,
          };
          this.getSearchCollaborators(payload);
        }
      },
    },
  },
};
</script>
