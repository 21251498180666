<template>
  <div>
    <v-snackbar
      :color="notificationType"
      right
      top
      :timeout="2000"
      v-model="showNotification"
    >
      {{ notificationMessage }}
    </v-snackbar>
    <v-toolbar flat class="elevation-10">
      <v-toolbar-title>Slots</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="black" dark class="ma-2" @click="setShowFilter(true)" small
        ><v-icon>mdi-filter</v-icon>
      </v-btn>
      <v-btn color="black" dark class="ma-2" @click="getSlots" small
        ><v-icon>mdi-refresh</v-icon></v-btn
      >
      <v-btn color="black" dark class="ma-2" @click="addSlot" small
        ><v-icon>mdi-plus</v-icon></v-btn
      >
    </v-toolbar>

    <v-dialog :value="dialog" persistent width="600">
      <v-card>
        <v-card-title>
          <span class="headline">{{ this.formTitle() }}</span>
        </v-card-title>

        <v-card-text>
          <v-alert
            v-if="successNotification"
            :value="successNotification"
            dense
            type="success"
            icon="mdi-check"
            outlined
            >Slot salvo com sucesso.</v-alert
          >
          <v-alert
            v-if="errorNotification"
            :value="errorNotification"
            color="error"
            icon="mdi-alert-circle"
            outlined
            >{{ this.errorMessage }}</v-alert
          >
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Nome *"
                    v-model="name"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('name')"
                    :rules="rulesFields"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Nick *"
                    v-model="nick"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('nick')"
                    :rules="rulesFields"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md4>
                  <v-select
                    label="Tipos de Plataforma"
                    required
                    :items="plataform_types"
                    v-model="plataform_id"
                    :readonly="this.mode == 'VIEW'"
                    item-text="description"
                    item-value="id"
                    :rules="rulesFields"
                    outlined
                  >
                  </v-select>
                </v-flex>
                <v-flex xs12 sm4 md4>
                  <v-text-field
                    required
                    label="Id App *"
                    v-model="id_app"
                    :readonly="this.mode == 'VIEW'"
                    :rules="rulesFields"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md4>
                  <v-select
                    label="Status *"
                    required
                    :items="possible_status"
                    v-model="status"
                    :readonly="this.mode == 'VIEW'"
                    item-text="description"
                    item-value="id"
                    :rules="rulesFields"
                    outlined
                  >
                  </v-select>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-select
                    label="Saques *"
                    required
                    :items="possible_web_deposits_withdrawals"
                    v-model="web_withdrawals"
                    :readonly="this.mode == 'VIEW'"
                    item-text="description"
                    item-value="id"
                    :rules="rulesFields"
                    outlined
                  >
                  </v-select>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-select
                    label="Depósitos *"
                    required
                    :items="possible_web_deposits_withdrawals"
                    v-model="web_deposits"
                    :readonly="this.mode == 'VIEW'"
                    item-text="description"
                    item-value="id"
                    :rules="rulesFields"
                    outlined
                  >
                  </v-select>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-select
                    label="BOT *"
                    required
                    :items="bots"
                    v-model="id_bot"
                    :readonly="this.mode == 'VIEW'"
                    item-text="name"
                    item-value="id"
                    :rules="rulesFields"
                    outlined
                  >
                  </v-select>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-select
                    label="Permite Jogadores None *"
                    required
                    :items="yes_or_no"
                    v-model="allow_none"
                    :readonly="this.mode == 'VIEW'"
                    item-text="description"
                    item-value="value"
                    :rules="rulesFields"
                    outlined
                  >
                  </v-select>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-select
                    label="Aprovação Automática *"
                    required
                    :items="yes_or_no"
                    v-model="automatic_approval"
                    :readonly="this.mode == 'VIEW'"
                    item-text="description"
                    item-value="value"
                    :rules="rulesFields"
                    outlined
                  >
                  </v-select>
                </v-flex>

                <v-flex xs12 sm3 md3 v-if="this.mode == 'VIEW'">
                  <v-text-field
                    required
                    label="ID"
                    v-model="id"
                    readonly
                    :error-messages="errors.collect('id')"
                    data-vv-name="id"
                    outlined
                  ></v-text-field>
                </v-flex>


              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-col>
            <v-btn text @click="closeDialog">{{
              mode == "VIEW" ? "Voltar" : "Cancelar"
            }}</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn
              text
              type="submit"
              right
              v-if="mode == 'EDIT' || mode == 'ADD'"
              @click="save"
              >Salvar</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-data-table
      :headers="headers"
      :loading="loading"
      :items="slots"
      :options.sync="pagination"
      :server-items-length="totalSlots"
      disable-pagination
      item-key="id"
      :footer-props="{
        'items-per-page-options': [10, 20, 50, 100],
        'items-per-page-text': 'Itens por página',
        'show-first-last-page': true,
        'page-text': `Página ${pagination.page} de ${parseInt(totalSlots/pagination.itemsPerPage) + 1}`
      }" 
      no-data-text="Nenhum slot encontrado"
      class="elevation-5 table-margin"
    >
      <template v-slot:[`item.plataform_id`]="{ item }">
        <span>{{ getPlataform(item.plataform_id) }}</span>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          :color="getColor(item.status)"
          class="ma-2"
          text-color="white"
          label
          >{{ getStatus(item.status) }}</v-chip
        >
      </template>

      <template v-slot:[`item.web_deposits`]="{ item }">
        <v-chip
          :color="getColor(item.web_deposits)"
          class="ma-2"
          text-color="white"
          label
          >{{ getDepositsWithdrawals(item.web_deposits) }}</v-chip
        >
      </template>

      <template v-slot:[`item.allow_none`]="{ item }">
        <v-chip
          :color="getColor(item.allow_none)"
          class="ma-2"
          text-color="white"
          label
          >{{ getDepositsWithdrawals(item.allow_none) }}</v-chip
        >
      </template>

      <template v-slot:[`item.automatic_approval`]="{ item }">
        <v-chip
          :color="getColor(item.automatic_approval)"
          class="ma-2"
          text-color="white"
          label
          >{{ getDepositsWithdrawals(item.automatic_approval) }}</v-chip
        >
      </template>
      
      <template v-slot:[`item.web_withdrawals`]="{ item }">
        <v-chip
          :color="getColor(item.web_withdrawals)"
          class="ma-2"
          text-color="white"
          label
          >{{ getDepositsWithdrawals(item.web_withdrawals) }}</v-chip
        >
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="viewSlot(item)"
          >mdi-information</v-icon
        >
        <v-icon small class="mr-2" @click="edit(item)">mdi-pencil</v-icon>
        <v-icon small class="mr-2" @click="showDeleteDialog(item)"
          >mdi-delete</v-icon
        >
      </template>
    </v-data-table>

    <v-dialog
      :value="filterDialog"
      @click:outside="setShowFilter(false)"
      width="600"
    >
      <slot-filter></slot-filter>
    </v-dialog>

    <v-dialog :value="dialogDelete" persistent width="300">
      <v-card>
        <v-card-title class="headline">Confirmar a exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn text @click="closeDeleteDialog()">Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn text type="submit" right @click="deleteSlot"
              >Confirmar</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import debounce from '../../../util/debounce.js' 
  import { mapFields } from 'vuex-map-fields'
  import SlotFilter from './SlotFilter.vue'

export default {
  name: "Slots",
  components: {
    SlotFilter,
  },
  created() {
    this.getBots();
    this.getSlots({ resetPage: false });
    this.$validator.extend("required", {
      getMessage: (field, [label]) => label + " deve ser informado",
      validate: (_, [, field]) => {
        if (
          (this[field] || this[field] === 0) &&
          this[field] !== "" &&
          this[field] !== null
        ) {
          return true;
        } else {
          return false;
        }
      },
    });
  },
  computed: {
    ...mapState("slots", [
      "dialog",
      "dialogDelete",
      "errorMessage",
      "errorNotification",
      "errors",
      "loading",
      "mode",
      "notificationType",
      "notificationMessage",
      "successNotification",
      "totalSlots",
      "plataform_types",
      "possible_status",
      "slots",
      "bots",
      "possible_web_deposits_withdrawals",
    ]),
    ...mapFields("slots", [
      "editedSlot.id",
      "editedSlot.name",
      "editedSlot.nick",
      "editedSlot.id_app",
      "editedSlot.plataform_id",
      "editedSlot.status",
      "editedSlot.web_withdrawals",
      "editedSlot.web_deposits",
      "editedSlot.id_bot",
      "editedSlot.bot_name",
      "editedSlot.allow_none",
      "editedSlot.automatic_approval",
      "pagination",
      "filterDialog",
      "showNotification",
      // ...
    ]),
    banksPix() {
      return this.banks.filter((b) => b.pix_key);
    },
  },
  data: () => ({
    rulesFields: [(value) => !!value || value === 0 || "Campo obrigatório"],
    headers: [
      { text: "ID", value: "id", sortable: true },
      {
        text: "Nome",
        align: "left",
        value: "name",
      },
      {
        text: "Nick",
        align: "left",
        value: "nick",
      },
      {
        text: "Plataforma",
        align: "left",
        value: "plataform_id",
      },
      { text: "Id App", value: "id_app" },
      { text: "Status", value: "status", sortable: true, align: "center" },
      {
        text: "Saques",
        value: "web_withdrawals",
        sortable: true,
        align: "center",
      },
      {
        text: "Depósitos",
        value: "web_deposits",
        sortable: true,
        align: "center",
      },
      {
        text: "Permite Jogadores None",
        value: "allow_none",
        sortable: true,
        align: "center",
      },
      {
        text: "Aprovação Automática",
        value: "automatic_approval",
        sortable: true,
        align: "center",
      },
      { text: "BOT", value: "bot_name", sortable: true, align: "center" },
      { text: "Ações", value: "actions", sortable: false, align: "center" },
    ],
    yes_or_no: [
      { value: 0, description: "Não" },
      { value: 1, description: "Sim" },
    ],
    rules: {
      required: (value) => !!value || "Required.",
      counter: (value) => value.length <= 20 || "Max 20 characters",
    },
    valid: false,
  }),
  mixins: [debounce],
  methods: {
    getTextPlataform(item) {
      return this.getPlataform[item].description;
    },
    getDepositsWithdrawals(item) {
      return this.possible_web_deposits_withdrawals[item].description;
    },
    getPlataform(plataform_id) {
      return this.plataform_types[plataform_id].description;
    },
    getStatus(status) {
      return this.possible_status[status].description;
    },
    getColor(item) {
      if (item == 0) {
        return "deep-orange darken-4";
      } else {
        return "green darken-3";
      }
    },
    edit(item) {
      this.editSlot(item);
    },
    ...mapMutations("slots", [
      "addSlot",
      "editSlot",
      "viewSlot",
      "setShowFilter",
    ]),
    ...mapActions("slots", [
      "closeDialog",
      "closeDeleteDialog",
      "deleteSlot",
      "getSlots",
      "getBots",
      "getLeagues",
      "saveSlot",
      "showDeleteDialog",
    ]),
    formTitle() {
      switch (this.mode) {
        case "ADD":
          return "Novo Slot";
        case "EDIT":
          return "Editar Slot";
        default:
          return "Slot";
      }
    },
    save() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.saveSlot();
        }
      });
    },
  },
  watch: {
    pagination: {
      handler() {
        this.getSlots({ resetPage: false });
      },
    },
  },
};
</script>

<style>
.alert {
  background-color: white;
  z-index: 100;
  top: 50%;
}
.error--text {
  color: red !important;
}
.right {
  float: right;
}
.v-card__text {
  padding: 0px !important;
}
</style>