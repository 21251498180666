<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2000"
      v-model="showNotification"
    >{{ notificationMessage }}</v-snackbar>
    <v-toolbar flat class="elevation-5">
      <div>
        <v-toolbar-title>Vendas</v-toolbar-title>
      </div>
      <v-spacer></v-spacer>
      <div class="button-toolbar">
        <!-- <download-excel
          :before-generate="generateSheet"
          :before-finish="finishSheet"
          :name="getSheetName()"
          :fetch="getItemsNoPage"
          :export-fields="sheet_fields"
          class="excel"
          worksheet="Vendas"
          type="xls"
        >
          <v-btn color="black" dark class="ma-2" small>
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </download-excel> -->
        <v-btn color="black" dark class="ma-2" @click="downloadXlsx({ show: true })" small>
          <v-icon>mdi-download</v-icon>
        </v-btn>
        <v-btn color="black" dark class="ma-2" @click="showFilter({show: true})" small>
          <v-icon :color="filtered ? 'grey' : 'white'">mdi-filter</v-icon>
        </v-btn>
        <v-btn color="black" dark class="ma-2" @click="getItems" small>
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-btn color="black" dark class="ma-2" @click="add({screen: 'sales'})" small>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :loading="loading"
      :items="items"
      :item-class="getClassColor"    
      :options.sync="pagination"
      :server-items-length="totalItems"              
      no-data-text="Nenhuma venda encontrada"
      class="elevation-10 table-margin"
    >
      <template v-slot:[`item.status`]="{ item }">
        <span>{{ getStatus(item.status) }}</span>
      </template>
      <template v-slot:[`item.date_created`]="{ item }">
        <span>{{ getDate(item.date_created)}}</span>
      </template>
      <template v-slot:[`item.value`]="{ item }">
        <span>{{ (item.value) ? item.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00' }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" v-if="item.status == 1" @click="backStepSale({sale: item})">mdi-arrow-left</v-icon>
        <v-icon small class="mr-2" v-if="item.status == 0" @click="startSale({sale: item})">mdi-arrow-right</v-icon>
        <v-icon small class="mr-2" v-if="item.status == 1" @click="inTransitSale({sale: item})">mdi-truck-fast</v-icon>
        <v-icon small class="mr-2" v-if="item.status == 2" @click="finishSale({sale: item})">mdi-check</v-icon>
        <v-icon small class="mr-2" @click="viewItem({item: item, screen: screen})">mdi-information</v-icon>
        <v-icon small class="mr-2" v-if="item.status == 0" @click="editItem({item: item, screen: screen})">mdi-pencil</v-icon>
        <v-icon
          small
          class="mr-2"
          v-if="item.status == 0"
          @click="showDeleteDialog({item: item, type: 0})"
        >mdi-delete</v-icon>
        <v-icon small class="mr-2" v-if="item.verified == 0 && profileAccess()" @click="verifyItem({item: item})">mdi-check</v-icon>
        <v-icon small class="mr-2" v-if="item.contact_id != '' && item.contact_id != null" @click="showContactDialog({item: item})">mdi-account</v-icon>
        <v-icon small class="mr-2" @click="showReceipt({item: item})">mdi-receipt</v-icon>
      </template>
    </v-data-table>

    <v-dialog :value="dialogInTransit" @click:outside="closeDialog()" width="600">
      <v-card>
        <v-card-title class="headline text-center justify-center">Venda em Trânsito</v-card-title>
        <v-card-text>
          <v-container>
            <v-layout warp>
              <v-flex xs12 sm12 md12>
                <v-text-field
                  label="Código de Rastreio"
                  v-model="tracking_code_transit"
                  outlined
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-col>
            <v-btn
              text
              @click="closeTransitSale"
            >Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn
              text
              type="submit"
              right
              @click="transitSale"
            >Confirmar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog :value="dialog" @click:outside="closeDialog()" width="1280">
      <sales-form screen="sales"></sales-form>
    </v-dialog>

    <v-dialog
      v-model="dialogReceipt"
    >
      <v-card height="90vh">
        <v-img max-height="100%" :src="receipt_show" contain></v-img>
      </v-card>
    </v-dialog>

    <v-dialog :value="dialogFilter" width="600" @click:outside="showFilter({show: false})" @keydown.enter="filter">
      <sales-filter></sales-filter>
    </v-dialog>

    <v-dialog :value="dialogDelete"  @click:outside="closeDeleteDialog" @keydown.enter="deleteItem" width="300">
      <v-card>
        <v-card-title class="headline">Confirmar a exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn text @click="closeDeleteDialog()">Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn text type="submit" right @click="deleteItem">Confirmar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogContact"
      max-width="500"
    >
      <v-card>
        <contact-card :contact_id="contact_show.phone" :contact_name="contact_show.name"></contact-card>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import SalesForm from "./SalesForm";
import SalesFilter from "./SalesFilter";
// import JsonExcel from "vue-json-excel";
import { utils, writeFile } from "xlsx";

export default {
  name: "Sales",
  computed: {
    ...mapState("login", ["userData"]),
    ...mapState("sales", [
      "contact_show",
      "dialog",
      "dialogDelete",
      "item",
      "instances",
      "errors",
      "filtered",
      "loading",
      "mode",
      "notificationType",
      "notificationMessage",
      "rowsPerPage",
      "totalItems",
      "items",
    ]),
    ...mapFields("sales", [
      "dialogReceipt",
      "dialogContact",
      "pagination",
      "dialogInTransit",
      "dialogFilter",
      "overlay",
      "receipt_show",
      "showNotification",
      "tracking_code_transit"
      // ...
    ]),
  },
  created() {
    this.getItems()
    this.setScreen({screen: 'sales'})
  },
  components: {
    // "download-excel": JsonExcel,
    "sales-form": SalesForm,
    "sales-filter": SalesFilter,
  },
  methods: {
    ...mapMutations("sales", [
      "add",
      "closeDialog",
      "closeTransitSale",
      "inTransitSale",
      "setScreen",
      "showFilter"
    ]),
    ...mapActions("sales", [
      "backStepSale",
      "closeDeleteDialog",
      "deleteItem",
      "finishSale",
      "editItem",
      "getItems",
      "getItemsNoPage",
      "showDeleteDialog",
      "viewItem",
      "verifyItem",
      "startSale",
      "showContactDialog",
      "showReceipt",
      "transitSale"
    ]),
    filter() {
      this.getItems({ resetPage: true });
      this.showFilter({ show: false });
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      }
      return false;
    },
    getDate(date) {
      if (date) {
        var d = new Date(date);
        return d.toLocaleDateString() + " " + d.toLocaleTimeString();
      }
      return "";
    },
    finishSheet() {
      this.overlay = false;
    },
    getClassColor(item) {
      if(item.status == 0) {
        return 'row-red'
      } else if(item.status == 1) {
        return 'row-orange'
      } else if(item.status == 3) {
        return 'row-green'
      } 
    },
    generateSheet() {
      this.overlay = true;
    },
    getSheetName() {
      var date = new Date();
      var name =
        "vendas_" +
        date.toLocaleDateString() +
        " " +
        date.toLocaleTimeString();
      return name + ".xls";
    },
    async downloadXlsx() {
      this.generateSheet()
      try {
        let itemsNoPage = await this.getItemsNoPage()
        let possible_status = ["Pendente", "Em Separação", "Enviado", "Concluido"]
        if(itemsNoPage) {
          const wb = utils.book_new()
            let items = itemsNoPage.map(row => ({
              "Nome":  row.name,
              "CEP":  row.address_cep,
              "Estado":  row.address_state,
              "Cidade":  row.address_city,
              "Observação":  row.weight,
              "Status":  possible_status[row.status],
              "Value":  this.valueXLSX(row.value),
              "Criado em":  this.dateCreatedXLSX(row.date_created),
              "Rastreio":  row.tracking_code,
            }))
          const ws = utils.json_to_sheet(items)
          utils.book_append_sheet(wb, ws, "Produtos")
          await writeFile(wb, this.getSheetName())
        }
      } catch (error) {
        this.finishSheet()
      } finally {
        this.finishSheet()
      }
    },
    valueXLSX: (value) => {
      if (value) {
        let real = value.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });
        return real.substr(3);
      }
      return 0;
    },
    dateCreatedXLSX: (value) => {   
       if(value) {
        var d = new Date(value);
        return d.toLocaleDateString() + " " + d.toLocaleTimeString() ;
      }
    },
    getStatus(type) {  
      let types = ['Pendente', 'Em Separação', 'Enviado', 'Concluido']
      return types[type]
    },
    profileAccess() {
      if(this.userData.role > 90 || this.userData.role == 2 ) {
        return true
      } else {
        return false
      }
    },
  },
  data: () => ({
    build_fields: {},
    expanded: [],
    headers: [
      { text: "Código", value: "id", sortable: false },
      { text: "Nome", value: "name", sortable: false },
      { text: "Cep", value: "address_cep", sortable: false },
      { text: "Estado", value: "address_state", sortable: false },
      { text: "Cidade", value: "address_city", sortable: false },
      { text: "Observação", value: "observation", sortable: false },
      { text: "Status", value: "status", sortable: false },
      { text: "Valor", value: "value", sortable: false },
      { text: "Criado Em", value: "date_created", sortable: false },
      { text: "Rastreio", value: "tracking_code", sortable: false },
      { text: "Ações", value: "actions", sortable: false, align: "center" },
    ],
    sheet_fields: {
      Nome: {
        field: "name"
      },
      Cep: {
        field: "address_cep"
      },
      Estado: {
        field: "address_state"
      },
      Cidade: {
        field: "address_city"
      },
      Observacao: {
        field: "weight"
      },
      Status: {
        field: 'status',
        callback: (type) => {
          let types = ["Pendente", "Em Separação", "Enviado", "Concluido"]
          return types[type]
        }
      },
      Valor: {
        field: "value",
        callback: (value) => {
          if (value) {
            let real = value.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
            return real.substr(3);
          }
          return 0;
        },
      },
      "Criado em": {
        field: "date_created",
        callback: (value) => {   
           if(value) {
            var d = new Date(value);
            return d.toLocaleDateString() + " " + d.toLocaleTimeString() ;
          }
        },
      },
      "Rastreio": {
        field: "tracking_code"
      },
    },
    valid: false,
  }),
  watch: {
    pagination: {
      handler() {
        this.getItems({ resetPage: false });
      },
    },
  },
};
</script>
