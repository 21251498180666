<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline text-center justify-center">Filtro</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form">
          <v-container grid-list-md>
            <v-layout wrap>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Nome *"
                    v-model="name"
                    :error-messages="errors.collect('name')"
                    v-validate="'required:Nome,name'"
                    data-vv-name="name"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Código *"
                    v-model="profile_code"
                    :error-messages="errors.collect('profile_code')"
                    v-validate="'required:Nome,profile_code'"
                    data-vv-profile_code="profile_code"
                    outlined
                  ></v-text-field>
                </v-flex>          

            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn text @click="showFilter({show: false})">Cancelar</v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn text type="submit" right @click="filter">Filtrar</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import debounce from "../../util/debounce.js";

export default {
  name: "ProfilesFilter",
  computed: {
    ...mapState("profiles", ["agents", "find_agents", "users"]),
    ...mapFields("profiles", [
      "filter.profile_code",
      "filter.name",
      // ...
    ]),
  },
  mixins: [debounce],
  mounted() {
    this.getAgents = this.debounce(this.getAgents, 500)
    this.getAttendants = this.debounce(this.getAttendants, 500); 
  },
  methods: {
    ...mapMutations("profiles", ["showFilter"]),
    ...mapActions("profiles", [
      "getItems",
      "getAttendants",
      "getUsers",
      "getAgents",
      "closeDialog",
      "save",
    ]),
    clearStart() {
        this.start = ''
        this.start_formatted = ''
    },
    clearEnd() {
        this.end = ''
        this.end_formatted = ''
    },
    filter() {
      this.getItems({ resetPage: true });
      this.showFilter({ show: false });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    },
    getTextUser(item) {
      if(item.account) {
        return item.name + ' | ' + item.account
      } else {
        return item.name
      }
    },
    setStart(date) {
      this.start = date;
      this.showStart = false;
      this.start_formatted = this.formatDate(date);
    },
    setEnd(date) {
      this.end = date;
      this.showEnd = false;
      this.end_formatted = this.formatDate(date);
    },
  },
  watch: {
    searchInput(val) {
      if (val) {
        var payload = {
          search: val,
        };
        this.getAttendants(payload);
      }
    },
    searchAgent (val) {
      var payload = {
        search: val
      }
      this.getAgents(payload)
    }
  }
};
</script>

<style>
</style>