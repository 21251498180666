<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar
      :color="notification.type"
      right
      top
      :timeout="2000"
      v-model="notification.show"
    >
      {{ notification.message }}
    </v-snackbar>

    <v-toolbar flat class="elevation-5">
      <div>
        <v-toolbar-title>Candidatos</v-toolbar-title>
      </div>
      <v-spacer></v-spacer>
      <div class="button-toolbar">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="black"
              dark
              class="ma-2"
              @click="setShowFilter(true)"
              v-bind="attrs"
              v-on="on"
              small
            >
              <v-icon color="white">mdi-filter</v-icon>
            </v-btn>
          </template>
          <span>Filtro</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="black"
              dark
              class="ma-2"
              @click="getItems"
              v-bind="attrs"
              v-on="on"
              small
              ><v-icon>mdi-refresh</v-icon></v-btn
            >
          </template>
          <span>Recarregar</span>
        </v-tooltip>

        <v-tooltip v-if="this.job.status == 0" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="black"
              dark
              class="ma-2"
              @click="addCandidate"
              v-bind="attrs"
              v-on="on"
              small
            >
              <v-icon color="white">mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Adicionar</span>
        </v-tooltip>
      </div>
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :loading="loading"
      :items="items"
      :options.sync="pagination"
      :server-items-length="totalItems"
      class="elevation-10 table-margin"
      no-data-text="Nenhum candidato encontrado"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ attrs, on }">
            <v-icon
              small
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="view(item)"
              >mdi-information</v-icon
            >
          </template>

          <span>Informações</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ attrs, on }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              small
              class="mr-2"
              @click="editCandidate({ item: item })"
              >mdi-pencil</v-icon
            >
          </template>
          <span>Editar</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ attrs, on }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              small
              class="mr-2"
              @click="showDeleteDialog(item)"
              >mdi-delete</v-icon
            >
          </template>
          <span>Excluir</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.final_result`]="{ item }">
        <v-chip
          :color="getColor(item.final_result)"
          class="ma-2"
          text-color="white"
          label
          >{{ getResult(item.final_result) }}</v-chip
        >
      </template>
      <template v-slot:[`item.phone`]="{ item }">
        <span>{{ getPhone(item.phone) }}</span>
      </template>
      <template v-slot:[`item.called_again`]="{ item }">
        <span v-if="item.called_again !== null" class="ma-2">{{
          getCalledAgain(item.called_again)
        }}</span>
      </template>
      <template v-slot:[`item.failed_in`]="{ item }">
        <span v-if="item.failed_in !== null" class="ma-2">{{
          getFailed(item.failed_in)
        }}</span>
      </template>
      <template v-slot:[`item.next_step`]="{ item }">
        <span v-if="item.next_step !== null" class="ma-2">{{
          getNextStep(item.next_step)
        }}</span>
      </template>
      <template v-slot:[`item.role`]="{ item }">
        <span class="ma-2">{{ getRole(item.role) }}</span>
      </template>
        <template v-slot:[`item.date`]="{ item }">
        <span class="ma-2">{{ getDate(item.date) }}</span>
      </template>
    </v-data-table>

    <v-dialog :value="dialog" width="800" @click:outside="closeDialog">
      <candidates-form></candidates-form>
    </v-dialog>

    <v-dialog
      :value="filterDialog"
      @click:outside="setShowFilter(false)"
      width="600"
    >
      <candidates-filter></candidates-filter>
    </v-dialog>

    <v-dialog :value="dialogDelete" persistent width="300">
      <v-card>
        <v-card-title class="headline">Confirmar a exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn text @click="closeDeleteDialog()">Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn text type="submit" right @click="deleteCandidate"
              >Confirmar</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import CandidatesForm from "./CandidatesForm.vue";
import CandidatesFilter from "./CandidatesFilter.vue";

export default {
  name: "Candidates",

  created() {
    this.getItems({ resetPage: false });
    this.getCollaborators();
    this.getCollaboratorsResponsibles();
    this.getRoles();
    this.getDepartments();
  },

  components: {
    CandidatesForm,
    CandidatesFilter,
  },

  methods: {
    ...mapActions("candidates", [
      "getItems",
      "getRoles",
      "getDepartments",
      "getCollaborators",
      "getCollaboratorsResponsibles",
      "closeDialog",
      "closeDeleteDialog",
      "deleteCandidate",
      "showDeleteDialog",
    ]),
    ...mapMutations("candidates", [
      "addCandidate",
      "view",
      "setShowFilter",
      "editCandidate",
    ]),
    getColor(result) {
      if (result == 0) {
        return "light-blue darken-3";
      } else if (result == 1) {
        return "green darken-3";
      } else {
        return "deep-orange darken-4";
      }
    },
    getPhone(tel) {
      tel = tel.replace(/\D/g, ""); //Remove tudo o que não é dígito
      tel = tel.replace(/^(\d{2})(\d)/g, "($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
      tel = tel.replace(/(\d)(\d{4})$/, "$1-$2"); //Coloca hífen entre o quarto e o quinto dígitos
      return tel;
    },

    getResult(value) {
      return this.possible_final_result[value].description;
    },
    getCalledAgain(value) {
      return this.possibles[value].description;
    },
    getFailed(value) {
      return this.steps[value].description;
    },
    getNextStep(value) {
      return this.steps[value].description;
    },

    getRole(role_id) {
      let role = this.roles.find((role) => role.id == role_id);
      return role.name;
    },
    getDepartment(department_id) {
      let department = this.roles.find(
        (department) => department.id == department_id
      );
      return department.name;
    },

    getDate(date) {
      if (date) {
        var d = new Date(date);
        return d.toLocaleDateString() + " " + d.toLocaleTimeString();
      }
      return "";
    },

    getTypeDescription(type) {
      return this.possible_types[type].description;
    },
  },
  computed: {
    ...mapState("login", ["userData"]),
    ...mapState("candidates", [
      "dialog",
      "items",
      "job",
      "collaborators",
      "collaboratorsResponsibles",
      "departments",
      "notification",
      "mode",
      "loading",
      "totalItems",
      "filterDialog",
      "possible_types",
      "departments",
      "possible_results",
      "possible_final_result",
      "steps",
      "possibles",
      "roles",
      "dialogDelete",
    ]),
    ...mapFields("candidates", ["pagination"]),
  },
  data: () => ({
    headers: [
      // { text: "ID", value: "id", sortable: true },
      {
        text: "Data da Entrevista",
        value: "date",
        sortable: true,
        align: "center",
      },
      {
        text: "Nome",
        value: "name",
        sortable: true,
        align: "center",
      },
      { text: "Telefone", value: "phone", sortable: true, align: "center" },
      // { text: "E-mail", value: "email", sortable: true, align: "center" },
      {
        text: "Resultado",
        value: "final_result",
        sortable: true,
        align: "center",
      },
      {
        text: "Próxima Etapa",
        value: "next_step",
        sortable: true,
        align: "center",
      },
      {
        text: "Reprovado na",
        value: "failed_in",
        sortable: true,
        align: "center",
      },
      {
        text: "Pode ser chamado novamente",
        value: "called_again",
        sortable: true,
        align: "center",
      },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        align: "center",
        width: "10%",
      },
    ],
  }),

  watch: {
    pagination: {
      handler() {
        this.getItems({ resetPage: false });
      },
    },
  },
};
</script>
