<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar
      :color="notification.type"
      right
      top
      :timeout="2000"
      v-model="notification.show"
    >
      {{ notification.message }}
    </v-snackbar>

    <v-toolbar>
      <v-icon color="black">mdi-handshake</v-icon>
      <v-toolbar-title class="font-weight-medium">Cobranças</v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="button-toolbar text-right">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="black"
              dark
              class="ma-2"
              @click="setDialog({ dialog: 'filterDialog', show: true })"
              v-bind="attrs"
              v-on="on"
              small
            >
              <v-icon color="white">mdi-filter</v-icon>
            </v-btn>
          </template>
          <span>Filtro</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="black"
              dark
              class="ma-2"
              @click="getItems({ resetPage: false })"
              v-bind="attrs"
              v-on="on"
              small
              ><v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Recarregar</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="black"
              dark
              class="ma-2"
              @click="
                setDialog({ dialog: 'formDialog', show: true, mode: 'ADD' })
              "
              v-bind="attrs"
              v-on="on"
              small
            >
              <v-icon color="white">mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Nova Cobrança</span>
        </v-tooltip>
      </div>
    </v-toolbar>

    <v-card>
      <v-data-table
        :headers="headers"
        :loading="loading"
        :items="items"
        :options.sync="pagination"
        :server-items-length="totalItems"
        no-data-text="Nenhum registro encontrado"
        class="elevation-10 table-margin"
        show-expand
        :expanded.sync="expanded"
      >
        <template
          v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }"
        >
          <v-icon @click="expand(true)" v-if="item.observation && !isExpanded"
            >mdi-chevron-down</v-icon
          >
          <v-icon @click="expand(false)" v-if="item.observation && isExpanded"
            >mdi-chevron-up</v-icon
          >
        </template>
        <template v-slot:expanded-item="{ item }">
          <td>Observação: {{ item.observation }}</td>
        </template>
        <template v-slot:[`item.date_created`]="{ item }">
          <span>{{ getDateCreated(item.date_created) }}</span>
        </template>

        <template v-slot:[`item.debt`]="{ item }">
          <span>{{
            item.debt.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })
          }}</span>
        </template>

        <template v-slot:[`item.rating`]="{ item }">
          <v-chip
            :color="getColorRating(item.rating)"
            class="ma-2"
            text-color="white"
            label
            >{{ getRatingDescription(item.rating) }}</v-chip
          >
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <v-chip
            :color="getColorStatus(item.status)"
            class="ma-2"
            text-color="white"
            label
            >{{ getStatusDescription(item.status) }}</v-chip
          >
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ attrs, on }">
              <v-icon
                small
                color="black"
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                @click="
                  setDialog({
                    dialog: 'formDialog',
                    show: true,
                    mode: 'VIEW',
                    item: item,
                  })
                "
                >mdi-information</v-icon
              >
            </template>
            <span>Informações</span>
          </v-tooltip> -->

          <v-tooltip bottom>
            <template v-slot:activator="{ attrs, on }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                class="mr-2"
                @click="
                  setDialog({
                    dialog: 'formDialog',
                    show: true,
                    mode: 'EDIT',
                    item: item,
                  })
                "
                >mdi-pencil</v-icon
              >
            </template>
            <span>Editar</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ attrs, on }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                class="mr-2"
                @click="
                  setDialog({ dialog: 'deleteDialog', show: true, item: item })
                "
                >mdi-delete</v-icon
              >
            </template>
            <span>Excluir</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      :value="deleteDialog"
      @click:outside="setDialog({ dialog: 'deleteDialog', show: false })"
      @keydown.enter="deleteItem"
      width="450"
    >
      <v-card>
        <v-card-title class="headline">Confirmar a exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn
              color="red lighten-1"
              @click="setDialog({ dialog: 'deleteDialog', show: false })"
              >Cancelar</v-btn
            >
          </v-col>
          <v-col class="text-right">
            <v-btn color="green lighten-1" type="submit" right @click="deleteItem"
              >Confirmar</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      :value="filterDialog"
      width="1000"
      @click:outside="setDialog({ dialog: 'filterDialog', show: false })"
    >
      <billings-filter></billings-filter>
    </v-dialog>

    <v-dialog
      :value="formDialog"
      @click:outside="setDialog({ dialog: 'formDialog', show: false })"
      width="1000"
    >
      <billings-form></billings-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import BillingsForm from "./BillingsForm";
import BillingsFilter from "./BillingsFilter";

export default {
  name: "Billings",
  components: {
    "billings-filter": BillingsFilter,
    "billings-form": BillingsForm,
  },
  created() {
    this.getItems({ resetPage: false });
  },
  methods: {
    ...mapActions("billings", ["getItems", "deleteItem"]),
    ...mapMutations("billings", ["view", "setDialog"]),
    getDateCreated(date_created) {
      if (date_created != null) {
        var d = new Date(date_created);
        return d.toLocaleDateString() + " " + d.toLocaleTimeString();
      } else {
        return "--------";
      }
    },
    getColorStatus(payload) {
      switch (payload) {
        case 0:
          return "blue darken-1";
        case 1:
          return "red darken-1";
        case 2:
          return "green darken-1";
        case 3:
          return "orange darken-1";
        default:
          return "grey darken-1";
      }
    },
    getColorRating(payload) {
      switch (payload) {
        case 0:
          return "green darken-1";
        case 1:
          return "blue darken-1";
        case 2:
          return "red darken-1";
        default:
          return "grey darken-1";
      }
    },
    getPrioridade(payload) {
      switch (payload) {
        case 1:
          return "Baixa";
        case 2:
          return "Normal";
        case 3:
          return "Alta";
        case 4:
          return "Urgente";
        default:
          return "Sem Prioridade";
      }
    },
    getRatingDescription(rating) {
      return this.possible_ratings[rating].description;
    },
    getStatusDescription(status) {
      return this.possible_status[status].description;
    },
  },
  computed: {
    ...mapState("login", ["userData"]),
    ...mapState("billings", [
      "items",
      "notification",
      "loading",
      "totalItems",
      "filterDialog",
      "formDialog",
      "deleteDialog",
      "possible_ratings",
      "possible_risks",
      "possible_status",
    ]),
    ...mapFields("billings", [
      "pagination",
      "filter.id",
      "filter.id_agent",
      "filter.id_current_annotated",
      "filter.id_last_annotated",
      "filter.ag_group_id",
      "filter.debt",
      "filter.rating",
      "filter.observation",
      "filter.status",
      "filter.risk",
    ]),
  },

  /*
   *
   */
  data: () => ({
    expanded: [],
    headers: [
      {
        text: "Data da Cobrança",
        value: "date_created",
        sortable: false,
        align: "center",
      },
      {
        text: "Nick do Agente",
        value: "nick",
        sortable: false,
        align: "center",
      },
      {
        text: "Contato / Grupo",
        value: "ag_group_id",
        sortable: false,
        align: "center",
      },
      {
        text: "Valor da Dívida",
        value: "debt",
        sortable: false,
        align: "center",
      },
      {
        text: "Classificação/ Prazo",
        value: "rating",
        sortable: false,
        align: "center",
      },
      {
        text: "Status",
        value: "status",
        sortable: false,
        align: "center",
      },
      {
        text: "Responsável",
        value: "responsible",
        sortable: false,
        align: "center",
      },
      {
        text: "Contato do Responsável",
        value: "responsible_contact",
        sortable: false,
        align: "center",
      },
      { text: "", value: "data-table-expand", sortable: false },
      { text: "Ações", value: "actions", sortable: false },
    ],
  }),
};
</script>
