<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline text-center justify-center">Filtro</span>

        <v-col class="text-right">
          <v-btn v-show="filtered" color="white" class="ma-2"  @click="clearFilter" right small depressed>
            <v-icon color="black">mdi-filter-remove</v-icon>
          </v-btn>
        </v-col>
      </v-card-title>

      <v-card-text>
        <v-form ref="form">
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm6 md6>
                <v-text-field
                  required
                  data-vv-name="id"
                  label="ID"
                  v-model="id"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field
                  required
                  data-vv-name="player_id"
                  label="ID do Jogador"
                  v-model="player_id"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-select
                    label="Clube"
                    :items="instances"
                    v-model="club"
                    item-text="name"
                    item-value="id_instance"
                    clear-icon="mdi-close"
                    :clearable="true"
                    outlined
                  >
                </v-select>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-select
                    label="Tipo"
                    :items="actions_players"
                    v-model="type"
                    item-text="name"
                    item-value="type"
                    data-vv-name="type"
                    outlined
                    clearable
                  >
                </v-select>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-menu
                  v-model="showStart"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="start_formatted"
                      label="De"
                      outlined
                      readonly
                      append-icon="mdi-close"
                      @click:append="clearStart()"
                      persistent-hint
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="pt-br"
                    v-model="start"
                    no-title
                    @input="(e) => setStart(e)"
                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-menu
                  v-model="showEnd"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="end_formatted"
                      label="Até"
                      outlined
                      readonly
                      append-icon="mdi-close"
                      @click:append="clearEnd()"
                      persistent-hint
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker locale="pt-br" v-model="end" no-title @input="(e) => setEnd(e)"></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex>
                <v-select
                  label="Item"
                  :items="supremeItems"
                  v-model="item"
                  item-text="description"
                  item-value="id"
                  clear-icon="mdi-close"
                  :clearable="true"
                  outlined
                  data-vv-name="item"
                >
                </v-select>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn text @click="showFilter({show: false})">Cancelar</v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn text type="submit" right @click="filter">Filtrar</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "PlayersFilter",
  computed: {
    ...mapState("instances", ["instances"]),
    ...mapState("players_delete", ["supremeItems"]),
    ...mapFields("players_delete", [
      "filter.id",
      "filter.player_id",
      "filter.type",
      "filter.club",
      "filter.item",
      "filter.start",
      "filter.end",
    ]),
  },
  data: () => ({
    actions_players: [
      {
        type: 0,
        name: "Exclusão de jogador"
      },
      {
        type: 1,
        name: "Envio de Items"
      },
      {
        type: 2,
        name: "Print"
      },
      {
        type: 3,
        name: "Print Agente"
      }
    ],
    showStart: false,
    showEnd: false,
    start_formatted: "",
    end_formatted: "",
  }),
  methods: {
    ...mapMutations("players_delete", ["showFilter", "setFiltered"]),
    ...mapActions("players_delete", ["getItems", "clearFilter"]),
    clearStart() {
      this.start = ''
      this.start_formatted = ''
    },
    clearEnd() {
      this.end = ''
      this.end_formatted = ''
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    },
    filter() {
      this.getItems({ resetPage: true });
      this.showFilter({ show: false });
      this.setFiltered(true);
    },
    setStart(date) {
      this.start = date;
      this.showStart = false;
      this.start_formatted = this.formatDate(date);
    },
    setEnd(date) {
      this.end = date;
      this.showEnd = false;
      this.end_formatted = this.formatDate(date);
    },
  }
};
</script>

<style>
</style>