<template>
    <div>
         <v-dialog :value="showDialog" persistent width="600">
      <v-card>
        <v-card-title>
          <span class="headline">Liga</span>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Nome *"
                    v-model="name"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('name')"
                    v-validate="'required:Name,name'"
                    data-vv-name="Nome"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Porcentagem de Taxa *"
                    v-model="percentage"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('percentage')"
                    v-validate="'required:Percentage,percentage'"
                    data-vv-name="Porcentagem"
                    outlined
                  ></v-text-field>
                </v-flex>

              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-col>
            <v-btn
              text
              @click="setShowDialog(false)"
              >{{ mode == "VIEW" ? "Voltar" : "Cancelar" }}</v-btn
            >
          </v-col>
          <v-col class="text-right">
            <v-btn
              text
              type="submit"
              right
              v-if="mode == 'EDIT' || mode == 'ADD'"
              @click="save"
              >Salvar</v-btn
            >
          </v-col>
        </v-card-actions>

      </v-card>
    </v-dialog>
    </div>
</template>

<script>

import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
    name: 'LeagueForm',
    methods: {
    ...mapMutations("league", ["setShowDialog", "setSelectedItem", "setShowDialog"]),
    ...mapActions("league", ["save"]),
  },
  computed: {
    ...mapState("league", [
      "mode",
      "selectedItem",
      "showDialog",
    ]),
    ...mapFields("league", [
      "selectedItem.id",
      "selectedItem.name",
      "selectedItem.percentage",
    ]),
  },
  data: () => ({
    valid: false,
  }),
}
</script>