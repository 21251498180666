<template>
    <div>
        <v-card>
            <v-card-title>
                <span class="headline text-center justify-center">Filtro</span>
            </v-card-title>

            <v-card-text>
                <v-form ref="form" lazy-validation>
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12 sm6 md6>
                                <v-text-field
                                    label="Domínio"
                                    v-model="nameDomain"
                                    :clearable="true"
                                    outlined
                                ></v-text-field>
                            </v-flex>

                            <v-flex xs12 sm6 md6>
                                <v-text-field
                                    label="Agente"
                                    v-model="agent"
                                    :clearable="true"
                                    outlined
                                ></v-text-field>
                            </v-flex>

                            <v-flex xs12 sm6 md6>
                                <v-select
                                    :clearable="true"
                                    clear-icon="mdi-close"
                                    label="Aplicativo"
                                    :items="possible_apps"
                                    v-model="app"
                                    item-text="app"
                                    item-value="app"
                                    outlined
                                ></v-select>
                            </v-flex>

                            <v-flex xs12 sm6 md6>
                                <v-text-field
                                    label="Clube"
                                    v-model="club"
                                    :clearable="true"
                                    outlined
                                ></v-text-field>
                            </v-flex>

                            <v-flex xs6 sm6 md6>
                                <v-select
                                    :clearable="true"
                                    clear-icon="mdi-close"
                                    label="Aprova Automaticamente"
                                    :items="possible_checkbox"
                                    v-model="approve"
                                    item-text="description"
                                    item-value="value"
                                    outlined
                                ></v-select>
                            </v-flex>

                            <v-flex xs6 sm6 md6>
                                <v-select
                                    :clearable="true"
                                    clear-icon="mdi-close"
                                    label="Cria Conta no Fichas"
                                    :items="possible_checkbox"
                                    v-model="createFichasAccount"
                                    item-text="description"
                                    item-value="value"
                                    outlined
                                ></v-select>
                            </v-flex>

                            <v-flex xs6 sm6 md6>
                                <v-select
                                    :clearable="true"
                                    clear-icon="mdi-close"
                                    label="Confirma E-mail no App"
                                    :items="possible_checkbox"
                                    v-model="emailConfirmationApp"
                                    item-text="description"
                                    item-value="value"
                                    outlined
                                ></v-select>
                            </v-flex>

                            <v-flex xs6 sm6 md6>
                                <v-select
                                    :clearable="true"
                                    clear-icon="mdi-close"
                                    label="Confirma E-mail no Fichas"
                                    :items="possible_checkbox"
                                    v-model="emailConfirmationFichas"
                                    item-text="description"
                                    item-value="value"
                                    outlined
                                ></v-select>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-col>
                    <v-btn
                        text
                        @click="
                            setShowFilter(false)
                            clearFilter()
                        "
                        >Cancelar</v-btn
                    >
                </v-col>
                <v-col class="text-right">
                    <v-btn text type="submit" right @click="filter()"
                        >Filtrar</v-btn
                    >
                </v-col>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
    name: 'ConfigClubsFilter',
    computed: {
        ...mapFields('custom_clubs', [
            'filter.nameDomain',
            'filter.agent',
            'filter.app',
            'filter.club',
            'filter.approve',
            'filter.createFichasAccount',
            'filter.emailConfirmationApp',
            'filter.emailConfirmationFichas',
        ]),
    },
    methods: {
        ...mapMutations('custom_clubs', ['setShowFilter', 'clearFilter']),
        ...mapActions('custom_clubs', ['getItems', 'getSearchCollaborators']),
        filter() {
            this.getItems({ resetPage: true })
            this.setShowFilter(false)
        },
    },
    data: () => ({
        possible_apps: [{ app: 'suprema' }, { app: 'cacheta' }],
        possible_checkbox: [
            { description: 'Sim', value: true },
            { description: 'Não', value: false },
        ],
    }),
}
</script>
