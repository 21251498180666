<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2000"
      v-model="showNotification"
    >{{ notificationMessage }}</v-snackbar>
    <v-toolbar flat class="elevation-5">
        <div>
            <v-toolbar-title>Movimentações Financeiras</v-toolbar-title>
        </div>
      <v-spacer></v-spacer>
      <div class="button-toolbar">
        <!-- <download-excel
          :before-generate="generateSheet"
          :before-finish="finishSheet"
          :name="getSheetName()"
          :fetch="getItemsNoPage"
          :fields="sheet_fields"
          class="excel"
          worksheet="Despesas"
        >
          <v-btn color="black" dark class="ma-2" small>
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </download-excel> -->
        <v-tooltip bottom>
          <template v-slot:activator="{on, attrs}">
            <v-btn color="black" dark class="ma-2" @click="downloadXlsx({ show: true })" v-bind="attrs" v-on="on" small>
              <v-icon>mdi-file-excel</v-icon>
            </v-btn>
          </template>
          <span>Gerar Planilha</span>
        </v-tooltip>
        <v-btn color="black" dark class="ma-2" @click="showFilter({show: true})" small>
          <v-icon>mdi-filter</v-icon>
        </v-btn>
        <v-btn color="black" dark class="ma-2" @click="getItems" small>
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-btn color="black" dark class="ma-2" @click="add" small><v-icon>mdi-plus</v-icon></v-btn>
      </div>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :loading="loading"
      :items="items"
      :options.sync="pagination" 
      :server-items-length="totalItems"
      :item-class= "getClassColor"  
      no-data-text="Nenhuma movimentação encontrada"
      class="elevation-10 table-margin"
    >
        <template v-slot:[`item.value`]="{ item }">
          <span>{{ getTextValue(item.type, item.value) }}</span>
        </template>
        <template v-slot:[`item.date_start`]="{ item }">
          <span>{{ getDate(item.date_start)}}</span>
        </template>
        <template v-slot:[`item.finish_at`]="{ item }">
          <span>{{ getDate(item.finish_at)}}</span>
        </template>
        <template v-slot:[`item.type`]="{ item }">
          <span>{{ getType(item.type)}}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            small
            class="mr-2"
            v-if="item.type == 3"
            @click="showDeleteDialog(item)"
          >mdi-delete</v-icon>
        </template>
    </v-data-table>
    <v-dialog :value="dialog" @click:outside="closeDialog()" width="800">
      <financial-moviments-form></financial-moviments-form>
    </v-dialog>
    <v-dialog :value="dialogDelete"  @click:outside="closeDeleteDialog" @keydown.enter="deleteItem" width="300">
      <v-card>
        <v-card-title class="headline">Confirmar a exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn text @click="closeDeleteDialog()">Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn text type="submit" right @click="deleteItem">Confirmar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog :value="dialogFilter" width="600" @click:outside="showFilter({show: false})" @keydown.enter="filter">
      <financial-moviments-filter></financial-moviments-filter>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import FinancialMovimentsForm from './FinancialMovimentsForm'
import FinancialMovimentsFilter from './FinancialMovimentsFilter'
//import JsonExcel from "vue-json-excel";
import { utils, writeFile } from "xlsx";

export default {
    name: "FinancialMoviments",
    created() {
      this.getBanks()
      this.getInstances()
      this.getItems({resetPage: false})
    },
    computed: {
      ...mapState('financial_moviments', ['items', 'notificationMessage', 'notificationType', 'totalItems']),
      ...mapFields('financial_moviments', ['dialog', 'dialogDelete', 'dialogFilter', 'loading', 'loading_financial', 'overlay', 'pagination', 'showNotification'])
    },
    components: {
      "financial-moviments-form": FinancialMovimentsForm,
      "financial-moviments-filter": FinancialMovimentsFilter,
      //"download-excel": JsonExcel,
    },
    data: () => ({
      headers: [
        { text: 'Tipo', value: "type", sortable: false },
        { text: 'Banco', value: "bank_name", sortable: false },
        { text: 'Valor', value: "value", sortable: false },
        { text: 'Descrição', value: "description", sortable: false },
        { text: 'Inicio', value: "date_start", sortable: false },
        { text: 'Fim', value: "finish_at", sortable: false },
        { text: 'Clube', value: "instance_name", sortable: false },
        { text: 'Criado Por', value: "user_start_name", sortable: false },
        { text: 'Finalizado Por', value: "user_finish_name", sortable: false },
        { text: 'Ações', value: "actions", sortable: false },
      ],
      sheet_fields: {
        Tipo: {
          field: "type",
          callback: (value) => {
            let types = ['Saques', 'Despesa', 'Depósito', 'Ajuste']
            return types[value]
          }
        },
        Banco: {
          field: "bank_name",
        },
        Valor: {
          field: "value",
          callback: (value) => {
            if (value) {
              let real = value.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              });
              return real.substr(3);
            }
            return 0;
          },
        },
        "Descrição": {
          field: "description",
        },
        "Inicio": {
          field: "date_start",
          callback: (value) => {   
            if(value) {
              var d = new Date(value);
              return d.toLocaleDateString() + " " + d.toLocaleTimeString() ;
            }
          },
        },
        "Fim": {
          field: "finish_at",
          callback: (value) => {   
            if(value) {
              var d = new Date(value);
              return d.toLocaleDateString() + " " + d.toLocaleTimeString() ;
            }
          },
        },
        "Clube": {
          field: "instance_name",
        },
        "Nick": {
          field: "nick",
        },
        "Criado Por": {
          field: "user_start_name",
        },
        "Finalizar Por": {
          field: "user_finish_name",
        },
      },
    }),
    methods: {
      ...mapMutations('financial_moviments', ['add', 'showFilter']),
      ...mapActions('financial_moviments', ['closeDeleteDialog', 'deleteItem', 'getItems', 'getItemsNoPage', 'showDeleteDialog']),
      ...mapActions("instances", ["getInstances"]),
      ...mapActions("bank", { getBanks: "getItems" }),
      getClassColor(item) {
        if(item.type == 0 || item.type == 1) {
          return 'row-red'
        } else if(item.type == 2) {
          return 'row-green'
        }
      },
      getDate(date) {
        if (date) {
          var d = new Date(date);
          return d.toLocaleDateString() + " " + d.toLocaleTimeString();
        }
        return "";
      },
      getType(type) {
        let types = ['Saques', 'Despesa', 'Depósito', 'Ajuste', 'Receita']
        return types[type]
      },
      getTextValue(type, value) {
        if(type == 0 || type == 1) {
          return '(-) ' + value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        } else if(type == 2) {
          return '(+) ' + value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        } else {
          return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        }
      },
      finishSheet() {
        this.overlay = false;
      },
      generateSheet() {
        this.overlay = true;
      },
      getSheetName() {
        var date = new Date();
        var name =
          "movimentacoes_financeiras_" +
          date.toLocaleDateString() +
          " " +
          date.toLocaleTimeString();
        return name + ".xls";
      },
      async downloadXlsx() {
        this.generateSheet()
        try {
          let itemsNoPage = await this.getItemsNoPage();
          if(itemsNoPage) {
            const wb = utils.book_new();
            let possible_types = ['Saques', 'Despesa', 'Depósito', 'Ajuste']
            let items = itemsNoPage.map(row => ({
              "Tipo":  possible_types[row.type],
              "Banco":  row.bank_name,
              "Valor": this.valueXLSX(row.value),
              "Descrição":  row.description,
              "Inicio":  this.dateXLSX(row.date_start),
              "Fim":  this.dateXLSX(row.finish_at),
              "Clube":  row.instance_name,
              "Nick":  row.nick,
              "Criado Por":  row.user_start_name,
              "Finalizado Por":  row.user_finish_name,
            }))
            const ws = utils.json_to_sheet(items);
            utils.book_append_sheet(wb, ws, "Despesas");
            await writeFile(wb, this.getSheetName());
          }
        } catch (error) {
          this.finishSheet()
        } finally {
          this.finishSheet()
        }
      },
      valueXLSX: (value) => {
        if (value) {
          let real = value.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          });
          return real.substr(3);
        }
        return 0;
      },
      dateXLSX: (value) => {   
        if(value) {
          var d = new Date(value);
          return d.toLocaleDateString() + " " + d.toLocaleTimeString() ;
        }
      },
    },
    watch: {
      pagination: {
        handler() {
          this.getItems({ resetPage: false });
        },
      },
  },
};
</script>
