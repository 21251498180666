<template>
  <div>
    <v-card>
      <v-card-title color="red">
          <span class="headline text-center justify-center">Filtro</span>

          <v-col class="text-right">
            <v-btn v-if="filtered == true" color="white" class="ma-2"  @click="clearFilter" right small depressed>
              <v-icon color="black">mdi-filter-remove</v-icon>
            </v-btn>
          </v-col>
      </v-card-title>

      <v-card-text>
        <v-form ref="form">
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm12 md12>
                <v-autocomplete
                  :clearable="true"
                  clear-icon="mdi-close"
                  label="Clube"
                  :items="slotsSuprema"
                  v-model="clubID"
                  item-text="name"
                  item-value="id_app"
                  outlined
                ></v-autocomplete>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn text @click="showFilter({show: false})">Cancelar</v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn text type="submit" right @click="filter">Filtrar</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
  </template>
  
  <script>
  import { mapActions, mapMutations, mapState } from "vuex";
  import { mapFields } from "vuex-map-fields";
  
  export default {
    name: "MeetingsFilter",
    computed: {
        ...mapState("slots", ["slots"]),
        ...mapFields("player_approval", [
          "filtered",
          "filter.clubID",
        ]),
        slotsSuprema() {
          return this.slots.filter((slot) => slot.plataform_id == 0);
        },
    },
    methods: {
        ...mapMutations("player_approval", ["showFilter", "clearFilter"]),
        ...mapActions("player_approval", ["getItems"]),
        filter() {
          this.getItems();
        },
    },
    data: () => ({
        showStart: false,
        showEnd: false,
        start_formatted: "",
        end_formatted: "",
    }),
  };
  </script>
  