<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline text-center justify-center">Filtro</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm6 md6>
                <v-menu
                  v-model="showStart"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="start_formatted"
                      label="De"
                      outlined
                      readonly
                      append-icon="mdi-close"
                      @click:append="clearStart()"
                      persistent-hint
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="pt-br"
                    v-model="start"
                    no-title
                    @input="(date) => setStart(date)"
                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-menu
                  v-model="showEnd"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="end_formatted"
                      label="Até"
                      outlined
                      readonly
                      append-icon="mdi-close"
                      @click:append="clearEnd()"
                      persistent-hint
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="pt-br"
                    v-model="end"
                    no-title
                    @input="(e) => setEnd(e)"
                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-autocomplete
                  label="Agente"
                  v-model="id_agent"
                  :items="agents"
                  :no-filter="true"
                  :search-input.sync="searchAgent"
                  item-text="nick"
                  item-value="id"
                  no-data-text="Nenhum agente encontrado"
                  placeholder="Digite aqui o nick do agente"
                  clearable
                  outlined
                ></v-autocomplete>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-select
                  label="Status"
                  clear-icon="mdi-close"
                  v-model="status"
                  :items="possible_status"
                  data-vv-name="id"
                  item-text="description"
                  item-value="id"
                  outlined
                ></v-select>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-select
                  label="Classificação / Prazo *"
                  v-model="rating"
                  :items="possible_ratings"
                  item-text="description"
                  item-value="id"
                  clear-icon="mdi-close"
                  clearable
                  outlined
                ></v-select>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-text-field
                  label="Valor da Dívida"
                  v-model="debt"
                  clearable
                  outlined
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn
            text
            @click="clearFilter()"
            >Cancelar</v-btn
          >
        </v-col>
        <v-col class="text-right">
          <v-btn text type="submit" right @click="filter">Filtrar</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import debounce from "../../../util/debounce.js";

export default {
  name: "BillingsFilter",
  computed: {
    ...mapState("billings", [
      "agents",
      "mode",
      "possible_ratings",
      "possible_status",
      "selectedItem",
    ]),
    ...mapFields("billings", [
      "filter.start",
      "filter.end",
      "filter.ag_group_id",
      "filter.date_created",
      "filter.debt",
      "filter.id_agent",
      "filter.observation",
      "filter.rating",
      "filter.responsible",
      "filter.responsible_contact",
      "filter.risk",
      "filter.status",
    ]),
  },
  methods: {
    ...mapMutations("billings", ["setDialog"]),
    ...mapActions("billings", ["getItems", "getAgents", "clearFilter"]),
    
    getStatusDescription(status) {
      return this.possible_status[status].description;
    },
    filter() {
      this.getItems();
      this.setDialog({ dialog: "filterDialog", show: false });
    },
    setStart(date) {
      this.start = date;
      this.showStart = false;
      this.start_formatted = this.formatDate(date);
    },
    setEnd(date) {
      this.end = date;
      this.showEnd = false;
      this.end_formatted = this.formatDate(date);
    },
    clearStart() {
      this.start = "";
      this.start_formatted = "";
    },
    clearEnd() {
      this.end = "";
      this.end_formatted = "";
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    },
  },
  mixins: [debounce],
  mounted() {
    this.getAgents = this.debounce(this.getAgents, 500);
  },
  data: () => ({
    showStart: false,
    showEnd: false,
    start_formatted: "",
    end_formatted: "",
    searchAgent: "",
  }),
  watch: {
    searchAgent(val) {
      var payload = {
        search: val,
      };
      this.getAgents(payload);
    },
  },
};
</script>
