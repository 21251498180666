import axios from "axios";
import { getField, updateField } from "vuex-map-fields";
import endPoints from "@config/endPoints";

export default {
  namespaced: true,
  state: {
    league_id: '',
    id: 0,
    items: [],
    itemsLeagueClosureClubEvents: [],
    filtered: false,
    loading: false,
    mode: "LIST",
    overlay: false,
    page: 1,
    pagination: {
      page: 1,
      itemsPerPage: 10,
    },
    filter: {
      id: '',
      idSubClub: '',
      id_league_closure_club_detail: '',
      value: '',
    },
    successNotification: false,
    notificationMessage: "",
    notificationType: "success",
    showNotification: false,
    totalItems: 0,
    totalItemsLeagueClosureClubEvents: 0,
    rowsPerPage: 10,
    start_formatted: '',
    finish_formatted: '',
    defaultClubClosureEvent: {
      id: null,
      id_league_closure_club_detail: null,
      id_league_closure_club: null,
      id_league_closure: null,
      league_closure_id: null,
      idDespesa: null,
      idFechamento: null,
      idSubClub: null,
      descricao: '',
      value: null,
    },
  },
  mutations: {
    loading: (state, payload) => (state.loading = payload.show),
    setMode: (state, mode) => (state.mode = mode),
    setFiltered: (state, filtered) => state.filtered = filtered,
    setItems: (state, items) => (state.items = items),
    setItemsLeagueClosureClubEvents: (state, items) => (state.itemsLeagueClosureClubEvents = items),
    setTotalItems: (state, total) => (state.totalItems = total),
    setTotalItemsLeagueClosureClubEvents: (state, total) => (state.totalItemsLeagueClosureClubEvents = total),
    setRowsPerPage: (state, rowsPerPage) => (state.rowsPerPage = rowsPerPage),
    setPage: (state, payload) => {
      state.pagination.page = payload.page;
      state.pagination.itemsPerPage = payload.itemsPerPage;
    },
    showErrorNotification: (state, show) => (state.errorNotification = show),
    showSuccessNotification: (state, show) =>
      (state.successNotification = show),
    showLoading: (state, show) => (state.loading = show),
    showNotification: (state, payload) => {
      (state.showNotification = payload.show),
      (state.notificationMessage = payload.message),
      (state.notificationType = payload.type);
    },
    showOverlay: (state, payload) => {
      state.overlay = payload.show;
    },
    updateField
  },
  actions: {
    getLeagueClosureClubDetailEvents: ({ commit, state }, payload) => {

      let url = endPoints.league_closure + "/league_closures_club_events"
      let filter = state.filter
      filter.id_league_closure_club_detail = payload.item.id
      url += '?id_league_closure_club_detail=' + filter.id_league_closure_club_detail
      /* commit('setFiltered', false)
      Object.keys(filter).forEach(function(field) {
        if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
          commit('setFiltered', true)         
          if (field == "finish_date" || field == "start_date") {
            var e = new Date(filter[field] + " 12:00:00")
            url += "&" + field + "=" + e
          } else {
              url += "&" + field + "=" + filter[field]
          }
        }
      }); */
      
      commit("loading", { show: true })
      axios.get(url).then((items) => {
        commit("setTotalItemsLeagueClosureClubEvents", items.data.total)
        commit("setItemsLeagueClosureClubEvents", items.data.data)
        commit("loading", { show: false })
      })
    },
    getItems: ({ commit, state }, payload) => {
      commit("loading", { show: true });
      let url = endPoints.league_closure_club_detail + "/" + payload.club_id     

      if (payload.resetPage === true) {
        url += "?page=" + 1 + "&" + "itemsPerPage=" + 10;
        commit("setPage", { page: 1, itemsPerPage: 10 });
      } else {
        url +=
          "?page=" +
          state.pagination.page +
          "&" +
          "itemsPerPage=" +
          state.pagination.itemsPerPage;
      }
      
      if (state.pagination && state.pagination.sortBy && state.pagination.sortBy.length > 0) {
        url += "&sort_by=" + state.pagination.sortBy[0]
        url += "&sort_desc=" + state.pagination.sortDesc[0]
      }
    
      axios.get(url).then((items) => {
        commit("setTotalItems", items.data.total);
        commit("loading", { show: false });
        commit("setItems", items.data.data);
      });
    },
  },
  getters: {
    getField,
  },
};
