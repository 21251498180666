<template>
  <v-card>
    <v-card-title class="justify-center">Encaminhar {{ this.forward_mode == 'RECEIPT' ? 'Comprovante' : 'Mensagem' }}</v-card-title>
    <v-card-text >
      <v-col class="d-flex" cols="12" sm="12" v-if="this.forward_mode != 'RECEIPT'">
        <v-autocomplete
          clearable
          deletable-chips
          v-model="selected_contacts"
          :no-filter="true"
          :items="contacts"
          outlined
          dense
          chips
          small-chips
          no-data-text="Nenhum contato encontrado"
          :item-text="getText"
          :return-object="true"
          placeholder="Digite aqui o nome do contato ou grupo"
          label="Contatos"
          multiple
          :search-input.sync="searchInput"
        ></v-autocomplete>
      </v-col>
      <v-col class="d-flex" cols="12" sm="12">
        <v-textarea
          auto-grow        
          label="Mensagem"
          background-color="white"
          outlined
          id="textareamessage"
          hide-details="true"
          rows=1
          v-model="textForward"
          :value="textForward"
        >
        </v-textarea>
      </v-col>
      <v-col class="d-flex" cols="12" sm="12">
        <v-select
          :items="instances"
          label="Whatsapp"
          outlined
          v-model="instance_id"
          item-text="name"
          item-value="id"
        ></v-select>
      </v-col>
    </v-card-text>
    <v-card-actions>
      <v-col>
        <v-btn text @click="showDialogForwardMessage({show: false, message: ''})">Cancelar</v-btn>
      </v-col>
      <v-col class="text-right">
        <v-btn text type="submit" right @click="forwardMessage({contacts: selected_contacts})">Encaminhar</v-btn>
      </v-col>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'
  import { mapFields } from 'vuex-map-fields'
  export default {
    props: [
      'contacts',
      'forwardMessage',
      'getContacts',
      'messageForward',
      'showDialog',
      'showDialogForwardMessage'
    ],
    computed: {
      ...mapFields('message', [
          'instance_id',
          'textForward',
      ]),
      ...mapGetters("instances", {instances: "instancesChat"}),
      ...mapState('message', ['forward_mode']),
    },
    data: () => ({
      selected_contacts: [],
      searchInput: '',
    }),
    methods: {
      customFilter(item){
        return item
      },
      getText(item) {
        if(item.name) {
          if(item.name.length == 1) {
            return item.name
          }
          
          var text = ""
          item.name.forEach((name) => {
            text += name + " | "
          })
          return text
        }
        else {
          return text
        }
      },
    },
    watch: {
      searchInput (val) {
        var payload = {
          search: val,
          contacts: this.selected_contacts
        }
        this.getContacts(payload)
      },
    },
  }
</script>

<style>
  .contato-autocomplete{
    margin: 10px !important;
  }
</style>
