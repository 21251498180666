import axios from 'axios';
import endPoints from '../../../config/endPoints';
import { getField, updateField } from 'vuex-map-fields';

const successNotification = {
    show: true,
    message: 'Solicitação salva com sucesso',
    type: 'success'
}

const generateErrorNotification = (message) => {
    return {
        show: true,
        message: message,
        type: 'error'
    }
}

const defaultErrorMessage = 'Houve um erro...'

const defaultRequestItem = {
    id: 0,
    title: '',
    description: '',
    department_origin: '',
    department_destination: '',
    status: 0,
    observation: ''
}

const defaultFilter = {
    department_origin: '',
    department_destination: '',
    title: '',
    description: '',
    status: 0
}

export default {
    namespaced: true,
    state: {
        items: [],
        loading: false,
        selectedItem: {},
        showDialog: false,
        deleteDialog: false,
        filterDialog: false,
        filtered: false,
        creation_date: new Date(),
        page: 0,
        mode: '',
        notification: {
            show: false,
            message: '',
            type: ''
        },
        filter: {
            department_origin: '',
            department_destination: '',
            title: '',
            description: '',
        }
    },
    mutations: {
        add: (state) => { 
            state.showDialog = true
            state.mode = "ADD"
            state.selectedItem = Object.assign({}, defaultRequestItem)
        },
        edit: (state, payload) => {
            state.showDialog = true
            state.mode = "EDIT"
            state.selectedItem = Object.assign({}, defaultRequestItem)
            state.selectedItem = payload
        },
        deleteRequest: (state, payload) => {
            state.deleteDialog = true
            state.selectedItem = payload
        },
        refreshItems: (state, payload) => {
            state.items = payload
        },
        setItems: (state, payload) => {
            state.items.push(...payload)
        },
        resetItems: (state) => {
            state.items.length = 0
        },
        clearFilter: (state) => {
            state.filter = Object.assign({}, defaultFilter)
        },
        setLoading: (state, payload) => state.loading = payload,
        setMode: (state, payload) => state.mode = payload,
        setSelectedItem: (state, payload) => state.selectedItem = payload,
        setShowDialog: (state, payload) => state.showDialog = payload,
        showNotification: (state, payload) => state.notification = payload,
        setDeleteDialog: (state, payload) => state.deleteDialog = payload,
        showFilter: (state, payload) => state.filterDialog = payload,
        setFiltered: (state, payload) => state.filtered = payload,
        setPage: (state, payload) => state.page = payload,
        updateField
    },
    getters: {
        getField
    },
    actions: {
        refreshItems: async ( {commit} ) => {
            commit('setLoading', true)
            let url = `${endPoints.requests}?page=0`
            try {
                let items = await axios.get(url)
                commit('refreshItems', items.data)
                commit('setPage', 1)
                commit('setLoading', false)
            } catch(error) {
                commit('setLoading', false)
                // GenerateErrorNotification gera um objeto no formato de uma notification e recebe como parâmetro uma string, 
                // que será retornada como a notification.message
                commit('showNotification', generateErrorNotification(error?.response?.data?.message ?? defaultErrorMessage))
            }
        },
        getItems: async ( {commit, state} ) => {
            commit('setLoading', true)
            let filter = state.filter
            let url = `${endPoints.requests}?page=${state.page}`

            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {                    
                    url += "&" + field + "=" + filter[field]
                }
            })
            try {
                let items = await axios.get(url)
                if(state.page === 0 && state.filtered) {
                    commit('resetItems', {})
                }
                commit('setItems', items.data)
                commit('setPage', ++state.page)
                commit('setLoading', false)
            } catch(error) {
                commit('setLoading', false)
                commit('showNotification', generateErrorNotification(error?.response?.data?.message ?? defaultErrorMessage))
            }
        },
        interact: async ( {commit, state}, payload ) => {
            commit('setLoading', true)
            let url = endPoints.requests_interactions
            try {
                await axios.post(`${url}`, payload)
                for(let i = 0; i < state.items.length; i++){
                    if(state.items[i].id == payload.request_id){
                        if(payload.type == '1'){
                            state.items[i].likes = (parseInt(state.items[i].likes) + 1).toString()
                            state.items[i].is_liked_by_user = "1"
                        } else {
                            state.items[i].dislikes = (parseInt(state.items[i].likes) + 1).toString()
                            state.items[i].is_disliked_by_user = "1"
                        }
                        break
                    }
                }
                commit('setLoading', false)
            } catch(error) {
                commit('showNotification', generateErrorNotification(error?.response?.data?.message ?? defaultErrorMessage))
                commit('setLoading', false)
            }   
        },
        deleteInteraction: async ( {commit, state}, payload) => {
            commit('setLoading', true)
            let url = endPoints.requests_interactions
            try {
                let targetInteraction = await axios.get(`${url}/${payload.id}`)
                let interactionID = targetInteraction.data.id
                await axios.delete(`${url}/${interactionID}`)
                commit('setLoading', false)
                for(let i = 0; i < state.items.length; i++) {
                    if(state.items[i].id == targetInteraction.data.request_id){
                        if(targetInteraction.data.type == '1'){
                            state.items[i].likes = (parseInt(state.items[i].likes) - 1).toString()
                            state.items[i].is_liked_by_user = '0'
                        } else {
                            state.items[i].dislikes = (parseInt(state.items[i].likes) - 1).toString()
                            state.items[i].is_disliked_by_user = '0'
                        }
                        break
                    }
                }
            } catch(error) {
                commit('showNotification', generateErrorNotification(error?.response?.data?.message ?? defaultErrorMessage))
                commit('setLoading', false)
            }  
        },
        updateInteraction: async ( {commit, state}, payload) => {
            commit('setLoading', true)
            let url = endPoints.requests_interactions
            try {
                let targetInteraction = await axios.get(`${url}/${payload.request_id}`)
                payload.id = targetInteraction.data.id
                await axios.put(`${url}/${payload.id}`, payload)
                commit('setLoading', false)
                for(let i = 0; i < state.items.length; i++) {
                    if(state.items[i].id == targetInteraction.data.request_id){
                        if(targetInteraction.data.type == '1'){
                            state.items[i].likes = (parseInt(state.items[i].likes) - 1).toString()
                            state.items[i].dislikes = (parseInt(state.items[i].dislikes) + 1).toString()
                            state.items[i].is_liked_by_user = '0'
                            state.items[i].is_disliked_by_user = '1'
                        } else {
                            state.items[i].likes = (parseInt(state.items[i].likes) + 1).toString()
                            state.items[i].dislikes = (parseInt(state.items[i].dislikes) - 1).toString()
                            state.items[i].is_liked_by_user = '1'
                            state.items[i].is_disliked_by_user = '0'
                        }
                        break
                    }
                }
            } catch(error) {
                commit('showNotification', generateErrorNotification(error?.response?.data?.message ?? defaultErrorMessage))
                commit('setLoading', false)
            }
        },
        save: async ( {commit, state} ) => {
            let url = endPoints.requests
            let func = ''
            state.mode === 'ADD' ? func = axios.post : (func = axios.put, url += `/${state.selectedItem.id}`)
            try {
                let data = new Date()
                state.selectedItem.creation_date = `${data.getFullYear()}-${data.getMonth() + 1}-${data.getDate()}`
                let response = await func(url, state.selectedItem)
                if(func === axios.put) {
                    for(let i = 0; i < state.items.length; i++){
                        if(state.items[i].id == response.data.id) {
                            state.items[i].department_destination = response.data.department_destination
                            state.items[i].department_origin = response.data.department_origin
                            state.items[i].description = response.data.description
                            state.items[i].title = response.data.title
                            state.items[i].observation = response.data.observation
                        }
                        break
                    }
                } else {
                    let data = response.data
                    state.items.push({
                        creation_date: data.creation_date,
                        department_destination: data.department_destination,
                        department_origin: data.department_origin,
                        description: data.description,
                        dislikes: '0',
                        likes: '0',
                        id: data.id,
                        is_disliked_by_user: '0',
                        is_liked_by_user: '0',
                        title: data.title,
                        user_id: data.user_id,
                        observation: data.observation,
                        status: 0
                    })
                }
                
                commit('showNotification', successNotification)
                commit('setShowDialog', false)
                commit('setSelectedItem', {})
            } catch(error) {
                commit('showNotification', generateErrorNotification(error?.response?.data?.message ?? defaultErrorMessage))
            }
        },
        removeRequest: async ( {commit, state} ) => {
            commit('setLoading', true)
            try {
                await axios.delete(`${endPoints.requests}/${state.selectedItem.id}`)
                state.items = state.items.filter(item => item.id !== state.selectedItem.id)
                commit('setLoading', false)
                commit('setDeleteDialog', false)
                commit('setSelectedItem', {})
            } catch(error) {
                commit('showNotification', generateErrorNotification(error?.response?.data?.message ?? defaultErrorMessage))
                commit('setLoading', false)
            }
        },
    }
}