import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../config/endPoints';

const defaultPlayerId = {
    id_player: null,
    id_pppoker: "",
};

const defaultPlayerAccount = {
    id_player: null,
    bank: '',
    bank_name: '',
    agency: '',
    account: '',
    pix_key: '',
    type: 0
}

export default {
    namespaced: true,
    state: {
        agent: {},
        dialog: false,
        contacts: [],
        documents: [],
        editedItem: {
            id_pppoker: ''
        },
        itemToDelete: {},
        loading_player: false,
        loading_player_chat: false,
        mode: 'LIST',
        mode_chat: 'VIEW',
        page: 1,
        player: {},
        player_accounts_chat: [],
        player_ids_chat: [],
        pagination_idrelated: {
            page: 1,
            itemsPerPage: 10
        },
        pagination_player_ids_chat: {
            page: 1,
            itemsPerPage: 10
        },
        pagination_player_accounts_chat: {
            page: 1,
            itemsPerPage: 10
        },
        player_chat: {},
        notificationMessage: '',
        notificationType: "success",
        showNotification: false,
        totalIdRelated: 0,
        totalPlayerIds: 0,
        totalPlayerIdsChat: 0, 
        totalAccountsChat: 0, 
    },
    mutations: {
        addPlayerId: (state) => {
            let d = Object.assign({}, defaultPlayerId)
            d.id_player = state.player_chat.id
            d.edited = 1
            state.player_ids_chat.unshift(d) 
        },
        addPlayerAccount: (state) => {
            let d = Object.assign({}, defaultPlayerAccount)
            d.id_player = state.player_chat.id
            d.edited = 1
            state.player_accounts_chat.unshift(d) 
        },
        editPlayerChat: (state) => {
            state.mode_chat = 'EDIT'
        },
        closePlayerChat: (state) => {
            state.mode_chat = 'VIEW'  
        },
        loading_id_related: (state, payload) => state.loading_id_related = payload.loading,
        loading_player: (state, payload) => state.loading_player = payload.loading,
        showNotification: (state, payload) => {
            state.showNotification = payload.show,
                state.notificationMessage = payload.message,
                state.notificationType = payload.type
        },
        setPlayer: (state, payload) => state.player = payload.player,
        setPlayerChat: (state, payload) => {
            state.player_chat = payload.player
        },
        setPlayerIdsChat: (state, payload) => {
            state.totalPlayerIdsChat = payload.player_ids_chat.length
            state.player_ids_chat = payload.player_ids_chat
        }, 
        setPlayerAccountsChat: (state, payload) => {
            state.totalAccountsChat = payload.player_accounts.length
            state.player_accounts_chat = payload.player_accounts
        },
        updateField
    },
    getters: {
        getIdPlayer: state => {
            return state.player.id
        },
        getField,
    },
    actions: {
        closePlayerData: ({commit}) => {
            commit('closePlayerChat')
            commit('chat/showPlayerData', {show: false}, {root: true})
        },
        getPlayer: ({ commit }, payload) => {
            commit('loading_player', { loading: true })
            let url = endPoints.players + "/player/" + payload.player_id;

            axios.get(url).then(response => {
                commit('setPlayer', { player: response.data })
                commit('loading_player', { loading: false })
            });
        },
        goBack: ({ dispatch }) => {
            dispatch('menu/openLink', '/agents', { root: true })
        },
        savePlayerChat: ({commit, dispatch, state}) => {
            let url = endPoints.players + "/" + state.player_chat.id 
            if (state.player_chat.birth_date !== null && state.player_chat.birth_date.indexOf("/") !== -1 && state.player_chat.birth_date.length === 10) {
                let splittedDate = state.player_chat.birth_date.split('/')
                state.player_chat.birth_date = `${splittedDate[2]}-${splittedDate[1]}-${splittedDate[0]}`
            }
            state.player_chat.pppoker_accepted = state.player_chat.pppoker_accepted ? 1 : 0
            state.player_chat.bonus = state.player_chat.bonus ? 1 : 0

            axios.put(url, state.player_chat).then(() => {
                let url_ids = endPoints.players + "/ids_pppoker" 
                let url_accounts = endPoints.players_accounts
                let new_ids = state.player_ids_chat.filter((id) => id.edited == 1)
                let new_accounts = state.player_accounts_chat.filter((acc) => acc.edited == 1)
                new_ids.forEach((id) => {
                    axios.post(url_ids, id).then().catch(() => {
                        let notification = {
                            show: true,
                            message: "Ocorreu um erro ao salvar o id: " + id.id_pppoker,
                            type: "error"
                        }
                        commit('message/showNotification', notification, { root: true })
                    })
                })
                new_accounts.forEach((acc) => {
                    axios.post(url_accounts, acc).then().catch(() => {
                        let notification = {
                            show: true,
                            message: "Ocorreu um erro ao salvar a conta",
                            type: "error"
                        }
                        commit('message/showNotification', notification, { root: true })
                    })
                })
                let notification = {
                    show: true,
                    message: "Atualizando dados!",
                    type: "success"
                }
                dispatch('closePlayerData')
                commit('showNotification', notification)
            }).catch(() => {
                let notification = {
                    show: true,
                    message: "Não foi possivel atualizar os dados do jogador!",
                    type: "error"
                }
                commit('showNotification', notification)
            })
        },
    }
}