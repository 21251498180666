<template>
    <div>
        <v-toolbar flat>
            <v-icon color="black" left>mdi-cash-multiple</v-icon>
            <v-toolbar-title><b> Itens da Conta</b></v-toolbar-title>
        </v-toolbar>
        <v-data-table
            :headers="headers"
            :loading="loading"
            :items="items"
            :options.sync="pagination"
            :server-items-length="totalItems"
            class=" mt-0"
            no-data-text="Nenhum item encontrado"
        >
            <template v-slot:[`item.value`]="{ item }">
                <span>{{ formatMoney(item.value) }}</span>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import { formatDate, formatMoney } from '@util/format_util'

export default {
    name: 'BillsItems',

    created() {
        this.getItems()
    },

    methods: {
        ...mapActions('bills_items', ['getItems', 'deleteItem']),
        ...mapMutations('bills_items', [
            'setShowDialog',
            'add',
            'edit',
            'view',
            'setShowFilter',
            'setDeleteDialog',
            'deleteCC',
        ]),
        formatMoney(value) {
            return formatMoney(value)
        },
        formatDate(date) {
            return formatDate(date)
        },
        formatDateDue(date) {
            let day = date.split('-')[2]
            let month = date.split('-')[1]
            let year = date.split('-')[0]
            return `${day}/${month}/${year}`
        },
        getStatusDescription(status) {
            switch (status) {
                case 0:
                    return 'Pendente'
                case 1:
                    return 'Concluído'
            }
        },
    },
    computed: {
        ...mapState('slots', ['slots']),
        ...mapState('bills_items', [
            'items',
            'notification',
            'mode',
            'loading',
            'totalItems',
            'showDialog',
            'filterDialog',
        ]),
        ...mapFields('bills_items', ['pagination']),
    },
    data: () => ({
        headers: [
            { text: 'ID', value: 'id', sortable: true, align: 'center' },
            {
                text: 'Descrição',
                value: 'description',
                sortable: false,
                align: 'center',
            },
            {
                text: 'ID Conta',
                value: 'id_bill',
                sortable: false,
                align: 'center',
            },
            { text: 'Valor', value: 'value', sortable: false, align: 'center' },
            {
                text: 'ID Externo',
                value: 'external_id',
                sortable: false,
                align: 'center',
            },
        ],
    }),
    watch: {
        pagination: {
            handler() {
                this.getItems()
            },
        },
    },
}
</script>
