<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline text-center justify-center">Filtro</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md>
            <v-layout wrap>

              <v-flex xs12 sm6 md12>
                <v-text-field
                  label="ID"
                  v-model="id"
                  :clearable="true"
                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 md12>
                <v-text-field
                  label="Nome"
                  v-model="name"
                  :clearable="true"
                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 md12>
                <v-text-field
                  label="Porcentagem de Taxa"
                  v-model="percentage"
                  :clearable="true"
                  outlined
                ></v-text-field>
              </v-flex>

            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn
            text
            @click="
              setShowFilter(false);
              clearFilter();
            "
            >Cancelar</v-btn
          >
        </v-col>
        <v-col class="text-right">
          <v-btn text type="submit" right @click="filter">Filtrar</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "LeagueFilter",
  computed: {
    ...mapState("league", ["mode", "selectedItem", "showDialog"]),
    ...mapFields("league", [
      "filter.id",
      "filter.name",
      "filter.percentage"
    ]),
  },   
  methods: {
    ...mapMutations("league", ["setShowFilter", "clearFilter"]),
    ...mapActions("league", ["getItems"]),
    filter() {
      this.getItems();
      this.setShowFilter(false);
    },
  },
  data: () => ({
  }),
};
</script>
