<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2000"
      :value="showNotification"
    >
      {{ notificationMessage }}
    </v-snackbar>
    <v-tabs v-model="tab" class="agent-data elevation-10">
      <v-tab>Fechamentos</v-tab>
      <v-tab>Pagamento</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item class="elevation-10">
        <v-toolbar flat class="elevation-5">
          <div>
            <v-toolbar-title >Fechamento Por Clube</v-toolbar-title>
          </div>
          <v-spacer></v-spacer>
          <div>
            <v-toolbar-title>Id Fechamento: {{club_id}}</v-toolbar-title>
          </div>
        </v-toolbar>

        <v-data-table
          :headers="headers"
          :loading="loading"
          :items="items"
          :options.sync="pagination"
          :server-items-length="totalItems"
          no-data-text="Nenhum item encontrado"
          class="elevation-10 table-margin"
        >
          <template v-slot:[`item.rake`]="{ item }">
            <span>{{
              item.rake.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })
            }}</span>
          </template>
          <template v-slot:[`item.value`]="{ item }">
            <span>{{
              item.value.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })
            }}</span>
          </template>
          <template v-slot:[`item.gains_loses`]="{ item }">
            <span>{{
              item.gains_loses.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })
            }}</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{on, attrs}">
                <v-icon small v-on="on" v-bind="attrs" color="yellow darken-3" class="mr-2" @click="viewItem(item)">
                    mdi-information
                </v-icon>
              </template>
              <span>Informações</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-tab-item>
      <v-tab-item>
        <LeagueClosurePayments/>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import LeagueClosurePayments from "./LeagueClosurePayments.vue"


export default {
  name: "LeagueClosureClub",
  computed: {
    ...mapState("league_closure_club", [
      "item",
      "loading",
      "mode",
      "notificationType",
      "notificationMessage",
      "rowsPerPage",
      "totalItems",
      "items",
      "overlay",
      "showNotification",
    ]),
    ...mapFields("league_closure_club", ['pagination'])
  },
  components: {
    LeagueClosurePayments,
  },
  created() {
    let club_id = this.$route.params.club_id;
    this.club_id = club_id
    this.getItems({ resetPage: false, club_id: club_id});
  },
  methods: {
    ...mapMutations("league_closure_club", ["setLeagueClosureId"]),
    ...mapActions("league_closure_club", [
      "getItems",
      "viewItem",
      "addClubDetail"
    ]),
    reload(){
      let club_id = this.$route.params.club_id;
      this.getItems({ resetPage: false, club_id: club_id});    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      }
      return false;
    },
    
  },
  data: () => ({
    tab: '', 
    headers: [
      { text: "ID", value: "id", sortable: false },
      { text: "Slot", value: "slot_name", sortable: false },
      { text: "Ganhos e Perdas", value: "gains_loses", sortable: false },
      { text: "Rake", value: "rake", sortable: false },
      { text: "Resultado", value: "value", sortable: false },
      { text: "Liga", value: "league", sortable: false },
      { text: "Ações", value: "actions", sortable: false },
    ],
    valid: false,
    timeProps: {
      format: "24hr",
    },
  }),
  watch: {
    pagination: {
      handler() {
        let club_id = this.$route.params.club_id;
        this.getItems({ resetPage: false, club_id: club_id});
        this.setLeagueClosureId(club_id);
      },
    },
  },
};
</script>

<style>

</style>
