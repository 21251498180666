<template>
    <div>
        <v-card>
            <v-card-title>
                <span class="headline text-center" v-if="this.mode == 'VIEW'"
                    >Informações da Ordem</span
                >
                <span class="headline text-center" v-else
                    >Criar Ordem Manual</span
                >
                <v-spacer></v-spacer>
                <v-progress-circular
                    v-if="loading"
                    indeterminate
                    class="text-right"
                    color="primary"
                    size="24"
                ></v-progress-circular>
            </v-card-title>

            <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12 sm6 md6 v-if="this.mode == 'VIEW'">
                                <v-text-field
                                    required
                                    label="ID"
                                    v-model="id"
                                    data-vv-name="id"
                                    outlined
                                    :readonly="this.mode == 'VIEW'"
                                ></v-text-field>
                            </v-flex>

                            <v-flex xs12 sm6 md6>
                                <v-select
                                    required
                                    label="Tipo *"
                                    :items="possible_types"
                                    :rules="[rules.requiredAcceptZero]"
                                    data-vv-name="type"
                                    item-text="text"
                                    item-value="id"
                                    v-model="type"
                                    outlined
                                    :readonly="this.mode == 'VIEW'"
                                >
                                </v-select>
                            </v-flex>

                            <v-flex xs12 sm6 md6>
                                <v-autocomplete
                                    required
                                    label="Origem *"
                                    :items="originsManual"
                                    :rules="[rules.requiredAcceptZero]"
                                    data-vv-name="origin"
                                    item-text="description"
                                    item-value="id"
                                    v-model="origin"
                                    outlined
                                    :readonly="this.mode == 'VIEW'"
                                >
                                </v-autocomplete>
                            </v-flex>

                            <v-flex xs12 sm6 md6 v-if="this.mode == 'VIEW'">
                                <v-text-field
                                    required
                                    label="Slot"
                                    v-model="slot_name"
                                    data-vv-name="slot_name"
                                    outlined
                                    :readonly="this.mode == 'VIEW'"
                                ></v-text-field>
                            </v-flex>

                            <v-flex xs12 sm6 md6 v-else>
                                <v-autocomplete
                                    label="Slot"
                                    placeholder="Digite aqui o nome do slot"
                                    no-data-text="Nenhum slot encontrado"
                                    v-model="slotSelected"
                                    item-value="id_app"
                                    item-text="name"
                                    :items="slots"
                                    deletable-chips
                                    outlined
                                    :readonly="this.mode == 'VIEW'"
                                ></v-autocomplete>
                            </v-flex>

                            <v-flex xs12 sm6 md6>
                                <v-text-field
                                    required
                                    label="Valor *"
                                    v-model="value"
                                    prefix="R$"
                                    :rules="[rules.required]"
                                    data-vv-name="value"
                                    outlined
                                    :readonly="this.mode == 'VIEW'"
                                ></v-text-field>
                            </v-flex>

                            <v-flex xs12 sm6 md6>
                                <v-text-field
                                    required
                                    label="ID App *"
                                    v-model="player_id"
                                    :rules="[rules.required]"
                                    data-vv-name="player_id"
                                    outlined
                                    :readonly="this.mode == 'VIEW'"
                                ></v-text-field>
                            </v-flex>

                            <v-flex xs12 sm6 md6>
                                <v-text-field
                                    v-bind="props"
                                    v-if="origin != 16"
                                    required
                                    :label="
                                        origin == 16
                                            ? 'ID Externo'
                                            : 'ID Externo *'
                                    "
                                    v-model="external_id"
                                    data-vv-name="external_id"
                                    :disabled="origin == 16"
                                    :readonly="
                                        origin == 16 || this.mode == 'VIEW'
                                    "
                                    :rules="
                                        origin != 16
                                            ? [rules.requiredNumber]
                                            : []
                                    "
                                    outlined
                                ></v-text-field>
                            </v-flex>

                            <v-flex xs12 sm6 md6 v-if="this.mode == 'VIEW'">
                                <v-text-field
                                    required
                                    readonly
                                    label="Bot "
                                    v-model="bot_origin"
                                    data-vv-name="bot_origin"
                                    outlined
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm12 md12>
                                <v-textarea
                                    required
                                    label="Observação *"
                                    v-model="description"
                                    :rules="[rules.required]"
                                    data-vv-name="description"
                                    outlined
                                    :rows="3"
                                    :readonly="this.mode == 'VIEW'"
                                ></v-textarea>
                            </v-flex>
                            <v-expansion-panels v-if="this.mode == 'VIEW'">
                                <v-expansion-panel>
                                    <v-expansion-panel-header>Auditoria</v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-layout wrap>
                                            <v-flex xs12 sm6 md6 v-if="this.mode == 'VIEW'">
                                                <v-text-field
                                                    required
                                                    readonly
                                                    label="Início"
                                                    :value="getDate(created_at)"
                                                    data-vv-name="date"
                                                    outlined
                                                ></v-text-field>
                                            </v-flex>
                                            <v-flex xs12 sm6 md6 v-if="this.mode == 'VIEW'">
                                                <v-text-field
                                                    required
                                                    readonly
                                                    label="Criado por "
                                                    :value="getNameUser(user_created_name)"
                                                    data-vv-name="user_created_name"
                                                    outlined
                                                ></v-text-field>
                                            </v-flex>
                                            <v-flex xs12 sm6 md6 v-if="this.mode == 'VIEW'">
                                                <v-text-field
                                                    required
                                                    readonly
                                                    label="Finalizado em"
                                                    :value="getDate(finish_at)"
                                                    data-vv-name="finish_at"
                                                    outlined
                                                ></v-text-field>
                                            </v-flex>

                                            <v-flex xs12 sm6 md6 v-if="this.mode == 'VIEW'">
                                                <v-text-field
                                                    required
                                                    readonly
                                                    label="Finalizado por"
                                                    :value="getNameUser(user_finish_name)"
                                                    data-vv-name="user_finish_name"
                                                    outlined
                                                ></v-text-field>
                                            </v-flex>
                                        </v-layout>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-layout>
                    </v-container>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-col>
                    <v-btn
                        v-if="this.mode == 'VIEW'"
                        text
                        @click="closeDialogInfo"
                    >
                        Voltar</v-btn
                    >
                    <v-btn text v-else @click="closeBotsFormDialog">
                        Cancelar</v-btn
                    >
                </v-col>
                <v-col class="text-right" v-if="mode != 'VIEW'">
                    <v-btn
                        text
                        type="submit"
                        right
                        :disabled="block_click"
                        @click="saveOrder"
                        >Salvar</v-btn
                    >
                </v-col>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import debounce from '../../../util/debounce.js'

export default {
    name: 'BotsForm',
    computed: {
        originsManual() {
            return this.origins.filter((origin) => origin.allow_manual == true)
        },
        ...mapGetters('login', ['getInstanceId', 'userData']),
        ...mapState('bots', [
            'loading',
            'mode',
            'instances',
            'origins',
        ]),
        ...mapState('agent_slot', ['plataform_types']),
        ...mapState('slots', ['slots']),

        ...mapFields('bots', [
            'selectedItem.id',
            'selectedItem.type',
            'selectedItem.club', // não precisa de club
            'selectedItem.slot',
            'selectedItem.value',
            'selectedItem.status',
            'selectedItem.created_at',
            'selectedItem.finish_at',
            'selectedItem.external_id',
            'selectedItem.player_id',
            'selectedItem.slot_name',
            'selectedItem.user_finish_name',
            'selectedItem.bot_origin',
            'selectedItem.user_created_name',
            'selectedItem.date_created',
            'selectedItem.origin',
            'selectedItem.user_ip',
            'selectedItem.id_instance',
            'selectedItem.description',
            'selectedItem.user_name',
            'selectedItem.user_id',
            'selectedItem.platform',
            'clicked',

            // platform: null,
            // ...
        ]),
    },
    created() {
        this.getPossiblesOrigins()
        this.$validator.extend('required', {
            getMessage: (field, [label]) => label + ' deve ser informado',
            validate: (_, [, field]) => {
                if (this[field] || this[field] === 0 || this.mode == 'VIEW') {
                    return true
                } else {
                    return false
                }
            },
        })
        this.getSlots({ resetPage: false, noPages: true, inactive: false })
    },
    mixins: [debounce],
    methods: {
        ...mapMutations('bots', [
            'closeBotsFormDialog',
            'closeDialogInfo',
            'showNotification',
        ]),
        ...mapActions('bots', ['save', 'searchSlots', 'getPossiblesOrigins']),
        ...mapActions('slots', ['getSlots']),
        getDate(date) {
            if (date != null && date != '') {
                var d = new Date(date)
                return d.toLocaleDateString() + ' ' + d.toLocaleTimeString()
            } else {
                return '--------'
            }
        },
        getNameUser(user) {
            if (user != null && user != '') {
                return user
            } else {
                return '--------'
            }
        },
        async saveOrder() {
            let valid = this.$refs.form.validate()
            console.log(this.$refs.form, "Slot: ", this.slotSelected)
            if (!valid) return
            const slot = this.slots.find(
                (s) => s.id_app == this.slotSelected
            )
            this.platform = slot.plataform_id
            console.log("Plataforma: ", this.platform)
            if(typeof value === 'string') {
                this.value = this.value.replace(',', '.')
            }
            if (this.origin == 16 && this.value > 1) {
                valid = false
                let notification = {
                    show: true,
                    message:
                        'Não é possível criar uma ordem desse tipo com valor maior que R$ 1,00',
                    type: 'error',
                }
                this.showNotification(notification)
            }

            this.slot = this.slotSelected
            this.block_click = true

            var date = new Date()
            this.date_created =
                date.toISOString().substr(0, 10) +
                ' ' +
                date.toLocaleTimeString()

            this.save()
                .then(() => {
                    this.block_click = false
                })
                .catch(() => {
                    this.block_click = false
                })
        },
    },
    data: () => ({
        slotSelected: null,
        rules: {
            onlyNumbers(value) {
                return !!parseInt(value) || 'Valor deve ser númerico'
            },
            decimal(value) {
                let regex = /^[-]?\d*(\.\d+)?$/
                return regex.test(value) || 'Valor deve ser númerico'
            },
            required(value) {
                return !!value || 'Campo Obrigatório'
            },
            requiredNumber(value) {
                if (!value) return 'Campo Obrigatório'
                return !!parseInt(value) || 'Um número deve ser informado'
            },
            requiredAcceptZero(value) {
                console.log('value', value)
                return !!value || value === 0 || 'Campo Obrigatório'
            },
            max_length(value, max_length) {
                if (value && value.length > max_length)
                    return 'Máximo de ' + max_length + ' caracteres.'
                return true
            },
        },
        possible_types: [
            { id: 0, text: 'Saque' },
            { id: 1, text: 'Depósito' },
        ],
        searchInput: '',
        block_click: false,
        valid: false,
    }),
    watch: {
        searchInput(val) {
            if (val) {
                var payload = {
                    search: val,
                }
                this.searchSlots(payload)
            }
        },
    },
}
</script>
