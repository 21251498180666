<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar
      :color="notification.type"
      right
      top
      :timeout="2000"
      v-model="notification.show"
    >
      {{ notification.message }}
    </v-snackbar>

    <v-toolbar flat class="elevation-5">
      <div>
        <v-toolbar-title>Avaliações</v-toolbar-title>
      </div>
      <v-spacer></v-spacer>
      <div class="button-toolbar">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="black"
              dark
              class="ma-2"
              @click="setShowFilter(true)"
              v-bind="attrs"
              v-on="on"
              small
            >
              <v-icon color="white">mdi-filter</v-icon>
            </v-btn>
          </template>
          <span>Filtro</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="black"
              dark
              class="ma-2"
              @click="getItems"
              v-bind="attrs"
              v-on="on"
              small
              ><v-icon>mdi-refresh</v-icon></v-btn
            >
          </template>
          <span>Recarregar</span>
        </v-tooltip>

      </div>
    </v-toolbar>

    <v-dialog
      :value="showDialog"
      @click:outside="setShowDialog(false)"
      width="1000"
    >
      <avaliations-form></avaliations-form>
    </v-dialog>

    <v-data-table
      :headers="headers"
      :loading="loading"
      :items="items"
      :options.sync="pagination"
      :server-items-length="totalItems"
      class="elevation-10 table-margin"
      no-data-text="Nenhuma avaliação encontrada"
    >

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ attrs, on }">
            <v-icon
              small
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="view(item)"
              >mdi-information</v-icon
            >
          </template>
          
          <span>Informações</span>
        </v-tooltip>

      </template>
      
       <template v-slot:[`item.score`]="{ item }">
        <v-chip :color="getColorScore(item.score)" class="ma-2" text-color="white" label>{{ getAvaliationDescription(item.score) }}</v-chip>
      </template>
       <template v-slot:[`item.status`]="{ item }">
        <v-chip :color="getColorStatus(item.status)" class="ma-2" text-color="white" label>{{ getStausDescription(item.status) }}</v-chip>
        <!-- <span class="ma-2">{{ getStausDescription(item.status) }}</span> -->
      </template>
       <template v-slot:[`item.finish_at`]="{ item }">
        <span class="ma-2">{{ getDate(item.finish_at) }}</span>
      </template>
       <template v-slot:[`item.created_at`]="{ item }">
        <span class="ma-2">{{ getDate(item.created_at) }}</span>
      </template>

    </v-data-table>

    <v-dialog
      :value="filterDialog"
      @click:outside="setShowFilter(false)"
      width="600"
    >
      <avaliations-filter></avaliations-filter>
    </v-dialog>


  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import AvaliationsFilter from "./AvaliationsFilter";
import AvaliationsForm from "./AvaliationsForm";

export default {
  name: "Avaliation",

  created() {
    this.getItems({ resetPage: false })
  },

  components: {
    AvaliationsFilter,
    AvaliationsForm,
  },

  methods: {
    ...mapActions("avaliations", ["getItems",]),
    ...mapMutations("avaliations", [
      "view",
      "setShowFilter",
      "setShowDialog",
      "setSelectedItem"
    ]),
    getAvaliationDescription(avaliation){
      return this.possible_avaliations[avaliation].avaliation
      },
    getStausDescription(status){
      status -=1
      return this.possible_status[status].description
      
    },
    getColorScore(status) {
      if(status == 0) {
        return 'light-blue darken-3'
      } else if(status == 1) {
        return 'deep-orange darken-4'
      }else if(status == 2) {
        return 'yellow darken-3'
      }else{
        return 'green darken-3'
      }
    },
    getColorStatus(status) {
      if(status == 1) {
        return 'light-blue darken-3'
      } else {
        return 'green darken-3'
      }
    },
      
    getDate(date) {
      if (date) {
        var d = new Date(date);
        return d.toLocaleDateString() + " " + d.toLocaleTimeString();
      }
      return "";
    },
  },
  computed: {
    ...mapState("login", ["userData"]),
    ...mapState("avaliations", [
      "items",
      "notification",
      "mode",
      "loading",
      "totalItems",
      "showDialog",
      "filterDialog",
      "possible_avaliations",
      "possible_status"
    ]),
    ...mapFields("avaliations", ["pagination"]),
  },
  data: () => ({
    headers: [
      { text: "ID", value: "id", sortable: true },
      { text: "Status", value: "status", sortable: true },
      { text: "Atendente", value: "user_name", sortable: true},
      { text: "ID Pppoker", value: "id_pppoker", sortable: true },
      { text: "Avaliação", value: "score", sortable: true },
      { text: "Atendimento Iniciado", value: "created_at", sortable: true },
      { text: "Atendimento Finalizado ", value: "finish_at", sortable: true },
      // { text: "ID Depósito", value: "deposit_id", sortable: true },
      // { text: "Contato", value: "contact_id", sortable: true },
      
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        align: "center",
        width: "10%",
      },
      ],
  }),

  watch: {
    pagination: {
      handler() {
        this.getItems({ resetPage: false });
      },
    },
  },
};
</script>
