import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../../config/endPoints';

/* const headers = ['Hora', 'Qt. Depósitos', 'Qt. Saques',
    'Qt. Despesas', 'Depósitos', 'Saques', 'Despesas',
    'Ajustes', 'Valor'
]; */
export default {
    namespaced: true,
    state: {
        items_quantity: [],
        items_quantity_days: [],
        items_value: [],
        items_value_days: [],
        items_quantity_months: [],
        items_value_months: [],
        items_pix_deposit_days: [],
    },
    mutations: {
        setItemsQuantity: (state, payload) => state.items_quantity = payload.items,
        setItemsValue: (state, payload) => state.items_value = payload.items,
        setItemsQuantityDays: (state, payload) => state.items_quantity_days = payload.items,
        setItemsValueDays: (state, payload) => state.items_value_days = payload.items,
        setItemsQuantityMonths: (state, payload) => state.items_quantity_months = payload.items,
        setItemsValueMonths: (state, payload) => state.items_value_months = payload.items,
        setItemsPixDepositDays: (state, payload) => state.items_pix_deposit_days = payload.items,
        loading: (state, payload) => state.loading = payload.show,
        updateField,
    },
    getters: {
        getField,
    },
    actions: {
        getItems: ({ commit }) => {
            commit('loading', { show: true })
            let url = endPoints.financial_dashboad + "/financial_moviments/last_hours"
            axios.get(url).then(items => {
                let data_quantity = []
                let data_value = []
                items.data.forEach(i => {
                    data_quantity.push([i.hour, i.deposits_quantity, i.withdrawals_quantity, i.expenses_quantity])
                    data_value.push([i.hour, i.deposits_value, i.withdrawals_value,
                        i.expenses_value, i.adjust_value, i.value
                    ])
                });
                commit('loading', { show: false })
                commit('setItemsQuantity', { items: data_quantity })
                commit('setItemsValue', { items: data_value })
            });
        },
        getItemsPerDays: ({ commit }) => {
            commit('loading', { show: true })
            let url = endPoints.financial_dashboad + "/financial_moviments/last_days"
            axios.get(url).then(items => {
                let data_quantity_days = []
                let data_value_days = []
                let data_pix_deposits_days = []
                items.data.forEach(i => {
                    data_quantity_days.push([i.hour, i.deposits_quantity, i.withdrawals_quantity, i.expenses_quantity])
                    data_value_days.push([i.hour, i.deposits_value, i.withdrawals_value,
                        i.expenses_value, i.adjust_value, i.value
                    ])
                    data_pix_deposits_days.push([i.hour, i.deposits_quantity, i.pix_deposit])
                });
                commit('loading', { show: false })
                commit('setItemsQuantityDays', { items: data_quantity_days })
                commit('setItemsValueDays', { items: data_value_days })
                commit('setItemsPixDepositDays', { items: data_pix_deposits_days})
            });
        },
        getItemsPerMonths: ({ commit }) => {
            commit('loading', { show: true })
            let url = endPoints.financial_dashboad + "/financial_moviments/last_months"
            axios.get(url).then(items => {
                let data_quantity_months = []
                let data_value_months = []
                items.data.forEach(i => {
                    data_quantity_months.push([i.hour, i.deposits_quantity, i.withdrawals_quantity, i.expenses_quantity])
                    data_value_months.push([i.hour, i.deposits_value, i.withdrawals_value,
                        i.expenses_value, i.adjust_value, i.value
                    ])
                });
                commit('loading', { show: false })
                commit('setItemsQuantityMonths', { items: data_quantity_months })
                commit('setItemsValueMonths', { items: data_value_months })
            });
        }
    }
}