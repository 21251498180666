<template>
<div>
    <v-snackbar
        :color="notificationType"
        right
        top
        :timeout="notificationTime"
        v-model="showNotification"
    >
        {{ notificationMessage }}
    </v-snackbar>
    <v-dialog
        width="500"
        height="400"
        v-model="dialogForwardMessage"
        @keydown.esc="showDialogForwardMessage({show: false, message: {}})"
    >
        <forward-message :contacts="contacts" :forwardMessage="forwardMessage" :getContacts="getContacts" :showDialogForwardMessage="showDialogForwardMessage"></forward-message>
    </v-dialog>
    <v-dialog :value="dialogWithdrawal" persistent width="600" @keydown.esc="showDialogWithdrawal({show: false})">
        <withdrawal-form screen="messages"></withdrawal-form>
    </v-dialog>
    <v-dialog :value="dialogAnnotated" persistent width="600" @keydown.esc="showDialogAnnotated({show: false})">
        <annotateds-form screen="messages"></annotateds-form>
    </v-dialog>
    <v-dialog :value="dialogFail" persistent width="600" @keydown.esc="showDialogFail({show: false})">
        <fail-form screen="messages"></fail-form>
    </v-dialog>
    <v-dialog :value="dialogDeposit" persistent width="600" @keydown.esc="showDialogDeposit({show: false})">
        <deposit-form screen="messages"></deposit-form>
    </v-dialog>
    <v-dialog :value="dialogUtilityOrder" persistent width="600" @click:outside="showDialogUtilityOrder({show: false})" @keydown.esc="showDialogUtilityOrder({show: false})">
        <utility-order-form></utility-order-form>
    </v-dialog>
    <v-dialog :value="dialogViewUtilityOrder" persistent width="800" @click:outside="showDialogViewUtilityOrder({show: false})" @keydown.esc="showDialogViewUtilityOrder({show: false})">
        <view-utility-order></view-utility-order>
    </v-dialog>
    <v-dialog :value="dialogVipSale" width="600" persistent @keydown.esc="showDialogVipSale({show: false})">
        <vip-sales-form screen="messages"></vip-sales-form>
    </v-dialog>
    <v-dialog :value="dialogDelete" width="600">
        <cancel-withdrawal :cancelFunc="closeDeleteDialog" :confirmFunc="deleteItem" message="Confirmar cancelamento de saque?"></cancel-withdrawal>
    </v-dialog>
    <v-dialog :value="dialogDeleteDeposit" width="600"> 
        <cancel-deposit :cancelFunc="depositCloseDeleteDialog" :confirmFunc="deleteDeposit" message="Confirmar cancelamento do depósito?"></cancel-deposit>
    </v-dialog>
    <v-dialog :value="dialogPlayer" persistent width="800" @keydown.esc="showDialogPlayer({show: false})">
        <players-form screen="messages"></players-form>
    </v-dialog>
    <div v-for="(message,index) in messages" v-bind:key="index">
        <div class="message-day">
            <div class="message-day-text" v-if="showMessageDay(message, index)">{{ getMessageDay(message.date) }}</div>
        </div>
        
        <message class="message" :is_checked="messageIsChecked(message.message_id)" :index="index" :isGroup="isGroup" :message="message" :openedChat="openedChat" parent="chat" :lastMessage="getLastMessage(index)" :class="{own: message.type == 0}" :reference="false">
        </message>
    </div>
</div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import ForwardMessage from './ForwardMessage.vue'
import Message from './Message.vue'
import debounce from '../../../../util/debounce.js' 
import DepositForm from '../../../financial/deposit/DepositForm'
import WithdrawalForm from '../../../financial/withdrawal/WithdrawalForm'
import AnnotatedsForm from '../../../financial/annotateds/AnnotatedsForm'
import FailForm from '../../../financial/fail/FailForm'
import CancelWithdrawal from '../../../../util/CardConfirm'
import CancelDeposit from '../../../../util/CardConfirm'
import PlayersForm from '../../../players/PlayersForm'
import UtilityOrderForm from '../../../players/PlayersDeleteForm'
import ViewUtilityOrder from './UtilityOrder.vue'
import VipSalesForm from '../../../financial/vip_sales/VipSalesForm.vue'

export default {
    name: 'messages',
    props: [
        'contact_id',
        'isGroup',
        'messages',
        'loading',
        'openedChat',
        'parent'
    ],
    components: {
        'cancel-withdrawal': CancelWithdrawal,
        'cancel-deposit': CancelDeposit,
        'forward-message': ForwardMessage,
        'deposit-form': DepositForm,
        'utility-order-form': UtilityOrderForm,
        'view-utility-order': ViewUtilityOrder,
        'withdrawal-form': WithdrawalForm,
        'annotateds-form': AnnotatedsForm,
        'fail-form': FailForm,
        'message': Message,
        'players-form': PlayersForm,
        'vip-sales-form': VipSalesForm
    },
    computed: {
        ...mapFields('message', [
            'dialogForwardMessage',
            'dialogDeposit',
            'dialogWithdrawal',
            'dialogAnnotated',
            'dialogVipSale',
            'dialogUtilityOrder',
            'dialogViewUtilityOrder',
            'dialogFail',
            'showNotification',
            'dialogPlayer',
        ]),
        ...mapFields('withdrawal', [
            'dialogDelete'
        ]),
        ...mapFields('deposit', {
            dialogDeleteDeposit: 'dialogDelete'
        }),
        ...mapGetters("instances", {instances: "instancesChat"}),
        ...mapState('message', ['contacts', 'notificationType', 'notificationMessage', 'notificationTime', 'selected_messages']),
        ...mapState('chat', ['lastEvent', 'scrollPosition']),
    },
    created() {
        this.getKnowByTypes()
    },
    data: () => ({
    }),
    mixins: [debounce],
    methods: {
        ...mapMutations('message', ['showDialogForwardMessage', 'showDialogDeposit', 'showDialogUtilityOrder', 'showDialogViewUtilityOrder', 'showDialogWithdrawal', 'showDialogAnnotated', 'showDialogFail', 'showDialogPlayer', 'showDialogVipSale']),
        ...mapActions('message', ['forwardMessage', 'getContacts']),
        ...mapActions('withdrawal', ['closeDeleteDialog', 'deleteItem']),
        ...mapActions('deposit', {depositCloseDeleteDialog: 'closeDeleteDialog', deleteDeposit: 'deleteItem'}),
        ...mapActions('players', ['getKnowByTypes']),
        getLastMessage(index) {
            if(index != 0) {
                return this.messages[index - 1]
            }
            return {}
        },
        getMessageDay(date) {
            return new Date(date).toLocaleString().substr(0,10)
        },
        showMessageDay(message, index) {
            if(index == 0) {
                return true
            } else {
                var lastMessage = this.getLastMessage(index)
                if(lastMessage) {
                    return (new Date(message.date).toLocaleString().substr(0,10) != new Date(lastMessage.date).toLocaleString().substr(0,10))
                } 
                return true
            }
        },
        messageIsChecked(message_id) {
            return this.selected_messages.indexOf(message_id) != -1
        },
        scrollTo(position) {
            var container = document.querySelector('.messages-container')
            container.scrollTop = position
        },
        scrollToEnd () {
            var container = document.querySelector('.messages-container')
            container.scrollTop = container.scrollHeight
        },
    },
    mounted() {
        this.getContacts = this.debounce(this.getContacts, 500)
        this.scrollToEnd = this.debounce(this.scrollToEnd, 200)
    },
    watch: {
        messages: function(val, oldVal) {
            if(val != oldVal && val.length != 0) {
                var scrollP = this.scrollPosition
                this.$nextTick(() => {
                    if(this.lastEvent != 'changeLimit') {
                        this.scrollToEnd()
                    } else {
                        var container = document.querySelector('.messages-container')
                        this.scrollTo(container.scrollHeight - scrollP)
                    }
                })
            }
        }
    }
}
</script>

<style>
@import './chats.css';
</style>