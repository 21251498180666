<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline text-center justify-center">Filtro</span>

        <v-col class="text-right">
          <v-btn v-if="filtered" color="white"  @click="clearFilter" right small depressed>
            <v-icon color="black">mdi-filter-remove</v-icon>
          </v-btn>
        </v-col>
      </v-card-title>

      <v-card-text>
        <v-form ref="form">
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm6 md6>
                <v-text-field
                    label="Id"
                    v-model="id"
                    outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-autocomplete
                  :clearable="true"
                  clear-icon="mdi-close"
                  label="Clube"
                  :items="slots"
                  v-model="club"
                  item-text="name"
                  item-value="id"
                  outlined
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-select
                  clear-icon="mdi-close"
                  :clearable="true"
                  label="Tipo de Acerto"
                  :items="possible_types_closure"
                  v-model="type_deal"
                  item-text="description"
                  item-value="id"
                  outlined
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field
                    label="Id do Agente"
                    v-model="id_agent"
                    outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field
                    label="Nick do Agente"
                    v-model="nick_agent"
                    outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-select
                  clear-icon="mdi-close"
                  :clearable="true"
                  label="Status"
                  :items="possible_status"
                  v-model="status"
                  item-text="description"
                  item-value="id"
                  outlined
                ></v-select>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn text @click="showFilter({show: false})">Cancelar</v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn text type="submit" right @click="makeFilter">Filtrar</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "AgentsPeriodClosureFilter",
  created() {
  },
  computed: {
    ...mapState("agents_closure", ["slots"]),
    ...mapFields("agents_closure", [
      "filtered",
      "filter.id",
      "filter.club",
      "filter.type_deal",
      "filter.id_agent",
      "filter.nick_agent",
      "filter.status"
      // ...
    ]),
  },
  data: () => ({
    payment_types: [
      { id: 0, description: 'A vista' },
      { id: 1, description: 'Anotado' }
    ],
    possible_types_closure: [
      {id: 0, description: "Ganho/Perda"},
      {id: 1, description: "Giro"}, 
      {id: 2, description: "Normal"},
      {id: 3, description: "Fichas Enviadas/Retiradas"},
      {id: 4, description: "Sem Acerto"},
      {id: 5, description: "Sem Acerto/GP"},
    ],
    possible_status: [
      {id: 0, description: "Pendente"},
      {id: 2, description: "Concluido"},
      {id: 3, description: "Aguardando Envio de Fichas"},
      {id: 4, description: "Enviar Fichas Manualmente"},
      {id: 5, description: "Erro nos Anotados"}
    ],
    searchPeriod: '',
    timeProps: {
      format: "24hr",
    },
  }),
  methods: {
    ...mapMutations("agents_closure", ["showFilter"]),
    ...mapActions("agents_closure", ["clearFilter", "getItems"]),
    makeFilter() {
      this.getItems({ resetPage: true });
      this.showFilter({ show: false });
    },
  },
  watch: {
    searchPeriod (val) {
      var payload = {
        search: val
      }
      this.getPeriodClosureByName(payload)
    }
  },
};
</script>

<style>
</style>