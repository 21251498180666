<template>
  <div>
    <div @drop.prevent="addDropFile" @dragover.prevent>
      <v-card>
        <v-card-title>
          <span class="headline text-center">Transferência de Fichas</span>
          <v-spacer></v-spacer>
          <v-progress-circular
            v-if="loading"
            indeterminate
            class="text-right"
            color="primary"
            size="24"
          ></v-progress-circular>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Nome PPPoker *"
                    v-model="name_pppoker"
                    :error-messages="errors.collect('name_pppoker')"
                    v-validate="'required:Nome,name_pppoker'"
                    data-vv-name="name_pppoker"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="ID PPPoker *"
                    v-model="id_pppoker"
                    :error-messages="errors.collect('id_pppoker')"
                    v-validate="'required:Id PPPoker,id_pppoker'"
                    data-vv-name="id_pppoker"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    label="Nome Suprema Poker *"
                    v-model="name_supreme"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('name_supreme')"
                    data-vv-name="name_supreme"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    label="ID Suprema Poker *"
                    v-model="id_supreme"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('id_supreme')"
                    data-vv-name="id_supreme"
                    outlined
                  ></v-text-field>
                </v-flex>

              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-data-table
          :headers="headers_transfer"
          :items="chips_transfer"      
          disable-pagination
          hide-default-footer      
          no-data-text="Nenhum item encontrado"            
        >
          <!-- <template v-slot:item.chips="props">
            <v-text-field
              required
              reverse
              v-model="props.item.chips"
              :readonly="mode == 'VIEW'"
            ></v-text-field>
          </template> -->
          <template v-slot:item.status="props">
            <v-select
              required
              :items="possible_status"
              reverse
              v-model="props.item.status"
              :readonly="mode == 'VIEW'"
              item-text="text"
              item-value="value"
            ></v-select>
          </template>
          <template v-slot:item.club_destiny="props">
            <v-select
              required
              :items="instances"
              reverse
              item-text="name"
              item-value="id_instance"
              v-model="props.item.club_destiny"
              :readonly="mode == 'VIEW'"
            ></v-select>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip v-if="!item.newagent_password" bottom >
              <template v-slot:activator="{on, attrs}">
                <v-icon v-on="on" v-bind="attrs" class="mr-2" @click="sendPlayerInfo({item: item})">mdi-google-controller</v-icon>
              </template>
              <span>Enviar Dados do Jogador</span>
            </v-tooltip>
            <v-tooltip v-else bottom>
              <template v-slot:activator="{on, attrs}">
                <v-icon class="mr-2"  v-on="on" v-bind="attrs" @click="sendAgentInfo({item: item})">mdi-face-agent</v-icon>
              </template>
              <span>Enviar Dados do Agente</span>
            </v-tooltip>
          </template>
        </v-data-table>

        <v-card-actions>
          <v-col>
            <v-btn color="red" text @click="closeDialogSupreme">{{ mode == 'VIEW' ? 'Voltar' : 'Cancelar'}}</v-btn>
          </v-col>
          <v-col class="text-right" v-if="mode != 'VIEW'">
            <v-btn color="blue" text type="submit" right @click="saveChipsTransfer">Salvar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import debounce from '../../../../util/debounce' 
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "RoutesForm",
  computed: {
    ...mapGetters("login", ["getInstanceId"]),
    ...mapState("chat", [
      "modeTransfer",
      "transfer_form",
      "menus",
      "route",
      "loading",
      "mode",
      "editedChipsTransfer",
      "chips_transfer",
    ]),
    ...mapState("instances", ["instances"]),
    ...mapFields("chat", [
      "dialogReceipt",
      "editedChipsTransfer.name_pppoker",
      "editedChipsTransfer.name_supreme",
      "editedChipsTransfer.id_pppoker",
      "editedChipsTransfer.id_supreme",
      "editedChipsTransfer.id_agent",
      "editedChipsTransfer.chips",
      "editedChipsTransfer.balance",
      "editedChipsTransfer.status"
    ]),
  },
  mixins: [debounce],
  mounted() {
    this.getChildrenSearch = this.debounce(this.getChildrenSearch, 500); 
  },
  created() {
    this.setScreen({ screen: this.$props.screen })
    this.getInstances()
    this.$validator.extend("required", {
      getMessage: (field, [label]) => label + " deve ser informado",
      validate: (_, [, field]) => {
        if (this[field] || this[field] === 0) {
          return true;
        } else {
          return false;
        }
      },
    });
  },
  methods: {
    ...mapActions("chat", [
      "getChildrenSearch",
      "getAttendants",
      "getUsers",
      "getAgents",
      "saveChipsTransferChat",
      "sendAgentInfo",
      "sendPlayerInfo"
    ]),
    ...mapActions("instances", ["getInstances"]),
    ...mapMutations("chat", ["closeDialogSupreme"]),
    saveChipsTransfer() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.saveChipsTransferChat();
        }
      });
    },
  },
  data: () => ({
    possible_status: [
      {
        text: "Não concluído",
        value: 0,
      },
      {
        text: "Cadastrado",
        value: 1,
      },
      {
        text: "Concluído",
        value: 2,
      },
    ],
    headers_transfer: [
      { text: "ID Agente PPPoker",  value: "id_agent", sortable: false, align: "center", width: "10%"},
      { text: "Saldo Agência PPPoker",  value: "balance", sortable: false, align: "center", width: "10%"},
      { text: "Saldo Fichas PPPoker",  value: "chips", sortable: false, align: "center", width: "10%"},
      { text: "Clube PPPoker",  value: "club_origin", sortable: false, align: "center", width: "10%"},
      { text: "ID Agente Suprema",  value: "newagent_id", sortable: false, align: "center", width: "10%"},
      { text: "Nome Agente Suprema",  value: "newagent_name", sortable: false, align: "center", width: "10%"},
      { text: "Senha Agente Suprema",  value: "newagent_password", sortable: false, align: "center", width: "10%"},
      { text: "Status",  value: "status", sortable: false, align: "center", width: "10%"},
      { text: "Ações",  value: "actions", sortable: false, align: "center", width: "10%"},
    ],
    test: [
      {
        id_agent:1,
        balance:1,
        chips:1,
        status:1,
        club_origin:1,
        club_destiny:1
      },
      {
        id_agent:1,
        balance:1,
        chips:1,
        status:1,
        club_origin:1,
        club_destiny:1
      },
    ],
    searchMenu: '',
  }),
  watch: {
    searchMenu (val) {
      var payload = {
        search: val
      }
      this.getChildrenSearch(payload)
    }
  },
};
</script>