import axios from "axios";
import endPoints from "../../../config/endPoints";
import { getField, updateField } from "vuex-map-fields";
import { defaultErrorNotification, defaultSuccessNotification } from "../../../constants"

const defaultFilter = {
    id_company: "",
    description: "",
    quantity: "",
    value: "",
    pix_type: "",
    status: "",
    pix_name: "",
    pix_key: "",
    start: "",
    end: "",
    user_id: "",
    judge: "",
};

const defaultItem = {
    id: null,
    id_company: null,
    description: "",
    link: null,
    quantity: 1,
    value: null,
    pix_type: null,
    status: 0,
    pix_name: "",
    pix_key: "",
    date_created: "",
    user_name: "",
    user_id: null,
    judge: null,
    receipt_expense: "",
    receipt_file: "",
    type_solicitation: null,
};

export default {
    namespaced: true,
    state: {
        date: "",
        totalItems: 0,
        companies: [],
        items: [],
        user: {},
        selectedItem: {},
        mode: "",
        loading: false,
        deleteDialog: false,
        finishDialog: false,
        approveDialog: false,
        refuseDialog: false,
        filterDialog: false,
        showDialog: false,
        showDialogExpense: false,
        id_financial_solicitation: null,
        dialogReceiptFinancialSolicitation: false,
        receipt_show: "",
        filter: {
            id_company: "",
            description: "",
            quantity: "",
            value: "",
            pix_type: "",
            status: "",
            pix_name: "",
            pix_key: "",
            start: "",
            end: "",
            user_id: "",
            judge: "",
        },
        notification: {
            show: false,
            message: "",
            type: "",
        },
        pagination: {
            page: 1,
            itemsPerPage: 10,
        },
        possible_solicitation_types: [
            { id: 0, name: "Compra" },
            { id: 1, name: "Reembolso" },
            { id: 2, name: "Outros" },
        ],
        possible_pix_types: [
            { pix_type: 0, name: "CPF / CNPJ" },
            { pix_type: 1, name: "Celular" },
            { pix_type: 2, name: "E-mail" },
            { pix_type: 3, name: "Aleatória" },
        ],
        possible_status: [
            { id: 0, name: "Pendente" },
            { id: 1, name: "Recusado" },
            { id: 2, name: "Aprovado" },
            { id: 3, name: "Concluído" },
        ],
    },
    mutations: {
        addSolicitation: (state) => {
            state.selectedItem = Object.assign({}, defaultItem);
            defaultItem.date_created = new Date();
            state.receipt_file = "";
            state.selectedItem.status = 0;
            state.showDialog = true;
            state.mode = "ADD";
        },
        edit: (state, payload) => {
            state.selectedItem = Object.assign({}, payload);
            state.showDialog = true;
            state.mode = "EDIT";
        },
        view: (state, payload) => {
            state.selectedItem = payload;
            state.showDialog = true;
            state.mode = "VIEW";
        },
        showDeleteDialog: (state, payload) => {
            state.selectedItem = payload;
            state.deleteDialog = true;
        },
        showFinishDialog: (state, payload) => {
            state.selectedItem = payload;
            state.finishDialog = true;
        },
        showApproveDialog: (state, payload) => {
            state.selectedItem = payload;
            state.approveDialog = true;
        },
        showRefuseDialog: (state, payload) => {
            state.selectedItem = payload;
            state.refuseDialog = true;
        },
        clearFilter: (state) => {
            state.filter = Object.assign({}, defaultFilter)
        },
        setItems: (state, payload) => (state.items = payload),
        setUserId: (state, payload) => (state.user_id = payload.id),
        setTotalItems: (state, payload) => (state.totalItems = payload),
        setLoading: (state, payload) => (state.loading = payload),
        showNotification: (state, payload) => (state.notification = payload),
        setSelectedItem: (state, payload) => (state.selectedItem = payload),
        setExpenseItem: (expenseState, payload) => (expenseState.selectedItem = payload.item),
        setDeleteDialog: (state, payload) => (state.deleteDialog = payload),
        setFinishDialog: (state, payload) => (state.finishDialog = payload),
        setApproveDialog: (state, payload) => (state.approveDialog = payload),
        setRefuseDialog: (state, payload) => (state.refuseDialog = payload),
        setDialogReceipt: (state, payload) => (state.dialogReceiptFinancialSolicitation = payload.show),
        setReceipt: (state, payload) => (state.receipt_show = payload.receipt),
        showOverlay: (state, payload) => {
            state.overlay = payload.show;
        },
        setCompanies: (state, payload) => {
            state.companies = payload.companies;
        },
        setShowDialog: (state, payload) => {
            state.showDialog = payload;
            state.selectedItem = Object.assign({}, defaultItem);
        },
        setShowDialogExpense: (state, payload) => {
            state.showDialogExpense = payload;
            state.selectedItem = Object.assign({}, defaultItem);
        },
        setShowFilter: (state, payload) => (state.filterDialog = payload),
        updateField,
    },
    actions: {
        showReceiptFinancialSolicitation: ({ commit }, payload) => {
            let url = endPoints.receipt;

            url += "/" + payload.financial_solicitations.expense_id + "?type=1";
            commit("showOverlay", { show: true });
            axios
                .get(url)
                .then((r) => {
                    let file = r.data.file;
                    let mimetype = file.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
                    if (mimetype.indexOf("pdf") != -1) {
                        let name = "Comprovante-" + payload.financial_solicitations.expense_id + ".pdf";
                        var a = document.createElement("a"); //Create <a>
                        a.href = file; //Image Base64 Goes here
                        a.download = name; //File name Here
                        a.click();
                    } else {
                        commit("setReceipt", { receipt: r.data.file });
                        commit("setDialogReceipt", { show: true });
                    }
                    commit("showOverlay", { show: false });
                })
                .catch(() => {
                    let notification = {
                        show: true,
                        message: "Comprovante não encontrado",
                        type: "error",
                    };
                    commit("showNotification", notification);
                    commit("showOverlay", { show: false });
                });
        },
        add: ({ commit }, payload) => {
            commit('expense/addFinancialSolicitation', { item: payload.financial_solicitations }, { root: true })
            commit('setShowDialogExpense', { show: true })
        },
        getItems: async ({ commit, state }) => {
            let filter = state.filter
            let url = endPoints.financial_solicitations

            url += '?page=' + state.pagination.page + "&itemsPerPage=" + state.pagination.itemsPerPage;

            Object.keys(filter).forEach(function (field) {
                if (filter[field] !== "" && filter[field] !== undefined) {
                    if (field == "end") {
                        var e = new Date(filter.end);
                        e.setDate(e.getDate() + 1);
                        url += "&" + field + "=" + e.toISOString().substr(0, 10);
                    } else {
                        url += "&" + field + "=" + filter[field];
                    }
                }
            });

            if (state.pagination && state.pagination.sortBy && state.pagination.sortBy.length > 0) {
                url += "&sort_by=" + state.pagination.sortBy[0]
                url += "&sort_desc=" + state.pagination.sortDesc[0]
            }
            try {
                commit("setLoading", true);
                let items = await axios.get(url);
                commit("setItems", items.data.data);
                commit("setTotalItems", items.data.total);
            } catch (error) {
                commit(
                    "showNotification",
                    defaultErrorNotification(error?.response?.data?.message)
                );
            }
            commit("setLoading", false);
        },
        getItemsNoPage: ({ state }) => {
            let url = endPoints.financial_solicitations;
            let filter = state.filter;
            url += "?page=" + "1" + "&" + "itemsPerPage=" + "99999";

            Object.keys(filter).forEach(function (field) {
                if (filter[field] !== "" && filter[field] !== undefined) {
                    if (field == "end") {
                        var e = new Date(filter.end);
                        e.setDate(e.getDate() + 1);
                        url += "&" + field + "=" + e.toISOString().substr(0, 10);
                    } else {
                        url += "&" + field + "=" + filter[field];
                    }
                }
            });
            return axios.get(url).then((items) => {
                return items.data.data;
            });
        },
        getCompanies: ({ commit }) => {
            let url = endPoints.companies;
            url += "?page=" + 1 + "&" + "itemsPerPage=" + 999;
            axios.get(url).then((items) => {
                var companies = items.data.data;
                commit("setCompanies", { companies: companies });
            });
        },
        deleteItem: async ({ commit, dispatch, state }) => {
            commit("setLoading", true);
            try {
                await axios.delete(`${endPoints.financial_solicitations}/${state.selectedItem.id}`);
                commit("setLoading", false);
                commit("setDeleteDialog", false);
                commit("setSelectedItem", {});
                commit("showNotification", defaultSuccessNotification("Item removido com sucesso"));
                return dispatch("getItems");
            } catch (error) {
                commit(
                    "showNotification",
                    defaultErrorNotification(error?.response?.message)
                );
                commit("setLoading", false);
            }
        },
        save: async ({ commit, state, dispatch, rootState }) => {
            let url = endPoints.financial_solicitations;
            let func = "";
            state.mode === "ADD"
                ? (func = axios.post)
                : ((func = axios.put), (url += `/${state.selectedItem.id}`));
            commit("setLoading", true);
            try {
                // save receipt func {
                if (state.selectedItem.receipt_file != "") {
                    var file = state.selectedItem.receipt_file;
          
                    const toBase64 = (file) =>
                      new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => resolve(reader.result);
                        reader.onerror = (error) => reject(error);
                      });
          
                    let base64_file = await toBase64(file);
                    state.selectedItem.receipt = base64_file;
                }
                // }
                let date_created = new Date();
                state.selectedItem.date_created = date_created.toISOString().substr(0, 10) + " " + date_created.toLocaleTimeString();
                state.selectedItem.user_id = rootState.login.userData.id;
                state.selectedItem.user_name = rootState.login.userData.name;
                state.selectedItem.quantity = parseFloat(state.selectedItem.quantity);
                state.selectedItem.value = parseFloat(state.selectedItem.value);
                if([0, 1].includes(state.selectedItem.pix_type)){
                    state.selectedItem.pix_key = state.selectedItem.pix_key.replaceAll('+', '')
                    state.selectedItem.pix_key = state.selectedItem.pix_key.replaceAll('(', '')
                    state.selectedItem.pix_key = state.selectedItem.pix_key.replaceAll(')', '')
                    state.selectedItem.pix_key = state.selectedItem.pix_key.replaceAll('-', '')
                    state.selectedItem.pix_key = state.selectedItem.pix_key.replaceAll('.', '')
                    state.selectedItem.pix_key = state.selectedItem.pix_key.replaceAll('/', '')
                    state.selectedItem.pix_key = state.selectedItem.pix_key.replaceAll(' ', '')
                }

                await func(url, state.selectedItem);
                commit("showNotification", defaultSuccessNotification("Item salvo com sucesso"));
                commit("setShowDialog", false);
                commit("setSelectedItem", {});
                dispatch("getItems");
                commit("setLoading", false);
            } catch (error) {
                commit("showNotification", defaultErrorNotification(error?.response?.message));
                commit("setLoading", false);
            }
        },
        updateStatus: ({ commit, state, dispatch, rootState }, payload) => {
            let url = endPoints.financial_solicitations;
            url += `/${state.selectedItem.id}`;
            commit("setLoading", true);
            try {
                state.selectedItem.status = payload.status;
                state.selectedItem.judge = rootState.login.userData.name.toString();
                axios.put(url, state.selectedItem).then(() => {
                    commit("setLoading", false);
                    commit("showNotification", defaultSuccessNotification("Registro atualizado!"));
                    commit("setRefuseDialog", false);
                    commit("setApproveDialog", false);
                    commit("setFinishDialog", false);
                    dispatch("getItems", { resetPage: true });
                });
            } catch (error) {
                commit("showNotification", defaultErrorNotification(error?.response?.message));
                commit("setLoading", false);
                commit("setFinishDialog", false);
                commit("setApproveDialog", false);
                commit("setRefuseDialog", false);
                dispatch("getItems", { resetPage: true });
            }
        },
        getUserId: ({ commit }, payload) => {
            commit("loading", { loading: true });
            let url = endPoints.users + "/" + payload.id;
            axios.get(url).then((response) => {
                commit("setUserId", { user: response.data });
                commit("loading", { loading: false });
            });
        },
    },
    getters: {
        getIdUser: (state) => {
            return state.user_id;
        },
        getField,
    },
};
