<template>
  <div>
    <v-snackbar
      :color="notification.type"
      right
      top
      :timeout="2000"
      v-model="notification.show"
    >
      {{ notification.message }}
    </v-snackbar>

    <v-toolbar flat class="elevation-5">
      <v-flex class="ml-6">
        <v-toolbar-title>Solicitações</v-toolbar-title>
      </v-flex>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="black"
            dark
            class="ma-2"
            @click="showFilter(true)"
            small
            v-bind="attrs"
            v-on="on"
          >
            <v-icon color="white">mdi-filter</v-icon>
          </v-btn>
        </template>
        <span>Filtrar</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="black"
            dark
            class="ma-2"
            @click="refreshItems"
            small
            v-on="on"
            v-bind="attrs"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>
        <span> Recarregar </span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="black"
            dark
            class="ma-2"
            @click="add(item)"
            small
            v-on="on"
            v-bind="attrs"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span> Adicionar </span>
      </v-tooltip>
    </v-toolbar>

    <v-dialog :value="showDialog" persistent width="600">
      <requests-form></requests-form>
    </v-dialog>

    <v-container fluid class="d-flex flex-wrap">
      <template v-for="item of items">
        <v-card
          width="50%"
          :key="item.id"
          :color="cardColor(item.status)"
          elevation="10"
          class="d-flex flex-column justify-space-around"
        >
          <div class="d-flex justify-space-between align-center">
            <v-card-title> {{ item.title }} </v-card-title>
            <template v-if="userPost(item)">
              <div class="mr-3">
                <v-icon medium class="mr-2" @click="edit(item)"
                  >mdi-pencil</v-icon
                >
                <v-icon medium @click="deleteRequest(item)">mdi-delete</v-icon>
              </div>
            </template>
          </div>

          <template v-if="item.observation">
            <div class="overline mb-4 pl-4">
              {{ item.observation }}
            </div>
          </template>

          <v-card-subtitle>
            {{ `${item.department_origin} → ${item.department_destination}` }}
          </v-card-subtitle>
          <v-card-text class="pl-4" outlined>
            {{ item.description }}
          </v-card-text>
          <v-card-actions class="mt-2">
            <v-flex class="text-left pl-2 subtitle-2">
              {{ formatDate(item.creation_date) }}
            </v-flex>

            <v-flex class="text-right pr-5">
              <div>
                <v-btn
                  rounded
                  v-bind="items"
                  :color="getButtonColor(item, 1)"
                  @click="requestInteract(item, 1)"
                  >{{ item.likes }} | Likes
                </v-btn>
                <v-btn
                  rounded
                  v-bind="items"
                  :color="getButtonColor(item, -1)"
                  @click="requestInteract(item, -1)"
                  >{{ item.dislikes }} | Dislikes
                </v-btn>
              </div>
            </v-flex>
          </v-card-actions>
        </v-card>
      </template>
    </v-container>

    <v-dialog :value="filterDialog" @click:outside="showFilter(false)" width="600">
      <requests-filter></requests-filter>
    </v-dialog>

    <v-dialog :value="deleteDialog" persistent width="300">
      <v-card>
        <v-card-title class="headline">Confirmar a exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn text @click="setDeleteDialog(false)">Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn text type="submit" right @click="removeRequest"
              >Confirmar</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import RequestsForm from "./RequestsForm";
import RequestsFilter from "./RequestsFilter";

export default {
  name: "Requests",
  created() {
    this.getItems();
  },
  mounted() {
    window.addEventListener("scroll", this.scroll, true);
  },
  components: {
    RequestsForm,
    RequestsFilter
  },
  methods: {
    ...mapActions("requests", [
      "getItems",
      "refreshItems",
      "interact",
      "deleteInteraction",
      "updateInteraction",
      "removeRequest",
    ]),
    ...mapMutations("requests", [
      "add",
      "edit",
      "deleteRequest",
      "setDeleteDialog",
      "showFilter"
    ]),
    getButtonColor(request, btnType) {
      let interacted = this.verifyInteraction(request, btnType);
      return interacted == btnType ? (btnType == 1 ? "primary" : "error") : "";
    },
    requestInteract(request, type) {
      let interacted = this.verifyInteraction(request, type);
      let interaction = {
        id: 1,
        request_id: request.id.toString(),
        user_id: 1,
        type: type.toString(),
      };
      if (interacted == 0) {
        this.interact(interaction);
      } else if (interacted == type) {
        this.deleteInteraction(request);
      } else {
        this.updateInteraction(interaction);
      }
    },
    verifyInteraction(request, type) {
      if (type == 1) {
        if (request.is_liked_by_user == 1) {
          return 1;
        } else if (request.is_disliked_by_user == 1) {
          return -1;
        } else {
          return 0;
        }
      } else {
        if (request.is_disliked_by_user == 1) {
          return -1;
        } else if (request.is_liked_by_user == 1) {
          return 1;
        } else {
          return 0;
        }
      }
    },
    userPost(post) {
      return (
        post.user_id === this.userData.id ||
        [99, 98, 97, 96, 95, 94].includes(this.userData.role)
      );
    },
    formatDate(dateString) {
      let date = new Date(dateString);
      return `${date.getDate() + 1}/${
        date.getMonth() + 1
      }/${date.getFullYear()}`;
    },
    scroll(e) {
      let bottomOfWindow =
        Math.floor(e.target.offsetHeight + e.target.scrollTop) ===
        e.target.scrollHeight;
      if (bottomOfWindow) this.getItems();
    },
    cardColor(status) {
      return status === 0
        ? "white"
        : status === 1
        ? "green lighten-3"
        : "red lighten-3";
    },
  },
  computed: {
    ...mapState("requests", [
      "items",
      "showDialog",
      "deleteDialog",
      "notification",
      "filterDialog"
    ]),
    ...mapState("login", ["userData"]),
  },
};
</script>

<style lang="scss" scoped>
</style>
