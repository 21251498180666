<template>
  <div>
    <v-dialog :value="showDialog" persistent width="600">
      <v-card>
        <v-card-title>
          <span class="headline">Nova Reunião</span>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    required
                    label="Título *"
                    v-model="title"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('title')"
                    v-validate="'required:Title,title'"
                    data-vv-name="Título"
                    outlined
                    dense
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm12 md12>
                  <v-text-field
                    required
                    label="Pauta *"
                    v-model="pauta"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('pauta')"
                    v-validate="'required:Pauta, pauta'"
                    data-vv-name="Pauta"
                    outlined
                    dense
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm12 md12>
                  <v-text-field
                    required
                    label="Criada por"
                    v-model="userData.name"
                    :readonly="true"
                    :error-messages="errors.collect('name')"
                    v-validate="'required:Name,name'"
                    data-vv-name="Criada por"
                    outlined
                    dense
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-select
                    label="Sala de Reunião"
                    :items="meeting_rooms"
                    v-model="room"
                    :error-messages="errors.collect('room')"
                    v-validate="'required:Room,room'"
                    data-vv-name="room"
                    item-text="description"
                    item-value="id"
                    outlined
                    dense
                  ></v-select>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-datetime-picker
                    v-model="date"
                    date-format="dd/MM/yyyy"
                    :time-picker-props="timeProps"
                    label="Data/Hora *"
                    :textFieldProps="{ outlined: true, dense: true }"
                  >
                    <template slot="dateIcon">
                      <v-icon>mdi-calendar</v-icon>
                    </template>
                    <template slot="timeIcon">
                      <v-icon>mdi-clock</v-icon>
                    </template>
                  </v-datetime-picker>
                </v-flex>

                <v-flex xs12 sm12 md12>
                  <v-text-field
                    required
                    label="Link da Reunião"
                    v-model="link"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('link')"
                    v-validate="'required:Link,link'"
                    data-vv-name="Link"
                    outlined
                    dense
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm12 md12>
                  <v-autocomplete
                    dense
                    outlined
                    multiple
                    clearable
                    small-chips
                    deletable-chips
                    v-model="participants"
                    :no-filter="true"
                    :items="users"
                    :search-input.sync="searchInput"
                    no-data-text="Nenhum usuário encontrado"
                    item-text="name"
                    item-value="id"
                    placeholder="Digite aqui o nome dos participantes"
                    label="Participantes"
                  ></v-autocomplete>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-col>
            <v-btn text @click="setShowDialog(false)">{{
              mode == "VIEW" ? "Voltar" : "Cancelar"
            }}</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn
              text
              type="submit"
              right
              v-if="mode == 'EDIT' || mode == 'ADD'"
              @click="saveMeeting()"
              >Salvar</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import debounce from "../../../util/debounce.js";

export default {
  name: "MeetingsForm",
  methods: {
    ...mapMutations("meetings", [
      "setUsers",
      "setShowDialog",
      "setSelectedItem",
      "setShowDialog",
    ]),
    ...mapActions("meetings", ["getUsers", "save"]),

    saveMeeting() {
      let d = new Date(this.date);
      this.date = d.toISOString().substr(0, 10) + " " + d.toLocaleTimeString();
      this.save();
    },
  },
  computed: {
    ...mapState("login", ["userData"]),
    ...mapState("meetings", ["mode", "users", "selectedItem", "showDialog"]),
    ...mapFields("meetings", [
      "selectedItem.id",
      "selectedItem.created_by",
      "selectedItem.date",
      "selectedItem.ata",
      "selectedItem.pauta",
      "selectedItem.link",
      "selectedItem.room",
      "selectedItem.title",
      "selectedItem.participants",
    ]),
  },
  mounted() {
    // v-auto-complete
    this.getUsers = this.debounce(this.getUsers, 500);
  },
  // v-auto-complete
  mixins: [debounce],
  data: () => ({
    meeting_rooms: [
      { id: 0, description: "Recepção" },
      { id: 1, description: "Baias" },
    ],
    timeProps: {
      format: "24hr",
    },
    valid: false,
    searchInput: "",
  }),
  watch: {
    searchInput: {
      handler() {
        if (this.searchInput) {
          var payload = {
            search: this.searchInput,
          };
          this.getUsers(payload);
        }
      },
    },
  },
};
</script>