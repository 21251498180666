import axios from 'axios';
// import endPoints from '../../config/endPoints';
import endPoints from '../../../config/endPoints';

export default {
    namespaced: true,
    state: {
        instances_nottreated: [],
        instances: []
    },
    mutations: {
        setInstances: (state, instances) => state.instances = instances,
    },
    actions: {
        disconnectInstance: ({ dispatch }, payload) => {
            axios.get(payload.instance.base_url + payload.instance.id + "/token/" + payload.instance.token + "/disconnect").then(() => {
                let payload = {
                    instance: payload.instance
                }
                dispatch('refreshInstance', payload)
            })
        },
        getInstances: ({ commit }) => {
            let instances_treated = []
            let url = endPoints.instances;
            url += '?page=' + 1 + '&' + 'itemsPerPage=' + 999;
            axios.get(url).then(instances => {
                if (instances.data && instances.data.data) {
                    instances = instances.data.data
                    instances = instances.filter((i) => i.token != "" && i.id != "")
                    instances.forEach((i) => {
                        axios.get(i.base_url + i.id + "/token/" + i.token + "/qr-code/image").then(connect => {
                            if (connect.data) {
                                if (connect.data.connected == true) {
                                    i.qr_code = null
                                    i.connected = true
                                    i.number = connect.data.number
                                    i.push_name = connect.data.name
                                    if(connect.data.battery) {
                                        i.battery = connect.data.battery
                                    }
                                    if(connect.data.plugged) {
                                        i.plugged = connect.data.plugged
                                    }
                                } else {
                                    i.connected = false
                                    i.qr_code = connect.data.value
                                    
                                    if (connect.data.disconnectedTime)
                                        i.disconnected_time.Int64 = connect.data.disconnectedTime
                                }
                                console.log(connect)
                            }
                            instances_treated.push(i)
                        }).catch(() => {
                            i.connected = false
                            i.qr_code = null
                            instances_treated.push(i)
                        })
                    })
                    commit('setInstances', instances_treated)

                } else {
                    commit('setInstances', [])
                }
            });
        },
        reconnectInstance: ({ dispatch }, payload) => {
            axios.get(payload.instance.base_url + payload.instance.id + "/token/" + payload.instance.token + "/reconnect").then(() => {
                let payload = {
                    instance: payload.instance
                }
                dispatch('refreshInstance', payload)
            })
        },
        restartInstance: ({ dispatch }, payload) => {
            axios.get(payload.instance.base_url + payload.instance.id + "/token/" + payload.instance.token + "/restart").then(() => {
                let payload = {
                    instance: payload.instance
                }
                dispatch('refreshInstance', payload)
            })
        },
        refreshInstance: (_, payload) => {
            let instances_treated = []
            axios.get(payload.instance.base_url + payload.instance.id + "/token/" + payload.instance.token + "/qr-code/image").then(connect => {
                if (connect.data) {
                    if (connect.data.connected == true) {
                        if (payload.instance.qr_code != null || payload.instance.connected != true) {
                            payload.instance.qr_code = null
                            payload.instance.connected = true
                        }
                    } else {
                        if (payload.instance.connected == true || payload.instance.qr_code == null || payload.instance.qr_code != connect.data.value) {
                            payload.instance.connected = false
                            payload.instance.qr_code = connect.data.value

                            if (connect.data.disconnectedTime)
                                payload.instance.disconnected_time.Int64 = connect.data.disconnectedTime
                        }
                    }
                }
            }).catch(() => {
                payload.instance.connected = false
                payload.instance.qr_code = null
                instances_treated.push(payload.instance)
            })
        }
    }
}