import axios from "axios";
import { getField, updateField } from "vuex-map-fields";
import endPoints from "../../../config/endPoints";
import {
  defaultErrorNotification,
  defaultSuccessNotification,
} from "../../../constants";

const defaultFilter = {
  ata: "",
  start: "",
  end: "",
  created_by: "",
  date: "",
  pauta: "",
  room: "",
  title: "",
};

const defaultMeetingsItem = {
  ata: "",
  created_by: "",
  date: "",
  pauta: "",
  link: "",
  room: "",
  title: "",
  participants: "",
  user_id: null,
};

export default {
  namespaced: true,
  state: {
    participantes: "",
    linkTeste: "",
    date: "",
    items: [],
    users: [],
    dateArray: [],
    selectedItem: {},
    mode: "",
    loading: false,
    totalItems: 0,
    linkDialog: false,
    deleteDialog: false,
    filterDialog: false,
    showDialog: false,
    filter: {
      ata: "",
      start: "",
      end: "",
      created_by: "",
      date: "",
      pauta: "",
      room: "",
      title: "",
    },
    notification: {
      show: false,
      message: "",
      type: "",
    },
    pagination: {
      page: 1,
      itemsPerPage: 10,
    },
  },
  mutations: {
    add: (state) => {
      state.mode = "ADD";
      state.selectedItem = Object.assign({}, defaultMeetingsItem);
      state.showDialog = true;
    },
    deleteItem: (state, payload) => {
      state.selectedItem = payload;
      state.deleteDialog = true;
    },
    edit: (state, payload) => {
      state.mode = "EDIT";
      state.selectedItem = Object.assign({}, payload);
      state.showDialog = true;
    },
    view: (state, payload) => {
      state.mode = "VIEW";
      state.selectedItem = payload;
      state.showDialog = true;
    },
    viewLink: (state, payload) => {
      state.mode = "VIEW";
      state.selectedItem = payload;
      state.linkTeste = state.selectedItem.link;
      state.participantes = state.selectedItem.participants;
      state.linkDialog = true;
    },
    clearFilter: (state) => {
      state.filter = Object.assign({}, defaultFilter);
    },
    setItems: (state, payload) => (state.items = payload),
    setTotalItems: (state, payload) => (state.totalItems = payload),
    setLoading: (state, payload) => (state.loading = payload),
    showNotification: (state, payload) => (state.notification = payload),
    setShowFilter: (state, payload) => (state.filterDialog = payload),
    setSelectedItem: (state, payload) => (state.selectedItem = payload),
    setDeleteDialog: (state, payload) => (state.deleteDialog = payload),
    setLinkDialog: (state, payload) => {
      state.linkDialog = payload;
      state.selectedItem = Object.assign({}, defaultMeetingsItem);
    },
    setShowDialog: (state, payload) => {
      state.showDialog = payload;
      state.selectedItem = Object.assign({}, defaultMeetingsItem);
    },
    setUsers: (state, payload) => {
      state.users = payload.users;
    },
    updateField,
  },
  actions: {
    getUsers: ({ commit }, payload) => {
      let url = endPoints.users;
      url += "/attendants/" + payload.search;

      axios.get(url).then((users) => {
        commit("setUsers", { users: users.data });
      });
    },
    getItems: async ({ commit, state }) => {
      let filter = state.filter;
      let url = endPoints.meetings;

      url +=
        "?page=" +
        state.pagination.page +
        "&itemsPerPage=" +
        state.pagination.itemsPerPage;

      Object.keys(filter).forEach(function(field) {
        if (
          filter[field] !== "" &&
          filter[field] !== undefined &&
          filter[field] !== null
        ) {
          if (field == "end") {
            var e = new Date(filter.end);
            e.setDate(e.getDate() + 1);
            url += "&" + field + "=" + e.toISOString().substr(0, 10);
          } else {
            url += "&" + field + "=" + filter[field];
          }
        }
      });

      if (
        state.pagination &&
        state.pagination.sortBy &&
        state.pagination.sortBy.length > 0
      ) {
        url +=
          "&sort_by=" +
          (state.pagination.sortBy[0] === "date"
            ? "id"
            : state.pagination.sortBy[0]);
        url += "&sort_asc=" + state.pagination.sortAsc[0];
      }

      commit("setLoading", true);

      try {
        let items = await axios.get(url);
        commit("setItems", items.data.data);
        commit("setTotalItems", items.data.total);
      } catch (error) {
        commit(
          "showNotification",
          defaultErrorNotification(error?.response?.data?.message)
        );
      }
      commit("setLoading", false);
    },
    getItemsNoPage: ({ state }) => {
      let url = endPoints.meetings;
      let filter = state.filter;

      url += "?page=" + "1" + "&" + "itemsPerPage=" + "99999";

      Object.keys(filter).forEach(function(field) {
        if (filter[field] !== "" && filter[field] !== undefined) {
          if (field == "end") {
            var e = new Date(filter.end);
            e.setDate(e.getDate() + 1);
            url += "&" + field + "=" + e.toISOString().substr(0, 10);
          } else {
            url += "&" + field + "=" + filter[field];
          }
        }
      });
      return axios.get(url).then((items) => {
        return items.data.data;
      });
    },
    deleteAction: async ({ commit, dispatch, state }) => {
      commit("setLoading", true);
      try {
        await axios.delete(`${endPoints.meetings}/${state.selectedItem.id}`);

        commit("setLoading", false);
        commit("setDeleteDialog", false);
        commit("setSelectedItem", {});
        commit(
          "showNotification",
          defaultSuccessNotification("Reunião removida com sucesso")
        );

        return dispatch("getItems");
      } catch (error) {
        commit(
          "showNotification",
          defaultErrorNotification(error?.response?.message)
        );
        commit("setLoading", false);
      }
    },
    save: async ({ commit, state, dispatch, rootState }) => {
      let url = endPoints.meetings;
      let func = "";
      state.mode === "ADD"
        ? (func = axios.post)
        : ((func = axios.put), (url += `/${state.selectedItem.id}`));
      commit("setLoading", true);

      state.selectedItem.participants = state.selectedItem.participants.join(
        " "
      );
      console.log(state.selectedItem);

      try {
        state.selectedItem.user_id = rootState.login.userData.id;
        state.selectedItem.created_by = rootState.login.userData.name;
        state.selectedItem.ata = "ata";

        await func(url, state.selectedItem);

        commit(
          "showNotification",
          defaultSuccessNotification("Reunião criada com sucesso")
        );
        commit("setShowDialog", false);
        commit("setLoading", false);
        dispatch("getItems");
      } catch (error) {
        console.log("deu erro ao salvar");
        commit(
          "showNotification",
          defaultErrorNotification(error?.response?.message)
        );
        commit("setLoading", false);
      }
    },
  },
  getters: {
    getField,
  },
};
