import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../config/endPoints';

const defaultExpenseType = {
    label: "",
    depth: 0,
    parent_node: 0,
};

const getExpenseChildren = (arr, parentId) => {
    let children = [];
    arr.forEach(item => {
        if(item.parent_node == parentId) {
            children.push({
                id_dre: item.id_dre,
                id: item.id,
                name: item.label,
                depth: item.depth,
                parent_node: item.parent_node,
                children: []
            })
        }
    })
    return children;
};

const recursiveSetChildren = (array, data) => {
    for(let i = 0; i < array.length; i++) {
        if(array[i].id == data.id) {
            array[i].children = data.data
            return
        }
        if(array[i]?.children) recursiveSetChildren(array[i].children, data)
    }
};

export default {
    namespaced: true,
    state: {
        dialog: false,
        dialogDelete: false,
        editedExpenseTypes: {
            label: '',
            id_dre: ''
        },
        errors: [],
        expenseTypes: [],
        showedExpenseTypes: [],
        expenseTypeToDelete: {},
        loading: false,
        mode: 'LIST',
        notificationMessage: '',
        notificationType: '',
        page: 1,
        pagination: {
            page: 1,
            itemsPerPage: 50
        },
        showNotification: false,
        treeViewKey: 0,
        totalExpenseTypes: 0,
        rowsPerPage: 50
    },
    mutations: {
        addExpenseType: (state, payload) => {
            state.dialog = true
            state.mode = "ADD"
            state.editedExpenseTypes = Object.assign({}, defaultExpenseType)
            state.editedExpenseTypes.parent_node = payload?.id ?? 0
        },
        editExpenseType: (state, item) => {
            state.editedExpenseTypes = item
            state.editedExpenseTypes.label = item.name
            state.dialog = true
            state.mode = "EDIT"
        },
        closeDialog: (state) => {
            state.editedExpenseTypes = Object.assign({}, defaultExpenseType)
            state.errors = [];
        },
        incrementTreeViewKey: (state) => state.treeViewKey += 1,
        loading: (state, loading) => state.loading = loading,
        setMode: (state, mode) => state.mode = mode,
        setExpenseTypes: (state, expenseTypes) => { 
            state.expenseTypes = expenseTypes
        },
        setShowedExpenseTypes: (state, expenseTypes) => {
            state.showedExpenseTypes = expenseTypes
        },
        setExpenseTypeToDelete: (state, expenseType) => state.expenseTypeToDelete = expenseType,
        setTotalExpenseTypes: (state, total) => state.totalExpenseTypes = total,
        showDialog: (state, show) => state.dialog = show,
        setDialogDelete: (state, show) => state.dialogDelete = show,
        showLoading: (state, show) => state.loading = show,
        updateField,
        showNotification: (state, payload) => {
            state.showNotification = payload.show,
            state.notificationMessage = payload.message,
            state.notificationType = payload.type
        }
    },
    getters: {
        getField,
        getExpenseTypes: (state) => {
            return state.expenseTypes
        }
    },
    actions: {
        closeDialog: (({ commit }) => {
            commit('showDialog', false)
            commit('closeDialog')
        }),
        closeDeleteDialog: ({ commit }) => {
            commit('setDialogDelete', false)
            commit('setExpenseTypeToDelete', {})
        },
        deleteExpenseType: ({ dispatch, commit, state }) => {
            commit('loading', true)
            axios.delete(endPoints.expense_types + "/" + state.expenseTypeToDelete.id, state.expenseTypeToDelete).then(() => {
                dispatch('closeDeleteDialog')
                let notification = {
                    show: true,
                    message: "Plano de contas excluído com sucesso.",
                    type: "success"
                }
                commit('showNotification', notification)
                dispatch('getExpenseTypes')
            });
        },
        getExpenseTypes: ({ commit }) => {
            commit('loading', true)
            let url = endPoints.expense_types;
            axios.get(url).then(expenseTypes => {
                commit('setTotalExpenseTypes', expenseTypes.data.total)
                commit('loading', false)
                commit('setShowedExpenseTypes', getExpenseChildren(expenseTypes.data.data, 0) )
                commit('setExpenseTypes', expenseTypes.data.data)
                commit('incrementTreeViewKey')
            });
        },
        getNodeChildren: ({ state }, payload) => {
            let children = [];
            state.expenseTypes.forEach(expense => {
                if(expense.parent_node === payload.id) {
                    children.push({
                        id: expense.id,
                        id_dre: expense.id_dre,
                        name: expense.label,
                        depth: expense.depth,
                        parent_node: expense.parent_node,
                        children: []
                    })
                }
            })
            recursiveSetChildren(state.showedExpenseTypes, {id: payload.id, data: children})
            
        },
        showDeleteDialog: ({ commit }, item) => {
            commit('setDialogDelete', true)
            commit('setExpenseTypeToDelete', item)
        },
        saveExpenseTypes: ({ dispatch, commit, state }) => {
            var url = endPoints.expense_types;
            let func = '';
            if (state.mode == 'ADD') {
                func = axios.post;
            } else {
                func = axios.put;
                url += '/' + state.editedExpenseTypes.id;
            }
            
            func(url, state.editedExpenseTypes).then(
                () => {
                    dispatch('getExpenseTypes')
                    let notification = {
                        show: true,
                        message: "Plano de contas salvo com sucesso.",
                        type: "success"
                    }
                    dispatch('closeDialog')
                    commit('showNotification', notification)
                },
                error => {
                    let notification = {
                        show: true,
                        message: error.response.data.message,
                        type: "error"
                    }
                    commit('showNotification', notification)
                }
            );
        }
    }
}