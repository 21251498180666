<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline text-center justify-center">Filtro</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form">
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm6 md6>
                <v-text-field
                  required
                  label="ID"
                  v-model="id"
                  :error-messages="errors.collect('id')"
                  data-vv-name="id"
                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-menu
                  v-model="showStart"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="start_formatted"
                      label="Inicio"
                      outlined
                      readonly
                      append-icon="mdi-close"
                      @click:append="clearStart()"
                      persistent-hint
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="pt-br"
                    v-model="start"
                    no-title
                    @input="(e) => setStart(e)"
                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-select
                  label="Tipo"
                  :items="possible_types"
                  append-icon="mdi-close"
                  @click:append="clearType()"
                  v-model="type_period"
                  item-text="description"
                  item-value="id"
                  outlined
                ></v-select>
              </v-flex>

            <v-flex xs12 sm6 md6>
                <v-text-field
                  required
                  label="Descrição"
                  v-model="description"
                  :error-messages="errors.collect('description')"
                  data-vv-name="description"
                  outlined
                ></v-text-field>
              </v-flex>

            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn text @click="showFilter({show: false})">Cancelar</v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn text type="submit" right @click="filter">Filtrar</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "PeriodsClosureFilter",
  computed: {
    ...mapState("instances", ["instances"]),
    ...mapFields("periods_closure", [
      "filter.id",
      "filter.start",
      "filter.type_period",
      "filter.description",
      // ...
    ]),
  },
  data: () => ({
    possible_types: [
      {
        id: 0,
        description: "Fechamento"
      },
      {
        id: 1,
        description: "Teste"
      }
    ],
    timeProps: {
      format: "24hr",
    },
    showStart: false,
    start_formatted: "",
  }),
  methods: {
    ...mapMutations("periods_closure", ["showFilter"]),
    ...mapActions("periods_closure", ["getItems"]),
    filter() {
      this.getItems({ resetPage: true });
      this.showFilter({ show: false });
    },
    setStart(date) {
      this.start = date;
      this.showStart = false;
      this.start_formatted = this.formatDate(date);
    },
    clearStart() {
        this.start = ''
        this.start_formatted = ''
    },
    clearType() {
        this.type_period = ''
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    },
  },
};
</script>

<style>
</style>