import { render, staticRenderFns } from "./WithdrawalsBlacklistForm.vue?vue&type=template&id=35a5834c"
import script from "./WithdrawalsBlacklistForm.vue?vue&type=script&lang=js"
export * from "./WithdrawalsBlacklistForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports