<template>
  <div>
    <div @drop.prevent="addDropFile" @dragover.prevent>
      <v-card>
        <v-card-title>
          <span class="headline text-center">Despesas</span>
          <v-spacer></v-spacer>
          <v-progress-circular
            v-if="loading"
            indeterminate
            class="text-right"
            color="primary"
            size="24"
          ></v-progress-circular>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm6 md6>
                  <v-select
                  required
                    :clearable="true"
                    clear-icon="mdi-close"
                    label="Empresa *"
                    :items="companies"
                    v-model="id_company"
                    :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                    :error-messages="errors.collect('id_company')"
                    v-validate="'required:Empresa,id_company'"
                    item-text="name"
                    item-value="id"
                    data-vv-name="id_company"
                    outlined
                  ></v-select>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-datetime-picker
                    v-model="date_picker"
                    date-format="dd/MM/yyyy"
                    :time-picker-props="timeProps"
                    label="Data *"
                    :textFieldProps="{ outlined: true }"
                  >
                    <template slot="dateIcon">
                      <v-icon>mdi-calendar</v-icon>
                    </template>
                    <template slot="timeIcon">
                      <v-icon>mdi-clock</v-icon>
                    </template>
                  </v-datetime-picker>
                </v-flex>

               <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="CPF / CNPJ Destinatário *"
                    v-model="inscription"
                    :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                    :error-messages="errors.collect('inscription')"
                    v-validate="'required:CPF / CNPJ ,inscription'"
                    data-vv-name="inscription"
                    outlined
                  ></v-text-field>
                </v-flex>

               <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Nome Destinatário*"
                    v-model="name_destiny"
                    :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                    :error-messages="errors.collect('name_destiny')"
                    v-validate="'required:Nome Destinatário ,name_destiny'"
                    data-vv-name="name_destiny"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm12 md12>
                  <v-text-field
                    required
                    label="Descrição *"
                    v-model="description"
                    :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                    :error-messages="errors.collect('description')"
                    v-validate="'required:Descrição,description'"
                    data-vv-name="description"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Valor *"
                    v-model="value"
                    :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                    :error-messages="errors.collect('value')"
                    v-validate="'required:Valor,value|decimal:2'"
                    data-vv-name="value"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-select
                    :clearable="true"
                    clear-icon="mdi-close"
                    label="Banco"
                    :items="banksPix"
                    v-model="bank"
                    :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                    :error-messages="errors.collect('bank')"
                    :item-text="getTextBank"
                    item-value="id"
                    data-vv-name="bank"
                    outlined
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Pago por"
                    v-model="user_name"
                    :readonly="true"
                    :error-messages="errors.collect('user_name')"
                    data-vv-name="user_name"
                    outlined
                  ></v-text-field>
                </v-flex>
                 <v-flex xs12 sm6 md6>
                  <v-select
                    :clearable="true"
                    clear-icon="mdi-close"
                    label="Centro de Custos"
                    :items="costCenters"
                    v-model="id_cost_center"
                    :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                    :error-messages="errors.collect('cost')"
                    :item-text="getTextCostCenter"
                    item-value="id"
                    data-vv-name="id_cost_center"
                    outlined
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-autocomplete
                    style="max-height: 200px"
                    :no-filter="true"
                    clearable
                    deletable-chips
                    chips
                    small-chips
                    label="Plano de Contas *"
                    :items="options"
                    v-model="type"
                    :item-text="text"
                    item-value="id"
                    outlined
                    no-data-text="Nenhum plano de contas encontrado"
                    placeholder="Digite aqui o plano de contas"
                    :search-input.sync="searchExpenseTypes"
                    content-class="teste"
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-select
                    label="Status *"
                    :items="possible_status"
                    v-model="status"
                    :readonly="true"
                    :error-messages="errors.collect('status')"
                    v-validate="'required:Status,status'"
                    item-text="description"
                    item-value="id"
                    data-vv-name="status"
                    outlined
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-select
                    :clearable="true"
                    clear-icon="mdi-close"
                    label="Recorrência"
                    :items="recurrence_types"
                    v-model="recurrence"
                    :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                    :error-messages="errors.collect('recurrence')"
                    item-text="description"
                    item-value="id"
                    data-vv-name="recurrence"
                    outlined
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-select
                    label="Tipo"
                    :items="variables"
                    v-model="variable"
                    :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                    :error-messages="errors.collect('variable')"
                    v-validate="'required:Tipo,variable'"
                    item-text="description"
                    item-value="id"
                    data-vv-name="variable"
                    outlined
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-textarea
                    auto-grow
                    outlined
                    label="Observação"
                    id="textareamessage"
                    hide-details="true"
                    rows="1"
                    :error-messages="errors.collect('observation')"
                    data-vv-name="observation"
                    v-model="observation"
                    :value="observation"
                  ></v-textarea>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Chave Pix *"
                    v-model="pix_key"
                    :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-file-input
                    v-model="receipt_file"
                    v-if="this.mode == 'ADD'"
                    label="Comprovante"
                    :error-messages="errors.collect('receipt')"
                    data-vv-name="receipt"
                    outlined
                  ></v-file-input>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-col>
            <v-btn text @click="closeDialog">{{
              mode == "VIEW" ? "Voltar" : "Cancelar"
            }}</v-btn>
          </v-col>
          <v-col class="text-right" v-if="mode != 'VIEW'">
            <v-btn text type="submit" right @click="saveExpense">Salvar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  name: "ExpenseForm",
  props: ["screen"],
  computed: {
    ...mapGetters("login", ["getInstanceId"]),
    ...mapState("expense", [
      "banks",
      "companies",
      "dialogReceipt",
      "expense_types",
      "item",
      "loading",
      "mode",
      "options",
      "filterTypes",
      "costCenters"
    ]),
    ...mapState("instances", ["instances"]),
    ...mapFields("expense", [
      "editedItem.id",
      "editedItem.bank",
      "editedItem.id_company",
      "editedItem.description",
      "editedItem.date",
      "editedItem.date_picker",
      "editedItem.type",
      "editedItem.recurrence",
      "editedItem.variable",
      "editedItem.observation",
      "editedItem.user_name",
      "editedItem.receipt",
      "editedItem.receipt_file",
      "editedItem.value",
      "editedItem.status",
      "editedItem.pix_key",
      "editedItem.id_cost_center",
      "editedItem.name_destiny",
      "editedItem.inscription",
      // ...
    ]),
    banksPix() {
      return this.banks.filter((b) => b.make_withdrawals == 1);
    },
  },
  created() {
    this.getCompanies();
    this.getBanks();
    this.filterTypes.has_child = "0"
    this.getExpenseTypes();
    this.$validator.extend("required", {
      getMessage: (field, [label]) => label + " deve ser informado",
      validate: (_, [, field]) => {
        if (this[field] || this[field] === 0) {
          return true;
        } else {
          return false;
        }
      },
    });
  },
  mounted() {
    this.getCostCenters();

  },
  methods: {

    text: item => item.label + " - " + item.id_dre,
    ...mapActions("expense", [
      "closeDialog",
      "getCompanies",
      "getBanks",
      "getCostCenters",
      "getExpenseTypes",
      "getSearchExpenseTypes",
      "save",
    ]),
    ...mapActions("instances", ["getInstances"]),
    addDropFile(e) {
      if (this.mode == "ADD" || this.mode == "EDIT") {
        if (e.dataTransfer) {
          if (e.dataTransfer.files[0]) {
            this.receipt_file = e.dataTransfer.files[0];
          }
        }
      }
    },
    getAlertMessage() {
      if (typeof this.bank == "object") {
        return this.bank.observation;
      }
      return "";
    },
    getTextBank(item) {
      if (item.account) {
        return item.name + " | " + item.account;
      } else {
        return item.name;
      }
    },
    getTextCostCenter(item) {
      return item.name;
    },
    getAccountType(type) {
      switch (type) {
        case 0:
          return "Corrente";
        case 1:
          return "Poupança";
      }
    },
    saveExpense() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          var d = new Date(this.date_picker);
          this.date = d.toISOString().substr(0, 10) + " " + d.toLocaleTimeString();
          this.value = parseFloat(this.value);
          this.save();
        }
      });
    },
  },
  data: () => ({
    searchExpenseTypes: '',
    possible_status: [
      { id: 0, description: "Pendente" },
      { id: 1, description: "Agendado" },
      { id: 2, description: "Pago" },
      { id: 3, description: "Em Processamento" },
    ],
    recurrence_types: [
      { id: 0, description: "Mensal" },
      { id: 1, description: "Trimestral" },
      { id: 2, description: "Semestral" },
      { id: 3, description: "Anual" },
    ],
    variables: [
      { id: 0, description: "Custo Fixo" },
      { id: 1, description: "Custo Variavel" },
    ],
    timeProps: {
      format: "24hr",
    },
    valid: false,
  }),
  watch: {
    searchExpenseTypes (val) {
      var payload = {
        search: val
      }
      this.getSearchExpenseTypes(payload)
    }
  }
};
</script>
<style>
.teste {
  max-height: 200px;
}
</style>