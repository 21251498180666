import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../config/endPoints';

const defaultItem = {
    cert_dir: "",
    cert_key: "",
    client_id: "",
    client_key: "",
    code: "",
    name: "",
    observation: "",
    account: null,
    status: 0,
    pix_key: '',
    priority: 0,
    pix_search_by_cpf: 0,
    pix_api_url: '',
    pix_auth_url: '',
    dev_app_key: '',
    receive_deposit: '',
    make_withdrawals: '',
    make_rate: '',
};

export default {
    namespaced: true,
    state: {
        dialog: false,
        dialogDelete: false,
        editedItem: {},
        errors: [],
        items: [],
        itemToDelete: {},
        loading: false,
        mode: 'LIST',
        notificationType: '',
        notificationMessage: '',
        page: 1,
        pagination: {
            page: 1,
            itemsPerPage: 100
        },
        showNotification: false,
        totalItems: 0,
        rowsPerPage: 100
    },
    mutations: {
        add: (state) => {
            state.dialog = true
            state.mode = "ADD"
            state.editedItem = Object.assign({}, defaultItem)
        },
        edit: (state, item) => {
            state.editedItem = item
            state.editedIndex = state.items.indexOf(item)
            state.dialog = true
            state.mode = "EDIT"
        },
        closeDialog: (state) => {
            state.editedItem = Object.assign({}, defaultItem)
            state.editedIndex = -1
            state.errors = [];
        },
        loading: (state, loading) => state.loading = loading,
        setErrorMessage: (state, message) => state.errorMessage = message,
        setMode: (state, mode) => state.mode = mode,
        setItems: (state, items) => state.items = items,
        setItemToDelete: (state, item) => state.itemToDelete = item,
        setTotalItems: (state, total) => state.totalItems = total,
        setRowsPerPage: (state, rowsPerPage) => state.rowsPerPage = rowsPerPage,
        showDialog: (state, show) => state.dialog = show,
        setDialogDelete: (state, show) => state.dialogDelete = show,
        showErrorNotification: (state, show) => state.errorNotification = show,
        showSuccessNotification: (state, show) => state.successNotification = show,
        showLoading: (state, show) => state.loading = show,
        showNotification: (state, payload) => {
            state.showNotification = payload.show,
                state.notificationMessage = payload.message,
                state.notificationType = payload.type
        },
        updateField,
        viewItem(state, item) {
            state.editedItem = item
            state.editedIndex = state.items.indexOf(item)
            state.dialog = true
            state.mode = "VIEW"
        }
    },
    getters: {
        getField,
        getBanks: (state) => {
            return state.items
        }
    },
    actions: {
        closeDialog: (({ commit }) => {
            commit('showDialog', false)
            setTimeout(() => {
                commit('closeDialog')
                commit('showErrorNotification', false)
                commit('showSuccessNotification', false)
            }, 300);
        }),
        closeDeleteDialog: ({ commit }) => {
            commit('setDialogDelete', false)
            commit('setitemToDelete', {})
        },
        deleteItem: ({ dispatch, commit, state }) => {
            commit('loading', true)
            axios.delete(endPoints.banks + "/" + state.itemToDelete.id, state.itemToDelete).then(() => {
                dispatch('closeDeleteDialog')
                return dispatch('getItems');
            });
        },
        getItems: ({ commit, state }) => {
            commit('loading', true)
            let url = endPoints.banks;
            url += '?page=' + state.pagination.page + '&' + 'itemsPerPage=' + state.pagination.itemsPerPage;
            if (state.pagination && state.pagination.sortBy && state.pagination.sortBy.length > 0) {
                url += "&sort_by=" + state.pagination.sortBy[0]
                url += "&sort_desc=" + state.pagination.sortDesc[0]
            }
            axios.get(url).then(items => {
                commit('setTotalItems', items.data.total)
                commit('loading', false)
                commit('setItems', items.data.data)
            });
        },
        showDeleteDialog: ({ commit }, item) => {
            commit('setDialogDelete', true)
            commit('setItemToDelete', item)
        },
        save: ({ dispatch, commit, state }) => {
            state.editedItem.rate_flat = parseFloat(state.editedItem.rate_flat)
            state.editedItem.rate_percentage = parseFloat(state.editedItem.rate_percentage)
            let url = endPoints.banks;
            let func = '';
            if (state.mode == 'ADD') {
                func = axios.post;
                url = endPoints.banks;
            } else {
                func = axios.put;
                url += '/' + state.editedItem.id;
            }

            func(url, state.editedItem).then(
                () => {
                    dispatch('getItems')
                    let notification = {
                        show: true,
                        message: "Banco salvo com sucesso.",
                        type: "success"
                    }
                    dispatch('closeDialog')
                    commit('showNotification', notification)
                },
                error => {
                    let notification = {
                        show: true,
                        message: error.response.data.message,
                        type: "error"
                    }
                    commit('showNotification', notification)
                }
            );
        }
    }
}