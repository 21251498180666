import axios from "axios";
import { getField, updateField } from "vuex-map-fields";
import endPoints from "../../../config/endPoints";
import b64 from "../../../util/Base64";

const defaultItem = {
  description: "",
  file_name: "",
  last_update: "",
  type_document: "",
  document_file: "",
};

export default {
  namespaced: true,
  state: {
    collaborator_id: null,
    dialog: false,
    dialogDelete: false,
    dialogShowDocument: false,
    documentToShow: "",
    selectedItem: {},
    find_agents: [],
    // filter: {
    //     id_master_agent: ''
    // },
    // filter_agent: {
    //     nick: '',
    // },
    items: [],
    itemToDelete: {},
    loading: false,
    mode: "LIST",
    overlay: false,
    pagination: {
      page: 1,
      itemsPerPage: 10,
    },
    subCollaboratorsCollaborator: [],
    totalItems: 0,
  },
  mutations: {
    add: (state) => {
      state.mode = "ADD";
      state.selectedContact = {};
      state.selectedItem = Object.assign({}, defaultItem);
      state.dialog = true;
    },
    edit: (state, payload) => {
      state.selectedContact = {};
      state.selectedItem = Object.assign({}, payload.item);
      state.editedIndex = state.subCollaboratorsCollaborator.indexOf(
        payload.item
      );
      state.mode = "EDIT";
      state.dialog = true;
    },
    closeDialog: (state) => {
      state.selectedItem = {};
      state.editedIndex = -1;
      state.errors = [];
      state.dialog = false;
      state.mode = "LIST";
    },
    loading: (state, payload) => (state.loading = payload.loading),
    setMode: (state, mode) => (state.mode = mode),
    setSubCollaborators: (state, payload) => {
      state.subCollaboratorsCollaborator = payload.subCollaborators;
    },
    setItems: (state, payload) => {
      state.items = payload.items;
    },
    showOverlay: (state, payload) => {
      state.overlay = payload.show;
    },
    setDialogShowDocument: (state, payload) =>
      (state.dialogShowDocument = payload.show),
    setDocumentToShow: (state, payload) =>
      (state.documentToShow = payload.document),
    setItemToDelete: (state, item) => (state.itemToDelete = item),
    setTotalItems: (state, payload) => (state.totalItems = payload.total),
    showDialog: (state, show) => (state.dialog = show),
    setFindAgents: (state, payload) =>
      (state.find_agents = payload.find_agents),
    setDialogDelete: (state, payload) => (state.dialogDelete = payload.show),
    showFilter: (state, payload) => (state.dialogFilter = payload.show),
    showLoading: (state, show) => (state.loading = show),
    showNotification: (state, payload) => {
      (state.showNotification = payload.show),
        (state.notificationMessage = payload.message),
        (state.notificationType = payload.type);
    },
    updateField,
  },
  getters: {
    getField,
  },
  actions: {
    closeDialog: ({ commit }) => {
      commit("closeDialog", false);
    },
    closeDeleteDialog: ({ commit }) => {
      commit("setDialogDelete", { show: false });
      commit("setItemToDelete", {});
    },
    deleteItem: ({ dispatch, commit, state }) => {
      commit("loading", true);
      let url = endPoints.collaborator_documents + "/";
      url += state.itemToDelete.id;
      axios.delete(url).then(() => {
        let notification = {
          show: true,
          message: "Documento excluido com sucesso",
          type: "success",
        };
        commit("showNotification", notification);
        commit("setDialogDelete", { show: false, item: {} });
        commit("loading", { loading: false });
        dispatch("getItems");
      });
    },
    showDeleteDialog: ({ commit }, item) => {
      commit("setDialogDelete", { show: true });
      commit("setItemToDelete", item);
    },
    getItems: ({ commit, rootGetters }) => {
      commit("loading", { loading: true });
      let url = endPoints.collaborator_documents;
      url += `/${rootGetters["collaborator/getIdCollaborator"]}`;
      axios.get(url).then((response) => {
        let data = response.data != null ? response.data : [];

        commit("setTotalItems", { total: data.length });
        commit("loading", { loading: false });
        commit("setItems", { items: data });
      });
    },
    showDocument: ({ commit }, payload) => {
      commit("setDialogShowDocument", { show: true });
      let url = endPoints.collaborator_documents;

      url += "/" + payload.item.id + "?type=1";
      commit("showOverlay", { show: true });
      axios
        .get(url)
        .then((r) => {
          let file = r.item.document_file;
          let mimetype = file.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
          if (mimetype.indexOf("pdf") != -1) {
            let name = "Documento-" + payload.item.id + ".pdf";
            var a = document.createElement("a");
            a.href = file;
            a.download = name;
            a.click();
          } else {
            commit("setDocumentToShow", {
              document: r.item.document_file,
            });
            commit("setDialogShowDocument", { show: true });
          }
          commit("showOverlay", { show: false });
        })
        .catch(() => {
          let notification = {
            show: true,
            message: "Documento não encontrado",
            type: "error",
          };
          commit("showNotification", notification);
          commit("showOverlay", { show: false });
        });
    },
    // showDocument: ({ commit }, payload) => {

    //     let file = payload.item.document_file
    //     let mimetype = file.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
    //     if (mimetype.indexOf("pdf") != -1 || payload.download == true) {
    //         var a = document.createElement("a"); //Create <a>
    //         a.href = file; //Image Base64 Goes here
    //         a.download = payload.item.file_name; //File name Here
    //         a.click();
    //     } else {
    //         commit('setDocumentToShow', { document: payload.item.document_file })
    //         commit('setDialogShowDocument', { show: true })
    //     }
    //     commit('overlay', { show: false })

    // },
    downloadDocument: ({ commit }, payload) => {
      let file = payload.item.document_file;
      let mimetype = file.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
      if (mimetype.indexOf("pdf") != -1 || payload.download == true) {
        var a = document.createElement("a"); //Create <a>
        a.href = file; //Image Base64 Goes here
        a.download = payload.item.file_name; //File name Here
        a.click();
      } else {
        commit("setDocumentToShow", { document: payload.item.document_file });
        commit("setDialogShowDocument", { show: true });
      }
      commit("showOverlay", { show: false });
    },
    save: ({ commit, dispatch, state, rootGetters }) => {
      var url = endPoints.collaborator_documents;
      let func = "";
      if (state.mode == "ADD") {
        func = axios.post;
        state.selectedItem.collaborator_id =
          rootGetters["collaborator/getIdCollaborator"];
        state.selectedItem.file_name = state.selectedItem.document_file.name;
        url = endPoints.collaborator_documents;
      } else {
        func = axios.put;
        url += endPoints.collaborator_documents;
      }

      b64(state.selectedItem.document_file).then((fileBase64) => {
        state.selectedItem.document_file = fileBase64;
        func(url, state.selectedItem).then(
          () => {
            dispatch("getItems", {
              resetPage: true,
              collaborator_id: rootGetters["collaborator/getIdCollaborator"],
            });
            let notification = {
              show: true,
              message: "Documento salvo com sucesso",
              type: "success",
            };
            commit("showNotification", notification);
            commit("closeDialog");
          },
          (error) => {
            let notification = {
              show: true,
              message: error.response.data.message,
              type: "error",
            };
            commit("showNotification", notification);
          }
        );
      });
    },
  },
};
