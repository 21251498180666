<template>
    <div>
        <v-card>
            <v-card-title>
                <span class="headline text-center justify-center">Reenvio</span>
            </v-card-title>

            <v-card-text>
                <v-container grid-list-md>
                    <v-layout wrap>
                        <v-flex xs12 sm6 md6>
                            <v-text-field
                                required
                                label="Nome Solicitante"
                                v-model="name"
                                outlined
                                readonly
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6 md6>
                            <v-text-field
                                required
                                label="Data Solicitação"
                                :value="getDate(createdAt)"
                                outlined
                                readonly
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6 md6>
                            <v-text-field
                                required
                                label="Valor"
                                :value="value ? parseFloat(value).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL',}) : 'R$ 0,00'"
                                outlined
                                readonly
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6 md6>
                            <v-text-field
                                required
                                label="Plataforma"
                                :value="platform"
                                outlined
                                readonly
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6 md6>
                            <v-text-field
                                required
                                label="Slot"
                                :value="`${slot} - ${slotLabel}`"
                                outlined
                                readonly
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6 md6>
                            <v-text-field
                                required
                                label="ID App"
                                :value="playerid"
                                outlined
                                readonly
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm12 md12>
                            <v-text-field
                                required
                                label="Motivo do Erro"
                                v-model="reason"
                                outlined
                                readonly
                            ></v-text-field>
                        </v-flex>
                        
                    </v-layout>
                    <v-divider></v-divider>
                    <v-form ref="form" v-model="valid">
                        <v-layout wrap class="mt-3">
                            <v-flex xs12 sm12 md12>
                                <span class="text-center justify-center">Informe os novos dados</span>
                            </v-flex>
                            <v-flex xs12 sm6 md6 class="mt-2">
                                <v-autocomplete
                                    label="Plataforma"
                                    :items="platforms"
                                    v-model="newPlatform"
                                    item-text="description"
                                    item-value="description"
                                    outlined
                                    :clearable="true"
                                    :rules="[rules.required]"
                                >
                                </v-autocomplete>
                            </v-flex>
                            <v-flex xs12 sm6 md6 class="mt-2">
                                <v-autocomplete
                                    :readonly="!platform"
                                    label="Clube *"
                                    :items="clubsApp.filter(club => club.platform == newPlatform)"
                                    v-model="newSlot"
                                    item-value="club"
                                    clear-icon="mdi-close"
                                    item-text="clubLabel"
                                    :clearable="true"
                                    outlined
                                    :rules="[rules.required]"
                                ></v-autocomplete>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-text-field
                                    required
                                    label="ID App"
                                    v-model="newPlayerId"
                                    outlined
                                    :rules="[rules.required]"
                                ></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-form>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-col>
                    <v-btn color="red" text @click="showDialogEditAndResend({ show: false, item: {}, mode: 'LIST' })"
                        >Cancelar</v-btn
                    >
                </v-col>
                <v-col class="text-right">
                    <v-btn color="green" text type="submit" right @click="resend"
                        >Reenviar</v-btn
                    >
                </v-col>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
    name: 'AutoAttendanceResend',
    computed: {
        ...mapFields('auto_attendance', [
            'editedItem',
            'editedItem.id',
            'editedItem.endToEndId',
            'editedItem.idCob',
            'editedItem.doc',
            'editedItem.createdAt',
            'editedItem.name',
            'editedItem.payerDocument',
            'editedItem.payerName',
            'editedItem.value',
            'editedItem.reason',
            'editedItem.slot',
            'editedItem.slotLabel',
            'editedItem.datePay',
            'editedItem.playerid',
            'editedItem.platform',
        ]),
        ...mapFields('auto_attendance', {
            newPlayerId: 'resend.playerid',
            newSlot: 'resend.slot',
            newPlatform: 'resend.platform'
        }),
        ...mapState("clubs", { clubsApp: "clubs", platforms: "platforms"})
    },
    created() {
        console.log('criou o resend')
    },
    data: () => ({
        rules: {
            required: value => !!value || "Campo obrigatório."
        },
        valid: false
    }),
    methods: {
        ...mapMutations('auto_attendance', [
            'showDialogEditAndResend'
        ]),
        ...mapActions('auto_attendance', ['resendAutoAttendance']),
        getDate(date) {
            if (date) {
                var d = new Date(date)
                return d.toLocaleDateString() + ' ' + d.toLocaleTimeString()
            }
            return ''
        },
        resend() {
            let valid = this.$refs.form.validate();
            console.log("Valido: ", valid)
            if(valid) {
                this.resendAutoAttendance()
            }
        }
    },
}
</script>

<style></style>
