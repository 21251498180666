<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2000"
      v-model="showNotification"
    >
      {{ notificationMessage }}
    </v-snackbar>

    <v-toolbar flat class="elevation-5">
      <v-toolbar-title>Árvore de Agentes</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="black" dark class="ma-2" @click="showFilter({show: true})" small>
              <v-icon>mdi-filter</v-icon>
            </v-btn>
        </template>
        <span>Filtrar</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="black" dark class="ma-2" @click="getItems({resetPage: false})" small>
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
        </template>
        <span>Recarregar</span>
      </v-tooltip>
      
     
    </v-toolbar>

    <v-treeview
      :active.sync="active"
      :items="items"
      :load-children="fetchTreeChildrens"
      :open.sync="open"
      activatable
      color="warning"
      open-on-click
      transition
      item-key="tree_code"
    >
      <template v-slot:prepend="{ item }">
        <v-icon v-if="!item.children">
          mdi-account
        </v-icon>
        <span>{{ item.tree_code + " - " +item.nick }}</span>
      </template>
    </v-treeview>

    <v-dialog :value="dialogFilter" @click:outside="showFilter({show: false})" width="600" @keydown.enter="filter">
      <agents-tree-filter></agents-tree-filter>
    </v-dialog>

    <v-dialog :value="dialogDelete" @click:outside="closeDeleteDialog" width="300">
      <v-card>
        <v-card-title class="headline">Confirmar exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn
              text
              @click="closeDeleteDialog()"
            >Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn
              text
              type="submit"
              right
              @click="deleteItem"
            >Confirmar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog :value="showContactDescription" @click:outside="showDialogContactDescription({show: false, contact_description: '', annotated: ''})" width="600"> 
      <contact-description
        start_mode="EDIT"
        screen="agent"
      ></contact-description>
    </v-dialog>
  </div>
</template>

<script>
  import { mapActions, mapMutations, mapState } from 'vuex'
  import { mapFields } from 'vuex-map-fields'
  import AgentsTreeFilter from './AgentsTreeFilter'
  import ContactDescription from '../../attendance/Chat/components/ContactDescription'

  export default {
    name: 'Agents',
    components: {
      "agents-tree-filter": AgentsTreeFilter,
      "contact-description": ContactDescription,
    },
    created() {
      this.getSlots({ resetPage: false, noPages: true, onlyIfNotSearched: true })
      this.getItems({resetPage: false})
    },
    computed: {
      ...mapState('agents_tree', ['dialog', 'dialogAnnotated', 'dialogDelete', 'item', 'loading', 'mode', 'notificationType', 'notificationMessage', 'rowsPerPage',  'totalItems', 'items']),
      ...mapFields('agents_tree', [
        'dialogFilter',
        'pagination',
        'showNotification'
      ]),
      ...mapFields('chat', ['showContactDescription'])
    },
    data: () => ({
      active: [],
      headers: [
        { text: "Código Arvore", value: "tree_code" },
        { text: "Nick", value: "nick" },
        { text: "Nivel", value: "level"},
        { text: "Tipo de Ganho (Master) ", value: "type_deal_with_master", align: "center"}, 
        { text: "Ganhos Sobre (Master)", value: "relationship_master_agent" },
        { text: '', value: 'data-table-expand' },
        { text: "Ações",  value: "actions", sortable: false, align: "center" },
      ],
      overlay: false,
      open: [],
      sheet_fields: {
        Clube: {
          field: 'club_name',
        },
        'Id PPPoker': {
          field: 'id_pppoker',
        },
        Nick: {
          field: 'nick',
        },
        'Rake Deal' : {
          field: 'rake_deal',
        },
        'Tipo de Acerto': {
          field: 'type_deal',
          callback: (type) => {
            let types = ["Ganho/Perda", "Giro", "Normal", "Fichas Enviadas/Retiradas", "Sem Acerto", "Sem Acerto/GP"];
            if (types[type]) {
              return types[type];
            }
            return "";
          }
        },
        Telefone: {
          field: 'phone',
          callback: (value) => {
            return 'Nº - ' + value
          }
        },
        "Nº Grupo": {
          field: 'ag_group_id',
          callback: (value) => {
            return 'Nº - ' + value
          }
        },
        'Grupo do Agente': {
          field: 'ag_group_name'
        },
        Master: {
          field: 'is_master_agent',
          callback: (value) => {
            if(value == 0) {
              return 'Não'
            } else {
              return 'Sim'
            }
          }
        },
      }
    }),
    methods: {
      ...mapActions("slots", {getSlots: "getSlots"}),
      ...mapActions('agents_tree', ['closeDialog', 'closeDeleteDialog', 'deleteItem', 'fetchTreeChildrens', 'getItems', 'getItemsNoPage', 'showDeleteDialog', 'viewItem']),
      ...mapMutations('agents_tree', ['showFilter']),
      filter() {
        this.getItems({resetPage: true});
        this.showFilter({ show: false });
      },
      getTypeDealWithMaster(type) {
        let types = ["Diferença", "Fixo"]
        if(types[type]) {
          return types[type]
        } 
        return "";
      },
      getRelationshipWithMaster(type) {
        let types = ["Jogadores", "Jogadores e Sub-Agentes"]
        if(types[type]) {
          return types[type]
        } 
        return "";
      },
      finishSheet() {
        this.overlay = false
      },
      generateSheet() {
        this.overlay = true
      },
      getSheetName() {
        var date = new Date()
        var name = 'agents_tree_' + date.toLocaleDateString() + " " + date.toLocaleTimeString()
        return name+'.xls'
      },
    },
    watch: {
      pagination: {
        handler () {
          this.getItems({resetPage: false})
        },
      },
    }
  }
</script>
