<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="4000"
      v-model="showNotification"
    >
      {{ notificationMessage }}
    </v-snackbar>
    <v-toolbar flat class="elevation-5" color="green lighten-4">
      <div>
        <v-toolbar-title style="color: rgb(29, 90, 0)"><v-icon style="margin-right: 8px; margin-bottom: 3px; color: rgb(29, 90, 0)">mdi-account-edit</v-icon>Manutenção de Jogadores</v-toolbar-title>
      </div>
      <v-spacer></v-spacer>
      <v-btn color="black" dark class="ma-2" @click="showPlayerDeleteDialog({ show: true })" small
          ><v-icon>mdi-plus</v-icon></v-btn
        >
      <div class="button-toolbar">
        <v-btn
          color="black"
          dark
          class="ma-2"
          @click="showFilter({ show: true })"
          small
          ><v-icon>mdi-filter</v-icon></v-btn
        >
        <v-btn color="black" dark class="ma-2" @click="reload" small
          ><v-icon>mdi-refresh</v-icon></v-btn
        >
      </div>
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :options.sync="pagination"
      :items="items"
      :server-items-length="totalItems"
      no-data-text="Nenhuma jogador encontrado"
      class="elevation-10 table-margin"
    >
      <template v-slot:[`item.type`]="{ item }">
        <v-chip :color="getColorTypeOrder(item.type)" class="ma-2" text-color="white" label>{{ getTypeOrder(item.type) }}</v-chip>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-chip :color="getColorStatus(item.status)" class="ma-2" text-color="white" label>{{ getStatus(item) }}</v-chip>
      </template>
      <template v-slot:[`item.finish_at`]="{ item }"> 
        <span>{{ getDate(item.finish_at)}}</span>
      </template>
      <template v-slot:[`item.created_at`]="{ item }"> 
        <span>{{ getDate(item.created_at)}}</span>
      </template>
      <template v-slot:[`item.item_name`]="{ item }"> 
        <span>{{ handleItemDisplay(item.item_name)}}</span>
      </template>
      <template v-slot:[`item.print`]="{ item }">
        <v-tooltip v-if="(item.type == 2 || item.type == 3) && item.status == 2" bottom>
          <template v-slot:activator="{on, attrs}">
            <v-icon small v-on="on" v-bind="attrs" class="mr-2" @click="viewPrint({item: item})">mdi-image-area</v-icon>
          </template>
          <span>Print</span>
        </v-tooltip>

      </template>
    </v-data-table>

    <v-dialog
      :value="dialogFilter"
      width="600"
      @click:outside="showFilter({ show: false })"
    >
      <player-delete-filter></player-delete-filter>
    </v-dialog>

    <v-dialog :value="dialogPrint" width="600" @click:outside="showDialogPrint({show: false})">

      <v-card height="90vh">
        <v-img :src="receipt" contain></v-img>
      </v-card>

    </v-dialog>

    <v-dialog :value="playerDeleteDialog" width="800" @click:outside="showPlayerDeleteDialog({show: false})">
      <player-delete-form></player-delete-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import PlayersDeleteFilter from './PlayersDeleteFilter'
import PlayersDeleteForm from './PlayersDeleteForm'

export default {
  name: "PlayersDelete",
  components: {
    "player-delete-filter": PlayersDeleteFilter,
    "player-delete-form": PlayersDeleteForm,
  },
  computed: {
    ...mapState("players_delete", [
      "dialogPrint",
      "receipt",
      "supremeItems",
      "dialogFilter",
      "playerDeleteDialog",
      "item",
      "loading",
      "mode",
      "notificationType",
      "notificationMessage",
      "rowsPerPage",
      "totalItems",
      "items",
      "overlay",
      "showNotification",
    ]),
    ...mapFields("players_delete", ['pagination'])
  },
  created() {
    this.getItems({ resetPage: false });
    this.getInstances()
    this.getSupremeItems()
    this.watchOrders();
  },
  methods: {
    ...mapActions("instances", ["getInstances"]),
    ...mapMutations("players_delete", [
        "showFilter",
        "showPlayerDeleteDialog",
        "showDialogPrint"
    ]),
    ...mapActions("players_delete", [
      "closeDialog",
      "getItems",
      "getBots",
      "getSupremeItems",
      "viewPrint"
    ]),
    reload(){
      this.getItems({ resetPage: false })
      this.getBots()
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      }
      return false;
    },
    getClassColor(item) {
      if (item.status == 1) {
        return "row-green";
      } else if (item.status == 0) {
        return "row-yellow-schedule";
      } else if (item.status == 2) {
        return "row-red";
      }
    },
    getStatus(item) {
      switch(item.status) {
        case 0:
          return 'Aguardando'
        case 1:
          return 'Em andamento'
        case 2: 
          return 'Concluído'
        case 3: 
          return 'Falha na Verificação'
        case 4: 
          return 'Diamantes Insuficientes'
        case 5: 
          return 'Id Inválido'
        case 6:
          return 'Bot Inativo'
        case 7:
          return 'Motivo não Identificado'
        case 8:
          return 'Erro de Confirmação'
        case 9:
          return 'Item Não Encontrado'
      }
    },
    getColorStatus(status) {
      if(status == 0) {
        return 'cyan'
      } else if(status == 1) {
        return 'red'
      } else if(status == 2) {
        return 'green'
      } else if(status >= 3) {
        return 'red'
      }
    },
    handleItemDisplay(item) {
      return item != null ? item : "----------"
    },
    watchOrders() {
      if(this.mode == 'LIST') {
        this.getItems({resetPage: false})
      }
      if(this.$router) {
        if(this.$router.history.current.name == "players_delete") {
          setTimeout(() => {
            this.watchOrders()
          }, 20000)
        }
      }
    },
    getColorTypeOrder(type) {
      if(type == 0) {
        return 'red'
      } else if(type == 1) {
        return 'green'
      } else if(type == 2) {
        return 'blue'
      } else if(type == 3) {
        return 'orange'
      }
    },
    getDate(date) {
      if (date != null && date != "") {
        var d = new Date(date);
        return d.toLocaleDateString() + " " + d.toLocaleTimeString();
      } else {
        return "--------"
      }
    },
    getTypeOrder(type) {
      switch (type) {
        case 0:
          return "Exclusão"
        case 1:
          return "Envio de Items"
        case 2:
          return "Perfil do Membro"
        case 3:
          return "Perfil do Agente"
      }
    },
  },
  data: () => ({
    headers: [
      { text: "ID", value: "id", sortable: false, align: "center"}, 
      { text: "Tipo", value: "type", sortable: false, align: "center" },
      { text: "Clube", value: "slot_name", sortable: true, align: "center"},
      { text: "ID do Jogador", value: "player_id", sortable: false, align: "center"}, 
      { text: "Criado Em", align: "center", value: "created_at", sortable: true },
      { text: "Finalizado Em", value: "finish_at", sortable: false, align: "center" },
      { text: "Item", value: "item_name", sortable: false, align: "center" },
      { text: "Print", value: "print", sortable: false, align: "center" },
      { text: "Status", value: "status", sortable: false, align: "center" },
    ],
    valid: false,
    timeProps: {
      format: "24hr",
    },
  }),
  watch: {
    pagination: {
      handler() {
        this.getItems({ resetPage: false });
      },
    },
  },
};
</script>

<style>
.test {
  color: rgb(29, 90, 0)
}
</style>
