<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline text-center justify-center">Filtro</span>

        <v-col class="text-right">
          <v-btn v-if="filtered" color="white" class="ma-2"  @click="clearFilter" right small depressed>
            <v-icon color="black">mdi-filter-remove</v-icon>
          </v-btn>
        </v-col>
      </v-card-title>

      <v-card-text>
        <v-form ref="form">
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm6 md6>
                <v-text-field
                    label="Código"
                    v-model="code"
                    outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-select
                    label="Estado"
                    :items="states"
                    v-model="state"
                    item-text="nome"
                    item-value="sigla"
                    clear-icon="mdi-close"
                    :clearable="true"
                    outlined
                >
                </v-select>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field
                    label="Rastreio"
                    v-model="tracking_code"
                    outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-select
                    label="Status"
                    :items="possible_status"
                    v-model="status"
                    item-text="description"
                    item-value="status"
                    clear-icon="mdi-close"
                    :clearable="true"
                    outlined
                >
                </v-select>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn text @click="showFilter({show: false})">Cancelar</v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn text type="submit" right @click="filter">Filtrar</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "SalesFilter",
  computed: {
    ...mapState("sales", ["filtered"]),
    ...mapFields("sales", [
      "filter.code",
      "filter.tracking_code",
      "filter.status",
      "filter.state"
      // ...
    ]),
  },
  data: () => ({
    possible_types: [
      {id: 0, description: 'Moletom'},
      {id: 1, description: 'Blusa'},
      {id: 2, description: 'Boné'},
      {id: 3, description: 'Calça'},
      {id: 4, description: 'Bermuda'},
      {id: 5, description: 'Outros'},
    ],
    possible_status: [
      {id: 0, description: 'Pendente'},
      {id: 1, description: 'Em Separação'},
      {id: 2, description: 'Enviado'},
      {id: 3, description: 'Concluido'}
    ],
    states: [
        {"nome": "Acre", "sigla": "AC"},
        {"nome": "Alagoas", "sigla": "AL"},
        {"nome": "Amapá", "sigla": "AP"},
        {"nome": "Amazonas", "sigla": "AM"},
        {"nome": "Bahia", "sigla": "BA"},
        {"nome": "Ceará", "sigla": "CE"},
        {"nome": "Distrito Federal", "sigla": "DF"},
        {"nome": "Espírito Santo", "sigla": "ES"},
        {"nome": "Goiás", "sigla": "GO"},
        {"nome": "Maranhão", "sigla": "MA"},
        {"nome": "Mato Grosso", "sigla": "MT"},
        {"nome": "Mato Grosso do Sul", "sigla": "MS"},
        {"nome": "Minas Gerais", "sigla": "MG"},
        {"nome": "Pará", "sigla": "PA"},
        {"nome": "Paraíba", "sigla": "PB"},
        {"nome": "Paraná", "sigla": "PR"},
        {"nome": "Pernambuco", "sigla": "PE"},
        {"nome": "Piauí", "sigla": "PI"},
        {"nome": "Rio de Janeiro", "sigla": "RJ"},
        {"nome": "Rio Grande do Norte", "sigla": "RN"},
        {"nome": "Rio Grande do Sul", "sigla": "RS"},
        {"nome": "Rondônia", "sigla": "RO"},
        {"nome": "Roraima", "sigla": "RR"},
        {"nome": "Santa Catarina", "sigla": "SC"},
        {"nome": "São Paulo", "sigla": "SP"},
        {"nome": "Sergipe", "sigla": "SE"},
        {"nome": "Tocantins", "sigla": "TO"}
    ]
  }),
  methods: {
    ...mapMutations("sales", ["showFilter"]),
    ...mapActions("sales", ["clearFilter", "getItems"]),
    filter() {
      this.getItems({ resetPage: true });
      this.showFilter({ show: false });
    },
  },
};
</script>

<style>
</style>