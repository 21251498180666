<template>
  <div>
    <v-card>
      <v-card-title>
        <span class='headline'>Novo Cadastro</span>
      </v-card-title>
      <v-card-text>
        <v-form ref='form' v-model='valid' lazy-validation>
          <v-container grid-list-md>
            <v-layout wrap>

              <v-flex xs12 sm12 md12>
                <v-text-field
                  label='E-mail'
                  v-model="email"
                  :rules="[rules.required, rules.validEmail]"
                  :readonly="this.mode == 'VIEW'"
                  data-vv-name='email'
                  item-text='name'
                  item-value='email'
                  clear-icon='mdi-close'
                  :clearable="['ADD', 'EDIT'].includes(this.mode)"
                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm12 md12>
                <v-text-field
                  label='Número de Telefone'
                  v-model="phone_number"
                  :rules="[rules.min_length(phone_number, 10), rules.decimal]"
                  :readonly="this.mode == 'VIEW'"
                  data-vv-name='phone_number'
                  item-text='phone_number'
                  item-value='phone_number'
                  clear-icon='mdi-close'
                  :clearable="['ADD', 'EDIT'].includes(this.mode)"
                  outlined
                ></v-text-field>
              </v-flex>

            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-col class="text-center">
          <v-btn
            raised
            color="error"
            @click="setFormDialog({ item: {}, show: false, mode: '' })"
            >{{ mode == "VIEW" ? "Voltar" : "Cancelar" }}</v-btn
          >
        </v-col>

        <v-col class="text-center">
          <v-btn
            raised
            color="success"
            type="submit"
            v-if="mode == 'EDIT' || mode == 'ADD'"
            @click="saveRegistration()"
            >{{ mode == "ADD" ? "Salvar" : "Editar" }}</v-btn
          >
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>

import { mapActions, mapMutations, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'ClientsRegistrationsForm',

  computed: {
    ...mapState('clients_registrations', [
      'dialogForm',
      'dialogMail',
      'mode',
      'selectedItem',
      'showNotification',
    ]),
    ...mapFields('clients_registrations', [
      'selectedItem.email',
      'selectedItem.phone_number',
    ]),
  },
  methods: {
    ...mapActions('clients_registrations', [
      'save',
    ]),
    ...mapMutations('clients_registrations', [
      'setFormDialog',
      'setMailDialog',
      'setSelectedItem',
    ]),
    saveRegistration() {
      let valid = this.$refs.form.validate()
      if (valid) {
        this.save();
      }
    },
  },
  data: () => ({
    valid: true,
    rules: {
      required(value) {
        return !!value || 'Campo Obrigatório'
      },
      requiredAcceptZero(value) {
        return !!value || value === 0 || 'Campo Obrigatório'
      },
      decimal(value) {
        let regex = /^[-]?\d*(\.\d+)?$/
        return regex.test(value) || 'Valor deve ser númerico'
      },
      min_length(value, min_length) {
        if(value && value.length < min_length) return 'Mínimo de ' + min_length + ' caracteres.'
        return true
      },
      max_length(value, max_length) {
        if(value && value.length > max_length) return 'Máximo de ' + max_length + ' caracteres.'
        return true
      },
      validEmail(value) {
        var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(regex.test(value)) {
          return !!value
        } else {
          return 'Insira um e-mail valido!'
        }
      }
    },
  })
}
</script>
