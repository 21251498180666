<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2000"
      v-model="showNotification"
    >
      {{ notificationMessage }}
    </v-snackbar>
    
    <v-toolbar class="mt-2 mb-5 elevation-6 px-3git">
      <v-toolbar-title class="title">Agentes</v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- <download-excel
        :before-generate="generateSheet"
        :before-finish="finishSheet"
        :name="getSheetName()"
        :fetch="getItemsNoPage"
        :fields="sheet_fields"
        class="excel"
        worksheet = "Agentes"
      >
       
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn  v-bind="attrs" v-on="on"  color="#373B40" dark class="ma-2" small>
              <v-icon>mdi-file-excel</v-icon>
            </v-btn> 
          </template>
          <span>Gerar Planilha</span>
        </v-tooltip>
      </download-excel>   -->

      <v-tooltip bottom>
        <template v-slot:activator="{on, attrs}">
          <v-btn color="black" dark class="ma-2" @click="downloadXlsx({ show: true })" v-bind="attrs" v-on="on" small>
            <v-icon>mdi-file-excel</v-icon>
          </v-btn>
        </template>
        <span>Gerar Planilha</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="#373B40" dark class="ma-2" @click="showFilter({show: true})" small>
              <v-icon>mdi-filter</v-icon>
            </v-btn>
        </template>
        <span>Filtrar</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="#373B40" dark class="ma-2" @click="getItems({resetPage: false})" small>
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
        </template>
        <span>Recarregar</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
           <v-btn v-bind="attrs" v-on="on" color="#373B40" dark class="ma-2" @click="add" small>
             <v-icon>mdi-plus</v-icon
            ></v-btn>
        </template>
        <span>Adicionar</span>
      </v-tooltip>
      
     
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :loading="loading"
      :items="items"
      :options.sync="pagination"
      :server-items-length="totalItems"
      :expanded.sync="expanded"
      :item-class="getClassColor" 
      show-expand
      :footer-props="{
        'items-per-page-options': [10, 20, 50, 100],
        'items-per-page-text': 'Itens por página',
        'show-first-last-page': true,
        'page-text': `Página ${pagination.page} de ${parseInt(totalItems/pagination.itemsPerPage) + 1}`
      }" 
      no-data-text="Nenhum agente encontrado"
      class="elevation-10 table-margin pt-6 row-pointer"
    >
    <template v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }">
      <v-icon @click="expand(true)" v-if="item.email && !isExpanded">mdi-chevron-down</v-icon>
      <v-icon @click="expand(false)" v-if="item.email && isExpanded">mdi-chevron-up</v-icon>
    </template>
      <template v-slot:[`item.id_pppoker`]="{ item }">
        <v-chip
          color="#2a2a2c"
          class="px-3"
          text-color="white"
        >
          {{( item.id_pppoker )}}
        </v-chip>
      </template>
      <template v-slot:[`item.id_pppoker_old`]="{ item }">
        <v-chip
          color="#5da889"
          class="px-3"
          text-color="white"
        >
          {{( item.id_pppoker_old )}}
        </v-chip>
      </template>
      <template v-slot:expanded-item="{ item }">
        <td>{{ item.email }}</td>
      </template>
      <template v-slot:[`item.situation`]="{ item }">
        <span>{{ getSituation(item.situation) }}</span>
      </template>
      <template v-slot:[`item.type_deal`]="{ item }">
        <span>{{ getTypeDeal(item.type_deal)}}</span>
      </template>
      <template v-slot:[`item.type_closure`]="{ item }">
        <span>{{ getTypeClosure(item.type_closure)}}</span>
      </template>
      <template v-slot:[`item.limit_type`]="{ item }">
        <span>{{ getLimitType(item.limit_type) }}</span>
      </template>
      <template v-slot:[`item.limit_annotated`]="{ item }">
        <span>{{ item.limit_annotated.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon  v-bind="attrs" v-on="on" small class="mr-2" @click="viewItem({item: item})">mdi-information</v-icon>
          </template>
          <span>Mais Informações</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon  v-bind="attrs" v-on="on" small class="mr-2" @click="edit({item: item})">mdi-pencil</v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
           <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="showDeleteDialog(item)">mdi-delete</v-icon>
          </template>
          <span>Excluir</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog :value="dialog" @click:outside="closeDialog" width="600">
      <agents-form></agents-form>
    </v-dialog>

    <v-dialog :value="dialogFilter" @click:outside="showFilter({show: false})" width="600" @keydown.enter="filter">
      <agents-filter></agents-filter>
    </v-dialog>

    <v-dialog :value="dialogDelete" @click:outside="closeDeleteDialog" width="300">
      <v-card>
        <v-card-title class="headline">Confirmar exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn
              text
              @click="closeDeleteDialog()"
            >Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn
              text
              type="submit"
              right
              @click="deleteItem"
            >Confirmar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog :value="showContactDescription" @click:outside="showDialogContactDescription({show: false, contact_description: '', annotated: ''})" width="600"> 
      <contact-description
        start_mode="EDIT"
        screen="agent"
      ></contact-description>
    </v-dialog>
  </div>
</template>

<script>
  import { mapActions, mapMutations, mapState } from 'vuex'
  import { mapFields } from 'vuex-map-fields'
  import AgentsForm from './AgentsForm'
  import ContactDescription from '../../attendance/Chat/components/ContactDescription'
  import AgentsFilter from './AgentsFilter'
  // import JsonExcel from 'vue-json-excel'
  import { utils, writeFile } from "xlsx";

  export default {
    name: 'Agents',
    components: {
      "agents-form": AgentsForm,
      "agents-filter": AgentsFilter,
      "contact-description": ContactDescription,
      // "download-excel": JsonExcel,
    },
    created() {
      this.getItems({resetPage: false})
      this.getSlots({noPages: true})
    },
    computed: {
      ...mapState('agents', ['dialog', 'dialogAnnotated', 'dialogDelete', 'item', 'loading', 'mode', 'notificationType', 'notificationMessage', 'rowsPerPage',  'totalItems', 'items']),
      ...mapFields('agents', [
        'dialogFilter',
        'pagination',
        'showNotification'
      ]),
      ...mapFields('chat', ['showContactDescription'])
    },
    data: () => ({
      expanded: [],
      headers: [
        { text: "Id", value: "id" },
        { text: "ID PPPoker", value: "id_pppoker_old", align: "left", width: "8%" },
        { text: "ID Suprema", value: "id_pppoker", align: "left", width: "6%"  },
        {
          text: "Nick",
          align: "left",
          value: "nick", 
          width: "5%" 
        },
        { text: "Tipo de Acerto", value: "type_deal"},
        { text: "Tipo de Fechamento", value: "type_closure"},
        { text: "Grupo", value: "ag_group_name" },
        { text: "Limite", value: "limit_annotated", width: "5%" },
        { text: "Tipo de Limite", value: "limit_type", align: "left", width: "10%"  },
        { text: "Situação", value: "situation", align: "left", width: "10%"  },
        { text: '', value: 'data-table-expand' },
        { text: "Ações",  value: "actions", sortable: false, align: "center", width: "12%"  },
      ],
      overlay: false,
      sheet_fields: {
        IdAgent: {
          field: 'id_master_agent',
        },
        Clube: {
          field: 'club_name',
        },
        'Id PPPoker': {
          field: 'id_pppoker',
        },
        'Id Suprema': {
          field: 'id_supreme',
        },
        Nick: {
          field: 'nick',
        },
        'Rake Deal' : {
          field: 'rake_deal',
        },
        'Tipo de Acerto': {
          field: 'type_deal',
          callback: (type) => {
            let types = ["Ganho/Perda", "Giro", "Normal", "Fichas Enviadas/Retiradas", "Sem Acerto", "Sem Acerto/GP"];
            if (types[type]) {
              return types[type];
            }
            return "";
          }
        },
        Telefone: {
          field: 'phone',
          callback: (value) => {
            return 'Nº - ' + value
          }
        },
        "Nº Grupo": {
          field: 'ag_group_id',
          callback: (value) => {
            return 'Nº - ' + value
          }
        },
        'Grupo do Agente': {
          field: 'ag_group_name'
        },
        'Limite': {
          field: 'limit_annotated',
        },
        'Tipo de Limite': {
          field: 'limit_type',
          callback: (type) => {
            let types = ["Anotados", "Anotados e Adt. de Rake"];
            if (types[type]) {
              return types[type];
            }
            return "";
          }
        },
        'Email': {
          field: 'email',
        },
      }
    }),
    methods: {
      ...mapActions("slots", {getSlots: "getSlots"}),
      ...mapActions('agents', ['closeDialog', 'closeDeleteDialog', 'deleteItem', 'getItems', 'getItemsNoPage', 'showDeleteDialog', 'viewItem']),
      ...mapActions('agents', ['contactDescription']),
      ...mapMutations('agents', ['add', 'edit', 'showFilter']),
      ...mapMutations('chat', ['showDialogContactDescription']),
      getTypeDeal(type) {
        let types = ["Ganho/Perda", "Giro", "Normal", "Fichas Enviadas/Retiradas", "Sem Acerto", "Sem Acerto/GP"];
        if (types[type]) {
          return types[type];
        }
        return "";
      },
      getTypeClosure(type) {
        let types = ["Automático", "Manual"];
        if (types[type]) {
          return types[type];
        }
        return "";
      },
      getTypeChips(type) {
        let types = ["Pagas", "Não Pagas", "Diferença Anotados"];
        if (types[type]) {
          return types[type];
        }
        return "";
      },
      getLimitType(type) {
        let types = ["Anotados", "Anotados e Adt. de Rake"];
        if (types[type]) {
          return types[type];
        }
        return "";
      },
      getClassColor(item){
        return item.blocked_annotated == 1 ? "row-yellow-schedule" : ""
      },
      filter() {
        this.getItems({resetPage: true});
        this.showFilter({ show: false });
      },
      finishSheet() {
        this.overlay = false
      },
      generateSheet() {
        this.overlay = true
      },
      getSheetName() {
        var date = new Date()
        var name = 'agents_' + date.toLocaleDateString() + " " + date.toLocaleTimeString()
        return name+'.xls'
      },
      async downloadXlsx() {
        this.generateSheet()
        try {
          let itemsNoPage = await this.getItemsNoPage();
          if(itemsNoPage) {
            const wb = utils.book_new();
            let items = itemsNoPage.map(row => ({
              "IdAgent":  row.id_master_agent,
              "Clube":  row.club_name,
              "Id PPPoker":  row.id_pppoker,
              "Id Suprema":  row.id_suprema,
              "Nick": row.nick,
              "Rake Deal": row.rake_deal,
              "Tipo de Acerto": this.typeDealXLSX(row.type_deal),
              "Telefone": this.phoneXLSX(row.phone),
              "Nº Grupo": this.phoneXLSX(row.ag_group_id),
              "Grupo do Agente": row.ag_group_name,
              "Limite": row.limit_annotated,
              "Tipo de Limite": this.limitTypeXLSX(row.limit_type),
              "Email": row.email,
              "Situação": this.getSituation(row.situation),
            }))
            const ws = utils.json_to_sheet(items);
            utils.book_append_sheet(wb, ws, "Agentes");
            await writeFile(wb, this.getSheetName());
          }
        } catch (error) {
          this.finishSheet()
        } finally {
          this.finishSheet()
        }
      },
      getSituation(situation) {
        let situations = ["Inadimplente - 2022", "Em Acordo", "Rotativo Semanal", "Ativo", "Não Definido", "Inadimplmente - 2021"] 
        if (situations[situation]) {
          return situations[situation];
        }
        return "";
      },
      typeDealXLSX: (type) => {
        let types = ["Ganho/Perda", "Giro", "Normal", "Fichas Enviadas/Retiradas", "Sem Acerto", "Sem Acerto/GP"];
        if (types[type]) {
          return types[type];
        }
        return "";
      },
      phoneXLSX: (value) => {
        return 'Nº - ' + value
      },
      limitTypeXLSX: (type) => {
        let types = ["Anotados", "Anotados e Adt. de Rake"];
        if (types[type]) {
          return types[type];
        }
        return "";
      }
    },
    watch: {
      pagination: {
        handler () {
          this.getItems({resetPage: false})
        },
      },
    }
  }
</script>
