<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline text-center justify-center">Filtro</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm6 md6>
                <v-select
                  append-icon="mdi-close"
                  @click:append="clearCompany()"
                  label="Empresa"
                  :items="companies"
                  v-model="id_company"
                  item-text="name"
                  item-value="id"
                  outlined
                ></v-select>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-select
                  label="Tipo de Solicitação"
                  :items="possible_solicitation_types"
                  v-model="type_solicitation"
                  data-vv-name="id"
                  item-text="name"
                  item-value="id"
                  outlined
                ></v-select>
              </v-flex>

              <v-flex xs12 sm6 md12>
                <v-text-field
                  label="Nome do PIX"
                  v-model="pix_name"
                  clearable
                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-menu
                  v-model="showStart"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="start_formatted"
                      label="De"
                      outlined
                      readonly
                      append-icon="mdi-close"
                      @click:append="clearStart()"
                      persistent-hint
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="pt-br"
                    v-model="start"
                    no-title
                    @input="(date) => setStart(date)"
                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-menu
                  v-model="showEnd"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="end_formatted"
                      label="Até"
                      outlined
                      readonly
                      append-icon="mdi-close"
                      @click:append="clearEnd()"
                      persistent-hint
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="pt-br"
                    v-model="end"
                    no-title
                    @input="(e) => setEnd(e)"
                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex xs12 sm6 md12>
                <v-text-field
                  label="Descrição"
                  v-model="description"
                  clearable
                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-text-field
                  label="Quantidade"
                  v-model="quantity"
                  clearable
                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-select
                  label="Status"
                  clear-icon="mdi-close"
                  v-model="status"
                  :items="possible_status"
                  data-vv-name="id"
                  item-text="name"
                  item-value="id"
                  outlined
                ></v-select>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-select
                  label="Tipo PIX"
                  clear-icon="mdi-close"
                  v-model="pix_type"
                  :items="possible_pix_types"
                  data-vv-name="pix_type"
                  item-text="name"
                  item-value="pix_type"
                  outlined
                ></v-select>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-text-field
                  label="Chave PIX"
                  v-model="pix_key"
                  clearable
                  outlined
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn
            text
            @click="
              setShowFilter(false);
              clearFilter();
            "
            >Cancelar</v-btn
          >
        </v-col>
        <v-col class="text-right">
          <v-btn text type="submit" right @click="filter">Filtrar</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "FinancialsolicitationsFilter",
  methods: {
    ...mapMutations("financial_solicitations", ["setShowFilter", "clearFilter"]),
    ...mapActions("financial_solicitations", ["getItems", "getStatus"]),
    filter() {
      this.getItems();
      this.setShowFilter(false);
    },
    clearCompany() {
      this.id_company = "";
    },
    // clearFilterField(param) {
    //   if (param == "company") {
    //   this.idcompany = "";
    //   } else if (param == "type_solicitation") {
    //   this.type_solicitation = "";        
    //   }
    // },
    setStart(date) {
      this.start = date;
      this.showStart = false;
      this.start_formatted = this.formatDate(date);
    },
    setEnd(date) {
      this.end = date;
      this.showEnd = false;
      this.end_formatted = this.formatDate(date);
    },
    clearStart() {
      this.start = "";
      this.start_formatted = "";
    },
    clearEnd() {
      this.end = "";
      this.end_formatted = "";
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    },
  },
  computed: {
    ...mapState("financial_solicitations", [
      "companies",
      "mode",
      "selectedItem",
      "showDialog",
      "possible_pix_types",
      "possible_solicitation_types",
      "possible_status",
    ]),
    ...mapFields("financial_solicitations", [
      "filter.id_company",
      "filter.start",
      "filter.end",
      "filter.pix_name",
      "filter.date_created",
      "filter.description",
      "filter.quantity",
      "filter.value",
      "filter.type_solicitation",
      "filter.pix_type",
      "filter.pix_key",
      "filter.status",
    ]),
  },
  data: () => ({
    showStart: false,
    showEnd: false,
    start_formatted: "",
    end_formatted: "",
  }),
};
</script>
