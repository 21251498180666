<template>
  <div>
    <div @dragover.prevent>
      <v-card>
        <v-card-title>
          <span class="headline text-center">Jogador</span>
          <v-spacer></v-spacer>
          <span class="red--text body-1 font-weight-medium mr-2"
           v-if="!isValid"> 
            {{ (this.isWarning) ? "CPF não cadastrado na Receita Federal" : errorMessage}} 
          </span>
        
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    data-vv-name="player_id"
                    label="ID do Jogador"
                    v-model="player_id"
                    outlined
                    :rules="rules"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-select
                    required
                    label="Clube"
                    :items="instances"
                    v-model="slot"
                    :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                    item-text="name"
                    item-value="id_pppoker"
                    data-vv-name="slot"
                    outlined
                    :rules="rules"
                    >
                  </v-select>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-select
                    required
                    label="Tipo"
                    :items="actions_players"
                    v-model="type"
                    :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                    item-text="name"
                    item-value="type"
                    data-vv-name="type"
                    outlined
                    :rules="rules2"
                  >
                  </v-select>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-select
                    v-if="editedItem.type === 1"
                    label="Item"
                    :items="supremeItems"
                    v-model="item"
                    :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                    item-text="description"
                    item-value="id"
                    data-vv-name="item"
                    outlined
                    :rules="rules"
                    required
                  >
                  </v-select>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-col>
            <v-btn text @click="closePlayerDeleteDialog()">{{ mode == 'VIEW' ? 'Voltar' : 'Cancelar'}}</v-btn>
          </v-col>
          <v-col class="text-right" v-if="mode != 'VIEW'">
            <v-btn text type="submit" right @click="saveInF">Salvar</v-btn>
          </v-col>
        </v-card-actions>

      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import debounce from '../../util/debounce.js';

export default {
  name: "PlayerForm",
  computed: {
    ...mapState("players_delete", [
      "editedItem",
      "loading",
      "mode",
      "knowByTypes",
      "contacts",
      "selectedContact",
      "isValid",
      "isWarning",
      "errorMessage",
      "dialog",
      "blockSave",
      "dialogConfirm",
      "players",
      "supremeItems"
    ]),
    ...mapState("instances", ["instances"]),
    ...mapFields("players_delete", [
      "editedItem.player_id",
      "editedItem.type",
      "editedItem.slot",
      "editedItem.item",
      "editedItem.date_created",
      // ...
    ]),
  },
  mixins: [debounce],
  methods: {
    text: item => item.player_id + " - " + item.name,
    ...mapActions("players_delete", [
      "save",
      "closePlayerDeleteDialog",
      "getSupremeItems",
    ]),
    ...mapMutations("players_delete", ["setIsValid", "setErrorMessage", "setBlockSave", "setDialogConfirm", "showPlayerDeleteDialog"]),
    saveInF() {
      console.log(this.valid)
      console.log(this.editedItem)
      if (this.valid) {
        this.save()
      }
    },
  },
  created() {
    this.getSupremeItems()
  },
  data: () => ({
    valid: true,
    actions_players: [
      {
        type: 0,
        name: "Exclusão de jogador"
      },
      {
        type: 1,
        name: "Envio de Items"
      },
      {
        type: 2,
        name: "Perfil do Membro"
      },
      {
        type: 3,
        name: "Perfil do Agente"
      }
    ],
    rules: [
      v => !!v || 'Campo obrigatório',
    ],
    rules2: [
      v => (v==1 || v==2 || v==0 || v==3) || 'Campo obrigatório',
    ],
  }),
};
</script>
