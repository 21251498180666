<template>
    <div>
        <div @drop.prevent="addDropFile" @dragover.prevent>
            <v-card>
                <v-card-title class="headline text-center justify-center">
                    Vincular Comprovante
                </v-card-title>
                <v-container grid-list-md>
                    <v-layout wrap>
                        <v-flex xs12 sm12 md12>
                            <v-file-input
                                class="mt-2"
                                v-model="file_receipt"
                                label="Comprovante"
                                outlined
                            ></v-file-input>
                        </v-flex>
                    </v-layout>
                </v-container>
                <v-card-actions>
                    <v-col>
                        <v-btn text @click="closeDialogAddReceipt()"
                            >Cancelar</v-btn
                        >
                    </v-col>
                    <v-col class="text-right">
                        <v-btn
                            text
                          
                            @click="
                                addReceipt()
                            "
                            >Vincular</v-btn
                        >
                    </v-col>
                </v-card-actions>
            </v-card>
        </div>
    </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import toBase64 from '@util/Base64'
import { mapActions } from 'vuex'
export default {
    name: 'DialogAddReceipt',
    computed: {
        ...mapFields('deposit', ['receiptItem.file_receipt']),
        fileBase64: function () {
            if (this.fileString) {
                return this.fileString
            }
            return ''
        },
    },
    methods: {
        ...mapActions('deposit', ['closeDialogAddReceipt', 'addReceipt']),
        addDropFile(e) {
          console.log('TESTE 3', e)
            if (e.dataTransfer) {
                if (e.dataTransfer.files[0]) {
                    this.file_receipt = e.dataTransfer.files[0]
                    this.setFileBase64()
                }
            }
        },
        setFileBase64() {
            if (this.file_receipt && this.file_receipt.name) {
                toBase64(this.file_receipt).then((fileBase64) => {
                    this.fileString = fileBase64
                })
            } else {
                this.fileString = ''
            }
        },
    },
    data:() => ({
        fileString: '',
    }), 
    watch: {
        file_receipt: {
            handler() {
                this.setFileBase64()
            },
        },
    }
}
</script>