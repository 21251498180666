<template>
  <div>
    <div @drop.prevent="addDropFile" @dragover.prevent>
      <v-card>
        <v-card-title>
          <span class="headline text-center">Erros</span>
          <v-spacer></v-spacer>
          <v-progress-circular
            v-if="loading"
            indeterminate
            class="text-right"
            color="primary"
            size="24"
          ></v-progress-circular>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>

                <v-flex xs12 sm6 md6>
                  <v-datetime-picker
                    v-model="date_picker"
                    date-format="dd/MM/yyyy"
                    :time-picker-props="timeProps"
                    label="Data *"
                    :textFieldProps="{outlined: true}"
                  >
                    <template slot="dateIcon">
                      <v-icon>mdi-calendar</v-icon>
                    </template>
                    <template slot="timeIcon">
                      <v-icon>mdi-clock</v-icon>
                    </template>
                  </v-datetime-picker>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-select
                    label="Tipo *"
                    required
                    :items="possible_type"
                    v-model="type"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('type')"
                    v-validate="'required:Type,type'"
                    data-vv-name="type"
                    outlined
                    item-text="text"
                    item-value="value"
                  >
                  </v-select>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-autocomplete
                    clearable
                    deletable-chips
                    v-model="user_id"
                    :no-filter="true"
                    :items="users"
                    outlined
                    chips
                    small-chips
                    no-data-text="Nenhum usuario encontrado"
                    item-text="name"
                    item-value="id"
                    placeholder="Digite aqui o nome do usuario"
                    label="Usuario"
                    :search-input.sync="searchInput"
                  ></v-autocomplete>
                </v-flex>                

                <v-flex xs12 sm6 md6>
                  <v-autocomplete
                    clearable
                    deletable-chips
                    v-model="id_agent"
                    :no-filter="true"
                    :items="agents"
                    outlined
                    chips
                    small-chips
                    no-data-text="Nenhum agente encontrado"
                    item-text="nick"
                    item-value="id"
                    placeholder="Digite aqui o nick do agente"
                    label="Agente"
                    :search-input.sync="searchAgent"
                  ></v-autocomplete>
                </v-flex>

                <v-flex xs12 sm6 md12>
                  <v-textarea
                    required
                    label="Descrição *"
                    v-model="description"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('description')"
                    v-validate="'required:Descrição,description'"
                    data-vv-name="description"
                    outlined
                    :rows="3"
                  ></v-textarea>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Valor *"
                    v-model="value"
                    :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                    :error-messages="errors.collect('value')"
                    v-validate="'required:Valor,value|decimal:2'"
                    data-vv-name="value"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-select
                    label="Status *"
                    required
                    :items="possible_status"
                    v-model="status"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('status')"
                    v-validate="'required:Status,status'"
                    data-vv-name="status"
                    outlined
                    item-text="text"
                    item-value="value"
                  >
                  </v-select>
                </v-flex>

                <v-flex xs12 sm6 md12>
                  <v-textarea
                    required
                    label="Observação *"
                    v-model="observation"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('observation')"
                    data-vv-name="observation"
                    outlined
                    :rows="3"
                  ></v-textarea>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-select
                    label="Saldo Positivo *"
                    required
                    :items="possible_balance"
                    v-model="balance"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('balance')"
                    v-validate="'required:Balance,balance'"
                    data-vv-name="balance"
                    outlined
                    item-text="text"
                    item-value="value"
                  >
                  </v-select>
                </v-flex>

              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-col>
            <v-btn text @click="closeDialog">{{ mode == 'VIEW' ? 'Voltar' : 'Cancelar'}}</v-btn>
          </v-col>
          <v-col class="text-right" v-if="mode != 'VIEW'">
            <v-btn text type="submit" right @click="saveFail">Salvar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import debounce from '../../../util/debounce.js' 
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "FailForm",
  props: ["screen", "instances"],
  computed: {
    ...mapGetters("login", ["getInstanceId"]),
    ...mapState("fail", [
      "users",
      "users_name",
      "agents",
      "find_agents",
      "item",
      "loading",
      "mode",
    ]),
    ...mapState("instances", ["instances"]),
    ...mapFields("fail", [
      "dialogReceipt",
      "editedItem.status",
      "editedItem.balance",
      "editedItem.type",
      "editedItem.id",
      "editedItem.id_agent",
      "editedItem.description",
      "editedItem.date",
      "editedItem.date_picker",
      "editedItem.observation",
      "editedItem.user_id",
      "editedItem.value",
      // ...
    ]),
  },
  mixins: [debounce],
  mounted() {
    this.getAgents = this.debounce(this.getAgents, 500)
    this.getAttendants = this.debounce(this.getAttendants, 500); 
  },
  created() {
    this.setScreen({ screen: this.$props.screen })
    this.getInstances()
    this.$validator.extend("required", {
      getMessage: (field, [label]) => label + " deve ser informado",
      validate: (_, [, field]) => {
        if (this[field] || this[field] === 0) {
          return true;
        } else {
          return false;
        }
      },
    });
  },
  methods: {
    ...mapActions("fail", [
      "getAttendants",
      "getUsers",
      "getAgents",
      "closeDialog",
      "save",
    ]),
    ...mapActions("instances", ["getInstances"]),
    ...mapMutations("fail", ["setScreen"]),
    addDropFile(e) {
      if (this.mode == "ADD" || this.mode == "EDIT") {
        if (e.dataTransfer) {
          if (e.dataTransfer.files[0]) {
            this.receipt_file = e.dataTransfer.files[0];
          }
        }
      }
    },
    getTextAgent(item) {
      if(item) {
          if(item.id_pppoker) {
              return item.id_pppoker + " | " + item.nick + " | " + item.club_name
          } else {
              return item.nick
          }
      }
      return ''
    },
    saveFail() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          var d = new Date(this.date_picker);
          this.date =
            d.toISOString().substr(0, 10) + " " + d.toLocaleTimeString();
          this.value = parseFloat(this.value);
          this.save();
        }
      });
    },
  },
  data: () => ({
    possible_status: [
      {
        text: "Não Prejuízo",
        value: 0,
      },
      {
        text: "Pendente",
        value: 1,
      },
      {
        text: "Recuperado",
        value: 2,
      },
    ],
    possible_type: [
      {
        text: "Adiantamento Rakeback",
        value: 0,
      },
      {
        text: "Anotados",
        value: 1,
      },
      {
        text: "Atenção",
        value: 2,
      },
      {
        text: "Comunicação",
        value: 3,
      },
      {
        text: "Demora",
        value: 4,
      },
      {
        text: "Fechamento de Caixa",
        value: 5,
      },
      {
        text: "Interpretação",
        value: 6,
      },
      {
        text: "Sistema",
        value: 7,
      },
      {
        text: "Depósito",
        value: 8,
      },
      {
        text: "Saque",
        value: 9,
      },
    ],
    possible_balance: [
      {
        text: "Sim",
        value: 0,
      },
      {
        text: "Não",
        value: 1,
      }
    ],
    timeProps: {
      format: "24hr",
    },
    userId: "",
    valid: false,
    searchAgent: '',
    searchInput: '',
  }),
  watch: {
    searchInput(val) {
      if (val) {
        var payload = {
          search: val,
        };
        this.getAttendants(payload);
      }
    },
    searchAgent (val) {
      var payload = {
        search: val
      }
      this.getAgents(payload)
    }
  }
};
</script>
