<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline text-center justify-center">Filtro</span>

        <v-col class="text-right">
            <v-btn v-if="filtered == true" color="white" class="ma-2"  @click="clearFilter" right small depressed>
              <v-icon color="black">mdi-filter-remove</v-icon>
            </v-btn>
          </v-col>
      </v-card-title>

      <v-card-text>
        <v-form ref="form">
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm6 md6>
                <v-text-field
                  required
                  label="ID"
                  v-model="id"
                  data-vv-name="ID"
                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-text-field
                  required
                  label="Valor"
                  v-model="value"
                  :error-messages="errors.collect('value')"
                  data-vv-name="value"
                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm12 md12>
                <v-text-field
                  required
                  label="Descrição"
                  v-model="description"
                  :error-messages="errors.collect('description')"
                  data-vv-name="description"
                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-menu
                  v-model="showStart"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="start_formatted"
                      label="Inicio"
                      outlined
                      readonly
                      append-icon="mdi-close"
                      @click:append="clearStart()"
                      persistent-hint
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="pt-br"
                    v-model="start_date"
                    no-title
                    @input="(e) => setStart(e)"
                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-menu
                  v-model="showFinish"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="finish_formatted"
                      label="Fim"
                      outlined
                      readonly
                      append-icon="mdi-close"
                      @click:append="clearFinish()"
                      persistent-hint
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker locale="pt-br" v-model="finish_date" no-title @input="(e) => setFinish(e)"></v-date-picker>
                </v-menu>
              </v-flex>

            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn text @click="showFilter({ show: false })">Cancelar</v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn text type="submit" right @click="filter">Filtrar</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "WeeklyRevenueFilter",
  computed: {
    ...mapState("weekly_revenue", ["bots", "filtered", "instances"]),
    ...mapFields("weekly_revenue", [
      "filter.id",
      "filter.value",
      "filter.description",
      "filter.start_date",
      "filter.finish_date",
    ]),
  },
  created() {
    this.getInstances()
  },
  data: () => ({
    showStart: false,
    showFinish: false,
    start_formatted: "",
    finish_formatted: "",
  }),
  methods: {
    ...mapMutations("weekly_revenue", ["showFilter"]),
    ...mapActions("weekly_revenue", [
      "getItems",
      "clearFilter",
    ]),
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    },
    filter() {
      this.getItems({ resetPage: true });
      this.showFilter({ show: false });
    },
    setStart(date) {
      this.start_date = date;
      this.showStart = false;
      this.start_formatted = this.formatDate(date);
    },
    setFinish(date) {
      this.finish_date = date;
      this.showFinish = false;
      this.finish_formatted = this.formatDate(date);
    },
    clearStart() {
        this.start_date = ''
        this.start_formatted = ''
    },
    clearFinish() {
        this.finish_date = ''
        this.finish_formatted = ''
    },
  },
};
</script>

<style></style>
