<template>
  <v-card @drop.prevent="addDropFile" @dragover.prevent>
    <v-card-title>
      <span class="headline text-center justify-center">Endereços</span>

      <v-spacer></v-spacer>
      <v-progress-circular
        v-if="loading"
        indeterminate
        class="text-right"
        color="primary"
        size="24"
      ></v-progress-circular>
    </v-card-title>

    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12 sm6 md6>
              <v-autocomplete
                v-model="country"
                :items="countrys_list"
                outlined
                label="Pais"
                item-text="nome"
                item-value="codigo"
                :value="country"
              ></v-autocomplete>
            </v-flex>
            <v-flex xs12 sm6 md6>
              <v-text-field
                required
                label="CEP *"
                :disabled="country != '076'"
                v-model="cep"
                :readonly="this.mode == 'VIEW'"
                :error-messages="errors.collect('cep')"
                v-validate="'decimal'"
                data-vv-name="cep"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm4 md4>
              <v-text-field
                required
                label="Estado"
                v-model="state"
                :disabled="country == '076'"
                :readonly="this.mode == 'VIEW'"
                :error-messages="errors.collect('state')"
                data-vv-name="state"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm4 md4>
              <v-text-field
                required
                label="Cidade"
                v-model="city"
                :disabled="country == '076'"
                :readonly="this.mode == 'VIEW'"
                :error-messages="errors.collect('city')"
                data-vv-name="city"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm4 md4>
              <v-text-field
                required
                label="Bairro"
                v-model="district"
                :disabled="country == '076'"
                :readonly="this.mode == 'VIEW'"
                :error-messages="errors.collect('district')"
                data-vv-name="district"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm5 md5>
              <v-text-field
                required
                label="Endereço"
                v-model="street"
                :disabled="country == '076'"
                :readonly="this.mode == 'VIEW'"
                :error-messages="errors.collect('street')"
                data-vv-name="street"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm2 md2>
              <v-text-field
                required
                label="Numero"
                v-model="number"
                :readonly="this.mode == 'VIEW'"
                :error-messages="errors.collect('number')"
                data-vv-name="number"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm5 md5>
              <v-text-field
                required
                label="Complemento"
                v-model="complement"
                :readonly="this.mode == 'VIEW'"
                :error-messages="errors.collect('complement')"
                data-vv-name="complement"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md12>
              <v-select
                  clear-icon
                  label="Tipo *"
                  :items="types"
                  v-model="type"
                  :readonly="this.mode == 'VIEW'"
                  :error-messages="errors.collect('type')"
                  :v-validate="'required:Tipo,type'"
                  item-text="description"
                  item-value="id"
                  data-vv-name="type"
                  outlined
              >
              </v-select>
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-col>
        <v-btn text @click="closeDialog">{{ mode == 'VIEW' ? 'Voltar' : 'Cancelar'}}</v-btn>
      </v-col>
      <v-col class="text-right">
        <v-btn text type="submit" right @click="save">Salvar</v-btn>
      </v-col>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import countrys from "./countrys"

export default {
    name: "DocumentsAgentForm",
    created() {
      this.$validator.extend("required", {
        getMessage: (field, [label]) => label + " deve ser informado",
        validate: (_, [, field]) => {
          if (this[field] || this[field] === 0) {
            return true;
          } else {
            return false;
          }
        },
      });
    },
    computed: {
        ...mapState("address_player", ["loading", "find_agents", "mode"]),
        ...mapFields("address_player", [
          "editedItem.country",
          "editedItem.state",
          "editedItem.district",
          "editedItem.street",
          "editedItem.cep",
          "editedItem.number",
          "editedItem.complement",
          "editedItem.city",
          "editedItem.type"
        ])
        
    },
    methods: {
      ...mapActions("address_player", ["closeDialog", "save"]),
      addDropFile(e) {
        if (this.mode == "ADD" || this.mode == "EDIT") {
          if (e.dataTransfer) {
            if (e.dataTransfer.files[0]) {
              let file = e.dataTransfer.files[0];
              this.document_file = file
            }
          }
        }
      }
    },
    data: () => ({
      countrys_list: countrys,
      types: [
        {
          id: 0, 
          description: "Principal"
        },
        {
          id: 1, 
          description: "Outros"
        },
      ],
      valid: false
    }),
    watch: {
      cep: {
        handler() {
          if(this.cep) {
            var r_cep = this.cep.replace("-")
            if(r_cep.length == 8) {
            // var cep_json = {}
              let url = "https://viacep.com.br/ws/" + r_cep + "/json/"

              var myHeaders = new Headers();
              var myInit = { 
                method: 'GET',
                headers: myHeaders,
                mode: 'cors',
                cache: 'default' 
              };

              const myRequest = new Request(url, myInit);
              fetch(myRequest).then(res => res.json())
              .then((cep) => {
                if(cep) {
                  console.log(cep)
                  this.city = cep.localidade
                  this.state = cep.uf
                  this.district = cep.bairro
                  this.street = cep.logradouro
                }
              }).catch((error) => {
                console.log(error);
              });
            }
          }
        }
      },
      country: {
        handler() {
          if(this.country != "076") {
              this.cep = ""
              this.city = ""
              this.state = ""
              this.district = ""
              this.street = ""
          }
        }
      }
    }
}
</script>