<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-snackbar
      :color="notificationType"
      top
      :timeout="2000"
      v-model="showNotification"
    >{{ notificationMessage }}</v-snackbar>
    <v-toolbar flat class="elevation-5">
      <div>
        <v-toolbar-title>Periodos de Fechamento</v-toolbar-title>
      </div>
      <v-spacer></v-spacer>
      <div class="button-toolbar">
<!--         <download-excel
          :before-generate="generateSheet"
          :before-finish="finishSheet"
          :name="getSheetName()"
          :fetch="getItemsNoPage"
          :export-fields="sheet_fields"
          class="excel"
          worksheet="Periodos"
          type="xls"
        >
          <v-btn color="black" dark class="ma-2" small>
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </download-excel> -->

        <!-- <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="black" dark class="ma-2" @click="setDialogUpdateExcel({show: true})" small>
              <v-icon>mdi-clipboard-edit-outline</v-icon>
            </v-btn>
          </template>
          <span>Converter Planilha</span>
        </v-tooltip> -->

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="black" dark class="ma-2" @click="updatePendingPayments" small>
              <v-icon>mdi-artstation</v-icon>
            </v-btn>
          </template>
          <span>Converter Anotados</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="black" dark class="ma-2" @click="showFilter({show: true})" small>
              <v-icon>mdi-filter</v-icon>
            </v-btn>
          </template>
          <span>Filtro</span>
        </v-tooltip>
             
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="black" dark class="ma-2" @click="getItems" small>
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Recarregar</span>
        </v-tooltip>
            
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="black" dark class="ma-2" @click="add" small>
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Novo Período</span>
        </v-tooltip>
      </div>
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :loading="loading"
      :items="items"
      :options.sync="pagination"
      :server-items-length="totalItems"   
      :item-class= "getLineColor"      
      group-by="start_date"
      :footer-props="{
        'items-per-page-options': [10, 20, 50, 100],
        'items-per-page-text': 'Itens por página',
        'show-first-last-page': true,
        'page-text': `Página ${pagination.page} de ${parseInt(totalItems/pagination.itemsPerPage) + 1}`
      }"      
      no-data-text="Nenhuma registro encontrado"
      class="elevation-10 table-margin"
    >
      <template v-slot:group.header="{items, isOpen, toggle}">
        <th colspan="24">
          <v-icon @click="toggle"
            >{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
          </v-icon>
          {{ "Periodo: " + getDate(items[0].start_date) + " - " + getDate(items[0].finish_date) }}
        </th>
      </template>
      <template v-slot:[`item.total_player_gains`]="{ item }">
        <span>{{ (item.total_player_gains) ? item.total_player_gains.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "0".toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
      </template>
      <template v-slot:[`item.total_rake`]="{ item }">
        <span>{{ (item.total_rake) ? item.total_rake.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "0".toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
      </template>
      <template v-slot:[`item.total_spin_rake`]="{ item }">
        <span>{{ (item.total_spin_rake) ? item.total_spin_rake.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "0".toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
      </template>
      <template v-slot:[`item.total_rakeback`]="{ item }">
        <span>{{ getTotalRakeback(item) }}</span>
      </template>
      <template v-slot:[`item.total_skin_rakeback`]="{ item }">
        <span>{{ getTotalSpinRakeback(item) }}</span>
      </template>
      <template v-slot:[`item.start_date`]="{ item }">
        <span>{{ getDate(item.start_date)}}</span>
      </template>
      <template v-slot:[`item.finish_date`]="{ item }">
        <span>{{ getDate(item.finish_date)}}</span>
      </template>
      <template v-slot:[`item.type_period`]="{ item }">
        <span>{{ getTypePeriod(item.type_period)}}</span>
      </template>
      <template v-slot:[`item.pending_closures`]="{ item }">
        <v-chip
          :color="getColorPendingClosures(item.pending_closures)"
          text-color="white"
          label
          > {{ item.pending_closures }}
        </v-chip>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          :color="getColorStatus(item.status)"
          text-color="white"
          label
          > {{ getStatus(item.status) }}
        </v-chip>
      </template>
      <template v-slot:[`item.total_league`]="{ item }">
        <span>{{ (item.total_rake) ? ( item.total_rake * 0.18 ).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "0".toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
      </template>
      <template v-slot:[`item.total_result`]="{ item }">
        <span>{{  getResult(item) }}</span>
      </template>
      <template v-slot:[`item.total_rentability`]="{ item }">
        <span>{{  getRentability(item) }}</span>
      </template>
      <template v-slot:[`item.total_value_to_pay`]="{ item }">
        <span>{{ (item.total_value_to_pay) ? item.total_value_to_pay.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "0".toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" small class="mr-2" @click="calcSpreadsheetClosureMaster({item: item})">mdi-calculator</v-icon>
          </template>
          <span>Computar</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" small class="mr-2" @click="showDialogSpreadsheetClosure({item: item})">mdi-google-spreadsheet</v-icon>
          </template>
          <span>Planilhas</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" small class="mr-2" @click="viewItem(item)">mdi-information</v-icon>
          </template>
          <span>Informações</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" small class="mr-2" @click="openDialogOverlay({ item })">mdi-cash-register</v-icon>
          </template>
          <span>Cadastrar Overlay</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" small class="mr-2" @click="openDialogConfimRelateAgent({ item: item })">mdi-account-multiple-check</v-icon>
          </template>
          <span>Processar Relacionamento Agentes</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" small class="mr-2" @click="edit({item: item})">mdi-pencil</v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" small class="mr-2" @click="showDeleteDialog(item)">mdi-delete</v-icon>
          </template>
          <span>Excluir</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" small class="mr-2" @click="showDialogAutoClosure(item)">mdi-check</v-icon>
          </template>
          <span>Fechamento Automático</span>
        </v-tooltip>
        <v-icon small class="mr-2" v-if="item.verified == 0 && profileAccess()" @click="verifyItem({item: item})">mdi-check</v-icon>
      </template>
    </v-data-table>

    <v-dialog :value="dialog" @click:outside="closeDialog()" width="800">
      <periods-closure-form></periods-closure-form>
    </v-dialog>

    <v-dialog :value="dialogFilter" width="600" @click:outside="showFilter({show: false})">
      <periods-closure-filter></periods-closure-filter>
    </v-dialog>

    <v-dialog :value="dialogOverlay" width="800" @click:outside="closeDialogOverlay({show: false})" @keydown.esc="closeDialogOverlay" >
      <v-card>
        <v-card-title>
          <span class="headline">Overlays do Período</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm7 md7>
                  <v-autocomplete
                    label="Clube *"
                    :items="slotsOverlay"
                    v-model="club"
                    :item-text="getTextSlot"
                    item-value="id"
                    clear-icon="mdi-close"
                    outlined
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    required
                    label="Valor *"
                    v-model="value"
                    data-vv-name="value"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm2 md2>
                  <v-btn color="black" dark class="ma-2" @click="getDataToFillOverlay()">
                    <v-icon>mdi-application-import</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-col>
            <v-btn text @click="closeDialogOverlay()">Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn @click="saveNewOverlay" text type="submit" right >Salvar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
      <v-card>
        <v-data-table
          :headers="headers_overlay"
          :items="overlay_items"
          disable-pagination
          hide-default-footer
        >
        <template v-slot:[`item.value`]="{ item }">
          <span>{{ (item.value) ? item.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "0".toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}}</span>
        </template>
        
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-on="on" v-bind="attrs" small class="mr-2" @click="deleteOverlay({ item })">mdi-delete</v-icon>
            </template>
            <span>Deletar</span>
          </v-tooltip>
        </template>
        </v-data-table>
      </v-card>
    </v-dialog>

    <v-dialog :value="dialogSpreadsheetClosure" @click:outside="setDialogSpreadsheetClosure({show: false})" @keydown.enter="deleteItem" width="1280">
        <v-card>
        <v-expansion-panels :value="0">
            <v-expansion-panel :value="true">
              <v-expansion-panel-header>Planilhas Utilizadas</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card>
                  <v-data-table
                    :headers="headers_spreadsheet"
                    :items="spreadsheet_period"
                    disable-pagination
                    hide-default-footer
                    :item-class= "getClassColor"        
                  >
                    <template v-slot:[`item.processed`]="{ item }">
                      <span>{{ item.processed ? 'Sim' : 'Não'}}</span>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-tooltip bottom v-if="totalItems > 0 || filtered">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-on="on" v-bind="attrs" small class="mr-2" @click="calcSpreadsheetClosure({item: item})">mdi-calculator</v-icon>
                        </template>
                        <span>Computar Planilha</span>
                      </v-tooltip>
                    </template>
                  </v-data-table>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>Nova Planilha</v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-card @drop.prevent="addDropFile" @dragover.prevent @paste="onPaste">
                  <v-container grid-list-md>
                    <v-layout wrap>
                      <v-flex xs12 sm12 md4 xl4>
                        <v-select
                            label="Tipo de Importação *"
                            :items="possible_types_import"
                            v-model="type_import"
                            :readonly="this.mode == 'VIEW'"
                            item-text="description"
                            item-value="id"
                            data-vv-name="status"
                        >
                        </v-select>
                      </v-flex>
                      <v-flex xs12 sm12 md4 xl4>
                        <v-autocomplete
                            label="Clube"
                            :clearable="true"
                            :items="slots"
                            v-model="club_import"
                            :readonly="this.mode == 'VIEW'"
                            :item-text="getTextSlot"
                            item-value="id"
                            clear-icon="mdi-close"
                        >
                        </v-autocomplete>
                      </v-flex>
                      <v-flex xs12 sm12 md4 xl4 v-if="type_import < 2">
                        <v-file-input
                          accept="*"
                          label="Adicionar Planilha"
                          v-model="selected_spreadsheet"
                        ></v-file-input>
                      </v-flex>
                      <v-flex xs12 sm12 md4 xl4 v-if="type_import < 2">
                          <v-text-field
                            required
                            class="text-right"
                            label="Linha Inicial *"
                            v-model="start_line"
                            text-align="right"
                            type="number"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-container>
                  <v-data-table
                    :headers="headers_config_spreadsheet"
                    :items="spreadsheet_headers_config_import"
                    disable-pagination
                    hide-default-footer
                    v-if="type_import < 2"
                  >     
                      <template v-slot:[`item.column_spreadsheet`]="props">
                        <v-text-field
                          required
                          reverse
                          v-model="props.item.column_spreadsheet"
                        ></v-text-field>
                      </template> 
                      <template v-slot:[`item.type_field`]="{ item }">
                        <span>{{ getTypeHeaderSpreadSheet(item.type_field)}}</span>
                      </template>
                  </v-data-table>
                  <v-card-actions>
                    <v-col>
                      <v-btn text @click="setDialogSpreadsheetClosure({show: false})">Cancelar</v-btn>
                    </v-col>
                    <v-col class="text-center">
                      <v-btn readonly v-if="overlay" color="error">Carregando ...</v-btn>
                    </v-col>
                    <v-col class="text-right">
                      <v-btn  @click="importSpreadsheet" text type="submit" right >Confirmar</v-btn>
                    </v-col>
                  </v-card-actions>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
        
    </v-dialog>

    <v-dialog :value="dialogErrorsPeriod" width="600" @click:outside="setErrorsPeriod({show: false, errors: []})">
      <v-card>
        <v-data-table   
          :headers="headers_errors"
          :items="errors_period"
          disable-pagination
          hide-default-footer        
        >
        </v-data-table>
      </v-card>
    </v-dialog>

    <v-dialog :value="dialogUpdateExcel" width="650" @click:outside="setDialogUpdateExcel({show: false})">
      <v-card @drop.prevent="addDropFile" @dragover.prevent @paste="onPaste">
        <v-data-table
          :headers="headers_config_spreadsheet"
          :items="spreadsheet_headers_config_import"
          disable-pagination
          hide-default-footer
        >     
          <template v-slot:[`item.column_spreadsheet`]="props">
            <v-text-field
              required
              reverse
              v-model="props.item.column_spreadsheet"
            ></v-text-field>
          </template> 
          <template v-slot:[`item.type_field`]="{ item }">
            <span>{{ getTypeHeaderSpreadSheet(item.type_field)}}</span>
          </template>
        </v-data-table>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12 sm12 md4 xl4>
              <v-select
                  label="Tipo de Importação *"
                  :items="possible_types_import"
                  v-model="type_import"
                  :readonly="this.mode == 'VIEW'"
                  item-text="description"
                  item-value="id"
                  data-vv-name="status"
              >
              </v-select>
            </v-flex>
            <v-flex xs12 sm12 md4 xl4>
              <v-file-input
                accept="*"
                label="Adicionar Planilha"
                v-model="selected_spreadsheet"
              ></v-file-input>
            </v-flex>
            <v-flex xs12 sm12 md4 xl4>
                <v-text-field
                  required
                  class="text-right"
                  label="Linha Inicial *"
                  v-model="start_line"
                  text-align="right"
                  type="number"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-col>
            <v-btn text @click="setDialogSpreadsheetClosure({show: false})">Cancelar</v-btn>
          </v-col>
          <v-col class="text-center">
            <v-btn readonly v-if="overlay" color="error">Carregando ...</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn v-if="selected_spreadsheet && nameSpreadsheet" @click="importSpreadsheet" text type="submit" right >Confirmar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
      <v-card>
        <v-card-title class="headline">Converter Planilha</v-card-title>
        <v-card-actions>
          <v-file-input
            label="Adicionar Planilha"
            v-model="excel_for_update"
          ></v-file-input>
        </v-card-actions>

        <v-card-actions>
          <v-col>
            <v-btn text type="submit" @click="setDialogUpdateExcel({show: false})" color="red">cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn text @click="updateExcel" color="green">converter</v-btn>
          </v-col>
        </v-card-actions>

      </v-card>
    </v-dialog>

    <v-dialog :value="dialogPlayersExclusion"  @click:outside="closeDialogPlayersExclusion" @keydown.esc="closeDialogPlayersExclusion" width="675">
      <v-card>
        <v-card-title class="headline">Confirmar a exclusão automática de jogadores inativos?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn text @click="closeDialogPlayersExclusion()">Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn text type="submit" right @click="playersExclusion">Confirmar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <v-dialog :value="dialogProcessRelateAgentsPlayer"  @click:outside="closeDialogProcessRelateAgentPlayer" @keydown.esc="closeDialogProcessRelateAgentPlayer" width="850">
      <v-card>
        <v-card-title class="headline">Confirmar o processamento do relacionamento de jogadores e agentes?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn text @click="closeDialogProcessRelateAgentPlayer()">Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn text type="submit" right @click="processAgentsPlayers">Confirmar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog :value="dialogDelete"  @click:outside="closeDeleteDialog" @keydown.enter="deleteItem" width="300">
      <v-card>
        <v-card-title class="headline">Confirmar a exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn text @click="closeDeleteDialog()">Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn text type="submit" right @click="deleteItem">Confirmar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog :value="dialogAutoClosure"  @click:outside="closeDialogAutoClosure" @keydown.enter="autoClosure" width="300">
      <v-card>
        <v-card-title class="headline">Confirmar operação?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn text @click="closeDialogAutoClosure()">Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn text type="submit" right @click="autoClosure">Confirmar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import PeriodsClosureForm from "./PeriodsClosureForm";
import PeriodsClosureFilter from "./PeriodsClosureFilter";
//import JsonExcel from "vue-json-excel";
export default {
  name: "PeriodsClosure",
  computed: {
    ...mapState('slots', ['slots']),
    ...mapState("login", ["userData"]),
    ...mapState("periods_closure", [
      "item",
      "errors",
      "errors_period",
      "overlay_items",
      "loading",
      "mode",
      "notificationType",
      "notificationMessage",
      "rowsPerPage",
      "totalItems",
      "items",
      "spreadsheet_headers_config_import",
      "spreadsheet_period",
      "slotsOverlay"
    ]),
    ...mapFields("periods_closure", [
      "pagination",
      "dialog",
      "dialogDelete",
      "dialogPlayersExclusion",
      "dialogUpdateExcel",
      "dialogOverlay",
      "dialogAutoClosure",
      "dialogErrorsPeriod",
      "dialogFilter",
      "dialogSpreadsheetClosure",
      "dialogProcessRelateAgentsPlayer",
      "overlay",
      "start_line",
      "selected_spreadsheet",
      "showNotification",
      "type_import",
      "club_import",
      "newOverlay.club",
      "newOverlay.period",
      "newOverlay.value",
      "excel_for_update"
      // ...
    ]),
    nameSpreadsheet() {
      console.log(this.selected_spreadsheet.name)
      return this.selected_spreadsheet.name
    }
  },
  created() {
    this.getSlots({ resetPage: false, noPages: true, onlyIfNotSearched: true });
    this.getItems()
  },
  components: {
    //"download-excel": JsonExcel,
    "periods-closure-form": PeriodsClosureForm,
    "periods-closure-filter": PeriodsClosureFilter,
  },
  methods: {
    addDropFile(e) {
      if (e.dataTransfer) {
        if (e.dataTransfer.files[0]) {
          this.selected_spreadsheet = e.dataTransfer.files[0];
        }
      }
    },
    ...mapActions("slots", ["getSlots"]),
    ...mapMutations("periods_closure", [
      "setDialogUpdateExcel",
      "add",
      "closeDialog",
      "edit",
      "setDialogSpreadsheetClosure",
      "setErrorsPeriod",
      "showFilter"
    ]),
    ...mapActions("periods_closure", [
      "getDataToFillOverlay",
      "updatePendingPayments",
      "openDialogOverlay",
      "openDialogPlayersExclusion",
      "openDialogConfimRelateAgent",
      "closeDialogPlayersExclusion",
      "closeDialogProcessRelateAgentPlayer",
      "deleteOverlay",
      "closeDialogOverlay",
      "saveNewOverlay",
      "calcSpreadsheetClosure",
      "calcSpreadsheetClosureMaster",
      "closeDeleteDialog",
      "closeDialogAutoClosure",
      "deleteItem",
      "processAgentsPlayers",
      "playersExclusion",
      "getItems",
      "getItemsNoPage",
      "importSpreadsheet",
      "updateExcel",
      "showDeleteDialog",
      "showDialogAutoClosure",
      "showDialogSpreadsheetClosure",
      "autoClosure",
      "verifyItem",
      "viewItem",
    ]),
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      }
      return false;
    },
    getColorPendingClosures(pending_closures) {
      if (pending_closures > 0) {
        return "red";
      }
      return "green";
    },
    getTextSlot(item) {
      let platform = ""
      if(item.plataform_id == 0) {
        platform = "Suprema"
      } else if(item.plataform_id == 1) {
        platform = "Pppoker"
      } else if(item.plataform_id == 2) {
        platform = "Cacheta"
      }
      return platform + " | " +item.name
    },
    getLineColor(item) {
      if (this.items.indexOf(item) % 2 == 0) {
        return "background-white"
      } else {
        return "background-grey"
      }
    },
    getTotalRakeback(item) {
      if(item.total_rakeback) {
        if(item.total_rakeback_sub_agents) {
          let total = (item.total_rakeback + item.total_rakeback_sub_agents)
          return total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        }
        return item.total_rakeback.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      } else if(item.total_rakeback_sub_agents) {
        return item.total_rakeback_sub_agents.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      }
      return "0".toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    },
    getResult(item) {
      let total_rb = 0
      let total = 0
      if(item.total_rakeback) {
        if(item.total_rakeback_sub_agents) {
          total_rb = (item.total_rakeback + item.total_rakeback_sub_agents)
        
        } else {
          total_rb = item.total_rakeback 
        }
        
      } else if(item.total_rakeback_sub_agents) {
        total_rb = item.total_rakeback_sub_agents 
      }
      if(item.total_rake) {
        total = (item.total_rake - (item.total_rake * 0.18) - total_rb )
        return total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      } else {
        return "0".toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      }
    },
    getRentability(item) {
      let total_rb = 0
      let total = 0
      if(item.total_rakeback) {
        if(item.total_rakeback_sub_agents) {
          total_rb = (item.total_rakeback + item.total_rakeback_sub_agents)
        
        } else {
          total_rb = item.total_rakeback 
        }
        
      } else if(item.total_rakeback_sub_agents) {
        total_rb = item.total_rakeback_sub_agents 
      }
      if(item.total_rake) {
        total = (item.total_rake - (item.total_rake * 0.18) - total_rb )/item.total_rake
        total = (total * 100).toFixed(2)
        return total.toLocaleString('pt-BR', { style: 'percent'}) + "%"
      } else {
        return "0%"
      }
    },
    getTotalSpinRakeback(item) {
      if(item.total_spin_rakeback) {
        if(item.total_spin_rakeback_sub_agents) {
          let total = (item.total_spin_rakeback + item.total_spin_rakeback_sub_agents)
          return total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        }
        return item.total_spin_rakeback.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      } else if(item.total_spin_rakeback_sub_agents) {
        return item.total_spin_rakeback_sub_agents.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      }
      return "0".toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    },
    getDate(date) {
      if (date) {
        var d = new Date(date + " 12:00:00");
        return d.toLocaleDateString();
      }
      return "";
    },
    getTypeHeaderSpreadSheet(type) {
      if(type == 'int') {
        return 'Inteiro'
      } else if(type == 'float') {
        return 'Decimal'
      } else {
        return 'Texto'
      }
    },
    getTypePeriod(type) {
      if(type == 0) {
        return 'Fechamento'
      } else {
        return 'Teste'
      }
    },
    finishSheet() {
      this.overlay = false;
    },
    generateSheet() {
      this.overlay = true;
    },
    getSheetName() {
      var date = new Date();
      var name =
        "periodos_fechamento" +
        date.toLocaleDateString() +
        " " +
        date.toLocaleTimeString();
      return name + ".xls";
    },
    getClassColor(item) {
      if(item.processed == 0) {
        return 'row-red'
      } else {
        return 'row-green'
      }
    },
    getStatus(status) {
      if(status == 0) {
        return 'Bloqueado'
      } else {
        return 'Liberado'
      }
    },
    getColorStatus(status) {
      if(status == 0) {
        return 'red'
      } else {
        return 'green'
      }
    },
    onPaste(e) {
      if(e.clipboardData && e.clipboardData.items.length != 0) {
        var file = e.clipboardData.items[0]
        var file_data = file.getAsFile()
        this.selected_spreadsheet = file_data
      }
    },
    profileAccess() {
      if(this.userData.role > 90 || this.userData.role == 2 ) {
        return true
      } else {
        return false
      }
    },
  },
  data: () => ({
    groupBy: [{ key: 'description' }],
    build_fields: {},
    expanded: [],
    headers: [
      { text: "Id", value: "id", sortable: false },
      { text: "Tipo", value: "type_period", sortable: false },
      { text: "Descrição", value: "description", sortable: false },
      { text: "Inicio", value: "start_date", sortable: false },
      { text: "Fim", value: "finish_date", sortable: false },
      { text: "Total Ganhos Jogador", value: "total_player_gains", sortable: false },
      { text: "Total Rake", value: "total_rake", sortable: false },
      { text: "Total Rakeback", value: "total_rakeback", sortable: false },
      { text: "Liga", value: "total_league", sortable: false },
      { text: "Resultado", value: "total_result", sortable: false },
      { text: "(%) SX", value: "total_rentability", sortable: false },
      { text: "Resultado Financeiro", value: "total_value_to_pay", sortable: false },
      { text: "Fechamentos Pendentes", value: "pending_closures", sortable: false, align: 'center' }, 
      { text: "Status", value: "status", sortable: false },
      { text: "Ações", value: "actions", sortable: false }
    ],
    headers_config_spreadsheet: [
      { text: "Descrição", value: "description_field", sortable: false },
      { text: "Coluna", value: "column_spreadsheet", align: 'right', sortable: true },
      { text: "Tipo", value: "type_field", align: 'right', sortable: false }
    ],
    headers_errors: [
      { text: "Erro", value: "error", sortable: false},
    ],
    headers_spreadsheet: [
      { text: "Id", value: "id", sortable: false },
      { text: "Nome", value: "name", sortable: false },
      { text: "Processada", value: "processed", sortable: true },
      { text: "Ações", value: "actions", sortable: false }
    ],
    headers_overlay: [
      { text: "Clube", value: "club_name", sortable: false },
      { text: "Valor", value: "value", sortable: false },
      { text: "Ações", value: "actions", sortable: false }
    ],
    possible_types_import: [
      { id: 1, description: "Importação Planilhas ( Adicionar )" },
      { id: 0, description: "Importação Planilha ( Substituir )" },
      { id: 2, description: "Importação Automática ( Adicionar )" },
      { id: 3, description: "Importação Automática ( Substituir )" }
    ],
    sheet_fields: {
      Inicio: {
        field: "start_date",
        callback: (value) => {
          if(value) {
            var d = new Date(value);
            return d.toLocaleDateString();
          }
        },
      },
      Fim: {
        field: "finish_date",
        callback: (value) => {
          if(value) {
            var d = new Date(value);
            return d.toLocaleDateString();
          }
        },
      },
      "Total Rake": {
        field: "total_rake",
        callback: (value) => {
          if (value) {
            return value.toString().replace(".", ",")
          }
          return 0;
        },
      },
      "Total Spin Rake": {
        field: "total_spin_rake",
        callback: (value) => {
          if (value) {
            return value.toString().replace(".", ",")
          }
          return 0;
        },
      },
      "Total Rakeback": {
        field: "total_agent_rakeback",
        callback: (value) => {
          if (value) {
            return value.toString().replace(".", ",")
          }
          return 0;
        },
      },
      "Total Spin Rakeback": {
        field: "total_agent_rakeback",
        callback: (value) => {
          if (value) {
            return value.toString().replace(".", ",")
          }
          return 0;
        },
      },
    },
    valid: false,
  }),
  watch: {
    pagination: {
      handler() {
        this.getItems({ resetPage: false });
      },
    },
  },
};
</script>

<style>
  .background-grey {
    background-color: rgba(225, 225, 225);
  }
  .background-white {
    background-color: rgba(255, 255, 255);
  }
</style>