<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar
      :color="notification.type"
      right
      top
      :timeout="2000"
      v-model="notification.show"
    >
      {{ notification.message }}
    </v-snackbar>

    <v-toolbar flat class="elevation-5">
      <div>
        <v-toolbar-title>Extrato</v-toolbar-title>
      </div>
      <v-spacer></v-spacer>
      <div class="button-toolbar">
      <!--   <download-excel
          :data="extract"
          :fields="sheet_fields"
          class="excel"
          worksheet="Extrato"
          name="Extrato.xls"
        >
          <v-tooltip bottom>
             <template v-slot:activator="{on, attrs}">
               <v-btn color="black" dark class="ma-2" small v-on="on" v-bind="attrs">
                 <v-icon>mdi-file-excel</v-icon>
               </v-btn>
             </template>
             <span>Baixar xls</span>
          </v-tooltip>
          
        </download-excel> -->
        <v-tooltip bottom>
          <template v-slot:activator="{on, attrs}">
            <v-btn color="black" dark class="ma-2" @click="downloadXlsx({ show: true })" v-bind="attrs" v-on="on" small>
              <v-icon>mdi-file-excel</v-icon>
            </v-btn>
          </template>
          <span>Gerar Planilha</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="black"
              dark
              class="ma-2"
              @click="showFilter({show: true})"
              v-bind="attrs"
              v-on="on"
              small
            >
              <v-icon color="white">mdi-filter</v-icon>
            </v-btn>
          </template>
          <span>Filtro</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="black"
              dark
              class="ma-2"
              @click="getItems"
              v-bind="attrs"
              v-on="on"
              small
              ><v-icon>mdi-refresh</v-icon></v-btn
            >
          </template>
          <span>Recarregar</span>
        </v-tooltip>
      </div>
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :loading="loading"
      :items="extract"
      :item-class= "getClassColor"  
      class="elevation-10 table-margin"
      :footer-props="{
        'items-per-page-options': [10, 20, 50, 100],
        'items-per-page-text': 'Itens por página',
        'show-first-last-page': true,
        'page-text': `Página ${pagination.page} de ${parseInt(totalItems/pagination.itemsPerPage) + 1}`
      }" 
      no-data-text="Nenhuma transação encontrada"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Pesquisar"
          class="mx-4"
        ></v-text-field>
      </template>
      <template v-slot:[`item.date`]="{ item }">
        <span>{{ getDate(item.date)}}</span>
      </template>
      <template v-slot:[`item.type`]="{ item }">
        <span>{{ getType(item.type)}}</span>
      </template>
      <template v-slot:[`item.type_operation`]="{ item }">
        <span>{{ getTypeOperation(item.type_operation)}}</span>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <span>{{ getStatus(item.status)}}</span>
      </template>
      <template v-slot:[`item.cpf_origin`]="{ item }">
        <span>{{ item.cpf_origin ? maskCpf(item.cpf_origin) : (item.cnpj_origin.indexOf("*") != -1 ? item.cnpj_origin : maskCnpj(item.cnpj_origin)  ) }}</span>
      </template>
      
      <template v-slot:[`item.cpf_destiny`]="{ item }">
        <span>{{ item.cpf_destiny ? maskCpf(item.cpf_destiny) : (item.cnpj_destiny.indexOf("*") != -1 ? item.cnpj_destiny : maskCnpj(item.cnpj_destiny)  ) }}</span>
      </template>
      <template v-slot:[`item.value`]="{ item }">
        <span>{{ item.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
      </template>
     
    </v-data-table>

    <v-dialog
      :value="showDialog"
      @click:outside="setShowDialog(false)"
      width="600"
    >
      <extract-form></extract-form>
    </v-dialog>

    <v-dialog
      :value="dialogFilter"
      @click:outside="showFilter({show: false})"
      width="600"
    >
      <extract-filter></extract-filter>
    </v-dialog>

    <v-dialog
      :value="deleteDialog"
      @click:outside="setDeleteDialog(true)"
      @keydown.enter="deleteItem"
      width="300"
    >
      <v-card>
        <v-card-title class="headline">Confirmar a exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn text @click="setDeleteDialog(false)">Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn text type="submit" right @click="deleteItem"
              >Confirmar</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import ExtractForm from "./ExtractForm";
import ExtractFilter from "./ExtractFilter";
// import JsonExcel from "vue-json-excel";
import { utils, writeFile } from "xlsx";

export default {
  name: "Extract",
  components: {
    ExtractForm,
    ExtractFilter,
    // "download-excel": JsonExcel,
  },
  created() {
  },
  methods: {
    ...mapActions("extract", ["getItems", "deleteItem", "viewItem", "getItemsNoPage"]),
    ...mapMutations("extract", [
      "setShowDialog",
      "view",
      "showFilter",
      "setDeleteDialog",
      "deleteBank",
    ]),
     getDate(date) {
      var d = new Date(date)
      return d.toLocaleDateString() + " " + d.toLocaleTimeString()
    },
    getType(type) {
      return this.possible_types[type];
    },
    getStatus(status) {
      return this.possible_status[status];
    },
    getTypeOperation(type) {
      return this.possible_types_operation[type];
    },
    getClassColor(item) {
      if(item.status == 3) {
        return 'row-yellow-schedule'
      } else if(item.type == 0 ) {
        if(item.type_operation == 2) {
          return 'row-orange'
        } else {
          return 'row-red'
        }
      } else if(item.type == 1) {
        return 'row-green'
      }
    },
    finishSheet() {
      this.loading = false;
    },
    generateSheet() {
      this.loading = true;
    },
    getSheetName() {
      var date = new Date();
      var name =
        "extrato_" +
        date.toLocaleDateString() +
        " " +
        date.toLocaleTimeString();
      return name + ".xls";
    },
    async downloadXlsx() {
      this.generateSheet()
      try {
        let itemsNoPage = await this.getItemsNoPage()
        if(itemsNoPage) {
          const wb = utils.book_new()
          let possible_types = ['Saida', 'Entrada']
          let possible_types_operation = ['Manual', 'Pix', 'Tarifa', 'Cartão', '-', '-', '-', '-', '-', '-']
          let possible_status = ['Pendente', 'Em Andamento', 'Concluido', "Recusado", "Em Processmento"];
          let items = itemsNoPage.map(row => ({
            "Data":  this.dateTimeXLSX(row.date),
            "Tipo":  possible_types[row.type],
            "Tipo Operação":  possible_types_operation[row.type_operation],
            "Valor":  this.valueXLSX(row.value),
            "Origem":  row.cpf_origin,
            "Nome Origem":  row.name_origin,
            "Banco Origem":  row.bank_origin,
            "Destino":  row.cpf_destiny,
            "Nome Destino":  row.name_destiny,
            "Banco Destino":  row.bank_destiny,
            "Status":  possible_status[row.status],
          }))
          const ws = utils.json_to_sheet(items)
          utils.book_append_sheet(wb, ws, "Despesas")
          await writeFile(wb, this.getSheetName())
        }
      } catch (error) {
        this.finishSheet()
      } finally {
        this.finishSheet()
      }
    },
    dateTimeXLSX: (value) => {
      if(value) {
        var d = new Date(value);
        return d.toLocaleDateString() + " " + d.toLocaleTimeString();
      }
    },
    valueXLSX: (value) => {
      if (value) {
        let real = value.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });
        return real.substr(3);
      }
      return 0;
    },
    maskCnpj(v){
        v=v.replace(/\D*/g,"")                           //Remove tudo o que não é dígito
        v=v.replace(/^(\d{2})(\d)/,"$1.$2")             //Coloca ponto entre o segundo e o terceiro dígitos
        v=v.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3") //Coloca ponto entre o quinto e o sexto dígitos
        v=v.replace(/\.(\d{3})(\d)/,".$1/$2")           //Coloca uma barra entre o oitavo e o nono dígitos
        v=v.replace(/(\d{4})(\d)/,"$1-$2")              //Coloca um hífen depois do bloco de quatro dígitos
        return v
    },
    maskCpf(v){
        v=v.replace(/\D/g,"")                    
        v=v.replace(/(\d{3})(\d)/,"$1.$2")       
        v=v.replace(/(\d{3})(\d)/,"$1.$2")       
        v=v.replace(/(\d{3})(\d{1,2})$/,"$1-$2") 
        return v
    },
    // Método pra decidir se o usuário ADMINISTRADOR do sistema interno pode alterar os dados da tela:
    profileAccess() {
      return [99, 93].includes(this.userData.role)
    },
  },
  computed: {
    ...mapFields("extract", {
        bankFilter: "filter.bank",
    }),
    ...mapState("login", ["userData"]),
    ...mapState("extract", [
      "items",
      "notification",
      "mode",
      "loading",
      "totalItems",
      "showDialog",
      "dialogFilter",
      "deleteDialog",
    ]),
    ...mapFields("extract", ["pagination"]),
    extract() {
      if(this.search) {
        let s = this.search.toLowerCase().replaceAll("-", "").replaceAll(".", "").replaceAll("/", "")
        return this.items.filter(item => {
          return item.cpf_destiny.toLowerCase().includes(s.toLowerCase()) 
          || item.cnpj_destiny.toLowerCase().includes(s.toLowerCase()) || item.name_destiny.toLowerCase().includes(s.toLowerCase()) || item.value == this.search 
          || item.cpf_origin.toLowerCase().includes(s.toLowerCase()) || item.cnpj_origin.toLowerCase().includes(s.toLowerCase()) || item.name_origin.toLowerCase().includes(s.toLowerCase()) 
        })
      }
      return this.items
    }
  },
  data: () => ({
    headers: [
      { text: "Data", value: "date", sortable: true, group: "teste"},
      { text: "Tipo", value: "type", sortable: true },
      { text: "Tipo Operação", value: "type_operation", sortable: true },
      { text: "Valor", value: "value", sortable: true },
      { text: "Origem", value: "cpf_origin", sortable: true },
      { text: "Nome", value: "name_origin", sortable: true },
      { text: "Destino", value: "cpf_destiny", sortable: true },
      { text: "Nome", value: "name_destiny", sortable: true },
      { text: "Status", value: "status", sortable: true },
    ],
    search: '',
    possible_status: ['Pendente', 'Em Andamento', 'Concluido', "Recusado", "Em Processmento"],
    possible_types: ['Saida', 'Entrada'],
    possible_types_operation: ['Manual', 'Pix', 'Tarifa', 'Cartão', '-', '-', '-', '-', '-', '-'],
    sheet_fields: {
      Data: {
        field: "date",
        callback: (value) => {
          if(value) {
            var d = new Date(value);
            return d.toLocaleDateString() + " " + d.toLocaleTimeString();
          }
        },
      },
      Tipo: {
        field: "type",
        callback: (type) => {
          let possible_types = ['Saida', 'Entrada'];
          return possible_types[type];
        },
      },
      "Tipo Operação": {
        field: "type_operation",
        callback: (type) => {
          let possible_types_operation = ['Manual', 'Pix', 'Tarifa', 'Cartão', '-', '-', '-', '-', '-', '-'];
          return possible_types_operation[type];
        },
      },
      Valor: {
        field: "value",
        callback: (value) => {
          if (value) {
            let real = value.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
            return real.substr(3);
          }
          return 0;
        },
      },
      Origem: {
        field: "cpf_origin",
      },
      "Nome Origem": {
        field: "name_origin",
      },
      "Banco Origem": {
        field: "bank_origin",
      },
      Destino: {
        field: "cpf_destiny",
      },
      "Nome Destino": {
        field: "name_destiny",
      },
      "Banco Destino": {
        field: "bank_destiny",
      },
      Status: {
        field: "status",
        callback: (value) => {
          let possible_status = ['Pendente', 'Em Andamento', 'Concluido', "Recusado", "Em Processmento"];
          return possible_status[value];
        },
      },      
    }
  }),
  watch: {
    pagination: {
      handler() {
        if(this.bankFilter) {
          this.getItems({ resetPage: false });
        }
      },
    },
  },
};
</script>
