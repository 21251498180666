<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    
    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2000"
      v-model="showNotification"
    >
        {{ notificationMessage }}
    </v-snackbar>

    <v-toolbar flat color="purple lighten-4">
      <v-icon color="purple darken-4">mdi-contacts </v-icon>
      <div style="margin-left:10px;color:purple">Contatos</div>
      <v-spacer></v-spacer>
      <div class="button-toolbar">
        <v-tooltip bottom>
          <template v-slot:activator="{on, attrs}">
            <v-btn color="black" dark class="ma-2" @click="downloadXlsx({ show: true })" v-bind="attrs" v-on="on" small>
              <v-icon>mdi-file-excel</v-icon>
            </v-btn>
          </template>
          <span>Baixar planilha.xlsx</span>
        </v-tooltip>
        
        <v-btn color="black" dark class="ma-2" @click="showFilter({show: true})" small><v-icon>mdi-filter</v-icon></v-btn>
        <v-btn color="black" dark class="ma-2" @click="getContacts" small><v-icon>mdi-refresh</v-icon></v-btn>
      </div>
    </v-toolbar>


    <v-data-table
      :headers="headers"
      :loading="loading"
      :items="contacts"
      :options.sync="pagination"
      :server-items-length="totalContacts"
      no-data-text="Nenhum contato encontrado"
      class="elevation-10 table-margin"
    >
      <template v-slot:[`item.phone`]="{ item }">
        <v-chip
          :color="item.phone.length > 13 ? 'blue' : 'green'"
          class="ma-2"
          text-color="white"
          label
          >{{ item.phone.length > 13 ? "Grupo" : "Contato" }}</v-chip
        >
      </template>
      <template v-slot:[`item.send_mass_message`]="{ item }">
        <v-chip
          :color="item.send_mass_message ? 'green' : 'red'"
          class="ma-2"
          text-color="white"
          label
          >{{ item.send_mass_message ? "Sim" : "Não" }}</v-chip
        >
      </template>
      <template v-slot:[`item.date_import`]="{ item }">
        <v-span>{{ getDate(item.date_import) }}</v-span>
        
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              @click="edit({ item: item })"
              v-on="on"
              v-bind="attrs"
              >mdi-pencil</v-icon
            >
          </template>
          <span>Editar</span>
        </v-tooltip>
      </template>
     
    </v-data-table>

    <v-dialog :value="dialog" width="600" @click:outside="closeDialog({show: false})">
      <contacts-form></contacts-form>
    </v-dialog>

    <v-dialog :value="dialogFilter" width="600" @click:outside="showFilter({show: false})">
      <contacts-filter></contacts-filter>
    </v-dialog>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import { mapFields } from 'vuex-map-fields'
  import ContactsForm from './ContactsForm'
  import ContactsFilter from './ContactsFilter'
  import { utils, writeFile } from "xlsx";

  export default {
    name: 'Contacts',
    computed: {
      ...mapState('login', ['userData']),
      ...mapState('contacts', ['contact_show', 'dialog', 'dialogDelete', 'dialogReceipt', 'dialogFilter', 
            'contact','instances', 'errors', 'users', 'loading', 'mode', 'notificationType', 'notificationMessage', 
            'rowsPerPage', 'receipt_show', 'totalContacts', 'contacts']),
      ...mapFields('contacts', [
        "dialog",
        "dialogReceipt",
        'id_agent',
        'pagination',
        'overlay',
        'showNotification'
        // ...
      ])
    },
    created() {
      this.getContacts({resetPage: false})
      this.setScreen({screen: 'contacts'})
    },
    components: {
      "contacts-filter": ContactsFilter,
      "contacts-form": ContactsForm
    },
    methods: {
      ...mapMutations('contacts', ['edit', 'viewContact', 'showFilter', 'setDialogReceipt', "setScreen"]),
      ...mapActions('contacts', ['closeDialog', 'closeDeleteDialog', 'deleteContact', 'getUsers', 'getContacts', 'getContactsNoPage', 
                    'showDeleteDialog','showReceipt', 'startWithdrawal', 'stopWithdrawal', 'getAgents' ]),
      isMobile() {
        if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
          return true;
        }
        return false
      },
      getDate(date) {
        if (date) {
          var d = new Date(date);
          return d.toLocaleDateString();
        }
        return "";
      },
      generateSheet() {
        this.overlay = true;
      },
      finishSheet() {
        this.overlay = false;
      },
      getSheetName() {
        var date = new Date();
        var name =
          "contacts" +
          date.toLocaleDateString() +
          " " +
          date.toLocaleTimeString();
        return name + ".xls";
      },
     async downloadXlsx() {
       this.generateSheet()
       try {
         let itemsNoPage = await this.getContactsNoPage();
         if(itemsNoPage) {
           const wb = utils.book_new();
           let items = itemsNoPage.map(row => ({
             "Nome": row.name,
             "Contato": row.contact_id2,
             "Instância": row.instance_name,
           }))
           const ws = utils.json_to_sheet(items);
           utils.book_append_sheet(wb, ws, "Contatos");
           await writeFile(wb, this.getSheetName());
         }
       } catch (error) {
         this.finishSheet()
       } finally {
         this.finishSheet()
       }
     }
    },
    data: () => ({
      headers: [
        { text: "Tipo", value: "phone", sortable: false, align: "left"},
        { text: "Contato",  value: "contact_id2", sortable: false, align: "left"},
        { text: "Nome",  value: "name", sortable: false, align: "left"},
        { text: "Instância",  value: "instance_name", sortable: false, align: "left"},
        { text: "Recebe Mensagem em Massa", value: "send_mass_message", sortable: false, align: "center"},
        { text: "Data Atualização", value: "date_import", sortable: false, align: "center"},
        { text: "Ações", value: "actions", sortable: false, align: "left"}
      ],
    }),
    watch: {
      pagination: {
        handler () {
          this.getContacts({resetPage: false})
        },
      },
    },
  }
</script>

<style>
</style>