import axios from "axios";
import { getField, updateField } from "vuex-map-fields";
import endPoints from "../../../config/endPoints";
import { defaultErrorNotification, defaultSuccessNotification } from "../../../constants";

const defaultItem = {
  id: null,
  collaborator_id: null,
  equipment_id: null,
};

export default {
  namespaced: true,
  state: {
    itemToDelete: {},
    editedItem: {},
    collab_equip: [],
    equipments: [],
    items: [],
    loading_equipments: false,
    dialogDelete: false,
    loading: false,
    dialog: false,
    collaborator_id: null,
    mode: "LIST",
    pagination_equipments: {
      page: 1,
      itemsPerPage: 10,
    },
    filter_equipment: {
      name: '',
    },
    totalEquipments: 0,
    notification: {
      show: false,
      message: "",
      type: "",
    },
  },
  mutations: {
    add: (state) => {
      state.mode = "ADD";
      state.editedItem = Object.assign({}, defaultItem);
      state.dialog = true;
    },
    closeDialog: (state) => {
      state.editedIndex = -1;
      state.editedItem = {};
      state.errors = [];
      state.dialog = false;
      state.mode = "LIST";
    },
    setEquipments: (state, payload) => {
      state.collab_equip = payload.collaborators_equipments;
    },
    setEquipmentsForm: (state, payload) => {
      state.equipments = payload.equipments;
    },
    setMode: (state, mode) => (state.mode = mode),
    showNotification: (state, payload) => (state.notification = payload),
    loading_equipments: (state, payload) => (state.loading_equipments = payload.loading),
    setFindEquipments: (state, payload) => state.find_equipments = payload.find_equipments,
    setCollaborator: (state, payload) => (state.collaborator = payload.collaborator),
    setTotalEquipments: (state, payload) => (state.totalEquipments = payload.total),
    setItemToDelete: (state, item) => (state.itemToDelete = item),
    setDialogDelete: (state, show) => (state.dialogDelete = show),
    showLoading: (state, show) => (state.loading = show),
    showDialog: (state, show) => (state.dialog = show),
    updateField,
  },
  getters: {
    getField,
  },
  actions: {
    closeDialog: ({ commit, dispatch, rootGetters }) => {
      dispatch("getEquipments", {
        resetPage: true,
        collaborator_id: rootGetters["collaborator/getIdCollaborator"],
      });
      commit("closeDialog", false);
    },
    closeDeleteDialog: ({ commit }) => {
      commit("setDialogDelete", false);
      commit("setItemToDelete", {});
    },
    deleteItem: ({ dispatch, commit, rootGetters, state }) => {
      commit("loading_equipments", true);
      axios.delete(endPoints.collaborators_equipments + "/" + state.itemToDelete.id, state.itemToDelete).then(() => {
        dispatch("closeDeleteDialog");
        commit("loading_equipments", { loading: true });
        commit("showNotification", defaultSuccessNotification("Equipamento removido com sucesso"));
        return dispatch("getEquipments", {
          resetPage: true,
          collaborator_id: rootGetters["collaborator/getIdCollaborator"],
        });
      });
    },
    getEquipments: ({ commit, state }, payload) => {
      commit('loading_equipments', { loading: true })
      let url = endPoints.collaborators_equipments
      url += '?page=' + state.pagination_equipments.page + '&' + 'itemsPerPage=' + state.pagination_equipments.itemsPerPage
        + '&collaborator_id=' + payload.collaborator_id;
      axios.get(url).then(response => {
        commit('setTotalEquipments', { total: response.data.total })
        commit('loading_equipments', { loading: false })
        commit('setEquipments', { collaborators_equipments: response.data.data })
      });
    },
    getEquipmentsForm: ({ commit }) => {
      let url = endPoints.equipments;
      url += "?page=" + 1 + "&" + "itemsPerPage=" + 999;
      axios.get(url).then((items) => {
        var equipments = items.data.data;
        commit("setEquipmentsForm", { equipments: equipments });
      });
    },
    showDeleteDialog: ({ commit }, item) => {
      commit("setDialogDelete", true);
      commit("setItemToDelete", item);
    },
    save: ({ commit, dispatch, state, rootGetters }) => {
      var url = endPoints.collaborators_equipments;
      let func = "";
      if (state.mode == "ADD") {
        func = axios.post;
        url = endPoints.collaborators_equipments;
        state.editedItem.collaborator_id = rootGetters["collaborator/getIdCollaborator"];
      } else {
        func = axios.put;
        url += "/" + state.editedItem.id;
      }

      func(url, state.editedItem).then(() => {
        dispatch("getEquipments", {
          resetPage: true,
          collaborator_id: rootGetters["collaborator/getIdCollaborator"],
        });
        dispatch("getEquipmentsForm")
        commit("showNotification",
          defaultSuccessNotification("Equipamento salvo com sucesso")
        );
        commit("closeDialog");
        // commit("setCollaborator");
        // commit("setEquipmentsForm");
        // commit("setEquipments");
      },
        (error) => {
          commit("showNotification",
            defaultErrorNotification(error?.response?.message)
          );
        }
      );
    },
    getFindEquipments: ({ commit }, payload) => {
      commit('loading_equipments', true)
      let url = endPoints.equipments + "?name=" + payload.search
      axios.get(url).then(items => {
        commit('loading_equipments', false)
        commit('setEquipmentsForm', { equipments: items.data.data })
      });
    },
  },
};
