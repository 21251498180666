<template>
    <div>
        <v-toolbar dense flat class="">
            <div class="button-toolbar mt-4 mr-4">
                <v-tooltip bottom dense size="30">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="#E4E4E4"
                            elevation="0"
                            class="py-4"
                            @click="setSelectedRegistrys()"
                            v-bind="attrs"
                            v-on="on"
                            small
                        >
                            <v-icon small color="dark" class="mr-2"
                                >mdi-card-multiple</v-icon
                            >
                            Selecionar Registros
                        </v-btn>
                    </template>
                    <span>Múltiplos Registros</span>
                </v-tooltip>
            </div>
            <div class="button-toolbar mt-4 mr-5">
                <v-tooltip bottom dense size="30" v-if="this.selectedRegistrys">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="#E4E4E4"
                            elevation="0"
                            class="py-4"
                            @click="
                                setDialogManualLow({
                                    multiples: true,
                                    show: true,
                                    items: selected.map((item) => {
                                        return {
                                            id: item.id,
                                            new_total_vinculations:
                                                item.linked_to,
                                        }
                                    }),
                                })
                            "
                            v-bind="attrs"
                            v-on="on"
                            small
                        >
                            <v-icon small color="dark" class="mr-2"
                                >mdi-arrow-down-bold</v-icon
                            >
                            Baixa Manual
                        </v-btn>
                    </template>
                    <span>Baixa Manual</span>
                </v-tooltip>
            </div>
            <v-spacer></v-spacer>
            <div class="button-toolbar mt-4 mr-1">
                <v-tooltip bottom dense size="30">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="#E4E4E4"
                            elevation="0"
                            class="py-4"
                            @click="setShowFilter(true)"
                            v-bind="attrs"
                            v-on="on"
                            small
                        >
                            Filtrar
                            <v-icon small color="dark">mdi-filter</v-icon>
                        </v-btn>
                    </template>
                    <span>Filtro</span>
                </v-tooltip>
            </div>
        </v-toolbar>
        <v-data-table
            v-model="selected"
            item-key="id"
            :show-select="selectedRegistrys"
            :headers="headers"
            :options.sync="pagination"
            :server-items-length="totalItems"
            :item-class="getClassColor"
            :items="items"
            :footer-props="{
                'items-per-page-options': [10, 20, 50, 100],
                'items-per-page-text': 'Itens por página',
                'show-first-last-page': true,
                'page-text': `Página ${pagination.page} de ${
                    parseInt(totalItems / pagination.itemsPerPage) + 1
                }`,
            }"
        >
            <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ attrs, on }">
                        <v-icon
                            small
                            color="black"
                            class="mr-2"
                            v-bind="attrs"
                            v-on="on"
                            @click="openDialog(item)"
                            >mdi-information</v-icon
                        >
                    </template>
                    <span>Informações</span>
                </v-tooltip>
            </template>
            <template v-slot:[`item.value`]="{ item }">
                <span>{{ formatMoney(item.value) }}</span>
            </template>
            <template v-slot:[`item.date`]="{ item }">
                <span>{{ formatDate(item.date) }}</span>
            </template>
            <template v-slot:[`item.moviment_type`]="{ item }">
                <span>{{ getTypeMoviment(item.moviment_type) }}</span>
            </template>
            <template v-slot:[`item.status`]="{ item }">
                <span>{{ getStatus(item.status) }}</span>
            </template>
            <template v-slot:[`item.type`]="{ item }">
                <span>{{ getType(item.type) }}</span>
            </template>
            <template v-slot:[`item.ligations`]="{ item }">
                <span>{{
                    formatLigations(item.linked_to, item.total_vinculations)
                }}</span>
            </template>
        </v-data-table>

        <v-dialog
            :value="dialogManualLow"
            @click:outside="setCloseDialogManualLow()"
            width="1280"
        >
            <PeriodsConciliationLowDialog />
        </v-dialog>
        <v-dialog
            :value="dialog"
            @click:outside="closeDialogVinculation"
            width="1280"
        >
            <PeriodConciliationVinculationsDialog />
        </v-dialog>

        <v-dialog
            :value="filterDialog"
            @click:outside="setShowFilter(false)"
            width="600"
        >
            <PeriodConciliationMovimentsFilter />
        </v-dialog>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { formatDate, formatMoney } from '@util/format_util'
import { mapFields } from 'vuex-map-fields'
export default {
    name: 'PeriodConciliationBankMovimentsGrid',
    computed: {
        ...mapState('period_conciliation_bank_moviments', [
            'items',
            'loading',
            'totalItems',
            'headers',
            'getClassColor',
            'filterDialog',
        ]),
        ...mapState('periods_conciliation_low_dialog', [
            'closeAllDialogsManualLow',
        ]),
        ...mapState('period_conciliation_dialog_vinculation', [
            'closeAllDialogs',
        ]),
        ...mapFields('period_conciliation_bank_moviments', [
            'pagination',
            'selectedItem',
        ]),
    },
    components: {
        PeriodsConciliationLowDialog: () =>
            import(
                '../periods_conciliation_low_dialog/PeriodsConciliationLowDialog.vue'
            ),
        PeriodConciliationVinculationsDialog: () =>
            import(
                '../period_conciliation_dialog_vinculation/PeriodConciliationVinculationsDialog.vue'
            ),
        PeriodConciliationMovimentsFilter: () =>
            import('./PeriodConciliationBankMovimentsFilter.vue'),
    },
    created() {
        this.getItems({ filter: { origin: 0 } })
        this.getBanks()
    },
    methods: {
        ...mapMutations('periods_conciliation_low_dialog', [
            'showDialogManualLow',
        ]),
        ...mapActions('periods_conciliation_low_dialog', [
            'setCloseDialogManualLow',
        ]),
        ...mapActions('bank', { getBanks: 'getItems' }),
        ...mapActions('period_conciliation_bank_moviments', ['getItems']),
        ...mapMutations('period_conciliation_bank_moviments', [
            'view',
            'setShowFilter',
        ]),
        ...mapActions('period_conciliation_dialog_vinculation', [
            'getVinculations',
        ]),
        ...mapMutations('period_conciliation_dialog_vinculation', [
            'closeDialog',
            'openVinculations',
            'buildItems',
        ]),
        ...mapMutations('period_conciliation', ['showNotification']),
        formatMoney(value) {
            return formatMoney(value)
        },
        openDialog(item) {
            item.tab_origin = 3
            this.dialog = true
            this.openVinculations(item)
            this.getVinculations()
            this.buildItems()
        },
        setSelectedRegistrys() {
            this.selectedRegistrys = !this.selectedRegistrys
            if (!this.selectedRegistrys) {
                this.selected = []
            }
        },
        setDialogManualLow(payload) {
            payload.tab_origin = 3
            this.dialogManualLow = true
            this.showDialogManualLow(payload)
        },
        closeDialogManualLow() {
            this.dialogManualLow = false
            this.setCloseDialogManualLow()
        },
        formatDate(date) {
            return formatDate(date)
        },
        closeDialogVinculation() {
            this.dialog = false
            this.closeDialog()
        },
        getTypeMoviment(value) {
            switch (value) {
                case 0:
                    return 'Manual'
                case 1:
                    return 'Pix'
                case 2:
                    return 'Tarifa'
                default:
                    return 'Não definido'
            }
        },
        getStatus(value) {
            switch (value) {
                case 2:
                    return 'Completa'
                case 3:
                    return 'Recusada'
                case 4:
                    return 'Em Processamento '
                default:
                    return 'Status desconhecido'
            }
        },
        getType(value) {
            switch (value) {
                case 0:
                    return 'Débito'
                case 1:
                    return 'Crédito'
                default:
                    return 'Não definido'
            }
        },
        formatLigations(linked_to, total_vinculations) {
            return `${linked_to} de ${total_vinculations}`
        },
    },
    data: () => ({
        selectedRegistrys: false,
        selected: [],
        dialog: false,
        dialogManualLow: false,
    }),
    watch: {
        pagination: {
            handler() {
                this.getItems({ resetPage: false })
            },
        },
        closeAllDialogs: {
            handler() {
                this.dialog = false
                this.dialog
            },
        },
        closeAllDialogsManualLow: {
            handler() {
                this.dialogManualLow = false
            },
        },
    },
}
</script>

