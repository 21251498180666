import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../config/endPoints';

const defaultRoute = {
    name: '',
    type: '',
    menu: ''
};

export default {
    namespaced: true,
    state: {
        dialog: false,
        dialogDelete: false,
        dialogFilter: false,
        dialogReceipt: false,
        editedRoute: {},
        filter: {
            name: '',
            type: '',
            menu: '',
        },
        instances: [],
        routes: [],
        routeToDelete: {},
        loading: false,
        mode: 'LIST',
        overlay: false,
        page: 1,
        pagination: {
            page: 1,
            routesPerPage: 10
        },
        successNotification: false,
        notificationMessage: '',
        notificationType: "success",
        receipt_show: '',
        selectedContact: {},
        showNotification: false,
        totalRoutes: 0,
        rowsPerPage: 10,
        menus: []
    },
    mutations: {
        add: (state, payload) => {
            state.mode = "ADD"
            state.editedRoute = Object.assign({}, defaultRoute)
            state.screen = payload.screen
            if (state.screen != "messages") {
                state.dialog = true
            }
        },
        edit: (state, payload) => {
            state.editedRoute = Object.assign({}, payload.item)
            state.dialog = true
            state.mode = "EDIT"
        },
        closeDialog: (state) => {
            state.editedRoute = {}
            state.editedIndex = -1
            state.errors = [];
            state.dialog = false
            state.mode = "LIST"
        },
        loading: (state, payload) => state.loading = payload.show,
        setMode: (state, mode) => state.mode = mode,
        setRoutes: (state, routes) => state.routes = routes,
        setRouteToDelete: (state, route) => state.routeToDelete = route,
        setTotalRoutes: (state, total) => state.totalRoutes = total,
        setRowsPerPage: (state, rowsPerPage) => state.rowsPerPage = rowsPerPage,
        showDialog: (state, show) => state.dialog = show,
        setPage: (state, payload) => {
            state.pagination.page = payload.page;
            state.pagination.routesPerPage = payload.routesPerPage
        },
        setScreen: (state, payload) => state.screen = payload.screen,
        setUsersName: (state, payload) => {
            let users_name = []
            payload.users.forEach(user => {
                users_name[user.id] = user.name
            });
        },
        setMenus: (state, payload) => {
            state.menus = payload.menus
        },
        setDialogDelete: (state, show) => state.dialogDelete = show,
        setDialogReceipt: (state, payload) => state.dialogReceipt = payload.show,
        setInstances: (state, payload) => state.instances = payload.instances,
        showFilter: (state, payload) => state.dialogFilter = payload.show,
        showErrorNotification: (state, show) => state.errorNotification = show,
        showSuccessNotification: (state, show) => state.successNotification = show,
        showLoading: (state, show) => state.loading = show,
        showNotification: (state, payload) => {
            state.showNotification = payload.show,
                state.notificationMessage = payload.message,
                state.notificationType = payload.type
        },
        showOverlay: (state, payload) => {
            state.overlay = payload.show
        },
        setReceipt: (state, payload) => state.receipt_show = payload.receipt,
        viewRoute(state, route) {
            state.editedRoute = route
            state.editedIndex = state.routes.indexOf(route)
            state.dialog = true
            state.mode = "VIEW"
        },
        updateField
    },
    getters: {
        getField,
    },
    actions: {
        closeDialog: (({ commit, state }) => {
            state.editedRoute = {}
            if (state.screen != "messages") {
                commit('message/setSelectedMessages', { messages: [] }, { root: true })
                commit('showDialog', false)
            } else {
                commit('message/showDialogFail', { show: false }, { root: true })
            }
            setTimeout(() => {
                commit('closeDialog')
                commit('showErrorNotification', false)
                commit('showSuccessNotification', false)
            }, 300);
        }),
        closeDeleteDialog: ({ commit }) => {
            commit('setDialogDelete', false)
            commit('setRouteToDelete', {})
        },
        deleteRoute: ({ dispatch, commit, state }) => {
            commit('loading', true)
            axios.delete(endPoints.routes + "/deleteRoute/" + state.routeToDelete.id, state.routeToDelete).then(() => {
                dispatch('closeDeleteDialog')
                commit('loading', false)
                let notification = {
                    show: true,
                    message: "Rota excluida com sucesso",
                    type: "success"
                }
                commit('showNotification', notification)
                return dispatch('getRoutes', { resetPage: false });
            });
        },
        getRoutes: ({ commit, state }, payload) => {
            commit('loading', true)
            let url = endPoints.routes;
            let filter = state.filter;
            if (payload.resetPage === true) {
                url += '?page=' + 1 + '&' + 'routesPerPage=' + 10;
                commit('setPage', { page: 1, routesPerPage: 10 })
            } else {
                url += '?page=' + state.pagination.page + '&' + 'routesPerPage=' + state.pagination.routesPerPage;
            }


            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined) {
                    url += "&" + field + "=" + filter[field]
                }
            });

            axios.get(url).then(routes => {
                commit('setTotalRoutes', routes.data.total)
                commit('loading', false)
                commit('setRoutes', routes.data.data)
            });
        },
        getChildrenSearch: ({ commit }, payload) => {
            let url = endPoints.menus + '/children_search';
            url += '/' + payload.search

            axios.get(url).then(menus => {
                commit('setMenus', { menus: menus.data })
            });
        },
        getRoutesNoPage: ({ state }) => {
            let url = endPoints.routes;
            let filter = state.filter;
            url += '?page=' + '1' + '&' + 'routesPerPage=' + '99999';

            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined) {
                    url += "&" + field + "=" + filter[field]
                }
            });
            return axios.get(url).then(routes => {
                return routes.data.data
            });
        },
        showDeleteDialog: ({ commit }, route) => {
            commit('setDialogDelete', true)
            commit('setRouteToDelete', route)
        },
        save: async({ dispatch, commit, state }) => {
            var url = endPoints.routes;
            let func = '';
            let data = state.editedRoute

            commit('showOverlay', { show: true })
            if (state.mode == 'ADD') {
                func = axios.post;
                url = endPoints.routes;
            } else {
                func = axios.put;
                url += '/updateRoute/' + data.id;
            }

            func(url, data).then(
                () => {
                    dispatch('getRoutes', { resetPage: true })
                    let notification = {
                        show: true,
                        message: "Rota salva com sucesso",
                        type: "success"
                    }
                    commit('loading', { show: false })
                    commit('showOverlay', { show: false })
                    if (state.screen != 'messages') {
                        commit('showNotification', notification)
                        commit('closeDialog')
                        commit('showOverlay', { show: false })
                    } else {
                        commit('closeDialog')
                        commit('message/showDialogFail', { show: false }, { root: true })
                        commit('message/showNotification', notification, { root: true })                        
                    }
                },
                error => {
                    let notification = {
                        show: true,
                        message: error.response.data.message,
                        type: "error"
                    }
                    commit('showNotification', notification)
                    commit('showOverlay', { show: false })
                }
            );
        },
    }
}