import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../../config/endPoints';
const axios = require('axios');

export default {
    namespaced: true,
    state: {
        items: [],
        itemsSearched: false,
        pagination: {
            page: 1,
            rowsPerPage: 10,
            totalItems: 0
        },
        filter: {
            account_payment_withdrawals: null,
        }
    },
    mutations: {
        setItems: (state, payload) => {
            state.items = payload.items;
            console.log('Items: ', state.items);
            state.itemsSearched = true;
        },
        updateField
    },
    getters: {
        getField
    },
    actions: {
        async getItems({ commit, state }, payload) {
            try {
                if(payload.onlyIfNotSearched && state.itemsSearched) {
                    return
                }
                let url = endPoints.bank_sub_accounts;
                if(payload.noPages) {
                    url += `?page=1&limit=9999`;
                } else {
                    url += `?page=${payload.page}&limit=${payload.rowsPerPage}`;
                
                }
                const response = await axios.get(url);
                commit('setItems', { items: response.data.data });
            } catch (error) {
                console.error(error);
            }
        }
    }
}