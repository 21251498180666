<template>
  <div>
    <v-card>
        <v-card-title>
          <span class="headline text-center">Filtro</span>
          <v-spacer></v-spacer>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Nome *"
                    v-model="name"
                    :error-messages="errors.collect('name')"
                    v-validate="'required:Nome,name'"
                    data-vv-name="name"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-select
                    label="Tipo *"
                    required
                    :items="possible_type"
                    v-model="type"
                    :error-messages="errors.collect('type')"
                    v-validate="'required:Type,type'"
                    data-vv-name="type"
                    outlined
                    item-text="text"
                    item-value="value"
                  >
                  </v-select>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-autocomplete
                    clearable
                    deletable-chips
                    v-model="menu"
                    :no-filter="true"
                    :items="menus"
                    outlined
                    chips
                    small-chips
                    no-data-text="Nenhum menu encontrado"
                    item-text="name"
                    item-value="id"
                    placeholder="Digite aqui o nome da tela relacionada"
                    label="Tela Relacionada"
                    :search-input.sync="searchMenu"
                  ></v-autocomplete>
                </v-flex>

              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn text @click="showFilter({show: false})">Cancelar</v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn text type="submit" right @click="filter">Filtrar</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import debounce from "../../util/debounce.js";

export default {
  name: "RoutesFilter",
  computed: {
    ...mapState("routes", ["menus"]),
    ...mapFields("routes", [
      "filter.menu",
      "filter.type",
      "filter.name",
      // ...
    ]),
  },
  mixins: [debounce],
  mounted() {
    this.getChildrenSearch = this.debounce(this.getChildrenSearch, 500);
  },
  methods: {
    ...mapMutations("routes", ["showFilter"]),
    ...mapActions("routes", [
      "getChildrenSearch",
      "getItems",
      "getAttendants",
      "getUsers",
      "getAgents",
      "closeDialog",
      "save",
    ]),
    clearStart() {
        this.start = ''
        this.start_formatted = ''
    },
    clearEnd() {
        this.end = ''
        this.end_formatted = ''
    },
    filter() {
      this.getItems({ resetPage: true });
      this.showFilter({ show: false });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    },
    getTextUser(item) {
      if(item.account) {
        return item.name + ' | ' + item.account
      } else {
        return item.name
      }
    },
    setStart(date) {
      this.start = date;
      this.showStart = false;
      this.start_formatted = this.formatDate(date);
    },
    setEnd(date) {
      this.end = date;
      this.showEnd = false;
      this.end_formatted = this.formatDate(date);
    },
  },
  data: () => ({
    possible_type: [
      {
        text: "GET",
        value: 0,
      },
      {
        text: "PUT",
        value: 1,
      },
      {
        text: "POST",
        value: 2,
      },
      {
        text: "PATCH",
        value: 3,
      },
      {
        text: "DELETE",
        value: 4,
      }
    ],
    searchMenu: '',
  }),
  watch: {
    searchMenu (val) {
      var payload = {
        search: val
      }
      this.getChildrenSearch(payload)
    }
  },
};
</script>

<style>
</style>