import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../../config/endPoints';

const getDateHour = () => {
    var d = new Date()
    var l = d.toLocaleDateString()
    var date = l.substr(-4) + "-" + l.substr(3, 2) + "-" + l.substr(0, 2)
    var c = date + " " + d.toLocaleTimeString()
    return new Date(c.substr(0, 13) + ":00:00")
}

const defaultItem = {
    description: "",
    start_date: "",
    finish_date: "",
    type_period: 0,
    last_period: ""
};

const spreadsheet_headers_config_import = [
    { database_field: "Club", description: "Clube", column_sheet: 0, type: "int" },
    { database_field: "IdPppoker", description: "Id do Jogador", column_sheet: 1, type: "string" },
    { database_field: "Nick", description: "Nick", column_sheet: 4, type: "string" },
    { database_field: "IdPppokerAgent", description: "Id do Agente", column_sheet: 6, type: "string" },
    { database_field: "PlayerGains", description: "Total de Ganhos do Jogador", column_sheet: 26, type: "float" },
    { database_field: "PlayerTax", description: "Total de Rake do Jogador", column_sheet: 45, type: "float" },
    { database_field: "PlayerSpin", description: "Total de Spin Up", column_sheet: 46, type: "float" },
    { database_field: "HandsPlayed", description: "Mãos Jogadas", column_sheet: 66, type: "int" }
]

export default {
    namespaced: true,
    state: {
        dialog: false,
        dialogPeriod: false,
        dialogFilter: false,
        dialogDelete: false,
        dialogSpreadsheet: false,
        dialogTotals: false,
        editedItem: {},
        items: [],
        chip_totals: [],
        item_spreadsheet: {},
        instances: [],
        itemToDelete: {},
        loading: false,
        mode: 'LIST',
        period_players_balance: {
            id: '',
            start_date: '',
            description: '',
            finish_date: ''
        },
        totalValue: 0,
        totalNormalChips: 0,
        totalPayedChips: 0,
        totalGPChips: 0,
        notificationType: '',
        notificationMessage: '',
        club_import: null,
        page: 1,
        filter: {
            only_agents: 0,
            view_type: 0,
            id_agent: "",
            sub_agent_id: "",
            nick_agent: "",
            nick_subagent: "",
            club: "",
            id_spreadsheet: "",
            type_deal_agent: "",
            period: ""
        },
        overlay: false,
        pagination: {
            page: 1,
            itemsPerPage: 10
        },
        paginationDialog: {
            page: 1,
            itemsPerPage: 10
        },
        periods_closure: [],
        spreadsheet_headers_config_import: spreadsheet_headers_config_import,
        selected_spreadsheet: null,
        spreadsheet_balance: [],
        start_line: 6,
        showNotification: false,
        totalItems: 0,
        totalItemsDialog: 0,
        rowsPerPage: 10
    },
    mutations: {
        add: (state) => {
            state.mode = "ADD"
            state.editedItem = Object.assign({}, defaultItem)
            state.editedItem.date_picker = getDateHour()
            state.dialogPeriod = true
        },
        edit: (state, payload) => {
            state.editedItem = Object.assign({}, payload.item)
            state.editedIndex = state.items.indexOf(payload.item)
            let start = new Date(payload.item.start_date + " 12:00:00")
            let finish = new Date(payload.item.finish_date + " 12:00:00")
            state.start_formatted = start.toLocaleDateString()
            state.finish_formatted = finish.toLocaleDateString()
            if (payload.item.last_period) {
                state.periods_closure = [{ id: payload.item.last_period, description: payload.item.last_period_name }]
            }
            state.dialog = true
            state.mode = "EDIT"
        },
        closeDialog: (state) => {
            state.dialog = false
            state.editedItem = Object.assign({}, defaultItem)
            state.editedIndex = -1
            state.errors = [];
        },
        loading: (state, loading) => state.loading = loading,
        overlay: (state, payload) => state.overlay = payload.show,
        setMode: (state, mode) => state.mode = mode,
        setSelectedSpreadsheet: (state, payload) => state.selected_spreadsheet = payload.spreadsheet,
        setItems: (state, items) => state.items = items,
        setChipsTotal: (state, chip_totals) => state.chip_totals = chip_totals,
        setItemSpreadsheet: (state, payload) => state.item_spreadsheet = payload.item,
        setItemsSpreadsheet: (state, payload) => state.spreadsheet_balance = payload.items,
        setTotalValue: (state, total) => state.totalValue = total,
        setTotalNormalChips: (state, total) => state.totalNormalChips = total,
        setTotalGPChips: (state, total) => state.totalGPChips = total,
        setTotalPayedChips: (state, total) => state.totalPayedChips = total,
        setItemToDelete: (state, item) => state.itemToDelete = item,
        setInstances: (state, payload) => state.instances = payload.instances,
        setTotalItems: (state, total) => state.totalItems = total,
        setTotalItemsDialog: (state, total) => state.totalItemsDialog = total,
        setRowsPerPage: (state, rowsPerPage) => state.rowsPerPage = rowsPerPage,
        showFilter: (state, payload) => state.dialogFilter = payload.show,
        showDialog: (state, show) => state.dialog = show,
        setDialogDelete: (state, show) => state.dialogDelete = show,
        setDialogSpreadsheet: (state, payload) => state.dialogSpreadsheet = payload.show,
        setDialogTotals: (state, payload) => state.dialogTotals = payload.show,
        setPeriodPlayersBalance: (state, payload) => state.period_players_balance = payload.period_players,
        setHeadersSpreadsheet: (state, payload) => {
            state.spreadsheet_headers_config_import = payload.headers
        },
        setPeriodsClosure: (state, payload) => state.periods_closure = payload.periods,
        showErrorNotification: (state, show) => state.errorNotification = show,
        showSuccessNotification: (state, show) => state.successNotification = show,
        showLoading: (state, show) => state.loading = show,
        showNotification: (state, payload) => {
            state.showNotification = payload.show,
                state.notificationMessage = payload.message,
                state.notificationType = payload.type
        },
        updateField
    },
    getters: {
        getField,
    },
    actions: {
        getInstances: ({ commit }) => {
            let url = endPoints.instances;
            url += '?page=' + 1 + '&' + 'itemsPerPage=' + 150;
            axios.get(url).then(instances => {
                commit('setInstances', { instances: instances.data.data })
            });
        },
        closeDeleteDialog: ({ commit }) => {
            commit('setDialogDelete', false)
            commit('setItemToDelete', {})
        },
        deleteItem: ({ dispatch, commit, state }) => {
            axios.delete(endPoints.players_balance + "/" + state.itemToDelete.id, state.itemToDelete).then(() => {
                dispatch('closeDeleteDialog')
                dispatch('getItems')
                return dispatch('getSpreadsheet');
            }).catch(() => {
                let notification = {
                    show: true,
                    message: "Não foi possivel excluir o registro.",
                    type: "error"
                }
                commit('showNotification', notification)
                dispatch('closeDeleteDialog')
                dispatch('getItems')
                return dispatch('getSpreadsheet');
            });
        },
        getItems: ({ commit, state, rootGetters }) => {
            let period = rootGetters['periods_players_balance/getPeriodSelected']
            if (period == 0 || period == null || period == undefined || period == "") {
                period = location.href.split('players_balance/')[1]
            }
            commit('loading', true)
            let url = endPoints.players_balance;
            let filter = state.filter;
            url += '/' + period + '?page=' + state.pagination.page + '&' + 'itemsPerPage=' + state.pagination.itemsPerPage;

            Object.keys(filter).forEach(function (field) {
                if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
                    if (field == "start") {
                        var e = new Date(filter[field])

                        url += "&" + field + "=" + e.toISOString().substr(0, 10) + " " + e.toLocaleTimeString()
                    } else {
                        url += "&" + field + "=" + filter[field]
                    }
                }
            });

            if (state.pagination && state.pagination.sortBy && state.pagination.sortBy.length > 0) {
                url += "&sort_by=" + (state.pagination.sortBy[0] === 'name' ? "id" : state.pagination.sortBy[0])
                url += "&sort_desc=" + state.pagination.sortDesc[0]
            }
            axios.get(url).then(items => {
                commit('setTotalItems', items.data.total)
                commit('loading', false)
                commit('setTotalValue', items.data.value)
                commit('setItems', items.data.data)
            });
        },
        getItemsNoPage: ({ commit, state, rootGetters }) => {
            commit('loading', true)
            let period = rootGetters['periods_players_balance/getPeriodSelected']
            if (period == 0 || period == null || period == undefined || period == "") {
                period = location.href.split('players_balance/')[1]
            }
            let url = `${endPoints.players_balance}/${period}?page=1&itemsPerPage=9999999`;
            let filter = state.filter;

            Object.keys(filter).forEach(function (field) {
                if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
                    url += "&" + field + "=" + filter[field]
                }
            });

            return axios.get(url).then(items => {
                commit('loading', false)
                return items.data.data
            });
        },
        importSpreadsheet: ({ commit, dispatch, state, rootGetters }) => {
            let period = rootGetters['periods_players_balance/getPeriodSelected']
            if (period == 0 || period == null || period == undefined || period == "") {
                period = location.href.split('players_balance/')[1]
            }
            let url = endPoints.players_balance + "/spreadsheet";
            let formData = new FormData();
            let headers = [];
            state.spreadsheet_headers_config_import.forEach((h) => {
                h.column_spreadsheet = parseInt(h.column_spreadsheet)
                headers.push(h)
            })
            formData.append('file', state.selected_spreadsheet);
            formData.append('headers', JSON.stringify(headers))
            formData.append('start_line', state.start_line)
            formData.append('id_period', period)
            formData.append('club_import', state.club_import)
            commit('overlay', { show: true })
            axios.post(url, formData).then(() => {
                commit('overlay', { show: false })
                let notification = {
                    show: true,
                    message: "Planilha importada com sucesso.",
                    type: "success"
                }
                commit('setSelectedSpreadsheet', { spreadsheet: null })
                commit('showNotification', notification)
                dispatch('getSpreadsheet')
                dispatch('getItems')
            }).catch(() => {
                let notification = {
                    show: true,
                    message: "Ocorreu um erro ao importar a planilha. Contate o administrador do sistema.",
                    type: "error"
                }
                commit('overlay', { show: false })
                commit('showNotification', notification)
            });
        },
        getSpreadsheet: ({ commit, state }) => {
            let url = endPoints.players_balance + "/spreadsheet";
            url += '?page=' + state.paginationDialog.page + '&' + 'itemsPerPage=' + state.paginationDialog.itemsPerPage;
            axios.get(url).then(items => {
                commit('setItemsSpreadsheet', { items: items.data.data })
                commit('setTotalItemsDialog', items.data.total)
            });
        },
        showDialogSpreadsheet: ({ commit, state }, payload) => {
            let url = endPoints.players_balance + "/spreadsheet";
            url += '?page=' + state.paginationDialog.page + '&' + 'itemsPerPage=' + state.paginationDialog.itemsPerPage;
            axios.get(url).then(items => {
                commit('setHeadersSpreadsheet', { headers: items.data.headers })
                commit('setItemSpreadsheet', { item: payload.item })
                commit('setItemsSpreadsheet', { items: items.data.data })
                commit('setDialogSpreadsheet', { show: true })
                commit('setTotalItemsDialog', items.data.total)
            });
        },
        showDialogTotals: ({ state, commit,rootGetters }) => {
            let url = endPoints.players_balance + "/totals?";
            let filter = state.filter;
            let period = rootGetters['periods_players_balance/getPeriodSelected']
            if (period == 0 || period == null || period == undefined || period == "") {
                period = location.href.split('players_balance/')[1]
            }
            filter.period = period
            Object.keys(filter).forEach(function (field) {
                if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
                    url += "&" + field + "=" + filter[field]
                }
            });

            axios.get(url).then(response => {
                commit('setDialogTotals', { show: true })
                commit('setChipsTotal', response.data)
            });
        },
        showDeleteDialog: ({ commit }, item) => {
            commit('setDialogDelete', true)
            commit('setItemToDelete', item)
        },
        save: ({ dispatch, commit, state }) => {
            var url = endPoints.period_closure;
            let func = '';
            if (state.mode == 'ADD') {
                func = axios.post;
                url = endPoints.period_closure;
            } else {
                func = axios.put;
                url += '/' + state.editedItem.id;
            }

            func(url, state.editedItem).then(
                () => {
                    dispatch('getItems')
                    let notification = {
                        show: true,
                        message: "Periodo de Fechamento salvo com sucesso.",
                        type: "success"
                    }
                    commit('closeDialog')
                    commit('showNotification', notification)
                },
                error => {
                    let notification = {
                        show: true,
                        message: error.response.data.message,
                        type: "error"
                    }
                    commit('showNotification', notification)
                }
            );
        },
    }
}