<template>
  <div>
    <v-dialog :value="showDialog" persistent width="1200">
      <v-card>
        <v-card-title>
          <span class="headline">Solicitação Financeira</span>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    label="Data de Criação *"
                    v-model="date_created"
                    :disabled="true"
                    :readonly="true"
                    :error-messages="errors.collect('date_created')"
                    data-vv-name="date_created"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-select
                    required
                    label="Empresa"
                    clear-icon="mdi-close"
                    v-model="id_company"
                    :items="companies"
                    :clearable="['ADD', 'EDIT'].includes(this.mode)"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('id_company')"
                    v-validate="'required: id_company'"
                    item-text="name"
                    item-value="id"
                    data-vv-name="id_company"
                    outlined
                  ></v-select>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-select
                    label="Tipo de Solicitação"
                    v-model="type_solicitation"
                    :items="possible_solicitation_types"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('type_solicitation')"
                    data-vv-name="type_solicitation"
                    item-text="name"
                    item-value="id"
                    outlined
                  ></v-select>
                </v-flex>

                <v-flex xs12 sm12 md12>
                  <v-text-field
                    label="Solicitante"
                    v-model="user_name"
                    v-show="['VIEW', 'EDIT'].includes(this.mode)"
                    :readonly="true"
                    :error-messages="errors.collect('user_name')"
                    data-vv-name="user_name"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm12 md12>
                  <v-text-field
                    required
                    label="Descrição *"
                    v-model="description"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('description')"
                    counter
                    maxlength="100"
                    hint="Insira no máximo 100 caracteres"
                    v-validate="'required:Descrição,description'"
                    data-vv-name="description"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex v-if="type_solicitation != 1" xs12 sm12 md12>
                  <v-text-field
                    label="Link"
                    v-model="link"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('link')"
                    data-vv-name="link"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex v-if="type_solicitation != 0" xs12 sm6 md6>
                  <v-text-field
                    :required="type_solicitation != 0"
                    label="Valor *"
                    v-model="value"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('value')"
                    v-validate="'required:Valor,value'"
                    data-vv-name="value"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex v-if="type_solicitation != 0" xs12 sm6 md6>
                  <v-text-field
                    label="Quantidade"
                    v-model="quantity"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('quantity')"
                    v-validate="'required:Quantidade,quantity|numeric'"
                    data-vv-name="quantity"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex v-if="type_solicitation != 0" xs12 sm6 md6>
                  <v-text-field
                    :required="type_solicitation != 0"
                    label="Nome do PIX *"
                    v-model="pix_name"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('pix_name')"
                    data-vv-name="pix_name"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex v-if="type_solicitation != 0" xs12 sm6 md6>
                  <v-select
                    :required="type_solicitation != 0"
                    label="Tipo PIX"
                    clear-icon="mdi-close"
                    v-model="pix_type"
                    :items="possible_pix_types"
                    :clearable="['ADD', 'EDIT'].includes(this.mode)"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('pix_type')"
                    v-validate="'required:Tipo PIX,pix_type'"
                    data-vv-name="pix_type"
                    item-text="name"
                    item-value="pix_type"
                    outlined
                  ></v-select>
                </v-flex>

                <v-flex v-if="type_solicitation != 0" xs12 sm12 md12>
                  <v-text-field
                    :required="type_solicitation != 0"
                    label="Chave PIX *"
                    v-model="pix_key"
                    v-show="['ADD', 'EDIT', 'VIEW'].includes(this.mode)"
                    v-mask="dynamicMask(pix_type)"
                    v-validate="'required:Chave PIX,pix_key'"
                    data-vv-name="pix_key"
                    outlined
                  ></v-text-field>
                </v-flex>
                
                <v-flex xs12 sm12 md12>
                  <v-file-input
                    v-model="receipt_file"
                    v-show="['retiradoPARAtestes'].includes(this.mode)"
                    label="Comprovante"
                    :error-messages="errors.collect('receipt')"
                    data-vv-name="receipt"
                    outlined
                  ></v-file-input>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-col>
            <v-btn text @click="setShowDialog(false)">{{
              mode == "VIEW" ? "Voltar" : "Cancelar"
            }}</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn
              text
              type="submit"
              right
              v-if="['ADD', 'EDIT'].includes(mode)"
              @click="saveFinancialSolicitation()"
              >Salvar</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import VueMask from "v-mask";
Vue.use(VueMask);

export default {
  name: "FinancialsolicitationsForm",
  methods: {
    ...mapMutations("financial_solicitations", [
      "setShowDialogExpense",
      "setSelectedItem",
      "setShowDialog",
    ]),
    ...mapActions("financial_solicitations", ["save"]),
    clearCompany() {
      this.id_company = "";
    },
    getPixType(pix_type) {
      return this.possible_pix_types[pix_type];
    },
    dynamicMask(tipoPix) {
      if (this.mode == "ADD") {
        switch (tipoPix) {
          case 0:
            if (this.pix_key.length > 14) {
              return "##.###.###/####-##";
            } else {
              return "###.###.###-##";
            }
          case 1:
            return "+55 (##) #####-####";
          default:
            return false;
        }
      }
    },
    addDropFile(e) {
      if (["ADD", "EDIT"].includes(this.mode)) {
        if (e.dataTransfer) {
          if (e.dataTransfer.files[0]) {
            this.receipt_file = e.dataTransfer.files[0];
          }
        }
      }
    },
    saveFinancialSolicitation() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.save();
        }
      });
    },
  },
  computed: {
    ...mapState("login", ["userData"]),
    ...mapState("financial_solicitations", [
      "companies",
      "mode",
      "selectedItem",
      "showDialog",
      "dialogReceipt",
      "possible_pix_types",
      "possible_solicitation_types",
    ]),
    ...mapFields("financial_solicitations", [
      "selectedItem.id",
      "selectedItem.id_company",
      "selectedItem.description",
      "selectedItem.quantity",
      "selectedItem.link",
      "selectedItem.receipt",
      "selectedItem.receipt_file",
      "selectedItem.value",
      "selectedItem.status",
      "selectedItem.type_solicitation",
      "selectedItem.pix_key",
      "selectedItem.pix_type",
      "selectedItem.pix_name",
      "selectedItem.date_created",
      "selectedItem.user_name",
      "selectedItem.user_id",
      "selectedItem.user_judge",
    ]),
  },
  data: () => ({
    valid: false,
  }),
};
</script>