// admin
import Users from '@components/users/Users'
import Profiles from '@components/profile/Profiles'
import Profile from '@components/profile/Profile'
import Routes from '@components/route/Routes'
import Menus from '@components/menus/Menus'

// chat
import AutomaticMessages from '@components/attendance/AutomaticMessages/AutomaticMessages'
import Avaliations from '@components/attendance/Avaliations/Avaliations'
import Chat from '@components/attendance/Chat/Chat'
import Instances from '@components/instances/Instances'
import ManageAnnotated from '@components/attendance/ManageAnnotated/ManageAnnotated'
import ManageAttendance from '@components/attendance/ManageAttendance/ManageAttendance'
import StatusInstance from '@components/attendance/Chat/StatusInstance'
import MassMessages from '@components/attendance/Chat/MassMessages'
import Bonus from '@components/attendance/Bonus/Bonus'
import Contacts from '@components/attendance/Contacts/Contacts'
import Occurrences from '@components/attendance/Occurrences/Occurrences'

// commercial
import Rakeback from '@components/commercial/rakeback/Rakeback'
import Agent from '@components/commercial/agents/Agent'
import AgentsTree from '@components/commercial/agents_tree/AgentsTree'
import Client from '@components/commercial/clients/client/Client'
import Clients from '@components/commercial/clients/Clients'
import Agents from '@components/commercial/agents/Agents'
import ReportAgents from '@components/commercial/agents/report/ReportAgents'
import AgentFolder from '@components/commercial/agents_folders/AgentFolder'
import AgentsFolders from '@components/commercial/agents_folders/AgentsFolders'
import Manager from '@components/commercial/managers/Manager'
import Managers from '@components/commercial/managers/Managers'

// financial
import AutoAttendance from '../components/financial/auto_attendance/AutoAttendance'
import Annotateds from '../components/financial/annotateds/Annotateds'
import Extract from '../components/financial/extract/Extract'
import Balance from '../components/financial/balance/Balance'
import Bank from '../components/financial/Bank'
import BanksBacen from '../components/financial/banks_bacen/BanksBacen'
import Billings from '../components/financial/billings/Billings'
import BillsToReceive from '../components/financial/bills/bills_to_receive/BillsToReceive'
import BillsToPay from '../components/financial/bills/bills_to_pay/BillsToPay'
import Bots from '../components/financial/bots/Bots'
import ChipsTransfer from '../components/financial/chips_transfer/ChipsTransfer'
import CostCenter from '../components/financial/cost_center/CostCenter'
import Deposits from '../components/financial/deposit/Deposits'
import Expense from '../components/financial/expense/Expense'
import ExpenseRecurrent from '../components/financial/expense_recurrent/ExpenseRecurrent'
import ExpenseTypes from '../components/expense_types/ExpenseTypes'
import Fail from '../components/financial/fail/Fail'
import FinancialChecks from '../components/financial/financial_checks/FinancialChecks'
import FinancialConciliation from '../components/financial/financial_conciliation/FinancialConciliation'
import FinancialMoviments from '../components/financial/balance/financial_moviments/FinancialMoviments'
import FinancialSolicitations from '../components/financial/financial_solicitations/FinancialSolicitations'
import GainsLoses from '../components/financial/gain_loses/GainsLoses'
import ParametersAgentClosure from '../components/financial/weekly_closure/parameters_agent_closure/ParametersAgentClosure'
import PeriodsClosure from '../components/financial/weekly_closure/PeriodsClosure'
import PaymentsAnnotateds from '../components/financial/payments_annotateds/PaymentsAnnotateds'
import PeriodConciliation from '@components/financial/conciliation/period_conciliation/PeriodConciliation'
import PeriodConciliationDetail from '@components/financial/conciliation/period_conciliation/PeriodConciliationDetail'
import Pix from '../components/financial/pix/Pix'
import PlayersBalance from '../components/financial/players_balance/PlayersBalance'
import PeriodsClosureTransactions from '../components/financial/weekly_closure/periods_closure_transactions/PeriodsClosureTransactions'
import LeagueClosure from '../components/financial/league_closure/LeagueClosure'
import LeagueClosureClub from '../components/financial/league_closure/league_closure_club/LeagueClosureClub'
import LeagueClosureClubDetail from '../components/financial/league_closure/league_closure_club/LeagueClosureClubDetail'
import PeriodsPlayersBalance from '../components/financial/players_balance/periods_players_balance/PeriodsPlayersBalance'
import PublishStatus from '../components/marketing/publish_status/PublishStatus'
import Receipts from '../components/financial/receipts/Receipts'
import SlotsRevenue from '../components/financial/slots_revenue/SlotsRevenue'
import VipSales from '../components/financial/vip_sales/VipSales'
import WeeklyRevenue from '../components/financial/weekly_revenue/WeeklyRevenue'
import Withdrawals from '../components/financial/withdrawal/Withdrawals'
import WithdrawalsBlacklist from '../components/financial/withdrawals_blacklist/WithdrawalsBlacklist'

// store
import Products from '@components/store/products/Products'
import Stock from '@components/store/stock/Stock'
import Sales from '@components/store/sales/Sales'

// Human Resources
import Emails from '@components/human_resources/emails/Emails'

// Internal
import Absences from '@components/internal/absences/Absences'
import Candidates from '@components/internal/jobs/Candidates.vue'
import Collaborator from '@components/internal/collaborators/Collaborator'
import Collaborators from '@components/internal/collaborators/Collaborators'
import Companies from '@components/internal/companies/Companies'
import Equipments from '@components/internal/equipments/Equipments'
import Jobs from '@components/internal/jobs/Jobs.vue'
import League from '@components/internal/league/League'
import Meetings from '@components/internal/meetings/Meetings'
import Requests from '@components/internal/requests/Requests.vue'
import Releases from '@components/internal/releases/Releases.vue'
import ProfileEdit from '@components/internal/profile_edit/ProfileEdit.vue'
import SystemServices from '@components/internal/system_services/SystemServices.vue'
import Slots from '@components/internal/slots/Slots'
import Tomticket from '@components/internal/tomticket/TomTicket'

// Marketing
import AppNotifications from '@components/marketing/app_notifications/AppNotifications'

// Outros   
import AgentsClosureDetail from '../components/financial/weekly_closure/agents_period_closure/AgentsPeriodClosureDetail'
import HomeComponent from '../components/HomeComponent.vue'
import Login from '../components/login/Login'
import PlayersDelete from '../components/players/PlayersDelete'
import Players from '../components/players/Players'
import Player from '../components/players/Player'
import PlayerHistory from '../components/players/player_history/PlayerHistory'
import AgentsClosure from '../components/financial/weekly_closure/agents_period_closure/AgentsPeriodClosure'
import AgentsClosureDates from '../components/financial/weekly_closure/agents_closure_dates/AgentsClosureDates'
import ClubClosure from '../components/financial/weekly_closure/club_closure/ClubClosure'
import BIAdm from '../components/bi/bi_adm/BIAdm'
import BIFinanc from '../components/bi/bi_financ/BIFinanc'
import BIAttendance from '../components/bi/bi_attendance/BIAttendance'
import BICommercial from '../components/bi/bi_commercial/BICommercial'
import BIAnnotated from '../components/bi/bi_annotated/BiAnnotated.vue'

import PageLoader from '@components/PageLoader'
import PlayerApproval from '../components/internal/player_approval/PlayerApproval.vue'
import ConfigClubs from '../components/custom_clubs/ConfigClubs.vue'
import NotificationsSchedule from '../components/marketing/notifications_schedule/NotificationsSchedule.vue'

const routes = [
    {
        name: 'home',
        path: '/',
        component: HomeComponent,
        meta: {
            requiresAuth: true,
            roles: [0, 1, 2, 70, 80, 97, 98, 99]
        }
    },
    {
        name: 'managers',
        path: '/managers',
        component: Managers,
        meta: {
            requiresAuth: true,
            roles: [2, 97, 99]
        }
    },
    {
        name: 'manager',
        path: '/managers/:manager_id',
        component: Manager,
        meta: {
            requiresAuth: true,
            roles: [2, 97, 99]
        }
    },
    {
        name: 'chat',
        path: '/chat',
        component: Chat,
        meta: {
            requiresAuth: true,
            roles: [0, 1, 2, 70, 80, 97, 98, 99]
        }
    },
    {
        name: 'contacts',
        path: '/contacts',
        component: Contacts,
        meta: {
            requiresAuth: true,
        }
    },
    {
        name: 'bonus',
        path: '/bonus',
        component: Bonus,
        meta: {
            requiresAuth: true,
            roles: [0, 1, 2, 70, 80, 97, 98, 99]
        }
    },
    {
        name: 'login',
        path: '/login',
        component: Login
    },
    {
        name: 'loginsx',
        path: '/loginsx',
        component: PageLoader
    },
    {
        name: 'chatid',
        path: '/chat/:contact_id',
        component: Chat,
        meta: {
            requiresAuth: true,
            roles: [0, 1, 2, 70, 80, 97, 98, 99]
        }
    },
    {
        name: 'emails',
        path: '/emails',
        component: Emails,
        meta: {
            requiresAuth: true,
            roles: [2, 97, 99]
        }
    },
    {
        name: 'expense',
        path: '/expense',
        component: Expense,
        meta: {
            requiresAuth: true,
            roles: [2, 97, 99]
        }
    },
    {
        name: 'chips_transfer',
        path: '/chips_transfer',
        component: ChipsTransfer,
        meta: {
            requiresAuth: true,
            roles: [2, 97, 99]
        }
    },
    {
        name: 'expense_recurrent',
        path: '/expense_recurrent',
        component: ExpenseRecurrent,
        meta: {
            requiresAuth: true,
            roles: [2, 97, 99]
        }
    },
    {
        name: 'fail',
        path: '/fail',
        component: Fail,
        meta: {
            requiresAuth: true,
            roles: [2, 97, 99]
        }
    },
    {
        name: 'bank',
        path: '/bank',
        component: Bank,
        meta: {
            requiresAuth: true,
            roles: [2, 80, 97, 99]
        }
    },
    {
        name: 'withdrawal',
        path: '/withdrawal',
        component: Withdrawals,
        meta: {
            requiresAuth: true,
            roles: [0, 1, 2, 70, 80, 97, 98, 99]
        }
    },
    {
        name: 'weekly_revenue',
        path: '/weekly_revenue',
        component: WeeklyRevenue,
        meta: {
            requiresAuth: true,
            roles: [0, 1, 2, 70, 80, 97, 98, 99]
        }
    },
    {
        name: 'slots_revenue',
        path: '/slots_revenue/:weekly_revenue_id',
        component: SlotsRevenue,
        meta: {
            requiresAuth: true,
            disableProfleValidation: true
        }
    },
    {
        name: 'deposits',
        path: '/deposit',
        component: Deposits,
        meta: {
            requiresAuth: true,
            roles: [0, 1, 2, 70, 80, 97, 98, 99]
        }
    },
    {
        name: 'user',
        path: '/users',
        component: Users,
        meta: {
            requiresAuth: true,
            roles: [96, 97, 98, 99]
        }
    },
    {
        name: 'menus',
        path: '/menus',
        component: Menus,
        meta: {
            requiresAuth: true,
            roles: [96, 97, 98, 99]
        }
    },
    {
        name: 'profiles',
        path: '/profiles',
        component: Profiles,
        meta: {
            requiresAuth: true,
            roles: [96, 97, 98, 99]
        }
    },
    {
        name: 'profile_by_id',
        path: '/profiles/:profile_id',
        component: Profile,
        meta: {
            requiresAuth: true,
            roles: [2, 3, 96, 97, 98, 99]
        }
    },
    {
        name: 'routes',
        path: '/routes',
        component: Routes,
        meta: {
            requiresAuth: true,
            roles: [96, 97, 98, 99]
        }
    },
    {
        name: 'qrcode',
        path: '/qrcode',
        component: StatusInstance,
        meta: {
            requiresAuth: true,
            roles: [0, 1, 70, 80, 98, 99]
        }
    },
    {
        name: 'instance',
        path: '/instances',
        component: Instances,
        meta: {
            requiresAuth: true,
            roles: [80, 96, 98, 99]
        }
    },
    {
        name: 'manage_attendance',
        path: '/manage_attendance',
        component: ManageAttendance,
        meta: {
            requiresAuth: true,
            roles: [80, 98, 99]
        }
    },
    {
        name: 'rakeback',
        path: '/rakeback',
        component: Rakeback,
        meta: {
            requiresAuth: true,
            roles: [2, 3, 96, 97, 98, 99]
        }
    },
    {
        name: 'agents',
        path: '/agents',
        component: Agents,
        meta: {
            requiresAuth: true,
            roles: [2, 3, 96, 97, 98, 99]
        }
    },
    {
        name: 'agent',
        path: '/agents/:agent_id',
        component: Agent,
        meta: {
            requiresAuth: true,
            roles: [2, 3, 96, 97, 98, 99]
        }
    },
    {
        name: 'agents_folders',
        path: '/agents_folders',
        component: AgentsFolders,
        meta: {
            requiresAuth: true,
            roles: [2, 3, 96, 97, 98, 99]
        }
    },
    {
        name: 'agents_folder',
        path: '/agents_folders/:folder_id',
        component: AgentFolder,
        meta: {
            requiresAuth: true,
            roles: [2, 3, 96, 97, 98, 99]
        }
    },
    {
        name: 'clients',
        path: '/clients',
        component: Clients,
    },
    {
        name: 'client',
        path: '/clients/:client_id',
        component: Client,
    },
    {
        name: 'manage_annotated',
        path: '/manage_annotated',
        component: ManageAnnotated,
        meta: {
            requiresAuth: true,
            roles: [96, 97, 98, 99]
        }
    },
    {
        name: 'automatic_messages',
        path: '/automatic_messages',
        component: AutomaticMessages,
        meta: {
            requiresAuth: true,
            roles: [98, 99]
        }
    },
    {
        name: 'balance',
        path: '/balance',
        component: Balance,
        meta: {
            requiresAuth: true,
            roles: [97, 99]
        }
    },
    {
        name: 'financial_moviments',
        path: '/financial_moviments',
        component: FinancialMoviments,
        meta: {
            requiresAuth: true,
            roles: [97, 99]
        }
    },
    {
        name: 'player',
        path: '/player',
        component: Players,
        meta: {
            requiresAuth: true,
            roles: [0, 1, 2, 3, 96, 97, 98, 99]
        }
    },
    {
        name: 'players_delete',
        path: '/players_delete',
        component: PlayersDelete,
        meta: {
            requiresAuth: true,
            roles: [0, 1, 2, 3, 96, 97, 98, 99]
        }
    },
    {
        name: 'player_by_id',
        path: '/players/:player_id',
        component: Player,
        meta: {
            requiresAuth: true,
            roles: [2, 3, 96, 97, 98, 99]
        }
    },
    {
        name: 'gains_loses',
        path: '/gains_loses',
        component: GainsLoses,
        meta: {
            requiresAuth: true,
            roles: [80, 96, 97, 98, 99]
        }
    },
    {
        name: 'stock',
        path: '/stock',
        component: Stock,
        meta: {
            requiresAuth: true,
            roles: [98, 99]
        }
    },
    {
        name: 'sales',
        path: '/sales',
        component: Sales,
        meta: {
            requiresAuth: true,
            roles: [98, 99]
        }
    },
    {
        name: "publish_status",
        path: '/publish_status',
        component: PublishStatus,
        meta: {
            requiresAuth: true,
            roles: [98, 99]
        }
    },
    {
        name: 'financial_checks',
        path: '/financial_checks',
        component: FinancialChecks,
        meta: {
            requiresAuth: true,
            roles: [97, 99]
        }
    },
    {
        name: 'financial_conciliation',
        path: '/financial_conciliation',
        component: FinancialConciliation,
        meta: {
            requiresAuth: true,
            roles: [97, 99]
        }
    },
    {
        name: 'products',
        path: '/products',
        component: Products,
        meta: {
            requiresAuth: true,
            roles: [98, 99]
        }
    },
    {
        name: 'periods_closure',
        path: '/periods_closure',
        component: PeriodsClosure,
        meta: {
            requiresAuth: true,
            roles: [98, 99]
        }
    },
    {
        name: 'agents_closure',
        path: '/agents_closure/:period_closure_id',
        component: AgentsClosure,
        meta: {
            requiresAuth: true,
            disableProfleValidation: true
        }
    },
    {
        name: 'agents_closure_dates',
        path: '/agents_closure/dates/:period_closure_dates',
        component: AgentsClosureDates,
        meta: {
            requiresAuth: true,
            disableProfleValidation: true
        }
    },
    {
        name: 'agents_closure_period',
        path: '/agents_closure/:period_id/:closure_id',
        component: AgentsClosureDetail,
        meta: {
            requiresAuth: true,
            disableProfleValidation: true
        }
    },
    {
        name: 'bi_adm',
        path: '/bi_adm',
        component: BIAdm,
        meta: {
            requiresAuth: true,
            disableProfleValidation: true
        }
    },
    {
        name: 'bi_financ',
        path: '/bi_financ',
        component: BIFinanc,
        meta: {
            requiresAuth: true,
            disableProfleValidation: true
        }
    },
    {
        name: 'bi_attendance',
        path: '/bi_attendance',
        component: BIAttendance,
        meta: {
            requiresAuth: true,
            disableProfleValidation: true
        }
    },
    {
        name: 'bi_commercial',
        path: '/bi_commercial',
        component: BICommercial,
        meta: {
            requiresAuth: true,
            disableProfleValidation: true
        }
    },
    {
        name: 'bi_annotated',
        path: '/bi_annotated',
        component: BIAnnotated,
        meta: {
            requiresAuth: true,
            disableProfleValidation: true
        }
    },
    {
        name: 'club_closure',
        path: '/club_closure',
        component: ClubClosure,
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'report_agents',
        path: '/report_agents',
        component: ReportAgents,
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'expense_types',
        path: '/expense_types',
        component: ExpenseTypes,
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'requests',
        path: '/requests',
        component: Requests,
        meta: {
            requiresAuth: true,
            roles: [0, 1, 2, 3, 6, 7, 70, 80, 81, 94, 96, 97, 98, 99]
        }
    },
    {
        name: 'pix',
        path: '/pix',
        component: Pix,
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'releases',
        path: '/releases',
        component: Releases,
        meta: {
            requiresAuth: true,
            roles: [0, 1, 2, 3, 6, 7, 70, 80, 81, 94, 96, 97, 98, 99]
        }
    },
    {
        name: 'receipts',
        path: '/receipts',
        component: Receipts,
        meta: {
            requiresAuth: true,
            roles: [2, 97, 99]
        }
    },
    {
        //----------------------------------------------------------------
        name: 'vip_sales',
        path: '/vip_sales',
        component: VipSales,
        meta: {
            requiresAuth: true,
            //roles: [2, 97, 99]
        }
    },
    {
        name: 'transactions',
        path: '/transactions',
        component: PeriodsClosureTransactions,
        meta: {
            requiresAuth: true,
            roles: [2, 5, 81, 87, 99]
        }
    },
    {
        name: 'agents_tree',
        path: '/agents_tree',
        component: AgentsTree,
        meta: {
            requiresAuth: true,
            roles: [2, 5, 81, 87, 99]
        }
    },
    {
        name: 'league',
        path: '/league',
        component: League,
        meta: {
            requiresAuth: true,
            roles: [2, 97, 99]
        }
    },
    {
        name: 'companies',
        path: '/companies',
        component: Companies,
        meta: {
            requiresAuth: true,
            roles: [2, 97, 99]
        }
    },
    {
        name: 'collaborators',
        path: '/collaborators',
        component: Collaborators,
        meta: {
            requiresAuth: true,
            roles: [2, 97, 99]
        }
    },
    {
        name: 'equipments',
        path: '/equipments',
        component: Equipments,
        meta: {
            requiresAuth: true,
            roles: [2, 97, 99]
        }
    },
    {
        name: 'collaborator',
        path: '/collaborators/:collaborator_id',
        component: Collaborator,
        meta: {
            requiresAuth: true,
            roles: [2, 3, 96, 97, 98, 99]
        }
    },
    {
        name: "system_services",
        path: "/system_services",
        component: SystemServices,
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'bots',
        path: '/bots',
        component: Bots,
        meta: {
            requiresAuth: true,
            roles: [2, 97, 99]
        }
    },
    {
        name: 'financial_solicitations',
        path: '/financial_solicitations/',
        component: FinancialSolicitations,
        meta: {
            requiresAuth: true,
        }
    },
    {
        name: 'mass_messages',
        path: '/mass_messages',
        component: MassMessages,
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'players_balance',
        path: '/players_balance/:id_period',
        component: PlayersBalance,
        // meta: {
        //     requiresAuth: true
        // }
    },
    {
        name: 'periods_players_balance',
        path: '/periods_players_balance',
        component: PeriodsPlayersBalance,
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'league_closure',
        path: '/league_closure',
        component: LeagueClosure,
        meta: {
            requiresAuth: true,
            roles: [2, 3, 96, 97, 98, 99]
        }
    },
    {
        name: 'league_closure_club',
        path: '/league_closure/club/:club_id',
        component: LeagueClosureClub,
        meta: {
            requiresAuth: true,
            disableProfleValidation: true
        }
    },
    {
        name: 'league_closure_club_detail',
        path: '/league_closure/club/detail/:club_id',
        component: LeagueClosureClubDetail,
        meta: {
            requiresAuth: true,
            disableProfleValidation: true
        }
    },
    {
        name: 'meetings',
        path: '/meetings',
        component: Meetings,
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'profile_edit',
        path: '/profile_edit',
        component: ProfileEdit,
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'banks_bacen',
        path: '/banks_bacen',
        component: BanksBacen,
        meta: {
            requiresAuth: true,
            roles: [2, 97, 99]
        }
    },
    {
        name: 'extract',
        path: '/extract',
        component: Extract,
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'cost_center',
        path: '/cost_center',
        component: CostCenter,
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'parameters_agent_closure',
        path: '/parameters_agent_closure',
        component: ParametersAgentClosure,
        meta: {
            requiresAuth: true,
            roles: [96, 97, 98, 99]
        }
    },
    {
        name: 'avaliations',
        path: '/avaliations',
        component: Avaliations
    },
    {
        name: 'slots',
        path: '/slots',
        component: Slots
    },
    {
        name: 'absences',
        path: '/absences',
        component: Absences
    },
    {
        name: 'player_history',
        path: '/player_history',
        component: PlayerHistory
    },
    {
        name: 'candidates',
        path: '/candidates/:id_job',
        component: Candidates
    },
    {
        name: 'jobs',
        path: '/jobs',
        component: Jobs,
    },
    {
        name: 'tomticket',
        path: '/tomticket',
        component: Tomticket
    },
    {
        name: 'occurrences',
        path: '/occurrences',
        component: Occurrences
    },
    {
        name: 'period_conciliation',
        path: "/period_conciliation",
        component: PeriodConciliation
    },
    {
        name: 'period_conciliation_detail',
        path: "/period_conciliation/:id",
        component: PeriodConciliationDetail
    },
    {
        name: 'billings',
        path: '/billings',
        component: Billings,
    },
    {
        name: 'withdrawals_blacklist',
        path: '/withdrawals_blacklist',
        component: WithdrawalsBlacklist,
        meta: {
            requiresAuth: true,
            roles: [0, 1, 2, 70, 80, 97, 98, 99]
        }
    },
    {
        name: 'annotateds',
        path: '/annotateds',
        component: Annotateds,
    },
    {
        name: 'bills_to_receive',
        path: '/bills_to_receive',
        component: BillsToReceive,
    },
    {
        name: 'bills_to_pay',
        path: '/bills_to_pay',
        component: BillsToPay,
    },
    {
        name: 'payments_annotateds',
        path: '/payments_annotateds',
        component: PaymentsAnnotateds,
    },
    {
        name: 'player_approval',
        path: '/player_approval',
        component: PlayerApproval,
    },
    {
        name: 'auto_attendance',
        path: '/auto_attendance',
        component: AutoAttendance,
        meta: {
            requiresAuth: true,
            roles: [2, 97, 99]
        }
    },
    {
        name: 'styles',
        path: '/styles',
        component: ConfigClubs,
        meta: {
            requiresAuth: true,
            roles: [4, 94, 99]
        }
    },
    {
        name: 'app_notifications',
        path: '/app_notifications',
        component: AppNotifications,
        meta: {
            requiresAuth: true,
        }
    },
    {
        name: 'notifications_schedule',
        path: '/notifications_schedule',
        component: NotificationsSchedule,
        meta: {
            requiresAuth: true,
            roles: [4, 94, 98, 99]
        }
    }
];

export default routes;