<template>
    <div>
        <v-card>
            <v-card-title>
                <span class="headline">QrCode Pagamento de Anotado</span>
            </v-card-title>

            <v-card-text>
                <v-form ref="form" lazy-validation>
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12 sm6 md6>
                                <v-text-field
                                    label="Contact Id"
                                    v-model="contact_id"
                                    readonly
                                    outlined
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-text-field
                                    label="Contact Name"
                                    v-model="contact_name"
                                    readonly
                                    outlined
                                ></v-text-field>
                            </v-flex>

                            <v-flex xs12 sm6 md6>
                                <v-text-field
                                    label="Valor"
                                    prefix="R$"
                                    v-model="value"
                                    readonly
                                    outlined
                                ></v-text-field>
                            </v-flex>

                            <v-flex xs12 sm6 md6>
                                <v-text-field
                                    readonly
                                    label="Banco"
                                    v-model="bank_name"
                                    outlined
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-datetime-picker
                                    v-model="date_created"
                                    label="Data de Criação"
                                    date-format="dd/MM/yyyy"
                                    disabled
                                    :time-picker-props="timeProps"
                                    :textFieldProps="{ outlined: true }"
                                >
                                    <template slot="dateIcon">
                                        <v-icon>mdi-calendar</v-icon>
                                    </template>
                                    <template slot="timeIcon">
                                        <v-icon>mdi-clock</v-icon>
                                    </template>
                                </v-datetime-picker>
                            </v-flex>
                            <v-flex xs12 sm6 md6 v-if="date_payment">
                                <v-datetime-picker
                                    v-model="date_payment"
                                    label="Data de Pagamento"
                                    date-format="dd/MM/yyyy"
                                    disabled
                                    :time-picker-props="timeProps"
                                    :textFieldProps="{ outlined: true }"
                                >
                                    <template slot="dateIcon">
                                        <v-icon>mdi-calendar</v-icon>
                                    </template>
                                    <template slot="timeIcon">
                                        <v-icon>mdi-clock</v-icon>
                                    </template>
                                </v-datetime-picker>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-col>
                    <v-btn text @click="closeDialog">{{
                        mode == 'VIEW' ? 'Voltar' : 'Cancelar'
                    }}</v-btn>
                </v-col>
                <v-col class="text-right">
                    <v-btn
                        text
                        type="submit"
                        right
                        v-if="mode == 'EDIT' || mode == 'ADD'"
                        @click="saveAnnotated"
                        >Salvar</v-btn
                    >
                </v-col>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'
// import { mapGetters } from 'vuex'

export default {
    name: 'PaymentsAnnotatedsForm',

    computed: {
        ...mapState('payments_annotateds', [
            'selectedItem',
            'showDialog',
        ]),
        ...mapFields('payments_annotateds', [
            'selectedItem.id',
            'selectedItem.bank_name',
            'selectedItem.contact_id',
            'selectedItem.contact_name',
            'selectedItem.date_payment',
            'selectedItem.value',
            'selectedItem.id_transaction_bank',
            'selectedItem.date_created',
            'selectedItem.status',
        ]),
    },
    methods: {
        formatDate(date) {
            let d = new Date(date)
            let day = d.getDate()
            let month = d.getMonth() + 1
            let year = d.getFullYear()
            if (day < 10) {
                day = '0' + day
            }
            if (month < 10) {
                month = '0' + month
            }
            return year + '-' + month + '-' + day + ' ' + d.toLocaleTimeString()
        },
        getDate(date) {
            var d = new Date(date)
            return d.toLocaleDateString() + ' ' + d.toLocaleTimeString()
        },
        ...mapMutations('payments_annotateds', [
            'setShowDialog',
            'setSelectedItem',
            'setShowDialog',
            
        ]),
        ...mapActions('payments_annotateds', ['closeDialog']),
    },
    data: () => ({
        timeProps: {
            format: '24hr',
        },
    }),
}
</script>
