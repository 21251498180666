<template>
  <div>
    <div @drop.prevent="addDropFile" @dragover.prevent>
      <v-card>
        <v-card-title>
          <span class="headline text-center">Bônus</span>
          <v-spacer></v-spacer>
          <v-progress-circular
            v-if="loading"
            indeterminate
            class="text-right"
            color="primary"
            size="24"
          ></v-progress-circular>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    :rules="[rules.required]"
                    label="ID *"
                    v-model="id_app"
                    :readonly="this.mode == 'VIEW' || this.mode == 'EDIT'"
                    data-vv-name="id_app"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    :rules="[rules.required]"
                    required
                    label="Contato *"
                    v-model="contact_id"
                    :readonly="this.mode == 'VIEW' || this.mode == 'EDIT'"
                    data-vv-name="contact_id"
                    outlined
                  >
                  </v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    :rules="[rules.required]"
                    required
                    label="CPF / CNPJ *"
                    v-model="inscription"
                    :readonly="this.mode == 'VIEW' || this.mode == 'EDIT'"
                    data-vv-name="inscription"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    :rules="[rules.required]"
                    required
                    type="number"
                    label="Valor *"
                    v-model="value"
                    :readonly="this.mode == 'VIEW' || this.mode == 'EDIT'"
                    data-vv-name="value"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    type="number"
                    label="ID Depósito *"
                    v-model="deposit_id"
                    :readonly="this.mode == 'VIEW' || this.mode == 'EDIT'"
                    data-vv-name="deposit_id"
                    outlined
                  ></v-text-field>
                </v-flex>
            
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    :rules="[rules.required]"
                    required
                    type="number"
                    label="ID Depósito Origem *"
                    v-model="deposit_id_origin"
                    :readonly="this.mode == 'VIEW' || this.mode == 'EDIT'"
                    data-vv-name="deposit_id_origin"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    type="number"
                    label="ID Saque *"
                    v-model="withdrawal_id"
                    :readonly="this.mode == 'VIEW' || this.mode == 'EDIT'"
                    data-vv-name="withdrawal_id"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    :rules="[rules.required]"
                    required
                    type="number"
                    label="Mãos Jogados *"
                    v-model="hands_played"
                    :readonly="this.mode == 'VIEW'"
                    data-vv-name="hands_played"
                    outlined
                  ></v-text-field>
                </v-flex>

              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-col>
            <v-btn text @click="closeDialog"> Fechar </v-btn>
          </v-col>
          <v-col class="text-right" v-if="mode != 'VIEW'">
            <v-btn text type="submit" right @click="saveBonus">Salvar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import debounce from '../../../util/debounce.js' 
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  data: () => ({
    valid: true,
    rules: {
      required(value) { 
        return !!value || 'Campo Obrigatório'
      },
    }
  }),
  name: "BonusForm",
  props: ["screen"],
  computed: {
    ...mapGetters("login", ["getInstanceId"]),
    ...mapState("bonus", [
      "menus",
      "bonu",
      "loading",
      "mode",
    ]),
    ...mapFields("bonus", [
      "dialogReceipt",
      "editedBonus.id_app",
      "editedBonus.value",
      "editedBonus.inscription",
      "editedBonus.contact_id",
      "editedBonus.hands_played",
      "editedBonus.deposit_id",
      "editedBonus.deposit_id_origin",
      "editedBonus.withdrawal_id",
      // ...
    ]),
  },
  mixins: [debounce],
  mounted() {
    this.getChildrenSearch = this.debounce(this.getChildrenSearch, 500); 
  },
  created() {
    this.setScreen({ screen: this.$props.screen })
    this.$validator.extend("required", {
      getMessage: (field, [label]) => label + " deve ser informado",
      validate: (_, [, field]) => {
        if (this[field] || this[field] === 0) {
          return true;
        } else {
          return false;
        }
      },
    });
  },
  methods: {
    ...mapActions("bonus", [
      "getChildrenSearch",
      "getAttendants",
      "getUsers",
      "getAgents",
      "closeDialog",
      "save",
    ]),
    ...mapMutations("bonus", ["setScreen"]),
    saveBonus() {
      let valid = this.$refs.form.validate()
      if (valid) {
        this.save();
      }
    },
  },
};
</script>