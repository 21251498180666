<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline text-center">Contato</span>
        <v-spacer></v-spacer>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md>
            <v-layout wrap>


              <v-flex xs12 sm6 md6>
                <v-text-field
                  label="Tipo"
                  :value="phone.length > 13 ? 'Grupo' : 'Contato'"
                  outlined
                  clearable
                  readonly
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-text-field
                  label="Contato"
                  v-model="contact_id2"
                  data-vv-name="contact_id2"
                  outlined
                  clearable
                  readonly
                ></v-text-field>
              </v-flex>
              
              <v-flex xs12 sm6 md6>
                <v-text-field
                  label="Nome"
                  v-model="name"
                  data-vv-name="name"
                  outlined
                  clearable
                  readonly
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-select
                  label="Instância"
                  :items="instances"
                  item-text="name"
                  item-value="id"
                  v-model="instance_id"
                  outlined
                  clearable
                  readonly
                ></v-select>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-select
                  label="Recebe Mensagem em Massa"
                  :items="possible_mass_message"
                  item-text="value"
                  item-value="id"
                  v-model="send_mass_message"
                  outlined
                ></v-select>
              </v-flex>

            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn text @click="closeDialog">Cancelar</v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn text type="submit" right @click="save">Salvar</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import debounce from "../../../util/debounce.js";

export default {
  name: "ContactsFilter",
  computed: {
    ...mapState("instances", ["instances"]),
    ...mapState("contacts", ["menus","possible_contacts"]),
    ...mapFields("contacts", [
      "editedItem.contact_id2",
      "editedItem.name",
      "editedItem.instance_id",
      "editedItem.phone",
      "editedItem.is_Group",
      "editedItem.send_mass_message"
      // ...
    ]),
  },  
  data: () => ({
    possible_mass_message: [
      {
        id: true,
        value: "Sim"
      },
      {
        id: false,
        value: "Não"
      }
    ]
  }),
  mixins: [debounce],
  created() {
    this.getInstances();
  },
  methods: {
    ...mapMutations("contacts", ["showFilter"]),
    ...mapActions("instances", ["getInstances"]),
    ...mapActions("contacts", ["getContacts", "closeDialog", "save"]),
    filter() {
      this.showFilter({ show: false });
      this.getContacts({ resetPage: true });
    },
  },
};
</script>

<style>
</style>