<template>
  <div>
    <div @drop.prevent="addDropFile" @dragover.prevent>
      <v-card>
        <v-card-title>
          <span class="headline text-center">Transferência de Fichas</span>
          <v-spacer></v-spacer>
          <v-progress-circular
            v-if="loading"
            indeterminate
            class="text-right"
            color="primary"
            size="24"
          ></v-progress-circular>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    label="Nome Suprema Poker *"
                    v-model="name_supreme"
                    data-vv-name="name_supreme"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    label="ID Suprema Poker *"
                    v-model="id_supreme"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('id_supreme')"
                    data-vv-name="id_supreme"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    type="number"
                    required
                    label="Fichas Disponíveis *"
                    v-model="chips"
                    :readonly="true"
                    :error-messages="errors.collect('chips')"
                    v-validate="'required:Fichas,chips|decimal:2'"
                    data-vv-name="chips"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-select
                    label="Status *"
                    required
                    :items="possible_status"
                    v-model="status"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('status')"
                    v-validate="'required:Status,status'"
                    data-vv-name="status"
                    outlined
                    item-text="text"
                    item-value="value"
                  >
                  </v-select>
                </v-flex>

              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-col>
            <v-btn text @click="closeDialogSupreme">{{ mode == 'VIEW' ? 'Voltar' : 'Cancelar'}}</v-btn>
          </v-col>
          <v-col class="text-right" v-if="mode != 'VIEW'">
            <v-btn text type="submit" right @click="saveChipsTransfer">Salvar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import debounce from '../../../util/debounce.js' 
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "RoutesForm",
  props: ["screen", "instances"],
  computed: {
    ...mapGetters("login", ["getInstanceId"]),
    ...mapState("chips_transfer", [
      "menus",
      "route",
      "loading",
      "mode",
    ]),
    ...mapState("instances", ["instances"]),
    ...mapFields("chips_transfer", [
      "dialogReceipt",
      "editedChipsTransfer.name_pppoker",
      "editedChipsTransfer.name_supreme",
      "editedChipsTransfer.id_pppoker",
      "editedChipsTransfer.id_supreme",
      "editedChipsTransfer.id_agent",
      "editedChipsTransfer.chips",
      "editedChipsTransfer.balance",
      "editedChipsTransfer.status",
      "editedChipsTransfer.club_destiny"
    ]),
    chipsFormatted() {
      return this.chips.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) 
    }
  },
  mixins: [debounce],
  mounted() {
    this.getChildrenSearch = this.debounce(this.getChildrenSearch, 500); 
  },
  created() {
    this.setScreen({ screen: this.$props.screen })
    this.getInstances()
    this.$validator.extend("required", {
      getMessage: (field, [label]) => label + " deve ser informado",
      validate: (_, [, field]) => {
        if (this[field] || this[field] === 0) {
          return true;
        } else {
          return false;
        }
      },
    });
  },
  methods: {
    ...mapActions("chips_transfer", [
      "getChildrenSearch",
      "getAttendants",
      "getUsers",
      "getAgents",
      "closeDialogSupreme",
      "save",
    ]),
    ...mapActions("instances", ["getInstances"]),
    ...mapMutations("chips_transfer", ["setScreen"]),
    saveChipsTransfer() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.save();
        }
      });
    },
  },
  data: () => ({
    possible_status: [
      {
        text: "Não concluído",
        value: 0,
      },
      {
        text: "Cadastrado",
        value: 1,
      },
      {
        text: "Concluído",
        value: 2,
      },
    ],
    searchMenu: '',
  }),
  watch: {
    searchMenu (val) {
      var payload = {
        search: val
      }
      this.getChildrenSearch(payload)
    }
  },
};
</script>