import axios from "axios";
import { getField, updateField } from "vuex-map-fields";
import endPoints from "@config/endPoints";
import formatDate from '../../util/formatDate.js'

const defaultFilter = {
  type_order: "",
  id_oper: "",
  bot_id: "",
  club: "",
  id_pppoker: "",
  status: "",
  id_instance: "",
};

const defaultItem = {
  item: 0,
  slot: "",
  player_id: "",
  type: 0,
};

export default {
  namespaced: true,
  state: {
    receipt: '',
    dialogPrint: false,
    supremeItems: [],
    players: [],
    dialogFilter: false,
    playerDeleteDialog: false,
    editedItem: {},
    filter: {
      type: "",
      player_id: "",
      slot: "",
      item: "",
      id: "",
      start: "",
      end: ""
    },
    items: [],
    instances: [],
    bots: [],
    filtered: false,
    loading: false,
    mode: "LIST",
    overlay: false,
    page: 1,
    pagination: {
      page: 1,
      itemsPerPage: 10,
    },
    successNotification: false,
    notificationMessage: "",
    notificationType: "success",
    showNotification: false,
    totalItems: 0,
    rowsPerPage: 10,
  },
  mutations: {
    loading: (state, payload) => (state.loading = payload.show),
    setMode: (state, mode) => (state.mode = mode),
    setItems: (state, items) => (state.items = items),
    setSupremeItems: (state, supremeItems) => (state.supremeItems = supremeItems),
    setBots: (state, bots) => (state.bots = bots),
    setTotalItems: (state, total) => (state.totalItems = total),
    setRowsPerPage: (state, rowsPerPage) => (state.rowsPerPage = rowsPerPage),
    showDialog: (state, show) => (state.dialog = show),
    setInstances: (state, payload) => state.instances = payload.instances,
    setFilter: (state, filter) => {
      state.filter = filter
    },
    setPlayers: (state, payload) => {
      state.players = payload.players
    },
    setFiltered: (state, filtered) => state.filtered = filtered,
    setPage: (state, payload) => {
      state.pagination.page = payload.page;
      state.pagination.itemsPerPage = payload.itemsPerPage;
    },
    showFilter: (state, payload) => (state.dialogFilter = payload.show),
    showPlayerDeleteDialog: (state, payload) => {
      state.playerDeleteDialog = payload.show
      state.editedItem = !payload.show ? {} : Object.assign({}, defaultItem)
      state.editedItem = payload.message ? { ...state.editedItem, message_id: payload.message.message_id, is_group: payload.message.is_group } : state.editedItem
    },
    setPrint: (state, payload) => {
      state.receipt = payload.print
      state.dialogPrint = true
    },
    showErrorNotification: (state, show) => (state.errorNotification = show),
    showSuccessNotification: (state, show) =>
      (state.successNotification = show),
    showLoading: (state, show) => (state.loading = show),
    showNotification: (state, payload) => {
      (state.showNotification = payload.show),
      (state.notificationMessage = payload.message),
      (state.notificationType = payload.type);
    },
    showOverlay: (state, payload) => {
      state.overlay = payload.show;
    },
    showDialogPrint(state, payload) {
      state.dialogPrint = payload.show
    },
    updateField
  },
  actions: {
    closeDialog: ({ commit }) => {
      commit("closeDialog", false);
    },
    getInstances: ({ commit }) => {
      let url = endPoints.instances;
      url += '?page=' + 1 + '&' + 'itemsPerPage=' + 50;
      axios.get(url).then(instances => {
          commit('setInstances', { instances: instances.data.data })
      });
    },
    clearFilter: ({ commit, dispatch }) => {
      commit('setFilter', Object.assign({}, defaultFilter))
      commit('showFilter', { show: false })
      commit('showOverlay', { show: true })

      setTimeout(function() {
          commit('showOverlay', { show: false })
          dispatch('getItems', { resetPage: true })
      }, 100);
    },
    getBots: ({ commit }) => {
      let url = endPoints.bots;
      axios.get(url).then((items) => {
        commit("setBots", items.data.data);
      });
    },
    activateBot: ({ dispatch }, payload) => {
      let url = endPoints.bots + "/" + payload.item.id;
      payload.item.is_active = payload.is_active;
      axios.put(url, payload.item).then(() => {
        dispatch("getBots");
      });
    },
    updateActionBot: ({ dispatch }, payload) => {
      let url = endPoints.bots + "/update/" + payload.item.id;
      payload.item.action_bot = payload.action_bot;
      axios.put(url, payload.item).then(() => {
        dispatch("getBots");
      });
    },
    getSearchIdPlayer: ({ commit }, payload) => {

      commit('loading_player', { loading: true })
      let url = endPoints.players + "/player_search/" + payload.search;

      axios.get(url).then((items) => {
        commit("setPlayers", { players: items.data });
      });
    },
    closePlayerDeleteDialog: ({ commit }) => {
      commit('showPlayerDeleteDialog', {show: false})
      commit('message/showDialogUtilityOrder', { show: false }, { root: true })
    },
    getItems: ({ commit, state }, payload) => {
      commit("loading", { show: true });
      let url = endPoints.utilities;

      let filter = state.filter;
      if (payload.resetPage === true) {
        url += "?page=" + 1 + "&" + "itemsPerPage=" + 10;
        commit("setPage", { page: 1, itemsPerPage: 10 });
      } else {
        url +=
          "?page=" +
          state.pagination.page +
          "&" +
          "itemsPerPage=" +
          state.pagination.itemsPerPage;
      }
      commit('setFiltered', false)
      Object.keys(filter).forEach(function(field) {
        if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
          commit('setFiltered', true)

          if (field == "end" || field == "start") {
            var e = new Date(filter[field])
            url += "&" + field + "=" + formatDate(e) + " " + e.toLocaleTimeString()
          } else {
              url += "&" + field + "=" + filter[field]
          }
        }
      });

      if (state.pagination && state.pagination.sortBy && state.pagination.sortBy.length > 0) {
        url += "&sort_by=" + state.pagination.sortBy[0]
        url += "&sort_desc=" + state.pagination.sortDesc[0]
    }

      axios.get(url).then((items) => {
        commit("setTotalItems", items.data.total);
        commit("loading", { show: false });
        commit("setItems", items.data.data);
      });
    },
    getSupremeItems: ({ commit }) => {
      let url = endPoints.items;

      axios.get(url).then((items) => {
        commit("setSupremeItems", items.data.data);
      });
    },
    save: ({ dispatch, commit, state }) => {
      var url = endPoints.utilities;
      let data = state.editedItem

      axios.post(url, data).then(
          () => {
              dispatch('getItems', { resetPage: true })
              let notification = {
                  show: true,
                  message: "Ordem criada com sucesso",
                  type: "success"
              }
              commit('loading', { show: false })
              commit('showOverlay', { show: false })
              commit('showNotification', notification)
              commit('showPlayerDeleteDialog', {show: false})
              commit('message/showDialogUtilityOrder', { show: false }, { root: true })
              commit('showOverlay', { show: false })
          },
          error => {
              let notification = {
                  show: true,
                  message: error.response.data.message,
                  type: "error"
              }
              commit('showNotification', notification)
              commit('showOverlay', { show: false })
          }
      );
    },
    viewPrint: ({commit} , payload) => {
      let url = endPoints.utilities + '/print/' + payload.item.id;
      axios.get(url).then(print => {
        let p = 'data:image/jpeg;base64,' + print.data.profile_print
        commit('setPrint', {print: p})
      });
    },
  },
  getters: {
    getField,
  },
};
