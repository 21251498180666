<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline text-center justify-center">Filtro</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm3 md3>
                <v-text-field
                  label="ID"
                  v-model="id"
                  :clearable="true"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm5 md5>
                <v-text-field
                  label="Nome"
                  v-model="name"
                  :clearable="true"
                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm4 md4>
                <v-text-field
                  label="Nick"
                  v-model="nick"
                  :clearable="true"
                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm3 md3>
                <v-text-field
                  label="ID App"
                  v-model="id_app"
                  :clearable="true"
                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm5 md5>
                <v-select
                  :clearable="true"
                  clear-icon="mdi-close"
                  label="Tipos de Plataforma"
                  :items="plataform_types"
                  v-model="plataform_id"
                  item-text="description"
                  item-value="id"
                  outlined
                ></v-select>
              </v-flex>

              <v-flex xs12 sm4 md4>
                <v-select
                  :clearable="true"
                  clear-icon="mdi-close"
                  label="Status"
                  :items="possible_status"
                  v-model="status"
                  item-text="description"
                  item-value="id"
                  outlined
                ></v-select>
              </v-flex>

              <v-flex xs12 sm3 md3>
                <v-select
                  :clearable="true"
                  clear-icon="mdi-close"
                  label="Saques"
                  :items="possible_web_deposits_withdrawals"
                  v-model="web_withdrawals"
                  item-text="description"
                  item-value="id"
                  outlined
                ></v-select>
              </v-flex>

              <v-flex xs12 sm3 md3>
                <v-select
                  :clearable="true"
                  clear-icon="mdi-close"
                  label="Depósitos"
                  :items="possible_web_deposits_withdrawals"
                  v-model="web_deposits"
                  item-text="description"
                  item-value="id"
                  outlined
                ></v-select>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-select
                  :clearable="true"
                  clear-icon="mdi-close"
                  label="BOT"
                  :items="bots"
                  v-model="id_bot"
                  item-text="name"
                  item-value="id"
                  outlined
                ></v-select>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn
            text
            @click="
              setShowFilter(false);
              clearFilter();
            "
            >Cancelar</v-btn
          >
        </v-col>
        <v-col class="text-right">
          <v-btn text type="submit" right @click="filter()">Filtrar</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "SlotFilter",
  computed: {
    ...mapState("slots", ["bots"]),
    ...mapFields("slots", [
      "filter.plataform_id",
      "filter.status",
      "filter.id",
      "filter.id_app",
      "filter.name",
      "filter.nick",
      "filter.id_bot",
      "filter.web_deposits",
      "filter.web_withdrawals",
      "plataform_types",
      "possible_status",
      "possible_web_deposits_withdrawals",
    ]),
  },
  methods: {
    ...mapMutations("slots", ["setShowFilter", "clearFilter"]),
    ...mapActions("slots", ["getSlots"]),
    filter() {
      this.getSlots({ resetPage: true });
      this.setShowFilter(false);
    },
  },
};
</script>
