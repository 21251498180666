<template>
    <div>
        <v-card>
            <v-card-title>
                <span class="headline text-center justify-center">Filtro</span>

                <v-col class="text-right">
                    <v-btn
                        v-if="filtered == true"
                        color="white"
                        class="ma-2"
                        @click="clearFilter"
                        right
                        small
                        depressed
                    >
                        <v-icon color="black">mdi-filter-remove</v-icon>
                    </v-btn>
                </v-col>
            </v-card-title>

            <v-card-text>
                <v-form ref="form" lazy-validation>
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12 sm6 md6>
                                <v-text-field
                                    label="ID"
                                    v-model="id"
                                    :clearable="true"
                                    outlined
                                ></v-text-field>
                            </v-flex>

                            <v-flex xs12 sm6 md6>
                                <v-text-field
                                    label="Valor"
                                    prefix="R$"
                                    v-model="value"
                                    :clearable="true"
                                    outlined
                                ></v-text-field>
                            </v-flex>

                            <v-flex xs12 sm6 md6>
                                <v-text-field
                                    label="ID Período"
                                    v-model="id_period"
                                    :clearable="true"
                                    outlined
                                ></v-text-field>
                            </v-flex>

                            <v-flex xs12 sm6 md6>
                                <v-text-field
                                    label="ID Fechamento"
                                    v-model="id_closure"
                                    :clearable="true"
                                    outlined
                                ></v-text-field>
                            </v-flex>

                            <v-flex xs12 sm6 md6>
                                <v-text-field
                                    label="ID Aplicativo"
                                    v-model="id_app"
                                    :clearable="true"
                                    outlined
                                ></v-text-field>
                            </v-flex>

                            <v-flex xs12 sm6 md6>
                                <v-select
                                    :clearable="true"
                                    clear-icon="mdi-close"
                                    label="Status"
                                    :items="possible_status"
                                    v-model="status"
                                    item-text="description"
                                    item-value="id"
                                    outlined
                                ></v-select>
                            </v-flex>

                            <v-flex xs12 sm6 md6>
                                <v-datetime-picker
                                    v-model="start"
                                    date-format="dd/MM/yyyy"
                                    clearText="Limpar"
                                    okText="Confirmar"
                                    :time-picker-props="timeProps"
                                    label="De "
                                    :textFieldProps="{ outlined: true }"
                                >
                                    <template slot="dateIcon">
                                        <v-icon>mdi-calendar</v-icon>
                                    </template>
                                    <template slot="timeIcon">
                                        <v-icon>mdi-clock</v-icon>
                                    </template>
                                </v-datetime-picker>
                            </v-flex>

                            <v-flex xs12 sm6 md6>
                                <v-datetime-picker
                                    v-model="end"
                                    date-format="dd/MM/yyyy"
                                    clearText="Limpar"
                                    okText="Confirmar"
                                    :time-picker-props="timeProps"
                                    label="Até "
                                    :textFieldProps="{ outlined: true }"
                                >
                                    <template slot="dateIcon">
                                        <v-icon>mdi-calendar</v-icon>
                                    </template>
                                    <template slot="timeIcon">
                                        <v-icon>mdi-clock</v-icon>
                                    </template>
                                </v-datetime-picker>
                            </v-flex>

                            <v-flex xs12 sm6 md6>
                                <v-menu
                                    v-model="showStart"
                                    :close-on-content-click="true"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="due_formatted"
                                            label="Data de Vencimento"
                                            outlined
                                            readonly
                                            append-icon="mdi-close"
                                            @click:append="clearStart()"
                                            persistent-hint
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        locale="pt-br"
                                        v-model="due_date"
                                        no-title
                                        @input="(date) => setDueDate(date)"
                                    ></v-date-picker>
                                </v-menu>
                            </v-flex>

                            <v-flex xs12 sm6 md6>
                                <v-autocomplete
                                    label="Clube"
                                    :items="slots"
                                    v-model="id_slot"
                                    :error-messages="errors.collect('slots')"
                                    item-text="name"
                                    item-value="id"
                                    :clearable="true"
                                    clear-icon="mdi-close"
                                    outlined
                                ></v-autocomplete>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-col>
                    <v-btn
                        text
                        @click="
                            setShowFilter(false)
                            clearFilter()
                        "
                        >Cancelar</v-btn
                    >
                </v-col>
                <v-col class="text-right">
                    <v-btn text type="submit" right @click="filter"
                        >Filtrar</v-btn
                    >
                </v-col>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
    name: 'BillsToReceiveFilter',
    computed: {
        ...mapState('bills_to_receive', ['mode', 'selectedItem', 'showDialog', 'filtered']),
        ...mapState('slots', ['slots']),
        ...mapFields('bills_to_receive', [
            'filter.id',
            'filter.value',
            'filter.id_client',
            'filter.status',
            'filter.start',
            'filter.end',
            'filter.date_created',
            'filter.due_date',
            'filter.id_slot',
            'filter.id_period',
            'filter.id_closure',
            'filter.id_app',
        ]),
    },
    methods: {
        ...mapMutations('bills_to_receive', ['setShowFilter', 'clearFilter']),
        ...mapActions('bills_to_receive', ['getItems']),
        filter() {
            this.getItems()
            this.setShowFilter(false)
        },
        formatDate(date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`
        },
        setDueDate(date) {
            this.due_date = date
            this.showStart = false
            this.due_formatted = this.formatDate(date)
        },
        setStart(date) {
            this.start = date
            this.showStart = false
            this.start_formatted = this.formatDate(date)
        },
        setEnd(date) {
            this.end = date
            this.showEnd = false
            this.end_formatted = this.formatDate(date)
        },
    },
    data: () => ({
        possible_status: [
            { id: 0, description: 'Pendente' },
            { id: 1, description: 'Concluído' },
        ],
        timeProps: {
            format: '24hr',
        },
        showStart: false,
        start_formatted: '',
        due_formatted: '',
        showEnd: false,
        end_formatted: '',
    }),
}
</script>
