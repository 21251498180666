<template>
  <div>
    <div>
      <v-card>
        <v-card-title>
          <span class="headline text-center justify-center">Sub-Agente</span>

          <v-spacer></v-spacer>
          <v-progress-circular
            v-if="loading_subagents"
            indeterminate
            class="text-right"
            color="primary"
            size="24"
          ></v-progress-circular>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs6 sm6 md6 v-if="mode == 'EDIT'">
                  <v-text-field
                    required
                    label="Id Suprema"
                    v-model="id_pppoker_agent"
                    :readonly="true"
                    :error-messages="errors.collect('id_pppoker_agent')"
                    data-vv-name="id_pppoker_agent"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs6 sm6 md6 v-if="mode == 'EDIT'">
                  <v-text-field
                    required
                    label="Nick Agente"
                    v-model="nick_agent"
                    :readonly="true"
                    :error-messages="errors.collect('nick_agent')"
                    data-vv-name="nick_agent"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs6 sm6 md6 v-if="mode == 'ADD'">
                  <v-autocomplete
                    clearable
                    deletable-chips
                    v-model="id_agent"
                    :no-filter="true"
                    :items="find_agents"
                    outlined
                    chips
                    small-chips
                    no-data-text="Nenhum agente encontrado"
                    :item-text="getTextAgent"
                    item-value="id"
                    placeholder="Digite aqui o nome do agente"
                    label="Agente"
                    :search-input.sync="searchAgent"
                  ></v-autocomplete>
                </v-flex>
                
                <v-flex xs12 sm6 md6>
                    <v-select
                    label="Ganhos Sobre *"
                    :items="possibile_relationships"
                    v-model="relationship_master_agent"
                    :error-messages="errors.collect('relationship_master_agent')"
                    v-validate="'required:Ganhos Sobre,relationship_master_agent'"
                    data-vv-name="relationship_master_agent"
                    item-text="description"
                    item-value="id"
                    outlined
                    ></v-select>
                </v-flex>
                <v-flex xs12 sm6 md6>
                    <v-select
                    label="Tipo de Ganho *"
                    :items="possible_types_deal"
                    v-model="type_deal_with_master"
                    :error-messages="errors.collect('type_deal_with_master')"
                    v-validate="'required:Tipo de Ganho,type_deal_with_master'"
                    data-vv-name="type_deal_with_master"
                    item-text="description"
                    item-value="id"
                    outlined
                    ></v-select>
                </v-flex>
                <v-flex xs12 sm6 md6 v-if="type_deal_with_master == 1">
                  <v-text-field
                    required
                    label="Valor Fixo*"
                    v-model="value_deal_with_master"
                    :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                    :error-messages="errors.collect('value_deal_with_master')"
                    v-validate="'required:Valor Fixo,value_deal_with_master|decimal:2'"
                    data-vv-name="value_deal_with_master"
                    append-icon="mdi-percent-outline"
                    outlined
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-col>
            <v-btn text @click="closeDialog">{{ mode == 'VIEW' ? 'Voltar' : 'Cancelar'}}</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn text type="submit" right @click="saveRakeDeal">Salvar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

import debounce from '../../../../util/debounce.js' 

export default {
    name: "RakeDealForm",
    computed: {
        ...mapState("subagents", ["find_agents", "loading_subagents", "mode"]),
        ...mapFields("subagents", [
            "editedItem.id_agent",
            "editedItem.id_pppoker_agent",
            "editedItem.nick_agent",
            "editedItem.type_deal_with_master",
            "editedItem.value_deal_with_master",
            "editedItem.relationship_master_agent",  
        ]),
        ...mapFields("subagents", {
            searchAgent: "filter_agent.nick",
        })
    },
    created() {
        this.$validator.extend("required", {
        getMessage: (field, [label]) => label + " deve ser informado",
        validate: (_, [, field]) => {
            if (this[field] || this[field] === 0) {
            return true;
            } else {
                return false;
            }
        },
        });
    },
    mixins: [debounce],
    mounted() {
        this.getFindAgents = this.debounce(this.getFindAgents, 500)
    },
    methods: {
        ...mapActions("subagents", ["closeDialog", "add", "getFindAgents", "save"]),
        getTextAgent(item) {
            if(item) {
                if(item.id_pppoker) {
                    return item.id_pppoker + " | " + item.nick + " | " + item.club_name
                } else {
                    return item.nick
                }
            }
            return ''
        },
        saveRakeDeal() {
            this.$validator.validateAll().then(valid => {
                if (valid) {  
                  this.relationship_master_agent = parseInt(this.relationship_master_agent)
                  this.type_deal_with_master = parseInt(this.type_deal_with_master)
                  this.value_deal_with_master = parseFloat(this.value_deal_with_master)
                  this.save();
                }
            });
        }
    },
    data: () => ({
        valid: false,
        possible_types_deal: [
            {
                id: 0, 
                description: "Diferença"
            },
            {
                id: 1, 
                description: "Fixo"
            }
        ],
        possibile_relationships: [
            {
                id: 0, 
                description: "Jogadores"
            },
            {
                id: 1, 
                description: "Jogadores e Sub-Agentes"
            }
        ],
    }),
    watch: {
        searchAgent: {
            handler () {
                if(this.searchAgent != null ) {
                    this.getFindAgents({search: this.searchAgent})
                }
            },
        },
    }
};
</script>
