import axios from "axios";
import { getField, updateField } from "vuex-map-fields";
import endPoints from "../../config/endPoints";
import { defaultErrorNotification } from "../../constants";
import b64 from '../../util/Base64'

const defaultConfigItem = {
    nameDomain: "",
    app: "",
    agent: "",
    club: "",
    approve: false,
    createFichasAccount: false,
    emailConfirmationApp: false,
    emailConfirmationFichas: false,
    logoUrl: "",
    backgroundImgUrl: "",
    style: {
        colorPrimary: "",
        backgroundImg: "",
    },
};

const defaultFilter = {
    nameDomain: "",
    app: "",
    agent: "",
    club: "",
    approve: "",
    createFichasAccount: "",
    emailConfirmationApp: "",
    emailConfirmationFichas: ""

}


export default {
    namespaced: true,
    state: {
        items: [],
        selectedItem: {},
        pagination: {
            page: 1,
            limit: 10,
            sortBy: [],
            sortDesc: []
        },
        filter: {
            nameDomain: "",
            app: "",
            agent: "",
            club: "",
            approve: "",
            createFichasAccount: "",
            emailConfirmationApp: "",
            emailConfirmationFichas: ""
        },
        notification: {
            show: false,
            message: "",
            type: "",
        },
        loading: false,
        dialog: false,
        filterDialog: false,
        mode: "",

    },
    mutations: {
        setItems(state, items) {
            state.items = items;
        },

        closeDialog: (state) => {
            state.selectedItem = {};
            state.dialog = false;
            state.mode = "LIST";
        },

        setShowDialog: (state, payload) => {
            console.log(payload)
            state.dialog = payload;
            state.selectedItem = Object.assign({}, defaultConfigItem);
        },
        setLoading(state, loading) {
            state.loading = loading;
        },
        setPage(state, { page, limit }) {
            state.pagination.page = page;
            state.pagination.limit = limit;
        },
        setTotalItems(state, total) {
            state.pagination.totalItems = total;
        },
        showNotification(state, notification) {
            state.notification = notification;
        },
        edit: (state, payload) => {
            state.selectedItem = Object.assign({}, payload.item);
            state.dialog = true;
            console.log(state.selectedItem)
            state.mode = "EDIT";
        },
        view: (state, payload) => {
            state.selectedItem = Object.assign({}, payload);
            state.selectedItem.backgroundImgUrl = payload.urlBucketAws + '/background.png'
            state.dialog = true;
            state.mode = "VIEW";
        },
        addConfig: (state) => {
            state.dialog = true;
            state.mode = "ADD";
            state.selectedItem = Object.assign({}, defaultConfigItem);
        },
        setShowFilter: (state, payload) => (state.filterDialog = payload),
        clearFilter: (state) => {
            state.filter = Object.assign({}, defaultFilter)
        },
        updateField,
    },
    actions: {
        closeDialog: ({ commit }) => {
            commit("closeDialog");
        },
        saveImgLogo: async ({ commit, state }, imageLogo) => {
            let imgLogo64 ;
            await b64(imageLogo).then((fileBase64) => {
                imgLogo64 = fileBase64
            }
            )
            let func = axios.post;
            let url = endPoints.aws + `/upload/${state.selectedItem.nameDomain}/logo`;
            try {
                await func(url, { newImage: imgLogo64 });
            } catch (error) {
                commit(
                    "showNotification",
                    defaultErrorNotification(error?.response?.data?.message)
                );
            }
        },
        saveImgBackground: async ({ commit, state }, imageBackground) => {
            let imageBackground64;
            await b64(imageBackground).then((fileBase64) => {
                imageBackground64  = fileBase64
            }
            )
            let func = axios.post;
            let url = endPoints.aws + `/upload/${state.selectedItem.nameDomain}/background`;
            try {
                await func(url, { newImage: imageBackground64 });
            } catch (error) {
                commit(
                    "showNotification",
                    defaultErrorNotification(error?.response?.data?.message)
                );
            }
        },
        save: async ({ commit, state, dispatch }, { imageLogo, imageBackground }) => {
            if (imageLogo) {
                await dispatch("saveImgLogo", imageLogo)
            }
            if (imageBackground) {
                await dispatch("saveImgBackground", imageBackground)

            }
            let url = endPoints.config_clubs;
            let func = '';
            if (state.mode === "ADD") {
                func = axios.post;
            } else {
                func = axios.put;
                url += "/" + state.selectedItem.nameDomain;
            }
            commit("setLoading", true);
            try {
                await func(url, state.selectedItem);
                commit("showNotification", {
                    show: true,
                    message: "Configuração salva com sucesso",
                    type: "success",
                });
                commit("closeDialog");
                dispatch("getItems", { resetPage: true });
            } catch (error) {
                commit(
                    "showNotification",
                    defaultErrorNotification(error?.response?.data?.message)
                );
            }
            commit("setLoading", false);
        },
        getItems: async ({ commit, state }, payload) => {
            let filter = state.filter
            let url = endPoints.config_clubs + "/name_clubs/list";
            if (payload.resetPage) {
                url += "?page=" + 1 + "&" + "limit=" + 10;
                commit("setPage", { page: 1, limit: 10 });
            } else {
                url +=
                    "?page=" +
                    state.pagination.page +
                    "&" +
                    "limit=" +
                    state.pagination.limit;
            }


            Object.keys(filter).forEach(function (field) {
                if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
                    url += "&" + field + "=" + filter[field]
                }
            })

            if (state.pagination && state.pagination.sortBy && state.pagination.sortBy.length > 0) {
                url += "&sort_by=" + (state.pagination.sortBy[0] === 'score' ? "id" : state.pagination.sortBy[0])
                url += "&sort_desc=" + state.pagination.sortDesc[0]
            }

            commit("setLoading", true);

            try {
                let items = await axios.get(url);
                commit("setItems", items.data)
                console.log(items.data)
                commit("setTotalItems", items.data.total);

            } catch (error) {
                commit(
                    "showNotification",
                    defaultErrorNotification(error?.response?.data?.message)
                );

            }
            commit("setLoading", false);
        },

    },
    getters: {
        getField,
    },
};
