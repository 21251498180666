import axios from "axios";
import { getField, updateField } from "vuex-map-fields";
import endPoints from "../../../config/endPoints";
import { defaultErrorNotification, defaultSuccessNotification } from "../../../constants"


const defaultFilter = {
  id: "",
  name: "",
}

const defaultCompaniesItem = {
  name: "",
};

export default {
  namespaced: true,
  state: {
    items: [],
    selectedItem: {},
    mode: "",
    loading: false,
    totalItems: 0,
    deleteDialog: false,
    filterDialog: false,
    showDialog: false,
    filter: {
      id: "",
      name: "",
    },
    notification: {
      show: false,
      message: "",
      type: "",
    },
    pagination: {
      page: 1,
      itemsPerPage: 10,
    },
  },
  mutations: {
    add: (state) => {
      state.selectedItem = Object.assign({}, defaultCompaniesItem);
      state.showDialog = true;
      state.mode = "ADD";
    },
    deleteCompany: (state, payload) => {
      state.selectedItem = payload;
      state.deleteDialog = true;
    },
    edit: (state, payload) => {
      state.selectedItem = Object.assign({}, payload);
      state.showDialog = true;
      state.mode = "EDIT";
    },
    view: (state, payload) => {
      state.selectedItem = payload;
      state.showDialog = true;
      state.mode = "VIEW";
    },
     setBanks: (state, payload) => {
       state.banks = payload.banks;
     },
    clearFilter: (state) => {
        state.filter = Object.assign({}, defaultFilter)
    },
    setItems: (state, payload) => (state.items = payload),
    setTotalItems: (state, payload) => (state.totalItems = payload),
    setLoading: (state, payload) => (state.loading = payload),
    showNotification: (state, payload) => (state.notification = payload),
    setSelectedItem: (state, payload) => state.selectedItem = payload,
    setDeleteDialog: (state, payload) => (state.deleteDialog = payload),
    setShowDialog: (state, payload) => {
      state.showDialog = payload;
      state.selectedItem = Object.assign({}, defaultCompaniesItem);
    },
    setShowFilter: (state, payload) => (state.filterDialog = payload),
    updateField,
  },
  actions: {
    getItems: async ({ commit, state }) => {
     
      let filter = state.filter
      let url = `${endPoints.companies}?page=${state.pagination.page}&itemsPerPage=${state.pagination.itemsPerPage}`;

      Object.keys(filter).forEach(function(field) {
        if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
          url += "&" + field + "=" + filter[field]
        }
      })

      if (state.pagination && state.pagination.sortBy && state.pagination.sortBy.length > 0) {
        url += "&sort_by=" + (state.pagination.sortBy[0] === 'name' ? "id" : state.pagination.sortBy[0])
        url += "&sort_desc=" + state.pagination.sortDesc[0]
      }

      commit("setLoading", true);

      try {
        let items = await axios.get(url);
        commit("setItems", items.data.data);
        commit("setTotalItems", items.data.total);
      } catch (error) {
        commit(
          "showNotification",
          defaultErrorNotification(error?.response?.data?.message)
        );
      }
      commit("setLoading", false);
    },
    deleteItem: async ({ commit, dispatch, state }) => {
      commit("setLoading", true);
      try {
        await axios.delete(`${endPoints.companies}/${state.selectedItem.id}`);

        commit("setLoading", false);
        commit("setDeleteDialog", false);
        commit("setSelectedItem", {});
        commit("showNotification", defaultSuccessNotification("Empresa removida com sucesso"));

        return dispatch("getItems");
      } catch (error) {
        commit(
          "showNotification",
          defaultErrorNotification(error?.response?.message)
        );
        commit("setLoading", false);
      }
    },
    save: async ({ commit, state, dispatch, rootState }) => {
      let url = endPoints.companies;
      let func = "";
      state.mode === "ADD"
        ? (func = axios.post)
        : ((func = axios.put), (url += `/${state.selectedItem.id}`));
      commit("setLoading", true);
      try {
        state.selectedItem.user_id = rootState.login.userData.id;
        state.selectedItem.user_name = rootState.login.userData.name;
        // state.selectedItem.percentage = parseFloat(state.selectedItem.percentage);

        await func(url, state.selectedItem);

        commit("showNotification", defaultSuccessNotification("Empresa salva com sucesso"));
        commit("setShowDialog", false);
        commit("setLoading", false);
        dispatch("getItems");
      } catch (error) {
        commit("showNotification", defaultErrorNotification(error?.response?.message));
        commit("setLoading", false);
      }
    },
  },
  getters: {
    getField,
  },
};
