import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../../config/endPoints';

const defaultItem = {
    bank: 1,
    id_instance: null,
    value: 0,
    type: 3,
};

export default {
    namespaced: true,
    state: {
        dialog: false,
        dialogDelete: false,
        dialogFilter: false,
        itemToDelete: {},
        editedItem: {},
        items: [],
        loading: false,
        filter: {
            type: '',
            bank: '',
            value: '',
            description: '',
            start: '',
            end: '',
            id_instance: ''
        },
        financial_moviments: [],
        notificationMessage: '',
        notificationType: '',
        overlay: false,
        pagination: {
            page: 1,
            itemsPerPage: 10
        },
        showNotification: false,
        totalItems: 0,
    },
    mutations: {
        add: (state) => {
            state.editedItem = Object.assign({}, defaultItem)
            state.dialog = true
            state.mode = "ADD"
        },
        closeDialog: (state) => {
            state.editedItem = {}
            state.editedIndex = -1
            state.errors = [];
            state.dialog = false
            state.mode = "LIST"
        },
        loading: (state, payload) => state.loading = payload.show,
        overlay: (state, payload) => {
            state.overlay = payload.show
        },
        setPage: (state, payload) => {
            state.pagination.page = payload.page;
            state.pagination.itemsPerPage = payload.itemsPerPage
        },
        setItems: (state, payload) => {
            state.items = payload.items
        },
        setDialogDelete: (state, show) => state.dialogDelete = show,
        setItemToDelete: (state, item) => state.itemToDelete = item,
        setTotalItems: (state, total) => {
            state.totalItems = total
        },
        setBankFMoviments: (state, payload) => state.bank_financial_moviments = payload.bank,
        showDialogFinancialMoviments: (state, payload) => state.dialog_financial = payload.show,
        showFilter: (state, payload) => state.dialogFilter = payload.show,
        showNotification: (state, payload) => {
            state.showNotification = payload.show,
                state.notificationMessage = payload.message,
                state.notificationType = payload.type
        },
        updateField,
    },
    getters: {
        getField,
    },
    actions: {
        closeDeleteDialog: ({ commit }) => {
            commit('setDialogDelete', false)
            commit('setItemToDelete', {})
        },
        deleteItem: ({ dispatch, commit, state }) => {
            commit('loading', { show: true })
            axios.delete(endPoints.financial_moviments + "/" + state.itemToDelete.id).then(() => {
                dispatch('closeDeleteDialog')
                commit('loading', false)
                let notification = {
                    show: true,
                    message: "Ajuste excluido com sucesso",
                    type: "success"
                }
                commit('showNotification', notification)
                commit('loading', { show: false })
                if (state.screen != 'messages') {
                    dispatch('getItems', { resetPage: false });
                }
            }).catch(() => {
                commit('loading', { show: false })
                let notification = {
                    show: true,
                    message: "Ocorreu um erro ao excluir o depósito.",
                    type: "error"
                }
                commit('showNotification', notification)
            });
        },
        getItems: ({ commit, state }, payload) => {
            commit('loading', { show: true })
            let url = endPoints.financial_moviments;
            let filter = state.filter;
            if (payload.resetPage === true) {
                url += '?page=' + 1 + '&' + 'itemsPerPage=' + 10;
                commit('setPage', { page: 1, itemsPerPage: 10 })
            } else {
                url += '?page=' + state.pagination.page + '&' + 'itemsPerPage=' + state.pagination.itemsPerPage;
            }

            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined) {
                    if (field == "end") {
                        var e = new Date(filter.end)
                        e.setDate(e.getDate() + 1)
                        url += "&" + field + "=" + e.toISOString().substr(0, 10)
                    } else {
                        url += "&" + field + "=" + filter[field]
                    }
                }
            });

            axios.get(url).then(items => {
                commit('setTotalItems', items.data.total)
                commit('loading', { show: false })
                commit('setItems', { items: items.data.data })
            });
        },
        getItemsNoPage: ({ commit, state }) => {
            commit('overlay', { show: true })
            let url = endPoints.financial_moviments;
            let filter = state.filter;
            url += '?page=' + '1' + '&' + 'itemsPerPage=' + '99999';

            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined) {
                    if (field == "end") {
                        var e = new Date(filter.end)
                        e.setDate(e.getDate() + 1)
                        filter.end = e.toISOString().substr(0, 10)
                    }
                    url += "&" + field + "=" + filter[field]
                }
            });
            return axios.get(url).then(items => {
                commit('overlay', { show: false })
                return items.data.data
            }).catch(() => {
                commit('overlay', { show: false })
            });
        },
        showDeleteDialog: ({ commit }, item) => {
            commit('setDialogDelete', true)
            commit('setItemToDelete', item)
        },
        save: ({ dispatch, commit, state }) => {
            var url = endPoints.financial_moviments;
            let func = '';
            if (state.mode == 'ADD') {
                func = axios.post;
                url = endPoints.financial_moviments;
            } else {
                func = axios.put;
                url += '/' + state.editedItem.id;
            }

            func(url, state.editedItem).then(
                () => {
                    dispatch('getItems', { resetPage: true })
                    let notification = {
                        show: true,
                        message: "Conferência realizada com sucesso.",
                        type: "success"
                    }
                    commit('closeDialog')
                    commit('showNotification', notification)
                },
                error => {
                    let notification = {
                        show: true,
                        message: error.response.data.message,
                        type: "error"
                    }
                    commit('showNotification', notification)
                }
            );
        }
    }
}