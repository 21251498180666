<template>
  <div>
    <v-card>
      <p class="text-center font-weight-medium text-h6">
        VALOR TOTAL: {{ this.withdrawal_total_value }}
      </p>

      <v-data-table
        :headers="headers"
        :loading="loading"
        :items="items"
        :options.sync="pagination"
        :server-items-length="totalItems"
        :item-class="getClassColor"
        :footer-props="{
          'items-per-page-options': [10, 20, 50, 100],
          'items-per-page-text': 'Itens por página',
          'show-first-last-page': true,
          'page-text': `Página ${pagination.page} de ${parseInt(totalItems/pagination.itemsPerPage) + 1}`
        }"
        no-data-text="Nenhum registro encontrado"
        class="elevation-10 table-margin"
      >
        <template v-slot:[`item.account_type`]="{ item }">
          <span>{{ getAccountType(item.account_type) }}</span>
        </template>
        <template v-slot:[`item.value`]="{ item }">
          <span>{{
            item.value
              ? item.value.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })
              : 0
          }}</span>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <span>{{ getStatus(item.status) }}</span>
        </template>
        <template v-slot:[`item.sended`]="{ item }">
          <span>{{ getSendedStatus(item.sended) }}</span>
        </template>
        <template v-slot:[`item.type_send`]="{ item }">
          <v-chip
            :color="getColorByTypeSend(item.type_send)"
            class="ma-2"
            :text-color="item.type_send != 0 ? 'white' : 'black'"
            label
            >{{ getSendType(item.type_send) }}</v-chip
          >
        </template>
        <template v-slot:[`item.type`]="{ item }">
          <span>{{ getTypeDescription(item.type) }}</span>
        </template>
        <template v-slot:[`item.inscription`]="{ item }">
          <span>{{ getIsEmpty(item.inscription) }}</span>
        </template>
        <template v-slot:[`item.agency`]="{ item }">
          <span>{{ getIsEmpty(item.agency) }}</span>
        </template>
        <template v-slot:[`item.account`]="{ item }">
          <span>{{ getIsEmpty(item.account) }}</span>
        </template>
        <template v-slot:[`item.check_bonus`]="{ item }">
          <v-chip
            :color="item.check_bonus ? 'red' : 'green'"
            class="ma-2"
            text-color="white"
            label
            >{{ getCheckBonus(item.check_bonus) }}</v-chip
          >
        </template>
        <template v-slot:[`item.user_registred`]="{ item }">
          <span>{{ item.user_registred == 0 ? "Não" : "Sim" }}</span>
        </template>
        <template v-slot:[`item.balance_pppoker`]="{ item }">
          <span>{{
            item.balance_pppoker
              ? item.balance_pppoker.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })
              : 0
          }}</span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "PlayerWithdrawals",

  methods: {
    ...mapActions("player_history", ["getItems"]),
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      }
      return false;
    },
    getAccountType(type) {
      switch (type) {
        case 0:
          return "Corrente";
        case 1:
          return "Poupança";
        case 2:
          return "Pix";
      }
    },
    getColorByTypeSend(type_send) {
      if (type_send == 0) {
        return "gray";
      } else if (type_send == 1) {
        return "cyan";
      } else if (type_send == 2 || type_send == 5) {
        return "green";
      } else if (type_send == 3 || type_send == 4) {
        return "red";
      }
    },
    getClassColor(item) {
      if (item.status == 2) {
        return "row-green";
      } else if (item.status == 0) {
        if (item.type_send == 1) {
          return "row-teal";
        } else if (item.type_send == 4) {
          return "row-refused";
        } else {
          return "row-red";
        }
      } else if (item.status == 3) {
        return "row-grey";
      } else if (item.status == 4) {
        return "row-orange";
      } else if (item.status == 5) {
        return "row-pink";
      } else if (item.status == 6 || item.type_send == 1) {
        return "row-blue";
      } else if (item.status == 7) {
        return "row-refused";
      } else if (
        item.status == 1 &&
        item.contact_id != null &&
        (item.id_instance == 24 || item.id_instance == 12)
      ) {
        return "row-purple";
      }
    },
    getStatus(type) {
      switch (type) {
        case 0:
          return "Pendente";
        case 1:
          return "Em andamento";
        case 2:
          return "Concluido";
        case 3:
          return "Estornado";
        case 4:
          return "Aguardando Pagamento";
        case 5:
          return "Agendado/Erro";
        case 6:
          return "Em Processamento";
        case 7:
          return "Recusado";
        case 8:
          return "Erro SW";
      }
    },
    getSendedStatus(type) {
      switch (type) {
        case 0:
          return "-----";
        case 1:
          return "Enviado";
        case 2:
          return "Recebido";
      }
    },
    getSendType(type) {
      switch (type) {
        case 0:
          return "Pendente";
        case 1:
          return "Solicitado";
        case 2:
          return "Realizado";
        case 3:
          return "Manual";
        case 4:
          return "Manual - PB";
        case 5:
          return "Realizado - PB";
      }
    },
    getTypeDescription(type) {
      let types = [
        "Fichas",
        "Rakeback",
        "Saldo Positivo",
        "Transferência",
        "Ordem Maleta",
        "Devolução",
      ];
      return types[type];
    },
    getIsEmpty(value) {
      if (value === "") {
        return "-----";
      }

      return value;
    },
    getCheckBonus(value) {
      if (value == "" || !value) {
        return "Não";
      }

      return "Sim";
    },
  },
  computed: {
    ...mapState("player_history", [
      "filtered",
      "items",
      "loading",
      "totalItems",
      "withdrawal_total_value",
    ]),
    ...mapFields("player_history", [
      "current_tab",
      "pagination",
      "filter.id_pppoker",
      "filter.value",
      "filter.status",
      "filter.start",
      "filter.end",
    ]),
  },
  data: () => ({
    headers: [
      { text: "ID", value: "id", sortable: false },
      { text: "CPF/CNPJ", value: "inscription", sortable: false },
      { text: "Nome", align: "left", value: "name", sortable: false },
      { text: "Banco", value: "bank_name", sortable: false },
      { text: "Valor", value: "value", sortable: false }, // soma valor
      { text: "Clube", value: "club_name", sortable: false },
      { text: "ID App", value: "id_pppoker", sortable: false },
      { text: "Tipo", value: "type", sortable: false },
      { text: "Status", value: "status", sortable: false },
      { text: "Retirada", value: "type_send", sortable: false },
      { text: "Saldo", value: "balance_pppoker", sortable: false },
      { text: "Verificar Bônus", value: "check_bonus", sortable: false },
      { text: "Recebido", value: "date", sortable: false },
      { text: "Finalizado", value: "finish_at", sortable: false },
    ],
    account_types: [
      { account_type: 0, description: "Corrente" },
      { account_type: 1, description: "Poupança" },
      { account_type: 2, description: "Pix" },
    ],
    send_types: [
      { send_types: 0, description: "Solicitado" },
      { send_types: 1, description: "Realizado" },
      { send_types: 2, description: "Manual" },
    ],
    possible_status: [
      { status: 0, description: "Pendente" },
      { status: 1, description: "Cancelado" },
      { status: 2, description: "Concluido" },
      { status: 3, description: "Estornado" },
      { status: 4, description: "Aguardando Pagamento" },
    ],
    possible_update_status: [
      { id: 2, description: "Concluido" },
      { id: 4, description: "Aguardando Pagamento" },
    ],
  }),
  watch: {
    pagination: {
      handler() {
        if (this.filtered) {
          if(this.current_tab == 'withdrawal') {
            this.getItems({ 
              end_point: 'withdrawal',
              extend_url: '',
              resetPage: false
            })
          }
        }
      },
    },
  },
};
</script>
