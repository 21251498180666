
import { getField, updateField } from "vuex-map-fields";

export default {
  namespaced: true,
  state: {
    dialogPeriod: false,
    pagination: {
      page: 1,
      itemsPerPage: 10,
    },

  },
  mutations: {
    showDialogPeriod: (state) => {
      state.dialogPeriod = true;
    },
    closeDialogPeriod: (state) => {
      state.dialogPeriod = false;
    },
    updateField,
  },
  actions: {
    showDialogPeriod({ state, commit, dispatch }) {
      console.log('showDialogPeriod')
      dispatch('period_conciliation/getItems', {pagination: state.pagination}, { root: true })
      commit('showDialogPeriod')

    },
    getRecordsByPeriod: function ({ commit, dispatch }, payload) {
      dispatch('period_conciliation_dialog_vinculation/setPeriod', { period: payload }, { root: true })
      dispatch('period_conciliation_dialog_vinculation/getRecordsRelated', null, { root: true })
      commit('closeDialogPeriod')
    },

  },
  getters: {
    getField,
  },
};
