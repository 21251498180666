<template>
    <div>
        <v-snackbar
            :color="notification.type"
            center
            top
            :timeout="2000"
            v-model="notification.show"
            >{{ notification.message }}</v-snackbar
        >
        <v-tabs v-model="tab" class="agent-data elevation-4 mb-2">
            <v-tab>Agentes</v-tab>
            <v-tab>Jogadores</v-tab>
            <v-tab>Duplicados</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="">
            <v-tab-item
                ><PeriodConciliationTransactionsCreditGrid
            /></v-tab-item>
            <v-tab-item><PeriodConciliationTransactionsTradeGrid :Duplicate="0"/></v-tab-item>
            <v-tab-item><PeriodConciliationTransactionsTradeGrid :Duplicate="1"/></v-tab-item>
        </v-tabs-items>
    </div>
</template>


<script>
import PeriodConciliationTransactionsCreditGrid from './PeriodConciliationTransactionsCreditGrid.vue'
import PeriodConciliationTransactionsTradeGrid from './PeriodConciliationTransactionsTradeGrid.vue'
import { mapState, mapActions, mapMutations } from 'vuex'
import { formatDate, formatMoney } from '@util/format_util'
import { mapFields } from 'vuex-map-fields'

export default {
    name: 'PeriodConciliationTransactionsGrid',
    computed: {
        ...mapState('period_conciliation_transactions', [
            'items',
            'loading',
            'totalItems',
            'notification',
        ]),
        ...mapFields('period_conciliation_transactions', ['pagination']),
    },
    created() {
        this.getItems({ type: 0 })
    },
    components: {
        PeriodConciliationTransactionsCreditGrid,
        PeriodConciliationTransactionsTradeGrid,
    },
    methods: {
        ...mapMutations('period_conciliation_transactions', [
            'showNotification',
        ]),
        ...mapActions('period_conciliation_transactions', ['getItems']),
        formatDate(date) {
            return formatDate(date)
        },

        formatMoney(value) {
            return formatMoney(value)
        },
    },
    data: () => ({
        tab: '',
        tabs: [],
    }),
}
</script>

