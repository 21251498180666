<template>
<div>
    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2000"
      v-model="showNotification"
    >
      {{ notificationMessage }}
    </v-snackbar>
    <v-toolbar flat class="elevation-5">
      <v-toolbar-title>Bancos</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="black" dark class="ma-2" @click="getItems" small><v-icon>mdi-refresh</v-icon></v-btn>
      <v-btn color="black" dark class="ma-2" @click="add" small><v-icon>mdi-plus</v-icon></v-btn>
      
    </v-toolbar>

    <v-dialog :value="dialog" persistent width="1000">

      <v-card>
        <v-card-title>
          <span class="headline">Bancos</span>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Código do Banco*"
                    v-model="code"
                    :disabled="this.mode == 'EDIT'"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('code')"
                    v-validate="'required:Código do banco,code|numeric'"
                    data-vv-name="code"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Nome *"
                    v-model="name"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('name')"
                    v-validate="'required:Nome,name'"
                    data-vv-name="name"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Conta"
                    v-model="account"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('account')"
                    data-vv-name="account"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-select
                    label="Status *"
                    :items="possible_status"
                    v-model="status"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('status')"
                    v-validate="'required:Status,status'"
                    item-text="description"
                    item-value="status"
                    data-vv-name="status"
                    outlined
                  >
                  </v-select>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    type="number"
                    label="Prioridade"
                    v-model="priority"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('priority')"
                    data-vv-name="Prioridade"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Chave Pix"
                    v-model="pix_key"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('pix_key')"
                    data-vv-name="Prioridade"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Tarifa Fixa"
                    v-model="rate_flat"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('rate_flat')"
                    data-vv-name="Tarifa Fixa"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Tarifa Percentual"
                    v-model="rate_percentage"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('rate_percentage')"
                    data-vv-name="Tarifa Percentual"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-select
                    label="Gera transação de tarifa no extrato *"
                    :items="yes_no"
                    v-model="make_rate"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('make_rate')"
                    v-validate="'required:Gera transação de tarifa no extrato,make_rate'"
                    item-text="description"
                    item-value="id"
                    data-vv-name="make_rate"
                    outlined
                  >
                  </v-select>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-select
                    label="Recebe Depositos *"
                    :items="yes_no"
                    v-model="receive_deposit"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('receive_deposit')"
                    v-validate="'required:Recebe Depositos,receive_deposit'"
                    item-text="description"
                    item-value="id"
                    data-vv-name="receive_deposit"
                    outlined
                  >
                  </v-select>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-select
                    label="Efetua Saque *"
                    :items="yes_no"
                    v-model="make_withdrawals"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('make_withdrawals')"
                    v-validate="'required:Efetua Saque,make_withdrawals'"
                    item-text="description"
                    item-value="id"
                    data-vv-name="make_withdrawals"
                    outlined
                  >
                  </v-select>
                </v-flex>
                <v-flex xs12 sm6 md12>
                  <v-textarea
                    required
                    label="Observação"
                    v-model="observation"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('observation')"
                    data-vv-name="observation"
                    outlined
                    :rows="2"
                  ></v-textarea>
                  <v-expansion-panels v-if="this.mode == 'VIEW' || this.mode == 'EDIT'">
                    <v-expansion-panel>
                      <v-expansion-panel-header>Integração</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-layout wrap>
                          <v-flex xs12 sm6 md6>
                            <v-text-field
                              required
                              label="Client Id"
                              v-model="client_id"
                              :readonly="this.mode == 'VIEW'"
                              :error-messages="errors.collect('client_id')"
                              data-vv-name="Client Id"
                              outlined
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm6 md6>
                            <v-text-field
                              required
                              label="Client Key"
                              v-model="client_key"
                              :readonly="this.mode == 'VIEW'"
                              :error-messages="errors.collect('client_key')"
                              data-vv-name="Client Key"
                              outlined
                              :append-icon="showCKey ? 'mdi-eye' : 'mdi-eye-off'"
                              :type="showCKey ? 'text' : 'password'"
                              @click:append="userData.role == 99 ? showCKey = !showCKey : ''"
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm6 md6>
                            <v-text-field
                              required
                              label="Diretório do Certificado"
                              v-model="cert_dir"
                              :readonly="this.mode == 'VIEW'"
                              :error-messages="errors.collect('cert_dir')"
                              data-vv-name="Cert Dir"
                              outlined
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm6 md6>
                             <v-text-field
                              required
                              label="Diretório Key"
                              v-model="cert_key"
                              :readonly="this.mode == 'VIEW'"
                              :error-messages="errors.collect('cert_key')"
                              data-vv-name="Cert Key"
                              outlined
                              :append-icon="showDKey ? 'mdi-eye' : 'mdi-eye-off'"
                              :type="showDKey ? 'text' : 'password'"
                              @click:append="userData.role == 99 ? showDKey = !showDKey : ''"
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm6 md6>
                            <v-text-field
                              required
                              label="Url Pix"
                              v-model="pix_api_url"
                              :readonly="this.mode == 'VIEW'"
                              :error-messages="errors.collect('pix_api_url')"
                              data-vv-name="Url Pix"
                              outlined
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm6 md6>
                            <v-text-field
                              required
                              label="Url Autenticação Pix"
                              v-model="pix_auth_url"
                              :readonly="this.mode == 'VIEW'"
                              :error-messages="errors.collect('pix_auth_url')"
                              data-vv-name="Client Id"
                              outlined
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm12 md6>
                            <v-text-field
                              required
                              label="Developer App Key"
                              v-model="dev_app_key"
                              :readonly="this.mode == 'VIEW'"
                              :error-messages="errors.collect('dev_app_key')"
                              data-vv-name="Client Id"
                              outlined
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm6 md6>
                            <v-select
                              label="Busca Pix por CPF *"
                              :items="yes_no"
                              v-model="pix_search_by_cpf"
                              :readonly="this.mode == 'VIEW'"
                              :error-messages="errors.collect('pix_search_by_cpf')"
                              v-validate="'required:Procura pix por CPF,pix_search_by_cpf'"
                              item-text="description"
                              item-value="id"
                              data-vv-name="pix_search_by_cpf"
                              outlined
                            >
                            </v-select>
                          </v-flex>
                        </v-layout>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-col>
            <v-btn
              text
              @click="closeDialog"
            >{{ mode == 'VIEW' ? 'Voltar' : 'Cancelar'}}</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn
              text
              type="submit"
              right
              v-if="mode == 'EDIT'  || mode == 'ADD'"
              @click="saveBank"
            >Salvar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-data-table
      :headers="headers"
      :loading="loading"
      :items="items"
      :options.sync="pagination"
      :server-items-length="totalItems"
      disable-pagination
      hide-default-footer
      no-data-text="Nenhum banco encontrado"
      class="elevation-10 table-margin"
    >
      <template v-slot:[`item.status`]="{ item }">
        <span>{{ getStatus(item.status)}}</span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="viewItem(item)">mdi-information</v-icon>
        <v-icon small class="mr-2" @click="edit(item)">mdi-pencil</v-icon>
        <v-icon small class="mr-2" @click="showDeleteDialog(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>

    <v-dialog :value="dialogDelete" persistent width="300">
      <v-card>
        <v-card-title class="headline">Confirmar a exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn
              text
              @click="closeDeleteDialog()"
            >Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn
              text
              type="submit"
              right
              @click="deleteItem"
            >Confirmar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import { mapFields } from 'vuex-map-fields'
  export default {
    name: 'BankAccount',
    computed: {
      ...mapState("login", ["userData"]),
      ...mapState('bank', ['dialog', 'dialogDelete', 'item', 'errors', 'loading', 'mode', 'totalItems', 'items', 'notificationType', 'notificationMessage']),
      ...mapFields('bank', [
          'editedItem.code',
          'editedItem.name',
          'editedItem.observation',
          'editedItem.status',
          'editedItem.account',
          'editedItem.priority',
          'editedItem.pix_key',
          'editedItem.client_id',
          'editedItem.client_key',
          'editedItem.cert_dir',
          'editedItem.cert_key',
          'editedItem.pix_api_url',
          'editedItem.pix_auth_url',
          'editedItem.dev_app_key',
          'editedItem.pix_search_by_cpf',
          'editedItem.rate_flat',
          'editedItem.rate_percentage',
          'editedItem.receive_deposit',
          'editedItem.make_withdrawals',
          'editedItem.make_rate',
          'showNotification',
          'pagination'
          // ...
        ])
    },
    created() {
      this.getItems()
      this.$validator.extend('required', {
        getMessage: (field, [label]) => label + ' deve ser informado',
        validate: (_, [, field]) => {
          if((this[field] || this[field] === 0) && (this[field] !== '' && this[field] !== null)) {
            return true
          } else {
            return false
          }
        }
      });
    },
    methods: {
      ...mapMutations('bank', ['add', 'edit', 'viewItem']),
      ...mapActions('bank', ['closeDialog', 'closeDeleteDialog', 'deleteItem', 'getItems', 'save', 'showDeleteDialog']),
      getStatus(type) {  
        switch(type) {
          case 0: 
            return 'Ativo'
          case 1: 
            return 'Alerta'
          case 2:
            return 'Inativo'
        }
      },
      saveBank() {
        let cloj = this.priority;
        this.$validator.validateAll().then((valid) => {
          if(valid) {
            this.priority = parseInt(cloj)
            this.save()
          }
        });
      }
    },
    data: () => ({
      headers: [
        { text: "Id", value: "id", sortable: false },
        { text: "Código do Banco",  value: "code", sortable: true },
        {
          text: "Nome",
          align: "left",
          value: "name",
          width: "20%",
          sortable: true
        },
        { text: "Conta", value: "account", sortable: false},
        { text: "Status", value: "status", sortable: true},
        { text: "Observação", value: "observation", sortable: false },
        { text: "Prioridade", value:"priority", sortable: true},
        { text: "Tarifa Percentual", value:"rate_percentage", sortable: false},
        { text: "Tarifa Fixa", value:"rate_flat", sortable: false},
        { text: "Ações",  value: "actions", sortable: false, align: "center"}
      ],
      possible_status: [
        {status: 0, description: 'Ativo'},
        {status: 1, description: 'Alerta'},
        {status: 2, description: 'Inativo'},
      ],
      yes_no: [
        {id: 0, description: 'Não'},
        {id: 1, description: 'Sim'}
      ],
      rules: {
        required: value => !!value || "Required.",
        counter: value => value.length <= 20 || "Max 20 characters"
      },
      valid: false,
      showCKey: false,
      showDKey: false
    }),
    watch: {
      pagination: {
        handler () {
          this.getItems()
        },
      },
    },
  }
</script>