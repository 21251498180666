import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../../config/endPoints';

const defaultItem = {
    bank: null,
    date: null,
    value: 0
};

export default {
    namespaced: true,
    state: {
        dialog_financial: false,
        items: [],
        loading: false,
        loading_financial: false,
        financial_item: {},
        filter: {},
        bank_financial_moviments: null,
        financial_moviments: [],
        pagination: {
            page: 1,
            itemsPerPage: 999
        },
        pagination_financial: {
            page: 1,
            itemsPerPage: 10
        },
        overlay: false,
        totalItems: 0,
        totalItemsFinancial: 0
    },
    mutations: {
        loading: (state, payload) => state.loading = payload.show,
        setPage: (state, payload) => {
            state.pagination.page = payload.page;
            state.pagination.itemsPerPage = payload.itemsPerPage
        },
        loadingFinancial: (state, payload) => state.loading = payload.show,
        setFinancialItem: (state, payload) => state.financial_item = payload.item,
        setFinancialMoviments: (state, payload) => {
            state.financial_moviments = payload.financial_moviments
        },
        setItems: (state, payload) => {
            state.items = payload.items
        },
        setTotalItems: (state, total) => state.totalItems = total,
        setTotalItemsFinancial: (state, total) => state.totalItemsFinancial = total,
        setBankFMoviments: (state, payload) => state.bank_financial_moviments = payload.bank,
        showDialogFinancialMoviments: (state, payload) => state.dialog_financial = payload.show,
        updateField,
    },
    getters: {
        getField,
    },
    actions: {
        add: (state) => {
            state.dialog = true
            state.mode = "ADD"
            state.editedItem = Object.assign({}, defaultItem)
        },
        getItems: ({ commit, state }, payload) => {
            commit('loading', { show: true })
            let url = endPoints.balance;
            let filter = state.filter;
            if (payload.resetPage === true) {
                url += '?page=' + 1 + '&' + 'itemsPerPage=' + 10;
                commit('setPage', { page: 1, itemsPerPage: 10 })
            } else {
                url += '?page=' + state.pagination.page + '&' + 'itemsPerPage=' + state.pagination.itemsPerPage;
            }

            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined) {
                    if (field == "end") {
                        var e = new Date(filter.end)
                        e.setDate(e.getDate() + 1)
                        url += "&" + field + "=" + e.toISOString().substr(0, 10)
                    } else {
                        url += "&" + field + "=" + filter[field]
                    }
                }
            });

            axios.get(url).then(items => {
                commit('setTotalItems', items.data.total)
                commit('loading', { show: false })
                commit('setItems', { items: items.data.data })
            });
        },
        getItemsNoPage: ({ state }) => {
            let url = endPoints.balance;
            let filter = state.filter;
            url += '?page=' + '1' + '&' + 'itemsPerPage=' + '99999';


            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined) {
                    if (field == "end") {
                        var e = new Date(filter.end)
                        e.setDate(e.getDate() + 1)
                        url += "&" + field + "=" + e.toISOString().substr(0, 10)
                    } else {
                        url += "&" + field + "=" + filter[field]
                    }
                }
            });
            return axios.get(url).then(items => {
                return items.data.data
            });
        },
        openFinancialMoviments: ({ commit, dispatch }, payload) => {
            commit('setFinancialItem', { item: payload.item })
            commit('setBankFMoviments', { bank: payload.item.bank })
            dispatch('getFinancialMoviments')
        },
        getFinancialMoviments: ({ commit, state }) => {
            let url = endPoints.financial_moviments;
            let filter = {
                bank: state.bank_financial_moviments
            }

            url += '?page=' + state.pagination_financial.page + '&' + 'itemsPerPage=' + state.pagination_financial.itemsPerPage;

            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined) {
                    url += "&" + field + "=" + filter[field]
                }
            });

            commit('loadingFinancial', { show: true })
            axios.get(url).then(items => {
                commit('showDialogFinancialMoviments', { show: true })
                commit('setTotalItemsFinancial', items.data.total)
                commit('loadingFinancial', { show: false })
                commit('setFinancialMoviments', { financial_moviments: items.data.data })
            });
        },
    }
}