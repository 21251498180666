<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2000"
      v-model="showNotification"
    >
      {{ notificationMessage }}
    </v-snackbar>
    <v-toolbar flat class="elevation-5">
      <div>
        <v-toolbar-title>Fechamento Detalhado Por Clube: {{id}}</v-toolbar-title>
      </div>
      <v-spacer></v-spacer>
      
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :loading="loading"
      :items="items"
      :options.sync="pagination"
      :server-items-length="totalItems"
      :expanded.sync="expanded"
      no-data-text="Nenhum item encontrado"
      class="elevation-10 table-margin"
      show-expand
      :footer-props="{
        'items-per-page-options': [10, 20, 50, 100],
        'items-per-page-text': 'Itens por página',
        'show-first-last-page': true,
        'page-text': `Página ${pagination.page} de ${parseInt(totalItems/pagination.itemsPerPage) + 1}`
      }" 
    >
      <template v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }">
        <v-icon @click="expand(true); getLccde(item);" v-if="item.type && !isExpanded">mdi-chevron-down</v-icon>
        <v-icon @click="expand(false)" v-if="item.type && isExpanded">mdi-chevron-up</v-icon>
      </template>

      <template v-slot:[`item.value`]="{ item }">
        <span>{{
          item.value.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })
        }}</span>
      </template>

      <template v-slot:expanded-item>
          <v-flex xs6 md6 sm6 class="text-center ma-12" v-for="item in itemsLeagueClosureClubEvents" :key="item.id" xs4>
            <v-card>
              <v-row>
                <v-col class="d-flex justify-space-around">
                  <div>
                    <strong>Id:</strong> 
                    {{ item.id }}
                    <br>
                    <strong>IdDespesa:</strong> 
                    {{ item.idDespesa }}
                    <br>
                    <strong>idFechamento:</strong> 
                    {{ item.idFechamento }}
                    <br>
                    <strong>idSubClub:</strong> 
                    {{ item.idSubClub }}
                    <br>
                    <strong>Descrição:</strong> 
                    {{ item.descricao }}
                    <br>
                    <strong>Valor:</strong> 
                    {{ item.valor }}
                    <br>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-flex>
      </template>
    </v-data-table>

  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "LeagueClosureClubDetail",
  computed: {
    ...mapState("league_closure_club_detail", [
      "itemsLeagueClosureClubEvents",
      "item",
      "loading",
      "mode",
      "notificationType",
      "notificationMessage",
      "rowsPerPage",
      "totalItems",
      "items",
      "overlay",
      "showNotification",
      "defaultClubClosureEvent",
    ]),
    ...mapFields("league_closure_club_detail", ['pagination'])
  },
  created() {
    let club_id = this.$route.params.club_id;
    this.id = club_id
    this.getItems({ resetPage: false, club_id: club_id});
  },
  methods: {
    ...mapActions("league_closure_club_detail", [
      "getLeagueClosureClubDetailEvents",
      "getItems",
      "deleteItem",
      "showDialogDelete",
      "closeDialogDelete",
      "viewItem",
    ]),
    reload(){
      let club_id = this.$route.params.club_id;
      this.getItems({ resetPage: false, club_id: club_id});    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      }
      return false;
    },
    getLccde(item) {
      this.getLeagueClosureClubDetailEvents({item: item})
    },
  },
  data: () => ({
    expanded: [],
    headers: [
      { text: "ID", value: "id", sortable: false },
      { text: "Tipo", value: "type", sortable: false },
      { text: "Valor", value: "value", sortable: false },
      { text: '', value: 'data-table-expand' },
    ],
    valid: false,
    timeProps: {
      format: "24hr",
    },
  }),
  watch: {
    pagination: {
      handler() {
        let club_id = this.$route.params.club_id;
        this.getItems({ resetPage: false, club_id: club_id});
      },
    },
  },
};
</script>

<style>

</style>
