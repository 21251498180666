
<template>
  <v-card class="teste">
    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2000"
      v-model="showNotification"
    >
      {{ notificationMessage }}
    </v-snackbar>
    <v-toolbar color="blue" flat dark>
      <v-toolbar-title>
        <template>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>{{ userData.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-toolbar-title>
    </v-toolbar>
    <v-tabs vertical>
      <v-tab>
        <v-icon left> mdi-lock </v-icon>
        Senha
      </v-tab>

      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-text-field
              class="form-profile-edit"
              outlined
              label="Senha Antiga"
              v-model="old_password"
              data-vv-name="old_password"
              @click:append="showCurrent = !showCurrent"
              :type="showCurrent ? 'text' : 'password'"
              :append-icon="showCurrent ? 'mdi-eye' : 'mdi-eye-off'"
            ></v-text-field>

            <v-text-field
              class="form-profile-edit"
              outlined
              label="Nova Senha"
              v-model="initial_password"
              data-vv-name="initial_password"
              @click:append="showCurrent = !showCurrent"
              :type="showCurrent ? 'text' : 'password'"
              :append-icon="showCurrent ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="passwordRules"
            ></v-text-field>

            <v-text-field
              class="form-profile-edit"
              outlined
              label="Confirmar Nova Senha"
              v-model="last_password"
              data-vv-name="last_password"
              @click:append="showCurrent = !showCurrent"
              :type="showCurrent ? 'text' : 'password'"
              :append-icon="showCurrent ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="passwordRules"
            ></v-text-field>

            <v-btn
              color="orange darken-1"
              style="margin-left: 47%; margin-bottom: 30px"
              @click="editPasswordForm"
            >
              <v-icon left> mdi-pencil </v-icon>
              Editar
            </v-btn>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat> </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  created() {
    this.getUser();
  },
  data: () => ({
    valid: true,
    nameRules: [
      (v) => !!v || "Nome é obrigatório",
      (v) =>
        (v && v.length <= 25 && v.length >= 4) ||
        "Nome deve ter entre 4 e 25 caracteres",
    ],
    // {{ prefix && prefix.length ? prefix.length : 0 }}
    // {{ v && v.length ? v.length : 0 }}
    // v = 0 by default to remove error warning
    emailRules: [
      (v) => !!v || "E-mail é obrigatório",
      (v) => /.+@.+\..+/.test(v) || "E-mail inválido",
    ],
    passwordRules: [
      (v) => !!v || "Senha é obrigatória",
      (v) => v.length >= 8 || "Senha deve possuir mais de 7 caracteres",
    ],
    showCurrent: false,
  }),
  computed: {
    ...mapState("login", ["userData"]),
    ...mapState("profile_edit", [
      "chatDialog",
      "notificationType",
      "notificationMessage",
    ]),
    ...mapFields("profile_edit", ["showNotification"]),
    getColor() {
      return this.userData.color;
    },
  },
  methods: {
    ...mapMutations("profile_edit", ["showChatDialog"]),
    ...mapActions("profile_edit", [
      "editUserName",
      "editEmail",
      "editPassword",
      "editUserImg",
    ]),
    ...mapActions("login", ["getUser"]),
    editUserNameForm() {
      if (this.name && this.name != undefined) {
        this.editUserName({ name: this.name, user_id: this.userData.id });
      }
    },
    editPasswordForm() {
      if (
        this.old_password &&
        this.old_password != undefined &&
        this.initial_password &&
        this.initial_password != undefined &&
        this.last_password &&
        this.last_password != undefined
      ) {
        this.editPassword({
          last_password: this.last_password,
          initial_password: this.initial_password,
          old_password: this.old_password,
          user_id: this.userData.id,
        });
      }
    },
    editUserImgForm() {
      this.editUserImg({ image: this.img, user_id: this.userData.id });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-profile-edit {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  margin-top: 15px;
  width: 350px;
}
</style>
