import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../../config/endPoints';

const getDateHour = () => {
    var d = new Date()
    var l = d.toLocaleDateString()
    var date = l.substr(-4) + "-" + l.substr(3, 2) + "-" + l.substr(0, 2)
    var c = date + " " + d.toLocaleTimeString()
    return new Date(c.substr(0, 13) + ":00:00")
}

const defaultItem = {
    description: "",
    start_date: "",
    finish_date: "",
    type_period: 0,
    last_period: null
};

const spreadsheet_headers_config_import = [
    { database_field: "Club", description: "Clube", column_sheet: 0, type: "int" },
    { database_field: "IdPppoker", description: "Id do Jogador", column_sheet: 1, type: "string" },
    { database_field: "Nick", description: "Nick", column_sheet: 4, type: "string" },
    { database_field: "IdPppokerAgent", description: "Id do Agente", column_sheet: 6, type: "string" },
    { database_field: "PlayerGains", description: "Total de Ganhos do Jogador", column_sheet: 26, type: "float" },
    { database_field: "PlayerTax", description: "Total de Rake do Jogador", column_sheet: 45, type: "float" },
    { database_field: "PlayerSpin", description: "Total de Spin Up", column_sheet: 46, type: "float" },
    { database_field: "HandsPlayed", description: "Mãos Jogadas", column_sheet: 66, type: "int" }
]

export default {
    namespaced: true,
    state: {
        dialog: false,
        dialogFilter: false,
        dialogDelete: false,
        dialogAutoClosure: false,
        dialogSpreadsheetClosure: false,
        dialogPlayersExclusion: false,
        dialogErrorsPeriod: false,
        dialogUpdateExcel: false,
        dialogOverlay: false,
        dialogProcessRelateAgentsPlayer: false,
        editedItem: {},
        errors: [],
        errors_period: [],
        autoClosureItem: {},
        start_formatted: '',
        finish_formatted: '',
        items: [],
        datesItems: [],
        overlay_items: [],
        item_spreadsheet: {},
        itemToDelete: {},
        loading: false,
        mode: 'LIST',
        notificationType: '',
        notificationMessage: '',
        processAgentPlayerItem: {},
        page: 1,
        filter: {
            id: "",
            description: "",
            start: "",
            type_period: "",
        },
        overlay: false,
        pagination: {
            page: 1,
            itemsPerPage: 10
        },
        paginationDates: {
            page: 1,
            itemsPerPage: 10
        },
        periods_closure: [],
        spreadsheet_headers_config_import: spreadsheet_headers_config_import,
        selected_spreadsheet: null,
        spreadsheet_period: [],
        start_line: 5,
        slots: [],
        showNotification: false,
        totalItems: 0,
        totalDatesItems: 0,
        type_import: 2,
        club_import: null,
        rowsPerPage: 10,
        overlayData: {},
        newOverlay: {
            club: null,
            value: 0,
            period: null
        },
        slotsOverlay: []
    },
    mutations: {
        add: (state) => {
            state.mode = "ADD"
            state.editedItem = Object.assign({}, defaultItem)
            state.editedItem.date_picker = getDateHour()
            state.dialog = true
        },
        edit: (state, payload) => {
            state.editedItem = Object.assign({}, payload.item)
            state.editedIndex = state.items.indexOf(payload.item)
            let start = new Date(payload.item.start_date + " 12:00:00")
            let finish = new Date(payload.item.finish_date + " 12:00:00")
            state.start_formatted = start.toLocaleDateString()
            state.finish_formatted = finish.toLocaleDateString()
            if (payload.item.last_period) {
                state.periods_closure = [{ id: payload.item.last_period, description: payload.item.last_period_name }]
            }
            state.dialog = true
            state.mode = "EDIT"
        },
        closeDialog: (state) => {
            state.dialog = false
            state.editedItem = Object.assign({}, defaultItem)
            state.editedIndex = -1
            state.errors = [];
        },
        loading: (state, loading) => state.loading = loading,
        overlay: (state, payload) => state.overlay = payload.show,
        setErrorMessage: (state, message) => state.errorMessage = message,
        setMode: (state, mode) => state.mode = mode,
        setSelectedSpreadsheet: (state, payload) => state.selected_spreadsheet = payload.spreadsheet,
        setItems: (state, items) => state.items = items,
        setDatesItems: (state, datesItems) => state.datesItems = datesItems,
        setOverlayItems: (state, items) => state.overlay_items = items,
        setItemSpreadsheet: (state, payload) => state.item_spreadsheet = payload.item,
        setItemsSpreadsheet: (state, payload) => state.spreadsheet_period = payload.items,
        setItemToDelete: (state, item) => state.itemToDelete = item,
        setFilledOverlay: (state, payload) => state.overlayData = payload,
        setOverlayItem: (state, payload) => state.newOverlay = payload,
        setAutoClosureItem: (state, item) => state.autoClosureItem = item,
        setEditedItem: (state, item) => state.editedItem = item,
        setTotalItems: (state, total) => state.totalItems = total,
        setTotalDatesItems: (state, total) => state.totalDatesItems = total,
        setRowsPerPage: (state, rowsPerPage) => state.rowsPerPage = rowsPerPage,
        showFilter: (state, payload) => state.dialogFilter = payload.show,
        showDialog: (state, show) => state.dialog = show,
        setDialogDelete: (state, show) => state.dialogDelete = show,
        setDialogAutoClosure: (state, show) => state.dialogAutoClosure = show,
        setDialogPlayersExclusion: (state, show) => state.dialogPlayersExclusion = show,
        setDialogProcessRelateAgentPlayer: (state, payload) => state.dialogProcessRelateAgentsPlayer = payload.show,
        setDialogSpreadsheetClosure: (state, payload) => {
            state.dialogSpreadsheetClosure = payload.show
            state.editemItem = !payload.show ? defaultItem : state.editemItem
        },
        setProcessAgentPlayerItem: (state, payload) => state.processAgentPlayerItem = payload.item,
        showDialogOverlay: (state, payload) => state.dialogOverlay = payload.show,
        setErrorsPeriod: (state, payload) => {
            state.dialogErrorsPeriod = payload.show,
                state.errors_period = payload.errors
        },
        setHeadersSpreadsheet: (state, payload) => {
            state.spreadsheet_headers_config_import = payload.headers
        },
        setPeriodsClosure: (state, payload) => state.periods_closure = payload.periods,
        setDialogUpdateExcel: (state, payload) => {
            state.dialogUpdateExcel = payload.show
        },
        setSlotsOverlay: (state, payload) => {
            state.slotsOverlay = payload.slots
        },
        setSlots: (state, payload) => state.slots = payload.slots,
        showErrorNotification: (state, show) => state.errorNotification = show,
        showSuccessNotification: (state, show) => state.successNotification = show,
        showLoading: (state, show) => state.loading = show,
        showNotification: (state, payload) => {
            state.showNotification = payload.show,
                state.notificationMessage = payload.message,
                state.notificationType = payload.type
        },
        updateField
    },
    getters: {
        getField,
    },
    actions: {
        getDataToFillOverlay: ({ commit, state }) => {

            let url = endPoints.period_closure
            let overlayData = state.newOverlay
            url += '/overlay_data'
            
            overlayData.club = parseInt(overlayData.club)
            overlayData.period = parseInt(overlayData.period)
            
            url += '/' + overlayData.period + '/' + overlayData.club

            commit('loading', true)
            axios.get(url).then(items => {
                commit('setFilledOverlay', items.data)
                commit('setOverlayItem', items.data)
                commit('loading', false)
            }).catch(() => {
                commit('loading', false)
                let notification = {
                    show: true,
                    message: "Erro ao trazer dados do overlay",
                    type: "error"
                }
                commit('showNotification', notification)
            });
        },
        calcSpreadsheetClosure: ({ commit, dispatch }, payload) => {
            let url = endPoints.pppoker_financial + "/weekly_closure_players_agents_spreadsheet"
            let closure = {
                period: payload.item.period,
                id_spreadsheet: payload.item.id
            }
            commit('overlay', { show: true })
            axios.post(url, closure).then((response) => {
                if (response) {
                    if (response.data) {
                        let errors = response.data
                        if (errors.length != 0) {
                            let errors_period = []
                            errors.forEach(e => { errors_period.push({ error: e }) })
                            commit('setErrorsPeriod', { errors: errors_period, show: true })
                        }
                    }
                }
                dispatch('getSpreadsheetClosure')
                dispatch('getItems')
                commit('overlay', { show: false })
                let notification = {
                    show: true,
                    message: "Planilha computada com sucesso.",
                    type: "success"
                }
                commit('showNotification', notification)
            }).catch(() => {
                let notification = {
                    show: true,
                    message: "Ocorreu um erro ao computar a planilha. Tente novamente mais tarde.",
                    type: "success"
                }
                commit('showNotification', notification)
            });
        },
        calcSpreadsheetClosureMaster: ({ commit, dispatch }, payload) => {
            let url = endPoints.pppoker_financial + "/weekly_closure_agents_master"
            let closure = {
                period: payload.item.id
            }
            commit('overlay', { show: true })
            axios.post(url, closure).then((response) => {
                dispatch('getItems')
                commit('overlay', { show: false })
                let notification = {
                    show: true,
                    message: "Período computado com sucesso.",
                    type: "success"
                }
                commit('showNotification', notification)
                if (response) {
                    if (response.data) {
                        let errors = response.data
                        if (errors.length != 0) {
                            let errors_period = []
                            errors.forEach(e => { errors_period.push({ error: e }) })
                            commit('setErrorsPeriod', { errors: errors_period, show: true })
                        }
                    }
                }
            }).catch((error) => {
                commit('overlay', { show: false })
                console.log(error)
                let notification = {
                    show: true,
                    message: "Erro ao computar o periodo: " + error?.response?.data?.message,
                    type: "error"
                }
                commit('showNotification', notification)
            });
        },
        closeDeleteDialog: ({ commit }) => {
            commit('setDialogDelete', false)
            commit('setItemToDelete', {})
        },
        closeDialogAutoClosure: ({ commit }) => {
            commit('setDialogAutoClosure', false)
            commit('setAutoClosureItem', {})
        },
        closeDialogProcessRelateAgentPlayer: ({ commit }) => {
            commit('setDialogProcessRelateAgentPlayer', { show: false })
            commit('setProcessAgentPlayerItem', {})
        },
        getSlotsOverlayInPeriod: ({ commit }, payload) => {
            let period = '';

            if(payload.period) {
                period = payload.period
            }

            const url = endPoints.period_closure + "/slots_overlay/" + period
            axios.get(url).then((response) => {
                commit('setSlotsOverlay', {slots: response.data})
            }).catch(() => {
                let notification = {
                    show: true,
                    message: "Não foi possivel buscar os slots do periodo!",
                    type: "error"
                }
                commit('showNotification', notification)
            });
        },
        openDialogOverlay: ({ commit, dispatch, state }, payload) => {
            state.newOverlay.period = payload.item.id
            const url = endPoints.period_closure + "/overlay/" + payload.item.id
            axios.get(url).then((response) => {
                dispatch('getSlotsOverlayInPeriod', { period: payload.item.id })
                commit('showDialogOverlay', { show: true })
                commit("setOverlayItems", response.data)
            }).catch((error) => {
                console.log(error)
                let notification = {
                    show: true,
                    message: "Erro ao buscar os overlays do periodo",
                    type: "error"
                }
                commit('showNotification', notification)
            })
        },
        openDialogConfimRelateAgent({ commit }, payload) {
            commit('setDialogProcessRelateAgentPlayer', { show: true })
            commit('setProcessAgentPlayerItem', payload)
        },
        closeDialogPlayersExclusion: ({ commit }) => {
            commit('setDialogPlayersExclusion', false)
            commit('setEditedItem', {})
        },
        openDialogPlayersExclusion: ({ commit }, payload) => {
            commit('setDialogPlayersExclusion', true)
            commit('setEditedItem', payload.item)
        },
        playersExclusion: ({ state, dispatch, commit }) => {
            commit('overlay', { show: true })
            const url = `${endPoints.period_closure}/players_exclusion/${state.editedItem.id}`
            axios.post(url).then(() => {
                let notification = {
                    show: true,
                    message: "Exclusão de jogadores iniciada com sucesso",
                    type: "success"
                }
                commit('showNotification', notification)
                dispatch('closeDialogPlayersExclusion')
                commit('overlay', { show: false })
            }).catch(() => {
                let notification = {
                    show: true,
                    message: "Não foi possível iniciar a exclusão de jogadores!",
                    type: "error"
                }
                commit('showNotification', notification)
                dispatch('closeDialogPlayersExclusion')
                commit('overlay', { show: false })
            });
        },
        deleteOverlay: ({ commit, dispatch }, payload) => {
            const url = endPoints.period_closure + "/overlay/" + payload.item.id

            axios.delete(url).then(() => {
                let notification = {
                    show: true,
                    message: "Overlay deletado com sucesso",
                    type: "success"
                }
                commit('showNotification', notification)
                axios.get(endPoints.period_closure + "/overlay/" + payload.item.period).then((response) => {
                    dispatch('getSlotsOverlayInPeriod', { period: payload.item.period })
                    commit("setOverlayItems", response.data)
                })
            }).catch(() => {
                let notification = {
                    show: true,
                    message: "Não foi possivel deletar o overlay!",
                    type: "error"
                }
                commit('showNotification', notification)
            });
        },
        closeDialogOverlay: ({ commit }) => {
            commit('showDialogOverlay', { show: false })
            commit('setOverlayItem', { club: null, period: null, value: 0 })
        },
        processAgentsPlayers: ({ state, dispatch, commit }) => {
            console.log(state.processAgentPlayerItem)
            const url = `${endPoints.period_closure}/process_agents_players/${state.processAgentPlayerItem.id}`
            axios.post(url).then((response) => {
                let notification = {
                    show: true,
                    message: "Relacionamento efetuado com sucesso",
                    type: "success"
                }
                commit('showNotification', notification)
                dispatch('closeDialogProcessRelateAgentPlayer')
                commit('overlay', { show: false })
                let errors = response.data
                if (errors) {
                    if (errors.length != 0) {
                        let errors_period = []
                        errors.forEach(e => { errors_period.push({ error: e }) })
                        commit('setErrorsPeriod', { errors: errors_period, show: true })
                    }
                }
            }).catch(() => {
                let notification = {
                    show: true,
                    message: "Ocorreram erros ao efetuar o relacionamento!",
                    type: "error"
                }
                commit('showNotification', notification)
                dispatch('closeDialogProcessRelateAgentPlayer')
                commit('overlay', { show: false })
            });
        },
        saveNewOverlay: ({ state, commit, dispatch }) => {
            const url = endPoints.period_closure + "/new_overlay"

            state.newOverlay.period = parseInt(state.newOverlay.period)
            state.newOverlay.value = parseFloat(state.newOverlay.value)
            
            axios.post(url, state.newOverlay).then(() => {
                let notification = {
                    show: true,
                    message: "Overlay salvo com sucesso",
                    type: "success"
                }
                commit('showNotification', notification)
                dispatch('getSlotsOverlayInPeriod', { period: state.newOverlay.period })
                state.newOverlay.club = null
                axios.get(endPoints.period_closure + "/overlay/" + state.newOverlay.period).then((response) => {
                    commit("setOverlayItems", response.data)
                })
            }).catch(() => {
                let notification = {
                    show: true,
                    message: "Não foi possivel salvar o overlay!",
                    type: "error"
                }
                commit('showNotification', notification)
            });
        },
        deleteItem: ({ dispatch, commit, state }) => {
            commit('loading', true)
            axios.delete(endPoints.period_closure + "/" + state.itemToDelete.id, state.itemToDelete).then(() => {
                dispatch('closeDeleteDialog')
                return dispatch('getItems');
            }).catch(() => {
                let notification = {
                    show: true,
                    message: "Não foi possivel excluir o periodo selecionado.",
                    type: "error"
                }
                commit('showNotification', notification)
                dispatch('closeDeleteDialog')
                return dispatch('getItems');
            });
        },
        getItems: ({ commit, state }) => {
            commit('loading', true)
            let url = endPoints.period_closure;
            let filter = state.filter;
            url += '?page=' + state.pagination.page + '&' + 'itemsPerPage=' + state.pagination.itemsPerPage;

            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
                    if (field == "start") {
                        var e = new Date(filter[field])

                        url += "&" + field + "=" + e.toISOString().substr(0, 10)
                    } else {
                        url += "&" + field + "=" + filter[field]
                    }
                }
            });
            axios.get(url).then(items => {
                commit('setTotalItems', items.data.total)
                commit('loading', false)
                commit('setItems', items.data.data)
            }).catch(() => {
                commit('loading', false)

                let notification = {
                    show: true,
                    message: "Erro ao carregar períodos de fechamento",
                    type: "error"
                }
                commit('showNotification', notification)
            });
        },
        getDatesItems: ({ commit, state }) => {
            commit('loading', true)
            let url = endPoints.period_closure_dates;
            let filter = state.filter;
            url += '?page=' + state.paginationDates.page + '&' + 'itemsPerPage=' + state.paginationDates.itemsPerPage;

            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
                    if (field == "start") {
                        var e = new Date(filter[field])

                        url += "&" + field + "=" + e.toISOString().substr(0, 10)
                    } else {
                        url += "&" + field + "=" + filter[field]
                    }
                }
            });
            axios.get(url).then(items => {
                console.log(items)
                commit('setTotalDatesItems', items.data.total)
                commit('loading', false)
                commit('setDatesItems', items.data.data)
            }).catch(() => {
                commit('loading', false)

                let notification = {
                    show: true,
                    message: "Erro ao carregar datas de fechamento",
                    type: "error"
                }
                commit('showNotification', notification)
            });
        },
        getItemsNoPage: ({ state }) => {
            let url = endPoints.period_closure;
            let filter = state.filter;
            url += '?page=' + '1' + '&' + 'itemsPerPage=' + '99999';

            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
                    if (field == "end" || field == "start") {
                        var e = new Date(filter[field])

                        url += "&" + field + "=" + e.toISOString().substr(0, 10) + " " + e.toLocaleTimeString()
                    } else {
                        url += "&" + field + "=" + filter[field]
                    }
                }
            });
            return axios.get(url).then(items => {
                return items.data.data
            });
        },
        getPeriodClosureByName: ({ commit }, payload) => {
            commit('loading', true)
            let url = endPoints.period_closure;
            url += '?page=' + 1 + '&' + 'itemsPerPage=' + 15;
            url += '&description=' + payload.search
            axios.get(url).then(items => {
                commit('loading', false)
                commit('setPeriodsClosure', { periods: items.data.data })
            });
        },
        importSpreadsheet: ({ commit, dispatch, state }) => {
            let url = endPoints.pppoker_financial + "/weekly_spreadsheet"
            let formData = new FormData();
            let headers = [];
            state.spreadsheet_headers_config_import.forEach((h) => {
                h.column_spreadsheet = parseInt(h.column_spreadsheet)
                headers.push(h)
            })
            formData.append('file', state.selected_spreadsheet);
            formData.append('period', state.item_spreadsheet.id);
            formData.append('headers', JSON.stringify(headers))
            formData.append('start_line', state.start_line)
            formData.append('type_import', state.type_import)
            formData.append('club_import', state.club_import)
            formData.append('type_period', state.editedItem.type_period)
            commit('overlay', { show: true })
            axios.post(url, formData).then((response) => {
                commit('overlay', { show: false })
                let errors = response.data.errors
                if (errors) {
                    if (errors.length != 0) {
                        let errors_period = []
                        errors.forEach(e => { errors_period.push({ error: e }) })
                        commit('setErrorsPeriod', { errors: errors_period, show: true })
                    }
                }
   
                let notification = {
                    show: true,
                    message: "Planilha importada com sucesso.",
                    type: "success"
                }
                commit('setSelectedSpreadsheet', { spreadsheet: null })
                commit('showNotification', notification)
                commit('setDialogSpreadsheetClosure', { show: false })
                dispatch('getSpreadsheetClosure')
                dispatch('getItems')
            }).catch(() => {
                commit('overlay', { show: false })
                let notification = {
                    show: true,
                    message: "Ocorreu um erro ao importar a planilha. Contate o administrador do sistema.",
                    type: "error"
                }
                commit('showNotification', notification)
            }).catch((error) => {
                console.log(error)
                commit('overlay', { show: false })
                let notification = {
                    show: true,
                    message: "Ocorreu um erro ao importar a planilha. Contate o administrador do sistema.",
                    type: "error"
                }
                commit('showNotification', notification)
            });
        },
        getSpreadsheetClosure: ({ commit, state }) => {
            let url = endPoints.pppoker_financial + "/spreadsheet_closure/" + state.item_spreadsheet.id + "?type_spreadsheet=1";
            axios.get(url).then(items => {
                commit('setItemsSpreadsheet', { items: items.data.data })
            });
        },
        showDialogSpreadsheetClosure: ({ commit }, payload) => {
            commit('setEditedItem', payload.item)
            let url = endPoints.pppoker_financial + "/spreadsheet_closure/" + payload.item.id + "?type_spreadsheet=1";
            axios.get(url).then(items => {
                commit('setHeadersSpreadsheet', { headers: items.data.headers })
                commit('setItemSpreadsheet', { item: payload.item })
                commit('setItemsSpreadsheet', { items: items.data.data })
                commit('setDialogSpreadsheetClosure', { show: true })
            });
        },
        showDeleteDialog: ({ commit }, item) => {
            commit('setDialogDelete', true)
            commit('setItemToDelete', item)
        },
        showDialogAutoClosure: ({ commit }, item) => {
            commit('setDialogAutoClosure', true)
            commit('setAutoClosureItem', item)
        },
        viewItem({ commit, dispatch }, payload) {
            commit('agents_closure/setPeriodClosure', { period_closure: payload }, { root: true })
            dispatch('menu/openLink', '/agents_closure/' + payload.id, { root: true })
        },
        viewItemDates({ commit, dispatch }, payload) {
            console.log(payload)
            commit('agents_closure/setPeriodClosureDates', { period_closure_dates: payload }, { root: true })
            dispatch('menu/openLink', `/agents_closure/dates/${payload.start_date}&${payload.finish_date} `, { root: true })
        },
        updatePendingPayments: ({ commit }) => {
            axios.put(endPoints.annotated + "/pending_payments", {}).then(() => {
                let notification = {
                    show: true,
                    message: "Semana atual convertida em pagamento pendente.",
                    type: "success"
                }
                commit('showNotification', notification)
            }).catch(() => {
                let notification = {
                    show: true,
                    message: "Erro ao realizar a conversão.",
                    type: "error"
                }
                commit('showNotification', notification)
            })
        },
        updateExcel: ({ commit, state }) => {
            var url = endPoints.period_closure + '/update_excel'
            let formData = new FormData();
            let headers = [];
            state.spreadsheet_headers_config_import.forEach((h) => {
                h.column_spreadsheet = parseInt(h.column_spreadsheet)
                headers.push(h)
            })
            formData.append('file', state.excel_for_update);
            formData.append('headers', JSON.stringify(headers))
            console.log(state.excel_for_update)
            
            commit('overlay', { show: true })
            axios.post(url, formData).then((response) => {
                console.log(response)
            })
        },
        save: ({ dispatch, commit, state }) => {
            var url = endPoints.period_closure;
            let func = '';
            if (state.mode == 'ADD') {
                func = axios.post;
                url = endPoints.period_closure;
            } else {
                func = axios.put;
                url += '/' + state.editedItem.id;
            }

            func(url, state.editedItem).then(
                () => {
                    dispatch('getItems')
                    let notification = {
                        show: true,
                        message: "Periodo de Fechamento salvo com sucesso.",
                        type: "success"
                    }
                    commit('closeDialog')
                    commit('showNotification', notification)
                },
                error => {
                    let notification = {
                        show: true,
                        message: error.response.data.message,
                        type: "error"
                    }
                    commit('showNotification', notification)
                }
            );
        },
        autoClosure: ({ commit, state }) => {
            commit('setDialogAutoClosure', false)
            commit("loading", true);
            commit('overlay', { show: true })
            var url = endPoints.period_closure + "/auto_closure/" + state.autoClosureItem.id;

            axios.post(url).then(
                () => {
                    let notification = {
                        show: true,
                        message: "Fechamento Automático finalizado com sucesso.",
                        type: "success"
                    }
                    commit("showNotification", notification)
                    commit("loading", false)
                    commit('overlay', { show: false })
                },
                error => {
                    let notification = {
                        show: true,
                        message: error.response.data.message,
                        type: "error"
                    }
                    commit('showNotification', notification)
                    commit("loading", false)
                    commit('overlay', { show: false })
                }
            )
        }
    }
}