<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-toolbar flat class="elevation-5">
        <div>
            <v-toolbar-title>Saldo Financeiro</v-toolbar-title>
        </div>
      <v-spacer></v-spacer>
      <div class="button-toolbar">
        <!-- <download-excel
          :before-generate="generateSheet"
          :before-finish="finishSheet"
          :name="getSheetName()"
          :fetch="getItemsNoPage"
          :export-fields="sheet_fields"
          class="excel"
          worksheet="Periodos"
          type="xls"
        >
          <v-btn color="black" dark class="ma-2" small>
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </download-excel> -->
        <v-tooltip bottom>
          <template v-slot:activator="{on, attrs}">
            <v-btn color="black" dark class="ma-2" @click="downloadXlsx({ show: true })" v-bind="attrs" v-on="on" small>
              <v-icon>mdi-file-excel</v-icon>
            </v-btn>
          </template>
          <span>Gerar Planilha</span>
        </v-tooltip>
        <v-btn color="black" dark class="ma-2" @click="getItems" small>
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </div>
    </v-toolbar>
    <v-spacer></v-spacer>
    <v-expansion-panels v-model="panel" multiple>
        <v-expansion-panel value="api">
            <v-expansion-panel-header>
                Bancos Api <v-spacer></v-spacer> 
                Soma Saldos:<br>
                <div style="display: contents; color:green">{{ " " + balanceApi.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table
                :headers="headers"
                :loading="loading"
                :items="itemsApi"
                :options.sync="pagination" 
                :server-items-length="totalItemsApi"
                :item-class= "getClassBalance"  
                no-data-text="Nenhum saldo encontrado"
                disable-pagination
                hide-default-footer
                class="elevation-10 table-margin"
              >
                <template v-slot:[`item.api_value`]="{ item }">
                  <span>{{ item.api_value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
                </template>
                <template v-slot:[`item.value`]="{ item }">
                  <span>{{ item.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
                </template>
                <template v-slot:[`item.api_value`]="{ item }">
                  <span>{{ item.api_value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
                </template>
                <template v-slot:[`item.pending_value`]="{ item }">
                  <span>{{ item.pending_value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
                </template>
                <template v-slot:[`item.difference_value`]="{ item }">
                  <span>{{ item.difference_value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
                </template>
                <template v-slot:[`item.date`]="{ item }">
                  <span>{{ getDate(item.date)}}</span>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon small class="mr-2" @click="openFinancialMoviments({item: item})">mdi-information</v-icon>
                </template>
              </v-data-table>
            </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel value="others">
            <v-expansion-panel-header
                >Outros Bancos</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <v-data-table
                :headers="headers"
                :loading="loading"
                :items="otherItems"
                :options.sync="pagination" 
                :server-items-length="totalOtherItems"
                :item-class= "getClassBalance"  
                no-data-text="Nenhum saldo encontrado"
                disable-pagination
                hide-default-footer
                class="elevation-10 table-margin"
              >
                <template v-slot:[`item.value`]="{ item }">
                  <span>{{ item.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
                </template>
                <template v-slot:[`item.api_value`]="{ item }">
                  <span>{{ item.api_value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
                </template>
                <template v-slot:[`item.pending_value`]="{ item }">
                  <span>{{ item.pending_value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
                </template>
                <template v-slot:[`item.difference_value`]="{ item }">
                  <span>{{ item.difference_value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
                </template>
                <template v-slot:[`item.date`]="{ item }">
                  <span>{{ getDate(item.date)}}</span>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon small class="mr-2" @click="openFinancialMoviments({item: item})">mdi-information</v-icon>
                </template>
              </v-data-table>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
    
     
    <v-dialog :value="dialog_financial" @click:outside="showDialogFinancialMoviments({show: false})" width="100%">
      <v-toolbar flat>
          <div class="toolbar-balance">
            <v-toolbar-title>
              <div class="toolbar-left">
                Movimentações {{ financial_item.bank_name }} 
              </div>
              <div class="toolbar-right" :class="{'row-red': financial_item.value < 0, 'row-green': financial_item.value >= 0}">Saldo: {{ financial_item.value ? financial_item.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ''}}</div>
            </v-toolbar-title>
          </div>
      </v-toolbar>
      <v-data-table
        :headers="headers_financial"
        :loading="loading_financial"
        :items="financial_moviments"
        :options.sync="pagination_financial" 
        :server-items-length="totalItemsFinancial"
        :item-class= "getClassColor"     
        no-data-text="Nenhum movimentação encontrada"
        class="elevation-10"
      >
        <template v-slot:[`item.value`]="{ item }">
          <span>{{ getTextValue(item.type, item.value) }}</span>
        </template>
        <template v-slot:[`item.date_start`]="{ item }">
          <span>{{ getDate(item.date_start)}}</span>
        </template>
        <template v-slot:[`item.finish_at`]="{ item }">
          <span>{{ getDate(item.finish_at)}}</span>
        </template>
        <template v-slot:[`item.type`]="{ item }">
          <span>{{ getType(item.type)}}</span>
        </template>
      </v-data-table>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields'
// import JsonExcel from 'vue-json-excel'
import { utils, writeFile } from "xlsx";


export default {
    name: "SaldoFinanceiro",
    created() {
      this.getItems({resetPage: false})
    },
    computed: {
      ...mapState('balance', ['financial_item', 'financial_moviments', 'items', 'totalItems', 'totalItemsFinancial']),
      ...mapFields('balance', ['dialog', 'dialog_financial', 'loading', 'loading_financial', 'pagination', 'overlay', 'pagination_financial']),
      itemsApi() {
        let apiItems = []
        this.items.forEach(item => {
          if (item.use_api == 1) {
            apiItems.push(item)
          }
        })
        return apiItems
      },
      otherItems() {
        let otherItems = []
        this.items.forEach(item => {
          if (item.use_api == 0) {
            otherItems.push(item)
          }
        })
        return otherItems
      },
      totalOtherItems() {
        let otherItems = []
        this.items.forEach(item => {
          if (item.api_value == 0) {
            otherItems.push(item)
          }
        })
        return otherItems.length
      },
      totalItemsApi() {
        let apiItems = []
        this.items.forEach(item => {
          if (item.api_value > 0) {
            apiItems.push(item)
          }
        })
        return apiItems.length
      },
      balanceApi() {
        let balance = 0
        this.items.forEach(item => {
          if (item.api_value > 0) {
            balance += item.api_value
          }
        })
        return balance
      },
    },
    components: {
      // "download-excel": JsonExcel,
    },
    data: () => ({
      panel: [0],
      headers: [
        { text: "Banco", value: "bank_name", sortable: false },
        { text: "Valor Api", value: "api_value", sortable: false },
        { text: "Valor Sistema", value: "value", sortable: false },
        { text: "Valor em Aberto", value: 'pending_value', sortable: false}, 
        { text: "Diferença", value: "difference_value", sortable: false },
        { text: "Data", value: "date", sortable: false },
        { text: "Ações", value: "actions", sortable: false }
      ],
      headers_financial: [
        { text: 'Tipo', value: "type", sortable: false },
        { text: 'Valor', value: "value", sortable: false },
        { text: 'Descrição', value: "description", sortable: false },
        { text: 'Inicio', value: "date_start", sortable: false },
        { text: 'Fim', value: "finish_at", sortable: false },
        { text: 'Clube', value: "instance_name", sortable: false },
        { text: 'Criado Por', value: "user_start_name", sortable: false },
        { text: 'Finalizado Por', value: "user_finish_name", sortable: false },
      ],
      sheet_fields: {
        Banco: {
          field: 'bank_name',
        },
        Valor: {
          field: "value",
          callback: (value) => {
            if (value) {
              let real = value.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              });
              if(value < 0 ) {
                return real.substr(4);
              }
              return real.substr(3);
            }
            return 0;
          },
        },
        Data: {
          field: 'date'
        },
      },
    }),
    methods: {
      ...mapMutations('balance', ['showDialogFinancialMoviments']),
      ...mapActions('balance', ['getItems', 'getItemsNoPage', 'getFinancialMoviments', 'openFinancialMoviments']),
      getClassColor(item) {
        if(item.type == 0 || item.type == 1) {
          return 'row-red'
        } else {
          return 'row-green'
        }
      },
      getClassBalance(item) {
        if(item.value < 0) {
          return 'row-red'
        } else {
          return 'row-green'
        }
      },
      getDate(date) {
        if (date) {
          var d = new Date(date);
          return d.toLocaleDateString() + " " + d.toLocaleTimeString();
        }
        return "";
      },
      getType(type) {
        let types = ['Saques', 'Despesas', 'Depósito', 'Ajuste']
        return types[type]
      },
      getSheetName() {
        var date = new Date();
        var name =
          "saldo_financeiro_" +
          date.toLocaleDateString() +
          " " +
          date.toLocaleTimeString();
        return name + ".xls";
      },
      finishSheet() {
        this.overlay = false;
      },
      generateSheet() {
        this.overlay = true;
      },
      getTextValue(type, value) {
        if(type == 0 || type == 1) {
          return '(-) ' + value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        } else {
          return '(+) ' + value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        }
      },
      async downloadXlsx() {
        this.generateSheet()
        try {
          let itemsNoPage = await this.getItemsNoPage();
          if(itemsNoPage) {
            const wb = utils.book_new();
            let items = itemsNoPage.map(row => ({
              "Banco":  row.bank_name,
              "Valor":  this.valueXLSX(row.value),
              "Data": row.date,
            }))
            const ws = utils.json_to_sheet(items);
            utils.book_append_sheet(wb, ws, "Periodos");
            await writeFile(wb, this.getSheetName());
          }
        } catch (error) {
          this.finishSheet()
        } finally {
          this.finishSheet()
        }
      },
      valueXLSX: (value) => {
        if (value) {
          let real = value.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          });
          if(value < 0 ) {
            return real.substr(4);
          }
          return real.substr(3);
        }
        return 0;
      },
    },
    watch: {
      pagination_financial: {
        handler() {
          this.getFinancialMoviments()
        }
      }
  },
};
</script>

<style scoped>
  @import './css/balance.css';
</style>