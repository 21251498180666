<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-card class="elevation-10"> 
      <v-card-title class="text-center justify-center">
        Endereços
      </v-card-title>
      <v-btn color="black"  @click="add" dark class="add-align-right" small>
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-data-table
        :headers="headers"
        :loading="loading"
        :items="items"
        :server-items-length="totalItems"
        hide-default-footer
        no-data-text="Nenhum endereço encontrado"
        class="elevation-10 table-margin"
      >
        <template v-slot:[`item.type`]="{ item }">
          {{ getType(item.type) }}
        </template>
        <template v-slot:[`item.country`]="{ item }">
          {{ getCountryByCode(item.country) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="edit({item: item})">mdi-pencil</v-icon>
          <v-icon small class="mr-2" @click="showDeleteDialog(item)">mdi-delete</v-icon>
        </template>
    </v-data-table>

    <v-dialog :value="dialog" @click:outside="closeDialog" width="1280">
      <address-player-form></address-player-form>
    </v-dialog>

    <v-dialog :value="dialogDelete" persistent width="300">
      <v-card>
        <v-card-title class="headline">Confirmar exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn
              text
              @click="deleteDialog({show: false, item: {}})"
            >Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn
              text
              type="submit"
              right
              @click="deleteItem"
            >Confirmar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import AddressPlayerForm from './AddressPlayerForm.vue';
import countrys from "./countrys"

export default {
    name: "AddressPlayer",
    computed: {
      ...mapState("address_player", ["loading", "dialog", "dialogDelete", "overlay", "mode", "items", "totalItems"]),
    },
    components: {
      "address-player-form": AddressPlayerForm, 
    },
    created() {
      this.getItems({player_id: this.$props.player_id})
      this.$validator.extend("required", {
        getMessage: (field, [label]) => label + " deve ser informado",
        validate: (_, [, field]) => {
            if (this[field] || this[field] === 0) {
            return true;
            } else {
                return false;
            }
        },
      });
    },
    methods: {
      ...mapMutations("address_player", ["add", "closeDialog", "edit"]),
      ...mapActions("address_player", ["closeDeleteDialog", "deleteItem", "getItems", "save", "showDocument", "showDeleteDialog"]),
      getCountryByCode(code) {
        let index = this.countrys_list.map(function(e) { return e.codigo; }).indexOf(code);
        if(index == -1) return ''
        return this.countrys_list[index].nome
      },
      getTextAgent(item) {
          if(item) {
              if(item.id_pppoker) {
                  return item.id_pppoker + " | " + item.nick + " | " + item.club_name
              } else {
                  return item.nick
              }
          }
          return ''
      },
      getType(type) {
        let types = ["Principal", "Outros"]
        return types[type]
      },
    },
    props: [
      'player_id'
    ],
    data: () => ({
      countrys_list: countrys,
      headers: [
        { text: "Tipo", value: "type", sortable: false, align: 'left' },
        { text: "País", value: "country", sortable: false, align: 'center' },
        { text: "Minas Gerais", value: "state", sortable: false, align: 'center' },
        { text: "Cidade", value: "city", sortable: false, align: 'center' },
        { text: "Bairro", value: "district", sortable: false, align: 'center' },
        { text: "Rua", value: "street", sortable: false, align: 'center' },
        { text: "Número", value: "number", sortable: false, align: 'right' },
        { text: "Complemento", value: "complement", sortable: false, align: 'right' },
        { text: "Ações", value: "actions", sortable: false, align: 'right'}
      ],
      valid: false,
    })
};
</script>

<style>
@import "../css/player.css";
</style>
