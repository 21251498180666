import endPoints from "../../../../config/endPoints"
import axios from "axios"
import { getField, updateField } from 'vuex-map-fields';

const defaultItem = {
    id: null,
    description: "",
    date_start: "",
    date_end: "",
}
export default {
    namespaced: true,
    state: {
        loading: false,
        notification: {
            show: false,
            message: "",
            type: "",
        },
        overlay: false,
        dialogForm: false,
        dialogFilter: false,
        dialogDelete: false,
        selectedItem: {},
        itemToDelete: {},
        mode: '',
        start_formatted: '',
        finish_formatted: '',
        dialog: false,
        filter: {
            id: "",
            date_start: "",
            date_end: "",
            description: "",
        },
        items: [],
        pagination: {
            page: 1,
            itemsPerPage: 10
        },
        totalItems: 0,
        periodSelected: {
            id: 0,
        }

    },
    mutations: {
        setDialogDelete: (state, show) => state.dialogDelete = show,
        setItemToDelete: (state, item) => state.itemToDelete = item,
        showFilter: (state, payload) => state.dialogFilter = payload.show,
        add: (state) => {
            state.dialog = true
            state.mode = 'ADD'
            state.selectedItem = Object.assign({}, defaultItem)
        },
        setTotalItems: (state, total) => state.totalItems = total,
        editPeriod: (state, payload) => {
            state.mode = 'EDIT'
            state.dialog = true;
            state.selectedItem = Object.assign({}, payload.item);
            let start = new Date(payload.item.date_start + " 12:00:00")
            let finish = new Date(payload.item.date_end + " 12:00:00")
            state.start_formatted = start.toLocaleDateString()
            state.finish_formatted = finish.toLocaleDateString()
        },
        closeDialog: (state) => {
            state.dialog = false;
            state.selectedItem = Object.assign({}, defaultItem)
            state.start_formatted = ''
            state.finish_formatted = ''
        },
        showNotification: (state, payload) => {
            state.notification.show = payload.show,
                state.notification.message = payload.message,
                state.notification.type = payload.type
        },
        setPeriodSelected: (state, period) => state.periodSelected.id = period,
        loading: (state, loading) => state.loading = loading,
        setItems: (state, items) => state.items = items,
        updateField

    },
    getters: {
        getPeriodSelected: state => {
            return state.periodSelected.id
        },
        getField,
    },
    actions: {
        showDeleteDialog: ({ commit }, item) => {
            commit('setDialogDelete', true)
            commit('setItemToDelete', item)
        },
        closeDeleteDialog: ({ commit }) => {
            commit('setDialogDelete', false)
            commit('setItemToDelete', {})
        },
        getItems: ({ commit, state }) => {
            commit('loading', true)
            let url = endPoints.periods_players_balance;
            let filter = state.filter;
            url += '?page=' + state.pagination.page + '&' + 'itemsPerPage=' + state.pagination.itemsPerPage;

            Object.keys(filter).forEach(function (field) {
                if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
                    if (field == "date_start" || field == "date_end") {
                        var e = new Date(filter[field])
                        url += "&" + field + "=" + e.toISOString().substr(0, 10)
                    } else {
                        url += "&" + field + "=" + filter[field]
                    }
                }
            });
            axios.get(url).then(items => {
                commit('setTotalItems', items.data.total)
                commit('loading', false)
                commit('setItems', items.data.data)
            });
        },
        viewItem({ commit, dispatch }, payload) {
            commit('setPeriodSelected', payload.id)
            commit('players_balance/setPeriodPlayersBalance', { period_players: payload }, { root: true })
            dispatch('players_balance/getItems', ({ period: payload.id }), { root: true })
            dispatch('menu/openLink', '/players_balance/' + payload.id, { root: true })
        },
        save({ dispatch, commit, state }) {
            let url = endPoints.periods_players_balance
            let func = '';
            if (state.mode == 'EDIT') {
                func = axios.put
                url += '/' + state.selectedItem.id
            } else if (state.mode == 'ADD') {
                func = axios.post
            }
            func(url, state.selectedItem).then(() => {
                dispatch('getItems')
                let notification = {
                    show: true,
                    message: "Período salvo com sucesso.",
                    type: "success"
                }
                commit('closeDialog')
                commit('showNotification', notification)
            },
                error => {
                    let notification = {
                        show: true,
                        message: error.response.data.message,
                        type: "error"
                    }
                    commit('showNotification', notification)
                })
        },
        deleteItem({ commit, dispatch, state }) {
            let url = endPoints.periods_players_balance + '/' + state.itemToDelete.id
            axios.delete(url).then(() => {
                dispatch('getItems')
                let notification = {
                    show: true,
                    message: "Período excluído com sucesso.",
                    type: "success"
                }
                dispatch('closeDeleteDialog')
                commit('showNotification', notification)
            },
                error => {
                    let notification = {
                        show: true,
                        message: error.response.data.message,
                        type: "error"
                    }
                    commit('showNotification', notification)
                }
            )
        },
        refreshAgents({ commit }, payload) {
            let data = payload
            let url = endPoints.periods_players_balance + '/update_agents/' + data.id;
            axios.put(url).then(() => {
                let notification = {
                    show: true,
                    message: "Agentes atualizados com sucesso.",
                    type: "success"
                }
                commit('showNotification', notification)
            }, 
            error => {
                let notification = {
                    show: true,
                    message: error.response.data.message,
                    type: "error"
                }
                commit('showNotification', notification)
            }
            );
        },
    }
}