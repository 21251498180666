<template>
    <div>
      <v-card>
        <v-card-title>
          <span class="headline text-center justify-center">Filtro</span>
  
          <v-col class="text-right">
            <v-btn color="white"  @click="clearFilterTransactions" right small depressed>
              <v-icon color="black">mdi-filter-remove</v-icon>
            </v-btn>
          </v-col>
        </v-card-title>
  
        <v-card-text>
          <v-form ref="form">
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                      label="Id App Jogador"
                      v-model="id_pppoker"
                      outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                      label="Id App Agente"
                      v-model="id_pppoker_agent"
                      outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-autocomplete
                    :clearable="true"
                    clear-icon="mdi-close"
                    label="Clube"
                    :items="slots"
                    v-model="id_slot"
                    item-text="name"
                    item-value="id"
                    outlined
                  ></v-autocomplete>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
  
        <v-card-actions>
          <v-col>
            <v-btn text @click="showFilterPlayerAgents({show: false})">Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn text type="submit" right @click="filter">Filtrar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </div>
  </template>
  
  <script>
  import { mapActions, mapMutations, mapState } from "vuex";
  import { mapFields } from "vuex-map-fields";
  
  export default {
    name: "PlayerAgentFilter",
    created() {
      this.getInstances()
    },
    computed: {
      ...mapState("agents_closure", ["slots"]),
      ...mapFields("agents_closure", [
        "filterPlayerAgent.id_pppoker",
        "filterPlayerAgent.id_pppoker_agent",
        "filterPlayerAgent.id_slot"
        // ...
      ]),
    },
    props: [
      'period'
    ],
    data: () => ({
      possible_suspect: [
        {id: 0, description: "Não"},
        {id: 1, description: "Sim"},
        {id: 2, description: "Sim/Validada"}
      ],
      possible_type_suspect: [
        {id: 0, description: "Interno - Externo"},
        {id: 1, description: "Externo - Interno"}
      ],
      searchPeriod: '',
      timeProps: {
        format: "24hr",
      },
    }),
    methods: {
      ...mapMutations("agents_closure", ["showFilterPlayerAgents"]),
      ...mapActions("agents_closure", ["clearFilterPlayerAgent", "getPlayersAgentInPeriod"]),
      filter() {
        this.getPlayersAgentInPeriod({ resetPage: true });
        this.showFilterPlayerAgents({ show: false });
      },
    }
  };
  </script>
  
  <style>
  </style>