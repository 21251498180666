import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../../config/endPoints';

const spreadsheet_headers_config_import = [
    { database_field: "ChipsUsed", description_field: "Consumo PPFichas", column_spreadsheet: 0, type_field: "float" },
    { database_field: "DiamondClubs", description_field: "Diamantes Clube", column_spreadsheet: 1, type_field: "float" },
    { database_field: "TaxLeague", description_field: "Taxa Liga", column_spreadsheet: 2, type_field: "float" },
    { database_field: "DiamondTables", description_field: "Diamante Mesas", column_spreadsheet: 3, type_field: "float" },
    { database_field: "Jackpot", description_field: "Total de Ganhos do Jogador", column_spreadsheet: 4, type_field: "float" },
    { database_field: "SpinUp", description_field: "SpinUp", column_spreadsheet: 5, type_field: "float" },
    { database_field: "Overlay", description_field: "Overlay", column_spreadsheet: 6, type_field: "float" },
    { database_field: "OtherValues", description_field: "Outros", column_spreadsheet: 7, type_field: "float" },
    { database_field: "PendingPayment", description_field: "Fiado", column_spreadsheet: 8, type_field: "float" },
    { database_field: "RakeSubClub", description_field: "Rake Sub-Clube", column_spreadsheet: 9, type_field: "float" },
    { database_field: "TaxLeagueSubClub", description_field: "Taxa Sub-Clube", column_spreadsheet: 10, type_field: "float" },
    { database_field: "SpinSubClub", description_field: "Spin Sub-Clube", column_spreadsheet: 11, type_field: "float" },
]

export default {
    namespaced: true,
    state: {
        editedItem: {
            id_instance: '',
            start_line: 1,
            period: '',
            spreadsheet: false
        },
        dialog: false,
        id_instance: '',
        items: [],
        periods_closure: [],
        loading: false,
        mode: 'LIST',
        overlay: false,
        others_value: 0,
        spreadsheet_headers_config_import: spreadsheet_headers_config_import,
        selected_spreadsheet: null,
        start_line: 1,
        showNotification: false,
    },
    mutations: {
        loading: (state, loading) => state.loading = loading,
        overlay: (state, payload) => state.overlay = payload.show,
        setSelectedSpreadsheet: (state, payload) => state.selected_spreadsheet = payload.spreadsheet,
        setPeriodsClosure: (state, payload) => state.periods_closure = payload.periods,
        showDialog: (state, show) => state.dialog = show,
        setHeadersSpreadsheet: (state, payload) => {
            state.spreadsheet_headers_config_import = payload.headers
        },
        showLoading: (state, show) => state.loading = show,
        showNotification: (state, payload) => {
            state.showNotification = payload.show,
                state.notificationMessage = payload.message,
                state.notificationType = payload.type
        },
        updateField
    },
    getters: {
        getField,
    },
    actions: {
        getPeriodClosureByName: ({ commit }, payload) => {
            commit('loading', true)
            let url = endPoints.period_closure;
            url += '?page=' + 1 + '&' + 'itemsPerPage=' + 15;
            url += '&description=' + payload.search
            axios.get(url).then(items => {
                commit('setPeriodsClosure', { periods: items.data.data })
            });
        },
        getSpreadsheetHeaders: ({ commit }, payload) => {
            let url = endPoints.pppoker_financial + "/spreadsheet_closure/" + payload.item.id + "?type_spreadsheet=1";
            axios.get(url).then(items => {
                commit('setHeadersSpreadsheet', { headers: items.data.headers })
            });
        },
        generateReportClubClosure: ({ commit, state }) => {
            let url = endPoints.period_closure + "/club_closure"
            let formData = new FormData();
            let headers = [];
            state.spreadsheet_headers_config_import.forEach((h) => {
                h.column_spreadsheet = parseInt(h.column_spreadsheet)
                headers.push(h)
            })

            state.editedItem.spreadsheet = !!(state.selected_spreadsheet != null && state.selected_spreadsheet.name)
            formData.append('file', state.selected_spreadsheet);
            formData.append('payload', JSON.stringify(state.editedItem));
            formData.append('headers', JSON.stringify(headers))
            commit('overlay', { show: true })
            axios.post(url, formData).then((response) => {
                if (response.data && response.data.url) {
                    let url = "http://" + window.location.hostname + response.data.url
                    window.open(url, "_blank")
                }
                commit('setSelectedSpreadsheet', { spreadsheet: {} })
            }).catch(() => {
                let notification = {
                    show: true,
                    message: "Ocorreu um erro ao gerar o fechamento. Contate o administrador do sistema.",
                    type: "error"
                }
                commit('overlay', { show: false })
                commit('showNotification', notification)
            });
        },
    }
}