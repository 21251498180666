<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2000"
      v-model="showNotification"
    >{{ notificationMessage }}</v-snackbar>
    <v-toolbar flat class="elevation-5">
        <div>
            <v-toolbar-title>Conferência Financeira</v-toolbar-title>
        </div>
      <v-spacer></v-spacer>
      <div class="button-toolbar">
        <!-- <download-excel
          :before-generate="generateSheet"
          :before-finish="finishSheet"
          :name="getSheetName()"
          :data="items"
          :fields="sheet_fields"
          class="excel"
          worksheet="Conferência Financeira"
          v-if="totalItems > 0 || filtered"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="black" dark class="ma-2" small v-bind="attrs" v-on="on">
                <v-icon>mdi-file-excel</v-icon>
              </v-btn>
            </template>
            <span>Gerar Planilha</span>
          </v-tooltip>
        </download-excel> -->

        <v-tooltip bottom>
          <template v-slot:activator="{on, attrs}">
            <v-btn color="black" dark class="ma-2" @click="downloadXlsx({ show: true })" v-bind="attrs" v-on="on" small>
              <v-icon>mdi-file-excel</v-icon>
            </v-btn>
          </template>
          <span>Gerar Planilha</span>
        </v-tooltip>
        
        <v-tooltip bottom v-if="totalItems > 0 || filtered">
          <template v-slot:activator="{ on, attrs }">
            <v-btn 
              color="black" 
              dark class="ma-2"
              @click="showFilter({show: true, actionFilter: false})" 
              v-bind="attrs" 
              v-on="on"
              small>
              <v-icon :color="filtered ? 'grey' : 'white'">mdi-filter</v-icon>
            </v-btn>
          </template>
          <span>Filtrar</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="black" dark class="ma-2" @click="add" small v-bind="attrs" v-on="on">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Nova Conferência</span>
        </v-tooltip>
      </div>
    </v-toolbar>
    <v-layout wrap>
      <v-flex xs12 sm12 md12 class="pr-2">
        <v-card class="elevation-10 table-margin">
          <v-card-title class="justify-center">Movimentações</v-card-title>

          <v-data-table
            :headers="headers"
            :loading="loading"
            :items="items"
            :options.sync="paginationMoviments"
            :item-class= "getClassColor"  
            no-data-text="Nenhuma conferencia encontrada"
            calculate-widths
          >
              <template v-slot:[`item.type_order`]="{ item }">
                <span>{{ getType(item.type_order, item.value)}}</span>
              </template>
              <template v-slot:[`item.value_order`]="{ item }">
                <span>{{ getTextValue(item.type_order, item.value_order) }}</span>
              </template>
              <template v-slot:[`item.value_moviment`]="{ item }">
                <span>{{ getTextValue(item.type_moviment, item.value_moviment) }}</span>
              </template>
              <template v-slot:[`item.date_order`]="{ item }">
                <span>{{ getDate(item.date_order, item.value)}}</span>
              </template>
              <template v-slot:[`item.date_moviment`]="{ item }">
                <span>{{ getDate(item.date_moviment, item.value)}}</span>
              </template>
              <template v-slot:[`item.slot_name`]="{ item }">
                <span>{{ getItemIsNull(item.slot_name)}}</span>
              </template>
              <template v-slot:[`item.id_app_order`]="{ item }">
                <span>{{ getItemIsNull(item.id_app_order)}}</span>
              </template>
              <template v-slot:[`item.id_app_moviment`]="{ item }">
                <span>{{ getItemIsNull(item.id_app_moviment)}}</span>
              </template>
              <template v-slot:[`item.type_moviment`]="{ item }">
                <span>{{ getType(item.type_moviment, item.value_moviment)}}</span>
              </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog :value="dialog" @click:outside="closeDialog()" width="800">
      <financial-checks-form></financial-checks-form>
    </v-dialog>

    <v-dialog :value="dialogFilter" width="600" @click:outside="showFilter({show: false, actionFilter: false})" @keydown.enter="filter">
      <financial-checks-filter></financial-checks-filter>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import FinancialChecksForm from './FinancialChecksForm'
import FinancialChecksFilter from './FinancialChecksFilter'
// import JsonExcel from "vue-json-excel";
import { utils, writeFile } from "xlsx";
import _ from 'lodash';

export default {
    name: "FinancialChecks",
    created() {
      this.getInstances()
    },
    computed: {
      ...mapState('financial_checks', ['items', 'check_items', 'notificationMessage', 'notificationType', 'totalItems', 'totalCheckItems', 'filtered', 'dialogFilter', 'orderByTypes', 'filter', 'actionFilter', 'filterWhenOpened', 'excelItems']),      
      ...mapFields('financial_checks', ['dialog', 'loading', 'overlay', 'paginationMoviments', 'showNotification']),
    },
    components: {
      "financial-checks-form": FinancialChecksForm,
      "financial-checks-filter": FinancialChecksFilter,
      // "download-excel": JsonExcel,
    },
    data: () => ({
      headers: [
        { text: 'Clube Ordem', value: "slot_name", sortable: false },
        { text: 'Tipo Ordem', value: "type_order", sortable: false },
        { text: 'Data Ordem', value: "date_order", sortable: false },
        { text: 'Valor Ordem', value: "value_order", sortable: false },
        { text: 'ID App Ordem', value: "id_app_order", sortable: false },
        { text: 'Tipo Movimentação', value: "type_moviment", sortable: false },
        { text: 'Data Movimentação', value: "date_moviment", sortable: false },
        { text: 'Valor Movimentação', value: "value_moviment", sortable: false },
        { text: 'ID App Movimentação', value: "id_app_moviment", sortable: false },
        { text: 'Nick Movimentação', value: "nick_moviment", sortable: false },
      ],
      sheet_fields: {
        "Clube Ordem": {
          field: "slot_name",
        },
        "Tipo Ordem": {
          field: "type_order",
          callback: (value) => {
            let types = ['Saques', 'Depósito', 'Saques', 'Depósito'] 
            if(value !== null) {
              return types[value]
            } else {
              return ""
            }
          }
        },
        "Data Ordem": {
          field: "date_order", 
          callback: (value) => {
            if (value) {
              var d = new Date(value);
              return d.toLocaleDateString() + " " + d.toLocaleTimeString();
            } else {
              return ""
            }
          }
        },
        "Valor Ordem": {
          field: "value_order",
          callback: (value) => {
            if (value) {
              let real = value.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              });
              return "R$" + real.substr(3);
            }
            return "";
          },
        },
        "Id App Ordem": {
          field: "id_app_order",
        },
        "Tipo Movimentação": {
          field: "type_moviment",
          callback: (value) => {
            let types = ['Saques', 'Depósito', 'Saques', 'Depósito'] 
            if(value !== null) {
              return types[value]
            } else {
              return ""
            }
          }
        },
        "Data Movimentação": {
          field: "date_moviment", 
          callback: (value) => {
            if (value) {
              var d = new Date(value);
              return d.toLocaleDateString() + " " + d.toLocaleTimeString();
            } else {
              return ""
            }
          }
        },
        "Valor Movimentação": {
          field: "value_moviment",
          callback: (value) => {
            if (value) {
              let real = value.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              });
              return "R$" + real.substr(3);
            }
            return "";
          },
        },
        "Id Movimentação": {
          field: "id_app_moviment",
        },
        "Nick Movimentação": {
          field: "nick_moviment",
        },
        "Compatível?": {
          field: "is_match",
          callback: (value) => {
            return value ? "Sim" : "Não"
          }
        },
        "Duvida?": {
          field: "is_maybe",
          callback: (value) => {
            return value ? "Sim" : "Não"
          }
        }
      },
      defaultOrderByType: 0,
    }),
    methods: {
      ...mapMutations('financial_checks', ['add', 'closeDialog', 'showFilter', 'setTotalItems', 'setTotalCheckItems', 'setExcelItems']),
      ...mapActions('financial_checks', ['getItemsNoPage', 'updateMoviment']),
      ...mapActions("instances", ["getInstances"]),
      getClassColor(item) {
        let className = '';

        if(!_.isUndefined(item.is_match)) {
          if(!item.is_match && !item.is_maybe) {
            className += ' not-matched-row'
          } else if (item.is_maybe) {
            className += ' maybe-matched-row' 
          } else {
            className += ' matched-row'
          }
        }

        return className
      },
      getDate(date, value) {
        if (date && value != 0) {
          var d = new Date(date);
          return d.toLocaleDateString() + " " + d.toLocaleTimeString();
        }
        return '---';
      },
      getItemIsNull(item) {
        return item ? item : '---'
      },
      getFinancialType(type, financialType, value) {
        var types = []
        if(type == 2) {
          types = ["Envio de Fichas Jogador", "Envio de Fichas Agência", "Abater Anotados", "Adicionar ao Saldo", "Transferência"]
        } else {
          types = ['Fichas', 'Rakeback', 'Saldo Positivo', 'Transferência']
        }
        
        if(value > 0) 
          return types[financialType]
        else 
          return '---'
      },
      getType(type, value) {
        if(type === 0) {
          return "Saques"
        }
        if(type === "" || type === null) return '---'
        let types = ['Saques', 'Depósito', 'Saques', 'Depósito']

        if(type >= 0 && type < 4 && value != 0)  {
          return types[type]
        }
        return '---'
      },
      getTextValue(type, value) {
        if(value > 0) {
          return '(+) ' + value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        } else if (value < 0) {
          value = value * -1
          return '(-) ' + value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        } 
        return '---'
      },
      finishSheet() {
        this.overlay = false;
      },
      updateMovimentT(item) {
        this.items.forEach(original => {
          if(original.id == item.item.id) {
            original.is_match = true
            original.is_maybe = false
          }
        })
      },
      generateSheet() {
        this.overlay = true;
        
      },
      getSheetName() {
        var date = new Date();
        var name =
          "conferencias_financeiras_" +
          date.toLocaleDateString() +
          " " +
          date.toLocaleTimeString();
        return name + ".xls";
      },
      async downloadXlsx() {
        this.generateSheet()
        try {
          let itemsNoPage = await this.getItemsNoPage()
          if(itemsNoPage) {
            const wb = utils.book_new()
            let possible_types = ['Saques', 'Depósito', 'Saques', 'Depósito']
            let items = itemsNoPage.map(row => ({
              "Clube Ordem":  row.slot_name,
              "Tipo Ordem":  possible_types[row.type_order],
              "Data Ordem":  this.dateTimeXLSX(row.date_order),
              "Valor Ordem":  this.valueXLSX(row.date_order),
              "Id App Ordem":  row.id_app_order,
              "Tipo Movimentação":  possible_types[row.type_moviment],
              "Data Movimentação":  this.dateTimeXLSX(row.date_moviment),
              "Valor Movimentação":  this.valueXLSX(row.value_moviment),
              "Id Movimentação":  row.id_app_moviment,
              "Nick Movimentação":  row.nick_moviment,
              "Compatível?":  this.trueOrFalse(row.is_match),
              "Duvida?":  this.trueOrFalse(row.is_maybe),
            }))
            const ws = utils.json_to_sheet(items)
            utils.book_append_sheet(wb, ws, "Conferencias")
            await writeFile(wb, this.getSheetName())
          }
        } catch (error) {
          this.finishSheet()
        } finally {
          this.finishSheet()
        }
      },
      dateTimeXLSX: (value) => {
        if (value) {
          var d = new Date(value);
          return d.toLocaleDateString() + " " + d.toLocaleTimeString();
        } else {
          return ""
        }
      },
      valueXLSX: (value) => {
        if (value) {
          let real = value.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          });
          return "R$" + real.substr(3);
        }
        return "";
      },
      trueOrFalse: (value) => {
        return value ? "Sim" : "Não"
      },
      validateDateFields(payload) {
        if(payload.startDate != "" || payload.endDate != "") {
          let auxSplitDate = []
          let start_date = ''
          let end_date = ''

          if(payload.startDate === "") {
            start_date = _.minBy(this.items, function(item) { return item.sort_date; });
            start_date = start_date.sort_date
          } else {
            let startTime = payload.startTime === "" || _.isUndefined(payload.startTime) ? "00:00:00" : payload.startTime

            if(payload.startDate.indexOf("-") === -1) {
              auxSplitDate = payload.startDate.split("/")
              start_date = '' + auxSplitDate[2] + '-' + auxSplitDate[1] + '-' + auxSplitDate[0] + ' ' +  startTime
            } else {
              start_date = payload.startDate + ' ' +  startTime
            }
          }

          if(this.end_formatted === "") {
            end_date = _.maxBy(this.items, function(item) { return item.sort_date; });
            end_date = end_date.sort_date
          } else {
            let endTime = payload.endTime === "" || _.isUndefined(payload.endTime) ? "23:59:59" : payload.endTime

            if(payload.endDate.indexOf("-") === -1) {
              auxSplitDate = payload.endDate.split("/")
              end_date = '' + auxSplitDate[2] + '-' + auxSplitDate[1] + '-' + auxSplitDate[0] + ' ' +  endTime
            } else {
              end_date = payload.endDate + ' ' +  endTime
            }
            
          } 

          return { 
            isValid: end_date > start_date,
            startDate: start_date,
            endDate: end_date
          }
        } 

        return {
          isValid: false,
          startDate: '',
          endDate: ''
        }
      },
    },
};
</script>

<style scoped>
  @import './css/financial_checks.css';
</style>
