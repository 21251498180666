<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline text-center justify-center">Filtro</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form">
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm3 md3>
                <v-text-field
                  required
                  label="ID"
                  v-model="id"
                  :error-messages="errors.collect('id')"
                  data-vv-name="id"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm9 md9>
                <v-text-field
                  required
                  label="Descrição"
                  v-model="description"
                  :error-messages="errors.collect('description')"
                  data-vv-name="description"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-menu
                  v-model="showStart"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="start_formatted"
                      label="Início"
                      outlined
                      readonly
                      append-icon="mdi-close"
                      @click:append="clearStart()"
                      persistent-hint
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="pt-br"
                    v-model="date_start"
                    no-title
                    @input="(date) => setStart(date)"
                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-menu
                  v-model="showEnd"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="end_formatted"
                      label="Fim"
                      outlined
                      readonly
                      append-icon="mdi-close"
                      @click:append="clearEnd()"
                      persistent-hint
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="pt-br"
                    v-model="date_end"
                    no-title
                    @input="(e) => setEnd(e)"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn text @click="showFilter({ show: false })">Cancelar</v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn text type="submit" right @click="filter">Filtrar</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "PeriodsPlayersBalanceFilter",
  computed: {
    ...mapState("instances", ["instances"]),
    ...mapFields("periods_players_balance", [
      "filter.id",
      "filter.date_start",
      "filter.date_end",
      "filter.description",
      // ...
    ]),
  },
  data: () => ({
    possible_types: [
      {
        id: 0,
        description: "Fechamento",
      },
      {
        id: 1,
        description: "Teste",
      },
    ],
    timeProps: {
      format: "24hr",
    },
    showStart: false,
    start_formatted: "",
  }),
  methods: {
    ...mapMutations("periods_players_balance", ["showFilter"]),
    ...mapActions("periods_players_balance", ["getItems"]),
    filter() {
      this.getItems({ resetPage: true });
      this.showFilter({ show: false });
    },
    setStart(date) {
      this.date_start = date;
      this.showStart = false;
      this.start_formatted = this.formatDate(date);
    },
    setEnd(date) {
      this.date_end = date;
      this.showEnd = false;
      this.end_formatted = this.formatDate(date);
    },
    clearStart() {
      this.date_start = "";
      this.start_formatted = "";
    },
    clearEnd() {
      this.date_end = "";
      this.end_formatted = "";
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    },
  },
};
</script>

<style>
</style>