<template>
    <v-card class="contact-card-description">
      <v-card-text>
          <v-container grid-list-md>
              <v-layout wrap>
                  <v-flex xs12 sm12 md12>
                      <v-avatar v-if="avatar" size="40" class="avatar-header">
                          <v-img v-if="avatar" :src="avatar"></v-img>
                      </v-avatar>
                      <v-card-title>
                          {{(contact_name) ? contact_name : contact_id }}
                      </v-card-title>
                  </v-flex>
  
                  <v-flex xs12 sm12 md4>
                      <v-text-field
                          outlined
                          v-model="totalRakes"
                          label="Adiantamento da Semana"
                          :readonly="true"
                          prepend-inner-icon="mdi-handshake-outline"
                      >
                      </v-text-field>
                  </v-flex>
  
                  <v-flex xs12 sm12 md4>
                      <v-text-field
                          outlined
                          v-model="totalLimit"
                          label="Limite"
                          :readonly="true"
                          prepend-inner-icon="mdi-cash-100"
                      ></v-text-field>
                  </v-flex>
  
                  <v-flex xs12 sm12 md4>
                      <v-text-field
                          required
                          label="Adiantamento Disponível para Depósito *"
                          v-model="rakebackAvailable"
                          :readonly="true"
                          outlined
                          prepend-inner-icon="mdi-cash-plus"
                      ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md12>
                      <v-card-title>
                          Formulário de Depósito
                      </v-card-title>
                  </v-flex>
                  <!-- Deposit Form -->
                  <v-flex xs12 sm4 md4>
                    <v-text-field
                      required
                      label="Valor *"
                      v-model="rakebackAvailable"
                      :readonly="true"
                      :error-messages="errors.collect('value')"
                      v-validate="'required:Valor,value|decimal:2'"
                      data-vv-name="value"
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4 md4>
                    <v-text-field
                      required
                      label="ID Suprema*"
                      v-model="deposit_rakeback.id_pppoker"
                      :readonly="false"
                      :error-messages="errors.collect('sent_id_pppoker')"
                      v-validate="'required:ID Suprema Enviado,sent_id_pppoker|max_length:45'"
                      data-vv-name="sent_id_pppoker"
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4 md4>
                    <v-text-field
                      required
                      label="Nick*"
                      v-model="deposit_rakeback.nick"
                      :readonly="false"
                      :error-messages="errors.collect('sent_nick')"
                      v-validate="'required:Nick Enviado,sent_nick|max_length:45'"
                      data-vv-name="sent_nick"
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md4>
                    <v-select
                      required
                      label="Tipo de Envio *"
                      @change="typeSelectOnChange()"
                      :items="possible_types"
                      v-model="deposit_rakeback.type"
                      :readonly="true"
                      :error-messages="errors.collect('type')"
                      v-validate="'required:Tipo de Envio,type'"
                      item-text="description"
                      item-value="id"
                      data-vv-name="type"
                      outlined
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 sm4 md4>
                    <v-select
                      required
                      label="Clube*"
                      :items="instances"
                      v-model="deposit_rakeback.id_instance"
                      :readonly="false"
                      :error-messages="errors.collect('sent_id_instance')"
                      v-validate="'required:Clube Enviado,sent_id_instance'"
                      item-text="name"
                      item-value="id_instance"
                      data-vv-name="sent_id_instance"
                      outlined
                    ></v-select>
                  </v-flex>
                  <!-- FIM -->
  
                  <v-flex xs12 sm12 md12>
                      <v-card-title>
                          Novo Adiantamento
                      </v-card-title>
  
                      <div class="button-toolbar" style="float:left">
                          <v-btn outlined color="blue lighten-2" class="ma-2 " @click="add" icon><v-icon>mdi-plus</v-icon></v-btn>
                          <v-btn outlined color="red lighten-2" class="ma-2" @click="remove" icon><v-icon>mdi-minus</v-icon></v-btn>
                      </div>
                  </v-flex>
  
                  <v-flex xs12 sm12 md4 v-for="item in rakebacks" :key="item.id">
                      <v-flex xs12 sm4 md10>
                          <v-text-field
                              required
                              label="Rake Gerado *"
                              v-model="item.new_rake"
                              outlined
                              :error-messages="errors.collect('value')"
                              v-validate="'required:Valor,rake|decimal:2'"
                              data-vv-name="rake"
                          ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm4 md10>
                          <v-select
                              required
                              label="Clube *"
                              :items="instances"
                              v-model="id_instance"
                              :error-messages="errors.collect('clube')"
                              item-text="name"
                              item-value="id_instance"
                              data-vv-name="id_instance"
                              outlined
                      ></v-select>
                      </v-flex>
                  </v-flex>
              </v-layout>
          </v-container>
      </v-card-text>
      <v-card-actions>
          <v-col>
              <v-btn
                  text
                  @click="closeRake"
              >Cancelar
              </v-btn>
          </v-col>
          <v-col class="text-center">
              <v-btn
                  text
                  type="submit"
                  right
                  @click="calcRake"
              >
              Calcular
              </v-btn>
          </v-col>
          <v-col class="text-right">
              <v-btn
                  text
                  type="submit"
                  right
                  @click="createDepositRake"
              >
              Criar Depósito
              </v-btn>
          </v-col>
      </v-card-actions>
    </v-card>
  </template>
  
  <script>
      import { mapActions, mapMutations, mapState } from 'vuex'
      import { mapFields } from 'vuex-map-fields'
      export default {
          computed: {
              ...mapState("chat", [
                  "camp",
                  "totalLimit",
                  "totalRakes",
                  "rakebackAvailable",
                  "inputRakebackAvailable",
                  "deposit_rakeback"
              ]),
              ...mapState("instances", ["instances"]),
              ...mapState("bank", { banks: "items" }),
              ...mapFields("rakeback", [
                  "dialogReceipt",
                  "editedRake.new_rake",
                  "editedRake.date_picker",
                  "editedRake.id_agent",
                  "editedRake.id_instance",
                  "editedRake.type",
                  "editedRake.bank",
              ]),
              ...mapState("login", ["userData"]),
              chipsList() {
                  return this.mode == 'VIEW' ? this.chips_contact.filter(c => c.status == 1) : this.chips_contact
              },
              showAddTo() {
                  let roles = [2, 5, 80, 81, 97, 98, 99]
                  return roles.indexOf(this.userData.role) != -1
              }
          },
          props: [
              'avatar',
              'contact_id',
              'contact_name',
              'description',
              'screen', 
              'start_mode',
              'showContactDescription',
              'openedChat'
          ],
          created() {
              this.mode = this.start_mode
          },
          data: () => ({
              possible_types: [
                  { id: 0, description: "Envio de Fichas Jogador"},
                  { id: 1, description: "Envio de Fichas Agência"},
                  { id: 2, description: "Abater Anotados"},
                  { id: 3, description: "Adicionar ao Saldo Positivo"},
                  { id: 4, description: "Transferência de Fichas"}
              ],
              possible_status: [
                  { id: 0, description: "Pendente" }
              ],
              payment_types: [
                  { id: 1, description: "Anotado" }
              ],
              mode: '',
              rakebacks: [ {id_instance: 0, new_rake: 0} ]
          }),
          methods: {
              ...mapMutations("deposit", ["setBank", "setPaymentType"]),
              ...mapMutations('chat', ["showDialogContactDescription", "showDialogRakeback", "showRakebackAvailable"]),
              ...mapActions('chat', [
                  'saveContactDescriptionChange',
                  'calcRakeback',
                  'createNewDepositRake'   
              ]),
              ...mapActions("deposit", ["save"]),
              createDepositRake() {
                  if(this.deposit_rakeback.type != null && this.deposit_rakeback.id_instance != "" ) {
                      console.log("Sim")
                      if (this.rakebackAvailable > 0) {
                          this.createNewDepositRake({ rakebacks: this.rakebacks })
                      }
                  }
              },
              closeRake() {
                  this.showDialogRakeback({show: false})
                  this.showRakebackAvailable({show: false})
              },
              add() {
                  let default_item = {id_instance: 0, new_rake: 0}
                  this.rakebacks.push(default_item)
              },
              remove() {
                  this.rakebacks.pop()
              },
              calcRake() {
                  this.calcRakeback({ rakebacks: this.rakebacks });
              },
              getTextBank(item) {
                  if(item.account) {
                      return item.name + ' | ' + item.account
                  } else {
                      return item.name
                  }
              },
              typeSelectOnChange() {
                  let idTransfer = this.banks.filter((bank) => bank.code === '1000')[0].id
                  if(this.type == 4) {
                      this.setBank({bank: idTransfer})
                      this.setPaymentType({paymentType: 0})
                  } else if (this.bank === idTransfer){
                      this.setBank({bank: ''})
                  }
              }
          },
          watch: {
              showContactDescription: function() {
                  if(this.showContactDescription == false) {
                      this.mode = 'VIEW'
                  }
              }
          }
      }
  </script>
  
  <style>
    @import './css/contact_description.css';
  </style>
