import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../../config/endPoints';

export default {
    namespaced: true,
    state: {
        dialog: false,
        dialogFilter: false,
        dialogReceipt: false,
        dialogDelete: false,
        showDialog: false,
        updateTypeLinkClient: null,
        groupClientLinkData: {},
        editedItem: {},
        filter: {
            name: '',
            contact_id2: '',
            instance_id: '',
            is_Group: '',
            client_id:'',
            search: '',
            send_message_mass: ''
        },
        contacts: [],
        contactsFilteredByClient: [],
        contactsId: [],
        loading: false,
        mode: 'LIST',
        overlay: false,
        page: 1,
        pagination: {
            page: 1,
            contactsPerPage: 10
        },
        successNotification: false,
        notificationMessage: '',
        notificationType: "success",
        receipt_show: '',
        selectedContact: {},
        showNotification: false,
        totalContacts: 0,
        rowsPerPage: 10,
        menus: [],
        possible_contacts: [
            { id:0,  value:"Não" },
            { id:1,  value:"Sim" },
        ]
    },

    mutations: {
        closeDialog: (state) => {
            state.dialog = false
            state.mode = "LIST"
        },
        edit: (state, payload) => {
            state.editedItem = Object.assign({}, payload.item);
            console.log(state.editedItem)
            state.editedIndex = state.contacts.indexOf(payload.item);
            state.dialog = true;
            state.mode = "EDIT";
        },
        loading: (state, payload) => state.loading = payload.show,
        setMode: (state, mode) => state.mode = mode,
        setContacts: (state, contacts) => state.contacts = contacts,
        setContactsFilteredByClient: (state, contacts) => state.contactsFilteredByClient = contacts,
        setTotalContacts: (state, total) => state.totalContacts = total,
        setRowsPerPage: (state, rowsPerPage) => state.rowsPerPage = rowsPerPage,
        showDialog: (state, show) => state.dialog = show,
        setPage: (state, payload) => {
            state.pagination.page = payload.page;
            state.pagination.contactsPerPage = payload.contactsPerPage
        },
        setScreen: (state, payload) => state.screen = payload.screen,
        setMenus: (state, payload) => {
            state.menus = payload.menus
        },
        setDialogReceipt: (state, payload) => state.dialogReceipt = payload.show,
        setInstances: (state, payload) => state.instances = payload.instances,
        showFilter: (state, payload) => state.dialogFilter = payload.show,
        showErrorNotification: (state, show) => state.errorNotification = show,
        showSuccessNotification: (state, show) => state.successNotification = show,
        showLoading: (state, show) => state.loading = show,
        showNotification: (state, payload) => {
            state.showNotification = payload.show,
            state.notificationMessage = payload.message,
            state.notificationType = payload.type
        },
        showOverlay: (state, payload) => {
            state.overlay = payload.show
        },
        setReceipt: (state, payload) => state.receipt_show = payload.receipt,
        viewContact(state, contact) {
            state.editedContact = contact
            state.editedIndex = state.contacts.indexOf(contact)
            state.dialog = true
            state.mode = "VIEW"
        },

        deleteLinkClient: (state, payload) => {
            state.selectedContact = payload;
            state.selectedContact.client_id = null;
            state.dialogDelete = true;
        },
        setDialogDelete: (state, payload) => {
            state.dialogDelete = payload
        },
        setShowDialog: (state, payload) => {
            state.showDialog = payload
        },
        setSelectedContact: (state, payload) => {
            state.selectedContact = payload
        },
        updateField
    },
    getters: {
        getField,
    },
    actions: {
        closeDialog: ({ commit, state }) => {
            state.editedContact = {}
            if (state.screen != "messages") {
                commit('message/setSelectedMessages', { messages: [] }, { root: true })
                commit('showDialog', false)
            } else {
                commit('message/showDialogFail', { show: false }, { root: true })
            }
            setTimeout(() => {
                commit('closeDialog')
                commit('showErrorNotification', false)
                commit('showSuccessNotification', false)
            }, 300);
        },
        closeDeleteDialog: ({ commit }) => {
            commit('setDialogDelete', false)
            commit('setContactToDelete', {})
        },
        deleteContact: ({ dispatch, commit, state }) => {
            commit('loading', true)
            axios.delete(endPoints.contacts + "/deleteContact/" + state.contactToDelete.id, state.contactToDelete).then(() => {
                dispatch('closeDeleteDialog')
                commit('loading', false)
                let notification = {
                    show: true,
                    message: "Contato excluida com sucesso",
                    type: "success"
                }
                commit('showNotification', notification)
                return dispatch('getContacts', { resetPage: false });
            });
        },
        getContacts: ({ commit, state, rootGetters }, payload) => {
            commit('loading', true)
            let url = endPoints.contact_list + "/all_contacts"
            let filter = state.filter;
            let client_id = rootGetters["client/getClientId"]
            
            if (payload.resetPage === true) {
                url += '?page=' + 1 + '&' + 'contactsPerPage=' + 10;
                commit('setPage', { page: 1, contactsPerPage: 10 })
            } else {
                url += '?page=' + state.pagination.page + '&' + 'contactsPerPage=' + state.pagination.contactsPerPage;
            }

            if (payload.filteredByClient != true) {
                state.filter.client_id = ""
            }  else {
                state.filter.client_id = client_id
            }
            
            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined && filter[field] != null) {
                    url += "&" + field + "=" + filter[field]
                }
            });

            axios.get(url).then(contacts => {
                commit('loading', false)
                payload.filteredByClient == true
                ? commit('setContactsFilteredByClient', contacts.data.data)
                : commit('setContacts', contacts.data.data)
                commit('setTotalContacts', contacts.data.total)
            })
        },
        getChildrenSearch: ({ commit }, payload) => {
            let url = endPoints.menus + '/children_search';
            url += '/' + payload.search

            axios.get(url).then(menus => {
                commit('setMenus', { menus: menus.data })
            });
        },
        getContactsNoPage: ({ commit, state }) => {
            commit('loading', true)
            let url = endPoints.contact_list + "/all_contacts"
            url += '?page=' + '1' + '&' + 'itemsPerPage=' + '99999';
            let filter = state.filter;

            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined && filter[field] != null) {
                    url += "&" + field + "=" + filter[field]
                }
            });

            return axios.get(url).then(contacts => {
                commit('loading', false)
                return contacts.data.data
            }).catch(() => {
                commit('loading', false )
            });
        },
        save: ({ dispatch, commit, state }) => {
            var url = endPoints.contact_list;
            let func = '';
            if (state.mode == 'ADD') {
                func = axios.post;
                url = endPoints.contact_list;
            } else {
                func = axios.put;
                url += '/update_contact/' + state.editedItem.id;
            }

            func(url, state.editedItem).then(
                () => {
                    console.log('cheguei aqui dentro')
                    dispatch('getContacts', {resetPage: false})
                    let notification = {
                        show: true,
                        message: "Contato salvo com sucesso.",
                        type: "success"
                    }
                    commit('closeDialog')
                    commit('showNotification', notification)
                },
                error => {
                    let notification = {
                        show: true,
                        message: error.response.data.message,
                        type: "error"
                    }
                    commit('showNotification', notification)
                }
            );
        },

        addClientLink: async ({commit, state, dispatch, rootState}) => {
            let url = endPoints.contact_list + '/add_client_link/' + state.selectedContact.contact_id2
            
            commit('loading', true)

            let date_created = new Date();
            state.groupClientLinkData.created_at = date_created.toISOString().substr(0, 10) + " " + date_created.toLocaleTimeString();
            state.groupClientLinkData.user_created = rootState.login.userData.id;
            state.groupClientLinkData.client_id = state.selectedContact.client_id;
            state.groupClientLinkData.group_id = state.selectedContact.contact_id2;

            try {
                await axios.put(url, state.groupClientLinkData)

                let notification = {
                    show: true,
                    message: "Vinculação de grupo realizada com sucesso",
                    type: "success"
                }

                commit('loading', false)
                dispatch('getContacts', {resetPage: false, filteredByClient: true });
                commit('setDialogDelete', false)
                commit('setShowDialog', false)
                commit('showNotification', notification)
            }
            catch (error){
                let notification = {
                    show: true,
                    message: error?.response?.data?.message,
                    type: "error"
                }
                commit('setDialogDelete', false)
                commit('loading', false)
                commit('showNotification', notification)
            }
        },

        removeClientLink: async ({commit, state, dispatch}) => {
            let url = endPoints.contact_list + '/remove_client_link/' + state.selectedContact.contact_id2
            
            commit('loading', true)

            state.groupClientLinkData.client_id = state.selectedContact.client_id;
            state.groupClientLinkData.group_id = state.selectedContact.contact_id2;

            try {
                await axios.put(url, state.groupClientLinkData)

                let notification = {
                    show: true,
                    message: "Vinculação de grupo excluída com sucesso",
                    type: "success"
                }

                commit('loading', false)
                dispatch('getContacts', {resetPage: false, filteredByClient: true });
                commit('setDialogDelete', false)
                commit('setShowDialog', false)
                commit('showNotification', notification)
            }
            catch (error){
                let notification = {
                    show: true,
                    message: error?.response?.data?.message,
                    type: "error"
                }
                commit('setDialogDelete', false)
                commit('loading', false)
                commit('showNotification', notification)
            }
        }
    }
}