import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../config/endPoints';

const defaultItem = {
    id_agent: null,
    type_deal_with_master: 0,
    value_deal_with_master: 0,
    relationship_master_agent: 0
};

export default {
    namespaced: true,
    state: {
        agent_id: 0,
        dialog: false,
        dialogDelete: false,
        editedItem: {},
        find_agents: [],
        filter: {
            id_master_agent: ''
        },
        filter_agent: {
            nick: '',
        },
        loading_subagents: false,
        itemToDelete: {},
        loading: false,
        loadingMastersAgent: false,
        mode: 'LIST',
        pagination_subagent: {
            page: 1,
            itemsPerPage: 10
        },
        paginationMastersAgent: {
            page: 1,
            itemsPerPage: 10
        },
        totalMasterAgents: 0,
        mastersAgent: [],
        subAgentsAgent: [],
        totalSubAgents: 0
    },
    mutations: {
        add: (state) => {
            state.mode = "ADD"
            state.selectedContact = {}
            state.editedItem = Object.assign({}, defaultItem)
            state.dialog = true
        },
        edit: (state, payload) => {
            state.selectedContact = {}
            state.editedItem = Object.assign({}, payload.item)
            state.editedIndex = state.subAgentsAgent.indexOf(payload.item)
            state.mode = "EDIT"
            state.dialog = true
        },
        closeDialog: (state) => {
            state.editedItem = {}
            state.editedIndex = -1
            state.errors = [];
            state.dialog = false
            state.mode = "LIST"
        },
        loading_subagents: (state, payload) => state.loading_subagents = payload.loading,
        loadingMastersAgent: (state, payload) => state.loadingMastersAgent = payload.loading,
        setMode: (state, mode) => state.mode = mode,
        setSubAgents: (state, payload) => {
            state.subAgentsAgent = payload.subAgents
        },
        setMastersAgent: (state, payload) => {
            state.mastersAgent = payload.mastersAgent
        },
        setItemToDelete: (state, item) => state.itemToDelete = item,
        setTotalSubAgents: (state, payload) => state.totalSubAgents = payload.total,
        setTotalMastersAgent: (state, payload) => state.totalMasterAgents = payload.total,
        setMasterAgents: (state, payload) => state.master_agents = payload.master_agents,
        showDialog: (state, show) => state.dialog = show,
        setFindAgents: (state, payload) => state.find_agents = payload.find_agents,
        setDialogDelete: (state, show) => state.dialogDelete = show,
        showFilter: (state, payload) => state.dialogFilter = payload.show,
        showLoading: (state, show) => state.loading = show,
        updateField
    },
    getters: {
        getField,
    },
    actions: {
        closeDialog: (({ commit }) => {
            commit('closeDialog', false)
        }),
        closeDeleteDialog: ({ commit }) => {
            commit('setDialogDelete', false)
            commit('setItemToDelete', {})
        },
        deleteItem: ({ dispatch, commit, rootGetters, state }) => {
            commit('loading', true)
            axios.delete(endPoints.subAgents + "/" + state.itemToDelete.id, state.itemToDelete).then(() => {
                dispatch('closeDeleteDialog')
                commit('loading_subagents', { loading: true })
                let notification = {
                    show: true,
                    message: "Sub-Agente excluida com sucesso",
                    type: "success"
                }
                commit('agent/showNotification', notification, { root: true })
                return dispatch('getSubAgents', { resetPage: true, agent_id: rootGetters["agent/getIdAgent"] });
            });
        },
        getSubAgents: ({ commit, state }, payload) => {
            commit('loading_subagents', { loading: true })
            let url = endPoints.subAgents
            url += '?page=' + state.pagination_subagent.page + '&' + 'itemsPerPage=' + state.pagination_subagent.itemsPerPage;
            url += "&id_master_agent=" + payload.agent_id;
            axios.get(url).then(response => {
                commit('setTotalSubAgents', { total: response.data.total })
                commit('loading_subagents', { loading: false })
                commit('setSubAgents', { subAgents: response.data.data })
            });
        },
        getMastersAgent: ({ commit, state }, payload) => {
            commit('loadingMastersAgent', { loading: true })
            let url = endPoints.subAgents + "/masters"
            url += '?page=' + state.pagination_subagent.page + '&' + 'itemsPerPage=' + state.pagination_subagent.itemsPerPage;
            url += "&id_agent=" + payload.agent_id;
            axios.get(url).then(response => {
                commit('setTotalMastersAgent', { total: response.data.total })
                commit('loadingMastersAgent', { loading: false })
                commit('setMastersAgent', { mastersAgent: response.data.data })
            });
        },
        getFindAgents({ commit, state }) {
            commit('loading_subagents', { loading: true })
            let url = endPoints.agents;
            let filter = state.filter_agent;
            url += '?page=' + 1 + '&' + 'itemsPerPage=' + 20;

            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined) {
                    url += "&" + field + "=" + filter[field]
                }
            });

            axios.get(url).then(items => {
                commit('loading_subagents', { loading: false })
                commit('setFindAgents', { find_agents: items.data.data })
            });
        },
        showDeleteDialog: ({ commit }, item) => {
            commit('setDialogDelete', true)
            commit('setItemToDelete', item)
        },
        save: ({ commit, dispatch, state, rootGetters }) => {
            var url = endPoints.subAgents;
            let func = '';
            if (state.mode == 'ADD') {
                func = axios.post;
                url = endPoints.subAgents;
                state.editedItem.id_master_agent = rootGetters["agent/getIdAgent"]
            } else {
                func = axios.put;
                url += '/' + state.editedItem.id;
            }

            func(url, state.editedItem).then(
                () => {
                    dispatch('getSubAgents', { resetPage: true, agent_id: rootGetters["agent/getIdAgent"] })
                    let notification = {
                        show: true,
                        message: "Sub-Agente salvo com sucesso",
                        type: "success"
                    }
                    commit('agent/showNotification', notification, { root: true })
                    commit('closeDialog')
                },
                error => {
                    let notification = {
                        show: true,
                        message: error.response.data.message,
                        type: "error"
                    }
                    commit('agent/showNotification', notification, { root: true })
                }
            );
        }
    }
}