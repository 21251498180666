<template>
  <div>
    <v-snackbar
      :color="notification.type"
      right
      top
      :timeout="2000"
      v-model="notification.show"
    >
      {{ notification.message }}
    </v-snackbar>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-card class="elevation-0">
      <v-card-title class="text-center justify-center">
        Dados
      </v-card-title>
 
      <v-container fluid>
        <v-row dense>
          <v-col cols="6">
            <v-card min-height="360" class="ma-4 pa-4 elevation-3 rounded-lg">
              <v-card-title class="text-center justify-center">
              Cadastro de Agente
            </v-card-title>
              <div>
                <div>
                  <strong>Nome:</strong> 
                  {{ agent.name }}
                </div>
                <div>
                  <strong>Id PPPoker:</strong> 
                  {{ agent.id_pppoker }}
                </div>
                <div>
                  <strong>Nick:</strong> 
                  {{ agent.nick }}
                </div>
                <div>
                  <strong>Tipo de Acerto:</strong> 
                  {{ getTypeDeal(agent.type_deal) }}
                </div>
                <div>
                  <strong>Tipo de Fechamento:</strong> 
                  {{ getTypeClosure(agent.type_closure) }}
                </div>
                <div>
                  <strong>Telefone:</strong> 
                  {{ agent.phone }}
                </div>
                <div>
                  <strong>Grupo:</strong> 
                  {{ agent.ag_group_id }}
                </div>
                <div v-if="agent.is_master_agent == 0">
                  <strong>Ag. Master:</strong> 
                  {{ agent.nick_master_agent }}
                </div>
                <div v-else>
                  <strong>Master: </strong>
                  {{ (agent.is_master_agent == 1) ? 'Sim' : 'Não' }}
                </div>
                <div>
                  <strong>Email:</strong> 
                  {{ agent.email }}
                </div>
              </div>
            </v-card>
          </v-col>

          <v-col cols="6" >
            <v-card v-if="agent.client_id" class="ma-4 pa-4 elevation-3 rounded-lg">
              <v-card-title class="text-center justify-center">
              Cadastro de Cliente
            </v-card-title>
            <div>
                <div>
                  <strong>ID:</strong> 
                  {{ client.id }}
                </div>
                <div>
                  <strong>Nome:</strong> 
                  {{ client.name }}
                </div>
                <div v-if="client.client_type == 1">
                  <strong>Razão social:</strong> 
                  {{ client.corporate_name }}
                </div>
                <div>
                  <strong>Tipo:</strong> 
                  {{ client.client_type == 0 ? "Pessoa Física" : "Pessoa Jurídica" }}
                </div>
                <div>
                  <strong>{{ client.client_type == 0 ? "CPF" : "CNPJ" }}</strong> 
                  {{ client.inscription }}
                </div>

                <div>
                  <strong>Telefone:</strong> 
                  {{ client.contact }}
                </div>
                <div>
                  <strong>{{  client.client_type == 0 
                              ? "Data de Nascimento:" 
                              : "Data de Abertura"
                  }}</strong> 
                  {{ client.birth_date }}
                </div>
                <div>
                  <strong>Endereço:</strong> 
                  {{ client.address }}, {{ client.number }} - {{ client.district }}
                </div>
                <div>
                  <strong>Complemento:</strong> 
                  {{ client.complement }}
                </div>
                <div>
                  <strong>Cidade:</strong> 
                  {{ client.city }} - {{ client.state }}
                </div>
                <div>
                  <strong>País:</strong> 
                  {{ client.country }}
                </div>
                <div>
                  <strong>CEP:</strong> 
                  {{ client.cep }}
                </div>
              </div>
            </v-card>
            <v-card v-else class="ma-4 pa-4 pb-8 elevation-3 rounded-lg">
              <v-card-title class="pa-0 pb-2  text-center justify-center">
              Cadastro de Cliente
              </v-card-title>
              <div class="text-center justify-center">
                <span>Esse agente ainda não possui vinculo com nenhum cliente</span>
                <div >
                  <v-btn
                    color="dark"
                    dark
                    class="ma-2"
                    @click="setShowDialog(true)"
                  >
                    <span color="white">Vincular Agente</span>
                  </v-btn>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>


      <v-dialog
        :value="showDialog"
        @keydown.esc="setShowDialog(false)"
        @click:outside="setShowDialog(false)"
        width="500"
      >
        <link-agent-form></link-agent-form>
      </v-dialog>

    </v-card>
  </div>
</template>



<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import LinkAgentForm from "./LinkAgentForm";


export default {
  name: "AgentData",

  components: {
    "link-agent-form": LinkAgentForm,
  },


  created() {
    if (this.agent.client_id != null) {
      this.getClient({id:this.agent.client_id});
    }
  },


  computed: {
    ...mapState("agent", [
      "agent",
    ]),
    ...mapState("clients", [
      "client",
    ]),
    ...mapState("agent_data", [
      "clientId",
      "loading",
      "showDialog",
      "notificationMessage",
      "notificationType",
      "notification",
    ]),

    ...mapFields("agent_data", [
      "showNotification",
    ]),
  },

  methods: {
    ...mapActions("clients", [
      "getClient",
    ]), 
    ...mapActions("agent_data", [
      "linkAgentToClient",
    ]),    
    ...mapMutations("agent_data", [
      "setShowDialog",
      "link",

    ]),


    getTypeClosure(type) {
        let types = ["Automático", "Manual"];
        if (types[type]) {
          return types[type];
        }
        return "";
      },
    getTypeDeal(type) {
      let types = ["Ganho/Perda", "Giro", "Normal", "Fichas Enviadas/Retiradas", "Sem Acerto", "Sem Acerto/GP"];
      if (types[type]) {
        return types[type];
      }
      return "";
    },
  },


  data: () => ({

  }),
  
  watch: {
    clientId: {
      handler () {
        this.getClient({id:this.clientId});
      }
    },
  }
};

</script>

