import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../../config/endPoints';

const defaultItem = {
    name: "",
    email: "",
    random_password: "",
    id_agent: "",
    status: null,
    date_created: "",
    default_password: null,
    type: null
}
const defaultFilter = {
    id: "",
    name: "",
    email: "",
    status: "",
    type: "",
    default_password: ""
}
export default {
    namespaced: true,
    state: {
        agent_id: 0,
        items: [],
        loading: false,
        dialog: false,
        passwordDialog: false,
        dialogDelete: false,
        dialogFilter: false,
        overlay: false,
        userToDelete: {},
        showNotification: false,
        notificationMessage: '',
        notificationType: "success",
        mode: "",
        pagination: {
            page: 1,
            itemsPerPage: 10
        },
        filter: {
            id: '',
            name: "",
            status: '',
            type: '',
            default_password: '',
            email: ''
        },
        selectedItem: {},
        totalItems: 0,
        possible_types: [
            { id: 0, type: "Normal" },
            { id: 1, type: "Master" }
        ],
        possible_status: [
            { id: 0, status: "Inativo" },
            { id: 1, status: "Ativo" }
        ],
        is_default_password: [
            { id: 0, text: "Não" },
            { id: 1, text: "Sim" }
        ],
    },
    mutations: {
        loading: (state, payload) => state.loading = payload.loading,
        setItems: (state, payload) => {
            state.items = payload.items
        },
        overlay: (state, payload) => {
            state.overlay = payload.show
        },
        add: (state) => {
            state.dialog = true
            state.mode = "ADD";
            state.selectedItem = Object.assign({}, defaultItem)
        },
        edit: (state, payload) => {
            state.selectedItem = Object.assign({}, payload)
            state.dialog = true
            state.mode = "EDIT";
        },
        showDialogDelete: (state, show) => state.dialogDelete = show,
        setUserToDelete: (state, user) => state.userToDelete = user,
        showDialog: (state, show) => state.dialog = show,
        showDialogPassword: (state, payload) => {
            state.passwordDialog = payload
            state.notificationMessage = payload.message,
                state.notificationType = payload.type
        },
        showCopyNotification: (state) => {
            state.showNotification = true,
            state.notificationMessage = "Senha copiada para área de transferência",
            state.notificationType = "success"
        },
        showNotification: (state, payload) => {
            state.showNotification = payload.show,
                state.notificationMessage = payload.message,
                state.notificationType = payload.type
        },
        openDialogPassword(state, payload) {
            state.selectedItem.random_password = payload
            state.passwordDialog = true
        },
        setTotalItems: (state, payload) => state.totalItems = payload.total,
        clearFilter: (state) => {
            state.filter = Object.assign({}, defaultFilter)
        },
        showFilter: (state, payload) => state.dialogFilter = payload.show,
        updateField
    },
    getters: {
        getField,
    },
    actions: {
        closeDialog: ({ state, commit }) => {
            state.selectedItem = Object.assign({}, defaultItem)
            commit('showDialog', false)
        },
        edit: ({ state }, payload) => {
            state.selectedItem = Object.assign({}, payload.item)
            state.mode = "EDIT"
            state.dialog = true
        },
        getItems: ({ commit, rootGetters, state }, payload) => {
            commit('loading', { loading: true })
            const filter = state.filter
            let url = endPoints.agent_users
            if (payload.resetPage === true) {
                url += '?page=' + 1 + '&' + 'itemsPerPage=' + 10;
            } else {
                url += '?page=' + state.pagination.page + '&' + 'itemsPerPage=' + state.pagination.itemsPerPage;
            }
            url += "&id_agent=" + `${rootGetters["agent/getIdAgent"]}`
            
            if (state.pagination && state.pagination.sortBy && state.pagination.sortBy.length > 0) {
                url += "&sort_by=" + state.pagination.sortBy[0]
                url += "&sort_desc=" + !state.pagination.sortDesc[0]
            }
            Object.keys(filter).forEach(function (field) {
                if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
                    url += "&" + field + "=" + filter[field]
                }
            });
            axios.get(url).then(response => {
                let data = response.data != null ? response.data : []
                commit('setTotalItems', { total: data.total })
                commit('loading', { loading: false })
                commit('setItems', { items: data.data })
            });
        },

        showDeleteDialog: ({ commit }, user) => {
            commit('showDialogDelete', true)
            commit('setUserToDelete', user)
        },
        save: ({ state, rootGetters, commit, dispatch }) => {
            var url = endPoints.agent_users
            if (state.mode == "ADD") {
                state.selectedItem.id_agent = rootGetters["agent/getIdAgent"]
                state.selectedItem.default_password = 1
                axios.post(url, state.selectedItem).then((response) => {
                    commit('showDialog', false)
                    commit('openDialogPassword', response.data.random_password)
                });
            } else {
                url += "/update/" + state.selectedItem.id
                axios.put(url, state.selectedItem).then(
                    () => {
                        dispatch('getItems', { resetPage: true })
                        let notification = {
                            show: true,
                            message: "Usuário editado com sucesso",
                            type: "success"
                        }
                        commit('showNotification', notification)
                        commit('showDialog', false)
                    },
                    error => {
                        let notification = {
                            show: true,
                            message: error.response.data.message,
                            type: "error"
                        }
                        commit('showNotification', notification)
                    }
                );
            }
        },
        closeDialogPassword: ({ commit, dispatch }) => {
            commit('showDialogPassword', false)
            let notification = {
                show: true,
                message: "Usuário adicionado com sucesso",
                type: "success"
            }
            commit('showNotification', notification)
            dispatch('getItems', { resetPage: false });
        },
        closeDeleteDialog: ({ commit }) => {
            commit('showDialogDelete', false)
            commit('setUserToDelete', {})
        },
        deleteUser: ({ dispatch, commit, state }) => {
            commit('loading', true)
            var url = endPoints.agent_users + "/" + state.userToDelete.id;
            axios.delete(url).then(() => {
                dispatch('closeDeleteDialog')
                commit('loading', false)
                let notification = {
                    show: true,
                    message: "Usuário excluído com sucesso",
                    type: "success"
                }
                commit('showNotification', notification)
                return dispatch('getItems', { resetPage: false });
            });
        },
    }
}