import axios from 'axios';
import endPoints from '../../../config/endPoints';
import { getField, updateField } from 'vuex-map-fields';
//import { mapActions, mapState } from 'vuex'

const defaultRelease = {
    id: 0,
    title: '',
    description: '',
    date: '',
    module: '',
    version: '',
}

export default {
    namespaced: true,
    state: {
        items: [],
        loading: false,
        selectedItem: {},
        showDialog: false,
        deleteDialog: false,
        mode: '',
        requests: [],
        requestTitleSearch: '',
        notification: {
            show: false,
            message: '',
            type: ''
        },
        pagination: {
            page: 1
        },
        totalItems: 0
        
    },
    mutations: {
        add: (state) => {
            state.showDialog = true
            state.mode = "ADD"
            state.selectedItem = Object.assign({}, defaultRelease)
        },
        deleteRelease: (state, payload) => {
            state.deleteDialog = true
            state.selectedItem = payload
        },
        edit: (state, payload) => {
            state.showDialog = true
            state.mode = "EDIT"
            state.selectedItem = Object.assign({}, defaultRelease)
            state.selectedItem = payload
        },
        view: (state, payload) => {
            state.showDialog = true
            state.mode = "VIEW"
            state.selectedItem = payload
        },
        setShowDialog: (state, payload) => state.showDialog = payload,
        setDeleteDialog: (state, payload) => state.deleteDialog = payload,
        setItems: (state, payload) => state.items = payload,
        setLoading: (state, payload) => state.loading = payload,
        setMode: (state, payload) => state.mode = payload,
        setSelectedItem: (state, payload) => state.selectedItem = payload,
        showNotification: (state, payload) => state.notification = payload,
        setTotalItems: (state, payload) => state.totalItems = payload,
        setPage: (state, payload) => state.pagination.page = payload,
        setRequests: (state, payload) => state.requests = payload,
        updateField
    },
    getters: {
        getField
    },
    actions: {
        refreshItems: async ( {commit} ) => {
            commit('setLoading', true)
            let url = `${endPoints.releases}?page=1`
            try {
                let items = await axios.get(url)
                commit('setItems', items.data.data)
                commit('setTotalItems', items.data.total)
                commit('setPage', 1)
                commit('setLoading', false)
            } catch(error) {
                let notification = {
                    show: true,
                    message: error.response.data.message,
                    type: 'error'
                }
                commit('showNotification', notification)
                commit('setLoading', false)
            }
        },
        getItems: async ( {commit, state} ) => {
            commit('setLoading', true)
            let url = `${endPoints.releases}?page=${state.pagination.page}`
            try {
                let items = await axios.get(url)
                commit('setItems', items.data.data)
                commit('setTotalItems', items.data.total)
                commit('setLoading', false)
            } catch(error) {
                let notification = {
                    show: true,
                    message: error.response.data.message,
                    type: 'error'
                }
                commit('showNotification', notification)
                commit('setLoading', false)
            }
            
        },
        save: async ( {commit, state, dispatch}, payload = [] ) => {
            let url = endPoints.releases
            let func = ''
            state.mode === 'ADD' ? func = axios.post : (func = axios.put, url += `/${state.selectedItem.id}`)
            commit('setLoading', true)
            try {
                
                let data = new Date(state.selectedItem.date)
                state.selectedItem.date = `${data.getFullYear()}-${data.getMonth() + 1}-${data.getDate()} ${data.getHours()}:${data.getMinutes()}:${data.getSeconds()}`
                await func(url, state.selectedItem)

                payload.forEach( (title) => {
                    for(let j = 0; j < state.requests.length; j++) {
                        if(title === state.requests[j].title) {
                            commit('requests/setMode', 'EDIT', {root:true})
                            let request = state.requests[j]
                            request.observation = `Concluido na versão ${state.selectedItem.version}`
                            request.status = 1
                            commit('requests/setSelectedItem', request, {root:true})
                            dispatch('requests/save', {}, {root:true})
                            break
                        }
                    }
                })
                
                let notification = {
                    show: true,
                    message: 'Atualização salva com sucesso',
                    type: 'success'
                }
                commit('showNotification', notification)
                commit('setShowDialog', false)
                commit('setSelectedItem', {})
                commit('setLoading', false)
                dispatch('getItems')
            } catch(error) {
                let notification = {
                    show: true,
                    message: error?.response?.data?.message,
                    type: 'error'
                }
                commit('showNotification', notification)
                commit('setLoading', false)
            }
        },
        deleteItem: async ( {commit, dispatch, state} ) => {
            commit('setLoading', true)
            try {
                let notification = {
                    show: true,
                    message: 'Atualização salva com sucesso',
                    type: 'success'
                }

                await axios.delete(`${endPoints.releases}/${state.selectedItem.id}`)

                commit('setLoading', false)
                commit('setDeleteDialog', false)
                commit('setSelectedItem', {})
                commit('showNotification', notification)

                return dispatch('getItems')
            } catch(error) {
                let notification = {
                    show: true,
                    message: error.response.data.message,
                    type: 'error'
                }
                commit('showNotification', notification)
                commit('setLoading', false)
            }
        },
        getRequestsByTitle: async ( {commit}, payload ) => {
            let url = endPoints.requests + `/search?title=${payload}`
            let data = await axios.get(url)
            commit('setRequests', data.data)
        }
    }
}