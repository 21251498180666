<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar
      :color="notification.type"
      right
      top
      :timeout="2000"
      v-model="notification.show"
    >
      {{ notification.message }}
    </v-snackbar>

    <v-toolbar flat class="ma-2 mb-5 elevation-6 px-3">
      <div>
        <v-toolbar-title>Venda de VIPs</v-toolbar-title>
      </div>
      <v-spacer></v-spacer>
      <div class="button-toolbar">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="#333"
              dark
              class="ma-2"
              @click="setShowFilter(true)"
              v-bind="attrs"
              v-on="on"
              small
            >
              <v-icon color="white">mdi-filter</v-icon>
            </v-btn>
          </template>
          <span>Filtro</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="#333"
              dark
              class="ma-2"
              @click="getItems"
              v-bind="attrs"
              v-on="on"
              small
              ><v-icon>mdi-refresh</v-icon></v-btn
            >
          </template>
          <span>Recarregar</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="#333"
              dark
              class="ma-2"
              v-bind="attrs"
              v-on="on"
              @click="add()"
              small
              ><v-icon>mdi-plus</v-icon></v-btn
            >
          </template>
          <span>Adicionar Venda</span>
        </v-tooltip>
      </div>
    </v-toolbar>

    <!-- <v-data-table
      :headers="headers"
      :loading="loading"
      :items="items"
      :options.sync="pagination"
      :server-items-length="totalItems"
      class="elevation-6 ma-2 table-margin px-6 pt-3 row-pointer"
      no-data-text="Nenhuma venda de Vip encontrada"
      @click:row="(item) => edit(item)"
    > -->
    <v-data-table
      :headers="headers"
      :loading="loading"
      :items="items"
      :options.sync="pagination"
      :server-items-length="totalItems"
      class="elevation-6 ma-2 table-margin px-6 pt-3 row-pointer"
      no-data-text="Nenhuma venda de Vip encontrada"
      :footer-props="{
        'items-per-page-options': [10, 20, 50, 100],
        'items-per-page-text': 'Itens por página',
        'show-first-last-page': true,
        'page-text': `Página ${pagination.page} de ${parseInt(totalItems/pagination.itemsPerPage) + 1}`
      }" 
    >
      <template v-slot:[`item.date_created`]="{ item }">
        <span>{{ getDate(item.date_created)}}</span>
      </template>

      <template v-slot:[`item.type`]="{ item }">
        <!-- <span>{{ item.type== 0 ? "Tipo 1" : item.type== 1 ? "Tipo 2" : "Tipo 3" }}</span> -->
        <span>{{ getPossibleTypes(item.type) }}</span>
      </template>

      <template v-slot:[`item.value`]="{ item }">
        <span>{{ item.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
      </template>

      <template v-slot:[`item.payment_type`]="{ item }">
       <span>{{ getPaymentType(item.payment_type) }}</span>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-chip :color="getColor(item.status)">
          <span>{{ 
            item.status == 0 ? "Pendente" : 
            item.status == 1 ? "Aguardando Envio" : 
            item.status == 2 ? "Pago" : 
            item.status == 3 ? "Concluído" : "Erro bot" }}
            </span>
        </v-chip>
      </template>

      <template v-slot:[`item.actions`]="{ item }" v-align="right">

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              v-on="on"
              :disabled="!(!item.has_receipt == 0 && item.status == 0)"
              v-bind="attrs"
              class="mr-2"
              @click="getRelatedPixes({ deposit: item, screen: 'deposit' })"
              >mdi-file-multiple</v-icon
            >
          </template>
          <span>Pix relacionados</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ attrs, on }">
            <v-icon
              small
              :disabled="item.has_receipt == 0 "
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="showReceipt(item)"
              >mdi-receipt</v-icon
            >
          </template>
          <span>Comprovante</span>
        </v-tooltip>
        

        <v-tooltip bottom>
          <template v-slot:activator="{ attrs, on }">
            <v-icon
              small
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="view(item)"
              >mdi-information</v-icon
            >
          </template>
          <span>Informações da venda</span>
        </v-tooltip>

        <v-tooltip bottom >
          <template v-slot:activator="{ attrs, on }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              :disabled="(item.status == 3 || item.status == 2)"
              small
              class="mr-2"
              @click="edit(item)"
              >mdi-pencil</v-icon
            >
          </template>
          <span>Editar Venda</span>
        </v-tooltip>

        <v-tooltip bottom >
          <template v-slot:activator="{ attrs, on }">
            <v-icon
              v-bind="attrs"
              :disabled="(item.status == 3 || item.status == 2)"

              v-on="on"
              small
              class="mr-2"
              @click="deleteVip(item)"
              >mdi-delete</v-icon
            >
          </template>
          <span>Excluir Venda</span>
        </v-tooltip>

        <v-tooltip bottom >
          <template v-slot:activator="{ attrs, on }">
            <v-icon
              v-bind="attrs"
              :disabled="!(item.status == 2 || item.status == 4)"
              v-on="on"
              small
              class="mr-2"
              @click="concludeVip(item)"
              > {{ item.status == 3 ? "mdi-check-bold" : "mdi-checkbox-marked-circle" }} </v-icon
            >
          </template>
          <span>Concluir Venda</span>
        </v-tooltip>

      </template>
    </v-data-table>



    
    <v-dialog
      v-model="dialogReceipt"
      @keydown.esc="setDialogReceipt({ show: false })"
      width="90%"
      @click:outside="setDialogReceipt({ show: false })"
    >
      <v-card height="90vh">
        <v-card-title right>
          <v-spacer></v-spacer>
          <v-menu
              bottom
              right
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="setDialogReceipt({ show: false })"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
            </v-menu>
        </v-card-title>
        <iframe
          v-if="receiptMimeType == 'pdf'"
          height="100%"
          width="100%"
          :src="receipt_show"
        ></iframe>
        <v-img
          v-if="receiptMimeType != 'pdf'"
          width="100%"
          max-height="100%"
          :src="receipt_show"
          contain
        ></v-img>
      </v-card>
    </v-dialog>

    <!-- <v-dialog v-model="dialogContact" max-width="500">
      <v-card>
        <contact-card
          :contact_id="contact_show.phone"
          :contact_name="contact_show.name"
        ></contact-card>
      </v-card>
    </v-dialog> -->

    <!-- <v-dialog v-model="dialogAbate" persistent max-width="650">
      <v-card>
        <v-card-title class="headline text-center justify-center"
          >ATENÇÃO</v-card-title
        >
        <v-card-title
          >VOCÊ ACABOU DE FINALIZAR UM DEPÓSITO DO TIPO ABATE DE ANOTADOS
          !!!!!</v-card-title
        >
        <v-card-title
          >TENHA CERTEZA DE QUE NÃO REALIZOU NENHUM ENVIO DE FICHAS PARA ESSA
          OPERAÇÃO !!!!!</v-card-title
        >

        <v-card-actions>
          <v-col class="text-right">
            <v-btn
              text
              type="submit"
              right
              @click="showDialogAbate({ show: false })"
              >EU NÃO ENVIEI FICHAS</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog> -->

    <v-dialog
      content-class="dialog-pix"
      :value="dialogPixRelated"
      @keydown.esc="showDialogPixRelateds({ show: false })"
      width="95%"
      @click:outside="showDialogPixRelateds({ show: false })"
    >
      <v-card>
        <v-card-title class="text-center justify-center"
          >Relacionamento Pix - Deposito</v-card-title
        >
        <v-layout wrap>
          <v-flex xs12 sm12 md12 xl12>
            <template>
              <v-data-table
                :headers="headersPixRelated"
                :loading="loading"
                :items="[selectedItem]"
                :items-per-page="1"
                hide-default-footer
              >
                <template v-slot:[`item.date_created`]="{ item }">
                  <span>{{ getDate(item.date_created)}}</span>
                </template>

                <template v-slot:[`item.type`]="{ item }">
                  <span>{{ item.type== 0 ? "Tipo 1" : item.type== 1 ? "Tipo 2" : "Tipo 3" }}</span>
                </template>

                <template v-slot:[`item.value`]="{ item }">
                  <span>{{ item.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
                </template>

                <template v-slot:[`item.payment_type`]="{ item }">
                  <span>{{ getPaymentType(item.payment_type) }}</span>
                </template>

                <template v-slot:[`item.status`]="{ item }">
                  <v-chip :color="getColor(item.status)">
                    <span>{{ item.status == 0 ? "Pendente" : item.status == 1 ? "Pago" : item.status == 2 ? "Concluído" : "Erro bot" }}</span>
                  </v-chip>
                </template>
              </v-data-table>

            </template>

          </v-flex>
          <v-flex xs12 sm12 md4 xl4 class="mb-2">
            <div class="pixes-related">
              <v-card
                class="mb-2 ml-2 mr-2 card-pix-related"
                color="#385F73"
                dark
                v-for="pix in pix_relateds"
                :key="pix.id"
              >
                <v-card-title>
                  Cliente:
                  {{ pix.client_key ? pix.client_key : "Não identificado" }}
                </v-card-title>
                <v-card-subtitle>
                  <b>{{ pix.client_name }}</b>
                  <br />
                  <b>{{ pix.endToEndId }}</b>
                  <br />
                  {{
                    "Valor: " +
                    pix.value.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })
                  }}
                  <br />
                  {{ "Data: " + getDate(pix.date) }}
                  <br />
                  {{
                    "Utilizado: " +
                    (pix.deposit_id && pix.deposit_id != null
                      ? `Sim - ${pix.deposit_id}`
                      : "Não")
                  }}
                </v-card-subtitle>
                <v-btn
                  depressed
                  class="ml-2 mb-2"
                  @click="linkDeposit({ item: pix })
                  "
                  >Vincular Depósito</v-btn
                >
              </v-card>
            </div>
          </v-flex>
          <v-flex xs12 sm12 md8 xl8>
            <div v-if="receiptMimeType != 'pdf'" class="pixes-related-image">
              <v-img max-height="100%" :src="receipt_show" contain></v-img>
            </div>
            <div v-if="receiptMimeType == 'pdf'" class="pixes-related-frame">
              <iframe height="100%" width="100%" :src="receipt_show"></iframe>
            </div>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>

    <!-- <v-dialog
      :value="dialogRefuseDeposit"
      @keydown.esc="showDialogRefuseDeposit({ show: false })"
      @click:outside="showDialogRefuseDeposit({ show: false })"
      width="450"
    >
      <v-card>
        <v-card-title class="headline text-center justify-center">
          Recusar Saque
        </v-card-title>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex>
              <v-textarea
                auto-grow
                required
                label="Mensagem *"
                v-model="message_refused"
                :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                :error-messages="errors.collect('message_refused')"
                v-validate="'required:Mensagem,message_refused'"
                data-vv-name="message_refused"
                outlined
                rows="2"
              ></v-textarea>
            </v-flex>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-col>
            <v-btn text @click="showDialogRefuseDeposit({ show: false })"
              >Cancelar</v-btn
            >
          </v-col>
          <v-col class="text-right">
            <v-btn
              text
              :disabled="block_refuse"
              :readonly="block_refuse"
              @click="
                refuseDeposit({ deposit: depositAutomatic, screen: 'deposit' })
              "
              >Recusar</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog> -->


    <v-dialog
      :value="showDialog"
      @keydown.esc="setShowDialog({ show: false })"
      @click:outside="setShowDialog(false)"
      width="1000"
    >
      <vip-sales-form></vip-sales-form>
    </v-dialog>

    <v-dialog
      :value="filterDialog"
      @keydown.esc="setShowFilter({ show: false })"
      @click:outside="setShowFilter(false)"
      width="600"
    >
      <vip-sales-filter></vip-sales-filter>
    </v-dialog>

    <v-dialog
      :value="deleteDialog"
      @click:outside="setDeleteDialog(true)"
      @keydown.enter="deleteItem"
      width="300"
    >
      <v-card>
        <v-card-title class="headline">Confirmar a exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn text @click="setDeleteDialog(false)">Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn text type="submit" right @click="deleteItem"
              >Confirmar</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      :value="concludeDialog"
      @click:outside="setConcludeDialog(true)"
      @keydown.enter="concludeItem"
      persistent
      width="310"
      style="overflow-x: hidden;"
    >
      <v-card>
        <v-card-title class="headline">Confirmar a conclusão da transação? </v-card-title>
        <v-card-text class="pl-6">Essas ação não pode ser revertida posteriormente </v-card-text>

        <v-card-actions>
          <v-col>
            <v-btn text @click="setConcludeDialog(false)">Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn text type="submit" right @click="concludeItem"
              >Confirmar</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import VipSalesForm from "./VipSalesForm";
import VipSalesFilter from "./VipSalesFilter";
// import DepositSearchPix from "./DepositSearchPix";


export default {
  name: "VipSales",

  created() {
    this.getItems({ resetPage: false });
    this.getBanks();
    this.watchDeposits();
    this.setScreen({ screen: "deposit" });
  },

  components: {
    "vip-sales-form": VipSalesForm,
    "vip-sales-filter": VipSalesFilter,
    // "deposit-search-pix": DepositSearchPix,

  },

  methods: {
    ...mapActions("bank", {"getBanks": "getItems"}),
    ...mapActions("vip_sales", [
      "getItems", 
      "deleteItem",
      "concludeItem",
      "showReceipt",
      "getRelatedPixes",
    ]),
    ...mapActions("vip_sales", [
      "linkDeposit",
      "linkDepositFinished",
    ]),

    ...mapMutations("vip_sales", [
      "setShowDialog",
      "add",
      "edit",
      "view",
      "deleteVip",
      "concludeVip",
      "setShowFilter",
      "setDeleteDialog",
      "setConcludeDialog",
      "receiptMimeType",
      "setDialogReceipt",
      "showDialogAbate",

    ]),
    ...mapMutations("vip_sales", [
      "setScreen",
      "showDialogSearchPix",
      "showDialogPixRelateds",
      "showDialogAbate",
      "showDialogRefuseDeposit",
    ]),
    watchDeposits() {
      if (this.mode == "LIST") {
        this.getItems({ resetPage: false });
      }
      if (this.$router) {
        if (this.$router.history.current.name == "deposits") {
          setTimeout(() => {
            this.watchDeposits();
          }, 25000);
        }
      }
    },
    getColor (status) {
        if (status == 0) return '#DEDEF1  ' //pendente
        else if (status == 1) return '#d4e9ed' //aguardando
        else if (status == 2) return '#e5efdd' //pago
        else if (status == 3) return '#f3f3f3' //concluído
        else return '#FFE4E1' //erro bot
    },
    getPaymentType(payment_type) {
     return this.possible_payment_types[payment_type].name
    },

    getDate(date) {
      if (date) {
        var d = new Date(date);
        return d.toLocaleDateString()+ " " + d.toLocaleTimeString();
      }
      return "";
    },
    getPossibleTypes(itemId) {
      return this.possible_types[itemId].name
    },

    // // profileAccess() {
    //   if (
    //     this.userData.role == 0 ||
    //     this.userData.role == 1 ||
    //     this.userData.role == 70
    //   ) {
    //     return false;
    //   } else {
    //     return true;
    //   }
    // }
  },
  computed: {
    ...mapState("bank", {"banks": "items"}),
    ...mapState("vip_sales", [
      "dialogSearchPix",
      "loadingSearchPix",
      "errors",
      "pix_relateds",
      "totalPixes",
      "dialogRefuseDeposit",
      "depositRefused",
      "dialogPrint",
    ]),
    ...mapState("vip_sales", [
      "userData",
      "items",
      "notification",
      "mode",
      "loading",
      "totalItems",
      "showDialog",
      "filterDialog",
      "deleteDialog",
      "concludeDialog",
      "dialogReceipt",
      "depositReceipt",
      "overlay",
      "pagination",
      "receipt_show",
      "selectedItem",
      "dialogAbate",

    ]),
    ...mapFields("vip_sales", [
      "pagination",
      "dialogPixRelated",
      "paginationPix",
      "showNotification",

    ]),
    ...mapFields("deposit", [
      "block_refuse",
      "depositRefused.message_refused",
      // ...
    ])
  },
  data: () => ({
    bank_pix: 77,
    expanded: [],
    headersPixRelated: [
      { text: "ID", value: "id", sortable: true },
      { text: "Data", value: "date_created", sortable: true },
      { text: "Id Jogador", value: "player_id", sortable: true, width: "6%" },
      { text: "Clube", value: "club_name", sortable: true },
      { text: "Tipo", value: "type", sortable: true },
      { text: "Banco", value: "bank_name", sortable: true },
      { text: "Valor", value: "value", sortable: true },
      { text: "Tipo de Pagamento", value: "payment_type", sortable: true, width: "10%" },
      { text: "Status", value: "status", sortable: true},
    ],
    headers: [
      { text: "ID", value: "id", sortable: true },
      { text: "Data", value: "date_created", sortable: true },
      { text: "Id Jogador", value: "player_id", sortable: true, width: "3%" },
      { text: "Clube", value: "club_name", sortable: true },
      { text: "Tipo", value: "type", sortable: true },
      { text: "Banco", value: "bank_name", sortable: true },
      { text: "Valor", value: "value", sortable: true },
      { text: "Tipo de Pagamento", value: "payment_type", sortable: true, width: "10%" },
      { text: "Status", value: "status", sortable: true,},
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        align: "center",
        width: "17%",
      },
    ],
    headersPix: [
      { text: "CPF/CPNJ", value: "client_key", sortable: true },
      {
        text: "Data",
        align: "left",
        value: "date",
        sortable: true,
      },
      { text: "Valor", value: "value", sortable: true },
      { text: "endToEndId", value: "endToEndId", sortable: false },
      { text: "txId", value: "txId", sortable: false },
      { text: "Utilizado", value: "deposit_id", sortable: true },
      { text: "Ações", value: "actions", sortable: false, align: "center" },
    ],
    possible_payment_types: [
      {
        id: 0,
        name: "Fichas"
      },
      {
        id: 1,
        name: "Pix"
      },
      {
        id: 2,
        name: "Anotado"
      }
    ],
    possible_types: [
        {
          id: 0,
          name: "Coelho"
        },
        {
          id: 1,
          name: "Time Bank"
        },
        {
          id: 2,
          name: "Emoji"
        },
        {
          id: 3,
          name: "Bronze 1 dia"
        },
        {
          id: 4,
          name: "Bronze 7 dias"
        },
        {
          id: 5,
          name: "Bronze 30 dias"
        },
        {
          id: 6,
          name: "Bronze 90 dias"
        },
        {
          id: 7,
          name: "Bronze 365 dias"
        },
        {
          id: 8,
          name: "Prata 1 dia"
        },
        {
          id: 9,
          name: "Prata 7 dias"
        },
        {
          id: 10,
          name: "Prata 30 dias"
        },
        {
          id: 11,
          name: "Prata 90 dias"
        },
        {
          id: 12,
          name: "Prata 365 dias"
        },
        {
          id: 13,
          name: "Ouro 1 dia"
        },
        {
          id: 14,
          name: "Ouro 7 dias"
        },
        {
          id: 15,
          name: "Ouro 30 dias"
        },
        {
          id: 16,
          name: "Ouro 90 dias"
        },
        {
          id: 17,
          name: "Ouro 365 dias"
        }
      ],
  }),
  watch: {
    pagination: {
      handler() {
        this.getItems({ resetPage: false });
      },
    },
  },
};
</script>

<style lang="sass">
.row-pointer > .v-data-table__wrapper > table > tbody > tr:hover
  cursor: pointer

.dialog-pix
  margin: 5px
  overflow-y: hidden !important

.pixes-related
  height: 60vh
  overflow-y: scroll

.pixes-related-image
  max-height: 60vh
  overflow: scroll

.pixes-related-frame
  height: 100%

.pixes-related::-webkit-scrollbar
  width: 8px
  background-color: #e4dcd4

.pixes-related::-webkit-scrollbar-thumb
  border-radius: 6px
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0)
  background-color: #e4dcd4

.pixes-related-image::-webkit-scrollbar
  width: 8px
  background-color: #e4dcd4

.pixes-related-image::-webkit-scrollbar-thumb
  border-radius: 6px
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0)
  background-color: #e4dcd4

.card-pix-related
  text-color: white !important

iframe::-webkit-scrollbar
  width: 8px
  background-color: #e4dcd4

iframe::-webkit-scrollbar-thumb
  border-radius: 6px
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0)
  background-color: #e4dcd4

</style>