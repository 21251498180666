import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../../config/endPoints';

const defaultItem = {
    club: '',
    type_manager: 0,
    id_pppoker: '',
    nick: '',
    transactions_charged: ''
};

export default {
    namespaced: true,
    state: {
        contacts: [],
        dialog: false,
        dialogDelete: false,
        dialogFilter: false,
        editedItem: {},
        filter: {
            club: '',
            type_manager: '',
            id_pppoker: '',
            nick: '', 
            transactions_charged: ''
        },
        instances: [],
        items: [],
        itemToDelete: {},
        loading: false,
        mode: 'LIST',
        page: 1,
        pagination: {
            page: 1,
            itemsPerPage: 10
        },
        successNotification: false,
        notificationMessage: '',
        notificationType: "success",
        selectedContact: {},
        showNotification: false,
        totalItems: 0,
        rowsPerPage: 10
    },
    mutations: {
        add: (state) => {
            state.mode = "ADD"
            state.editedItem = Object.assign({}, defaultItem)
            state.dialog = true
        },
        edit: (state, payload) => {
            state.selectedContact = {}
            state.editedItem = Object.assign({}, payload.item)
            state.editedIndex = state.items.indexOf(payload.item)
            state.mode = "EDIT"
            state.dialog = true
        },
        closeDialog: (state) => {
            state.editedItem = {}
            state.editedIndex = -1
            state.errors = [];
            state.dialog = false
            state.mode = "LIST"
        },
        loading: (state, loading) => state.loading = loading,
        setMode: (state, mode) => state.mode = mode,
        setItems: (state, items) => state.items = items,
        setItemToDelete: (state, item) => state.itemToDelete = item,
        setTotalItems: (state, total) => state.totalItems = total,
        setRowsPerPage: (state, rowsPerPage) => state.rowsPerPage = rowsPerPage,
        showDialog: (state, show) => state.dialog = show,
        setPage: (state, payload) => {
            state.pagination.page = payload.page;
            state.pagination.itemsPerPage = payload.itemsPerPage
        },
        setContacts: (state, payload) => {
            state.contacts = payload.contacts
        },
        setDialogDelete: (state, show) => state.dialogDelete = show,
        showFilter: (state, payload) => state.dialogFilter = payload.show,
        showErrorNotification: (state, show) => state.errorNotification = show,
        showSuccessNotification: (state, show) => state.successNotification = show,
        showLoading: (state, show) => state.loading = show,
        showNotification: (state, payload) => {
            state.showNotification = payload.show,
                state.notificationMessage = payload.message,
                state.notificationType = payload.type
        },
        updateField
    },
    getters: {
        getField,
    },
    actions: {
        closeDialog: (({ commit }) => {
            commit('closeDialog', false)
        }),
        closeDeleteDialog: ({ commit }) => {
            commit('setDialogDelete', false)
            commit('setItemToDelete', {})
        },
        deleteItem: ({ dispatch, commit, state }) => {
            commit('loading', true)
            axios.delete(endPoints.managers + "/" + state.itemToDelete.id, state.itemToDelete).then(() => {
                dispatch('closeDeleteDialog')
                commit('loading', false)
                let notification = {
                    show: true,
                    message: "Gestor excluido com sucesso",
                    type: "success"
                }
                commit('showNotification', notification)
                return dispatch('getItems', { resetPage: false });
            });
        },
        getItems: ({ commit, state }, payload) => {
            commit('loading', true)
            let url = endPoints.managers;
            let filter = state.filter;
            if (payload.resetPage === true) {
                url += '?page=' + 1 + '&' + 'itemsPerPage=' + 10;
                commit('setPage', { page: 1, itemsPerPage: 10 })
            } else {
                url += '?page=' + state.pagination.page + '&' + 'itemsPerPage=' + state.pagination.itemsPerPage;
            }


            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined) {
                    url += "&" + field + "=" + filter[field]
                }
            });

            axios.get(url).then(items => {
                commit('showFilter', { show: false })
                commit('setTotalItems', items.data.total)
                commit('loading', false)
                commit('setItems', items.data.data)
            });
        },
        getItemsNoPage: ({ state }) => {
            let url = endPoints.managers;
            let filter = state.filter;
            url += '?page=' + '1' + '&' + 'itemsPerPage=' + '99999';

            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined) {
                    url += "&" + field + "=" + filter[field]
                }
            });
            return axios.get(url).then(items => {
                return items.data.data
            });
        },
        showDeleteDialog: ({ commit }, item) => {
            commit('setDialogDelete', true)
            commit('setItemToDelete', item)
        },
        save: ({ dispatch, commit, state }) => {
            var url = endPoints.managers;
            let func = '';
            if (state.mode == 'ADD') {
                func = axios.post;
                url = endPoints.managers;
            } else {
                func = axios.put;
                url += '/' + state.editedItem.id;
            }

            func(url, state.editedItem).then(
                () => {
                    dispatch('getItems', { resetPage: true })
                    let notification = {
                        show: true,
                        message: "Gestor salvo com sucesso",
                        type: "success"
                    }
                    commit('showNotification', notification)
                    commit('closeDialog')
                },
                error => {
                    let notification = {
                        show: true,
                        message: error.response.data.message,
                        type: "error"
                    }
                    commit('showNotification', notification)
                }
            );

        },
        viewItem({ dispatch }, payload) {
            dispatch('menu/openLink', '/managers/' + payload.item.id, { root: true })
        }
    }
}