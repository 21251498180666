import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../../config/endPoints';

const defaultItem = {
    player_id: ""
}

export default {
    namespaced: true,
    state: {
        agent_id: 0,
        items: [],
        loading: false,
        dialog: false,
        confirmDialog: false,
        formDialog: false,
        dialogFilter: false,
        overlay: false,
        players: [],
        pagination: {
            page: 1,
            itemsPerPage: 10
        },
        filter: {
            id_pppoker: '',
            name: '',
        },
        editedItem: {},
        totalItems: 0,
        percentage: null,
        player_agent_id: 0
    },
    mutations: {
        loading: (state, payload) => state.loading = payload.loading,
        setItems: (state, payload) => {
            state.items = payload.items
        },
        overlay: (state, payload) => {
            state.overlay = payload.show
        },
        add: (state) => {
            state.dialog = true
            state.editedItem = Object.assign({}, defaultItem)
        },
        edit: (state, payload) => {
            state.percentage = payload.percentage
            state.player_agent_id = payload.player_agent_id
            state.formDialog = true
        },
        setPlayers: (state, players) => state.players = players,
        showDialog: (state, show) => state.dialog = show,
        showConfirmDialog: (state, show) => state.confirmDialog = show,
        showFormDialog: (state, show) => state.formDialog = show,
        setTotalItems: (state, payload) => state.totalItems = payload.total,
        showFilter: (state, payload) => state.dialogFilter = payload.show,
        updateField
    },
    getters: {
        getField,
    },
    actions: {
        closeDialog: ({ state, commit }) => {
            state.editedItem = Object.assign({}, {})
            commit('showDialog', false)
        },
        closeFormDialog: ({ state, commit }) => {
            state.percentage = null
            commit('showFormDialog', false)
        },
        getItems: ({ commit, rootGetters, state }) => {
            commit('loading', { loading: true })
            const filter = state.filter
            let url = endPoints.players_agent
            url += `/${rootGetters["agent/getIdAgent"]}`
            url += '?page=' + state.pagination.page + '&' + 'itemsPerPage=' + state.pagination.itemsPerPage;

            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
                    url += "&" + field + "=" + filter[field]
                }
            });

            axios.get(url).then(response => {
                let data = response.data != null ? response.data : []
                commit('setTotalItems', { total: data.total })
                commit('loading', { loading: false })
                commit('setItems', { items: data.data })
                console.log(state.items)
            });
        },
        getPlayers: ({ commit, rootGetters }, payload) => {
            let url = endPoints.agents;
            url += '/players_by_id_pppoker/' + payload.search + '?agent_id=' + rootGetters["agent/getIdAgent"] + "&club=" + rootGetters["agent/getClubAgent"]
            axios.get(url).then(response => {
                commit('setPlayers', response.data)
            });
        },
        save: ({ state, rootGetters, commit }) => {
            const url = endPoints.players_agent + "/" + state.editedItem.player_id
            const body = {
                club: rootGetters["agent/getClubAgent"],
                id_agent: rootGetters["agent/getIdAgent"],
                id_pppoker_agent: rootGetters["agent/getIdPPPokerAgent"]
            }
            
            axios.post(url, body).then(() => {
                commit('showDialog', false)
                commit('showConfirmDialog', false)
            });
        },
        saveDeal: ({ state, commit, dispatch }) => {
            const url = endPoints.agents + "/players_agent/" + state.player_agent_id;
            const body = {
                player_deal: parseFloat(state.percentage)
            }
            
            axios.post(url, body).then(() => {
                commit('showFormDialog', false)
                dispatch("getItems")
            });
        }
    }
}