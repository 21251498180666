<template>
    <v-list class="attendances elevation-12" subheader>
        <div class="subheader-at">
            <v-subheader class="subheader-at">
                Atendimentos
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on"
                            >mdi-note-multiple-outline</v-icon
                        >
                    </template>
                    <span>Auditoria</span>
                </v-tooltip>
                <v-switch
                    v-model="audit_mode"
                    value
                    input-value="true"
                    color="indigo"
                ></v-switch>
                <v-spacer></v-spacer>
                <v-switch
                    v-model="showPlayers"
                    value
                    input-value="true"
                    color="indigo"
                ></v-switch>
                <div :class="{ 'row-red': activePlayers > 5 }">
                    {{ activePlayers + ' ' }}
                </div>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">mdi-account</v-icon>
                    </template>
                    <span>Visualizar Jogadores</span>
                </v-tooltip>
                <v-switch
                    v-model="showGroup"
                    value
                    input-value="true"
                    color="indigo"
                ></v-switch>
                <div
                    class="count-agents"
                    :class="{ 'row-red': activeAgents > 5 }"
                >
                    {{ activeAgents + ' ' }}
                </div>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on"
                            >mdi-account-group</v-icon
                        >
                    </template>
                    <span>Visualizar Agentes</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            v-bind="attrs"
                            v-on="on"
                            @click="
                                showDialogContacts({
                                    show: true,
                                    mode: 'new-chat',
                                })
                            "
                            >mdi-plus</v-icon
                        >
                    </template>
                    <span>Novo Chat</span>
                </v-tooltip>
            </v-subheader>
        </div>
        <v-divider class="divider-black"></v-divider>
        <div class="filter">
            <v-layout wrap>
                <v-flex xs12 sm12 md12 xl12>
                    <v-text-field
                        class="mt-2 mr-1"
                        placeholder="Procurar"
                        prepend-inner-icon="mdi-magnify"
                        dense
                        outlined
                        v-model="chatFilter"
                        hide-details
                    ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                    <v-select
                        class="mt-2 mb-2"
                        label="Números"
                        :items="instancesUser"
                        item-text="nick"
                        item-value="id"
                        v-model="instanceFilter"
                        outlined
                        hide-details
                        multiple
                        dense
                        small-chips
                        deletable-chips
                    >
                        <template slot="item" slot-scope="data">
                            <v-simple-checkbox
                                :value="getInstanceChecked(data.item.id)"
                                @click="setInstanceChecked(data.item.id)"
                                class="mr-2"
                            ></v-simple-checkbox>
                            <v-list>
                                <v-list-item>
                                    {{ data.item.nick }}

                                    <div
                                        v-if="showHourError(data.item)"
                                        class="instance_d_hour"
                                    >
                                        <spam textColor="red">
                                            {{ getHour(data.item) }}
                                        </spam>
                                        <v-icon color="red" small
                                            >mdi-alert-circle</v-icon
                                        >
                                    </div>
                                </v-list-item>
                            </v-list>
                        </template>
                    </v-select>
                </v-flex>
            </v-layout>
        </div>
        <v-divider></v-divider>
        <v-list-item-group v-model="chat">
            <template v-for="(chat, index) in filteredChats">
                <v-list-item
                    v-bind:key="chat.contact_id"
                    @click="openChat(chat)"
                    :class="classByTime(chat)"
                >
                    <!--v-list-item-avatar>
                        <v-img v-if="chat.avatar" :src="chat.avatar"></v-img>
                    </v-list-item-avatar-->
                    <v-icon color="primary" v-if="contact_id == chat.contact_id">mdi-arrow-right</v-icon>
                    <v-list-item-content style="margin-left:20px;">
                        <v-list-item-title
                            class="font-weight-medium"
                            :class="{
                                fontblack: blackTextColor(chat),
                                fontprimary: blueTextColor(chat),
                            }"
                            v-html="
                                chat.contact_name
                                    ? chat.contact_name
                                    : chat.contact_id
                            "
                        ></v-list-item-title>
                        <v-list-item-subtitle>
                            <div
                                class="instance_name"
                                :class="{
                                    fontblack:
                                        classByTime(chat) != '',
                                    fontprimary: blueTextColor(chat),
                                }"
                            >
                                {{ chat.instance_name }}
                            </div>
                            <v-chip
                                class="chip-type-contact"
                                v-if="chat.is_group"
                                x-small
                                color="success"
                                >Grupo</v-chip
                            >
                            <v-chip
                                class="chip-type-contact"
                                v-else
                                x-small
                                color="info"
                                >Jogador</v-chip
                            >
                            <v-chip
                                class="chip-type-contact"
                                v-if="
                                    chat.attendance_id && chat.attendance_id > 0
                                "
                                x-small
                                color="purple"
                                text-color="white"
                                >{{
                                    chat.user_attendance
                                        ? chat.user_attendance
                                        : '-'
                                }}</v-chip
                            >
                        </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action class="chat-action">
                        <div>
                            <v-icon
                                v-if="chat.registered > 0"
                                :color="getColorRegistered(chat.registered)"
                                v-on="on"
                                >mdi-check-decagram</v-icon
                            >
                            <v-icon @click="setChatUnreaded(chat)">{{
                                getChatIcon(chat)
                            }}</v-icon>
                        </div>
                        <div
                            class="message-data-chat"
                            :class="{
                                fontblack: blackTextColor(chat),
                            }"
                        >
                            {{ getLastMessageHour(chat) }}
                        </div>
                    </v-list-item-action>
                </v-list-item>
                <v-divider v-bind:key="index"></v-divider>
            </template>
        </v-list-item-group>
    </v-list>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import debounce from '../../../../util/debounce.js'
import l from 'lodash'

export default {
    created() {
        this.getInstances()
        this.getClubs({ resetPage: false, noPages: true, onlyActive: true});
    },
    computed: {
        ...mapState('chat', ['contact_id']),
        ...mapGetters('login', ['getInstanceId']),
        ...mapGetters('instances', ['instancesChat']),
        ...mapFields('chat', [
            'audit_mode',
            'instanceFilter',
            'showPlayers',
            'showGroup',
        ]),
        activePlayers() {
            let total = 0
            this.filteredChats.map(
                (c) => (total += c.is_group == false ? 1 : 0),
            )
            return total
        },
        activeAgents() {
            let total = 0
            this.filteredChats.map((c) => (total += c.is_group == true ? 1 : 0))
            return total
        },
        instancesUser: {
            get: function () {
                let instanceId = this.getInstanceId
                if (
                    instanceId != '' &&
                    instanceId != null &&
                    instanceId != undefined
                ) {
                    return this.instancesChat.filter((i) => {
                        return i.id == this.getInstanceId
                    })
                }
                return this.instancesChat
            },
        },
        filteredChats: {
            get: function () {
                if (this.chatFilter == '') {
                    return this.chats
                } else {
                    return this.chats.filter((c) => {
                        return (
                            c.contact_name
                                .toLowerCase()
                                .indexOf(this.chatFilter.toLowerCase()) != -1
                        )
                    })
                }
            },
            set: function () {},
        },
    },
    data: () => ({
        chat: {},
        chatFilter: '',
        oldCountNotification: -1,
        dataAtual: new Date(),
        filter_type: true,
        filter_types: [
            { id: false, description: 'Não ver' },
            { id: true, description: 'Ver' },
        ],
    }),
    methods: {
        ...mapMutations('chat', ['showDialogContacts', 'closeDialog']),
        ...mapActions('chat', ['updateInstancesFilter']),
        ...mapActions('instances', ['getInstances']),
        ...mapActions("clubs", ["getClubs"]),
        classByTime(chat) {
            if (chat.receive_time && chat.receive_time != 0) {
                var actualTime = new Date().getTime()
                if (this.checkChatPriority(chat)) {
                    return 'chat-priority'
                } else {
                    var diff = actualTime - chat.receive_time
                    diff /= 60000
                    if (diff < 2) {
                        return ''
                    } else if (diff > 2 && diff < 3) {
                        return 'chat-time-1'
                    } else if (diff < 3) {
                        return 'chat-time-2'
                    } else if (diff < 5) {
                        return 'chat-time-3'
                    } else if (diff < 7) {
                        return 'chat-time-4'
                    } else if (diff < 8) {
                        return 'chat-time-5'
                    } else {
                        return ''
                    }
                }
            }
            return 'bk-red'
        },
        blueTextColor(chat) {
            return this.contact_id == chat.contact_id
        },
        blackTextColor(chat) {
            let classByTime = this.classByTime(chat)
            if(this.$vuetify.theme.dark && classByTime != ''){
                return true
            } else {
                return false;
            }
        },
        checkChatPriority(chat) {
            if (chat.description.chips != undefined && chat.description.chips.length > 0) {
                if (chat.description.chips[10]){
                    if (chat.description.chips[10].status == 1){
                        return true
                    }
                }
            }
            return false
        },
        getColorRegistered(registered) {
            if (registered == 9) {
                return 'purple'
            }
            if (registered == 10) {
                return 'black'
            } else if (registered == 50) {
                return 'green'
            } else if (registered == 99) {
                return 'blue'
            }
        },
        getLastMessageHour(chat) {
            var messageDate = new Date(chat.last_message_time)
            var difference = this.dataAtual.getTime() - messageDate.getTime()
            if (difference < 0) difference *= -1
            difference /= 1000 * 3600 * 24
            if (
                this.dataAtual.toLocaleDateString() ==
                messageDate.toLocaleDateString()
            ) {
                return messageDate.toLocaleTimeString().substr(0, 5)
            }
            return messageDate.toLocaleString().substr(0, 10)
        },
        getBadgeChat(chat) {
            return chat.readed_time <= chat.last_message_time
        },
        getChatIcon(chat) {
            return chat.readed == false ? 'mdi-chat-alert' : 'mdi-chat-outline'
        },
        getInstanceChecked(instanceId) {
            return this.instanceFilter.indexOf(instanceId) !== -1
        },
        setInstanceChecked(instanceId) {
            let indexInstanceIdExists =
                this.instanceFilter.indexOf(instanceId) === -1

            if (indexInstanceIdExists) {
                this.instanceFilter.push(instanceId)
            } else {
                this.instanceFilter = l.difference(this.instanceFilter, [
                    instanceId,
                ])
            }
        },
        showHourError(instance) {
            if (instance.disconnected_time.Int64 != 0) {
                var diffMs =
                    new Date().getTime() - instance.disconnected_time.Int64
                return Math.round(diffMs / 60000) < 60
            } else {
                return false
            }
        },
        getHour(instance) {
            return new Date(instance.disconnected_time.Int64)
                .toLocaleTimeString()
                .substr(0, 5)
        },
        notificationSound() {
            let context = new AudioContext()
            let oscillator = context.createOscillator()
            oscillator.frequency.value = 493.9
            let contextGain = context.createGain()

            oscillator.connect(contextGain)
            contextGain.connect(context.destination)
            oscillator.start(0)
            contextGain.gain.exponentialRampToValueAtTime(
                0.00001,
                context.currentTime + 0.5,
            )
        },
        watchInstance() {
            this.getInstances()
            if (this.$router) {
                var current = this.$router.history.current.name
                var watch = current == 'chat' || current == 'chatid'

                if (watch) {
                    setTimeout(() => {
                        this.watchInstance()
                    }, 120000)
                }
            }
        },
    },
    updated: function () {
        this.$nextTick(function () {
            let title = 'SX Grupo'
            let notification = this.chats.filter((c) => {
                return c.readed == false
            })
            if (notification.length != 0) {
                title = '(' + notification.length + ') ' + title
            }
            this.oldCountNotification = notification.length
            document.title = title
        })
    },
    mixins: [debounce],
    mounted() {
        this.filteredChats = this.chats
    },
    props: ['chats', 'getChats', 'isGroup', 'openChat', 'setChatUnreaded'],
    watch: {
        instanceFilter: function () {
            this.updateInstancesFilter()
        },
        showPlayers: function () {
            this.updateInstancesFilter()
        },
        showGroup: function () {
            this.updateInstancesFilter()
        },
    },
}
</script>


<style>
@import './chats.css';
</style>