<template>
  <div>
    <v-dialog :value="formDialog" width="800">
      <v-card>
        <v-card-title>
          <span class="headline">Novo Registro</span>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>

                <v-flex xs12 sm6 md6>
                  <v-select
                    label="Tipo de Bloqueio"
                    v-model="blocked_type"
                    :rules="[rules.requiredAcceptZero]"
                    :items="possible_blocked_types"
                    :readonly="this.mode == 'VIEW'"
                    data-vv-name="blocked_type"
                    item-text="name_blocked_type"
                    item-value="blocked_type"
                    clear-icon="mdi-close"
                    :clearable="['ADD', 'EDIT'].includes(this.mode)"
                    required
                    outlined
                  ></v-select>
                </v-flex>

                <v-flex v-if="blocked_type == 3" xs12 sm6 md6>
                  <v-select
                    label="Tipo PIX"
                    v-model="pix_type"
                    :rules="[rules.requiredAcceptZero]"
                    :items="possible_pix_types"
                    :readonly="this.mode == 'VIEW'"
                    data-vv-name="pix_type"
                    item-text="name_pix_type"
                    item-value="pix_type"
                    clear-icon="mdi-close"
                    :clearable="['ADD', 'EDIT'].includes(this.mode)"
                    required
                    outlined
                  ></v-select>
                </v-flex>

                <v-flex v-if="this.pix_type || this.pix_type == 0" xs12 sm12 md12>
                  <v-text-field
                    :rules="[rules.required]"
                    label="Chave PIX *"
                    v-model="pix_key"
                    data-vv-name="pix_key"
                    :clearable="['ADD', 'EDIT'].includes(this.mode)"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex v-if="blocked_type == 1" xs12 sm6 md6>
                  <v-text-field
                    label="CPF *"
                    :rules="[rules.required]"
                    v-model="cpf"
                    data-vv-name="cpf"
                    hint="Insira no máximo 11 caracteres"
                    maxlength="11"
                    counter
                    :clearable="['ADD', 'EDIT'].includes(this.mode)"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex v-if="blocked_type == 2" xs12 sm6 md6>
                  <v-text-field
                    label="CNPJ *"
                    :rules="[rules.required]"
                    v-model="cnpj"
                    data-vv-name="cnpj"
                    hint="Insira no máximo 14 caracteres"
                    maxlength="14"
                    counter
                    :clearable="['ADD', 'EDIT'].includes(this.mode)"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex v-if="this.blocked_type == 0" xs12 sm6 md6>
                  <v-text-field
                    :rules="[rules.required]"
                    label="ID *"
                    v-model="id_blacklist"
                    data-vv-name="id_blacklist"
                    :clearable="['ADD', 'EDIT'].includes(this.mode)"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm12 md12>
                  <v-textarea
                    label="Observação"
                    v-model="observation"
                    :rules="[rules.max_length(observation, 300)]"
                    rows="1"
                    maxlength="300"
                    hint="Insira no máximo 300 caracteres"
                    counter
                    outlined
                  ></v-textarea>
                </v-flex>

              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-col class="text-center">
            <v-btn
              raised
              color="error"
              @click="setDialog({ dialog: 'formDialog', show: false, mode: '' })"
              >{{ mode == "VIEW" ? "Voltar" : "Cancelar" }}</v-btn
            >
          </v-col>

          <v-col class="text-center">
            <v-btn
              raised
              color="success"
              type="submit"
              v-if="mode == 'EDIT' || mode == 'ADD' && profileAccess()"
              @click="saveBlacklist()"
              >Salvar</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "WithdrawalsBlacklistForm",
  computed: {
    ...mapState('login', ['userData']),
    ...mapState("withdrawals_blacklist", [
      "formDialog",
      "mode",
      "possible_blocked_types",
      "possible_pix_types",
      "selectedItem",
    ]),
    ...mapFields("withdrawals_blacklist", [
      "selectedItem.id",
      "selectedItem.id_blacklist",
      "selectedItem.blocked_type",
      "selectedItem.cpf",
      "selectedItem.cnpj",
      "selectedItem.observation",
      "selectedItem.pix_key",
      "selectedItem.pix_type",
    ]),
  },
  methods: {
    ...mapMutations("withdrawals_blacklist", ["setDialog", "setSelectedItem"]),
    ...mapActions("withdrawals_blacklist", ["save"]),

    saveBlacklist() {
      let valid = this.$refs.form.validate()
      if (valid) {
        //vamo ver no backend né
        // this.id_blacklist = toString(this.id_blacklist);
        // this.pix_key = toString(this.pix_key);
        this.save();
      }
    },
    profileAccess() {
      return this.userData.role >= 80
    },
  },
  data: () => ({
    valid: true,
    rules: {
      required(value) { 
        return !!value || 'Campo Obrigatório'
      },
      requiredAcceptZero(value) { 
        return !!value || value === 0 || 'Campo Obrigatório'
      },
      max_length(value, max_length) {
        if(value && value.length > max_length) return 'Máximo de ' + max_length + ' caracteres.'
        return true
      },
    }
  }),
};
</script>
