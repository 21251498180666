<template>
  <div @drop.prevent="addDropFile" @dragover.prevent>
    <v-dialog :value="showDialog" persistent width="640" >
      <v-card  class="px-4 ">

        <v-btn absolute fab right icon @click="setShowDialog(false)" >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-card class="d-flex flex-row align-end px-7 pb-2 elevation-0" tile>

          <div>
            <v-card-title class="pt-8 pl-0">
              <span class="headline font-weight-medium">Vincular Agente</span>
            </v-card-title>
            <v-card-subtitle class="pt-2 pl-0 pb-0">
              <span>Vincule o cadastro do agente a um cadastro de cliente</span>
            </v-card-subtitle>
          </div>
          <v-flex xs12 sm10 md10 class="pl-10" >
            <v-text-field
              class="mt-0 pb-0 mb-0 pr-0"
              label="Buscar Cliente"
              v-model="search"
              @input="searchClient"
              append-icon="mdi-magnify"
            ></v-text-field>
          </v-flex>
        </v-card>

        <v-card-text class="ml-2 mt-0">
          <v-form ref="form" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap class="">

                <v-data-table
                  :headers="headers"
                  :loading="loading"
                  :items="items"
                  :options.sync="pagination"
                  :server-items-length="totalItems"
                  class="elevation-1 py-2 mb-6 row-pointer"
                  no-data-text="Nenhum cliente encontrado"
                >
                <template v-slot:[`item.inscription`]="{ item }">
                  <span>{{ getCpf(item.inscription) }}</span>
                </template>

                <template v-slot:[`item.actions`]="{ item }" v-align="right">
                  <v-tooltip>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="blue"
                        dark
                        class="ma-2 elevation-0"
                        @click="link({client: item, agent: agent})"
                        v-bind="attrs"
                        v-on="on"
                        small
                      >
                        <span color="white">Vincular</span>
                      </v-btn>
                    </template>
                  </v-tooltip>

                </template>
              </v-data-table>

              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";


export default {
  name: 'LinkAgentForm',


  created() {
    this.getItems({resetPage: false});
  },

  computed: {

    ...mapState("clients", [
      "items",
      "notification",
      "loading",
      "pagination",
      "totalItems",
    ]),
    ...mapState("agent", [
      "agent",
    ]),

    ...mapState("agent_data", [
      "selectedItem",
      "showDialog",
    ]),
    ...mapFields("clients", [
      "filter.search",
      "pagination",
    ]),
  },



  methods: {

    ...mapActions("clients", [
      "getItems",
    ]),
    ...mapActions("agent_data", [
      "link",
    ]),
    ...mapMutations("agent_data", [
      "setShowDialog",
      "setSelectedItem",
      "setShowDialog"
    ]),

    searchClient()  {
      this.getItems({resetPage: false})
    },

    isPeoplePerson() {
      return this.selectedItem.type == 0 || this.selectedItem.type == undefined
    },

    setTypeVerification()  {
      this.typeVerification = this.selectedItem.type
      console.log(this.typeVerification)
    },

    getCpf(cpf) {
      if (cpf.length == 11 ){
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
      } else {
        return cpf.replace(/(\d{2})?(\d{3})?(\d{3})?(\d{4})?(\d{2})/, "$1.$2.$3/$4-$5");
      }
    },
  },

  data: () => ({
    headers: [
        { text: "Id", value: "id" },
        { text: "Name/Nome Fantasia", value: "name", sortable: true, },
        { text: "CPF/CNPJ", value: "inscription", sortable: true },
        { text: "Ações",  value: "actions", sortable: false, align: "center" },
    ],

    timeProps: {
      format: "24hr",
    },
  }),

}
</script>


<style>
:disabled {
  opacity: 55%;
}
</style>