<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2000"
      v-model="showNotification"
    >{{ notificationMessage }}</v-snackbar>
    <v-toolbar flat class="elevation-5">
        <div>
            <v-toolbar-title>Conciliação Financeira</v-toolbar-title>
        </div>
      <v-spacer></v-spacer>
      <v-flex xs2 sm2 md2>
        <v-select
            label="Ordenar Por"
            :items="orderByTypes"
            v-model="defaultOrderByType"
            item-text="description"
            item-value="id"
            outlined
            v-if="totalItems > 0"
            dense
            class="centered-input text--darken-3 mt-5" 
        >
        </v-select>
      </v-flex>
      <v-spacer></v-spacer>
      <div class="button-toolbar">
        <!-- <download-excel
          :before-generate="generateSheet"
          :before-finish="finishSheet"
          :name="getSheetName()"
          :data="orderedItems"
          :fields="sheet_fields"
          class="excel"
          v-if="totalItems > 0"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="black" dark class="ma-2" small v-bind="attrs" v-on="on">
                <v-icon>mdi-file-excel</v-icon>
              </v-btn>
            </template>
            <span>Gerar Planilha</span>
          </v-tooltip>
        </download-excel> -->

        <v-tooltip bottom>
          <template v-slot:activator="{on, attrs}">
            <v-btn color="black" dark class="ma-2" @click="downloadXlsx({ show: true })" v-bind="attrs" v-on="on" small>
              <v-icon>mdi-file-excel</v-icon>
            </v-btn>
          </template>
          <span>Gerar Planilha</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="black" dark class="ma-2" @click="add" small v-bind="attrs" v-on="on">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Nova Conciliação</span>
        </v-tooltip>
      </div>
    </v-toolbar>

    <v-layout wrap>
      <v-flex xs12 sm12 md12 class="pr-2">
        <v-card class="elevation-10 table-margin">
          <v-data-table
            :headers="headers"
            :loading="loading"
            :items="orderedItems"
            :single-expand="true"
            :expanded.sync="expanded"
            item-key="value"
            show-expand
            :server-items-length="totalItems"
            :item-class= "getClassColor"  
            no-data-text="Nenhuma conferencia encontrada"
            :footer-props="{ disablePagination : true, itemsPerPageOptions: [-1] }"
            calculate-widths
          >
            <template v-slot:[`item.value`]="{ item }">
              <span>{{ getTextValue(item.value) }}</span>
            </template>

            <template v-slot:expanded-item="{ item }">
              <td v-if="item.extract_system" :colspan="4">
                <v-data-table
                  class="children-tab"
                  :headers="headersBank"
                  :loading="loading"
                  :items="item.extract_bank"
                  :item-class= "getClassColorLocal"
                  hide-default-footer
                  :footer-props="{ disablePagination : true, itemsPerPageOptions: [-1] }"
                >
                  <template v-slot:[`item.date_start`]="{ item }">
                    <span>{{ getDate(item.date_start)}}</span>
                  </template>
                  <template v-slot:[`item.finish_at`]="{ item }">
                    <span>{{ getDate(item.finish_at)}}</span>
                  </template>
                  <template v-slot:[`item.value`]="{ item }">
                    <span>{{ getTextValue(item.value) }}</span>
                  </template>
                  <template v-slot:[`item.type_transation`]="{ item }">
                    <span>{{ getType(item.type_transation) }}</span>
                  </template>
                </v-data-table>
              </td>
              <td v-if="!item.extract_system" :colspan="7">
                <v-data-table
                  class="children-tab"
                  :headers="headersBank"
                  :loading="loading"
                  :items="item.extract_bank"
                  :item-class= "getClassColorLocal"
                  hide-default-footer
                  :footer-props="{ disablePagination : true, itemsPerPageOptions: [-1] }"
                >
                  <template v-slot:[`item.date_start`]="{ item }">
                    <span>{{ getDate(item.date_start)}}</span>
                  </template>
                  <template v-slot:[`item.finish_at`]="{ item }">
                    <span>{{ getDate(item.finish_at)}}</span>
                  </template>
                  <template v-slot:[`item.value`]="{ item }">
                    <span>{{ getTextValue(item.value) }}</span>
                  </template>
                </v-data-table>
              </td>
              <td v-if="item.extract_bank" :colspan="4">
                <v-data-table
                  class="children-tab"
                  :headers="headersSystem"
                  :loading="loading"
                  :items="item.extract_system"
                  :item-class= "getClassColorLocal"
                  hide-default-footer
                  :footer-props="{ disablePagination : true, itemsPerPageOptions: [-1] }"
                >
                  <template v-slot:[`item.date_start`]="{ item }">
                    <span>{{ getDate(item.date_start)}}</span>
                  </template>
                  <template v-slot:[`item.finish_at`]="{ item }">
                    <span>{{ getDate(item.finish_at)}}</span>
                  </template>
                  <template v-slot:[`item.value`]="{ item }">
                    <span>{{ getTextValue(item.value) }}</span>
                  </template>
                </v-data-table>
              </td>
              <td v-if="!item.extract_system" :colspan="7">
                <v-data-table
                  class="children-tab"
                  :headers="headersSystem"
                  :loading="loading"
                  :items="item.extract_system"
                  :item-class= "getClassColorLocal"
                  hide-default-footer
                  :footer-props="{ disablePagination : true, itemsPerPageOptions: [-1] }"
                >
                  <template v-slot:[`item.date_start`]="{ item }">
                    <span>{{ getDate(item.date_start)}}</span>
                  </template>
                  <template v-slot:[`item.finish_at`]="{ item }">
                    <span>{{ getDate(item.finish_at)}}</span>
                  </template>
                  <template v-slot:[`item.value`]="{ item }">
                    <span>{{ getTextValue(item.value) }}</span>
                  </template>
                </v-data-table>
              </td>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip bottom v-if="item.correction == 0 ">
                <template v-slot:activator="{on, attrs}">
                  <v-icon color="red" small v-on="on" v-bind="attrs" class="mr-2" @click="correctionCheck(item)">mdi-check</v-icon>
                </template>
                <span>Conferência Realizada</span>
              </v-tooltip> 

              <v-tooltip bottom v-if="item.correction == 1 ">
                <template v-slot:activator="{on, attrs}">
                  <v-icon color="green" small v-on="on" v-bind="attrs" class="mr-2" @click="correctionCheck(item)">mdi-close-thick</v-icon>
                </template>
                <span>Conferência Realizada</span>
              </v-tooltip> 

            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog :value="dialog" @click:outside="closeDialog()" width="800">
      <financial-conciliation-form></financial-conciliation-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import FinancialConciliationForm from './FinancialConciliationForm'
// import JsonExcel from "vue-json-excel";
import _ from 'lodash';
import { utils, writeFile } from "xlsx";

export default {
    name: "FinancialConciliation",
    created() {
      this.getInstances()
    },
    computed: {
      ...mapState('financial_conciliation', ['items', 'bank', 'notificationMessage', 'notificationType', 'totalItems', 'orderByTypes']),      
      ...mapFields('financial_conciliation', ['dialog', 'loading', 'overlay', 'showNotification']),
      orderedItems: {
        get() {
          let orderedItems = _.clone(this.items)
          let order = this.defaultOrderByType === 1 ? ['value'] : ['difference', 'value'] 
          orderedItems = _.sortBy(orderedItems, order);

          return orderedItems
        },
        set: function() {
        }
      }
    },
    components: {
      "financial-conciliation-form": FinancialConciliationForm,
      // "download-excel": JsonExcel,
    },
    data: () => ({
      expanded: [],
      singleExpand: false,
      headers: [
        { text: 'Banco', value: "bank_name", sortable: false, align: "center" },
        { text: 'Período', value: "period", sortable: false, align: "center" },
        { text: 'Valor', value: "value", sortable: false, align: "center" },
        { text: 'Sistema', value: "moviment_count", sortable: false, align: "center" },
        { text: 'Extrato Bancário', value: "ofx_count", sortable: false, align: "center" },
        { text: 'Diferença', value: "difference", sortable: false, align: "center" },
        // { text: 'Tipo',  value: "type_transation", sortable: false, align: "center" },
        { text: 'Ações',  value: "actions", sortable: false, align: "center" },
      ],
      headersBank: [
        { text: 'Local', value: "local", sortable: false, align: "center" },
        { text: 'Valor', value: "value", sortable: false, align: "center" },
        { text: 'Descrição', value: "description", sortable: false, align: "center" },
        { text: 'Data Banco', value: "date", sortable: false, align: "center" },
      ],
      headersSystem: [
        { text: 'Local', value: "local", sortable: false, align: "center" },
        { text: 'Valor', value: "value", sortable: false, align: "center" },
        { text: 'Descrição', value: "description", sortable: false, align: "center" },
        { text: 'Criado em', value: "date_start", sortable: false, align: "center" },
        { text: 'Finalizado em', value: "finish_at", sortable: false, align: "center" },
      ],
      types: ['Saída', 'Entrada'],
      sheet_fields: {
        Type: {
          field: 'type_transation',
          callback: (value) => {
            if(value) {
              let type = [
                'Saída',
                'Entrada'  
              ]
              return type[value]
            }
            return ''
          }
        },
        Banco: {
          field: "bank_name",
        },
        Período: {
          field: "period"
        },
        Valor: {
          field: "value",
          callback: (value) => {
            if (value || value === 0) {
              let real = value.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              });
              return real;
            }
            return "";
          },
        },
        Movimentações: {
          field: "moviment_count"
        },
        OFX: {
          field: "ofx_count"
        },
        Diferença: {
          field: "difference"
        }
      },
      defaultOrderByType: 1
    }),
    methods: {
      ...mapMutations('financial_conciliation', ['add', 'closeDialog']),
      ...mapActions('financial_conciliation', ['updateMoviment', 'getNoUrl']),
      ...mapActions("instances", ["getInstances"]),
      getClassColor(item) {
        let className = '';

        if(item.correction == 0) {
          className = 'conciliation-error'
        } else {
          className = 'conciliation-ok'
        }

        return className
      },
      getType(type) {
        return this.types[type]
      },
      getClassColorLocal(item) {
        let className = '';

        if(item.local == "Banco") {
          className = 'light-blue lighten-5'
        } else if (item.local == "Sistema") {
          className = 'yellow lighten-5'
        }

        return className
      },
      getTextValue(value) {
        return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })        
      },
      getDate(date) {
        if (date) {
          var d = new Date(date);
          return d.toLocaleDateString() + " " + d.toLocaleTimeString();
        }
        return "";
      },
      getBank() {
        return this.bank
      },
      correctionCheck(item) {
        if (item.correction == 0) {
          item.correction = 1
        } else {
          item.correction = 0
        }

        this.getNoUrl()
      },
      finishSheet() {
        this.overlay = false;
      },
      generateSheet() {
        this.overlay = true;
      },
      getSheetName() {
        var date = new Date();
        var name =
          "conferencias_financeiras_" +
          date.toLocaleDateString() +
          " " +
          date.toLocaleTimeString();
        return name + ".xls";
      },
      async downloadXlsx() {
        this.generateSheet()
        try {
          let orderedItems = await this.orderedItems()
          if(orderedItems) {
            const wb = utils.book_new()
            let possible_types = ["Saída", "Entrada"];
              let items = orderedItems.map(row => ({
              "Tipo":  possible_types[row.type_transation],
              "Banco":  row.bank_name,
              "Período":  row.period,
              "Valor":  this.valueXLSX(row.value),
              "Movimentações":  row.moviment_count,
              "OFX":  row.ofx_count,
              "Diferença":  row.difference,
            }))
            const ws = utils.json_to_sheet(items)
            utils.book_append_sheet(wb, ws, "Conciliações")
            await writeFile(wb, this.getSheetName())
          }
        } catch (error) {
          this.finishSheet()
        } finally {
          this.finishSheet()
        }
      },
      valueXLSX: (value) => {
        if (value) {
          let real = value.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          });
          return real.substr(3);
        }
        return 0;
      },
    },
};
</script>

<style scoped>
.children-tab {
 background-color: rgb(199, 199, 199);  
}
.color-system {
 color: rgb(129, 129, 129);  
}
.color-bank {
 color: rgb(117, 60, 60);  
}

  @import './css/financial_conciliation.css';
</style>

