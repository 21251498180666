import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../config/endPoints';

const defaultItem = {
    ag_group_id: '',
    ag_group_name: '',
    id_instance: '',
    email: '',
    id_pppoker: '',
    id_supreme: '',
    id_master_agent: '',
    is_master_agent: 0,
    is_club: false,
    limit_annotated: 0,
    limit_type: 0,
    name: '',
    nick: '',
    rake_deal: '',
    type_deal: 2,
    type_closure: 0,
    phone: '',
    type_chips: 0,
    type_send: 0,
    id_pppoker_old: '',
    responsible: '',
    responsible_contact: '',
    situation: 4,
};

export default {
    namespaced: true,
    state: {
        contacts: [],
        groups_contacts: [],
        dialog: false,
        dialogDelete: false,
        dialogFilter: false,
        editedItem: {},
        filter: {
            id_instance: '',
            id: '',
            name: '',
            nick: '',
            master_agent: '',
            phone: '',
            rake_deal: '',
            id_supreme: '',
            client_id: '',
            search:'',
        },
        instances: [],
        items: [],
        itemsFilteredByClient: [],
        itemToDelete: {},
        loading: false,
        master_agents: [],
        mode: 'LIST',
        page: 1,
        pagination: {
            page: 1,
            itemsPerPage: 10
        },
        paginationByClient: {
            page: 1,
            itemsPerPage: 10
        },
        successNotification: false,
        notificationMessage: '',
        notificationType: "success",
        selectedContact: {},
        selectedContactWithdrawal: {},
        selectedResponsibleContact: {},
        showNotification: false,
        totalItems: 0,
        totalItemsByClient: 0,
        rowsPerPage: 10,
    },
    mutations: {
        add: (state) => {
            state.mode = "ADD"
            state.selectedContact = {}
            state.editedItem = Object.assign({}, defaultItem)
            state.dialog = true
        },
        edit: (state, payload) => {
            state.selectedContact = {}
            state.editedItem = Object.assign({}, payload.item)
            state.editedIndex = state.items.indexOf(payload.item)
            if (payload.item.id_master_agent) {
                state.master_agents = [{ id: payload.item.id_master_agent, nick: payload.item.nick_master_agent }]
            } else {
                state.master_agents = []
            }
            if (payload.item.ag_group_id) {
                var contact = { _id: payload.item.ag_group_id, name: [payload.item.ag_group_name] }
                state.selectedContact = contact
                state.contacts = [contact]
            } else {
                state.contacts = []
                state.selectedContact = {}
            }
            state.mode = "EDIT"
            state.dialog = true
        },
        closeDialog: (state) => {
            state.editedItem = {}
            state.editedIndex = -1
            state.errors = [];
            state.dialog = false
            state.mode = "LIST"
        },
        loading: (state, loading) => state.loading = loading,
        setMode: (state, mode) => state.mode = mode,
        setFilter: (state, payload) => {
            state.filter = Object.assign({}, payload.filter)
        },
        setItems: (state, items) => state.items = items,
        setItemsFilteredByClient: (state, items) => state.itemsFilteredByClient = items,
        setItemToDelete: (state, item) => state.itemToDelete = item,
        setTotalItems: (state, total) => state.totalItems = total,
        setTotalItemsByClient: (state, total) => state.totalItemsByClient = total,
        setRowsPerPage: (state, rowsPerPage) => state.rowsPerPage = rowsPerPage,
        showDialog: (state, show) => state.dialog = show,
        setPage: (state, payload) => {
            state.pagination.page = payload.page;
            state.pagination.itemsPerPage = payload.itemsPerPage
        },
        setPageByClient: (state, payload) => {
            state.paginationByClient.page = payload.page;
            state.paginationByClient.itemsPerPage = payload.itemsPerPage
        },
        setGroups: (state, payload) => {
            state.groups_contacts = payload.groups_contacts
        },
        setContacts: (state, payload) => {
            state.contacts = payload.contacts
        },
        setDialogDelete: (state, show) => state.dialogDelete = show,
        setInstances: (state, payload) => state.instances = payload.instances,
        setMasterAgents: (state, payload) => state.master_agents = payload.master_agents,
        showFilter: (state, payload) => state.dialogFilter = payload.show,
        showErrorNotification: (state, show) => state.errorNotification = show,
        showSuccessNotification: (state, show) => state.successNotification = show,
        showLoading: (state, show) => state.loading = show,
        showNotification: (state, payload) => {
            state.showNotification = payload.show,
                state.notificationMessage = payload.message,
                state.notificationType = payload.type
        },
        updateField
    },
    getters: {
        getField,
    },
    actions: {
        closeDialog: (({ commit }) => {
            commit('closeDialog', false)
        }),
        closeDeleteDialog: ({ commit }) => {
            commit('setDialogDelete', false)
            commit('setItemToDelete', {})
        },
        contactDescription: ({ commit, dispatch }, payload) => {
            if (payload.item.ag_group_id == "") {
                let notification = {
                    show: true,
                    message: "Agente não possui grupo configurado",
                    type: "error"
                }
                commit('showNotification', notification)
            } else {
                dispatch('chat/editContactDescription', payload, { root: true })
            }
        },
        deleteItem: ({ dispatch, commit, state }) => {
            commit('loading', true)
            axios.delete(endPoints.agents + "/" + state.itemToDelete.id, state.itemToDelete).then(() => {
                dispatch('closeDeleteDialog')
                commit('loading', false)
                let notification = {
                    show: true,
                    message: "Agente excluido com sucesso",
                    type: "success"
                }
                commit('showNotification', notification)
                return dispatch('getItems', { resetPage: false });
            });
        },
        getGroupsList({ commit }, payload) {

            let url = endPoints.agents_groups + '/search_groups/' + payload.search
            
            if (payload.search) {
              axios.get(url).then((response) => {
                  let groups = []
                  if (response.data != null) {
                    groups = response.data
                  }
                  var payloadGroups = {
                    groups_contacts: groups,
                  }
                  commit('setGroups', payloadGroups)
                })
            }
          },
        getContacts({ commit }, payload) {
            if (payload.search) {
                axios.get(endPoints.contact_list + "/search_contacts/" + payload.search).then(response => {
                    var payloadContacts = {
                        contacts: response.data
                    }
                    commit('setContacts', payloadContacts)
                });
            }
        },
        getInstances: ({ commit }) => {
            let url = endPoints.instances;
            url += '?page=' + 1 + '&' + 'itemsPerPage=' + 100;
            axios.get(url).then(instances => {
                commit('setInstances', { instances: instances.data.data })
            });
        },
        getItems: ({ commit, state }, payload) => {
            commit('loading', true)
            let url = endPoints.agents;
            let filter = state.filter;
            if (payload.resetPage === true) {
                url += '?page=' + 1 + '&' + 'itemsPerPage=' + 10;
                commit('setPage', { page: 1, itemsPerPage: 10 })
            } else {
                url += '?page=' + state.pagination.page + '&' + 'itemsPerPage=' + state.pagination.itemsPerPage;
            }

            payload.filteredByClient != true
            ? state.filter.client_id = ""
            : ""

            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
                    url += "&" + field + "=" + filter[field]
                }
            });

            if (state.pagination && state.pagination.sortBy && state.pagination.sortBy.length > 0) {
                url += "&sort_by=" + state.pagination.sortBy[0]
                url += "&sort_desc=" + state.pagination.sortDesc[0]
            }

            axios.get(url).then(items => {
                commit('showFilter', { show: false })

                payload.filteredByClient == true
                    ? commit('setItemsFilteredByClient', items.data.data)
                    : commit('setItems', items.data.data)

                commit('setTotalItems', items.data.total)
                commit('loading', false)
            });
        },
        getItemsByClient: ({ commit, state }, payload) => {
            commit('loading', true)
            let url = endPoints.agents;
            let filter = state.filter;
            if (payload.resetPage === true) {
                url += '?page=' + 1 + '&' + 'itemsPerPage=' + 10;
                commit('setPageByClient', { page: 1, itemsPerPage: 10 })
            } else {
                url += '?page=' + state.paginationByClient.page + '&' + 'itemsPerPage=' + state.paginationByClient.itemsPerPage;
            }

            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
                    url += "&" + field + "=" + filter[field]
                }
            });

            if (state.paginationByClient && state.paginationByClient.sortBy && state.paginationByClient.sortBy.length > 0) {
                url += "&sort_by=" + state.paginationByClient.sortBy[0]
                url += "&sort_desc=" + state.paginationByClient.sortDesc[0]
            }

            axios.get(url).then(items => {
                commit('showFilter', { show: false })

                commit('setItemsFilteredByClient', items.data.data)

                commit('setTotalItemsByClient', items.data.total)
                commit('loading', false)
            });
        },
        getItemsNoPage: ({ state }) => {
            let url = endPoints.agents;
            let filter = state.filter;
            url += '?page=' + '1' + '&' + 'itemsPerPage=' + '99999';

            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined) {
                    url += "&" + field + "=" + filter[field]
                }
            });
            return axios.get(url).then(items => {
                return items.data.data
            });
        },
        showDeleteDialog: ({ commit }, item) => {
            commit('setDialogDelete', true)
            commit('setItemToDelete', item)
        },
        save: ({ dispatch, commit, state }) => {
            var url = endPoints.agents;
            let func = '';
            if (state.mode == 'ADD') {
                func = axios.post;
                url = endPoints.agents;
            } else {
                func = axios.put;
                url += '/' + state.editedItem.id;
            }

            // state.editedItem.responsible_contact = state.selectedResponsibleContact[0].toString()
            // state.editedItem.responsible = state.selectedResponsibleContact[1].toString()

            func(url, state.editedItem).then(
                () => {
                    dispatch('getItems', { resetPage: true })
                    let notification = {
                        show: true,
                        message: "Agente salvo com sucesso",
                        type: "success"
                    }
                    commit('showNotification', notification)
                    commit('closeDialog')
                },
                error => {
                    let notification = {
                        show: true,
                        message: error.response.data.message,
                        type: "error"
                    }
                    commit('showNotification', notification)
                }
            );

        },
        viewItem({ dispatch }, payload) {
            dispatch('menu/openLink', '/agents/' + payload.item.id, { root: true })
        }
    }
}
