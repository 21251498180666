import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../../config/endPoints';

export default {
    namespaced: true,
    state: {
        loading: false,
        ol: false,
        status_publish: {
            text: '',
            image: '',
            clubs: [],
            type: 1
        },
        showNotification: false,
        notificationMessage: '',
        notificationType: ''
    },
    mutations: {
        updateField,
        showLoading: (state, payload) => state.loading = payload.show,
        overlay: (state, payload) => state.ol = payload.show,
        showNotification: (state, payload) => {
            state.showNotification = payload.show,
                state.notificationMessage = payload.message,
                state.notificationType = payload.type
        },
    },
    getters: {
        getField,
    },
    actions: {
        publishStatus({ commit, state }) {
            var status_publish = state.status_publish
            var notification = {
                show: true,
                message: "",
                type: "error"
            }
            var payload = {}
            if (status_publish.type == 1 && !status_publish.image.name) {
                notification.message = "Imagem deve ser selecionada."
                commit('showNotification', notification)
                return
            } else if (status_publish.clubs.length == 0) {
                notification.message = "Pelo menos um clube deve ser selecionado."
                commit('showNotification', notification)
                return
            } else if (status_publish.type == 0 && !status_publish.text) {
                notification.message = "Texto deve ser preenchido."
                commit('showNotification', notification)
                return
            }
            commit('overlay', { show: true })
            var url = endPoints.httpchats + "/publish_status"
            if (status_publish.type == 1) {
                payload = {
                    text: '',
                    image: status_publish.image.fileBase64,
                    clubs: status_publish.clubs,
                    type: status_publish.type
                }
            } else {
                payload = {
                    text: status_publish.text,
                    image: '',
                    clubs: status_publish.clubs,
                    type: status_publish.type
                }
            }
            axios.post(url, payload).then(response => {
                let quantity = response.data.total
                notification.type = "success"
                notification.message = "Status publicado com sucesso em " + quantity + " clubes"
                commit('overlay', { show: false })
                commit('showNotification', notification)
            }).catch(() => {
                notification.message = "Ocorreu um erro ao publicar o status"
                commit('overlay', { show: false })
                commit('showNotification', notification)
            });
        }
    }
}