<template>
  <div>
    <v-card>
        <v-card-title>
          <span class="headline text-center">Filtro</span>
          <v-spacer></v-spacer>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    clearable
                    required
                    label="Nome PPPoker *"
                    v-model="name_pppoker"
                    data-vv-name="name_pppoker"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    clearable
                    required
                    label="ID PPPoker *"
                    v-model="id_pppoker"
                    data-vv-name="id_pppoker"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    clearable
                    label="ID Agente *"
                    v-model="id_agent"
                    data-vv-name="id_agent"
                    outlined
                  ></v-text-field>
                </v-flex>
              
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    clearable
                    type="number"
                    label="Saldo Agência *"
                    v-model="balance"
                    data-vv-name="balance"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    clearable
                    label="Nome Supremo Poker *"
                    v-model="name_supreme"
                    :error-messages="errors.collect('name_supreme')"
                    data-vv-name="name_supreme"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    clearable
                    label="ID Supremo Poker *"
                    v-model="id_supreme"
                    data-vv-name="id_supreme"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    clearable
                    type="number"
                    label="Fichas Disponíveis *"
                    v-model="chips"
                    data-vv-name="chips"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-select
                    clearable
                    label="Status *"
                    :items="possible_status"
                    v-model="status"
                    data-vv-name="status"
                    outlined
                    item-text="text"
                    item-value="value"
                  >
                  </v-select>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-select
                    clearable
                    label="Clubes *"
                    :items="instances"
                    v-model="club_origin"
                    data-vv-name="club_origin"
                    outlined
                    item-text="name"
                    item-value="id_instance"
                  >
                  </v-select>
                </v-flex>
              
                <v-flex xs12 sm6 md6>
                  <v-select
                    clearable
                    label="Agente / Jogadot *"
                    :items="player_or_agent"
                    v-model="newagent_password"
                    data-vv-name="newagent_password"
                    outlined
                    item-text="text"
                    item-value="value"
                  >
                  </v-select>
                </v-flex>

              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn text @click="showFilter({show: false})">Cancelar</v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn text type="submit" right @click="filter">Filtrar</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import debounce from "../../../util/debounce.js";

export default {
  name: "ChipsTransfersFilter",
  computed: {
    ...mapState("instances", ["instances"]),
    ...mapFields("chips_transfer", [
      "filter.name_pppoker",
      "filter.name_supreme",
      "filter.id_pppoker",
      "filter.id_supreme",
      "filter.id_agent",
      "filter.chips",
      "filter.status",
      "filter.balance",
      "filter.club_origin",
      "filter.newagent_password"
      // ...
    ]),
  },
  mixins: [debounce],
  methods: {
    ...mapMutations("chips_transfer", ["showFilter"]),
    ...mapActions("chips_transfer", [
      "getChildrenSearch",
      "getChipsTransfers",
      "getAttendants",
      "getUsers",
      "getAgents",
      "closeDialog",
      "save",
    ]),
    filter() {
      this.getChipsTransfers({ resetPage: true });
      this.showFilter({ show: false });
    },
  },
  data: () => ({
    possible_status: [
      {
        text: "Não concluído",
        value: 0,
      },
      {
        text: "Cadastrado",
        value: 1,
      },
      {
        text: "Concluído",
        value: 2,
      },
      {
        text: "Manual",
        value: 3,
      },
      {
        text: "BOT",
        value: 4,
      },
    ],
    player_or_agent: [
      {
        text: "Agente",
        value: 0,
      },
      {
        text: "Jogador",
        value: 1,
      },
    ],
  }),
};
</script>

<style>
</style>