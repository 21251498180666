const deposit_filter = {
    code: '',
    id_instance: '',
    bank: '',
    start: '',
    end: '',
    value: '',
    status: '',
    id_pppoker: '',
    nick: '',
    payment_type: '',
    type: '',
    plataform: ''
};

export default deposit_filter