import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../../config/endPoints';
import { isLocalHost } from '../../../config/endPoints';
import { TYPE_DEAL_SPIN } from '../../../constants/closure'

const getDateHour = () => {
    var d = new Date()
    var l = d.toLocaleDateString()
    var date = l.substr(-4) + "-" + l.substr(3, 2) + "-" + l.substr(0, 2)
    var c = date + " " + d.toLocaleTimeString()
    return new Date(c.substr(0, 13) + ":00:00")
}

const defaultItem = {
    description: "",
    start_date: "",
    finish_date: ""
};

const defaultFilter = {
    id: "",
    period: "",
    type_deal: "",
    id_agent: "",
    nick_agent: "",
    club: "",
    status: ""
}

const calcAnnotatedValues = (item, annotated, advance) => {
    var total_value_to_pay = parseFloat(item.total_value_to_pay.toFixed(2))
    var total_value_remaining = total_value_to_pay - advance 

    var annotated_values = {
        adjust_value: 0,
        test: 0,
        changed_value: 0,
    }
    if (total_value_to_pay > 0) {
        if (annotated.pending_payment > 0) {
            // Considerar o adiantamento
            if (annotated.pending_payment < total_value_remaining) {
                annotated_values.adjust_value -= annotated.pending_payment
                annotated_values.changed_value -= annotated.pending_payment
                total_value_to_pay += annotated_values.adjust_value
            } else {
                annotated_values.adjust_value -= total_value_remaining
                annotated_values.changed_value -= total_value_remaining
                return annotated_values
            }
        }
        if (annotated.current_week > 0 && total_value_remaining > 0) {
            if (annotated.current_week < total_value_remaining) {
                // Considerar o adiantamento 
                annotated_values.adjust_value -= annotated.current_week
                annotated_values.changed_value -= annotated.current_week
            } else {
                annotated_values.adjust_value -= total_value_remaining
                annotated_values.changed_value -= total_value_remaining
            }
        }
    } else {
        annotated_values.changed_value = (total_value_remaining) * -1
    }
    console.log(annotated_values)
    return annotated_values
}

export default {
    namespaced: true,
    state: {
        rakeInAdvance: 0,
        actual_balance: 0,
        adjustsStore: [],
        annotated: {},
        agentClosureToFinish: {
            annotated: {}
        },
        chartRake: [],
        chartRakeback: [],
        dialog: false,
        dialogFilter: false,
        dialogDelete: false,
        dialogRecalculate: false,
        dialogAdjustValue: false,
        dialogFilterPlayerAgents: false,
        adjust_check: false,
        dialogSpreadsheetClosure: false,
        dialogAgentClosureFinish: false,
        dialogEditPlayerAgent: false,
        editedItem: {},
        errors: [],
        firstTime: true,
        items: [],
        itemsTransactions: [],
        item_spreadsheet: {},
        itemToDelete: {},
        loading: false,
        loading_details: false,
        mode: 'LIST',
        notificationType: '',
        notificationMessage: '',
        message_to_send: '',
        page: 1,
        editedPlayerAgent: {},
        filter: {
            start_date: "",
            finish_date: "",
        },
        filterPlayerAgent: {
            id_pppoker: '',
            id_pppoker_agent: '',
            id_slot: ''
        },
        paginationPlayerAgentInPeriod: {
            page: 1,
            itemsPerPage: 10
        },
        filtered: false,
        managers_player_agent: [],
        overlay: false,
        period_closure_id: '',
        period_closure: {
            period: '',
            start_date: '',
            finish_date: ''
        },
        period_closure_dates: {
            start_date: '',
            finish_date: ''
        },
        pagination: {
            page: 1,
            itemsPerPage: 15
        },
        players_agent: [],
        showNotification: false,
        slots: [],
        start_balance: 0,
        totalItems: 0,
        totalItemsTransaction: 0,
        totalItemsPlayerAgent: 0,
        rowsPerPage: 10
    },
    mutations: {
        add: (state) => {
            state.mode = "ADD"
            state.editedItem = Object.assign({}, defaultItem)
            state.editedItem.date_picker = getDateHour()
            state.dialog = true
        },
        edit: (state, payload) => {
            state.editedItem = Object.assign({}, payload.item)
            state.editedIndex = state.items.indexOf(payload.item)
            if (payload.item.date) {
                state.editedItem.date_picker = new Date(payload.item.date)
            } else {
                state.editedItem.date_picker = ''
            }
            state.dialog = true
            state.mode = "EDIT"
        },
        closeDialog: (state) => {
            state.dialog = false
            state.editedItem = Object.assign({}, defaultItem)
            state.editedIndex = -1
            state.errors = [];
        },
        resend: (state) => {
            state.mode = "RESEND"
        },
        loading: (state, loading) => state.loading = loading,
        overlay: (state, payload) => state.overlay = payload.show,
        setErrorMessage: (state, message) => state.errorMessage = message,
        setMode: (state, mode) => state.mode = mode,
        setSelectedSpreadsheet: (state, payload) => state.selected_spreadsheet = payload.spreadsheet,
        setItems: (state, items) => state.items = items,
        setItemSpreadsheet: (state, payload) => state.item_spreadsheet = payload.item,
        setItemsSpreadsheet: (state, payload) => state.spreadsheet_period = payload.items,
        setItemToDelete: (state, item) => state.itemToDelete = item,
        setEditedItem: (state, item) => state.editedItem = item,
        setTotalItems: (state, total) => state.totalItems = total,
        setTotalItemsPlayerAgent: (state, payload) => state.totalItemsPlayerAgent = payload.total,
        setRowsPerPage: (state, rowsPerPage) => state.rowsPerPage = rowsPerPage,
        showFilter: (state, payload) => state.dialogFilter = payload.show,
        showDialog: (state, show) => state.dialog = show,
        setRakeInAdvance: (state, payload) => {
            state.rakeInAdvance = payload.rakeInAdvance
        },
        setDialogDelete: (state, show) => state.dialogDelete = show,
        setDialogRecalculate: (state, show) => state.dialogRecalculate = show,
        setAnnotated: (state, payload) => state.annotated = payload.annotated,
        setDialogSpreadsheetClosure: (state, payload) => state.dialogSpreadsheetClosure = payload.show,
        setPeriodClosure: (state, payload) => state.period_closure = payload.period_closure,
        setPeriodClosureDates: (state, payload) => state.period_closure_dates = payload.period_closure_dates,
        setPeriodClosureId: (state, payload) => {
            state.period_closure_id = payload.period_closure_id
        },
        setFilter: (state, filter) => {
            state.filter = filter
        },
        setFiltered: (state, payload) => {
            state.filtered = payload.filtered
        },
        showErrorNotification: (state, show) => state.errorNotification = show,
        showSuccessNotification: (state, show) => state.successNotification = show,
        showLoading: (state, payload) => state.loading = payload.show,
        showNotification: (state, payload) => {
            state.showNotification = payload.show,
                state.notificationMessage = payload.message,
                state.notificationType = payload.type
        },
        showDialogEditPlayerAgent: (state, payload) => {
            state.dialogEditPlayerAgent = payload.show
            state.editedPlayerAgent = payload.item ? payload.item : {}
        },
        showDialogFinishAgentClosure: (state, payload) => {
            state.agentClosureToFinish.adjust_value = 0
            state.agentClosureToFinish = payload.item
            state.agentClosureToFinish.id_instance = payload.item.club
            state.dialogAgentClosureFinish = payload.show
            if (payload.show == false) {
                state.adjust_check = false
                state.dialogAdjustValue = false
                state.firstTime = true
            }
        },
        setMessageToSend: (state, payload) => {
            state.message_to_send = payload.message
        },
        showDialogAdjustValue: (state, payload) => {
            state.dialogAdjustValue = payload.show
        },
        adjustIsTrueStore: (state, payload) => {
            state.adjust_check = true
            state.dialogAdjustValue = false
            state.adjustsStore = payload
        },
        adjustIsFalseStore: (state) => {
            state.adjust_check = false
            state.dialogAdjustValue = false
            state.adjustsStore = []
        },
        setAdjusts: (state, payload) => {
            state.adjustsStore = payload.adjustsStore
        },
        setFirstTime: (state) => {
            state.firstTime = false
        },
        setCheckAdjusts: (state) => {
            state.adjust_check = true
        },
        setSlots: (state, payload) => {
            state.slots = payload.slots
        },
        setPlayersAgent: (state, payload) => {
            state.players_agent = payload.items
        },
        showFilterPlayerAgents: (state, payload) => {
            state.dialogFilterPlayerAgents = payload.show
        },
        setManagersPlayerAgentPeriod: (state, payload) => {
            state.managers_player_agent = payload.items
        },
        updateField,
    },
    getters: {
        getField,
    },
    actions: {
        closeReorderDialog: ({ commit }) => {
            commit('setDialogReorder', false)
            commit('setEditedItem', {})
        },
        calcSpreadsheetClosure: ({ commit, dispatch }, payload) => {
            let url = endPoints.pppoker_financial + "/weekly_closure_agents"
            let closure = {
                period: payload.item.period,
                id_spreadsheet: payload.item.id
            }
            commit('overlay', { show: true })
            axios.post(url, closure).then(() => {
                dispatch('getSpreadsheetClosure')
                dispatch('getItems')
                commit('overlay', { show: false })
                let notification = {
                    show: true,
                    message: "Planilha computada com sucesso.",
                    type: "success"
                }
                commit('showNotification', notification)
            }).catch(() => {
                let notification = {
                    show: true,
                    message: "Ocorreu um erro ao computar a planilha. Tente novamente mais tarde.",
                    type: "success"
                }
                commit('showNotification', notification)
            });
        },

        getManagersPlayerAgentPeriod: ({commit}, payload) => {
            const url = `${endPoints.agents_closure}/managers_player_agent?id_player_agent_period=${payload.id}`
            axios.get(url).then((response) => {
                commit('setManagersPlayerAgentPeriod', {items: response.data.data})
            }).catch(() => {
                let notification = {
                    show: true,
                    message: "Não foi possivel buscar os gestores relacionados ao jogador!",
                    type: "error"
                }
                commit('showNotification', notification)
            })

            console.log("teste", payload)
        },
        getSlotsInPeriod: ({ commit, state }, payload) => {
            let period = '';

            if(state.period_closure_id) {
                period = state.period_closure_id
            } else if(payload && payload.period_id) {
                period = payload.period_id
            }

            if(payload && payload.use_payload_period) {
                period = payload.period_id
            }

            const url = endPoints.period_closure + "/slots/" + period
            axios.get(url).then((response) => {
                commit('setSlots', {slots: response.data})
            }).catch(() => {
                let notification = {
                    show: true,
                    message: "Não foi possivel buscar os slots do periodo!",
                    type: "error"
                }
                commit('showNotification', notification)
            });
        },
        getPlayersAgentInPeriod: ({ commit, state }, payload) => {
            let period = '';
            let filter = state.filterPlayerAgent
            if(state.period_closure_id) {
                period = state.period_closure_id
            } else if(payload && payload.period_id) {
                period = payload.period_id
            }
            let url =  endPoints.period_closure + "/players_agent/" + period 
            url += "?page=" + state.paginationPlayerAgentInPeriod.page + "&itemsPerPage=" + state.paginationPlayerAgentInPeriod.itemsPerPage

            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
                    url += "&" + field + "=" + filter[field]
                }
            });

            axios.get(url).then((response) => {
                commit('setPlayersAgent', { items: response.data.data })
                commit('setTotalItemsPlayerAgent', { total: response.data.total })
            }).catch(() => {
                let notification = {
                    show: true,
                    message: "Não foi possivel buscar os jogadores do periodo!",
                    type: "error"
                }
                commit('showNotification', notification)
            });
        },
        openReport: (_, payload) => {
            let ep = "/birt/frameset?__report=reports/fechamento.rptdesign&__format=pdf&agent_closure_id=" + payload.item.id
            let base_url = "https://sistema.sxgrupo.com.br"
            
            if(isLocalHost(window.location.hostname)) {
                base_url = "http://127.0.0.1:9999"
                ep = ep.replace("fechamento", "fechamento_local")
            }
            window.open(base_url + ep, '_blank');
        },
        closeDeleteDialog: ({ commit }) => {
            commit('setDialogDelete', false)
            commit('setItemToDelete', {})
        },
        closeRecalculateDialog: ({ commit }) => {
            commit('setDialogRecalculate', false)
            commit('setEditedItem', {})
        },
        clearFilter: ({ commit, dispatch }) => {
            commit('setFilter', Object.assign({}, defaultFilter))
            commit('setFiltered', { filtered: false })
            commit('showFilter', { show: false })
            dispatch('getItems', { resetPage: true })
        },
        deleteItem: ({ dispatch, commit, state }) => {
            commit('loading', true)
            axios.delete(endPoints.period_closure + "/" + state.itemToDelete.id, state.itemToDelete).then(() => {
                dispatch('closeDeleteDialog')
                return dispatch('getItems');
            });
        },
        recalculateClosure: ({ commit, dispatch, state }, payload) => {
            commit('loading', true)
            const url = endPoints.agents_closure + "/recalculate_closure?resolve=" + payload.resolve
            
            axios.post(url, state.editedItem.item).then(() => {
                const notification = {
                    show: true,
                    message: "Fechamento do agente recalculado!",
                    type: "success"
                }
                commit('showNotification', notification)
                commit('loading', false)
                dispatch('closeRecalculateDialog')
                return dispatch('getItems');
            }).catch(() => {
                const notification = {
                    show: true,
                    message: "Não foi possível recalcular o fechamento do agente!",
                    type: "error"
                }
                commit('showNotification', notification)
                commit('loading', false)
                dispatch('closeRecalculateDialog')
            })
        },
        getRakesPass: ({ commit, state }, payload) => {
            let url = endPoints.rakeback + '/rakes_week_by_agent/';
            
            let filter = Object.assign({}, state.filter);
            filter.status = 2;
            filter.number_agent = payload.contact_id;

            url += '?page=' + 1 + '&' + 'rakesPerPage=' + 100;

            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined) {
                    url += "&" + field + "=" + filter[field]
                }
            });

            axios.get(url).then(sum => {
                commit('setRakeInAdvance', {  rakeInAdvance: sum.data.value.toFixed(2) })
            })
        },
        getAdjustsStore: ({ state, commit }, payload) => {
            if (state.firstTime) {
                let url = endPoints.agents_closure + '/adjusts_by_id_closure/' + payload.id_closure;
                
                commit('setFirstTime')
                return axios.get(url)
            } else {
                return {
                    data: []
                }
            }
        },
        dialogFinishAgentClosure:  ({ commit, state, dispatch }, payload) => {
            if (!payload.item.contact_id) {
                let notification = {
                    show: true,
                    message: "Agente não possui contato configurado.",
                    type: "success"
                }
                commit('showNotification', notification)
                return
            }
            if(payload.item.status == 4 || payload.item.status == 6) {
                commit('showLoading', { show: true })
                const updateUrl = endPoints.agents_closure + "/update_closure/" + payload.item.id
                const body = {
                    status: 2
                }
                axios.post(updateUrl, body).then(() => {
                    let notification = {
                        show: true,
                        message: "Fechamento realizado para o agente",
                        type: "success"
                    }
                    commit('showNotification', notification)
                    commit('showLoading', { show: false })
                    dispatch('getItems')
                }).catch((error) => {
                    let notification = {
                        show: true,
                        message: error.response.data.message,
                        type: "error"
                    }
                    commit('showNotification', notification)
                    commit('showLoading', { show: false })
                    dispatch('getItems')
                })
                return
            }
            let url = endPoints.rakeback + '/rakes_week_by_agent/';
            let filter = Object.assign({}, state.filter);
            filter.status = 2;
            filter.number_agent = payload.contact_id;
            url += '?page=' + 1 + '&' + 'rakesPerPage=' + 100;

            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined) {
                    url += "&" + field + "=" + filter[field]
                }
            });

            axios.get(url).then(sum => {
                commit('setRakeInAdvance', {  rakeInAdvance: sum.data.value.toFixed(2) })
            }).then(async () => {
                var url = endPoints.annotated + "/contact/" + payload.item.contact_id
                payload.item.adjust_from_annotated = 0
                state.start_balance = 0
                state.actual_balance = 0
                try {
                    let response = await axios.get(url)
                    let annotated = response.data
                    let annotated_values = calcAnnotatedValues(payload.item, annotated, state.rakeInAdvance)
                    payload.item.start_balance = 0
                    annotated.add_to = 0
                    annotated.discount_from = 1
                    if (payload.item.type_deal == 1) {
                        payload.item.adjust_value = 0
                        annotated.changed_value = 0
                        annotated.original_pending_payment = annotated.pending_payment + annotated.current_week
                        state.start_balance = annotated.pending_payment + annotated.current_week
                    } else {
                        annotated.original_pending_payment = 0
                        annotated.changed_value = annotated_values.changed_value.toFixed(2)
                        // payload.item.adjust_value = annotated_values.adjust_value + (state.rakeInAdvance * -1)
                        payload.item.adjust_from_annotated = annotated_values.adjust_value
                    }
                    commit('setMessageToSend', {message: ''})
                    commit('setAnnotated', { annotated: annotated })
                    commit('showDialogFinishAgentClosure', payload)
                } catch(error) {
                    if (error.response) {
                        if (error.response.status == 404) {
                            var annotated = { add_to: 0, contact_id: payload.item.contact_id, changed_value: 0, current_week: 0, discount_from: 1, observation: '', original_pending_payment: 0, pending_payment: 0, new_annotated: true }
                            if (payload.item.type_deal == 1) {
                                payload.item.adjust_value = 0
                                payload.item.start_balance = 0
                            } else {
                                payload.item.adjust_value = 0
                                payload.item.adjust_from_annotated = 0
                            }
                            commit('setAnnotated', { annotated: annotated })
                            commit('setMessageToSend', {message: ''})
                            commit('showDialogFinishAgentClosure', payload)
                        } else {
                            let notification = {
                                show: true,
                                message: "Não foi possivel buscar os anotados!",
                                type: "error"
                            }
                            commit('showNotification', notification)
                        }
                    } else {
                        let notification = {
                            show: true,
                            message: "Não foi possivel buscar os anotados!",
                            type: "error"
                        }
                        commit('showNotification', notification)
                    }
                }
        
            })
        },
        getMessageClosure: ({ commit, state }, payload) => {
            let url = endPoints.agents_closure + "/message_closure"
            state.agentClosureToFinish.balance_transactions = parseFloat(state.agentClosureToFinish.balance_transactions)
            console.log(state.agentClosureToFinish.balance_transactions)
            payload.adjust_value = parseFloat(payload.adjust_value)
            payload.agent_closure = state.agentClosureToFinish
            payload.agent_closure.adjust_value = parseFloat(payload.agent_closure.adjust_value)
            if(payload.agent_closure.type_deal == TYPE_DEAL_SPIN) {
                if(state.start_balance == 0 || state.actual_balance == 0) {
                    let notification = {
                        show: true,
                        message: "Giro deve ser informado",
                        type: "error"
                    }
                    commit('showNotification', notification)
                    return
                }
            }
            payload.id_instance = state.agentClosureToFinish.id_instance
            payload.annotated = state.annotated
            payload.annotated.current_week = parseFloat(payload.annotated.current_week)
            payload.annotated.pending_payment = parseFloat(payload.annotated.pending_payment)
            payload.annotated.changed_value = parseFloat(payload.annotated.changed_value)
            payload.adjusts = state.adjustsStore
            payload.adjusts.forEach((adj) => {
                adj.value = parseFloat(adj.value)
            })
            axios.post(url, payload).then((response) => { 
                commit('setMessageToSend', {message: response?.data?.message})
            }).catch(() => {
                let notification = {
                    show: true,
                    message: "Ocorreu um erro ao buscar a mensagem",
                    type: "error"
                }
                commit('showNotification', notification)
            })

        },
        resendMessage: ({ commit, dispatch, state }, payload) => {
            let url = endPoints.agents_closure + "/resend_message"

            payload.contact_id = state.annotated.contact_id;

            url += '?contact_id=' + payload.contact_id; 

            commit('showLoading', { show: true })
            axios.post(url, payload).then(() => {
                let notification = {
                    show: true,
                    message: "Mensagem reenviada com sucesso",
                    type: "success"
                }
                commit('showLoading', { show: false })
                commit("showDialogFinishAgentClosure", { item: { annotated: {} }, show: false })
                dispatch('getItems')
                commit('showNotification', notification)
            }).catch(() => {
                let notification = {
                    show: true,
                    message: "Não foi possivel reenviar a mensagem.",
                    type: "error"
                }
                commit('showLoading', { show: false })
                commit('showNotification', notification)
            })
        },
        finishAgentClosure: ({ commit, dispatch, state }, payload) => {
   
            let url = endPoints.agents_closure + "/finish_closure"
            payload.adjust_value = parseFloat(payload.adjust_value)
            state.agentClosureToFinish.balance_transactions = parseFloat(state.agentClosureToFinish.balance_transactions)
            payload.agent_closure = state.agentClosureToFinish
            payload.agent_closure.adjust_value = parseFloat(payload.agent_closure.adjust_value)
            if(payload.agent_closure.type_deal == TYPE_DEAL_SPIN) {
                if(state.start_balance == 0 || state.actual_balance == 0) {
                    let notification = {
                        show: true,
                        message: "Giro deve ser informado",
                        type: "error"
                    }
                    commit('showNotification', notification)
                    return
                }
            }
            payload.id_instance = state.agentClosureToFinish.id_instance
            payload.annotated = state.annotated
            payload.annotated.current_week = parseFloat(payload.annotated.current_week)
            payload.annotated.pending_payment = parseFloat(payload.annotated.pending_payment)
            payload.annotated.changed_value = parseFloat(payload.annotated.changed_value)
            payload.adjusts = state.adjustsStore
            payload.adjusts.forEach((adj) => {
                adj.value = parseFloat(adj.value)
            })

            commit('showLoading', { show: true })
            axios.post(url, payload).then(() => {
                let notification = {
                    show: true,
                    message: "Acerto finalizado com sucesso",
                    type: "success"
                }
                commit('showLoading', { show: false })
                commit("showDialogFinishAgentClosure", { item: { annotated: {} }, show: false })
                dispatch('getItems')
                commit('showNotification', notification)
                if (state.rakeInAdvance != 0) {
                    url = endPoints.rakeback + "/updateRakesPass/" + state.annotated.contact_id
                    axios.put(url).then(() => {
                        if (state.rakeInAdvance > payload.agent_closure.rakeback) {
                            url = endPoints.rakeback + "/with_ag"
                            let newRake = {}
                            newRake.value = (state.rakeInAdvance - payload.agent_closure.rakeback)
                            newRake.ag_group_id = state.annotated.contact_id
                            newRake.date_picker = new Date
                            newRake.status = 1
                            axios.post(url, newRake)
                        }
                    })
                }
            }).catch((error) => {
                let notification = {
                    show: true,
                    message: error?.response?.data?.message || "Não foi possivel finalizar o acerto.",
                    type: "error"
                }
                commit('showLoading', { show: false })
                commit('showNotification', notification)
            })
        },
        getItems: ({ commit, state }, payload) => {
            let period = '';

            if(state.period_closure_id) {
                period = state.period_closure_id
            } else if(payload && payload.period_id) {
                period = payload.period_id
            }
            if (period) {
                commit('loading', true)
                var filter = state.filter;
                let url = endPoints.agents_closure;
                url += '?page=' + state.pagination.page + '&' + 'itemsPerPage=' + state.pagination.itemsPerPage;
                filter.period = period
                let filtered = false
                Object.keys(filter).forEach((field) => {
                    if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
                        filtered = true

                        if (field == "start") {
                            var e = new Date(filter[field])

                            url += "&" + field + "=" + e.toISOString().substr(0, 10) + " " + e.toLocaleTimeString()
                        } else {
                            url += "&" + field + "=" + filter[field]
                        }
                    }
                });
                if (state.pagination && state.pagination.sortBy && state.pagination.sortBy.length > 0) {
                    url += "&sort_by=" + state.pagination.sortBy[0]
                    url += "&sort_desc=" + state.pagination.sortDesc[0]
                }
                commit('setFiltered', { filtered: filtered })
                axios.get(url).then(items => {

                    commit('setTotalItems', items.data.total)
                    commit('loading', false)
                    commit('setItems', items.data.data)
                });
            }
        },
        getDatesItems: ({commit, state}, payload) => {
            const dates = payload.period_closure_dates

            if (dates) {
                commit('loading', true)
                var filter = state.filter;
                let url = endPoints.agents_closure_dates;
                url += '?page=' + state.pagination.page + '&' + 'itemsPerPage=' + state.pagination.itemsPerPage;
                filter.start_date = dates.start_date
                filter.finish_date = dates.finish_date

                let filtered = false
                Object.keys(filter).forEach((field) => {
                    if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
                        filtered = true

                        if (field == "start") {
                            var e = new Date(filter[field])

                            url += "&" + field + "=" + e.toISOString().substr(0, 10) + " " + e.toLocaleTimeString()
                        } else {
                            url += "&" + field + "=" + filter[field]
                        }
                    }
                });
                // if (state.pagination && state.pagination.sortBy && state.pagination.sortBy.length > 0) {
                //     url += "&sort_by=" + state.pagination.sortBy[0]
                //     url += "&sort_desc=" + state.pagination.sortDesc[0]
                // }
                commit('setFiltered', { filtered: filtered })
                axios.get(url).then(items => {
                    commit('setTotalItems', items.data.total)
                    commit('loading', false)
                    commit('setItems', items.data.data)
                });
            }
        },
        getItemsNoPage: ({ state }) => {
            let url = endPoints.agents_closure;
            let filter = state.filter;
            url += '?page=' + '1' + '&' + 'itemsPerPage=' + '99999';

            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
                    if (field == "end" || field == "start") {
                        var e = new Date(filter[field])

                        url += "&" + field + "=" + e.toISOString().substr(0, 10) + " " + e.toLocaleTimeString()
                    } else {
                        url += "&" + field + "=" + filter[field]
                    }
                }
            });
            return axios.get(url).then(items => {
                return items.data.data
            });
        },
        importSpreadsheet: ({ commit, dispatch, state }) => {
            let url = endPoints.pppoker_financial + "/weekly_spreadsheet"
            let formData = new FormData();
            let headers = []
            formData.append('file', state.selected_spreadsheet);
            formData.append('period', state.item_spreadsheet.id);
            formData.append('headers', JSON.stringify(headers))
            formData.append('start_line', state.start_line)
            formData.append('type_import', state.type_import)
            commit('overlay', { show: true })
            axios.post(url, formData).then(() => {
                commit('overlay', { show: false })
                let notification = {
                    show: true,
                    message: "Planilha importada com sucesso.",
                    type: "success"
                }
                commit('setSelectedSpreadsheet', { spreadsheet: {} })
                commit('showNotification', notification)
                dispatch('getSpreadsheetClosure')
                dispatch('getItems')
            })
        },
        checkSuspectTransaction: ({ commit, dispatch }, payload) => {
            let data = payload.item
            let url = `${endPoints.periods_closure_transactions}/${data.id}`;
            data.suspect = 2
            axios.put(url, data).then(() => {
                let notification = {
                    show: true,
                    message: "Transação atualizada com sucesso.",
                    type: "success"
                }
                commit('showNotification', notification)
                dispatch('getPeriodClosureTransactions', { resetPage: true })
            }).catch(() => {
                let notification = {
                    show: true,
                    message: "Ocorreu um erro ao atualizar a transação.",
                    type: "error"
                }
                commit('showNotification', notification)
            })
        },
        getSpreadsheetClosure: ({ commit, state }) => {
            let url = endPoints.pppoker_financial + "/spreadsheet_closure/" + state.item_spreadsheet.id + "?type_spreadsheet=1";
            axios.get(url).then(items => {
                commit('setItemsSpreadsheet', { items: items.data.data })
            });
        },
        showDialogSpreadsheetClosure: ({ commit }, payload) => {
            let url = endPoints.pppoker_financial + "/spreadsheet_closure/" + payload.item.id + "?type_spreadsheet=1";
            axios.get(url).then(items => {
                commit('setItemSpreadsheet', { item: payload.item })
                commit('setItemsSpreadsheet', { items: items.data.data })
                commit('setDialogSpreadsheetClosure', { show: true })
            });
        },
        showDeleteDialog: ({ commit }, item) => {
            commit('setDialogDelete', true)
            commit('setItemToDelete', item)
        },
        showRecalculateDialog: ({ commit }, item) => {
            commit('setDialogRecalculate', true)
            commit('setEditedItem', item)
        },
        reorder: ({ commit, dispatch }, payload) => {
            const url = endPoints.agents_closure + "/reorder";
            axios.post(url, payload.item).then(() => {
                let notification = {
                    show: true,
                    message: "Ordem reenviada com sucesso",
                    type: "success"
                }
                dispatch('getItems', { resetPage: true })
                dispatch('closeReorderDialog')
                commit('showNotification', notification)
            }).catch((error) => {
                let notification = {
                    show: true,
                    message: error.response.data.message,
                    type: "error"
                }
                commit('showNotification', notification)
            })
        },
        save: ({ dispatch, commit, state }) => {
            var url = endPoints.period_closure;
            let func = '';
            if (state.mode == 'ADD') {
                func = axios.post;
                url = endPoints.period_closure;
            } else {
                func = axios.put;
                url += '/' + state.editedItem.id;
            }

            func(url, state.editedItem).then(
                () => {
                    dispatch('getItems')
                    let notification = {
                        show: true,
                        message: "Periodo de Fechamento salvo com sucesso.",
                        type: "success"
                    }
                    commit('closeDialog')
                    commit('showNotification', notification)
                },
                error => {
                    let notification = {
                        show: true,
                        message: error.response.data.message,
                        type: "error"
                    }
                    commit('showNotification', notification)
                }
            );
        },
        savePlayerAgent: ( { commit, dispatch, state } ) => {
            let url =  endPoints.period_closure + "/players_agent/" + state.editedPlayerAgent.id;

            axios.put(url, state.editedPlayerAgent).then(() => {
                
                let notification = {
                    show: true,
                    message: "Relacionamento salvo com sucesso!",
                    type: "success"
                }
                
                dispatch('getPlayersAgentInPeriod', {period_id: state.editedPlayerAgent.period_id})
                commit('showNotification', notification)
                commit('showDialogEditPlayerAgent', {show: false})
            }).catch(() => {
                let notification = {
                    show: true,
                    message: "Ocorreu um erro ao salvar o relacionamento",
                    type: "error"
                }
                commit('showNotification', notification)
            });
        },
        viewItem: ({ dispatch }, payload) => {
            dispatch('menu/openLink', '/agents_closure/' + payload.item.period + "/" + payload.item.id, { root: true })
        }
    }
}