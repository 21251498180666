<template>
  <div>
    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2000"
      v-model="showNotification"
    >
      {{ notificationMessage }}
    </v-snackbar>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-card class="elevation-10">
      <v-card-title class="text-center justify-center">
        Grupos Vinculados
      </v-card-title>
      <v-btn
        color="black"
        @click="setDialog({ dialog: 'formDialog', show: true, mode: 'BOND', item: '' })"
        dark
        class="add-align-right"
        small
      >
        <v-icon>mdi-account-group</v-icon>
      </v-btn>

      <!-- <v-btn
        color="black"
        @click="setDialog({ dialog: 'formDialog', show: true, mode: 'ADD', item: '' })"
        dark
        class="add-align-right"
        small
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn> -->
      <v-btn color="black" @click="getItems" dark class="add-align-right" small>
        <v-icon>mdi-reload</v-icon>
      </v-btn>
      <v-data-table
        :headers="headers"
        :loading="loading"
        :items="items"
        :server-items-length="totalItems"
        disable-pagination
        hide-default-footer
        no-data-text="Nenhum grupo vinculado"
        class="elevation-10 table-margin"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <!-- <v-icon
            small
            class="mr-2"
            @click="
              setDialog(
                { dialog: 'formDialog', show: true, mode: 'EDIT' },
                item
              )
            "
            >mdi-pencil</v-icon
          > -->
          <v-icon
            small
            class="mr-2"
            @click="setDialog({ dialog: 'deleteDialog', show: true, item: item })"
            >mdi-delete</v-icon
          >
        </template>
      </v-data-table>

      <v-dialog :value="deleteDialog" persistent width="350">
        <v-card>
          <v-card-title class="headline">Confirmar exclusão?</v-card-title>

          <v-card-actions>
            <v-col>
              <v-btn
                text
                @click="setDialog({ dialog: 'deleteDialog', show: false, item: '' })"
                >Cancelar</v-btn
              >
            </v-col>
            <v-col class="text-right">
              <v-btn text type="submit" right @click="deleteItem"
                >Confirmar</v-btn
              >
            </v-col>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- form -->
      <v-dialog
        :value="formDialog"
        @click:outside="setDialog({ dialog: 'formDialog', show: false, item: '' })"
        persistent
        width="600"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Novo Vínculo</span>
          </v-card-title>

          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12 sm12 md12 v-if="this.mode == 'ADD'">
                    <v-text-field
                      required
                      label="Novo Grupo a ser vinculado"
                      hint="Adicionar um novo grupo para vincular o agente atual"
                      persistent-hint
                      v-model="new_group_id"
                      :rules="[rules.required]"
                      :clearable="this.mode == 'ADD' || this.mode == 'EDIT'"
                      :error-messages="errors.collect('new_group_id')"
                      data-vv-name="new_group_id"
                      outlined
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm12 md12 v-if="this.mode == 'BOND'">
                    <v-autocomplete
                      required
                      label="Grupo existente a ser vinculado"
                      hint="Vincular o agente atual a um grupo existente"
                      persistent-hint
                      clearable
                      deletable-chips
                      outlined
                      chips
                      small-chips
                      :no-filter="true"
                      :items="groups_contacts"
                      :return-object="true"
                      :search-input.sync="searchInput"
                      :item-text="getTextGroup"
                      :rules="[rules.required]"
                      v-model="ag_group_id"
                      v-validate="'required:Grupo,ag_group_id'"
                      data-vv-name="ag_group_id"
                      no-data-text="Nenhum contato encontrado"
                      placeholder="Digite aqui o nome do grupo ou contato"
                    ></v-autocomplete>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-col>
              <v-btn
                text
                outlined
                @click="setDialog({ dialog: 'formDialog', show: false, item: '' })"
                >{{ mode == "VIEW" ? "Voltar" : "Cancelar" }}</v-btn
              >
            </v-col>
            <v-col class="text-right">
              <v-btn text outlined type="submit" right @click="linkAgentToGroup"
                >Salvar</v-btn
              >
            </v-col>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import debounce from "../../../../util/debounce.js";

export default {
  name: "AgentGroups",
  computed: {
    ...mapState("agent_group", [
      "notificationMessage",
      "notificationType",
      "mode",
      "loading",
      "groups_contacts",
      "items",
      "totalItems"
    ]),
    ...mapFields("agent_group", [
      "showNotification",
      "formDialog",
      "deleteDialog"
    ]),
    ...mapFields("agent_group", [
      "selectedItem.id",
      "selectedItem.ag_group_name",
      "selectedItem.ag_group_id",
      "selectedItem.id_agent",
      "selectedItem.new_group_id"
    ])
  },
  created() {
    this.getItems();
    this.$validator.extend("required", {
      getMessage: (field, [label]) => label + " deve ser informado",
      validate: val => {
        if (!val) {
          return false;
        }
        return true;
      }
    });
  },
  methods: {
    ...mapMutations("agent_group", ["add", "setDialog"]),
    ...mapActions("agent_group", [
      "deleteItem",
      "getItems",
      "save",
      "getGroupsList"
    ]),
    getTextGroup(item) {
      if (item) {
        if (item.ag_group_id) {
          // return item.id + " | " + item.ag_group_id;
          return item.ag_group_id + " | " + item.ag_group_name + " | " + item.nick;
        }
        // } else {
        //   return item.ag_group_id;
        // }
      }
      return "";
    },
    linkAgentToGroup() {
      let valid = this.$refs.form.validate();
      if (valid) {
        this.save();
      }
    }
  },
  data: () => ({
    headers: [
      { text: "ID do Grupo",   value: "ag_group_id", sortable: false },
      { text: "Nome do Grupo", value: "ag_group_name", sortable: false },
      { text: "Nick do Grupo", value: "nick", sortable: false },
      { text: "ID do Agente",  value: "id_agent", sortable: false },
      { text: "Ações", value: "actions", sortable: false, align: "right" }
    ],
    rules: {
      required(value) {
        return !!value || "Campo Obrigatório";
      }
    },
    searchInput: "",
    valid: false
  }),
  mixins: [debounce],
  mounted() {
    this.getGroupsList = this.debounce(this.getGroupsList, 500);
  },
  watch: {
    searchInput(val) {
      var payload = {
        search: val,
        groups_contacts: this.ag_group_id
      };
      this.getGroupsList(payload);
    }
  }
};
</script>

