<template>
  <div>
    <v-tabs v-model="main_tab" class="elevation-10 mb-2">
      <v-tab>Auditoria</v-tab>
      <v-tab>Depósitos/Saques</v-tab>
      <v-tab>Despesas</v-tab>
      <v-tab>DRE</v-tab>
      <v-tab>Poker Geral</v-tab>
    </v-tabs>
    <v-tabs-items v-model="main_tab" class="elevation-10">
      <v-tab-item>
        <iframe class="dash-bi-audit" src="https://app.powerbi.com/view?r=eyJrIjoiOTQ2MzZjNGItMjRlZC00MDgwLWE0MmItZjk0ZjcyMzAxNTNkIiwidCI6Ijg3NTIzMTA4LTlkZWUtNDE0ZS1hYjFmLTYwMDE2M2MwN2VhZiJ9&pageName=ReportSection" frameborder="0" allowFullScreen="true"></iframe>
      </v-tab-item>
      <v-tab-item>
        <iframe class="dash-bi-audit" src="https://app.powerbi.com/view?r=eyJrIjoiNTM0YjA1ZWQtMTM1Yy00NGY5LTlmNDctNTMzNTZlYjY5MmQ2IiwidCI6Ijg3NTIzMTA4LTlkZWUtNDE0ZS1hYjFmLTYwMDE2M2MwN2VhZiJ9" frameborder="0" allowFullScreen="true"></iframe>
      </v-tab-item>
      <v-tab-item>
        <iframe class="dash-bi-audit" src="https://app.powerbi.com/view?r=eyJrIjoiYzE4MDJlMDEtMjBlYS00OGIxLThmMmQtZDNmZTcxNDJiYzJmIiwidCI6Ijg3NTIzMTA4LTlkZWUtNDE0ZS1hYjFmLTYwMDE2M2MwN2VhZiJ9&pageName=ReportSection" frameborder="0" allowFullScreen="true"></iframe>
      </v-tab-item>
      <v-tab-item>
        <iframe class="dash-bi-audit" src="https://app.powerbi.com/view?r=eyJrIjoiZDE3YWRiNmUtNzNhZi00ODZkLTkyZjYtMDdlNTM0ZGMxY2U0IiwidCI6Ijg3NTIzMTA4LTlkZWUtNDE0ZS1hYjFmLTYwMDE2M2MwN2VhZiJ9&pageName=ReportSection23b4e093cd324d67548b" frameborder="0" allowFullScreen="true"></iframe>
      </v-tab-item>
      <v-tab-item>
        <iframe class="dash-bi-audit" src="https://app.powerbi.com/view?r=eyJrIjoiZWI3ZWQ2MDAtMTQ4ZS00ZDU0LTgzZjMtNTMxZTM0OTRhNDRjIiwidCI6Ijg3NTIzMTA4LTlkZWUtNDE0ZS1hYjFmLTYwMDE2M2MwN2VhZiJ9&pageName=ReportSection" frameborder="0" allowFullScreen="true"></iframe>
      </v-tab-item>
    </v-tabs-items>
   
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed : {
    ...mapState("login", ["userData"]),
  },
  data () {
    return {
      main_tab: '',
      tab: ''
    }
  }
}
</script>

<style scoped>
  .dashboard-tab {
    background-color: #f2f2f2;
  }
  .dash-bi-audit {
    height: 90vh;
    width: 100%;
  }
</style>