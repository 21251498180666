<template>
  <div>
    <v-overlay :value="loading_equipments">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar
      :color="notification.type"
      right
      top
      :timeout="2000"
      v-model="notification.show"
    >
      {{ notification.message }}
    </v-snackbar>

    <v-list-item
      class="
        card-collaborator
        text-center
        justify-center
        elevation-10
        collaborator-data
      "
    >
      <v-list-item-content>
        <v-list-item-title class="headline"
          >Colaborador {{ collaborator.name }}</v-list-item-title
        >
        <v-list-item-subtitle>
          <div>ID: {{ collaborator.id }}</div>
          <div>CPF: {{ collaborator.cpf }}</div>
          <div>Telefone: {{ collaborator.phone }}</div>
          <div>Endereço: {{ collaborator.address }}</div>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-tabs v-model="tab" class="collaborator-data elevation-10">
      <v-tab>Equipamentos</v-tab>
      <v-tab>Documentos</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item class="elevation-10">
        <v-card>
          <v-card-title class="text-center justify-center">
            Lista de Equipamentos
          </v-card-title>
          <v-btn
            color="black"
            @click="addEquipment"
            dark
            class="add-align-right"
            small
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-data-table
            :headers="headersEquipments"
            :loading="loading_equipments"
            :items="collab_equip"
            :options.sync="paginationEquipments"
            :server-items-length="totalEquipments"
            no-data-text="Nenhum equipamento encontrado"
            class="elevation-1 table-equipments"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="showDeleteDialogEquipment(item)"
                >mdi-delete</v-icon
              >
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>

      <v-tab-item class="elevation-10">
        <collaborator-documents></collaborator-documents>
      </v-tab-item>
    </v-tabs-items>

    <v-dialog
      :value="dialog_equipments"
      @click:outside="closeDialogEquipments"
      width="1280"
    >
      <collaborators-equipments-form></collaborators-equipments-form>
    </v-dialog>

    <v-dialog
      :value="dialogDeleteEquipment"
      @click:outside="closeDeleteDialogEquipment"
      width="300"
    >
      <v-card>
        <v-card-title class="headline">Confirmar exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn text @click="closeDeleteDialogEquipment()">Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn text type="submit" right @click="deleteEquipment"
              >Confirmar</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import CollaboratorsEquipmentsForm from "./collaboratorsequipments/CollaboratorsEquipmentsForm.vue";
import CollaboratorDocuments from "./documents/CollaboratorDocuments";

export default {
  name: "Collaborator",
  computed: {
    ...mapState("collaborator", [
      "collaborator",
      "documents",
      "loading_documents",
    ]),
    ...mapFields("collaborator", ["paginationEquipments", "showNotification"]),
    ...mapState("collaborators_equipments", [
      "pagination_equipments",
      "loading_equipments",
      "totalEquipments",
      "collab_equip",
      "notification",
      "equipments",
    ]),
    ...mapFields("collaborators_equipments", {
      dialog_equipments: "dialog",
      dialogDeleteEquipment: "dialogDelete",
      paginationEquipments: "pagination_equipments",
    }),
    ...mapFields("collaborators_equipments", ["equip_id", "model", "color"]),
  },
  components: {
    CollaboratorsEquipmentsForm,
    "collaborator-documents": CollaboratorDocuments,
  },
  created() {
    let collaborator_id = this.$route.params.collaborator_id;
    this.collaborator_id = collaborator_id;
    this.getCollaborator({ collaborator_id: collaborator_id });
    this.getEquipments({ collaborator_id: collaborator_id });
  },
  data: () => ({
    headersDocuments: [
      {
        text: "Nome",
        value: "name",
      },
    ],
    headersEquipments: [
      { text: "Equipamento", value: "equipment_name", sortable: false },
      { text: "Marca / Modelo", value: "equipment_model", sortable: false },
      { text: "Cor", value: "equipment_color", sortable: false },
      { text: "Ações", value: "actions", sortable: false, align: "right" },
    ],
    tab: "",
  }),
  methods: {
    ...mapMutations("collaborators_equipments", {
      closeDialogEquipments: "closeDialog",
      addEquipment: "add",
    }),
    ...mapActions("collaborator", ["getCollaborator", "goBack"]),
    ...mapActions("collaborators_equipments", {
      showDeleteDialogEquipment: "showDeleteDialog",
      closeDeleteDialogEquipment: "closeDeleteDialog",
      getEquipments: "getEquipments",
      deleteEquipment: "deleteItem",
    }),
  },
  watch: {
    paginationEquipments: {
      handler() {
        this.getEquipments({
          resetPage: false,
          collaborator_id: this.collaborator_id,
        });
      },
    },
  },
};
</script>

<style>
@import "./css/collaborators_equipments.css";
</style>
