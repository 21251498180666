import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../../config/endPoints';

const defaultItem = {
    period: 0,
    value: 0,
    slot: 0,
    slot_name: "",
};

const defaultFilter = {
    period: "",
    id: "",
    value: "",
    slot: "",
}

export default {
    namespaced: true,
    state: {
        dialog: false,
        dialogFilter: false,
        dialogDelete: false,
        editedItem: {},
        items: [],
        itemToDelete: {},
        loading: false,
        mode: 'LIST',
        notificationType: '',
        notificationMessage: '',
        page: 1,
        filter: {
            period: "",
            id: "",
            value: "",
            slot: "",
        },
        filtered: false,
        overlay: false,
        weekly_revenue_id: '',
        weekly_revenue: {
            id: '',
            start_date: '',
            finish_date: '',
            description: '',
        },
        pagination: {
            page: 1,
            itemsPerPage: 10
        },
        showNotification: false,
        totalItems: 0,
        rowsPerPage: 10
    },
    mutations: {
        add: (state) => {
            state.mode = "ADD"
            state.editedItem = Object.assign({}, defaultItem)
            state.dialog = true
        },
        closeDialog: (state) => {
            state.dialog = false
            state.editedItem = Object.assign({}, defaultItem)
            state.editedIndex = -1
            state.errors = [];
        },
        loading: (state, loading) => state.loading = loading,
        overlay: (state, payload) => state.overlay = payload.show,
        setErrorMessage: (state, message) => state.errorMessage = message,
        setMode: (state, mode) => state.mode = mode,
        setItems: (state, items) => state.items = items,
        setItemToDelete: (state, item) => state.itemToDelete = item,
        setTotalItems: (state, total) => state.totalItems = total,
        setRowsPerPage: (state, rowsPerPage) => state.rowsPerPage = rowsPerPage,
        showFilter: (state, payload) => state.dialogFilter = payload.show,
        showDialog: (state, show) => state.dialog = show,
        setDialogDelete: (state, show) => state.dialogDelete = show,
        setWeeklyRevenue: (state, payload) => state.weekly_revenue = payload.weekly_revenue,
        setWeeklyRevenueId: (state, payload) => {
            state.weekly_revenue_id = payload.weekly_revenue_id
        },
        setFilter: (state, filter) => {
            state.filter = filter
        },
        setFiltered: (state, payload) => {
            state.filtered = payload.filtered
        },
        showErrorNotification: (state, show) => state.errorNotification = show,
        showSuccessNotification: (state, show) => state.successNotification = show,
        showLoading: (state, payload) => state.loading = payload.show,
        showNotification: (state, payload) => {
            state.showNotification = payload.show,
                state.notificationMessage = payload.message,
                state.notificationType = payload.type
        },
        showDialogFinishAgentClosure: (state, payload) => {
            state.agentClosureToFinish.adjust_value = 0
            state.agentClosureToFinish = payload.item
            state.agentClosureToFinish.id_instance = payload.item.club
            state.dialogAgentClosureFinish = payload.show
        },
        updateField,
    },
    getters: {
        getField,
    },
    actions: {
        closeDeleteDialog: ({ commit }) => {
            commit('setDialogDelete', false)
            commit('setItemToDelete', {})
        },
        clearFilter: ({ commit, dispatch }) => {
            commit('setFilter', Object.assign({}, defaultFilter))
            commit('setFiltered', { filtered: false })
            commit('showFilter', { show: false })
            dispatch('getItems', { resetPage: true })
        },
        deleteItem: ({ dispatch, commit, state }) => {
            commit('loading', true)
            axios.delete(endPoints.slots_revenue + "/" + state.itemToDelete.id).then(() => {
                dispatch('closeDeleteDialog')
                return dispatch('getItems');
            });
        },
        getItems: ({ commit, state }, payload) => {
            let period = '';
            if(state.weekly_revenue_id) {
                period = state.weekly_revenue_id
            } else if(payload && payload.weekly_revenue_id) {
                period = payload.weekly_revenue_id
            }
            if (period) {
                commit('loading', true)
                var filter = state.filter;
                let url = endPoints.slots_revenue;
                url += '?page=' + state.pagination.page + '&' + 'itemsPerPage=' + state.pagination.itemsPerPage;
                filter.period = period
                let filtered = false
                Object.keys(filter).forEach((field) => {
                    if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
                        filtered = true
                        url += "&" + field + "=" + filter[field]
                    }
                });
                if (state.pagination && state.pagination.sortBy && state.pagination.sortBy.length > 0) {
                    url += "&sort_by=" + state.pagination.sortBy[0]
                    url += "&sort_desc=" + state.pagination.sortDesc[0]
                }
                commit('setFiltered', { filtered: filtered })
                axios.get(url).then(items => {
                    commit('setTotalItems', items.data.total)
                    commit('loading', false)
                    commit('setItems', items.data.data)
                });
            }
        },
        showDeleteDialog: ({ commit }, item) => {
            commit('setDialogDelete', true)
            commit('setItemToDelete', item)
        },
        save: ({ dispatch, commit, state }) => {
            var url = endPoints.slots_revenue;
            
            state.editedItem.period = parseInt(state.weekly_revenue_id)
            state.editedItem.value = parseFloat(state.editedItem.value)
            
            axios.post(url, state.editedItem).then(
                () => {
                    dispatch('getItems')
                    let notification = {
                        show: true,
                        message: "Faturamento salvo com sucesso.",
                        type: "success"
                    }
                    commit('closeDialog')
                    commit('showNotification', notification)
                },
                error => {
                    let notification = {
                        show: true,
                        message: error.response.data.message,
                        type: "error"
                    }
                    commit('showNotification', notification)
                }
            );
        },
        calcApp: ({ dispatch, commit }, item) => {
            var url = endPoints.slots_revenue + "/calc_app/" + item.id;
            axios.put(url, item).then(
                () => {
                    dispatch('getItems')
                    let notification = {
                        show: true,
                        message: "Cálculo Realizado; \n Valor de Taxa do App atualizado",
                        type: "success"
                    }
                    commit('showNotification', notification)
                },
                () => {
                    let notification = {
                        show: true,
                        message: "Erro ao fazer o cálculo; \n Contate o administrador",
                        type: "error"
                    }
                    commit('showNotification', notification)
                }
            )
        }, 
        return({ dispatch }) {
            dispatch('menu/openLink', '/weekly_revenue', { root: true })
        },
    }
}