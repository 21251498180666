<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline text-center justify-center">Filtro</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md>
            <v-layout wrap>

              <v-flex xs12 sm6 md6>
                <v-text-field
                  label="ID"
                  v-model="id"
                  :clearable="true"
                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-select
                  label="Tipo"
                  v-model="type"
                  :items="possible_types"
                  item-text="name"
                  item-value="id"
                  :clearable="true"
                  outlined
                ></v-select>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-text-field
                  v-model="name"
                  label="Nome/Nome Fantasia"
                  :clearable="true"

                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-text-field
                  v-model="corporate_name"
                  label="Razão Social"
                  :clearable="true"

                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 md6 >
                <v-text-field
                  v-model="inscription"
                  label="CPF/CNPJ"
                  :clearable="true"
                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-text-field
                  v-if="this.type == 0"
                  v-model="birth_date"
                  type="date"
                  label="Data de Nascimento/Abertura"
                  :clearable="true"
                  outlined
                ></v-text-field>

                <v-text-field
                  v-else
                  v-model="birth_date"
                  type="date"
                  label="Data de Abertura"
                  :clearable="true"
                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-text-field
                  v-model="city"
                  label="Cidade"
                  :clearable="true"
                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-autocomplete
                  v-model="state"
                  :items="states"
                  label="Estado"
                  :clearable="true"
                  outlined
                ></v-autocomplete>
              </v-flex>
              
              <v-flex xs12 sm6 md6>
                <v-autocomplete
                  v-model="country"
                  :items="countries"
                  item-text="common"
                  item-value="common"
                  label="País"
                :clearable="true"                    
                  outlined
                ></v-autocomplete>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-text-field
                  v-model="whatsapp_group"
                  label="Grupo de WhatsApp"
                :clearable="true"
                  outlined
                ></v-text-field>
              </v-flex>

               <v-flex xs12 sm6 md6>
                <v-select
                  label="Tipo de Pagamento"
                  v-model="payment_for"
                  :items="type_of_payment"
                  item-text="name"
                  item-value="id"
                  :clearable="true"
                  outlined
                ></v-select>
              </v-flex>

            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn
            text
            @click="
              setShowFilter(false);
              clearFilter();
            "
            >Cancelar</v-btn
          >
        </v-col>
        <v-col class="text-right">
          <v-btn text type="submit" right @click="filter">Filtrar</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "ClientsFilter",

  created() {
    this.getStates();
    this.getCountries();
  },

  computed: {
    ...mapState("clients", [
      "showDialog",
      "states",
      "countries",
      "possible_types",
      "type_of_payment",
    ]),

    ...mapFields("clients", [
      "filter.id",
      "filter.type",
      "filter.inscription",
      "filter.name",
      "filter.birth_date",
      "filter.corporate_name",
      "filter.country",
      "filter.city",
      "filter.state",
      "filter.contact",
      "filter.whatsapp_group",
      "filter.payment_for",
      "filter.situation"
    ]),

    getSituation(situation) {
      let situations = ["Inadimplente", "Em Acordo", "Rotativo Semanal", "Ativo", "Não Definido"] 
      if (situations[situation]) {
      return situations[situation];
      }
      return "";
    }
  },   
  methods: {
    ...mapMutations("clients", ["setShowFilter", "clearFilter"]),
    ...mapActions("clients", [
      "getItems",
      "getStates",
      "getCountries",
    ]),

    filter() {
      this.getItems({ resetPage: true });
      this.setShowFilter(false);
    },

    
  },
  data: () => ({
    timeProps: {
      format: "24hr",
    },
  }),
};
</script>
