<template>
    <div>
        <v-card>
            <v-card-title>
                <span class="headline text-center justify-center">Filtro</span>

                <v-col class="text-right">
                    <v-btn
                        v-if="filtered == true"
                        color="white"
                        class="ma-2"
                        @click="clearFilter"
                        right
                        small
                        depressed
                    >
                        <v-icon color="black">mdi-filter-remove</v-icon>
                    </v-btn>
                </v-col>
            </v-card-title>

            <v-card-text>
                <v-form ref="form">
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12 sm6 md6>
                                <v-text-field
                                    required
                                    label="ID"
                                    v-model="id"
                                    outlined
                                    :clearable="true"
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-autocomplete
                                    label="Status"
                                    :clearable="true"
                                    clear-icon="mdi-close"
                                    :items="possible_status"
                                    v-model="status"
                                    item-text="description"
                                    item-value="id"
                                    outlined
                                    multiple
                                ></v-autocomplete>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-datetime-picker
                                    v-model="start_date"
                                    date-format="dd/MM/yyyy"
                                    clearText="Limpar"
                                    okText="Confirmar"
                                    :time-picker-props="timeProps"
                                    label="De *"
                                    :textFieldProps="{ outlined: true }"
                                >
                                    <template slot="dateIcon">
                                        <v-icon>mdi-calendar</v-icon>
                                    </template>
                                    <template slot="timeIcon">
                                        <v-icon>mdi-clock</v-icon>
                                    </template>
                                </v-datetime-picker>
                            </v-flex>

                            <v-flex xs12 sm6 md6>
                                <v-datetime-picker
                                    v-model="end_date"
                                    date-format="dd/MM/yyyy"
                                    clearText="Limpar"
                                    okText="Confirmar"
                                    label="Até *"
                                    :time-picker-props="timeProps"
                                    :textFieldProps="{ outlined: true }"
                                >
                                    <template slot="dateIcon">
                                        <v-icon>mdi-calendar</v-icon>
                                    </template>
                                    <template slot="timeIcon">
                                        <v-icon>mdi-clock</v-icon>
                                    </template>
                                </v-datetime-picker>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-autocomplete
                                    label="Plataforma"
                                    :items="platforms"
                                    v-model="platform"
                                    item-text="description"
                                    item-value="description"
                                    outlined
                                    :clearable="true"
                                >
                                </v-autocomplete>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-autocomplete
                                    :readonly="!platform"
                                    label="Clube *"
                                    :items="clubsApp.filter(club => club.platform == platform)"
                                    v-model="id_club"
                                    item-value="club"
                                    clear-icon="mdi-close"
                                    item-text="clubLabel"
                                    :clearable="true"
                                    multiple
                                    outlined
                                ></v-autocomplete>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-text-field
                                    required
                                    label="Valor"
                                    v-model="value"
                                    outlined
                                    :clearable="true"
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-text-field
                                    required
                                    label="ID Jogador"
                                    v-model="player_id"
                                    outlined
                                    :clearable="true"
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-text-field
                                    required
                                    label="Documento"
                                    v-model="doc"
                                    outlined
                                    :clearable="true"
                                ></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-col>
                    <v-btn text @click="showFilter({ show: false })"
                        >Cancelar</v-btn
                    >
                </v-col>
                <v-col class="text-right">
                    <v-btn text type="submit" right @click="filter"
                        >Filtrar</v-btn
                    >
                </v-col>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
    name: 'AutoAttendanceFilter',
    computed: {
        ...mapFields('auto_attendance', [
            'filter.id',
            'filter.doc',
            'filter.status',
            'filter.start_date',
            'filter.end_date',
            'filter.player_id',
            'filter.id_club'
        ]),
        ...mapState("clubs", { clubsApp: "clubs", platforms: "platforms"}),
    },
    created() {
    },
    data: () => ({
        possible_status: [
            { id: 'Solicitado', description: 'Solicitado' },
            { id: 'Cancelado', description: 'Cancelado' },
            { id: 'Aguardando envio', description: 'Aguardando envio' },
            { id: 'Erro', description: 'Erro' },
            { id: 'Aguardando liberação', description: 'Aguardando liberação' },
            { id: 'Enviado', description: 'Enviado'}
        ],
        timeProps: {
            format: '24hr',
        },
        platform: null,
    }),
    methods: {
        ...mapActions('auto_attendance', ['getItems']),
        ...mapMutations('auto_attendance', ['showFilter']),
        filter() {
            this.getItems({ resetPage: true })
            this.showFilter({ show: false })
        },
    },
}
</script>

<style></style>
