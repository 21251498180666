<template>
  <div>
         <v-dialog :value="showDialog" persistent width="600">
      <v-card>
        <v-card-title>
          <span class="headline">Pasta de Agente</span>
          <v-spacer></v-spacer>
          <v-progress-circular
            v-if="loadingAgents"
            indeterminate
            class="text-right"
            color="primary"
            size="24"
          ></v-progress-circular>
          <v-progress-circular
            v-if="loadingUsers"
            indeterminate
            class="text-right"
            color="primary"
            size="24"
          ></v-progress-circular>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>

                <v-flex xs6 sm6 md12 v-if="mode == 'ADD_AGENT'">
                  <v-autocomplete
                    clearable
                    deletable-chips
                    v-model="agent_id"
                    :no-filter="true"
                    :items="findAgents"
                    outlined
                    chips
                    small-chips
                    no-data-text="Nenhum agente encontrado"
                    :item-text="getTextAgent"
                    item-value="id"
                    placeholder="Digite aqui o nome do agente"
                    label="Agente"
                    :search-input.sync="searchAgent"
                  ></v-autocomplete>
                </v-flex>

                <v-flex xs6 sm6 md12 v-if="mode == 'ADD_CLIENT'">
                  <v-autocomplete
                    clearable
                    deletable-chips
                    v-model="client_id"
                    :no-filter="true"
                    :items="findClients"
                    outlined
                    chips
                    small-chips
                    no-data-text="Nenhum cliente encontrado"
                    :item-text="getTextClient"
                    item-value="id"
                    placeholder="Digite aqui o nome do cliente"
                    label="Cliente"
                    :search-input.sync="searchClient"
                  ></v-autocomplete>
                </v-flex>

                <v-flex xs12 sm12 md12 v-if="mode == 'ADD_USER'">
                  <v-flex xs12 sm12 md12>
                    <v-text-field
                      label="Porcentagem"
                      v-model="percentage"
                      :error-messages="errors.collect('percentage')"
                      v-validate="'min_value:0|max_value:100'"
                      data-vv-name="percentage"
                      append-icon="mdi-percent-outline"
                      outlined
                      type="number"
                    ></v-text-field>
                  </v-flex>
                </v-flex>

                <v-flex xs6 sm6 md12 v-if="mode == 'ADD_USER'">
                  <v-autocomplete
                    clearable
                    deletable-chips
                    v-model="user_id"
                    :no-filter="true"
                    :items="findUsers"
                    outlined
                    chips
                    small-chips
                    no-data-text="Nenhum usuário encontrado"
                    item-text="name"
                    item-value="id"
                    placeholder="Digite aqui o nome do usuário"
                    label="Usuário"
                    :search-input.sync="searchUser"
                  ></v-autocomplete>
                </v-flex>
                
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-col>
            <v-btn
              text
              @click="setShowDialog(false)"
              >{{ mode == "VIEW" ? "Voltar" : "Cancelar" }}</v-btn
            >
          </v-col>
          <v-col class="text-right">
            <v-btn
              text
              type="submit"
              right
              @click="saveFolder"
              >Salvar</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: 'AgentsFoldersForm',
  created() {
    this.getFindAgents();
    this.getFindUsers();
    this.getFindClients();
  },
  methods: {
    ...mapMutations("agents_folder", ["setShowDialog", "setSelectedItem"]),
    ...mapActions("agents_folder", ["save", "getFindAgents", "getFindUsers", "getFindClients"]),
    saveFolder() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.percentage = parseFloat(this.percentage)
          this.save();
        }
      });
    },
    getTextAgent(item) {
      if(item) {
        if(item.id_pppoker) {
          return item.id_pppoker + " | " + item.nick + " | " + item.club_name
        } else {
          return item.nick
        }
      }
      return ''
    },
    getTextClient(item) {
      if(item) {
        return item.name + " | " + item.contact
      }
      return ''
    },
  },
  computed: {
    ...mapState("agents_folder", [
      "mode",
      "findAgents",
      "findClients",
      "findUsers",
      "selectedItem",
      "showDialog",
      "loadingAgents",
      "loadingUsers"
    ]),
    ...mapFields("agents_folder", [
      "selectedItem.user_id",
      "selectedItem.percentage",
      "selectedItem.agent_id",
      "selectedItem.client_id",
      
    ]),
    ...mapFields("agents_folder", {
      searchAgent: "filter_agents.nick",
      searchUser: "filter_users.name",
      searchClient: "filter_clients.name",
    })
  },
  data: () => ({
    timeProps: {
      format: "24hr",
    },
  }),
  watch: {
    searchAgent: {
      handler () {
          this.searchAgent !== null && this.getFindAgents()
      }
    },
    searchUser: {
      handler () {
          this.searchUser !== null && this.getFindUsers()
      }
    },
    searchClient: {
      handler () {
          this.searchUser !== null && this.getFindClients()
      }
    },
  },
}
</script>

<style>

</style>