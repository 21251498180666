<template>
  <v-container class="elevation-10 status">
    <v-row>
      <v-col
        v-for="i in instancesByUser"
        :key="i.id"
        cols="12"
        sm="12"
      >
        <v-card
          class="pa-2 text-center"
          :class="{alertcolor: alertColor(i), connected: !alertColor(i) && isConnected(i) }"
          outlined
          tile
        >
          <div class="instance-name">
            <b class="text-center">{{i.name}}</b>
            <div v-if="i.battery">
              <v-icon v-if="i.battery < 10">{{ i.plugged ? 'mdi-battery-charging-10' : 'mdi-battery-alert-variant-outline' }}</v-icon>
              <v-icon v-if="i.battery >= 10 && i.battery < 20">{{ i.plugged ? 'mdi-battery-charging-10' : 'mdi-battery-10' }}</v-icon>
              <v-icon v-if="i.battery >= 20 && i.battery < 30">{{ i.plugged ? 'mdi-battery-charging-20' : 'mdi-battery-20' }}</v-icon>
              <v-icon v-if="i.battery >= 30 && i.battery < 40">{{ i.plugged ? 'mdi-battery-charging-30' : 'mdi-battery-30' }}</v-icon>
              <v-icon v-if="i.battery >= 40 && i.battery < 50">{{ i.plugged ? 'mdi-battery-charging-40' : 'mdi-battery-40' }}</v-icon>
              <v-icon v-if="i.battery >= 50 && i.battery < 60">{{ i.plugged ? 'mdi-battery-charging-50' : 'mdi-battery-50' }}</v-icon>
              <v-icon v-if="i.battery >= 60 && i.battery < 70">{{ i.plugged ? 'mdi-battery-charging-60' : 'mdi-battery-60' }}</v-icon>
              <v-icon v-if="i.battery >= 70 && i.battery < 80">{{ i.plugged ? 'mdi-battery-charging-70' : 'mdi-battery-70' }}</v-icon> 
              <v-icon v-if="i.battery >= 80 && i.battery < 90">{{ i.plugged ? 'mdi-battery-charging-80' : 'mdi-battery-80' }}</v-icon>
              <v-icon v-if="i.battery >= 90 && i.battery < 100">{{ i.plugged ? 'mdi-battery-charging-90' : 'mdi-battery-90' }}</v-icon>
              <v-icon v-if="i.battery == 100">{{ i.plugged ? 'mdi-battery-charging' : 'mdi-battery' }}</v-icon>
            </div>
          </div>

          <div v-if="isConnected(i)" class="text-connected justify-center align-center">
            Conectado
            <br>
            <div class="text-center">
              <v-btn class="mx-2" @click="refreshInstance({instance: i})">Atualizar</v-btn>
              <v-btn class="mx-2" color="orange" @click="reconnectInstance({instance: i})">Reconectar</v-btn>
              <v-btn class="mx-2" color="red" @click="restartInstance({instance: i})">Reiniciar</v-btn>
            </div>
          </div>
          <div v-else-if="isQr(i)" class="text-connected justify-center align-center">
            <v-img><img :src="i.qr_code"></v-img>
            <v-btn class="mx-2" v-if="!isConnected(i)" @click="refreshInstance({instance: i})">      
              Atualizar
            </v-btn>
            <v-btn class="mx-2" color="orange" v-if="!isConnected(i)" @click="reconnectInstance({instance: i})">Reconectar</v-btn>
            <v-btn class="mx-2" color="red" v-if="!isConnected(i)" @click="restartInstance({instance: i})">Reiniciar</v-btn>
          </div>
          <div v-else class="text-error justify-center align-center">
            Desconectado
            <br>
            <v-btn class="mx-2" v-if="!isConnected(i)" @click="refreshInstance({instance: i})">      
              Atualizar
            </v-btn>
            <v-btn class="mx-2" color="red" v-if="!isConnected(i)" @click="restartInstance({instance: i})">Reiniciar</v-btn>
          </div>

          Último aviso de desconexão: {{getDisconnectDate(i)}}
          <div v-if="isConnected(i)">
            Telefone conectado: {{ i.number }}
            <br>
            Nome Exibição (Whatsapp): {{ i.push_name }}
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters, mapState, mapActions } from 'vuex'
  export default {
    props: [

    ],
    computed: {
      ...mapGetters('login', ['getInstanceId']),
      ...mapState('status_instance', ['instances']),
      instancesByUser: function() {
          
          let instanceId = this.getInstanceId
        
          let instances = []

          if(instanceId != "" && instanceId != null && instanceId != undefined)  {
            instances = this.instances.filter((i) => i.id == instanceId)
            return instances
          } 
          return this.instances
      },
    },
    created () {
      this.getInstances()
      this.watchInstance()
    },
    data: () => ({
      
    }),
    methods: {
      ...mapActions('status_instance', ['disconnectInstance', 'getInstances', 'reconnectInstance', 'refreshInstance', 'restartInstance']),
      isConnected(instance) {
        return (instance.connected == true)
      },
      isQr(instance) {
        return (instance.qr_code != null)
      },
      alertColor(instance) {
        if(instance.disconnected_time.Int64 != 0) {
          var diffMs = new Date().getTime() - instance.disconnected_time.Int64
          return Math.round(diffMs / 60000) < 60
        }
        else {
          return false
        }
      },
      getDisconnectDate(instance) {
        if(instance.disconnected_time.Int64) {

          var d = new Date(instance.disconnected_time.Int64);
          return d.toLocaleDateString() + " " + d.toLocaleTimeString()
        }
        return "-"
      },
      watchInstance() {

        this.instances.forEach(i => {
          this.refreshInstance({instance: i})
        })
        if(this.$router) {
          if(this.$router.history.current.name == "qrcode") {
            setTimeout(() => {
              this.watchInstance()
            }, 5000)
          }
        }
        
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import './status.sass'
</style>
