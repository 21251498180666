<template>
  <div class="page-loader" v-if="!isloaded">
    <img class="spider" src="../assets/logo-sx-removebg-preview.png">
    <!-- <div class="cube"></div>
    <div class="cube"></div>
    <div class="cube"></div>
    <div class="cube"></div> -->
  </div> 
</template>

<script>
  export default {
    data: () => {
      return {
        isloaded: false
      }
    },
    mounted() {
        document.onreadystatechange = setTimeout(() => {
            if (document.readyState == "complete") { 
                this.isloaded = true;
            }
        }, 900 );
    },
  }
</script>

<style lang="scss" scoped>
   $colors: #8CC271, #69BEEB, #F5AA39, #E9643B;
  // -----------------------------------------------------
  .page-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgb(255, 255, 255);
    z-index: 999;
  }
  // -----------------------------------------------------
 

  // -----------------------------------------------------
  @keyframes left {
    40% {
      transform: translateX(-60px);
    }
    50% {
      transform: translateX(0);      
    }
  }
  @keyframes right {
    40% {
      transform: translateX(60px);
    }
    50% {
      transform: translateX(0);
    }
  }
</style>