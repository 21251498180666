<template>
  <div>
    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2000"
      v-model="showNotification"
    >
      {{ notificationMessage }}
    </v-snackbar>

    <v-overlay :value="loading_overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-card class="elevation-10">
      <v-card-title class="text-center justify-center">
        Parâmetros Fechamento
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm6 md6>
                <v-select
                  label="Cobrar Overlay *"
                  :items="possible_overlays"
                  v-model="charge_overlay"
                  :error-messages="errors.collect('charge_overlay')"
                  v-validate="'required:Cobrar Overlay,charge_overlay'"
                  data-vv-name="charge_overlay"
                  item-text="description"
                  item-value="id"
                  outlined
                ></v-select>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-text-field
                  required
                  label="Valor Overlay *"
                  v-model="value_overlay"
                  :readonly="this.mode == 'VIEW'"
                  :error-messages="errors.collect('value_overlay')"
                  v-validate="'required:Valor Overlay,value_overlay|decimal:2'"
                  data-vv-name="value_overlay"
                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-select
                  label="Cobrar Diamantes *"
                  :items="possible_diamonds"
                  v-model="charge_diamonds"
                  :error-messages="errors.collect('charge_diamonds')"
                  v-validate="'required:Cobrar Diamantes,charge_diamonds'"
                  data-vv-name="charge_diamonds"
                  item-text="description"
                  item-value="id"
                  outlined
                ></v-select>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-text-field
                  required
                  label="Valor Diamantes *"
                  v-model="value_diamonds"
                  :readonly="this.mode == 'VIEW'"
                  :error-messages="errors.collect('value_diamonds')"
                  v-validate="
                    'required:Valor Diamantes,value_diamonds|decimal:2'
                  "
                  data-vv-name="value_diamonds"
                  outlined
                ></v-text-field>
              </v-flex>

              <!--v-flex xs12 sm6 md6>
                <v-select
                  label="Cobrar Transações *"
                  :items="possible_transactions"
                  v-model="charge_transactions"
                  :error-messages="errors.collect('charge_transactions')"
                  v-validate="'required:Cobrar Transações,charge_transactions'"
                  data-vv-name="charge_transactions"
                  item-text="description"
                  item-value="id"
                  outlined
                ></v-select>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-text-field
                  required
                  label="Valor Percentual Transações (%)*"
                  v-model="value_transactions"
                  :readonly="this.mode == 'VIEW'"
                  :error-messages="errors.collect('value_transactions')"
                  v-validate="
                    'required:Valor Transações,value_transactions|decimal:2'
                  "
                  data-vv-name="value_transactions"
                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-text-field
                  required
                  label="Valor Fixo por Transação (R$)*"
                  v-model="fix_value_transactions"
                  :readonly="this.mode == 'VIEW'"
                  :error-messages="errors.collect('fix_value_transactions')"
                  v-validate="
                    'required:Fix Valor Transaçõe,fix_value_transactions|decimal:2'
                  "
                  data-vv-name="fix_value_transactions"
                  outlined
                ></v-text-field>
              </v-flex!-->

              <v-flex xs12 sm6 md6>
                <v-select
                  label="Permite Exclusão Automática de Jogadores *"
                  :items="possible_exclusions"
                  v-model="allow_auto_exclusion"
                  :error-messages="errors.collect('allow_auto_exclusion')"
                  v-validate="'required:Permite Exclusão Automática de Jogadores,allow_auto_exclusion'"
                  data-vv-name="allow_auto_exclusion"
                  item-text="description"
                  item-value="id"
                  outlined
                ></v-select>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-text-field
                  required
                  label="Saldo Máximo para Exclusão Automática *"
                  v-model="max_value_exclusion"
                  :readonly="this.mode == 'VIEW'"
                  :error-messages="errors.collect('max_value_exclusion')"
                  v-validate="
                    'required:Saldo Máximo para Exclusão Automática,max_value_exclusion|decimal:2'
                  "
                  data-vv-name="max_value_exclusion"
                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-text-field
                  required
                  label="Semanas Inativas para Exclusão *"
                  v-model="weeks_for_exclusion"
                  :readonly="this.mode == 'VIEW'"
                  :error-messages="errors.collect('weeks_for_exclusion')"
                  v-validate="
                    'required:Semanas Inativas para Exclusão,weeks_for_exclusion'
                  "
                  data-vv-name="weeks_for_exclusion"
                  outlined
                ></v-text-field>
              </v-flex>

             <v-flex xs12 sm6 md6>
                <v-select
                  label="Movimentações na Central *"
                  :items="yes_no"
                  v-model="moves_in_central"
                  :error-messages="errors.collect('moves_in_central')"
                  v-validate="'required:Movimentações na Central,moves_in_central'"
                  data-vv-name="moves_in_central"
                  item-text="description"
                  item-value="id"
                  outlined
                ></v-select>
              </v-flex>

              
             <v-flex xs12 sm6 md6>
                <v-select
                  label="Abate Movimentações do 24H *"
                  :items="yes_no"
                  v-model="discount_moviments_tfh"
                  :error-messages="errors.collect('discount_moviments_tfh')"
                  v-validate="'required:Movimentações na Central,discount_moviments_tfh'"
                  data-vv-name="discount_moviments_tfh"
                  item-text="description"
                  item-value="id"
                  outlined
                ></v-select>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-select
                  label="Envia Relatório de Fechamento *"
                  :items="yes_no"
                  v-model="send_report_closure"
                  :error-messages="errors.collect('send_report_closure')"
                  v-validate="'required:Movimentações na Central,send_report_closure'"
                  data-vv-name="send_report_closure"
                  item-text="description"
                  item-value="id"
                  outlined
                ></v-select>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-select
                  label="Envia Relatório de Transações da Central *"
                  :items="yes_no"
                  v-model="send_report_central_transactions"
                  :error-messages="errors.collect('send_report_central_transactions')"
                  v-validate="'required:Movimentações na Central,send_report_central_transactions'"
                  data-vv-name="send_report_central_transactions"
                  item-text="description"
                  item-value="id"
                  outlined
                ></v-select>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-col class="text-center">
          <v-btn text type="submit" class="primary" @click="saveParameters()"
            >Salvar</v-btn
          >
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "ParametersAgentClosure",
  computed: {
    ...mapState("parameters_agent_closure", [
      "notificationMessage",
      "notificationType",
      "loading",
      "loading_overlay",
      "mode",
      "items",
      "possible_overlays",
      "possible_diamonds",
      "possible_transactions",
      "possible_exclusions",
    ]),
    ...mapFields("parameters_agent_closure", ["showNotification"]),
    ...mapFields("parameters_agent_closure", [
      "selectedItem.id",
      "selectedItem.id_agent",
      "selectedItem.value_overlay",
      "selectedItem.value_diamonds",
      "selectedItem.value_transactions",
      "selectedItem.fix_value_transactions",
      "selectedItem.charge_overlay",
      "selectedItem.charge_diamonds",
      "selectedItem.charge_transactions",
      "selectedItem.allow_auto_exclusion",
      "selectedItem.max_value_exclusion",
      "selectedItem.weeks_for_exclusion",
      "selectedItem.moves_in_central",
      "selectedItem.discount_moviments_tfh",
      "selectedItem.send_report_closure",
      "selectedItem.send_report_central_transactions",
    ]),
  },
  created() {
    this.getItem();
    this.$validator.extend("required", {
      getMessage: (field, [label]) => label + " deve ser informado",
      validate: (_, [, field]) => {
        if (this[field] || this[field] === 0) {
          return true;
        } else {
          return false;
        }
      },
    });
  },
  methods: {
    ...mapMutations("parameters_agent_closure", ["add"]),
    ...mapActions("parameters_agent_closure", [
      "deleteItem",
      "getItem",
      "save",
    ]),
    saveParameters() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.save();
        }
      });
    },
  },
  data: () => ({
    valid: false,
    yes_no: [
      { id: 0, description: "Não" },
      { id: 1, description: "Sim" }
    ]
  }),
};
</script>

