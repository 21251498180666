<template>
    <div>
      <div @drop.prevent="addDropFile" @dragover.prevent>
        <v-card>
          <v-card-title>
            <span class="headline text-center">Ocorrência</span>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <v-form ref="form" lazy-validation>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-select
                    class="mt-2 mb-2"
                    label="Ocorrências"
                    :items="occurrences"
                    item-text="occurrence"
                    item-value="id"
                    v-model="occurrencesSelected"
                    outlined
                    hide-details
                    multiple
                    dense
                    small-chips
                    deletable-chips
                  ></v-select>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
  
          <v-card-actions>
            <v-col>
              <v-btn text @click="closeDialog"> Fechar </v-btn>
            </v-col>
            <v-col class="text-right">
              <v-btn text type="submit" right @click="finishAt">Salvar</v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </div>
    </div>
  </template>
  
  <script>
  import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
  
  export default {
    data: () => ({
    }),
    name: "OccurrencesForm",
    computed: {
      ...mapState("chat", ["occurrences","payloadFinishAt"]),
      ...mapFields("chat",["occurrencesSelected"]),
      ...mapGetters("chat", ["getFinishAt",]),
    },
    methods: {
      ...mapMutations("chat", ["closeDialog"]),
      ...mapActions("chat", ["finishAttendance", "saveOccurrences"]),
      finishAt(){
        this.saveOccurrences();
        this.finishAttendance(this.getFinishAt)
      },
    },
  };
  </script>