var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showMenu)?_c('div',[(
      !_vm.menu.hasSubGroup &&
      (_vm.menu.roles ? _vm.menu.roles.indexOf(_vm.userData.role) != -1 : true)
    )?_c('simple-menu',{attrs:{"menu":_vm.menu}}):_vm._e(),(
      _vm.menu.hasSubGroup &&
      (_vm.menu.roles ? _vm.menu.roles.indexOf(_vm.userData.role) != -1 : true)
    )?_c('compound-menu',{attrs:{"menu":_vm.menu}}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }