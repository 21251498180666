import axios from "axios";
import { getField, updateField } from "vuex-map-fields";
import endPoints from "@config/endPoints";
import {
  defaultErrorNotification,
  defaultSuccessNotification,
} from "@constants";
import formatDate from '@util/formatDate.js'
const defaultPagination = {
  page: 1,
  itemsPerPage: 10,
};
const paramsQuery = [
  {
    id: 0,
    name: "period_conciliation_orders",
    initials: "pco",
    column: "order_id"
  },
  {
    id: 1,
    name: "period_conciliation_transactions",
    initials: "pct",
    column: "transaction_id"
  },
  {
    id: 2,
    name: "period_conciliation_moviments",
    initials: "pcm",
    column: "moviment_id"
  },
  {
    id: 3,
    name: "period_conciliation_bank_moviments",
    initials: "pcbm",
    column: "bank_moviment_id"
  },
  {
    id: 4,
    name: "period_conciliation_annotated",
    initials: "pca",
    column: "annotated_id"
  },
  {
    id: 5,
    name: "period_conciliation_closures",
    initials: "pcc",
    column: "closure_id"
  },
]

export default {
  namespaced: true,
  state: {
    items: [
      {
        type: 'Ordem',
        disabled: false,
        id: 0
      },
      {
        type: 'Transação',
        disabled: false,
        id: 1
      },
      {
        type: 'Movimentação Sistema',
        disabled: false,
        id: 2
      },
      {
        type: 'Movimentação Bancária',
        disabled: false,
        id: 3
      },
      {
        type: 'Anotado',
        disabled: false,
        id: 4
      },
      {
        type: 'Fechamento',
        disabled: false,
        id: 5
      },
    ],
    lowTypes: [
      { id: 1, description: 'Erro' },
      { id: 2, description: 'Fora do Período' },
      { id: 3, description: 'Número de Vinculações Incorreto' },
      { id: 4, description: 'Jogador Saiu do Clube' },
      { id: 5, description: 'Transferencia de Fichas entre Clubes' },
      { id: 6, description: 'Reembolso - Saque Cancelado' },
      { id: 99, description: 'Outros' },
  ],
    linkRecordDialog: false,
    dialogPeriod: false,
    periodBySearch: null,
    search_in: 6,
    expand: false,
    credit: false,
    dialogDropManually: false,
    itemsToLinked: [],
    totalItemsToLinked: 0,
    closure: [],
    transaction: [],
    moviment: [],
    low:{
      low_type: null,
      observation_low: "",
    },
    registrysToVinculation: {
      info_registry_one: {},
      info_registry_two: {},
    },
    bankMoviment: [],
    order: [],
    annotated: [],
    selectedItem: {},
    mode: "",
    callGetRecords: "",
    loading: false,
    closeAllDialogs: false,
    totalItems: 0,
    filter: {
      id: "",
      start: "",
      end: ""
    },
    notification: {
      show: false,
      message: "",
      type: "",
    },
    overlay: false,
    pagination: {
      page: 1,
      itemsPerPage: 10,
    },
  },
  mutations: {
    showNotification: (state, payload) => (state.notification = payload),
    showLoading: (state, payload) => {
      state.loading = payload.show;
    },
    setMoviment: (state, payload) => {
      state.moviment.push(payload);
    },
    setOrder: (state, payload) => {
      state.order.push(payload);
    },
    setClosure: (state, payload) => {
      state.closure.push(payload);
    },
    setAnnotated: (state, payload) => {
      state.annotated.push(payload);
    },
    setBankMoviment: (state, payload) => {
      state.bankMoviment.push(payload);
    },
    setTransaction: (state, payload) => {
      state.transaction.push(payload);
    },
    setCredit: (state, payload) => {
      state.credit = payload;
    },
    showLinkRecordDialog: (state) => {
      state.linkRecordDialog = true;
    },

    setCloseDialog: (state) => {      
      state.closeAllDialogs = !state.closeAllDialogs;
    },
    setCallGetRecords: (state) => {
      state.callGetRecords = true;
    },
    closeDialogLink: (state) => {
      state.linkRecordDialog = false;
      state.callGetRecords = false;
      state.pagination = Object.assign({}, defaultPagination)
    },
    showDialogDrop: (state, payload) => {
      state.dialogDropManually = payload.show;
      state.observation_low = "";
      state.low_type = null;
    },
    closeDialog: (state) => {
      state.expand = false;
      state.moviment = []
      state.order = []
      state.closure = []
      state.annotated = []
      state.bankMoviment = []
      state.transaction = []
      state.search_in = null
      state.selectedItem = Object.assign({}, {})
    },
    setTotalItems: (state, payload) => (state.totalItems = payload.total),
    setItems: (state, payload) => {
      state.items = payload.items
    },
    setPeriod: (state, payload) => {
      state.periodBySearch = payload.id;
    },
    setTotalItemsLinked: (state, payload) => (state.totalItemsToLinked = payload.total),
    setItemsToLInked: (state, payload) => {
      state.itemsToLinked = payload.items
    },
    buildItems: (state) => {
      if (state.selectedItem.order_id) {
        state.items[0].disabled = true
      } else {
        state.items[0].disabled = false
      }
      if (state.selectedItem.transaction_id) {
        state.items[1].disabled = true
      } else {
        state.items[1].disabled = false
      }
      if (state.selectedItem.moviment_id) {
        state.items[2].disabled = true
        state.items[5].disabled = true
      } else {
        state.items[2].disabled = false
      }
      if (state.selectedItem.bank_moviment_id) {
        state.items[3].disabled = true
        state.items[5].disabled = true
      } else {
        state.items[3].disabled = false
      }
      if (state.selectedItem.annotated_id) {
        state.items[4].disabled = true
      } else {
        state.items[4].disabled = false
      }
      if (state.selectedItem.closure_id) {
        state.items[2].disabled = true
        state.items[3].disabled = true
        state.items[5].disabled = true
      } else if (!state.selectedItem.moviment_id && !state.selectedItem.bank_moviment_id) {
        state.items[5].disabled = false
      }
      state.items[state.selectedItem.tab_origin].disabled = true
    },
    openVinculations: (state, payload) => {
      state.selectedItem = payload;
      state.expand = true
      state.mode = "VIEW";

    },
    updateField,
  },
  actions: {
    linkRegistry: async function ({ state, commit, dispatch }, payload) {
      paramsQuery[payload.tab_linked].id = payload.id
      paramsQuery[state.selectedItem.tab_origin].id = state.selectedItem.id
      let url = `${endPoints.conciliation}/link_records_manually`;
      let data = state.registrysToVinculation
      data.info_registry_one = paramsQuery[state.selectedItem.tab_origin]
      data.info_registry_one.id_period_conciliation = state.selectedItem.id_period_conciliation
      if (state.selectedItem.tab_origin == 1) {
        data.info_registry_one.is_duplicate = state.selectedItem.is_duplicate
      }
      data.info_registry_two = paramsQuery[payload.tab_linked]
      // commit('showOverlay', { show: true })

      axios.post(url, data).then(
        () => {
          let notification = {
            show: true,
            message: "Registros vinculados com sucesso.",
            notificationTime: 3000,
            type: "success"
          }
          commit('period_conciliation/showNotification', notification, { root: true })
          let path = `${data.info_registry_one.name}/getItems`
          if (state.selectedItem.tab_origin == 1) {
            dispatch('period_conciliation_transactions/getItems', { type: state.selectedItem.type, is_duplicate: state.selectedItem.is_duplicate }, { root: true })
          } else {
            dispatch(path, '', { root: true })
          }
          commit('closeDialogLink')
          commit('closeDialog')
          commit('setCloseDialog')

        },
        error => {
          console.log(error)
          let notification = {
            show: true,
            message: error.response.data.message,
            notificationTime: 3000,
            type: "error"
          }
          // commit('showOverlay', { show: false })
          commit('period_conciliation/showNotification', notification, { root: true })
          commit('closeDialogLink')
          commit('setCloseDialog')

          return
        }
      )
    },
    setPeriod: function ({ commit }, payload) {
      commit("setPeriod", {id : payload.period})
    },
    getRecordsRelated: async ({ state,dispatch }) => {
      console.log(state.selectedItem)
      let type = state.selectedItem.type
      if (state.selectedItem.tab_origin == 1) {
        type = state.selectedItem.type_transaction
      }
      let filter = {
        type_transaction: type,
        value:
          state.selectedItem.value ||
          state.selectedItem.rakeback ||
          state.selectedItem.changed_value,
        player_id:
          state.selectedItem.player_id ||
          state.selectedItem.player_id_app,
        vinculation:3
      }
      filter.tab_origin = paramsQuery[state.selectedItem.tab_origin].column
      // commit("setLinkRecordDialog", { show: true });
      switch (state.search_in) {
        case 0:
          dispatch("getItemsToLinked", {
            filter: filter,  url: "period_conciliation_orders"
          });
          break;
        case 1:
          filter.manager_type = 0
          dispatch("getItemsToLinked", {
            filter: filter, url: "period_conciliation_transactions"
          });
          break;
        case 2:
          dispatch("getItemsToLinked", {
            filter: filter, url: "period_conciliation_moviments"
          });
          break;
        case 3:
          dispatch("getItemsToLinked", {
            filter: filter, url: "period_conciliation_bank_moviments"
          });
          break;
        case 4:
          dispatch("getItemsToLinked", {
            filter: filter, url: "period_conciliation_annotated"
          });
          break;
        case 5:
          dispatch("getItemsToLinked", {
            filter: filter, url: "period_conciliation_closures"
          });
          break;
      }
    },
    getItemsToLinked: async ({ commit, state }, payload) => {
      let filter = payload.filter;
      let url = `${endPoints.conciliation}/${payload.url}/${state.periodBySearch}?page=${state.pagination.page}&itemsPerPage=${state.pagination.itemsPerPage}`;

      Object.keys(filter).forEach(function (field) {
        if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
          if (field == "end" || field == "start") {
            var e = new Date(filter[field])
            url += "&" + field + "=" + formatDate(e) + " " + e.toLocaleTimeString()
          } else {
            url += "&" + field + "=" + filter[field]
          }
        }
      });

      if (
        state.pagination &&
        state.pagination.sortBy &&
        state.pagination.sortBy.length > 0
      ) {
        url +=
          "&sort_by=" +
          (state.pagination.sortBy[0] === "name"
            ? "id"
            : state.pagination.sortBy[0]);
        url += "&sort_desc=" + state.pagination.sortDesc[0];
      }
      commit("setCallGetRecords");
      commit("showLinkRecordDialog");
      commit("showLoading", { show: true });
      try {
        let items = await axios.get(url);
        commit("setItemsToLInked", { items: items.data.data });
        commit("setTotalItemsLinked", { total: items.data.total });

      } catch (error) {
        commit(
          "showNotification",
          defaultErrorNotification(error?.response?.data?.message)
        );
      }
      
      commit("showLoading", { show: false });
    },

    getVinculations: async ({ commit, state }) => {
      if (state.selectedItem.order_id) {
        let url = `${endPoints.conciliation}/period_conciliation_orders/order/${state.selectedItem.order_id}`;
        let order = await axios.get(url);
        commit('setOrder', order.data)
      }
      if (state.selectedItem.moviment_id) {
        let url = `${endPoints.conciliation}/period_conciliation_moviments/moviment/${state.selectedItem.moviment_id}`;
        let moviment = await axios.get(url);
        commit('setMoviment', moviment.data)
      }
      if (state.selectedItem.bank_moviment_id) {
        let url = `${endPoints.conciliation}/period_conciliation_bank_moviments/bank_moviment/${state.selectedItem.bank_moviment_id}`;
        let bank_moviment = await axios.get(url);
        commit('setBankMoviment', bank_moviment.data)
      }
      if (state.selectedItem.annotated_id) {
        let url = `${endPoints.conciliation}/period_conciliation_annotated/annotated/${state.selectedItem.annotated_id}`;
        let annotated = await axios.get(url);
        commit('setAnnotated', annotated.data)
      }
      if (state.selectedItem.closure_id) {
        let url = `${endPoints.conciliation}/period_conciliation_closures/closure/${state.selectedItem.closure_id}`;
        let closure = await axios.get(url);
        commit('setClosure', closure.data)
      }
      if (state.selectedItem.transaction_id) {
        let url = `${endPoints.conciliation}/period_conciliation_transactions/transaction/${state.selectedItem.transaction_id}`;
        let transaction = await axios.get(url);
        if (transaction.data.type == 0) {
          commit('setCredit', true)
        }
        commit('setTransaction', transaction.data)
      }

    },
    getItems: async ({ commit, state, rootGetters }) => {
      let period = rootGetters['period_conciliation/getPeriodSelected']
      if (period == 0 || period == null || period == undefined || period == "") {
        period = location.href.split('period_conciliation/')[1]
      }
      let filter = state.filter;
      let url = `${endPoints.conciliation}/period_conciliation_orders/${period}?page=${state.pagination.page}&itemsPerPage=${state.pagination.itemsPerPage}`;

      Object.keys(filter).forEach(function (field) {
        if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
          commit('setFiltered', { filtered: true })

          if (field == "end" || field == "start") {
            var e = new Date(filter[field])
            url += "&" + field + "=" + formatDate(e) + " " + e.toLocaleTimeString()
          } else {
            url += "&" + field + "=" + filter[field]
          }
        }
      });

      if (
        state.pagination &&
        state.pagination.sortBy &&
        state.pagination.sortBy.length > 0
      ) {
        url +=
          "&sort_by=" +
          (state.pagination.sortBy[0] === "name"
            ? "id"
            : state.pagination.sortBy[0]);
        url += "&sort_desc=" + state.pagination.sortDesc[0];
      }

      commit("showLoading", { show: true });

      try {
        let items = await axios.get(url);
        commit("setItems", { items: items.data.data });
        commit("setTotalItems", { total: items.data.total });
      } catch (error) {
        commit(
          "showNotification",
          defaultErrorNotification(error?.response?.data?.message)
        );
      }
      commit("showLoading", { show: false });
    },
    deleteItem: async ({ commit, dispatch, state }) => {
      commit("showLoading", { show: true });
      try {
        await axios.delete(`${endPoints.conciliation}/period_conciliation/${state.selectedItem.id}`);

        commit("showLoading", { show: false });
        commit("showDeleteDialog", { show: false });
        commit("setSelectedItem", {});
        commit(
          "showNotification",
          defaultSuccessNotification("Periodo de conciliação removido com sucesso")
        );

        return dispatch("getItems");
      } catch (error) {
        commit(
          "showNotification",
          defaultErrorNotification(error?.response?.data?.message)
        );
        commit("showLoading", { show: false });
      }
    },
  },
  getters: {
    getField,
  },
};
