import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../../config/endPoints';

import _ from 'lodash'

const defaultItem = {
    id_instance: null,
    value: 0,
    start: '',
    end: '',
    club: '',
    file: null
};

const defaultFilter = {
    type: '',
    value: '',
    id_pppoker: '',
    start_date: '',
    start_time: '',
    end_date: '',
    end_time: '',
};


export default {
    namespaced: true,
    state: {
        actionFilter: false,
        dialog: false,
        dialogFilter: false,
        excelItems: [],
        editedItem: {},
        items: [],
        check_items: [],
        loading: false,
        filter: {
            type: '',
            value: '',
            id_pppoker: '',
            start_date: '',
            start_time: '',
            end_date: '',
            end_time: ''
        },
        filterWhenOpened: {
            type: '',
            value: '',
            id_pppoker: '',
            start_date: '',
            start_time: '',
            end_date: '',
            end_time: ''
        },
        filtered: false,
        notificationMessage: '',
        notificationType: '',
        overlay: false,
        orderByTypes: [
            {id: 1, description: 'Não Compativeis'},
            {id: 2, description: 'Data'}
        ],
        paginationMoviments: {
            page: 1, 
            itemsPerPage: 15
        },
        showNotification: false,
        totalItems: 0,
        totalCheckItems: 0,
        file: ''
    },
    mutations: {
        add: (state) => {
            state.editedItem = Object.assign({}, defaultItem)
            state.dialog = true
            state.mode = "ADD"
        },
        closeDialog: (state) => {
            state.editedIndex = -1
            state.errors = [];
            state.dialog = false
            state.mode = "LIST"
        },
        onlyCloseDialog: (state) => {
            state.dialog = false
        },
        loading: (state, payload) => state.loading = payload.show,
        overlay: (state, payload) => {
            state.overlay = payload.show
        },
        setExcelItems: (state, payload) => {
            state.excelItems = payload.excelItems
        },
        
        setFilter: (state, payload) => {
            state.filter = payload.filter
        },
        setFiltered: (state, payload) => {
            state.filtered = payload.filtered
        },
        setFilterWhenOpened: (state) => {
            state.filterWhenOpened = _.clone(state.filter)
        },
        setTotalItems: (state, total) => {
            console.log("Total: ", total)
            state.totalItems = total
        },
        setItems: (state, payload) => {
            state.items = payload.items
        },
        setCheckItems: (state, payload) => {
            state.check_items = payload.items
        },
        setTotalCheckItems: (state, total) => {
            state.totalCheckItems = total
        },
        setFile: (state, payload) => state.file = payload.file,
        showFilter: (state, payload) =>  { 
            state.dialogFilter = payload.show 
            state.actionFilter = payload.actionFilter

            if(payload.show) {
                state.filterWhenOpened = _.clone(state.filter)
            }

            if(!payload.actionFilter) {
                state.filter = _.clone(state.filterWhenOpened)
            }
        },
        
        showNotification: (state, payload) => {
            state.showNotification = payload.show,
                state.notificationMessage = payload.message,
                state.notificationType = payload.type
        },
        updateField,
    },
    getters: {
        getField,
    },
    actions: {
        clearFilter: ({ commit }) => {
            commit('setFilter', { filter: Object.assign({}, defaultFilter) })
            commit('setFiltered', { filtered: false })
            commit('showFilter', { show: false, actionFilter: true })
            commit('overlay', { show: true })

            setTimeout(function() {
                commit('overlay', { show: false })
            }, 500);
        },
        closeDeleteDialog: ({ commit }) => {
            commit('setDialogDelete', false)
            commit('setItemToDelete', {})
        },
        getItemsNoPage: ({ commit, state }) => {
            commit('overlay', { show: true })
            let url = endPoints.financial_check;
            url += '?page=' + '1' + '&' + 'itemsPerPage=' + '99999';
            url += `&date_start=${state.editedItem.start}&date_end=${state.editedItem.end}&id_instance=${state.editedItem.club}`

            let formData = new FormData();
            formData.append('file', state.editedItem.file);

            return axios.post(url, formData).then(items => {
                commit('overlay', { show: false })
                commit('setTotalItems', items.data.data.length)
                commit('setItems', { items: items.data.data })

                let notification = {
                    show: true,
                    message: "Conferência realizada com sucesso.",
                    type: "success"
                }
                commit('closeDialog')
                commit('showNotification', notification)

                return items.data.data_moviments
            }).catch((error) => {
                console.log(error)
                commit('overlay', { show: false })
            });
        },
        updateMoviment: ({ commit, state }, payload) => {
            commit('overlay', { show: true })
            let url = endPoints.financial_check;
            url += "/" + payload.code
            url += `?date_start=${state.editedItem.start}&date_end=${state.editedItem.end}&id_instance=${state.editedItem.club}`

            let nullRows = state.items.filter(function(item){return item.value === 0}).map(function(item){return item.sort_date})
            url += "&null_rows=" + nullRows

            return axios.put(url).then(items => {
                commit('overlay', { show: false })
                commit('setTotalItems', items.data.total_moviments)
                commit('setItems', { items: items.data.data_moviments })

                let notification = {
                    show: true,
                    message: "Conferência atualizada com sucesso.",
                    type: "success"
                }
                commit('showNotification', notification)

                return items.data.data_moviments
            }).catch(() => {
                commit('overlay', { show: false })
            });
        },
        validateDateFields({ state }, payload) {
            if(payload.startDate != "" || payload.endDate != "") {
              let auxSplitDate = []
              let startDate = ''
              let endDate = ''
  
              if(payload.startDate === "") {
                startDate = _.minBy(state.items, function(item) { return item.sort_date; });
                startDate = startDate.sort_date
              } else {
                if(payload.startDate.indexOf('-') !== -1) {
                    startDate = payload.startDate
                    let startTime = payload.startTime === "" || _.isUndefined(payload.startTime) ? "00:00:00" : payload.startTime
                    startDate = startDate + " " + startTime
                } else {
                    auxSplitDate = payload.startDate.split("/")
                    let startTime = payload.startTime === "" || _.isUndefined(payload.startTime) ? "00:00:00" : payload.startTime
                    startDate = '' + auxSplitDate[2] + '-' + auxSplitDate[1] + '-' + auxSplitDate[0] + ' ' +  startTime
                }
              }
  
              if(payload.endDate === "") {
                endDate = _.maxBy(state.items, function(item) { return item.sort_date; });
                endDate = endDate.sort_date
              } else {
                if(payload.endDate.indexOf('-') !== -1) {
                    endDate = payload.endDate
                    let endTime = payload.endTime === "" || _.isUndefined(payload.endTime) ? "23:59:59" : payload.endTime
                    endDate = endDate + " " + endTime
                } else {
                    auxSplitDate = payload.endDate.split("/")
                    let endTime = payload.endTime === "" || _.isUndefined(payload.endTime) ? "23:59:59" : payload.endTime
                    endDate = '' + auxSplitDate[2] + '-' + auxSplitDate[1] + '-' + auxSplitDate[0] + ' ' +  endTime
                }
              } 
  
              return { 
                isValid: endDate > startDate,
                startDate: startDate,
                endDate: endDate
              }
            } 
  
            return {
              isValid: false,
              startDate: '',
              endDate: ''
            }
        }
    }
}