import axios from 'axios';
import endPoints from '../../config/endPoints';
import { getField, updateField } from 'vuex-map-fields';

const defaultItem = {
    name: '',
    icon: '',
    parent: null,
    level: '',
    order: null,
    profile_father: null,
    link: ''
}

export default {
    namespaced: true,
    state: {
        menuSelected: {},
        open: [],
        active: [],
        menus: [],
        menusRelation: [],
        dialog: false,
        dialogDelete: false,
        dialogRelation: false,
        editedItem: {},
        item: {},
        childrens: [],
        itemToDelete: {},
        loading: false,
        mode: 'LIST',
        notificationType: '',
        notificationMessage: '',
        showNotification: ''
    },
    mutations: {
        add: (state, payload) => {
            state.editedItem = Object.assign({}, defaultItem)
            if (payload.item) {
                state.item = payload.item
                state.editedItem.level = 2
            } else {
                state.editedItem.level = 1
            }
            state.dialog = true
            state.mode = "ADD"
        },
        edit: (state, payload) => {
            state.editedItem = Object.assign({}, payload.item)
            state.dialog = true
            state.mode = "EDIT"
        },
        setDialogRelation: (state, payload) => {
            if (payload.show == true) {
                state.menusRelation = payload.menusRelation
                state.menuSelected = payload.menuSelected
                state.dialogRelation = true
            } else {
                state.menusRelation = []
                state.menuSelected = {}
                state.dialogRelation = false                
            }
        },
        closeDialog: (state) => {
            state.item = {}
            state.editedItem = {}
            state.dialog = false
        },
        loading: (state, payload) => {
            state.loading = payload.show
        },
        setItemToDelete: (state, item) => state.itemToDelete = item,
        setDialogDelete: (state, show) => state.dialogDelete = show,
        showNotification: (state, payload) => {
            state.showNotification = payload.show,
                state.notificationMessage = payload.message,
                state.notificationType = payload.type
        },
        setMenus: (state, payload) => {
            state.menus = payload.menus
        },
        setOpen: (state, payload) => {
            state.open = payload.open
        },
        setActive: (state, payload) => {
            state.active = payload.active
        },
        updateField 
    },
    getters: {
        getField
    },
    actions: {
        changeOrderUp: ({ commit, dispatch }, payload) => {
            let url = endPoints.menus + "/change_order_up/" + payload.item.id;
            commit('loading', { show: false })
            axios.put(url, payload.item).then(() => {
                let notification = {
                    show: true,
                    message: "Ordem alterada com sucesso",
                    type: "success"
                }
                commit('loading', { show: false })
                commit('showNotification', notification)
                dispatch('getMenus', {id_profile: 0})
            });
        },
        changeOrderDown: ({ commit, dispatch }, payload) => {
            let url = endPoints.menus + "/change_order_down/" + payload.item.id;
            commit('loading', { show: false })
            axios.put(url, payload.item).then(() => {
                let notification = {
                    show: true,
                    message: "Ordem alterada com sucesso",
                    type: "success"
                }
                commit('loading', { show: false })
                commit('showNotification', notification)
                dispatch('getMenus', {id_profile: 0})
            });
        },
        getMenus: ({ commit }, payload) => {
            let url = endPoints.menus + '/menus/' + payload.id_profile ;

            commit('loading', { show: true })
            axios.get(url).then(menu => {
                let at_menus = []
                if (menu.data != null) {
                    at_menus = menu.data
                }
                at_menus.forEach((m) => {
                    if(m.has_chield) {
                        m.children = []
                    }
                })
                commit('setMenus', { menus: at_menus })
                commit('loading', { show: false })
            });
        },
        viewRelation: ({commit}, payload) => {           
            let url = endPoints.menus + '/profiles_relation/' + payload.item.id;
            axios.get(url).then(items => {
                console.log(items)
                commit('setDialogRelation', { show: true, menusRelation: items.data, menuSelected: payload.item })
            })
        },
        getChildrens: ( _, payload) => {           
            let url = endPoints.menus + '/childrens';
            url += '?profileFather=' + payload.profile_father + '&' + 'id=' + payload.id;
            axios.get(url).then(res => {
                if(res.data && res.data.length > 0) {
                    let data = res.data
                    data.forEach((d) => {
                        if(d.has_chield) {
                            d.children = []
                        }
                    })
                    payload.children = data
                } else {
                    delete payload.children
                }
            })
            .catch(() => {
                delete payload.children
            })
        },
        deleteDialog: ({ commit }, payload) => {
            commit('setDialogDelete', payload.show)
            commit('setItemToDelete', payload.item)
        },
        deleteItem: ({ commit, dispatch, state }) => {
            let url = endPoints.menus + '/';
            
            url += state.itemToDelete.id

            let count = -1
            let correct = 0
            state.menus.forEach( fathers => {
              count += 1
              if (fathers.id == state.itemToDelete.parent) {
                correct = count
              }
            });

            axios.delete(url).then(() => {
                let notification = {
                    show: true,
                    message: "Menu excluido com sucesso",
                    type: "success"
                }
                commit('showNotification', notification)
                dispatch('deleteDialog', { show: false, item: {} })
                dispatch('getMenus', { id_profile: 0 })
                dispatch('getChildrens', state.menus[correct])
            });

        },
        saveMenus: ({ commit, dispatch, state }) => {
            let url = endPoints.menus;
            let func = ''
            let data = state.editedItem

            if (state.editedItem.level == 2 && state.mode == 'ADD') {
                
                state.editedItem.parent = state.item.id

                let count = 0
                state.menus.forEach(children => {
                    if (children.parent == state.item.id) {
                        count += 1 
                    }
                });
                state.editedItem.order = count

                func = axios.post;
            } else {
                if (state.mode == 'ADD') {
                    func = axios.post;
                } else {
                    if (state.editedItem.level == 2) {
                        let item = state.menus.find((a) => a._id == state.editedItem.children_of)
                        if (item) {
                            item.childrens = state.editedItem
                        } else {
                            return
                        }
                    } 
                    url += '/update/' + data.id
                    func = axios.put;
                }
            }
            if (state.editedItem) {
                func(url, data).then(() => {
                    dispatch('getMenus', { id_profile: 0 })
                    let notification = {
                        show: true,
                        message: "Mensagem salva com sucesso",
                        type: "success"
                    }
                    commit('showNotification', notification)
                    commit('closeDialog')
                });
            }
            error => {
                let notification = {
                    show: true,
                    message: error.response.data.message,
                    type: "error"
                }
                commit('showNotification', notification)
                commit('showOverlay', { show: false })
            }
        },
        saveMenu: ({ dispatch, commit, state }, payload) => {
            var url = endPoints.profiles + '/menu';
            axios.post(url, payload).then(
                () => {
                let notification = {
                    show: true,
                    message: "Adicionado com sucesso",
                    type: "success"
                }
                commit('showNotification', notification)
                dispatch('viewRelation', { item: state.menuSelected } )
                },
                error => {
                    let notification = {
                        show: true,
                        message: error.response.data.message,
                        type: "error"
                    }
                    commit('showNotification', notification)
                }
            );
        },
        removeMenu: ({ dispatch, commit, state }, payload) => {
            var url = endPoints.profiles + '/deleteMenu/' + payload.id_relation;
            axios.delete(url).then(
                () => {
                    let notification = {
                        show: true,
                        message: "Removido com sucesso",
                        type: "success"
                    }
                    commit('showNotification', notification)
                    dispatch('viewRelation', { item: state.menuSelected } )
                },
                error => {
                    let notification = {
                        show: true,
                        message: error.response.data.message,
                        type: "error"
                    }
                    commit('showNotification', notification)
                }
            );
        },
    }
}