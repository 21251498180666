import axios from 'axios';
import endPoints from '../../../config/endPoints';
import { getField, updateField } from 'vuex-map-fields';
import b64 from '../../../util/Base64'

const defaultItem = {
    id: '',
    color: 'info',
    name: '',
    content: '',
    contact: '',
    image: '',
    level: '',
    type: 1,
    childrens: []
}

export default {
    namespaced: true,
    state: {
        automatic_messages: [],
        dialog: false,
        dialogDelete: false,
        editedItem: {},
        item: {},
        itemToDelete: {},
        loading: false,
        mode: 'LIST',
        notificationType: '',
        notificationMessage: '',
        showNotification: ''
    },
    mutations: {
        add: (state, payload) => {
            state.editedItem = Object.assign({}, defaultItem)
            if (payload.item) {
                state.item = payload.item
                state.editedItem.level = 2
            } else {
                state.editedItem.level = 1
            }
            state.dialog = true
            state.mode = "ADD"
        },
        edit: (state, payload) => {
            state.editedItem = Object.assign({}, payload.item)
            state.dialog = true
            state.mode = "EDIT"
        },
        closeDialog: (state) => {
            state.item = {}
            state.editedItem = {}
            state.dialog = false
        },
        loading: (state, payload) => {
            state.loading = payload.show
        },
        setItemToDelete: (state, item) => state.itemToDelete = item,
        setDialogDelete: (state, show) => state.dialogDelete = show,
        showNotification: (state, payload) => {
            state.showNotification = payload.show,
                state.notificationMessage = payload.message,
                state.notificationType = payload.type
        },
        setAutomaticMessages: (state, payload) => {
            state.automatic_messages = payload.messages
        },
        updateField
    },
    getters: {
        getField
    },
    actions: {
        changeOrder: ({ commit, dispatch }, payload) => {
            let url = endPoints.automatic_message + "/change_order/" + payload.type;
            commit('loading', { show: false })
            axios.put(url, payload.item).then(() => {
                let notification = {
                    show: true,
                    message: "Ordem alterada com sucesso",
                    type: "success"
                }
                commit('loading', { show: false })
                commit('showNotification', notification)
                dispatch('getAutomaticMessages')
            });
        },
        getAutomaticMessages: ({ commit }, payload) => {
            let url = endPoints.automatic_message;

            if (payload && payload.type) {
                url += '?type=' + payload.type
            }

            if(payload && payload.filter) {
                url += "&content=" + payload.filter
            }

            commit('loading', { show: true })
            axios.get(url).then(messages => {
                let at_messages = []
                if (messages.data != null) {
                    at_messages = messages.data
                }
                commit('setAutomaticMessages', { messages: at_messages })
                commit('loading', { show: false })
            });
        },
        deleteDialog: ({ commit }, payload) => {
            commit('setDialogDelete', payload.show)
            commit('setItemToDelete', payload.item)
        },
        deleteItem: ({ commit, dispatch, state }) => {

            let url = endPoints.automatic_message + '/';

            if (state.itemToDelete.level == 1) {
                url += state.itemToDelete._id
                axios.delete(url).then(() => {
                    let notification = {
                        show: true,
                        message: "Mensagem excluida com sucesso",
                        type: "success"
                    }
                    commit('showNotification', notification)
                    dispatch('deleteDialog', { show: false, item: {} })
                    dispatch('getAutomaticMessages')
                });
            } else {
                url += 'childrens'
                let removeChildren = state.automatic_messages.find((a) => a._id == state.itemToDelete.children_of)
                if (removeChildren) {
                    removeChildren.childrens.splice(state.itemToDelete.index, 1)

                    axios.put(url, removeChildren).then(() => {
                        let notification = {
                            show: true,
                            message: "Mensagem excluida com sucesso",
                            type: "success"
                        }
                        commit('showNotification', notification)
                        dispatch('deleteDialog', { show: false, item: {} })
                        dispatch('getAutomaticMessages')
                    });
                }

            }
        },
        saveMessage: ({ commit, dispatch, state }) => {
            var save = {}
            let url = endPoints.automatic_message;
            let func = ''

            if (state.editedItem.level == 2 && state.mode == 'ADD') {
                url += '/childrens'
                state.editedItem.order = state.item.childrens.length
                state.item.childrens.push(state.editedItem)
                save = state.item
                func = axios.put;
            } else {
                if (state.mode == 'ADD') {
                    save = state.editedItem
                    func = axios.post;
                } else {
                    if (state.editedItem.level == 2) {
                        let item = state.automatic_messages.find((a) => a._id == state.editedItem.children_of)
                        if (item) {
                            item.childrens[state.editedItem.index] = state.editedItem
                            save = item
                        } else {
                            return
                        }
                    } else {
                        save = state.editedItem
                    }
                    func = axios.put;
                }
            }
            if (state.editedItem.image && typeof state.editedItem.image != "string") {
                b64(state.editedItem.image).then((fileBase64) => {
                    state.editedItem.image = fileBase64
                    func(url, save).then(() => {
                        let notification = {
                            show: true,
                            message: "Mensagem salva com sucesso",
                            type: "success"
                        }
                        commit('showNotification', notification)
                        commit('deleteDialog', { show: false, item: {} })
                        dispatch('getAutomaticMessages')
                    });
                })
            } else {
                if (state.item) {
                    func(url, save).then(() => {
                        let notification = {
                            show: true,
                            message: "Mensagem salva com sucesso",
                            type: "success"
                        }
                        commit('showNotification', notification)
                        commit('closeDialog')
                        dispatch('getAutomaticMessages')
                    });
                }
            }

        }
    }
}