<template>
  <div>
    <v-container grid-list-md>
      <v-layout wrap>
          <v-flex sm12 md6 xs6 xl6>
              <v-card class="elevation-10" >
                  <v-card-title class="text-center justify-center">Rake</v-card-title>
                  <GChart
                      type="LineChart"
                      :data="chartRake"
                      :options="optionsPlayers"
                      :events="chartEventsRake"    
                      :resizeDebounce="500"
                      ref="chartRake"
                  />
              </v-card>
          </v-flex>
          <v-flex sm12 md6 xs6 xl6>
              <v-card class="elevation-10" >
                  <v-card-title class="text-center justify-center">Rakeback</v-card-title>
                  <GChart
                      type="LineChart"
                      :data="chartRakeback"
                      :options="optionsPlayers"
                      :events="chartEventsRakeback"                      
                      :resizeDebounce="500"
                      ref="chartRakeback"
                  />
              </v-card>
          </v-flex>
          <v-flex sm12 md6 xs6 xl6>
              <v-card class="elevation-10" >
                  <v-card-title class="text-center justify-center">Jogadores Ativos</v-card-title>
                  <GChart
                      type="LineChart"
                      :data="chartActivePlayers"
                      :options="optionsPlayers"
                      :resizeDebounce="500"
                      :events="chartEventsPlayer"
                      ref="chartPlayers"
                  />
              </v-card>
          </v-flex>
          <v-flex sm12 md6 xs6 xl6>
              <v-card class="elevation-10" >
                  <v-card-title class="text-center justify-center">Agentes Ativos</v-card-title>
                  <GChart
                      type="LineChart"
                      :data="chartActiveAgents"
                      :options="optionsPlayers"
                      :events="chartEventsAgents"
                      :resizeDebounce="500"
                      ref="chartAgents"
                  />
              </v-card>
          </v-flex>
          <v-flex sm12 md6 xs6 xl6>
            <v-card-title class="text-center justify-center">Top Agente da Semana</v-card-title>
            <v-data-table
              :headers="headers_top_agents" 
              :items="top_agents_week"
              disable-pagination
              hide-default-footer
              class="elevation-10 table-margin table-at"
            >
              <template v-slot:[`item.total_rake`]="{ item }">
                <span>{{ (item.total_rake) ? item.total_rake.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0.00' }}</span>
              </template>
              <template v-slot:[`item.rakeback`]="{ item }">
                <span>{{ (item.rakeback) ? item.rakeback.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0.00' }}</span>
              </template>
              <template v-slot:[`item.total_rake_sub_agents`]="{ item }">
                <span>{{ (item.total_rake_sub_agents) ? item.total_rake_sub_agents.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0.00' }}</span>
              </template>
              <template v-slot:[`item.total_rakeback_sub_agents`]="{ item }">
                <span>{{ (item.total_rakeback_sub_agents) ? item.total_rakeback_sub_agents.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0.00' }}</span>
              </template>
            </v-data-table>
          </v-flex>
          <v-flex sm12 md6 xs6 xl6>
            <v-card-title class="text-center justify-center">Top Agente do Mês</v-card-title>
            <v-data-table
              :headers="headers_top_agents" 
              :items="top_agents_month"
              disable-pagination
              hide-default-footer
              class="elevation-10 table-margin table-at"
            >
              <template v-slot:[`item.total_rake`]="{ item }">
                <span>{{ (item.total_rake) ? item.total_rake.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0.00' }}</span>
              </template>
              <template v-slot:[`item.rakeback`]="{ item }">
                <span>{{ (item.rakeback) ? item.rakeback.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0.00' }}</span>
              </template>
              <template v-slot:[`item.total_rake_sub_agents`]="{ item }">
                <span>{{ (item.total_rake_sub_agents) ? item.total_rake_sub_agents.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0.00' }}</span>
              </template>
              <template v-slot:[`item.total_rakeback_sub_agents`]="{ item }">
                <span>{{ (item.total_rakeback_sub_agents) ? item.total_rakeback_sub_agents.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0.00' }}</span>
              </template>
            </v-data-table>
          </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  created() {
    this.getRakesAgent()
    this.getTopAgents()
  },
  computed : {
    ...mapState("login", ["userData"]),
    ...mapState("rake_dashboard", [
      "qt_active_agents",
      "qt_active_players",
      "rakes_agent",
      "rakeback_agent",
      "top_agents_week",
      "top_agents_month"
    ]),
    chartRake() {
      return this.rakes_agent
    },
    chartRakeback() {
      return this.rakeback_agent
    },
    chartActivePlayers() {
      return this.qt_active_players
    },
    optionsPlayers() {
      let defaultOptions = {
        legend: { 
          position: 'bottom',
        },
        vAxis: {
          title: 'Valor',
        },  
        series: {}
      }
      let serieVisible = {lineWidth: 2}
      let serieInvisible = {lineWidth: 0}
      if(this.qt_active_players[0]) {
        let headers = this.qt_active_players[0];
        for(let i = 1; i < headers.length; i++) {
          if(this.filter_player == -1) {
            defaultOptions.series[i - 1] = serieVisible;
          }
          else {
            if(this.filter_player == i) {
              defaultOptions.series[i - 1] = serieVisible;
            } else {
              defaultOptions.series[i - 1] = serieInvisible;
            }
          }
        }
      }
      return defaultOptions;
    },
    chartActiveAgents() {
      return this.qt_active_agents
    }
  },
  data () {
    return {
      chartEventsPlayer: {
        'select': () => {          
          console.log('to aquii')
          const table = this.$refs.chartPlayers.chartObject;
          let selection = table.getSelection();     

          if(selection.length > 0) {
            if(selection[0].column) this.filter_player = selection[0].column;
            else this.filter_player = -1
          } else this.filter_player = -1
        }
      },
      chartEventsAgents: {
        'select': () => {          
          const table = this.$refs.chartAgents.chartObject;
          let selection = table.getSelection();     

          if(selection.length > 0) {
            if(selection[0].column) this.filter_player = selection[0].column;
            else this.filter_player = -1
          } else this.filter_player = -1
        }
      },
      chartEventsRake: {
        'select': () => {          
          const table = this.$refs.chartRake.chartObject;
          let selection = table.getSelection();     
          if(selection.length > 0) {
            if(selection[0].column) this.filter_player = selection[0].column;
            else this.filter_player = -1
          } else this.filter_player = -1
        }
      },
      chartEventsRakeback: {
        'select': () => {          
          const table = this.$refs.chartRakeback.chartObject;
          let selection = table.getSelection();     
          if(selection.length > 0) {
            if(selection[0].column) this.filter_player = selection[0].column;
            else this.filter_player = -1
          } else this.filter_player = -1
        }
      }, 
      filter_player: -1,
/*       filter_agents: -1,
      filter_rake: -1,
      filter_rakeback: -1, */
      headers_rake: [
        {type: 'string', label: 'Data'},
        {type: 'number', label: 'Total Rake'},
        {type: 'number', label: 'Rakeback'},
      ],
      header_active_agents_players: [
        {type: 'string', label: 'Data'},
        {type: 'number', label: 'Jogadores Ativos'},
        {type: 'number', label: 'Agentes Ativos'},
      ],
      headers_top_agents: [
        { text: "Id Suprema", value: "id_pppoker" },
        { text: "Nick", value: "nick" },
        { text: "Rake", value: "total_rake" },
        { text: "Rakeback", value: "rakeback" },
        { text: "Rake Sub-Agentes", value: "total_rake_sub_agents" },
        { text: "Rakeback Sub-Agentes", value: "total_rakeback_sub_agents" },
      ],
      options_rake:  {
          chart: {
              title: 'Rake/Rakeback Gerado',
          },
          legend: { position: 'bottom'},
          vAxis: {
              title: 'Valor',
          },  
      },
      options_active_players:  {
          chart: {
              title: 'Jogadores/Agentes Ativos',
          },
          legend: { 
            position: 'bottom',
          },
          vAxis: {
              title: 'Valor',
          },  
      },
      tab: ''
    }
  },
  methods: {
    ...mapActions('rake_dashboard', ['getRakesAgent', 'getTopAgents']),
    showCommercialAdmin() {
      let roles = [93, 96, 97, 99]
      return roles.indexOf(this.userData.role) != -1
    },
  },
}
</script>