import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../../config/endPoints';

const defaultItem = {
    value: 0,
    start: '',
    end: '',
    bank: '',
    file: null,
};

export default {
    namespaced: true,
    state: {
        dialog: false,
        editedItem: {},
        items: [],
        loading: false,
        notificationMessage: '',
        notificationType: '',
        overlay: false,
        orderByTypes: [
            {id: 1, description: 'Valor'},
            {id: 2, description: 'Diferença'}
        ],
        showNotification: false,
        totalItems: 0,
        file: ''
    },
    mutations: {
        add: (state) => {
            state.editedItem = Object.assign({}, defaultItem)
            state.dialog = true
            state.mode = "ADD"
        },
        closeDialog: (state) => {
            state.editedIndex = -1
            state.errors = [];
            state.dialog = false
            state.mode = "LIST"
        },
        onlyCloseDialog: (state) => {
            state.dialog = false
        },
        loading: (state, payload) => state.loading = payload.show,
        overlay: (state, payload) => {
            state.overlay = payload.show
        },
        setTotalItems: (state, total) => {
            state.totalItems = total
        },
        setItems: (state, payload) => {
            state.items = payload.items
        },
        setFile: (state, payload) => state.file = payload.file,
        showNotification: (state, payload) => {
            state.showNotification = payload.show,
                state.notificationMessage = payload.message,
                state.notificationType = payload.type
        },
        updateField,
    },
    getters: {
        getField,
    },
    actions: {
        createFinancialConciliation: ({ commit, state }) => {
            commit('overlay', { show: true })
            let url = endPoints.financial_conciliation;
            url += '?page=' + '1' + '&' + 'itemsPerPage=' + '99999';
            url += `&date_start=${state.editedItem.start}&date_end=${state.editedItem.end}`
            url += `&bank=${state.editedItem.bank}`

            let formData = new FormData();
            formData.append('file', state.editedItem.file);
            window.state = state

            return axios.post(url, formData).then(item => {

                console.log(item.data.array)
                commit('overlay', { show: false })
                commit('setTotalItems', item.data.array.length)
                commit('setItems', { items: item.data.array })

                let notification = {
                    show: true,
                    message: "Conferência realizada com sucesso.",
                    type: "success"
                }
                commit('closeDialog')
                commit('showNotification', notification)
            }).catch((e) => {
                console.log(e)
                commit('overlay', { show: false })
            });
        },
        getNoUrl({ commit, state }) {
            commit('setItems', {items: state.items})

            let notification = {
                show: true,
                message: "Conferência atualizada",
                type: "success"
            }
            commit('showNotification', notification)
        }
    }
}