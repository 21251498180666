import { getField, updateField } from "vuex-map-fields";
import { defaultErrorNotification, defaultSuccessNotification } from "../../../../constants"
import axios from 'axios';
import endPoints from "../../../../config/endPoints";

let defaultClientIdApp = {
  player_id: null,
  platform: null,
  checked: false,
}

export default {
  namespaced: true,
  state: {
    loading: false,
    clientId: null,
    dialogDelete: false,
    client:{},
    agent:{},
    selectedItem: {},
    mode: "",
    showDialog: false,
    clientApps: [],
    clientIdApp: {
      player_id: null,
      platform: null,
      checked: false,
    },
    notification: {
      show: false,
      message: "",
      type: "",
    },
  },


    mutations: {
      link: (state, payload) => {
        state.selectedItem = Object.assign({}, payload);
        state.showDialog = true;
      },
      setDelete: (state, payload) => {
        state.agent = Object.assign({}, payload);
        state.dialogDelete = true;
      },
      setClientIdDelete: (state, payload) => {
        state.clientAppId = payload;
        console.log(state.clientAppId)
        console.log(payload)
        state.dialogDelete = true;
      },
      setDeleteDialog: (state, payload) => {
        state.dialogDelete = payload;
      },
      setClientId: (state, payload) => (state.clientId = payload),
      setAgent: (state, payload) => (state.agent = payload),
      setLoading: (state, payload) => (state.loading = payload),
      showNotification: (state, payload) => (state.notification = payload),
      setShowDialog: (state, payload) => {
        state.showDialog = payload;
      },
      
      
      setClientApps: (state, payload) => (state.clientApps = payload),
      setClientIdApp: (state, payload) => (state.clientIdApp = Object.assign({}, payload)),

      updateField,
    },

    getters: {
        getClientId: state => {
            return state.clientId
        },
        
        getField,
    },

    actions: {
      goBack: ({ dispatch }) => {
        dispatch('menu/openLink', '/clients', { root: true })
      },
      link: async ({dispatch, commit,state}, payload) => {
          commit('setLoading', true )

          let func = axios.put
          let url = endPoints.agents + "/agent/link/"+ payload.agent.id;
              
          try{
            if(payload.delete == true){
              payload.agent.client_id = null
            } else {
              payload.agent.client_id = payload.client.id
            }
            let response = await func(url, payload.agent);
              
          
            if (payload.delete == true){
              commit("setDeleteDialog", false)
              commit("agents/setFilter", {filter:{client_id: state.clientId}}, { root: true });
              
            } else {

              commit("agents/setFilter", {filter:{client_id: payload.client.id}}, { root: true });
            }
            
            dispatch("agents/getItemsByClient", {resetPage: true, filteredByClient: true}, { root: true });
            commit("setClientId", response.data.client_id)
            
            commit("setLoading", false);
            commit("showNotification", defaultSuccessNotification("Vinculação realizada com sucesso"));
          }
          catch (error) {
            commit("showNotification", defaultErrorNotification(error?.response?.data?.message));commit("setLoading", false);
          }
      },

      deleteLink: ({dispatch, state}) => {
        dispatch("link", {delete: true, agent: state.agent});
      },

      getClientIdApps: async ({commit, state}) => {
        commit('setLoading', true )

        let func = axios.get
        let url = endPoints.client_apps + "?client_id="+ state.clientId;
            
        try{
          let response = await func(url, state.clientIdApp);
          console.log(response.data.data)

          for(let app of response.data.data){ 
            app.iconCopy = "mdi-content-copy"
          }
          console.log(response.data.data)

          commit("setClientApps", response.data.data);
          console.log(state.clientApps)
          commit("setLoading", false);
        }
        catch (error) {
          commit("showNotification", defaultErrorNotification(error?.response?.data?.message));commit("setLoading", false);
        }
      },

      addClientApp: async ({commit, state, dispatch}) => {
        commit('setLoading', true )

        let func = axios.post
        let url = endPoints.client_apps;
        
        try{
        let date_created = new Date();
          console.log("state.clientIdApp")
          console.log(state.clientIdApp) 

          state.clientIdApp.player_id = parseFloat(state.clientIdApp.player_id)
          state.clientIdApp.client_id = parseFloat(state.clientId)
          state.clientIdApp.created_at = date_created.toISOString().substr(0, 10) + " " + date_created.toLocaleTimeString();

          
          console.log("state.clientIdApp")
          console.log(state.clientIdApp) 
          
          let response = await func(url, state.clientIdApp);

          
          dispatch("getClientIdApps", response);
          commit("setClientIdApp", defaultClientIdApp);
          commit("setLoading", false);
          commit("showNotification", defaultSuccessNotification("App Id adicionado com sucesso"));
        }
        catch (error) {
          commit("showNotification", defaultErrorNotification(error?.response?.data?.message));commit("setLoading", false);
        }
      },

      removeClientApp: async ({commit, state, dispatch}) => {
        commit('setLoading', true )

        let func = axios.delete
        let url = endPoints.client_apps + "/" +  state.clientAppId.id
        
        try{
          let response = await func(url);
          dispatch("getClientIdApps", response);
          commit("setClientIdApp", defaultClientIdApp);
          commit("setLoading", false);
          commit("showNotification", defaultSuccessNotification("App Id removido com sucesso"));
          commit("setDeleteDialog", false)
        }
        catch (error) {
          console.log(error?.response?.data?.message)
          commit("showNotification", defaultErrorNotification(error?.response?.data?.message));
          commit("setLoading", false);
        }
      },

      checkIdApp: async ({commit, state, dispatch}, payload) => {
        commit('setLoading', true )

        let func = axios.put
        let url = endPoints.client_apps + "/update/" + payload.id

        payload.checked = !payload.checked
        commit("setClientIdApp", payload)
        console.log(state.clientIdApp)
        
        try{
          let response = await func(url, state.clientIdApp);
          
          dispatch("getClientIdApps", response);
          commit("setLoading", false);
          commit("setClientIdApp", defaultClientIdApp);
          commit("showNotification", defaultSuccessNotification("App Id verificado com sucesso"));
        }
        catch (error) {
          console.log(error?.response?.data?.message)
          commit("showNotification", defaultErrorNotification(error?.response?.data?.message));commit("setLoading", false);
        }
      }

    },
  };
  