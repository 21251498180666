<template>
    <div>
        <v-card>
            <v-card-title>
                <span class="headline text-center justify-center">Informações</span>
            </v-card-title>

            <v-card-text>
                <v-form ref="form">
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12 sm6 md6>
                                <v-text-field
                                    required
                                    label="Data"
                                    :value="getDate(createdAt)"
                                    outlined
                                    readonly
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-text-field
                                    required
                                    label="Valor"
                                    :value="value ? parseFloat(value).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL',}) : 'R$ 0,00'"
                                    outlined
                                    readonly
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-text-field
                                    required
                                    label="Documento Solicitante"
                                    v-model="doc"
                                    outlined
                                    readonly
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-text-field
                                    required
                                    label="Nome Solicitante"
                                    v-model="name"
                                    outlined
                                    readonly
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-text-field
                                    required
                                    label="Slot"
                                    :value="`${slot} - ${slotLabel}`"
                                    outlined
                                    readonly
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-text-field
                                    required
                                    label="ID App"
                                    v-model="playerid"
                                    outlined
                                    readonly
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-text-field
                                    required
                                    label="Status"
                                    v-model="status"
                                    outlined
                                    readonly
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm12 md12>
                                <v-text-field
                                    required
                                    label="Motivo"
                                    v-model="reason"
                                    outlined
                                    readonly
                                ></v-text-field>
                            </v-flex>
                            <v-expansion-panels>
                                <v-expansion-panel>
                                    <v-expansion-panel-header>
                                        Cobrança
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-layout wrap>
                                            <v-flex xs12 sm6 md6>
                                                <v-text-field
                                                    required
                                                    label="Conta"
                                                    v-model="accountNickname"
                                                    outlined
                                                    readonly
                                                ></v-text-field>
                                            </v-flex>
                                            <v-flex xs12 sm6 md6>
                                                <v-text-field
                                                    required
                                                    label="ID Cobrança"
                                                    v-model="idCob"
                                                    outlined
                                                    readonly
                                                ></v-text-field>
                                            </v-flex>
                                        </v-layout>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                            <v-expansion-panels class="mt-3">
                                <v-expansion-panel>
                                    <v-expansion-panel-header>
                                        Pagamento
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-layout wrap>
                                            <v-flex xs12 sm6 md6>
                                                <v-text-field
                                                    required
                                                    label="ID Cobrança"
                                                    v-model="idCob"
                                                    outlined
                                                    readonly
                                                ></v-text-field>
                                            </v-flex>
                                            <v-flex xs12 sm6 md6>
                                                <v-text-field
                                                    required
                                                    label="Data Pagamento"
                                                    :value="getDate(datePay)"
                                                    outlined
                                                    readonly
                                                ></v-text-field>
                                            </v-flex>
                                            <v-flex xs12 sm6 md6>
                                                <v-text-field
                                                    required
                                                    label="Documento Pagador"
                                                    v-model="payerDocument"
                                                    outlined
                                                    readonly
                                                ></v-text-field>
                                            </v-flex>
                                            <v-flex xs12 sm6 md6>
                                                <v-text-field
                                                    required
                                                    label="Nome Pagador"
                                                    v-model="payerName"
                                                    outlined
                                                    readonly
                                                ></v-text-field>
                                            </v-flex>
                                            <v-flex xs12 sm12 md12>
                                                <v-text-field
                                                    required
                                                    label="EndToEndId"
                                                    v-model="endToEndId"
                                                    outlined
                                                    readonly
                                                ></v-text-field>
                                            </v-flex>
                                        </v-layout>
                                        
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-layout>
                    </v-container>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-col>
                    <v-btn text @click="showDialogInfo"
                        >Voltar</v-btn
                    >
                </v-col>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields' 
export default {
    name: 'AutoAttendanceDetail',
    computed: {
        ...mapFields('auto_attendance', [
            'editedItem.accountNickname',
            'editedItem.id',
            'editedItem.datePay',
            'editedItem.endToEndId',
            'editedItem.idCob',
            'editedItem.doc',
            'editedItem.createdAt',
            'editedItem.name',
            'editedItem.payerDocument',
            'editedItem.payerName',
            'editedItem.playerid',
            'editedItem.value',
            'editedItem.reason',
            'editedItem.status',
            'editedItem.slot',
            'editedItem.slotLabel'
        ])
    },
    created() {
    },
    data: () => ({
    }),
    methods: {
        ...mapMutations('auto_attendance', [
            'showDialogInfo'
        ]),
        getDate(date) {
            if (date) {
                var d = new Date(date)
                return d.toLocaleDateString() + ' ' + d.toLocaleTimeString()
            }
            return ''
        }
    },
}
</script>

<style></style>
