import { getField, updateField } from 'vuex-map-fields';
import axios from 'axios';
import endPoints from '../../../config/endPoints';

export default {
    namespaced: true,
    state: {
        dialogFilter: true,
        filter: {
            clubID: '',
        },
        filtered: false,
        items: [],
        loading: false,
        notificationMessage: '',
        notificationType: '',
        overlay: false,
        showNotification: false,
    },
    mutations: {
        setItems: (state, payload) => {
            state.items = payload
        },
        clearFilter: (state) => {
            state.filter = {
                clubID: '',
            }
        },
        overlay: (state, payload) => {
            state.overlay = payload.show
        },
        showLoading: (state, payload) => {
            state.loading = payload.show
        },
        showFilter: (state, payload) => {
            state.dialogFilter = payload.show
        },
        showNotification: (state, payload) => {
            state.showNotification = payload.show,
            state.notificationMessage = payload.message,
            state.notificationType = payload.type
        },
        updateField,
    },
    getters: {
        getField,
    },
    actions: {
        getItems({ commit, state }) {
            let url = endPoints.player_approval
            if(state.filter.clubID) {
                url += '?clubID=' + state.filter.clubID
            } else {
                commit('showNotification', { show: true, message: 'Selecione um clube', type: 'warning' })
                return 
            }
            commit('showLoading', { show: true })
            axios.get(url).then((response) => {
                commit('showLoading', { show: false })
                commit('setItems', response?.data?.data || [])
                commit('showFilter', { show: false })
            })  
        },
        approve({ commit, dispatch }, payload) {
            let url = endPoints.player_approval
            
            commit('overlay', { show: true })
            axios.post(url, payload.item).then(() => {
                commit('showNotification', { show: true, message: `Jogador ${payload.item.approve == 0 ? "reprovado" : "aprovado"} com sucesso`, type: 'success' })
                dispatch('getItems')
                commit('overlay', { show: false })
            }).catch(() => {
                commit('overlay', { show: false })
                commit('showNotification', { show: true, message: `Erro ao ${payload.item.approve == 0 ? "reprovar" : "aprovar"} jogador`, type: 'error' })
            })
        },
    }
}