<template>
    <div>
        <v-snackbar
            :color="notification.type"
            center
            top
            :timeout="2000"
            v-model="notification.show"
            >{{ notification.message }}</v-snackbar
        >
        <v-tabs v-model="tab" class="agent-data elevation-10">
            <v-tab>Ordens</v-tab>
            <v-tab>Transações</v-tab>
            <v-tab>Movimentações Sistema</v-tab>
            <v-tab>Movimentações Bancárias</v-tab>
            <v-tab>Anotados</v-tab>
            <v-tab>Fechamento</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="elevation-10">
            <v-tab-item><PeriodConciliationOrdersGrid/></v-tab-item>
            <v-tab-item><PeriodConciliationTransactionsGrid/></v-tab-item>
            <v-tab-item><PeriodConciliationMovimentsGrid/></v-tab-item>
            <v-tab-item><PeriodConciliationBankMovimentsGrid/></v-tab-item>
            <v-tab-item><PeriodConciliationAnnotatedGrid/></v-tab-item>
            <v-tab-item><PeriodConciliationClosuresGrid/></v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import PeriodConciliationAnnotatedGrid from "./period_conciliation_annotated/PeriodConciliationAnnotatedGrid";
import PeriodConciliationBankMovimentsGrid from "./period_conciliation_bank_moviments/PeriodConciliationBankMovimentsGrid";
import PeriodConciliationOrdersGrid from './period_conciliation_orders/PeriodConciliationOrdersGrid.vue';
import PeriodConciliationMovimentsGrid from './period_conciliation_moviments/PeriodConciliationMovimentsGrid.vue';
import PeriodConciliationTransactionsGrid from './period_conciliation_transactions/PeriodConciliationTransactionsGrid.vue';
import PeriodConciliationClosuresGrid from './period_conciliation_closures/PeriodConciliationClosuresGrid.vue';

export default {
    name: 'PeriodConciliationDetail',
    computed: {
        ...mapState('period_conciliation', [
            "notificationType",
            "notificationMessage",
            'notification',
            'showNotification'
        ]),
    },
    created() {},
    components: {
        PeriodConciliationAnnotatedGrid,
        PeriodConciliationBankMovimentsGrid,
        PeriodConciliationMovimentsGrid,
        PeriodConciliationOrdersGrid,
        PeriodConciliationTransactionsGrid,
        PeriodConciliationClosuresGrid,
    },
    methods: {},
    data: () => ({
        tab: '',
        tabs: []
    }),
}
</script>
