var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Solicitação de Anotado")])]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm6":"","md6":""}},[_c('v-datetime-picker',{directives:[{name:"validate",rawName:"v-validate",value:('required:Data,date_created'),expression:"'required:Data,date_created'"}],attrs:{"required":"","date-format":"dd/MM/yyyy","disabled":this.mode == 'VIEW',"time-picker-props":_vm.timeProps,"textFieldProps":{ outlined: true },"error-messages":_vm.errors.collect('date_created'),"data-vv-name":"date_created"},model:{value:(_vm.date_created),callback:function ($$v) {_vm.date_created=$$v},expression:"date_created"}},[_c('template',{slot:"dateIcon"},[_c('v-icon',[_vm._v("mdi-calendar")])],1),_c('template',{slot:"timeIcon"},[_c('v-icon',[_vm._v("mdi-clock")])],1)],2)],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","md6":""}},[_c('v-autocomplete',{directives:[{name:"validate",rawName:"v-validate",value:('required:CLiente,id_client'),expression:"'required:CLiente,id_client'"}],attrs:{"label":"Cliente","clearable":true,"outlined":"","items":_vm.clients,"disabled":this.screen == 'messages' ||
                                    this.mode == 'VIEW',"readonly":this.mode == 'VIEW' ||
                                    this.screen == 'messages',"item-text":"name","item-value":"id","clear-icon":"mdi-close","error-messages":_vm.errors.collect('id_client'),"data-vv-name":"id_client"},model:{value:(_vm.id_client),callback:function ($$v) {_vm.id_client=$$v},expression:"id_client"}})],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","md6":""}},[_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:('required:Valor,value'),expression:"'required:Valor,value'"}],attrs:{"required":"","label":"Valor *","prefix":"R$","clearable":this.mode == 'ADD' ||
                                    this.mode == 'EDIT',"readonly":this.mode == 'VIEW',"error-messages":_vm.errors.collect('value'),"data-vv-name":"value","outlined":""},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","md6":""}},[_c('v-autocomplete',{directives:[{name:"validate",rawName:"v-validate",value:('required:Clubes,id_slot'),expression:"'required:Clubes,id_slot'"}],attrs:{"label":"Clube","clearable":true,"outlined":"","items":_vm.slots,"readonly":this.mode == 'VIEW',"disabled":this.mode == 'VIEW',"item-text":"name","item-value":"id","clear-icon":"mdi-close","error-messages":_vm.errors.collect('id_slot'),"data-vv-name":"id_slot"},model:{value:(_vm.id_slot),callback:function ($$v) {_vm.id_slot=$$v},expression:"id_slot"}})],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","md6":""}},[_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:('required:ID Aplicativo,id_app'),expression:"'required:ID Aplicativo,id_app'"}],attrs:{"required":"","label":"ID Aplicativo *","clearable":this.mode == 'ADD' ||
                                    this.mode == 'EDIT',"readonly":this.mode == 'VIEW',"error-messages":_vm.errors.collect('id_app'),"data-vv-name":"id_app","outlined":""},model:{value:(_vm.id_app),callback:function ($$v) {_vm.id_app=$$v},expression:"id_app"}})],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","md6":""}},[_c('v-select',{directives:[{name:"validate",rawName:"v-validate",value:('required:Tipo,type'),expression:"'required:Tipo,type'"}],attrs:{"label":"Tipo *","items":_vm.possible_types,"error-messages":_vm.errors.collect('type'),"item-text":"description","readonly":this.mode == 'VIEW',"item-value":"id","data-vv-name":"type","outlined":""},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1),(_vm.type == 1)?_c('v-flex',{attrs:{"xs12":"","sm6":"","md6":""}},[_c('v-select',{directives:[{name:"validate",rawName:"v-validate",value:(
                                    'required:Tipo de Pagamento,payment_type'
                                ),expression:"\n                                    'required:Tipo de Pagamento,payment_type'\n                                "}],attrs:{"label":"Tipo de Pagamento *","items":_vm.possible_payment_types,"item-text":"description","readonly":this.mode == 'VIEW',"item-value":"id","error-messages":_vm.errors.collect('payment_type'),"outlined":""},model:{value:(_vm.payment_type),callback:function ($$v) {_vm.payment_type=$$v},expression:"payment_type"}})],1):_vm._e(),(this.mode == 'VIEW' && this.pix_id)?_c('v-expansion-panels',[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v("Pix")]),_c('v-expansion-panel-content',[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm4":"","md4":""}},[_c('v-text-field',{attrs:{"required":"","label":"CPF/CNPJ","readonly":true,"outlined":"","value":_vm.pixRelated.client_key}})],1),_c('v-flex',{attrs:{"xs12":"","sm4":"","md4":""}},[_c('v-text-field',{attrs:{"required":"","label":"Data","readonly":true,"outlined":"","value":_vm.getDate(_vm.pixRelated.date)}})],1),_c('v-flex',{attrs:{"xs12":"","sm4":"","md4":""}},[_c('v-text-field',{attrs:{"label":"Valor","readonly":true,"outlined":"","value":_vm.pixRelated.value.toLocaleString(
                                                        'pt-BR',
                                                        {
                                                            style: 'currency',
                                                            currency: 'BRL',
                                                        },
                                                    )}})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":""}},[_c('v-text-field',{attrs:{"label":"Identificador","readonly":true,"outlined":"","value":_vm.pixRelated.endToEndId
                                                        ? _vm.pixRelated.endToEndId
                                                        : _vm.pixRelated.txId}})],1)],1)],1)],1)],1):_vm._e()],1)],1)],1)],1),_c('v-card-actions',[_c('v-col',[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.closeDialog}},[_vm._v(_vm._s(_vm.mode == 'VIEW' ? 'Voltar' : 'Cancelar'))])],1),_c('v-col',{staticClass:"text-right"},[(_vm.mode == 'EDIT' || _vm.mode == 'ADD')?_c('v-btn',{attrs:{"text":"","type":"submit","right":""},on:{"click":_vm.saveAnnotated}},[_vm._v("Salvar")]):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }