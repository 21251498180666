<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline text-center justify-center">Filtro</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form">
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm12 md12>
                <v-select
                  :clearable="true"
                  clear-icon="mdi-close"
                  label="Clube"
                  :items="instances"
                  v-model="club"
                  item-text="name"
                  item-value="id_instance"
                  outlined
                ></v-select>
              </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-datetime-picker
                    v-model="start"
                    date-format="dd/MM/yyyy"
                    clearText="Limpar"
                    okText="Confirmar"
                    :time-picker-props="timeProps"
                    label="De *"
                    :textFieldProps="{outlined: true}"
                  >
                    <template slot="dateIcon">
                      <v-icon>mdi-calendar</v-icon>
                    </template>
                    <template slot="timeIcon">
                      <v-icon>mdi-clock</v-icon>
                    </template>
                  </v-datetime-picker>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-datetime-picker
                    v-model="end"
                    date-format="dd/MM/yyyy"
                    clearText="Limpar"
                    okText="Confirmar"
                    label="Até *"
                    :time-picker-props="timeProps"
                    :textFieldProps="{outlined: true}"
                  >
                    <template slot="dateIcon">
                      <v-icon>mdi-calendar</v-icon>
                    </template>
                    <template slot="timeIcon">
                      <v-icon>mdi-clock</v-icon>
                    </template>
                  </v-datetime-picker>
                </v-flex>

              <v-flex xs12 sm12 md12>
                <v-select
                  clear-icon="mdi-close"
                  :clearable="true"
                  label="Status"
                  :items="possible_status"
                  v-model="status"
                  item-text="description"
                  item-value="id"
                  outlined
                ></v-select>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn text @click="showFilter({show: false})">Cancelar</v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn text type="submit" right @click="filter">Filtrar</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "DepositsFilter",
  computed: {
    ...mapState("instances", ["instances"]),
    ...mapFields("gains_loses", [
      "filter.club",
      "filter.start",
      "filter.end",
      "filter.status",
      // ...
    ]),
  },
  data: () => ({
    payment_types: [
      { id: 0, description: 'A vista' },
      { id: 1, description: 'Anotado' }
    ],
    possible_status: [
      { id: 0, description: "SOS Não Verificado" },
      { id: 1, description: "Normal" },
      { id: 2, description: "SOS Verificado" },
    ],
    timeProps: {
      format: "24hr",
    },
  }),
  methods: {
    ...mapMutations("gains_loses", ["showFilter"]),
    ...mapActions("gains_loses", ["getItems"]),
    filter() {
      this.getItems({ resetPage: true });
      this.showFilter({ show: false });
    },
  },
};
</script>

<style>
</style>