import axios from 'axios'
import { getField, updateField } from 'vuex-map-fields'
import endPoints from '../../../config/endPoints'

const defaultItem = {
  id: null,
  ag_group_id: null,
  id_agent: null,
  new_group_id: null,
}

// const defaultFilter = {
//       id: '',
//       ag_group_id: '',
//       id_agent: '',
// }

export default {
  namespaced: true,
  state: {
    mode: '',
    selectedItem: {},
    items: [],
    groups_contacts: [],
    loading: false,
    formDialog: false,
    deleteDialog: false,
    notificationMessage: '',
    notificationType: 'success',
    showNotification: false,
    filter: {
      id: '',
      ag_group_name: '',
      new_group_id: '',
      ag_group_id: '',
      id_agent: '',
    },
  },
  mutations: {
    add: (state) => {
      state.selectedItem = Object.assign({}, defaultItem)
      state.mode = 'ADD'
    },
    edit: (state, payload) => {
      state.selectedItem = Object.assign({}, payload)
      state.editDialog = true
      state.mode = 'EDIT'
    },
    loading: (state, payload) => (state.loading = payload.loading),
    setDialog: (state, payload) => {
      state[payload.dialog] = payload.show
      if (payload.mode) state.mode = payload.mode
      if (payload.item != '') {
        state.selectedItem = Object.assign({}, payload.item)
      } else {
        state.selectedItem = Object.assign({}, defaultItem)
      }
    },
    setGroups: (state, payload) => {
      state.groups_contacts = payload.groups_contacts
    },
    setItems: (state, payload) => {
      state.items = payload.items
    },
    setDefaultItem: (state) =>
      (state.selectedItem = Object.assign({}, defaultItem)),
    showLoading: (state, show) => (state.loading = show),
    showNotification: (state, payload) => {
        (state.showNotification = payload.show),
        (state.notificationMessage = payload.message),
        (state.notificationType = payload.type)
    },
    updateField,
  },
  getters: {
    getField,
  },
  actions: {
    getGroupsList({ commit }, payload) {
      if (payload.search) {
        axios
          .get(endPoints.agents_groups + '/search_groups/' + payload.search)
          .then((response) => {
            let groups = []
            if (response.data != null) {
              groups = response.data
            }
            var payloadGroups = {
              groups_contacts: groups,
            }
            commit('setGroups', payloadGroups)
          })
      }
    },
    getItems: ({ state, commit, rootGetters }) => {
      console.log('trazendo')

      const filter = state.filter
      filter.id_agent = rootGetters['agent/getIdAgent'].toString()
      commit('loading', { loading: true })
      let url = endPoints.agents_groups + '?id_agent=' + filter.id_agent

      // Object.keys(filter).forEach(function (field) {
      //   if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
      //       url += "&" + field + "=" + filter[field]
      //   }
      // });

      axios.get(url).then((response) => {
        commit('setDialog', { dialog: 'formDialog', show: false })
        commit('setItems', { items: response.data.data })
        console.log('response', response.data.data)
        commit('loading', { loading: false })
      })
    },
    deleteItem: ({ dispatch, commit, state }) => {
      commit('loading', true)
      axios
        .delete(endPoints.agents_groups + '/' + state.selectedItem.id)
        .then(() => {
          commit('loading', false)
          let notification = {
            show: true,
            message: 'Vínculo excluído com sucesso!',
            type: 'success',
          }
          commit('setDialog', { dialog: 'deleteDialog', show: false })
          commit('showNotification', notification)
          return dispatch('getItems', { resetPage: false })
        })
    },
    save: ({ dispatch, commit, state, rootGetters }) => {
      let url = endPoints.agents_groups
      let func = ''
      console.log("Item: ", state.selectedItem)
      state.selectedItem.ag_group_id = state.selectedItem.ag_group_id.ag_group_id
      if (state.mode === 'BOND') {
        func = axios.post
      } else {
        func = axios.put
        state.selectedItem = state.selectedItem.ag_group_id
        url += `/${state.selectedItem.id}`
      }

      state.selectedItem.id_agent = rootGetters['agent/getIdAgent']
      console.log(state.mode)
      func(url, state.selectedItem).then(
        () => {
          dispatch('getItems')
          let notification = {
            show: true,
            message: 'Vínculo salvo com sucesso!',
            type: 'success',
          }
          commit('setDialog', { dialog: 'formDialog', show: false })
          commit('showNotification', notification)
          commit('setDefaultItem')
        },
        (error) => {
          let notification = {
            show: true,
            message: error.response.data.message,
            type: 'error',
          }
          commit('setDialog', { dialog: 'formDialog', show: false })
          commit('showNotification', notification)
          commit('setDefaultItem')
        }
      )
    },
  },
}
