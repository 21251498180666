<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2000"
      v-model="showNotification"
    >{{ notificationMessage }}</v-snackbar>
    <v-toolbar flat class="elevation-5">
      <div>
        <v-toolbar-title>Estoque</v-toolbar-title>
      </div>
      <v-spacer></v-spacer>
      <div class="button-toolbar">
        <!-- <download-excel
          :before-generate="generateSheet"
          :before-finish="finishSheet"
          :name="getSheetName()"
          :fetch="getItemsNoPage"
          :export-fields="sheet_fields"
          class="excel"
          worksheet="Estoque"
          type="xls"
          v-if="profileAccess()"
        >
          <v-btn color="black" dark class="ma-2" small>
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </download-excel> -->
        <v-btn v-if="profileAccess()" color="black" dark class="ma-2" @click="downloadXlsx({ show: true })" small>
          <v-icon>mdi-download</v-icon>
        </v-btn>
        <v-btn color="black" dark class="ma-2" @click="showFilter({show: true})" small>
          <v-icon :color="filtered ? 'grey' : 'white'">mdi-filter</v-icon>
        </v-btn>
        <v-btn color="black" dark class="ma-2" @click="getItems" small>
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-btn color="black" dark v-if="profileAccess()" class="ma-2" @click="add" small>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :loading="loading"
      :items="items"
      :options.sync="pagination"
      :server-items-length="totalItems"        
      no-data-text="Nenhuma produto em estoque"
      class="elevation-10 table-margin"
    >

      <template v-slot:[`item.type`]="{ item }">
        <span>{{ getType(item.type)}}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="openShipment(item)">mdi-calculator</v-icon>
        <v-icon small class="mr-2" @click="viewItem(item)">mdi-information</v-icon>
        <v-icon small class="mr-2" v-if="profileAccess()" @click="edit({item: item})">mdi-pencil</v-icon>
        <v-icon
          small
          class="mr-2"
          v-if="profileAccess()" 
          @click="showDeleteDialog(item)"
        >mdi-delete</v-icon>
      </template>
    </v-data-table>

    <v-dialog :value="dialog" @click:outside="closeDialog()" width="800">
      <stock-form></stock-form>
    </v-dialog>

    <v-dialog :value="dialogFilter" width="600" @click:outside="showFilter({show: false})" @keydown.enter="filter">
      <stock-filter></stock-filter>
    </v-dialog>

    <v-dialog :value="dialogShipment" width="1000" @click:outside="setShipmentDialog({show: false, item: {}})" @keydown.enter="filter">
      <shipment></shipment>
    </v-dialog>

    <v-dialog :value="dialogDelete"  @click:outside="closeDeleteDialog" @keydown.enter="deleteItem" width="300">
      <v-card>
        <v-card-title class="headline">Confirmar a exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn text @click="closeDeleteDialog()">Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn text type="submit" right @click="deleteItem">Confirmar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import StockForm from "./StockForm";
import StockFilter from "./StockFilter";
import Shipment from './Shipment'
// import JsonExcel from "vue-json-excel";
import { utils, writeFile } from "xlsx";

export default {
  name: "Stock",
  computed: {
    ...mapState("login", ["userData"]),
    ...mapState("stock", [
      "chipment_data",
      "dialog",
      "dialogDelete",
      "item",
      "errors",
      "filtered",
      "loading",
      "mode",
      "notificationType",
      "notificationMessage",
      "items",
      "rowsPerPage",
      "totalItems",
    ]),
    ...mapFields("stock", [
      "dialogFilter",
      "dialogShipment",
      "overlay",
      "pagination",
      "showNotification",
      // ...
    ]),
  },
  created() {
    this.getItems()
  },
  components: {
    // "download-excel": JsonExcel,
    "stock-form": StockForm,
    "stock-filter": StockFilter,
    "shipment": Shipment
  },
  methods: {
    ...mapMutations("stock", [
      "add",
      "closeDialog",
      "edit",
      "viewItem",
      "showFilter",
      "setShipmentDialog"
    ]),
    ...mapMutations("shipments", ["setShipmentItem"]),
    ...mapActions("stock", [
      "closeDeleteDialog",
      "deleteItem",
      "getItems",
      "getItemsNoPage",
      "showDeleteDialog"
    ]),
    filter() {
      this.getItems({ resetPage: true });
      this.showFilter({ show: false });
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      }
      return false;
    },
    openShipment(item) {
      this.setShipmentItem({item: item})
      this.setShipmentDialog({show: true})
    },
    profileAccess() {
      if(this.userData.role > 90) {
        return true
      } else {
        return false
      }
    },
    getDate(date) {
      if (date) {
        var d = new Date(date);
        return d.toLocaleDateString() + " " + d.toLocaleTimeString();
      }
      return "";
    },
    finishSheet() {
      this.overlay = false;
    },
    generateSheet() {
      this.overlay = true;
    },
    getSheetName() {
      var date = new Date();
      var name =
        "stock_" +
        date.toLocaleDateString() +
        " " +
        date.toLocaleTimeString();
      return name + ".xls";
    },
    async downloadXlsx() {
      this.generateSheet()
      try {
        let itemsNoPage = await this.getItemsNoPage()
        if(itemsNoPage) {
          const wb = utils.book_new()
          let possible_types = ["Moletom", "Blusa", "Boné", "Calça", "Bermuda", "Outros"]
          let items = itemsNoPage.map(row => ({
            "Nome":  row.name,
            "Comprimento":  row.length,
            "Altura":  row.height,
            "Largura":  row.width,
            "Peso":  row.weight,
            "Tipo":  possible_types[row.type],
          }))
          const ws = utils.json_to_sheet(items)
          utils.book_append_sheet(wb, ws, "stock")
          await writeFile(wb, this.getSheetName())
        }
      } catch (error) {
        this.finishSheet()
      } finally {
        this.finishSheet()
      }
    },
  },
  data: () => ({
    build_fields: {},
    expanded: [],
    headers: [
      { text: "Código", value: "id", sortable: false },
      { text: "Produto", value: "product_name", sortable: false },
      { text: "Quantidade", value: "quantity", sortable: false },
      { text: "Tamanho", value: "size", sortable: false },
      { text: "CEP", value: "cep", sortable: false },
      { text: "Ações", value: "actions", sortable: false, align: "center" },
    ],
    sheet_fields: {
      Nome: {
        field: "name"
      },
      Comprimento: {
        field: "length"
      },
      Altura: {
        field: "height"
      },
      Largura: {
        field: "width"
      },
      Peso: {
        field: "weight"
      },
      Tipo: {
        field: 'type',
        callback: (type) => {
          let types = ["Moletom", "Blusa", "Boné", "Calça", "Bermuda", "Outros"]
          return types[type]
        }
      },
    },
    valid: false,
  }),
  watch: {
    pagination: {
      handler() {
        this.getItems({ resetPage: false });
      },
    }
  },
};
</script>
