<template>
  <div>
    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2000"
      v-model="showNotification"
    >
      {{ notificationMessage }}
    </v-snackbar>
    <v-list-item class="card-agent justify-space-between py-2 elevation-3 agent-data rounded">
      
      <v-list-item-content class=""> 
        <div>
          <v-btn
            color="transparent"
            elevation="0"
            class="pa-0"
            @click="goBack"
            small
          ><v-icon>mdi-chevron-left</v-icon> Voltar </v-btn>
        </div>
      </v-list-item-content>

      <v-list-item-content class="text-center">
        <v-list-item-title class="headline font-weight-medium">{{ agent.nick }}</v-list-item-title>
        <v-list-item-subtitle>
          <div>Nome: {{ agent.name }}</div>
          <div>Id PPPoker: {{ agent.id_pppoker }}</div>
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-content class="text-end justify-end "> 
        <div class="button-toolbar">
          <v-tooltip bottom >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="dark"
                dark
                elevation="0"
                class="ma-2"
                @click="changeToAgentDataTab()"
                v-bind="attrs"
                v-on="on"
                small
              >
                <span color="white">Ver dados</span>
              </v-btn>
            </template>
            <span>Dados do Agente</span>
          </v-tooltip>
        </div>
      </v-list-item-content>

    </v-list-item>

    <template >
      <v-card class="rounded-lg pb-8">
        <div color="grey" class="elevation-0 rounded-lg">
        <v-tabs 
          v-model="tab" 
          class="agent-data"
          next-icon="mdi-chevron-right"
          prev-icon="mdi-chevron-left"
          show-arrows
        >
          <v-tab>Sub-Agentes</v-tab>
          <v-tab>Faixas de Rakeback</v-tab>
          <v-tab>Masters</v-tab>
          <v-tab>Rake</v-tab>
          <v-tab>Documentos</v-tab>
          <v-tab>Jogadores</v-tab>
          <v-tab>Saldo</v-tab>
          <v-tab>Parâmetros Fechamento</v-tab>
          <v-tab>Usuários</v-tab>
          <v-tab>Slots</v-tab>
          <v-tab>Grupos do Agente</v-tab>
          <v-tab ripple href='#tab-data'>Dados</v-tab>
        </v-tabs>
      </div>
        <v-tabs-items v-model="tab" class="">

          <v-tab-item>
            <v-card> 
              <v-card-title class="text-center justify-center">
                  Sub-Agentes
              </v-card-title>
              <v-btn color="black"  @click="addSubAgent" dark class="add-align-right" small>
                  <v-icon>mdi-plus</v-icon>
              </v-btn>
              <v-data-table
                :headers="headersSubAgent"
                :loading="loading_subagent"
                :items="subAgentsAgent"
                :options.sync="paginationSubAgent"
                :server-items-length="totalSubAgents"
                no-data-text="Nenhum sub-agente encontrado"
                class="elevation-1 table-subagent"
              >
                <template v-slot:[`item.type_deal_with_master`]="{ item }">
                  <span>{{ getTypeDealWithMaster(item.type_deal_with_master) }}</span>
                </template>
                <template v-slot:[`item.value_deal_with_master`]="{ item }">
                  <span>{{ (item.value_deal_with_master) ? item.value_deal_with_master + "%" : ''}}</span>
                </template>
                <template v-slot:[`item.relationship_master_agent`]="{ item }">
                  <span>{{ getRelationshipWithMaster(item.relationship_master_agent) }}</span>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon small class="mr-2" @click="editSubAgent({item: item})">mdi-pencil</v-icon>
                  <v-icon small class="mr-2" @click="showDeleteDialogSubAgent(item)">mdi-delete</v-icon>
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>
          <v-tab-item >
            <v-card class="elevation-10"> 
              <v-card-title class="text-center justify-center">
                Faixas de Rakeback
              </v-card-title>
              <v-btn color="black"  @click="addRakeDeal" dark class="add-align-right" small>
                  <v-icon>mdi-plus</v-icon>
              </v-btn>
              <v-data-table
                :headers="headersRakeDeal"
                :loading="loading_rake_deals"
                :items="rake_deals"
                :options.sync="paginationRakeDeal"
                hide-default-footer
                :server-items-length="totalRakeDeals"
                no-data-text="Nenhum rake-deal encontrado"
                class="elevation-1 table-subagent"
              >
                <template v-slot:[`item.start_value`]="{ item }">
                  <span>{{ item.start_value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
                </template>
                <template v-slot:[`item.finish_value`]="{ item }">
                  <span>{{ item.finish_value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
                </template>
                <template v-slot:[`item.percentage`]="{ item }">
                  <span>{{ item.percentage + "%" }}</span>
                </template>
                <template v-slot:[`item.type_rake_deal`]="{ item }">
                  <span>{{ getRakeDealType(item.type_rake_deal) }}</span>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon small class="mr-2" @click="editRakeDeal({item: item})">mdi-pencil</v-icon>
                  <v-icon small class="mr-2" @click="showDeleteDialogRakeDeal(item)">mdi-delete</v-icon>
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card> 
              <v-card-title class="text-center justify-center">
                  Masters
              </v-card-title>
              <v-data-table
                :headers="headersMastersAgent"
                :loading="loadingMastersAgent"
                :items="mastersAgent"
                :options.sync="paginationMastersAgent"
                :server-items-length="totalMastersAgent"
                no-data-text="Nenhum master encontrado"
                class="elevation-1 table-subagent"
              >
                <template v-slot:[`item.type_deal_with_master`]="{ item }">
                  <span>{{ getTypeDealWithMaster(item.type_deal_with_master) }}</span>
                </template>
                <template v-slot:[`item.value_deal_with_master`]="{ item }">
                  <span>{{ (item.value_deal_with_master) ? item.value_deal_with_master + "%" : ''}}</span>
                </template>
                <template v-slot:[`item.relationship_master_agent`]="{ item }">
                  <span>{{ getRelationshipWithMaster(item.relationship_master_agent) }}</span>
                </template>
                <!--template v-slot:[`item.actions`]="{ item }">
                  <v-icon small class="mr-2" @click="editSubAgent({item: item})">mdi-pencil</v-icon>
                  <v-icon small class="mr-2" @click="showDeleteDialogSubAgent(item)">mdi-delete</v-icon>
                </template!-->
              </v-data-table>
            </v-card>
          </v-tab-item>
          <v-tab-item class="elevation-10">
            <rake></rake>
          </v-tab-item>
          <v-tab-item class="elevation-10">
            <documents-agent></documents-agent>
          </v-tab-item>
          <v-tab-item class="elevation-10">
            <players-agent :agent_id="agent_id"></players-agent>
          </v-tab-item>
          <v-tab-item class="elevation-10">
            <balance-agent :agent_id="agent_id"></balance-agent>
          </v-tab-item>
          <v-tab-item class="elevation-10">
            <parameters-agent-closure></parameters-agent-closure>
          </v-tab-item>
          <v-tab-item class="elevation-10">
            <users></users>
          </v-tab-item>
          <v-tab-item class="elevation-10">
            <agent-slots></agent-slots>
          </v-tab-item>
          <v-tab-item class="elevation-10">
            <agent-groups></agent-groups>
          </v-tab-item>
          <v-tab-item class="elevation-10" value='tab-data'>
            <agent-data></agent-data>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </template>

    <v-dialog :value="dialogRakedeal" @click:outside="closeDialogRakeDeal" width="1280">
      <rake-deal-form></rake-deal-form>
    </v-dialog>

    <v-dialog :value="dialog_subagents" @click:outside="closeDialogSubAgents" width="1280">
      <subagents-form></subagents-form>
    </v-dialog>
    
    <v-dialog :value="dialogDeleteRakeDeal" @click:outside="closeDeleteDialogRakeDeal" width="300">
      <v-card>
        <v-card-title class="headline">Confirmar exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn
              text
              @click="closeDeleteDialogRakeDeal()"
            >Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn
              text
              type="submit"
              right
              @click="deleteRakeDeal"
            >Confirmar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog :value="dialogDeleteSubAgent" @click:outside="closeDeleteDialogSubAgent" width="300">
      <v-card>
        <v-card-title class="headline">Confirmar exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn
              text
              @click="closeDeleteDialogSubAgent()"
            >Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn
              text
              type="submit"
              right
              @click="deleteSubAgent"
            >Confirmar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import AgentSlots from './agent_slots/AgentSlots.vue'
import BalanceAgent from './balance_agent/BalanceAgent'
import DocumentsAgent from "./documents/DocumentsAgent"
import PlayersAgent from './players_agent/PlayersAgent'
import Rake from "./rake/Rake"
import RakeDealForm from "./rake_deal/RakeDealForm"
import SubAgentsForm from './subagents/SubAgentsForm.vue';
import Users from './users/Users.vue';
import ParametersAgentClosure from "../../financial/weekly_closure/parameters_agent_closure/ParametersAgentClosure.vue";
import AgentGroups from "./agent_groups/AgentGroups.vue";
import AgentData from "./agent_data/AgentData.vue";

export default {
  name: "Agent",
  computed: {
    ...mapState("agent", [
      "agent",
      "documents",
      "loading_documents",
      "loading_subagent",
      "notificationMessage",
      "notificationType",
    ]),
    ...mapState("rake_deals", ["loading_rake_deals", "rake_deals", "totalRakeDeals"]),
    ...mapFields("agent", ["paginationSubAgent", "showNotification"]),
    ...mapState("subagents", ["mastersAgent", "subAgentsAgent", "totalSubAgents", "totalMastersAgent"]),
    ...mapFields("rake_deals", {
        dialogRakedeal: "dialog",
        dialogDeleteRakeDeal: "dialogDelete",
        paginationRakeDeal: "pagination"
      }  
    ),
    ...mapFields("subagents", {
      loadingMastersAgent: "loadingMastersAgent",
      dialog_subagents: "dialog",
      dialogDeleteSubAgent: "dialogDelete",
      paginationMastersAgent: "paginationMastersAgent",
      paginationSubAgent: "pagination_subagent"
    })
  },
  
  components: {
    "balance-agent": BalanceAgent,
    "documents-agent": DocumentsAgent, 
    "players-agent": PlayersAgent,
    "rake": Rake,
    "rake-deal-form": RakeDealForm,
    "subagents-form": SubAgentsForm,
    "parameters-agent-closure": ParametersAgentClosure,
    "users": Users,
    "agent-slots": AgentSlots,
    "agent-groups": AgentGroups,
    "agent-data": AgentData,
  },
  created() {
    let agent_id = this.$route.params.agent_id;
    this.agent_id = agent_id;
    this.getAgent({ agent_id: agent_id });
    this.getSubAgents({ agent_id: agent_id });
    this.getRakeDeals({ agent_id: agent_id });
    this.getMastersAgent({ agent_id: agent_id });
    this.getSlots({noPages: true})
  },
  data: () => ({
    headersDocuments: [
      {
        text: "Nome",
        value: "name",
      },
    ],
    headersRakeDeal: [
      { text: "Valor Inicial", value: "start_value", sortable: false },
      { text: "Valor Final", value: "finish_value", sortable: false, align: 'center' },
      { text: "Porcentagem", value: "percentage", sortable: false, align: 'right' },
      { text: "Tipo de Faixa", value: "type_rake_deal", sortable: false, align: 'right' },
      { text: "Ações", value: "actions", sortable: false, align: 'right'}
    ],
    headersSubAgent: [
      { text: "Id", value: "id_pppoker_agent", sortable: false },
      { text: "Nick", value: "nick_agent", sortable: false },
      { text: "Tipo de Ganho", value: "type_deal_with_master", sortable: false },
      { text: "Valor Fixo", value: "value_deal_with_master", sortable: false },
      { text: "Ganhos Sobre", value: "relationship_master_agent", sortable: false },
      { text: "Ações", value: "actions", sortable: false, align: 'center', width: "12%"}
    ],
    headersMastersAgent: [
      { text: "Id", value: "id_pppoker_agent", sortable: false },
      { text: "Nick", value: "nick_master_agent", sortable: false },
      { text: "Tipo de Ganho", value: "type_deal_with_master", sortable: false },
      { text: "Valor Fixo", value: "value_deal_with_master", sortable: false },
      { text: "Ganhos Sobre", value: "relationship_master_agent", sortable: false },
    ],
    tab: ''
  }),
  methods: {
    ...mapActions("slots", {getSlots: "getSlots"}),
    ...mapMutations("rake_deals", {
      editRakeDeal: "edit"
    }),
    ...mapMutations("subagents", {
      addSubAgent: "add",
      closeDialogSubAgents: "closeDialog",
      editSubAgent: "edit"
    }),
    ...mapActions("agent", ["getAgent", "goBack"]),
    ...mapActions("rake_deals", {
      closeDialogRakeDeal: "closeDialog",
      closeDeleteDialogRakeDeal: "closeDeleteDialog",
      deleteRakeDeal: "deleteItem",
      getRakeDeals: "getRakeDeals",
      addRakeDeal: "add",
      showDeleteDialogRakeDeal: "showDeleteDialog",
    }),
    ...mapActions("subagents", {
      getMastersAgent: "getMastersAgent",
      closeDeleteDialogSubAgent: "closeDeleteDialog",
      getSubAgents: "getSubAgents",
      deleteSubAgent: "deleteItem",
      showDeleteDialogSubAgent: "showDeleteDialog",
    }),
    getTypeDeal(type) {
      let types = ["Ganho/Perda", "Giro", "Normal", "Fichas Enviadas/Retiradas", "Sem Acerto", "Sem Acerto/GP"];
      if (types[type]) {
        return types[type];
      }
      return "";
    },
    getRakeDealType(type) {
      let types = ["Geral", "Ganhos", "Perdas"];
      if (types[type]) {
        return types[type];
      }
      return "";
    },
    getTypeClosure(type) {
        let types = ["Automático", "Manual"];
        if (types[type]) {
          return types[type];
        }
        return "";
      },
    getTypeDealWithMaster(type) {
      let types = ["Diferença", "Fixo"]
      if(types[type]) {
        return types[type]
      } 
      return "";
    },
    getRelationshipWithMaster(type) {
      let types = ["Jogadores", "Jogadores e Sub-Agentes"]
      if(types[type]) {
        return types[type]
      } 
      return "";
    },
    getTypeChips(type) {
      let types = ["Pagas", "Não Pagas", "Diferença Anotados"]
      if(types[type]) {
        return types[type]
      } 
      return "";
    },

    changeToAgentDataTab(){
     this.tab='tab-data'
    },
  },
  watch: {
    paginationSubAgent: {
      handler () {
        this.getSubAgents({resetPage: false, agent_id: this.agent_id})
      },
    },
    paginationRakeDeal: {
      handler() {
        this.getRakeDeals({agent_id: this.agent_id});
      }
    },
    paginationMastersAgent: {
      handler() {
        this.getMastersAgent({agent_id: this.agent_id});
      }
    }
  }
};
</script>

<style>
@import "./css/agent.css";
</style>
