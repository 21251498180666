import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../../config/endPoints';

const defaultItem = {
    id_player: null,
    id_pppoker: "",
};

export default {
    namespaced: true,
    state: {
        dialog: false,
        dialogDelete: false,
        editedItem: {},
        find_agents: [],
        loading_playerids: false,
        itemToDelete: {},
        loading: false,               
        mode: 'LIST',
        pagination: {
            page: 1,
            itemsPerPage: 10
        },
        playerIds: [],
        totalPlayerIds: 0
    },
    mutations: {
        add: (state) => {
            state.mode = "ADD"
            state.selectedContact = {}
            state.editedItem = Object.assign({}, defaultItem)
            state.dialog = true
        },
        edit: (state, payload) => {
            state.selectedContact = {}
            state.editedItem = Object.assign({}, payload.item)
            state.editedIndex = state.playerIds.indexOf(payload.item)
            state.mode = "EDIT"
            state.dialog = true
        },
        closeDialog: (state) => {
            state.editedItem = {}
            state.editedIndex = -1
            state.errors = [];
            state.dialog = false
            state.mode = "LIST"
        },
        loading_playerids: (state, payload) => state.loading_playerids = payload.loading,
        setMode: (state, mode) => state.mode = mode,
        setPlayerIds: (state, payload) => {
            state.playerIds = payload.playerIds
        },
        setItemToDelete: (state, item) => state.itemToDelete = item,
        setTotalPlayerIds: (state, payload) => {
            console.log(payload.total)
            state.totalPlayerIds = payload.total
        },
        setMasterAgents: (state, payload) => state.master_agents = payload.master_agents,
        showDialog: (state, show) => state.dialog = show,
        setFindAgents: (state, payload) => state.find_agents = payload.find_agents,
        setDialogDelete: (state, show) => state.dialogDelete = show,
        showFilter: (state, payload) => state.dialogFilter = payload.show,
        showLoading: (state, show) => state.loading = show,
        updateField
    },
    getters: {
        getField,
    },
    actions: {
        closeDialog: (({ commit }) => {
            commit('closeDialog', false)
        }),
        closeDeleteDialog: ({ commit }) => {
            commit('setDialogDelete', false)
            commit('setItemToDelete', {})
        },
        showDeleteDialog: ({ commit }, item) => {
            commit('setDialogDelete', true)
            commit('setItemToDelete', item)
        },
        deleteItem: ({ dispatch, commit, rootGetters, state }) => {
            console.log('heuhaudha')
            commit('loading_playerids', {loading: true})
            axios.delete(endPoints.players + "/ids_pppoker/" + state.itemToDelete.id, state.itemToDelete).then(() => {
                dispatch('closeDeleteDialog')
                commit('loading_playerids', { loading: true })
                let notification = {
                    show: true,
                    message: "Id relacionado excluido com sucesso",
                    type: "success"
                }
                commit('player/showNotification', notification, { root: true })
                return dispatch('getPlayerIds', { resetPage: true, player_id: rootGetters["player/getIdPlayer"] });
            });
        },
        getPlayerIds: ({ commit, state }, payload) => {
            commit('loading_playerids', { loading: true })
            let url = endPoints.players + "/ids_pppoker"
  
            url += '?page=' + state.pagination.page + '&' + 'itemsPerPage=' + state.pagination.itemsPerPage;
            url += "&player_id=" + payload.player_id;

            axios.get(url).then(response => {
                commit('setPlayerIds', { playerIds: response.data.data })
                commit('setTotalPlayerIds', { total: response.data.total })
                commit('loading_playerids', { loading: false })
            });
        },
        save: ({ dispatch, commit, rootGetters, state }) => {
            let url = endPoints.players + "/ids_pppoker" 
            let func = '';
            if (state.mode == 'ADD') {
                func = axios.post;
                url = endPoints.players + "/ids_pppoker";
                state.editedItem.id_player = rootGetters["player/getIdPlayer"]
            } else {
                func = axios.put;
                url += '/' + state.editedItem.id;
            }
            func(url, state.editedItem).then(
                () => {
                    dispatch('getPlayerIds', {player_id: rootGetters["player/getIdPlayer"]})
                    let notification = {
                        show: true,
                        message: "ID salvo com sucesso.",
                        type: "success"
                    }
                    commit('closeDialog')
                    commit('player/showNotification', notification, { root: true })
                },
                () => {
                    let notification = {
                        show: true,
                        message: "ID já relacionado a outro jogador.",
                        type: "error"
                    }
                    commit('player/showNotification', notification, { root: true })
                }
            );
        }
    }
}