import axios from "axios";
import { getField, updateField } from "vuex-map-fields";
import endPoints from "@config/endPoints";


export default {
  namespaced: true,
  state: {
    banks: [],
    league_closure_id: 0,
    dialogAutomaticPayment: false,
    dialogPayment: false,
    dialogPixRelated: false,
    dialogDeletePayment: false,
    club_id: 0,
    items: [],
    itemToRelatePix: {},
    payments: [],
    loading: false,
    mode: "LIST",
    overlay: false,
    page: 1,
    pagination: {
      page: 1,
      itemsPerPage: 10,
    },
    paginationPayment: {
      page: 1,
      itemsPerPage: 10,
    },
    paymentId: 0,
    paymentAutomatic: {},
    paymentToDelete: {},
    pixRelateds: {},
    pix_key: null,
    possible_payments: [
      {
        id: 0,
        description: "Recebimento"
      },
      {
        id: 2,
        description: "Pagamento"
      },
      {
        id:3,
        description: "Ajuste"
      }
    ],
    successNotification: false,
    notificationMessage: "",
    notificationType: "success",
    newPayment: {
      value: null,
      type: null,
      date: '',
      id_league_closure: null,
      user_created: null,
      date_created: null,
      pix_key: null,
      id: null,
    },
    receiptMimeType: 'pdf',
    receipt_show: '',
    showNotification: false,
    totalItems: 0,
    totalPayments: 0,
    totalPixes: 0,
    rowsPerPage: 10,
    rowsPerPagePayment: 10,
    modDialogPayment: 'ADD'
  },
  mutations: {  
    setDialogPayment: (state, show) => (state.dialogPayment = show),
    setModeViewDialogPayment: (state, payload) => (state.modDialogPayment = payload.mod),
    loading: (state, payload) => (state.loading = payload.show),
    setAutomaticPayment: (state, payload) => (state.dialogAutomaticPayment = payload.show),
    setBanks: (state, items) => (state.banks = items),
    setDialogDeletePayment: (state, show) => (state.dialogDeletePayment = show),
    setMode: (state, mode) => (state.mode = mode),
    setItems: (state, items) => (state.items = items),
    setDialogPixRelated: (state, payload) => (state.dialogPixRelated = payload.show),
    setItemToRelate: (state, payload) => (state.itemToRelatePix = payload.item),
    setPayments: (state, items) => (state.payments = items),
    setPaymentId: (state, payload) => (state.paymentId = payload.id),
    setPixKey: (state, payload) => (state.pix_key = payload.pix_key),
    setAtualPayment: (state, payload) => {
      state.newPayment.value = payload.item.value
      state.newPayment.pix_key = payload.item.pix_key
      state.newPayment.type = payload.item.type
      state.newPayment.date_created = payload.item.date_created
      state.newPayment.id = payload.item.id
    },
    setPaymentToDelete: (state, item) => (state.paymentToDelete = item),
    setLeagueClosureId: (state, payload) => (state.league_closure_id = payload),
    setRowsPerPage: (state, rowsPerPage) => (state.rowsPerPage = rowsPerPage),
    setRowsPerPagePayment: (state, rowsPerPage) => (state.rowsPerPagePayment = rowsPerPage),
    setSelectedItem: (state, payload) => (state.selectedItem = payload),
    setPage: (state, payload) => {
      state.pagination.page = payload.page;
      state.pagination.itemsPerPage = payload.itemsPerPage;
    },
    setPixRelateds: (state, payload) => {
      state.totalPixes = payload.total,
      state.pixRelateds = payload.pixes
    },
    setReceipt: (state, payload) => state.receipt_show = payload.receipt,
    setReceiptMimeType: (state, payload) =>  state.receiptMimeType = payload.receiptMimeType,
    setTotalItems: (state, total) => (state.totalItems = total),
    setTotalPayments: (state, total) => (state.totalPayments = total),
    showFilter: (state, payload) => (state.dialogFilter = payload.show),
    showErrorNotification: (state, show) => (state.errorNotification = show),
    showSuccessNotification: (state, show) =>
      (state.successNotification = show),
    showLoading: (state, show) => (state.loading = show),
    showNotification: (state, payload) => {
      (state.showNotification = payload.show),
      (state.notificationMessage = payload.message),
      (state.notificationType = payload.type);
    },
    showOverlay: (state, payload) => {
      state.overlay = payload.show;
    },
    updateField
  },
  actions: {
    closeDialogPayment: ({ commit }) => {
        commit("setDialogPayment", false);
    },
    showDialogAutomaticPayment: ({commit}, payload) => {
      commit("setAutomaticPayment", payload)
    },
    showDialogPayment: ({ commit },payload) => {
      commit("setModeViewDialogPayment", payload);
      commit("setDialogPayment", true);
    },
    showDialogPixRelateds: ({ commit }, item) => {
      commit("setDialogPixRelated", item);
      commit("setItemToRelate", item);
  },
    closeDialogDeletePayment: ({ commit }) => {
      commit("setDialogDeletePayment", false);
      commit("setPaymentToDelete", {});
    },
    showDialogDeletePayment: ({ commit }, item) => {
        commit("setDialogDeletePayment", true);
        commit("setPaymentToDelete", item);
    },
    viewItem({ dispatch }, payload) {
      dispatch('menu/openLink', '/league_closure/club/detail/' + payload.id, { root: true })
      
    },
    savePayment({state, commit, rootState, dispatch}){
      
      state.newPayment.value = parseFloat(state.newPayment.value)
      state.newPayment.id_league_closure = parseInt(state.league_closure_id)
      state.newPayment.user_created = parseInt(rootState.login.userData.id)
      let date_created = new Date();
      state.newPayment.date_created = date_created.toISOString().substr(0, 10) + " " + date_created.toLocaleTimeString();


      let url = endPoints.league_closure + "/add_payment";
        
      let data = state.newPayment    
      let func = axios.post;
      func(url, data).then(
          () => {
            let notification = {
              show: true,
              message: "Novo pagamento adicionado com sucesso",
              type: "success",
            };
            commit("showNotification", notification);
            commit("setDialogPayment", false);
            dispatch('getPayments', { resetPage: false })
          },
          error => {
            let notification = {
              show: true,
              message: "Falha ao adicionar novo pagamento",
              type: "error",
            };
            commit("showNotification", notification);
            console.log(error)
          }
      );
    },
    editPayment({state, commit, rootState, dispatch}){
      
      state.newPayment.value = parseFloat(state.newPayment.value)
      state.newPayment.id_league_closure = parseInt(state.league_closure_id)
      state.newPayment.user_created = parseInt(rootState.login.userData.id)
      let date_created = new Date();
      state.newPayment.date_created = date_created.toISOString().substr(0, 10) + " " + date_created.toLocaleTimeString();


      let url = endPoints.league_closure + "/update_payment";
        
      let data = state.newPayment    
      let func = axios.put;
      func(url, data).then(
          () => {
            let notification = {
              show: true,
              message: "Pagamento editado com sucesso",
              type: "success",
            };
            commit("showNotification", notification);
            commit("setDialogPayment", false);
            dispatch('getPayments', { resetPage: false })
          },
          error => {
            let notification = {
              show: true,
              message: "Falha ao ditar pagamento",
              type: "error",
            };
            commit("showNotification", notification);
            console.log(error)
          }
      );
    },
    finishPayment: ({ commit, dispatch, state},payload) => {
      commit("loading", { show: true });
      if (payload.payment.bank) {
        let data = {
          bank_id: payload.payment.bank,
          status: 2,
          id: state.paymentId
        }
        axios
          .put(endPoints.league_closure + "/payment", data)
          .then(() => {
            dispatch("showDialogAutomaticPayment", { show: false });
            let notification = {
              show: true,
              message: "Pagamento da despesa em processamento!",
              type: "success",
            };
            // commit("setPaymentAutomatic", { payment: {} });
            commit("loading", { show: false });
            commit("showNotification", notification);
            dispatch("getPayments", { resetPage: false });
          })
          .catch((error) => {
            console.log("Dei erro aqui: ", error);
            let notification = {
              show: true,
              message:
                "Ocorreu um erro ao realizar o envio do Pix. Realize o envio manual!",
              type: "error",
            };
            commit("loading", { show: false });
            commit("showNotification", notification);
          });
      } else {
        let notification = {
          show: true,
          message: "Banco para pagamento deve ser informado",
          type: "error",
        };
        commit("showNotification", notification);
      }
    },
    finishAutomaticPayment: ( {state, dispatch, commit},payload) => {
      commit("loading", { show: true });
      let bankPix = state.banks.filter((b) => b.id ==  payload.payment.bank)[0]
      if (state.pix_key == null){
        let notification = {
          show: true,
          message:
            "Chave pix deve ser informada para pagamento automático",
          type: "error",
        };
        commit("loading", { show: false });
        dispatch("showDialogAutomaticPayment", { show: false });
        commit("showNotification", notification);
        return;
      }
      if (payload.payment.bank) {
        let data = {
          bank_id: bankPix.id,
          status: 2,
          id: state.paymentId,
          pix_key: state.pix_key
        }
        axios
          .put(endPoints.pix + "/league_closure", data)
          .then(() => {
            dispatch("showDialogAutomaticPayment", { show: false });
            let notification = {
              show: true,
              message: "Pagamento em processamento!",
              type: "success",
            };
            commit("setPaymentAutomatic", { payment: {} });
            commit("loading", { show: false });
            commit("showNotification", notification);
            dispatch("getPayments", { resetPage: false });
          })
          .catch((error) => {
            console.log("Dei erro aqui: ", error);
            let notification = {
              show: true,
              message:
                "Ocorreu um erro ao realizar o envio do Pix. Realize o envio manual!",
              type: "error",
            };
            commit("loading", { show: false });
            commit("showNotification", notification);
          });
      } else {
        let notification = {
          show: true,
          message: "Banco para pagamento deve ser informado",
          type: "error",
        };
        commit("loading", { show: false });
        dispatch("showDialogAutomaticPayment",{ show: false })
        commit("showNotification", notification);
      }
    },
    getPayments:({ commit, state }, payload) => {
      commit("loading", { show: true });
      let url = endPoints.league_closure + "/payment/" + state.league_closure_id 
      

      if (payload.resetPage === true) {
        url += "?page=" + 1 + "&" + "itemsPerPage=" + 10;
        commit("setPage", { page: 1, itemsPerPage: 10 });
      } else {
        url +=
          "?page=" +
          state.paginationPayment.page +
          "&" +
          "itemsPerPage=" +
          state.paginationPayment.itemsPerPage;
      }
      
      if (state.paginationPayment && state.paginationPayment.sortBy && state.paginationPayment.sortBy.length > 0) {
        url += "&sort_by=" + state.paginationPayment.sortBy[0]
        url += "&sort_desc=" + state.paginationPayment.sortDesc[0]
      }
      axios.get(url).then((items) => {
        commit("setTotalPayments", items.data.total);
        commit("loading", { show: false });
        commit("setPayments", items.data.data);
      });
    },
    deletePayment: ({ dispatch, commit, state }) => {
      commit("loading", { show: true });
      let url = endPoints.league_closure
      axios.delete(url + "/payment/" + state.paymentToDelete.id).then(() => {
          commit("loading", { show: false });
          dispatch("closeDialogDeletePayment");
          let notification = {
            show: true,
            message: "Pagamento excluido com sucesso",
            type: "success",
          };
          commit("showNotification", notification);
          return dispatch("getPayments", { resetPage: false });
        });
    },
    getItems: ({ commit, state }, payload) => {
      commit("loading", { show: true });
      let url = endPoints.league_closure_club + "/" + payload.club_id     

      if (payload.resetPage === true) {
        url += "?page=" + 1 + "&" + "itemsPerPage=" + 10;
        commit("setPage", { page: 1, itemsPerPage: 10 });
      } else {
        url +=
          "?page=" +
          state.pagination.page +
          "&" +
          "itemsPerPage=" +
          state.pagination.itemsPerPage;
      }
      
      if (state.pagination && state.pagination.sortBy && state.pagination.sortBy.length > 0) {
        url += "&sort_by=" + state.pagination.sortBy[0]
        url += "&sort_desc=" + state.pagination.sortDesc[0]
      }
    
      axios.get(url).then((items) => {
        commit("setTotalItems", items.data.total);
        commit("loading", { show: false });
        commit("setItems", items.data.data);
      });
    },
    getRelatedPixes: async ({ commit, state }) => {
      let url = endPoints.pix
      url += '?page=' + 1 + '&' + 'itemsPerPage=' + 15;
      
      url += `&value=${state.itemToRelatePix.value}&used=0&type=1&status=1&date=${state.itemToRelatePix.date} 00:00:00`
      
      axios.get(url).then(pixes => {
          if (pixes) {
              // state.pixRelated = Object.assign({}, payload.deposit)
              commit('setPixRelateds', { pixes: pixes.data.data, total: pixes.data.total })
          } else {
              commit('setPixRelateds', { pixes: [] })
              commit('showDialogPixRelateds', { show: false })
          }
      });
    },
    linkPix: ({commit, state, dispatch},payload) => {
      let url = endPoints.league_closure_club + "/link_pix"
      let data = {
          id: state.itemToRelatePix.id,
          pix_id: payload.item.id
      }
      axios.post(url, data).then(() => {
          let notification = {
              show: true,
              message: "Pix vinculado com sucesso",
              type: "success"
          }
          commit("setDialogPixRelated", {show:false})
          commit('showNotification', notification)
          dispatch('getPayments', { resetPage: true })
      }).catch((error) => {
          let notification = {
              show: true,
              message: error.response.data.message,
              type: "error"
          }
          commit('showNotification', notification)
      })
    },
    addClubDetail: ({commit}, payload) => {
      commit("loading", { show: true });
      let url = endPoints.league_closure
      url = url + "/add_club_detail";

      let data = {start_date: payload.start_date, end_date:payload.end_date , id: payload.id}
      let func = axios.put;        

      func(url, data).then(
          () => {
            let notification = {
              show: true,
              message: "Detalhes do clube adicionado com sucesso",
              type: "success",
            };
            commit("showNotification", notification);
            commit("loading", { show: false });
          },
          error => {
            commit("loading", { show: false });
            console.log(error)
          }
      );
    },
  },
  getters: {
    getField,
  },
};
