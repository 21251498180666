import axios from 'axios';
import endPoints from '../../../config/endPoints';
import { getField, updateField } from 'vuex-map-fields';
import { defaultErrorNotification, defaultSuccessNotification } from "@/constants"

const defaultAgentFolder = {
    id: 0,
    title: '',
    description: '',
    date_picker: '',
    date: '',
    date_cread: '',
}

const defaultFilter = {
    title: '',
    description: '',
}

const formatDate = (d) => {
    var day, month, year;
    month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

export default {
    namespaced: true,
    state: {
        items: [],
        selectedItem: {},
        mode: "",
        totalItems: 0,
        loading: false,
        deleteDialog: false,
        filterDialog: false,
        showDialog: false,
        filter: {
            title: "",
            description: ""
        },
        notification: {
            show: false,
            message: "",
            type: "",
        },
        pagination: {
            page: 1,
            itemsPerPage: 10,
        }
    },
    mutations: {
        add: (state) => {
            defaultAgentFolder.date = new Date();
            state.selectedItem = Object.assign({}, defaultAgentFolder);
            state.showDialog = true;
            state.mode = "ADD";
        },
        edit: (state, payload) => {
            payload.date_picker = new Date(payload.date);
            state.selectedItem = Object.assign({}, payload);
            state.showDialog = true;
            state.mode = "EDIT";
        },
        deleteFolder: (state, payload) => {
            state.selectedItem = payload;
            state.deleteDialog = true;
        },
        clearFilter: (state) => {
            state.filter = Object.assign({}, defaultFilter)
        },
        setSelectedItem: (state, payload) => (state.selectedItem = payload),
        setItems: (state, payload) => (state.items = payload),
        setLoading: (state, payload) => (state.loading = payload),
        showNotification: (state, payload) => (state.notification = payload),
        setDeleteDialog: (state, payload) => (state.deleteDialog = payload),
        setShowFilter: (state, payload) => (state.filterDialog = payload),
        setShowDialog: (state, payload) => {
            state.showDialog = payload;
            state.selectedItem = Object.assign({}, defaultAgentFolder);
        },
        setTotalItems: (state, payload) => (state.totalItems = payload),
        updateField
    },
    getters: {
        getField
    },
    actions: {
        getItems: async ({ commit, state }) => {
            let filter = state.filter
            let url = `${endPoints.agents_folders}?page=${state.pagination.page}&itemsPerPage=${state.pagination.itemsPerPage}`;
            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {                    
                    if (field == "end_date" || field == "start_date") {
                        var e = new Date(filter[field])
                        url += "&" + field + "=" + formatDate(e) + " " + e.toLocaleTimeString()
                    } else {
                        url += "&" + field + "=" + filter[field]
                    }
                }
            })
        
            commit("setLoading", true);
        
            try {
                let items = await axios.get(url);
                commit("setItems", items.data.data);
                commit("setTotalItems", items.data.total);
            } catch (error) {
                commit("showNotification", defaultErrorNotification(error?.response?.data?.message));
            }
            commit("setLoading", false);
        },
        deleteItem: async ({ commit, dispatch, state }) => {
            commit("setLoading", true);
            try {
              await axios.delete(`${endPoints.agents_folders}/${state.selectedItem.id}`);
      
              commit("setDeleteDialog", false);
              commit("setSelectedItem", {});
              commit("showNotification", defaultSuccessNotification("Pasta removida com sucesso"));
      
              return dispatch("getItems");
            } catch (error) {
              commit("showNotification", defaultErrorNotification(error?.response?.data?.message));
            }
            commit("setLoading", false);
        },

        save: async ({ commit, state, dispatch }) => {
            let url = endPoints.agents_folders;
            let func = "";
            
            state.mode === "ADD"
              ? (func = axios.post)
              : ((func = axios.put), (url += `/${state.selectedItem.id}`));
            commit("setLoading", true);
            try {
              let date_created = new Date()
              date_created = `${formatDate(date_created)} ${date_created.toLocaleTimeString()}`
              state.mode === "ADD" && (state.selectedItem.date_created = date_created)
      
              await func(url, state.selectedItem);
              commit("showNotification", defaultSuccessNotification("Pasta salva com sucesso"));
              commit("setShowDialog", false);
              commit("setLoading", false);
              dispatch("getItems");
            } catch (error) {
              commit("showNotification", defaultErrorNotification(error?.response?.data?.message));
              commit("setLoading", false);
            }
          },
        viewItem({ dispatch }, payload) {
            dispatch('menu/openLink', `/agents_folders/${payload.id}`, { root: true })
        }

        
    },

}