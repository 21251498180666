<template>
    <div>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <v-snackbar
            :color="notificationType"
            center
            top
            :timeout="2000"
            v-model="showNotification"
        >
            {{ notificationMessage }}
        </v-snackbar>
        <v-toolbar flat class="elevation-5">
            <div>
                <v-toolbar-title>Notificações Agendadas</v-toolbar-title>
            </div>
            <v-spacer></v-spacer>
            <div class="button-toolbar">
                <!-- <v-btn
                    color="black"
                    dark
                    class="ma-2"
                    @click="showFilter({ show: true })"
                    small
                    ><v-icon>mdi-filter</v-icon></v-btn
                > -->
                <v-btn color="black" dark class="ma-2" @click="getItems" small
                    ><v-icon>mdi-refresh</v-icon></v-btn
                >
                <v-btn
                    color="black"
                    dark
                    class="ma-2"
                    @click="addNotification"
                    small
                    ><v-icon>mdi-plus</v-icon></v-btn
                >
            </div>
        </v-toolbar>

        <v-data-table
            :headers="headers"
            :loading="loading"
            :items="items"
            :options.sync="pagination"
            :server-items-length="totalItems"
            no-data-text="Nenhuma notificação encontrada"
            class="elevation-10 table-margin"
            show-expand
            :expanded.sync="expanded"
        >
            <template v-slot:[`item.status`]="{ item }">
                <v-chip
                    :color="getClassStatus(item.status)"
                    class="ma-2"
                    text-color="white"
                    label
                    >{{ getStatusDescription(item.status) }}</v-chip
                >
            </template>
            <template v-slot:[`item.createdBy`]="{ item }">
                <span class="ma-2">{{
                    getNameUserCreated(item.createdBy)
                }}</span>
            </template>
            <template v-slot:[`item.date`]="{ item }">
                <span class="ma-2">{{ getDate(item.date) }}</span>
            </template>
            <template v-slot:[`item.createdAt`]="{ item }">
                <span class="ma-2">{{ getDate(item.createdAt) }}</span>
            </template>
            <template
                v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }"
            >
                <v-icon @click="expand(true)" v-if="item.body && !isExpanded"
                    >mdi-chevron-down</v-icon
                >
                <v-icon @click="expand(false)" v-if="item.body && isExpanded"
                    >mdi-chevron-up</v-icon
                >
            </template>
            <template v-slot:expanded-item="{ item }">
                <td :colspan="headers.length">{{ item.body }}</td>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            v-on="on"
                            v-bind="attrs"
                            class="mr-2"
                            color="blue"
                            @click="viewNotification(item)"
                            >mdi-information</v-icon
                        >
                    </template>
                    <span>Informações</span>
                </v-tooltip>

                <v-tooltip bottom v-if="item.status == 1">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            v-on="on"
                            color="orange"
                            v-bind="attrs"
                            class="mr-2"
                            @click="edit({ item: item })"
                            >mdi-pencil</v-icon
                        >
                    </template>
                    <span>Editar Agendamento</span>
                </v-tooltip>

                <v-tooltip bottom v-if="item.status == 1">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            v-on="on"
                            v-bind="attrs"
                            class="mr-2"
                            color="red"
                            @click="showDeleteDialog(item)"
                            >mdi-delete</v-icon
                        >
                    </template>
                    <span>Excluir Agendamento</span>
                </v-tooltip>

                <v-tooltip bottom v-if="item.status == 5">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            v-on="on"
                            v-bind="attrs"
                            class="mr-2"
                            color="green"
                            @click="rescheduleNotification({ id: item.id })"
                            >mdi-send-clock</v-icon
                        >
                    </template>
                    <span>Reagendar</span>
                </v-tooltip>
            </template>
        </v-data-table>

        <v-dialog
            :value="dialogForm"
            width="800"
            @click:outside="closeDialogForm"
        >
            <notifications-schedule-form></notifications-schedule-form>
        </v-dialog>

        <v-dialog :value="dialogFilter" width="600">
            <notifications-schedule-filter></notifications-schedule-filter>
        </v-dialog>

        <v-dialog
            :value="dialogDelete"
            persistent
            width="300"
            @click:outside="closeDeleteDialog()"
        >
            <v-card>
                <v-card-title class="headline"
                    >Confirmar a exclusão?</v-card-title
                >

                <v-card-actions>
                    <v-col>
                        <v-btn text @click="closeDeleteDialog()"
                            >Cancelar</v-btn
                        >
                    </v-col>
                    <v-col class="text-right">
                        <v-btn
                            text
                            type="submit"
                            right
                            @click="deleteScheduleNotification"
                            >Confirmar</v-btn
                        >
                    </v-col>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import NotificationsScheduleForm from './NotificationsScheduleForm'
import NotificationsScheduleFilter from './NotificationsScheduleFilter'

export default {
    name: 'NotificationsSchedule',
    computed: {
        ...mapState('login', ['userData']),
        ...mapState('users', ['users']),
        ...mapState('notifications_schedule', [
            'dialogForm',
            'dialogDelete',
            'dialogFilter',
            'item',
            'loading',
            'mode',
            'notificationType',
            'notificationMessage',
            'rowsPerPage',
            'totalItems',
            'items',
        ]),
        ...mapFields('notifications_schedule', [
            'pagination',
            'overlay',
            'showNotification',
        ]),
    },
    created() {
        this.getItems({ resetPage: false })
        this.getUsers({ noPages: true })
    },
    components: {
        'notifications-schedule-form': NotificationsScheduleForm,
        'notifications-schedule-filter': NotificationsScheduleFilter,
    },
    methods: {
        ...mapActions('users', ['getUsers']),
        ...mapMutations('notifications_schedule', [
            'addNotification',
            'viewNotification',
            'showFilter',
            'edit',
            'closeDialogForm',
        ]),
        ...mapActions('notifications_schedule', [
            'deleteScheduleNotification',
            'getItems',
            'getItemsNoPage',
            'rescheduleNotification',
            'showDeleteDialog',
            'closeDeleteDialog',
        ]),
        isMobile() {
            if (
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent,
                )
            ) {
                return true
            }
            return false
        },
        getStatusDescription(status) {
            return this.possibileStatus[status]
        },

        getNameUserCreated(id) {
            const user = this.users.find((user) => user.id === id)
            return user ? user.name : 'Usuário não encontrado'
        },

        getDate(date) {
            if (date) {
                var d = new Date(date)
                return d.toLocaleDateString() + ' ' + d.toLocaleTimeString()
            }
            return ''
        },

        getClassStatus(status) {
            switch (status) {
                case 0:
                    return 'red'
                case 1:
                    return 'blue'
                case 2:
                    return 'yellow'
                case 3:
                    return 'green'
                case 4:
                    return 'red'
                case 5:
                    return 'orange'

                default:
                    'brown'
            }
        },
    },
    data: () => ({
        expanded: [],
        headers: [
            {
                text: 'ID',
                align: 'left',
                value: 'id',
                sortable: true,
            },
            {
                text: 'Título',
                align: 'center',
                value: 'title',
                sortable: false,
            },
            {
                text: 'Data Agendamento',
                align: 'center',
                value: 'date',
                sortable: false,
            },
            {
                text: 'Data de Criação',
                align: 'center',
                value: 'createdAt',
                sortable: false,
            },

            {
                text: 'Status',
                align: 'center',
                value: 'status',
                sortable: false,
            },
            {
                text: 'Número de Envios',
                align: 'center',
                value: 'notifications_count',
                sortable: false,
            },
            {
                text: 'Criado Por',
                align: 'center',
                value: 'createdBy',
                sortable: false,
            },

            {
                text: 'Ações',
                value: 'actions',
                align: 'center',
                sortable: false,
            },
        ],

        possibileStatus: [
            'Pendente',
            'Agendado',
            'Enviando',
            'Enviado',
            'Erro',
            'Erro Agendamento',
        ],

        valid: false,
    }),
    watch: {
        pagination: {
            handler() {
                this.getItems({ resetPage: false })
            },
        },
    },
}
</script>

<style>
</style>