<template>
  <div>
    <v-tabs v-model="tab" class="elevation-10">
      <v-tab v-if="showFinancial">Financeiro</v-tab>
      <v-tab v-if="showAttendance">Atendimento</v-tab>
      <v-tab v-if="showCommercial">Comercial</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
        <v-tab-item v-if="showFinancial" class="dashboard">
          <v-flex sm12 md12 xs12 xl12 class="elevation-10">
            <financial-dashboard></financial-dashboard>
          </v-flex>
        </v-tab-item> 
        <v-tab-item v-if="showFinancial" class="dashboard">
          <v-flex sm12 md12 xs12 xl12 class="elevation-10">
            <attendance-dashboard></attendance-dashboard>
          </v-flex>
        </v-tab-item> 
        <v-tab-item v-if="showCommercial" class="dashboard">
          <v-flex sm12 md12 xs12 xl12 class="elevation-10">
            <commercial-dashboard></commercial-dashboard>
          </v-flex>
        </v-tab-item> 
    </v-tabs-items> 
  </div>
</template>

<script>
  import { mapState } from "vuex";
  import CommercialDashboard from './dashboards/CommercialDashboard'
  import FinancialDashboard from './dashboards/FinancialDashboard'
  import AttendanceDashboard from './dashboards/AttendanceDashboard'

  export default {
    name: 'HomeComponent',
    components: {
      "financial-dashboard": FinancialDashboard,
      "attendance-dashboard": AttendanceDashboard,
      "commercial-dashboard": CommercialDashboard
    },
    computed: {
      ...mapState("login", ["userData"]),
      showFinancial() {
        let roles = [2, 93, 97, 98, 99]
        return roles.indexOf(this.userData.role) != -1
      },
      showAttendance() {
        let roles = [0, 1, 2, 80, 93, 95, 96, 97, 98, 99]
        return roles.indexOf(this.userData.role) != -1
      },
      showFinancialAdmin() {
        let roles = [93, 97, 99]
        return roles.indexOf(this.userData.role) != -1
      },
      showCommercial() {
        let roles = [3, 93, 96, 97, 99]
        return roles.indexOf(this.userData.role) != -1
      }
    },
    data: () => ({
      tab: 'financial'
    }),
    methods: {
    }
  }
  
 function jiraHelpdesk(callback) {
  var jhdScript = document.createElement('script');
  jhdScript.type = 'text/javascript';
  jhdScript.setAttribute('data-jsd-embedded', null);
  jhdScript.setAttribute('data-key', '6e00e452-a95e-40bc-92eb-74af680a0072');
  jhdScript.setAttribute('data-base-url', 'https://jsd-widget.atlassian.com');
  jhdScript.src='https://jsd-widget.atlassian.com/assets/embed.js';
  if(jhdScript.readyState) { // old IE support
   jhdScript.onreadystatechange = function() {
    if ( jhdScript.readyState === 'loaded' || jhdScript.readyState === 'complete' ) {
     jhdScript.onreadystatechange = null;
     callback();
    }
   };
  } else { //modern browsers
   jhdScript.onload = function() {
    callback();
   };
  }
  document.getElementsByTagName('head')[0].appendChild(jhdScript);
 }

 jiraHelpdesk(function() {
  var DOMContentLoaded_event = document.createEvent('Event');
  DOMContentLoaded_event.initEvent('DOMContentLoaded', true, true);
  window.document.dispatchEvent(DOMContentLoaded_event);
 });

 function resizeJiraHelpdeskMobile() {
  var jhdIframe = document.getElementById('jsd-widget');
  if (jhdIframe && window.innerWidth < 768) {
   jhdIframe.style.height = '1px';
  }
 }
 resizeJiraHelpdeskMobile();
</script>

<style scoped>
  .dashboard {
    margin-top: 10px;
    background-color: #f2f2f2;
  }
</style>