  
<template>
  
  <v-app>
    <!-- <PageLoader /> -->
    <div v-if="isLogged">
      <MenuStars>

      </MenuStars>
    </div>

    <v-content>
      <v-container fluid class="container-app">
        <router-view></router-view>
      </v-container>
    </v-content>

  </v-app>
</template>


<style>
</style>

<script>

  // import PageLoader from './components/PageLoader.vue'
  import MenuStars from './components/menustars/MenuStarsComponent'
  import { mapGetters } from 'vuex'

  export default{
    components: {
      MenuStars,
      // PageLoader
    },
    computed: {
      ...mapGetters('login', [
        'isLogged',
      ])
    },
    created: function () {
      
    }
  }
  
</script>

<style lang="sass">
#app 
  font-family: 'Roboto', sans-serif

.container-app 
  height: 100vh

.overflow-hidden 
  overflow: hidden !important

.container-app::-webkit-scrollbar 
    width: 8px
    background-color: #F5F5F5

/* Handle */

.container-app::-webkit-scrollbar-thumb 
    border-radius: 6px
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3)
    background-color: #e4dcd4

@media (max-width: 480px) 
  .v-application--wrap 
    min-height: 93vh !important
</style>