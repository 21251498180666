<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    
    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2000"
      v-model="showNotification"
    >
        {{ notificationMessage }}
    </v-snackbar>
    <v-toolbar flat class="elevation-5">
      <div>
        <v-toolbar-title>Adiantamentos de Rakeback</v-toolbar-title>
      </div>
      <v-spacer></v-spacer>
      <div class="button-toolbar">
        <v-btn color="black" dark class="ma-2" @click="getRakesWeek" small><v-icon>mdi-calendar-week</v-icon></v-btn>
        <v-btn color="black" dark class="ma-2" @click="showFilter({show: true})" small><v-icon>mdi-filter</v-icon></v-btn>
        <v-btn color="black" dark class="ma-2" @click="getRakes({noFilter: 'yes'})" small><v-icon>mdi-refresh</v-icon></v-btn>
        <!-- <v-btn color="black" dark class="ma-2" @click="add" small><v-icon>mdi-plus</v-icon></v-btn> -->
      </div> 
    </v-toolbar>


     <v-data-table
      :headers="headers"
      :loading="loading"
      :items="rakes"
      :options.sync="pagination"
      :server-items-length="totalRakes"
      :item-class= "getClassColor"
      no-data-text="Nenhum adiantamento de Rakeback encontrado"
      class="elevation-10 table-margin"
    >
      <template v-slot:[`item.date_picker`]="{ item }">
        <span>{{ getDate(item.date_picker) }}</span>
      </template>
      <template v-slot:[`item.value`]="{ item }">
        <span>{{
          item.value.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })
        }}</span>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <span>{{ getStatus(item.status) }}</span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">

        <v-tooltip bottom>
          <template v-slot:activator="{on, attrs}">
            <v-icon small v-on="on" v-bind="attrs" class="mr-2" @click="viewRake(item)">mdi-information</v-icon>
          </template>
          <span>Informações</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{on, attrs}">        
            <v-icon small v-on="on" v-bind="attrs" class="mr-2" @click="edit({item: item})">mdi-pencil</v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>

        <v-tooltip bottom> 
          <template v-slot:activator="{on, attrs}">    
            <v-icon small v-on="on" v-bind="attrs" class="mr-2" @click="showDeleteDialog(item)">mdi-delete</v-icon>
          </template>
          <span>Apagar</span>
        </v-tooltip>

      </template>
      
    </v-data-table>

    <v-dialog :value="dialogFilter" width="600" @click:outside="showFilter({show: false})">
      <rakeback-filter></rakeback-filter>
    </v-dialog>
  
    <v-dialog :value="dialog" width="600" @click:outside="closeDialog">
      <rakeback-form></rakeback-form>
    </v-dialog>

    <v-dialog :value="dialogDelete" persistent width="500">
      <v-card>
        <v-card-title class="headline">Confirmar a exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn
              text
              @click="closeDeleteDialog()"
            >Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn
              text
              type="submit"
              right
              @click="deleteRake"
            >Confirmar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogReceipt"
      @click:outside="setDialogReceipt({show: false})"
    >
      <v-card height="90vh">
        <v-img max-height="100%" :src="receipt_show" contain></v-img>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import { mapFields } from 'vuex-map-fields'
  import RakebackFilter from './RakebackFilter'
  import RakebackForm from './RakebackForm'

  export default {
    name: 'Rakes',
    computed: {
      ...mapState('login', ['userData']),
      ...mapState('rakeback', ['contact_show', 'dialogDelete', 'dialogReceipt', 
            'rake','instances', 'errors', 'users', 'loading', 'mode', 'notificationType', 'notificationMessage', 
            'rowsPerPage', 'receipt_show', 'totalRakes', 'rakes', 'agents']),
      ...mapFields('rakeback', [
          "dialogReceipt",
          'pagination',
          'dialogFilter',
          'dialog',
          'overlay',
          'showNotification'
          // ...
        ])
    },
    created() {
      this.getRakes({resetPage: false})
      this.setScreen({screen: 'rakeback'})
    },
    components: {
      "rakeback-filter": RakebackFilter,
      "rakeback-form": RakebackForm
    },
    methods: {
      ...mapMutations('rakeback', ['add', 'edit', 'viewRake', 'showFilter', 'setDialogReceipt', "setScreen"]),
      ...mapActions('rakeback', ['closeDialog', 'closeDeleteDialog', 'deleteRake', 'getUsers', 'getRakes', 'getRakesWeek', 'getRakesNoPage', 
                    'showDeleteDialog','showReceipt', 'startWithdrawal', 'stopWithdrawal', 'getAgents' ]),
      isMobile() {
        if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
          return true;
        }
        return false
      },
      getClassColor(item) {
        let className = '';

        if(item.status == 0) {
          className = 'conciliation-error'
        } else if (item.status == 1) {
          className = 'conciliation-warning'
        } else if (item.status == 2) {
          className = 'conciliation-ok'
        }

        return className
      },
      getDate(date) {
        var d = new Date(date);
        return d.toLocaleDateString() + " " + d.toLocaleTimeString();
      },
      getStatus(status) {
        return this.statuss[status]
      },
      getSheetName() {
        var date = new Date();
        var name =
          "despesas_" +
          date.toLocaleDateString() +
          " " +
          date.toLocaleTimeString();
        return name + ".xls";
      }
    },
    data: () => ({
      headers: [
        { text: "Data",  value: "date_picker", sortable: false, align: "center" },
        { text: "Agente",  value: "name", sortable: false, align: "center"},
        { text: "Valor",  value: "value", sortable: false, align: "center"},
        { text: "Status",  value: "status", sortable: false, align: "center" },
        // { text: "Ações",  value: "actions", sortable: false, align: "center" }
      ],
      statuss: [
        'Pendente',
        'Devendo',
        'Concluído'
      ],
    }),
    watch: {
      pagination: {
        handler () {
          this.getRakes({resetPage: false})
        },
      },
    },
  }
</script>

<style>
</style>