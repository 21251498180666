import axios from 'axios';
import endPoints from '../../../config/endPoints';
import { buildDataTopRakes } from './buildDataDashboard'

export default {
    namespaced: true,
    state: {
        qt_active_players: [],
        qt_active_agents: [],
        rakeback_agent: [],
        rakes_agent: [],
        top_agents_week: [],
        top_agents_month: []
    },
    mutations: {
        loading: (state, payload) => state.loading = payload.loading,
        loading_top_agents: (state, payload) => state.loading = payload.loading,
        setRakesAgent: (state, payload) => state.rakes_agent = payload.rakes_agent,
        setRakebackAgent: (state, payload) => state.rakeback_agent = payload.rakeback_agent,
        setTopAgentsWeek: (state, payload) => state.top_agents_week = payload.top_agents_week,
        setTopAgentsMonth: (state, payload) => state.top_agents_month = payload.top_agents_month,
        setQtActiveAgents: (state, payload) => state.qt_active_agents = payload.qt_active_agents,
        setQtActivePlayers: (state, payload) => state.qt_active_players = payload.qt_active_players
    },
    actions: {
        getRakesAgent: ({ commit }) => {
            commit('loading', { loading: true })
            let url = endPoints.agents_closure + "/last_rakes";
            axios.get(url).then(response => {
                let rake_agents = []
                let rakeback_agents = []
                let qt_active_players = []
                let qt_active_agents = []
                if (response.data) {
                    let rakes = buildDataTopRakes(response.data)
                    rake_agents = rakes.rake;
                    rakeback_agents = rakes.rakeback;
                    qt_active_players = rakes.players_active;
                    qt_active_agents = rakes.agents_active;
                }
                commit('setQtActivePlayers', { qt_active_players: qt_active_players })
                commit('setQtActiveAgents', { qt_active_agents: qt_active_agents })
                commit('setRakesAgent', { rakes_agent: rake_agents })
                commit('setRakebackAgent', { rakeback_agent: rakeback_agents })
                commit('loading', { loading: false })
            });
        },
        getTopAgents: ({ commit }) => {
            commit('loading_top_agents', { loading: true })
            let url = endPoints.agents_closure + "/top_agents";
            axios.get(url).then(response => {
                let top_agents_month = []
                let top_agents_week = []
                if (response.data) {
                    response.data.forEach(r => {
                        if (r.type == 0) {
                            top_agents_week.push(r)
                        } else {
                            top_agents_month.push(r)
                        }

                    });
                }
                commit('setTopAgentsWeek', { top_agents_week: top_agents_week })
                commit('setTopAgentsMonth', { top_agents_month: top_agents_month })
                commit('loading_top_agents', { loading: false })
            });
        }
    },
}