<template>
    <v-card>
        <v-snackbar
            :color="notificationType"
            right
            top
            :timeout="2000"
            v-model="showNotification"
            >{{ notificationMessage }}
        </v-snackbar>
        <v-card-title class="headline text-center justify-center">Calculadora de Frete</v-card-title>
        <v-form ref="form">
            <v-container grid-list-md>
                <v-layout wrap>
                    <v-flex xs12 sm12 md12>
                        <v-text-field
                            label="Produto"
                            :value="shipmentItem.product_name"
                            outlined
                            :readonly="true"
                        ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md6>
                        <v-text-field
                            label="Cep Destino *"
                            v-model="cep"
                            outlined
                        ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md6>
                        <v-select
                            label="Modo de Envio"
                            :items="possible_types"
                            v-model="type"
                            item-text="description"
                            item-value="id"
                            clear-icon="mdi-close"
                            :clearable="true"
                            outlined
                        ></v-select>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-form>
        <v-container grid-list-md>
            <v-data-table 
                :headers="headers" 
                :items="shipment_options"  
                disable-pagination
                hide-default-footer
                :loading="loading"
                class="elevation-1 table-at"
            >
                <template v-slot:[`item.value`]="{ item }">
                    <span>{{ item.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
                </template>
                <template v-slot:[`item.deadline`]="{ item }">
                    {{ item.deadline }} dias
                </template>
            </v-data-table>
        </v-container>

        <v-card-actions>
            <v-col>
                <v-btn text @click="setShipmentDialog({show: false})">Cancelar</v-btn>
            </v-col>
            <v-col class="text-right">
                <v-btn text type="submit" right @click="calcShipment">Calcular</v-btn>
            </v-col>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
    computed: {
        ...mapState("shipments", [
            "loading",
            "shipmentItem",
            "shipment_options",
            "notificationType",
            "notificationMessage",
        ]),
        ...mapFields("shipments", [
            "cep",
            "type",
            "showNotification"
        // ...
        ]),
    },
    data: () => ({
        headers: [
            { text: "Transportadora", value: "company", sortable: false },
            { text: "Valor", value: "value", sortable: false },
            { text: "Prazo", value: "deadline", sortable: false }
        ],
        possible_types: [
            {id: '04510', description: 'PAC'},
            {id: '04014', description: 'Sedex'}
        ]
    }),
    methods: {
        ...mapMutations("stock", [
            "setShipmentDialog"
        ]),
        ...mapActions("shipments", [
            'calcShipment'
        ]),
    }
}
</script>