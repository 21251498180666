<template>
  <div>
    <v-tabs v-model="main_tab" class="elevation-10 mb-2">
      <v-tab v-if="showFinancialAdmin()">Auditoria</v-tab>
      <v-tab v-if="showFinancialAdmin()">Despesas</v-tab>
    </v-tabs>
    <v-tabs-items v-model="main_tab" class="elevation-10">
      <v-tab-item>
        <iframe class="dash-bi-audit" src="https://app.powerbi.com/view?r=eyJrIjoiOTQ2MzZjNGItMjRlZC00MDgwLWE0MmItZjk0ZjcyMzAxNTNkIiwidCI6Ijg3NTIzMTA4LTlkZWUtNDE0ZS1hYjFmLTYwMDE2M2MwN2VhZiJ9&pageName=ReportSection" frameborder="0" allowFullScreen="true"></iframe>
      </v-tab-item>
      <v-tab-item>
        <iframe class="dash-bi-audit" src="https://app.powerbi.com/view?r=eyJrIjoiYzE4MDJlMDEtMjBlYS00OGIxLThmMmQtZDNmZTcxNDJiYzJmIiwidCI6Ijg3NTIzMTA4LTlkZWUtNDE0ZS1hYjFmLTYwMDE2M2MwN2VhZiJ9" frameborder="0" allowFullScreen="true"></iframe>
      </v-tab-item>
    </v-tabs-items>
   
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed : {
    ...mapState("login", ["userData"]),
  },
  data () {
    return {
      main_tab: '',
      tab: ''
    }
  },
  methods: {
    showFinancialAdmin() {
      let roles = [93, 97, 99]
      return roles.indexOf(this.userData.role) != -1
    }
  },
}
</script>

<style scoped>
  .dashboard-tab {
    background-color: #f2f2f2;
  }
  .dash-bi-audit {
    height: 90vh;
    width: 100%;
  }
</style>