<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline text-center justify-center">Filtro</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md>
            <v-layout wrap>

              <v-flex xs12 sm6 md6>
                <v-text-field
                  label="Nome"
                  v-model="name"
                  :clearable="true"
                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-text-field
                  label="Nick"
                  v-model="nick"
                  :clearable="true"
                  outlined
                ></v-text-field>
              </v-flex>

               <v-flex xs12 sm3 md3>
                <v-text-field
                  label="ID App"
                  v-model="id_app"
                  :clearable="true"
                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm5 md5>
                
               <v-select
                  :clearable="true"
                  clear-icon="mdi-close"
                  label="Tipos de Plataforma"
                  :items="plataform_types"
                  v-model="plataform_id"
                  item-text="description"
                  item-value="id"
                  outlined
                ></v-select>
                
              </v-flex>

              <v-flex xs12 sm4 md4>
                
               <v-select
                  :clearable="true"
                  clear-icon="mdi-close"
                  label="Status"
                  :items="possible_status"
                  v-model="status"
                  item-text="status"
                  item-value="id"
                  outlined
                ></v-select>
                
              </v-flex>

            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn
            text
            @click="
              showFilter(false);
              clearFilter();
            "
            >Cancelar</v-btn
          >
        </v-col>
        <v-col class="text-right">
          <v-btn text type="submit" right @click="filter()">Filtrar</v-btn>
        </v-col>
        
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "agent_slot_filter",
  computed: {
    ...mapFields("agent_slot", [
      "filter.plataform_id",
      "filter.status",
      "filter.id",
      "filter.id_app",
      "filter.name",
      "filter.nick",
      "plataform_types",
      "possible_status"
    ]),
  },   
  methods: {
    ...mapMutations("agent_slot", ["showFilter", "clearFilter"]),
    ...mapActions("agent_slot", ["getItems"]),
    filter() {
      this.getItems({ resetPage: false });
      this.showFilter({ show: false });
    },
  },
};
</script>
