import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../../config/endPoints';
import formatDate from '../../../util/formatDate.js';
// import { PAGE, TOKEN } from '../../../constants/tomticket.js';

import {
  defaultErrorNotification,
  defaultSuccessNotification,
} from '../../../constants';

const defaultFilter = {
  id_pppoker: '',
  value: '',
  start: '',
  end: '',
};

const defaultTomTicketItem = {
  id_pppoker: '',
  value: '',
  start: '',
  end: '',
};

export default {
  namespaced: true,
  state: {
    items: [],
    selectedItem: {},
    totalItems: 0,
    total_value: null,
    tab: null,
    loading: false,
    filtered: false,
    filterDialog: false,
    showDialog: false,
    filter: {
      id_pppoker: '',
      value: '',
      start: '',
      end: '',
    },
    notification: {
      show: false,
      message: '',
      type: '',
    },
    pagination: {
      page: 1,
      itemsPerPage: 10,
    },
  },
  mutations: {
    setDialog: (state, payload) => {
      state[payload.dialog] = payload.show;
    },
    setFilter: (state, payload) => (state.filter = payload),
    setFiltered: (state, payload) => (state.filtered = payload),
    setLoading: (state, payload) => (state.loading = payload),
    setSelectedItem: (state, payload) => (state.selectedItem = payload),
    setDefaultItem: (state) =>
      (state.selectedItem = Object.assign({}, defaultTomTicketItem)),
    setItems: (state, payload) => (state.items = payload),
    setTotalItems: (state, payload) => (state.totalItems = payload),
    showNotification: (state, payload) => (state.notification = payload),
    updateField,
  },
  actions: {
    clearFilter: ({ commit }) => {
      commit('setFilter', Object.assign({}, defaultFilter));
      commit('setDialog', { dialog: 'filterDialog', show: false });
    },
    getItems: async ({ commit, state }) => {
      let filter = state.filter;
      let url = endPoints.tom_ticket + '/tickets';
    //   let url = endPoints.tom_ticket + '/tickets/ + PAGE_PARAM';

      // url += '/chamados/' + TOKEN + '/' + PAGE;
      // url += "?page=" + state.pagination.page + "&itemsPerPage=" + state.pagination.itemsPerPage
      // https://api.tomticket.com/chamado/{token}/{identificador_chamado}

      commit('setFiltered', false);
      Object.keys(filter).forEach(function(field) {
        if (
          filter[field] !== '' &&
          filter[field] !== undefined &&
          filter[field] !== null
        ) {
          commit('setFiltered', true);

          if (field == 'end' || field == 'start') {
            var e = new Date(filter[field]);
            url +=
              '&' + field + '=' + formatDate(e) + ' ' + e.toLocaleTimeString();
          } else {
            url += '&' + field + '=' + filter[field];
          }
        }
      });

      if (
        state.pagination &&
        state.pagination.sortBy &&
        state.pagination.sortBy.length > 0
      ) {
        url +=
          '&sort_by=' +
          (state.pagination.sortBy[0] === 'date'
            ? 'id'
            : state.pagination.sortBy[0]);
        url += '&sort_asc=' + state.pagination.sortAsc[0];
      }
      commit('setLoading', true);
      try {
        commit('setDefaultItem');
        let items = await axios.get(url);
        commit('setItems', items.data.data);
        commit('setTotalItems', items.data.total_itens);
        commit('setDialog', { dialog: 'filterDialog', show: false });
        defaultSuccessNotification();
      } catch (error) {
        commit(
          'showNotification',
          defaultErrorNotification(error?.response?.data?.message)
        );
      }
      commit('setLoading', false);
    },
  },
  getters: {
    getField,
  },
};
