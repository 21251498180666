<template>
  <div>
    <v-card class="elevation-10">
      <v-card-title class="text-center justify-center">
        Usuários do Agente
      </v-card-title>
      <v-spacer></v-spacer>
      <v-snackbar
        :color="notificationType"
        right
        top
        :timeout="2000"
        v-model="showNotification"
      >
        {{ notificationMessage }}
      </v-snackbar>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            color="black"
            dark
            class="add-align-right"
            @click="add"
            small
          >
            <v-icon>mdi-plus</v-icon></v-btn
          >
        </template>
        <span>Adicionar</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            color="black"
            dark
            class="add-align-right"
            @click="getItems"
            small
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>
        <span>Recarregar</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            color="black"
            dark
            class="add-align-right"
            @click="showFilter({ show: true })"
            small
          >
            <v-icon>mdi-filter</v-icon>
          </v-btn>
        </template>
        <span>Filtrar</span>
      </v-tooltip>
    </v-card>

    <v-data-table
      :headers="headers"
      :loading="loading"
      :items="items"
      :options.sync="pagination"
      :server-items-length="totalItems"
      no-data-text="Nenhum usuário encontrado"
      class="elevation-10 table-margin"
    >
      <template v-slot:[`item.type`]="{ item }">
        <span>{{ getType(item.type) }}</span>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          :color="getColorStatus(item.status)"
          class="ma-2"
          text-color="white"
          label
          >{{ getStatus(item.status) }}</v-chip
        >
      </template>
      <template v-slot:[`item.default_password`]="{ item }">
        <span>{{ getIsDefaultPassword(item.default_password) }}</span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              v-on="on"
              v-bind="attrs"
              class="mr-2"
              @click="edit(item)"
              >mdi-pencil</v-icon
            >
          </template>
          <span>Editar Usuário</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              v-on="on"
              v-bind="attrs"
              class="mr-2"
              @click="showDeleteDialog(item)"
              >mdi-delete</v-icon
            >
          </template>
          <span>Excluir Usuário</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog
      :value="dialog"
      persistent
      width="600"
      @click:outside="closeDialog()"
    >
      <users-form></users-form>
    </v-dialog>

    <v-dialog
      :value="dialogFilter"
      persistent
      width="600"
      @click:outside="showFilter({ show: false }), clearFilter()"
    >
      <users-filter></users-filter>
    </v-dialog>

    <v-dialog :value="passwordDialog" persistent width="600">
      <v-card>
        <v-layout class="justify-center">
          <v-card-title> Senha do Usuário Cadastrado </v-card-title>
        </v-layout>
        <v-layout class="justify-center">
           {{ random_password }} 
          <template>
            <v-icon color="blue" small center @click="copyToClipBoard(random_password)" class="ml-3">mdi-content-copy</v-icon>
          </template>
        </v-layout>

        <v-layout class="justify-center">
          <v-card-actions>
            <v-col>
              <v-btn large color="primary" center @click="closeDialogPassword()"
                >OK</v-btn
              >
            </v-col>
          </v-card-actions>
        </v-layout>
      </v-card>
    </v-dialog>

    <v-dialog :value="dialogDelete" persistent width="500">
      <v-card>
        <v-card-title class="headline">Confirmar a exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn text @click="closeDeleteDialog()">Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn text type="submit" right @click="deleteUser"
              >Confirmar</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import UsersForm from "./UsersForm.vue";
import UsersFilter from "./UsersFilter.vue";
export default {
  name: "Users",
  components: {
    UsersForm,
    UsersFilter,
  },
  computed: {
    ...mapState("agent_users", [
      "state",
      "selectedItem",
      "loading",
      "items",
      "totalItems",
      "dialog",
      "notificationType",
      "notificationMessage",
      "dialogDelete",
      "dialogFilter",
      "passwordDialog",
      "possible_types",
      "possible_status",
      "is_default_password",
    ]),
    ...mapFields("agent_users", [
      "pagination",
      "selectedItem.random_password",
      "showNotification",
    ]),
  },
  created() {
    this.getItems({ resetPage: true });
  },
  methods: {
    ...mapActions("agent_users", [
      "getItems",
      "save",
      "closeDialog",
      "deleteUser",
      "showDeleteDialog",
      "closeDialogPassword",
      "closeDeleteDialog",
    ]),
    ...mapMutations("agent_users", [
      "add",
      "edit",
      "showFilter",
      "showDialogPassword",
      "showDialogDelete",
      "clearFilter",
      "showCopyNotification"
    ]),
    copyToClipBoard(textToCopy) {
      this.showCopyNotification()
      navigator.clipboard.writeText(textToCopy);
    },
    getColorStatus(status) {
      if (status == 0) {
        return "deep-orange darken-4";
      } else {
        return "green darken-3";
      }
    },
    getIsDefaultPassword(item) {
      return this.is_default_password[item].text;
    },
    getType(value) {
      return this.possible_types[value].type;
    },
    getStatus(value) {
      return this.possible_status[value].status;
    },
  },
  watch: {
    pagination: {
      handler() {
        this.getItems({ resetPage: false });
      },
    },
  },
  data: () => ({
    headers: [
      { text: "Id", value: "id", sortable: true },
      { text: "Nome", value: "name", sortable: true, align: "center" },
      { text: "Email", value: "email", sortable: false, align: "center" },
      { text: "Status", value: "status", sortable: true, align: "center" },
      {
        text: "Data de Criação",
        value: "date_created",
        sortable: true,
        align: "center",
      },
      {
        text: "Senha Padrão",
        value: "default_password",
        sortable: false,
        align: "center",
      },
      { text: "Tipo", value: "type", sortable: false, align: "center" },
      { text: "Ações", value: "actions", sortable: false },
    ],
  }),
};
</script>
