<template>
  <div>
    <v-card>
      <p class="text-center font-weight-medium text-h6">
        VALOR TOTAL: {{ this.deposits_total_value }}
      </p>

      <v-data-table
        :headers="headers"
        :loading="loading"
        :items="items"
        :options.sync="pagination"
        :server-items-length="totalItems"
        :item-class="getClassColor"
        :footer-props="{
          'items-per-page-options': [10, 20, 50, 100],
          'items-per-page-text': 'Itens por página',
          'show-first-last-page': true,
          'page-text': `Página ${pagination.page} de ${parseInt(totalItems/pagination.itemsPerPage) + 1}`
        }"
        no-data-text="Nenhum depósito encontrado"
        class="elevation-10 table-margin"
      >
        <template v-slot:[`item.date`]="{ item }">
          <span>{{ getDate(item.date) }}</span>
        </template>
        <template v-slot:[`item.date_created`]="{ item }">
          <span>{{ getDate(item.date_created) }}</span>
        </template>
        <template v-slot:[`item.finish_at`]="{ item }">
          <span>{{ getDate(item.finish_at) }}</span>
        </template>
        <!-- <template v-slot:[`item.value`]="{ item }">
          <span>{{
            item.value.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })
          }}</span>
        </template> -->
        <template v-slot:[`item.status`]="{ item }">
          <span>{{ getStatus(item.status) }}</span>
        </template>
        <template v-slot:[`item.payment_type`]="{ item }">
          <span>{{ getPaymentType(item.payment_type) }}</span>
        </template>
        <template v-slot:[`item.type`]="{ item }">
          <span>{{
            item.id_sx ? getType(item.type) + "-SX" : getType(item.type)
          }}</span>
        </template>
        <template v-slot:[`item.validation_state`]="{ item }">
          <v-chip
            :color="getColorValidation(item.validation_state)"
            class="ma-2"
            :text-color="item.validation_state != 0 ? 'white' : 'black'"
            label
            >{{ getValidationState(item.validation_state) }}</v-chip
          >
        </template>
        <template v-slot:[`item.sended`]="{ item }">
          <span>{{ getSendedStatus(item.sended) }}</span>
        </template>
        <template v-slot:[`item.type_send`]="{ item }">
          <v-chip
            :color="getColorByTypeSend(item.type_send)"
            class="ma-2"
            :text-color="item.type_send != 0 ? 'white' : 'black'"
            label
            >{{ getSendType(item.type_send) }}</v-chip
          >
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "PlayerDeposits",

  methods: {
    ...mapActions("player_history", ["getItems"]),
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      }
      return false;
    },
    getColorByTypeSend(type_send) {
      if (type_send == 0) {
        return "gray";
      } else if (type_send == 1) {
        return "cyan";
      } else if (type_send == 2 || type_send == 5) {
        return "green";
      } else if (type_send == 3 || type_send == 4) {
        return "red";
      }
    },
    getColorValidation(validation_state) {
      if (validation_state == 1 || validation_state == 6) {
        return "indigo";
      } else if (validation_state == 2) {
        return "orange";
      } else if (validation_state == 5 || validation_state == 4) {
        return "red";
      }
    },
    getDate(date) {
      if (date) {
        var d = new Date(date);
        return d.toLocaleDateString() + " " + d.toLocaleTimeString();
      }
      return "";
    },
    getSendType(type) {
      switch (type) {
        case 0:
          return "Pendente";
        case 1:
          return "Solicitado";
        case 2:
          return "Realizado";
        case 3:
          return "Manual";
        case 4:
          return "Manual - PB";
        case 5:
          return "Realizado - PB";
        case 6:
          return "Duplicado"
      }
    },
    getPaymentType(type) {
      return this.payment_types[type];
    },
    getType(type) {
      return this.possible_types[type];
    },
    getStatus(status) {
      return this.possible_status[status];
    },
    getClassColor(item) {
      if (item.status == 2) {
        return "row-green";
      } else if (item.status == 0) {
        if (item.validation_state == 1 || item.validation_state == 6) {
          return "row-blue";
        }
        return "row-red";
      } else if (item.status == 5) {
        return "row-yellow-schedule";
      } else if (item.status == 6) {
        if (item.type_send == 3) {
          return "row-refused";
        }
        return "row-teal";
      } else if (item.status == 7) {
        return "row-pink";
      }
    },
    getValidationState(validation) {
      let validation_types = [
        "Pendente",
        "Automática",
        "Manual",
        "Não encontrado",
        "Duplicado",
        "Valor Diferente",
        "Manual - Sistema",
      ];
      return validation_types[validation];
    },
  },
  computed: {
    ...mapState("player_history", [
      "filtered",
      "items",
      "loading",
      "totalItems",
      "deposits_total_value",
    ]),
    ...mapFields("player_history", [
      "current_tab",
      "pagination",
      "filter.id_pppoker",
      "filter.value",
      "filter.status",
      "filter.start",
      "filter.end",
    ]),
  },
  data: () => ({
    possible_types: [
      "Envio Jogador",
      "Envio Agência",
      "Abater Anotados",
      "Adicionar ao Saldo Positivo",
      "Transferência",
      "Bônus",
      "Reembolso",
    ],
    possible_status: [
      "Pendente",
      "Em Andamento",
      "Concluído",
      "Estornado",
      "Aguardando Pagamento",
      "Agendado/Erro",
      "Validado",
      "Recusado",
    ],
    payment_types: [
      "Transferência",
      "Anotado",
      "Troca de Ticket",
      "Pix",
      "Bônus",
    ],
    validation_types: [
      "Pendente",
      "Automática",
      "Manual",
      "Não encontrado",
      "Duplicado",
    ],
    headers: [
      { text: "Id", value: "id", sortable: false },
      { text: "Banco", value: "bank_name", sortable: false },
      { text: "Valor", value: "value", sortable: false }, // soma valor
      { text: "Clube", value: "club_name", sortable: false },
      { text: "Id Suprema", value: "id_pppoker", sortable: false },
      { text: "Nick", value: "nick", sortable: false },
      { text: "Status", value: "status", sortable: false },
      { text: "Pagamento", value: "payment_type", sortable: false },
      { text: "Fichas", value: "type", sortable: false },
      { text: "Validação", value: "validation_state", sortable: false },
      { text: "Status do Envio", value: "type_send", sortable: false },
      { text: "Criado em", value: "date_created", sortable: false },
    ],
  }),
  watch: {
    pagination: {
      handler() {
        if (this.filtered) {
          if(this.current_tab == 'deposits') {
            this.getItems({ 
              end_point: 'deposits',
              extend_url: '',
              resetPage: false
            })
          }
        }
      },
    },
  },
};
</script>
