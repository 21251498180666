const possible_roles = [
    { role: 0, description: "Atendente" },
    { role: 1, description: "Atendimento Conferência" },
    { role: 2, description: "Financeiro" },
    { role: 3, description: "Comercial" },
    { role: 4, description: "Marketing" },
    { role: 5, description: "Financeiro 2" },
    { role: 6, description: "Atendimento Saque" },
    { role: 7, description: "Atendimento Marketing" },
    { role: 8, description: "Auditoria" },
    { role: 9, description: "Recursos Humanos" },
    { role: 70, description: "Dono de Clube" },
    { role: 80, description: "Supervisão Chat" },
    { role: 81, description: "Supervisão Financeira" },
    { role: 94, description: "Gerente Marketing" },
    { role: 95, description: "Gerente RH" },
    { role: 96, description: "Gerente Comercial" },
    { role: 97, description: "Gerente Financeiro" },
    { role: 98, description: "Gerente Chat" },
    { role: 99, description: "Administrador" }
  ];

  export default possible_roles;