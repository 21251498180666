// const ruleAccountType = (text) => {

//     if (text.toLowerCase().indexOf("cor") != -1) {
//         return 0
//     } else if (text.toLowerCase().indexOf("pou") != -1) {
//         return 1
//     }
// };

const ruleValue = (text) => {
    text = text.replace(",", ".")
    var float = parseFloat(text)
    if (!isNaN(float) && float != "" && float != undefined) {
        return float
    } else {
        return 0
    }
}

const ruleInscription = (text) => {
    text = text.replace(/\./g, "")
    text = text.replace(/-/g, "")
    text = text.replace(/\//, "")
    return text
}

// const ruleBank = (text, banks) => {
//     if (text) {
//         text = text.toLowerCase();
//         text = text.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a');
//         text = text.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e');
//         text = text.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i');
//         text = text.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o');
//         text = text.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u');
//         text = text.replace(new RegExp('[Ç]', 'gi'), 'c');
//         let findByName = (b) => {
//             return b.name && b.name.toLowerCase().indexOf(text) != -1
//         }
//         let bank = banks.find(findByName)
//         if (bank) {
//             return bank.id
//         } else {
//             return ''
//         }
//     } else {
//         return ""
//     }
// }

const rulePixKey = (text) => {
    let replace = text.replaceAll("-", "").replaceAll(".", "").replaceAll("/", "")
    if(replace.length < 14) {
        return text.replaceAll("-", "").replaceAll(".", "").replaceAll("/", "")
    } else {
        return text
    }
}

/*const ruleClub = (text, clubs) => {
    let int = parseInt(text)
    let club = ''
    console.log(int)
    let findById = (c) => {
        if(c.id_pppoker && c.id_pppoker != null) {
            console.log(text.length, c.name, c.id_pppoker, text == c.id_pppoker)
            return c.id_pppoker.indexOf(text) != -1
        } 
        return false
    }
    let findByName = (c) => {
        return c.name && c.name.toLowerCase().indexOf(text) != -1
    }
    if (!isNaN(int) && int != "" && int != undefined) {
        club = clubs.find(findById)
    } else {
        club = clubs.find(findByName)
    }
    if (club && club != '' && club.id) {
        return club.id_instance
    }
}*/

const withdrawal_template = {
    1: { field: 'name' },
    5: { field: 'inscription', rule: ruleInscription },
    8: { field: 'idApp' },
    11: { field: 'value', rule: ruleValue },
    12: { field: 'pixKey', rule: rulePixKey }
};

export default withdrawal_template