<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-card class="elevation-10"> 
      <v-card-title class="text-center justify-center">
        Documentos
      </v-card-title>
      <v-btn color="black"  @click="add" dark class="add-align-right" small>
          <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-data-table
        :headers="headers"
        :loading="loading"
        :items="items"
        :server-items-length="totalItems"
        hide-default-footer
        no-data-text="Nenhum documento encontrado"
        class="elevation-10 table-margin"
      >
        <template v-slot:item.type_document="{ item }">
          <span>{{ getTypeDocument(item.type_document) }}</span>
        </template>
        
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{on, attrs}">
              <v-icon small  v-on="on" v-bind="attrs" class="mr-2" @click="showDocument({item: item, download: false})">mdi-receipt</v-icon>
            </template>
            <span>Visualizar</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{on, attrs}">
              <v-icon small  v-on="on" v-bind="attrs" class="mr-2" @click="showDocument({item: item, download: true})">mdi-download-outline</v-icon>
            </template>
            <span>Baixar</span>
          </v-tooltip>
          <v-icon small class="mr-2" @click="showDeleteDialog(item)">mdi-delete</v-icon>
        </template>

      </v-data-table>
      <v-dialog :value="dialog" @click:outside="closeDialog" width="1280">
        <documents-agent-form></documents-agent-form>
      </v-dialog>

      <v-dialog v-model="dialogShowDocument" @click:outside="setDialogShowDocument({show: false})">
        <v-card height="90vh">
          <v-img max-height="100%" :src="documentToShow" contain></v-img>
        </v-card>
      </v-dialog>

      
    <v-dialog :value="dialogDelete" persistent width="300">
      <v-card>
        <v-card-title class="headline">Confirmar exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn
              text
              @click="deleteDialog({show: false, item: {}})"
            >Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn
              text
              type="submit"
              right
              @click="deleteItem"
            >Confirmar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import DocumentsAgentForm from './DocumentsAgentForm.vue';

export default {
    name: "DocumentsAgent",
    computed: {
      ...mapState("documents_agent", ["loading", "dialog", "dialogDelete", "dialogShowDocument", "documentToShow", "overlay", "mode", "items", "totalItems"]),
    },
    components: {
      "documents-agent-form": DocumentsAgentForm, 
    },
    created() {
      this.getItems()
      this.$validator.extend("required", {
        getMessage: (field, [label]) => label + " deve ser informado",
        validate: (_, [, field]) => {
            if (this[field] || this[field] === 0) {
            return true;
            } else {
                return false;
            }
        },
      });
    },
    methods: {
      ...mapMutations("documents_agent", ["add", "closeDialog", "setDialogShowDocument"]),
      ...mapActions("documents_agent", ["closeDeleteDialog", "deleteItem", "getItems", "save", "showDocument", "showDeleteDialog"]),
      getTextAgent(item) {
          if(item) {
              if(item.id_pppoker) {
                  return item.id_pppoker + " | " + item.nick + " | " + item.club_name
              } else {
                  return item.nick
              }
          }
          return ''
      },
      getTypeDocument(type) {
        let types = ["Identidade/CPF", "CNPJ", "Comprovante de Endereço", "Contrato", "Outros"]
        return types[type]
      },
      saveDocument() {
        this.$validator.validateAll().then(valid => {
          if (valid) {  
            this.save();
          }
        });
      }
    },
    data: () => ({
      headers: [
        { text: "Descrição", value: "description", sortable: false },
        { text: "Tipo de Documento", value: "type_document", sortable: false, align: 'center' },
        { text: "Nome do Arquivo", value: "file_name", sortable: false, align: 'right' },
        { text: "Ações", value: "actions", sortable: false, align: 'right'}
      ],
      valid: false,
      types_document: [
        {
            id: 0, 
            description: "Identidade/CPF"
        },
        {
            id: 1, 
            description: "CNPJ"
        },
        {
            id: 2, 
            description: "Comprovante de Endereço"
        },
        {
            id: 3, 
            description: "Contrato"
        },
        {
            id: 4, 
            description: "Outros"
        }
      ]
    })
};
</script>

<style>
@import "../css/agent.css";
</style>
