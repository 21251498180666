const withdrawal_filter = {
    id_pppoker: '',
    id_instance: '',
    inscription: '',
    name: '',
    bank: '',
    bank_payment: '',
    pix_key: '',
    value: '',
    status: '',
    start: '',
    end: ''
};

export default withdrawal_filter