<template>
  <div>
    <div @drop.prevent="addDropFile" @dragover.prevent>
      <v-card>
        <v-card-title>
          <span class="headline text-center">Rota</span>
          <v-spacer></v-spacer>
          <v-progress-circular
            v-if="loading"
            indeterminate
            class="text-right"
            color="primary"
            size="24"
          ></v-progress-circular>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Nome *"
                    v-model="name"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('name')"
                    v-validate="'required:Nome,name'"
                    data-vv-name="name"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-select
                    label="Tipo *"
                    required
                    :items="possible_type"
                    v-model="type"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('type')"
                    v-validate="'required:Type,type'"
                    data-vv-name="type"
                    outlined
                    item-text="text"
                    item-value="value"
                  >
                  </v-select>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-autocomplete
                    :readonly="this.mode == 'VIEW'"
                    clearable
                    deletable-chips
                    v-model="menu"
                    :no-filter="true"
                    :items="menus"
                    outlined
                    chips
                    small-chips
                    no-data-text="Nenhum menu encontrado"
                    item-text="name"
                    item-value="id"
                    placeholder="Digite aqui o nome da tela relacionada"
                    label="Tela Relacionada"
                    :search-input.sync="searchMenu"
                  ></v-autocomplete>
                </v-flex>

              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-col>
            <v-btn text @click="closeDialog">{{ mode == 'VIEW' ? 'Voltar' : 'Cancelar'}}</v-btn>
          </v-col>
          <v-col class="text-right" v-if="mode != 'VIEW'">
            <v-btn text type="submit" right @click="saveRoute">Salvar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import debounce from '../../util/debounce.js' 
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "RoutesForm",
  props: ["screen", "instances"],
  computed: {
    ...mapGetters("login", ["getInstanceId"]),
    ...mapState("routes", [
      "menus",
      "route",
      "loading",
      "mode",
    ]),
    ...mapState("instances", ["instances"]),
    ...mapFields("routes", [
      "dialogReceipt",
      "editedRoute.id_menu",
      "editedRoute.menu",
      "editedRoute.type",
      "editedRoute.name",
      // ...
    ]),
  },
  mixins: [debounce],
  mounted() {
    this.getChildrenSearch = this.debounce(this.getChildrenSearch, 500); 
  },
  created() {
    this.setScreen({ screen: this.$props.screen })
    this.getInstances()
    this.$validator.extend("required", {
      getMessage: (field, [label]) => label + " deve ser informado",
      validate: (_, [, field]) => {
        if (this[field] || this[field] === 0) {
          return true;
        } else {
          return false;
        }
      },
    });
  },
  methods: {
    ...mapActions("routes", [
      "getChildrenSearch",
      "getAttendants",
      "getUsers",
      "getAgents",
      "closeDialog",
      "save",
    ]),
    ...mapActions("instances", ["getInstances"]),
    ...mapMutations("routes", ["setScreen"]),
    saveRoute() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.save();
        }
      });
    },
  },
  data: () => ({
    possible_type: [
      {
        text: "GET",
        value: 0,
      },
      {
        text: "PUT",
        value: 1,
      },
      {
        text: "POST",
        value: 2,
      },
      {
        text: "PATCH",
        value: 3,
      },
      {
        text: "DELETE",
        value: 4,
      }
    ],
    searchMenu: '',
  }),
  watch: {
    searchMenu (val) {
      var payload = {
        search: val
      }
      this.getChildrenSearch(payload)
    }
  },
};
</script>