var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-snackbar',{attrs:{"color":_vm.notification.type,"right":"","top":"","timeout":2000},model:{value:(_vm.notification.show),callback:function ($$v) {_vm.$set(_vm.notification, "show", $$v)},expression:"notification.show"}},[_vm._v(" "+_vm._s(_vm.notification.message)+" ")]),_c('v-dialog',{attrs:{"value":_vm.filterDialog,"width":"600"},on:{"click:outside":function($event){return _vm.setDialog({ dialog: 'filterDialog', show: false })}}},[_c('player-history-filter')],1),_c('v-toolbar',[_c('v-toolbar-title',{staticClass:"font-weight-medium"},[_vm._v("Histórico do Jogador")]),_c('v-spacer'),_c('div',{staticClass:"button-toolbar text-right"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"color":"black","dark":"","small":""},on:{"click":function($event){return _vm.setDialog({ dialog: 'filterDialog', show: true })}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-filter")])],1)]}}])},[_c('span',[_vm._v("Filtro")])])],1)],1),_c('v-tabs',{staticClass:"player-data",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{on:{"click":function($event){return _vm.getItems({
          end_point: 'deposits',
          extend_url: '',
          resetPage: true,
        })}}},[_vm._v("Depósitos")]),_c('v-tab',{on:{"click":function($event){return _vm.getItems({
          end_point: 'withdrawal',
          extend_url: '',
          resetPage: true,
        })}}},[_vm._v("Saques")]),_c('v-tab',{on:{"click":function($event){return _vm.getItems({
          end_point: 'player_history',
          extend_url: '/rake',
          resetPage: true,
        })}}},[_vm._v("Rake Gerado")]),_c('v-tab',{on:{"click":function($event){return _vm.getItems({
          end_point: 'player_history',
          extend_url: '/closure',
          resetPage: true,
        })}}},[_vm._v("Fechamento Semanal")]),_c('v-tab',{on:{"click":function($event){return _vm.getItems({
          end_point: 'periods_closure_transactions',
          extend_url: '',
          resetPage: true,
        })}}},[_vm._v("Transações")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{staticClass:"elevation-15"},[_c('player-deposits')],1),_c('v-tab-item',{staticClass:"elevation-15"},[_c('player-withdrawals')],1),_c('v-tab-item',{staticClass:"elevation-15"},[_c('player-rake')],1),_c('v-tab-item',{staticClass:"elevation-15"},[_c('player-closure')],1),_c('v-tab-item',{staticClass:"elevation-15"},[_c('player-transactions')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }