<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2500"
      v-model="showNotification"
    >{{ notificationMessage }}</v-snackbar>
    <v-list subheader v-if="isMobile()">
      <v-list-item>
        <v-row no-gutters>
          <v-flex xs12 sm3 md3>
            <v-datetime-picker
              v-model="startDate"
              date-format="dd/MM/yyyy"
              :time-picker-props="timeProps"
              label="De"
            >
              <template slot="dateIcon">
                <v-icon>mdi-calendar</v-icon>
              </template>
              <template slot="timeIcon">
                <v-icon>mdi-clock</v-icon>
              </template>
            </v-datetime-picker>
          </v-flex>
          <v-flex xs12 sm3 md3>
            <v-datetime-picker
              v-model="endDate"
              date-format="dd/MM/yyyy"
              :time-picker-props="timeProps"
              label="Até"
            >
              <template slot="dateIcon">
                <v-icon>mdi-calendar</v-icon>
              </template>
              <template slot="timeIcon">
                <v-icon>mdi-clock</v-icon>
              </template>
            </v-datetime-picker>
          </v-flex>
          <v-flex xs12 sm3 md3>
            <v-autocomplete
              clearable
              v-model="user_id"
              :no-filter="true"
              :items="users"
              dense
              chips
              small-chips
              no-data-text="Nenhum usuario encontrado"
              :item-text="getText"
              item-value="id"
              placeholder="Digite aqui o nome do usuario"
              label="Usuario"
              :search-input.sync="searchInput"
            ></v-autocomplete>
            <v-btn color="black" dark class="ma-2" @click="getItems" small>Filtrar</v-btn>
          </v-flex>
        </v-row>
      </v-list-item>
      <v-col xs="6" md="6" sm="6">Total: {{ total }}</v-col>
      <v-col xs="6" md="6" sm="6">Tempo Médio: {{ avg_time }}</v-col>
    </v-list>
    <div v-else>
      <v-toolbar flat>
        <v-col xs="3" md="3" sm="12">
          <v-datetime-picker
            v-model="startDate"
            date-format="dd/MM/yyyy"
            :time-picker-props="timeProps"
            label="De"
          >
            <template slot="dateIcon">
              <v-icon>mdi-calendar</v-icon>
            </template>
            <template slot="timeIcon">
              <v-icon>mdi-clock</v-icon>
            </template>
          </v-datetime-picker>
        </v-col>
        <v-col xs="3" md="3" sm="12">
          <v-datetime-picker
            v-model="endDate"
            date-format="dd/MM/yyyy"
            :time-picker-props="timeProps"
            label="Até"
          >
            <template slot="dateIcon">
              <v-icon>mdi-calendar</v-icon>
            </template>
            <template slot="timeIcon">
              <v-icon>mdi-clock</v-icon>
            </template>
          </v-datetime-picker>
        </v-col>
        <v-col xs="5" md="5" sm="12">
          <v-flex xs12 sm12 md12>
            <v-autocomplete
              clearable
              v-model="user_id"
              :no-filter="true"
              :items="users"
              dense
              chips
              small-chips
              no-data-text="Nenhum usuario encontrado"
              :item-text="getText"
              item-value="id"
              placeholder="Digite aqui o nome do usuario"
              label="Usuario"
              :search-input.sync="searchInput"
            ></v-autocomplete>
          </v-flex>
        </v-col>
        <v-col xs="2" md="2" sm="12">
          <v-flex xs12 sm12 md12>
            <v-btn color="black" dark class="ma-2" @click="getItems" small>Filtrar</v-btn>
          </v-flex>
        </v-col>
      </v-toolbar>
      <v-toolbar flat>
        <v-col xs="4" md="4" sm="4">Total: {{ total }}</v-col>
        <v-col xs="4" md="4" sm="4">Tempo Médio: {{ avg_time }}</v-col>
        <v-col xs="4" md="4" sm="4">1º Contato: {{ avg_time_first_contact }}</v-col>
      </v-toolbar>
    </div>
    <v-dialog :value="dialog_message_at" @click:outside="showDialogMessagesAttendance({show: false, mode: ''})" width="800">
      <div class="background_messages">
        <div v-for="(message,index) in messages_reverse" v-bind:key="index">
            <message class="message" :index="index" :isGroup="isGroup(message.contact_id)" :message="message" :lastMessage="getLastMessage(index)" :class="{own: message.type == 0}" :openedChat="{contact_id: message.contact_id, contact_name: message.contact_name}" :reference="false">
            </message>
        </div>
      </div>
    </v-dialog>
    <v-data-table :headers="headers" :items="attendances" class="elevation-1 table-at">
      <template v-slot:[`item.contact_id`]="{ item }">
        <span>{{ (item.participant_phone) ? item.participant_phone : item.contact_id }}</span>
      </template>
      <template v-slot:[`item.participant_phone`]="{ item }">
        <span>{{ (item.participant_phone) ? item.contact_id : '' }}</span>
      </template>
      <template v-slot:[`item.receive_time`]="{ item }">
        <span>{{ getDate(item.receive_time)}}</span>
      </template>
      <template v-slot:[`item.ended_time`]="{ item }">
        <span>{{ getDate(item.ended_time)}}</span>
      </template>
      <template v-slot:[`item.time`]="{ item }">
        <span>{{ getDifference(item.receive_time, item.ended_time)}}</span>
      </template>
      <template v-slot:[`item.start_time`]="{ item }">
        <span>{{ getDifference(item.receive_time, item.start_time)}}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="dialogMessagesAttendance({show: true, item: item})">mdi-chat-outline</v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import Message from "../Chat/components/Message"

import debounce from "../../../util/debounce.js";

export default {
  components: {
    'message': Message,
  },
  computed: {
    ...mapFields("manage_attendance", ["dialogTransferAttendance", "showNotification"]),
    ...mapState("manage_attendance", [
      "avg_time",
      "avg_time_first_contact",
      "total",
      "attendances",
      "attendance_transfer",
      "dialog_message_at",
      "overlay",
      "users",
      "users_name",
      "messages_at",
      "notificationType",
      "notificationMessage"
    ]),
    messages_reverse: {
      get: function() {
        var b = Object.assign(this.messages_at)
        return b.reverse()
      }
    },
  },
  created() {},
  data: () => ({
    date: "",
    headers: [
      { text: "Número", value: "contact_id" },
      { text: "Grupo", value: "participant_phone" },
      { text: "Nome", value: "contact_name" },
      { text: "Atendente", value: "attendant_name"},
      { text: "Inicio", value: "receive_time" },
      { text: "Fim", value: "ended_time" },
      { text: "Tempo", value: "time" },
      { text: "1º Contato", value: "start_time" },
      { text: "Ações",  value: "actions", sortable: false, align: "center" },
    ],
    initDate: "",
    menu1: false,
    menu2: false,
    startDate: "",
    user_id: "",
    endDate: "",
    timeProps: {
      format: "24hr",
    },
    userId: "",
    searchInput: "",
  }),
  mixins: [debounce],
  mounted() {
    this.getAttendants = this.debounce(this.getAttendants, 500);
  },
  methods: {
    ...mapMutations("manage_attendance", ["showDialogMessagesAttendance"]),
    ...mapActions("manage_attendance", [
      "dialogMessagesAttendance",
      "finishAttendance",
      "getAttendants",
      "getUsers",
      "getAttendances",
      "transferAttendance",
    ]),
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      }
      return false;
    },
    isGroup(contact_id) {
      return contact_id.indexOf("-") != -1
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    getLastMessage(index) {
      if(index != 0) {
          return this.messages_at[index - 1]
      }
      return {}
    },
    getDate(time) {
      if (time) {
        var date = new Date(time * 1000);
        return date.toLocaleDateString() + " " + date.toLocaleTimeString();
      }
      return "";
    },
    getDifference(time1, time2) {
      if (time1 && time2) {
        var delta = Math.abs(time1 - time2);

        // calculate (and subtract) whole hours
        var hours = Math.floor(delta / 3600) % 24;
        delta -= hours * 3600;

        // calculate (and subtract) whole minutes
        var minutes = Math.floor(delta / 60) % 60;
        delta -= minutes * 60;
        var seconds = delta % 60;

        if (hours.toString().length == 1) {
          hours = "0".concat(hours.toString());
        }
        if (minutes.toString().length == 1) {
          minutes = "0".concat(minutes.toString());
        }
        if (seconds.toString().length == 1) {
          seconds = "0".concat(seconds.toString());
        }
        // what's left is seconds
        return hours + ":" + minutes + ":" + seconds;
      }
    },
    getItems() {
      if (this.startDate && this.endDate) {
        this.getAttendances({
          start_date: this.startDate,
          end_date: this.endDate,
          user_id: (this.user_id) ? this.user_id : null,
        });
      }
    },
    getText(item) {
      return item.id + " - " + item.name;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");

      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
  watch: {
    searchInput(val) {
      if (val) {
        var payload = {
          search: val,
        };
        this.getAttendants(payload);
      }
    },
  },
};
</script>

<style scoped>
@import "./css/manage_attendance.css";
@import "../Chat/components/css/message.css";
</style>
