<template>
  <div>
    <v-card>
      <p class="text-center">
        Mãos jogadas: R${{ this.rake_hands_played }} ||
        Ganho total: R${{ this.rake_player_tax }} ||
        Taxa total: R${{ this.rake_player_gains }} 
      </p>

      <v-data-table
        :headers="headers"
        :loading="loading"
        :items="items"
        :options.sync="pagination"
        :server-items-length="totalItems"
        :item-class="getClassColor"
        no-data-text="Nenhuma registro encontrado"
        class="elevation-10 table-margin"
      >
        <template v-slot:[`item.player_gains`]="{ item }">
          <span>{{
            item.player_gains 
              ? item.player_gains.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })
              : "R$ 0.00"
          }}</span>
        </template>
        <template v-slot:[`item.player_tax`]="{ item }">
          <span>{{
            item.player_tax 
              ? item.player_tax.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })
              : "R$ 0.00"
          }}</span>
        </template>
        <template v-slot:[`item.player_spin`]="{ item }">
          <span>{{
            item.player_spin 
              ? item.player_spin.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })
              : "R$ 0.00"
          }}</span>
        </template>
        <template v-slot:[`item.received`]="{ item }">
          <span>{{
            item.received 
              ? item.received.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })
              : "R$ 0.00"
          }}</span>
        </template>
        <template v-slot:[`item.sended`]="{ item }">
          <span>{{
            item.sended 
              ? item.sended.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })
              : "R$ 0.00"
          }}</span>
        </template>
        <template v-slot:[`item.balance`]="{ item }">
          <span>{{
            item.balance 
              ? item.balance.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })
              : "R$ 0.00"
          }}</span>
        </template>
        <template v-slot:[`item.player_total_mtt`]="{ item }">
          <span>{{
            item.player_total_mtt 
              ? item.player_total_mtt.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })
              : "R$ 0.00"
          }}</span>
        </template>
        <template v-slot:[`item.player_total_sng`]="{ item }">
          <span>{{
            item.player_total_sng 
              ? item.player_total_sng.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })
              : "R$ 0.00"
          }}</span>
        </template>
        <template v-slot:[`item.player_total_rg`]="{ item }">
          <span>{{
            item.player_total_rg 
              ? item.player_total_rg.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })
              : "R$ 0.00"
          }}</span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "PlayerRake",

  methods: {
    ...mapActions("player_history", ["getItems"]),
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      }
      return false;
    },
    getClassColor(item) {
      if (item.status == 0 && item.type_deal != 4 && item.type_deal != 5) {
        return "row-red";
      } else if (item.status == 3) {
        return "row-teal";
      } else if (item.status == 4) {
        return "row-orange";
      } else if (item.status == 5) {
        return "row-purple";
      } else if (item.status == 6) {
        return "row-yellow-schedule";
      } else {
        return "row-green";
      }
    },
  },
  computed: {
    ...mapState("player_history", [
      "filtered",
      "items",
      "loading",
      "totalItems",
      "rake_player_tax",
      "rake_player_gains",
      "rake_hands_played",
    ]),
    ...mapFields("player_history", [
      "current_tab",
      "pagination",
      "filter.id_pppoker",
      "filter.value",
      "filter.status",
      "filter.start",
      "filter.end",
    ]),
  },
  data: () => ({
    headers: [
      { text: "Id", value: "id", sortable: false },
      { text: "Clube", value: "club", sortable: false },
      { text: "Period", value: "period", sortable: false },
      { text: "IdPppoker", value: "id_pppoker", sortable: false },
      { text: "IdAgent", value: "id_agent", sortable: false },
      { text: "Nick", value: "nick", sortable: false },
      { text: "PlayerGains", value: "player_gains", sortable: false },
      { text: "PlayerTax", value: "player_tax", sortable: false },
      // { text: "PlayerSpin", value: "player_spin", sortable: false },
      // { text: "Received", value: "received", sortable: false },
      // { text: "Sended", value: "sended", sortable: false },
      { text: "Balance", value: "balance", sortable: false },
      { text: "HandsPlayed", value: "hands_played", sortable: false },
      // { text: "PlayerTotalMtt", value: "player_total_mtt", sortable: false },
      // { text: "PlayerTotalSng", value: "player_total_sng", sortable: false },
      // { text: "PlayerTotalRg", value: "player_total_rg", sortable: false },
    ],
  }),
  watch: {
    pagination: {
      handler() {
        if (this.filtered) {
          if(this.current_tab == 'player_history') {
            this.getItems({ 
              end_point: 'player_history',
              extend_url: '/rake',
              resetPage: false
            })
          }
        }
      },
    },
  },
};
</script>
