<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-snackbar
      :color="notificationType"
      top
      center
      :timeout="2000"
      v-model="showNotification"
    >{{ notificationMessage }}</v-snackbar>
    <v-container class="elevation-10 status">
      <v-row>
        <v-col
          v-for="s in system_services"
          :key="s.service_name"
          cols="12"
          sm="12"
        >
          <v-card
            class="pa-2 text-center"
            
            outlined
            tile
          >
            <v-avatar color="green" size="30"></v-avatar>
            {{ s.name }}
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "SystemServices",
  created() {
    this.getSystemServices();
  },
  data: () => ({

    servicesStars: [
      { description: "Chat", service: "chat" },
      { description: "Comercial", service: "commercial" },
      { description: "Financeiro", service: "financial" },
      { description: "Jogadores", service: "player" },
      { description: "Loja", service: "store" },
      { description: "Login", service: "login" },
      { description: "Pix", service: "pix" },
    ],
    types: [
      {id: 0, description: "Stars"},
      {id: 1, description: "Estrela"},
    ],
    type_service: 0
  }),
  components: {
  },
  methods: {
    ...mapActions("system_services", ["getStatus", "getSystemServices", "restart", "start"]),
    getStatusText(status) {
      if(typeof(status) == "string" && status.length > 0) {
        var messageTreated = status
          .replace(/&/g, "&amp;")
          .replace(/</g, "&lt;")
          .replace(/>/g, "&gt;")
          .replace(/"/g, "&quot;")
          .replace(/'/g, "&#039;")
          .replace(/\n/g, "<br>")
          .replace(/\*([^]+?)\*/g, "<b>$1</b>");
        return messageTreated;
      } 

      return ""
    },
  },
  computed: {
    ...mapState("system_services", ["notificationMessage", "system_services"]),
    ...mapFields("system_services", ["active", "overlay", "notificationType", "service", "status", 'showNotification'])
  },
};
</script>

<style lang="scss" scoped>
</style>
