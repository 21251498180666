<template>
  <div>
    <v-tabs v-model="main_tab" class="elevation-10">
      <v-tab>Movimentações</v-tab>
      <v-tab v-if="showFinancialAdmin()">Auditoria</v-tab>
      <v-tab v-if="showFinancialAdmin()">Despesas</v-tab>
    </v-tabs>
    <v-tabs-items v-model="main_tab" class="elevation-10">
      <v-tab-item>
        <v-tabs v-model="tab" class="elevation-10">
          <v-tab>Por Hora</v-tab>
          <v-tab>Por Dia</v-tab>
          <v-tab>Por Mês</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item class="dashboard-tab">
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex sm12 md6 xs12 xl6>
                    <v-card class="elevation-10">
                      <v-card-title class="text-center justify-center">Movimentações Últimas 24 Horas - Quantidade</v-card-title>
                      <GChart
                          type="ColumnChart"
                          :data="chartDataQuantity"
                          :options="options_quantity"
                          :resizeDebounce="500"
                        />
                    </v-card>
                  </v-flex>
                  <v-flex sm12 md6 xs12 xl6 v-if="showFinancialAdmin()">
                    <v-card class="elevation-10">
                      <v-card-title class="text-center justify-center">Movimentações Últimas 24 Horas - Valor</v-card-title>
                      <GChart
                          type="LineChart"
                          :data="chartDataValue"
                          :options="options_value"
                          :resizeDebounce="500"
                        />
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-tab-item> 
            <v-tab-item class="dashboard-tab">
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex sm12 md6 xs12 xl12>
                    <v-card class="elevation-10">
                      <v-card-title class="text-center justify-center">Movimentações Últimos 30 Dias - Quantidade</v-card-title>
                      <GChart
                          type="ColumnChart"
                          :data="chartDataQuantityDays"
                          :options="options_quantity_month"
                          :resizeDebounce="500"
                        />
                    </v-card>
                  </v-flex>
                  <v-flex sm12 md6 xs12 xl12 v-if="showFinancialAdmin()">
                    <v-card class="elevation-10">
                      <v-card-title class="text-center justify-center">Movimentações Últimos 30 Dias - Valor</v-card-title>
                      <GChart
                          type="LineChart"
                          :data="chartDataValueDays"
                          :options="options_value_month"
                          :resizeDebounce="500"
                        />
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-tab-item> 
            <v-tab-item class="dashboard-tab">
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex sm12 md6 xs12 xl12>
                    <v-card class="elevation-10">
                      <v-card-title class="text-center justify-center">Movimentações Últimos Meses - Quantidade</v-card-title>
                      <GChart
                          type="ColumnChart"
                          :data="chartDataQuantityMonths"
                          :options="options_quantity_month"
                        />
                    </v-card>
                  </v-flex>
                  <v-flex sm12 md6 xs12 xl12 v-if="showFinancialAdmin()">
                    <v-card class="elevation-10">
                      <v-card-title class="text-center justify-center">Movimentações Últimos Meses - Valor</v-card-title>
                      <GChart
                          type="LineChart"
                          :data="chartDataValueMonths"
                          :options="options_value_month"
                        />
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-tab-item> 

            <!--v-tab-item v-if="showFinancial" class="dashboard">
              <v-flex sm12 md12 xs12 xl12 class="elevation-10">
                <attendance-dashboard></attendance-dashboard>
              </v-flex>
            </v-tab-item--> 
        </v-tabs-items> 
      </v-tab-item>
      <v-tab-item>
        <iframe class="dash-bi-audit" src="https://app.powerbi.com/view?r=eyJrIjoiOTQ2MzZjNGItMjRlZC00MDgwLWE0MmItZjk0ZjcyMzAxNTNkIiwidCI6Ijg3NTIzMTA4LTlkZWUtNDE0ZS1hYjFmLTYwMDE2M2MwN2VhZiJ9&pageName=ReportSection" frameborder="0" allowFullScreen="true"></iframe>
      </v-tab-item>
      <v-tab-item>
        <iframe class="dash-bi-audit" src="https://app.powerbi.com/view?r=eyJrIjoiYzE4MDJlMDEtMjBlYS00OGIxLThmMmQtZDNmZTcxNDJiYzJmIiwidCI6Ijg3NTIzMTA4LTlkZWUtNDE0ZS1hYjFmLTYwMDE2M2MwN2VhZiJ9" frameborder="0" allowFullScreen="true"></iframe>
      </v-tab-item>
    </v-tabs-items>
   
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  created() {
    this.getItems()
    this.getItemsPerDays()
    this.getItemsPerMonths()
  },
  computed : {
    ...mapState("login", ["userData"]),
    ...mapState('financial_dashboard', ['items_quantity', 'items_quantity_days', 'items_quantity_months', 'items_value', 'items_value_days', 'items_value_months']),
    chartDataQuantity() {
      return [this.headers_quantity, ...this.items_quantity]
    },
    chartDataQuantityDays() {
      return [this.headers_quantity, ...this.items_quantity_days]
    },
    chartDataQuantityMonths() {
      return [this.headers_quantity, ...this.items_quantity_months]
    },
    chartDataValue() {
      return [this.headers_value, ...this.items_value]
    },
    chartDataValueDays() {
      return [this.headers_value, ...this.items_value_days]
    },
    chartDataValueMonths() {
      return [this.headers_value, ...this.items_value_months]
    },
  },
  data () {
    return {
      headers_quantity: [
        {type: 'string', label: 'Hora'},
        {type: 'number', label: 'Depósitos'},
        {type: 'number', label: 'Saques'},
        {type: 'number', label: 'Despesas'},
      ],
      headers_value: [
        {type: 'string', label: 'Hora'},
        {type: 'number', label: 'Depósitos'}, 
        {type: 'number', label: 'Saques'},
        {type: 'number', label: 'Despesas'},      
        {type: 'number', label: 'Ajustes'},      
        {type: 'number', label: 'Saldo'}
      ],
      options_quantity:  {
        chart: {
          title: 'Movimentações Financeiras',
          subtitle: '(Últimas 24 Horas)',
        },
        legend: { position: 'bottom'},
        vAxis: {
          title: 'Quantidade',
        },  
      },
      options_value:  {
        chart: {
          title: 'Movimentações Financeiras',
          subtitle: '(Últimas 24 Horas)',
        },
        legend: { position: 'bottom'},
        vAxis: {
          title: 'Valor'
        },  
      },
      options_quantity_month:  {
        chart: {
          title: 'Movimentações Financeiras',
          subtitle: '(Últimos 30 Dias)',
        },
        legend: { position: 'right'},
        vAxis: {
          title: 'Quantidade'
        }
      },  
      options_value_month:  {
        chart: {
          title: 'Movimentações Financeiras',
          subtitle: '(Últimas 24 Horas)',
        },
        legend: { position: 'right'},
        vAxis: {
          title: 'Valor'
        },  
      },
      main_tab: '',
      tab: ''
    }
  },
  methods: {
    ...mapActions('financial_dashboard', ['getItems', 'getItemsPerDays', 'getItemsPerMonths']),
    showFinancialAdmin() {
      let roles = [93, 97, 99]
      return roles.indexOf(this.userData.role) != -1
    }
  },
}
</script>

<style scoped>
  .dashboard-tab {
    background-color: #f2f2f2;
  }
  .dash-bi-audit {
    height: 85vh;
    width: 100%;
  }
</style>