<template>
  <div>
    <div>

      <v-card>
        <v-card-title>
          <span class="headline">Estoque</span>
          <v-spacer></v-spacer>
          <v-progress-circular
            v-if="loading"
            indeterminate
            class="text-right"
            color="primary"
            size="24"
          ></v-progress-circular>

        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm12 md12>
                  <v-autocomplete
                      v-model="product_id"
                      :readonly="this.mode != 'ADD'"
                      :items="products"
                      outlined
                      chips
                      small-chips
                      no-data-text="Nenhum produto encontrado"
                      item-text="name"
                      item-value="id"
                      placeholder="Digite aqui o nome do produto"
                      label="Produto *"
                      v-validate="'required:Produto,product_id'"
                      :error-messages="errors.collect('product_id')"
                      data-vv-name="product_id"
                      :search-input.sync="searchProduct"
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Quantidade"
                    v-model="quantity"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('quantity')"
                    v-validate="'required:Quantidade,quantity|decimal'"
                    data-vv-name="quantity"
                    outlined
                    type="number"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Tamanho"
                    v-model="size"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('size')"
                    v-validate="'max_length:10'"
                    data-vv-name="size"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="CEP *"
                    v-model="cep"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('cep')"
                    v-validate="'decimal'"
                    data-vv-name="cep"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Bairro"
                    v-model="neighborhood"
                    :disabled="true"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('neighborhood')"
                    v-validate="'required:Bairro,neighborhood|max_length:100'"
                    data-vv-name="neighborhood"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm5 md5>
                  <v-text-field
                    required
                    label="Endereço"
                    v-model="address"
                    :disabled="true"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('address')"
                    v-validate="'required:Endereço,address|max_length:100'"
                    data-vv-name="address"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm2 md2>
                  <v-text-field
                    required
                    label="Numero"
                    v-model="address_number"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('address_number')"
                    v-validate="'required:Número,address_number|max_length:10'"
                    data-vv-name="address_number"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm5 md5>
                  <v-text-field
                    required
                    label="Complemento"
                    v-model="address_complement"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('address_complement')"
                    v-validate="'max_length:50'"
                    data-vv-name="address_complement"
                    outlined
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-col>
            <v-btn text @click="closeDialog">{{ mode == 'VIEW' ? 'Voltar' : 'Cancelar'}}</v-btn>
          </v-col>
          <v-col class="text-right" v-if="mode != 'VIEW'">
            <v-btn text type="submit" right @click="saveProduct">Salvar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import debounce from '../../../util/debounce.js' 
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "ProductsForm",
  computed: {
    ...mapState("stock", [
      "loading",
      "mode",
      "products"
    ]),
    ...mapFields("stock", [
      "editedItem.id",
      "editedItem.product_id",
      "editedItem.product_name", 
      "editedItem.size",
      "editedItem.quantity",     
      "editedItem.cep",    
      "editedItem.neighborhood", 
      "editedItem.address",
      "editedItem.address_number",
      "editedItem.address_complement",
      "selectedProduct"
      // ...
    ]),
  },
  created() {
    this.$validator.extend("required", {
      getMessage: (field, [label]) => label + " deve ser informado",
      validate: (_, [, field]) => {
        if (this[field] || this[field] === 0) {
          return true;
        } else {
          return false;
        }
      },
    });
    this.$validator.extend("max_length", {
      getMessage: (_, [length]) => "No máximo " + length + " caracteres.",
      validate: (val, [length]) => {
        if (val.length > length) {
          return false;
        } else {
          return true;
        }
      },
    });
    this.getProducts = this.debounce(this.getProducts, 500)
  },
  methods: {
    ...mapMutations("stock", ["closeDialog"]),
    ...mapActions("stock", ["getProducts", "save"]),
    saveProduct() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.quantity = parseInt(this.quantity)
          this.save();
        }
      });
    },
  },
  mixins: [debounce],
  data: () => ({
    valid: false,
    possible_types: [
      {id: 0, description: 'Moletom'},
      {id: 1, description: 'Blusa'},
      {id: 2, description: 'Boné'},
      {id: 3, description: 'Calça'},
      {id: 4, description: 'Bermuda'},
      {id: 5, description: 'Outros'},
    ],
    searchProduct: ''
  }),
  watch: {
    searchProduct: {
      handler () {
        if(this.searchProduct != null) {
          this.getProducts({search: this.searchProduct})
        }
      },
    },
    cep: {
      handler() {
        if(this.cep) {
          var r_cep = this.cep.replace("-")
          if(r_cep.length == 8) {
          // var cep_json = {}
            let url = "https://viacep.com.br/ws/" + r_cep + "/json/"

            var myHeaders = new Headers();
            var myInit = { 
              method: 'GET',
              headers: myHeaders,
              mode: 'cors',
              cache: 'default' 
            };

            const myRequest = new Request(url, myInit);
            fetch(myRequest).then(res => res.json())
            .then((cep) => {
              if(cep) {
                this.neighborhood = cep.bairro
                this.address = cep.logradouro
              }
            }).catch((error) => {
              console.log(error);
            });
          }
        }
      }
        
    }
  }
};
</script>
