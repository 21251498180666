<template>
<div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    
    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2000"
      v-model="showNotification"
    >
        {{ notificationMessage }}
    </v-snackbar>
    <v-toolbar flat class="elevation-5">
      <div>
        <v-toolbar-title>Jogadores</v-toolbar-title>
      </div>
      <v-spacer></v-spacer>
      <div class="button-toolbar">
          <download-excel
            :before-generate="generateSheet"
            :before-finish="finishSheet"
            :name="getSheetName()"
            :fetch="getItemsNoPage"
            :fields="sheet_fields"
            class="excel"
            worksheet = "Jogadores"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="black" dark class="ma-2" small v-bind="attrs" v-on="on">
                  <v-icon>mdi-file-excel</v-icon>
                </v-btn>
              </template>
              <span>Gerar Planilha</span>
            </v-tooltip>
          </download-excel>  

        <v-tooltip bottom >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="black" dark class="ma-2" @click="showFilter({show: true})" small v-bind="attrs" v-on="on">
              <v-icon :color="filtered ? 'grey' : 'white'">mdi-filter</v-icon>
            </v-btn>
          </template>
          <span>Filtrar</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="black" dark class="ma-2" @click="getItems" small>
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Recarregar</span>
        </v-tooltip>
        
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="black" dark class="ma-2" @click="add" small v-bind="attrs" v-on="on">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Novo Jogador</span>
        </v-tooltip>
      </div> 
    </v-toolbar>
     <v-data-table
      :headers="headers"
      :loading="loading"
      :items="items"
      :options.sync="pagination"
      :server-items-length="totalItems"
      no-data-text="Nenhum jogador encontrado"
      class="elevation-10 table-margin"
    >

      <template v-slot:[`item.pppoker_accepted`]="{ item }">
        <v-simple-checkbox :value="(item.pppoker_accepted == 0) ? false : true" disabled></v-simple-checkbox>
      </template>

      <template v-slot:[`item.cpf`]="{ item }">
        <span>{{ getCpfMask(item.cpf) }}</span>
      </template>

      <template v-slot:[`item.club_name`]="{ item }">
        <span>{{ getItemIsNull(item.club_name) }}</span>
      </template>

      <template v-slot:[`item.birth_date`]="{ item }">
        <span>{{ getItemIsNull(item.birth_date) }}</span>
      </template>

      <template v-slot:[`item.contact_id`]="{ item }">
        <span>{{ getItemIsNull(item.contact_id) }}</span>
      </template>

      <template v-slot:[`item.contact_name`]="{ item }">
        <span>{{ getItemIsNull(item.contact_name) }}</span>
      </template>

      <template v-slot:[`item.know_by_description`]="{ item }">
        <span>{{ getItemIsNull(item.know_by_description) }}</span>
      </template>

      <template v-slot:[`item.date_created`]="{ item }">
        <span>{{ getDate(item.date_created) }}</span>
      </template>

      <template v-slot:[`item.bonus`]="{ item }">
        <span>{{ getBonusStatus(item.bonus) }}</span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="viewItem({item: item})">mdi-information</v-icon>
        <v-icon small class="mr-2" @click="edit({item: item})">mdi-pencil</v-icon>
        <v-icon small class="mr-2" @click="showDeleteDialog(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>
    
    <v-dialog :value="dialog" width="800" @click:outside="closeDialog">
      <player-form></player-form>
    </v-dialog>

    <v-dialog :value="dialogFilter" width="600" @click:outside="showFilter({show: false})">
      <player-filter></player-filter>
    </v-dialog>

    <v-dialog :value="dialogDelete" persistent width="300">
      <v-card>
        <v-card-title class="headline">Confirmar a exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn
              text
              @click="closeDeleteDialog()"
            >Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn
              text
              type="submit"
              right
              @click="deleteItem"
            >Confirmar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import { mapFields } from 'vuex-map-fields'
  import PlayersForm from './PlayersForm'
  import PlayersFilter from './PlayersFilter'
  import JsonExcel from 'vue-json-excel'

  export default {
    name: 'Players',
    computed: {
      ...mapState('players', ['dialog', 'dialogDelete', 'item','instances', 'loading', 'notificationType', 
            'notificationMessage', 'totalItems', 'items', 'filtered']),
      ...mapFields('players', [
          'pagination',
          'dialogFilter',
          'overlay',
          'showNotification'
          // ...
        ])
    },
    created() {
      this.getItems({resetPage: false})
      this.getInstances()
      this.getKnowByTypes()
    },
    components: {
      "download-excel": JsonExcel,
      "player-form": PlayersForm,
      "player-filter": PlayersFilter
    },
    methods: {
      ...mapMutations('players', ['add', 'edit', 'showFilter']),
      ...mapActions('players', ['closeDialog', 'closeDeleteDialog', 'deleteItem', 'getItems', 'getItemsNoPage', 
                    'showDeleteDialog', 'getKnowByTypes', 'viewItem']),
      ...mapActions("instances", ["getInstances"]),
      isMobile() {
        if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
          return true;
        }
        return false
      },
      finishSheet() {
        this.overlay = false
      },
      generateSheet() {
        this.overlay = true
      },
      getBonusStatus(bonus) {
        if(bonus == 0) {
          return "Não"
        } else {
          return "Sim"
        }
      },
      getCpfMask(cpfUnmasked) {
        if(cpfUnmasked !== null && cpfUnmasked !== "") {
          if(cpfUnmasked.length === 11)
            return `${cpfUnmasked.substring(0, 3)}.${cpfUnmasked.substring(3, 6)}.${cpfUnmasked.substring(6, 9)}-${cpfUnmasked.substring(9, 11)}`
          else 
            return cpfUnmasked
        } else {
          return '---'
        }
      },
      getDate(value) {
        return value.substring(0, 10)
      },
      getItemIsNull(value) {
        if (value === null || value === "")
          return "---"
        
        return value
      },
      getSheetName() {
        var date = new Date()
        var name = 'jogadores_' + date.toLocaleDateString() + " " + date.toLocaleTimeString()
        return name+'.xls'
      },
    },
    data: () => ({
      headers: [
        { text: "Contato", value: "contact_id", sortable: false, align: "left"},
        { text: "Clube", value: "club_name", sortable: true, align: "center"},
        { text: "Id PPPoker", value: "player_id", sortable: false, align: "center"}, 
        { text: "Nome", value: "name", sortable: false },
        { text: "CPF", value: "cpf", sortable: false, align: "center",  width: "10%" },
        { text: "Nascimento", align: "center", value: "birth_date", sortable: true },
        { text: "Celular", value: "contact_id", sortable: false },
        { text: "Contato",  value: "contact_name", sortable: false, width: "10%" },
        { text: "Como conheceu o ST⭐RS?",  value: "know_by_description", sortable: false, width: "15%" },
        { text: "Criado em: ", value: "date_created", sortable: true, width: "10%" },
        { text: "Bônus?", value: "bonus", sortable: true, align: "center"}, 
        { text: "Aceito PPPoker?", value: "pppoker_accepted", sortable: false, align: "center"}, 
        { text: "Ações",  value: "actions", sortable: false, align: "center" }
      ],
      sheet_fields: {
        Clube: {
          field: 'club_name'
        },
        Nome: {
          field: 'name'
        },
        CPF: {
          field: 'cpf',
          callback: (value) => {
            if(value !== null && value !== "") {
              if(value.length === 11)
                return `${value.substring(0, 3)}.${value.substring(3, 6)}.${value.substring(6, 9)}-${value.substring(9, 11)}`
              else 
                return value
            } else {
              return '---'
            }
          }
        },
        Nascimento: {
          field: 'birth_date',
          callback: (value) => {
            if(value) {
              return 'n: ' + value
            }
            return '-'
          }
        },
        Celular: {
          field: 'contact_id',
          callback: (value) => {
            if(value) {
              return 'Nº: ' + value
            }
            return '-'
          }
        },
        Contato: {
          field: 'contact_name',
          callback: (value) => {
            if(value) {
              return value
            }
            return '-'
          }
        },
        "Criado em": {
          field: "date_created",
          callback: (value) => {   
            if(value) {
              var d = new Date(value);
              return d.toLocaleDateString() + " " + d.toLocaleTimeString() ;
            }
          },
        },
        "Como conheceu o ST⭐RS?": {
          field: 'know_by_description',
          callback: (value) => {
            if(value) {
              return value
            }
            return '-'
          }
        },
        "Bônus?": {
          field: 'pppoker_accepted',
          callback: (value) => {
            if(value == 1) {
              return 'Sim'
            }
            return 'Não'
          }
        },
        "Aceito PPPoker?": {
          field: 'pppoker_accepted',
          callback: (value) => {
            if(value == 1) {
              return 'Sim'
            }
            return 'Não'
          }
        },
        "Email": {
          field: 'email'
        }
      },
      valid: false
    }),
    watch: {
      pagination: {
        handler () {
          this.getItems({resetPage: false})
        },
      },
    },
  }
</script>