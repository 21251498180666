import axios from 'axios';
import endPoints from '../../../config/endPoints';

export default {
    namespaced: true,
    state: {
        qt_active_agents_players: [],
        rakes_agent: []
    },
    mutations: {
        loading: (state, payload) => state.loading = payload.loading,
        setRakesAgent: (state, payload) => state.rakes_agent = payload.rakes_agent,
        setQtActivePlayersAgents: (state, payload) => state.qt_active_agents_players = payload.qt_active_agents_players
    },
    actions: {
        getRakesAgent: ({ commit, rootGetters }) => {
            commit('loading', { loading: true })
            let url = endPoints.agents_closure + "/agent_last_rakes/" + rootGetters["agent/getIdAgent"];
            axios.get(url).then(response => {
                let rake_agents = []
                let qt_active_agents_players = []
                if (response.data) {
                    response.data.forEach(r => {
                        let date = new Date(r.start_date)
                        rake_agents.push([date.toLocaleDateString(), r.total_rake, r.total_rake_sub_agents, r.rakeback, r.total_rakeback_sub_agents])
                        qt_active_agents_players.push([date.toLocaleDateString(), r.active_players, r.active_agents])
                    });
                }
                commit('setQtActivePlayersAgents', { qt_active_agents_players: qt_active_agents_players })
                commit('setRakesAgent', { rakes_agent: rake_agents })
                commit('loading', { loading: false })
            });
        }
    }
}