<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline text-center justify-center"
          >Filtrar usuários</span
        >

        <!-- <v-col class="text-right">
          <v-btn
            v-if="filtered"
            color="white"
            @click="clearFilter"
            right
            small
            depressed
          >
            <v-icon color="black">mdi-filter-remove</v-icon>
          </v-btn>
        </v-col> -->
      </v-card-title>

      <v-card-text>
        <v-form ref="form">
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm6 md6>
                <v-text-field
                  label="Inscription"
                  outlined
                  v-model="inscription"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-text-field
                  label="Nome"
                  outlined
                  v-model="name"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-text-field
                  label="Email"
                  outlined
                  v-model="email"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-select
                  clear-icon="mdi-close"
                  :clearable="true"
                  label="Função"
                  :items="possible_roles"
                  item-text="desc"
                  item-value="id"
                  v-model="role"
                  outlined
                ></v-select>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-select
                  :clearable="true"
                  clear-icon="mdi-close"
                  label="Clube"
                  :items="instances"
                  v-model="id_instance"
                  item-text="name"
                  item-value="id_instance"
                  outlined
                ></v-select>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-select
                  clear-icon="mdi-close"
                  :clearable="true"
                  label="Status"
                  :items="possible_status"
                  item-text="desc"
                  item-value="id"
                  v-model="status"
                  outlined
                ></v-select>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-select
                  clear-icon="mdi-close"
                  :clearable="true"
                  label="Authenticator Configurado"
                  :items="yes_no"
                  item-text="desc"
                  item-value="id"
                  v-model="authenticator_configured"
                  outlined
                ></v-select>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn text @click="showFilter({ show: false })">Cancelar</v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn text type="submit" right @click="filter">Filtrar</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
export default {
  name: "UsersFilter",
  create() {
  },
  computed: {
    ...mapState("instances", ["instances"]),
    ...mapFields("users", [
      "filter.name",
      "filter.email",
      "filter.club",
      "filter.role",
      "filter.status",
      "filter.id_instance",
      "filter.default_password",
      "filter.inscription",
      "filter.authenticator_configured"
    ]),
  },
  data: () => ({
    possible_roles: [
      { id: 0, desc: "Atendente" },
      { id: 1, desc: "Atendimento Conferência" },
      { id: 2, desc: "Financeiro" },
      { id: 3, desc: "Comercial" },
      { id: 4, desc: "Marketing" },
      { id: 5, desc: "Financeiro 2" },
      { id: 6, desc: "Atendimento Saque" },
      { id: 7, desc: "Atendimento Marketing" },
      { id: 70, desc: "Dono de Clube" },
      { id: 80, desc: "Supervisão Chat" },
      { id: 81, desc: "Supervisão Financeira" },
      { id: 94, desc: "Gerente Marketing" },
      { id: 95, desc: "Gerente RH" },
      { id: 96, desc: "Gerente Comercial" },
      { id: 97, desc: "Gerente Financeiro" },
      { id: 98, desc: "Gerente Chat" },
      { id: 99, desc: "Administrador" },
    ],
    yes_no: [
      { id: 0, desc: "Não" },
      { id: 1, desc: "Sim" }
    ],
    possible_status: [
      { id: 1, desc: "Ativo" },
      { id: 0, desc: "Inativo" },
    ],
  }),
  methods: {
    ...mapActions("users", ["getItems"]),
    ...mapMutations("users", ["showFilter"]),
    filter() {
      this.getItems({ resetPage: true });
      this.showFilter({ show: false });
    },
  },
};
</script>

<style scoped>
</style>