<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    
    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2000"
      v-model="showNotification"
    >
        {{ notificationMessage }}
    </v-snackbar>
    <v-toolbar flat class="elevation-5">
      <div>
        <v-toolbar-title>Bônus</v-toolbar-title>
      </div>
      <v-spacer></v-spacer>
      <div class="button-toolbar">
        <v-btn color="black" dark class="ma-2" @click="showDepositDialog({show: true})" small><v-icon>mdi-numeric-8-circle</v-icon></v-btn>
        <v-btn v-if="profileAccess()" color="black" dark class="ma-2" @click="add" small><v-icon>mdi-plus</v-icon></v-btn>
        <v-btn color="black" dark class="ma-2" @click="showFilter({show: true})" small><v-icon>mdi-filter</v-icon></v-btn>
        <v-btn color="black" dark class="ma-2" @click="getBonusNoFilter" small><v-icon>mdi-refresh</v-icon></v-btn>
      </div> 
    </v-toolbar>


    <v-data-table
      :headers="headers"
      :loading="loading"
      :items="bonus"
      :options.sync="pagination"
      :server-items-length="totalBonus"
      no-data-text="Nenhum bônus encontrado"
      class="elevation-10 table-margin"
    >
      <template v-slot:[`item.actions`]="{ item }">

        <v-tooltip bottom>
          <template v-slot:activator="{on, attrs}">
            <v-icon small v-on="on" v-bind="attrs" class="mr-2" @click="editedBonus(item)">mdi-pencil</v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{on, attrs}">
            <v-icon small v-on="on" v-bind="attrs" class="mr-2" @click="viewBonus(item)">mdi-information</v-icon>
          </template>
          <span>Informações</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{on, attrs}">
            <v-icon small v-on="on" v-bind="attrs" class="mr-2" @click="viewPrint({item: item})">mdi-image-area</v-icon>
          </template>
          <span>Print</span>
        </v-tooltip>

      </template>
    </v-data-table>

    <v-dialog :value="dialogFilter" width="600" @click:outside="showFilter({show: false})">
      <bonus-filter></bonus-filter>
    </v-dialog>

    <v-dialog :value="dialogPrint" width="600" @click:outside="showDialogPrint({show: false})">

      <v-card height="90vh">
        <v-img :src="receipt" contain></v-img>
      </v-card>

    </v-dialog>

    <v-dialog :value="dialog" width="800" @click:outside="closeDialog">
      <bonus-form></bonus-form>
    </v-dialog>

    <v-dialog :value="depositDialog" width="800" @click:outside="showDepositDialog({show: false})">
      <deposit-form></deposit-form>
    </v-dialog>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import { mapFields } from 'vuex-map-fields'
  import BonusFilter from './BonusFilter'
  import BonusForm from './BonusForm'
  import DepositForm from './DepositForm'

  export default {
    name: 'Bonus',
    bonus_code: 'Bonus',
    computed: {
      ...mapState('login', ['userData']),
      ...mapState('bonus', ['contact_show', 'dialog', 'dialogDeposit', 'depositDialog', 
            'errors', 'loading', 'notificationType', 'notificationMessage',  'receipt',
            'rowsPerPage', 'receipt_show', 'totalBonus', 'bonus', 'dialogPrint']),
      ...mapFields('bonus', [
          'pagination',
          'dialogFilter',
          'overlay',
          'showNotification'
          // ...
        ])
    },
    created() {
      this.getBonus({resetPage: false})
    },
    components: {
      "bonus-filter": BonusFilter,
      "bonus-form": BonusForm,
      "deposit-form": DepositForm
    },
    methods: {
      ...mapMutations('bonus', ['showDepositDialog', 'add', 'addBonus', 'edit', 'viewBonus', 'showFilter', 'setDialogReceipt', 'setScreen', 'showDialogPrint', 'editedBonus']),
      ...mapActions('bonus', ['closeDialog', 'getBonus','getBonusNoFilter', 'viewPrint',
                    'showDeleteDialog','showReceipt', 'startWithdrawal', 'stopWithdrawal', 'getAgents' ]),
      isMobile() {
        return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
      },
      profileAccess() {
        return this.userData.role == 98 || this.userData.role == 99
      },
    },
    data: () => ({
      headers: [
        { text: "ID",  value: "id", sortable: false, align: "center"},
        { text: "Player ID",  value: "id_app", sortable: false, align: "center"},
        { text: "Contato",  value: "contact_id", sortable: false, align: "center" },
        { text: "CPF / CNPJ",  value: "inscription", sortable: false, align: "center"},
        { text: "ID Depósito",  value: "deposit_id", sortable: false, align: "center"},
        { text: "ID Depósiton Origem",  value: "deposit_id_origin", sortable: false, align: "center"},
        { text: "ID Saque",  value: "withdrawal_id", sortable: false, align: "center"},
        { text: "Valor",  value: "value", sortable: false, align: "center"},
        { text: "Mãos Jogadas",  value: "hands_played", sortable: false, align: "center"},
        { text: "Ações",  value: "actions", sortable: false, align: "center"},
      ],
    }),
    watch: {
      pagination: {
        handler () {
          this.getBonus({resetPage: false})
        },
      },
    },
  }
</script>

<style>
</style>