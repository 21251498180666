const ruleKnowBy = (text, knowByTypes) => {
    let findByDescription = (b) => {
        return b.description && b.description.toLowerCase().indexOf(text) != -1
    }

    if (text) {
        text = text.toLowerCase();
        text = text.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a');
        text = text.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e');
        text = text.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i');
        text = text.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o');
        text = text.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u');
        text = text.replace(new RegExp('[Ç]', 'gi'), 'c');
        
        let knowByType = knowByTypes.find(findByDescription)
        if (knowByType) {
            return knowByType.id
        } 
    }

    text = "outros"
    let knowByType = knowByTypes.find(findByDescription)
    return knowByType.id ? knowByType.id : 0 
}

const player_template = {
    1: { field: 'name' },
    2: { field: 'cpf' },
    3: { field: 'birth_date' },
    4: { field: 'email' },
    5: { field: 'player_id' },
    6: { field: 'know_by', rule: ruleKnowBy },
};

export default player_template