<template>
    <div>
        <v-overlay :value="loading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <v-snackbar
            :color="notification.type"
            right
            top
            :timeout="2000"
            v-model="notification.show"
        >
            {{ notification.message }}
        </v-snackbar>

        <v-toolbar flat class="elevation-5">
            <v-toolbar-title><b>Pagamento de Anotados</b></v-toolbar-title>
            <v-spacer></v-spacer>
            <div class="button-toolbar">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="black"
                            dark
                            class="ma-2"
                            @click="setShowFilter(true)"
                            v-bind="attrs"
                            v-on="on"
                            small
                        >
                            <v-icon color="white">mdi-filter</v-icon>
                        </v-btn>
                    </template>
                    <span>Filtro</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="black"
                            dark
                            class="ma-2"
                            @click="getItems"
                            v-bind="attrs"
                            v-on="on"
                            small
                            ><v-icon>mdi-refresh</v-icon></v-btn
                        >
                    </template>
                    <span>Recarregar</span>
                </v-tooltip>
            </div>
        </v-toolbar>

        <v-data-table
            :headers="headers"
            :loading="loading"
            :items="items"
            :options.sync="pagination"
            :server-items-length="totalItems"
            class="elevation-10 table-margin"
            no-data-text="Nenhum pagamento de anotado encontrado"
        >
            <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ attrs, on }">
                        <v-icon
                            small
                            color="blue"
                            class="mr-2"
                            v-bind="attrs"
                            v-on="on"
                            @click="view(item)"
                            >mdi-information</v-icon
                        >
                    </template>
                    <span>Informações</span>
                </v-tooltip>
                <v-tooltip bottom v-if="item.status == 0">
                    <template v-slot:activator="{ attrs, on }">
                        <v-icon
                            small
                            color="black"
                            class="mr-2"
                            v-bind="attrs"
                            v-on="on"
                            @click="checkStatus(item)"
                            >mdi-qrcode</v-icon
                        >
                    </template>
                    <span>Verificar Status QrCode</span>
                </v-tooltip>
            </template>
            <template v-slot:[`item.value`]="{ item }">
                <span>{{
                    item.value.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                    })
                }}</span>
            </template>
            <template v-slot:[`item.date_created`]="{ item }">
                <span class="ma-2">{{ getDate(item.date_created) }}</span>
            </template>
            <template v-slot:[`item.date_payment`]="{ item }">
                <span class="ma-2">{{ getDate(item.date_payment) }}</span>
            </template>

            <template v-slot:[`item.status`]="{ item }">
                <v-chip
                    :color="getClassStatus(item.status)"
                    class="ma-2"
                    text-color="white"
                    label
                    >{{ getStatus(item.status) }}</v-chip
                >
            </template>
        </v-data-table>

        <v-dialog
            :value="showDialog"
            @click:outside="setShowDialog(false)"
            width="1000"
        >
            <payments-annotateds-form></payments-annotateds-form>
        </v-dialog>

        <v-dialog
            :value="filterDialog"
            @click:outside="setShowFilter(false)"
            width="600"
        >
            <payments-annotateds-filter></payments-annotateds-filter>
        </v-dialog>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import PaymentsAnnotatedsForm from './PaymentsAnnotatedsForm'
import PaymentsAnnotatedsFilter from './PaymentsAnnotatedsFilter'

export default {
    name: 'PaymentsAnnotateds',
    created() {
        this.getItems()
    },

    components: {
        PaymentsAnnotatedsForm,
        PaymentsAnnotatedsFilter,
    },

    methods: {
        ...mapActions('payments_annotateds', ['getItems', 'checkStatus']),
        ...mapMutations('payments_annotateds', [
            'setShowDialog',
            'view',
            'setShowFilter',
        ]),
        profileAccess() {
            if (
                this.userData.role == 0 ||
                this.userData.role == 1 ||
                this.userData.role == 70
            ) {
                return false
            } else {
                return true
            }
        },
        getDate(date) {
            if (date) {
                var d = new Date(date)
                return d.toLocaleDateString() + ' ' + d.toLocaleTimeString()
            }
            return ''
        },
        getClassStatus(status) {
            if (status == 0) {
                return 'red'
            } else if (status == 1) {
                return 'green'
            } else if (status == 2) {
                return 'orange'
            }
        },
        getStatus(status) {
            return this.possible_status[status].description
        },
    },
    computed: {
        ...mapState('login', ['userData']),
        ...mapState('payments_annotateds', [
            'items',
            'notification',
            'loading',
            'totalItems',
            'showDialog',
            'filterDialog',
            'possible_status',
        ]),
        ...mapFields('payments_annotateds', ['pagination']),
    },
    data: () => ({
        headers: [
            { text: 'ID', value: 'id', sortable: false },
            {
                text: 'Contact Id',
                value: 'contact_id',
                sortable: false,
                align: 'center',
            },
            {
                text: 'Contact Name',
                value: 'contact_name',
                sortable: false,
                align: 'center',
            },
            { text: 'Valor', value: 'value', sortable: false, align: 'center' },
            {
                text: 'Status',
                value: 'status',
                sortable: false,
                align: 'center',
            },
            {
                text: 'Banco',
                value: 'bank_name',
                sortable: false,
                align: 'center',
            },
            {
                text: 'Data Criação',
                value: 'date_created',
                sortable: false,
                align: 'center',
            },
            {
                text: 'Data Pagamento',
                value: 'date_payment',
                sortable: false,
                align: 'center',
            },
            {
                text: 'Ações',
                value: 'actions',
                sortable: false,
                align: 'center',
                width: '10%',
            },
        ],
    }),
    watch: {
        pagination: {
            handler() {
                this.getItems({ resetPage: false })
            },
        },
    },
}
</script>
