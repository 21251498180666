<template>
  <div class="login-center" @keydown.enter="enterPressed">
    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2000"
      v-model="showNotification"
    >
      {{ notificationMessage }}
    </v-snackbar>
    <v-container fill-height fluid>
      <v-row justify="center">
        <v-col cols="12" sm="6" md="6">
          <v-col align="center" justify="center" cols="12" sm="12" md="12">
            <v-img
              max-width="250"
              max-height="250"
              src="../../assets/logo-sx.png"
            ></v-img>
          </v-col>
          <v-card  v-if="authenticationStep">
            <v-card-title class="text-center justify-center">
              <span class="headline text-center">Confirmar acesso</span>
            </v-card-title>
            <v-card-text >
              <v-layout column align-center justify-center>
                <v-layout column align-center justify-center v-if="qrCodeAuthenticatorShow">
                  Leia o código abaixo no APP Authenticator ( Microsoft, Google, etc. )
                  <v-flex md6>
                    <img :src="qrCodeAuthenticator"/>
                  </v-flex>
                </v-layout>
                Informe o Token Gerado no APP Authenticator ( Microsoft, Google, etc. )
                <v-flex md6>
                  <v-text-field class="mt-10"
                    v-model="token"
                    label="Token"
                    name="token"
                    prepend-icon="mdi-lock"
                    type="number"
                  />
                </v-flex>
              </v-layout>
            </v-card-text>
            
            <v-col class="text-center">
              <v-btn text type="submit" right @click="setAuthenticationFalse">Voltar</v-btn>
              <v-btn text type="submit" right @click="checkAuthentication(token)">Entrar</v-btn>
            </v-col>
          </v-card>
          <v-form v-else ref="form" v-model="valid" lazy-validation @submit="login" class="mt-4">
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    prepend-icon="mdi-account"
                    :rules="rulesFields"
                    class="input-login"
                    label="Email *"
                    v-model="username"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    prepend-icon="mdi-lock"
                    :rules="rulesFields"
                    class="input-login"
                    label="Senha *"
                    v-model="password"
                    :type="showPassword ? 'text' : 'password'"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPassword = !showPassword"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>

          <v-col v-if="!authenticationStep" class="text-center">
            <v-btn text type="submit" right @click="login">Entrar</v-btn>
          </v-col>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style lang="sass" scoped>
.login-center
  display: flex
  justify-center: center
  align-items: center
  margin-top: 25vh

.authenticator-center
  display: flex
  justify-center: center
  align-items: center
  margin-top: 25vh

.title-login
  color: white

.input-login
  color: white !important
</style>


<script>
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "Login",

  data: () => ({
    token: '',
    token_message: '',
    valid: false,
    showPassword: false,
    submitted: false,
    rulesFields: [(value) => !!value || "Campo obrigatório"],
  }),
  computed: {
    ...mapState("login", [
      "accessToken",
      "user",
      "notificationType",
      "notificationMessage",
      "authenticationStep",
      "qrCodeAuthenticator",
      "qrCodeAuthenticatorShow"
    ]),
    ...mapFields("login", [
      "user.username",
      "user.password",
      "showNotification",
    ]),
    ...mapGetters("login", ["isLogged","getAuthenticationStep"]),
  },
  mounted(){
    console.log(this.$route)
    this.token_message = 'Olá, um token foi enviado para seu email e/ou whatsaap, informe esse token para validar seu acesso.'
  },
  methods: {
    enterPressed() {
      console.log('Apertou Enter')
      if(this.authenticationStep){
        this.checkAuthentication(this.token)
      } else{
        this.login()
      }
    },
    login() {
      let valid = this.$refs.form.validate();
      if (valid) {
        this.checkUser();
      }
    },
    setAuthenticationFalse(){
      this.setAuthenticationStep({show: false})
    },

    ...mapActions("login", ["checkUser", "redirectToHomePage", "sendAuthentication", "checkAuthentication"]),
    ...mapMutations("login", ["setAuthenticationStep"]),
  },
};
</script>
