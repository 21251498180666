import { getField, updateField } from 'vuex-map-fields';
import axios from "axios"
import endPoints from '../../../config/endPoints';

export default {
    namespaced: true,
    state: {
        cep: '',
        loading: false,
        notificationType: '',
        notificationMessage: '',
        showNotification: false,
        shipment_options: [],
        shipmentItem: {},
        type: ''
    },
    mutations: {
        showLoading: (state, payload) => state.loading = payload.show,
        setShipmentOptions: (state, payload) => state.shipment_options = payload.items,
        setShipmentItem: (state, payload) => state.shipmentItem = payload.item,
        showNotification: (state, payload) => {
            state.showNotification = payload.show,
                state.notificationMessage = payload.message,
                state.notificationType = payload.type
        },
        updateField,
    },
    getters: {
        getField,
    },
    actions: {
        calcShipment: ({ commit, state }) => {

            if (state.cep == "") {
                let notification = {
                    show: true,
                    message: "Cep deve ser informado",
                    type: "error"
                }
                commit('showNotification', notification)
            } else {
                var r_cep = state.cep.replace("-")
                if (r_cep.length == 8) {
                    commit('showLoading', { show: true })
                    let url = endPoints.stock + "/shipment/" + state.shipmentItem.id
                    let data = []
                    axios.post(url, { cep: r_cep, type: state.type }).then(response => {
                        if (response.data) {
                            data = response.data
                        }
                        commit('showLoading', { show: false })
                        commit('setShipmentOptions', { items: data })
                    }).catch(() => {
                        let notification = {
                            show: true,
                            message: "Ocorreu um erro ao calcular o frete. Tente mais tarde",
                            type: "error"
                        }
                        commit('showNotification', notification)
                        commit('showLoading', { show: false })
                    })
                } else {
                    let notification = {
                        show: true,
                        message: "Cep invalido",
                        type: "error"
                    }
                    commit('showNotification', notification)
                }
            }

        }
    }
}