<template>
  <div>
    <v-snackbar
      :color="notification.type"
      right
      top
      :timeout="2000"
      v-model="notification.show"
    >
      {{ notification.message }}
    </v-snackbar>
    <v-list-item class="card-agent text-center justify-center elevation-10 agent-data">
      <v-list-item-content>
        <v-list-item-title class="headline">  {{ folder.title }}</v-list-item-title>
        <v-list-item-subtitle>
          <div> ID: {{ folder.id }}:</div>
          <div> Descrição: {{ folder.description }}</div>
          <div> Data: {{ getDate(folder.date) }}</div>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-tabs v-model="tab" class="agent-data elevation-10">
      <v-tab>Agentes</v-tab>
      <v-tab>Usuários</v-tab>
      <v-tab>Clientes</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item class="elevation-10">
        <v-card> 
          <v-card-title>
              Agentes
              <v-spacer></v-spacer>
              <div class="button-toolbar">
                <v-btn color="black"  @click="addAgent" dark class="add-align-right" small>
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
                <v-btn color="black"  @click="showFilterDialog({show: true, filterType: 'FILTER_AGENT'})" dark class="add-align-right" small>
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </div>
          </v-card-title>
          
          
          <v-data-table
            :headers="headersAgents"
            :loading="loadingAgents"
            :items="agents"
            no-data-text="Nenhum agente encontrado"
            class="elevation-1 table-subagent"
          >
            <template v-slot:[`item.actions`]="{ item }" v-if="profileAccess()">
              <v-icon small class="mr-2" @click="prepareItemToDelete(item)">mdi-delete</v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>

      <v-tab-item class="elevation-10">
        <v-card> 
          <v-card-title>
              Usuários
              <v-spacer></v-spacer>
              <div class="button-toolbar">
                <v-btn color="black"  @click="addUser" dark class="add-align-right" small>
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </div>
          </v-card-title>
          <v-data-table
            :headers="headersUsers"
            :loading="loadingUsers"
            :items="users"
            no-data-text="Nenhum usuário encontrado"
            class="elevation-1 table-subagent"
          >
            
            <template v-slot:[`item.actions`]="{ item }" v-if="profileAccess()">
              <v-icon small class="mr-2" @click="prepareItemToDelete(item)">mdi-delete</v-icon>
            </template>
            <template v-slot:[`item.percentage`]="{ item }">
              <span>{{ getPercentage(item.percentage) }}</span>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
      <v-tab-item class="elevation-10">
        <v-card> 
          <v-card-title>
              Clientes
              <v-spacer></v-spacer>
              <div class="button-toolbar">
                <v-btn color="black"  @click="addClient" dark class="add-align-right" small>
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </div>
          </v-card-title>
          <v-data-table
            :headers="headersClient"
            :loading="loadingClients"
            :items="clients"
            no-data-text="Nenhum cliente encontrado"
            class="elevation-1 table-subagent"
          >
            
            <template v-slot:[`item.actions`]="{ item }" v-if="profileAccess()">
              <v-icon small class="mr-2" @click="prepareItemToDelete(item)">mdi-delete</v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
    </v-tabs-items>

    <v-dialog :value="showDialog" @click:outside="setShowDialog(false)" width="1280">
      <agents-folder-form></agents-folder-form>
    </v-dialog>

    
    <v-dialog :value="showDeleteDialog" @click:outside="closeDeleteDialog" width="300">
      <v-card>
        <v-card-title class="headline">Confirmar exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn
              text
              @click="closeDeleteDialog"
            >Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn
              text
              type="submit"
              right
              @click="deleteItem"
            >Confirmar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog :value="filterDialog" @click:outside="showFilterDialog({show: false})" width="600">
      <v-card>
        <v-card-title v-if="filterType == 'FILTER_USER'" class="headline">Filtrar por Usuários</v-card-title>
        <v-card-title v-else class="headline">
          Filtrar por Agentes
          <v-btn text color="red" class="filter_remove" @click="filterRemove()"><v-icon>mdi-filter-remove</v-icon></v-btn>
        </v-card-title>

        <v-container grid-list-md>
          <v-layout wrap class="mt-4">
            <v-flex 
              xs12 sm12 md12 
              v-if="filterType == 'FILTER_USER'"
            >
              <v-text-field
                label="Usuário"
                v-model="name_filter"
                data-vv-name="name_filter"
                outlined
              ></v-text-field>
            </v-flex>

            <v-flex 
              v-if="filterType == 'FILTER_AGENT'"
              xs12 sm12 md12
            >
              <v-text-field
                label="Agente"
                v-model="name_filter"
                data-vv-name="name_filter"
                outlined
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-container>

        <v-card-actions>
          <v-col>
            <v-btn
              text
              @click="showFilterDialog({show: false})"
            >Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn
              text
              type="submit"
              right
              @click="getFilterGeneric({ folder_id: folder_id})"
            >Confirmar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import AgentsFolderForm from "./AgentsFolderForm"
import { mapFields } from "vuex-map-fields";

export default {
  name: "AgentFolder",
  computed: {
    ...mapState("agents_folder", [
      "folder",
      "agents",
      "users",
      "clients",
      "loadingAgents",
      "loadingUsers",
      "loadingClients",
      "notification",
      "showDeleteDialog",
      "showDialog",
      "filterDialog",
      "filterType",
      "selectedItem",
    ]),
    ...mapState("login", ["userData"]),
    ...mapFields("agents_folder", [
      "filterGeneric.name_filter",
    ]),
  },
  
  components: {
    AgentsFolderForm
    // "rake-deal-form": RakeDealForm,
    // "subagents-form": SubAgentsForm,
    // "documents-agent": DocumentsAgent, 
  },
  created() {
    let folder_id = this.$route.params.folder_id;
    this.folder_id = folder_id;
    this.getFolder(folder_id)
    this.getAgents(folder_id);
    this.getUsers(folder_id);
    this.getClients(folder_id);
  },
  data: () => ({
    headersUsers: [
      { text: "Nome", value: "username", sortable: false },
      { text: "Comissão %", value: "percentage", sortable: false },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        align: "center",
        width: "10%",
      },
    ],
    headersClient: [
      { text: "Nome", value: "username", sortable: false },
      { text: "Contato", value: "contact", sortable: false },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        align: "center",
        width: "10%",
      },
    ],
    headersAgents: [
      { text: "Nick", value: "nickname", sortable: false },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        align: "center",
        width: "10%",
      },
    ],
    tab: ''
  }),
  methods: {
    ...mapMutations("agents_folder", [
      "addUser",
      "addAgent",
      "addClient",
      "prepareItemToDelete",
      "setShowDialog",
      "closeDeleteDialog",
      "showFilterDialog"
    ]),
    ...mapActions("agents_folder", ["getAgents", "goBack", "getUsers", "getClients", "getFolder", "deleteItem", "getFilterGeneric"]),
    getDate(date) {
      var d = new Date(date);
      return d.toLocaleDateString()
    },
    getPercentage(num) {
      return Number(num/100).toLocaleString(undefined,{style: 'percent', minimumFractionDigits:2}); 
    },
    profileAccess() {
      if ([0,2,70].includes(this.userData.role)) {
        return false;
      } else {
        return true;
      }
    },
    filterRemove() {
      let folder_id = this.$route.params.folder_id;
      this.getAgents(folder_id);
      this.getUsers(folder_id);
      this.showFilterDialog({show: false})
    }
  },
};
</script>

<style scoped>
@import "../agents/css/agent.css";

.filter_remove {
  margin-left: auto;
}

</style>