<template>
  <div>
    <div @drop.prevent="addDropFile" @dragover.prevent>
      <v-card>
        <v-card-title>
          <span class="headline text-center">Nova Conciliação</span>
          <v-spacer></v-spacer>
          <v-progress-circular
            v-if="loading"
            indeterminate
            class="text-right"
            color="primary"
            size="24"
          ></v-progress-circular>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm4 md4>
                  <v-menu
                      v-model="showStart"
                      :close-on-content-click="true"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                  >
                      <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                              v-model="start_formatted"
                              label="De *"
                              outlined=""
                              readonly
                              append-icon="mdi-close"
                              @click:append="clearStart()"
                              persistent-hint
                              v-bind="attrs"
                              v-on="on"
                              :error-messages="errors.collect('start_formatted')"
                              v-validate="'required:Data Inicial,start'"
                              data-vv-name="start_formatted"
                          ></v-text-field>
                      </template>
                      <v-date-picker 
                        locale="pt-br" 
                        v-model="editedItem.start" 
                        no-title 
                        @input="(e) => this.setStart(e)"
                      >
                      </v-date-picker>
                  </v-menu>
                </v-flex>

                <v-flex xs12 sm4 md4>
                  <v-menu
                      v-model="showEnd"
                      :close-on-content-click="true"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                  >
                      <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                              v-model="end_formatted"
                              label="Até *"
                              outlined=""
                              readonly
                              append-icon="mdi-close"
                              @click:append="clearEnd()"
                              persistent-hint
                              v-bind="attrs"
                              v-on="on"
                              :error-messages="errors.collect('end_formatted')"
                              v-validate="'required:Data Final,end'"
                              data-vv-name="end_formatted"
                          ></v-text-field>
                      </template>
                      <v-date-picker 
                        locale="pt-br" 
                        v-model="editedItem.end" 
                        no-title 
                        @input="(e) => this.setEnd(e)"
                      >
                      </v-date-picker>
                  </v-menu>
                </v-flex>

                <v-flex xs12 sm4 md4>
                   <v-select
                        label="Banco *"
                        :items="banks"
                        :item-text="getTextBank"
                        item-value="id"
                        clear-icon="mdi-close"
                        :clearable="true"
                        outlined
                        v-model="editedItem.bank"
                    >
                    </v-select>
                </v-flex>

                <v-flex xs12 sm12 md12>
                  <v-file-input 
                    ref="fileupload"
                    required
                    accept=".ofx" 
                    label="OFX *"
                    v-model="editedItem.file"
                    :error-messages="errors.collect('file')"
                    data-vv-name="file"
                    outlined
                    show-size
                    @change="selectFile"
                    v-validate="'required:OFX,file'"
                  ></v-file-input>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-col>
            <v-btn text @click="closeDialog">{{ mode == 'VIEW' ? 'Voltar' : 'Cancelar'}}</v-btn>
          </v-col>
          <v-col class="text-right" v-if="mode != 'VIEW'">
            <v-btn text type="submit" right @click="saveFinancialConciliation">Criar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "FinancialConciliationForm",
  beforeUpdate() {
    this.setFormDatesOnUpdate()
  },
  computed: {
    ...mapState("bank", { banks: "items" }),
    ...mapState("instances", ["instances"]),
    ...mapState("financial_conciliation", [
      "item",
      "loading",
      "mode",
      "editedItem"
    ]),
  },
  created() {
    this.getItems()
    this.clearStart()
    this.clearEnd()

    this.$validator.extend("required", {
      getMessage: (field, [label]) => label + " deve ser informado",
      validate: (_, [, field]) => {
        if (this[field]) {
          return true;
        } else {
          return false;
        }
      },
    });
  },
  methods: {
    addDropFile(e) {
      if (this.mode == "ADD") {
        if (e.dataTransfer) {
          if (e.dataTransfer.files[0]) {
            this.file = e.dataTransfer.files[0];
          }
        }
      }
    },
    ...mapMutations("financial_conciliation", ["closeDialog", "onlyCloseDialog", "showNotification"]),
    ...mapActions("financial_conciliation", ["createFinancialConciliation"]),
    ...mapActions("bank", ["getItems"]),
    saveFinancialConciliation() {
      this.$validator.validate().then((valid) => {
        valid = true
        if (valid) {
          this.onlyCloseDialog()
          this.createFinancialConciliation();
        } else {
          let notification = {
              show: true,
              message: "Erro ao criar nova conciliação. Verifique os campos",
              type: "error"
          }

          this.showNotification(notification)
        }
      });
    },
    selectFile(file) {
      if (file !== undefined) {
        this.file = file
      }
    },
    clearStart() {
      this.start = ''
      this.start_formatted = ''
    },
    clearEnd() {
        this.end = ''
        this.end_formatted = ''
    },
    setStart(date) {
        this.start = date
        this.showStart = false
        this.start_formatted = this.formatDate(date)
    },
    setEnd(date) {
        this.end = date
        this.showEnd = false
        this.end_formatted = this.formatDate(date)
    },
    formatDate(date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`
    },
    setFormDatesOnUpdate() {
      if(this.end === "") 
        this.end_formatted = ""

      if(this.start === "") 
        this.start_formatted = ""

      if(this.$refs.fileupload.isDirty && (this.editedItem.start === "" || this.editedItem.end === "" || this.editedItem.bank === ""))
        this.$refs.fileupload.clearableCallback()
    },
    getTextBank(item) {
      if(item.account) {
        return item.name + ' | ' + item.account
      } else {
        return item.name
      }
    },
  },
  data: () => ({
    valid: false,
    showStart: false,
    showEnd: false,
    start_formatted: '',
    end_formatted: ''
  }),
};
</script>
