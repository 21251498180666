<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar
      :color="notification.type"
      right
      top
      :timeout="2000"
      v-model="notification.show"
    >
      {{ notification.message }}
    </v-snackbar>

    <v-toolbar flat class="elevation-5">
      <v-icon color="black">mdi-file-tree</v-icon>
      <v-toolbar-title class="ml-2">Colaboradores</v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="button-toolbar">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="black"
              dark
              class="ma-2"
              @click="setShowFilter(true)"
              v-bind="attrs"
              v-on="on"
              small
            >
              <v-icon color="white">mdi-filter</v-icon>
            </v-btn>
          </template>
          <span>Filtro</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="black"
              dark
              class="ma-2"
              @click="getItems"
              v-bind="attrs"
              v-on="on"
              small
              ><v-icon>mdi-refresh</v-icon></v-btn
            >
          </template>
          <span>Recarregar</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="black"
              dark
              class="ma-2"
              v-if="profileAccess()"
              v-bind="attrs"
              v-on="on"
              @click="add()"
              small
              ><v-icon>mdi-plus</v-icon></v-btn
            >
          </template>
          <span>Adicionar Colaborador</span>
        </v-tooltip>
      </div>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :loading="loading"
      :items="items"
      :options.sync="pagination"
      :server-items-length="totalItems"
      class="elevation-10 table-margin"
      no-data-text="Nenhum colaborador encontrado"
    >
      <template v-slot:[`item.status`]="{ item }">
        <span>{{ getStatusByCode(item.status) }}</span>
      </template>

      <template v-slot:[`item.phone`]="{ item }">
        <span>{{ getPhone(item.phone) }}</span>
      </template>

      <template v-slot:[`item.department`]="{ item }">
        <span>{{ getDepartment(item.department) }}</span>
      </template>

      <template v-slot:[`item.role`]="{ item }">
        <span>{{ getRole(item.role) }}</span>
      </template>

      <template v-slot:[`item.cpf`]="{ item }">
        <span>{{ getCpf(item.cpf) }}</span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ attrs, on }">
            <v-icon
              color="black"
              small
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="viewItem({ item: item })"
              >mdi-information</v-icon
            >
          </template>
          <span>Informações</span>
        </v-tooltip>
        <v-tooltip bottom v-if="profileAccess()">
          <template v-slot:activator="{ attrs, on }">
            <v-icon
              color="blue darken-1"
              v-bind="attrs"
              v-on="on"
              small
              class="mr-2"
              @click="edit(item)"
              >mdi-pencil</v-icon
            >
          </template>
          <span>Editar</span>
        </v-tooltip>
        <v-tooltip bottom v-if="profileAccess()">
          <template v-slot:activator="{ attrs, on }">
            <v-icon
              color="red darken-1"
              small
              v-bind="attrs"
              v-on="on"
              class="mr-2"
              @click="deleteCollaborators(item)"
              >mdi-delete</v-icon
            >
          </template>
          <span>Excluir</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog
      :value="showDialog"
      @click:outside="setShowDialog(false)"
      width="1000"
    >
      <collaborators-form></collaborators-form>
    </v-dialog>
    <v-dialog
      :value="filterDialog"
      @click:outside="setShowFilter(false)"
      width="600"
    >
      <collaborators-filter></collaborators-filter>
    </v-dialog>
    <v-dialog
      :value="deleteDialog"
      @click:outside="setDeleteDialog(true)"
      @keydown.enter="deleteItem"
      width="300"
    >
      <v-card>
        <v-card-title class="headline">Confirmar a exclusão?</v-card-title>
        <v-card-actions>
          <v-col>
            <v-btn text @click="setDeleteDialog(false)">Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn text type="submit" right @click="deleteItem"
              >Confirmar</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import CollaboratorsForm from "./CollaboratorsForm";
import CollaboratorsFilter from "./CollaboratorsFilter";

export default {
  name: "Collaborators",
  created() {
    this.getRoles();
    this.getCompanies();
    this.getDepartments();
    this.getItems({ resetPage: false });
  },
  components: {
    CollaboratorsForm,
    CollaboratorsFilter,
  },
  computed: {
    ...mapState("login", ["userData"]),
    ...mapState("collaborators", [
      "notification",
      "companies",
      "items",
      "mode",
      "loading",
      "totalItems",
      "showDialog",
      "filterDialog",
      "deleteDialog",
      "departments",
      "roles",
    ]),
    ...mapFields("collaborators", ["id_company", "pagination"]),
  },
  data: () => ({
    headers: [
      { text: "ID", value: "id", sortable: false },
      { text: "Nome Completo", value: "name", sortable: false, align: "center" },
      { text: "Empresa", value: "company_name", sortable: false, align: "center"},
      { text: "Setor", value: "department", sortable: false, align: "center" },
      { text: "Cargo", value: "role", sortable: false, align: "center" },
      { text: "CPF", value: "cpf", sortable: false, align: "center" },
      { text: "Telefone", value: "phone", sortable: false, align: "center" },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        align: "center",
        width: "10%",
      },
    ],
  }),
  methods: {
    ...mapActions("collaborators", [
      "viewItem",
      "getItems",
      "getRoles",
      "getDepartments",
      "getCompanies",
      "deleteItem",
      "edit",
    ]),
    ...mapMutations("collaborators", [
      "setShowDialog",
      "add",
      "deleteCollaborators",
      "view",
      "setShowFilter",
      "setDeleteDialog",
    ]),
    getCpf(cpf) {
      return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    },
    getPhone(tel) {
      tel = tel.replace(/\D/g, ""); //Remove tudo o que não é dígito
      tel = tel.replace(/^(\d{2})(\d)/g, "($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
      tel = tel.replace(/(\d)(\d{4})$/, "$1-$2"); //Coloca hífen entre o quarto e o quinto dígitos
      return tel;
    },

    getDepartment(department_id) {
      let department = this.departments.find(
        (department) => department.id == department_id
      );
      return department.name;
    },
    getRole(role){

      role -= 1
      
      return this.roles[role].name
    },
    getStatusByCode(role) {
      switch (role) {
        case 0:
          return "Inativo";
        case 1:
          return "Ativo";
      }
    },
    profileAccess() {
      if (
        this.userData.role == 0 ||
        this.userData.role == 1 ||
        this.userData.role == 70
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    pagination: {
      handler() {
        this.getItems({ resetPage: false });
      },
    },
  },
};
</script>
