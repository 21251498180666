<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar
      :color="notification.type"
      right
      top
      :timeout="2000"
      v-model="notification.show"
    >
      {{ notification.message }}
    </v-snackbar>

    <v-dialog
      :value="filterDialog"
      width="600"
      @click:outside="setDialog({ dialog: 'filterDialog', show: false })"
    >
      <player-history-filter></player-history-filter>
    </v-dialog>

    <v-toolbar>
      <v-toolbar-title class="font-weight-medium"
        >Histórico do Jogador</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <div class="button-toolbar text-right">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="black"
              dark
              class="ma-2"
              @click="setDialog({ dialog: 'filterDialog', show: true })"
              v-bind="attrs"
              v-on="on"
              small
            >
              <v-icon color="white">mdi-filter</v-icon>
            </v-btn>
          </template>
          <span>Filtro</span>
        </v-tooltip>
      </div>
    </v-toolbar>

    <v-tabs v-model="tab" class="player-data">
      <v-tab
        @click="
          getItems({
            end_point: 'deposits',
            extend_url: '',
            resetPage: true,
          })
        "
        >Depósitos</v-tab
      >
      <v-tab
        @click="
          getItems({
            end_point: 'withdrawal',
            extend_url: '',
            resetPage: true,
          })
        "
        >Saques</v-tab
      >
      <v-tab
        @click="
          getItems({
            end_point: 'player_history',
            extend_url: '/rake',
            resetPage: true,
          })
        "
        >Rake Gerado</v-tab
      >
      <v-tab
        @click="
          getItems({
            end_point: 'player_history',
            extend_url: '/closure',
            resetPage: true,
          })
        "
        >Fechamento Semanal</v-tab
      >
      <v-tab
        @click="
          getItems({
            end_point: 'periods_closure_transactions',
            extend_url: '',
            resetPage: true,
          })
        "
        >Transações</v-tab
      >
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item class="elevation-15">
        <player-deposits></player-deposits>
      </v-tab-item>

      <v-tab-item class="elevation-15">
        <player-withdrawals></player-withdrawals>
      </v-tab-item>

      <v-tab-item class="elevation-15">
        <player-rake></player-rake>
      </v-tab-item>

      <v-tab-item class="elevation-15">
        <player-closure></player-closure>
      </v-tab-item>
      
      <v-tab-item class="elevation-15">
        <player-transactions></player-transactions>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import PlayerHistoryFilter from "./PlayerHistoryFilter";
import PlayerClosure from "./PlayerTabs/PlayerClosure";
import PlayerDeposits from "./PlayerTabs/PlayerDeposits";
import PlayerRake from "./PlayerTabs/PlayerRake";
import PlayerTransactions from "./PlayerTabs/PlayerTransactions";
import PlayerWithdrawals from "./PlayerTabs/PlayerWithdrawals";

export default {
  name: "PlayerHistory",
  components: {
    "player-history-filter": PlayerHistoryFilter,
    "player-closure": PlayerClosure,
    "player-deposits": PlayerDeposits,
    "player-rake": PlayerRake,
    "player-transactions": PlayerTransactions,
    "player-withdrawals": PlayerWithdrawals,
  },
  methods: {
    ...mapActions("player_history", ["getItems"]),
    ...mapMutations("player_history", ["view", "setDialog"]),
  },
  computed: {
    ...mapState("login", ["userData"]),
    ...mapState("player_history", [
      "items",
      "notification",
      "loading",
      "filterDialog",
    ]),
    ...mapFields("player_history", [
      "tab",
      "filter.id_pppoker",
      "filter.value",
      "filter.status",
      "filter.start",
      "filter.end",
    ]),
  },
};
</script>
