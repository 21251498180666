var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('div',{staticClass:"button-toolbar mt-4 mr-4"},[_c('v-tooltip',{attrs:{"bottom":"","dense":"","size":"30"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"py-4",attrs:{"color":"#E4E4E4","elevation":"0","small":""},on:{"click":function($event){return _vm.setSelectedRegistrys()}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"dark"}},[_vm._v("mdi-card-multiple")]),_vm._v(" Selecionar Registros ")],1)]}}])},[_c('span',[_vm._v("Múltiplos Registros")])])],1),_c('div',{staticClass:"button-toolbar mt-4 mr-5"},[(this.selectedRegistrys)?_c('v-tooltip',{attrs:{"bottom":"","dense":"","size":"30"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"py-4",attrs:{"color":"#E4E4E4","elevation":"0","small":""},on:{"click":function($event){_vm.setDialogManualLow({
                                multiples: true,
                                show: true,
                                items: _vm.selected.map((item) => {
                                    return {
                                        id: item.id,
                                        new_total_vinculations:
                                            item.linked_to,
                                    }
                                }),
                            })}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"dark"}},[_vm._v("mdi-arrow-down-bold")]),_vm._v(" Baixa Manual ")],1)]}}],null,false,1038258044)},[_c('span',[_vm._v("Baixa Manual")])]):_vm._e()],1),_c('v-spacer'),_c('div',{staticClass:"button-toolbar mt-4 mr-1"},[_c('v-tooltip',{attrs:{"bottom":"","dense":"","size":"30"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"py-4",attrs:{"color":"#E4E4E4","elevation":"0","small":""},on:{"click":function($event){return _vm.setShowFilter(true)}}},'v-btn',attrs,false),on),[_vm._v(" Filtrar "),_c('v-icon',{attrs:{"small":"","color":"dark"}},[_vm._v("mdi-filter")])],1)]}}])},[_c('span',[_vm._v("Filtro")])])],1)],1),_c('v-data-table',{attrs:{"item-key":"id","show-select":_vm.selectedRegistrys,"headers":_vm.headers,"options":_vm.pagination,"server-items-length":_vm.totalItems,"items":_vm.items,"item-class":_vm.getClassColor,"footer-props":{
            'items-per-page-options': [10, 20, 50, 100],
            'items-per-page-text': 'Itens por página',
            'show-first-last-page': true,
            'page-text': `Página ${_vm.pagination.page} de ${
                parseInt(_vm.totalItems / _vm.pagination.itemsPerPage) + 1
            }`,
        }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:`item.actions`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ attrs, on }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","color":"black"},on:{"click":function($event){return _vm.openDialog(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-information")])]}}],null,true)},[_c('span',[_vm._v("Informações")])])]}},{key:`item.status`,fn:function({ item }){return [_c('v-chip',{staticClass:"ma-2",attrs:{"color":_vm.getColorStatus(item.status),"text-color":"white","label":""}},[_vm._v(_vm._s(_vm.getStatus(item)))])]}},{key:`item.type`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.getTypeOrder(item.type)))])]}},{key:`item.created_at`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.created_at)))])]}},{key:`item.value`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.formatMoney(item.value)))])]}},{key:`item.ligations`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.formatLigations(item.linked_to, item.total_vinculations)))])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-dialog',{attrs:{"value":_vm.dialogManualLow,"width":"1280"},on:{"click:outside":function($event){return _vm.setCloseDialogManualLow()}}},[_c('PeriodsConciliationLowDialog')],1),_c('v-dialog',{attrs:{"value":_vm.dialog,"width":"1280"},on:{"click:outside":_vm.closeDialogVinculation}},[_c('PeriodConciliationVinculationsDialog')],1),_c('v-dialog',{attrs:{"value":_vm.filterDialog,"width":"600"},on:{"click:outside":function($event){return _vm.setShowFilter(false)}}},[_c('PeriodConciliationOrdersFilter')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }