<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline">Filtro</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm6 md6>
                <v-select
                  clearable
                  clear-icon="mdi-close"
                  label="Clube"
                  :items="instances"
                  v-model="club"
                  item-text="name"
                  item-value="id_instance"
                  data-vv-name="id_instance"
                  outlined
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field
                  required
                  label="ID"
                  v-model="id_pppoker"
                  data-vv-name="id_pppoker"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field required label="Nick" v-model="nick" data-vv-name="nick" outlined></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                  <v-select
                    label="Tipo de Gestor"
                    :items="types_manager"
                    v-model="type_manager"
                    :error-messages="errors.collect('type_manager')"
                    item-text="description"
                    item-value="type"
                    clear-icon="mdi-close"
                    :clearable="true"
                    outlined
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-select
                    label="Cobrar Transações *"
                    :items="types_transactions_charged"
                    v-model="transactions_charged"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('transactions_charged')"
                    v-validate="'required:Tipo de Gestor,transactions_charged'"
                    item-text="description"
                    item-value="type"
                    data-vv-name="transactions_charged"
                    outlined
                  ></v-select>
                </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn text @click="showFilter({show: false})">Cancelar</v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn text type="submit" right @click="filter">Filtrar</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "ManagersFilter",
  computed: {
    ...mapState('instances', ['instances']),
    ...mapFields("managers", [
        "filter.club",
        "filter.id_pppoker",
        "filter.nick",
        "filter.type_manager",
        "filter.transactions_charged"
      // ...
    ])
  },
  data: () => ({
    types_manager: [
      { type: 0, description: "Interno" },
      { type: 1, description: "Externo" }
    ],
    searchAgent: ''
  }),
  methods: {
    ...mapMutations("managers", ["showFilter"]),
    ...mapActions("managers", ["getItems"]),
    filter() {
      this.getItems({resetPage: true});
      this.showFilter({ show: false });
    },
  },
  watch: {
  }
};
</script>

<style>
</style>