export const BANK_ANNOTATED = 22;
export const BANK_BONUS = 38;
export const BANK_TRANSFER = 43;

export const DEPOSIT_PAYMENT_TYPE_TRANSFER = 0;
export const DEPOSIT_PAYMENT_TYPE_ANNOTATED = 1;
export const DEPOSIT_PAYMENT_TYPE_TICKET_EXCHANGE = 2;
export const DEPOSIT_PAYMENT_TYPE_PIX = 3;
export const DEPOSIT_PAYMENT_TYPE_BONUS = 4;
export const DEPOSIT_PAYMENT_TYPE_GAINS_LOSSES = 5;
export const DEPOSIT_PAYMENT_TYPE_CHIP = 6;
export const DEPOSIT_PAYMENT_TYPE_REFUND = 7;

export const DEPOSIT_TYPE_SEND_PLAYER_CHIP = 0;
export const DEPOSIT_TYPE_SEND_AGENCY_CHIP = 1;
export const DEPOSIT_TYPE_ANNOTATED_PAY = 2;
export const DEPOSIT_TYPE_ADD_TO_POSITIVE_BALANCE = 3;
export const DEPOSIT_TYPE_BONUS = 5;
export const DEPOSIT_TYPE_REFUND = 6;