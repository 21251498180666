import axios from 'axios';
import endPoints from '../../../config/endPoints';
import { getField, updateField } from 'vuex-map-fields';

export default {
    namespaced: true,
    state: {
        active: '',
        overlay: false,
        status: '',
        service: '',
        system_services: [],
        notificationMessage: '',
    },
    mutations: {
        add: (state) => { 
            state.showDialog = true
            state.mode = "ADD"
        },
        overlay: (state, payload) => {
            state.overlay = payload.show
        },
        setActive: (state, payload) => {
            state.active = payload.active
        },
        setStatus: (state, payload) => {
            state.status = payload.status
        },
        setSystemServices: (state, payload) => state.system_services = payload.system_services,
        showNotification: (state, payload) => state.notification = payload,
        updateField
    },
    getters: {
        getField
    },
    actions: {
        getSystemServices: async ( {commit} ) => {
            try {
                commit('overlay', {show: true})
                let resp = await axios.get(endPoints.status_service + "/check_all_services")
                commit('setSystemServices', {system_services: resp.data})
                commit('overlay', {show: false})
                return resp.data
            } catch(error) {
                commit('overlay', {show: false})
                console.log('teste')
            }
        },
        getStatus: async ( {commit, state} ) => {
            try {
                commit('overlay', {show: true})
                let resp = await axios.get(`${endPoints.system_services}/${state.service}`)
                commit('overlay', {show: false})
                let st = resp.data?.status
                let active = resp.data?.active
                commit('setStatus', {status: st})
                commit('setActive', {active: active})
            } catch(error) {
                console.log('teste')
            }
        },
        restart: async ( {commit, state} ) => {
            try {
                commit('overlay', {show: true})
                await axios.get(`${endPoints.system_services}/restart/${state.service}`)
                commit('overlay', {show: false})
                const successNotification = {
                    show: true,
                    message: 'Serviço reiniciado com sucesso',
                    type: 'success'
                }
                commit('showNotification', successNotification)
            } catch(error) {
                commit('overlay', {show: false})
                const errorNotification = {
                    show: true,
                    message: 'Erro ao iniciar o serviço',
                    type: 'error'
                }
                commit('showNotification', errorNotification)
            }
        },
        start: async ( {commit, state} ) => {
            try {
                commit('overlay', {show: true})
                await axios.get(`${endPoints.system_services}/restart/${state.service}`)
                commit('overlay', {show: false})
                const successNotification = {
                    show: true,
                    message: 'Serviço iniciado com sucesso',
                    type: 'success'
                }
                commit('showNotification', successNotification)
            } catch(error) {
                commit('overlay', {show: false})
                const errorNotification = {
                    show: true,
                    message: 'Erro ao iniciar o serviço',
                    type: 'error'
                }
                commit('showNotification', errorNotification)
            }
        },
    }
}