import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../../config/endPoints';

const createDate = (days, months, years) => {
    var date = new Date();
    date.setDate(date.getDate() + days);
    date.setMonth(date.getMonth() + months);
    date.setFullYear(date.getFullYear() + years);
    return date;
}

export default {
    namespaced: true,
    state: {
        average_time_deposits: 0,
        average_time_withdrawals: 0,
        items_day_quantity: [],
        items_month_quantity: [],
        instances: {},
        errorDeposits: 0,
        errorWithdrawals: 0,
        loading: false,
        pendingDeposits: 0,
        pendingWithdrawals: 0,
        selectedInstance: "",
        todayDeposits: 0,
        todayWithdrawals: 0,
        totalDeposits: 0,
        totalWithdrawals: 0,
        openChats: [],
        overlay: false,
    },
    mutations: {
        setAverageTimeDeposits: (state, payload) => state.average_time_deposits = payload,
        setAverageTimeWithdrawals: (state, payload) => state.average_time_withdrawals = payload,
        setErrorDeposits: (state, payload) => state.errorDeposits = payload,
        setErrorWithdrawals: (state, payload) => state.errorWithdrawals = payload,
        setItemsDayQuantity: (state, payload) => state.items_day_quantity = payload.items,
        setItemsMonthQuantity: (state, payload) => state.items_month_quantity = payload.items,
        setInstances: (state, payload) => state.instances = payload,
        setTodayDeposits: (state, payload) => state.todayDeposits = payload,
        setTotalDeposits: (state, payload) => state.totalDeposits = payload,
        setPendingDeposits: (state, payload) => state.pendingDeposits = payload,
        setOpenChats: (state, payload) => state.openChats = payload,
        setOverlay: (state, payload) => state.overlay = payload,
        setSelectedInstance: (state, payload) => state.selectedInstance = payload,
        setTotalWithdrawals: (state, payload) => state.totalWithdrawals = payload,
        setPendingWithdrawals: (state, payload) => state.pendingWithdrawals = payload,
        setTodayWithdrawals: (state, payload) => state.todayWithdrawals = payload,
        loading: (state, payload) => state.loading = payload.show,
        updateField,
    },
    getters: {
        getField,
        getSelectedInstance: state => () => {
            let id_instance=0;
            for(let i=0; i<state.instances.length; i++){
                if(state.instances[i].name == state.selectedInstance)
                    id_instance = state.instances[i].id_instance
            }
            return id_instance
        }, 
        getSelectedId: state => () => {
            let id
            for(let i=0; i<state.instances.length; i++){
                if(state.instances[i].name == state.selectedInstance){
                    id = state.instances[i].id
                }
            }
            return id == "" ? 0 : (typeof(id) == 'undefined' ? "" : id)
        }
    },
    actions: {
        getItemsDay: ({ commit }) => {
            commit('loading', { show: true })
            let url = endPoints.attendance_dashboard + "/attendances_day"
            axios.get(url).then(items => {
                let data_quantity = []
                items.data.forEach(i => {
                    let hour = i._id.date.substr(-2)
                    data_quantity.push([hour, i.count])
                });
                commit('loading', { show: false })
                commit('setItemsDayQuantity', { items: data_quantity })
            })
        },
        getItemsMonth: ({ commit }) => {
            // pass month
            let date = createDate(0, -1, 0)
            date = date.toISOString().substr(0, 10)
            date = date + " 00:00:00"
            date = new Date(date)
            commit('loading', { show: true })
            let url = endPoints.attendance_dashboard + "/attendances_month?start_time=" + date.getTime() / 1000
            axios.get(url).then(items => {
                let data_quantity = []
                if (items != null) {
                    let first = true
                    let last_date = ''
                    var register = ['', '', '', '', '', 0]
                    items.data.forEach(i => {
                        if (i._id.date != last_date) {
                            if (!first) {
                                data_quantity.push(Object.assign([], register))
                                date = i._id.date.substr(3, ) + "/" + i._id.date.substr(0, 2)
                                register = [date, '', '', '', '', 0]
                            } else {

                                first = false
                                date = i._id.date.substr(3, ) + "/" + i._id.date.substr(0, 2)
                                register = [date, '', '', '', '', 0]
                            }
                            last_date = i._id.date
                        }
                        let hour = new Date(i._id.period * 1000).getHours()
                        let period = 0
                        switch (hour) {
                            case 0:
                                period = 1
                                break;
                            case 6:
                                period = 2
                                break;
                            case 12:
                                period = 3
                                break;
                            case 18:
                                period = 4
                                break;
                        }
                        register[period] = i.count
                        register[5] += i.count

                    });
                }
                if (items.data.length != 0) {
                    data_quantity.push(Object.assign([], register))
                }
                commit('loading', { show: false })
                commit('setItemsMonthQuantity', { items: data_quantity })
            })
        },
        
        
        getChats: ({ commit, getters }) => {
            let url = endPoints.attendance_dashboard + "/monitoring/open_chats"
            url += `?id=${getters.getSelectedId()}`
            commit('setOverlay', true)
            axios.get(url).then(items => {
                commit('setOpenChats', items.data)
                commit('setOverlay', false)
            })
        },

        getDeposits: ({ commit, getters }) => {
            let url = endPoints.financial_monitoring;
            url += '/deposits';
            if(getters.getSelectedInstance() != 0 && getters.getSelectedInstance() != undefined){
                url += `?id_instance=${getters.getSelectedInstance()}`
            }
            commit('setOverlay', false)
            axios.get(url).then(items => {
                let data = items.data
                commit('setTotalDeposits', data.total_deposits)
                commit('setPendingDeposits', data.total_deposits_pending)
                commit('setErrorDeposits', data.total_deposits_error)
                commit('setTodayDeposits', data.total_deposits_today)
                commit('setAverageTimeDeposits', data.average_time)
                commit('setOverlay', false)
            });
        },

        getInstances: ({ commit }) => {
            let url = endPoints.instances
            url += '?page=' + 1 + '&' + 'itemsPerPage=50'
            commit('setOverlay', true)
            axios.get(url).then(instances => {
                commit('setInstances', instances.data.data.map(el => (
                    {
                        name: el.name,
                        id_instance: el.id_instance,
                        id: el.id
                    }
                )))
                commit('setOverlay', false)
            })
        },

        getWithdrawals: ({ commit, getters }) => {
            let url = endPoints.financial_monitoring
            url += '/withdrawals'
            if(getters.getSelectedInstance() != 0 && getters.getSelectedInstance() != undefined){
                url += `?id_instance=${getters.getSelectedInstance()}`
            }
            commit('setOverlay', true)
            axios.get(url).then(items => {
                let data = items.data
                commit("setTotalWithdrawals", data.total_withdrawals)
                commit("setPendingWithdrawals", data.total_withdrawals_pending)
                commit("setErrorWithdrawals", data.total_withdrawals_error)
                commit('setTodayWithdrawals', data.total_withdrawals_today)
                commit('setAverageTimeWithdrawals', data.average_time)
                commit('setOverlay', true)
            })
        }
        
    }
}