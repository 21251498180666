<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline text-center justify-center">Filtro</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md>
            <v-layout wrap>
              
              <v-flex xs12 sm6 md12>
                <v-text-field
                  label="Título"
                  v-model="title"
                  data-vv-name="title"
                  :clearable="true"
                  outlined
                ></v-text-field>
              </v-flex>


              <v-flex xs12 sm6 md6>
                <v-select
                  label="Setor de origem"
                  :items="possible_modules"
                  v-model="department_origin"
                  clear-icon="mdi-close"
                  :clearable="true"
                  outlined
                >
                </v-select>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-select
                  label="Setor de destino"
                  :items="possible_modules  "
                  v-model="department_destination"
                  clear-icon="mdi-close"
                  :clearable="true"
                  outlined
                >
                </v-select>
              </v-flex>
              
              <v-flex xs12 sm6 md12>
                <v-textarea
                  label="Descrição"
                  v-model="description"
                  data-vv-name="description"
                  outlined
                  :rows="3"
                ></v-textarea>
              </v-flex>
            
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn text @click="showFilter(false); clearFilter()">Cancelar</v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn text type="submit" right @click="filter()">Filtrar</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>

import { mapActions, mapMutations} from 'vuex';
import { mapFields } from "vuex-map-fields";


export default {
  name: 'RequestsFilter',
  methods: {
    ...mapMutations('requests', [
      'clearFilter',
      'showFilter',
      'setPage',
      'setFiltered'
    ]),
    ...mapActions('requests', [
      'getItems'
    ]),
    filter() {
      this.setPage(0)
      this.getItems()
      this.setFiltered(true)
      this.showFilter(false)
    } 
  },
  computed: {
    ...mapFields('requests', [
      "filter.title",
      "filter.status",
      "filter.department_origin",
      "filter.department_destination",
      "filter.description"
    ]),
  },
  data: () => ({
    possible_modules: [
      "Atendimento",
      "Comercial",
      "Diretoria",
      "Financeiro",
      "Marketing",
      "RH",
      "TI",
    ],
    possible_status: [
      {
        text: "Pendente",
        value: 0,
      },
      {
        text: "Concluido",
        value: 1,
      },
      {
        text: "Recusado",
        value: 2,
      },
    ]
  })
}
</script>