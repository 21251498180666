<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    
    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2000"
      v-model="showNotification"
    >
        {{ notificationMessage }}
    </v-snackbar>
    <v-toolbar flat color="orange lighten-4">
      <v-icon color="yellow darken-4">mdi-account-key </v-icon>
      <div style="margin-left:10px;color:rgb(138, 91, 4)">Perfil de Acesso</div>
      <v-spacer></v-spacer>
      <div class="button-toolbar">
        <v-btn color="black" dark class="ma-2" @click="showFilter({show: true})" small><v-icon>mdi-filter</v-icon></v-btn>
        <v-btn color="black" dark class="ma-2" @click="getItems" small><v-icon>mdi-refresh</v-icon></v-btn>
        <v-btn color="black" dark class="ma-2" v-if="profileAdministrator()" @click="add" small><v-icon>mdi-plus</v-icon></v-btn>
      </div> 
    </v-toolbar>


     <v-data-table
      :headers="headers"
      :loading="loading"
      :items="items"
      :options.sync="pagination"
      :server-items-length="totalItems"
      no-data-text="Nenhum perfil encontrado"
      class="elevation-10 table-margin"
    >
      <template v-slot:[`item.actions`]="{ item }">

        <v-tooltip bottom>
          <template v-slot:activator="{on, attrs}">
            <v-icon small v-on="on" v-bind="attrs" class="mr-2" @click="viewItem({item: item})">mdi-information</v-icon>
          </template>
          <span>Informações</span>
        </v-tooltip>

        <v-tooltip bottom v-if="profileAdministrator()" >
          <template v-slot:activator="{on, attrs}">        
            <v-icon small v-on="on" v-bind="attrs" class="mr-2" @click="edit({item: item})">mdi-pencil</v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>

        <v-tooltip bottom v-if="profileAdministrator()"> 
          <template v-slot:activator="{on, attrs}">    
            <v-icon small v-on="on" v-bind="attrs" class="mr-2" @click="showDeleteDialog(item)">mdi-delete</v-icon>
          </template>
          <span>Apagar</span>
        </v-tooltip>

      </template>
    </v-data-table>
    
    <v-dialog :value="dialog" width="800" @click:outside="closeDialog">
      <profile-form></profile-form>
    </v-dialog>

    <v-dialog :value="dialogFilter" width="600" @click:outside="showFilter({show: false})">
      <profile-filter></profile-filter>
    </v-dialog>

    <v-dialog :value="dialogDelete" persistent width="500">
      <v-card>
        <v-card-title class="headline">Ao excluir o Perfil irá remover a relação dos usuários com esse perfil. Confirmar a exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn
              text
              @click="closeDeleteDialog()"
            >Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn
              text
              type="submit"
              right
              @click="deleteItem"
            >Confirmar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogReceipt"
      @click:outside="setDialogReceipt({show: false})"
    >
      <v-card height="90vh">
        <v-img max-height="100%" :src="receipt_show" contain></v-img>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import { mapFields } from 'vuex-map-fields'
  import ProfilesForm from './ProfilesForm'
  import ProfilesFilter from './ProfilesFilter'

  export default {
    name: 'Profiles',
    profile_code: 'Profiles',
    computed: {
      ...mapState('login', ['userData']),
      ...mapState('profiles', ['contact_show', 'dialog', 'dialogDelete', 'dialogReceipt', 
            'item','instances', 'errors', 'users', 'loading', 'mode', 'notificationType', 'notificationMessage', 
            'rowsPerPage', 'receipt_show', 'totalItems', 'items', 'agents']),
      ...mapFields('profiles', [
          "dialogReceipt",
          'id_agent',
          'pagination',
          'dialogFilter',
          'overlay',
          'showNotification'
          // ...
        ])
    },
    created() {
      this.getItems({resetPage: false})
      this.setScreen({screen: 'profiles'})
    },
    components: {
      "profile-form": ProfilesForm,
      "profile-filter": ProfilesFilter
    },
    methods: {
      ...mapMutations('profiles', ['add', 'edit', 'showFilter', 'setDialogReceipt', "setScreen"]),
      ...mapActions('profiles', ['closeDialog', 'viewItem', 'closeDeleteDialog', 'deleteItem', 'getUsers', 'getItems', 'getItemsNoPage', 
                    'showDeleteDialog','showReceipt', 'startWithdrawal', 'stopWithdrawal', 'getAgents' ]),
      isMobile() {
        if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
          return true;
        }
        return false
      },
      finishSheet() {
        this.overlay = false
      },
      generateSheet() {
        this.overlay = true
      },
      profileAdministrator() {
        return this.userData.role == 99
      },
      getSheetName() {
        var date = new Date()
        var name = 'erros_' + date.toLocaleDateString() + " " + date.toLocaleTimeString()
        return name+'.xls'
      },
      getSheetProfileCode() {
        var date = new Date()
        var profile_code = 'erros_' + date.toLocaleDateString() + " " + date.toLocaleTimeString()
        return profile_code+'.xls'
      },
    },
    data: () => ({
      headers: [
        { text: "Nome",  value: "name", sortable: false},
        { text: "Código",  value: "profile_code", sortable: false},
        { text: "Ações",  value: "actions", sortable: false, align: "right" }
      ],
      sheet_fields: {
        Nome: {
          field: 'name',
        },
        ID: {
          field: 'profile_code',
        },
      },
      valid: false
    }),
    watch: {
      pagination: {
        handler () {
          this.getItems({resetPage: false})
        },
      },
    },
  }
</script>

<style>
</style>