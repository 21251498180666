<template>
  <div>
    <div @drop.prevent="addDropFile" @dragover.prevent>
      <v-card>
        <v-toolbar flat class="elevation-5">
          <div>
            <p></p>
            <v-toolbar-title>Auditorias</v-toolbar-title>
            <p class="font-weight-thin">Feito com amor para o Hemerson <v-icon>mdi-heart</v-icon></p>
          </div>
          <v-spacer></v-spacer>
          <div class="button-toolbar">
            <v-btn color="black" dark class="ma-2" @click="showFilter({show: true})" small><v-icon>mdi-filter</v-icon></v-btn>
            <v-btn color="black" dark class="ma-2" @click="getAudits" small><v-icon>mdi-refresh</v-icon></v-btn>
            <v-btn color="black" dark class="ma-2" @click="addAudit" small><v-icon>mdi-plus</v-icon></v-btn>
          </div> 
        </v-toolbar>

        <v-data-table
          :headers="headers"
          :loading="loading"
          :items="audits"
          :options.sync="pagination"
          no-data-text="Nenhuma auditoria encontrada"
          class="elevation-10 table-margin"
        >
          <template v-slot:[`item.date`]="{ item }">
            <span>{{ getDate(item.date)}}</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">

            <v-tooltip bottom>
              <template v-slot:activator="{on, attrs}">        
                <v-icon small v-on="on" v-bind="attrs" class="mr-2" @click="edit({item: item})">mdi-pencil</v-icon>
              </template>
              <span>Editar</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{on, attrs}">    
                <v-icon small v-on="on" v-bind="attrs" class="mr-2" @click="showDeleteDialogAudit(item)">mdi-delete</v-icon>
              </template>
              <span>Apagar</span>
            </v-tooltip>      

            <v-dialog :value="dialogAuditForm" width="800" @click:outside="closeDialogAuditForm">
              <fail-audit-form></fail-audit-form>
            </v-dialog>

          </template>
        </v-data-table>

      </v-card>
    </div>
  </div>
</template>

<script>
import debounce from '../../../util/debounce.js' 
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import FailAuditForm from './FailAuditForm'

export default {
  name: "FailForm",
  props: ["screen", "instances"],
  computed: {
    ...mapGetters("login", ["getInstanceId"]),
    ...mapState("fail", [
      "audits",
      "item",
      "loading",
      "mode",
      'dialogAuditForm'
    ]),
    ...mapState("instances", ["instances"]),
    ...mapFields("fail", [
      "dialogReceipt",
      "editedItem.status",
      "editedItem.balance",
      "editedItem.type",
      "editedItem.id",
      "editedItem.id_agent",
      "editedItem.description",
      "editedItem.date",
      "editedItem.date_picker",
      "editedItem.observation",
      "editedItem.user_id",
      "editedItem.value",
      // ...
    ]),
  },
  mixins: [debounce],
  mounted() {
    this.getAgents = this.debounce(this.getAgents, 500)
    this.getAttendants = this.debounce(this.getAttendants, 500); 
  },
  created() {
    this.setScreen({ screen: this.$props.screen })
    this.getInstances()
    this.getAudits({resetPage: false})
    this.$validator.extend("required", {
      getMessage: (field, [label]) => label + " deve ser informado",
      validate: (_, [, field]) => {
        if (this[field] || this[field] === 0) {
          return true;
        } else {
          return false;
        }
      },
    });
  },
  components: {
    "fail-audit-form": FailAuditForm,
  },
  methods: {
    ...mapActions("fail", [
      "showDeleteDialogAudit",
      "getAttendants",
      "getUsers",
      "getAgents",
      "getAudits",
      "closeDialog",
      "closeDialogAudits",
      "save",
    ]),
    ...mapActions("instances", ["getInstances"]),
    ...mapMutations("fail", ["setScreen", "addAudit"]),
    addDropFile(e) {
      if (this.mode == "ADD" || this.mode == "EDIT") {
        if (e.dataTransfer) {
          if (e.dataTransfer.files[0]) {
            this.receipt_file = e.dataTransfer.files[0];
          }
        }
      }
    },
    getTextAgent(item) {
      if(item) {
          if(item.id_pppoker) {
              return item.id_pppoker + " | " + item.nick + " | " + item.club_name
          } else {
              return item.nick
          }
      }
      return ''
    },
    getDate(date) {
      let datenova = date.split('-').reverse().join('/');
      return datenova
    },
    saveFail() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          var d = new Date(this.date_picker);
          this.date =
            d.toISOString().substr(0, 10) + " " + d.toLocaleTimeString();
          this.value = parseFloat(this.value);
          this.save();
        }
      });
    },
  },
  data: () => ({
    headers: [
      {
        text: "Data",
        align: "center",
        value: "date",
        sortable: false,
      },
      { text: "Quantidade de Auditorias",  value: "mount", sortable: false, align: "center" },
      { text: "Ações",  value: "actions", sortable: false, align: "center" }
    ],
  }),
  watch: {
    pagination: {
      handler () {
        this.getAudits({resetPage: false})
      }
    }
  }
};
</script>
