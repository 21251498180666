<template>
  <div>
    <v-card>
      <p class="text-center">
        Rake total: R${{ this.closure_total_rakeback }} ||
        Ganho total: R${{ this.closure_total_gains }}
      </p>
      <v-data-table
        :headers="headers"
        :loading="loading"
        :items="items"
        :options.sync="pagination"
        :server-items-length="totalItems"
        :item-class="getClassColor"
        no-data-text="Nenhuma registro encontrado"
        class="elevation-10 table-margin"
      >
        <template v-slot:[`item.total_gains`]="{ item }">
          <span>{{
            getTotalAgSubAg(item, "total_gains", "total_gains_sub_agents")
          }}</span>
        </template>
        <template v-slot:[`items.rakeback`]="{ items }">
          <span>{{ getRakeback(items, "rakeback") }}</span>
        </template>
        <template v-slot:[`item.rakeback_spin`]="{ item }">
          <span>{{ getTotalAgSubAg(item, "rakeback_spin") }}</span>
        </template>
        <template v-slot:[`item.value_to_pay`]="{ item }">
          <span>{{
            item.total_value_to_pay
              ? item.total_value_to_pay.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })
              : "R$ 0,00"
          }}</span>
        </template>
        <template v-slot:[`item.total_value_to_send`]="{ item }">
          <span>{{
            item.total_value_to_send
              ? item.total_value_to_send.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })
              : "R$ 0,00"
          }}</span>
        </template>
        <template v-slot:[`item.adjust_value`]="{ item }">
          <span>{{
            item.adjust_value
              ? item.adjust_value.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })
              : "R$ 0,00"
          }}</span>
        </template>
        <template v-slot:[`item.percentage_applied`]="{ item }">
          <span>{{ item.percentage_applied + "%" }}</span>
        </template>
        <template v-slot:[`item.type_deal`]="{ item }">
          <span>{{ getTypeDeal(item.type_deal) }}</span>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <span>{{ getStatus(item) }}</span>
        </template>
        <template v-slot:[`item.start_date`]="{ item }">
            <span>{{ formatDateWithoutTime(item.start_date) }}</span>
        </template>
        <template v-slot:[`item.finish_date`]="{ item }">
            <span>{{ formatDateWithoutTime(item.finish_date) }}</span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { formatDateWithoutTime } from '@util/format_util'
import { mapFields } from "vuex-map-fields";

export default {
  name: "PlayerClosure",

  methods: {
    ...mapActions("player_history", ["getItems"]),
    formatDateWithoutTime(date) {
        return formatDateWithoutTime(date)
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      }
      return false;
    },
    getClassColor(item) {
      if (item.status == 0 && item.type_deal != 4 && item.type_deal != 5) {
        return "row-red";
      } else if (item.status == 3) {
        return "row-teal";
      } else if (item.status == 4) {
        return "row-orange";
      } else if (item.status == 5) {
        return "row-purple";
      } else if (item.status == 6) {
        return "row-yellow-schedule";
      } else {
        return "row-green";
      }
    },
    getStatus(item) {
      if (item.status == 2 || item.type_deal == 4 || item.type_deal == 5) {
        return "Concluido";
      } else if (item.status == 3) {
        return "Aguardando Envio de Fichas";
      } else if (item.status == 4) {
        return "Enviar Fichas Manualmente";
      } else if (item.status == 5) {
        return "Erro nos Anotados";
      } else if (item.status == 6) {
        return "Resolver Diferença";
      } else {
        return "Pendente";
      }
    },
    getTypeDeal(type) {
      let types = [
        "Ganho/Perda",
        "Giro",
        "Normal",
        "Fichas Enviadas/Retiradas",
        "Sem Acerto",
        "Sem Acerto/GP",
      ];
      return types[type];
    },
    getTotalAgSubAg(item, field1, field2) {
      if (item[field1] || item[field2] === 0) {
        if (item[field2] || item[field2] === 0) {
          let total = item[field2] + item[field1];
          return total.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          });
        }
        return item[field1].toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });
      } else if (item[field2] || item[field2] === 0) {
        return item[field2].toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });
      }
      return "0".toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
    },
    getRakeback(item) {
      let rake = item["rakeback"];
      return rake.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
    },
  },
  computed: {
    ...mapState("player_history", [
      "filtered",
      "items",
      "loading",
      "totalItems",
      "closure_total_rakeback",
      "closure_total_gains",
    ]),
    ...mapFields("player_history", [
      "current_tab",
      "pagination",
      "filter.id_pppoker",
      "filter.value",
      "filter.status",
      "filter.start",
      "filter.end",
    ]),
  },
  data: () => ({
    tab: "",
    headers: [
      { text: "Período Data Inicial",   value: "start_date",     sortable: false },
      { text: "Período Data Final",     value: "finish_date",    sortable: false },
      // { text: "Período",        value: "period",         sortable: false },
      { text: "Tipo do Acerto", value: "type_deal",      sortable: false },
      { text: "Id Pppoker",     value: "id_pppoker",     sortable: false },
      { text: "Id Agente",      value: "id_agent",       sortable: false },
      { text: "Ativos",         value: "active_players", sortable: false },
      { text: "Clube",          value: "club_name",      sortable: false },
      { text: "Ganhos",         value: "total_gains",    sortable: false },
      {
        text: "Valor Total a Pagar",
        value: "total_value_to_pay",
        sortable: false,
      }, //soma total
      { text: "(%)",            value: "percentage_applied",  sortable: false },
      { text: "Rakeback",       value: "rakeback",            sortable: false },
      { text: "Rakeback Spin",  value: "rakeback_spin",       sortable: false },
      { text: "Ajustes",        value: "adjust_value",        sortable: false },
      { text: "Valor a Pagar",  value: "value_to_pay",        sortable: false },
      { text: "Valor a Enviar", value: "total_value_to_send", sortable: false },
      { text: "Status",         value: "status",              sortable: false },
      { text: "Criado em",      value: "date_created",        sortable: false },
    ],
  }),
  watch: {
      pagination: {
        handler() {
          if (this.filtered) {
            if(this.current_tab == 'player_history') {
              this.getItems({ 
                end_point: 'player_history',
                extend_url: '/closure',
                resetPage: false
              })
            }
          }
        },
      },
    },
  };
</script>
