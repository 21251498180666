import axios from "axios";
import { getField, updateField } from "vuex-map-fields";
import endPoints from "../../../config/endPoints";
import formatDate from '../../../util/formatDate.js'
import {
    defaultErrorNotification,
    defaultSuccessNotification,
} from "../../../constants";

const defaultFilter = {
    id_pppoker: "",
    value: "",
    start: "",
    end: "",
};

const defaultItem = {
    id_pppoker: "",
    value: "",
    start: "",
    end: "",
};

export default {
    namespaced: true,
    state: {
        items: [],
        selectedItem: {},
        current_tab: 'deposits',
        extend_url: '',
        totalItems: 0,
        closure_total_rakeback: 0,
        closure_total_gains: 0,
        rake_hands_played: 0,
        rake_player_tax: 0,
        rake_player_gains: 0,
        transactions_total_value: 0,
        deposits_total_value: 0,
        withdrawal_total_value: 0,
        total_value: null,
        tab: null,
        allow_pagination: false,
        loading: false,
        filtered: false,
        filterDialog: true,
        showDialog: false,
        filter: {
            id_pppoker: "",
            value: "",
            start: "",
            end: "",
        },
        notification: {
            show: false,
            message: "",
            type: "",
        },
        pagination: {
            page: 1,
            itemsPerPage: 10,
        },
    },
    mutations: {
        setDialog: (state, payload) => { state[payload.dialog] = payload.show },
        setFilter: (state, payload) => (state.filter = payload),
        setFiltered: (state, payload) => (state.filtered = payload),
        setCurrentTab: (state, payload) => (state.current_tab) = payload,
        setExtendUrl: (state, payload) => (state.extend_url) = payload,
        setLoading: (state, payload) => (state.loading = payload),
        setSelectedItem: (state, payload) => (state.selectedItem = payload),
        setDefaultItem: (state) => (state.selectedItem = Object.assign({}, defaultItem)),
        setItems: (state, payload) => (state.items = payload),
        setPage: (state, payload) => {
            state.pagination.page = payload.page
            state.pagination.itemsPerPage = payload.itemsPerPage
        },
        setTotalItems: (state, payload) => (state.totalItems = payload),
        setTotalClosureRakeback: (state, payload) => (state.closure_total_rakeback = payload),
        setTotalClosureGains: (state, payload) => (state.closure_total_gains = payload),
        setTotalRakeHandsPlayed: (state, payload) => (state.rake_hands_played = payload),
        setTotalRakePlayerTax: (state, payload) => (state.rake_player_tax = payload),
        setTotalRakePlayerGains: (state, payload) => (state.rake_player_gains = payload),
        setTotalTransactionsValue: (state, payload) => (state.transactions_total_value = payload),
        setTotalDepositsValue: (state, payload) => (state.deposits_total_value = payload),
        setTotalWithdrawalsValue: (state, payload) => (state.withdrawal_total_value = payload),
        showNotification: (state, payload) => (state.notification = payload),
        updateField,
    },
    actions: {
        clearFilter: ({ commit }) => {
            commit('setFilter', Object.assign({}, defaultFilter))
            commit('setDialog', ({ dialog: 'filterDialog', show: false }))
        },
        getItems: async ({ commit, state }, payload) => {

            let filter = state.filter
            let url = endPoints[payload.end_point]
            commit('setCurrentTab', payload.end_point)
            commit('setExtendUrl', payload.extend_url)

            if (payload.extend_url != '') {
                url += payload.extend_url
            }
            if (payload.resetPage === true) {
                url += '?page=' + 1 + '&' + 'itemsPerPage=' + 10
                commit('setPage', { page: 1, itemsPerPage: 10 })
            } else {
                url += "?page=" + state.pagination.page + "&itemsPerPage=" + state.pagination.itemsPerPage
            }

            commit('setFiltered', false)
            Object.keys(filter).forEach(function (field) {
                if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {

                    commit('setFiltered', true)
                    if (field == "end" || field == "start") {
                        var e = new Date(filter[field])
                        url += "&" + field + "=" + formatDate(e) + " " + e.toLocaleTimeString()
                    } else if (state.current_tab == 'periods_closure_transactions') {
                        if(field == 'id_pppoker') {
                            url += '&' + 'id_player_app' + '=' + filter[field]
                        }
                    } else {
                        url += "&" + field + "=" + filter[field]
                    }
                }
            });

            if (
                state.pagination &&
                state.pagination.sortBy &&
                state.pagination.sortBy.length > 0
            ) {
                url += "&sort_by=" + (state.pagination.sortBy[0] === "date" ? "id" : state.pagination.sortBy[0])
                url += "&sort_asc=" + state.pagination.sortAsc[0]
            }
            commit('setLoading', true)
            try {
                commit('setDefaultItem')
                let items = await axios.get(url)
                commit('setItems', items.data.data)
                commit('setTotalItems', items.data.total)

                commit('setTotalClosureRakeback', items.data.total_rakeback)
                commit('setTotalClosureGains', items.data.total_gains)
                commit('setTotalRakeHandsPlayed', items.data.hands_played)
                commit('setTotalRakePlayerTax', items.data.player_tax)
                commit('setTotalRakePlayerGains', items.data.player_gains)
                commit('setTotalTransactionsValue', items.data.total_value)
                commit('setTotalDepositsValue', items.data.total_value)
                commit('setTotalWithdrawalsValue', items.data.total_value)

                commit('setDialog', ({ dialog: 'filterDialog', show: false }))
                defaultSuccessNotification()
            } catch (error) {
                commit(
                    'showNotification',
                    defaultErrorNotification(error?.response?.data?.message)
                );
            }
            commit('setLoading', false);
        }
    },
    getters: {
        getField,
    }
};
