<template>
    <div>
        <v-card>
            <v-card-title class="text-center justify-center"
            >Relacionamento Pix - Fechamento Liga</v-card-title
            >
            <v-layout wrap>
            <v-flex xs12 sm12 md12 xl12>
                <template>
                    <v-data-table
                        :headers="headersPixRelated"
                        :items="[itemToRelatePix]"
                        :items-per-page="1"
                        hide-default-footer
                    >
                        <!-- <template v-slot:[`item.value`]="{ item }">
                            <span>{{ 
                              item.value.toLocaleString("pt-BR", {
                                  style: "currency",
                                  currency: "BRL",
                              })
                            }}</span>
                        </template> -->
                        <template v-slot:[`item.value`]="{ item }">
                          <span v-if = "item.value">{{
                            item.value ? item.value.toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            }) : "0".toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })
                          }}</span>
                        </template>
                        <template  v-slot:[`item.type`]="{ item }">
                          <span>
                            {{ getTypePaymentDescription(item.type) }}
                          </span>
                        </template>
                        <template v-if = "item.date" v-slot:[`item.date`]="{ item }">
                            <span>{{ getDate(item.date) }}</span>
                        </template>
                    </v-data-table>
                </template>
            </v-flex>
            <v-flex xs12 sm12 md4 xl4 class="mb-2">
                <div class="pixes-related">
                <v-card
                    class="mb-2 ml-2 mr-2 card-pix-related"
                    color="#385F73"
                    dark
                    v-for="pix in pixRelateds"
                    :key="pix.id"
                >
                    <v-card-title>
                    Cliente:
                    {{ pix.client_key ? pix.client_key : "Não identificado" }}
                    </v-card-title>
                    <v-card-subtitle>
                    <b>{{ pix.client_name }}</b>
                    <br />
                    <b>{{ pix.endToEndId }}</b>
                    <br />
                    {{
                        "Valor: " +
                        pix.value.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                        })
                    }}
                    <br />
                    {{ "Data: " + getDate(pix.date) }}
                    <br />
                    {{
                        "Utilizado: " +
                        (pix.deposit_id && pix.deposit_id != null
                        ? `Sim - ${pix.deposit_id}`
                        : "Não")
                    }}
                    </v-card-subtitle>
                    <v-btn
                    depressed
                    class="ml-2 mb-2"
                    @click="linkPix({ item: pix})"
                    >Vincular Pix</v-btn
                    >
                </v-card>
                </div>
            </v-flex> 
            <v-flex xs12 sm12 md8 xl8>
                <div v-if="receiptMimeType != 'pdf'" class="pixes-related-image">
                <v-img max-height="100%" :src="receipt_show" contain></v-img>
                </div>
                <div v-if="receiptMimeType == 'pdf'" class="pixes-related-frame">
                <iframe height="100%" width="100%" :src="receipt_show"></iframe>
                </div>
            </v-flex>
            </v-layout>
        </v-card>
    </div>
</template>


<script>
import { mapState, mapActions } from "vuex";
// import { mapFields } from "vuex-map-fields";

export default {
  name: "Deposits",
  computed: {
    ...mapState("league_closure_club", [
      "possible_payments",
      "itemToRelatePix",
      "pixRelateds",
      "receiptMimeType",
      "receipt_show"
    ]),
  },
  created() {
    this.getRelatedPixes()
  },
  methods: {
    // ...mapActions("bank", { getBanks: "getItems" }),
    ...mapActions("league_closure_club", [
      "getRelatedPixes",
      "linkPix",
    ]),

    getDate(date) {
      if (date) {
        var d = new Date(date);
        return d.toLocaleDateString()
      }
      return "";
    },
    getTypePaymentDescription(type){
      let p_payments = this.possible_payments
      for (const item in p_payments) {
        if (type == p_payments[item].id){
          return p_payments[item].description
        }
      }
      return type.description
    },

  },
  data: () => ({
    headersPixRelated: [
      { text: "ID", value: "id", sortable: false },
      { text: "Valor", value: "value", sortable: false },
      { text: "Data", value: "date", sortable: false },
      { text: "Tipo", value: "type", sortable: false },
      { text: "Responsável", value: "user_name", sortable: false },
    ],
    item: {
      value:0
    },
    
  }),
//   watch: {
//     pagination: {
//       handler() {
//         this.getItems({ resetPage: false });
//       },
//     },
//   },
};
</script>

<style lang="sass">
.dialog-pix
  margin: 5px
  overflow-y: hidden !important

.pixes-related
  height: 60vh
  overflow-y: scroll

.pixes-related-image
  max-height: 60vh
  overflow: scroll

.pixes-related-frame
  height: 100%

.pixes-related::-webkit-scrollbar
  width: 8px
  background-color: #e4dcd4

.pixes-related::-webkit-scrollbar-thumb
  border-radius: 6px
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0)
  background-color: #e4dcd4

.pixes-related-image::-webkit-scrollbar
  width: 8px
  background-color: #e4dcd4

.pixes-related-image::-webkit-scrollbar-thumb
  border-radius: 6px
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0)
  background-color: #e4dcd4

.card-pix-related
  text-color: white !important

iframe::-webkit-scrollbar
  width: 8px
  background-color: #e4dcd4

iframe::-webkit-scrollbar-thumb
  border-radius: 6px
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0)
  background-color: #e4dcd4
</style>