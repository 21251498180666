<template>
  <v-card>
    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2000"
      v-model="showNotification"
    >
        {{ notificationMessage }}
    </v-snackbar>
    <v-dialog :value="dialogChangePassword" width="600" persistent>
      <change-password></change-password>
    </v-dialog>
    <v-navigation-drawer app v-model="drawer" :mini-variant.sync="mini" permanent class="elevation-10">
      <v-card v-if="!mini" append-icon="mdi-menu">
        <v-card-title>
          {{ userData.name }}
          <v-spacer></v-spacer>
          <v-icon v-if="!mini" @click="mini = !mini">mdi-chevron-left</v-icon>

        </v-card-title>
        <v-card-subtitle>
          {{ userData.profile_access }}
          {{ userData.email }}
          <v-spacer></v-spacer>
        </v-card-subtitle>
        <v-expand-transition>
          <div v-show="show">
            <v-card-actions class="text-center">
              <v-btn @click="showDialogChangePassword({show: true})" outlined large>
                <v-icon>mdi-lock-open-outline</v-icon>
              </v-btn>
              <v-spacer> </v-spacer>
              <v-btn @click="logout()" outlined large>
                  <v-icon>mdi-exit-to-app</v-icon>
              </v-btn>
            </v-card-actions>
          </div>
        </v-expand-transition>
        
      </v-card>
      <v-card v-else>
        <v-card-title>
          <v-icon >mdi-menu</v-icon>
        </v-card-title>
        
      </v-card>
      <v-divider></v-divider>

      <v-toolbar
        dark
        color="blue darken-2"
      >
        <v-icon v-if="mini">mdi-magnify</v-icon>
        <v-text-field
          v-if="!mini"
          v-model="searchMenus"
          cache-items
          flat
          expand
          hide-no-data
          hide-details
          label="Buscar Menu"
          solo-inverted
        ></v-text-field>
      </v-toolbar>
      <v-divider></v-divider>

      <v-list dense>
        <div 
          v-for="menu in menus"
          :key="menu.description"
          @click="openMenu(menu); if(!menu.hasSubGroup) mini = true"
        >
          <menu-stars :menu="menu"></menu-stars>
        </div>
      </v-list>

      <template v-slot:append>
        <div v-if="!mini" class="text-center pa-2" style="display: flex;">
          <v-btn class="" @click="toggle_dark_mode()" outlined large>
            <v-icon>mdi-theme-light-dark</v-icon>
          </v-btn>
          <v-btn class="ml-1" @click="showDialogChangePassword({show: true})" outlined large>
            <v-icon>mdi-lock-open-outline</v-icon>
          </v-btn>
          <v-spacer> </v-spacer>
          <v-btn class="ml-1 mr-1" @click="logout()" outlined large>
                
              <v-icon>mdi-exit-to-app</v-icon>
          </v-btn>
        </div>

        <div v-if="mini" class="pa-2">
          <v-img height="40" width="40" src="../../assets/logo-sx-mini.png" class="pa-5"></v-img>
        </div>
      </template>

    </v-navigation-drawer>
  </v-card>
</template>

<script>

  import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
  import { mapFields } from 'vuex-map-fields'
  import ChangePassword from './ChangePassword'
  import Menu from './Menu'
  import debounce from '@util/debounce' 

  export default {
    name: "menustars",
    components: {
      "change-password": ChangePassword,
      "menu-stars": Menu
    },
    mixins: [debounce],
    mounted() {
      this.buildMenuSearch = this.debounce(this.buildMenuSearch, 500); 
    },
    computed: {
      ...mapState('menu', ['menus', 'minim', 'notificationType', 'notificationMessage']),
      ...mapState('login', ['userData', 'setStatus']),
      ...mapGetters('login', [
        'isLogged',
      ]),
      ...mapFields('menu', [
        'drawer',
        'dialogChangePassword',
        'showNotification'
      ])
    },
    data: () => ({
      mini: true,
      searchMenus: '',
      show: false
    }),
    methods: {   
      closeDrawer () {
        this.drawer = false
        this.mini = false
        console.log('to aqui', this.drawer)
      },
      toggle_dark_mode: function() {
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
        localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
      },
      ...mapMutations('menu', ['showDialogChangePassword']),
      ...mapActions('login', ['logout']),
      ...mapActions('menu', ['openMenu', 'buildMenuSearch', 'buildMenu']),
    },
    watch: {
      minim: function () {
        this.mini = this.minim
      },
      searchMenus (val) {
        var payload = {
          search: val,
        }
        if (payload.search.length > 2) {
          this.buildMenuSearch({ menu_name: payload.search })
        } else {
          this.buildMenu()
        }
      },
    }
  };
</script>

<style lang="sass">
  .away 
    margin-bottom: 10px

  .switch-center 
    display: flex
    justify-content: center

  .v-navigation-drawer__content::-webkit-scrollbar-thumb 
    border-radius: 6px
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3)
    background-color: #e4dcd4

  .v-navigation-drawer__content::-webkit-scrollbar 
    width: 8px
    background-color: #e4dcd4

  .v-navigation-drawer__content::-webkit-scrollbar-thumb 
    border-radius: 6px
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3)
    background-color: #e4dcd4

</style>