import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../../config/endPoints';

const defaultBonus = {
    id_app: '',
    contact_id: '',
    deposit_id: '',
    deposit_id_origin: '',
    hands_played: '',
    inscription: '',
    value: '',
    withdrawal_id: ''
};

export default {
    namespaced: true,
    state: {
        editedBonus: {},
        dialog: false,
        dialogPrint: false,
        dialogFilter: false,
        depositDialog: false,
        editedItem: {},
        filter: {
            id_app: '',
            contact_id: '',
            inscription: '',
            value: '',
            hands_played: '',
            deposit_id: '',
            deposit_id_origin: '',
            withdrawal_id: ''
        },
        bonus: [],
        loading: false,
        mode: '',
        overlay: false,
        page: 1,
        pagination: {
            page: 1,
            bonusPerPage: 10
        },
        pix: {
            client_key: ''
        },
        successNotification: false,
        notificationMessage: '',
        notificationType: "success",
        receipt: '',
        selectedContact: {},
        showNotification: false,
        totalBonus: 0,
        rowsPerPage: 10,
        menus: []
    },
    mutations: {
        closeDialog: (state) => {
            state.editedBonus = {}
            state.errors = [];
            state.dialog = false
            state.mode = "LIST"
        },
        loading: (state, payload) => state.loading = payload.show,
        setMode: (state, mode) => state.mode = mode,
        setBonus: (state, bonus) => state.bonus = bonus,
        setTotalBonus: (state, total) => state.totalBonus = total,
        setRowsPerPage: (state, rowsPerPage) => state.rowsPerPage = rowsPerPage,
        showDialog: (state, show) => state.dialog = show,
        setPage: (state, payload) => {
            state.pagination.page = payload.page;
            state.pagination.bonusPerPage = payload.bonusPerPage
        },
        setScreen: (state, payload) => state.screen = payload.screen,
        setUsersName: (payload) => {
            let users_name = []
            payload.users.forEach(user => {
                users_name[user.id] = user.name
            });
        },
        setDialogReceipt: (state, payload) => state.dialogReceipt = payload.show,
        showFilter: (state, payload) => state.dialogFilter = payload.show,
        showErrorNotification: (state, show) => state.errorNotification = show,
        showSuccessNotification: (state, show) => state.successNotification = show,
        showLoading: (state, show) => state.loading = show,
        showNotification: (state, payload) => {
            state.showNotification = payload.show,
                state.notificationMessage = payload.message,
                state.notificationType = payload.type
        },
        showOverlay: (state, payload) => {
            state.overlay = payload.show
        },
        showDepositDialog: (state, payload) => {
            state.depositDialog = payload.show
            if (payload.show == true) {
                state.editedItem.status = 1
                state.editedItem.value = 5
                state.editedItem.type = 5
                state.editedItem.payment_type = 4
            } else {
                state.editedItem = {}
            }
        },
        setPrint: (state, payload) => {
            state.receipt = payload.print
            state.dialogPrint = true
        },
        add(state) {
            state.editedBonus = Object.assign({}, defaultBonus);
            state.mode = "ADD"
            state.dialog = true
        },
        viewBonus(state, bonus) {
            state.editedBonus = Object.assign({}, bonus);
            state.mode = "VIEW"
            state.dialog = true
        },
        editedBonus(state, bonus) {
            state.editedBonus = Object.assign({}, bonus);
            state.mode = "EDIT"
            state.dialog = true
        },
        showDialogPrint(state, payload) {
            state.dialogPrint = payload.show
        },
        saveNext(state, payload) {
            console.log(payload.item)
            state.editedBonus.id_app = payload.item.id_pppoker
            state.editedBonus.inscription = payload.item.pix_identifier
            state.editedBonus.value = payload.item.value
            state.editedBonus.contact_id = payload.item.contact_id
            state.editedBonus.value = 8
            state.mode = "ADD"
        },
        updateField
    },
    getters: {
        getField,
    },
    actions: {
        closeDialog: (({ commit, state }) => {
            state.editedBonus = {}
            if (state.screen != "messages") {
                commit('message/setSelectedMessages', { messages: [] }, { root: true })
                commit('showDialog', false)
            } else {
                commit('message/showDialogFail', { show: false }, { root: true })
            }
            setTimeout(() => {
                commit('closeDialog')
                commit('showErrorNotification', false)
                commit('showSuccessNotification', false)
            }, 300);
        }),
        getBonus: ({ commit, state }, payload) => {
            commit('loading', true)
            let url = endPoints.bonus;
            let filter = state.filter;
            if (payload.resetPage === true) {
                url += '?page=' + 1 + '&' + 'bonusPerPage=' + 10;
                commit('setPage', { page: 1, bonusPerPage: 10 })
            } else {
                url += '?page=' + state.pagination.page + '&' + 'bonusPerPage=' + state.pagination.bonusPerPage;
            }


            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined) {
                    url += "&" + field + "=" + filter[field]
                }
            });

            axios.get(url).then(bonus => {
                commit('setTotalBonus', bonus.data.total)
                commit('loading', false)
                commit('setBonus', bonus.data.data)
            });
        },
        getBonusNoFilter: ({ commit, state }, payload) => {
            commit('loading', true)
            let url = endPoints.bonus;
            if (payload.resetPage === true) {
                url += '?page=' + 1 + '&' + 'bonusPerPage=' + 10;
                commit('setPage', { page: 1, bonusPerPage: 10 })
            } else {
                url += '?page=' + state.pagination.page + '&' + 'bonusPerPage=' + state.pagination.bonusPerPage;
            }

            axios.get(url).then(bonus => {
                commit('setTotalBonus', bonus.data.total)
                commit('loading', false)
                commit('setBonus', bonus.data.data)
            });
        },
        viewPrint: ({commit} , payload) => {
            let url = endPoints.bonus + '/id_app/' + payload.item.id_app;
            axios.get(url).then(print => {
                let teste = 'data:image/jpeg;base64,' + print.data.profile_print
                commit('setPrint', {print: teste})
            });
        },
        save: async({ dispatch, commit, state }) => {
            var url = endPoints.bonus;
            let func = '';
            let data = state.editedBonus
            state.editedBonus.hands_played = parseInt(state.editedBonus.hands_played)
            state.editedBonus.deposit_id = parseInt(state.editedBonus.deposit_id)
            state.editedBonus.deposit_id_origin = parseInt(state.editedBonus.deposit_id_origin)
            state.editedBonus.value = parseInt(state.editedBonus.value)
            state.editedBonus.withdrawal_id = parseInt(state.editedBonus.withdrawal_id)

            commit('showOverlay', { show: true })
            if (state.mode == 'ADD') {
                func = axios.post;
                url = endPoints.bonus;
            } else {
                func = axios.put;
                url += '/updateBonus/' + data.id;
            }

            func(url, data).then(
                () => {
                    dispatch('getBonus', { resetPage: true })
                    let notification = {
                        show: true,
                        message: "Bônus salvo com sucesso ",
                        type: "success"
                    }
                    commit('showOverlay', { show: false })
                    commit('showNotification', notification)
                    commit('closeDialog')
                },
                error => {
                    let notification = {
                        show: true,
                        message: error.response.data.message,
                        type: "error"
                    }
                    commit('showNotification', notification)
                    commit('showOverlay', { show: false })
                }
            );
        },
        saveDepositSt: async({ commit, dispatch, state }) => {
            var url = endPoints.deposits + "/deposit_bonus";
            let data = state.editedItem
            data.has_pix = "false"
            commit('showOverlay', { show: true })

            axios.post(url, data).then(
                () => {
                    let notification = {
                        show: true,
                        message: "Depósito salvo com sucesso.",
                        notificationTime: 3000,
                        type: "success"
                    }
                    commit('showOverlay', { show: false  })
                    commit('showNotification', notification)
                    commit('saveNext', { item: state.editedItem })
                    commit('showDepositDialog', {show: false})
                    dispatch('save')
                },
                error => {
                    let notification = {
                        show: true,
                        message: error.response.data.message,
                        notificationTime: 3000,
                        type: "error"
                    }
                    commit('showOverlay', { show: false  })
                    commit('showNotification', notification)
                    return
                }
            )
        },
    }
}