<template>
    <div>
        <v-overlay :value="loading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <v-snackbar
            :color="notification.type"
            right
            top
            :timeout="2000"
            v-model="notification.show"
        >
            {{ notification.message }}
        </v-snackbar>

        <v-toolbar flat class="elevation-5">
            <!-- <img src="https://img.icons8.com/ios/50/null/coin-in-hand.png"/> -->
            <v-toolbar-title><b>Anotados</b></v-toolbar-title>
            <v-spacer></v-spacer>
            <div class="button-toolbar">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="black"
                            dark
                            class="ma-2"
                            @click="setShowFilter(true)"
                            v-bind="attrs"
                            v-on="on"
                            small
                        >
                            <v-icon color="white">mdi-filter</v-icon>
                        </v-btn>
                    </template>
                    <span>Filtro</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="black"
                            dark
                            class="ma-2"
                            @click="getItems"
                            v-bind="attrs"
                            v-on="on"
                            small
                            ><v-icon>mdi-refresh</v-icon></v-btn
                        >
                    </template>
                    <span>Recarregar</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="black"
                            dark
                            class="ma-2"
                            v-if="profileAccess()"
                            v-bind="attrs"
                            v-on="on"
                            @click="add({screen: 'annotateds'})"
                            small
                            ><v-icon>mdi-plus</v-icon></v-btn
                        >
                    </template>
                    <span>Adicionar Anotado</span>
                </v-tooltip>
            </div>
        </v-toolbar>

        <v-data-table
            :headers="headers"
            :loading="loading"
            :items="items"
            :options.sync="pagination"
            :server-items-length="totalItems"
            class="elevation-10 table-margin"
            no-data-text="Nenhum anotado encontrado"
        >
            <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ attrs, on }">
                        <v-icon
                            small
                            color="black"
                            class="mr-2"
                            v-bind="attrs"
                            v-on="on"
                            @click="viewItem(item)"
                            >mdi-information</v-icon
                        >
                    </template>
                    <span>Informações</span>
                </v-tooltip>

                <v-tooltip bottom v-if="profileAccess()">
                    <template v-slot:activator="{ attrs, on }">
                        <v-icon
                            color="blue"
                            v-bind="attrs"
                            v-on="on"
                            small
                            class="mr-2"
                            @click="edit(item)"
                            >mdi-pencil</v-icon
                        >
                    </template>
                    <span>Editar</span>
                </v-tooltip>

                <v-tooltip bottom v-if="item.status == 2  && item.type == 0">
                    <template v-slot:activator="{ attrs, on }">
                        <v-icon
                            color="green"
                            v-bind="attrs"
                            v-on="on"
                            small
                            class="mr-2"
                            @click="createOrderAnnotated(item)"
                            >mdi-robot</v-icon
                        >
                    </template>
                    <span>Criar Ordem</span>
                </v-tooltip>
                <v-tooltip bottom v-if="item.status == 4 && item.type == 0">
                    <template v-slot:activator="{ attrs, on }">
                        <v-icon
                            color="green"
                            v-bind="attrs"
                            v-on="on"
                            small
                            class="mr-2"
                            @click="reorderAnnotated(item)"
                            >mdi-send</v-icon
                        >
                    </template>
                    <span>Reenviar Ordem</span>
                </v-tooltip>

                <v-tooltip bottom v-if="profileAccess()">
                    <template v-slot:activator="{ attrs, on }">
                        <v-icon
                            color="red"
                            v-bind="attrs"
                            v-on="on"
                            small
                            class="mr-2"
                            @click="deleteAnnotated(item)"
                            >mdi-delete</v-icon
                        >
                    </template>
                    <span>Excluir</span>
                </v-tooltip>
            </template>
            <template v-slot:[`item.value`]="{ item }">
                <span>{{
                    item.value.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                    })
                }}</span>
            </template>
            <template v-slot:[`item.date_created`]="{ item }">
                <span class="ma-2">{{ getDate(item.date_created) }}</span>
            </template>
            <template v-slot:[`item.id_client`]="{ item }">
                <span class="ma-2">{{ getNameClient(item.id_client) }}</span>
            </template>
            <template v-slot:[`item.type`]="{ item }">
                <span class="ma-2">{{ getType(item.type) }}</span>
            </template>
            <template v-slot:[`item.payment_type`]="{ item }">
                <span class="ma-2">{{
                    getPaymentType(item.payment_type)
                }}</span>
            </template>
            <template v-slot:[`item.id_slot`]="{ item }">
                <span class="ma-2">{{ getSlotName(item.id_slot) }}</span>
            </template>
            <template v-slot:[`item.status`]="{ item }">
                <v-chip
                    :color="getClassStatus(item.status)"
                    class="ma-2"
                    text-color="white"
                    label
                    >{{ getStatus(item.status) }}</v-chip
                >
            </template>
        </v-data-table>

        <v-dialog
            :value="showDialog"
            @click:outside="setShowDialog(false)"
            width="1000"
        >
            <annotateds-form></annotateds-form>
        </v-dialog>

        <v-dialog
            :value="filterDialog"
            @click:outside="setShowFilter(false)"
            width="600"
        >
            <annotateds-filter></annotateds-filter>
        </v-dialog>

        <v-dialog
            :value="deleteDialog"
            @click:outside="setDeleteDialog(false)"
            @keydown.enter="deleteItem"
            width="400"
        >
            <v-card>
                <v-card-title class="headline"
                    >Confirmar a exclusão?</v-card-title
                >

                <v-card-actions>
                    <v-col>
                        <v-btn text @click="setDeleteDialog(false)"
                            >Cancelar</v-btn
                        >
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text type="submit" right @click="deleteItem"
                            >Confirmar</v-btn
                        >
                    </v-col>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            :value="dialogOrder"
            @click:outside="setDialogOrder(false)"
            width="400"
        >
            <v-card>
                <v-card-title class="headline"
                    >Confirmar a criação da ordem?</v-card-title
                >

                <v-card-actions>
                    <v-col>
                        <v-btn text @click="setDialogOrder(false)"
                            >Cancelar</v-btn
                        >
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text type="submit" right @click="createOrder"
                            >Confirmar</v-btn
                        >
                    </v-col>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            :value="dialogReorder"
            @click:outside="setDialogReorder(false)"
            width="400"
        >
            <v-card>
                <v-card-title class="headline"
                    >Confirmar o reenvio da ordem?</v-card-title
                >

                <v-card-actions>
                    <v-col>
                        <v-btn text @click="setDialogReorder(false)"
                            >Cancelar</v-btn
                        >
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text type="submit" right @click="reorder"
                            >Confirmar</v-btn
                        >
                    </v-col>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import AnnotatedsForm from './AnnotatedsForm'
import AnnotatedsFilter from './AnnotatedsFilter'

export default {
    name: 'Annotateds',
    created() {
        this.getClients({ resetPage: false })
        this.getSlots()
        this.getItems()
    },

    components: {
        AnnotatedsForm,
        AnnotatedsFilter,
    },

    methods: {
        ...mapActions('clients', { getClients: 'getItems' }),
        ...mapActions('annotateds', [
            'getItems',
            'deleteItem',
            'getSlots',
            'createOrder',
            'viewItem',
            'reorder',
        ]),
        ...mapMutations('annotateds', [
            'setShowDialog',
            'add',
            'edit',
            'createOrderAnnotated',
            'reorderAnnotated',
            'setShowFilter',
            'setDeleteDialog',
            'setDialogOrder',
            'setDialogReorder',
            'deleteAnnotated',
        ]),
        profileAccess() {
            if (
                this.userData.role == 0 ||
                this.userData.role == 1 ||
                this.userData.role == 70
            ) {
                return false
            } else {
                return true
            }
        },
        getDate(date) {
            if (date) {
                var d = new Date(date)
                return d.toLocaleDateString() + ' ' + d.toLocaleTimeString()
            }
            return ''
        },
        getClassStatus(status) {
            if (status == 0) {
                return 'purple'
            } else if (status == 1) {
                return 'blue'
            } else if (status == 2) {
                return 'orange'
            } else if (status == 3) {
                return 'green'
            } else if (status == 4) {
                return 'red'
            }else if (status == 5) {
                return 'yellow'
            }
        },
        getType(type) {
            return this.possible_types[type].description
        },
        getStatus(status) {
            return this.possible_status[status].description
        },
        getPaymentType(payment_type) {
            if (payment_type != null) {
                return this.possible_payment_types[payment_type].description
            }
            return ''
        },
        getSlotName(id) {
            const slot = this.slots.find((element) => element.id == id)
            return slot.name
        },
        getNameClient(id) {
            const client = this.clients.find((element) => element.id == id)
            return client.name
        },
    },
    computed: {
        ...mapState('clients', { clients: 'items' }),
        ...mapState('login', ['userData']),
        ...mapState('annotateds', [
            'items',
            'notification',
            'mode',
            'loading',
            'totalItems',
            'showDialog',
            'filterDialog',
            'deleteDialog',
            'dialogOrder',
            'dialogReorder',
            'slots',
            'possible_types',
            'possible_payment_types',
            'possible_status',
        ]),
        ...mapFields('annotateds', ['pagination']),
    },
    data: () => ({
        headers: [
            { text: 'ID', value: 'id', sortable: true },
            {
                text: 'Cliente',
                value: 'id_client',
                sortable: true,
                align: 'center',
            },
            {
                text: 'Data de Criação',
                value: 'date_created',
                sortable: true,
                align: 'center',
            },
            { text: 'Valor', value: 'value', sortable: true, align: 'center' },
            {
                text: 'ID Clube',
                value: 'id_slot',
                sortable: true,
                align: 'center',
            },
            {
                text: 'ID App',
                value: 'id_app',
                sortable: true,
                align: 'center',
            },
            { text: 'Tipo', value: 'type', sortable: true, align: 'center' },
            {
                text: 'Status',
                value: 'status',
                sortable: true,
                align: 'center',
            },
            {
                text: 'Tipo de Pagamento',
                value: 'payment_type',
                sortable: true,
            },
            {
                text: 'Ações',
                value: 'actions',
                sortable: false,
                align: 'center',
                width: '10%',
            },
        ],
    }),
    watch: {
        pagination: {
            handler() {
                this.getItems({ resetPage: false })
            },
        },
    },
}
</script>
