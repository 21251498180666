<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline text-center justify-center">Filtro</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form">
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm6 md12>
                <v-select
                  append-icon="mdi-close"
                  @click:append="clearCompany()"
                  label="Empresa *"
                  :items="companies"
                  v-model="id_company"
                  item-text="name"
                  item-value="id"
                  outlined
                ></v-select>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-menu
                  v-model="showStart"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="start_formatted"
                      label="De"
                      outlined
                      readonly
                      append-icon="mdi-close"
                      @click:append="clearStart()"
                      persistent-hint
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="pt-br"
                    v-model="start"
                    no-title
                    @input="(date) => setStart(date)"
                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-menu
                  v-model="showEnd"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="end_formatted"
                      label="Até"
                      outlined
                      readonly
                      append-icon="mdi-close"
                      @click:append="clearEnd()"
                      persistent-hint
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="pt-br"
                    v-model="end"
                    no-title
                    @input="(e) => setEnd(e)"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field
                  required
                  label="CPF / CNPJ Destinatário"
                  v-model="inscription"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field
                  required
                  label="Nome Destinatário"
                  v-model="name_destiny"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field
                  required
                  label="Descrição"
                  v-model="description"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field
                  required
                  label="Observação"
                  v-model="observation"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field
                  required
                  label="Valor"
                  v-model="value"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-autocomplete
                  :clearable="true"
                  clear-icon="mdi-close"
                  label="Banco"
                  :items="banks"
                  v-model="bank"
                  :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                  :error-messages="errors.collect('bank')"
                  :item-text="getTextBank"
                  item-value="id"
                  data-vv-name="bank"
                  outlined
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-autocomplete
                  clearable
                  v-model="user_id"
                  :no-filter="true"
                  :items="users"
                  chips
                  small-chips
                  no-data-text="Nenhum usuario encontrado"
                  item-text="name"
                  item-value="id"
                  label="Usuario"
                  outlined
                  :search-input.sync="searchInput"
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-autocomplete
                  style="max-height: 200px"
                  :no-filter="true"
                  clearable
                  deletable-chips
                  chips
                  small-chips
                  label="Plano de Contas"
                  :items="options"
                  v-model="type"
                  :item-text="text"
                  item-value="id"
                  outlined
                  no-data-text="Nenhum plano de contas encontrado"
                  placeholder="Digite aqui o plano de contas"
                  :search-input.sync="searchExpenseTypes"
                  content-class="teste"
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-select
                  :clearable="true"
                  clear-icon="mdi-close"
                  label="Recorrência"
                  :items="recurrence_types"
                  v-model="recurrence"
                  item-text="description"
                  item-value="id"
                  outlined
                ></v-select>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-select
                  :clearable="true"
                  clear-icon="mdi-close"
                  label="Tipo"
                  :items="variables"
                  v-model="variable"
                  item-text="description"
                  item-value="id"
                  outlined
                ></v-select>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-select
                  :clearable="true"
                  clear-icon="mdi-close"
                  label="Centro de Custos"
                  :items="costCenters"
                  v-model="id_cost_center"
                  :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                  :error-messages="errors.collect('cost_center')"
                  :item-text="getTextCostCenter"
                  item-value="id"
                  data-vv-name="id_cost_center"
                  outlined
                ></v-select>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn text @click="showFilter({ show: false })">Cancelar</v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn text type="submit" right @click="filter">Filtrar</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import debounce from "../../../util/debounce.js";

export default {
  name: "ExpensesFilter",
  computed: {
    ...mapState("expense", ["banks", "expense_types", "users", "companies", "options", "mode", "filterTypes","costCenters"]),
    ...mapFields("expense", [
      "filter.start",
      "filter.end",
      "filter.description",
      "filter.observation",
      "filter.inscription",
      "filter.name_destiny",
      "filter.value",
      "filter.bank",
      "filter.id_company",
      "filter.user_id",
      "filter.recurrence",
      "filter.variable",
      "filter.type",
      "filter.id_cost_center"
      // ...
    ]),
  },
  created() {
    this.filterTypes.has_child = "0"
    this.getExpenseTypes();
    this.getBanks();
    this.getCostCenters();
  },
  data: () => ({
    showStart: false,
    showEnd: false,
    start_formatted: "",
    end_formatted: "",
    searchExpenseTypes: '',
    recurrence_types: [
      { id: 0, description: "Mensal" },
      { id: 1, description: "Trimestral" },
      { id: 2, description: "Semestral" },
      { id: 3, description: "Anual" },
    ],
    variables: [
      { id: 0, description: "Custo Fixo" },
      { id: 1, description: "Custo Variavel" },
    ],
    searchInput: "",
  }),
  mixins: [debounce],
  mounted() {
    this.getUsers = this.debounce(this.getUsers, 500);
  },
  methods: {
    text: item => item.label + " - " + item.id_dre,
    ...mapMutations("expense", ["showFilter"]),
    ...mapActions("expense", [
      "getItems",
      "getUsers",
      "getBanks",
      "getCostCenters",
      "clearFilter",
      "getExpenseTypes",
      "getSearchExpenseTypes"
    ]),
    clearCompany() {
      this.id_company = "";
    },
    clearStart() {
      this.start = "";
      this.start_formatted = "";
    },
    clearEnd() {
      this.end = "";
      this.end_formatted = "";
    },
    filter() {
      this.getItems({ resetPage: true });
      this.showFilter({ show: false });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    },
    getTextBank(item) {
      if (item.account) {
        return item.name + " | " + item.account;
      } else {
        return item.name;
      }
    },
    getTextCostCenter(item) {
        return item.name;
    },
    setStart(date) {
      this.start = date;
      this.showStart = false;
      this.start_formatted = this.formatDate(date);
    },
    setEnd(date) {
      this.end = date;
      this.showEnd = false;
      this.end_formatted = this.formatDate(date);
    },
  },
  watch: {
    searchInput: {
      handler() {
        if (this.searchInput) {
          var payload = {
            search: this.searchInput,
          };
          this.getUsers(payload);
        }
      },
    },
    searchExpenseTypes (val) {
      var payload = {
        search: val
      }
      this.getSearchExpenseTypes(payload)
    }
  }
};
</script>

<style></style>
