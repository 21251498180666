<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-snackbar
      :color="notification.type"
      top
      :timeout="2000"
      v-model="notification.show"
      >{{ notification.message }}</v-snackbar
    >
    <v-toolbar flat class="elevation-5">
         <v-icon color="black" style="padding-right:6px">mdi-calendar-blank-multiple </v-icon>
        <v-toolbar-title> <strong> Períodos de Saldo de Jogadores</strong></v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="button-toolbar">

       
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="black"
              dark
              class="ma-2"
              @click="showFilter({ show: true })"
              small
            >
              <v-icon>mdi-filter</v-icon>
            </v-btn>
          </template>
          <span>Filtro</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="black"
              dark
              class="ma-2"
              @click="getItems"
              small
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Recarregar</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="black"
              dark
              class="ma-2"
              @click="add"
              small
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Novo Período</span>
        </v-tooltip>
      </div>
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :loading="loading"
      :items="items"
      :options.sync="pagination"
      :server-items-length="totalItems"
      :item-class="getLineColor"
      no-data-text="Nenhum registro encontrado"
      class="elevation-10 table-margin"
    >
      <template v-slot:[`item.date_start`]="{ item }">
        <span>{{ getDate(item.date_start) }}</span>
      </template>
      <template v-slot:[`item.date_end`]="{ item }">
        <span>{{ getDate(item.date_end) }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
       
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-on="on"
              v-bind="attrs"
              small
              color="black"
              class="mr-2"
              @click="viewItem(item)"
              >mdi-arrow-expand</v-icon
            >
          </template>
          <span>Visualizar Período</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-on="on"
              v-bind="attrs"
              small
              color="green"
              class="mr-2"
              @click="refreshAgents(item)"
              >mdi-refresh-auto</v-icon
            >
          </template>
          <span>Atualizar Agentes</span>
        </v-tooltip>
        
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-on="on"
              v-bind="attrs"
              small
              color="blue"
              class="mr-2"
              @click="editPeriod({ item: item })"
              >mdi-pencil</v-icon
            >
          </template>
          <span>Editar</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-on="on"
              v-bind="attrs"
              small
              color="red"
              class="mr-2"
              @click="showDeleteDialog(item)"
              >mdi-delete</v-icon
            >
          </template>
          <span>Excluir</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog :value="dialog" @click:outside="closeDialog" width="800">
      <periods-players-balance-form></periods-players-balance-form>
    </v-dialog>

    <v-dialog
      :value="dialogFilter"
      width="600"
      @click:outside="showFilter({ show: false })"
    >
      <periods-players-balance-filter></periods-players-balance-filter>
    </v-dialog>

    <v-dialog
      :value="dialogDelete"
      @click:outside="closeDeleteDialog"
      @keydown.enter="deleteItem"
      width="300"
    >
      <v-card>
        <v-card-title class="headline">Confirmar a exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn text @click="closeDeleteDialog()">Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn text type="submit" right @click="deleteItem"
              >Confirmar</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import PeriodsPlayersBalanceForm from "./PeriodsPlayersBalanceForm.vue";
import PeriodsPlayersBalanceFilter from "./PeriodsPlayersBalanceFilter.vue";

export default {
  name: "PeriodsPlayersBalance",
  computed: {
    ...mapState("login", ["userData"]),
    ...mapState("periods_players_balance", [
      "dialogDelete",
      "dialogFilter",
      "loading",
      "notification",
      "totalItems",
      "dialog",
      "items",
    ]),
    ...mapFields("periods_players_balance", [
      "pagination",
      "overlay",
      // ...
    ]),
    nameSpreadsheet() {
      return this.selected_spreadsheet.name;
    },
  },
  created() {
    // this.getInstances()
    this.getItems();
  },
  components: {
    PeriodsPlayersBalanceForm,
    PeriodsPlayersBalanceFilter,
  },

  methods: {
    ...mapMutations("periods_players_balance", ["editPeriod", "add", "closeDialog", "showFilter"]),
    ...mapActions("periods_players_balance", ["getItems", "viewItem", "refreshAgents", "edit", "deleteItem", "showDeleteDialog"]),
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      }
      return false;
    },
    getLineColor(item) {
      if (this.items.indexOf(item) % 2 == 0) {
        return "background-white";
      } else {
        return "background-grey";
      }
    },

    getDate(date) {
      if (date) {
        var d = new Date(date + " 12:00:00");
        return d.toLocaleDateString();
      }
      return "";
    },
    getClassColor(item) {
      if (item.processed == 0) {
        return "row-red";
      } else {
        return "row-green";
      }
    },
  },
  data: () => ({
    build_fields: {},
    expanded: [],
    headers: [
      { text: "Id", value: "id", sortable: false },
      { text: "Descrição", value: "description", sortable: false },
      { text: "Inicio", value: "date_start", sortable: false },
      { text: "Fim", value: "date_end", sortable: false },
      { text: "Ações", value: "actions", sortable: false },
    ],
    valid: false,
  }),
  watch: {
    pagination: {
      handler() {
        this.getItems({ resetPage: false });
      },
    },
  },
};
</script>

<style>
.background-grey {
  background-color: rgba(225, 225, 225);
}

.background-white {
  background-color: rgba(255, 255, 255);
}
</style>