<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2000"
      v-model="showNotification"
    >
      {{ notificationMessage }}
    </v-snackbar>
    
    <v-toolbar class="mt-2 mb-5 elevation-0 px-3git">
      <v-toolbar-title class="title">Cadastros do Cliente</v-toolbar-title>
      <v-spacer></v-spacer>
       
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
          v-bind="attrs"
          v-on="on"
          color="#373B40"
          dark
          @click="setFormDialog({ item: {}, show: true, mode: 'ADD' })"
          small
          >Registrar novo cadastro</v-btn>
        </template>
        <span>Novo cadastro de acesso aos aplicativos</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            color="black"
            dark
            @click="getItems()"
            class="ma-2"
            small
            ><v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>
        <span>Recarregar</span>
      </v-tooltip>
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :loading="loading"
      :items="itemsClientsRegistrations"
      :options.sync="pagination"
      :server-items-length="totalItems"
      :item-class="getClassColor"
      no-data-text="Nenhum cadastro criado para esse cliente"
      class="elevation-10 table-margin pt-6"
    >

      <!-- <template v-slot:[`item.client_id`]="{ item }">
        <span>{{ ( item.client_id ) }}</span>
      </template> -->

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ attrs, on }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              small
              class="mr-2"
              @click="setFormDialog({ item: item, show: true, mode: 'EDIT' })"
              >mdi-pencil</v-icon
            >
          </template>
          <span>Editar</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ attrs, on }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              small
              class="mr-2"
              @click="setDeleteDialog({ item: item, show: true, mode: 'DELETE' })"
              >mdi-delete</v-icon
            >
          </template>
          <span>Excluir Cadastro</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ attrs, on }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              small
              class="mr-2"
              @click="setMailDialog({ item: item, show: true, mode: 'MAIL' })"
              >mdi-email</v-icon
            >
          </template>
          <span>Envio de senha por e-mail</span>
        </v-tooltip>

      </template>
    </v-data-table>

    <v-dialog
      :value="dialogForm"
      @keydown.esc="setFormDialog({ item: {}, show: false, mode: '' })"
      @click:outside="setFormDialog({ item: {}, show: false, mode: '' })"
      width="800"
    >
      <clients-registrations-form></clients-registrations-form>
    </v-dialog>

    <v-dialog
      :value="dialogDelete"
      @click:outside="setDeleteDialog({ item: {}, show: false, mode: '' })"
      width="350"
    >
      <v-card>
        <v-card-title class="headline">Deseja deletar o cadastro?</v-card-title>
        <v-card-actions>
          <v-col>
            <v-btn text @click="setDeleteDialog({ item: {}, show: false, mode: '' })">Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn text type="submit" right @click="deleteRegistration()">Confirmar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      :value="dialogMail"
      @click:outside="setMailDialog({ item: {}, show: false, mode: '' })"
      width="500"
    >
      <v-card :loading="loading">
        <v-card-title class="headline">Deseja enviar a senha por e-mail?</v-card-title>
        <v-card-actions>
          <v-col class="text-center">
            <v-btn raised color="error" @click="setMailDialog({ item: {}, show: false, mode: '' })">Não</v-btn>
          </v-col>
          <v-col class="text-center">
            <v-btn raised color="success" type="submit" right @click="mailFA()">Sim</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapActions, mapMutations, mapState } from 'vuex'
  import { mapFields } from 'vuex-map-fields'
  import ClientsRegistrationsForm from "./ClientsRegistrationsForm.vue"

  export default {
    name: 'ClientsRegistrations',
    components: {
      "clients-registrations-form": ClientsRegistrationsForm,
    },
    created() {
      let client_id = this.$route.params.client_id
      this.manageClientId({ client_id: client_id })
    },
    computed: {
      ...mapState('clients_registrations', [
        'dialogDelete',
        'dialogForm',
        'dialogMail',
        'itemsClientsRegistrations',
        'loading', 
        'mode', 
        'notificationType', 
        'notificationMessage', 
        'rowsPerPage',
        'showDialog',
        'totalItems', 
      ]),
      ...mapFields('clients_registrations', [
        'pagination',
        "filter.created_at",
        "filter.client_id",
        "filter.email",
      ]),
      ...mapFields("client", [
        "showNotification",
      ]),
    },
    methods: {
      ...mapActions('clients_registrations', [
        'deleteItem',
        'getItems',
        'mailFirstAccess',
        'manageClientId',
        'viewItem',
      ]),
      ...mapMutations("clients_registrations", [
        "setDeleteDialog",
        "setFormDialog",
        "setMailDialog",
      ]),
      getClassColor(item){
        return item.id == 1 ? "row-yellow-schedule" : ""
      },
      deleteRegistration(){
        this.deleteItem()
      },
      mailFA(){
        this.mailFirstAccess()
      },
    },
    data: () => ({
      headers: [
        { text: "Criado Em", value: "created_at", align: "center", sortable: false },
        { text: "E-mail", value: "email", align: "center", sortable: false },
        { text: "Telefone", value: "phone_number", align: "center", sortable: false },
        { text: "",  value: "actions", sortable: false, align: "center" },
      ],
      overlay: false,
    }),
    // watch: {
    //   pagination: {
    //     handler () {
    //       this.getItems()
    //     },
    //   },
    // }
  }
</script>

