<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline text-center justify-center">Filtro</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm2 md2>
                <v-text-field
                  dense
                  label="ID"
                  v-model="id"
                  :clearable="true"
                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm4 md4>
                <v-text-field
                  dense
                  label="ISPB"
                  v-model="ispb"
                  :clearable="true"
                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-text-field
                  dense
                  label="Nome"
                  v-model="name"
                  :clearable="true"
                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm2 md2>
                <v-text-field
                  dense
                  label="Código"
                  v-model="code"
                  :clearable="true"
                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-text-field
                  dense
                  label="Participante da COMPE"
                  v-model="compe"
                  :clearable="true"
                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm4 md4>
                <v-text-field
                  dense
                  label="Acesso Principal"
                  v-model="model"
                  :clearable="true"
                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm12 md12>
                <v-text-field
                  dense
                  label="Nome Extenso"
                  v-model="full_name"
                  :clearable="true"
                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 md12>
                <v-text-field
                  dense
                  label="Início da Operação"
                  v-model="date_op"
                  :clearable="true"
                  outlined
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn
            text
            @click="
              setShowFilter(false);
              clearFilter();
            "
            >Cancelar</v-btn
          >
        </v-col>
        <v-col class="text-right">
          <v-btn text type="submit" right @click="filter">Filtrar</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "BanksBacenFilter",
  computed: {
    ...mapState("banks_bacen", ["mode", "selectedItem", "showDialog"]),
    ...mapFields("banks_bacen", [
      "filter.id",
      "filter.ispb",
      "filter.name",
      "filter.code",
      "filter.compe",
      "filter.access",
      "filter.full_name",
      "filter.date_op",
    ]),
  },
  methods: {
    ...mapMutations("banks_bacen", ["setShowFilter", "clearFilter"]),
    ...mapActions("banks_bacen", ["getItems"]),
    filter() {
      this.getItems();
      this.setShowFilter(false);
    },
  },
  data: () => ({}),
};
</script>
