const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});


export async function dataUrlToFile(dataUrl, fileName) {
    let mimetype = dataUrl.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
    const res = await fetch(dataUrl);
    const blob = await res.blob();
    let format
    let mimeArray = mimetype.split("/")
    if (mimeArray.length > 1) {
        format = mimeArray[1]
        return new File([blob], fileName + '.' + format, { type: mimetype });
    }

    return new File([blob], fileName, { type: mimetype });
}

export default toBase64