import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../../config/endPoints';

export default {
    namespaced: true,
    state: {
        dialog: false,
        contacts: [],
        documents: [],
        editedItem: {},
        itemToDelete: {},
        loading_manager: false,
        loading_documents: false,
        loading_subagent: false,
        manager: {},
        mode: 'LIST',
        page: 1,
        subAgents: [],
        notificationMessage: '',
        notificationType: "success",
        showNotification: false,
        totalSubAgents: 0,
        totalRakeDeals: 0,
    },
    mutations: {
        loading_manager: (state, payload) => state.loading_manager = payload.loading,
        loading_rake_deals: (state, payload) => {
            state.loading_rake_deals = payload.loading
        },
        showNotification: (state, payload) => {
            state.showNotification = payload.show,
                state.notificationMessage = payload.message,
                state.notificationType = payload.type
        },
        setManager: (state, payload) => state.manager = payload.manager,
        setSubAgents: (state, payload) => state.subAgents = payload.subAgents,
        setTotalSubAgents: (state, payload) => state.totalSubAgents = payload.total,
        setTotalRakeDeals: (state, payload) => state.totalRakeDeals = payload.total,
        updateField
    },
    getters: {
        getIdManager: state => {
            return state.manager.id
        },
        getField,
    },
    actions: {
        getManager: ({ commit }, payload) => {
            commit('loading_manager', { loading: true })
            let url = endPoints.managers + "/manager/" + payload.manager_id;
            axios.get(url).then(response => {
                commit('setManager', { manager: response.data })
                commit('loading_manager', { loading: false })
            });
        },
        goBack: ({ dispatch }) => {
            dispatch('menu/openLink', '/managers', { root: true })
        }
    }
}