<template>
  <div @drop.prevent="addDropFile" @dragover.prevent>
    <v-dialog :value="showDialog" persistent width="780" >
      <v-card  class="px-4 ">

        <v-btn absolute fab right icon @click="setShowDialog(false)" >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-card class="d-flex flex-row align-end px-7 pb-2 elevation-0" tile>

          <div>
            <v-card-title class="pt-8 pl-0">
              <span class="headline font-weight-medium">Vincular Grupo</span>
            </v-card-title>
            <v-card-subtitle class="pt-2 pl-0 pr-10 pb-0">
              <span>Vincular grupo a esse cliente</span>
            </v-card-subtitle>
          </div>
          <v-flex xs12 sm8 md8 class="pl-16" >
            <v-text-field
              class="mt-0 pb-0 mb-0 pr-0"
              label="Buscar grupo"
              v-model="search"
              @input="searchGroup"
              append-icon="mdi-magnify"
            ></v-text-field>
          </v-flex>
        </v-card>

        <v-card-text class="ml-2 mt-0">
          <v-form ref="form" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap class="">

                <v-data-table
                  :headers="headers"
                  :loading="loading"
                  :items="contacts"
                  :options.sync="pagination"
                  :server-items-length="totalContacts"
                  class="elevation-1 py-2 mb-6"
                  no-data-text="Nenhum cliente encontrado"
                  :footer-props="{
                    'items-per-page-options': [10, 20, 50, 100],
                    'items-per-page-text': 'Itens por página',
                    'show-first-last-page': true,
                    'page-text': `Página ${pagination.page} de ${parseInt(totalContacts/pagination.contactsPerPage) + 1}`
                  }"
                >

                <template v-slot:[`item.actions`]="{ item }" v-align="right">
                  <v-tooltip v-if="item.client_id" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        dark
                        color="#c3c3c3"
                        class="ma-2 elevation-0"
                        v-bind="attrs"
                        v-on="on"
                        small
                      >
                        <span color="white">Já vinculado</span>
                      </v-btn>
                    </template>
                    <span>Não é possivel vincular grupo a mais de um de cliente</span>
                  </v-tooltip>
                  <v-tooltip v-else>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="blue"
                        dark
                        class="ma-2 elevation-0"
                        @click="link({item})"
                        v-bind="attrs"
                        v-on="on"
                        small
                      >
                        <span color="white">Vincular</span>
                      </v-btn>
                    </template>
                  </v-tooltip>

                </template>
              </v-data-table>

              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";


export default {
  name: 'LinkGroupForm',


  created() {
    this.getContacts({resetPage: false});
  },

  computed: {
    ...mapState("contacts", [
      "showDialog",
      'contacts', 
      'loading',
      'totalContacts',
      'filter.search',
      'pagination',
      'selectedContact',
    ]),

    ...mapState("clients", [
      "client",
    ]),
    ...mapFields("contacts", [
      'pagination',
      'filter.search',
    ]),
  },



  methods: {

    ...mapActions("contacts", [
      'getContacts',
      'addClientLink',
    ]),
    ...mapMutations("contacts", [
      "setShowDialog",
      'setSelectedContact',
    ]),

    searchGroup()  {
      this.getContacts({resetPage: false})
    },

    link(item)  {
      let contact = (item.item)
      contact.client_id = this.client.id

      this.setSelectedContact(contact)
      this.addClientLink()
    },
  },

  data: () => ({

    headers: [
      { text: "Nome do Grupo", value: "name", sortable: false },
      { text: "Telefone", value: "phone", sortable: false },
      { text: "Clube", value: "instance_name", sortable: false },
      { text: "Ações", value: "actions", sortable: false, align: "right" },
    ],

    timeProps: {
      format: "24hr",
    },
  }),
  watch: {
    pagination: {
      handler () {
        this.getContacts({resetPage: false})
      },
    },
  }

}
</script>
