import axios from "axios";
import { getField, updateField } from "vuex-map-fields";
import endPoints from "../../../config/endPoints";
import { defaultErrorNotification } from "../../../constants";

const defaultFilter = {
  name: "",
  start_date: "",
  finish_date: "",
  nominated_candidate: "",
  final_result: "",
  called_again: "",
  email: "",
  failed_in: "",
  next_step: "",

}

const defaultCandidateItem = {
  id: null,
  behavioral_interview: null,
  id_job: null,
  called_again: null,
  department: null,
  final_result: 0,
  result_test: null,
  next_step: null,
  role: null,
  date: "",
  email: "",
  interview_format: 0,
  name: "",
  nominated_candidate: 1,
  practice_test: "",
  psychological_assessment: null,
  requester: null,
  requester_name: "",
  responsible_practice_test: null,
  responsible_test: null,
  test: "",
  who_nominated: null,
  return_process: null,
  phone: "",
  interview_result: null,
  date_interview: "",
  failed_in: null,
};

export default {
  namespaced: true,
  state: {
    job: {},
    departments: [],
    candidateToDelete: {},
    collaborators: [],
    collaboratorsNominated: [],
    collaboratorsResponsibles: [],
    roles: [],
    items: [],
    selectedItem: {},
    dialogDelete: false,
    mode: "",
    loading: false,
    errors: [],
    totalItems: 10,
    dialog: false,
    deleteDialog: false,
    filterDialog: false,
    overlay: false,
    filter: {
      name: "",
      start_date: "",
      finish_date: "",
      email: "",
      called_again: "",
      next_step: "",
      failed_in: "",
      nominated_candidate: "",
      final_result: ""
    },
    notification: {
      show: false,
      message: "",
      type: "",
    },
    pagination: {
      page: 1,
      itemsPerPage: 10,
    },
    steps: [
      { id: 0, description: "Entrevista Comportamental" },
      { id: 1, description: "Prova" },
      { id: 2, description: "Entrevista Técnica" },
      { id: 3, description: "Avaliação Psicológica" },
      { id: 4, description: "Finalizado" }
    ],
    possible_interview: [
      { id: 0, description: "Presencial" },
      { id: 1, description: "Online" },
    ],
    possible_results: [
      { id: 0, description: "Aprovado(a)" },
      { id: 1, description: "Reprovado(a)" },
    ],
    possible_final_result: [
      { id: 0, description: "Em aberto" },
      { id: 1, description: "Aprovado(a)" },
      { id: 2, description: "Reprovado(a)" },
    ],
    possible_psychological_assessment: [
      { id: 0, description: "Apto(a)" },
      { id: 1, description: "Inapto(a)" },
    ],
    possibles: [
      { id: 0, description: "Sim" },
      { id: 1, description: "Não" },
    ]
  },
  mutations: {
    addCandidate: (state) => {
      state.selectedItem.return_process = null
      state.dialog = true;
      state.mode = "ADD";
      state.selectedItem = Object.assign({}, defaultCandidateItem);
      state.selectedItem.return_process = null
      state.selectedItem.return_process = "";
      state.collaborators.find(collaborator => {
        if (collaborator.id == state.job.requester) {
          state.selectedItem.requester_name = collaborator.name
        }
      })
      state.selectedItem.date = new Date();
      state.selectedItem.department = state.job.department
      state.selectedItem.role = state.job.role
      state.selectedItem.requester = state.job.requester
    },
    setJob: (state, payload) => {
      state.job = payload
    },
    closeDialog: (state) => {
      state.selectedItem = {};
      state.editedIndex = -1;
      state.errors = [];
      state.dialog = false;
      state.mode = "LIST";
    },
    editCandidate: (state, payload) => {
      state.collaboratorsNominated = state.collaborators
      payload.item.date = new Date(payload.item.date);
      state.selectedItem = Object.assign({}, payload.item);
      if (state.selectedItem.requester != null) {
        state.selectedItem.requester_name = state.collaborators.find(collaborator => collaborator.id == state.selectedItem.requester).name
      }
      state.selectedItem.date = new Date(state.selectedItem.date);
      state.selectedItem.test = new Date(state.selectedItem.test);
      state.selectedItem.date_interview = new Date(state.selectedItem.date_interview);
      state.selectedItem.practice_test = new Date(state.selectedItem.practice_test);
      state.selectedItem.return_process = new Date(state.selectedItem.return_process);
      state.dialog = true;
      state.mode = "EDIT";
    },
    view: (state, payload) => {
      state.collaboratorsNominated = state.collaborators
      state.selectedItem = payload;
      if (state.selectedItem.requester != null) {
        state.selectedItem.requester_name = state.collaborators.find(collaborator => collaborator.id == state.selectedItem.requester).name
      }
      state.selectedItem.date = new Date(state.selectedItem.date);
      state.selectedItem.test = new Date(state.selectedItem.test);
      state.selectedItem.date_interview = new Date(state.selectedItem.date_interview);
      state.selectedItem.practice_test = new Date(state.selectedItem.practice_test);
      state.selectedItem.return_process = new Date(state.selectedItem.return_process);
      state.dialog = true;
      state.mode = "VIEW";
    },
    clearFilter: (state) => {
      state.filter = Object.assign({}, defaultFilter)
    },
    setCollaboratorsNominated: (state, payload) => {
      state.collaboratorsNominated = payload.collaborators;
    },
    setCollaborators: (state, payload) => {
      state.collaborators = payload.collaborators;
    },
    setCollaboratorsResponsibles: (state, payload) => {
      state.collaboratorsResponsibles = payload.collaborators;
    },
    setRoles: (state, payload) => {
      state.roles = payload.roles;
    },
    setDepartments: (state, payload) => {
      state.departments = payload.departments;
    },
    setItems: (state, payload) => {
      state.items = payload
    },
    setCandidateToDelete: (state, candidate) => state.candidateToDelete = candidate,
    setTotalItems: (state, payload) => (state.totalItems = payload),
    setLoading: (state, payload) => (state.loading = payload),
    showNotification: (state, payload) => (state.notification = payload),
    setDialogDelete: (state, show) => state.dialogDelete = show,
    setShowDialog: (state, payload) => {
      state.dialog = payload;
      state.selectedItem = Object.assign({}, defaultCandidateItem);
    },
    setPage: (state, payload) => {
      state.pagination.page = payload.page;
      state.pagination.itemsPerPage = payload.itemsPerPage;
    },
    showOverlay: (state, payload) => {
      state.overlay = payload.show;
    },
    setShowFilter: (state, payload) => (state.filterDialog = payload),
    updateField,
  },
  actions: {
    closeDialog: ({ commit }) => {
      commit("closeDialog", false);
    },
    closeDeleteDialog: ({ commit }) => {
      commit('setDialogDelete', false)
      commit('setCandidateToDelete', {})
    },
    showDeleteDialog: ({ commit }, item) => {
      commit('setDialogDelete', true)
      commit('setCandidateToDelete', item)
    },
    deleteCandidate: ({ dispatch, commit, state }) => {
      commit('setLoading', true)

      axios.delete(endPoints.candidates + "/" + state.candidateToDelete.id, state.candidateToDelete).then(() => {
        dispatch('closeDeleteDialog')
        let notification = {
          show: true,
          message: "Candidato excluído com sucesso.",
          type: "success"
        }
        commit('showNotification', notification)
        return dispatch('getItems', { resetPage: false });
      });
    },
    getSearchCollaborators: ({ commit }, payload) => {
      let url = endPoints.collaborators;
      url += "/collaborator/" + payload.search;

      axios.get(url).then((collaborators) => {
        if (payload.type == 0) {
          commit("setCollaborators", { collaborators: collaborators.data });
        } else if (payload.type == 1) {
          commit("setCollaboratorsNominated", { collaborators: collaborators.data });
        }

      });
    },
    getCollaboratorsResponsibles: ({ commit }) => {
      let url = endPoints.collaborators;
      url += "?page=" + 1 + "&" + "itemsPerPage=" + 999 + "&" + "department=" + 7;

      axios.get(url).then((collaborators) => {
        commit("setCollaboratorsResponsibles", { collaborators: collaborators.data.data });
      });
    },
    getCollaborators: ({ commit }) => {
      let url = endPoints.collaborators;
      url += "?page=" + 1 + "&" + "itemsPerPage=" + 999;

      axios.get(url).then((collaborators) => {
        commit("setCollaborators", { collaborators: collaborators.data.data });
      });
    },
    getRoles: ({ commit }) => {
      let url = endPoints.roles;
      url += "?page=" + 1 + "&" + "itemsPerPage=" + 999;

      axios.get(url).then((roles) => {
        commit("setRoles", { roles: roles.data.data });

      });
    },
    getDepartments: ({ commit }) => {
      let url = endPoints.departments;
      url += "?page=" + 1 + "&" + "itemsPerPage=" + 999;

      axios.get(url).then((departments) => {
        commit("setDepartments", { departments: departments.data.data });

      });
    },
    getItems: async ({ commit, state, rootGetters }, payload) => {
      let job = {};
      let jobId = '';
      job = rootGetters["jobs/getJob"];
      jobId = job.id;

      if (job.id == undefined || job.id == null || job.id == '') {
        jobId = window.location.pathname.split("/")[2];
        let urlJob = endPoints.jobs + "?id=" + jobId;
        try {
          job = await axios.get(urlJob);

          job = job.data.data[0]

        } catch (error) {
          commit(
            "showNotification",
            defaultErrorNotification(error?.response?.data?.message)
          );
        }

      }
      let filter = state.filter
      let url = endPoints.candidates + '/' + jobId

      if (payload.resetPage) {
        url += "?page=" + 1 + "&" + "itemsPerPage=" + 10;
        commit("setPage", { page: 1, itemsPerPage: 10 });
      } else {
        url +=
          "?page=" +
          state.pagination.page +
          "&" +
          "itemsPerPage=" +
          state.pagination.itemsPerPage;
      }
     
      Object.keys(filter).forEach(function (field) {
        if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
          url += "&" + field + "=" + filter[field]
        }
      })
      if (state.pagination && state.pagination.sortBy && state.pagination.sortBy.length > 0) {
        url += "&sort_by=" + (state.pagination.sortBy[0] === 'score' ? "id" : state.pagination.sortBy[0])
        url += "&sort_desc=" + state.pagination.sortDesc[0]
      }
      commit("setLoading", true);
      try {
        let items = await axios.get(url);
        commit("setItems", items.data.data)
        commit("setTotalItems", items.data.total);

      } catch (error) {

        commit(
          "showNotification",
          defaultErrorNotification(error?.response?.data?.message)
        );

      }
      commit("setJob", job)
      commit("setLoading", false);
    },
    save: async ({ dispatch, commit, state }) => {
      let func = "";
      let data = state.selectedItem;
      data.id_job = state.job.id;
      data.requester_name = state.job.requester_name;
      data.requester = state.job.requester;
      data.phone = data.phone.replace("-", "").replace("(", "").replace(")", "")
      var url = endPoints.candidates;
      commit("showOverlay", { show: true });
      if (state.mode == "ADD") {
        func = axios.post;
        url = endPoints.candidates;
      } else {
        func = axios.put;
        url += "/" + data.id;
      }
      if(data.test == null || data.test == "Invalid Date"){
        data.test = ''
      }
      if(data.date_interview == null || data.date_interview == "Invalid Date"){
        data.date_interview = ''
      }
      if(data.practice_test == null || data.practice_test == "Invalid Date"){
        data.practice_test = ''
      }
      if(data.return_process == null || data.return_process == "Invalid Date"){
        data.return_process = ''
      }
      func(url, data).then(
        () => {
          dispatch("getItems", { resetPage: false });
          let notification = {
            show: true,
            message: "Candidato salvo com sucesso",
            type: "success",
          };
          commit("showNotification", notification);
          commit("closeDialog");
          commit("showOverlay", { show: false });
        },
        (error) => {
          let notification = {
            show: true,
            message: error.response.data.message,
            type: "error",
          };
          commit("showNotification", notification);
          commit("showOverlay", { show: false });
        }
      );
    },
  },
  getters: {
    getField,
  },
};
