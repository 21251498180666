import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

let darkModeConfig = localStorage.getItem('dark_theme');
let darkMode = darkModeConfig == 'true' ? true : false;

export default new Vuetify({
    theme: {
        dark: darkMode,
        options: { customProperties: true },
        themes: {
            light: {
                "text-color-contrast": '#000000',
                "text-color-equal-theme": '#ffffff'
            },
            dark: {
                "text-color-contrast": '#ffffff',
                "text-color-equal-theme": '#000000'
            }
            
        }
    },
});
