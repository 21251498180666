<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline text-center justify-center">Filtro</span>

        <v-col class="text-right">
          <v-btn v-if="filtered" color="white" class="ma-2"  @click="clearFilter" right small depressed>
            <v-icon color="black">mdi-filter-remove</v-icon>
          </v-btn>
        </v-col>
      </v-card-title>

      <v-card-text>
        <v-form ref="form">
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm12 md12>
                <v-autocomplete
                    v-model="product_id"
                    :items="products"
                    outlined
                    chips
                    small-chips
                    no-data-text="Nenhum produto encontrado"
                    item-text="name"
                    item-value="id"
                    placeholder="Digite aqui o nome do produto"
                    label="Produto"
                    :search-input.sync="searchProduct"
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field
                    label="Tamanho"
                    v-model="size"
                    outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field
                    label="Cep"
                    v-model="cep"
                    outlined
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn text @click="showFilter({show: false})">Cancelar</v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn text type="submit" right @click="filter">Filtrar</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import debounce from '../../../util/debounce.js' 
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "ProductsFilter",
  computed: {
    ...mapState("stock", ["filtered", "products"]),
    ...mapFields("stock", [
      "filter.product_id",
      "filter.size",
      "filter.cep"
    ]),
  },
  data: () => ({
    searchProduct: ''
  }),
  mixins: [debounce],
  mounted() {
    this.getProducts = this.debounce(this.getProducts, 500)
  },
  methods: {
    ...mapMutations("stock", ["showFilter"]),
    ...mapActions("stock", ["clearFilter", "getItems", "getProducts"]),
    filter() {
      this.getItems({ resetPage: true });
      this.showFilter({ show: false });
    },
  },
  watch: {
    searchProduct: {
      handler () {
        if(this.searchProduct != null) {
          this.getProducts({search: this.searchProduct})
        }
      },
    },
  }
};
</script>

<style>
</style>