<template>
  <div>
    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2000"
      v-model="showNotification"
    >
      {{ notificationMessage }}
    </v-snackbar>
    <v-toolbar flat class="elevation-10">
      <v-toolbar-title>Clubes / Instâncias</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="black" dark class="ma-2" @click="getInstances" small><v-icon>mdi-refresh</v-icon></v-btn>
      <v-btn color="black" dark class="ma-2" @click="add" small><v-icon>mdi-plus</v-icon></v-btn>
      
    </v-toolbar>

    <v-dialog :value="dialog" persistent width="600">

      <v-card>
        <v-card-title>
          <span class="headline">{{ this.formTitle() }}</span>
        </v-card-title>

        <v-card-text>
          <v-alert
            v-if="successNotification"
            :value="successNotification"
            dense
            type="success"
            icon="mdi-check"
            outlined
          >Instância salva com sucesso.</v-alert>
          <v-alert
            v-if="errorNotification"
            :value="errorNotification"
            color="error"
            icon="mdi-alert-circle"
            outlined
          >{{ this.errorMessage }}</v-alert>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Nome *"
                    v-model="name"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('name')"
                    v-validate="'required:Nome,name'"
                    data-vv-name="name"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Nick *"
                    v-model="nick"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('nick')"
                    v-validate="'required:Nick,nick'"
                    data-vv-name="nick"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-select
                    label="Liga *"
                    :clearable="true"
                    :items="leagues"
                    v-model="id_league"
                    :readonly="this.mode == 'VIEW'"
                    item-text="name"
                    item-value="id"
                    outlined
                  >
                  </v-select>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Número *"
                    v-model="phone"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('phone')"
                    v-validate="'required:Telefone,phone'"
                    data-vv-name="phone"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="ID Suprema *"
                    v-model="id_pppoker"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('id_pppoker')"
                    v-validate="'required:Id Pppoker  ,id_pppoker'"
                    data-vv-name="id_pppoker"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-select
                    :clearable="true"
                    clear-icon="mdi-close"
                    label="Banco Preferencial"
                    v-model="preferred_bank"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('preferred_bank')"
                    :items="banksPix"
                    :item-text="getTextBank"
                    item-value="id"
                    data-vv-name="preferred_bank"
                    outlined
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="ID"
                    v-model="id"
                    :disabled="this.mode == 'EDIT'"
                    :readonly="this.mode == 'VIEW' || this.mode == 'EDIT'"
                    :error-messages="errors.collect('id')"
                    data-vv-name="id"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Token"
                    v-model="token"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('token')"
                    data-vv-name="token"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12 v-if="this.mode != 'ADD'">
                  <v-text-field
                    required
                    label="Url Base *"
                    v-model="base_url"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('base_url')"
                    data-vv-name="base_url"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12 v-if="this.mode != 'ADD'">
                  <v-select
                    label="Pix Automático *"
                    required
                    :items="automatic_pix_types"
                    v-model="automatic_pix"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('automatic_pix')"
                    v-validate="'required:Pix Automático,automatic_pix'"
                    item-text="description"
                    item-value="id"
                    data-vv-name="automatic_pix"
                    outlined
                  >
                  </v-select>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-checkbox :readonly="this.mode == 'VIEW'" :disabled="this.mode == 'VIEW'" v-model="is_club" label="Clube"></v-checkbox>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-col>
            <v-btn
              text
              @click="closeDialog"
            >{{ mode == 'VIEW' ? 'Voltar' : 'Cancelar'}}</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn
              text
              type="submit"
              right
              v-if="mode == 'EDIT'  || mode == 'ADD'"
              @click="save"
            >Salvar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-data-table
      :headers="headers"
      :loading="loading"
      :items="instances"
      :options.sync="pagination"
      :server-items-length="totalInstances"
      disable-pagination
      hide-default-footer
      item-key="id_instance"
      no-data-text="Nenhuma instância encontrada"
      class="elevation-5 table-margin"
    >
      <template v-slot:[`item.is_club`]="{ item }">
        <v-simple-checkbox :value="item.is_club" disabled></v-simple-checkbox>
      </template>

      <template v-slot:[`item.automatic_pix`]="{ item }">
        {{item.automatic_pix == 1 ? 'Sim' : 'Não'}}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="newUpdateContactList(item)">mdi-contacts</v-icon>
        <v-icon small class="mr-2" @click="updateContactList(item)">mdi-reload</v-icon>
        <v-icon small class="mr-2" @click="viewInstance(item)">mdi-information</v-icon>
        <v-icon small class="mr-2" @click="edit(item)">mdi-pencil</v-icon>
        <v-icon small class="mr-2" @click="showDeleteDialog(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>

    <v-dialog :value="dialogDelete" persistent width="300">
      <v-card>
        <v-card-title class="headline">Confirmar a exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn
              text
              @click="closeDeleteDialog()"
            >Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn
              text
              type="submit"
              right
              @click="deleteInstance"
            >Confirmar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
  
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import debounce from '../../util/debounce.js' 
  import { mapFields } from 'vuex-map-fields'

  export default {
    name: 'Instancias',
    created() {
      this.getLeagues();
      this.getBanks()
      this.getInstances();
      this.$validator.extend('required', {
        getMessage: (field, [label]) => label + ' deve ser informado',
        validate: (_, [, field]) => {
          if((this[field] || this[field] === 0) && (this[field] !== '' && this[field] !== null)) {
            return true
          } else {
            return false
          }
        }
      });
    },
    computed: {
      ...mapState("bank", { banks: "items" }),
      ...mapState('contact_chat', ['contacts']),
      ...mapState('instances', ['dialog', 'dialogDelete', 'instance', 'leagues', 'errorMessage', 'errorNotification', 'errors', 'loading', 'mode',
                                'notificationType', 'notificationMessage', 'successNotification', 'totalInstances', 'instances']),
      ...mapFields('instances', [
          'editedInstance.id',
          'editedInstance.base_url',
          'editedInstance.name',
          'editedInstance.id_league',
          'editedInstance.preferred_bank',
          'editedInstance.nick',
          'editedInstance.token',
          'editedInstance.phone',
          'editedInstance.url',
          'editedInstance.cg_id',
          'editedInstance.cg_name',
          'editedInstance.id_pppoker',
          'editedInstance.is_club',
          'editedInstance.automatic_pix',
          'pagination',
          'showNotification'
          // ...
        ]),
        banksPix() {
          return this.banks.filter((b) => b.pix_key)
        }
    },
    data: () => ({
      automatic_pix_types: [
        {
            id: 0, 
            description: "Não"
        },
        { 
            id: 1,
            description: "Sim"
        }
      ],
      headers: [
        { text: "ID Sistema", value: "id_instance" },
        { text: "Número", value: "phone" },
        {
          text: "Nome",
          align: "left",
          value: "name"
        },
        {
          text: "Nick",
          align: "left",
          value: "nick"
        },
        {
          text: "Liga",
          align: "left",
          value: "name_league"
        },
        { text: "Id Pppoker",  value: "id_pppoker" },
        { text: "Id",  value: "id" },
        { text: "Token", value: "token" },
        { text: "Clube?", value: "is_club" },
        { text: "Pix Automático", value: "automatic_pix" },
        { text: "Ações",  value: "actions", sortable: false, align: "center" }
      ],
      rules: {
        required: value => !!value || "Required.",
        counter: value => value.length <= 20 || "Max 20 characters"
      },
      valid: false,
      searchInput: '',
      selected_contact: ''
    }),
    mounted() {
      this.getContacts = this.debounce(this.getContacts, 500)
    },
    mixins: [debounce],
    methods: {
      add() {
        this.addInstance()
        this.selected_contact = {}
      },
      edit(item) {
        this.editInstance(item)
        this.selected_contact = {}
      },
      ...mapMutations('instances', ['addInstance', 'editInstance', 'viewInstance']),
      ...mapActions('contact_chat', ['getContacts']),
      ...mapActions("bank", { getBanks: "getItems" }),
      ...mapActions('instances', ['closeDialog', 'closeDeleteDialog', 'deleteInstance', 'getInstances', 'getLeagues', 'saveInstance', 'showDeleteDialog', 'updateContactList', 'newUpdateContactList']),
      getText(item) {
        if(item.name) {
          if(item.name.length == 1) {
            return item.name
          }
          
          var text = ""
          item.name.forEach((name) => {
            text += name + " | "
          })
          return text
        }
        else {
          return text
        }
      },
      getTextBank(item) {
        if(item.account) {
          return item.name + ' | ' + item.pix_key
        } else {
          return item.name
        }
      },
      formTitle() {
        switch (this.mode) {
          case "ADD":
            return "Nova Instância";
          case "EDIT":
            return "Editar Instância";
          default:
            return "Instância";
        }
      },
      save() {
        this.$validator.validateAll().then(valid => {
          if(valid) {
            if(this.selected_contact) {
              if(this.selected_contact._id != "" && this.selected_contact._id != undefined) {
                this.cg_id = this.selected_contact["_id"]
                this.cg_name = this.selected_contact["name"][0]
              }
            }
            this.saveInstance()
          }
        })
      }
    },
     watch: {
      pagination: {
        handler () {
          this.getInstances()
        },
      },
      searchInput (val) {
        var payload = {
          search: val,
          contacts: this.selected_contact
        }
        this.getContacts(payload)
      },
    },
  }
</script>

<style>
  .alert {
    background-color: white;
    z-index: 100;
    top: 50%;
  }
  .error--text {
    color: red !important;
  }
  .right {
    float: right;
  }
  .v-card__text {
    padding: 0px !important;
  }
</style>