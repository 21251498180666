<template>
  <div>
    <v-dialog :value="dialog" persistent width="600">
      <v-card>
        <v-card-title>
          <span class="headline">Faturamento</span>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm6 md6>
                  <v-select
                    label="Slot"
                    :items="instances"
                    v-model="slot"
                    :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                    item-text="name"
                    item-value="id_instance"
                    outlined
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="Valor *"
                    v-model="value"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('value')"
                    v-validate="'required:Value,value'"
                    data-vv-name="Valor"
                    outlined
                  ></v-text-field>
                </v-flex>

              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-col>
            <v-btn text @click="closeDialog">{{
              mode == "VIEW" ? "Voltar" : "Cancelar"
            }}</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn
              text
              type="submit"
              right
              v-if="mode == 'EDIT' || mode == 'ADD'"
              @click="save"
              >Salvar</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "SlotsRevenueForm",
  methods: {
    ...mapMutations("slots_revenue", [
      "setEditedItem",
      "closeDialog",
    ]),
    ...mapActions("slots_revenue", ["save", "showDialogForm"]),
    formatDate(date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`
    },
  },
  computed: {
    ...mapState("slots_revenue", ["mode", "editedItem", "dialog"]),
    ...mapState('instances', ['instances']),
    ...mapFields("slots_revenue", [
      "editedItem.id",
      "editedItem.value",
      "editedItem.slot",
    ]),
  },
  data: () => ({
    showStart: false,
    showFinish: false,
    valid: false,
  }),
};
</script>