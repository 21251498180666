<template>
    <div>
        <div @drop.prevent="addDropFile" @dragover.prevent>
            <v-card>
                <v-card-title>
                    <span class="headline text-center"
                        >Agendar Notificação</span
                    >
                    <v-spacer></v-spacer>
                    <v-progress-circular
                        v-if="loading"
                        indeterminate
                        class="text-right"
                        color="primary"
                        size="24"
                    ></v-progress-circular>
                </v-card-title>

                <v-card-text>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-container grid-list-md>
                            <v-layout wrap>
                                <v-flex xs12 sm4 md4>
                                    <v-datetime-picker
                                        v-model="date_picker"
                                        date-format="dd/MM/yyyy"
                                        :time-picker-props="timeProps"
                                        label="Data *"
                                        :disabled="mode == 'VIEW'"
                                        :textFieldProps="{ outlined: true }"
                                    >
                                        <template slot="dateIcon">
                                            <v-icon>mdi-calendar</v-icon>
                                        </template>
                                        <template slot="timeIcon">
                                            <v-icon>mdi-clock</v-icon>
                                        </template>
                                    </v-datetime-picker>
                                </v-flex>
                                <v-flex xs12 sm8 md8>
                                    <v-text-field
                                        required
                                        label="Título *"
                                        v-model="title"
                                        :readonly="this.mode == 'VIEW'"
                                        :error-messages="
                                            errors.collect('title')
                                        "
                                        v-validate="'required:Título,title'"
                                        data-vv-name="title"
                                        outlined
                                    ></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm6 md12>
                                    <v-textarea
                                        required
                                        label="Corpo *"
                                        v-model="body"
                                        :readonly="this.mode == 'VIEW'"
                                        :error-messages="errors.collect('body')"
                                        v-validate="'required:Corpo,body'"
                                        data-vv-name="body"
                                        outlined
                                        :rows="3"
                                    ></v-textarea>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-col>
                        <v-btn text @click="closeDialogForm">{{
                            mode == 'VIEW' ? 'Voltar' : 'Cancelar'
                        }}</v-btn>
                    </v-col>
                    <v-col class="text-right" v-if="mode != 'VIEW'">
                        <v-btn text type="submit" right @click="save"
                            >Salvar</v-btn
                        >
                    </v-col>
                </v-card-actions>
            </v-card>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
    name: 'NotificationsScheduleForm',
    computed: {
        ...mapState('notifications_schedule', ['item', 'loading', 'mode',]),
        ...mapFields('notifications_schedule', [
            'selectedItem.title',
            'selectedItem.date_picker',
            'selectedItem.date',
            'selectedItem.body',
        ]),
    },
    created() {
        this.$validator.extend('required', {
            getMessage: (field, [label]) => label + ' deve ser informado',
            validate: (_, [, field]) => {
                if (this[field] || this[field] === 0) {
                    return true
                } else {
                    return false
                }
            },
        })
    },
    methods: {
        ...mapActions('notifications_schedule', ['saveNotification']),
        ...mapMutations('notifications_schedule', ['closeDialogForm']),

        save() {
            this.$validator.validateAll().then((valid) => {
                if (valid) {
                    var d = new Date(this.date_picker)
                    this.date =
                        d.toISOString().substr(0, 10) +
                        ' ' +
                        d.toLocaleTimeString()
                    this.value = parseFloat(this.value)
                    this.saveNotification()
                }
            })
        },
    },
    data: () => ({
        timeProps: {
            format: '24hr',
        },
        valid: false,
    }),
}
</script>
