import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../../config/endPoints';

const defaultItem = {
    id_player: 0,
    bank: '',
    agency: '',
    account: '',
    pix_key: '',
    type: 0
};

export default {
    namespaced: true,
    state: {
        dialog: false,
        dialogDelete: false,
        editedItem: {},
        itemToDelete: {},
        items: [],
        loading: false,
        mode: 'LIST',
        overlay: false,
        pagination_subagent: {
            page: 1,
            itemsPerPage: 10
        },
        totalSubAgents: 0
    },
    mutations: {
        add: (state) => {
            state.mode = "ADD"
            state.selectedContact = {}
            state.editedItem = Object.assign({}, defaultItem)
            state.dialog = true
        },
        edit: (state, payload) => {
            state.selectedContact = {}
            state.editedItem = Object.assign({}, payload.item)
            state.editedIndex = state.items.indexOf(payload.item)
            state.mode = "EDIT"
            state.dialog = true
        },
        closeDialog: (state) => {
            state.editedItem = {}
            state.editedIndex = -1
            state.errors = [];
            state.dialog = false
            state.mode = "LIST"
        },
        loading: (state, payload) => state.loading = payload.loading,
        setMode: (state, mode) => state.mode = mode,
        setSubAgents: (state, payload) => {
            state.subAgentsAgent = payload.subAgents
        },
        setItems: (state, payload) => {
            state.items = payload.items
        },
        setItemToDelete: (state, item) => state.itemToDelete = item,
        setTotalSubAgents: (state, payload) => state.totalSubAgents = payload.total,
        setMasterAgents: (state, payload) => state.master_agents = payload.master_agents,
        showDialog: (state, show) => state.dialog = show,
        setFindAgents: (state, payload) => state.find_agents = payload.find_agents,
        setDialogDelete: (state, show) => state.dialogDelete = show,
        showFilter: (state, payload) => state.dialogFilter = payload.show,
        showLoading: (state, show) => state.loading = show,
        updateField
    },
    getters: {
        getField,
    },
    actions: {
        closeDialog: (({ commit }) => {
            commit('closeDialog', false)
        }),
        closeDeleteDialog: ({ commit }) => {
            commit('setDialogDelete', false)
            commit('setItemToDelete', {})
        },
        deleteItem: ({ dispatch, commit, rootGetters, state }) => {
            commit('loading', true)
            axios.delete(endPoints.players_accounts + "/" + state.itemToDelete.id, state.itemToDelete).then(() => {
                dispatch('closeDeleteDialog')
                commit('loading', { loading: true })
                let notification = {
                    show: true,
                    message: "Endereço excluido com sucesso",
                    type: "success"
                }
                commit('agent/showNotification', notification, { root: true })
                return dispatch('getSubAgents', { resetPage: true, agent_id: rootGetters["agent/getIdAgent"] });
            });
        },
        getItems({ commit }, payload) {
            commit('loading', { loading: true })
            let url = endPoints.players_accounts;
            url += '?page=' + 1 + '&' + 'itemsPerPage=' + 20;
            url += "&player_id=" + payload.player_id;

            axios.get(url).then(items => {
                commit('loading', { loading: false })
                commit('setItems', { items: items.data.data })
            });
        },
        showDeleteDialog: ({ commit }, item) => {
            commit('setDialogDelete', true)
            commit('setItemToDelete', item)
        },
        save: ({ commit, dispatch, state, rootGetters }) => {
            var url = endPoints.players_accounts;
            let func = '';
            if (state.mode == 'ADD') {
                func = axios.post;
                url = endPoints.players_accounts;
                state.editedItem.id_player = rootGetters["player/getIdPlayer"]
                console.log(state.editedItem.id_player)
            } else {
                func = axios.put;
                url += '/' + state.editedItem.id;
            }

            func(url, state.editedItem).then(
                () => {
                    dispatch('getItems', { resetPage: true, player_id: rootGetters["player/getIdPlayer"] })
                    let notification = {
                        show: true,
                        message: "Endereço salvo com sucesso",
                        type: "success"
                    }
                    commit('player/showNotification', notification, { root: true })
                    commit('closeDialog')
                },
                error => {
                    let notification = {
                        show: true,
                        message: error.response.data.message,
                        type: "error"
                    }
                    commit('player/showNotification', notification, { root: true })
                }
            );
        }
    }
}