import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../config/endPoints';

const defaultItem = {
    start_value: 0,
    finish_value: 0,
    percentage: 50,
    id_agent: null,
    type_rake_deal: 0
};

export default {
    namespaced: true,
    state: {
        agent_id: "",
        dialog: false,
        dialogDelete: false,
        editedItem: {},
        filter: {
            id_agent: ''
        },
        loading_rake_deals: false,
        itemToDelete: {},
        loading: false,
        mode: 'LIST',
        pagination: {
            page: 1,
            itemsPerPage: 10
        },
        rake_deals: [],
        totalRakeDeals: 0
    },
    mutations: {
        add: (state, payload) => {
            state.mode = "ADD"
            state.selectedContact = {}
            state.editedItem = Object.assign({}, defaultItem)
            state.editedItem.start_value = payload.start_value
            state.dialog = true
        },
        edit: (state, payload) => {
            state.selectedContact = {}
            state.editedItem = Object.assign({}, payload.item)
            state.editedIndex = state.rake_deals.indexOf(payload.item)
            state.mode = "EDIT"
            state.dialog = true
        },
        closeDialog: (state) => {
            state.editedItem = {}
            state.editedIndex = -1
            state.errors = [];
            state.dialog = false
            state.mode = "LIST"
        },
        loading_rake_deals: (state, payload) => state.loading_rake_deals = payload.loading,
        setAgentId: (state, payload) => state.agent_id = payload.agent_id,
        setMode: (state, mode) => state.mode = mode,
        setRakeDeals: (state, payload) => state.rake_deals = payload.rakeDeals,
        setItemToDelete: (state, item) => state.itemToDelete = item,
        setTotalRakeDeals: (state, payload) => state.totalRakeDeals = payload.total,
        showDialog: (state, show) => state.dialog = show,
        setDialogDelete: (state, show) => state.dialogDelete = show,
        showFilter: (state, payload) => state.dialogFilter = payload.show,
        showLoading: (state, show) => state.loading = show,
        updateField
    },
    getters: {
        getField,
    },
    actions: {
        add: ({ commit, rootGetters }) => {
            axios.get(endPoints.rake_deals + "/start_rake_deal/" + rootGetters["agent/getIdAgent"]).then((response) => {
                commit('add', {start_value: response.data})
            });
        },
        closeDialog: (({ commit }) => {
            commit('closeDialog', false)
        }),
        closeDeleteDialog: ({ commit }) => {
            commit('setDialogDelete', false)
            commit('setItemToDelete', {})
        },
        deleteItem: ({ dispatch, commit, state }) => {
            commit('loading', true)
            axios.delete(endPoints.rake_deals + "/" + state.itemToDelete.id, state.itemToDelete).then(() => {
                dispatch('closeDeleteDialog')
                commit('loading', false)
                let notification = {
                    show: true,
                    message: "Faixa de Rake excluida com sucesso",
                    type: "success"
                }
                commit('agent/showNotification', notification, { root: true })
                return dispatch('getRakeDeals', { resetPage: false });
            });
        },
        getRakeDeals: ({ commit }, payload) => {
            commit('loading_rake_deals', { loading: true })
            let url = endPoints.rake_deals + "?id_agent=" + payload.agent_id
            axios.get(url).then(response => {
                commit('setTotalRakeDeals', { total: response.data.total })
                commit('loading_rake_deals', { loading: false })
                commit('setRakeDeals', { rakeDeals: response.data.data })
            });
        },
        showDeleteDialog: ({ commit }, item) => {
            commit('setDialogDelete', true)
            commit('setItemToDelete', item)
        },
        save: ({ commit, dispatch, state, rootGetters }) => {
            var url = endPoints.rake_deals;
            let func = '';
            if (state.mode == 'ADD') {
                func = axios.post;
                url = endPoints.rake_deals;
                state.editedItem.id_agent = rootGetters["agent/getIdAgent"]
            } else {
                func = axios.put;
                url += '/' + state.editedItem.id;
            }
            
            state.editedItem.type_deal = rootGetters["agent/getAgentTypeDeal"]

            func(url, state.editedItem).then(
                () => {
                    dispatch('getRakeDeals', { resetPage: true, agent_id: rootGetters["agent/getIdAgent"] })
                    let notification = {
                        show: true,
                        message: "Faixa de Rake salva com sucesso",
                        type: "success"
                    }
                    commit('agent/showNotification', notification, { root: true })
                    commit('closeDialog')
                },
                error => {
                    let notification = {
                        show: true,
                        message: error.response.data.message,
                        type: "error"
                    }
                    commit('agent/showNotification', notification, { root: true })
                }
            );

        }
    }
}