import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../../config/endPoints';

const defaultItem = {
    id: null,
    id_slot: null,
    type_deal: null
}
const defaultFilter = {
    name: "",
    nick: "",
    status: "",
    id_app: "",
    plataform_id: ""
}
export default {
    namespaced: true,
    state: {
        agent_id: 0,
        items: [],
        loading: false,
        dialog: false,
        dialogDelete: false,
        dialogFilter: false,
        overlay: false,
        slotToDelete: {},
        showNotification: false,
        notificationMessage: '',
        notificationType: "success",
        mode: "",
        pagination: {
            page: 1,
            itemsPerPage: 10
        },
        filter: {
            id_app: '',
            name: "",
            status: '',
            nick: '',
            plataform_id: '',
        },
        selectedItem: {},
        totalItems: 0,
        slots: [],
        plataform_types: [
            {
                id: 0,
                description: "Suprema Poker"
            },
            {
                id: 1,
                description: "PPPoker"
            },
            {
                id: 2,
                description: "Cacheta"
            }
        ],
        possible_status: [
            { id: 0, status: "Inativo" },
            { id: 1, status: "Ativo" }
        ],
    },
    mutations: {
        loading: (state, payload) => state.loading = payload.loading,
        setItems: (state, payload) => {
            state.items = payload.items
        },
        overlay: (state, payload) => {
            state.overlay = payload.show
        },
        add: (state) => {
            state.dialog = true
            state.mode = "ADD";
            state.selectedItem = Object.assign({}, defaultItem)
        },
        edit: (state, payload) => {
            console.log(payload)
            state.selectedItem = Object.assign({}, payload)
            state.dialog = true
            state.mode = "EDIT";
        },
        showDialogDelete: (state, show) => state.dialogDelete = show,
        setToDelete: (state, payload) => state.slotToDelete = payload,
        showDialog: (state, show) => state.dialog = show,
        showCopyNotification: (state) => {
            state.showNotification = true,
                state.notificationMessage = "Senha copiada para área de transferência",
                state.notificationType = "success"
        },
        showNotification: (state, payload) => {
            state.showNotification = payload.show,
                state.notificationMessage = payload.message,
                state.notificationType = payload.type
        },
        setSlots: (state, payload) => {
            state.slots = payload.slots;
          },
        setTotalItems: (state, payload) => state.totalItems = payload.total,
        clearFilter: (state) => {
            state.filter = Object.assign({}, defaultFilter)
        },
        showFilter: (state, payload) => state.dialogFilter = payload.show,
        updateField
    },
    getters: {
        getField,
    },
    actions: {
        closeDialog: ({ state, commit }) => {
            state.selectedItem = Object.assign({}, defaultItem)
            commit('showDialog', false)
        },
        edit: ({ state }, payload) => {
            state.selectedItem = Object.assign({}, payload.item)
            state.mode = "EDIT"
            state.dialog = true
        },
        getItems: ({ commit, rootGetters, state }, payload) => {
            commit('loading', { loading: true })
            const filter = state.filter
            let url = endPoints.agent_slots

            if (payload.resetPage === true) {
                url += '?page=' + 1 + '&' + 'itemsPerPage=' + 10;
            } else {
                url += '?page=' + state.pagination.page + '&' + 'itemsPerPage=' + state.pagination.itemsPerPage;
            }
            url += "&id_agent=" + `${rootGetters["agent/getIdAgent"]}`

            if (state.pagination && state.pagination.sortBy && state.pagination.sortBy.length > 0) {
                url += "&sort_by=" + state.pagination.sortBy[0]
                url += "&sort_desc=" + !state.pagination.sortDesc[0]
            }
            
            Object.keys(filter).forEach(function (field) {
                if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
                    url += "&" + field + "=" + filter[field]
                }
            });
            axios.get(url).then(response => {
                let data = response.data != null ? response.data : []
                commit('setTotalItems', { total: data.total })
                commit('loading', { loading: false })
                commit('setItems', { items: data.data })
            });
        },
        getSearchSlots: ({ commit}, payload) => {
            let url = endPoints.agent_slots;
            url += "/slot/" + payload.search;

            axios.get(url).then((slots) => {
                commit("setSlots", { slots: slots.data });
            });
            

        },

        showDeleteDialog: ({ commit }, slot) => {
            commit('showDialogDelete', true)
            commit('setToDelete', slot)
            
        },
        save: ({ state, rootGetters, commit, dispatch }) => {
            var url = endPoints.agent_slots + "/" + rootGetters["agent/getIdAgent"]
            if (state.mode == "ADD") {
                state.selectedItem.id_agent = rootGetters["agent/getIdAgent"]
                
                axios.post(url, state.selectedItem).then(() => {
                    commit('showDialog', false)
                    dispatch('getItems', { resetPage: true })
                });
            } else if (state.mode == "EDIT") {
                axios.put(endPoints.agent_slots + "/" + state.selectedItem.id, state.selectedItem).then(() => {
                    commit('showDialog', false)
                    dispatch('getItems', { resetPage: false })
                }).catch((error) => {
                    let notification = {
                        show: true,
                        message: error.response.data.message,
                        type: "error"
                    }
                    commit('showNotification', notification)
                })
            }
        },
        closeDeleteDialog: ({ commit }) => {
            commit('showDialogDelete', false)
            commit('setToDelete', {})
        },
        deleteUser: ({ dispatch, commit, state }) => {
            commit('loading', true)
            var url = endPoints.agent_slots + "/" + state.slotToDelete.id;
            axios.delete(url).then(() => {
                dispatch('closeDeleteDialog')
                commit('loading', false)
                let notification = {
                    show: true,
                    message: "Relacionamento excluído com sucesso",
                    type: "success"
                }
                commit('showNotification', notification)
                return dispatch('getItems', { resetPage: false });
            });
        },
    },
}