<template>
  <div>
    <!-- <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay> -->

    <v-card>
      <v-card-title>
        <span class="headline">Enviar Mensagem</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs6 sm6 md6>
                <v-select
                    clearable
                    clear-icon="mdi-close"
                    label="Clube *"
                    :items="instances"
                    item-text="name"
                    item-value="id"
                    data-vv-name="id"
                    outlined
                    required
                    :error-messages="errors.collect('instance_id')"
                    v-validate="'required:Clube,instance_id'"
                    v-model="editedItem.instance_id"
                  >
                </v-select>
              </v-flex>


              <v-flex xs6 sm6 md6>
                <v-select
                    clearable
                    clear-icon="mdi-close"
                    label="Tipo *"
                    :items="messageTypes"
                    item-text="name"
                    item-value="type"
                    data-vv-name="type"
                    outlined
                    required
                    :error-messages="errors.collect('message_type')"
                    v-validate="'required:Tipo,message_type'"
                    v-model="editedItem.message_type"
                    @change="messageTypeOnChange"
                  >
                </v-select>
              </v-flex>
            </v-layout>

            <v-layout wrap>
              <v-flex xs12 sm12 md12>
                <v-textarea
                    auto-grow        
                    label="Mensagem *"
                    background-color="white"
                    outlined
                    required
                    rows="1"
                    id="textareadesc"
                    :readonly="false"
                    hide-details="true"
                    :error-messages="errors.collect('message_to_send')"
                    v-validate="'required:Mensagem,message_to_send'"
                    v-model="editedItem.message_to_send"
                >
                </v-textarea>
              </v-flex>
            </v-layout>
            
            <v-layout wrap v-if="this.userData.role == 99">
              <v-flex xs12 sm12 md12 class="recover-margin">
                <v-checkbox
                  :label="'Modo Desenvolvedor'"
                  v-model="editedItem.dev_mode"
                ></v-checkbox>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn text @click="showSendMessageModal({show: false})">Cancelar</v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn text type="submit" right @click="sendMessage">Enviar</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "ManageAnnotatedSendMessage",
  created() {
    const self = this
    this.$validator.extend("required", {
      getMessage: (field, [label]) => label + " deve ser informado",
      validate: (_, [, field]) => {
        if (self.editedItem[field] || self.editedItem[field] === 0) {
          return true;
        } else {
          return false;
        }
      },
    });
  },
  computed: {
    ...mapState("instances", ["instances", "overlay"]),
    ...mapState("login", ["userData"]),
    ...mapState("manage_annotated", ["editedItem"]),
  },
  data: () => ({
    messageTypes: [
      {
        name: 'Acerto (Segunda)',
        type: 1
      },
      {
        name: 'Cobrança (Terça)',
        type: 2
      }
    ]
  }),
  methods: {
    ...mapMutations("manage_annotated", ["showSendMessageModal", "setMessageToSend"]),
    ...mapFields('manage_annotated', [
        "instance_id",
        "message_type",
        "dev_mode",
        "message_to_send"
      ]
    ),
    ...mapActions("manage_annotated", ["sendManageAnnotatedMessage"]),
    sendMessage() {
      this.$validator.validateAll().then(valid => {
        if (valid) {
          this.sendManageAnnotatedMessage()
        }
      })
    },
    messageTypeOnChange(newValue) {
      const automaticMessage = "*Mensagem automática enviada pelo sistema*"
      if (newValue == 1) {
        this.editedItem.message_to_send = "Olá! Estamos passando para verificar se você já recebeu e conferiu o seu acerto. " +
          "Caso não tenha recebido ou tenha alguma duvida, entre em contato conosco. \n\n" + automaticMessage
      } else if (newValue == 2) {
        this.editedItem.message_to_send = "Olá! Estamos enviando esta mensagem para lembrar que há um valor a ser acertado com o clube. O limite deste acerto é às 18h. " +
          "Caso o acerto ja tenha sido feito, favor desconsiderar. \n\n" + automaticMessage
      } else 
        this.editedItem.message_to_send = ""
    }
  }
};
</script>

<style>
</style>