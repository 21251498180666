<template>
  <div>
    <v-card>
    <v-card-title>
        <span v-if="modDialogPayment=='ADD'" class="headline">Adicionar Pagamento</span>
        <span v-if="modDialogPayment=='EDIT'" class="headline">Editar Pagamento</span>
    </v-card-title>

    <v-card-text>
        <v-container grid-list-md>
            <v-layout wrap>
            <v-flex xs12 sm4 md6>
              <v-text-field
                required
                label="Valor *"
                v-model="value"
                :error-messages="errors.collect('value')"
                :rules="[rules.required, rules.decimal]"
                data-vv-name="value"
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm4 md6>
              <v-text-field
                label="Chave Pix"
                v-model="pix_key"
                :error-messages="errors.collect('pix_key')"
                data-vv-name="pix_key"
                outlined
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 md6>
              <v-select
                label="Tipo *"
                :items="possible_payments"
                v-model="type"
                :error-messages="errors.collect('payment_type')"
                item-text="description"
                item-value="id"
                :rules="[rules.requiredAcceptZero]"
                data-vv-name="possible_payments"
                outlined
              ></v-select>
            </v-flex>

            <v-flex xs12 sm6 md6>
                <v-menu
                    v-model="showDate"
                    :close-on-content-click="true"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="date"
                        label="Data de Pagamento *"
                        outlined=""
                        readonly
                        append-icon="mdi-close"
                        @click:append="clearDate()"
                        persistent-hint
                        :error-messages="errors.collect('date')"
                        :rules="[rules.required]"
                        data-vv-name="date"
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                </template>
                <v-date-picker locale="pt-br" v-model="date" no-title @input="(e) => setDate(e)"></v-date-picker>
                </v-menu>
            </v-flex>

            </v-layout>
        </v-container>
    </v-card-text>

    <v-card-actions>
        <v-col>
        <v-btn text @click="closeDialogPayment">Cancelar</v-btn>
        </v-col>
        <v-col class="text-right">
        <v-btn v-if="modDialogPayment=='ADD'"
            text
            type="submit"
            right
            @click="savePayment"
            >
              Salvar
          </v-btn>
        <v-btn v-if="modDialogPayment=='EDIT'"
            text
            type="submit"
            right
            @click="editPayment"
            >
              Editar
          </v-btn>
        </v-col>
    </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "LeagueClosureFormPayment",
  methods: {
    ...mapActions("league_closure_club", ["savePayment", "closeDialogPayment", "editPayment"]),
    formatDate(date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`
    },
    clearDate() {
      this.date = ''
      this.finish_formatted = ''
    },
    setDate(date) {
      this.date = date
      this.showDate = false
      this.finish_formatted = this.formatDate(date)
    },
  },
  computed: {
    ...mapState("league_closure_club", ["newPayment", "payment_type", "possible_payments", "modDialogPayment"]),
    ...mapFields("league_closure_club", [
      "newPayment.type",
      "newPayment.value",
      "newPayment.date",
      "newPayment.pix_key",
    ]),
  },
  data: () => ({
    showDate: false,
    valid: false,
    rules: {
      required(value) { 
        return !!value || 'Campo Obrigatório'
      },
      requiredAcceptZero(value) { 
        return !!value || value === 0 || 'Campo Obrigatório'
      },
      decimal(value) {
        let regex = /^[-]?\d*(\.\d+)?$/
        return regex.test(value) || 'Valor deve ser númerico'
      },
    }
  }),
};
</script>