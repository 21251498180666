<template>
  <div>
    <div>

      <v-card>
        <v-card-title>
          <span class="headline">Venda</span>
          <v-spacer></v-spacer>
          <v-progress-circular
            v-if="loading"
            indeterminate
            class="text-right"
            color="primary"
            size="24"
          ></v-progress-circular>
          <v-expansion-panels v-if="screen == 'messages'">
            <v-expansion-panel>
              <v-expansion-panel-header class="message-with-header" >Mensagem De: {{ contact }} | {{instance_name}}</v-expansion-panel-header>
              <v-expansion-panel-content class="message-with-content">
                <div class="message-withdrawal" v-html="messageTreated">
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

        </v-card-title>

        <v-card-text>
          <v-expansion-panels :value="0">
            <v-expansion-panel :value="true">
              <v-expansion-panel-header>Venda</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-container grid-list-md>
                    <v-layout wrap>
                      <v-flex xs12 sm4 md4>
                        <v-text-field
                          required
                          label="Nome *"
                          v-model="name"
                          :disabled="this.mode == 'EDIT'"
                          :readonly="this.mode == 'VIEW'"
                          :error-messages="errors.collect('name')"
                          v-validate="'required:Nome,name|max_length:100'"
                          data-vv-name="name"
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm4 md4>
                        <v-text-field
                          required
                          label="CEP *"
                          v-model="address_cep"
                          :readonly="this.mode == 'VIEW'"
                          :error-messages="errors.collect('address_cep')"
                          v-validate="'decimal'"
                          data-vv-name="address_cep"
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm4 md4>
                        <v-text-field
                          required
                          label="Criado em"
                          v-model="date_created"
                          :disabled="true"
                          :readonly="this.mode == 'VIEW'"
                          :error-messages="errors.collect('date_created')"
                          v-validate="'max_length:100'"
                          data-vv-name="date_created"
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm4 md4>
                        <v-text-field
                          required
                          label="Estado"
                          v-model="address_state"
                          :disabled="true"
                          :readonly="this.mode == 'VIEW'"
                          :error-messages="errors.collect('address_state')"
                          v-validate="'max_length:2'"
                          data-vv-name="address_state"
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm4 md4>
                        <v-text-field
                          required
                          label="Cidade"
                          v-model="address_city"
                          :disabled="true"
                          :readonly="this.mode == 'VIEW'"
                          :error-messages="errors.collect('address_city')"
                          v-validate="'max_length:50'"
                          data-vv-name="address_city"
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm4 md4>
                        <v-text-field
                          required
                          label="Bairro"
                          v-model="address_neighborhood"
                          :disabled="true"
                          :readonly="this.mode == 'VIEW'"
                          :error-messages="errors.collect('address_neighborhood')"
                          v-validate="'max_length:100'"
                          data-vv-name="address_neighborhood"
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm5 md5>
                        <v-text-field
                          required
                          label="Endereço"
                          v-model="address"
                          :disabled="true"
                          :readonly="this.mode == 'VIEW'"
                          :error-messages="errors.collect('address')"
                          v-validate="'max_length:100'"
                          data-vv-name="address"
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm2 md2>
                        <v-text-field
                          required
                          label="Numero"
                          v-model="address_number"
                          :readonly="this.mode == 'VIEW'"
                          :error-messages="errors.collect('address_number')"
                          v-validate="'max_length:10'"
                          data-vv-name="address_number"
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm5 md5>
                        <v-text-field
                          required
                          label="Complemento"
                          v-model="address_complement"
                          :readonly="this.mode == 'VIEW'"
                          :error-messages="errors.collect('address_complement')"
                          v-validate="'max_length:50'"
                          data-vv-name="address_complement"
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm4 md4>
                        <v-text-field
                          required
                          label="Código de Rastreio"
                          v-model="tracking_code"
                          :readonly="this.mode == 'VIEW'"
                          :error-messages="errors.collect('tracking_code')"
                          v-validate="'max_length:20'"
                          data-vv-name="tracking_code"
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm4 md4>
                        <v-select
                            clear-icon
                            label="Status *"
                            :items="possible_status"
                            v-model="status"
                            :readonly="this.mode == 'VIEW'"
                            :error-messages="errors.collect('status')"
                            :v-validate="'required:Status,status'"
                            item-text="description"
                            item-value="id"
                            data-vv-name="status"
                            outlined
                        >
                        </v-select>
                      </v-flex>
                      <v-flex xs12 sm4 md4>
                        <v-text-field
                          required
                          label="Valor Total"
                          :readonly="true"
                          outlined
                          :value="total"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm12 md12>
                        <v-file-input
                          v-model="receipt_file"
                          v-if="this.mode == 'ADD'"
                          label="Comprovante"
                          outlined
                        ></v-file-input>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-form>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>Produtos</v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-btn v-if="status == 0 && (mode == 'ADD' || mode == 'EDIT')" color="black" dark class="add-align-right" @click="addProduct" small>
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
                <v-data-table
                  :headers="headers_product"
                  :items="products"      
                  disable-pagination
                  hide-default-footer    
                  :item-class= "getClassColor"    
                  no-data-text="Nenhum item encontrado"            
                >
                  <template v-slot:item.product_name="props">
                    <v-flex xs12 sm12 md12>
                      <v-autocomplete
                          v-model="props.item.stock_id"
                          :readonly="mode != 'ADD' && mode != 'EDIT'"
                          v-if="mode == 'ADD' || (mode == 'EDIT' && !props.item.id)"
                          :items="stock_products"
                          chips
                          :cache-items="true"
                          small-chips
                          no-data-text="Nenhum produto encontrado"
                          :item-text="getText"
                          item-value="id"
                          placeholder="Digite aqui o nome do produto"
                          @change="(event) => changeProduct(event, products.indexOf(props.item))"
                          :search-input.sync="searchProduct"
                          return-object
                      ></v-autocomplete>
                      <span v-else>{{ props.item.name }}</span>
                    </v-flex>
                  </template>
                  <template v-slot:item.size="props">
                   <span>{{ props.item.size }}</span>
                  </template> 
                  <template v-slot:item.value="props">
                    <v-text-field
                      required
                      reverse
                      v-if="mode == 'ADD' || (mode == 'EDIT' && props.item.edited == 1)"
                      prepend-inner-icon="mdi-currency-usd"
                      v-model="props.item.value"
                      :readonly="mode == 'VIEW'  && profileAccess()"
                    ></v-text-field>
                    <span v-else>{{ props.item.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
                  </template>
                  <template v-slot:item.quantity="props">
                    <v-text-field
                      v-if="mode == 'ADD' || (mode == 'EDIT' && props.item.edited == 1)"
                      required
                      class="text-right-input"
                      v-model="props.item.quantity"
                      :readonly="mode == 'VIEW'"
                      reverse
                    ></v-text-field>
                    <span v-else>{{ props.item.quantity }}</span>
                  </template>      
                  <template v-slot:item.stock_quantity="props">
                    <span>{{ props.item.stock_quantity }}</span>
                  </template>  
                  <template v-slot:item.shipment="props">
                    <v-text-field
                      v-if="mode == 'ADD' || (mode == 'EDIT' && props.item.edited == 1)"
                      required
                      v-model="props.item.shipment"
                      prepend-inner-icon="mdi-currency-usd"
                      :readonly="mode == 'VIEW'  && profileAccess()"
                      reverse
                    ></v-text-field>
                   <span v-else>{{ props.item.shipment.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
                  </template>   
                  <template v-slot:item.actions="props">
                    <v-icon v-if="mode == 'ADD' || (mode == 'EDIT' && !props.item.id)" small class="mr-2" @click="removeItem(products.indexOf(props.item))">mdi-close</v-icon>
                    <v-icon v-if="mode == 'EDIT' && props.item.id && props.item.edited == 1" small class="mr-2" @click="cancelEdit(props.item, products.indexOf(props.item))">mdi-close</v-icon>
                    <v-icon v-if="mode == 'EDIT' && (props.item.edited == 0)" small class="mr-2" @click="editItem(props.item, products.indexOf(props.item))">mdi-pencil</v-icon>
                    <v-icon v-if="mode == 'EDIT' && (!props.item.id || props.item.edited == 1)" small class="mr-2" @click="saveItem(props.item)">mdi-check</v-icon>
                    <v-icon v-if="mode == 'EDIT' && props.item.id && props.item.edited == 0" small class="mr-2"  @click="showDeleteDialog({item: props.item, type: 1})">mdi-delete</v-icon>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>

        <v-card-actions>
          <v-col>
            <v-btn text @click="closeDialog({screen: screen})">{{ mode == 'VIEW' ? 'Voltar' : 'Cancelar'}}</v-btn>
          </v-col>
          <v-col class="text-center" v-if="mode != 'VIEW'">
            <v-btn v-if="mode == 'ADD' || (mode == 'EDIT' && hasEdited)" dark @click="calcShipments">Calcular Frete</v-btn>
          </v-col>
          <v-col class="text-right" v-if="mode != 'VIEW'">
            <v-btn text type="submit" right @click="saveSale">Salvar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import debounce from '../../../util/debounce.js' 

export default {
  name: "SalesForm",
  computed: {
    ...mapState("login", ["userData"]),
    ...mapState("sales", [
      "contact",
      "instance_name",
      "loading",
      "mode",
      "message",
      "stock_products"
    ]),
    ...mapFields("sales", [
        "backup_items",
        "editedItem.id",
        "editedItem.name",
        "editedItem.address_state",
        "editedItem.address_city",
        "editedItem.address_cep",
        "editedItem.address_neighborhood",
        "editedItem.address",
        "editedItem.address_number",
        "editedItem.address_complement",
        "editedItem.status",
        "editedItem.tracking_code",
        "editedItem.date_created",
        "products",
        "editedItem.receipt_file",
        "editedItem.stock_quantity",
      // ...
    ]),
    messageTreated: function() {
      if(this.message) {
        var messageTreated = 
        this.message
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#039;')
        .replace(/\n/g, '<br>')
        .replace(/\*([^]+?)\*/g, "<b>$1</b>")
        return messageTreated
      } else {
        return ''
      }
    },
    hasEdited() {
      var hasEdited = false
      this.products.forEach((p) => {
        if(p.edited == 1) hasEdited = true
      })
      return hasEdited
    },
    total() {
      var total = 0
      this.products.forEach((product) => {
        total += parseInt(product.quantity) * (parseFloat(product.value) + parseFloat(product.shipment))
      })
      if(isNaN(total)) return 0
      return total
    }
  },
  created() {
    this.$validator.extend("required", {
      getMessage: (field, [label]) => label + " deve ser informado",
      validate: (_, [, field]) => {
        if (this[field] || this[field] === 0) {
          return true;
        } 
        else {
          return false;
        }
      },
    });
    this.$validator.extend("max_length", {
      getMessage: (_, [length]) => "No máximo " + length + " caracteres.",
      validate: (val, [length]) => {
        if (val.length > length) {
          return false;
        } else {
          return true;
        }
      },
    });
  },
  methods: {
    ...mapMutations("sales", ["showNotification"]),
    ...mapActions("sales", ["closeDialog", "calcShipments", "getStockProducts","save", "saveItemSale", "showDeleteDialog"]),
    addProduct() {
      let p = Object.assign({}, this.default_product)
      if(this.id) {
        p.sales_id = this.id  
      } else {
        p.sales_id = null
      }
      this.products.push(p)
    },
    changeProduct(e, index) {
      if(e.value) {
        this.products[index].stock_id = e.id
        this.products[index].value = e.value
        this.products[index].size = e.size
        this.products[index].stock_quantity = e.quantity
        this.products[index].name = e.name
      }
    },
    getClassColor(item) {
      if(item.quantity > item.stock_quantity) {
        return 'row-red'
      }
    },
    getText(item) {
      return item.name + " - " + item.size
    },
    profileAccess() {
      return (this.userData.role > 90) 
    },
    removeItem(index) {
      this.products.splice(index, 1)
    },
    cancelEdit(item, index) {
      item.value = this.backup_items[index].value
      item.shipment = this.backup_items[index].shipment
      item.quantity = this.backup_items[index].quantity
      item.edited = 0
    },
    editItem(item, index) {
      this.backup_items[index] = JSON.parse(JSON.stringify(item))
      item.edited = 1
    },
    saveItem(item) {
      item.sales_id = this.id
      item.value = parseFloat(item.value)
      item.quantity = parseInt(item.quantity)
      item.shipment = parseFloat(item.shipment)

      if(item.id) {
        this.saveItemSale({item: item, mode: 'EDIT'})
      } else {
  
        this.saveItemSale({item: item, mode: 'ADD'})
      }
    },
    saveSale() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          if(this.products.length == 0) {
            let notification = {
              show: true,
              message: "Pelo menos um produto deve ser informado",
              type: "error"
            }
            this.showNotification(notification)
          } else {
            this.products.map((p) => {
              p.value = parseFloat(p.value)
              p.quantity = parseInt(p.quantity)
              p.shipment = parseFloat(p.shipment)
            })
            this.save();
          }
        } 
      });
    },
  },
  mounted() {
    this.getStockProducts = this.debounce(this.getStockProducts, 500)
  },
  mixins: [debounce],
  data: () => ({
    valid: false,
    default_product: {
      sales_id: null,
      stock_id: '',
      edited: 1,
      quantity: 1,
      shipment: 0,
      name: '',
      stock_quantity: 0,
      value: ''
    },
    headers_product: [
      { text: "Produto", value: "product_name", sortable: false },
      { text: "Tamanho", value: "size", sortable: false },
      { text: "Quantidade", value: "quantity", sortable: false, align: 'right' },
      { text: "Estoque", value: "stock_quantity", sortable: false, align: 'right' },
      { text: "Valor", value: "value", sortable: false, align: 'right' },
      { text: "Frete", value: "shipment", sortable: false, align: 'right'},
      { text: "Ações", value: "actions", sortable: false, align: "center" },
    ],
    panel: [0],
    possible_status: [
      {id: 0, description: 'Pendente'},
      {id: 1, description: 'Em Separação'},
      {id: 2, description: 'Enviado'},
      {id: 3, description: 'Concluido'}
    ],
    searchProduct: ''
  }),
  props: [
    'screen',
  ],
  watch: {
    address_cep: {
      handler() {
        if(this.address_cep) {
          var r_cep = this.address_cep.replace("-")
          if(r_cep.length == 8) {
          // var cep_json = {}
            let url = "https://viacep.com.br/ws/" + r_cep + "/json/"

            var myHeaders = new Headers();
            var myInit = { 
              method: 'GET',
              headers: myHeaders,
              mode: 'cors',
              cache: 'default' 
            };

            const myRequest = new Request(url, myInit);
            fetch(myRequest).then(res => res.json())
            .then((cep) => {
              if(cep) {
                this.address_neighborhood = cep.bairro
                this.address = cep.logradouro
                this.address_state = cep.uf
                this.address_city = cep.localidade
              }
            }).catch((error) => {
              console.log(error);
            });
          }
        }
      }
    },
    searchProduct: {
      handler () {
        if(this.searchProduct != null) {
          this.getStockProducts({search: this.searchProduct})
        }
      }
    },
  }
};
</script>

<style scoped>
  .add-align-right {
    float: right !important;
  }

  .text-right-input {
    text-align: right !important;
  }
</style>
