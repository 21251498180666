import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../../config/endPoints';
import defaultItem from './default_deposit'
import defaultFilter from './default_deposit_filter'
import formatDate from '../../../util/formatDate.js'
import { defaultErrorNotification } from "../../../constants"
import { BANK_ANNOTATED, BANK_BONUS, DEPOSIT_PAYMENT_TYPE_ANNOTATED, DEPOSIT_PAYMENT_TYPE_BONUS, DEPOSIT_TYPE_BONUS, DEPOSIT_TYPE_SEND_PLAYER_CHIP } from './consts.js';
const buildTimeString = (avg) => {
    // calculate (and subtract) whole days
    var days = Math.floor(avg / 86400);
    avg -= days * 86400;

    // calculate (and subtract) whole hours
    var hours = Math.floor(avg / 3600) % 24;
    avg -= hours * 3600;
    if (hours.toString().length == 1) {
        hours = '0' + hours.toString()
    }

    // calculate (and subtract) whole minutes
    var minutes = Math.floor(avg / 60) % 60;
    avg -= minutes * 60;
    if (minutes.toString().length == 1) {
        minutes = '0' + minutes.toString()
    }

    // what's left is seconds
    var seconds = avg % 60;
    seconds = parseInt(seconds)
    if (seconds.toString().length == 1) {
        seconds = '0'.concat(seconds.toString())
    }

    return hours + ":" + minutes + ":" + seconds;
}


export default {
    namespaced: true,
    state: {
        block_refuse: false,
        avg_time: 0,
        banks: [],
        contact: '',
        contacts: [],
        contact_show: {},
        loadingSearchPix: false,
        dialog: false,
        dialogAbate: false,
        dialogContact: false,
        dialogDelete: false,
        dialogReorder: false,
        dialogDeposit: false,
        dialogFilter: false,
        dialogPrint: false,
        dialogReceipt: false,
        dialogAddReceipt: false,
        dialogApproveOperation: false,
        dialogPixRelated: false,
        dialogSearchPix: false,
        depositReceipt: {},
        dialogRefuseDeposit: false,
        depositRefused: {},
        editedItem: {},
        receiptItem: {},
        filter: {
            id: '',
            code: '',
            id_instance: '',
            bank: '',
            start: '',
            end: '',
            value: '',
            status: '',
            id_pppoker: '',
            nick: '',
            payment_type: '',
            type_send: '',
            type: '',
            validation: '',
            plataform: '',
            pix_identifier: '',
            endToEndId: ''
        },
        e2eValid: false,
        filtered: false,
        instance_name: '',
        instances: [],
        items: [],
        itemToDelete: {},
        loading: false,
        mode: 'LIST',
        message_deposit: '',
        print: '',
        overlay: false,
        page: 1,
        pagination: {
            page: 1,
            itemsPerPage: 10
        },
        paginationPix: {
            page: 1,
            itemsPerPage: 5
        },
        pix: {
            client_key: ''
        },
        pix_search: {
            date_start: '',
            date_end: '',
            bank_pix: 82,
        },
        successNotification: false,
        notificationMessage: '',
        notificationType: "success",
        receipt_show: '',
        receiptMimeType: 'pdf',
        selectedContact: {},
        showNotification: false,
        totalItems: 0,
        totalPixes: 0,
        pix_relateds: [],
        pix_found: [],
        total_value: 0,
        rowsPerPage: 10,
        users: [],
        block_click: false,
        showButtonConfirmation: false,
        showButtonIgnoreExists: false
    },
    mutations: {
        add: (state, payload) => {
            console.log("add",payload)
            state.dialog = true
            state.mode = "ADD"
            state.screen = payload.screen
            if (payload.deposit) {
                state.editedItem = payload.deposit
                state.message_deposit = payload.message
                
                state.contact = payload.contact
                state.instance_name = payload.instance_name
            } else {
                state.dialog = true
                state.editedItem = Object.assign({}, defaultItem)
                state.message = ""
                state.contact = ""
                state.instance_name = ""
            }
            console.log("add",state.editedItem)
        },
        edit: (state, payload) => {
            state.editedItem = Object.assign({}, payload.deposit)
            state.editedIndex = state.items.indexOf(payload.deposit)
            state.screen = payload.screen
            if (state.screen != "messages") {
                state.dialog = true
                state.content = ""
                state.contact = ""
                state.instance_name = ""
            } else {
                state.message = payload.message
                state.contact = payload.contact
                state.instance_name = payload.instance_name
            }
            if (payload.deposit.date) {
                payload.deposit.date_picker = new Date(payload.deposit.date)
            } else {
                payload.deposit.date_picker = ''
            }
            state.mode = "EDIT"
        },
        closeDialog: (state) => {
            console.log("closeDialog",state.editedItem)
            state.editedItem = {}
            console.log("closeDialog",state.editedItem)
            state.editedIndex = -1
            state.errors = [];
            state.dialog = false
            state.mode = "LIST"
        },
        loading: (state, payload) => state.loading = payload.show,
        setMode: (state, mode) => state.mode = mode,
        setItems: (state, items) => state.items = items,
        setItemToDelete: (state, item) => state.itemToDelete = item,
        setEditedItem: (state, item) => {
            state.editedItem = item
        },
        setE2EValid: (state, valid) => {
            state.e2eValid = valid
        },
        setReceiptItem: (state, item) => {
            state.receiptItem = Object.assign({}, { deposit_id: item.id, file_receipt: "" })
        },
        setTotalItems: (state, total) => state.totalItems = total,
        setRowsPerPage: (state, rowsPerPage) => state.rowsPerPage = rowsPerPage,
        showDialog: (state, show) => state.dialog = show,
        setShowButtonConfirmation: (state, show) => state.showButtonConfirmation = show,
        setShowButtonIgnoreExists: (state, show) => state.showButtonIgnoreExists = show,
        setPage: (state, payload) => {
            state.pagination.page = payload.page;
            state.pagination.itemsPerPage = payload.itemsPerPage
        },
        setBank: (state, payload) => {
            state.editedItem.bank = payload.bank
        },
        setBanks: (state, payload) => {
            state.banks = payload.banks
        },
        setBlockRefused: (state, payload) => state.block_refuse = payload.block,
        setContacts: (state, payload) => {
            state.contacts = payload.contacts
        },
        setPaymentType: (state, payload) => {
            state.editedItem.payment_type = payload.paymentType
        },
        setUsers: (state, payload) => {
            state.users = payload.users
        },
        setPrint: (state, payload) => {
            state.print = payload.print
            state.dialogPrint = true
        },
        showDialogPrint(state, payload) {
            state.dialogPrint = payload.show
        },
        showDialogAbate: (state, payload) => state.dialogAbate = payload.show,
        setAvgTime: (state, payload) => state.avg_time = payload.avg_time,
        setContactShow: (state, payload) => state.contact_show = payload.contact,
        setDialogContact: (state, payload) => state.dialogContact = payload.show,
        setDialogDelete: (state, show) => state.dialogDelete = show,
        setDialogReorder: (state, show) => state.dialogReorder = show,
        setDialogAddReceipt: (state, show) => state.dialogAddReceipt = show,
        setDialogReceipt: (state, payload) => state.dialogReceipt = payload.show,
        setDepositReceipt: (state, payload) => state.depositReceipt = payload.item,
        setInstances: (state, payload) => state.instances = payload.instances,

        setMasterAgents: (state, payload) => state.master_agents = payload.master_agents,
        setReceiptMimeType: (state, payload) => state.receiptMimeType = payload.receiptMimeType,
        setFilter: (state, filter) => {
            state.filter = filter
        },
        setTotalValue: (state, payload) => state.total_value = payload.total_value,
        setPixRelateds: (state, payload) => {
            state.totalPixes = payload.total,
                state.pix_relateds = payload.pixes
        },
        setFiltered: (state, filtered) => state.filtered = filtered,
        showDialogPixRelateds: (state, payload) => state.dialogPixRelated = payload.show,
        showDialogSearchPix: (state, payload) => {
            state.dialogSearchPix = payload.show
        },
        showDialogApproveOperation: (state, payload) => {
            state.dialogApproveOperation = payload.show
            state.editedItem = payload.item
        },
        setLoadingSearchPix: (state, payload) => {
            state.loadingSearchPix = payload.show
        },
        showFilter: (state, payload) => state.dialogFilter = payload.show,
        showErrorNotification: (state, show) => state.errorNotification = show,
        showSuccessNotification: (state, show) => state.successNotification = show,
        showLoading: (state, show) => state.loading = show,
        showNotification: (state, payload) => {
            state.showNotification = payload.show,
                state.notificationMessage = payload.message,
                state.notificationType = payload.type
        },
        overlay: (state, payload) => {
            state.overlay = payload.show
        },
        setDepositRefuse: (state, payload) => {
            state.depositRefused = payload
        },
        setBlockClick: (state, payload) => {
            state.block_click = payload
        },
        showDialogRefuseDeposit: (state, payload) => {
            state.dialogRefuseDeposit = payload.show
        },
        setPix: (state, payload) => state.pix = payload.pix,
        setScreen: (state, payload) => state.screen = payload.screen,
        setReceipt: (state, payload) => state.receipt_show = payload.receipt,
        viewItem(state, payload) {
            state.editedItem = payload.item
            state.screen = payload.screen
            state.editedItem.date_picker = new Date(payload.item.date)
            state.editedIndex = state.items.indexOf(payload.item)
            state.dialog = true
            state.mode = "VIEW"
        },
        updateField
    },
    getters: {
        getField,
    },
    actions: {
        addReceipt: async ({ state, dispatch, commit }) => {
            console.log(state.receiptItem)
            let url = endPoints.receipt;
            let data = state.receiptItem
            var file = data.file_receipt

            const toBase64 = file => new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });

            let base64_file = await toBase64(file)
            data.file = base64_file

            axios.post(url, data).then(() => {
                //notification
                dispatch('closeDialogAddReceipt')
                dispatch('getItems', { resetPage: true })
                let notification = {
                    show: true,
                    message: "O comprovante foi vinculado  ao depósito com sucesso!",
                    type: "success"
                }
                commit('showNotification', notification)


            }).catch((error) => {
                let notification = {
                    show: true,
                    message: (error.response.data.message ? error.response.data.message : "Ocorreu um erro ao vincular o comprovante ao depósito!"),
                    type: "error"
                }
                commit('showNotification', notification)
            })
        },
        searchPix: async ({ state, commit }) => {
            commit('setLoadingSearchPix', { show: true })
            commit('showDialogSearchPix', { show: false })
            const url = endPoints.pix + "/search?start=" + state.pix_search.date_start + "&end=" + state.pix_search.date_end + "&bank=" + state.pix_search.bank_pix
            try {
                await axios.get(url).then(response => {
                    commit('setLoadingSearchPix', { show: false })
                    let notification = {
                        show: true,
                        message: "Os Pix do período informado foram carregados com sucesso!",
                        type: "success"
                    }
                    console.log(response)
                    commit('showNotification', notification)
                })
            } catch (error) {
                commit('setLoadingSearchPix', { show: false })
                commit("showNotification", defaultErrorNotification(error?.response?.data?.message));
            }


        },
        clearFilter: ({ commit, dispatch }) => {
            commit('setFilter', Object.assign({}, defaultFilter))
            commit('setFiltered', false)
            commit('showFilter', { show: false })
            commit('overlay', { show: true })

            setTimeout(function () {
                commit('overlay', { show: false })
                dispatch('getItems', { resetPage: true })
            }, 1000);
        },
        closeDialog: (({ commit, state }) => {
            state.editedItem = {}
            if (state.screen != "messages") {
                commit('message/setSelectedMessages', { messages: [] }, { root: true })
                commit('showDialog', false)
            } else {
                commit('message/showDialogDeposit', { show: false }, { root: true })
            }
            setTimeout(() => {
                commit('closeDialog')
                commit('showErrorNotification', false)
                commit('showSuccessNotification', false)
            }, 300);
        }),
        closeDeleteDialog: ({ commit }) => {
            commit('setDialogDelete', false)
            commit('setItemToDelete', {})
        },
        closeReorderDialog: ({ commit }) => {
            commit('setDialogReorder', false)
            commit('setEditedItem', {})
        },
        showDialogReorder: ({ commit }, payload) => {
            commit('setDialogReorder', true)
            commit('setEditedItem', payload.deposit)
        },
        showDialogAddReceipt: ({ commit }, payload) => {
            commit('setDialogAddReceipt', true)
            commit('setReceiptItem', payload.item)
        },
        closeDialogAddReceipt: ({ commit }) => {
            commit('setDialogAddReceipt', false)
            commit('setReceiptItem', {})
        },
        reorder: ({ state, commit, dispatch }) => {
            const url = endPoints.deposits + "/reorder";
            axios.post(url, state.editedItem).then(() => {
                let notification = {
                    show: true,
                    message: "Ordem reenviada com sucesso",
                    type: "success"
                }
                dispatch('getItems', { resetPage: true })
                dispatch('closeReorderDialog')
                commit('showNotification', notification)
            }).catch((error) => {
                let notification = {
                    show: true,
                    message: error.response.data.message,
                    type: "error"
                }
                commit('showNotification', notification)
            })
        },
        deleteItem: ({ dispatch, commit, state }) => {
            commit('loading', { show: true })
            axios.delete(endPoints.deposits + "/" + state.itemToDelete.id, state.itemToDelete).then(() => {
                dispatch('closeDeleteDialog')
                commit('loading', false)
                let notification = {
                    show: true,
                    message: "Depósito excluido com sucesso",
                    type: "success"
                }
                commit('showNotification', notification)
                commit('loading', { show: false })
                if (state.screen != 'messages') {
                    dispatch('getItems', { resetPage: false });
                }
            }).catch(() => {
                commit('loading', { show: false })
                let notification = {
                    show: true,
                    message: "Ocorreu um erro ao excluir o depósito.",
                    type: "error"
                }
                commit('showNotification', notification)
            });
        },
        linkDeposit: ({ state, dispatch, commit }, payload) => {
            let url = endPoints.deposits + "/link_deposit";
            let data = {
                id: state.editedItem.id,
                pix_id: payload.item.id
            }
            axios.post(url, data).then(() => {
                let notification = {
                    show: true,
                    message: "Depósito vinculado com sucesso",
                    type: "success"
                }
                dispatch('getItems', { resetPage: true })
                commit('showDialogPixRelateds', { show: false })
                commit('showNotification', notification)
            }).catch((error) => {
                let notification = {
                    show: true,
                    message: error.response.data.message,
                    type: "error"
                }
                commit('showNotification', notification)
            })
        },
        linkDepositFinished: ({ state, dispatch, commit }, payload) => {
            let url = endPoints.deposits + "/link_deposit_finished";
            let data = {
                id: state.editedItem.id,
                pix_id: payload.item.id
            }
            axios.post(url, data).then(() => {
                let notification = {
                    show: true,
                    message: "Depósito vinculado com sucesso",
                    type: "success"
                }
                dispatch('getItems', { resetPage: true })
                commit('showDialogPixRelateds', { show: false })
                commit('showNotification', notification)
            }).catch((error) => {
                let notification = {
                    show: true,
                    message: error.response.data.message,
                    type: "error"
                }
                commit('showNotification', notification)
            })
        },
        getItems: ({ commit, state }, payload) => {
            commit('loading', { show: true })
            let url = endPoints.deposits;
            let filter = state.filter;

            if (payload.resetPage === true) {
                url += '?page=' + 1 + '&' + 'itemsPerPage=' + 10;
                commit('setPage', { page: 1, itemsPerPage: 10 })
            } else {
                url += '?page=' + state.pagination.page + '&' + 'itemsPerPage=' + state.pagination.itemsPerPage;
            }

            commit('setFiltered', false)
            Object.keys(filter).forEach(function (field) {
                if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
                    commit('setFiltered', true)

                    if (field == "end" || field == "start") {
                        var e = new Date(filter[field])
                        url += "&" + field + "=" + formatDate(e) + " " + e.toLocaleTimeString()
                    } else {
                        url += "&" + field + "=" + filter[field]
                    }
                }
            });
            if (state.pagination && state.pagination.sortBy && state.pagination.sortBy.length > 0) {
                url += "&sort_by=" + state.pagination.sortBy[0]
                url += "&sort_desc=" + state.pagination.sortDesc[0]
            }
            axios.get(url).then(items => {
                let data = items.data
                let avg = buildTimeString(data.average)
                commit('setAvgTime', { avg_time: avg })
                commit('setTotalItems', data.total)
                commit('loading', { show: false })
                commit('setItems', data.data)
                commit('setTotalValue', { total_value: data.total_value })
            });
        },
        getItemsNoPage: ({ commit, state }) => {
            commit('overlay', { show: true })
            let url = endPoints.deposits;
            let filter = state.filter;
            url += '?page=' + '1' + '&' + 'itemsPerPage=' + '99999';

            Object.keys(filter).forEach(function (field) {
                if (filter[field] !== "" && filter[field] !== undefined) {
                    if (field == "end" || field == "start") {
                        var e = new Date(filter[field])

                        url += "&" + field + "=" + e.toISOString().substr(0, 10) + " " + e.toLocaleTimeString()

                    } else {
                        url += "&" + field + "=" + filter[field]
                    }
                }
            });
            return axios.get(url).then(items => {
                commit('overlay', { show: false })
                return items.data.data
            }).catch(() => {
                commit('overlay', { show: false })
            });
        },
        getUsers: ({ commit }, payload) => {
            let url = endPoints.users;
            url += '/attendants/' + payload.search

            axios.get(url).then(users => {
                commit('setUsers', { users: users.data })
            });
        },
        getRelatedPixes: async ({ commit, state }, payload) => {
            if (!payload.deposit.pix_identifier) {
                let notification = {
                    show: true,
                    message: "CPF/CNPJ inválido ou não informado",
                    type: "success"
                }
                commit('showNotification', notification)
                return
            }
            let doc = payload.deposit.pix_identifier.replace(/\D/g, '')
            let url = endPoints.pix24h;
            // start = deposit.date_created - 1 day
            let date = new Date(payload.deposit.date_created)
            date.setDate(date.getDate() - 10)
            let start = date.toISOString().substr(0, 10) + " " + date.toLocaleTimeString()
            // end = deposit.date_created + 1 day
            date = new Date(payload.deposit.date_created)
            date.setDate(date.getDate() + 1)
            let end = date.toISOString().substr(0, 10) + " " + date.toLocaleTimeString()
            url += '?page=' + state.paginationPix.page + '&' + 'itemsPerPage=' + 15;

            url += `&value=${payload.deposit.value}&used=false&type=1&client_key=${doc}&start=${start}&end=${end}`


            try {
                let urlReceipt = endPoints.receipt;
                urlReceipt += '/' + payload.deposit.id + '?type=2'
                let receipt = await axios.get(urlReceipt)
                let file = receipt.data.file
                let mimetype = file.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
                mimetype = mimetype.replace("application/", "").replace("image/", "")
                commit('setReceiptMimeType', { receiptMimeType: mimetype })
                commit('setReceipt', { receipt: receipt.data.file })
            } catch (err) {
                console.log(err)
                commit('setReceipt', { receipt: null })
            }

            axios.get(url).then(pixes => {
                if (pixes) {

                    state.editedItem = Object.assign({}, payload.deposit)
                    commit('setPixRelateds', { pixes: pixes.data.data, total: pixes.data.total })
                    commit('showDialogPixRelateds', { show: true })
                } else {
                    commit('setPixRelateds', { pixes: [] })
                    commit('showDialogPixRelateds', { show: false })
                }
            });
        },
        generateBonus: ({ commit, dispatch }, payload) => {
            var url = endPoints.deposits + "/bonus"
            let data = { id: payload.deposit.id }
            axios.post(url, data).then(() => {
                let notification = {
                    show: true,
                    message: "Bônus gerado com sucesso",
                    type: "success"
                }
                dispatch('getItems', { resetPage: true })
                commit('showNotification', notification)
            }).catch((error) => {
                let notification = {
                    show: true,
                    message: error.response.data.message,
                    type: "error"
                }
                commit('showNotification', notification)
            })
        },
        refuseDeposit: ({ commit, dispatch, state }, payload) => {
            state.editedItem = Object.assign({}, state.depositRefused)
            state.editedIndex = state.items.indexOf(state.depositRefused)
            state.editedItem.status = 9
            state.mode = "EDIT"
            state.screen = payload.screen
            commit('showDialogRefuseDeposit', { show: false })
            dispatch('save', { close: false })
        },
        refuseDepositDialog: ({ commit }, payload) => {
            commit('setDepositRefuse', Object.assign({}, payload.deposit))
            commit('showDialogRefuseDeposit', { show: true })
        },
        showContactDialog: ({ commit }, payload) => {
            var url = endPoints.httpchats + '/contact/' + payload.deposit.contact_id
            axios.get(url).then((response) => {
                if (response && response.data) {
                    commit('setContactShow', { contact: response.data })
                    commit('setDialogContact', { show: true })
                } else {
                    let notification = {
                        show: true,
                        message: "Ocorreu um erro ao buscar o contato!",
                        type: "error"
                    }
                    commit('showNotification', notification)
                }
            }).catch(() => {
                let notification = {
                    show: true,
                    message: "Ocorreu um erro ao buscar o contato!",
                    type: "error"
                }
                commit('showNotification', notification)
            })
        },
        showDeleteDialog: ({ commit }, item) => {
            commit('setDialogDelete', true)
            commit('setItemToDelete', item)
        },
        startDeposit: ({ dispatch, state }, payload) => {
            state.editedItem = Object.assign({}, payload.deposit)
            state.editedIndex = state.items.indexOf(payload.deposit)
            state.editedItem.status = 1
            state.screen = payload.screen
            state.mode = "EDIT"
            dispatch('save', { close: false })
        },
        scheduleDeposit: ({ dispatch, state }, payload) => {
            state.editedItem = Object.assign({}, payload.deposit)
            state.editedIndex = state.items.indexOf(payload.deposit)
            state.editedItem.status = 5
            state.screen = payload.screen
            state.mode = "EDIT"
            dispatch('save', { close: false })
        },
        refuseDepositDuplicate: ({ dispatch, state }, payload) => {
            state.editedItem = Object.assign({}, payload.deposit)
            state.editedIndex = state.items.indexOf(payload.deposit)
            if(state.editedItem.status != 10) {
                state.editedItem.type_send = 6
            }
            state.editedItem.status = 7
            state.screen = payload.screen
            state.mode = "EDIT"
            dispatch('save', { close: false })

        },
        stopDeposit: ({ dispatch, state }, payload) => {
            state.editedItem = Object.assign({}, payload.deposit)
            state.editedIndex = state.items.indexOf(payload.deposit)
            state.editedItem.status = 0
            state.mode = "EDIT"
            state.screen = payload.screen
            dispatch('save', { close: false })
        },
        validateDeposit: ({ dispatch, state }, payload) => {
            state.editedItem = Object.assign({}, payload.deposit)
            state.editedIndex = state.items.indexOf(payload.deposit)
            state.editedItem.status = 6
            state.mode = "EDIT"
            state.screen = payload.screen
            dispatch('save', { close: false })
        },
        finishDeposit: ({ dispatch, state }, payload) => {
            state.editedItem = Object.assign({}, payload.deposit)
            state.editedIndex = state.items.indexOf(payload.deposit)
            state.editedItem.status = 2
            state.mode = "EDIT"
            state.screen = payload.screen
            dispatch('save', { close: false })
        },
        viewItem: ({ commit }, payload) => {
            if (payload.item.pix_id) {
                axios.get(endPoints.pix + "/" + payload.item.pix_id).then((response) => {
                    let pix = response.data
                    commit('setPix', { pix: pix })
                    commit('viewItem', payload)
                }).catch(() => {
                    commit('viewItem', payload)
                })
            } else {
                commit('viewItem', payload)
            }
        },
        checkIsGainsAndLosses: async ({ state, commit, dispatch }) => {
            var url = endPoints.clients_drx + "/check/gains_and_losses/" + state.editedItem.contact_id;

            axios.get(url)
                .then(response => {
                    if (response.data == true) {
                        dispatch('save', { close: false })
                    } else {
                        let notification = {
                            show: true,
                            message: "Tipo de fechamento do contato diferente de Ganhos/Perdas. Clique em confirmar caso deseje prosseguir com a criação",
                            notificationTime: 5000,
                            type: "error"
                        }
                        commit('showNotification', notification)
                        commit('setShowButtonConfirmation', true)

                    }
                })
                .catch(error => {
                    console.error("Erro ao verificar ganhos e perdas", error);
                    let notification = {
                        show: true,
                        message: "Erro ao verificar tipo de fechamento do contato",
                        notificationTime: 5000,
                        type: "error"
                    }
                    commit('showNotification', notification)
                    dispatch('save', { close: false })
                });

        },
        getEndToEnd: async ({ commit, state }, payload) => {
            console.log(payload.receipt)
            var file = payload.receipt

            const toBase64 = file => new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
            let notification = {}
            let base64_file = await toBase64(file)

            var url = endPoints.receipt + "/e2e";
            let data = {
                receipt: base64_file
            }
            try {
                let response = await axios.post(url, data)
                console.log("response.data", response.data)
                state.editedItem.e2e_identified = response.data.e2e
                console.log("response.data", state.editedItem.e2e_identified)
                if (!response.data.found) {
                    notification = {
                        show: true,
                        message: "Pix não encontrado para esse identificador",
                        notificationTime: 7000,
                        type: "error"
                    }
                    commit('setE2EValid', false)
                } else if (!response.data.available) {
                    notification = {
                        show: true,
                        message: "Identificador já utilizado. Possível Golpe!",
                        notificationTime: 7000,
                        type: "error"
                    }
                    commit('setE2EValid', false)

                } else {
                    notification = {
                        show: true,
                        message: "Identificador válido identificado",
                        notificationTime: 7000,
                        type: "success"
                    }
                    commit('setE2EValid', true)
                    state.editedItem.endToEndId = response.data.e2e
                    state.editedItem.value = response.data.value ? response.data.value : state.editedItem.value
                    state.editedItem.pix_identifier = response.data.inscription ? response.data.inscription : state.editedItem.pix_identifier
                }
                commit('message/showDialogDeposit', { show: true }, { root: true })
                commit('message/showNotification', notification, { root: true })
                return response.data
            } catch (err) {
                notification = {
                    show: true,
                    message: "Nenhum identificador encontrado. Preencha manualmente",
                    notificationTime: 7000,
                    type: "error"
                }
                commit('setE2EValid', false)
                commit('message/showNotification', notification, { root: true })
                commit('message/showDialogDeposit', { show: true }, { root: true })
                console.log(err)
            }
        },
        validateEndToEndId: async ({ commit, state }, payload) => {
            var url = endPoints.pix + "/check-identifier/" + payload;
            let notification = {}
            try {
                let response = await axios.get(url)
                console.log(response.data)
                if (!response.data.found) {
                    notification = {
                        show: true,
                        message: "Pix não encontrado para esse identificador",
                        notificationTime: 7000,
                        type: "error"
                    }
                    commit('setE2EValid', false)
                } else {
                    if (!response.data.available) {
                        notification = {
                            show: true,
                            message: "Identificador já utilizado. Possível Golpe!",
                            notificationTime: 7000,
                            type: "error"
                        }
                        commit('setE2EValid', false)
                    } else {
                        notification = {
                            show: true,
                            message: "Identificador válido",
                            notificationTime: 7000,
                            type: "success"
                        }
                        state.editedItem.value = response.data.value ? response.data.value : state.editedItem.value
                        state.editedItem.pix_identifier = response.data.inscription ? response.data.inscription : state.editedItem.pix_identifier
                        commit('setE2EValid', true)
                    }
                }
                if (state.screen == 'messages') {
                    commit('message/showNotification', notification, { root: true })
                } else {
                    commit('showNotification', notification)
                }
            } catch (err) {
                notification = {
                    show: true,
                    message: "Erro ao validar Identificador",
                    notificationTime: 7000,
                    type: "error"

                }
                commit('setE2EValid', false)
                if (state.screen == 'messages') {
                    commit('message/showNotification', notification, { root: true })
                } else {
                    commit('showNotification', notification)
                }
                console.log(err)
            }
        },
        save: async ({ dispatch, commit, rootGetters, state }, payload) => {
            var url = endPoints.deposits;
            let func = '';
            let data = state.editedItem
            data.nick = data.nick ? data.nick : ''

            if (state.editedItem.payment_type == 3 && !state.e2eValid && state.mode == 'ADD') {
                let notification = {
                    show: true,
                    message: "Identificador Inválido",
                    notificationTime: 5000,
                    type: "error"
                }
                if (state.screen == 'messages') {
                    commit('message/showNotification', notification, { root: true })
                } else {
                    commit('showNotification', notification)
                }
                return
            }

            if (payload.check_exists == false) {
                data.check_exists = payload.check_exists
            }
            if (payload.origin == "RAKEDEPOSIT") {
                state.mode = 'ADD'
                data = payload.deposit
            }
            switch (data.payment_type) {
                case DEPOSIT_PAYMENT_TYPE_BONUS:
                    data.bank = BANK_BONUS
                    break;
                case DEPOSIT_PAYMENT_TYPE_ANNOTATED:
                    data.bank = BANK_ANNOTATED
                    break;
            }
            if (data.type != DEPOSIT_TYPE_SEND_PLAYER_CHIP && data.type != DEPOSIT_TYPE_BONUS) {
                data.id_pppoker = ''
                data.id_instance = null
            }

            commit('loading', { show: true })
            commit('overlay', { show: true })
            if (state.screen == 'messages') {
                let chat = rootGetters["chat/openedChat"]
                if (data.payment_type == 1 && chat.block_annotated && chat.block_annotated == true) {
                    let notification = {
                        show: true,
                        message: "Criação de anotados bloqueada para esse contato.",
                        notificationTime: 3000,
                        type: "error"
                    }
                    commit('message/showNotification', notification, { root: true })
                    commit('loading', { show: false })
                    return
                }
            }

            if (state.mode == 'ADD') {
                func = axios.post;
                url = endPoints.deposits;
                if (data.receipt_file.name && payload.origin == "DEPOSIT") {
                    var file = data.receipt_file

                    const toBase64 = file => new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => resolve(reader.result);
                        reader.onerror = error => reject(error);
                    });

                    let base64_file = await toBase64(file)
                    data.receipt = base64_file
                }
            } else {
                func = axios.put;
                url += '/' + data.id;
            }

            func(url, data).then(
                () => {
                    commit('showDialogApproveOperation', { show: false })
                    commit('setBlockClick', false)
                    commit('setShowButtonConfirmation', false)
                    let notification = {
                        show: true,
                        message: "Depósito salvo com sucesso.",
                        notificationTime: 3000,
                        type: "success"
                    }
                    commit('loading', { show: false })
                    commit('overlay', { show: false })
                    if (state.screen != 'messages') {
                        commit('message/setSelectedMessages', { messages: [] }, { root: true })
                        dispatch('getItems', { resetPage: true })
                        commit('showNotification', notification)
                        commit('closeDialog')
                    } else {
                        commit('closeDialog')
                        commit('message/showDialogDeposit', { show: false }, { root: true })
                        commit('message/showNotification', notification, { root: true })
                    }
                    if (data.type == 2 && data.status == 2) {
                        commit('showDialogAbate', { show: true })
                    }
                },
                error => {
                    commit('setBlockClick', false)
                    commit('setShowButtonConfirmation', false)
                    if (error.response.data.message == "Já existe um anotado igual criado nos últimos 5 minutos. Verifique e confirme a operação") {
                        commit('setShowButtonConfirmation', true)
                        commit('setShowButtonIgnoreExists', true)
                    }
                    if (state.screen != 'messages') {
                        let notification = {
                            show: true,
                            message: error.response.data.message,
                            type: "error",
                            notificationTime: 5000,
                        }
                        commit('message/setSelectedMessages', { messages: [] }, { root: true })
                        commit('showNotification', notification)
                        commit('loading', { show: false })
                        commit('overlay', { show: false })
                    } else {
                        let payload = {
                            show: true,
                            message: error.response.data.message,
                            notificationTime: 5000,
                            type: "error"
                        }
                        commit('loading', { show: false })
                        commit('message/showNotification', payload, { root: true })
                    }
                }
            );
        },
        showReceipt: ({ commit }, payload) => {
            let url = endPoints.receipt;

            url += '/' + payload.item.id + '?type=2'
            commit('setDepositReceipt', { item: payload.item })
            commit('overlay', { show: true })
            axios.get(url).then(r => {
                let file = r.data.file
                let mimetype = file.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
                mimetype = mimetype.replace("application/", "").replace("image/", "")
                commit('setReceiptMimeType', { receiptMimeType: mimetype })
                commit('setReceipt', { receipt: r.data.file })
                commit('setDialogReceipt', { show: true })
                commit('overlay', { show: false })
            }).catch(() => {
                let notification = {
                    show: true,
                    message: "Comprovante não encontrado",
                    type: "error"
                }
                commit('showNotification', notification)
                commit('overlay', { show: false })
            });
        },
        downloadReceipts: ({ commit, state }) => {
            let url = endPoints.receipt;

            url += '?type=2'
            let filter = state.filter;

            Object.keys(filter).forEach(function (field) {
                if (filter[field] !== "" && filter[field] !== undefined) {
                    if (field == "end" || field == "start") {
                        var e = new Date(filter[field])

                        url += "&" + field + "=" + e.toISOString().substr(0, 10) + " " + e.toLocaleTimeString()
                    } else {
                        url += "&" + field + "=" + filter[field]
                    }
                }
            });

            commit('overlay', { show: true })
            axios.get(url).then((response) => {
                if (response.data) {
                    var downloadWindow = document.createElement("a");
                    downloadWindow.href = response.data
                    downloadWindow.download = "deposit.zip";
                    downloadWindow.click();
                }

                commit('loading', { show: false })
                commit('overlay', { show: false })
            });
        },
        findTransactionPrint: ({ commit }, payload) => {
            commit('loading', { show: true })
            const url = endPoints.orders + '/operation/' + payload.item.id + "?origin=" + 1;
            axios.get(url).then(print => {
                const img = 'data:image/jpeg;base64,' + print.data.transaction_print

                commit('setPrint', { print: img })
                commit('loading', { show: false })
            }).catch(() => {
                commit('loading', { show: false })
                let notification = {
                    show: true,
                    message: "Não foi possível encontrar o print da transação",
                    type: "error"
                }
                commit('showNotification', notification)
            });
        },
        approveOperation: ({ commit, dispatch, state }) => {
            try {
                axios.post(endPoints.deposits + "/approve_operation", state.editedItem).then(() => {
                    let notification = {
                        show: true,
                        message: "Operação aprovada com sucesso!",
                        type: "success"
                    }
                    commit('showNotification', notification)
                    commit('showDialogApproveOperation', { show: false })
                    dispatch('getItems', { resetPage: true })
                }).catch((error) => {
                    let notification = {
                        show: true,
                        message: error.response?.data?.message,
                        type: "error"
                    }
                    commit('showNotification', notification)
                })
            } catch (error) {
                console.log(error)
            }
        },
    }
}