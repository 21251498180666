<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    
    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2000"
      v-model="showNotification"
    >
        {{ notificationMessage }}
    </v-snackbar>
    <v-toolbar flat class="elevation-5">
      <div>
        <v-toolbar-title>Transferência de Fichas</v-toolbar-title>
      </div>
      <v-spacer></v-spacer>
      <div class="button-toolbar">
        <!-- <download-excel
          :before-generate="generateSheet"
          :before-finish="finishSheet"
          :fetch="getItemsNoPage"
          :name="getSheetName()"
          :fields="sheet_fields"
          class="excel"
          worksheet="Transferencia"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{on, attrs}">
              <v-btn color="black" dark class="ma-2" small v-on="on" v-bind="attrs">
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </template>
            <span>Baixar excel</span>
          </v-tooltip>
          
        </download-excel> -->
        <v-tooltip bottom>
          <template v-slot:activator="{on, attrs}">
            <v-btn color="black" dark class="ma-2" @click="downloadXlsx({ show: true })" v-bind="attrs" v-on="on" small>
              <v-icon>mdi-file-excel</v-icon>
            </v-btn>
          </template>
          <span>Gerar Planilha</span>
        </v-tooltip>
        <!-- <v-btn color="black" dark class="ma-2" @click="showDialogSpreadsheetTransfer" small><v-icon>mdi-microsoft-excel</v-icon></v-btn> -->
        <v-btn color="black" dark class="ma-2" @click="showFilter({show: true})" small><v-icon>mdi-filter</v-icon></v-btn>
        <v-btn color="black" dark class="ma-2" @click="getChipsTransfers" small><v-icon>mdi-refresh</v-icon></v-btn>
        <v-btn color="black" dark class="ma-2" @click="add" small><v-icon>mdi-plus</v-icon></v-btn>
      </div> 
    </v-toolbar>


    <v-data-table
      :headers="headers"
      :item-class= "getClassColor"
      :loading="loading"
      :items="chipsTransfers"
      :options.sync="pagination"
      :server-items-length="totalChipsTransfers"
      no-data-text="Nenhuma transferência encontrada"
      class="elevation-10 table-margin"
      :page.sync="page"
      @page-count="pageCount = $event"
    >
      <template v-slot:[`item.actions`]="{ item }">

        <v-tooltip bottom>
          <template v-slot:activator="{on, attrs}">
            <v-icon small v-on="on" v-bind="attrs" class="mr-2" @click="viewChipsTransfer(item)">mdi-information</v-icon>
          </template>
          <span>Informações</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{on, attrs}">        
            <v-icon small v-on="on" v-bind="attrs" class="mr-2" @click="edit({item: item})">mdi-pencil</v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>

        <v-tooltip bottom> 
          <template v-slot:activator="{on, attrs}">    
            <v-icon small v-on="on" v-bind="attrs" class="mr-2" @click="showDeleteDialog(item)">mdi-delete</v-icon>
          </template>
          <span>Apagar</span>
        </v-tooltip>

        <v-tooltip bottom v-if="item.status == 1">
          <template v-slot:activator="{on, attrs}">
            <v-icon small v-on="on" v-bind="attrs" class="mr-2" @click="finishTransferBot({transfer: item, screen: 'chipsTransfer'})">mdi-robot</v-icon>
          </template>
          <span>Deixar com o BOT</span>
        </v-tooltip>

        <v-tooltip bottom v-if="item.status == 1">
          <template v-slot:activator="{on, attrs}">
            <v-icon small v-on="on" v-bind="attrs" class="mr-2" @click="finishTransfer({transfer: item, screen: 'chipsTransfer'})">mdi-check</v-icon>
          </template>
          <span>Concluir Transferência</span>
        </v-tooltip>

        <v-tooltip bottom v-if="item.status == 2">
          <template v-slot:activator="{on, attrs}">
            <v-icon small v-on="on" v-bind="attrs" class="mr-2" @click="backTransfer({transfer: item, screen: 'chipsTransfer'})">mdi-close-circle-outline</v-icon>
          </template>
          <span>Transferência Não Realizada</span>
        </v-tooltip>      

        <v-tooltip v-if="(item.name_supreme == '' || item.name_supreme == null) || (item.id_supreme == '' || item.id_supreme == null)" bottom>
          <template v-slot:activator="{on, attrs}">
            <v-icon small v-on="on" v-bind="attrs" class="mr-2" @click="registerSupreme({item: item})">mdi-account-plus-outline</v-icon>
          </template>
          <span>Cadastro Suprema Poker</span>
        </v-tooltip>

      </template>

      <template v-slot:[`item.id_pppoker`]="{ item }">
        <v-chip
          color="amber accent-4"
          class="ma-2"
          label
          text-color="white"
        >
          {{( item.id_pppoker )}}
        </v-chip>
      </template>

      <template v-slot:[`item.chips`]="{ item }">
        <span>
          {{( item.chips.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })  )}}
        </span>
      </template>

      <template v-slot:[`item.id_supreme`]="{ item }">
        <v-chip
          v-if="item.id_supreme"
          color="light-blue darken-3"
          class="ma-2"
          label
          text-color="white"
        >
          {{( item.id_supreme )}}
        </v-chip>
        <v-icon 
          v-if="!item.id_supreme"
          color="red"
        >
          mdi-circle-off-outline
        </v-icon>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          :color="getColorButton(item)"
          label
          text-color="white"
        >
          {{( getStatus(item) )}}
        </v-chip>
      </template>

      <template v-slot:[`item.name_supreme`]="{ item }">
        <v-template
          v-if="item.name_supreme" 
        >
          {{( item.name_supreme )}}
        </v-template>
        <v-icon 
          v-if="!item.name_supreme"
          color="red"
        >
          mdi-circle-off-outline
        </v-icon>
      </template>

      <template v-slot:[`item.club_destiny`]="{ item }">
        <v-template
          v-if="item.club_destiny" 
        >
          {{( getClub(item.club_destiny) )}}
        </v-template>
        <v-icon 
          v-if="!item.club_destiny"
          color="red"
        >
          mdi-circle-off-outline
        </v-icon>
      </template>

    </v-data-table>

    <v-card
      class="d-flex justify-center mb-6 max-h"
    >
      <v-card
        class="max-h"
      >
        <v-text-field
          class="max-h"
          type="number"
          label="Página"
          v-model="page"
          data-vv-name="page"
          outlined
          dense
        ></v-text-field>
      </v-card>
    </v-card>

    <v-dialog :value="dialog" width="600" @click:outside="closeDialog">
      <chips-transfer-form></chips-transfer-form>
    </v-dialog>

    <v-dialog :value="dialogSupreme" width="600" @click:outside="closeDialogSupreme">
      <chips-transfer-supreme-form></chips-transfer-supreme-form>
    </v-dialog>

    <v-dialog :value="dialogFilter" width="600" @click:outside="showFilter({show: false})">
      <chips-transfer-filter></chips-transfer-filter>
    </v-dialog>

    <v-dialog :value="dialogDelete" persistent width="500">
      <v-card>
        <v-card-title class="headline">Confirmar a exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn
              text
              @click="closeDeleteDialog()"
            >Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn
              text
              type="submit"
              right
              @click="deleteChipsTransfer"
            >Confirmar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog :value="dialogSpreadsheetTransfer" @click:outside="setDialogSpreadsheetTransfer({show: false})" @keydown.enter="deleteItem" width="1280">
      <v-card>
        <v-expansion-panels :value="0">
          <v-expansion-panel>
            <v-expansion-panel-header>Nova Planilha</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card @drop.prevent="addDropFile" @dragover.prevent @paste="onPaste">
                <v-data-table
                  :headers="headers_config_spreadsheet"
                  :items="spreadsheet_headers_config_import"
                  disable-pagination
                  hide-default-footer
                >     
                    <template v-slot:[`item.column_spreadsheet`]="props">
                      <v-text-field
                        required
                        reverse
                        v-model="props.item.column_spreadsheet"
                      ></v-text-field>
                    </template> 
                    <template v-slot:[`item.type_field`]="{ item }">
                      <span>{{ getTypeHeaderSpreadSheet(item.type_field)}}</span>
                    </template>
                  </v-data-table>
                  <v-container grid-list-md>
                    <v-layout wrap>
                      <v-flex xs12 sm12 md4 xl4>
                        <v-select
                            label="Tipo de Importação *"
                            :items="possible_types_import"
                            v-model="type_import"
                            :readonly="this.mode == 'VIEW'"
                            item-text="description"
                            item-value="id"
                            data-vv-name="status"
                        >
                        </v-select>
                      </v-flex>
                      <v-flex xs12 sm12 md4 xl4>
                        <v-file-input
                          accept="*"
                          label="Adicionar Planilha"
                          v-model="selected_spreadsheet"
                        ></v-file-input>
                      </v-flex>
                      <v-flex xs12 sm12 md4 xl4>
                          <v-text-field
                            required
                            class="text-right"
                            label="Linha Inicial *"
                            v-model="start_line"
                            text-align="right"
                            type="number"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-container>
                  <v-card-actions>
                    <v-col>
                      <v-btn text @click="setDialogSpreadsheetTransfer({show: false})">Cancelar</v-btn>
                    </v-col>
                    <v-col class="text-center">
                      <v-btn readonly v-if="overlay" color="error">Carregando ...</v-btn>
                    </v-col>
                    <v-col class="text-right">
                      <v-btn v-if="selected_spreadsheet && nameSpreadsheet" @click="importSpreadsheet" text type="submit" right >Confirmar</v-btn>
                    </v-col>
                  </v-card-actions>
                </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
        
    </v-dialog>

    <v-dialog
      v-model="dialogReceipt"
      @click:outside="setDialogReceipt({show: false})"
    >
      <v-card height="90vh">
        <v-img max-height="100%" :src="receipt_show" contain></v-img>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import { mapFields } from 'vuex-map-fields'
  import ChipsTransferForm from './ChipsTransferForm'
  import ChipsTransferSupremeForm from './ChipsTransferSupremeForm'
  import ChipsTransferFilter from './ChipsTransferFilter'
  // import JsonExcel from "vue-json-excel";
  import { utils, writeFile } from "xlsx";

  export default {
    name: 'ChipsTransfer',
    route_code: 'ChipsTransfer',
    computed: {
      ...mapState('login', ['userData']),
      ...mapState('instances', ['instances']),
      ...mapState('chips_transfer', ['contact_show', 'dialog', 'dialogSupreme', 'dialogDelete', 'dialogReceipt',
            'route', 'errors', 'users', 'loading', 'mode', 'notificationType', 'notificationMessage', 
            'rowsPerPage', 'receipt_show', 'totalChipsTransfers', 'chipsTransfers', 'agents', 'spreadsheet_period', "spreadsheet_headers_config_import", ]),
      ...mapFields('chips_transfer', [
          "dialogReceipt",
          "dialogSpreadsheetTransfer",
          'id_agent',
          'pagination',
          'dialogFilter',
          'overlay',
          "start_line",
          'showNotification',
          "type_import",
          "selected_spreadsheet",
          "mode"
          // ...
        ]),
        nameSpreadsheet() {
          return this.selected_spreadsheet.name
        }
    },
    created() {
      this.getInstances()
      this.getChipsTransfers({resetPage: false})
      this.setScreen({screen: 'routes'})
      this.watchChipsTransfer()
    },
    components: {
      // "download-excel": JsonExcel,
      "chips-transfer-form": ChipsTransferForm,
      "chips-transfer-supreme-form": ChipsTransferSupremeForm,
      "chips-transfer-filter": ChipsTransferFilter
    },
    methods: {
      addDropFile(e) {
        if (e.dataTransfer) {
          if (e.dataTransfer.files[0]) {
            this.selected_spreadsheet = e.dataTransfer.files[0];
          }
        }
      },
      ...mapMutations('chips_transfer', ['add', 'edit', 'viewChipsTransfer', 'showFilter', 'setDialogReceipt', "setScreen", 'registerSupreme',"setDialogSpreadsheetTransfer" ]),
      ...mapActions("instances", ["getInstances"]),
      ...mapActions('chips_transfer', ['closeDialog', "getItemsNoPage",'closeDialogSupreme', 'closeDeleteDialog', 'deleteChipsTransfer', 'getUsers', 'getChipsTransfers', 'getChipsTransfersNoPage', "downloadReceipts", 
                    'showDeleteDialog','showReceipt', 'startWithdrawal', 'stopWithdrawal', 'getAgents', 'finishTransfer', 'finishTransferBot', 'backTransfer', 'showDialogSpreadsheetTransfer', 'importSpreadsheet' ]),
      isMobile() {
        if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
          return true;
        }
        return false
      },
      watchChipsTransfer() {
        console.log('entrei aqui', this.mode, this.$router)
        if(this.mode == 'LIST') {
          this.getChipsTransfers({resetPage: false})
        }
        if(this.$router) {
          if(this.$router.history.current.name == "chips_transfer") {
            setTimeout(() => {
              this.watchChipsTransfer()
            }, 25000)
          }
        }
      },
      getTypeHeaderSpreadSheet(type) {
        if(type == 'int') {
          return 'Inteiro'
        } else if(type == 'float') {
          return 'Decimal'
        } else {
          return 'Texto'
        }
      },
      getType(type) {
        return this.types[type]
      },
      getClassColor(item) {
        let className = '';

        if(item.status == 0) {
          className = 'conciliation-error'
        } else if (item.status == 2) {
          className = 'conciliation-ok'
        } else if (item.status == 1) {
          className = 'conciliation-warning'
        } else if (item.status == 4) {
          className = 'conciliation-bot'
        } else if (item.status == 3) {
          className = 'conciliation-manuel'
        }

        return className
      },
      getColorButton(item) {
        let className = '';

        if(item.status == 0) {
          className = 'red'
        } else if (item.status == 2) {
          className = 'green'
        } else if (item.status == 1) {
          className = 'blue'
        } else if (item.status == 4) {
          className = 'yellow darken-3'
        } else if (item.status == 3) {
          className = 'purple'
        }

        return className
      },
      getStatus(item) {
        if(item.status == 0) {
          return 'Não Concluído'
        } else if(item.status == 2) {
          return 'Concluído'
        } else if(item.status == 1) {
          return 'Cadastrado'
        } else if(item.status == 3) {
          return 'Manual'
        } else if(item.status == 4) {
          return 'BOT'
        }
      },
      generateSheet() {
        this.overlay = true;
      },
      finishSheet() {
        this.overlay = false;
      },
      getSheetName() {
        var date = new Date();
        var name =
          "transfers_chips" +
          date.toLocaleDateString() +
          " " +
          date.toLocaleTimeString();
        return name + ".xls";
      },
      getClub(item) {
        if (item) {
          console.log(item)
          console.log(this.instances)
          return this.instances[item].name
        }
      },
      onPaste(e) {
        if(e.clipboardData && e.clipboardData.items.length != 0) {
          var file = e.clipboardData.items[0]
          var file_data = file.getAsFile()
          this.selected_spreadsheet = file_data
        }
      },
      getColor(item) {
        if(item.status == 0) {
          return 'red'
        } else if(item.status == 2) {
          return 'green'
        } else if(item.status == 1) {
          return 'yellow'
        }
      },
      async downloadXlsx() {
        this.generateSheet()
        try {
          let itemsNoPage = await this.getItemsNoPage()
          if(itemsNoPage) {
            const wb = utils.book_new()
            let items = itemsNoPage.map(row => ({
              "Nome PPPoker":  row.name_pppoker,
              "ID PPPoker":  row.id_pppoker,
              "ID Agente":  row.id_agent,
              "Saldo Agência":  row.balance,
              "ID Suprema Poker":  row.id_pppoker,
              "Saldo Fichas":  row.chips,
              "Status":  row.status,
              "Clube Origem":  row.club_origin,
              "Senha":  row.newagent_password,
            }))
            const ws = utils.json_to_sheet(items)
            utils.book_append_sheet(wb, ws, "Transferencia")
            await writeFile(wb, this.getSheetName())
          }
        } catch (error) {
          this.finishSheet()
        } finally {
          this.finishSheet()
        }
      },
    },
    data: () => ({
      page: 1,
      pageCount: 0,
      headers_config_spreadsheet: [
        { text: "Descrição", value: "description_field", sortable: false },
        { text: "Coluna", value: "column_spreadsheet", align: 'right', sortable: true },
        { text: "Tipo", value: "type_field", align: 'right', sortable: false }
      ],
      headers_spreadsheet: [
        { text: "Id", value: "id", sortable: false },
        { text: "Nome", value: "name", sortable: false },
        { text: "Processada", value: "processed", sortable: true },
        { text: "Ações", value: "actions", sortable: false }
      ],
      headers: [
        { text: "Nome PPPoker",  value: "name_pppoker", sortable: false, align: "center"},
        { text: "ID PPPoker",  value: "id_pppoker", sortable: false, align: "center" },
        { text: "ID Agente",  value: "id_agent", sortable: false, align: "center" },
        { text: "Saldo Agência",  value: "balance", sortable: false, align: "center"},
        { text: "Nome Suprema",  value: "name_supreme", sortable: false, align: "center"},
        { text: "ID Suprema Poker",  value: "id_supreme", sortable: false, align: "center"},
        { text: "Senha",  value: "newagent_password", sortable: false, align: "center"},
        { text: "Saldo Fichas",  value: "chips", sortable: false, align: "center"},
        { text: "Clube Origem",  value: "club_origin", sortable: false, align: "center"},
        { text: "Status",  value: "status", sortable: false, align: "center"},
        { text: "Ações",  value: "actions", sortable: false, align: "center" }
      ],
      possible_types_import: [
        { id: 1, description: "Adicionar Planilha" }
      ],
      sheet_fields: {
        "Nome PPPoker": {
          field: 'name_pppoker',
        },
        "ID PPPoker": {
          field: 'id_pppoker',
        },
        "ID Agente": {
          field: 'id_agent',
        },
        "Saldo Agência": {
          field: 'balance',
        },
        "ID Suprema Poker": {
          field: 'id_pppoker',
        },
        "Saldo Fichas": {
          field: 'chips',
        },
        "Status": {
          field: 'status',
        },
        "Clube Origem": {
          field: 'club_origin',
        },
        "Senha": {
          field: 'newagent_password',
        },
      },
    }),
    watch: {
      pagination: {
        handler () {
          this.getChipsTransfers({resetPage: false})
        },
      },
    },
  }
</script>

<style>
.max-h{
  height: 40px;
}
</style>