<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2000"
      :value="showNotification"
    >
      {{ notificationMessage }}
    </v-snackbar>
    <v-toolbar flat class="elevation-5">
        <div>
        <v-toolbar-title>Pagamentos</v-toolbar-title>
        </div>
        <v-spacer></v-spacer>
        <div class="button-toolbar">
          <v-btn color="black" dark class="ma-2" @click="showDialogPayment({mod: 'ADD'})" small>
          <v-icon>mdi-plus</v-icon></v-btn>
        </div>
        <div class="button-toolbar">
          <v-btn color="black" dark class="ma-2" @click="reload" small>
          <v-icon>mdi-refresh</v-icon></v-btn>
        </div>
    </v-toolbar>

    <v-data-table
        :headers="headers"
        :loading="loading"
        :items="payments"
        :options.sync="paginationPayment"
        :server-items-length="totalPayments"
        no-data-text="Nenhum item encontrado"
        class="elevation-10 table-margin"
    >
        
        <template v-slot:[`item.value`]="{ item }">
        <span>{{
            item.value.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
            })
        }}</span>
        </template>
        <template v-slot:[`item.type`]="{ item }">
          <span>
            {{ getTypePaymentDescription(item.type) }}
          </span>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <span>{{ getStatus(item.status) }}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
              <template v-slot:activator="{on, attrs}">
              <v-icon small v-on="on" v-bind="attrs" color="red darken-3" class="mr-2" @click="showDialogDeletePayment(item)">
                  mdi-delete
              </v-icon>
              </template>
              <span>Deletar Pagamento</span>
          </v-tooltip>
          <v-tooltip bottom >
            <template v-if= "item.pix_id == null" v-slot:activator="{ on, attrs }">
              <v-icon small v-on="on" v-bind="attrs" color="blue" class="mr-2" @click="showDialogPixRelateds({show:true, item: item})">
                mdi-file-multiple
              </v-icon
              >
            </template>
            <span>Pix relacionados</span>
          </v-tooltip>
          <v-tooltip bottom >
            <template v-if= "item.pix_id == null" v-slot:activator="{ on, attrs }">
              <v-icon small v-on="on" v-bind="attrs" color="grey" class="mr-2" @click="showDialogPayment({mod: 'EDIT'}), setAtualPayment({item:item})">
                mdi-pencil
              </v-icon
              >
            </template>
            <span>Editar Pagamento</span>
          </v-tooltip>
          <!-- <v-tooltip bottom v-if="item.status == 0"> -->
          <v-tooltip bottom v-if="item.status == 0">
            <template v-slot:activator="{ attrs, on }">
              <v-icon
                v-bind="attrs"
                color="green"
                v-on="on"
                v-if="item.status == 0 || item.status == 1"
                small
                class="mr-2"
                @click="showDialogAutomaticPayment({ show: true }), setPaymentId({id: item.id}), setPixKey({pix_key:item.pix_key})"
                >mdi-check</v-icon
              >
            </template>
            <span>Salvar</span>
          </v-tooltip>
        </template>
    </v-data-table>
    <v-dialog
      :value="dialogPayment"
      width="600"
      @click:outside="showDialogAutomaticPayment({ show: false })"
    >
      <LeagueClosureFormPayment/>
    </v-dialog>

    <v-dialog :value="dialogDeletePayment" persistent width="300">
      <v-card>
        <v-card-title class="headline">Confirmar exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn
              text
              @click="closeDialogDeletePayment"
            >Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn
              text
              type="submit"
              right
              @click="deletePayment"
            >Confirmar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      style="overflow: hidden"
      content-class="dialog-pix"
      :value="dialogPixRelated"
      @keydown.esc="showDialogPixRelateds({ show: false })"
      width="95%"
      @click:outside="showDialogPixRelateds({ show: false })"
      >
      <LeagueClosureFormPix/>
    </v-dialog>

    <v-dialog
      :value="dialogAutomaticPayment"
      @click:outsude="showDialogAutomaticPayment({ show: false })"
      width="450"
    >
      <v-card>
        <v-card-title class="headline text-center justify-center">
          Realizar Pagamento
        </v-card-title>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12 sm12 md12 >
              <v-select
                label="Banco Pagamento*"
                :items="banksPix"
                v-model="bank"
                :error-messages="errors.collect('bank')"
                :item-text="getTextBank"
                item-value="id"
                data-vv-name="bank"
                outlined
              >
              </v-select>
            </v-flex>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-col>
            <v-btn text @click="showDialogAutomaticPayment({ show: false })"
              >Cancelar</v-btn
            >
          </v-col>
          <v-col>
            <v-btn
              text
              @click="
                finishPayment({ payment: paymentAutomatic, screen: 'payment'})
              "
              >Manual</v-btn
            >
          </v-col>
          <v-col
            class="text-right"
            v-if="paymentAutomatic.value < 20000 || (this.userData.role == 80 || this.userData.role == 98 || this.userData.role == 99)"
          >
            <v-btn
              text
              type="submit"
              right
              @click="finishAutomaticPayment({ payment: paymentAutomatic})"
              >Automático</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import LeagueClosureFormPayment from "./../league_closure_forms/LeagueClosureFormPayment.vue"
import LeagueClosureFormPix from '../league_closure_forms/LeagueClosureFormPix.vue'


export default {
  name: "LeagueClosureClub",
  components: {
      LeagueClosureFormPayment,
      LeagueClosureFormPix
  },
  computed: {
    ...mapState("bank", { banks: "items" }, "cost_center", {cost_centers: "items"}),
    ...mapState("login", ["userData"]),
    ...mapState("league_closure_club", [
      "dialogPixRelated",
      "dialogPayment",
      "dialogDeletePayment",
      "item",
      "loading",
      "notificationType",
      "notificationMessage",
      "rowsPerPagePayment",
      "totalPayments",
      "payments",
      "overlay",
      "showNotification",
      "possible_payments",
      "paymentAutomatic",
      "dialogAutomaticPayment",
    ]),
    ...mapFields("league_closure_club", [
      "paymentAutomatic.bank",
    ]),
    banksPix() {
      this.setBanks(this.banks)
      return this.banks.filter((b) => b.make_withdrawals == 1);
    },
    ...mapFields("league_closure_club", ['paginationPayment'])
  },
  created() {
    this.getPayments({ resetPage: true});
    this.getBanks();
    this.setBanks(this.banks)
  },
  methods: {
    ...mapActions("bank", { getBanks: "getItems" }),
    ...mapMutations("league_closure_club", [
        "addPayment",
        "setPaymentId",
        "setPixKey",
        "setAtualPayment",
        "setBanks"
    ]),
    ...mapActions("league_closure_club", [
      "showDialogAutomaticPayment",
      "showDialogPixRelateds",
      "deletePayment",
      "showDialogPayment",
      "closeDialogPayment",
      "getPayments",
      "deletePayment",
      "showDialogDeletePayment",
      "closeDialogDeletePayment",
      "finishPayment",
      "finishAutomaticPayment"
    ]),
    getTypePaymentDescription(type){
      let p_payments = this.possible_payments
      for (const item in p_payments) {
        if (type == p_payments[item].id){
          return p_payments[item].description
        }
      }
      return type.description
    },
    getStatus(status) {
      return this.possible_status[status];
    },
    getTextBank(item) {
      if (item.account) {
        return item.name + " | " + item.pix_key;
      } else {
        return item.name;
      }
    },
    reload(){
      this.getPayments({ resetPage: true})},
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      }
      return false;
    },
    
  },
  data: () => ({
    tab: '', 
    possible_status: ["Pendente", "Agendado", "Pago", "Em Processamento"],
    headers: [
      { text: "Id", value: "id", sortable: false },
      { text: "Valor", value: "value", sortable: false },
      { text: "Data e Hora de Criação", value: "date_created", sortable: false },
      { text: "Data de Pagamento", value: "date", sortable: false },
      { text: "Tipo de Pagamento", value: "type", sortable: false },
      { text: "Responsável", value: "user_name", sortable: false },
      { text: "Status", value: "status", sortable: false },
      { text: "Pix", value: "pix_key", sortable: false },
      { text: "Ações", value: "actions", sortable: false },
    ],
    valid: false,
    timeProps: {
      format: "24hr",
    },
  }),
  watch: {
    paginationPayment: {
      handler() {
        this.getPayments({ resetPage: false});
      },
    },
  },
};
</script>

<style>

</style>
