<template>
  <div>
    <div class="document" >
      <v-img :max-height="150" :src="thumbsrc" :lazy-src="thumbsrc"></v-img>
      <v-row no-gutters class="download">
        <v-col class="col-icon" sm="1" md="1" lg="1" xl="1">
          <div class="icon">
            <v-icon v-if="mimetype == 'application/xlsx' || (mimetype.indexOf('spreadsheet') != -1)">mdi-google-spreadsheet</v-icon>
            <v-icon v-if="mimetype == 'application/pdf'">mdi-file-pdf-outline</v-icon>
          </div>
        </v-col>
        <v-col sm="9" md="9" lg="9" xl="9" class="title">
          {{content}}
        </v-col>
        <v-col sm="2" md="2" lg="2" xl="2" class="title" justify-center>
          <v-btn color="transparent"  @click="downloadMessageDocument({message_id: message_id, is_group: is_group, file_name: content})">
            <v-icon size="30">mdi-download-circle-outline</v-icon>
          </v-btn>
        </v-col>
        
      </v-row>
    </div>
    <div class="message-mimetype">{{getMimeType(mimetype)}}</div>
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  export default {
    props: [
      'content',
      'docsrc',
      'mimetype',
      'thumbsrc',
      'is_group',
      'message_id'
    ],
    data: () => ({
      showExpandImage: false,
    }),
    methods: {
      ...mapActions('message', ['downloadMessageDocument']),
      getMimeType(mimetype) {
        if(mimetype.replace("application/", "") == 'pdf') {
          return 'PDF'
        } 
        else if(mimetype.replace("application/", "") == 'xlsx') {
          return 'XLSX'
        }
        else if(mimetype.indexOf('spreadsheet') != -1) {
          return 'XLSX'
        }
    
        return '-'      
      }
    }
  }
</script>

<style>
  .document{
    min-width: 300px;
    justify-content: center;
    align-items: center;
    color: rgba(0, 0, 0, 0.87) !important;
    text-decoration: none;
    margin: 5px 10px 0 10px;
    margin-right: auto;
  }

  .download {

  }

  .icon {
    margin-top: 2px;
    margin-left: 2px;
    margin-right: 5px;
  }

  .download > .title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px !important;
  }

  a:hover, a:visited, a:link, a:active {
      text-decoration: none;
  }
  
  .message.own .imagdocumente{
    margin-right: 0;
    margin-left: auto;
  }
  @media (max-width: 480px) {
    .document{
      width: 100px;
    }
    .document img{
      width: 80px;
    }
  }
</style>
