<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline text-center justify-center">Filtro</span>

        <v-col class="text-right">
          <v-btn v-if="filtered" color="white" class="ma-2"  @click="clearFilter" right small depressed>
            <v-icon color="black">mdi-filter-remove</v-icon>
          </v-btn>
        </v-col>
      </v-card-title>

      <v-card-text>
        <v-form ref="form">
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm12 md12>
                <v-text-field
                    label="Nome"
                    v-model="name"
                    outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md12>
                <v-select
                    label="Tipo *"
                    :items="possible_types"
                    v-model="type"
                    item-text="description"
                    item-value="id"
                    clear-icon="mdi-close"
                    :clearable="true"
                    outlined
                ></v-select>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn text @click="showFilter({show: false})">Cancelar</v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn text type="submit" right @click="filter">Filtrar</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "ProductsFilter",
  computed: {
    ...mapState("products", ["filtered"]),
    ...mapFields("products", [
      "filter.name",
      "filter.type"
      // ...
    ]),
  },
  data: () => ({
    possible_types: [
      {id: 0, description: 'Moletom'},
      {id: 1, description: 'Blusa'},
      {id: 2, description: 'Boné'},
      {id: 3, description: 'Calça'},
      {id: 4, description: 'Bermuda'},
      {id: 5, description: 'Outros'},
    ],
  }),
  methods: {
    ...mapMutations("products", ["showFilter"]),
    ...mapActions("products", ["clearFilter", "getItems"]),
    filter() {
      this.getItems({ resetPage: true });
      this.showFilter({ show: false });
    },
  },
};
</script>

<style>
</style>