<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline">Filtro</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm6 md6>
                <v-text-field
                  required
                  label="ID Suprema"
                  v-model="id_pppoker"
                  data-vv-name="id_pppoker"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field required label="Nome" v-model="name" data-vv-name="name" outlined></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn text @click="showFilter({show: false})">Cancelar</v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn text type="submit" right @click="filter">Filtrar</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "PlayersAgentFilter",
  computed: {
    ...mapFields("players_agent", [
        "filter.id_pppoker",
        "filter.name",
      // ...
    ])
  },
  methods: {
    ...mapMutations("players_agent", ["showFilter"]),
    ...mapActions("players_agent", ["getItems"]),
    filter() {
      this.getItems();
      this.showFilter({ show: false });
    },
  },
};
</script>

<style>
</style>