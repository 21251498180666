import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../../config/endPoints';

const defaultItem = {
    id: null,
    occurrence: ''
};

export default {
    namespaced: true,
    state: {
        dialog: false,
        editedItem: {},
        dialogDelete: false,
        itemToDelete: {},
        occurrences: [],
        loading: false,
        mode: '',
        overlay: false,
        page: 1,
        pagination: {
            page: 1,
            occurrencesPerPage: 10
        },

        successNotification: false,
        notificationMessage: '',
        notificationType: "success",
        showNotification: false,
        totalOccurrences: 0,
        rowsPerPage: 10,
        menus: []
    },
    mutations: {
        add: (state) => {
            state.mode = "ADD"
            state.editedItem = Object.assign({}, defaultItem)
            state.dialog = true
        },
        closeDialog: (state) => {
            state.editedItem = {}
            state.errors = [];
            state.dialog = false
            state.mode = "LIST"
        },
        setItemToDelete: (state, item) => state.itemToDelete = item,
        setDialogDelete: (state, show) => state.dialogDelete = show,
        loading: (state, payload) => state.loading = payload.show,
        setMode: (state, mode) => state.mode = mode,
        setOccurrences: (state, occurrences) => state.occurrences = occurrences,
        setTotalOccurrences: (state, total) => state.totalOccurrences = total,
        setRowsPerPage: (state, rowsPerPage) => state.rowsPerPage = rowsPerPage,
        showDialog: (state, show) => state.dialog = show,
        setPage: (state, payload) => {
            state.pagination.page = payload.page;
            state.pagination.occurrencesPerPage = payload.occurrencesPerPage
        },
        setScreen: (state, payload) => state.screen = payload.screen,
        showErrorNotification: (state, show) => state.errorNotification = show,
        showSuccessNotification: (state, show) => state.successNotification = show,
        showLoading: (state, show) => state.loading = show,
        showNotification: (state, payload) => {
            state.showNotification = payload.show,
                state.notificationMessage = payload.message,
                state.notificationType = payload.type
        },
        showOverlay: (state, payload) => {
            state.overlay = payload.show
        },
        viewOccurrences(state, occurrences) {
            state.editedItem = Object.assign({}, occurrences);
            state.mode = "VIEW"
            state.dialog = true
        },
        editedItem(state, occurrences) {
            state.editedItem = Object.assign({}, occurrences);
            state.mode = "EDIT"
            state.dialog = true
        },

        updateField
    },
    getters: {
        getField,
    },
    actions: {
        closeDeleteDialog: ({ commit }) => {
            commit('setDialogDelete', false)
            commit('setItemToDelete', {})
        },
        deleteItem: ({ dispatch, commit, state }) => {
            commit('loading', true) 
            let url = endPoints.httpchats + "/delete_occurrence/" + state.itemToDelete.id 
            axios.delete(url, state.itemToDelete).then(() => {
                dispatch('getOccurrences')
                dispatch('closeDeleteDialog')
                let notification = {
                    show: true,
                    message: "Ocorrência excluida com sucesso.",
                    type: "success"
                }
                commit('showNotification', notification)
            });
            dispatch('closeDeleteDialog')
        },
        showDeleteDialog: ({ commit }, item) => {
            commit('setDialogDelete', true)
            commit('setItemToDelete', item)
        },
        closeDialog: (({ commit, state }) => {
            state.editedItem = {}
            if (state.screen != "messages") {
                commit('message/setSelectedMessages', { messages: [] }, { root: true })
                commit('showDialog', false)
            } else {
                commit('message/showDialogFail', { show: false }, { root: true })
            }
            setTimeout(() => {
                commit('closeDialog')
                commit('showErrorNotification', false)
                commit('showSuccessNotification', false)
            }, 300);
        }),
        getOccurrences: ({ commit, state }) => {
            commit('loading', true)
            let url = endPoints.httpchats + "/occurrences"
            url += '?page=' + state.pagination.page + '&' + 'itemsPerPage=' + state.pagination.itemsPerPage;
            axios.get(url).then(occurrences => {
                commit('loading', false)
                commit('setTotalOccurrences', occurrences.data.total)
                commit('setOccurrences', occurrences.data.data)
            })
        },
    
        save: ({ commit, dispatch, state }) => {
            var url = endPoints.httpchats + "/save_occurrence";
            let func = '';
            if (state.mode == 'ADD') {
                func = axios.post;
                url = endPoints.httpchats + "/save_occurrence";
            } else {
                func = axios.put;
                url += '/' + state.editedItem.id;
            }

            func(url, state.editedItem).then(
                () => {
                    dispatch('getOccurrences')
                    let notification = {
                        show: true,
                        message: "Ocorrência salva com sucesso.",
                        type: "success"
                    }
                    commit('closeDialog')
                    commit('showNotification', notification)
                },
                error => {
                    let notification = {
                        show: true,
                        message: error.response.data.message,
                        type: "error"
                    }
                    commit('showNotification', notification)
                }
            );
        }
       
    }
}