<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline text-center justify-center">Filtro</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm6 md12>
                <v-text-field
                  label="Descrição"
                  v-model="description"
                  data-vv-name="description"
                  :clearable="true"
                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-select
                  label="Empresa"
                  :items="possible_companies"
                  item-text="name"
                  item-value="id"
                  v-model="company"
                  clear-icon="mdi-close"
                  :clearable="true"
                  outlined
                >
                </v-select>
              </v-flex>

              <v-flex xs12 sm6 md12>
                <v-textarea
                  label="Valor"
                  v-model="value"
                  data-vv-name="value"
                  outlined
                  :clearable="true"
                ></v-textarea>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-select
                  label="Banco"
                  :items="banks"
                  v-model="bank"
                  clear-icon="mdi-close"
                  :item-text="getTextBank"
                  item-value="id"
                  :clearable="true"
                  outlined
                >
                </v-select>
              </v-flex>

              <v-flex xs12 sm6 md12>
                <v-textarea
                  label="Observação"
                  v-model="observation"
                  data-vv-name="observation"
                  :clearable="true"
                  outlined
                  :rows="3"
                ></v-textarea>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-datetime-picker
                  v-model="start_date"
                  date-format="dd/MM/yyyy"
                  clearText="Limpar"
                  okText="Confirmar"
                  :time-picker-props="timeProps"
                  label="De *"
                  :textFieldProps="{ outlined: true }"
                >
                  <template slot="dateIcon">
                    <v-icon>mdi-calendar</v-icon>
                  </template>
                  <template slot="timeIcon">
                    <v-icon>mdi-clock</v-icon>
                  </template>
                </v-datetime-picker>
                
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-datetime-picker
                  v-model="end_date"
                  date-format="dd/MM/yyyy"
                  clearText="Limpar"
                  okText="Confirmar"
                  label="Até *"
                  :time-picker-props="timeProps"
                  :textFieldProps="{outlined: true}"
                >
                  <template slot="dateIcon">
                    <v-icon>mdi-calendar</v-icon>
                  </template>
                  <template slot="timeIcon">
                    <v-icon>mdi-clock</v-icon>
                  </template>
                </v-datetime-picker>
                
              </v-flex>


            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn
            text
            @click="
              setShowFilter(false);
              clearFilter();
            "
            >Cancelar</v-btn
          >
        </v-col>
        <v-col class="text-right">
          <v-btn text type="submit" right @click="filter">Filtrar</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "ReceiptsFilter",
  computed: {
    ...mapState("receipts", ["mode", "selectedItem", "showDialog", "banks"]),
    ...mapFields("receipts", [
      "filter.company",
      "filter.description",
      "filter.value",
      "filter.start_date",
      "filter.end_date",
      "filter.observation",
      "filter.bank",
    ]),
  },   
  methods: {
    ...mapMutations("receipts", ["setShowFilter", "clearFilter"]),
    ...mapActions("receipts", ["getItems"]),
    filter() {
      this.getItems();
      this.setShowFilter(false);
    },
    getTextBank(item) {
      if(item.account) {
        return item.name + ' | ' + item.account
      } else {
        return item.name
      }
    }
  },
  data: () => ({
    possible_companies: [
      {
        id: 0,
        name: "Stars",
      },
      {
        id: 1,
        name: "Aquarios",
      },
      {
        id: 2,
        name: "Jacks",
      },
      {
        id: 3,
        name: "Canubet",
      },
      {
        id: 4,
        name: "Grupo Stars",
      },
      {
        id: 5,
        name: "Stars Horizon",
      },
      {
        id: 6,
        name: "Outros",
      },
      {
        id: 7,
        name: "CGP",
      },
      {
        id: 8,
        name: "PHD Digital",
      },
      {
        id: 9,
        name: "Se precisar",
      },
    ],
    timeProps: {
      format: "24hr",
    },
  }),
};
</script>
