import { render, staticRenderFns } from "./LeagueForm.vue?vue&type=template&id=4ad0e410"
import script from "./LeagueForm.vue?vue&type=script&lang=js"
export * from "./LeagueForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports