<template>
  <div>
    <div @dragover.prevent>
      <v-card>
        <v-card-title>
          <span class="headline text-center">Jogador</span>
          <v-spacer></v-spacer>
          <span class="red--text body-1 font-weight-medium mr-2"
           v-if="!isValid"> 
            {{ (this.isWarning) ? "CPF não cadastrado na Receita Federal" : errorMessage}} 
          </span>
          
          <v-progress-circular
            v-if="loading"
            indeterminate
            class="text-right"
            color="primary"
            size="24"
          ></v-progress-circular>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs9 sm9 md9>
                  <v-text-field
                    required
                    label="Nome"
                    v-model="name"
                    :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                    :disabled="this.mode == 'VIEW' || this.mode == 'FINISH' || loading"
                    :error-messages="errors.collect('name')"
                    v-validate="'max_length:100'"
                    data-vv-name="name"
                    outlined
                    @change="validatePlayer()"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    label="CPF"
                    v-model="cpf"
                    :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                    :disabled="this.mode == 'VIEW' || this.mode == 'FINISH' || loading"
                    v-validate="'max_length:14'"
                    data-vv-name="cpf"
                    v-mask="'###.###.###-##'"
                    @change="validatePlayer()"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    label="Data de Nascimento"
                    v-model="birth_date"
                    :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                    :disabled="this.mode == 'VIEW' || this.mode == 'FINISH' || loading"
                    v-validate="'max_length:10'"
                    data-vv-name="birth_date"
                    v-mask="'##/##/####'"
                    @change="validatePlayer()"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm9 md9>
                  <v-text-field
                    required
                    label="Email"
                    v-model="email"
                    :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                    :disabled="this.mode == 'VIEW' || this.mode == 'FINISH'"
                    :error-messages="errors.collect('email')"
                    v-validate="'email|max_length:100'"
                    data-vv-name="email"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="ID PPPoker"
                    v-model="player_id"
                    :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                    :disabled="this.mode == 'VIEW' || this.mode == 'FINISH'"
                    :error-messages="errors.collect('player_id')"
                    data-vv-name="player_id"
                    v-validate="'max_length:50'"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-select
                      label="Clube"
                      :items="instances"
                      v-model="club"
                      :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                      :disabled="this.mode == 'VIEW' || this.mode == 'FINISH'"
                      :error-messages="errors.collect('club')"
                      item-text="name"
                      item-value="id_instance"
                      data-vv-name="club"
                      outlined
                    >
                  </v-select>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-select
                    label="Como conheceu o ST⭐RS?"
                    :items="knowByTypes"
                    v-model="know_by"
                    :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                    :disabled="this.mode == 'VIEW' || this.mode == 'FINISH'"
                    :error-messages="errors.collect('know_by')"
                    item-text="description"
                    item-value="id"
                    data-vv-name="know_by"
                    outlined
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-autocomplete
                      :clearable="this.mode != 'VIEW'"
                      v-model="selectedContact"
                      :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                      :disabled="this.mode == 'VIEW' || this.mode == 'FINISH'"
                      :no-filter="true"
                      :items="contacts"
                      outlined
                      no-data-text="Nenhum contato encontrado"
                      :item-text="getTextContact"
                      :return-object="true"
                      placeholder="Digite aqui o nome do contato"
                      label="Contato"
                      :search-input.sync="searchInput"
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs12 sm12 md6 xl6>
                  <v-checkbox :readonly="this.mode == 'VIEW'" :disabled="this.mode == 'VIEW'" v-model="bonus" class="mx-2" label="Bônus"></v-checkbox>
                </v-flex>
                <v-flex xs12 sm12 md6 xl6>
                  <v-checkbox :readonly="this.mode == 'VIEW'" :disabled="this.mode == 'VIEW'" v-model="pppoker_accepted" class="mx-2" label="Aceito PPPoker"></v-checkbox>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-col>
            <v-btn text @click="closeDialog" :disabled="loading">{{ mode == 'VIEW' ? 'Voltar' : 'Cancelar'}}</v-btn>
          </v-col>
          <v-col class="text-right" v-if="mode != 'VIEW'">
            <v-btn text type="submit" right @click="savePlayer" :disabled="loading || blockSave">Salvar</v-btn>
          </v-col>
        </v-card-actions>

        <v-dialog :value="dialogConfirm" persistent width="400">
          <v-card>
            <v-card-title class="headline text-center justify-center mb-2">Deseja realmente continuar?</v-card-title>

            <v-card-text class="black--text text-center justify-center">Existe uma validação pendente:</v-card-text>
            <v-card-text class="red--text font-weight-medium text-center justify-center mb-2">
              {{(this.isWarning) ? "CPF não cadastrado na Receita Federal" : errorMessage}}
            </v-card-text>

            <v-card-actions>
              <v-col>
                <v-btn
                  text
                  @click="setDialogConfirm({ dialogConfirm: false })"
                >Cancelar</v-btn>
              </v-col>
              <v-col class="text-right">
                <v-btn
                  text
                  type="submit"
                  right
                  @click="save"
                >Confirmar</v-btn>
              </v-col>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import debounce from '../../util/debounce.js';

export default {
  name: "PlayerForm",
  computed: {
    ...mapState("players", [
      "loading",
      "mode",
      "knowByTypes",
      "contacts",
      "selectedContact",
      "isValid",
      "isWarning",
      "errorMessage",
      "dialog",
      "blockSave",
      "dialogConfirm"
    ]),
    ...mapState("instances", ["instances"]),
    ...mapFields("players", [
      "editedItem.id",
      "editedItem.name",
      "editedItem.cpf",
      "editedItem.birth_date",
      "editedItem.email",
      "editedItem.player_id",
      "editedItem.club",
      "editedItem.know_by",
      "editedItem.contact_id",
      "editedItem.contact_name",
      "editedItem.pppoker_accepted",
      "editedItem.bonus"
      // ...
    ]),
  },
  mixins: [debounce],
  created() {
    this.getContacts = this.debounce(this.getContacts, 500)

    this.$validator.extend("required", {
      getMessage: (field, [label]) => label + " deve ser informado",
      validate: (_, [, field]) => {
        if (this[field] || this[field] === 0) {
          return true;
        } else {
          return false;
        }
      },
    });

    this.$validator.extend("max_length", {
      getMessage: (_, [length]) => "No máximo " + length + " caracteres.",
      validate: (val, [length]) => {
        if (val.length > length) {
          return false;
        } else {
          return true;
        }
      },
    });
  },
  methods: {
    ...mapActions("players", [
      "closeDialog",
      "save",
      "getContacts",
      "validate",
    ]),

    ...mapMutations("players", ["setIsValid", "setErrorMessage", "setBlockSave", "setDialogConfirm"]),
    savePlayer() {
      var self = this;

      this.$validator.validateAll().then((valid) => {
        if (valid) {
          
          if (self.selectedContact._id) {
            self.contact_id = self.selectedContact._id
            self.contact_name = self.selectedContact.name[0]
          } else {
            self.contact_id = ""
            self.contact_name = ""
          }

          self.pppoker_accepted = self.pppoker_accepted ? 1 : 0
          self.bonus = self.bonus ? 1 : 0

          if(self.isValid){
            self.save();
          }
          else {
            this.setDialogConfirm({ dialogConfirm: true })
          }
        }
      });
    },
    getTextContact(item) {
      if(item.name) {
        if(item.name.length == 1) {
          return item.name[0]
        }
        
        var text = ""
        item.name.forEach((name) => {
          text += name + " | "
        })
        return text
      }
      else {
        return text
      }
    },
    validatePlayer() {
      if (this.birth_date === "" && this.cpf !== "") {
        this.setIsValid({ isValid: false })
        this.setErrorMessage({ errorMessage: "Impossível validar CPF sem data de nascimento" })
        this.setBlockSave({ blockSave: true })
      } else if (this.birth_date !== "" && this.cpf !== "") {
        this.validate({ cpf: this.cpf, name: this.name, birth_date: this.birth_date })
      }

    }
  },
  data: () => ({
    valid: false,
    searchInput: '',
  }),
  watch: {
    searchInput: {
      handler () {
        this.getContacts({search: this.searchInput, contacts: this.contacts})
      },
    }
  },
};
</script>
