<template>
    <div>
        <v-card>
            <v-card-title>
                <span class="headline">Solicitação de Anotado</span>
            </v-card-title>

            <v-card-text>
                <v-form ref="form" lazy-validation>
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12 sm6 md6>
                                <v-datetime-picker
                                    v-model="date_created"
                                    required
                                    date-format="dd/MM/yyyy"
                                    :disabled="this.mode == 'VIEW'"
                                    :time-picker-props="timeProps"
                                    :textFieldProps="{ outlined: true }"
                                    :error-messages="
                                        errors.collect('date_created')
                                    "
                                    v-validate="'required:Data,date_created'"
                                    data-vv-name="date_created"
                                >
                                    <template slot="dateIcon">
                                        <v-icon>mdi-calendar</v-icon>
                                    </template>
                                    <template slot="timeIcon">
                                        <v-icon>mdi-clock</v-icon>
                                    </template>
                                </v-datetime-picker>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-autocomplete
                                    label="Cliente"
                                    :clearable="true"
                                    outlined
                                    :items="clients"
                                    v-model="id_client"
                                    :disabled="
                                        this.screen == 'messages' ||
                                        this.mode == 'VIEW'
                                    "
                                    :readonly="
                                        this.mode == 'VIEW' ||
                                        this.screen == 'messages'
                                    "
                                    item-text="name"
                                    item-value="id"
                                    clear-icon="mdi-close"
                                    :error-messages="
                                        errors.collect('id_client')
                                    "
                                    v-validate="'required:CLiente,id_client'"
                                    data-vv-name="id_client"
                                >
                                </v-autocomplete>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-text-field
                                    required
                                    label="Valor *"
                                    prefix="R$"
                                    v-model="value"
                                    :clearable="
                                        this.mode == 'ADD' ||
                                        this.mode == 'EDIT'
                                    "
                                    :readonly="this.mode == 'VIEW'"
                                    :error-messages="errors.collect('value')"
                                    v-validate="'required:Valor,value'"
                                    data-vv-name="value"
                                    outlined
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-autocomplete
                                    label="Clube"
                                    :clearable="true"
                                    outlined
                                    :items="slots"
                                    v-model="id_slot"
                                    :readonly="this.mode == 'VIEW'"
                                    :disabled="this.mode == 'VIEW'"
                                    item-text="name"
                                    item-value="id"
                                    clear-icon="mdi-close"
                                    :error-messages="errors.collect('id_slot')"
                                    v-validate="'required:Clubes,id_slot'"
                                    data-vv-name="id_slot"
                                >
                                </v-autocomplete>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-text-field
                                    required
                                    label="ID Aplicativo *"
                                    v-model="id_app"
                                    :clearable="
                                        this.mode == 'ADD' ||
                                        this.mode == 'EDIT'
                                    "
                                    :readonly="this.mode == 'VIEW'"
                                    :error-messages="errors.collect('id_app')"
                                    v-validate="'required:ID Aplicativo,id_app'"
                                    data-vv-name="id_app"
                                    outlined
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                                <v-select
                                    label="Tipo *"
                                    :items="possible_types"
                                    v-model="type"
                                    :error-messages="errors.collect('type')"
                                    v-validate="'required:Tipo,type'"
                                    item-text="description"
                                    :readonly="this.mode == 'VIEW'"
                                    item-value="id"
                                    data-vv-name="type"
                                    outlined
                                ></v-select>
                            </v-flex>
                            <v-flex xs12 sm6 md6 v-if="type == 1">
                                <v-select
                                    label="Tipo de Pagamento *"
                                    :items="possible_payment_types"
                                    v-model="payment_type"
                                    item-text="description"
                                    :readonly="this.mode == 'VIEW'"
                                    item-value="id"
                                    :error-messages="
                                        errors.collect('payment_type')
                                    "
                                    v-validate="
                                        'required:Tipo de Pagamento,payment_type'
                                    "
                                    outlined
                                ></v-select>
                            </v-flex>

                            <v-expansion-panels
                                v-if="this.mode == 'VIEW' && this.pix_id"
                            >
                                <v-expansion-panel>
                                    <v-expansion-panel-header
                                        >Pix</v-expansion-panel-header
                                    >
                                    <v-expansion-panel-content>
                                        <v-layout wrap>
                                            <v-flex xs12 sm4 md4>
                                                <v-text-field
                                                    required
                                                    label="CPF/CNPJ"
                                                    :readonly="true"
                                                    outlined
                                                    :value="
                                                        pixRelated.client_key
                                                    "
                                                ></v-text-field>
                                            </v-flex>
                                            <v-flex xs12 sm4 md4>
                                                <v-text-field
                                                    required
                                                    label="Data"
                                                    :readonly="true"
                                                    outlined
                                                    :value="
                                                        getDate(pixRelated.date)
                                                    "
                                                ></v-text-field>
                                            </v-flex>
                                            <v-flex xs12 sm4 md4>
                                                <v-text-field
                                                    label="Valor"
                                                    :readonly="true"
                                                    outlined
                                                    :value="
                                                        pixRelated.value.toLocaleString(
                                                            'pt-BR',
                                                            {
                                                                style: 'currency',
                                                                currency: 'BRL',
                                                            },
                                                        )
                                                    "
                                                ></v-text-field>
                                            </v-flex>
                                            <v-flex xs12 sm12 md12>
                                                <v-text-field
                                                    label="Identificador"
                                                    :readonly="true"
                                                    outlined
                                                    :value="
                                                        pixRelated.endToEndId
                                                            ? pixRelated.endToEndId
                                                            : pixRelated.txId
                                                    "
                                                ></v-text-field>
                                            </v-flex>
                                        </v-layout>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-layout>
                    </v-container>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-col>
                    <v-btn text @click="closeDialog">{{
                        mode == 'VIEW' ? 'Voltar' : 'Cancelar'
                    }}</v-btn>
                </v-col>
                <v-col class="text-right">
                    <v-btn
                        text
                        type="submit"
                        right
                        v-if="mode == 'EDIT' || mode == 'ADD'"
                        @click="saveAnnotated"
                        >Salvar</v-btn
                    >
                </v-col>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'
// import { mapGetters } from 'vuex'

export default {
    name: 'AnnotatedsForm',
    created() {
        this.getClients({ resetPage: false })
    },
    computed: {
        ...mapState('annotateds', [
            'mode',
            'screen',
            'selectedItem',
            'showDialog',
            'pixRelated',
            'slots',
            'possible_types',
            'possible_payment_types',
        ]),
        ...mapState('clients', { clients: 'items' }),
        ...mapFields('annotateds', [
            'selectedItem.id',
            'selectedItem.id_client',
            'selectedItem.id_slot',
            'selectedItem.id_app',
            'selectedItem.type',
            'selectedItem.value',
            'selectedItem.pix_id',
            'selectedItem.date_created',
            'selectedItem.payment_type',
        ]),
    },
    methods: {
        saveAnnotated() {
            this.$validator.validateAll().then((valid) => {
                if (valid) {
                    let date = new Date(this.date_created)
                    this.date_created = this.formatDate(date)
                    this.save()
                }
            })
        },
        formatDate(date) {
            let d = new Date(date)
            let day = d.getDate()
            let month = d.getMonth() + 1
            let year = d.getFullYear()
            if (day < 10) {
                day = '0' + day
            }
            if (month < 10) {
                month = '0' + month
            }
            return  year + '-' + month + '-' + day + ' ' + d.toLocaleTimeString()
        },
        getDate(date) {
            var d = new Date(date)
            return d.toLocaleDateString() + ' ' + d.toLocaleTimeString()
        },
        getStatus(status) {
            let possible_status = ['Pendente', 'Concluido', 'Erro']
            return possible_status[status - 1]
        },
        ...mapMutations('annotateds', [
            'setShowDialog',
            'setSelectedItem',
            'setShowDialog',
            'setShowDialogPixRelated',
        ]),
        ...mapActions('annotateds', ['save', 'getSlots', 'closeDialog']),
        ...mapActions('clients', { getClients: 'getItems' }),
    },
    data: () => ({
        headersPix: [
            { text: 'CPF/CPNJ', value: 'client_key', sortable: true },
            {
                text: 'Data',
                align: 'left',
                value: 'date',
                sortable: true,
            },
            { text: 'Valor', value: 'value', sortable: true },
            { text: 'endToEndId', value: 'endToEndId', sortable: false },
            { text: 'Deposito Id', value: 'deposit_id', sortable: false },
            { text: 'Saque Id', value: 'withdrawal_id', sortable: false },
            { text: 'Despesa Id', value: 'expense_id', sortable: false },
            { text: 'Entrada/Saída', value: 'type', sortable: false },
            { text: 'Status', value: 'status', sortable: false },
            {
                text: 'Ações',
                value: 'actions',
                sortable: false,
                align: 'center',
            },
        ],
        timeProps: {
            format: '24hr',
        },
    }),
}
</script>
