import axios from "axios";
import { getField, updateField } from "vuex-map-fields";
import endPoints from "@config/endPoints";
import {
  defaultErrorNotification,
  defaultSuccessNotification,
} from "@constants";
import formatDate from '@util/formatDate.js'


const defaultFilter = {
  id: "",
  start: "",
  end: "",
  type: "",
  type_transaction: "",
  date: "",
  agent_id: "",
  manager_id: "",
  sub_agent_id: "",
  player_id: "",
  value: "",
  slot: "",
  vinculations: "",
  selected_types_low: "",
  is_duplicate: "",
}


export default {
  namespaced: true,
  state: {
    headersTrade: [
      { text: 'ID', value: 'id', sortable: false },
      { text: 'Data', value: 'date', sortable: false },
      { text: 'Manager ID', value: 'manager_id_app', sortable: false },
      { text: 'Manager', value: 'manager_name_app', sortable: false },
      { text: 'Tipo de Gestor', value: 'type_manager', sortable: false },
      { text: 'Player ID', value: 'player_id_app', sortable: false },
      { text: 'Player Nick', value: 'player_name_app', sortable: false },
      {
        text: 'Tipo Transação',
        value: 'type_transaction',
        sortable: false,
      },
      { text: 'Valor', value: 'value', sortable: true },
      { text: 'Agente ID', value: 'agent_id_app', sortable: false },
      { text: 'Agente Nick', value: 'agent_name_app', sortable: false },
      { text: 'SubAgent ID', value: 'sub_agent_id', sortable: false },
      { text: 'SubAgent Nick', value: 'sub_agent_name', sortable: false },
      { text: 'Nick Club', value: 'nick', sortable: false },
      { text: 'ID Club App', value: 'id_club_app', sortable: false },
      { text: 'Ligações', value: 'ligations', sortable: false },
      { text: 'Ações', value: 'actions', sortable: false },
    ],
    headersCredit: [
      { text: 'ID', value: 'id', sortable: false },
      { text: 'Data', value: 'date', sortable: false },
      { text: 'Manager ID', value: 'manager_id_app', sortable: false },
      { text: 'Manager', value: 'manager_name_app', sortable: false },
      { text: 'Tipo de Gestor', value: 'type_manager', sortable: false },
      {
        text: 'Tipo Transação',
        value: 'type_transaction',
        sortable: false,
      },
      { text: 'Valor', value: 'value', sortable: true },
      { text: 'Agente ID', value: 'agent_id_app', sortable: false },
      { text: 'Agente Nick', value: 'agent_name_app', sortable: false },
      { text: 'SubAgent ID', value: 'sub_agent_id', sortable: false },
      { text: 'SubAgent Nick', value: 'sub_agent_name', sortable: false },
      { text: 'ID Club', value: 'id_club', sortable: false },
      { text: 'Nick Club', value: 'nick', sortable: false },
      { text: 'ID Club App', value: 'id_club_app', sortable: false },
      { text: 'Ligações', value: 'ligations', sortable: false },
      { text: 'Ações', value: 'actions', sortable: false },
    ],
    getClassColor(item) {
      let ligationsTotals = item.total_vinculations - item.linked_to
      if (item.type_low == 1) {
        return 'brown--text text--darken-1'
      } else {
        if (ligationsTotals == 0) {
          return 'light-green--text text--darken-3'
        } else if (ligationsTotals == 1) {
          return 'blue--text text--darken-4'
        } else if (ligationsTotals == 2 && item.total_vinculations == 3) {
          return 'orange--text text--darken-2'
        }
        else if (ligationsTotals == item.total_vinculations) {
          return 'red--text text--accent-4'
        }
      }

    },
    itemsCredit: [],
    itemsTrade: [],
    itemsDuplicated: [],
    selectedItem: {},
    mode: "",
    dialog: false,
    loading: false,
    totalItemsCredit: 0,
    totalItemsTrade: 0,
    totalItemsDuplicated: 0,
    filterDialogTrader: false,
    filterDialog: false,
    filtered: false,
    filter: {
      id: "",
      start: "",
      end: "",
      type: "",
      type_transaction: "",
      date: "",
      agent_id: "",
      manager_id: "",
      sub_agent_id: "",
      player_id: "",
      value: "",
      slot: "",
      vinculations: "",
      is_duplicate: "",
      selected_types_low: "",
    },
    notification: {
      show: false,
      message: "",
      type: "",
    },
    overlay: false,

    paginationCredit: {
      page: 1,
      itemsPerPage: 10,
    },
    paginationTrade: {
      page: 1,
      itemsPerPage: 10,
    },
  },
  mutations: {
    showNotification: (state, payload) => (state.notification = payload),
    showLoading: (state, payload) => {
      state.loading = payload.show;
    },
    setTotalItemsCredit: (state, payload) => (state.totalItemsCredit = payload.total),
    setTotalItemsTrade: (state, payload) => (state.totalItemsTrade = payload.total),
    setTotalItemsDuplicated: (state, payload) => (state.totalItemsDuplicated = payload.total),
    setItemsCredit: (state, payload) => {
      state.itemsCredit = payload.items
    },
    setItemsTrade: (state, payload) => {
      state.itemsTrade = payload.items
    },
    setItemsDuplicated: (state, payload) => {
      state.itemsDuplicated = payload.items
    },
    closeDialog: (state) => {
      state.dialog = false
    },
    view: (state, payload) => {
      state.selectedItem = payload;
      state.mode = "VIEW";
      state.dialog = true;
    },
    clearFilter: (state) => {
      state.filter = Object.assign({}, defaultFilter)
    },
    setShowFilter: (state, payload) => {
      if (payload.type == 'trader') {
        state.filterDialogTrader = payload.show;
      } else if (payload.type == 'credit') {
        state.filterDialog = payload.show;
      }
    },
    setFiltered: (state, payload) => state.filtered = payload.filtered,


    updateField,
  },


  actions: {
    getItems: async ({ commit, state, rootGetters }, payload) => {
      let type = payload.type
      let duplicate = payload.is_duplicate
      let period = rootGetters['period_conciliation/getPeriodSelected']
      if (period == 0 || period == null || period == undefined || period == "") {
        period = location.href.split('period_conciliation/')[1]
      }
      let filter = state.filter;
      let itemsPerPage, page, sortBy = '', sortDesc = '';
      if (type == 0) {
        if (state.paginationCredit.sortBy && state.paginationCredit.sortBy.length > 0) {
          sortBy = state.paginationCredit.sortBy[0]
          sortDesc = state.paginationCredit.sortDesc[0]
        }
        itemsPerPage = state.paginationCredit.itemsPerPage
        page = state.paginationCredit.page
      } else {
        if (state.paginationTrade.sortBy && state.paginationTrade.sortBy.length > 0) {
          sortBy = state.paginationTrade.sortBy[0]
          sortDesc = state.paginationTrade.sortDesc[0]
        }
        itemsPerPage = state.paginationTrade.itemsPerPage
        page = state.paginationTrade.page
      }

      if (itemsPerPage == -1) {
        itemsPerPage = 9999
      }

      let url = `${endPoints.conciliation}/period_conciliation_transactions/${period}?page=${page}&itemsPerPage=${itemsPerPage}`;

      filter.type = type
      filter.is_duplicate = duplicate
      Object.keys(filter).forEach(function (field) {
        if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
          // commit('setFiltered', {filtered: true})

          if (field == "end" || field == "start" || field == "date") {
            var e = new Date(filter[field])
            url += "&" + field + "=" + formatDate(e) + " " + e.toLocaleTimeString()
          }
          else if (field == "value") {
            var v = filter[field].replace(",", ".")
            url += "&" + field + "=" + v
          }
          else {
            url += "&" + field + "=" + filter[field]
          }
        }
      });

      if (sortBy != '' && sortDesc != '') {

        url += "&sort_by=" + sortBy
        url += "&sort_desc=" + sortDesc
      }
      commit("showLoading", { show: true });

      try {
        let items = await axios.get(url);
        if (type == 0) {

          commit("setItemsCredit", { items: items.data.data });
          commit("setTotalItemsCredit", { total: items.data.total });
        } else {
          if (duplicate == 1){
            commit("setItemsDuplicated", { items: items.data.data });
            commit("setTotalItemsDuplicated", { total: items.data.total });
          }else{
            commit("setItemsTrade", { items: items.data.data });
            commit("setTotalItemsTrade", { total: items.data.total });
          }
        }
      } catch (error) {
        commit(
          "showNotification",
          defaultErrorNotification(error?.response?.data?.message)
        );
      }
      commit("showLoading", { show: false });
    },
    deleteItem: async ({ commit, dispatch, state }) => {
      commit("showLoading", { show: true });
      try {
        await axios.delete(`${endPoints.conciliation}/period_conciliation/${state.selectedItem.id}`);

        commit("showLoading", { show: false });
        commit("showDeleteDialog", { show: false });
        commit("setSelectedItem", {});
        commit(
          "showNotification",
          defaultSuccessNotification("Periodo de conciliação removido com sucesso")
        );

        return dispatch("getItems");
      } catch (error) {
        commit(
          "showNotification",
          defaultErrorNotification(error?.response?.data?.message)
        );
        commit("showLoading", { show: false });
      }
    },
  },
  getters: {
    getField,
  },
};
