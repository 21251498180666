<template>
  <div>
    <v-card>
        <v-card-title>
          <span class="headline text-center">Filtro</span>
          <v-spacer></v-spacer>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    clearable
                    label="ID *"
                    v-model="id_app"
                    data-vv-name="id_app"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    clearable
                    label="Contato *"
                    v-model="contact_id"
                    data-vv-name="contact_id"
                    outlined
                  >
                  </v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    clearable
                    label="CPF / CNPJ *"
                    v-model="inscription"
                    data-vv-name="inscription"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    clearable
                    label="Valor *"
                    v-model="value"
                    data-vv-name="value"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    clearable
                    label="ID Depósito *"
                    v-model="deposit_id"
                    data-vv-name="deposit_id"
                    outlined
                  ></v-text-field>
                </v-flex>
            
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    clearable
                    label="ID Depósito Origem *"
                    v-model="deposit_id_origin"
                    data-vv-name="deposit_id_origin"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    clearable
                    type="number"
                    label="ID Saque *"
                    v-model="withdrawal_id"
                    data-vv-name="withdrawal_id"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    clearable
                    type="number"
                    label="Mãos Jogados *"
                    v-model="hands_played"
                    data-vv-name="hands_played"
                    outlined
                  ></v-text-field>
                </v-flex>

              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn text @click="showFilter({show: false})">Cancelar</v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn text type="submit" right @click="filter">Filtrar</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import debounce from "../../../util/debounce.js";

export default {
  name: "RoutesFilter",
  computed: {
    ...mapFields("bonus", [
      "filter.value",
      "filter.inscription",
      "filter.contact_id",
      "filter.id_app",
      "filter.hands_played",
      "filter.deposit_id",
      "filter.deposit_id_origin",
      "filter.withdrawal_id"
      // ...
    ]),
  },
  mixins: [debounce],
  mounted() {
    this.getChildrenSearch = this.debounce(this.getChildrenSearch, 500);
  },
  methods: {
    ...mapMutations("bonus", ["showFilter"]),
    ...mapActions("bonus", [
      "getChildrenSearch",
      "getBonus",
      "getAttendants",
      "getUsers",
      "getAgents",
      "closeDialog",
      "save",
    ]),
    filter() {
      this.getBonus({ resetPage: true });
      this.showFilter({ show: false });
    },
  },
};
</script>

<style>
</style>