const defaultItem = {
    contact_id: null,
    name: "",
    address_state: null,
    address_city: null,
    address_cep: null,
    address_neighborhood: null,
    address: null,
    address_number: null,
    address_complement: null,
    status: 0,
    tracking_code: null,
    date_created: '',
    receipt_file: {},
    receipt: ''
}

export default defaultItem