<template>
    <div>
        <v-snackbar
            :color="notification.type"
            right
            top
            :timeout="2000"
            v-model="notification.show"
        >
            {{ notification.message }}
        </v-snackbar>

        <v-list-item
            class="
                card-agent
                justify-space-between
                elevation-3
                agent-data
                rounded
            "
        >
            <v-list-item-content>
                <div>
                    <v-btn
                        color="transparent"
                        elevation="0"
                        class="pa-0"
                        @click="goBack"
                        small
                        ><v-icon>mdi-chevron-left</v-icon>Voltar
                    </v-btn>
                </div>
            </v-list-item-content>

            <div min-width="200">
                <v-list-item-content class="text-center">
                    <v-list-item-title class="headline font-weight-medium">
                        {{ client.name }}
                    </v-list-item-title>

                    <v-list-item-subtitle>
                        <div>ID: {{ client.id }}</div>
                    </v-list-item-subtitle>
                </v-list-item-content>
            </div>

            <v-list-item-content class="text-end justify-end">
            </v-list-item-content>
        </v-list-item>

        <template>
            <v-card class="rounded-lg pb-8 px-8 pt-4">
                <div color="grey" class="elevation-0 rounded-lg">
                    <v-tabs v-model="tab" class="agent-data">
                        <v-tab>Dados</v-tab>
                        <v-tab>Anotados</v-tab>
                        <v-tab>Agentes</v-tab>
                        <v-tab>Grupos</v-tab>
                        <v-tab>Plataformas</v-tab>
                        <v-tab>Cadastros</v-tab>
                    </v-tabs>
                </div>

                <v-tabs-items v-model="tab" class="">
                    <v-tab-item class="elevation-10">
                        <v-toolbar class="mt-2 mb-5 elevation-0 px-3git">
                            <v-toolbar-title class="title"
                                >Dados do Cliente</v-toolbar-title
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                v-if="this.mode == 'VIEW'"
                                color="#373B40"
                                dark
                                elevation="0"
                                class=""
                                @click="edit(selectedItem)"
                                small
                            >
                                <span color="white">Editar</span>
                            </v-btn>
                        </v-toolbar>

                        <v-card>
                            <v-card-text>
                                <v-form ref="form" lazy-validation>
                                    <v-container grid-list-md>
                                        <v-layout wrap>
                                            <v-flex xs12 sm6 md6>
                                                <v-select
                                                    label="Tipo *"
                                                    v-model="type"
                                                    :items="possible_types"
                                                    item-text="name"
                                                    item-value="id"
                                                    :readonly="
                                                        this.mode == 'VIEW'
                                                    "
                                                    @chage="setTypeVerification"
                                                    required
                                                    outlined
                                                ></v-select>
                                            </v-flex>

                                            <template v-if="isPeoplePerson()">
                                                <v-flex xs12 sm6 md6>
                                                    <v-text-field
                                                        v-model="name"
                                                        label="Nome *"
                                                        :readonly="
                                                            this.mode == 'VIEW'
                                                        "
                                                        :rules="rulesFields"
                                                        required
                                                        outlined
                                                    ></v-text-field>
                                                </v-flex>
                                            </template>

                                            <template v-else>
                                                <v-flex xs12 sm12 md12>
                                                    <v-text-field
                                                        v-model="name"
                                                        label="Nome Fantasia *"
                                                        :readonly="
                                                            this.mode == 'VIEW'
                                                        "
                                                        :rules="rulesFields"
                                                        required
                                                        outlined
                                                    ></v-text-field>
                                                </v-flex>
                                                <v-flex xs12 sm12 md12>
                                                    <v-text-field
                                                        v-model="corporate_name"
                                                        label="Razão Social *"
                                                        :readonly="
                                                            this.mode == 'VIEW'
                                                        "
                                                        :rules="rulesFields"
                                                        required
                                                        outlined
                                                    ></v-text-field>
                                                </v-flex>
                                            </template>

                                            <template>
                                                <v-flex xs12 sm4 md4>
                                                    <v-text-field
                                                        v-model="nickname"
                                                        label="Apelido"
                                                        :readonly="
                                                            this.mode == 'VIEW'
                                                        "
                                                        outlined
                                                    ></v-text-field>
                                                </v-flex>
                                            </template>

                                            <v-flex xs12 sm6 md3>
                                                <v-text-field
                                                    v-if="isPeoplePerson()"
                                                    v-model="inscription"
                                                    label="CPF *"
                                                    :readonly="
                                                        this.mode == 'VIEW'
                                                    "
                                                    required
                                                    outlined
                                                ></v-text-field>
                                                <v-text-field
                                                    v-else
                                                    label="CNPJ *"
                                                    v-model="inscription"
                                                    :rules="rulesFields"
                                                    :readonly="
                                                        this.mode == 'VIEW'
                                                    "
                                                    required
                                                    outlined
                                                ></v-text-field>
                                            </v-flex>

                                            <v-flex xs12 sm6 md3>
                                                <v-text-field
                                                    v-if="isPeoplePerson()"
                                                    v-model="birth_date"
                                                    type="date"
                                                    label="Data de Nascimento"
                                                    :readonly="
                                                        this.mode == 'VIEW'
                                                    "
                                                    required
                                                    outlined
                                                ></v-text-field>

                                                <v-text-field
                                                    v-else
                                                    v-model="birth_date"
                                                    type="date"
                                                    label="Data de Abertura"
                                                    :readonly="
                                                        this.mode == 'VIEW'
                                                    "
                                                    required
                                                    outlined
                                                ></v-text-field>
                                            </v-flex>

                                            <v-flex xs12 sm6 md2>
                                                <v-text-field
                                                    v-model="cep"
                                                    label="CEP *"
                                                    :readonly="
                                                        this.mode == 'VIEW'
                                                    "
                                                    :rules="rulesFields"
                                                    required
                                                    outlined
                                                    @blur="searchAddress"
                                                ></v-text-field>
                                            </v-flex>

                                            <v-flex xs12 sm6 md4>
                                                <v-text-field
                                                    v-model="address"
                                                    label="Endereço *"
                                                    :readonly="
                                                        this.mode == 'VIEW'
                                                    "
                                                    :rules="rulesFields"
                                                    required
                                                    outlined
                                                ></v-text-field>
                                            </v-flex>

                                            <v-flex xs12 sm6 md2>
                                                <v-text-field
                                                    v-model="number"
                                                    label="Número *"
                                                    :readonly="
                                                        this.mode == 'VIEW'
                                                    "
                                                    :rules="rulesFields"
                                                    required
                                                    outlined
                                                ></v-text-field>
                                            </v-flex>

                                            <v-flex xs12 sm6 md6>
                                                <v-text-field
                                                    v-model="complement"
                                                    label="Complemento"
                                                    :readonly="
                                                        this.mode == 'VIEW'
                                                    "
                                                    outlined
                                                ></v-text-field>
                                            </v-flex>

                                            <v-flex xs12 sm6 md4>
                                                <v-text-field
                                                    v-model="district"
                                                    label="Bairro *"
                                                    :readonly="
                                                        this.mode == 'VIEW'
                                                    "
                                                    :rules="rulesFields"
                                                    required
                                                    outlined
                                                ></v-text-field>
                                            </v-flex>

                                            <v-flex xs12 sm6 md4>
                                                <v-text-field
                                                    v-model="city"
                                                    label="Cidade *"
                                                    :readonly="
                                                        this.mode == 'VIEW'
                                                    "
                                                    :rules="rulesFields"
                                                    required
                                                    outlined
                                                ></v-text-field>
                                            </v-flex>

                                            <v-flex xs12 sm6 md2>
                                                <v-autocomplete
                                                    v-model="state"
                                                    :items="states"
                                                    label="Estado *"
                                                    :readonly="
                                                        this.mode == 'VIEW'
                                                    "
                                                    :rules="rulesFields"
                                                    required
                                                    outlined
                                                ></v-autocomplete>
                                            </v-flex>

                                            <v-flex xs12 sm6 md2>
                                                <v-autocomplete
                                                    v-model="country"
                                                    :items="countries"
                                                    item-text="common"
                                                    item-value="common"
                                                    label="País *"
                                                    :readonly="
                                                        this.mode == 'VIEW'
                                                    "
                                                    :rules="rulesFields"
                                                    required
                                                    outlined
                                                ></v-autocomplete>
                                            </v-flex>

                                            <v-flex xs12 sm md3>
                                                <vue-tel-input-vuetify
                                                    outlined
                                                    label="Phone"
                                                    v-model="contact"
                                                    @input="onInput"
                                                ></vue-tel-input-vuetify>
                                            </v-flex>

                                            <v-flex xs12 sm6 md6>
                                                <v-select
                                                    label="Situação "
                                                    :items="situations"
                                                    v-model="situation"
                                                    :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                                                    :error-messages="errors.collect('situation')"
                                                    v-validate="'required:Situação,situation'"
                                                    item-text="description"
                                                    item-value="id"
                                                    data-vv-name="situation"
                                                    outlined
                                                ></v-select>
                                            </v-flex>
                                        </v-layout>
                                    </v-container>
                                </v-form>
                            </v-card-text>

                            <v-card-actions>
                                <v-col>
                                    <v-btn text @click="cancelEdit('VIEW')">{{
                                        mode == 'VIEW' ? '' : 'Cancelar'
                                    }}</v-btn>
                                </v-col>
                                <v-col class="text-right">
                                    <v-btn
                                        color="blue"
                                        dark
                                        elevation="0"
                                        type="submit"
                                        right
                                        v-if="mode == 'EDIT'"
                                        @click="saveRelease"
                                        >Salvar</v-btn
                                    >
                                </v-col>
                            </v-card-actions>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-toolbar class="mt-2 mb-5 elevation-0 px-3git">
                            <v-toolbar-title class="title"
                                >Informações sobre Anotados</v-toolbar-title
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                v-if="this.mode == 'VIEW'"
                                color="#373B40"
                                dark
                                elevation="0"
                                class=""
                                @click="edit(selectedItem)"
                                small
                            >
                                <span color="white">Editar</span>
                            </v-btn>
                        </v-toolbar>

                        <v-card>
                            <v-card-text>
                                <v-form ref="form" lazy-validation>
                                    <v-container grid-list-md>
                                        <v-layout wrap>
                                            <v-flex xs12 sm6 md6>
                                                <v-select
                                                    label="Tipo de Pagamento "
                                                    v-model="payment_for"
                                                    :items="type_of_payment"
                                                    item-text="name"
                                                    item-value="id"
                                                    :readonly="
                                                        this.mode == 'VIEW'
                                                    "
                                                    @chage="setTypeVerification"
                                                    required
                                                    outlined
                                                ></v-select>
                                            </v-flex>

                                            <template v-if="isPeoplePerson()">
                                                <v-flex xs12 sm6 md6>
                                                    <v-text-field
                                                        v-model="
                                                            limit_annotated
                                                        "
                                                        prefix="R$"
                                                        label="Limite de Anotados"
                                                        :readonly="
                                                            this.mode == 'VIEW'
                                                        "
                                                        :rules="rulesFields"
                                                        required
                                                        outlined
                                                    ></v-text-field>
                                                </v-flex>
                                            </template>
                                        </v-layout>
                                    </v-container>
                                </v-form>
                            </v-card-text>
                            <v-card-actions>
                                <v-col>
                                    <v-btn text @click="cancelEdit('VIEW')">{{
                                        mode == 'VIEW' ? '' : 'Cancelar'
                                    }}</v-btn>
                                </v-col>
                                <v-col class="text-right">
                                    <v-btn
                                        color="blue"
                                        dark
                                        elevation="0"
                                        type="submit"
                                        right
                                        v-if="mode == 'EDIT'"
                                        @click="saveRelease"
                                        >Salvar</v-btn
                                    >
                                </v-col>
                            </v-card-actions>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <agents-data></agents-data>
                    </v-tab-item>
                    <v-tab-item>
                        <client-groups></client-groups>
                    </v-tab-item>
                    <v-tab-item>
                        <client-apps></client-apps>
                    </v-tab-item>
                    <v-tab-item>
                        <clients-registrations></clients-registrations>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </template>
    </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import AgentsData from './agent_data/AgentsData.vue'
import ClientGroups from './clients_groups/ClientGroups.vue'
import ClientApps from './clients_apps/ClientApps.vue'
import ClientsRegistrations from "./clients_registrations/ClientsRegistrations.vue"

export default {
    name: 'Client',

    created() {
        this.loadClient()
        this.getClientIdApps()
    },

    components: {
        'agents-data': AgentsData,
        'client-groups': ClientGroups,
        'client-apps': ClientApps,
        'clients-registrations': ClientsRegistrations,
    },

    computed: {
        ...mapState('clients', [
            'loading',
            'notification',
            'client',
            'mode',
            'selectedItem',
            'type_of_payment',
            'showDialog',
            'states',
            'countries',
        ]),
        ...mapState('agents', ['items']),

        ...mapFields('clients', [
            'showNotification',
            'selectedItem.id',
            'selectedItem.type',
            'selectedItem.inscription',
            'selectedItem.name',
            'selectedItem.nickname',
            'selectedItem.birth_date',
            'selectedItem.date_picker',
            'selectedItem.corporate_name',
            'selectedItem.country',
            'selectedItem.cep',
            'selectedItem.payment_for',
            'selectedItem.limit_annotated',
            'selectedItem.district',
            'selectedItem.address',
            'selectedItem.number',
            'selectedItem.city',
            'selectedItem.state',
            'selectedItem.complement',
            'selectedItem.contact',
            'selectedItem.whatsapp_group',
            'selectedItem.situation'
        ]),
    },

    methods: {
        ...mapActions('agent', ['getAgentsLinkedToClient']),
        ...mapActions('client', ['goBack', 'getClientIdApps']),
        ...mapActions('clients', [
            'getClient',
            'updateSelectedItem',
            'save',
            'getCep',
            'getStates',
            'getCountries',
            'viewItem',
        ]),
        ...mapMutations('clients', [
            'setShowDialog',
            'setSelectedItem',
            'setShowDialog',
            'edit',
            'setSelectedItem',
            'view',
            'setMode',
        ]),
        ...mapMutations('client', ['setClientId']),

        saveRelease() {
            this.$validator.validateAll().then(async (valid) => {
                if (valid) {
                    if(this.contactToFormat) {
                        this.selectedItem.contact = this.contactToFormat
                    } 
                    this.contact = parseInt(this.selectedItem.contact)
                    await this.save()
                    this.loadClient()
                }
            })
        },

        async loadClient() {
            let client_id = this.$route.params.client_id

            this.setClientId(client_id)
            await this.getClient({ id: client_id })
            this.view(this.client)
            this.getStates()
            this.getCountries()
        },

        onInput(formattedNumber, item) {
            if (item.number.e164) {
                this.contactToFormat = parseFloat(
                    item.number.e164.replace(/\+/g, ''),
                )
            }
        },

        cancelEdit() {
            this.setMode()
            this.loadClient()
        },

        searchAddress() {
            this.getCep()
        },

        isPeoplePerson() {
            return (
                this.selectedItem.type == 0 ||
                this.selectedItem.type == undefined
            )
        },

        setTypeVerification() {
            this.typeVerification = this.selectedItem.type
        },
    },

    data: () => ({
        tab: '',
        contactToFormat: '',
        rulesFields: [(value) => !!value || 'Campo obrigatório'],
        possible_types: [
            {
                id: 0,
                name: 'Pessoa Física',
            },
            {
                id: 1,
                name: 'Pessoa Juridica',
            },
        ],
        situations: [
            {
                id: 0,
                description: "Inadimplente"
            }, 
            {
                id: 1,
                description: "Em Acordo"
            },
            {
                id: 2,
                description: "Rotativo Semanal"
            },
            {
                id: 3,
                description: "Ativo"
            },
            { 
                id: 4, 
                description: "Não Definido"
            }
        ],
    }),

    watch: {},
}
</script>
