import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../../config/endPoints';

export default {
    namespaced: true,
    state: {
        balance_agency: 0,
        balance_player: 0,
        items: [],
        items_club: [],
        loading: false,
        overlay: false,
        pagination: {
            page: 1,
            itemsPerPage: 10
        },
        totalItems: 0
    },
    mutations: {
        loading: (state, payload) => state.loading = payload.loading,
        setBalanceAgency: (state, payload) => {
            state.balance_agency = payload.balance_agency
            console.log(state.balance_agency)
        },
        setBalancePlayer: (state, payload) => {
            state.balance_player = payload.balance_player
        },
        setItems: (state, payload) => {
            state.items = payload.items
        },
        setItemsClub: (state, payload) => {
            state.items_club = payload.items
        },
        overlay: (state, payload) => {
            state.overlay = payload.show
        },
        setTotalItems: (state, payload) => state.totalItems = payload.total,
        setTotalItemsClub: (state, payload) => state.totalItemsClub = payload.total,
        updateField
    },
    getters: {
        getField,
    },
    actions: {
        getItems: ({ commit, rootGetters, state }) => {
            commit('loading', { loading: true })
            let url = endPoints.balance_agent
            console.log(url)
            url += `/${rootGetters["manager/getIdManager"]}`
            url += '?page=' + state.pagination.page + '&' + 'itemsPerPage=' + state.pagination.itemsPerPage + "&type=1";
            axios.get(url).then(response => {
                let data = response.data != null ? response.data : []
       
                commit('setBalanceAgency', { balance_agency: data.balance_agent })
                commit('setBalancePlayer', { balance_player: data.balance_player })
                commit('loading', { loading: false })
                commit('setTotalItems', { total: data.total })
                commit('setItems', { items: data.data })
            });
        },
        getItemsByClub: ({ commit, rootGetters }) => {
            commit('loading_club', { loading: true })
            let url = endPoints.balance_agent_club
            url += `/${rootGetters["manager/getIdManager"]}&type=1`
            axios.get(url).then(response => {
                let data = response.data != null ? response.data : []
                commit('loading_club', { loading: false })
                commit('setItemsClub', { items: data.data })
                commit('setTotalItemsClub', { total: data.length })
            });
        }
    }
}