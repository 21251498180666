<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline text-center justify-center">Filtro</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm6 md6>
                <v-menu
                  v-model="showStart"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="start_formatted"
                      label="De"
                      outlined
                      readonly
                      append-icon="mdi-close"
                      @click:append="clearStart()"
                      persistent-hint
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="pt-br"
                    v-model="start"
                    no-title
                    @input="(date) => setStart(date)"
                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-menu
                  v-model="showEnd"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="end_formatted"
                      label="Até"
                      outlined
                      readonly
                      append-icon="mdi-close"
                      @click:append="clearEnd()"
                      persistent-hint
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="pt-br"
                    v-model="end"
                    no-title
                    @input="(e) => setEnd(e)"
                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-text-field
                  label="ID Blacklist"
                  v-model="id_blacklist"
                  clearable
                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-select
                  label="Tipo de Bloqueio"
                  v-model="blocked_type"
                  :items="possible_blocked_types"
                  item-text="name_blocked_type"
                  item-value="blocked_type"
                  clearable
                  outlined
                ></v-select>
              </v-flex>

              <v-flex v-if="this.blocked_type == 3" xs12 sm6 md6>
                <v-select
                  label="Tipo PIX"
                  v-model="pix_type"
                  :items="possible_pix_types"
                  item-text="name_pix_type"
                  item-value="pix_type"
                  clearable
                  outlined
                ></v-select>
              </v-flex>
              
              <v-flex xs12 sm12 md12>
                <v-textarea
                  label="Observação"
                  v-model="observation"
                  :rules="[rules.max_length(observation, 300)]"
                  rows="1"
                  maxlength="300"
                  hint="Insira no máximo 300 caracteres"
                  counter
                  outlined
                ></v-textarea>
              </v-flex>

            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn
            text
            @click="clearFilter()"
            >Cancelar</v-btn
          >
        </v-col>
        <v-col class="text-right">
          <v-btn text type="submit" right @click="filterItems">Filtrar</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "WithdrawalsBlacklistFilter",
  computed: {
    ...mapState("withdrawals_blacklist", [
      "mode",
      "possible_blocked_types",
      "possible_pix_types",
      "selectedItem",
    ]),
    ...mapFields("withdrawals_blacklist", [
      "filter.id_blacklist",
      "filter.blocked_type",
      "filter.observation",
      "filter.pix_type",
      "filter.start",
      "filter.end",
    ]),
  },
  methods: {
    ...mapMutations("withdrawals_blacklist", ["setDialog"]),
    ...mapActions("withdrawals_blacklist", ["getItems", "clearFilter"]),
    filterItems() {
      this.getItems();
    },
    setStart(date) {
      this.start = date;
      this.showStart = false;
      this.start_formatted = this.formatDate(date);
    },
    setEnd(date) {
      this.end = date;
      this.showEnd = false;
      this.end_formatted = this.formatDate(date);
    },
    clearStart() {
      this.start = "";
      this.start_formatted = "";
    },
    clearEnd() {
      this.end = "";
      this.end_formatted = "";
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    },
  },
  data: () => ({
    showStart: false,
    showEnd: false,
    start_formatted: "",
    end_formatted: "",
    rules: {
      required(value) { 
        return !!value || 'Campo Obrigatório'
      },
      requiredAcceptZero(value) { 
        return !!value || value === 0 || 'Campo Obrigatório'
      },
      max_length(value, max_length) {
        if(value && value.length > max_length) return 'Máximo de ' + max_length + ' caracteres.'
        return true
      },
    }
  }),
};
</script>
