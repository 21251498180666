<template>
  <div>
    <v-dialog :value="dialog" persistent width="600">
      <v-card>
        <v-card-title>
          <span class="headline">Vaga</span>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm6 md6>
                  <v-datetime-picker
                    v-model="start_date"
                    required
                    label="Data Inicial*"
                    date-format="dd/MM/yyyy"
                    :disabled="this.mode == 'VIEW'"
                    :time-picker-props="timeProps"
                    :textFieldProps="{ outlined: true }"
                  >
                    <template slot="dateIcon">
                      <v-icon>mdi-calendar</v-icon>
                    </template>
                    <template slot="timeIcon">
                      <v-icon>mdi-clock</v-icon>
                    </template>
                  </v-datetime-picker>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-autocomplete
                    clearable
                    v-model="requester"
                    :no-filter="true"
                    :items="collaborators"
                    :disabled="this.mode == 'VIEW'"
                    chips
                    small-chips
                    no-data-text="Nenhum colaborador encontrado"
                    item-text="name"
                    item-value="id"
                    label="Solicitante*"
                    outlined
                    :search-input.sync="searchInput"
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-autocomplete
                    clearable
                    v-model="responsible"
                    :no-filter="true"
                    :items="collaboratorsByDepartments"
                    :disabled="this.mode == 'VIEW'"
                    chips
                    small-chips
                    no-data-text="Nenhum colaborador encontrado"
                    item-text="name"
                    item-value="id"
                    label="Responsável"
                    outlined
                    :search-input.sync="searchInputResponsible"
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-select
                    label="Setor *"
                    :items="departments"
                    v-model="department"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('department')"
                    v-validate="'required:Setor,department'"
                    data-vv-name="department"
                    item-text="name"
                    item-value="id"
                    outlined
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-select
                    label="Cargo *"
                    :items="roles"
                    v-model="role"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('role')"
                    v-validate="'required:Cargo,role'"
                    data-vv-name="role"
                    item-text="name"
                    item-value="id"
                    outlined
                  ></v-select>
                </v-flex>
                 <v-flex v-if="this.finish_date !== null && this.finish_date !== ''" xs12 sm6 md6>
                  <v-datetime-picker
                    v-model="finish_date"
                    required
                    label="Data Final"
                    date-format="dd/MM/yyyy"
                    :disabled="this.mode == 'VIEW'"
                    :time-picker-props="timeProps"
                    :textFieldProps="{ outlined: true }"
                  >
                    <template slot="dateIcon">
                      <v-icon>mdi-calendar</v-icon>
                    </template>
                    <template slot="timeIcon">
                      <v-icon>mdi-clock</v-icon>
                    </template>
                  </v-datetime-picker>
                </v-flex>
                 <v-flex v-if="this.cust !== null && this.cust !== ''" xs12 sm6 md6>
                  <v-text-field
                    label="Custo da Vaga"
                    v-model="cust"
                    prefix="R$"
                    disabled
                    :readonly="this.mode == 'VIEW'"
                    data-vv-name="Descrição"
                    outlined
                  ></v-text-field>
                </v-flex>

              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-col>
            <v-btn text @click="setShowDialog(false)">{{
              mode == "VIEW" ? "Voltar" : "Cancelar"
            }}</v-btn>
          </v-col>
          <v-col class="text-right" v-if="mode != 'VIEW'">
            <v-btn text type="submit" right @click="saveJob">Salvar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "JobsForm",
  methods: {
    ...mapActions("jobs", [
      "save",
      "getSearchCollaborators",
      "getSearchCollaboratorsByDepartment"
    ]),
    ...mapMutations("jobs", ["setShowDialog"]),
    saveJob() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          var d = new Date(this.start_date);
          this.start_date =
            d.toISOString().substr(0, 10) + " " + d.toLocaleTimeString();
          this.value = parseFloat(this.value);

          if (this.finish_date != null && this.finish_date != "" && this.finish_date != undefined) {
            this.finish_date =
              this.finish_date.toISOString().substr(0, 10) +
              " " +
              this.finish_date.toLocaleTimeString();
            this.value = parseFloat(this.value);
          }

          this.save();
        }
      });
    },
  },
  computed: {
    ...mapState("jobs", [
      "mode",
      "selectedItem",
      "dialog",
      " errors",
      "departments",
      "roles",
      "collaborators",
      "collaboratorsByDepartments",
    ]),
    ...mapFields("jobs", [
      "selectedItem.cust",
      "selectedItem.start_date",
      "selectedItem.finish_date",
      "selectedItem.responsible",
      "selectedItem.requester",
      "selectedItem.role",
      "selectedItem.department",
    ]),
  },
  data: () => ({
    valid: false,
    timeProps: {
      format: "24hr",
    },
    searchInput: "",
    searchInputResponsible: "",
  }),
  watch: {
    searchInput: {
      handler() {
        if (this.searchInput) {
          var payload = {
            search: this.searchInput,
          };
          this.getSearchCollaborators(payload);
        }
      },
    },
    searchInputResponsible: {
      handler() {
        if (this.searchInputResponsible) {
          var payload = {
            search: this.searchInputResponsible,
            department: 7
          };
          this.getSearchCollaboratorsByDepartment(payload);
        }
      },
    },
  },
};
</script>