<template>
  <div @drop.prevent="addDropFile" @dragover.prevent>
    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2500"
      v-model="notificationVisible"
    >{{ notificationMessage }}</v-snackbar>
      <v-card>
        <v-container class="elevation-10 status" wrap>
          <v-layout wrap>
              <v-flex xs12 sm12 md12>
                <v-select
                    :clearable="true"
                    clear-icon="mdi-close"
                    label="Tipo"
                    :items="types_message"
                    v-model="type_message"
                    item-text="description"
                    item-value="id"
                    outlined
                  ></v-select>
              </v-flex>
              <v-flex xs12 sm6 md6 v-if="type_message != 1">
                <v-datetime-picker
                    v-model="start_date"
                    date-format="dd/MM/yyyy"
                    :time-picker-props="timeProps"
                    label="De"
                >
                    <template slot="dateIcon">
                    <v-icon>mdi-calendar</v-icon>
                    </template>
                    <template slot="timeIcon">
                    <v-icon>mdi-clock</v-icon>
                    </template>
                </v-datetime-picker>
              </v-flex>
              <v-flex xs12 sm6 md6 v-if="type_message != 1">
                  <v-datetime-picker
                      v-model="finish_date"
                      date-format="dd/MM/yyyy"
                      :time-picker-props="timeProps"
                      label="Até"
                  >
                      <template slot="dateIcon">
                      <v-icon>mdi-calendar</v-icon>
                      </template>
                      <template slot="timeIcon">
                      <v-icon>mdi-clock</v-icon>
                      </template>
                  </v-datetime-picker>
              </v-flex>
              <v-flex xs12 sm6 md6>
                  <v-select
                      :clearable="true"
                      clear-icon="mdi-close"
                      label="Clube *"
                      :items="instancesChat"
                      v-model="instance_id"
                      item-text="name"
                      item-value="id"
                      outlined
                      :rules="rulesFields"
                  ></v-select>
              </v-flex>
              <v-flex xs12 sm6 md6>
                  <v-select
                      :clearable="true"
                      clear-icon="mdi-close"
                      label="Clube Envio"
                      :items="instancesChat"
                      v-model="instance_send"
                      item-text="name"
                      item-value="id"
                      outlined
                      :rules="rulesFields"
                  ></v-select>
              </v-flex>
              <v-flex xs12 sm4 md4>
                  <v-select
                      :clearable="true"
                      clear-icon="mdi-close"
                      label="Grupo"
                      :items="is_groups"
                      v-model="is_group"
                      item-text="description"
                      item-value="id"
                      outlined
                      :rules="rulesFields"
                  ></v-select>
              </v-flex>
              <v-flex xs12 sm4 md4>
                    <v-text-field
                      required
                      label="Tag *"
                      v-model="tag"
                      outlined
                      :rules="rulesFields"
                    ></v-text-field>  
              </v-flex>
              <v-flex xs12 sm4 md4>
                    <v-text-field
                      required
                      label="Limite *"
                      v-model="limit"
                      outlined
                      type="number"
                      :rules="rulesFields"
                    ></v-text-field>  
              </v-flex>
              <v-flex xs12 sm12 md12>
                  <v-textarea
                    auto-grow
                    outlined
                    label="Texto *"
                    id="textareamessage"
                    hide-details="true"
                    rows="1"
                    v-model="text_message"
                    :value="text_message"
                    :rules="rulesFields"
                  ></v-textarea>
              </v-flex>
              <v-flex xs12 sm12 md12 class="mt-2">
                <v-file-input
                    v-model="image"
                    label="Imagem"
                    outlined
                    v-on:change="addFile(image)"
                  ></v-file-input>
              </v-flex>
              <v-flex xs12 sm12 md12 class="mt-2">
                <v-card height="200px" v-if="image">
                  <v-img max-height="100%"  :src="typeof image == 'string' ? image : image.fileBase64" contain></v-img>
                </v-card>
              </v-flex>
  
              <v-flex xs12 sm12 md12 class="ma-2 text-center justify-center">
                  Chats para envio: {{ chat_number }}
                  <v-btn color="green" outlined dark class="ma-2 text-center justify-center" @click="calcMassMessages">Calcular</v-btn>
                  <v-btn color="black" dark class="ma-2 text-center justify-center" @click="sendMessage">Enviar</v-btn>
              </v-flex>
          </v-layout>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import b64 from '../../../util/Base64'
import debounce from "../../../util/debounce.js";

export default {
  computed: {
    ...mapGetters("instances", ["instancesChat"]),
    ...mapFields("mass_messages", ["chat_number", "image", "is_group", "instance_id", "instance_send", "start_date", "finish_date", "limit", "tag", "text_message", "type_message",  "notificationVisible"]),
    ...mapState("mass_messages", [
      "notificationType",
      "notificationMessage",
    ])
  },
  created() {
    this.getInstances()
  },
  data: () => ({
    date: "",
    headers: [
      { text: "Número", value: "contact_id" },
      { text: "Grupo", value: "participant_phone" },
      { text: "Nome", value: "contact_name" },
      { text: "Atendente", value: "attendant_name"},
      { text: "Inicio", value: "receive_time" },
      { text: "Fim", value: "ended_time" },
      { text: "Tempo", value: "time" },
      { text: "1º Contato", value: "start_time" },
      { text: "Ações",  value: "actions", sortable: false, align: "center" },
    ],
    is_groups: [
        {id: 0, description: "Não"},
        {id: 1, description: "Sim"}
    ],
    initDate: "",
    menu1: false,
    menu2: false,
    startDate: "",
    endDate: "",
    timeProps: {
      format: "24hr",
    },
    types_message: [
      { id: 1, description: "Por Contato" },
      { id: 2, description: "Por Chat" }
    ]
  }),
  mixins: [debounce],
  mounted() {
    this.getAttendants = this.debounce(this.getAttendants, 500);
  },
  methods: {
    ...mapActions("instances", ["getInstances"]),
    ...mapActions("mass_messages", [
        "calcMassMessages",
        "sendMessage"
    ]),
    addDropFile(e) {
      if (e.dataTransfer) {
          if (e.dataTransfer.files[0]) {
              let image = e.dataTransfer.files[0];
              this.receipt_file = image
              b64(image).then((fileBase64) => {
                  image.fileBase64 = fileBase64
                  this.image = image
              })
          }
      }
    },
    addFile(image) {
      b64(image).then((fileBase64) => {
        this.image.fileBase64 = fileBase64
      })
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      }
      return false;
    },
    isGroup(contact_id) {
      return contact_id.indexOf("-") != -1
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");

      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
  watch: {
  },
};
</script>
