<template>
    <div>
        <v-toolbar dense flat class="">
            <div class="button-toolbar mt-4 mr-4">
                <v-tooltip bottom dense size="30">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="#E4E4E4"
                            elevation="0"
                            class="py-4"
                            @click="setSelectedRegistrys()"
                            v-bind="attrs"
                            v-on="on"
                            small
                        >
                            <v-icon small color="dark" class="mr-2"
                                >mdi-card-multiple</v-icon
                            >
                            Selecionar Registros
                        </v-btn>
                    </template>
                    <span>Múltiplos Registros</span>
                </v-tooltip>
            </div>
            <div class="button-toolbar mt-4 mr-5">
                <v-tooltip bottom dense size="30" v-if="this.selectedRegistrys">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="#E4E4E4"
                            elevation="0"
                            class="py-4"
                            @click="
                                setDialogManualLow({
                                    multiples: true,
                                    show: true,
                                    items: selected.map((item) => {
                                        return {
                                            id: item.id,
                                            new_total_vinculations:
                                                item.linked_to,
                                        }
                                    }),
                                })
                            "
                            v-bind="attrs"
                            v-on="on"
                            small
                        >
                            <v-icon small color="dark" class="mr-2"
                                >mdi-arrow-down-bold</v-icon
                            >
                            Baixa Manual
                        </v-btn>
                    </template>
                    <span>Baixa Manual</span>
                </v-tooltip>
            </div>
            <v-spacer></v-spacer>
            <div class="button-toolbar mt-4 mr-1">
                <v-tooltip bottom dense size="30">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="#E4E4E4"
                            elevation="0"
                            class="py-4"
                            @click="setShowFilter(true)"
                            v-bind="attrs"
                            v-on="on"
                            small
                        >
                            Filtrar
                            <v-icon small color="dark">mdi-filter</v-icon>
                        </v-btn>
                    </template>
                    <span>Filtro</span>
                </v-tooltip>
            </div>
        </v-toolbar>
        <v-data-table
            v-model="selected"
            item-key="id"
            :show-select="selectedRegistrys"
            :headers="headers"
            :options.sync="pagination"
            :server-items-length="totalItems"
            :items="items"
            :item-class="getClassColor"
            :footer-props="{
                'items-per-page-options': [10, 20, 50, 100],
                'items-per-page-text': 'Itens por página',
                'show-first-last-page': true,
                'page-text': `Página ${pagination.page} de ${
                    parseInt(totalItems / pagination.itemsPerPage) + 1
                }`,
            }"
        >
            <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ attrs, on }">
                        <v-icon
                            small
                            color="black"
                            class="mr-2"
                            v-bind="attrs"
                            v-on="on"
                            @click="openDialog(item)"
                            >mdi-information</v-icon
                        >
                    </template>
                    <span>Informações</span>
                </v-tooltip>
            </template>
            <template v-slot:[`item.type`]="{ item }">
                <span>{{ getTypeOrder(item.type) }}</span>
            </template>
            <template v-slot:[`item.date_created`]="{ item }">
                <span>{{ formatDate(item.date_created) }}</span>
            </template>
            <template v-slot:[`item.type_deal_agent`]="{ item }">
                <span>{{ getTypeDeal(item.type_deal_agent) }}</span>
            </template>
            <template v-slot:[`item.total_gains`]="{ item }">
                <span>{{ formatMoney(item.total_gains) }}</span>
            </template>
            <template v-slot:[`item.value_to_pay`]="{ item }">
                <span>{{ formatMoney(item.value_to_pay) }}</span>
            </template>
            <template v-slot:[`item.rakeback_spin`]="{ item }">
                <span>{{ formatMoney(item.rakeback_spin) }}</span>
            </template>
            <template v-slot:[`item.rakeback`]="{ item }">
                <span>{{ formatMoney(item.rakeback) }}</span>
            </template>
            <template v-slot:[`item.total_value_to_pay`]="{ item }">
                <span>{{ formatMoney(item.total_value_to_pay) }}</span>
            </template>
            <template v-slot:[`item.ligations`]="{ item }">
                <span>{{
                    formatTextVicnulations(
                        item.linked_to,
                        item.total_vinculations,
                    )
                }}</span>
            </template>
        </v-data-table>
        <v-dialog
            :value="dialogManualLow"
            @click:outside="setCloseDialogManualLow()"
            width="1280"
        >
            <PeriodsConciliationLowDialog />
        </v-dialog>
        <v-dialog
            :value="dialog"
            @click:outside="closeDialogVinculation"
            width="1280"
        >
            <PeriodConciliationVinculationsDialog />
        </v-dialog>

        <v-dialog
            :value="filterDialog"
            @click:outside="setShowFilter(false)"
            width="600"
        >
            <PeriodConciliationClosureFilter />
        </v-dialog>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { formatDate, formatMoney } from '@util/format_util'
import { mapFields } from 'vuex-map-fields'

export default {
    name: 'PeriodConciliationClosuresGrid',
    computed: {
        ...mapState('period_conciliation_closures', [
            'items',
            'loading',
            'totalItems',
            'headers',
            'getClassColor',
            'filterDialog',
        ]),
         ...mapState('periods_conciliation_low_dialog', [
            'closeAllDialogsManualLow',
        ]),
        ...mapState('period_conciliation_dialog_vinculation', [
            'closeAllDialogs',
        ]),
        ...mapFields('period_conciliation_closures', [
            'pagination',
            'selectedItem',
        ]),
    },
    components: {
        PeriodsConciliationLowDialog: () =>
            import(
                '../periods_conciliation_low_dialog/PeriodsConciliationLowDialog.vue'
            ),
        PeriodConciliationVinculationsDialog: () =>
            import(
                '../period_conciliation_dialog_vinculation/PeriodConciliationVinculationsDialog.vue'
            ),
        PeriodConciliationClosureFilter: () =>
            import('./PeriodConciliationClosureFilter.vue'),
    },
    created() {
        this.getItems()
    },
    methods: {
        ...mapMutations('periods_conciliation_low_dialog', [
            'showDialogManualLow',
        ]),
        ...mapActions('periods_conciliation_low_dialog', [
            'setCloseDialogManualLow',
        ]),
        ...mapActions('period_conciliation_dialog_vinculation', [
            'getVinculations',
        ]),
        ...mapMutations('period_conciliation_dialog_vinculation', [
            'closeDialog',
            'openVinculations',
            'buildItems',
        ]),
        ...mapActions('period_conciliation_closures', ['getItems']),
        ...mapMutations('period_conciliation_closures', [
            'view',
            'setShowFilter',
        ]),
        ...mapMutations('period_conciliation', ['showNotification']),

        formatDate(date) {
            return formatDate(date)
        },
        openDialog(item) {
            item.tab_origin = 5
            this.dialog = true
            this.openVinculations(item)
            this.getVinculations()
            this.buildItems()
        },
        closeDialogVinculation() {
            this.dialog = false
            this.closeDialog()
        },
        formatMoney(value) {
            return formatMoney(value)
        },
        setSelectedRegistrys() {
            this.selectedRegistrys = !this.selectedRegistrys
            if (!this.selectedRegistrys) {
                this.selected = []
            }
        },
        setDialogManualLow(payload) {
            payload.tab_origin = 5
            this.dialogManualLow = true
            this.showDialogManualLow(payload)
        },
        closeDialogManualLow() {
            this.dialogManualLow = false
            this.setCloseDialogManualLow()
        },
        getTypeDeal(type) {
            let types = [
                'Ganho/Perda',
                'Giro',
                'Normal',
                'Fichas Enviadas/Retiradas',
                'Sem Acerto',
                'Sem Acerto/GP',
            ]
            if (types[type]) {
                return types[type]
            }
            return ''
        },
        getTypeOrder(type) {
            let types = [
                'Retirada de Fichas',
                'Envio de Fichas',
                'Retirada de Fichas Maleta',
                'Envio de Fichas Maleta',
            ]
            return types[type]
        },
        formatTextVicnulations(linked_to, total_vinculations) {
            return `${linked_to} de ${total_vinculations}`
        },
    },
    data: () => ({
        selectedRegistrys: false,
        selected: [],
        dialog: false,
        dialogManualLow: false,
        singleSelect: false,
    }),
    watch: {
        pagination: {
            handler() {
                this.getItems({ resetPage: false })
            },
        },
         closeAllDialogs: {
            handler() {
                this.dialog = false
                this.dialog
            },
        },
        closeAllDialogsManualLow: {
            handler() {
                this.dialogManualLow = false
            }
        }
        
    }
}
</script>

