<template>
  <div>
    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2000"
      v-model="showNotification"
    >{{ notificationMessage }}</v-snackbar>                     
    <v-tabs v-model="tab" class="agent-data elevation-10">
      <v-tab>Fechamentos</v-tab>
      <v-tab>Transações</v-tab>
      <v-tab>Jogador x Agente</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item class="elevation-10">
        <v-toolbar flat class="elevation-5">
          <div>
            <v-toolbar-title>Fechamento Agentes Período {{ period_closure.id || period_closure_id }} - {{ getDate(period_closure.start_date) }} até {{ getDate(period_closure.finish_date) }}</v-toolbar-title>
          </div>
          <v-spacer></v-spacer>
          <div class="button-toolbar">
            <download-excel
              :before-generate="generateSheet"
              :before-finish="finishSheet"
              :name="getSheetName()"
              :fetch="getItemsNoPage"
              :export-fields="sheet_fields"
              class="excel"
              worksheet="Transações Agentes"
              type="xls"
            >
              <v-btn color="black" dark class="ma-2" small>
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </download-excel>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" color="black" dark class="ma-2" @click="showFilter({show: true})" small>
                  <v-icon :color="filtered ? 'grey' : 'white'">mdi-filter</v-icon>
                </v-btn> 
              </template>
              <span>Filtrar</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" color="black" dark class="ma-2" @click="getItems" small>
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>Recarregar</span>
            </v-tooltip>
          </div>
        </v-toolbar>

        <v-data-table
          :headers="headers"
          :loading="loading"
          :items="items"
          :options.sync="pagination"
          :server-items-length="totalItems"    
          no-data-text="Nenhuma registro encontrado"
          class="elevation-10 table-margin"
          :item-class= "getClassColor" 
          :footer-props="{
            'items-per-page-options': [10, 20, 50, 100],
            'items-per-page-text': 'Itens por página',
            'show-first-last-page': true,
            'page-text': `Página ${pagination.page} de ${parseInt(totalItems/pagination.itemsPerPage) + 1}`
          }" 
        >
          <template v-slot:[`item.is_master_agent`]="{ item }">
            <span>{{ getIsMasterAgent(item.is_master_agent) }}</span>
          </template>
          <template v-slot:[`item.total_gains`]="{ item }">
            <span>{{ getTotalAgSubAg(item, "total_gains", "total_gains_sub_agents") }}</span>
          </template>
          <template v-slot:[`item.total_taxes`]="{ item }">
            <span>{{ getTotalRakeAgSubAg(item)}}</span>
          </template>
          <template v-slot:[`item.rakeback`]="{ item }">
            <span>{{ getTotalRakebackAgSubAg(item, "rakeback", "total_rakeback_sub_agents" ) }}</span>
          </template>
          <template v-slot:[`item.rakeback_spin`]="{ item }">
            <span>{{ getTotalAgSubAg(item, "rakeback_spin", "total_spin_rakeback_sub_agents" ) }}</span>
          </template>
          <template v-slot:[`item.value_to_pay`]="{ item }">
            <span>{{ (item.total_value_to_pay) ? item.total_value_to_pay.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "R$ 0,00" }}</span>
          </template>
          <template v-slot:[`item.total_value_to_send`]="{ item }">
            <span>{{ (item.total_value_to_send) ? item.total_value_to_send.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "R$ 0,00" }}</span>
          </template>
          <template v-slot:[`item.adjust_value`]="{ item }">
            <span>{{ (item.adjust_value) ? item.adjust_value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "R$ 0,00" }}</span>
          </template>
          <template v-slot:[`item.percentage_applied`]="{ item }">
            <span>{{ item.percentage_applied + "%"}}</span>
          </template>
          <template v-slot:[`item.type_deal`]="{ item }">
            <span>{{ getTypeDeal(item.type_deal)}}</span>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <span>{{ getStatus(item)}}</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-on="on" v-bind="attrs" small class="mr-2" @click="viewItem({item: item})">mdi-information</v-icon>
              </template>
              <span>Informações</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-on="on" v-bind="attrs" small class="mr-2" @click="openReport({item: item}, '')">mdi-file</v-icon>
              </template>
              <span>Gerar Relatório de Fechamento</span>
            </v-tooltip>

            <v-tooltip bottom v-if="item.status == 4">
              <template v-slot:activator="{on, attrs}">
                <v-icon small class="mr-2"
                  @click="reorder({ item })"  v-on="on" v-bind="attrs"
                  >mdi-robot</v-icon>
              </template>
              <span>Reenviar Ordem</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.status != 2 && item.status != 3 && item.type_deal != 4">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-on="on" v-bind="attrs" small class="mr-2" @click="openDialog(item, '')">mdi-check</v-icon>
              </template>
              <span>Finalizar Acerto</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.status != 2 && item.status != 3 && item.type_deal != 4">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-on="on" v-bind="attrs" small class="mr-2" @click="openDialog(item, 'RESEND')">mdi-email-fast-outline</v-icon>
              </template>
              <span>Reenviar Mensagem</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.status == 2">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-on="on" v-bind="attrs" small class="mr-2" @click="showRecalculateDialog({item: item})">mdi-calculator</v-icon>
              </template>
              <span>Recalcular Acerto</span>
            </v-tooltip>
          </template>
        </v-data-table>

        <v-dialog :value="dialog" @click:outside="closeDialog()">
          <agents-period-closure-detail></agents-period-closure-detail>
        </v-dialog>

        <v-dialog :value="dialogAgentClosureFinish" @click:outside="newShowDialogFinishAgentClosure()" width="800">
          <v-card>
            <v-card-title class="text-center justify-center">
              <span class="headline">Fechamento Agente - {{ agentClosureToFinish.nick_agent }}</span>
              <v-spacer></v-spacer>
              <v-progress-circular
                v-if="loading"
                indeterminate
                class="text-right"
                color="primary"
                size="24"
                ></v-progress-circular>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-layout wrap>
                    <v-flex xs4 sm4 md4>
                    <span>Tipo de Acerto: {{getTypeDeal(agentClosureToFinish.type_deal)}}</span>
                    </v-flex>
                    <v-flex xs4 sm4 md4 v-if="agentClosureToFinish.type_deal == 0">
                      <span>Porcentagem Aplicada: {{agentClosureToFinish.percentage_applied}}%</span>
                    </v-flex>
                    <v-flex xs4 sm4 md4 v-if="agentClosureToFinish.type_deal == 0">
                      <span>Total Ganhos: {{getTotalAgSubAg(agentClosureToFinish, "total_gains", "total_gains_sub_agents")}}</span>
                    </v-flex>
                    <v-flex xs6 sm6 md6 v-if="agentClosureToFinish.type_deal != 0">
                      <span>Porcentagem Aplicada: {{agentClosureToFinish.percentage_applied}}%</span>
                    </v-flex>
                    <v-flex xs4 sm4 md4>
                      <span>Rake Gerado: {{getTotalRake(agentClosureToFinish)}}</span>
                    </v-flex>
                    <v-flex xs4 sm4 md4>
                      <span>Rakeback: {{getTotalRakeback(agentClosureToFinish)}}</span>
                    </v-flex>
                    <v-flex xs4 sm4 md4>
                      <span>Valor a Pagar: {{(agentClosureToFinish.total_value_to_pay) ? agentClosureToFinish.total_value_to_pay.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "R$ 0,00"  }}</span>
                    </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-divider class="mx-4"></v-divider>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm4 md4 v-if="this.mode != 'RESEND'">
                  <v-text-field v-if="agentClosureToFinish.type_deal != 1"
                      required
                      label="Alterar Anotados"
                      v-model="changed_value"
                      :readonly="(mode == 'VIEW' || mode == 'RESEND')"
                      v-validate="'decimal:2'"
                      :error-messages="errors.collect('changed_value')"
                      data-vv-name="changed_value"
                      outlined
                  ></v-text-field>
                    <v-text-field v-else
                      required
                      label="Anotados (Antigo)"
                      v-model="original_pending_payment"
                      :readonly="true"
                      v-validate="'decimal:2'"
                      :error-messages="errors.collect('original_pending_payment')"
                      data-vv-name="original_pending_payment"
                      outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md4 v-if="this.mode != 'RESEND'">
                    <v-text-field
                        required
                        label="Pagamento Pendente *"
                        v-model="pending_payment"
                        :readonly="agentClosureToFinish.type_deal != 1"
                        v-validate="'decimal:2'"
                        :error-messages="errors.collect('pending_payment')"
                        data-vv-name="pending_payment"
                        outlined
                    ></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md4 v-if="this.mode != 'RESEND'">
                  <v-text-field
                      required
                      label="Semana Atual *"
                      v-model="current_week"
                      :readonly="true"
                      v-validate="'decimal:2'"
                      :error-messages="errors.collect('current_week')"
                      data-vv-name="current_week"
                      outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md4 v-if="this.mode != 'RESEND'"> 
                  <v-text-field
                    required
                    label="Adiantamento de Rakeback"
                    v-model="rakeInAdvance"
                    :readonly="true"
                    v-validate="'decimal:2'"
                    :error-messages="errors.collect('rakeInAdvance')"
                    data-vv-name="rakeInAdvance"
                    outlined
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-flex v-if="this.mode != 'RESEND'"
                class="justify-center"
                style="display: flex"
              >
                <v-btn
                  class="mb-6 ml-2"  
                  :color="agentClosureToFinish.type_deal != 1 ? 'primary' : 'warning'"
                  @click="getAdjusts()"
                >
                  {{ agentClosureToFinish.type_deal != 1  ? "Adicionar Ajustes" : "Informar Giro/Ajustes" }}
                </v-btn>
              </v-flex>
              <v-layout wrap>
                <v-flex xs12 sm12 md12 v-if="this.mode != 'RESEND'"> 
                  <v-textarea
                      auto-grow        
                      label="Anotados - Observação"
                      background-color="white"
                      outlined
                      rows="1"
                      id="textareadesc"
                      :readonly="mode == 'VIEW'"
                      hide-details="true"
                      v-model="observation"
                      :value="observation"
                      v-on:keyup.enter="checkEnter('observation')"
                  >
                  </v-textarea>
                </v-flex>
                <v-flex xs6 sm6 md12 v-if="agentClosureToFinish.type_deal == 3 && this.mode != 'RESEND'">
                  <v-text-field
                      required
                      label="Saldo Transações"
                      v-model="balance_transactions"
                      :error-messages="errors.collect('balance_transactions')"
                      v-validate="'required:Saldo Inicial,balance_transactions|decimal:2'"
                      data-vv-name="balance_transactions"
                      outlined
                      type="number"
                  ></v-text-field>
                </v-flex>
                <!--v-flex xs12 sm12 md12 xl12 v-if="this.mode != 'RESEND'">
                  <v-select
                      label="Whatsapp *"
                      :items="wpp_instances"
                      v-model="id_instance"
                      :error-messages="errors.collect('id_instance')"
                      v-validate="'required:Whatsapp,id_instance'"
                      item-text="name"
                      item-value="id_instance"
                      data-vv-name="id_instance"
                      outlined
                    >
                  </v-select>
                </v-flex-->
                <v-flex xs12 sm12 md12 v-if="this.mode != 'RESEND'">
                  <v-textarea
                      required
                      auto-grow        
                      rows="1"
                      label="Texto Adicional *"
                      v-model="additional_text"
                      :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                      data-vv-name="additional_text"
                      outlined
                  ></v-textarea>
                </v-flex>
                <v-flex xs12 sm12 md12> 
                  <v-textarea
                      auto-grow        
                      label="Mensagem"
                      background-color="white"
                      outlined
                      id="textareadesc"
                      :readonly="true"
                      hide-details="true"
                      v-model="message_to_send"
                      :value="message_to_send"
                      v-on:keyup.enter="checkEnter('message_to_send')"
                  >
                  </v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
            <v-card-actions>
              <v-col class="text-center">
                <v-btn color="error" text @click="newShowDialogFinishAgentClosure()">Cancelar</v-btn>
              </v-col>
              <v-col class="text-center">
                <v-btn color="info" text @click="getMessageClosure({message: message_to_send, adjust_value: adjust_value})">Mensagem</v-btn>
              </v-col>
              <v-col class="text-center" v-if="this.mode != 'RESEND'">
                <v-btn color="success" text type="submit" right @click="finishAgentClosureIfValid({message: message, adjust_value: adjust_value})">Enviar</v-btn>
              </v-col>
              <v-col class="text-center" v-if="this.mode == 'RESEND' && message_to_send != ''">
                <v-btn color="success" text type="submit" right @click="resendMessage({message: message_to_send})">Reenviar Mensagem</v-btn>
              </v-col>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog :value="dialogFilter" width="600" @click:outside="showFilter({show: false})" @keydown.enter="filter">
          <agents-period-closure-filter :showPeriod="false"></agents-period-closure-filter>
        </v-dialog>

        <v-dialog :value="dialogFilterPlayerAgents" width="600" @click:outside="showFilter({show: false})" @keydown.enter="filter">
          <player-agent-filter></player-agent-filter>
        </v-dialog>

        
       <v-dialog :value="dialogFilterTransactions" width="600" @click:outside="showFilterTransactions({show: false})" @keydown.enter="filter">
          <periods-closure-transactions-filter></periods-closure-transactions-filter>
        </v-dialog>

        <v-dialog :value="dialogDelete"  @click:outside="closeDeleteDialog" @keydown.enter="deleteItem" width="300">
          <v-card>
            <v-card-title class="headline">Confirmar a exclusão?</v-card-title>

            <v-card-actions>
              <v-col>
                <v-btn text @click="closeDeleteDialog()">Cancelar</v-btn>
              </v-col>
              <v-col class="text-right">
                <v-btn text type="submit" right @click="deleteItem">Confirmar</v-btn>
              </v-col>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog :value="dialogRecalculate"  @click:outside="closeRecalculateDialog" width="600">
          <v-card>
            <v-card-title class="headline">Recalcular o acerto?</v-card-title>

            <v-card-actions>
              <v-col>
                <v-btn text @click="closeRecalculateDialog()">Cancelar</v-btn>
              </v-col>
              <v-col>
                <v-btn text type="submit" right @click="recalculateClosure({ resolve: '0' })">Recalcular</v-btn>
              </v-col>
              <v-col>
                <v-btn text type="submit" right @click="recalculateClosure({ resolve: '1' })">Resolver Diferença</v-btn>
              </v-col>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-tab-item>
      <v-tab-item>
        <v-toolbar flat class="elevation-5">
          <div>
            <v-toolbar-title>Transações Período {{ period_closure.id || period_closure_id }} - {{ getDate(period_closure.start_date) }} até {{ getDate(period_closure.finish_date) }}</v-toolbar-title>
          </div>
          <v-spacer></v-spacer>
          <div class="button-toolbar">
            <download-excel
              :before-generate="generateSheetTransactions"
              :before-finish="finishSheetTransactions"
              :name="getSheetNameTransactions()"
              :fetch="getPeriodClosureTransactionsByPeriodNoPage"
              :export-fields="sheet_fields_transactions"
              class="excel"
              worksheet="Fechamento Agentes"
              type="xls"
            >
              <v-btn color="black" dark class="ma-2" small>
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </download-excel>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" color="black" dark class="ma-2" @click="showFilterTransactions({show: true})" small>
                  <v-icon :color="filtered ? 'grey' : 'white'">mdi-filter</v-icon>
                </v-btn> 
              </template>
              <span>Filtrar</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" color="black" dark class="ma-2" @click="getPeriodClosureTransactionsByPeriod({period_id: period, resetPage: false})" small>
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>Recarregar</span>
            </v-tooltip>
          </div>
        </v-toolbar>
        <v-data-table
          :headers="headersTransactions"
          :loading="loadingTransactions"
          :items="itemsTransactions"
          :options.sync="paginationTransactions"
          :server-items-length="totalItemsTransaction"    
          no-data-text="Nenhuma registro encontrado"
          class="elevation-10 table-margin"
          :footer-props="{
            'items-per-page-options': [10, 20, 50, 100],
            'items-per-page-text': 'Itens por página',
            'show-first-last-page': true,
            'page-text': `Página ${paginationTransactions.page} de ${parseInt(totalItemsTransaction/paginationTransactions.itemsPerPage) + 1}`
          }"     
          :item-class= "getClassColorTransaction" 
        >
          <template v-slot:[`item.date`]="{ item }">
            <span>{{ getDateTime(item.date)}}</span>
          </template>
          <template v-slot:[`item.value`]="{ item }">
            <span>{{ item.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
          </template>
          <template v-slot:[`item.type_transaction`]="{ item }">
            <span>{{ item.type_transaction == 0 ? "Saída" : "Entrada" }}</span>
          </template>
          <template v-slot:[`item.suspect`]="{ item }">
            <span>{{ item.suspect == 0 ? "Não" : item.suspect == 1 ? "Sim" : "Sim/Validada" }}</span>
          </template>
          <template v-slot:[`item.type_suspect`]="{ item }">
            <span>{{ item.type_suspect == 0 ? "Interno - Externo" : item.type_suspect == 1 ? "Externo - Interno" : "-"}}</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }"> 
            <v-tooltip bottom v-if="item.status != 2 && item.status != 3 && item.type_deal != 4">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-on="on" v-bind="attrs" small class="mr-2" @click="checkSuspectTransaction({item: item})">mdi-check</v-icon>
              </template>
              <span>Validar Transação</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-tab-item>
      <v-tab-item>
        <v-toolbar flat class="elevation-5">
          <div>
            <v-toolbar-title>Jogador x Agente {{ period_closure.id || period_closure_id }} - {{ getDate(period_closure.start_date) }} até {{ getDate(period_closure.finish_date) }}</v-toolbar-title>
          </div>
          <v-spacer></v-spacer>
          <div class="button-toolbar">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" color="black" dark class="ma-2" @click="showFilterPlayerAgents({show: true})" small>
                  <v-icon :color="filtered ? 'grey' : 'white'">mdi-filter</v-icon>
                </v-btn> 
              </template>
              <span>Filtrar</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" color="black" dark class="ma-2" @click="getPlayersAgentInPeriod" small>
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>Recarregar</span>
            </v-tooltip>
          </div>
        </v-toolbar>
        <v-data-table
          no-data-text="Nenhuma registro encontrado"
          class="elevation-10 table-margin"
          :items="players_agent"
          :headers="headersPlayerAgentInPeriod"
          :options.sync="paginationPlayerAgentInPeriod"
          :server-items-length="totalItemsPlayerAgent"
          show-expand
          :expanded.sync="expandedPlayerAgentInPeriod"
          :footer-props="{
              'items-per-page-options': [10, 20, 50, 100],
              'items-per-page-text': 'Itens por página',
              'show-first-last-page': true,
              'page-text': `Página ${paginationPlayerAgentInPeriod.page} de ${parseInt(totalItemsPlayerAgent/paginationPlayerAgentInPeriod.itemsPerPage) + 1}`
          }" 
          @item-expanded="expandManagersPlayerAgentInPeriod"
          :single-expand="true"
        >
          <template v-slot:expanded-item="{  }">
              <td :colspan="headersPlayerAgentInPeriod.length / managers_player_agent.length" v-for="manager in managers_player_agent" :key="manager.id_manager_app" >
                {{`Gestor: ${manager.nick}`}}
                <br>
                {{`Valor Movimentado: ${(manager.value_transactions_period) ? manager.value_transactions_period.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 0}`}}
                <br>
                {{`Quantidade Transações: ${manager.count_transactions_period}`}}
              </td>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip bottom v-if="item.id_app_agent_system == 'None'">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-on="on" v-bind="attrs" small class="mr-2" @click="showDialogEditPlayerAgent({item: item, show: true})">mdi-pencil</v-icon>
                </template>
                <span>Editar</span>
              </v-tooltip>
            </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs-items>

    <v-dialog :value="dialogEditPlayerAgent" @click:outside="showDialogEditPlayerAgent({show: false})" @keydown.esc="showDialogEditPlayerAgent({show: false})">
      <v-card>
        <v-card-title>
          <span class="headline">Relacionamento Jogador x Agente</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm6 md6>
                <v-text-field
                  v-model="editedPlayerAgent.id_pppoker"
                  label="Id Jogador"
                  :rules="rules.required"
                  outlined
                  :readonly="true"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field
                  v-model="editedPlayerAgent.nick"
                  label="Nick Jogador"
                  :rules="rules.required"
                  outlined
                  :readonly="true"
                ></v-text-field>
              </v-flex>
              <v-flex xs6 sm6 md6>
                  <v-autocomplete
                    clearable
                    deletable-chips
                    v-model="editedPlayerAgent.id_agent"
                    :no-filter="true"
                    :items="find_agents"
                    outlined
                    chips
                    small-chips
                    no-data-text="Nenhum agente encontrado"
                    :item-text="getTextAgent"
                    item-value="id"
                    placeholder="Digite aqui o nome do agente"
                    label="Agente"
                    :search-input.sync="searchAgent"
                  ></v-autocomplete>
                </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-col>
            <v-btn text @click="showDialogEditPlayerAgent({show: false})">{{ mode == 'VIEW' ? 'Voltar' : 'Cancelar'}}</v-btn>
          </v-col>
          <v-col class="text-right" v-if="mode != 'VIEW'">
            <v-btn text type="submit" right @click="savePlayerAgent">Salvar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog :value="dialogAdjustValue"  @click:outside="showDialogAdjustValue({ show: false })" width="1200">
      <v-card class="styled: background-color: light-gray lighten-4">
        <v-card-title class="headline justify-space-between">
          <h4>Adicionar Ajustes</h4>
          <v-btn text color="red" @click="showDialogAdjustValue({ show: false })"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-container grid-list-md class="mt-5">
          <v-layout wrap>
            <v-flex xs12 sm3 md3>
              <v-checkbox
                v-model="diamonds"
                label="Diamantes"
                color="red"
                hide-details
              ></v-checkbox>
            </v-flex>
            <v-flex xs12 sm3 md3 class="center">
              <v-checkbox
                v-model="central_transactions"
                label="Transações Central"
                color="purple darken-3"
                hide-details
              ></v-checkbox>
            </v-flex>
            <v-flex xs12 sm3 md3>
              <v-checkbox
                v-model="transactions"
                label="Cobrança por Transações"
                color="indigo darken-3"
                hide-details
              ></v-checkbox>
            </v-flex>
            <v-flex xs12 sm3 md3 class="center">
              <v-checkbox
                v-model="overlay"
                label="Overlay"
                color="orange darken-3"
                hide-details
              ></v-checkbox>
            </v-flex>
          </v-layout>
        </v-container>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12 sm3 md3>
              <v-text-field
                v-if="diamonds"
                color="black"
                label="Diamantes *"
                v-model="diamonds_value"
                data-vv-name="diamonds_value"
                outlined
                type="number"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm3 md3>
              <v-text-field
                v-if="central_transactions"
                color="purple"
                label="Transações da Central *"
                v-model="central_transactions_value"
                data-vv-name="central_transactions_value"
                outlined
                type="number"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm3 md3>
              <v-text-field
                v-if="transactions"
                color="black"
                label="Cobrança por Transções *"
                v-model="transactions_value"
                data-vv-name="transactions_value"
                outlined
                type="number"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm3 md3>
              <v-text-field
                v-if="overlay"
                color="black"
                label="Overlay *"
                v-model="overlay_value"
                data-vv-name="overlay_value"
                outlined
                type="number"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-container>
        <v-divider class="mx-4"></v-divider>
        <v-div class="d-flex ml-2 mt-2 mb-3">
          <h3 class="mt-3 ml-2">Outros</h3>
          <v-btn outlined color="blue lighten-2" class="ma-2 " @click="add" icon><v-icon>mdi-plus</v-icon></v-btn>
          <v-btn outlined color="red lighten-2" class="ma-2" @click="remove" icon><v-icon>mdi-minus</v-icon></v-btn>
        </v-div>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12 sm4 md4 v-for="item in others" :key="item.id">
              <v-text-field
                class="mx-4"
                color="black"
                label="Descrição *"
                v-model="item.description"
                data-vv-name="description"
                outlined
              ></v-text-field>
              <v-text-field
                class="mx-4"
                color="black"
                label="Valor *"
                v-model="item.value"
                data-vv-name="value"
                outlined
                type="number"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-container>
        <v-divider class="mx-4"></v-divider>
        <v-container grid-list-md class="mt-5" v-if="agentClosureToFinish.type_deal==1">
          <h3 class="mb-4">Acerto Tipo Giro</h3>
          <v-layout wrap class="justify-space-around mx-17">
            <v-flex xs12 sm4 md4>
              <v-text-field
                label="Saldo Inicial *"
                v-model="start_balance"
                data-vv-name="start_balance"
                outlined
                type="number"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm4 md4>
              <v-text-field
                label="Saldo Final *"
                v-model="actual_balance"
                data-vv-name="actual_balance"
                outlined
                type="number"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-container>
        <v-divider v-if="agentClosureToFinish.type_deal==1" class="mx-4"></v-divider>
        <v-card-actions>
          <v-col>
            <v-btn text color="red" @click="adjustIsFalse()">Desconsiderar Ajustes</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn text color="blue" type="submit" right @click="adjustIsTrue()">Adicionar Ajustes</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import AgentsPeriodClosureFilter from "./AgentsPeriodClosureFilter";
import AgentsPeriodClosureDetail from "./AgentsPeriodClosureDetail";
import PeriodsClosureTransactionsFilter from '../periods_closure_transactions/PeriodsClosureTransactionsFilter'
import PlayerAgentFilter from '../player_agent/PlayerAgentFilter'
import JsonExcel from "vue-json-excel";
import debounce from '@util/debounce.js' 

export default {
  name: "AgentsPeriodClosure",
  computed: {
    ...mapState("subagents", ["find_agents"]),
    ...mapState("login", ["userData"]),
    ...mapState("agents_closure", [
      "rakeInAdvance",
      "agentClosureToFinish",
      "dialog",
      "dialogDelete",
      "dialogRecalculate",
      "dialogAdjustValue",
      "adjust_check",
      "adjustsStore",
      "item",
      "instances",
      "errors",
      "filtered",
      "filter",
      "loading",
      "managers_player_agent",
      "message_to_send",
      "mode",
      "period_closure",
      "period_closure_id",
      "players_agent",
      "notificationType",
      "notificationMessage",
      "rowsPerPage",
      "totalItems",
      "items",
    ]),
    ...mapState('periods_closure_transactions', [
      "itemsTransactions",
      "totalItemsTransaction"
    ]),
    ...mapFields("agents_closure", [
      "annotated.original_pending_payment",
      "annotated.changed_value",
      "annotated.observation",
      "annotated.current_week",
      "annotated.pending_payment",
      "agentClosureToFinish.adjust_from_annotated",
      "agentClosureToFinish.adjust_value",
      "agentClosureToFinish.message_closure",
      "agentClosureToFinish.id_instance",
      "agentClosureToFinish.balance_transactions",
      "dialogFilterPlayerAgents",
      "message_to_send",
      "start_balance",
      "actual_balance",
      "editedPlayerAgent",
      "dialogAgentClosureFinish",
      "paginationPlayerAgentInPeriod",
      "pagination",
      "dialogFilter",
      "dialogEditPlayerAgent",
      "overlay",
      "showNotification",
      "totalItemsPlayerAgent",
      "filter.sortBy",
      "filter.sortDesc",
      // ...
    ]),
    ...mapFields("subagents", {
        searchAgent: "filter_agent.nick",
      }),
    ...mapFields("periods_closure_transactions", [
      "dialogFilterTransactions",
      "paginationTransactions",
      "loadingTransactions"
    ]),
    wpp_instances() {
      return this.instances.filter((i) => i.id)
    }
  },
  created() {
    this.period = this.$route.params.period_closure_id
    this.getSlotsInPeriod({period_id: this.$route.params.period_closure_id})
    this.getItems({period_id: this.$route.params.period_closure_id})
    this.getPlayersAgentInPeriod({period_id: this.$route.params.period_closure_id})
    this.setPeriodClosureId({period_closure_id: this.$route.params.period_closure_id})
    this.getPeriodClosureTransactionsByPeriod({ period_id: this.$route.params.period_closure_id, resetPage: true})
  },
  mixins: [debounce],
  mounted() {
    this.getFindAgents = this.debounce(this.getFindAgents, 500); 
  },
  components: {
    "download-excel": JsonExcel,
    "agents-period-closure-filter": AgentsPeriodClosureFilter,
    "periods-closure-transactions-filter": PeriodsClosureTransactionsFilter,
    "agents-period-closure-detail": AgentsPeriodClosureDetail,
    "player-agent-filter": PlayerAgentFilter,
  },
  methods: {
    ...mapActions("subagents", ["getFindAgents"]),
    ...mapMutations("agents_closure", [
      "adjustIsTrueStore",
      "adjustIsFalseStore",
      "setMode",
      "closeDialog",
      "setCheckAdjusts",
      "setAnnotatedChangedValue",
      "setManagersPlayerAgentPeriod",
      "setPeriodClosureId",
      "showDialogAdjustValue",
      "showDialogAdjustSpin",
      "showDialogFinishAgentClosure",
      "showFilterPlayerAgents",
      "showDialogEditPlayerAgent",
      "showFilter",
    ]),
    ...mapMutations('periods_closure_transactions', [
      "showFilterTransactions"
    ]),
    ...mapActions('instances', ['getInstances']),
    ...mapActions("agents_closure", [
      "getSlotsInPeriod",
      "closeDeleteDialog",
      "closeRecalculateDialog",
      "checkSuspectTransaction",
      "dialogFinishAgentClosure",
      "deleteItem",
      "recalculateClosure",
      "finishAgentClosure",
      "resendMessage",
      "getItems",
      "getAdjustsStore",
      "getMessageClosure",
      "getItemsNoPage",
      "showRecalculateDialog",
      "getManagersPlayerAgentPeriod",
      "viewItem",
      "verifyItem",
      "openReport",
      "getPlayersAgentInPeriod",
      "reorder",
      "savePlayerAgent"
    ]),
    ...mapActions("periods_closure_transactions", [
      "getPeriodClosureTransactionsByPeriod",
      "getPeriodClosureTransactionsByPeriodNoPage",
    ]),
    filter() {
      this.getItems({ resetPage: true });
      this.showFilter({ show: false });
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      }
      return false;
    },
    getDate(date) {
      if (date) {
        var d = new Date(date);
        return d.toLocaleDateString();
      }
      return "";
    },
    expandManagersPlayerAgentInPeriod(payload) {
      console.log(payload)
      if(payload.value) {
        this.setManagersPlayerAgentPeriod({items: []})
        this.getManagersPlayerAgentPeriod(payload.item)
      } else {
        this.setManagersPlayerAgentPeriod({items: []})
      }
    },
    getDateTime(date) {
      if (date) {
        var d = new Date(date);
        return d.toLocaleDateString() + " " + d.toLocaleTimeString();
      }
      return "";
    },
    getTypeDeal(type) {
        let types = ["Ganho/Perda", "Giro", "Normal", "Fichas Enviadas/Retiradas", "Sem Acerto", "Sem Acerto/GP"]
        return types[type]
    },
    getIsMasterAgent(master) {
      let types_master = ["Não", "Sim"]
      return types_master[master]
    },
    finishSheet() {
      this.overlay = false;
    }, 
    finishSheetTransactions() {
      this.overlay = false;
    },
    finishAgentClosureIfValid(ag_closure) {
      this.$validator.validateAll().then((valid) => { 
        if(valid) {
          this.finishAgentClosure(ag_closure)
        }
      })
    },
    getClassColorTransaction(item) {
      if(item.type_transaction == 0) {
        return "row-red"
      } else {
        return "row-green"
      }
    },
    getTextAgent(item) {
        if(item) {
            if(item.id_pppoker) {
                return item.id_pppoker + " | " + item.nick + " | " + item.club_name
            } else {
                return item.nick
            }
        }
        return ''
    },
    generateSheet() {
      this.overlay = true;
    },
    generateSheetTransactions() {
      this.overlay = false;
    },
    getStatus(item) {
      if(item.status == 2 || item.type_deal == 4 || item.type_deal == 5) {
        return 'Concluido'
      } else if(item.status == 3){
        return 'Aguardando Envio de Fichas'
      } else if(item.status == 4){
        return 'Enviar Fichas Manualmente'
      } else if(item.status == 5){
        return 'Erro nos Anotados'
      } else if(item.status == 6){
        return 'Resolver Diferença'
      } else {
        return 'Pendente'
      }
    },
    getTotalRake(item) {
      let rake = item.total_taxes + item.total_rake_sub_agents + item.total_spin_sub_agents + item.total_spin
      return rake.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    },
    getTotalRakeback(item) {
      let rakeback = item.rakeback + item.rakeback_spin + item.total_spin_rakeback_sub_agents + item.total_rakeback_sub_agents
      return rakeback.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    },
    getRakeInAdvance() {
      return this.rakeInAdvance.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    },
    getTotalAgSubAg(item, field1, field2) {
      if(item[field1] || item[field2] === 0) {
        if(item[field2] || item[field2] === 0) {
          let total = (item[field2] + item[field1])
          return total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        }
        return item[field1].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      } else if(item[field2] || item[field2] === 0) {
        return item[field2].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      }
      return "0".toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    },
    getTotalRakeAgSubAg(item) {
      let rake = item["total_taxes"] + item["total_rake_sub_agents"] + item["total_spin"] + item["total_spin_sub_agents"]
      return rake.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    },
    getTotalRakebackAgSubAg(item) {
      let rake = item["rakeback"] + item["total_rakeback_sub_agents"] + item["rakeback_spin"] + item["total_spin_rakeback_sub_agents"]
      return rake.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    },
    getSheetName() {
      var date = new Date();
      var name =
        "agentes_fechamento_" + this.period_closure.id + 
        date.toLocaleDateString() +
        " " +
        date.toLocaleTimeString();
      return name + ".xls";
    },
    getSheetNameTransactions() {
      var date = new Date();
      var name =
        "transacoes_fechamento_" + this.period_closure.id + 
        date.toLocaleDateString() +
        " " +
        date.toLocaleTimeString();
      return name + ".xls";
    },
    getClassColor(item) {
      if(item.status == 0 && item.type_deal != 4 && item.type_deal != 5) {
        return 'row-red'
      } else if(item.status == 3) {
        return 'row-teal'
      } else if(item.status == 4) {
        return 'row-orange'
      } else if(item.status == 5) {
        return 'row-purple'
      } else if(item.status == 6) {
        return 'row-yellow-schedule'
      } else {
        return 'row-green'
      }
    },
    add() {
      let default_item = {description: '', value: 0, type: 3, id: 0}
      this.others.push(default_item)
    },
    remove() {
      this.others.pop()
    },
    adjustIsTrue() {
      let temp_value = 0
      let temp_adjusts = []
      if (parseFloat(this.diamonds_value) > 0 && this.diamonds) {
        let default_item = {description: 'Diamantes', value: parseFloat(this.diamonds_value), type: 0, id: 0}
        temp_adjusts.push(default_item)
        temp_value += parseFloat(this.diamonds_value)
      }
      if (parseFloat(this.central_transactions_value) != 0 && this.central_transactions) {
        
        let default_item = {description: 'Transações da Central', value: parseFloat(this.central_transactions_value), type: 5, id: 0}
        temp_adjusts.push(default_item)
        temp_value += parseFloat(this.central_transactions_value)
        console.log('entrei aqu')
      }
      if (parseFloat(this.transactions_value) > 0 && this.transactions) {
        let default_item = {description: 'Cobrança por Transações', value: parseFloat(this.transactions_value), type: 1, id: 0}
        temp_adjusts.push(default_item)
        temp_value += parseFloat(this.transactions_value)
      } 
      if (parseFloat(this.overlay_value) > 0 && this.overlay) {
        let default_item = {description: 'Overlay', value: parseFloat(this.overlay_value), type: 2, id: 0}
        temp_adjusts.push(default_item)
        temp_value += parseFloat(this.overlay_value)
      }
      if (this.others.length > 0) {
        this.others.forEach(other => {
          if (parseFloat(other.value) > 0 && other.description.length > 1) {
            temp_adjusts.push(other)
            this.others_value += parseFloat(other.value)
          }
          temp_value += parseFloat(other.value)
        });
      }
      if ((parseFloat(this.start_balance) >= 0 && parseFloat(this.actual_balance) >= 0) && this.agentClosureToFinish.type_deal == 1) {
        let spinValue = (parseFloat(this.start_balance) - parseFloat(this.actual_balance)) 
        let default_item = {description: 'Giro', value: parseFloat(spinValue), type: 4, id: 0}
        
        temp_adjusts.push(default_item)
        temp_value += parseFloat(spinValue)
      }
      if(temp_adjusts.length > 0) {
        this.adjusts = temp_adjusts
        console.log("Temp value: ", temp_value)
        this.adjust_value = temp_value
        this.adjustIsTrueStore(this.adjusts)
      }

    },
    adjustIsFalse() {
      this.diamonds_value = 0
      this.transactions_value = 0
      this.overlay_value = 0
      this.adjust_value = 0
      this.others_value = 0
      this.start_balance = 0 
      this.actual_balance = 0
      this.central_transactions_value = 0
      this.central_transactions = false
      this.diamonds = false
      this.transactions = false
      this.overlay = false
      this.others = []
      this.adjusts = []
      this.adjustIsFalseStore()
    },
    newShowDialogFinishAgentClosure() {
      this.adjustIsFalse()
      this.showDialogFinishAgentClosure({item: {annotated: {}}, show: false})
    },
    getAdjusts() {
      this.showDialogAdjustValue({show: true})
    },
    openDialog(item, mode) {
      this.setMode(mode)
      this.adjustIsFalse()
      this.getAdjustsStore({id_closure: item.id}).then((result) =>{
        this.adjusts = result.data
        let value = 0
 
        this.adjusts.forEach(adjust => {
          if (adjust.type == 0) {
            this.diamonds = true
            this.diamonds_value = adjust.value
            value += adjust.value
          } else if (adjust.type == 1) {
            this.transactions = true
            this.transactions_value = adjust.value
            value += adjust.value
          } else if (adjust.type == 2) {
            this.overlay = true
            this.overlay_value = adjust.value
            value += adjust.value
          } else if(adjust.type == 5) {
            this.central_transactions = true
            this.central_transactions_value = adjust.value
          } else {
            this.others.push(adjust)
            value += adjust.value
          }
        })
        this.adjust_value = value
        if (this.adjusts.length > 0) {
          this.setCheckAdjusts()
        }
        this.adjusts = []
        this.dialogFinishAgentClosure({item: item, show: true})
      })
      
    }
  },
  data: () => ({
    others: [],
    adjusts: [],
    additional_text: "",
    build_fields: {},
    expanded: [],
    expandedPlayerAgentInPeriod: [],
    tab: '',
    headers: [
      { text: "Id", value:"id", sortable: false }, 
      { text: "Cliente", value: "client_nickname", sortable: false },
      { text: "Id Pppoker", value: "id_pppoker", sortable: false },
      { text: "Nome do Agente", value: "nick_agent", sortable: false },
      { text: "Tipo do Acerto", value: "type_deal", sortable: true },
      { text: "Master", value: "name_master_agent", sortable: false },
      { text: "QT. Master", value: "count_master_agent", sortable: true },
      { text: "Clube", value: "club_name", sortable: false },
      { text: "Ganhos", value: "total_gains", sortable: true },
      { text: "Rake Gerado", value: "total_taxes", sortable: true },
      { text: "(%)", value: "percentage_applied", sortable: true },
      { text: "Rakeback", value: "rakeback", sortable: true },
      { text: "Rakeback Spin", value: "rakeback_spin", sortable: true},
      { text: "Ajustes", value: "adjust_value", sortable: true },
      { text: "Valor a Pagar", value: "value_to_pay", sortable: true },
      { text: "Valor a Enviar", value: "total_value_to_send", sortable: true },
      { text: "Status", value: "status", sortable: true },
      { text: "Responsável", value: "user_name", sortable: true },
      { text: "Ações", value: "actions", sortable: false }
    ],
    headersTransactions: [
      { text: "Id", value: "id", sortable: false },
      { text: "Data", value: "date", sortable: false },
      { text: "Gestor", value: "id_manager_app", sortable: false },
      { text: "Nick Gestor", value: "manager_nick", sortable: false },
      { text: "Agente", value: "id_agent_app", sortable: false },
      { text: "Player", value: "id_player_app", sortable: false },
      { text: "Tipo", value: "type_transaction", sortable: false },
      { text: "Valor", value: "value", sortable: false },
      { text: "Clube", value: "club_name", sortable: false },
      { text: "Suspeita", value: "suspect", sortable: false },
      { text: "Tipo Suspeita ( G - J )", value: "type_suspect", sortable: false },
      { text: "Ações", value: "actions", sortable: false }
    ],
    headersPlayerAgentInPeriod: [
      { text: "Id Jogador", value: "id_pppoker", sortable: false},
      { text: "Nick Jogador", value: "nick", sortable: false },
      { text: "Id App Agente", value: "id_pppoker_agent", sortable: false},
      { text: "Nick do Agente", value: "agent_nick", sortable: false },
      { text: "Id App Agente Sistema", value: "id_app_agent_system", sortable: false},
      { text: "Clube", value: "slot_name", sortable: false},
      { text: "Ações", value: "actions", sortable: false }
    ],
    valid: false,
    central_transactions: false,
    diamonds: false,
    transactions: false,
    overlay: false,
    diamonds_value: 0,
    transactions_value: 0,
    overlay_value: 0,
    others_value: 0,
    adjust_value: 0,
    central_transactions_value: 0,
    period: '',
    rules: {
      required: [(value) => !!value || "Campo obrigatório"]
    },
    sheet_fields: {
      "Id Pppoker": {
        field: "id_pppoker"
      },
      "Nick do Agente": {
        field: "nick_agent"
      },
      "Tipo do Acerto": {
        field: "type_deal",
        callback: (value) => {
          let types = ["Ganho/Perda", "Giro", "Normal", "Fichas Enviadas/Retiradas", "Sem Acerto", "Sem Acerto/GP"]
          return types[value]
        }
      },
      "Master": {
        field: "name_master_agent"
      },
      "Clube": {
        field: "club_name",
      },
      "Agentes Ativos": {
        field: "active_agents",
      },
      "Jogadores Ativos": {
        field: "active_players"
      },
      "Ganhos": {
        field: "total_gains",
        callback: (value) => {
          if (value) {
            let real = value.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
            if(value < 0) {
              real = real.substr(4);
              return "-" + real
            } else {
              return real.substr(3);
            }
          }
          return 0;
        },
      },
      "Rake Gerado": {
        field: "total_taxes",
        callback: (value) => {
          if (value) {
            let real = value.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
            if(value < 0) {
              real = real.substr(4);
              return "-" + real
            } else {
              return real.substr(3);
            }
          }
          return 0;
        },
      },
      "Porcentagem Aplicada (%)": {
        field: "percentage_applied"
      },
      "Rakeback": {
        field: "rakeback",
        callback: (value) => {
          if (value) {
            let real = value.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
            if(value < 0) {
              real = real.substr(4);
              return "-" + real
            } else {
              return real.substr(3);
            }
          }
          return 0;
        },
      },
      "Rakeback Subs": {
        field: "total_rakeback_sub_agents",
        callback: (value) => {
          if (value) {
            let real = value.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
            if(value < 0) {
              real = real.substr(4);
              return "-" + real
            } else {
              return real.substr(3);
            }
          }
          return 0;
        },
      },
      "Rakeback Spin": {
        field: "rakeback_spin",
        callback: (value) => {
          if (value) {
            let real = value.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
            if(value < 0) {
              real = real.substr(4);
              return "-" + real
            } else {
              return real.substr(3);
            }
          }
          return 0;
        },
      },
      "Rakeback Spin Subs": {
        field: "total_spin_rakeback_sub_agents",
        callback: (value) => {
          if (value) {
            let real = value.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
            if(value < 0) {
              real = real.substr(4);
              return "-" + real
            } else {
              return real.substr(3);
            }
          }
          return 0;
        },
      },
      "Ajustes": {
        field: "adjust_value",
        callback: (value) => {
          if (value) {
            let real = value.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
            if(value < 0) {
              real = real.substr(4);
              return "-" + real
            } else {
              return real.substr(3);
            }
          }
          return 0;
        },
      },
      "Valor a Pagar": {
        field: "value_to_pay",
        callback: (value) => {
          if (value) {
            let real = value.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
            if(value < 0) {
              real = real.substr(4);
              return "-" + real
            } else {
              return real.substr(3);
            }
          }
          return 0;
        },
      },
      "Status": {
        field: "status",
        callback: (value) => {
          if(value == 2 || value == 4) {
            return 'Concluido'
          } else if(value == 3){
            return 'Aguardando Envio de Fichas'
          } else if(value == 4){
            return 'Erro no Envio de Fichas'
          } else{
            return 'Pendente'
          }
        }
      },
      "Responsável": { 
        field: "user_name"
      },
    },
    sheet_fields_transactions: {
      "Clube": {
        field: "club_name",
      },
      "Periodo": {
        field: "id_period_closure"
      },
      "Data": {
        field: "date",
        callback: (value) => {
          if (value) {
            var d = new Date(value);
            return d.toLocaleDateString() + " " + d.toLocaleTimeString();
          }
          return "";
        }
      },
      "Gestor": {
        field: "id_manager_app"
      },
      "Agente": {
        field: "id_agent_app"
      },
      "Jogador": {
        field: "id_player_app"
      },
      "Tipo": {
        field: "type_transaction",
        callback: (value) => value == 0 ? "Saída" : "Entrada"
      },
      "Valor": {
        field: "value",
        callback: (value) => {
          if (value) {
            let real = value.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
            if(value < 0) {
              real = real.substr(4);
              return "-" + real
            } else {
              return real.substr(3);
            }
          }
          return 0;
        }
      },
      "Suspeita": {
        field: "suspect",
        callback: (value) => value == 0 ? "Não" : "Sim"
      },
      "Tipo Suspeita": {
        field: "type_suspect",
        callback: (value) => value == 0 ? "Interno - Externo" : value == 1 ? "Externo - Interno" : "-"
      },
    },
  }),
  watch: {
    start_balance: {
      handler() {
        if(this.agentClosureToFinish.type_deal == 1) {
          this.adjust_value = parseFloat(this.start_balance - this.actual_balance).toFixed(2)
          this.current_week = parseFloat(this.actual_balance).toFixed(2)

          this.pending_payment = parseFloat(parseFloat(this.adjust_value - this.agentClosureToFinish.total_value_to_pay).toFixed(2)).toFixed(2)

          this.adjust_value *= -1
        }
        if(this.agentClosureToFinish.type_deal == 3) {
          this.adjust_value = parseFloat((this.start_balance - this.actual_balance) + (this.adjust_from_annotated * -1))
          this.adjust_value *= -1
        }
      },
    },
    actual_balance: {
      handler() {
        if(this.agentClosureToFinish.type_deal == 1) {
          this.adjust_value = parseFloat(this.start_balance - this.actual_balance).toFixed(2)
          this.current_week = parseFloat(this.actual_balance).toFixed(2)
          this.pending_payment = parseFloat(parseFloat(this.adjust_value - this.agentClosureToFinish.total_value_to_pay).toFixed(2)).toFixed(2)
          this.adjust_value *= -1
        }
        if( this.agentClosureToFinish.type_deal == 3) {
          this.adjust_value = parseFloat(this.start_balance - this.actual_balance + (this.adjust_from_annotated * -1)).toFixed(2)
          this.adjust_value *= -1
        }
      }
    },
    agentClosureToFinish: function(val, oldVal) {
      if(val.id != oldVal.id) {
        this.additional_text = ""
      }
    },
    pagination: {
      handler() {
        this.getItems({ resetPage: false });
      },
    },
    paginationTransactions: {
      handler() {
        this.getPeriodClosureTransactionsByPeriod({ period_id: this.$route.params.period_closure_id, resetPage: false});
      },
    },
    paginationPlayerAgentInPeriod: {
      handler() {
        this.getPlayersAgentInPeriod({period_id: this.$route.params.period_closure_id})
      },
    },
    searchAgent: {
      handler () {
          if(this.searchAgent != null ) {
              this.getFindAgents({search: this.searchAgent})
          }
      },
    },
  },
};
</script>
