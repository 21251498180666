export const formatDate = (date) => {

    if (date) {
        var d = new Date(date);
        return d.toLocaleDateString() + " " + d.toLocaleTimeString();
    }
    return "";

}

export const formatMoney = (value) => {
    return value.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
    })
}

export const formatDateWithoutTime = (date) => {
    if (date) {
        var d = new Date(date)
        return d.toLocaleDateString()
    }
    return "";
}