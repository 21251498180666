<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline text-center justify-center">Filtro</span>

        <v-col class="text-right">
            <v-btn v-if="filtered == true" color="white" class="ma-2"  @click="clearFilter" right small depressed>
              <v-icon color="black">mdi-filter-remove</v-icon>
            </v-btn>
          </v-col>
      </v-card-title>

      <v-card-text>
        <v-form ref="form">
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm6 md6>
                <v-select
                  :clearable="true"
                  clear-icon="mdi-close"
                  label="Slot"
                  :items="instances"
                  v-model="slot"
                  item-text="name"
                  item-value="id_instance"
                  outlined
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field
                  required
                  label="Valor"
                  v-model="value"
                  :error-messages="errors.collect('value')"
                  data-vv-name="value"
                  outlined
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn text @click="showFilter({ show: false })">Cancelar</v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn text type="submit" right @click="filter">Filtrar</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "SlotsRevenueFilter",
  computed: {
    ...mapState("slots_revenue", ["filtered"]),
    ...mapState('instances', ['instances']),
    ...mapFields("slots_revenue", [
      "filter.slot",
      "filter.value",
    ]),
  },
  created() {
    this.getInstances()
  },
  methods: {
    ...mapMutations("slots_revenue", ["showFilter"]),
    ...mapActions('instances', ['getInstances']),
    ...mapActions("slots_revenue", [
      "getItems",
      "clearFilter",
    ]),
    filter() {
      this.getItems({ resetPage: true });
      this.showFilter({ show: false });
    },
  },
};
</script>

<style></style>
