<template>
  <div>
    <v-dialog :value="dialog" persistent width="600">
      <v-card>
        <v-card-title>
          <span class="headline">Faltas </span>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    required
                    label="ID"
                    v-model="id"
                    readonly
                    :disabled="this.mode !== 'VIEW'"
                    data-vv-name="Porcentagem"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-text-field
                    label="Descrição"
                    v-model="description"
                    :readonly="this.mode == 'VIEW'"
                    data-vv-name="Descrição"
                    outlined
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-datetime-picker
                    v-model="date_picker"
                    required
                    date-format="dd/MM/yyyy"
                    :disabled="this.mode == 'VIEW'"
                    :time-picker-props="timeProps"
                    :textFieldProps="{ outlined: true }"
                  >
                    <template slot="dateIcon">
                      <v-icon>mdi-calendar</v-icon>
                    </template>
                    <template slot="timeIcon">
                      <v-icon>mdi-clock</v-icon>
                    </template>
                  </v-datetime-picker>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-datetime-picker
                    v-model="finish_date"
                    required
                    date-format="dd/MM/yyyy"
                    :disabled="this.mode == 'VIEW'"
                    :time-picker-props="timeProps"
                    label="Data Final*"
                    :error-messages="errors.collect('finish_date')"
                    v-validate="'required:Data Final,finish_date'"
                    :textFieldProps="{ outlined: true }"
                  >
                    <template slot="dateIcon">
                      <v-icon>mdi-calendar</v-icon>
                    </template>
                    <template slot="timeIcon">
                      <v-icon>mdi-clock</v-icon>
                    </template>
                  </v-datetime-picker>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-autocomplete
                    clearable
                    v-model="id_collaborator"
                    :no-filter="true"
                    :items="collaborators"
                    :disabled="this.mode == 'VIEW'"
                    chips
                    small-chips
                    no-data-text="Nenhum colaborador encontrado"
                    item-text="name"
                    item-value="id"
                    label="Colaborador"
                    outlined
                    :search-input.sync="searchInput"
                  ></v-autocomplete>
                </v-flex>

                <v-flex xs12 sm6 md6>
                  <v-select
                    label="Tipo *"
                    :items="possible_types"
                    v-model="type_abstence"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('type_abstence')"
                    v-validate="'required:Tipo,type_abstence'"
                    data-vv-name="type_abstence"
                    item-text="description"
                    item-value="id"
                    outlined
                  ></v-select>
                </v-flex>

                <v-flex xs12 sm12 md12>
                  <v-file-input
                    v-model="attest_file"
                    v-if="this.mode == 'ADD'"
                    label="Atestado"
                    :error-messages="errors.collect('attest')"
                    data-vv-name="attest"
                    outlined
                  ></v-file-input>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-col>
            <v-btn text @click="setShowDialog(false)">{{
              mode == "VIEW" ? "Voltar" : "Cancelar"
            }}</v-btn>
          </v-col>
          <v-col class="text-right" v-if="mode != 'VIEW'">
            <v-btn text type="submit" right @click="saveAbsence">Salvar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "AbsencesForm",
  methods: {
    ...mapActions("absences", ["save", "getSearchCollaborators","getCollaborators"]),
    ...mapMutations("absences", ["setShowDialog"]),
    addDropFile(e) {
      if (this.mode == "ADD" || this.mode == "EDIT") {
        if (e.dataTransfer) {
          if (e.dataTransfer.files[0]) {
            this.attest_file = e.dataTransfer.files[0];
          }
        }
      }
    },
    saveAbsence() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          var d = new Date(this.date_picker);
          this.start_date =
            d.toISOString().substr(0, 10) + " " + d.toLocaleTimeString();
          this.value = parseFloat(this.value);

          if (this.finish_date != null && this.finish_date != "") {
            this.finish_date =
              this.finish_date.toISOString().substr(0, 10) +
              " " +
              this.finish_date.toLocaleTimeString();
            this.value = parseFloat(this.value);
          }

          this.save();
        }
      });
    },
  },
  computed: {
    ...mapState("absences", [
      "mode",
      "selectedItem",
      "dialog",
      " errors",
      "possible_types",
      "collaborators",
    ]),
    ...mapFields("absences", [
      "selectedItem.id",
      "selectedItem.id_collaborator",
      "selectedItem.description",
      "selectedItem.start_date",
      "selectedItem.finish_date",
      "selectedItem.type_abstence",
      "selectedItem.attest_file",
      "selectedItem.date_picker",
      "selectedItem.collaborator"
    ]),
  },
  data: () => ({
    valid: false,
    timeProps: {
      format: "24hr",
    },
    searchInput: "",
  }),
  watch: {
    searchInput: {
      handler() {
        if (this.searchInput) {
          var payload = {
            search: this.searchInput,
          };
          this.getSearchCollaborators(payload);
        }
      },
    },
  },
};
</script>