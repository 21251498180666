<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline">Filtro</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm6 md6>
                <v-select
                  clearable
                  clear-icon="mdi-close"
                  label="Clube *"
                  :items="instances"
                  v-model="club"
                  item-text="name"
                  item-value="id_instance"
                  data-vv-name="id_instance"
                  multiple
                  outlined
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-select
                  label="Master"
                  :items="types_master"
                  v-model="master"
                  :error-messages="errors.collect('master')"
                  v-validate="'required'"
                  item-text="description"
                  item-value="id"
                  data-vv-name="master"
                  clear-icon="mdi-close"
                  :clearable="true"
                  outlined
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field
                  required
                  label="ID"
                  v-model="id_pppoker"
                  data-vv-name="id_pppoker"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field required label="Nick" v-model="nick" data-vv-name="nick" outlined></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-menu
                  v-model="showStart"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="start_formatted"
                      label="De"
                      outlined
                      readonly
                      append-icon="mdi-close"
                      @click:append="clearStart()"
                      persistent-hint
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="pt-br"
                    v-model="start"
                    no-title
                    @input="(e) => setStart(e)"
                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-menu
                  v-model="showEnd"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="end_formatted"
                      label="Até"
                      outlined
                      readonly
                      append-icon="mdi-close"
                      @click:append="clearEnd()"
                      persistent-hint
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker locale="pt-br" v-model="end" no-title @input="(e) => setEnd(e)"></v-date-picker>
                </v-menu>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn text @click="showFilter({show: false})">Cancelar</v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn text type="submit" right @click="filter">Filtrar</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
export default {
  name: "ReportAgentsFilter",
  computed: {
    ...mapState("agents", ["instances"]),
    ...mapFields("report_agents_rake", [
      "filter.start",
      "filter.end",
      "filter.id_pppoker",
      "filter.nick",
      "filter.club",
      "filter.master"
      // ...
    ])
  },
  data: () => ({
    searchAgent: '',
    types_master: [
      {id: 1, description: 'Sim'},
      {id: 0, description: 'Não'}
    ]
  }),
  methods: {
    ...mapMutations("report_agents_rake", ["showFilter"]),
    ...mapActions("report_agents_rake", ["getItems"]),
    clearStart() {
        this.start = ''
        this.start_formatted = ''
    },
    clearEnd() {
        this.end = ''
        this.end_formatted = ''
    },
    setStart(date) {
      this.start = date;
      this.showStart = false;
      this.start_formatted = this.formatDate(date);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    },
    setEnd(date) {
      this.end = date;
      this.showEnd = false;
      this.end_formatted = this.formatDate(date);
    },
    getTextAgent(item) {
      if(item) {
        if(item.id_pppoker) {
          return item.id_pppoker + " | " + item.nick + " | " + item.club
        } else {
          return item.nick
        }
      }
      return ''
    },
    filter() {
      this.getItems({resetPage: true});
      this.showFilter({ show: false });
    },
  },
  watch: {
  }
};
</script>

<style>
</style>