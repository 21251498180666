<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar
      :color="notification.type"
      right
      top
      :timeout="2000"
      v-model="notification.show"
    >
      {{ notification.message }}
    </v-snackbar>

    <v-toolbar flat class="elevation-5">
      <div>
        <v-toolbar-title>Faltas</v-toolbar-title>
      </div>
      <v-spacer></v-spacer>
      <div class="button-toolbar">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="black"
              dark
              class="ma-2"
              @click="setShowFilter(true)"
              v-bind="attrs"
              v-on="on"
              small
            >
              <v-icon color="white">mdi-filter</v-icon>
            </v-btn>
          </template>
          <span>Filtro</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="black"
              dark
              class="ma-2"
              @click="getItems"
              v-bind="attrs"
              v-on="on"
              small
              ><v-icon>mdi-refresh</v-icon></v-btn
            >
          </template>
          <span>Recarregar</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="black"
              dark
              class="ma-2"
              @click="addAbsence"
              v-bind="attrs"
              v-on="on"
              small
            >
              <v-icon color="white">mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Adicionar</span>
        </v-tooltip>
      </div>
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :loading="loading"
      :items="items"
      :options.sync="pagination"
      :server-items-length="totalItems"
      class="elevation-10 table-margin"
      no-data-text="Nenhuma falta encontrada"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ attrs, on }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              small
              class="mr-2"
              @click="showAttest({ item: item })"
              >mdi-receipt</v-icon
            >
          </template>
          <span>Atestado</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ attrs, on }">
            <v-icon
              small
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="view(item)"
              >mdi-information</v-icon
            >
          </template>

          <span>Informações</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ attrs, on }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              small
              class="mr-2"
              @click="editAbsence({ item: item })"
              >mdi-pencil</v-icon
            >
          </template>
          <span>Editar</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ attrs, on }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              small
              class="mr-2"
              @click="showDeleteDialog(item)"
              >mdi-delete</v-icon
            >
          </template>
          <span>Excluir</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.type_abstence`]="{ item }">
        <v-chip
          :color="getColor(item.type_abstence)"
          class="ma-2"
          text-color="white"
          label
          >{{ getTypeDescription(item.type_abstence) }}</v-chip
        >
      </template>

      <template v-slot:[`item.start_date`]="{ item }">
        <span class="ma-2">{{ getDate(item.start_date) }}</span>
      </template>
      <template v-slot:[`item.finish_date`]="{ item }">
        <span class="ma-2">{{ getDate(item.finish_date) }}</span>
      </template>
    </v-data-table>

    <v-dialog :value="dialog" width="800" @click:outside="closeDialog">
      <absences-form></absences-form>
    </v-dialog>

    <v-dialog
      :value="filterDialog"
      @click:outside="setShowFilter(false)"
      width="600"
    >
      <absences-filter></absences-filter>
    </v-dialog>

    <v-dialog :value="dialogDelete" persistent width="300">
      <v-card>
        <v-card-title class="headline">Confirmar a exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn text @click="closeDeleteDialog()">Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn text type="submit" right @click="deleteAbsence"
              >Confirmar</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import AbsencesForm from "./AbsencesForm.vue";
import AbsencesFilter from "./AbsencesFilter.vue";

export default {
  name: "Absences",

  created() {
    this.getItems({ resetPage: false });
    this.getCollaborators();

  },

  components: {
    AbsencesForm,
    AbsencesFilter,
  },

  methods: {
    ...mapActions("absences", [
      "getItems",
      "getCollaborators",
      "closeDialog",
      "showAttest",
      "closeDeleteDialog",
      "deleteAbsence",
      "showDeleteDialog",
    ]),
    ...mapMutations("absences", [
      "addAbsence",
      "view",
      "setShowFilter",
      "editAbsence",
    ]),
    getColor(type) {
      if (type == 0) {
        return "deep-orange darken-4";
      } else {
        return "green darken-3";
      }
    },

    getDate(date) {
      if (date) {
        var d = new Date(date);
        return d.toLocaleDateString() + " " + d.toLocaleTimeString();
      }
      return "";
    },

    getTypeDescription(type) {
      return this.possible_types[type].description;
    },
  },
  computed: {
    ...mapState("login", ["userData"]),
    ...mapState("absences", [
      "dialog",
      "items",
      "notification",
      "mode",
      "loading",
      "totalItems",
      "filterDialog",
      "possible_types",
      "dialogDelete",
    ]),
    ...mapFields("absences", ["pagination"]),
  },
  data: () => ({
    headers: [
      { text: "ID", value: "id", sortable: true },
      { text: "Descrição", value: "description", sortable: true },
      {
        text: "Data Inicial",
        value: "start_date",
        sortable: true,
        align: "center",
      },
      {
        text: "Data Final",
        value: "finish_date",
        sortable: true,
        align: "center",
      },
      {
        text: "Colaborador",
        value: "collaborator",
        sortable: true,
        align: "center",
      },
      { text: "Tipo", value: "type_abstence", sortable: true, align: "center" },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        align: "center",
        width: "10%",
      },
    ],
  }),

  watch: {
    pagination: {
      handler() {
        this.getItems({ resetPage: false });
      },
    },
  },
};
</script>
