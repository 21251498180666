<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar
      :color="notification.type"
      right
      top
      :timeout="2000"
      v-model="notification.show"
    >
      {{ notification.message }}
    </v-snackbar>

    <v-toolbar flat class="elevation-5">
      <div>
        <v-toolbar-title>Base de dados: Banco Central do Brasil (BACEN)</v-toolbar-title>
      </div>
      <v-spacer></v-spacer>
      <div class="button-toolbar">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="black"
              dark
              class="ma-2"
              @click="setShowFilter(true)"
              v-bind="attrs"
              v-on="on"
              small
            >
              <v-icon color="white">mdi-filter</v-icon>
            </v-btn>
          </template>
          <span>Filtro</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="black"
              dark
              class="ma-2"
              @click="getItems"
              v-bind="attrs"
              v-on="on"
              small
              ><v-icon>mdi-refresh</v-icon></v-btn
            >
          </template>
          <span>Recarregar</span>
        </v-tooltip>
      </div>
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :loading="loading"
      :items="items"
      :options.sync="pagination"
      :server-items-length="totalItems"
      class="elevation-10 table-margin"
      no-data-text="Nenhuma banco encontrado"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ attrs, on }">
            <v-icon
              small
              color="black"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="view(item)"
              >mdi-information</v-icon
            >
          </template>
          <span>Informações</span>
        </v-tooltip>

        <!-- <v-tooltip bottom v-if="profileAccess()">
          <template v-slot:activator="{ attrs, on }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              small
              class="mr-2"
              @click="edit(item)"
              >mdi-pencil</v-icon
            >
          </template>
          <span>Editar</span>
        </v-tooltip>

        <v-tooltip bottom v-if="profileAccess()">
          <template v-slot:activator="{ attrs, on }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              small
              class="mr-2"
              @click="deleteBank(item)"
              >mdi-delete</v-icon
            >
          </template>
          <span>Excluir</span>
        </v-tooltip> -->
      </template>
    </v-data-table>

    <v-dialog
      :value="showDialog"
      @click:outside="setShowDialog(false)"
      width="600"
    >
      <banks-bacen-form></banks-bacen-form>
    </v-dialog>

    <v-dialog
      :value="filterDialog"
      @click:outside="setShowFilter(false)"
      width="600"
    >
      <banks-bacen-filter></banks-bacen-filter>
    </v-dialog>

    <v-dialog
      :value="deleteDialog"
      @click:outside="setDeleteDialog(true)"
      @keydown.enter="deleteItem"
      width="300"
    >
      <v-card>
        <v-card-title class="headline">Confirmar a exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn text @click="setDeleteDialog(false)">Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn text type="submit" right @click="deleteItem"
              >Confirmar</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import BanksBacenForm from "./BanksBacenForm";
import BanksBacenFilter from "./BanksBacenFilter";

export default {
  name: "BanksBacen",
  components: {
    BanksBacenForm,
    BanksBacenFilter,
  },
  created() {
    this.getItems();
  },
  methods: {
    ...mapActions("banks_bacen", ["getItems", "deleteItem", "viewItem"]),
    ...mapMutations("banks_bacen", [
      "setShowDialog",
      "view",
      "setShowFilter",
      "setDeleteDialog",
      "deleteBank",
    ]),

    // Método pra decidir se o usuário ADMINISTRADOR do sistema interno pode alterar os dados da tela:
    // profileAccess() {
    //   if (
    //     this.userData.role == 99
    //   ) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
  },
  computed: {
    ...mapState("login", ["userData"]),
    ...mapState("banks_bacen", [
      "items",
      "notification",
      "mode",
      "loading",
      "totalItems",
      "showDialog",
      "filterDialog",
      "deleteDialog",
    ]),
    ...mapFields("banks_bacen", ["pagination"]),
  },
  data: () => ({
    headers: [
      { text: "ID", value: "id", sortable: true },
      { text: "ISPB", value: "ispb", sortable: true },
      { text: "Nome", value: "name", sortable: true },
      { text: "Código", value: "code", sortable: true },
      { text: "Participante da COMPE", value: "compe", sortable: true },
      { text: "Acesso Principal", value: "access", sortable: true },
      { text: "Nome Extenso", value: "full_name", sortable: true },
      { text: "Início da Operação", value: "date_op", sortable: true },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        align: "center",
        width: "10%",
      },
    ],
  }),
  watch: {
    pagination: {
      handler() {
        this.getItems({ resetPage: false });
      },
    },
  },
};
</script>
