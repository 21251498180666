<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar
      :color="notification.type"
      right
      top
      :timeout="2000"
      v-model="notification.show"
    >
      {{ notification.message }}
    </v-snackbar>

    <v-toolbar>
      <v-icon color="black">mdi-account-cancel-outline</v-icon>
      <v-toolbar-title class="font-weight-medium">Blacklist de Saques</v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="button-toolbar text-right">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="black"
              dark
              class="ma-2"
              @click="setDialog({ dialog: 'filterDialog', show: true })"
              v-bind="attrs"
              v-on="on"
              small
            >
              <v-icon color="white">mdi-filter</v-icon>
            </v-btn>
          </template>
          <span>Filtro</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="black"
              dark
              class="ma-2"
              @click="getItems()"
              v-bind="attrs"
              v-on="on"
              small
              ><v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Recarregar</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="black"
              dark
              class="ma-2"
              @click="
                setDialog({ dialog: 'formDialog', show: true, mode: 'ADD' })
              "
              v-bind="attrs"
              v-on="on"
              small
            >
              <v-icon color="white">mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Novo registro</span>
        </v-tooltip>
      </div>
    </v-toolbar>

    <v-card>
      <v-data-table
        :headers="headers"
        :loading="loading"
        :items="items"
        :options.sync="pagination"
        :server-items-length="totalItems"
        no-data-text="Nenhum registro encontrado"
        class="elevation-10 table-margin"
        show-expand
        :expanded.sync="expanded"
      >
        <template
          v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }"
        >
          <v-icon @click="expand(true)" v-if="item.observation && !isExpanded"
            >mdi-chevron-down</v-icon
          >
          <v-icon @click="expand(false)" v-if="item.observation && isExpanded"
            >mdi-chevron-up</v-icon
          >
        </template>
        <template v-slot:[`item.blocked_type`]="{ item }">
          <span>{{ getBlockedTypeName(item.blocked_type) }}</span>
        </template>
        <template v-slot:expanded-item="{ item }">
          <td>Observação: {{ item.observation }}</td>
        </template>
        <template v-slot:[`item.date_created`]="{ item }">
          <span>{{ getDateCreated(item.date_created) }}</span>
        </template>


        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ attrs, on }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                class="mr-2"
                @click="
                  setDialog({
                    dialog: 'formDialog',
                    show: true,
                    mode: 'EDIT',
                    item: item,
                  })
                "
                >mdi-pencil</v-icon
              >
            </template>
            <span>Editar</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ attrs, on }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                class="mr-2"
                @click="
                  setDialog({ dialog: 'deleteDialog', show: true, item: item })
                "
                >mdi-delete</v-icon
              >
            </template>
            <span>Excluir</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      :value="deleteDialog"
      @click:outside="setDialog({ dialog: 'deleteDialog', show: false, mode: '' })"
      @keydown.enter="deleteItem"
      width="450"
    >
      <v-card>
        <v-card-title class="headline">Confirmar a exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn
              color="red lighten-1"
              @click="setDialog({ dialog: 'deleteDialog', show: false, mode: '' })"
              >Cancelar</v-btn
            >
          </v-col>
          <v-col class="text-right">
            <v-btn color="green lighten-1" type="submit" right @click="deleteItem"
              >Confirmar</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      :value="filterDialog"
      width="800"
      @click:outside="setDialog({ dialog: 'filterDialog', show: false, mode: '' })"
    >
      <withdrawals-blacklist-filter></withdrawals-blacklist-filter>
    </v-dialog>

    <v-dialog
      :value="formDialog"
      @click:outside="setDialog({ dialog: 'formDialog', show: false, mode: '' })"
      width="1000"
    >
      <withdrawals-blacklist-form></withdrawals-blacklist-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import WithdrawalsBlacklistForm from "./WithdrawalsBlacklistForm";
import WithdrawalsBlacklistFilter from "./WithdrawalsBlacklistFilter";

export default {
  name: "WithdrawalsBlacklist",
  components: {
    "withdrawals-blacklist-form": WithdrawalsBlacklistForm,
    "withdrawals-blacklist-filter": WithdrawalsBlacklistFilter,
  },
  created() {
    console.log('cheguei aqui')
    this.getItems()
    console.log('cheguei aqui 2 ')
  },
  methods: {
    ...mapActions("withdrawals_blacklist", ["getItems", "deleteItem"]),
    ...mapMutations("withdrawals_blacklist", ["view", "setDialog"]),
    getBlockedTypeName(param) {
      return this.possible_blocked_types[param];
    },
    getDateCreated(date_created) {
      if (date_created != null) {
        var d = new Date(date_created);
        return d.toLocaleDateString() + " " + d.toLocaleTimeString();
      } else {
        return "--------";
      }
    },
  },
  computed: {
    ...mapState("withdrawals_blacklist", [
      "items",
      "notification",
      "totalItems",
      "filterDialog",
      "deleteDialog",
    ]),
    ...mapFields("withdrawals_blacklist", [
      "pagination",
      "formDialog",
      "loading",
      "filter.id",
      "filter.id_blacklist",
      "filter.blocked_type",
      "filter.name_blocked_type",
      "filter.observation",
    ]),
  },

  data: () => ({
    expanded: [],
    headers: [
      {
        text: "Data",
        value: "date_created",
        sortable: false,
        align: "center",
      },
      {
        text: "Tipo de Bloqueio",
        value: "blocked_type",
        sortable: false,
        align: "center",
      },
      {
        text: "ID Blacklist",
        value: "id_blacklist",
        sortable: false,
        align: "center",
      },
      { text: "", value: "data-table-expand", sortable: false },
      { text: "Ações", value: "actions", sortable: false },
    ],
    possible_blocked_types: ["ID", "CPF", "CNPJ", "Chave PIX" ],
  }),
};
</script>
