import axios from 'axios';
import endPoints from '../../../config/endPoints';
import { getField, updateField } from 'vuex-map-fields';

const formatDate = (date) => {
    if (!date) return null;

    return date.getTime();
};

export default {
    namespaced: true,
    state: {
        chat_number: 0,
        instance_id: '',
        tag: 'mkt-1',
        start_date: '',
        finish_date: '',
        text_message: '',
        instance_send: '',
        image: '',
        limit: 999999,
        is_group: 0,
        notificationVisible: false,
        notificationMessage: '',
        notificationTime: 0,
        notificationType: '',
        type_message: 1
    },
    mutations: {

        showNotification: (state, payload) => {
            state.notificationVisible = payload.show,
                state.notificationMessage = payload.message,
                state.notificationTime = payload.notificationTime ? payload.notificationTime : 1000
            state.notificationType = payload.type
        },
        
        setChatNumber: (state, payload) => {
            console.log("Chat number", payload)
            state.chat_number = payload.number
        },
        updateField
    },
    getters: {
        getField
    },
    actions: {
        calcMassMessages: ({commit, state}) => {
            let url = endPoints.httpchats;
            url += '/get_mass_messages'
            let payload = {
                instance_id: state.instance_id,
                content: state.text_message,
                start_date: formatDate(state.start_date),
                finish_date: formatDate(state.finish_date),
                tag: state.tag,
                is_group: state.is_group == 0 ? false : true,
                instance_send: state.instance_send,
                image: state.image?.fileBase64,
                type_message: state.type_message,
                limit: state.limit
            }

            axios.post(url, payload).then(response => {
                let notification = {
                    show: true,
                    message: "Quantidade calculada!",
                    notificationTime: 2500,
                    type: "success"
                }
                commit('showNotification', notification)
                console.log(response.data.sended)
                commit('setChatNumber', {number: response.data.sended})
            })
        },
        sendMessage: ({commit, state}) => {
            let url = endPoints.httpchats;
            url += '/send_mass_messages'
            let payload = {
                instance_id: state.instance_id,
                content: state.text_message,
                start_date: formatDate(state.start_date),
                finish_date: formatDate(state.finish_date),
                tag: state.tag,
                is_group: state.is_group == 0 ? false : true,
                instance_send: state.instance_send,
                image: state.image?.fileBase64,
                type_message: state.type_message,
                limit: parseInt(state.limit)
            }

            axios.post(url, payload).then(() => {
                let notification = {
                    show: true,
                    message: "Requisição de envio criada!",
                    notificationTime: 2500,
                    type: "success"
                }
                commit('showNotification', notification)
            }).catch(() => {
                let notification = {
                    show: true,
                    message: "Ocorreu um erro no envio!",
                    notificationTime: 2500,
                    type: "error"
                }
                commit('showNotification', notification)
            });
        }
    }
}