const getHelloPart = () => {
    let data = new Date();
    var hora = data.getHours();
    if(hora >= 0 && hora < 12) {
        return "Bom Dia"
    }
    if(hora >= 12 && hora < 18) {
        return "Boa Tarde"
    }
    if(hora >= 18 && hora < 24) {
        return "Boa Noite"
    }
}

export const getMessageContent = (userName, content) => {
    if(typeof(content) == "string" && content.length > 0) {
        var messageTreated = content
        .replaceAll("[nome_usuario]", userName)
        .replaceAll("[parte_do_dia]", getHelloPart())
        return messageTreated;
    } 
}