<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2000"
      v-model="showNotification"
    >{{ notificationMessage }}</v-snackbar>
    <v-toolbar flat class="elevation-5">
      <div>
        <v-toolbar-title>Produtos</v-toolbar-title>
      </div>
      <v-spacer></v-spacer>
      <div class="button-toolbar">
        <!-- <download-excel
          :before-generate="generateSheet"
          :before-finish="finishSheet"
          :name="getSheetName()"
          :fetch="getItemsNoPage"
          :export-fields="sheet_fields"
          class="excel"
          worksheet="Produtos"
          type="xls"
        >
          <v-btn color="black" dark class="ma-2" small>
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </download-excel> -->
        <v-tooltip bottom>
          <template v-slot:activator="{on, attrs}">
            <v-btn color="black" dark class="ma-2" @click="downloadXlsx({ show: true })" v-bind="attrs" v-on="on" small>
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </template>
          <span>Gerar Planilha</span>
        </v-tooltip>
        <v-btn color="black" dark class="ma-2" @click="showFilter({show: true})" small>
          <v-icon :color="filtered ? 'grey' : 'white'">mdi-filter</v-icon>
        </v-btn>
        <v-btn color="black" dark class="ma-2" @click="getItems" small>
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-btn color="black" dark class="ma-2" @click="add" small>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :loading="loading"
      :items="items"
      :options.sync="pagination"
      :server-items-length="totalItems"              
      no-data-text="Nenhuma registro encontrado"
      class="elevation-10 table-margin"
    >
    
      <template v-slot:[`item.value`]="{ item }">
        <span>{{ (item.value) ? item.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 0}}</span>
      </template>
      <template v-slot:[`item.type`]="{ item }">
        <span>{{ getType(item.type)}}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="viewItem(item)">mdi-information</v-icon>
        <v-icon small class="mr-2" @click="edit({item: item})">mdi-pencil</v-icon>
        <v-icon
          small
          class="mr-2"
          @click="showDeleteDialog(item)"
        >mdi-delete</v-icon>
        <v-icon small class="mr-2" v-if="item.verified == 0 && profileAccess()" @click="verifyItem({item: item})">mdi-check</v-icon>
      </template>
    </v-data-table>

    <v-dialog :value="dialog" @click:outside="closeDialog()" width="800">
      <products-form></products-form>
    </v-dialog>

    <v-dialog :value="dialogFilter" width="600" @click:outside="showFilter({show: false})" @keydown.enter="filter">
      <products-filter></products-filter>
    </v-dialog>

    <v-dialog :value="dialogDelete"  @click:outside="closeDeleteDialog" @keydown.enter="deleteItem" width="300">
      <v-card>
        <v-card-title class="headline">Confirmar a exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn text @click="closeDeleteDialog()">Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn text type="submit" right @click="deleteItem">Confirmar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import ProductsForm from "./ProductsForm";
import ProductsFilter from "./ProductsFilter";
// import JsonExcel from "vue-json-excel";
import { utils, writeFile } from "xlsx";

export default {
  name: "Products",
  computed: {
    ...mapState("login", ["userData"]),
    ...mapState("products", [
      "dialog",
      "dialogDelete",
      "item",
      "errors",
      "filtered",
      "loading",
      "mode",
      "notificationType",
      "notificationMessage",
      "rowsPerPage",
      "totalItems",
      "items",
    ]),
    ...mapFields("products", [
      "pagination",
      "dialogFilter",
      "overlay",
      "showNotification",
      // ...
    ]),
  },
  created() {
    this.getItems()
  },
  components: {
    // "download-excel": JsonExcel,
    "products-form": ProductsForm,
    "products-filter": ProductsFilter,
  },
  methods: {
    ...mapMutations("products", [
      "add",
      "closeDialog",
      "edit",
      "viewItem",
      "showFilter"
    ]),
    ...mapActions("products", [
      "closeDeleteDialog",
      "deleteItem",
      "getItems",
      "getItemsNoPage",
      "showDeleteDialog",
      "verifyItem"
    ]),
    filter() {
      this.getItems({ resetPage: true });
      this.showFilter({ show: false });
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      }
      return false;
    },
    getDate(date) {
      if (date) {
        var d = new Date(date);
        return d.toLocaleDateString() + " " + d.toLocaleTimeString();
      }
      return "";
    },
    getType(type) {
      let types = ["Moletom", "Blusa", "Boné", "Calça", "Bermuda", "Outros"]
      return types[type]
    },
    finishSheet() {
      this.overlay = false;
    },
    generateSheet() {
      this.overlay = true;
    },
    getSheetName() {
      var date = new Date();
      var name =
        "produtos_" +
        date.toLocaleDateString() +
        " " +
        date.toLocaleTimeString();
      return name + ".xls";
    },
    async downloadXlsx() {
      this.generateSheet()
      try {
        let itemsNoPage = await this.getItemsNoPage()
        let possible_types = ['Moletom', 'Blusa', 'Boné', 'Calça', 'Bermuda', 'Outros']
        if(itemsNoPage) {
          const wb = utils.book_new()
            let items = itemsNoPage.map(row => ({
              "Nome":  row.name,
              "Comprimento":  row.lenght,
              "Altura":  row.height,
              "Largura":  row.width,
              "Peso":  row.weight,
              "Tipo":  possible_types[row.type],
            }))
          const ws = utils.json_to_sheet(items)
          utils.book_append_sheet(wb, ws, "Produtos")
          await writeFile(wb, this.getSheetName())
        }
      } catch (error) {
        this.finishSheet()
      } finally {
        this.finishSheet()
      }
    },
    profileAccess() {
      if(this.userData.role > 90 || this.userData.role == 2 ) {
        return true
      } else {
        return false
      }
    },
  },
  data: () => ({
    build_fields: {},
    expanded: [],
    headers: [
      { text: "Nome", value: "name", sortable: false },
      { text: "Valor", value: "value", sortable: false },
      { text: "Comprimento", value: "length", sortable: false },
      { text: "Altura", value: "height", sortable: false },
      { text: "Largura", value: "width", sortable: false },
      { text: "Peso", value: "weight", sortable: false },
      { text: "Tipo", value: "type", sortable: false },
      { text: "Ações", value: "actions", sortable: false, align: "center" },
    ],
    sheet_fields: {
      Nome: {
        field: "name"
      },
      Comprimento: {
        field: "length"
      },
      Altura: {
        field: "height"
      },
      Largura: {
        field: "width"
      },
      Peso: {
        field: "weight"
      },
      Tipo: {
        field: 'type',
        callback: (type) => {
          let types = ["Moletom", "Blusa", "Boné", "Calça", "Bermuda", "Outros"]
          return types[type]
        }
      },
    },
    valid: false,
  }),
  watch: {
    pagination: {
      handler() {
        this.getItems({ resetPage: false });
      },
    },
  },
};
</script>
