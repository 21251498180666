import axios from 'axios';
import endPoints from '../../../config/endPoints';
import { getField, updateField } from 'vuex-map-fields';

export default {
    namespaced: true,
    state: {
        active: '',
        overlay: false,
        status: '',
        service: '',
        chatDialog: false,
        editedItem: {},
        showNotification: false,
        notificationMessage: '',
        notificationType: ''
    },
    mutations: {
        add: (state) => { 
            state.showDialog = true
            state.mode = "ADD"
        },
        overlay: (state, payload) => {
            state.overlay = payload.show
        },
        setActive: (state, payload) => {
            state.active = payload.active
        },
        setStatus: (state, payload) => {
            state.status = payload.status
        },
        showChatDialog: (state, payload) => {
            state.chatDialog = payload.show
        },
        updateField,
        showNotification: (state, payload) => {
            (state.notificationMessage = payload.message),
            (state.notificationType = payload.type),
            (state.showNotification = payload.show)
        },
    },
    getters: {
        getField,
    },
    actions: {
        editPassword: ({ dispatch, commit }, payload) => {
            var url = endPoints.users + "/update_useredit/" + payload.user_id;
            if (payload.last_password == payload.initial_password && payload.last_password.length > 7) {   
                payload = {
                    password: payload.last_password,
                    old_password: payload.old_password
                }           
                axios.put(url, payload).then(
                  () => {
                    dispatch("login/getUserEdit", payload.user_id , { root: true });
                    dispatch("login/logout", {}, { root: true });
                    let notification = {
                      show: true,
                      message: "Senha alterada com sucesso",
                      type: "success",
                    };
                    commit("login/showNotification", notification, { root: true });
                  },
                  (error) => {
                    let notification = {
                      show: true,
                      message: error.response.data.message,
                      type: "error",
                    };
                    commit("showNotification", notification);
                  }
                );
            } else if (payload.last_password != payload.initial_password) {
                let notification = {
                    show: true,
                    message: 'As senhas devem coincidir',
                    type: "error",
                };
                commit("showNotification", notification);            
            } else {
                let notification = {
                    show: true,
                    message: 'Senha inválida',
                    type: "error",
                };
                commit("showNotification", notification);            
            }
        },
        editUserName: ({ dispatch, commit }, payload) => {
            var url = endPoints.users + "/update_useredit/" + payload.user_id;
            if (payload.name.length > 3) {              
                axios.put(url, payload).then(
                  () => {
                    dispatch("login/getUserEdit", payload.user_id , { root: true });
                    let notification = {
                      show: true,
                      message: "Nome alterado com sucesso",
                      type: "success",
                    };
                    commit("showNotification", notification);
                    commit("showDialog", false);
                  },
                  (error) => {
                    let notification = {
                      show: true,
                      message: error.response.data.message,
                      type: "error",
                    };
                    commit("showNotification", notification);
                  }
                );
            } else {
                let notification = {
                    show: true,
                    message: 'O Nome deve conter mais de 3 letras',
                    type: "error",
                };
                commit("showNotification", notification);            
            }
        },
        editUserImg: async ({ dispatch, commit }, payload) => {
            var url = endPoints.users + "/update_useredit/" + payload.user_id;
            payload = {
                image: payload.image
            }

            if (payload.image) {              
                axios.put(url, payload).then(
                  () => {
                    dispatch("login/getUserEdit", payload.user_id , { root: true });
                    let notification = {
                      show: true,
                      message: "Imagem salva com sucesso",
                      type: "success",
                    };
                    commit("showNotification", notification);
                  },
                  (error) => {
                    let notification = {
                      show: true,
                      message: error.response.data.message,
                      type: "error",
                    };
                    commit("showNotification", notification);
                  }
                );
            } else {
                let notification = {
                    show: true,
                    message: 'O arquivo não é suportado ou não existe',
                    type: "error",
                };
                commit("showNotification", notification);            
            }

        }
    }
}