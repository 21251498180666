<template>
    <div>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <v-snackbar
            :color="notification.type"
            top
            :timeout="2000"
            v-model="notification.show"
        >
            {{ notification.message }}
        </v-snackbar>

        <v-toolbar flat class="elevation-5">
            <v-toolbar-title><b>Períodos de Conciliação</b></v-toolbar-title>
            <v-spacer></v-spacer>
            <div class="button-toolbar">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="black"
                            dark
                            class="ma-2"
                            @click="showFilterDialog({ show: true })"
                            v-bind="attrs"
                            v-on="on"
                            small
                        >
                            <v-icon color="white">mdi-filter</v-icon>
                        </v-btn>
                    </template>
                    <span>Filtro</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="black"
                            dark
                            class="ma-2"
                            @click="getItems"
                            v-bind="attrs"
                            v-on="on"
                            small
                            ><v-icon>mdi-refresh</v-icon></v-btn
                        >
                    </template>
                    <span>Recarregar</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="black"
                            dark
                            class="ma-2"
                            v-bind="attrs"
                            v-on="on"
                            @click="add()"
                            small
                            ><v-icon>mdi-plus</v-icon></v-btn
                        >
                    </template>
                    <span>Adicionar Periodo de Conciliação</span>
                </v-tooltip>
            </div>
        </v-toolbar>

        <v-data-table
            :headers="headers"
            :loading="loading"
            :items="items"
            :options.sync="pagination"
            :server-items-length="totalItems"
            class="elevation-10 table-margin"
            no-data-text="Nenhum periodo de conciliação encontrado"
            :footer-props="{
                'items-per-page-options': [10, 20, 50, 100],
                'items-per-page-text': 'Itens por página',
                'show-first-last-page': true,
                'page-text': `Página ${pagination.page} de ${
                    parseInt(totalItems / pagination.itemsPerPage) + 1
                }`,
            }"
        >
            <template v-slot:[`item.start_date`]="{ item }">
                <span>{{ getDate(item.start_date) }}</span>
            </template>
            <template v-slot:[`item.finish_date`]="{ item }">
                <span>{{ getDate(item.finish_date) }}</span>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ attrs, on }">
                        <v-icon
                            small
                            color="black"
                            class="mr-2"
                            v-bind="attrs"
                            v-on="on"
                            @click="fillTables(item)"
                            >mdi-table-arrow-up</v-icon
                        >
                    </template>
                    <span>Preencher Tabelas</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ attrs, on }">
                        <v-icon
                            small
                            color="black"
                            class="mr-2"
                            v-bind="attrs"
                            v-on="on"
                            @click="openPeriodDetail(item)"
                            >mdi-information</v-icon
                        >
                    </template>
                    <span>Informações</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ attrs, on }">
                        <v-icon
                            color="blue"
                            v-bind="attrs"
                            v-on="on"
                            small
                            class="mr-2"
                            @click="edit({ item: item })"
                            >mdi-pencil</v-icon
                        >
                    </template>
                    <span>Editar</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ attrs, on }">
                        <v-icon
                            color="red"
                            v-bind="attrs"
                            v-on="on"
                            small
                            class="mr-2"
                            @click="
                                showDeleteDialog({ item: item, show: true })
                            "
                            >mdi-delete</v-icon
                        >
                    </template>
                    <span>Excluir</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ attrs, on }">
                        <v-icon
                            color="black"
                            v-bind="attrs"
                            v-on="on"
                            small
                            class="mr-2"
                            @click="linkOperations({ item: item })"
                            >mdi-vector-link</v-icon
                        >
                    </template>
                    <span>Vincular Operações</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ attrs, on }">
                        <v-icon
                            color="red"
                            v-bind="attrs"
                            v-on="on"
                            small
                            class="mr-2"
                            @click="removeVinculations({ item: item })"
                            >mdi-close-box-multiple</v-icon
                        >
                    </template>
                    <span>Remover Vinculações</span>
                </v-tooltip>
                <!-- <v-tooltip bottom>
                    <template v-slot:activator="{ attrs, on }">
                        <v-icon
                            color="black"
                            v-bind="attrs"
                            v-on="on"
                            small
                            class="mr-2"
                            @click="linkOperations({ item: item })"
                            >mdi-calendar-multiple</v-icon
                        >
                    </template>
                    <span>Vincular Operações Fora do Período</span>
                </v-tooltip> -->
            </template>
        </v-data-table>

        <v-dialog
            :value="formDialog"
            @click:outside="showFormDialog({ show: false })"
            @keydown.esc="showFormDialog({ show: false })"
            width="1000"
        >
            <period-conciliation-form></period-conciliation-form>
        </v-dialog>

         <v-dialog
            :value="errDialog"
            @click:outside="showErrDialog({ show: false })"
            @keydown.esc="showErrDialog({ show: false })"
            width="1100"
        >
            <v-card>
                <v-card-title class="headline"
                    >{{ messageErr }}</v-card-title
                >

                <v-card-actions>
                    <v-col class="text-center">
                        <v-btn width="100" color="error" @click="showErrDialog({ show: false })"
                            >Ok</v-btn
                        >
                    </v-col>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            :value="filterDialog"
            @click:outside="showFilterDialog({ show: false })"
            @keydown.esc="showFilterDialog({ show: false })"
            width="600"
        >
            <period-conciliation-filter></period-conciliation-filter>
        </v-dialog>

        <v-dialog
            :value="deleteDialog"
            @click:outside="showDeleteDialog({ show: false })"
            @keydown.enter="deleteItem"
            @keydown.esc="showDeleteDialog({ show: false })"
            width="300"
        >
            <v-card>
                <v-card-title class="headline"
                    >Confirmar a exclusão?</v-card-title
                >

                <v-card-actions>
                    <v-col>
                        <v-btn text @click="showDeleteDialog({ show: false })"
                            >Cancelar</v-btn
                        >
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text type="submit" right @click="deleteItem"
                            >Confirmar</v-btn
                        >
                    </v-col>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import PeriodConciliationForm from './PeriodConciliationForm'
import PeriodConciliationFilter from './PeriodConciliationFilter'

export default {
    name: 'PeriodConciliation',

    created() {
        this.getItems()
    },

    components: {
        PeriodConciliationForm,
        PeriodConciliationFilter,
    },

    methods: {
        ...mapActions('period_conciliation', [
            'getItems',
            'deleteItem',
            'linkOperations',
            'removeVinculations',
            'openPeriodDetail',
            'fillTables',
        ]),
        ...mapMutations('period_conciliation', [
            'showFormDialog',
            'showErrDialog',
            'add',
            'edit',
            'view',
            'showFilterDialog',
            'showDeleteDialog',
            'deleteCC',
        ]),
        getDate(date) {
            if (date) {
                var d = new Date(date)
                return d.toLocaleDateString() + ' ' + d.toLocaleTimeString()
            }
            return ''
        },
    },
    computed: {
        ...mapState('login', ['userData']),
        ...mapState('period_conciliation', [
            'items',
            'notificationType',
            'notificationMessage',
            'notification',
            'mode',
            'loading',
            'totalItems',
            'showDialog',
            'filterDialog',
            'deleteDialog',
            'messageErr',
        ]),
        ...mapFields('period_conciliation', [
            'formDialog',
            'errDialog',
            'pagination',
            'overlay',
        ]),
    },
    data: () => ({
        headers: [
            { text: 'ID', value: 'id', sortable: true },
            { text: 'Inicio', value: 'start_date', sortable: true },
            { text: 'Fim', value: 'finish_date', sortable: true },
            {
                text: 'Ações',
                value: 'actions',
                sortable: false,
                align: 'center',
                width: '10%',
            },
        ],
    }),
    watch: {
        pagination: {
            handler() {
                this.getItems({ resetPage: false })
            },
        },
    },
}
</script>
