import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../config/endPoints';

export default {
    namespaced: true,
    state: {
        agent: {},
        dialog: false,
        contacts: [],
        documents: [],
        editedItem: {},
        itemToDelete: {},
        loading_agent: false,
        loading_documents: false,
        loading_subagent: false,
        mode: 'LIST',
        page: 1,
        subAgents: [],
        notificationMessage: '',
        notificationType: "success",
        showNotification: false,
        totalSubAgents: 0,
        totalRakeDeals: 0,
    },
    mutations: {
        loading_agent: (state, payload) => state.loading_agent = payload.loading,
        loading_rake_deals: (state, payload) => {
            state.loading_rake_deals = payload.loading
        },
        showNotification: (state, payload) => {
            state.showNotification = payload.show,
                state.notificationMessage = payload.message,
                state.notificationType = payload.type
        },
        setAgent: (state, payload) => state.agent = payload.agent,
        setSubAgents: (state, payload) => state.subAgents = payload.subAgents,
        setTotalSubAgents: (state, payload) => state.totalSubAgents = payload.total,
        setTotalRakeDeals: (state, payload) => state.totalRakeDeals = payload.total,
        updateField
    },
    getters: {
        getIdAgent: state => {
            return state.agent.id
        },
        getClubAgent: state => {
            return state.agent.id_instance
        },
        getAgentTypeDeal: state => {
            return state.agent.type_deal
        },
        getIdPPPokerAgent: state => {
            return state.agent.id_pppoker
        },
        getField,
    },
    actions: {
        getAgent: ({ commit }, payload) => {
            commit('loading_agent', { loading: true })
            let url = endPoints.agents + "/agent/" + payload.agent_id;
            axios.get(url).then(response => {
                commit('setAgent', { agent: response.data })
                commit('loading_agent', { loading: false })
            });
        },
        goBack: ({ dispatch }) => {
            dispatch('menu/openLink', '/agents', { root: true })
        },
    }
}