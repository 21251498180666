import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../../config/endPoints';
import { defaultErrorNotification } from "../../../constants";


const defaultNotificationItem = {
    title: '',
    date_picker: new Date(),
    date: '',
    body: ''
};

export default {
    namespaced: true,
    state: {
        loading: false,
        items: [],
        dialogForm: false,
        dialogDelete: false,
        dialogFilter: false,
        overlay: false,
        itemToDelete: {},
        showNotification: false,
        selectedItem: {},
        mode: '',
        notificationMessage: '',
        notificationType: '',
        pagination: {
            page: 1,
            itemsPerPage: 10,
        },
        filter: {

            status: ""
        },
    },
    mutations: {
        updateField,
        showLoading: (state, payload) => state.loading = payload.show,
        edit: (state, payload) => {
            state.selectedItem = Object.assign({}, payload.item);
            state.selectedItem.date_picker = new Date(payload.item.date);
            state.dialogForm = true;
            state.mode = "EDIT";
        },
        overlay: (state, payload) => state.ol = payload.show,
        showNotification: (state, payload) => {
            state.showNotification = payload.show,
                state.notificationMessage = payload.message,
                state.notificationType = payload.type
        },
        closeDialogForm: (state) => {
            state.selectedItem = {}
            state.dialogForm = false
            state.mode = "LIST"
        },
        setItems(state, items) {
            state.items = items;
        },
        setLoading(state, loading) {
            state.loading = loading;
        },
        setTotalItems(state, total) {
            state.pagination.totalItems = total;
        },
        addNotification: (state) => {
            state.dialogForm = true;
            state.mode = "ADD";
            state.selectedItem = Object.assign({}, defaultNotificationItem);
        },
        viewNotification: (state, payload) => {
            state.selectedItem = payload;
            state.selectedItem.date_picker = new Date(payload.date);
            state.dialogForm = true;
            state.mode = "VIEW";
        },
        showOverlay: (state, payload) => {
            state.overlay = payload.show;
        },
        setDialogDelete: (state, show) => state.dialogDelete = show,
        setItemToDelete: (state, item) => state.itemToDelete = item,
    },
    getters: {
        getField,
    },
    actions: {
        showDeleteDialog: ({ commit }, item) => {
            commit('setDialogDelete', true)

            commit('setItemToDelete', item)
        },
        closeDeleteDialog: ({ commit }) => {
            commit('setDialogDelete', false)
            commit('setItemToDelete', {})
        },
        deleteScheduleNotification: async ({ dispatch, commit, state }) => {
            var url = endPoints.notifications_schedule + '/schedule/' + state.itemToDelete.id;

            axios.delete(url).then(
                () => {
                    dispatch("getItems", { resetPage: true });
                    let notification = {
                        show: true,
                        message: "Agendamento removido com sucesso",
                        type: "success",
                    };
                    dispatch("closeDeleteDialog")
                    commit("showNotification", notification);
                },
                (error) => {
                    let notification = {
                        show: true,
                        message: error.response.data.message,
                        type: "error",
                    };
                    commit("showNotification", notification);
                }
            );
        },
        rescheduleNotification: async ({ dispatch, commit }, payload) => {
            var url = endPoints.notifications_schedule + '/reschedule';
            console.log(payload)
            let data = {
                idNotificationSchedule: payload.id
            }

            axios.post(url, data).then(
                () => {
                    dispatch("getItems", { resetPage: true });
                    let notification = {
                        show: true,
                        message: "Notificação reagendada com sucesso",
                        type: "success",
                    };
                    commit("showNotification", notification);
                },
                (error) => {
                    let notification = {
                        show: true,
                        message: error.response.data.message,
                        type: "error",
                    };
                    commit("showNotification", notification);
                }
            );
        },
        saveNotification: async ({ dispatch, commit, state }) => {
            var url = endPoints.notifications_schedule;
            let func = "";
            let data = {
                title: state.selectedItem.title,
                body: state.selectedItem.body,
                date: state.selectedItem.date,
            }

            commit("showOverlay", { show: true });
            if (state.mode == "ADD") {
                func = axios.post;
                url += "/create_notification_schedule"
            } else {
                func = axios.put;
                url += "/schedule/" + state.selectedItem.id;
            }
            func(url, data).then(
                () => {
                    dispatch("getItems", { resetPage: true });
                    let notification = {
                        show: true,
                        message: "Notificação agendada com sucesso",
                        type: "success",
                    };
                    commit("showNotification", notification);
                    commit("closeDialogForm");
                    commit("showOverlay", { show: false });
                },
                (error) => {
                    let notification = {
                        show: true,
                        message: error.response.data.message,
                        type: "error",
                    };
                    commit("showNotification", notification);
                    commit("showOverlay", { show: false });
                }
            );
        },
        getItems: async ({ commit, state }, payload) => {
            let filter = state.filter
            let url = endPoints.notifications_schedule + "/schedules";
            if (payload.resetPage) {
                url += "?page=" + 1 + "&" + "itemsPerPage=" + 10;
                commit("setPage", { page: 1, itemsPerPage: 10 });
            } else {
                url +=
                    "?page=" +
                    state.pagination.page +
                    "&" +
                    "itemsPerPage=" +
                    state.pagination.itemsPerPage;
            }

            Object.keys(filter).forEach(function (field) {
                if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
                    url += "&" + field + "=" + filter[field]
                }
            })

            if (state.pagination && state.pagination.sortBy && state.pagination.sortBy.length > 0) {
                url += "&sort_by=" + (state.pagination.sortBy[0] === 'score' ? "id" : state.pagination.sortBy[0])
                url += "&sort_desc=" + state.pagination.sortDesc[0]
            }

            commit("setLoading", true);

            try {
                let items = await axios.get(url);
                commit("setItems", items.data.data)
                commit("setTotalItems", items.data.total);
            } catch (error) {
                commit(
                    "showNotification",
                    defaultErrorNotification(error?.response?.data?.message)
                );


            }
            commit("setLoading", false);
        }
    }
}