import axios from "axios";
import { getField, updateField } from "vuex-map-fields";
import endPoints from "@config/endPoints";
import {
  defaultErrorNotification,
  defaultSuccessNotification,
} from "@constants";
import formatDate from '@util/formatDate.js'


const defaultFilter = {
  id: "",
  start: "",
  end: "",
  date_finalization: "",
  type_movement: "",
  type_transaction: "",
  value: "",
  bank: "",
  slot: "",
  origin: "",
  vinculations: "",
  selected_types_low: "",
}


export default {
  namespaced: true,
  state: {
    getClassColor(item) {
      let ligationsTotals = item.total_vinculations - item.linked_to
      if (item.type_low == 1) {
        return 'brown--text text--darken-1'
      } else {
        if (ligationsTotals == 0) {
          return 'light-green--text text--darken-3'
        } else if (ligationsTotals == 1) {
          return 'blue--text text--darken-4'
        } else if (ligationsTotals == 2 && item.total_vinculations == 3) {
          return 'orange--text text--darken-2'
        }
        else if (ligationsTotals == item.total_vinculations) {
          return 'red--text text--accent-4'
        }
      }

    },
    possible_payments_league_closure: [
      {
        id: 0,
        description: "Recebimento"
      },
      {
        id: 2,
        description: "Pagamento"
      },
      {
        id:3,
        description: "Ajuste"
      }
    ],
    possible_types: [
      { id: 0, description: 'Saque' },
      { id: 1, description: 'Depósito' },
      { id: 2, description: 'Despesa' },
      { id: 3, description: 'Fechamento da Liga' },
    ],
    possible_types_deposits: [
      'Envio Jogador',
      'Envio Agência',
      'Abater Anotados',
      'Adicionar ao Saldo Positivo',
      'Transferência',
      'Bônus',
      'Reembolso',
    ],
    possible_types_withdrawals: [
      'Fichas',
      'Rakeback',
      'Saldo Positivo',
      'Transferência',
      'Ordem Maleta',
      'Devolução',
    ],
    possible_status: [
      "Pendente",
      "Em Andamento",
      "Concluído",
      "Estornado",
      "Aguardando Pagamento",
      "Agendado/Erro",
      "Validado",
      "Recusado",
      "Finalizado com Erro"
    ],
    headers: [
      { text: 'ID do Movimento', value: 'id_moviment', sortable: false },
      { text: 'Criado em', value: 'created_at', sortable: false },
      { text: 'Finalizado em', value: 'finish_at', sortable: false },
      {
        text: 'Tipo de Movimentação',
        value: 'moviment_type',
        sortable: false,
      },
      {
        text: 'Tipo',
        value: 'type',
        sortable: false,
      },
      { text: 'Status', value: 'status', sortable: false },
      { text: 'Valor', value: 'value', sortable: true },
      { text: 'Banco', value: 'bank_name', sortable: false },
      { text: 'Slot', value: 'slot_name', sortable: false },
      { text: 'ID do Jogador', value: 'player_id', sortable: false },
      { text: 'Origem', value: 'origin_name', sortable: false },
      {
        text: 'Ligações',
        value: 'ligations',
        sortable: false,
        align: 'center',
      },
      { text: 'Ações', value: 'actions', sortable: false },
    ],
    items: [],
    selectedItem: {},
    mode: "",
    dialog: false,
    loading: false,
    totalItems: 0,
    filterDialog: false,
    filtered: false,
    filter: {
      id: "",
      start: "",
      end: "",
      date_finalization: "",
      type_movement: "",
      type_transaction: "",
      value: "",
      bank: "",
      slot: "",
      origin: "",
      vinculations: "",
      selected_types_low: "",
    },
    expense_types: [],
    notification: {
      show: false,
      message: "",
      type: "",
    },
    overlay: false,
    pagination: {
      page: 1,
      itemsPerPage: 10,
    },
  },
  mutations: {
    setExpenseTypes: (state, payload) => {
      state.expense_types = payload.expense_types
    },
    showNotification: (state, payload) => (state.notification = payload),
    showLoading: (state, payload) => {
      state.loading = payload.show;
    },
    view: (state, payload) => {
      state.selectedItem = payload;
      state.dialog = true
      state.mode = "VIEW";
    },
    closeDialog: (state) => {
      state.dialog = false
    },
    setTotalItems: (state, payload) => (state.totalItems = payload.total),
    setItems: (state, payload) => {
      state.items = payload.items
    },
    clearFilter: (state) => {
      state.filter = Object.assign({}, defaultFilter)
    },
    setShowFilter: (state, payload) => {
      state.filterDialog = payload;
    },
    setFiltered: (state, payload) => state.filtered = payload.filtered,


    updateField,
  },
  actions: {
    getExpenseTypes: ({ commit }) => {
      let url = endPoints.expense_types;
      url += "?page=" + 1 + "&" + "itemsPerPage=" + 999;
      axios.get(url).then((items) => {
        var types = items.data.data;
        commit("setExpenseTypes", { expense_types: types });
      });
    },

    getItems: async ({ commit, state, rootGetters }) => {
      let period = rootGetters['period_conciliation/getPeriodSelected']
      if (period == 0 || period == null || period == undefined || period == "") {
        period = location.href.split('period_conciliation/')[1]
      }
      let filter = state.filter;
      let url = `${endPoints.conciliation}/period_conciliation_moviments/${period}?page=${state.pagination.page}&itemsPerPage=${state.pagination.itemsPerPage}`;
      Object.keys(filter).forEach(function (field) {
        if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
          commit('setFiltered', { filtered: true })

          if (field == "end" || field == "start" || field == "date_finalization") {
            var e = new Date(filter[field])
            url += "&" + field + "=" + formatDate(e) + " " + e.toLocaleTimeString()
          }
          else if (field == "value") {
            var v = filter[field].replace(",", ".")
            url += "&" + field + "=" + v
          }
          else {
            url += "&" + field + "=" + filter[field]
          }
        }
      });

      if (state.pagination && state.pagination.sortBy && state.pagination.sortBy.length > 0) {

        url += "&sort_by=" + state.pagination.sortBy[0]
        url += "&sort_desc=" + state.pagination.sortDesc[0]
      }

      commit("showLoading", { show: true });

      try {
        let items = await axios.get(url);
        commit("setItems", { items: items.data.data });
        commit("setTotalItems", { total: items.data.total });
      } catch (error) {
        commit(
          "showNotification",
          defaultErrorNotification(error?.response?.data?.message)
        );
      }
      commit("showLoading", { show: false });
    },
    deleteItem: async ({ commit, dispatch, state }) => {
      commit("showLoading", { show: true });
      try {
        await axios.delete(`${endPoints.conciliation}/period_conciliation/${state.selectedItem.id}`);

        commit("showLoading", { show: false });
        commit("showDeleteDialog", { show: false });
        commit("setSelectedItem", {});
        commit(
          "showNotification",
          defaultSuccessNotification("Periodo de conciliação removido com sucesso")
        );

        return dispatch("getItems");
      } catch (error) {
        commit(
          "showNotification",
          defaultErrorNotification(error?.response?.data?.message)
        );
        commit("showLoading", { show: false });
      }
    },
  },
  getters: {
    getField,
  },
};
