import axios from "axios";
import { getField, updateField } from "vuex-map-fields";
import endPoints from "@config/endPoints";
import formatDate from '@util/formatDate.js'
import {
  defaultErrorNotification,
  // defaultSuccessNotification,
} from "../../../constants";

const defaultFilter = {
  id: "",
  type: "",
  type_operation: "",
  cpf_origin: "",
  name_origin: "",
  cpf_destiny: "",
  name_destiny: "",
  value: "",
  start: "",
  status: "",
  end: ""
};

const defaultBankItem = {
  ispb: null,
  name: "",
  code: null,
  compe: "",
  access: "",
  full_name: "",
  date_op: "",
};

export default {
  namespaced: true,
  state: {
    items: [],
    selectedItem: {},
    mode: "",
    loading: false,
    totalItems: 0,
    deleteDialog: false,
    dialogFilter: true,
    showDialog: false,
    filter: {
      id: "",
      bank: "",
      type: "",
      type_operation: "",
      cpf_origin: "",
      name_origin: "",
      cpf_destiny: "",
      name_destiny: "",
      value: "",
      start: new Date(new Date().setHours(0, 0, 0, 0)),
      status: "",
      end: new Date()
    },
    notification: {
      show: false,
      message: "",
      type: "",
    },
    pagination: {
      page: 1,
      itemsPerPage: 10,
    },
  },
  mutations: {
    view: (state, payload) => {
      state.selectedItem = payload;
      state.showDialog = true;
      state.mode = "VIEW";
    },
    clearFilter: (state) => {
      state.filter = Object.assign({}, defaultFilter);
    },
    showFilter: (state, payload) => state.dialogFilter = payload.show,
    setItems: (state, payload) => (state.items = payload),
    setTotalItems: (state, payload) => (state.totalItems = payload),
    setLoading: (state, payload) => (state.loading = payload),
    showNotification: (state, payload) => (state.notification = payload),
    setSelectedItem: (state, payload) => (state.selectedItem = payload),
    setDeleteDialog: (state, payload) => (state.deleteDialog = payload),
    setShowDialog: (state, payload) => {
      state.showDialog = payload;
      state.selectedItem = Object.assign({}, defaultBankItem);
    },
    setShowFilter: (state, payload) => {
      console.log('to aqui')
      state.dialogFilter = payload
    },
    updateField,
  },
  actions: {
    getItems: async ({ commit, state }) => {
      let filter = state.filter;
      let url = `${endPoints.extract}?page=${state.pagination.page}&itemsPerPage=${state.pagination.itemsPerPage}`;


      Object.keys(filter).forEach(function(field) {
        if (
          filter[field] !== "" &&
          filter[field] !== undefined &&
          filter[field] !== null
        ) {
          if (field == "end" || field == "start") {
              var e = new Date(filter[field])
              url += "&" + field + "=" + formatDate(e) + " " + e.toLocaleTimeString()
          } else {
              url += "&" + field + "=" + filter[field]
          }
        }
      });

      if (
        state.pagination &&
        state.pagination.sortBy &&
        state.pagination.sortBy.length > 0
      ) {
        url +=
          "&sort_by=" +
          (state.pagination.sortBy[0] === "id"
            ? "id"
            : state.pagination.sortBy[0]);
        url += "&sort_desc=" + state.pagination.sortDesc[0];
      }

      commit("setLoading", true);

      try {
        let items = await axios.get(url);
        commit("setItems", items.data.data);
        commit("setTotalItems", items.data.total);
      } catch (error) {
        commit(
          "showNotification",
          defaultErrorNotification(error?.response?.data?.message)
        );
      }
      commit("setLoading", false);
    },
    getItemsNoPage: ({ commit, state }) => {
      commit("setLoading", true);
      let filter = state.filter;
      let url = `${endPoints.extract}?page=1&itemsPerPage=99999`;
  
      Object.keys(filter).forEach(function(field) {
        if (
          filter[field] !== "" &&
          filter[field] !== undefined &&
          filter[field] !== null
        ) {
          if (field == "end" || field == "start") {
              var e = new Date(filter[field])
              url += "&" + field + "=" + formatDate(e) + " " + e.toLocaleTimeString()
          } else {
              url += "&" + field + "=" + filter[field]
          }
        }
      });
      return axios.get(url).then(items => {
        commit("setLoading", false);
          return items.data.data
      }).catch(() => {
        commit("setLoading", false);
      });
    }
  },
  getters: {
    getField,
  },
};
