import axios from "axios";
import { getField, updateField } from "vuex-map-fields";
import endPoints from "../../config/endPoints";

const defaultUser = {
  inscription: "",
  name: "",
  email: "",
  role: 0,
  contact: "",
  date_created: "",
  status: 1,
  default_password: 1,
};

export default {
  namespaced: true,
  state: {
    dialog: false,
    dialogDelete: false,
    dialogFilter: false,
    editedUser: {},
    errors: [],
    filter: {
      name: "",
      email: "",
      role: "",
      club: "",
      status: "",
      id_instace: "",
      default_password: "",
      inscription: "",
    },
    userToDelete: {},
    instances: [],
    loading: false,
    mode: "LIST",
    overlay: false,
    pagination: {
      page: 1,
      itemsPerPage: 10,
    },
    page: 1,
    showNotification: false,
    notificationMessage: "",
    notificationType: "",
    totalUsers: 0,
    rowsPerPage: 10,
    users: [],
  },
  mutations: {
    addUser: (state) => {
      state.dialog = true;
      state.mode = "ADD";
      state.editedUser = Object.assign({}, defaultUser);
    },
    editUser: (state, payload) => {
      state.editedUser = Object.assign({}, payload.item);
      state.editedIndex = state.users.indexOf(payload.item);
      state.dialog = true;
      state.mode = "EDIT";
    },
    closeDialog: (state) => {
      state.editedUser = Object.assign({}, defaultUser);
      state.editedIndex = -1;
      state.errors = [];
    },
    loading: (state, loading) => (state.loading = loading),
    overlay: (state, payload) => (state.overlay = payload.show),
    setErrorMessage: (state, message) => (state.errorMessage = message),
    setMode: (state, mode) => (state.mode = mode),
    setUserToDelete: (state, user) => (state.userToDelete = user),
    setUsers: (state, users) => (state.users = users),
    setItems: (state, users) => (state.users = users),
    setInstances: (state, payload) => (state.instances = payload.instances),
    setTotalUsers: (state, total) => (state.totalUsers = total),
    setTotalItems: (state, total) => (state.totalUsers = total),
    setRowsPerPage: (state, rowsPerPage) => (state.rowsPerPage = rowsPerPage),
    showDialog: (state, show) => (state.dialog = show),
    setDialogDelete: (state, show) => (state.dialogDelete = show),
    showErrorNotification: (state, show) => (state.errorNotification = show),
    showFilter: (state, payload) => (state.dialogFilter = payload.show),
    showSuccessNotification: (state, show) =>
      (state.successNotification = show),
    showLoading: (state, show) => (state.loading = show),
    showNotification: (state, payload) => {
      (state.showNotification = payload.show),
        (state.notificationMessage = payload.message),
        (state.notificationType = payload.type);
    },
    updateField,
    viewUser(state, item) {
      state.editedUser = item;
      state.editedIndex = state.users.indexOf(item);
      state.dialog = true;
      state.mode = "VIEW";
    },
  },
  getters: {
    getField,
  },
  actions: {
    edit: ({ commit, rootGetters }, payload) => {
      let notification = {
        show: true,
        message: "Você não tem permissão para alterar esse usuario",
        type: "error",
      };

      var role = rootGetters["login/getRole"];
      if (role == 98) {
        var role_chat = [0, 1, 80];
        if (role_chat.indexOf(payload.item.role) == -1) {
          commit("showNotification", notification);
          return;
        }
      } else if (role == 97) {
        var role_financial = [2];
        if (role_financial.indexOf(payload.item.role) == -1) {
          commit("showNotification", notification);
          return;
        }
      } else if (role == 96) {
        var role_commercial = [3];
        if (role_commercial.indexOf(payload.item.role) == -1) {
          commit("showNotification", notification);
          return;
        }
      }
      commit("editUser", payload);
    },
    closeDialog: ({ commit }) => {
      commit("showDialog", false);
      setTimeout(() => {
        commit("closeDialog");
        commit("showErrorNotification", false);
        commit("showSuccessNotification", false);
      }, 300);
    },
    closeDeleteDialog: ({ commit }) => {
      commit("setDialogDelete", false);
      commit("setUserToDelete", {});
    },
    getInstances: ({ commit }) => {
      let url = endPoints.instances;
      url += "?page=" + 1 + "&" + "itemsPerPage=" + 30;
      axios.get(url).then((instances) => {
        commit("setInstances", { instances: instances.data.data });
      });
    },
    getItems: ({ commit, state }) => {
      commit("loading", true);
      let url = endPoints.users;
      let filter = state.filter;
      url +=
        "?page=" +
        state.pagination.page +
        "&" +
        "itemsPerPage=" +
        state.pagination.itemsPerPage;

      Object.keys(filter).forEach(function(field) {
        if (
          filter[field] !== "" &&
          filter[field] !== undefined &&
          filter[field] !== null
        ) {
          if (field == "start") {
            var e = new Date(filter[field]);

            url +=
              "&" +
              field +
              "=" +
              e.toISOString().substr(0, 10) +
              " " +
              e.toLocaleTimeString();
          } else {
            url += "&" + field + "=" + filter[field];
          }
        }
      });
      if (
        state.pagination &&
        state.pagination.sortBy &&
        state.pagination.sortBy.length > 0
      ) {
        url +=
          "&sort_by=" +
          (state.pagination.sortBy[0] === "instance_name"
            ? "instance_id"
            : state.pagination.sortBy[0]);
        url += "&sort_desc=" + state.pagination.sortDesc[0];
      }
      axios.get(url).then((items) => {
        commit("setTotalItems", items.data.total);
        commit("loading", false);
        commit("setItems", items.data.data);
      });
    },
    getUsers: ({ commit, state }, payload) => {
      commit("loading", true);

      let url = endPoints.users;
      if(!payload || !payload.noPages) {
        url +=
        "?page=" +
        state.pagination.page +
        "&" +
        "itemsPerPage=" +
        state.pagination.itemsPerPage;
      } else {
        url += "?page=1&itemsPerPage=99999";
      }
      
      axios.get(url).then((users) => {
        commit("setTotalUsers", users.data.total);
        commit("loading", false);
        commit("setUsers", users.data.data);
      });
    },
    getItemsNoPage: ({ state }) => {
      let url = endPoints.users;
      let filter = state.filter;
      url += "?page=" + "1" + "&" + "itemsPerPage=" + "99999";

      Object.keys(filter).forEach(function(field) {
        if (
          filter[field] !== "" &&
          filter[field] !== undefined &&
          filter[field] !== null
        ) {
          // if (field == "end" || field == "start") {
          //     var e = new Date(filter[field])

          //     url += "&" + field + "=" + e.toISOString().substr(0, 10) + " " + e.toLocaleTimeString()
          // } else {

          // }
          url += "&" + field + "=" + filter[field];
        }
      });
      if (
        state.pagination &&
        state.pagination.sortBy &&
        state.pagination.sortBy.length > 0
      ) {
        url +=
          "&sort_by=" +
          (state.pagination.sortBy[0] === "instance_name"
            ? "instance_id"
            : state.pagination.sortBy[0]);
        url += "&sort_desc=" + state.pagination.sortDesc[0];
      }
      return axios.get(url).then((items) => {
        return items.data.data;
      });
    },
    showDeleteDialog: ({ commit }, item) => {
      commit("setDialogDelete", true);
      commit("setUserToDelete", item);
    },
    deleteUser: ({ dispatch, commit, state }) => {
      commit("loading", true);
      axios
        .delete(
          endPoints.users + "/" + state.userToDelete.id,
          state.userToDelete
        )
        .then(() => {
          dispatch("closeDeleteDialog");
          let notification = {
            show: true,
            message: "Usuário salvo com sucesso.",
            type: "success",
          };
          commit("showNotification", notification);
          return dispatch("getUsers");
        });
    },
    resetQrCode: ({dispatch, commit}, payload) => {
      commit('loading', true);
      axios.put(endPoints.users + "/reset_qr_code/" +  payload.user.id).then(() => {
        dispatch("getUsers", {resetPage: false});
        let notification = {
          show: true,
          message: "QR code resetado com sucesso",
          type: "success",
        };
        commit("showNotification", notification);
      }).catch(() => {
        let notification = {
          show: true,
          message: "Ocorreu um erro ao resetar o QR Code",
          type: "error",
        };
        commit("showNotification", notification);
      })
    },  
    saveUser: ({ dispatch, commit, state }) => {
      var url = endPoints.users;
      let func = "";
      if (state.mode == "ADD") {
        func = axios.post;
        url = endPoints.users;
      } else {
        func = axios.put;
        url += "/update/" + state.editedUser.id;
      }

      func(url, state.editedUser).then(
        () => {
          dispatch("getUsers");
          let notification = {
            show: true,
            message: "Usuário salvo com sucesso",
            type: "success",
          };
          commit("showNotification", notification);
          commit("showDialog", false);
        },
        (error) => {
          let messageError = error.response.data.message;
          if (messageError.includes("Duplicate entry")) {
            messageError = "Email já cadastrado";
          }
          let notification = {
            show: true,
            message: messageError,
            type: "error",
          };
          commit("showNotification", notification);
        }
      );
    },
  },
};
