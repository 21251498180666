<template>
  <div class="elevation-10">
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="50"></v-progress-circular>
    </v-overlay>
    <v-snackbar
      :color="notificationType"
      center
      top
      :timeout="2000"
      v-model="showNotification"
    >
        {{ notificationMessage }}
    </v-snackbar>
    <v-dialog v-model="dialog" max-width="600">
      <div @drop.prevent="addDropFile" @dragover.prevent>
        <v-card>
          <v-card-title>
            <span class="headline">Mensagem</span>
          </v-card-title>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    label="Nome *"
                    v-model="name"
                    :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                    :error-messages="errors.collect('name')"
                    v-validate="'required'"
                    data-vv-name="name"
                    type="text"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-select
                  :items="types"
                  label="Tipo"
                  outlined
                  v-model="type"
                  v-if="level == 2"
                  item-text="description"
                  item-value="type"
                ></v-select>
                <v-flex xs12 sm12 md12>
                  <v-card height="200px" v-if="image">
                    <v-img max-height="100%" :src="typeof image == 'string' ? image : image.fileBase64" contain></v-img>
                  </v-card>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-file-input
                    v-model="image"
                    label="Imagem"
                    outlined
                    prepend-icon
                    prepend-inner-icon="mdi-paperclip"
                  ></v-file-input>
                </v-flex>
                <v-flex xs12 sm12 md12 v-if="level == 1">
                  <v-text-field
                    label="Cor"
                    v-model="color"
                    type="text"
                    :readonly="this.mode == 'VIEW' || this.mode == 'FINISH'"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-textarea
                    auto-grow
                    label="Conteúdo"
                    background-color="white"
                    outlined
                    id="textareamessage"
                    hide-details="true"
                    rows="1"
                    v-model="content"
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
          <v-card-actions>
            <v-col>
              <v-btn text @click="closeDialog">Cancelar</v-btn>
            </v-col>
            <v-col class="text-right">
              <v-btn text type="submit" right @click="save">Salvar</v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </div>
    </v-dialog>
    <v-dialog :value="dialogDelete" persistent width="300">
      <v-card>
        <v-card-title class="headline">Confirmar exclusão?</v-card-title>

        <v-card-actions>
          <v-col>
            <v-btn
              text
              @click="deleteDialog({show: false, item: {}})"
            >Cancelar</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn
              text
              type="submit"
              right
              @click="deleteItem"
            >Confirmar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-toolbar flat>
      Mensagens Automáticas
      <v-spacer></v-spacer>
      <v-icon @click="add({})">mdi-plus</v-icon>
    </v-toolbar>
    <v-divider></v-divider>
    <v-treeview dense :items="items" item-children="childrens">
      <template slot="append" slot-scope="{ item }">
        <v-icon :class="{mdichevron: item.order == 1}" @click="changeOrder({item: item, type: 0})" v-if="item.type == 1 && item.level == 1 && item.index != items.length - 3">mdi-chevron-down</v-icon>
        <v-icon class="mdichevron" @click="changeOrder({item: item, type: 1})" v-if="item.type == 1 && item.level == 1  && item.index != 0">mdi-chevron-up</v-icon>
        <v-icon v-if="item.level == 1" @click="add({item: item})">mdi-plus</v-icon>
        <v-icon @click="edit({item: item})" v-if="item.type == 1 || item.level == 2">mdi-pencil</v-icon>
        <v-icon @click="deleteDialog({show: true, item: item})" v-if="item.type == 1 || item.level == 2">mdi-delete</v-icon>
      </template>
    </v-treeview>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import b64 from '../../../util/Base64'

export default {
  created() {
    this.getAutomaticMessages()
  },
  computed: {
    items () {
      var registers =  this.automatic_messages.map((a, index) => {
        a.index = index
        if(a.childrens) {
          let children_of = a._id 
          a.childrens.map((c, index) => {
            c.children_of = children_of
            c.index = index
          })
        }
        return a
      })
      return registers
    },
    ...mapState("automatic_messages", ["automatic_messages", "loading", "mode", "notificationMessage", "notificationType"]),
    ...mapFields("automatic_messages", [
      "dialog",
      "dialogDelete",
      "editedItem.id",
      "editedItem.color",
      "editedItem.name",
      "editedItem.content",
      "editedItem.contact",
      "editedItem.image",
      "editedItem.level",
      "editedItem.type",
      "showNotification"
    ])
  },
  data: () => ({
    valid: false,
    types: [
      {type: 1, description: 'Normal'},
      {type: 99, description: 'Padrão'}
    ]
  }),
  methods: {
    addDropFile(e) {
      if (e.dataTransfer) {
        if (e.dataTransfer.files[0]) {
          let image = e.dataTransfer.files[0];
          b64(image).then((fileBase64) => {
            image.fileBase64 = fileBase64
            this.image = image
          })
        }
      }
    },
    ...mapMutations("automatic_messages", ["add", "closeDialog", "edit"]),
    ...mapActions("automatic_messages", ["changeOrder", "deleteDialog", "deleteItem", "getAutomaticMessages", "saveMessage"]),
    save() {
      this.$validator.validateAll().then(valid => {
        if(valid) {
          this.saveMessage()
        }
      })
    }
  }
};
</script>

<style>
  .mdichevron {
    margin-right: 20px !important;
  }
</style>