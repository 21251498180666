import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../config/endPoints';
import validCpfCnpj from '../../util/CpfCnpj'

const defaultItem = {
    bonus: 0,
    name: '',
    cpf: '',
    birth_date: '',
    email: '',
    player_id: '',
    club: null,
    know_by: null,
    contact_id: '',
    contact_name: '',
    pppoker_accepted: false,
};

const defaultFilter = {
    name: '',
    email: '',
    player_id: '',
    club: '',
    know_by: '',
    pppoker_accepted: '',
    start: '',
    end: ''
};

export default {
    namespaced: true,
    state: {
        blockSave: false,
        contacts: [],
        dialog: false,
        dialogConfirm: false,
        dialogDelete: false,
        dialogFilter: false,
        editedItem: {},
        filter: {
            name: '',
            email: '',
            player_id: '',
            club: '',
            know_by: '',
            pppoker_accepted: '',
            bonus: '',
            start: '',
            end: ''
        },
        filtered: false,
        instances: [],
        items: [],
        loading: false,
        mode: 'LIST',
        overlay: false,
        page: 1,
        pagination: {
            page: 1,
            itemsPerPage: 10
        },
        notificationMessage: '',
        notificationType: "success",
        selectedContact: {},
        showNotification: false,
        totalItems: 0,
        rowsPerPage: 10,
        screen: 'players',
        knowByTypes: [],
        isValid: true,
        isWarning: false,
        errorMessage: ""
    },
    mutations: {
        add: (state, payload) => {
            state.dialog = true
            state.mode = "ADD"

            if (payload.item) {
                state.editedItem = Object.assign({}, payload.item)
            } else {
                state.editedItem = Object.assign({}, defaultItem)
                state.selectedContact = {}
                state.contacts = []
                state.isValid = true
            }
        },
        edit: (state, payload) => {
            if (payload.item.contact_id) {
                var contact = { _id: payload.item.contact_id, name: [payload.item.contact_name] }
                state.selectedContact = contact
                state.contacts = [contact]
            } else {
                state.contacts = []
                state.selectedContact = {}
            }

            payload.item.date_picker = new Date(payload.item.date)
            state.editedItem = Object.assign({}, payload.item)
            state.editedIndex = state.instances.indexOf(payload.item)
            state.dialog = true
            state.mode = "EDIT"
        },
        closeDialog: (state) => {
            state.editedItem = {}
            state.editedIndex = -1
            state.errors = [];
            state.dialog = false
            state.mode = "LIST"
            state.errorMessage = ""
            state.isValid = true
            state.blockSave = false
        },
        loading: (state, payload) => state.loading = payload.show,
        setBlockSave: (state, payload) => state.blockSave = payload.blockSave,
        setFilter: (state, filter) => {
            state.filter = filter
        },
        setFiltered: (state, filtered) => {
            state.filtered = filtered
        },
        setItems: (state, items) => state.items = items,
        setItemToDelete: (state, item) => state.itemToDelete = item,
        setOverlay: (state, payload) => state.overlay = payload.show,
        setTotalItems: (state, total) => state.totalItems = total,
        setPage: (state, payload) => {
            state.pagination.page = payload.page;
            state.pagination.itemsPerPage = payload.itemsPerPage
        },
        setContacts: (state, payload) => {
            if (payload.contacts.length === 1)
                state.selectedContact = payload.contacts[0]

            state.contacts = payload.contacts
        },
        setDialogConfirm: (state, payload) => state.dialogConfirm = payload.dialogConfirm,
        setDialogDelete: (state, show) => state.dialogDelete = show,
        setIsValid: (state, payload) => state.isValid = payload.isValid,
        setIsWarning: (state, payload) => state.isWarning = payload.isWarning,
        setErrorMessage: (state, payload) => state.errorMessage = payload.errorMessage,
        showFilter: (state, payload) => state.dialogFilter = payload.show,
        showNotification: (state, payload) => {
            state.showNotification = payload.show,
                state.notificationMessage = payload.message,
                state.notificationType = payload.type
        },
        viewItem(state, item) {
            if (item.contact_id) {
                var contact = { _id: item.contact_id, name: [item.contact_name] }
                state.selectedContact = contact
                state.contacts = [contact]
            } else {
                state.contacts = []
                state.selectedContact = {}
            }

            state.editedItem = item
            state.editedItem.date_picker = new Date(item.date)
            state.editedIndex = state.items.indexOf(item)
            state.dialog = true
            state.mode = "VIEW"
        },
        updateField,
        setKnowByTypes: (state, payload) => state.knowByTypes = payload.knowByTypes,
        setScreen: (state, payload) => state.screen = payload.screen,
        setSelectedContact: (state, payload) => {
            state.selectedContact = payload.selectedContact
            state.contacts = [payload.selectedContact]
        },
    },
    getters: {
        getField,
        getKnowByTypes: (state) => {
            return state.knowByTypes
        }
    },
    actions: {
        clearFilter: ({ commit, dispatch }) => {
            commit('setFilter', Object.assign({}, defaultFilter))
            commit('setFiltered', false)
            commit('showFilter', { show: false })
            commit('setOverlay', { show: true })

            setTimeout(function() {
                commit('setOverlay', { show: false })
                dispatch('getItems', { resetPage: true })
            }, 1000);
        },
        closeDialog: (({ commit }) => {
            commit('closeDialog')
            commit('message/showDialogPlayer', { show: false, screen: 'messages' }, { root: true })
        }),
        closeDeleteDialog: ({ commit }) => {
            commit('setDialogDelete', false)
            commit('setItemToDelete', {})
        },
        deleteItem: ({ dispatch, commit, state }) => {
            commit('loading', { show: true })
            axios.delete(endPoints.players + "/player/" + state.itemToDelete.id).then(() => {
                dispatch('closeDeleteDialog')
                commit('loading', { show: false })
                let notification = {
                    show: true,
                    message: "Jogador excluido com sucesso",
                    type: "success"
                }
                commit('showNotification', notification)
                return dispatch('getItems', { resetPage: false });
            });
        },
        getContacts({ commit }, payload) {
            if (payload.search) {
                axios.get(endPoints.contact_list + "/search_contacts/" + payload.search).then(response => {
                    var payloadContacts = {
                        contacts: response.data
                    }
                    commit('setContacts', payloadContacts)
                });
            } else {
                var payloadContacts = {
                    contacts: payload.contacts
                }
                commit('setContacts', payloadContacts)
            }
        },
        getItems: ({ commit, state }, payload) => {
            commit('loading', { show: true })
            let url = endPoints.players;
            let filter = state.filter;
            if (payload.resetPage === true) {
                url += '?page=' + 1 + '&' + 'itemsPerPage=' + 10;
                commit('setPage', { page: 1, itemsPerPage: 10 })
            } else {
                url += '?page=' + state.pagination.page + '&' + 'itemsPerPage=' + state.pagination.itemsPerPage;
            }


            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined) {
                    if (field == "end") {
                        var e = new Date(filter.end)
                        e.setDate(e.getDate() + 1)
                        url += "&" + field + "=" + e.toISOString().substr(0, 10)
                    } else {
                        url += "&" + field + "=" + filter[field]
                    }
                }
            });

            if (state.pagination && state.pagination.sortBy && state.pagination.sortBy.length > 0) {
                url += "&sort_by=" + state.pagination.sortBy[0]
                url += "&sort_desc=" + state.pagination.sortDesc[0]
            }

            axios.get(url).then(items => {
                commit('setTotalItems', items.data.total)
                commit('loading', { show: false })
                commit('setItems', items.data.data)
            });
        },
        getItemsNoPage: ({ state }) => {
            let url = endPoints.players;
            let filter = state.filter;
            url += '?page=' + '1' + '&' + 'itemsPerPage=' + '99999';

            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined) {
                    if (field == "end") {
                        var e = new Date(filter.end)
                        e.setDate(e.getDate() + 1)
                        url += "&" + field + "=" + e.toISOString().substr(0, 10)
                    } else {
                        url += "&" + field + "=" + filter[field]
                    }
                }
            });
            return axios.get(url).then(items => {
                return items.data.data
            });
        },
        getKnowByTypes: ({ commit }) => {
            commit('loading', { show: true })
            let url = endPoints.players + '/know_by_types';
            axios.get(url).then(knowByTypesResult => {
                commit('loading', { show: false })
                commit('setKnowByTypes', { knowByTypes: knowByTypesResult.data })
            });
        },
        showDeleteDialog: ({ commit }, item) => {
            commit('setDialogDelete', true)
            commit('setItemToDelete', item)
        },
        save: ({ dispatch, commit, state }) => {
            var url = endPoints.players;
            let func = '';
            if (state.mode == 'ADD') {
                func = axios.post;
                url = endPoints.players;
            } else {
                func = axios.put;
                url += '/' + state.editedItem.id;
            }

            if (state.editedItem.birth_date !== null && state.editedItem.birth_date.indexOf("/") !== -1 && state.editedItem.birth_date.length === 10) {
                let splittedDate = state.editedItem.birth_date.split('/')
                state.editedItem.birth_date = `${splittedDate[2]}-${splittedDate[1]}-${splittedDate[0]}`
            }

            if (!state.isValid) {
                if(state.isWarning) {
                    state.editedItem.cpf = ""
                }
            }

            func(url, state.editedItem).then(
                () => {
                    
                    dispatch('getItems', { resetPage: true })
                    let notification = {
                        show: true,
                        message: "Jogador salvo com sucesso.",
                        type: "success"
                    }
                    
                    dispatch('closeDialog')
                    
                    if (state.screen == 'messages') {
                        commit('message/showNotification', notification, { root: true })
                    } else {
                        commit('showNotification', notification)
                    }
                    
                    commit('setScreen', { screen: 'players' })
                    commit('closeDialog')
                    
                },
                error => {
                    let notification = {
                        show: true,
                        message: error.response.data.message,
                        type: "error"
                    }

                    if (state.screen == 'messages') {
                        commit('message/showNotification', notification, { root: true })
                    } else {
                        commit('showNotification', notification)
                    }
                    
                    commit('setScreen', { screen: 'players' })
                    commit('closeDialog')
                }
            );
            commit('setDialogConfirm', { dialogConfirm: false })
        },
        validate: ({ commit, state }, payload) => {
            commit('setErrorMessage', { errorMessage: "" })
            commit('loading', { show: true })
            if (payload.cpf !== "") {
                if (!validCpfCnpj(payload.cpf)) {
                    commit('setErrorMessage', { errorMessage: "CPF inválido." })
                    commit('setIsValid', { isValid: false })
                    commit('setIsWarning', { isWarning: false })
                    commit('setBlockSave', { blockSave: true })
                    commit('loading', { show: false })
                } else if (payload.cpf !== "" && payload.name !== "" && payload.birth_date !== "") {
                    let url = endPoints.players + "/validate_cpf"
                    let birthDateFormatted = payload.birth_date.replaceAll("/", "")
                    let cpfFormatted = payload.cpf.replaceAll(".", "").replaceAll("-", "")

                    var data = {
                        birth_date: birthDateFormatted,
                        cpf: cpfFormatted,
                        name: payload.name,
                        mode: state.mode
                    }

                    axios.post(url, data).then(response => {
                        commit('setIsValid', { isValid: response.data.IsValid })
                        commit('setIsWarning', { isWarning: response.data.IsWarning })
                        commit('setErrorMessage', { errorMessage: response.data.ErrorMessage })
                        commit('setBlockSave', { blockSave: response.data.BlockSave })
                        commit('loading', { show: false })
                    });
                }
            } else {
                commit('setErrorMessage', { errorMessage: "" })
                commit('setIsValid', { isValid: true })
                commit('setIsWarning', { iswarning: false })
                commit('setBlockSave', { blockSave: false })
                commit('loading', { show: false })
            }
        },
        viewItem({ dispatch }, payload) {
            dispatch('menu/openLink', '/players/' + payload.item.id, { root: true })
        }
    }
}